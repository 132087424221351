import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ConversationTypeChannel from "./ConversationTypeChannel";
import { conversationAction } from "../../../../../store/conversations/conversationAction";

import Loader from "../../../../../components/common/Loader";

function ConversationTypes(props) {
  const [typesList, setTypesList] = useState([]);
  const [enableTypesList, setEnableTypesList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      conversationAction.onGetAllCampaigns({
        productId: 2,
      })
    ).then(data => {
      if (data?.responseMsg === "Success") {
        setTypesList(data?.responseData?.length > 0 ? data?.responseData : []);
      }
    });
  }, []);

  const enableCampaigns = id => {
    var values = [];
    typesList.map(x => {
      if (x.id === id) {
        x.isEnabled = !x.isEnabled;
        values.push({
          productId: 2,
          campaignId: id,
          isEnabled: x.isEnabled,
        });
      }
    });
    setTypesList([...typesList], typesList);
    if (enableTypesList.length === 0) {
      setEnableTypesList(values);
    } else {
      var isExisting = enableTypesList.filter(
        x => x.campaignId === values[0].campaignId
      );
      if (isExisting.length > 0) {
        enableTypesList.map(x => {
          if (x.campaignId === values[0].campaignId) {
            x.isEnabled = values[0].isEnabled;
          }
        });
      } else {
        enableTypesList.push(values[0]);
      }
      setEnableTypesList([...enableTypesList], enableTypesList);
    }
  };

  const onButtonPress = () => {
    setLoading(true);
    dispatch(conversationAction.onEnableCampaigns(enableTypesList)).then(
      data => {
        toast.success(data?.responseMsg);
        dispatch(conversationAction.onChange("activeTabId", `${4}`));
        setLoading(false);
      }
    );
  };

  return (
    <div className="conversationTypeWrapper">
      {isLoading ? <Loader /> : null}
      <div className="conversationTypeInnerWrapper">
        <h2>Conversation Types</h2>
        <span>
          Organize conversations by similar types of requests such as sales,
          support, or press questions. By Default we’ve enabled OFish Presets
          and you will be able to add your own soon. Once you enable a
          conversation type, you’ll be able to add customizations in the next
          steps.
        </span>
        <div className="conversationTypeContentWrapper">
          <Grid container item xs={12} spacing={3}>
            <Grid item md={6}>
              {typesList?.map((item, index) => {
                return index % 2 == 0 ? (
                  <ConversationTypeChannel
                    title={item.name}
                    type={item.type}
                    isActive={item.isActive}
                    discription={item.discription}
                    isEnabled={item.isEnabled}
                    onEnableCampaigns={() => {
                      enableCampaigns(item.id);
                    }}
                  />
                ) : (
                  <></>
                );
              })}
            </Grid>
            <Grid item md={6}>
              {typesList?.map((item, index) => {
                return index % 2 == 1 ? (
                  <ConversationTypeChannel
                    title={item.name}
                    type={item.type}
                    isActive={item.isActive}
                    discription={item.discription}
                    isEnabled={item.isEnabled}
                    onEnableCampaigns={() => {
                      enableCampaigns(item.id);
                    }}
                  />
                ) : (
                  <></>
                );
              })}
            </Grid>
          </Grid>
          <div></div>
          {/* <div>
            <ConversationTypeChannel />
          </div> */}
        </div>
      </div>
      <Button
        color="#F5F8FA"
        variant="contained"
        autoFocus
        type="submit"
        onClick={() => {
          dispatch(conversationAction.onChange("activeTabId", `${2}`));
        }}
        style={{
          bottom: 0,
          position: "absolute",
          right: "100px",
          margin: "20px",
        }}>
        <span style={{ color: "#7E8299", fontSize: "12px" }}>Go Back</span>
      </Button>
      <Button
        color="primary"
        variant="contained"
        autoFocus
        type="submit"
        onClick={() => {
          onButtonPress();
        }}
        style={{ bottom: 0, position: "absolute", right: "0", margin: "20px" }}>
        Continue
      </Button>
    </div>
  );
}

export default ConversationTypes;
