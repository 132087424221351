import React from "react";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

export default function GroupColorAvatar() {
  const classes = useStyles();
  return (
    <AvatarGroup max={3} className={classes.root}>
      <Avatar alt='Remy Sharp' src='' className={classes.orange} />
      <Avatar alt='Travis Howard' src='' className={classes.orange} />
      <Avatar alt='Cindy Baker' src='' className={classes.orange} />
      <Avatar
        alt='Agnes Walker'
        src='/static/images/avatar/4.jpg'
        className={classes.orange}
      />
      <Avatar
        alt='Trevor Henderson'
        src='/static/images/avatar/5.jpg'
        className={classes.orange}
      />
    </AvatarGroup>
  );
}
