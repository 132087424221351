import React from "react";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";

const StorageBreakdown = ({
  value1 = 0,
  value2 = 0,
  value3 = 0,
  value11 = "0 B",
  value22 = "0 B",
  value33 = "0 B",
}) => {
  return (
    <Box>
      <Grid className="storgarBreakdownGrayBox">
        <Box className="storgarBreakdownBlueBox" width={`${value1}%`}></Box>
        <Box className="storgarBreakdownOrangeBox" width={`${value2}%`}></Box>
        <Box className="storgarBreakdownGreenBox" width={`${value3}%`}></Box>
      </Grid>
      <Grid className="storageBreakdownData">
        <Grid container direction="column" className="contentBox">
          <>
            <span className="spanNumberData">
              {value1}% ({value11}){" "}
            </span>
            <span className="spanTextColor">Asset Files</span>
          </>
        </Grid>
        <Grid container direction="column" className="contentBox">
          <>
            <span className="spanNumberData">
              {value2}% ({value22})
            </span>
            <span className="spanTextColor">Conversation Files</span>
          </>
        </Grid>
        <Grid container direction="column" className="contentBox">
          <>
            <span className="spanNumberData">
              {value3}% ({value33})
            </span>
            <span className="spanTextColor">Product Registrations</span>
          </>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StorageBreakdown;
