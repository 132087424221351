import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../store/assetsManagement/assetsActions";
import { IconButton } from "@material-ui/core";
import { Close } from "../../../ofishadmin/views/LeftNav/SvgIcons";
import { useHistory } from "react-router-dom";

const NewFolderPopup = ({
  currentFolder,
  closePopup,
  openPopup,
  startLoading,
  endLoading,
  loadData,
  parent,
  onParentId,
  onUploadFolderSelectionParent,
  uploadFolderSelectionParent,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { parentId, isfavorite } = useSelector((state) => state.AssetsReducer);
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState(false);
  const onFolderNameValidation = (value) => {
    var regName = /^[a-zA-Z0-9-\s]+$/;
    if (value !== "" && (!regName.test(value) || value.length > 50)) {
      setFolderNameError(true);
    } else {
      if (value.trim() === "") {
        setFolderName("");
      } else {
        setFolderName(value);
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (folderName.trim() === "") {
      setFolderName("");
    } else {
      startLoading();
      setFolderName(folderName.trim());
      const parent = parentId === 0 ? null : parentId;
      dispatch(assetsActions.onAddAssetsFolder(folderName, parent, isfavorite)).then(
        (data) => {
          closePopup();
          endLoading();
          history.push('assets-media-library');
        }
      );
    }
  };
  return (
    <div>
      <Dialog
        className="newAssetFolder"
        open={openPopup}
        onClose={closePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
          <DialogTitle className="newFolderPopupHeader" id="alert-dialog-title">
            Create New Folder{" "}
            <IconButton className="closePopup" onClick={closePopup}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className="addLaunchPopupWrapper">
            <DialogContentText id="alert-dialog-description">
              New Folder
              <TextValidator
                id="folderName"
                placeholder="Enter a name"
                variant="outlined"
                name="folderName"
                fullWidth
                validators={["required"]}
                errorMessages={["Field is required"]}
                value={folderName}
                className={
                  folderName === "" && folderNameError
                    ? "errorOutline"
                    : "newAssetFolderInput"
                }
                onBlur={() =>
                  folderName !== ""
                    ? setFolderName(folderName.trim())
                    : setFolderName("")
                }
                onChange={(v) => onFolderNameValidation(v.target.value)}
                InputLabelProps={{
                  style: {
                    // Style for the placeholder text
                    color: "blue", // Change the color to your desired value
                    fontWeight: "bold", // Change the font weight to your desired value
                  },
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="popupFooter addCatFooter">
            <Button
              color="primary"
              className="createFolderBtn"
              disabled={folderName === ""}
              variant="contained"
              autoFocus
              type="submit"
            >
              Create
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};

export default NewFolderPopup;
