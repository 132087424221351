import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";

const AddAvailabilityPeriodButton = ({ onClick }) => {
  return (
    <span
      style={{
        position: "absolute",
        bottom: "15px",
        right: "25px",
        color: "blue",
        zIndex: 9,
      }}>
      <IconButton color='primary' size='small' onClick={onClick}>
        <Add />
      </IconButton>
    </span>
  );
};

export { AddAvailabilityPeriodButton };
