import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { MdAdd, MdClear } from "react-icons/md";

const useStyles = makeStyles(theme => ({
  MuiChip: {
    backgroundColor: "#F3F6F9",
    color: "#70808F",
    maxWidth: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    margin: "0px 5px 5px 0px",
    fontWeight: "700",
    fontSize: "14px",
    padding: "0px 6px 0px 2px",
  },
  chipSubTitle: {
    color: "#3699FF",
  },
  chipIcon: {
    width: "16px",
    height: "16px",
  },
}));

const TitleChip = ({ title = "0 Title" }) => {
  return <span className='titleChip'>{title}</span>;
};

const ChipItem = ({
  chipTitle,
  chipSubTitle,
  accessLevel,
  onDelete,
  isAdd = false,
  resetMargin = null,
}) => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.MuiChip}
      style={{ ...resetMargin }}
      deleteIcon={
        isAdd ? (
          <MdAdd className={classes.chipIcon} />
        ) : (
          <MdClear className={classes.chipIcon} />
        )
      }
      label={
        <span>
          <span>{chipTitle} </span>
          <span className={classes.chipSubTitle}>{chipSubTitle}</span>
          {accessLevel && (
            <span style={{ fontWeight: "normal" }}>{` ${accessLevel}`}</span>
          )}
        </span>
      }
      onDelete={onDelete}
    />
  );
};

export { TitleChip, ChipItem };
