import React, { useEffect, useCallback, useState } from "react";
import AssetManagement from "./AssetManagement";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";

export const SharedFilesAssets = () => {
    const dispatch = useDispatch();
    const { type, search, time, isfavorite, parentId } = useSelector(
      (state) => state.AssetsReducer
    );
    const [isLoading, setLoading] = useState(false);
    const [parent, setParent] = useState(0);
    // pagination states
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrderBy] = useState({
      key: "Name",
      val: 0,
    });
  
    const loadData = useCallback(() => {
      setLoading(true);
      dispatch(assetsActions.onChange("selectedMediaAssets", []));
      dispatch(
        assetsActions.onGetAssetManagementList({
          parentId: parentId,
          type: type,
          search: search,
          time: time,
          isfavorite: isfavorite,
          pageSize: rowsPerPage,
          pageNo: page,
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
        })
      ).then((data) => {
        setLoading(false);
      });
    }, [
      dispatch,
      parentId,
      type,
      search,
      time,
      isfavorite,
      order.orderByKey,
      order.orderVal,
      page,
      rowsPerPage,
    ]);
  
    useEffect(() => {
      dispatch(assetsActions.onChange("isConversation", true));
      dispatch(assetsActions.onChange("gridData", []));
      // loadData();
    }, [dispatch, page, rowsPerPage]);
    return (
      <div>
        <AssetManagement
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          setOrderBy={setOrderBy}
          loadData={loadData}
          isLoading={isLoading}
          setLoading={setLoading}
          parent={parent}
          setParent={setParent}
        />
      </div>
    );
}