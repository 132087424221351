import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { InputField } from "../../../../../components/common";
import { Add } from "@material-ui/icons";
import RadioButtons from "../../../../../components/common/RadioButton/RadioButton";
import { Autocomplete } from "@material-ui/lab";
import { FiChevronDown } from "react-icons/fi";
import { CustomConversationPopup } from "./CustomConversationPopup";
import { ConversationBasicPopupGrid } from "./ConversationBasicPopupGrid";
import { useDispatch, useSelector } from "react-redux";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import OutsideClickHandler from "react-outside-click-handler";
import { ConversationAccordionChips } from "./ConversationAccordionChips";
import { useDebounce } from "../../../../../utils/use-debounce";
import Loader from "../../../../../components/common/Loader";

export const ConversationBasicsSetting = ({
  paramId,
  isAccordion1Edit,
  basicConversationProps,
  addUpdateConversation,
}) => {
  const {
    basicConversationData,
    inboxTypeList,
    allConversationChannels,
    selectedConversationChannels,
  } = useSelector(state => state.conversationReducer);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const [activeTabId, setActiveTabId] = useState("1");
  const [colorOption, setColorOption] = useState(false);
  const [inputAllowed, setInputAllowed] = useState(false);
  const [searchChannels, setSearchChannels] = useState("");
  const [filteredChannels, setFilteredChannels] = useState();
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  const [checkboxSelectedChannel, setCheckboxSelectedChannel] = useState([]);
  const [updataProductData, setUpdataProductData] = useState([]);
  const [order, setOrderBy] = useState({
    orderByKey: "Name",
    orderVal: 0,
  });

  useEffect(() => {
    loadAllConversationChannels();
  }, [
    searchChannels,
    order.orderByKey,
    order.orderVal,
  ]);

  const loadAllConversationChannels = useCallback(() => {
    setIsLoading(true);
    dispatch(
      conversationAction.onGetAllConversationChannels({
        searchQuery: searchChannels,
        channel: null,
        pagination: {
          pageSize: 1000,
          pageNo: 1,
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
        },
      })
    ).then(data => {
      setIsLoading(false);
    });
  });

  const allTabs = [
    {
      id: "1",
      title: "All Channels",
      component: "",
    },
    // {
    //     id: "2",
    //     title: "OFish Connections",
    //     component: "",
    // },
    // {
    //     id: "3",
    //     title: "Social",
    //     component: "",
    // },
    // {
    //     id: "4",
    //     title: "E-Mail",
    //     component: "",
    // },
  ];

  const colors = [
    { color: "#2D6EC8" },
    { color: "#8254E3" },
    { color: "#00ABA3" },
    { color: "#E06145" },
    { color: "#FFAD4E" },
    { color: "#3699FF" },
    { color: "#18BC6D" },
    { color: "#36CFC9" },
    { color: "#54B0E3" },
    { color: "#80D7D3" },
    { color: "#FFEC3D" },
    { color: "#B867AB" },
    { color: "#439442" },
    { color: "#E23C3C" },
    { color: "#DDD587" },
    { color: "#E18DB0" },
    { color: "#FE157F" },
    { color: "#9FB6E5" },
    { color: "#F997EF" },
    { color: "#8FA3B5" },
  ];

  function handleKeyPress(event) {
    if (!inputAllowed) {
      event.preventDefault();
    }
  }
  const addDevice = device => {
    if (
      !selectedConversationChannels?.some(
        selectedConversationChannels =>
          Number(selectedConversationChannels.productId) == Number(device.id)
      )
    ) {
      let newDevice = {
        value: device?.name,
        id: device?.id,
        generic: device?.category,
      };
      dispatch(
        conversationAction.onChange("selectedConversationChannels", [
          ...selectedConversationChannels,
          newDevice,
        ])
      );
    }
  };

  useEffect(() => {
    let sortedChannels = [];
    if (order.orderByKey === "Name" && order.orderVal === 1) {
      sortedChannels = allConversationChannels.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    } else if (order.orderByKey === "Channel" && order.orderVal === 0) {
      sortedChannels = allConversationChannels
        .sort((a, b) => (a.channel > b.channel ? 1 : -1))
        .reverse();
    } else {
      sortedChannels = allConversationChannels;
    }
    setUpdataProductData(sortedChannels);
  }, [
    searchChannels,
    allConversationChannels,
    order.orderVal,
    order.orderByKey,
  ]);

  useEffect(() => {
    if (searchChannels !== "") {
      setFilteredChannels(
        updataProductData.filter(c =>
          c?.name?.toLowerCase().includes(searchChannels?.toLowerCase())
        )
      );
    } else {
      setFilteredChannels(updataProductData);
    }
  }, [searchChannels, updataProductData]);

  const onCheckboxaddChannel = item => {
    const itemIndex = checkboxSelectedChannel.findIndex(
      selectedItem =>
        selectedItem.id === item.id && selectedItem.name === item.name
    );

    if (itemIndex !== -1) {
      setCheckboxSelectedChannel(prevSelected =>
        prevSelected.filter((_, index) => index !== itemIndex)
      );
    } else {
      setCheckboxSelectedChannel(prevSelected => [...prevSelected, item]);
    }
  };

  const onselectAllDevices = () => {
    setCheckboxSelectedChannel(filteredChannels);
    if (selectAllCheckBox === true) {
      setCheckboxSelectedChannel([]);
    }
  };

  const addMultiple = () => {
    // Check for duplicates and remove duplicates
    const uniqueCheckboxDevices = checkboxSelectedChannel.filter(
      x =>
        !selectedConversationChannels?.some(
          selectedConversationChannels =>
            Number(selectedConversationChannels.id) === Number(x.id)
        )
    );
    if (uniqueCheckboxDevices.length > 0) {
      // Map data about according to payload
      const uniqueDevicesToAdd = uniqueCheckboxDevices.map(x => {
        return {
          value: x.name,
          id: x.id,
          generic: x.category,
        };
      });
      dispatch(
        conversationAction.onChange("selectedConversationChannels", [
          ...selectedConversationChannels,
          ...uniqueDevicesToAdd,
        ])
      );
    }
    setCheckboxSelectedChannel([]);
    setSelectAllCheckBox(false);
  };

  const onRemoveSelectedChannel = id => {
    const updatedChannels = selectedConversationChannels?.filter(
      x => x.id !== id
    );
    dispatch(
      conversationAction.onChange(
        "selectedConversationChannels",
        updatedChannels
      )
    );
  };
  const onSubmitConversationChannel = () => {
    basicConversationProps("channels", selectedConversationChannels);
    setIsOpen(false);
  };

  return (
    <div className='conversationBasicsSetting'>
      <ValidatorForm ref={useRef("form")}>
        <Grid container spacing={2} className='conversationBasicsSettingInputs'>
          <Divider />
          <Grid item xs={4}>
            <InputField
              showRequired={true}
              placeholder='Enter Internal Name'
              inputlabel={"Conversation Type Name (Internal)"}
              id='conversation-name'
              name='conversation-name'
              validators={["required"]}
              disabled={paramId && !isAccordion1Edit}
              onChange={({ target }) =>
                basicConversationProps("nameInternal", target.value)
              }
              value={basicConversationData?.nameInternal}
              errorMessages={[`Please enter Conversation Type Name (Internal)`]}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              placeholder='Enter Public Name'
              inputlabel={"Conversation Type Name (Public)"}
              id='conversation-name'
              name='conversation-name'
              disabled={paramId && !isAccordion1Edit}
              onChange={({ target }) =>
                basicConversationProps("namePublic", target.value)
              }
              value={basicConversationData?.namePublic}
            />
          </Grid>
          <Grid item xs={4} className='colorContainer'>
            <InputField
              placeholder='Enter Color Tag'
              inputlabel={"Conversation Type Color (Tag)"}
              id='conversation-name'
              name='conversation-name'
              disabled={paramId && !isAccordion1Edit}
              onChange={({ target }) =>
                basicConversationProps("color", target.value)
              }
              readOnly={false}
              onKeyPress={handleKeyPress}
              autoComplete={"off"}
              onClick={() => {
                setColorOption(true);
              }}
              value={basicConversationData?.color}
            />
            <OutsideClickHandler onOutsideClick={() => setColorOption(false)}>
              <span
                className='colorBox'
                style={{ background: `${basicConversationData?.color}` }}
                onClick={() => setColorOption(true)}
              />
              {colorOption && (
                <div className='colorOptions'>
                  {colors.map((item, index) => {
                    return (
                      <span ket={item.id}>
                        <button
                          style={{ background: `${item.color}` }}
                          onClick={() => {
                            basicConversationProps("color", item.color);
                            setColorOption(false);
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              )}
            </OutsideClickHandler>
          </Grid>

          <Grid item xs={3} className='autocompleteBox'>
            <span>Assigned Inbox</span>
            <Autocomplete
             options={inboxTypeList}
             getOptionLabel={(option) => option.value}
             value={inboxTypeList.find((option) => option.id == basicConversationData?.assignedInbox) || null}
              disableClearable={true}
              popupIcon={<FiChevronDown />}
              onChange={(event, newValue) => {
                basicConversationProps("assignedInbox", newValue?.id.toString());
              }}
              selectOnFocus
              clearOnBlur={false}
              handleHomeEndKeys
              disabled={paramId && !isAccordion1Edit}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={"Select Assigned Inbox"}
                  variant='outlined'
                />
              )}
            />
          </Grid>

          <Grid xs={9} item>
            <InputField
              placeholder='Entert Coversation Description'
              inputlabel={`Description`}
              id='conversation-name'
              name='conversation-name'
              disabled={paramId && !isAccordion1Edit}
              onChange={({ target }) =>
                basicConversationProps("shortDescription", target.value)
              }
              value={basicConversationData?.shortDescription}
            />
          </Grid>

          <Grid xs={12} className='conversationChannel'>
            {!paramId || isAccordion1Edit ? (
              <Button
                color='primary'
                className='addBtn'
                variant='contained'
                onClick={() => {
                  setIsOpen(true);
                }}>
                <Add />
              </Button>
            ) : null}

            <Typography variant='body1' className='channelHeadings'>
              Conversation Channels
            </Typography>
            <CustomConversationPopup
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title='Conversation Channels'
              heading='OFish Channels'
              description='Choose the channels through which this type of conversation can be started and used.'
              allTabs={allTabs}
              activeTabId={activeTabId}
              setActiveTabId={setActiveTabId}
              searchChannels={searchChannels}
              setSearchChannels={setSearchChannels}
              chipData={selectedConversationChannels}
              chipText={"Added channels will appear here"}
              onRemoveChips={onRemoveSelectedChannel}
              onSubmit={onSubmitConversationChannel}
              isLoading={isLoading}
              >
              <ConversationBasicPopupGrid
                filteredChannels={filteredChannels}
                addDevice={addDevice}
                order={order}
                setOrderBy={setOrderBy}
                onCheckboxaddChannel={onCheckboxaddChannel}
                checkboxSelectedChannel={checkboxSelectedChannel}
                setSelectAllCheckBox={setSelectAllCheckBox}
                selectAllCheckBox={selectAllCheckBox}
                addMultiple={addMultiple}
                onselectAllDevices={onselectAllDevices}
              />
            </CustomConversationPopup>
          </Grid>

          <Grid xs={12}>
            <p className='chooseChannel'>
              Choose the channels through which this type of conversation can be
              started and used.<span> Learn More.</span>
            </p>
            <ConversationAccordionChips
              text='Added conversation channel will appear here'
              chipData={selectedConversationChannels}
              onRemoveChips={onRemoveSelectedChannel}
            />
          </Grid>

          <Grid xs={12} className='conversationSettingContainer'>
            <h3>Conversation Settings</h3>
            <Grid item className='conversationChecked'>
              <Typography variant='body2'>
                Can visitors participate in this conversation type?
              </Typography>
              <Grid>
                <Grid
                  item
                  className='radioButtton'
                  style={{
                    opacity:
                      basicConversationData.isPublic !== true &&
                      paramId &&
                      !isAccordion1Edit
                        ? 0.5
                        : 1,
                  }}>
                  <RadioButtons
                    label={"Yes"}
                    disabled={
                      basicConversationData.isPublic !== true &&
                      paramId &&
                      !isAccordion1Edit
                    }
                    value={basicConversationData.isPublic}
                    selectedValue={basicConversationData.isPublic === true}
                    handleChange={() =>
                      basicConversationProps("isPublic", true)
                    }
                  />
                  <List>
                    <ListItem className='checkList'>
                      Yes{" "}
                      <Typography variant='caption'>
                        - Visitors are allowed to chat with this Conversation
                        Type
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid
                  item
                  className='radioButtton'
                  style={{
                    opacity:
                      basicConversationData.isPublic !== false &&
                      paramId &&
                      !isAccordion1Edit
                        ? 0.5
                        : 1,
                  }}>
                  <RadioButtons
                    label={"Yes"}
                    disabled={
                      basicConversationData.isPublic !== false &&
                      paramId &&
                      !isAccordion1Edit
                    }
                    value={!basicConversationData.isPublic}
                    selectedValue={basicConversationData.isPublic === false}
                    handleChange={() =>
                      basicConversationProps("isPublic", false)
                    }
                  />
                  <List>
                    <ListItem className='checkList'>
                      No{" "}
                      <Typography variant='caption'>
                        - Users must have an account/Logged-In to access this
                        Conversation Type
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className='conversationChecked'>
              <Typography variant='body2'>
                Does this conversation allow for more than two participants?
              </Typography>
              <Grid>
                <Grid
                  item
                  className='radioButtton'
                  style={{
                    opacity:
                      basicConversationData.isGroupChat !== true &&
                      paramId &&
                      !isAccordion1Edit
                        ? 0.5
                        : 1,
                  }}>
                  <RadioButtons
                    label={"Yes"}
                    disabled={
                      basicConversationData.isGroupChat !== true &&
                      paramId &&
                      !isAccordion1Edit
                    }
                    value={basicConversationData.isGroupChat}
                    selectedValue={basicConversationData.isGroupChat === true}
                    handleChange={() =>
                      basicConversationProps("isGroupChat", true)
                    }
                  />
                  <List>
                    <ListItem className='checkList'>
                      Yes{" "}
                      <Typography variant='caption'>
                        - Group Chats are allowed between 3+ users (Visitors are
                        not able to join group conversations)
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid
                  item
                  className='radioButtton'
                  style={{
                    opacity:
                      basicConversationData.isGroupChat !== false &&
                      paramId &&
                      !isAccordion1Edit
                        ? 0.5
                        : 1,
                  }}>
                  <RadioButtons
                    label={"Yes"}
                    disabled={
                      basicConversationData.isGroupChat !== false &&
                      paramId &&
                      !isAccordion1Edit
                    }
                    value={!basicConversationData.isGroupChat}
                    selectedValue={basicConversationData.isGroupChat === false}
                    handleChange={() =>
                      basicConversationProps("isGroupChat", false)
                    }
                  />
                  <List>
                    <ListItem className='checkList'>
                      No{" "}
                      <Typography variant='caption'>
                        - 1:1 Chats Between Two Users Only Allowed
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className='conversationChecked'>
              <Typography variant='body2'>
                Hide conversations your brand is not in?
              </Typography>
              <div className='checkedText'>
                If you allow customers to message each other, such as family
                members or B2B customers, you can hide their conversations from
                your main inbox.
              </div>
              <Grid>
                <Grid
                  item
                  className='radioButtton'
                  style={{
                    opacity:
                      basicConversationData.isDeplayedInAdminInbox !== true &&
                      paramId &&
                      !isAccordion1Edit
                        ? 0.5
                        : 1,
                  }}>
                  <RadioButtons
                    label={"Yes"}
                    disabled={
                      basicConversationData.isDeplayedInAdminInbox !== true &&
                      paramId &&
                      !isAccordion1Edit
                    }
                    value={basicConversationData.isDeplayedInAdminInbox}
                    selectedValue={
                      basicConversationData.isDeplayedInAdminInbox === true
                    }
                    handleChange={() =>
                      basicConversationProps("isDeplayedInAdminInbox", true)
                    }
                  />
                  <List>
                    <ListItem className='checkList'>
                      Yes{" "}
                      <Typography variant='caption'>
                        - Hide conversations not involving my brand (You can
                        still find these conversations in your archive or in
                        individual contact profiles)
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid
                  item
                  className='radioButtton'
                  style={{
                    opacity:
                      basicConversationData.isDeplayedInAdminInbox !== false &&
                      paramId &&
                      !isAccordion1Edit
                        ? 0.5
                        : 1,
                  }}>
                  <RadioButtons
                    label={"Yes"}
                    disabled={
                      basicConversationData.isDeplayedInAdminInbox !== false &&
                      paramId &&
                      !isAccordion1Edit
                    }
                    value={!basicConversationData.isDeplayedInAdminInbox}
                    selectedValue={
                      basicConversationData.isDeplayedInAdminInbox === false
                    }
                    handleChange={() =>
                      basicConversationProps("isDeplayedInAdminInbox", false)
                    }
                  />
                  <List>
                    <ListItem className='checkList'>
                      No{" "}
                      <Typography variant='caption'>
                        - Show all conversations in my conversations inboxes
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!paramId || isAccordion1Edit ? (
            <Grid item className='btn_save_next'>
              <>
                <Button
                  variant='outlined'
                  className='skipButton'
                  disabled={!paramId && !isAccordion1Edit}
                  // onClick={() => { setAccordionName("") }}
                >
                  Skip
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  className='dashboardButtons saveBasicInfo'
                  type='submit'
                  onClick={addUpdateConversation}
                  disabled={!basicConversationData?.nameInternal}>
                  Next
                </Button>
              </>
            </Grid>
          ) : null}
        </Grid>
      </ValidatorForm>
    </div>
  );
};
