import React, { useState, useEffect } from "react";
import {
  LogHeader,
  MainHeading,
  InnerHeader,
} from "../../../components/common/Header/index";
import FilterBar from "../../../components/common/FilterBar";
import { eventsPic } from "../../../resources/images/index";
import { Grid, Button } from "@material-ui/core";
import Cards from "../../../components/common/Cards";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import Loader from "../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { pubEventActions } from "../../../store/pubEvent/pubEventActions";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import EventIcon from "@material-ui/icons/Event";
import { accountActions } from "../../../store/accounts/accountActions";
import Footer from "../../../components/common/Footer/Footer";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  GridSelected,
  GridUnSelected,
  ListSelected,
  ListUnSelected,
} from "../../../ofishadmin/views/LeftNav/SvgIcons";
import SearchHighlight from "../../../components/common/SearchHighlight";

const data = [
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Event Title",
    cardtext: "September 1, 2020",
    time: "10 AM PST",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Event Title",
    cardtext: "September 1, 2020",
    time: "10 AM PST",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Event Title",
    cardtext: "September 1, 2020",
    time: "10 AM PST",
  },
];

function LogEvents(props) {
  document.title = "OFish - Events";

  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [showViewAll, setShowViewAll] = useState(true);
  const [isFilter, setIsFilter] = useState(true);
  const [releaseSrch, setReleaseSrch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [catName, setCatName] = useState("");
  const [childName, setChildName] = useState("");
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [isList, setIsList] = useState(false);
  const [isGrid, setIsGrid] = useState(false);
  const { slug } = useParams();

  const { pubEventsCategory, pubEventsWithCategory, selectedEventCategory } =
    useSelector(state => state.pubEventReducer);

  useEffect(() => {
    if (slug && pubEventsCategory?.length > 0) {
      const catData = pubEventsCategory.filter(x => x.urlSlug === slug);
      if (catData?.length > 0) {
        const fiterObj = {
          id: catData[0].id,
          name: catData[0].name,
          urlSlug: catData[0].urlSlug,
        };
        dispatch(pubEventActions.onAddValueInFilter(fiterObj));
        setLoading(true);
        const filterPayload = {
          releaseDate: 0,
          categoryIds: [catData[0].id],
          take: 100,
          skip: 0,
          title: "",
          dataType: ["CategoryOnly"],
        };
        dispatch(pubEventActions.onGetPubAllEvents(filterPayload)).then(res => {
          setLoading(false);
        });
      }
    }
  }, [slug, pubEventsCategory]);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(accountActions.onAddUserSession(window.location.href));
    if (selectedEventCategory?.length > 0) {
      let catIds = [];
      selectedEventCategory.forEach(element => {
        catIds.push(element.id);
      });
      setLoading(true);
      const filterPayload = {
        releaseDate: 0,
        categoryIds: catIds,
        take: 100,
        skip: 0,
        title: "",
        dataType: ["CategoryOnly"],
      };
      dispatch(pubEventActions.onGetPubAllEvents(filterPayload)).then(res => {
        setLoading(false);
      });
    } else {
      loadData();
    }
  }, []);

  const ShowListView = () => {
    setIsList(true);
    setIsGrid(false);
  };

  const ShowGridView = () => {
    setIsList(false);
    setIsGrid(true);
  };

  const loadData = () => {
    setLoading(true);
    setShowViewAll(true);
    setIsSearch(false);
    setReleaseSrch("");
    const eventData = {
      eventDate: 0,
      categoryIds: [],
      take: 3,
      skip: 0,
      title: "",
      dataType: ["LatestOnly", "CategoryOnly"],
    };
    const catIds = [];
    dispatch(pubEventActions.onGetPubAllEvents(eventData)).then(res => {
      if (res && res.success) {
        dispatch(pubEventActions.onGetPubEventCategories()).then(eventCat => {
          if (eventCat && eventCat.success) {
            dispatch(pubEventActions.onGetPubEventDateFilter(catIds)).then(
              eventDate => {
                if (eventDate && !eventDate.success) {
                  toast.error(eventDate.message);
                }
                setLoading(false);
              }
            );
          } else {
            setLoading(false);
            if (eventCat) {
              toast.error(eventCat.message);
            }
          }
        });
      } else {
        if (res) {
          toast.error(res.message);
        }
        setLoading(false);
      }
    });
  };

  const redirectURL = () => {
    history.push("/events-calendar");
  };

  const onSearch = () => {
    if (releaseSrch.trim() !== "") {
      setIsSearch(true);
      setLoading(true);
      setShowViewAll(false);
      const filterPayload = {
        eventDate: 0,
        categoryIds: [],
        take: 100,
        skip: 0,
        title: releaseSrch,
        dataType: ["LatestOnly"],
      };
      dispatch(pubEventActions.onGetPubAllEvents(filterPayload)).then(res => {
        setLoading(false);
      });
    } else {
      loadData();
    }
  };

  const viewAllData = (item, isCategory) => {
    if (item.groupId !== 0 && isCategory) {
      const fiterObj = {
        id: item.groupId,
        name: item.groupBy,
        urlSlug: item.urlSlug,
      };
      dispatch(pubEventActions.onAddValueInFilter(fiterObj));
    }
    setIsViewClicked(true);
    setLoading(true);
    let dataTypeval = "";
    if (item.groupId === 0) {
      dataTypeval = "LatestOnly";
    } else {
      dataTypeval = "CategoryOnly";
    }
    const filterPayload = {
      eventDate: 0,
      categoryIds: item.groupId !== 0 ? [item.groupId] : [],
      take: 100,
      skip: 0,
      title: "",
      dataType: [dataTypeval],
    };
    dispatch(pubEventActions.onGetPubAllEvents(filterPayload)).then(res => {
      setLoading(false);
      setShowViewAll(false);
    });
  };

  const viewChildData = (rel, item) => {
    setChildName(rel.value);
    setCatName(item);
    setLoading(true);
    let dataTypeval = "CategoryOnly";
    const filterPayload = {
      releaseDate: 0,
      categoryIds: rel.id !== "0" ? [parseInt(rel.id)] : [],
      take: 100,
      skip: 0,
      title: "",
      dataType: [dataTypeval],
    };
    dispatch(pubEventActions.onGetPubAllEvents(filterPayload)).then(res => {
      setLoading(false);
      setShowViewAll(false);
    });
  };

  const onDdetail = item => {
    if (item.urlSlug) {
      history.push("/events/category/" + item.urlSlug);
    } else {
      toast.error("No record found");
    }
  };

  return (
    <div className='releasesWrapper'>
      {isLoading ? <Loader /> : null}
      <LogHeader />
      <MainHeading headerSubHeading='Events'>
        <div className='publicBodyTwoColumn'>
          <span className='eventCalBtn' onClick={redirectURL}>
            {" "}
            <EventIcon /> View Full Calendar{" "}
          </span>
          <div className='leftFiletBar'>
            <FilterBar
              startLoading={() => setLoading(true)}
              stopLoading={() => setLoading(false)}
              showButton={() => setIsFilter(true)}
              hideButton={() => setIsFilter(false)}
              loadData={() => loadData()}
              searchPage={() => onSearch()}
              onChange={e => setReleaseSrch(e.target.value)}
              value={releaseSrch}
            />
          </div>
          <div className='rightContentArea'>
            <InnerHeader
              picture={eventsPic}
              text='Discover upcoming events from trade shows to product launches to digital events.'
              heading='Events'
              searchPage={() => onSearch()}
              onChange={e => setReleaseSrch(e.target.value)}
              value={releaseSrch}
              textPlace='Search events'
            />
            {pubEventsWithCategory?.length > 0 &&
              pubEventsWithCategory.map((eventType, index) => (
                <div
                  key={index}
                  className='boxMainWrapper eventsWrapper releaseInnerWrapper'>
                  <div className='releaseMainGridWrapper'>
                    {!showViewAll && (
                      <>
                        <div
                          className={`viewAllBtn ${
                            isViewClicked ? "innerToggle" : ""
                          }`}>
                          {isViewClicked && (
                            <span className='toggleGrids'>
                              View As
                              <span onClick={ShowGridView}>
                                {" "}
                                {isGrid ? (
                                  <GridSelected />
                                ) : (
                                  <GridUnSelected />
                                )}{" "}
                              </span>
                              <span onClick={ShowListView}>
                                {" "}
                                {isList ? (
                                  <ListSelected />
                                ) : (
                                  <ListUnSelected />
                                )}{" "}
                              </span>
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {isSearch ? (
                      <h2>{`${
                        eventType.data?.length
                      }${" results found for `"}${releaseSrch}${"`"}`}</h2>
                    ) : (
                      <h2
                        className={`${
                          childName ? "innerCategoryHeading" : ""
                        }`}>
                        <span>
                          {!eventType.isParent && eventType.groupId !== 0 && (
                            <h2
                              onClick={() => {
                                viewAllData(catName, false);
                              }}>
                              {catName.groupBy}
                              {childName !== "" && (
                                <ArrowForwardIosIcon
                                  style={{ color: "#49b8ef", margin: "20px" }}
                                />
                              )}
                            </h2>
                          )}
                          {eventType.groupBy}
                        </span>
                        {showViewAll &&
                          isFilter &&
                          selectedEventCategory?.length === 0 && (
                            <div className='viewAllBtn'>
                              <Button
                                color='primary'
                                size='small'
                                onClick={() => viewAllData(eventType, true)}>
                                View All
                              </Button>
                            </div>
                          )}
                      </h2>
                    )}
                  </div>
                  <Grid container spacing={3} style={{ marginTop: "15px" }}>
                    {selectedEventCategory &&
                      selectedEventCategory.length > 0 &&
                      eventType.childCategories &&
                      eventType.childCategories?.length > 0 &&
                      eventType.childCategories.map((rel, i) => (
                        <Grid key={i} item md={4}>
                          <span
                            className='subCategoryHeading'
                            onClick={() => {
                              viewChildData(rel, eventType);
                            }}>
                            <ArrowForwardIosIcon /> {rel.value}
                          </span>
                        </Grid>
                      ))}
                  </Grid>
                  <div
                    className={`cards smallCard ${
                      isList ? "gridListView" : ""
                    }`}>
                    <Grid container spacing={3} style={{ marginTop: "15px" }}>
                      {eventType.data &&
                        eventType.data.length > 0 &&
                        eventType.data.map((col, i) => (
                          <Grid key={i} item md={4}>
                            <Cards
                              picture={col.imageUrl}
                              subheading={
                                <SearchHighlight
                                  text={col.title}
                                  highlight={releaseSrch}
                                />
                              }
                              cardtext={col.subTitle}
                              time={moment(col.startDate).format("h:mm:ss a")}
                              onClick={() => onDdetail(col)}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                </div>
              ))}
            {pubEventsWithCategory?.length === 0 && (
              <div className='releaseInnerWrapper noCategory'>
                <div className='releaseMainGridWrapper'>
                  <span>
                    <h2
                      onClick={() => {
                        viewAllData(catName, false);
                      }}>
                      {catName.groupBy}
                    </h2>
                    {childName !== "" && (
                      <ArrowForwardIosIcon style={{ color: "#49b8ef" }} />
                    )}
                    <h2>{childName}</h2>
                  </span>
                </div>
                <span>No Events Found</span>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </MainHeading>
    </div>
  );
}

export default LogEvents;
