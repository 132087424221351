import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Chip,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ExpandMore, MoreHoriz } from "@material-ui/icons";
import { MdClear } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { endpoints } from "../../../../../config/apiConfig";
import { searchIcon, crossImg } from "../../../../../resources/images";
import { useDebounce } from "../../../../../utils/use-debounce";
import { endUserActions } from "../../../../../store/endUser/endUserActions";
import { productCategoriesActions } from "../../../../../store/product/productCategoriesAction";
import {
  GridHide,
  ListShow,
  ListHide,
  GridShow,
  FilterArrow,
} from "../../../LeftNav/SvgIcons";
import {
  Address,
  CategoryFiltersSection,
  FilterCreationDate,
  UserDetails,
} from "../../EndUser";
import { InputField } from "../../../../../components/common";
import { contactsActions } from "../../../../../store/contacts/contactsActions";

const ChipItem = ({ chipLabel, chipValue, onDelete }) => {
  const classes = useStyless();

  return (
    <Chip
      className={classes.MuiChip}
      deleteIcon={<MdClear style={{ width: "16px", height: "16px" }} />}
      label={
        <span className={classes.MuiChip}>
          <b>{chipLabel}: </b>
          {chipValue}
        </span>
      }
      onDelete={onDelete}
    />
  );
};

const useStyless = makeStyles(theme => ({
  gridWrapper: {
    paddingTop: "29px",
    paddingBottom: "20px",
    borderBottom: "1px solid #E2E6E9",
    position: "relative",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  MuiChip: {
    backgroundColor: "#CDE5FF",
    color: "#70808F",
    maxWidth: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    margin: "0px 5px 5px 0px",
  },
  chip: {
    margin: 2,
  },
  dropdownBg: {
    background: "#f1f1f1",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    border: "1px solid #F3F6F9",
    backgroundColor: "#F3F6F9",
    borderRadius: "6px",
    "&:before": {
      borderBottom: "1px solid #F3F6F9",
      "&:hover": {
        borderBottom: "0 !important",
      },
    },
    "&:after": {
      borderBottom: "1px solid #F3F6F9",
    },

    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9",
      },
    },
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px",
  },
}));

const loadEmailsSearchable = async ({ fieldValue }) => {
  const dataResponse = await fetch(
    `${endpoints.customers.getUserAutoCompleteByEmail}${fieldValue}`
  );
  return dataResponse.json();
};

const loadUserSearchable = async ({ fieldName, fieldValue }) => {
  const dataResponse = await fetch(
    `${endpoints.customers.getUserAutoCompleteByKV}?fieldName=${fieldName}&value=${fieldValue}`
  );
  return dataResponse.json();
};

const handleSubmit = e => {
  e.preventDefault();
};

const AllBusinessContactsGridFilter = ({
  IsFilterApplied,
  PaginationResponse,
  AppliedFilters,
  onApplyFilters,
  ActiveTabId,
  setActiveTabId,
  isListView,
  onToggleListView,
  hideFilters= "",
}) => {
  const classes = useStyless();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const { debouncedValue: debouncedSearchTxt } = useDebounce(searchText, 500);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lastLoginFrom , setLastLoginFrom] = useState(null);
  const [lastLoginTo , setLastLoginTo] = useState(null);

  const [bgIds, setBGIds] = useState(
    AppliedFilters?.groupIds ? [...AppliedFilters.groupIds] : []
  );
  const [envIds, setEnvIds] = useState(
    AppliedFilters?.environmentId ? [...AppliedFilters.environmentId] : []
  );
  const [userStatus, setUserStatus] = useState([]);
  const [email, setEmail] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [contactId, setContactId] = useState([]);

  const [selectedPC, setSelectedPC] = useState([]);
  const [selectedSCs, setSelectedSC] = useState([]);
  const [deviceRegisteredQty, setDeviceRegisteredQty] = useState([]);
  // const [productName, setProductName] = useState([]);
  // const [productSKU, setProductSKU] = useState([]);

  const [countryId, setCountryId] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zipCodeData, setZipCodeData] = useState([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [streetAddress, setStreetAddress] = useState([]);
  const [streetList, setStreetList] = useState([]);
  const [noOfContacts , setNoOfContacts] = useState("");
  const [companyName , setCompanyName] = useState("");
  const [lastLogin , setlastLogin] = useState(null);

  const [isFilter, setIsFilter] = useState(false);

  const [businessGroup, setBusinessGroup] = useState([]);
  const [environments, setEnvironments] = useState([]);
  // TODO: env filter need to handle in API

  const [emailsList, setEmailsList] = useState([]);
  const [firstNameList, setFirstNameList] = useState([]);
  const [lastNameList, setLastNameList] = useState([]);
  const [contactIdList, setContactIdList] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  let filtersIndex = 0;
  const filtersLimit = 4;
  const indexReference = useRef(filtersIndex);
  const [showFilters, setShowFilter] = useState(false);

  useEffect(() => {
    dispatch(contactsActions.onGetBusinessGroupDropDown()).then(data => {
      if (data && data.success) {
        const list = data?.data.map(x => {
          return {
            id: x.id,
            value: x.value,
            generic: x.generic,
            checked: false,
          };
        });
        setBusinessGroup(list);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(endUserActions.onGetUserEnvs()).then(data => {
      if (data && data.success) {
        const list = data?.data.map(x => {
          return {
            id: x.id,
            value: x.value,
          };
        });
        setEnvironments(list);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    setSearchText(AppliedFilters?.searchText);
    if (AppliedFilters?.groupIds?.length > 0)
      setBGIds([...AppliedFilters.groupIds]);
    else if (!AppliedFilters?.groupIds) setBGIds([]);

    if (AppliedFilters?.environmentId?.length > 0)
      setEnvIds([...AppliedFilters.environmentId]);
    else if (!AppliedFilters?.environmentId) setEnvIds([]);

    setUserStatus(AppliedFilters?.userStatus);
    setEmail(AppliedFilters?.email);
    setFirstName(AppliedFilters?.firstName);
    setLastName(AppliedFilters?.lastName);
    setContactId(AppliedFilters?.id);
    setNoOfContacts(AppliedFilters?.noOfContacts)
    setCompanyName(AppliedFilters?.companyName)
    setlastLogin(AppliedFilters?.lastLogin)

    if (AppliedFilters?.productCategories?.length > 0)
      setSelectedPC(AppliedFilters?.productCategories);
    else if (AppliedFilters?.productCategories?.length === 0) setSelectedPC([]);
    setSelectedSC(AppliedFilters?.productSubCategoryId || []);
    setDeviceRegisteredQty(AppliedFilters?.deviceRegistered);
    // setProductName(AppliedFilters?.productName);
    // setProductSKU(AppliedFilters?.productSKU);

    setCountryId(AppliedFilters?.countryId);
    setStateId(AppliedFilters?.stateId);
    setCityId(AppliedFilters?.cityId);
    setZipCodeData(AppliedFilters?.zipCodeData);
    setStreetAddress(AppliedFilters?.address);
    setStartDate(AppliedFilters?.startDate || null);
    setEndDate(AppliedFilters?.endDate || null);
    setLastLoginFrom(AppliedFilters?.lastLoginFrom || null);
    setLastLoginTo(AppliedFilters?.lastLoginTo || null);
  }, [AppliedFilters]);

  const showFilter = () => {
    window.scroll(0, 0);
    // document.body.classList.add("bodyScroll");
    setIsFilter(true);
  };

  const hideFilter = () => {
    // document.body.classList.remove("bodyScroll");
    setIsFilter(false);
  };

  const applyFilters = () => {
    onApplyFilters(
      {
        searchText: searchText,
        startDate: startDate,
        endDate: endDate,
        lastLoginTo:lastLoginTo,
        lastLoginFrom:lastLoginFrom,
        groupIds: bgIds?.length > 0 ? bgIds : null,
        noOfContacts: noOfContacts,
        companyName:companyName,
        lastLogin:lastLogin,
        userStatus: userStatus,
        email: email,
        firstName: firstName,
        lastName: lastName,
        id: contactId,
        productCategories: selectedPC || [],
        productSubCategoryId: selectedSCs?.length > 0 ? selectedSCs : [],
        environmentId: envIds?.length > 0 ? envIds : [],
        deviceRegistered: deviceRegisteredQty,
        // productName: productName,
        // productSKU: productSKU,
        countryId: countryId,
        stateId: stateId,
        cityId: cityId,
        zipCodeData: zipCodeData,
        address: streetAddress,
        lastLogin: null,
        rating: null,
        companyName: "",
        date: 0,
      },
      isFilterApplied()
    );
    hideFilter();
  };

  const loadProductsSubCategories = useCallback(
    parentCatIds => {
      return dispatch(
        productCategoriesActions.onGetAllProductsSubCategories(parentCatIds)
      ).then(data => {
        if (data?.success) {
          setSubCategories(data?.data);
        } else setSubCategories([]);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedPC?.length > 0) {
      loadProductsSubCategories(
        selectedPC.map(c => {
          return {
            productCategoryId: c.id,
          };
        })
      );
    }
  }, [selectedPC]);

  useEffect(() => {
    // if (debouncedSearchTxt)
    applyFilters();
  }, [debouncedSearchTxt]);

  const isFilterApplied = () => {
    return (
      startDate ||
      endDate ||
      lastLoginFrom ||
      lastLoginTo||
      bgIds?.length > 0 ||
      noOfContacts ||
      companyName ||
      lastLogin ||
      envIds?.length > 0 ||
      userStatus?.length > 0 ||
      email?.length > 0 ||
      firstName?.length > 0 ||
      lastName?.length > 0 ||
      contactId?.length > 0 ||
      selectedPC?.length > 0 ||
      selectedSCs?.length > 0 ||
      deviceRegisteredQty?.length > 0 ||
      countryId?.length > 0 ||
      stateId?.length > 0 ||
      cityId?.length > 0 ||
      zipCodeData?.length > 0 ||
      streetAddress?.length > 0
    );
  };

  const filtersCount =
    bgIds?.length +
    envIds?.length +
    userStatus?.length +
    email?.length +
    firstName?.length +
    lastName?.length +
    contactId?.length +
    selectedPC?.length +
    selectedSCs?.length +
    deviceRegisteredQty?.length +
    countryId?.length +
    stateId?.length +
    cityId?.length +
    zipCodeData?.length +
    streetAddress?.length +
    (startDate ? 1 : 0) +
    (endDate ? 1 : 0) +
    (lastLoginFrom ? 1 : 0) +
    (lastLoginTo ? 1 : 0) +
    (noOfContacts ? 1 : 0) +
    (companyName ? 1 : 0) +
    (lastLogin ? 1 : 0);

  indexReference.current = filtersIndex;

  return (
    <div className={classes.gridWrapper}>
      <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            md={11}
            className='filterMainSearch endUserSearchFilterMain'>
            <img src={searchIcon} alt='' className='searchIcon' />
            <InputField
              placeholder='Search'
              value={searchText}
              onKeyPress={({ key }) => {
                if (["Enter", "enter"].includes(key)) applyFilters();
              }}
              onChange={({ target }) => {
                setSearchText(target.value);
              }}
              onPaste={value => {
                const splitValue = value.split("\n");
                setSearchText(splitValue.join(", "));
              }}
            />
            <span className='endUserSearchFilter' onClick={showFilter}>
              {(["4", "5", "6"].includes(ActiveTabId) && filtersCount > 1) ||
              (!["4", "5", "6"].includes(ActiveTabId) && filtersCount > 0) ? (
                <span>{`${filtersCount} Filter${
                  filtersCount > 1 ? "(s)" : ""
                } Applied `}</span>
              ) : null}
              <FilterArrow color={"#fff"} />
            </span>
          </Grid>

          <Grid item md={1} className='blockEndUserBtn'>
            <span
              onClick={() => {
                dispatch(endUserActions.onBCListViewChange(false));

                onToggleListView(false);
              }}>
              {isListView ? <GridHide /> : <GridShow />}
            </span>
            <span
              onClick={() => {
                dispatch(endUserActions.onBCListViewChange(true));
                onToggleListView(true);
              }}>
              {isListView ? <ListShow /> : <ListHide />}
            </span>
          </Grid>
        </Grid>
        {IsFilterApplied && isFilterApplied() && (
          <div
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "6px",
            }}>{`${PaginationResponse?.totalRecords || 0} Users Found`}</div>
        )}

        {isFilter && (
          <div className='endUserFilterWrapper'>
            <div className='endUserFilterInnerWrapper'>
              <img
                className='filterCrossImage'
                src={crossImg}
                alt='cross icon'
                onClick={hideFilter}
              />
              <div className='formContainer'>
                <h3>All Business Contacts Filters</h3>
                <Accordion
                  defaultExpanded
                  id='end-user-filter-expandable'
                  TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>
                      <span>Active Filter(s)</span>
                      <span>
                        <Button
                          className='filterClearBtn'
                          variant='outlined'
                          onClick={() => {
                            onApplyFilters(
                              {
                                searchText: "",
                                startDate: null,
                                endDate: null,
                                groupIds: null,
                                noOfContacts:"",
                                lastLoginTo:null,
                                lastLoginFrom:null,
                                userStatus: [],
                                email: [],
                                firstName: [],
                                lastName: [],
                                id: [],
                                productCategories: [],
                                productSubCategoryId: [],
                                deviceRegistered: [],
                                productName: [],
                                productSKU: [],
                                countryId: [],
                                stateId: [],
                                cityId: [],
                                zipCodeData: [],
                                address: [],
                                lastLogin: null,
                                rating: null,
                                companyName: null,
                                date: 0,
                              },
                              false
                            );
                            if (ActiveTabId !== "1") setActiveTabId(`${1}`);
                            hideFilter();
                          }}>
                          Clear
                        </Button>
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <div>
                    {filtersCount <= 0 && (
                      <div className={`filtersChips`}>No Active Filter(s)</div>
                    )}
                    {(startDate || endDate) && (
                      <ChipItem
                        chipLabel='Date'
                        chipValue={`${
                          startDate
                            ? moment(startDate).format("MM/DD/yyyy")
                            : "?"
                        } - ${
                          endDate ? moment(endDate).format("MM/DD/yyyy") : "?"
                        }`}
                        onDelete={() => {
                          if (startDate) setStartDate(null);
                          if (endDate) setEndDate(null);
                        }}
                      />
                    )}

                    {(lastLoginFrom || lastLoginTo) && (
                      <ChipItem
                        chipLabel='Last Login Date'
                        chipValue={`${
                          lastLoginFrom
                            ? moment(lastLoginFrom).format("MM/DD/yyyy")
                            : "?"
                        } - ${
                          lastLoginTo ? moment(lastLoginTo).format("MM/DD/yyyy") : "?"
                        }`}
                        onDelete={() => {
                          if (lastLoginFrom) setLastLoginFrom(null);
                          if (lastLoginTo) setLastLoginTo(null);
                        }}
                      />
                    )}

                    {selectedPC?.length > 0 &&
                      selectedPC.map(pc => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Category'
                              chipValue={pc.value}
                              onDelete={() =>
                                setSelectedPC([
                                  ...selectedPC.filter(
                                    prdC => prdC.value !== pc.value
                                  ),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {selectedSCs?.length > 0 &&
                      selectedSCs.map(psc => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Sub Category'
                              chipValue={psc.value}
                              onDelete={() =>
                                setSelectedSC([
                                  ...selectedSCs.filter(
                                    prdSC => prdSC.value !== psc.value
                                  ),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {bgIds?.length > 0 &&
                      bgIds.map(ugId => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Business Group'
                              chipValue={
                                businessGroup?.find(eug => eug?.id === ugId)
                                  ?.value
                              }
                              onDelete={() =>
                                setBGIds([
                                  ...bgIds.filter(ugid => ugid !== ugId),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {noOfContacts && (
                      <ChipItem
                        chipLabel='No. of Contacts'
                        chipValue={`${noOfContacts}`}
                        onDelete={() => {
                          if (noOfContacts) setNoOfContacts("");
                        }}
                      />
                    )}

                   {companyName && (
                      <ChipItem
                        chipLabel='No. of Contacts'
                        chipValue={`${companyName}`}
                        onDelete={() => {
                          if (companyName) setCompanyName("");
                        }}
                      />
                    )}

                   {lastLogin && (
                      <ChipItem
                        chipLabel='Last Login'
                        chipValue={`${lastLogin}`}
                        onDelete={() => {
                          if (lastLogin) setlastLogin(null);
                        }}
                      />
                    )}

                    {envIds?.length > 0 &&
                      envIds.map(ugId => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Environments'
                              chipValue={
                                environments?.find(eug => eug?.id === ugId)
                                  ?.value
                              }
                              onDelete={() =>
                                setEnvIds([
                                  ...envIds.filter(ugid => ugid !== ugId),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {deviceRegisteredQty?.length > 0 &&
                      deviceRegisteredQty.map(qty => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Qty'
                              chipValue={qty.value}
                              onDelete={() =>
                                setDeviceRegisteredQty([
                                  ...deviceRegisteredQty.filter(
                                    drQty => drQty.value !== qty.value
                                  ),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {userStatus?.length > 0 &&
                      userStatus.map(us => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Status'
                              chipValue={
                                us === 1
                                  ? "Active"
                                  : us === -1
                                  ? "Deleted"
                                  : us === 2
                                  ? "Blocked"
                                  : us === 3
                                  ? "Archived"
                                  : "In-Active"
                              }
                              onDelete={() =>
                                setUserStatus([
                                  ...userStatus.filter(userS => userS !== us),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {firstName?.length > 0 &&
                      firstName.map(fn => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='First Name'
                              chipValue={fn.value}
                              onDelete={() =>
                                setFirstName([
                                  ...firstName.filter(
                                    fstN => fstN.value !== fn.value
                                  ),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {email?.length > 0 &&
                      email.map(em => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Email'
                              chipValue={em.email}
                              onDelete={() =>
                                setEmail([
                                  ...email.filter(
                                    eml => eml.email !== em.email
                                  ),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {lastName?.length > 0 &&
                      lastName.map(ln => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Last Name'
                              chipValue={ln.value}
                              onDelete={() =>
                                setLastName([
                                  ...lastName.filter(
                                    lstN => lstN.value !== ln.value
                                  ),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {contactId?.length > 0 &&
                      contactId.map(cId => {
                        filtersIndex = filtersIndex + 1;
                        return (
                          (filtersIndex <= filtersLimit ||
                            (filtersIndex > filtersLimit && showFilters)) && (
                            <ChipItem
                              chipLabel='Id'
                              chipValue={cId.value}
                              onDelete={() =>
                                setContactId([
                                  ...contactId.filter(
                                    cntId => cntId.value !== cId.value
                                  ),
                                ])
                              }
                            />
                          )
                        );
                      })}

                    {countryId?.map(ctry => {
                      filtersIndex = filtersIndex + 1;
                      return (
                        (filtersIndex <= filtersLimit ||
                          (filtersIndex > filtersLimit && showFilters)) && (
                          <ChipItem
                            chipLabel='Country'
                            chipValue={ctry.value}
                            onDelete={() => {
                              const tempList = Array.from(
                                countryId.filter(ccc => ccc.id !== ctry.id)
                              );
                              while (countryId.length > 0) countryId.pop();
                              tempList.map(tC => countryId.push({ ...tC }));
                              setCountryId([...countryId]);
                            }}
                          />
                        )
                      );
                    })}

                    {stateId?.map(stId => {
                      filtersIndex = filtersIndex + 1;
                      return (
                        (filtersIndex <= filtersLimit ||
                          (filtersIndex > filtersLimit && showFilters)) && (
                          <ChipItem
                            chipLabel='State'
                            chipValue={stId.value}
                            onDelete={() =>
                              setStateId([
                                ...stateId.filter(
                                  stid => stid.value !== stId.value
                                ),
                              ])
                            }
                          />
                        )
                      );
                    })}

                    {cityId?.map(ctyId => {
                      filtersIndex = filtersIndex + 1;
                      return (
                        (filtersIndex <= filtersLimit ||
                          (filtersIndex > filtersLimit && showFilters)) && (
                          <ChipItem
                            chipLabel='City'
                            chipValue={ctyId.value}
                            onDelete={() =>
                              setCityId([
                                ...cityId.filter(
                                  cty => cty.value !== ctyId.value
                                ),
                              ])
                            }
                          />
                        )
                      );
                    })}

                    {zipCodeData?.map(x => {
                      filtersIndex = filtersIndex + 1;
                      return (
                        (filtersIndex <= filtersLimit ||
                          (filtersIndex > filtersLimit && showFilters)) && (
                          <ChipItem
                            chipLabel='Zip Code'
                            chipValue={x.value}
                            onDelete={() =>
                              setZipCodeData([
                                ...zipCodeData.filter(y => y.value !== x.value),
                              ])
                            }
                          />
                        )
                      );
                    })}

                    {streetAddress?.map(street => {
                      filtersIndex = filtersIndex + 1;
                      return (
                        (filtersIndex <= filtersLimit ||
                          (filtersIndex > filtersLimit && showFilters)) && (
                          <ChipItem
                            chipLabel='Address'
                            chipValue={street.value}
                            onDelete={() =>
                              setStreetAddress([
                                ...streetAddress.filter(
                                  cty => cty.value !== street.value
                                ),
                              ])
                            }
                          />
                        )
                      );
                    })}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}>
                    {filtersCount > filtersLimit && (
                      <IconButton
                        color={"primary"}
                        onClick={() => setShowFilter(!showFilters)}>
                        <MoreHoriz />
                      </IconButton>
                    )}
                  </div>
                </Accordion>

                <h4>Creation Date</h4>
                <FilterCreationDate
                  StartDate={startDate}
                  setStartDate={setStartDate}
                  EndDate={endDate}
                  setEndDate={setEndDate}
                />

                {
                  hideFilters === "companyFilter" &&               
                  <div>
                   <h4>Last Login Date</h4>
                  <FilterCreationDate
                    StartDate={lastLoginFrom}
                    setStartDate={setLastLoginFrom}
                    EndDate={lastLoginTo}
                    setEndDate={setLastLoginTo}
                  />
                  </div>
                }


                <Accordion defaultExpanded className='filterAccordiansInner'>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>
                      <h4>Category</h4>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CategoryFiltersSection
                      SelectedPC={selectedPC}
                      setSelectedPC={setSelectedPC}
                      SubCategories={subCategories}
                      // setSubCategories={setSubCategories}
                      SelectedSCs={selectedSCs}
                      setSelectedSC={setSelectedSC}
                      BusinessGroups={businessGroup}
                      BusinessGroupIds={bgIds}
                      setBGIds={setBGIds}
                      Environments={environments}
                      EnvironmentIds={envIds}
                      setEnvIds={setEnvIds}
                      noOfContacts={noOfContacts}
                      setNoOfContacts={setNoOfContacts}
                      companyName={companyName}
                      setCompanyName={setCompanyName}
                      lastLogin={lastLogin}
                      setlastLogin={setlastLogin}
                      hideFilters={hideFilters}
                    />
                  </AccordionDetails>
                </Accordion>
                {
                 hideFilters !== "companyFilter"  &&
                <Accordion defaultExpanded className='filterAccordiansInner'>
                  <AccordionSummary expandIcxon={<ExpandMore />}>
                    <Typography>
                      <h4>User Details</h4>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <UserDetails
                      // EndUserGroup={endUserGroup}
                      // UserGroupIds={ugIds}
                      // setUGIds={setUGIds}
                      DeviceRegisteredQty={deviceRegisteredQty}
                      setDeviceRegisteredQty={setDeviceRegisteredQty}
                      UserStatus={userStatus}
                      setUserStatus={setUserStatus}
                      Email={email}
                      setEmail={setEmail}
                      FirstName={firstName}
                      setFirstName={setFirstName}
                      LastName={lastName}
                      setLastName={setLastName}
                      ContactId={contactId}
                      setContactId={setContactId}
                      onEmailChange={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadEmailsSearchable({
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.data?.length > 0)
                              setEmailsList(data.data);
                            else if (!data.data?.success) setEmailsList([]);
                          });
                      }}
                      EmailsList={emailsList}
                      onFirstNameChange={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadUserSearchable({
                            fieldName: "FirstName",
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.length > 0) setFirstNameList(data);
                            else if (!data?.success) setFirstNameList([]);
                          });
                      }}
                      FirstNameList={firstNameList}
                      onLastNameChange={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadUserSearchable({
                            fieldName: "LastName",
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.length > 0) setLastNameList(data);
                            else if (!data?.success) setLastNameList([]);
                          });
                      }}
                      LastNameList={lastNameList}
                      onContactIdChange={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadUserSearchable({
                            fieldName: "Id",
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.length > 0) setContactIdList(data);
                            else if (!data?.success) setContactIdList([]);
                          });
                      }}
                      ContactIdList={contactIdList}
                    />
                  </AccordionDetails>
                </Accordion>
                }
                <Accordion defaultExpanded className='filterAccordiansInner'>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>
                      <h4>Address</h4>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Address
                      CountryId={countryId}
                      setCountryId={setCountryId}
                      onCountryChange={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadUserSearchable({
                            fieldName: "CountryId",
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.length > 0) setCountryList(data);
                            else if (!data?.success) setCountryList([]);
                          });
                      }}
                      countryList={countryList}
                      StateId={stateId}
                      setStateId={setStateId}
                      onStateChange={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadUserSearchable({
                            fieldName: "StateId",
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.length > 0) setStateList(data);
                            else if (!data?.success) setStateList([]);
                          });
                      }}
                      stateList={stateList}
                      CityId={cityId}
                      setCityId={setCityId}
                      onCityChange={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadUserSearchable({
                            fieldName: "CityId",
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.length > 0) setCityList(data);
                            else if (!data?.success) setCityList([]);
                          });
                      }}
                      cityList={cityList}
                      zipCodeData={zipCodeData}
                      setZipCodeData={setZipCodeData}
                      onChageZipCode={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadUserSearchable({
                            fieldName: "ZipCode",
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.length > 0) setZipCodeList(data);
                            else if (!data?.success) setZipCodeList([]);
                          });
                      }}
                      zipCodeList={zipCodeList}
                      StreetAddress={streetAddress}
                      setStreetAddress={setStreetAddress}
                      onStreetChange={fieldValue => {
                        if (typeof fieldValue === "string")
                          loadUserSearchable({
                            fieldName: "Address",
                            fieldValue: fieldValue,
                          }).then(({ data }) => {
                            if (data?.length > 0) setStreetList(data);
                            else if (!data?.success) setStreetList([]);
                          });
                      }}
                      streetList={streetList}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className='endUserfilrerBtn'>
                <Button
                  variant='contained'
                  onClick={() => {
                    applyFilters();
                  }}>
                  Apply Filter
                </Button>
              </div>
            </div>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
};
export { AllBusinessContactsGridFilter };
