import { Button, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { ReleaseBasicPreview } from "./ReleaseBasicPreview";
import { ReleaseRequirementsPreview } from "./ReleaseRequirementsPreview";
import { EligibleDevicesPreview } from "./EligibleDevicesPreview";
import { ReleasePlanPreview } from "./ReleasePlanPreview";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { useHistory } from "react-router";
import Loader from "../../../../components/common/Loader";
import { useDispatch } from "react-redux";
import { softwareUpdateActions } from "../../../../store/softwareUpdates/softwareUpdatesActions";
import { softwareBuildActions } from "../../../../store/softwareUpdates/SoftwareBuildActions";

export const SoftwareReleasePreview = ({
  buildId,
  isLoading,
  selectedRelease,
  selectedReleasePortal,
  selectedReleaseContacts,
  selectedReleaseProducts,
  poratlAndContactData,
  selectedReleaseId,
  fetchReleaseData,
  updatedTempData,
  softwareReleaseDetials,
  selectedReleaseNotifications,
  notificationTypes,
}) => {

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchReleaseData();
    return () => {
      if (buildId) {
        dispatch(softwareBuildActions.onResetSoftwareBuildData());
      } else {
        dispatch(softwareUpdateActions.onResetSoftwareReleaseData());
      }
    };
  }, [fetchReleaseData]);

  return (
    <Grid container className="softwareReleasePreview">
      {isLoading ? <Loader /> : null}
      <span className="heading">
        {buildId ? "Build " : "Release "} Information
      </span>
      <Grid className="softwareReleasePreviewTabs">
        <TopTabNavigator
          getActiveTabId={(tabId) => {
            // setActiveTabId(`${tabId}`);
          }}
          tabs={[
            {
              id: 1,
              title: `${buildId ? "Build" : "Release"} Basics`,
              component: (
                <ReleaseBasicPreview
                  selectedRelease={selectedRelease}
                  buildId={buildId}
                />
              ),
            },
            {
              id: 2,
              title: `${buildId ? "Build" : "Release"} requirements`,
              component: (
                <ReleaseRequirementsPreview
                  selectedReleasePortal={selectedReleasePortal}
                  updatedTempData={updatedTempData}
                  poratlAndContactData={poratlAndContactData}
                  selectedReleaseId={selectedReleaseId}
                  buildId={buildId}
                />
              ),
            },
            {
              id: 3,
              title: "ELIGIBLE DEVICES",
              component: (
                <EligibleDevicesPreview
                  selectedReleaseProducts={selectedReleaseProducts}
                  buildId={buildId}
                />
              ),
            },
            {
              id: 4,
              title: `${buildId ? "Build" : "Release"} Plan`,
              component: (
                <ReleasePlanPreview
                  selectedRelease={selectedRelease}
                  buildId={buildId}
                  softwareReleaseDetials={softwareReleaseDetials}
                  selectedReleasePortal={selectedReleasePortal}
                  selectedReleaseNotifications={selectedReleaseNotifications}
                  notificationTypes={notificationTypes}
                />
              ),
            },
          ]}
        />
      </Grid>

      <Grid container justifyContent="flex-end">
        <Button
          className="editButton"
          onClick={() => {
            buildId
              ? history.push(
                  `/software-new-build/${selectedReleaseId}/${buildId}`
                )
              : history.push(`/new-software-release/${selectedReleaseId}`);
          }}
        >
          Edit
        </Button>
      </Grid>
    </Grid>
  );
};
