import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { RoutesObj } from "../../../../routes";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { ItemImage } from "../../../../components/common";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

const AllCategoriesGrid = memo(({ listData, loadData, order, onOrderBy }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.UpdateProductCategory.path,
      state: { productCategory: value },
      search: `id=${value.productCategoryId}`,
    });
  };

  const onItemSelect = itemId => {
    const itemExists =
      selectedCategories.length > 0 &&
      selectedCategories.find(c => c === itemId);
    if (itemExists) {
      setSelectedCategories(selectedCategories.filter(c => c !== itemId));
    } else {
      setSelectedCategories([...selectedCategories, itemId]);
    }
  };

  const deleteProductCategories = () => {
    dispatch(
      productCategoriesActions.onDeleteProductCategories({
        ids: selectedCategories,
      })
    ).then(res => {
      if (!res?.success) {
        toast.error(res.message);
      } else {
        setSelectedCategories([]);
        loadData();
      }
    });
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        {selectedCategories.length > 0 ? (
          <Grid item md={9} className={classes.deleteItems}>
            <span
              className='gridDeleteItems'
              onClick={() => deleteProductCategories()}>
              <DeleteIcon /> Delete
            </span>
          </Grid>
        ) : null}
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont} disabled={true} />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              CATEGORY NAME
              <TableSortLabel
                active={order.orderByKey === "CategoryName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CategoryName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              # OF PRODUCTS
              <TableSortLabel
                active={order.orderByKey === "ProductCount"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "ProductCount",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PARENT
              <TableSortLabel
                active={order.orderByKey === "Parent"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Parent",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              SHOW IN APP
              <TableSortLabel
                active={order.orderByKey === "ShowInApp"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "ShowInApp",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={
                (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
              }>
              QUICK ACTIONS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listData?.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridHeaderInner}>
                  <Checkbox
                    className={classes.checkBoxFont}
                    onClick={() => onItemSelect(item.productCategoryId)}
                    checked={
                      selectedCategories.length > 0 &&
                      selectedCategories?.includes(item.productCategoryId)
                    }
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <ItemImage imgSrc={item?.featuredImage} />
                    <span>{item.categoryName}</span>
                  </span>
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.productCount}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <Button
                    variant='contained'
                    className={classes.pendingButtons}>
                    {item.parent}
                  </Button>
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.showInApp}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions productsAction'>
                    <span onClick={() => redirectUrl(item)}>
                      <EditIcon />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {listData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any category.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
});

export { AllCategoriesGrid };
