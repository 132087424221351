import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";
import apiCall from "axios";

async function getAllContacts() {
  return axios
    .get(endpoints.customers.getAllContacts)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteCompanies(ids) {
  return axios
    .post(endpoints.companies.deleteCompanies, ids)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return null;
    });
}
async function getCusGroups() {
  return axios
    .get(endpoints.customerGroups.getCustomerGroups)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteCusGroups(ids) {
  return axios
    .delete(endpoints.customerGroups.deleteCustomerGroups, {
      data: JSON.stringify(ids),
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addCusGroups(value) {
  return axios
    .post(endpoints.customerGroups.addCustomerGroups, {
      name: value,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCountries() {
  return axios
    .post(endpoints.generic.getCountries)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getCities() {
  return axios
    .post(endpoints.generic.getCities)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getProvinces() {
  return axios
    .post(endpoints.generic.getProvinces)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function uploadImage(image) {
  let formData = new FormData();
  formData.append("file", image);
  const token = localStorage.getItem("access_token");
  return apiCall({
    url: endpoints.artifacts.uploadImage,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      let data = response?.data ?? null;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveCompanyInformation(desc, comImage, leader, comContact) {
  return axios
    .post(
      endpoints.companies.saveCompany,
      JSON.stringify({
        name: comContact.companyName,
        bio: null,
        companyAddressId: null,
        companyAddress: {
          // company: comContact.companyName,
          address: {
            // streetAddress: comContact.companyAddress,
            city: comContact.companyCity,
            state: comContact.selectedState
              ? comContact.selectedState.name
              : "",
            country: comContact.selectedCountry
              ? comContact.selectedCountry.name
              : "",
            zipCode: comContact.companyZip,
          },
        },
        description: desc,
        imageId: comImage,
        image: null,
        leaders: leader,
        pressContact: {
          name: comContact.pressContactName,
          email: comContact.pressContactEmail,
          imageId: comContact.pressContactImage,
        },
        phoneNumber1: {
          countryCode: comContact.phoneCode1,
          number: comContact.phoneNumber1,
          isFax: false,
        },
        phoneNumber2: {
          countryCode: comContact.phoneCode2,
          number: comContact.phoneNumber2,
          isFax: false,
        },
        faxNumber: {
          countryCode: comContact.phoneCode3,
          number: comContact.phoneNumber3,
          isFax: true,
        },
        emailAddress1: comContact.contactEmail1,
        emailAddress2: comContact.contactEmail2,
        emailAddress3: comContact.contactEmail3,
        businessHours: {
          mondayFrom: comContact.mondayFrom,
          mondayTo: comContact.mondayTo,
          isMondayClosed: comContact.mondayCheck,
          tuesdayFrom: comContact.tuesdayFrom,
          tuesdayTo: comContact.tuesdayTo,
          isTuesdayClosed: comContact.tuesdayCheck,
          wednesdayFrom: comContact.wednessdayFrom,
          wednesdayTo: comContact.wednessdayTo,
          isWednesdayClosed: comContact.wednessdayCheck,
          thursdayFrom: comContact.thursdayFrom,
          thursdayTo: comContact.thursdayTo,
          isThursdayClosed: comContact.thursdayCheck,
          fridayFrom: comContact.fridayFrom,
          fridayTo: comContact.fridayTo,
          isFridayClosed: comContact.fridayCheck,
          saturdayFrom: comContact.saturdayFrom,
          saturdayTo: comContact.saturdayTo,
          isSaturdayClosed: comContact.saturdayCheck,
          sundayFrom: comContact.sundayFrom,
          sundayTo: comContact.sundayTo,
          isSundayClosed: comContact.sundayCheck,
        },
        twitter: comContact.twitterLink,
        linkedIn: comContact.linkedInLink,
        instagram: comContact.instagramLink,
        facebook: comContact.facebookLink,
      })
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addContact(value) {
  return axios
    .post(endpoints.companies.saveCompany, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function moveContacts(ids, groupId) {
  return axios
    .put(`${endpoints.customers.moveContacts}${groupId}`, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateContact(value, contactId) {
  return axios
    .put(
      `${endpoints.customers.updateContact}${contactId}`,
      JSON.stringify(value)
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getBusinessGroup(modal) {
  return axios
    .post(endpoints.customers.getBusinessGroup, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function addBusinessGroup(value) {
  return axios
    .post(endpoints.customers.addBusinessGroup, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function addCompany(value) {
  return axios
    .post(endpoints.customers.addCompany, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function updateCompany(value) {
  return axios
    .post(endpoints.companies.updateCompany, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getProductSubCategories() {
  return axios
    .post(endpoints.generic.getProductSubCategories)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPolicies() {
  return axios
    .get(endpoints.customers.getPolicies)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function addCustomer(value) {
  return axios
    .post(endpoints.customers.addCustomer, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanies(modal) {
  return axios
    .post(endpoints.companies.getCompanies, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getBusinessGroupListSearch(modal) {
  return axios
    .post(endpoints.customers.getBusinessGroupListSearch, modal)
    .then(({ data }) => data)
    .catch(error => {
      return null;
    });
}

async function getCustomerList(modal) {
  return axios
    .post(endpoints.customers.getCustomerList, modal)
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getEndUserGroup(modal) {
  return axios
    .post(endpoints.customers.getEndUserGroup, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllUserGroup() {
  return axios
    .get(endpoints.customers.getAllUserGroup)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getEndUserList(modal) {
  return axios
    .post(endpoints.customers.getEndUserList, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function deleteUsers(value, isPermanent) {
  return axios
    .post(
      `${endpoints.customers.deleteUsers}?deletePermanent=${isPermanent}`,
      value
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function deleteGroups(value) {
  return axios
    .post(endpoints.customers.deleteGroups, value)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getCompanyDetail(id) {
  return axios
    .get(`${endpoints.companies.getCompanyDetail}${`?id=${id}`}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function updateBusniessContact(value) {
  return axios
    .post(endpoints.companies.updateCompany, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateUser(value) {
  return axios
    .post(endpoints.customers.updateUser, value)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyById(id) {
  return axios
    .get(`${endpoints.companies.getCompanyById}${`=${id}`}`)
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyByName(name) {
  return axios
    .get(`${endpoints.companies.getCompanyByName}${`=${name}`}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateGroup(modal) {
  return axios
    .post(endpoints.customers.addUpdateGroup, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getBUConnectionList(comId) {
  return axios
    .get(`${endpoints.customers.getBUComConnectionList}${`=${comId}`}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getContactsByContactTypeId(typId) {
  return axios
    .get(`${endpoints.customers.getBUContactTypeById}${`=${typId}`}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getGroupById(id) {
  return axios
    .post(`${endpoints.customers.getGroupById}${`=${id}`}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getBusinessGroupdropdown() {
  return axios
    .get(endpoints.customers.GetGroupDropdownForBC)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

const postCustomersEndpoint = async ({ endpoint, data }) => {
  return axios
    .post(endpoint, data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return null;
    });
};

async function addCompanyDetail(modal) {
  return axios
    .post(endpoints.companies.saveCompany, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyOFishConnections() {
  return axios
    .get(endpoints.companies.getCompanyOFishConnections)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateCompanyOFishConnections(modal) {
  return axios
    .post(endpoints.companies.addUpdateCompanyOFishConnections, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteCompanyOFishConnections(modal) {
  return axios
    .post(endpoints.companies.deleteCompanyOFishConnections, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyUsers(id) {
  return axios
    .get(endpoints.companies.getCompanyUsers(id))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyLocTypes() {
  return axios
    .get(endpoints.companies.getCompanyLocTypes)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyLocations(id) {
  return axios
    .get(endpoints.companies.getCompanyLocations(id))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyLocationsById(id) {
  return axios
    .get(endpoints.companies.getCompanyLocationsById(id))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateCompanyLocations(modal) {
  return axios
    .post(endpoints.companies.addUpdateCompanyLocations, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteCompanyLocations(id) {
  return axios
    .post(`${endpoints.companies.deleteCompanyLocations}${id}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function markFavouriteCustomer(value) {
  return axios
    .post(endpoints.customers.markUnMarkFavouriteUsers, value)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

async function updateMarkUserAsArchived(value) {
  return axios
    .post(endpoints.customers.markUserAsArchived, value)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateRestoreUsers(value) {
  return axios
    .post(endpoints.customers.RestoreUsers, value)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function blockCustomer(value) {
  return axios
    .post(endpoints.customers.blockUnBlockCustomers, value)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function markUnMarkFavouriteCompanies(value) {
  return axios
    .post(endpoints.companies.markUnMarkFavouriteCompanies, value)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getBlockedBusinessLocation(id) {
  return axios
    .get(`${endpoints.companies.getBlockedBusinessLocation}/${id}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

export const contactsApi = {
  getAllContacts: getAllContacts,
  getCompanies: getCompanies,
  deleteCompanies: deleteCompanies,
  getCusGroups: getCusGroups,
  deleteCusGroups: deleteCusGroups,
  addCusGroups: addCusGroups,
  getCountries: getCountries,
  getCities: getCities,
  getProvinces: getProvinces,
  uploadImage: uploadImage,
  saveCompanyInformation: saveCompanyInformation,
  addContact: addContact,
  moveContacts: moveContacts,
  updateContact: updateContact,
  getBusinessGroupListSearch: getBusinessGroupListSearch,
  getBusinessGroup: getBusinessGroup,
  addBusinessGroup: addBusinessGroup,
  addCompany: addCompany,
  updateCompany: updateCompany,
  getProductSubCategories: getProductSubCategories,
  getPolicies: getPolicies,
  addCustomer: addCustomer,
  getCustomerList: getCustomerList,
  getEndUserGroup: getEndUserGroup,
  getEndUserList: getEndUserList,
  deleteUsers: deleteUsers,
  deleteGroups: deleteGroups,
  getCompanyDetail: getCompanyDetail,
  updateBusniessContact: updateBusniessContact,
  updateUser: updateUser,
  getCompanyById: getCompanyById,
  getCompanyByName: getCompanyByName,
  getAllUserGroup: getAllUserGroup,
  addUpdateGroup: addUpdateGroup,
  getBUConnectionList: getBUConnectionList,
  getContactsByContactTypeId: getContactsByContactTypeId,
  getGroupById: getGroupById,
  getBusinessGroupdropdown: getBusinessGroupdropdown,
  postCustomersEndpoint: postCustomersEndpoint,
  addCompanyDetail: addCompanyDetail,
  getCompanyOFishConnections: getCompanyOFishConnections,
  addUpdateCompanyOFishConnections: addUpdateCompanyOFishConnections,
  deleteCompanyOFishConnections: deleteCompanyOFishConnections,
  getCompanyUsers: getCompanyUsers,
  getCompanyLocations: getCompanyLocations,
  addUpdateCompanyLocations: addUpdateCompanyLocations,
  deleteCompanyLocations: deleteCompanyLocations,
  getCompanyLocationsById: getCompanyLocationsById,
  updateMarkUserAsArchived: updateMarkUserAsArchived,
  blockCustomer: blockCustomer,
  markFavouriteCustomer: markFavouriteCustomer,
  markUnMarkFavouriteCompanies: markUnMarkFavouriteCompanies,
  updateRestoreUsers: updateRestoreUsers,
  getCompanyLocTypes: getCompanyLocTypes,
  getBlockedBusinessLocation:getBlockedBusinessLocation,
};
