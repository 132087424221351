const rootURL = "https://aquaticavchat.azurewebsites.net/api";
const newRootURL = "https://aquaticavchat.azurewebsites.net/api";
const connectionUrl = "https://aquaticavchat.azurewebsites.net/signalr";

// const rootURL = "https://aquaticavchatdev.azurewebsites.net/api";
// const newRootURL = "https://aquaticavchatdev.azurewebsites.net/api";
// const connectionUrl = "https://aquaticavchatdev.azurewebsites.net/signalr";
export const ConversationConfig = {
  productId: 2,
  generic: "admin",
  userName: "Aquatic AV",
  //For Production
  clientId: "5e63f328-1876-4771-bcb6-53f59f29f8a3",
  //For Dev
  // clientId: "d5083803-29d5-4bf0-bce7-fbaab34011a6",
};

export const endpoints = {
  conversation: {
    connectionUrl: connectionUrl,
    getUserRoomsByUsersId: `${rootURL}/Hub/GetUserRoomsByUsersId`,
    getChatDetailsByRoomId: `${rootURL}/Hub/GetChatDetailsByRoomId`,
    makeRoomFavorite: `${rootURL}/Hub/MakeRoomFavorite`,
    changeRoomCoversationType: `${rootURL}/Hub/ChangeRoomCoversationType`,
    createRoom: `${rootURL}/Hub/CreateRoom`,
    deleteRooms: `${rootURL}/Hub/DeleteRooms`,
    uploadFile: `${rootURL}/Hub/UploadFile`,
    sendMsgViaAPI: `${rootURL}/Hub/SendMsgViaAPI`,
    getCampaignsDdl: `${rootURL}/Configurations/GetCampaignsddlWithCount`,
    updateConversationStatusInRoom: `${rootURL}/Hub/UpdateConversationStatusInRoom`,
    updateCampaignIdInRoom: `${rootURL}/Hub/UpdateCampaignIdInRoom`,
    downloadFile: `${rootURL}/Hub/DownloadFile/`,
    getInboxTypes: `${rootURL}/Configurations/GetInboxTypes`,
    addUpdateTemplates: `${rootURL}/Configurations/AddUpdateTemplates`,
    getTemplateById: `${rootURL}/Configurations/GetTemplateById/`,
    getTemplate: `${rootURL}/Configurations/GetTemplates`,
    getConversationsDdl: `${rootURL}/Hub/GetConversationsDdl`,
    markConversationTagsToRooms: `${rootURL}/Hub/MarkConversationTagsToRooms`,
    getConversationTagsDdl: `${rootURL}/Hub/GetConversationTagsDdl`,
    markRoomAssignee: `${rootURL}/Hub/MarkRoomAssignee`,
    markRoomInbox: `${rootURL}/Hub/UpdateInboxInRoom`,
    sendMessages: `${rootURL}/Webhook/SendMessage`,
    sendInstaMessages:`${rootURL}/IWebhook/SendMessage`,
  },
  conversationPopUp: {
    getConfigurationStatus: `${newRootURL}/Configurations/GetConfigurationStatus`,
    enableConversations: `${newRootURL}/Configurations/EnableConversations`,
    getAllCampaigns: `${newRootURL}/Configurations/GetAllCampaigns`,
    enableCampaigns: `${newRootURL}/Configurations/EnableCampaigns`,
    addUpdateCampaignSettings: `${newRootURL}/Configurations/AddUpdateCampaignSettings`,
    getCampaignSettings: `${newRootURL}/Configurations/GetCampaignSettings`,
    addUpdateProductNotificationSettings: `${newRootURL}/Configurations/AddUpdateProductNotificationSettings`,
    getConversationStatuses: `${newRootURL}/Configurations/GetConversationStatuses`,
    getAllCampaignsGrid: `${newRootURL}/Configurations/GetAllCampaignsGrid`,
    getCampaignSettingsByCampaignId: `${newRootURL}/Configurations/GetCampaignSettingsByCampaignId`,
    makeConversationFavourite: `${rootURL}/Configurations/MarkAsFavourite`,
    archivedConversationType: `${rootURL}/Configurations/ArchivedConversationType`,
    deleteConversationType: `${rootURL}/Configurations/DeleteCampaign`,
    getAllConversationChannels: `${rootURL}/Configurations/GetChannels`,
    getConversationPermission: `${rootURL}/Configurations/GetPermissions`,
    addUpdateConversationPermission: `${rootURL}/Configurations/AddUpdatePermissions`,
    getConversationRoles: `https://ofishproductionapi.azurewebsites.net/api/Customers/Roles`,
    getConversationCustomerData: `https://ofishproductionapi.azurewebsites.net/api/Customers/GetCustomersData`,
    getAllConversationStatus: `${rootURL}/Configurations/ConversationStatus/get`,
    getStatusAction: `${rootURL}/DropDown/status-actions`,
    addUpdateStatus: `${rootURL}/Configurations/ConversationStatus/save`,
    deleteConversationStatus: `${rootURL}/Configurations/ConversationStatus/delete`,
  },
};
