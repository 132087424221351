import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import Overview from "./Overview";
import Leadership from "./Leadership";
import ContactUs from "./ContactUs";
import Loader from "../../../components/common/Loader";
import {
  LogHeader,
  MainHeading,
} from "../../../components/common/Header/index";
import { pubCompanyActions } from "../../../store/pubCompany/pubCompanyActions";
import { accountActions } from "../../../store/accounts/accountActions";
import Footer from "../../../components/common/Footer/Footer";

function CompanyInfo(props) {
  document.title = "OFish - Company Information";

  const dispatch = useDispatch();
  const [isOverview, setIsOverview] = useState(true);
  const [isLeadership, setIsLeadership] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const overview = () => {
    setIsOverview(true);
    setIsLeadership(false);
    setIsContact(false);
  };

  const leadership = () => {
    setIsOverview(false);
    setIsLeadership(true);
    setIsContact(false);
  };

  const contact = () => {
    setIsOverview(false);
    setIsLeadership(false);
    setIsContact(true);
  };

  useEffect(() => {
    dispatch(accountActions.onAddUserSession(window.location.href));
    setLoading(true);
    dispatch(pubCompanyActions.onGetPubCompanyInfo()).then(data => {
      setLoading(false);
    });
  }, []);

  const { companyInfo } = useSelector(state => state.pubCompanyReducer);

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <LogHeader />
      <MainHeading headerSubHeading='Company Info'>
        {/* <div className="publicBody">Home</div> */}
        <div className='boxMainWrapper companyInfo'>
          <div className='infoTabs'>
            <ul className='intoTabsHeader'>
              <li>
                <Button
                  color='primary'
                  variant='outlined'
                  className={isOverview ? "active" : null}
                  onClick={overview}>
                  Company Overview
                </Button>
              </li>
              <li>
                <Button
                  color='primary'
                  variant='outlined'
                  className={isLeadership ? "active" : null}
                  onClick={leadership}>
                  Leadership
                </Button>
              </li>
              <li>
                <Button
                  color='primary'
                  variant='outlined'
                  className={isContact ? "active" : null}
                  onClick={contact}>
                  Contact Us
                </Button>
              </li>
            </ul>
          </div>

          {isOverview && (
            <Overview
              description={companyInfo !== "" ? companyInfo?.companyDesc : ""}
              compImage={
                companyInfo !== "" ? companyInfo?.featuredImageURL : ""
              }
            />
          )}

          {isLeadership && <Leadership Active={true} />}

          {isContact && <ContactUs Active={true} />}
        </div>
        <Footer />
      </MainHeading>
    </div>
  );
}

export default CompanyInfo;
