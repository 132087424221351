import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { RoutesObj } from "../../../../../routes";
import {
  EditIcon,
  SortIcon,
  StarFill,
  StarOutline,
  ViewEye,
  Delete,
} from "../../../LeftNav/SvgIcons";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { toast } from "react-toastify";
import { LightenDarkenColor } from "lighten-darken-color";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
}));

const AllCompaniesGrid = ({
  setLoading,
  listData,
  loadData,
  order,
  onOrderBy,
  onMarkFavouriteCompanies,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const redirectUrl = (value, isView) => {
    history.push({
      pathname: RoutesObj.BusinessCustomer.path,
      companyId: value.companyId,
      search: `id=${value.companyId}${isView ? `&acitveTabId=${isView}` : ""}`,
      state: { scrollTo: ".businessContacts" },
    });
  };

  const onItemSelect = itemId => {
    const itemExists =
      selectedCompanies.length > 0 && selectedCompanies.find(c => c === itemId);
    if (itemExists) {
      setSelectedCompanies(selectedCompanies.filter(c => c !== itemId));
    } else {
      setSelectedCompanies([...selectedCompanies, itemId]);
    }
  };

  const deleteCompanies = (ids, type) => {
    setLoading(true);
    dispatch(contactsActions.onDeleteCompanies(ids)).then(res => {
      if (!res?.success) {
        toast.error(res.message);
        setLoading(false);
      } else {
        toast.success(`Company mark as ${type}`);
        loadData();
        setSelectedCompanies([]);
      }
    });
  };

  useEffect(() => {
    if (isAllSelected === true) {
      const allComapniesIds = listData.map(l => l.companyId);
      setSelectedCompanies(allComapniesIds);
    } else {
      setSelectedCompanies([]);
    }
  }, [isAllSelected, listData]);

  return (
    <>
      <Paper className={classes.gridTopNav} style={{ paddingTop: "10px" }}>
        {selectedCompanies.length > 0 && (
          <Grid
            item
            md={9}
            className={(classes.deleteItems, "tableGridActions")}>
            {/* <Button
              onClick={() =>
                deleteCompanies(
                  {
                    ids: selectedCompanies,
                    status: 3,
                  },
                  "archive"
                )
              }>
              Archive
            </Button>
            <Button
              onClick={() =>
                deleteCompanies(
                  {
                    ids: selectedCompanies,
                    status: 5,
                  },
                  "block"
                )
              }>
              Block
            </Button> */}
            <Button
              onClick={() =>
                deleteCompanies(
                  {
                    ids: selectedCompanies,
                    status: -1,
                  },
                  "delete"
                )
              }>
              Delete
            </Button>
          </Grid>
        )}
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox
                className={classes.checkBoxFont}
                checked={isAllSelected}
                onClick={() => setIsAllSelected(!isAllSelected)}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Company Name
              <TableSortLabel
                active={order.orderByKey === "CompanyName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CompanyName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              No. of Contacts
              <TableSortLabel
                active={order.orderByKey === "TotalUsers"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "TotalUsers",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Business Group
              <TableSortLabel
                active={order.orderByKey === "BusinessGroup"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "BusinessGroup",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Last Login
              <TableSortLabel
                active={order.orderByKey === "LastLogin"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "LastLogin",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={
                (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
              }>
              Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listData?.map((item, index) => {
            return (
              <TableRow
                key={index}
                className={`gridBodyRow endUserRow ${
                  selectedCompanies.length > 0 &&
                  selectedCompanies?.includes(item.companyId)
                    ? "boxChecked"
                    : " "
                }`}>
                <TableCell className={(classes.gridHeaderInner, "gridRow")}>
                  <Checkbox
                    className={(classes.checkBoxFont, "enduserCheckbox")}
                    onClick={() => onItemSelect(item.companyId)}
                    checked={
                      selectedCompanies.length > 0 &&
                      selectedCompanies?.includes(item.companyId)
                    }
                  />
                  <span className='endUserPic'>
                    {item.image ? (
                      <img src={item.image} alt='' />
                    ) : (
                      <i className='userInitials'>{`${item?.companyName
                        ?.charAt(0)
                        ?.toUpperCase()}`}</i>
                    )}
                  </span>
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "capitalize-first")}>
                  {item.companyName}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "tableItemHighlight")}>
                  {`${item.totalUsers} Contacts`}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "tableItemHighlight")}>
                  {item?.businessGroup || "-"}

                  {/* {item.businessGroup} */}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "lastLoginWrapper")}>
                  {item.lastLogin ? moment(item.lastLogin).format("MM/DD/YYYY") : "-" }
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions'>
                    <span
                      onClick={() => {
                        onMarkFavouriteCompanies({
                          selectedCustomers: {
                            companyId: item.companyId,
                            isFavourite: !item?.isFavourite,
                          },
                        });
                      }}>
                      {item?.isFavourite ? <StarFill /> : <StarOutline />}
                    </span>
                    <span onClick={() => redirectUrl(item)}>
                      <EditIcon />
                    </span>
                    <span onClick={() => redirectUrl(item, "1")}>
                      <ViewEye />
                    </span>
                    <span
                      onClick={() =>
                        deleteCompanies(
                          {
                            ids: [item?.id],
                            status: -1,
                          },
                          "delete"
                        )
                      }>
                      <Delete />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {listData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any company.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export { AllCompaniesGrid };
