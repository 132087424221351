import { conversationTypes } from "./conversationTypes";

const INITIAL_STATE = {
  activeTabId: "1",
  hubId: 0,
  selectedAssignee:"",
  isPopupOpen: false,
  isNewConversation: false,
  allChat: 0,
  editChannelType: false,
  editChannelCampId: 0,
  selectedTab: "1",
  editConversationSettingId: null,
  tabsList: [],
  roomsData: [],
  loadCurrentListing: false,
  basicConversationData: {
    productId: 0,
    campaignId: 0,
    nameInternal: "",
    namePublic: "",
    color: "",
    shortDescription: "",
    channels: [],
    isPublic: false,
    isGroupChat: false,
    isDeplayedInAdminInbox: false,
    type: "",
    status: 1,
    assignedInbox: "",
  },
  conversationPermissionData: [],
  allEndUserGroupData: [],
  selectedConversationPermission: [],
  selectedStartConversationPermission: [],
  selectedReciveNewConversationPermission: [],
  inboxTypeList: [],
  compatibleProductsList: [],
  selectedConversationChannels: [],
  allConversationChannels: [],
  conversationRoles:[],
  conversationPermissionGridData:[],
  isSelectedDetialsFav:true,
  conversationCompaginId: 0,
  conversationPlateform: {},
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case conversationTypes.CONVERSATION_ONCHANGE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case conversationTypes.ON_CHANGE_Basic_Conversation_FIELD: {
      const { propName, value } = action.payload.prop;
      const updatedBasicConversationData = {
        ...state.basicConversationData,
        [propName]: value,
      };
      return {
        ...state,
        errorMessage: "",
        basicConversationData: updatedBasicConversationData,
      };
    }
    case conversationTypes.RESET_CONVERSATION_DATA:
      return {
        // ...state,
        // basicConversationData: { ...INITIAL_STATE.basicConversationData },
        // selectedConversationPermission: [],
        // selectedStartConversationPermission: [],
        // selectedReciveNewConversationPermission: [],
        // selectedConversationChannels: [],
         INITIAL_STATE,
      };
    default:
      return state;
  }
};
