import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../components/common/InputField";
import EmptyTextarea from "../../../../components/common/Textarea";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { useDispatch, useSelector } from "react-redux";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { locationsActions } from "../../../../store/locations/locationsActions";
import { AddressAutoComplete } from "../../../../components/common";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function CustomizedAccordions() {
  const dispatch = useDispatch();
  let isDisable = [];
  const {
    locationNameInternal,
    locationNamePublic,
    locationShortDescription,
    locationStreetAddress,
    locationCountry,
    locationCity,
    locationState,
    locationPostalCode,
  } = useSelector(state => state.LocationsReducer);
  const [allCountries, setAllCountries] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [allState, setAllState] = useState([]);
  const [isEditAble, setIsEditAble] = useState([]);
  useEffect(() => {
    dispatch(contactsActions.onGetCountries()).then(data => {
      if (data && data.success) {
        setAllCountries(data.data);
      }
    });
    dispatch(contactsActions.onGetCities()).then(data => {
      if (data && data.success) {
        setAllCities(data.data);
      }
    });
    dispatch(contactsActions.onGetProvinces()).then(data => {
      if (data && data.success) {
        setAllState(data.data);
      }
    });
  }, []);
  const onLocationNameInternalValidation = value => {
    var regName = /^[a-zA-Z0-9\s]+$/;
    if (value !== "" && (!regName.test(value) || value.length > 50)) {
    } else {
      if (value.trim() === "") {
        dispatch(locationsActions.onChange("locationNameInternal", ""));
      } else {
        dispatch(locationsActions.onChange("locationNameInternal", value));
      }
    }
  };
  const onLocationNamePublicValidation = value => {
    var regName = /^[a-zA-Z0-9\s]+$/;
    if (value !== "" && (!regName.test(value) || value.length > 50)) {
    } else {
      if (value.trim() === "") {
        dispatch(locationsActions.onChange("locationNamePublic", ""));
      } else {
        dispatch(locationsActions.onChange("locationNamePublic", value));
      }
    }
  };
  const onLocationShortDescriptionValidation = value => {
    var regName = /^[a-zA-Z0-9,-_\s]+$/;
    if (value !== "" && (!regName.test(value) || value.length > 1000)) {
    } else {
      if (value.trim() === "") {
        dispatch(locationsActions.onChange("locationShortDescription", ""));
      } else {
        dispatch(locationsActions.onChange("locationShortDescription", value));
      }
    }
  };
  const onLocationStreetAddressValidation = value => {
    var regName = /^[a-zA-Z0-9,-_#\s]+$/;
    if (value !== "" && (!regName.test(value) || value.length > 50)) {
    } else {
      if (value.trim() === "") {
        dispatch(locationsActions.onChange("locationStreetAddress", ""));
      } else {
        dispatch(locationsActions.onChange("locationStreetAddress", value));
      }
    }
  };
  const onLocationCityValidation = value => {
    var regName = /^[a-zA-Z0-9,-_#\s]+$/;
    if (value !== "" && (!regName.test(value) || value.length > 50)) {
    } else {
      if (value.trim() === "") {
        dispatch(locationsActions.onChange("locationCity", ""));
      } else {
        dispatch(locationsActions.onChange("locationCity", value));
      }
    }
  };
  const onLocationCountryValidation = value => {
    var regName = /^[a-zA-Z0-9,-_#\s]+$/;
    if (value !== "" && (!regName.test(value) || value.length > 50)) {
    } else {
      if (value.trim() === "") {
        dispatch(locationsActions.onChange("locationCountry", ""));
      } else {
        dispatch(locationsActions.onChange("locationCountry", value));
      }
    }
  };

  const onLocationStateValidation = value => {
    var regName = /^[a-zA-Z0-9,-_#\s]+$/;
    if (value !== "" && (!regName.test(value) || value.length > 50)) {
    } else {
      if (value.trim() === "") {
        dispatch(locationsActions.onChange("locationState", ""));
      } else {
        dispatch(locationsActions.onChange("locationState", value));
      }
    }
  };

  const onLocationPostalCodeValidation = value => {
    // var regName = /^(?:[A-Z0-9a-z]+([-_ ]?[A-Z0-9a-z]+)*)?$/;
    // if (value !== "" && (!regName.test(value) || value.length > 15)) {
    // } else {
    if (value.trim() === "") {
      dispatch(locationsActions.onChange("locationPostalCode", ""));
    } else {
      dispatch(locationsActions.onChange("locationPostalCode", value));
    }
    // }
  };
  const classes = useStyles();
  return (
    <>
      <AccordionContainer title='Location Overview'>
        <Grid container spacing={3}>
          <Grid item xs={11} className={classes.gridWrapper}>
            <InputField
              placeholder='Location Name (Internal)'
              inputlabel='Location Name (Internal)'
              validators={["required"]}
              errorMessages={["Please enter location name (Internal)"]}
              value={locationNameInternal}
              onBlur={() =>
                locationNameInternal !== ""
                  ? dispatch(
                      locationsActions.onChange(
                        "locationNameInternal",
                        locationNameInternal.trim()
                      )
                    )
                  : dispatch(
                      locationsActions.onChange("locationNameInternal", "")
                    )
              }
              onChange={v => onLocationNameInternalValidation(v.target.value)}
            />
          </Grid>

          <Grid item xs={11} className={classes.gridWrapper}>
            <InputField
              placeholder='Location Name (Public)'
              inputlabel='Location Name (Public)'
              validators={["required"]}
              errorMessages={["Please enter location name (Public)"]}
              value={locationNamePublic}
              onBlur={() =>
                locationNamePublic !== ""
                  ? dispatch(
                      locationsActions.onChange(
                        "locationNamePublic",
                        locationNamePublic.trim()
                      )
                    )
                  : dispatch(
                      locationsActions.onChange("locationNamePublic", "")
                    )
              }
              onChange={v => onLocationNamePublicValidation(v.target.value)}
            />
          </Grid>

          <Grid item xs={11} className={classes.gridWrapper}>
            <EmptyTextarea
              textLabel='Short Description'
              value={locationShortDescription}
              onBlur={() =>
                locationShortDescription !== ""
                  ? dispatch(
                      locationsActions.onChange(
                        "locationShortDescription",
                        locationShortDescription.trim()
                      )
                    )
                  : dispatch(
                      locationsActions.onChange("locationShortDescription", "")
                    )
              }
              onChange={v =>
                onLocationShortDescriptionValidation(v.target.value)
              }
            />
          </Grid>

          <Grid item xs={11} className={classes.gridWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={6} className={classes.gridWrapper}>
                {/*<InputField
                  placeholder='282 Kinney Drive'
                  inputlabel='Street Address'
                  validators={["required"]}
                  errorMessages={["Please enter street address"]}
                  value={locationStreetAddress}
                  onBlur={() =>
                    locationStreetAddress !== ""
                      ? dispatch(
                          locationsActions.onChange(
                            "locationStreetAddress",
                            locationStreetAddress.trim()
                          )
                        )
                      : dispatch(
                          locationsActions.onChange("locationStreetAddress", "")
                        )
                  }
                  onChange={v =>
                    onLocationStreetAddressValidation(v.target.value)
                  }
                />*/}
                <AddressAutoComplete
                  address={locationStreetAddress}
                  autoComId={"popupEnvLocInput"}
                  onChange={e => {
                    dispatch(
                      locationsActions.onChange(
                        "locationStreetAddress",
                        e.target.value
                      )
                    );
                  }}
                  setPlace={p => {
                    isDisable = [];
                    onLocationCityValidation("");
                    onLocationStateValidation("");
                    onLocationCountryValidation("");
                    dispatch(
                      locationsActions.onChange("locationPostalCode", "")
                    );
                    const val =
                      document.getElementById("popupEnvLocInput")?.value;
                    p?.address_components?.forEach(x => {
                      if (x?.types?.includes("route")) {
                        onLocationStreetAddressValidation(x?.long_name);
                      }
                      if (x?.types?.includes("locality")) {
                        onLocationCityValidation(x?.long_name);
                        isDisable.push("city");
                      }
                      if (x?.types?.includes("administrative_area_level_1")) {
                        onLocationStateValidation(x?.long_name);
                        isDisable.push("state");
                      }
                      if (x?.types?.includes("country")) {
                        onLocationCountryValidation(x?.long_name);
                        isDisable.push("country");
                      }
                      if (x?.types?.includes("postal_code")) {
                        dispatch(
                          locationsActions.onChange(
                            "locationPostalCode",
                            x?.long_name
                          )
                        );
                        isDisable.push("postalCode");
                      }
                    });
                    setIsEditAble(isDisable);
                  }}
                />
              </Grid>

              <Grid item xs={6} className={classes.gridWrapper}>
                <InputField
                  placeholder='Enter country or region'
                  inputlabel='Country or region'
                  validators={["required"]}
                  errorMessages={["Please enter country or region"]}
                  value={locationCountry}
                  onChange={v => onLocationCountryValidation(v.target.value)}
                  // disabled={isEditAble.includes("country") ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={11} className={classes.gridWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={4} className={classes.gridWrapper}>
                <InputField
                  placeholder='Enter City'
                  inputlabel='City'
                  validators={["required"]}
                  errorMessages={["Please enter city"]}
                  value={locationCity}
                  onChange={v => onLocationCityValidation(v.target.value)}
                  // disabled={isEditAble.includes("city") ? true : false}
                />
              </Grid>

              <Grid item xs={4} className={classes.gridWrapper}>
                <InputField
                  placeholder='Enter State'
                  inputlabel='State'
                  // validators={["required"]}
                  errorMessages={["Please enter state"]}
                  value={locationState}
                  onChange={v => onLocationStateValidation(v.target.value)}
                  // disabled={isEditAble.includes("state") ? true : false}
                />
              </Grid>

              <Grid item xs={4} className={classes.gridWrapper}>
                <InputField
                  placeholder='Zip code or postal code'
                  inputlabel='Zip code or postal code'
                  validators={["required"]}
                  value={locationPostalCode}
                  onBlur={() =>
                    locationPostalCode !== ""
                      ? dispatch(
                          locationsActions.onChange(
                            "locationPostalCode",
                            locationPostalCode.trim()
                          )
                        )
                      : dispatch(
                          locationsActions.onChange("locationPostalCode", "")
                        )
                  }
                  onChange={v => onLocationPostalCodeValidation(v.target.value)}
                  // validators={[
                  //   "matchRegexp:^(?:[A-Z0-9a-z ]+([-_ ]?[A-Z0-9a-z]+)*)?$",
                  //   "matchRegexp:^.{0,15}$",
                  // ]}
                  errorMessages={[
                    "Special characters and multiple spaces are not allowed",
                    "Maximum length is 15 characters",
                  ]}
                  // disabled={isEditAble.includes("postalCode") ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionContainer>
    </>
  );
}
