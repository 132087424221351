import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AcrchiveIcon,
  BlockIcon,
  DeleteIcon,
  EditIcon,
  EditIconSmall,
  EndUsersSortingIcon,
  SmallOutFill,
  SmallStarFill,
  SortIcon,
  StarFill,
  StarOutline,
  ViewEye,
} from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";
import { toast } from "react-toastify";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { RoutesObj } from "../../../../routes";
import { endpoints } from "../../../../config/apiConfig";
import Loader from "../../../../components/common/Loader";
import { Delete } from "@material-ui/icons";
import { DeletePopup } from "../../../../components/common";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridRow: {
    fontSize: "12px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function AllContactsGrid({
  endUserList,
  loadCustomerLists,
  selectedRecord,
  isDelete,
  order,
  onOrderBy,
  activeTabId,
  onSelectAll,
  isDeleted,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [isSelected, setSelection] = useState(false);
  const [permanentDeleteAble, setPermanentDeleteAble] = useState([]);
  const [openDeletePopup, setIsOpenDeletePopup] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    onSelectAll(endUserList, isSelected);
  }, [isSelected]);

  const redirectUrl = (value, isView) => {
    history.push({
      pathname: RoutesObj.CustomerOverview.path,
      userId: value.userId,
      search: `id=${value.userId}&acitveTabId=${isView}`,
    });
  };

  const deleteCustomerList = () => {
    var userIds = [];
    endUserList.map(x => {
      if (x.checked) {
        userIds.push(x.userId);
      }
    });
    if (userIds.length > 0) {
      setLoading(true);
      dispatch(contactsActions.onDeleteUsers(userIds, false)).then(data => {
        setLoading(false);
        if (!data?.success) {
          toast.error(data?.message);
        } else {
          loadCustomerLists();
        }
      });
    } else {
      toast.error("Please select the user.");
    }
  };

  const deleteMultipleCustomerListPermanently = () => {
    var userIds = endUserList.filter(x => x.checked).map(x => x.userId);
    setSelectedIds(userIds);
  };

  const deleteCustomer = ({ userId, isParmanentDelete = false }) => {
    setLoading(true);
    dispatch(contactsActions.onDeleteUsers([userId], isParmanentDelete)).then(
      data => {
        setLoading(false);
        if (!data?.success) {
          toast.error(data?.message);
        } else {
          loadCustomerLists();
        }
      }
    );
  };

  const onArchiveCustomers = () => {
    const ids = endUserList.filter(x => x.checked).map(y => y.userId);
    if (ids.length > 0) {
      const modal = { userIds: ids, isArchived: !(`${activeTabId}` === `4`) };
      setLoading(true);
      dispatch(contactsActions.onUpdateMarkUserAsArchived(modal)).then(data => {
        setLoading(false);
        if (data.success) {
          toast.success(
            `Customer ${
              activeTabId === `4` ? "unarchive" : "archive"
            } successfully.`
          );
          loadCustomerLists();
        } else {
          toast.error(data.message);
        }
      });
    } else {
      toast.error("Please select the user.");
    }
  };

  const onBlockCustomers = () => {
    const selectedUserIds = endUserList
      .filter(x => x.checked)
      .map(y => y.userId);

    if (selectedUserIds.length > 0) {
      setLoading(true);
      dispatch(
        contactsActions.onPostCustomersEndpoint({
          endpoint: endpoints.customers.blockUnBlockCustomers,
          data: {
            userIds: selectedUserIds,
            isBlocked: !(`${activeTabId}` === `6`),
          },
        })
      ).then(data => {
        setLoading(false);
        if (!data?.success) {
          toast.error(data?.message);
        } else {
          loadCustomerLists();
        }
      });
    } else {
      toast.error("Please select the user.");
    }
  };

  const onMarkFavouriteCustomer = selectedCustomer => {
    setLoading(true);
    dispatch(
      contactsActions.onPostCustomersEndpoint({
        endpoint: endpoints.customers.markUnMarkFavouriteUsers,
        data: [
          {
            userId: selectedCustomer.id,
            isFavourite: !selectedCustomer.isFavourite,
          },
        ],
      })
    ).then(data => {
      setLoading(false);
      if (!data?.success) {
        toast.error(data?.message);
      } else {
        loadCustomerLists();
      }
    });
  };

  const restoreAllContacts = () => {
    let userIds = [];
    endUserList.map(x => {
      if (x.checked) {
        userIds.push(x.userId);
      }
    });
    if (userIds.length > 0) {
      setLoading(true);
      dispatch(contactsActions.onUpdateRestoreUsers(userIds)).then(data => {
        setLoading(false);
        if (!data?.success) {
          toast.error(data?.message);
        } else {
          loadCustomerLists();
        }
      });
    } else {
      toast.error("Please select the user.");
    }
  };

  return (
    <Paper style={{ position: "relative" }}>
      {/* <span className='userNumber'>{endUserList.length} Users Found</span> */}
      {isLoading ? <Loader /> : null}
      <Paper className={classes.gridTopNav}>
        <Grid
          item
          md={9}
          className={
            (classes.deleteItems, "tableGridActions gridOperationBtn")
          }>
          <span>
            {isDelete && (
              <>
                <Button
                  onClick={() => {
                    if (activeTabId === "5") {
                      deleteMultipleCustomerListPermanently();
                      setIsOpenDeletePopup(true);
                    } else {
                      deleteCustomerList();
                    }
                  }}
                  startIcon={<Delete />}>
                  Delete
                </Button>

                {!(`${activeTabId}` === `5`) && (
                  <Button
                    onClick={() => onBlockCustomers()}
                    startIcon={<BlockIcon />}>
                    {`${activeTabId}` === `6` ? "UnBlock" : "Block"}
                  </Button>
                )}
                <Button
                  onClick={() => onArchiveCustomers()}
                  startIcon={<AcrchiveIcon />}>
                  {`${activeTabId}` === `4` ? "Unarchive" : "Archive"}
                </Button>

                {`${activeTabId}` === `5` && (
                  <Button onClick={() => restoreAllContacts()}>Restore</Button>
                )}
              </>
            )}
          </span>
        </Grid>
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox
                className={classes.checkBoxFont}
                checked={
                  endUserList.length > 0 &&
                  endUserList.length ===
                    endUserList.filter(u => u.checked).length
                }
                onClick={() => setSelection(!isSelected)}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              First Name
              <TableSortLabel
                active={order.orderByKey === "FirstName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "FirstName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={EndUsersSortingIcon}
                className='EndUsersSortingIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Last Name
              <TableSortLabel
                active={order.orderByKey === "LastName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "LastName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={EndUsersSortingIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Products Owned
              <TableSortLabel
                active={order.orderByKey === "DeviceRegistered"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "DeviceRegistered",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={EndUsersSortingIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              End User Group
              <TableSortLabel
                active={order.orderByKey === "GroupName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "GroupName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={EndUsersSortingIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              style={{ width: "110px" }}
              className={classes.gridHeaderInner}>
              Last Login
              <TableSortLabel
                active={order.orderByKey === "LastLogin"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "LastLogin",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={EndUsersSortingIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              style={{ textAlign: "right" }}
              className={classes.gridHeaderInner}>
              Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {endUserList.map(item => {
            return (
              <TableRow
                className={`gridBodyRow endUserRow ${
                  item.checked ? "boxChecked" : " "
                }`}>
                <TableCell className={(classes.gridHeaderInner, "gridRow")}>
                  <Checkbox
                    className={(classes.checkBoxFont, "enduserCheckbox")}
                    checked={item.checked}
                    onClick={() => selectedRecord(item)}
                  />
                  <span className='endUserPic'>
                    {item.image ? (
                      <img src={item.image} alt='' />
                    ) : (
                      <i className='userInitials'>{`${item?.firstName
                        ?.charAt(0)
                        ?.toUpperCase()}${item?.lastName
                        ?.charAt(0)
                        ?.toUpperCase()}`}</i>
                    )}
                  </span>
                </TableCell>
                <TableCell
                  className={
                    (classes.gridBodyInner,
                    "inlineLink",
                    "endUserTableItems",
                    "capitalize-first")
                  }>
                  <span onClick={() => redirectUrl(item, "1")}>
                    {`${
                      item?.firstName?.length <= 25
                        ? item?.firstName
                        : item?.firstName?.slice(0, 22) + "..." ?? "--"
                    }`}
                  </span>
                </TableCell>
                <TableCell
                  className={
                    (classes.gridBodyInner,
                    "inlineLink",
                    "endUserTableItems",
                    "capitalize-first")
                  }>
                  <span onClick={() => redirectUrl(item, "1")}>
                    {`${
                      item?.lastName?.length <= 25
                        ? item?.lastName
                        : item?.lastName?.slice(0, 22) + "..."
                    }`}
                  </span>
                </TableCell>
                <TableCell className={(classes.gridBodyInner, "inlineLink")}>
                  <span
                    onClick={() =>
                      item.deviceRegistered > 0 && redirectUrl(item, "3")
                    }>
                    {item.deviceRegistered == 0 ? (
                      "No Products"
                    ) : item.deviceRegistered == 1 ? (
                      <span
                        style={{
                          fontWeight: 700,
                          color: "#3699FF",
                        }}>
                        {`${"1 Product "}${
                          item?.registrationPendingDevices > 0
                            ? ` (${item?.registrationPendingDevices})`
                            : ""
                        }`}
                      </span>
                    ) : (
                      <span
                        style={{
                          fontWeight: 700,
                          color: "#3699FF",
                        }}>
                        {`${item.deviceRegistered}${" Products "}${
                          item?.registrationPendingDevices > 0
                            ? ` (${item?.registrationPendingDevices})`
                            : ""
                        }`}
                      </span>
                    )}
                  </span>
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "tableItemHighlight")}>
                  {item?.groupName || "-"}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "endUserTableItems")}>
                  {item.lastLoginDT !== null && item.lastLoginDT !== ""
                    ? moment(item.lastLoginDT).fromNow()
                    : "--"}
                </TableCell>

                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions'>
                    <span onClick={() => onMarkFavouriteCustomer(item)}>
                      {item?.isFavourite ? <SmallStarFill /> : <SmallOutFill />}
                    </span>
                    <span onClick={() => redirectUrl(item, "2")}>
                      <EditIconSmall />
                    </span>
                    <span onClick={() => redirectUrl(item, "1")}>
                      <ViewEye />
                    </span>
                    <span
                      onClick={() => {
                        if (isDeleted) {
                          setPermanentDeleteAble(item);
                          setIsOpenDeletePopup(true);
                        } else {
                          deleteCustomer({ userId: item.id });
                        }
                      }}>
                      <DeleteIcon />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {endUserList.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  {`${"Uh Oh! You haven’t "}${
                    activeTabId === "4"
                      ? "archived"
                      : activeTabId === "5"
                      ? "deleted"
                      : activeTabId === "6"
                      ? "blocked"
                      : "create"
                  }${" any user."}`}
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* {showMovePopup && (
        <MoveContactPopup
          openPopup={showMovePopup}
          closePopup={() => setShowMovePopup(false)}
          startLoading={() => startLoading()}
          endLoading={() => endLoading()}
          loadData={() => loadData()}
          groupData={customerGroups}
        />
      )} */}
      {openDeletePopup && (
        <DeletePopup
          maxwidth='1099px'
          isvisible={openDeletePopup}
          oncloseaction={() => setIsOpenDeletePopup(false)}
          heading='Permanently Delete User'
          deletemessage={`Are you sure you want to permanently delete End User(s)?`}
          subheading='Once a user is permanently deleted, you will no longer have access to their information.'
          cancelbtn={() => setIsOpenDeletePopup(false)}
          deletebtn={() => {
            if (!selectedIds.length) {
              deleteCustomer({
                userId: permanentDeleteAble.userId,
                isParmanentDelete: true,
              });
            } else {
              if (selectedIds.length > 0) {
                setLoading(true);
                dispatch(contactsActions.onDeleteUsers(selectedIds, true)).then(
                  data => {
                    setLoading(false);
                    if (!data?.success) {
                      toast.error(data?.message);
                    } else {
                      loadCustomerLists();
                    }
                  }
                );
              } else {
                toast.error("Please select the user.");
              }
              setSelectedIds([]);
              setIsOpenDeletePopup(false);
            }
            setIsOpenDeletePopup(false);
          }}
        />
      )}
    </Paper>
  );
}

export default AllContactsGrid;
