import React from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./EventUtils";
import { Button } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import HouseIcon from "@material-ui/icons/House";
import Loader from "../Loader";
import CustomCheckbox from "../checkbox/CustomCheckbox";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pubEventActions } from "../../../store/pubEvent/pubEventActions";
import moment from "moment";
import { LightenDarkenColor } from "lighten-darken-color";
import { withRouter } from "react-router-dom";
// import FilterAltIcon from '@material-ui/icons/FilterAlt';

class DemoApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekendsVisible: true,
      currentEvents: [],
      isFilter: false,
      calendarData: [],
      isLoading: false,
      eventCategory: [],
    };
  }

  componentDidMount() {
    this.loadEvents();
    this.getEventCategory();
  }

  loadEvents() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const clndrObj = {
      eventDateStart: moment(firstDay).format("YYYY-MM-DD"),
      eventDateEnd: moment(lastDay).format("YYYY-MM-DD"),
      categoryIds: [],
    };
    this.setState({ isLoading: true });
    this.props.actions.onGetPubEventCalander(clndrObj).then(data => {
      this.setState({ isLoading: false });
      if (data && data.success) {
        let testArr = [];
        data.data.forEach(element => {
          const res = {
            id: element.eventId,
            title: element.title,
            start: element.startDate,
            end: element.endDate,
            backgroundColor: LightenDarkenColor(element.color, 140),
            textColor: element.color,
          };
          testArr.push(res);
        });
        this.setState({ calendarData: testArr });
      }
    });
  }

  getEventCategory() {
    this.props.actions.onGetPubEventCategories().then(data => {
      if (data && data.success) {
        this.props.actions.onChange("calendarCate", data.data);
        // this.setState({ eventCategory: data.data });
      }
    });
  }
  onPressCheckBox(value) {
    let catIds = [];
    const { selectedClndrCat } = this.props.state;
    this.props.actions.onSelectCalendarCategory(value);
    if (selectedClndrCat && selectedClndrCat.length > 0) {
      selectedClndrCat.forEach(element => {
        catIds.push(element.id);
      });
      if (catIds.length > 0) {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        const clndrObj = {
          eventDateStart: moment(firstDay).format("YYYY-MM-DD"),
          eventDateEnd: moment(lastDay).format("YYYY-MM-DD"),
          categoryIds: catIds,
        };
        this.setState({ isLoading: true });
        this.props.actions.onGetPubEventCalander(clndrObj).then(data => {
          this.setState({ isLoading: false });
          if (data && data.success) {
            let testArr = [];
            data.data.forEach(element => {
              const res = {
                id: element.eventId,
                title: element.title,
                start: element.startDate,
                end: element.endDate,
                backgroundColor: LightenDarkenColor(element.color, 140),
                textColor: element.color,
              };
              testArr.push(res);
            });
            this.setState({ calendarData: testArr });
          }
        });
      }
    }
  }

  checkEventCategory(itemId) {
    const { selectedClndrCat } = this.props.state;
    const relExist = selectedClndrCat.find(eventCat => {
      return eventCat.id == itemId;
    });
    if (relExist) {
      return true;
    } else {
      return false;
    }
  }

  onClearFilter() {
    this.loadEvents();
    this.setState({
      isFilter: false,
    });
    this.props.actions.onChange("selectedClndrCat", []);
  }

  render() {
    const { calendarCate } = this.props.state;
    return (
      <div className='demo-app'>
        {this.state.isLoading ? <Loader /> : null}
        {this.renderSidebar()}
        <div className='demo-app-main'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView='dayGridMonth'
            // editable={false}
            // selectable={true}
            // selectMirror={true}
            // dayMaxEvents={true}
            // weekends={this.state.weekendsVisible}
            events={this.state.calendarData} // alternatively, use the `events` setting to fetch from a feed
            // select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
          />
        </div>
      </div>
    );
  }

  renderSidebar() {
    const { eventCategory } = this.state;
    const { calendarCate } = this.props.state;
    return (
      <div className='demo-app-sidebar'>
        <span>
          <h2>Aquatic AV Calendar</h2>
          {/* <Button
            color="primary"
            variant="outlined"
            startIcon={<HouseIcon />}
            component={Link}
            // to="/events"
          >
            Events Home
          </Button> */}
        </span>
        <OutsideClickHandler
          onOutsideClick={() => this.setState({ isFilter: false })}>
          <span className='filterMainWrapper'>
            <Button
              color='primary'
              variant='outlined'
              // startIcon={<FilterAltIcon />}
              // component={Link}
              // to="/events"
              onClick={() =>
                this.setState({
                  isFilter: !this.state.isFilter,
                })
              }>
              Filter
            </Button>

            {this.state.isFilter && (
              <div className='filterDropdown'>
                <div className='filterHeading'>
                  <span>Event Announcement</span>
                  <span onClick={() => this.onClearFilter()}>clear</span>
                </div>
                <ul className='filterList'>
                  {calendarCate &&
                    calendarCate.length > 0 &&
                    calendarCate.map((cate, i) => (
                      <li key={i}>
                        <span>
                          <CustomCheckbox
                            checkedId={"calendar-category" + i}
                            pressCheck={() => this.onPressCheckBox(cate)}
                            checkValue={this.checkEventCategory(cate.id)}
                          />
                          <i
                            style={{
                              backgroundColor: cate.color
                                ? LightenDarkenColor(cate.color, 140)
                                : LightenDarkenColor("#54b0e3", 140),
                              color: cate.color ? cate.color : "#54b0e3",
                            }}>
                            {cate.name}
                          </i>
                        </span>
                        <span>{cate.count}</span>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </span>
        </OutsideClickHandler>
      </div>
    );
  }

  // handleWeekendsToggle = () => {
  //   this.setState({
  //     weekendsVisible: !this.state.weekendsVisible
  //   });
  // };

  // handleDateSelect = selectInfo => {
  //   let title = prompt("Please enter a new title for your event");
  //   let calendarApi = selectInfo.view.calendar;

  //   calendarApi.unselect(); // clear date selection

  //   if (title) {
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: selectInfo.startStr,
  //       end: selectInfo.endStr,
  //       allDay: selectInfo.allDay
  //     });
  //   }
  // };

  handleEventClick = clickInfo => {
    const { history } = this.props;
    history.push("/events/category/" + clickInfo?.event?.urlSlug);
  };

  // handleEvents = events => {
  //   this.setState({
  //     currentEvents: events
  //   });
  // };
}

export default connect(
  state => ({
    state: state.pubEventReducer,
  }),
  dispatch => ({
    actions: bindActionCreators(pubEventActions, dispatch),
  })
)(withRouter(DemoApp));

function renderEventContent(eventInfo) {
  return (
    <>
      <b></b>
      <i>{`${eventInfo.timeText} ${eventInfo.event.title}`}</i>
    </>
  );
}

// function renderSidebarEvent(event) {
//   return (
//     <li key={event.id}>
//       <b>
//         {formatDate(event.start, {
//           year: "numeric",
//           month: "short",
//           day: "numeric"
//         })}
//       </b>
//       <i>{event.title}</i>
//     </li>
//   );
// }
