import React, { useEffect, useState } from 'react';

import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import DummyComp from "./DummyComp";
import ConversationTypesDetail from "./ConversationTypesDetail";
import { SelectInput } from "../../../../../components/common";
import { ThreeDots } from "../../../LeftNav/SvgIcons";
import OutsideClickHandler from "react-outside-click-handler";
import { Link ,useHistory , useParams} from "react-router-dom";
import { DeleteFilesOverlay } from '../../../../../components/common/Modal/AssetsModals';
import { useDispatch, useSelector } from 'react-redux';
import { conversationAction } from '../../../../../store/conversations/conversationAction';
import { toast } from 'react-toastify';
import { ArrowBackIos } from '@material-ui/icons';
import { Button } from '@material-ui/core';

function ConversationTypesOverview(props) {
  const {
    isSelectedDetialsFav
  } = useSelector(
    state => state.conversationReducer
);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id : paramId} = useParams();
  const [activeTabId] = useState("1");
  const [status, setStatus] = useState(1);
  const [isConvTypeNav, setIsConvTypeNav] = useState();
  const [statusClass, setStatusClass] = useState("newSoftwareHeaderSelectActive");
  const [isConversationSettingDelete , setIsConversationSettingDelete] = useState(false);

  const releaseStatus = [
    { id: 1, name: "Active" },
    { id: 0, name: "In Active" },
  ];
  useEffect(() => {
    switch (status) {
      case 1:
        setStatusClass("newSoftwareHeaderSelectActive");
        break;
      case 0:
        setStatusClass("newSoftwareHeaderSelectInactive");
        break;
    }
  }, [status]);

  const markConversationFav = (item)=>{
    dispatch(conversationAction.onMakeConversationFavourite([
      {
        campaignId: paramId,
        isFavourite: !isSelectedDetialsFav
      }
    ])).then((data)=>{
     if(data.responseMsg === "Data Updated Successfully"){
      dispatch(
        conversationAction.onChange(
          "isSelectedDetialsFav",
          data?.responseData[0].isFavourite
        )
      );
      toast.success("Conversation Type is successfully mark as Favourite/UnFavourite")
     }else{
      toast.error("Something went wrong!")
     }
    })
    setIsConvTypeNav(false)
  }

  const onArchivedConversation = () => {
    dispatch(conversationAction.onArchivedConversationType(paramId))
      .then((data) => {
        if (data && data.data && data.data.success) {
          toast.success("Conversation Type is successfully marked as Archived");
          history.push("/conversation-settings");
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        toast.error("An error occurred. Please try again later.");
      });
      setIsConvTypeNav(false)
  };
  const onDeleteConversation = ()=>{
    dispatch(conversationAction.onDeleteConversationType(paramId)).then((data)=>{
      if(data.responseCode === 1){
       toast.success("Conversation Type is Deleted Successfully.");
       history.push("/conversation-settings");
      }else{
       toast.error("Something went wrong!")
      }
     })
     .catch((error) => {
      console.error("Error occurred:", error);
      toast.error("An error occurred. Please try again later.");
    });
    setIsConversationSettingDelete(false);
  }

  return (
    <div className="conversationTypesWrapper conversationSetting">
      <div className="conversationSettingsHeader">
        <span className="settingsHeader">
          <h2 className='conversationPreviewHeading'>My First Conversation Type</h2>
          <Button
                color="primary"
                className='conversationPreviewBackBtn'
                startIcon={<ArrowBackIos style={{ fontSize: "14px" }} />}
                onClick={()=>{history.push("/conversation-settings")}}
              >
                Back to Conversation Types
          </Button>
        </span>
        <div className="statusConatiner">
          <div className={statusClass}>
            <SelectInput
              placeholder="Select"
              value={status}
              customMenuItems={releaseStatus}
              disablePlaceholderOption={true}
              removeSelectOption={true}
              handleChange={({ target }) => setStatus(target.value)}
            />
          </div>{" "}
          <OutsideClickHandler onOutsideClick={() => setIsConvTypeNav(false)}>
            <div className="conversationTypesNav">
              <span onClick={() => setIsConvTypeNav(!isConvTypeNav)}>               
                <ThreeDots />
              </span>
              {isConvTypeNav && (
                <div className="convTypesOptions">
                  <span onClick={()=> history.push(`/conversation-type/${paramId}/edit`)}>Edit</span>
                  <span onClick={()=>markConversationFav()}>{ isSelectedDetialsFav ? "Favorite" : "UnFavorite" }</span>
                  <span onClick={()=>onArchivedConversation()}>Archive</span>
                  <span onClick={()=>{
                    setIsConversationSettingDelete(true)
                    setIsConvTypeNav(false)
                  }}>Delete Conversation Type</span>
                </div>
              )}
            </div>{" "}
          </OutsideClickHandler>
        </div>
      </div>
      {isConversationSettingDelete ?
        <DeleteFilesOverlay
          heading={"Delete Conversation Setting"}
          openDeleteFilesOverlay={isConversationSettingDelete}
          setOpenDeleteFilesOverlay={(value)=>{setIsConversationSettingDelete(value)}}
          deleteAssetFiles={()=>{onDeleteConversation()}}
          overlayText1={
            "Are you sure you want to Delete Conversation Setting?"
          }
          overlayText2={""}
          buttonText={"Delete"}
        />
        :
        null
      }
      <div className="conversationTabsWrapper convSettingsTab ConvDetailsTab">
        <TopTabNavigator
          tabs={[
            {
              id: 1,
              title: `Details`,
              component: (
                <ConversationTypesDetail
                  isActive={activeTabId === "1"}
                  style={{ marginTop: "30px" }}
                />
              ),
            },
            {
              id: 2,
              title: `Activity`,
              component: <DummyComp isActive={activeTabId === "4"} />,
            },
            {
              id: 3,
              title: `Notes`,
              component: <DummyComp isActive={activeTabId === "5"} />,
            },
          ]}
          value={activeTabId}
          className={"con-settting"}
        />
      </div>
    </div>
  );
}

export default ConversationTypesOverview;
