import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { StarFill, StarOutline } from "../../../LeftNav/SvgIcons";
import {
  Pagination,
  ListViewTile,
  Spinner,
} from "../../../../../components/common";
import { AllBusinessContactsGridFilter } from ".";
import { AllCompaniesGrid } from "../Comapnies";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { RoutesObj } from "../../../../../routes";
import { toast } from "react-toastify";

const CompaniesDataView = () => {
  document.title = "OFish - Companies";

  const history = useHistory();
  const dispatch = useDispatch();

  const { isBCListView } = useSelector(state => state.endUserReducer);

  const [isListView, setIsListView] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [activeTabId, setActiveTabId] = useState("2");
  const [isFilterAppling, setIsFilterAppling] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterModel, setFilters] = useState({
    searchText: "",
    startDate: "",
    endDate: "",
    noOfContacts:"",
    lastLoginFrom:null,
    lastLoginTo:null,
    productCategories: [],
    productSubCategoryId: [],
    environmentId: [],
    productName: [],
    productSKU: [],
    countryId: [],
    stateId: [],
    cityId: [],
    address: [],
    firstName: [],
    lastName: [],
    email: [],
    lastLogin: null,
    deviceRegistered: [],
    rating: null,
    companyName: null,
    date: 0,
    groupIds: null,
    id: [],
    userStatus: [],
  });
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });

  const redirectToCompany = value => {
    history.push({
      pathname: RoutesObj.NewCompanyContacts.path,
      state: { company: value },
      search: `id=${value.companyId}`,
    });
  };

  const loadAllCompanies = useCallback(() => {
    const modal = {
      ...filterModel,
      email:
        filterModel.email?.length > 0
          ? filterModel.email.map(em => em.email)
          : [],
      firstName:
        filterModel.firstName?.length > 0
          ? filterModel.firstName.map(fn => fn.id)
          : [],
      lastName:
        filterModel.lastName?.length > 0
          ? filterModel.lastName.map(ln => ln.id)
          : [],
      id: filterModel.id?.length > 0 ? filterModel.id.map(ln => ln.id) : [],
      productCategories:
        filterModel.productCategories?.length > 0
          ? filterModel.productCategories.map(pc => pc.id)
          : [],
      productSubCategoryId:
        filterModel.productSubCategoryId?.length > 0
          ? filterModel.productSubCategoryId.map(pc => pc.id)
          : [],
      deviceRegistered:
        filterModel.deviceRegistered?.length > 0
          ? filterModel.deviceRegistered.map(dr => dr.id)
          : [],
      environmentId:
        filterModel.environmentId?.length > 0
          ? filterModel.environmentId.map(env => env)
          : [],
      productName:
        filterModel.productName?.length > 0
          ? filterModel.productName.map(pn => pn.id)
          : [],
      productSKU:
        filterModel.productSKU?.length > 0
          ? filterModel.productSKU.map(ps => ps.id)
          : [],
      countryId:
        filterModel.countryId?.length > 0
          ? filterModel.countryId.map(ct => ct.value)
          : [],
      stateId:
        filterModel.stateId?.length > 0
          ? filterModel.stateId.map(st => st.value)
          : [],
      cityId:
        filterModel.cityId?.length > 0
          ? filterModel.cityId.map(ct => ct.value)
          : [],
      address:
        filterModel.address?.length > 0
          ? filterModel.address.map(ct => ct.value)
          : [],
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    setLoading(true);
    dispatch(contactsActions.onGetComapnies(modal)).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          setPaginationResponse(data?.data?.pagination);
          setCustomerList(data.data.data);
          if (isFilterAppling) {
            setIsFilterApplied(true);
          }
          setIsFilterAppling(false);
        }
      }
      setLoading(false);
    });
  }, [
    filterModel,
    rowsPerPage,
    page,
    dispatch,
    order.orderByKey,
    order.orderVal,
  ]);

  useEffect(() => {
    loadAllCompanies();
  }, [loadAllCompanies]);

  useEffect(() => {
    setIsListView(isBCListView);
  }, [isBCListView]);

  const onMarkFavouriteCompanies = ({ selectedCustomers }) => {
    setLoading(true);
    dispatch(
      contactsActions.onMarkUnMarkFavouriteCompanies(selectedCustomers)
    ).then(data => {
      setLoading(false);
      if (!data?.success) toast.error(data?.message || "Something went wrong!");
      else loadAllCompanies();
    });
  };
  useEffect(() => {
    setPage(1);
}, [rowsPerPage])

  return (
    <div
      className='gridBody contentBody businessCustomerGrid'
      style={{ width: "100%" }}>
      {isLoading ? <Spinner /> : null}
      <AllBusinessContactsGridFilter
        IsFilterApplied={isFilterApplied}
        PaginationResponse={paginationResponse}
        AppliedFilters={filterModel}
        onApplyFilters={(filters, isFilterApplied) => {
          setFilters(filters);
          if (isFilterApplied) setIsFilterAppling(isFilterApplied);
          else {
            setIsFilterAppling(false);
            setIsFilterApplied(false);
          }
        }}
        ActiveTabId={activeTabId}
        setActiveTabId={setActiveTabId}
        isListView={isListView}
        onToggleListView={isLstView => setIsListView(isLstView)}
        hideFilters={"companyFilter"}
      />
      <h2 className='gridInnerHeading'>
        Companies
        <span className='gridTopPagination'>
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={[]}
            backIconButtonProps={{ style: { display: "none" } }}
            nextIconButtonProps={{ style: { display: "none" } }}
          />
        </span>
      </h2>

      {isListView ? (
        <AllCompaniesGrid
          setLoading={setLoading}
          listData={customerList}
          loadData={() => loadAllCompanies()}
          order={order}
          onOrderBy={setOrderBy}
          onMarkFavouriteCompanies={onMarkFavouriteCompanies}
        />
      ) : (
        <Grid container spacing={2}>
          {customerList.map((item, index) => {
            return (
              <Grid item md={3}>
                <ListViewTile
                  companyname={item.companyName}
                  contacts={
                    item?.totalUsers > 0
                      ? `${item?.totalUsers} Contact${
                          item?.totalUsers > 1 ? "s" : ""
                        }`
                      : "No Contact"
                  }
                  buttontxt={item.businessGroup}
                  backgroundcolor={item.backgroundcolor}
                  textcolor={item.textcolor}
                  rating={item?.isFavourite ? <StarFill /> : <StarOutline />}
                  onRatingClick={() => {}}
                  onClick={() => redirectToCompany(item)}
                  onMarkFavourite={() =>
                    onMarkFavouriteCompanies({
                      selectedCustomers: {
                        companyId: item.companyId,
                        isFavourite: !item?.isFavourite,
                      },
                    })
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Pagination
        recordCount={paginationResponse?.totalRecords}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export { CompaniesDataView };
