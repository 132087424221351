import React, { useEffect, useCallback, useState } from "react";
// import AnimatedTabs from "../../../../components/common/Tabs/AnimatedTabs";
import AssetManagement from "./AssetManagement";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";

export const FavoritesAssets = () => {
  const dispatch = useDispatch();
  const {
    type,
    search,
    time,
    isfavorite,
    parentId,
    orderByAlphabet,
    orderByRecentlyUpdated,
    orderByCreatedDate,
    orderByFileSize,
  } = useSelector((state) => state.AssetsReducer);
  const [isLoading, setLoading] = useState(false);
  const [parent, setParent] = useState(0);
  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    key: "Name",
    val: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(assetsActions.onChange("isfavorite", true));
    dispatch(assetsActions.onChange("selectedMediaAssets", []));
    dispatch(
      assetsActions.onGetAssetManagementList({
        parentId: parentId,
        type: type,
        search: search,
        time: time,
        isfavorite: true,
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
        orderByAlphabet: orderByAlphabet,
        orderByRecentlyUpdated: orderByRecentlyUpdated,
        orderByCreatedDate: orderByCreatedDate,
        orderByFileSize: orderByFileSize,
      })
    ).then((data) => {
      setLoading(false);
    });
  }, [
    dispatch,
    parentId,
    type,
    search,
    time,
    order.orderByKey,
    order.orderVal,
    page,
    rowsPerPage,
    orderByAlphabet,
    orderByRecentlyUpdated,
    orderByCreatedDate,
    orderByFileSize,
  ]);

  useEffect(() => {
    dispatch(assetsActions.onChange("isfavorite", true));
    loadData();
  }, [dispatch, loadData, page, rowsPerPage]);
  return (
    <div>
      <AssetManagement
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrderBy={setOrderBy}
        loadData={loadData}
        isLoading={isLoading}
        setLoading={setLoading}
        parent={parent}
        setParent={setParent}
      />
    </div>
  );
};
