import { pubEventTypes } from "./pubEventTypes";
import _ from "lodash";

const INITIAL_STATE = {
  errorMessage: "",
  pubEventsWithCategory: [],
  pubEventsCategory: [],
  eventDateFilter: [],
  selectedEventCategory: [],
  selectedPubEventDateFilter: [],
  allEvents: [],
  selectedClndrCat: [],
  calendarCate: []
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case pubEventTypes.PUB_EVENT_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value
      };

    case pubEventTypes.SELECTED_PUB_EVENT_CATEGORY: {
      const newState = { ...state };
      const eventCategory = action.payload;
      const catExist = newState.selectedEventCategory.find(cat => {
        return cat.id == eventCategory.id;
      });
      if (catExist && newState.selectedEventCategory.length > 0) {
        _.remove(newState.selectedEventCategory, cate => {
          return cate.id == eventCategory.id;
        });
      } else {
        newState.selectedEventCategory.push(eventCategory);
      }
      return newState;
    }

    case pubEventTypes.SELECTED_PUB_EVENT_FILTER: {
      const newState = { ...state };
      const eventCategory = action.payload;
      const relExist = newState.selectedPubEventDateFilter.find(cat => {
        return cat.id == eventCategory.id;
      });
      if (relExist && newState.selectedPubEventDateFilter.length > 0) {
        _.remove(newState.selectedPubEventDateFilter, cate => {
          return cate.id == eventCategory.id;
        });
      } else {
        newState.selectedPubEventDateFilter.push(eventCategory);
      }
      return newState;
    }

    case pubEventTypes.SELECTED_PUB_CLNDR_CATEGORY: {
      const newState = { ...state };
      const eventCategory = action.payload;
      const catExist = newState.selectedClndrCat.find(cat => {
        return cat.id == eventCategory.id;
      });
      if (catExist && newState.selectedClndrCat.length > 0) {
        _.remove(newState.selectedClndrCat, cate => {
          return cate.id == eventCategory.id;
        });
      } else {
        newState.selectedClndrCat.push(eventCategory);
      }
      return newState;
    }
    default:
      return state;
  }
};
