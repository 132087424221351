import React from "react";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

export default function GroupAvatar() {
  return (
    <AvatarGroup max={4}>
      <Avatar
        alt='Remy Sharp'
        src='https://images.pexels.com/photos/9988949/pexels-photo-9988949.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      />
      <Avatar
        alt='Travis Howard'
        src='https://images.pexels.com/photos/9789893/pexels-photo-9789893.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      />
      <Avatar
        alt='Cindy Baker'
        src='https://images.pexels.com/photos/10131170/pexels-photo-10131170.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      />
      <Avatar alt='Agnes Walker' src='/static/images/avatar/4.jpg' />
      <Avatar alt='Trevor Henderson' src='/static/images/avatar/5.jpg' />
    </AvatarGroup>
  );
}
