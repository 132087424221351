import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashboard from "../Dashbard";
import { useDispatch, useSelector } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import LaunchesGrid from "./LaunchesGrid";
import { Pagination } from "../../../../components/common";
import AddLaunchesPopup from "../../../../components/common/popUps/AddLaunchesPopup";
import Loader from "../../../../components/common/Loader";
import { useDebounce } from "../../../../utils/use-debounce";
import GridSearchFilter from "../../../../components/common/FilterLayout/GridFilter";

function PastLaunches(props) {
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [filters, setFilters] = useState({
    name:""
  });
  const { debouncedValue } = useDebounce(filters.name , 500)
  const [pastLaunches , setPastLaunches] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    key: "launch_plan",
    val: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(launchesActions.onAllSelectedLaunches([]));
    dispatch(
      launchesActions.onGetLaunchesList({
        launchPlan: debouncedValue,
        type:"past",
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.key,
        orderByVal: order.val,
      })
    ).then(data => {
      if (data?.success) {
        if (data.data?.data) {
        setPaginationResponse(data?.data?.pagination);
        setPastLaunches(data?.data?.data);
        setLoading(false);
        }
      }else{
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
        setPastLaunches([]); 
      }
      setLoading(false);
    });
  }, [dispatch, debouncedValue, page, rowsPerPage, order.key, order.val]);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, [dispatch, loadData, page, rowsPerPage]);

  return (
    <Dashboard>
      <div className='body-content'>
      {isLoading ? <Loader /> : null}
        <div className='contentHeading  launchBtn'>
          <h1>Past Launches </h1>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => history.push("/new-launch")}>
            New Launch
          </Button>
        </div>

        <div className='gridBody contentBody '>
          <GridSearchFilter
            isFilterAdded={false}
            setFilters={setFilters}
            filters={filters}
          />
          <LaunchesGrid
            launchesData={pastLaunches}
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadData()}
            order={order}
            onOrderBy={setOrderBy}
          />

          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          {isModal && (
            <AddLaunchesPopup
              openPopup={isModal}
              closePopup={() => setIsModal(false)}
              startLoading={() => setLoading(true)}
              endLoading={() => setLoading(false)}
              loadData={() => loadData()}
            />
          )}
        </div>
      </div>
    </Dashboard>
  );
}

export default PastLaunches;
