import _ from "lodash";
import { locationsTypes } from "./locationsTypes";
import subDays from "date-fns/subDays";

function checkByDays(value, days) {
  let byDays = "";
  if (days === 30) {
    byDays = subDays(new Date(), 30);
    return value.filter(x => byDays < new Date(x.uploadedAt));
  } else if (days === 90) {
    byDays = subDays(new Date(), 90);
    return value.filter(x => byDays < new Date(x.uploadedAt));
  } else if (days === 180) {
    byDays = subDays(new Date(), 180);
    return value.filter(x => byDays < new Date(x.uploadedAt));
  } else if (days === 365) {
    byDays = subDays(new Date(), 365);
    return value.filter(x => byDays < new Date(x.uploadedAt));
  } else {
    return value;
  }
}

function checkByDaysCollection(value, days) {
  let byDays = "";
  if (days === 30) {
    byDays = subDays(new Date(), 30);
    return value.filter(x => byDays < new Date(x.dateCreated));
  } else if (days === 90) {
    byDays = subDays(new Date(), 90);
    return value.filter(x => byDays < new Date(x.dateCreated));
  } else if (days === 180) {
    byDays = subDays(new Date(), 180);
    return value.filter(x => byDays < new Date(x.dateCreated));
  } else if (days === 365) {
    byDays = subDays(new Date(), 365);
    return value.filter(x => byDays < new Date(x.dateCreated));
  } else {
    return value;
  }
}

const INITIAL_STATE = {
  errorMessage: "",
  selectedLocations: [],
  gridData: [],
  showAllData: true,
  filterKey: "",
  fiterValue: "",
  pageSize: 20,
  pageNo: 1,
  nameInternal: "",
  namePublic: "",
  dateCreated: 0,
  orderByKey: "NameInternal",
  orderByVal: 0,
  locationId: 0,
  isDeleted: 0,
  locationNameInternal: "",
  locationNameTitle: "New Location",
  locationNamePublic: "",
  locationShortDescription: "",
  locationStreetAddress: "",
  locationCountryId: 0,
  locationCityId: 0,
  locationStateId: 0,
  locationPostalCode: "",
  paginationResponse: {
    currentCount: 0,
    totalRecords: 0,
  },
  locationCountry: "",
  locationCity: "",
  locationState: "",
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case locationsTypes.LOCATIONS_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    case locationsTypes.SELECTED_LOCATIONS: {
      const newState = { ...state };
      const gridData = action.payload;
      const gridDataExist = newState.selectedLocations.find(data => {
        return data.id === gridData.id;
      });

      if (gridDataExist && newState.selectedLocations.length > 0) {
        _.remove(newState.selectedLocations, data => {
          return data.id === gridData.id;
        });
      } else {
        newState.selectedLocations.push(gridData);
      }
      return newState;
    }
    case locationsTypes.ALL_SELECTED_LOCATIONS:{
      return {
        ...state,
        selectedLocations: action.payload,
      };
    }
    case locationsTypes.SELECTED_ALL_LOCATIONS: {
      const newState = { ...state };
      const allCollection = action.payload;
      const allCollectionExist = newState.selectedAllCollection.find(allCol => {
        return allCol.id === allCollection.id;
      });

      if (allCollectionExist && newState.selectedAllCollection.length > 0) {
        _.remove(newState.selectedAllCollection, allCol => {
          return allCol.id === allCollection.id;
        });
      } else {
        newState.selectedAllCollection.push(allCollection);
      }
      return newState;
    }
    case locationsTypes.ON_SELECT_FILETYPE: {
      const newState = { ...state };
      const colCategory = action.payload;
      const colCategoryExist = newState.selectedFileType.find(colCat => {
        return colCat === colCategory;
      });

      if (colCategoryExist && newState.selectedFileType.length > 0) {
        _.remove(newState.selectedFileType, colCat => {
          return colCat === colCategory;
        });
      } else {
        newState.selectedFileType.push(colCategory);
      }
      return newState;
    }
    case locationsTypes.ON_PRESS_FILTER: {
      const newState = { ...state };
      let filterArray = [];
      const searchData = action.payload;
      if (searchData.searchText !== "") {
        let byName = [];
        byName = _.filter(newState.gridData, o => {
          if (o.name) {
            return o.name
              .toLowerCase()
              .includes(searchData.searchText.toLowerCase());
          }
        });
        if (searchData.dayFilter !== "") {
          byName = checkByDays(byName, searchData.dayFilter);
        }
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, x => {
            const aa = byName.filter(y => y.type === x);
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byName;
        }
      } else if (searchData.dayFilter !== "") {
        let byMonth = [];
        byMonth = checkByDays(newState.gridData, searchData.dayFilter);
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, x => {
            const aa = byMonth.filter(y => y.type === x);
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byMonth;
        }
      } else {
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, x => {
            const aa = newState.gridData.filter(y => y.type === x);
            filterArray = filterArray.concat(aa);
          });
        }
      }
      newState.gridData = filterArray;
      return newState;
    }
    case locationsTypes.ON_SELECT_CATTYPE: {
      const newState = { ...state };
      const colCategory = action.payload;
      const colCategoryExist = newState.selectedCatType.find(colCat => {
        return colCat === colCategory;
      });

      if (colCategoryExist && newState.selectedCatType.length > 0) {
        _.remove(newState.selectedCatType, colCat => {
          return colCat === colCategory;
        });
      } else {
        newState.selectedCatType.push(colCategory);
      }
      return newState;
    }
    case locationsTypes.ON_PRESS_FILTER_CAT: {
      const newState = { ...state };
      let filterArray = [];
      const searchData = action.payload;
      if (searchData.searchText !== "") {
        let byName = [];
        byName = _.filter(newState.copyAllCollections, o => {
          if (o.title) {
            return o.title
              .toLowerCase()
              .includes(searchData.searchText.toLowerCase());
          }
        });
        if (searchData.dayFilter !== "") {
          byName = checkByDaysCollection(byName, searchData.dayFilter);
        }
        if (newState.selectedCatType.length > 0) {
          _.forEach(newState.selectedCatType, x => {
            const aa = byName.filter(y => y.category && y.category.name === x);
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byName;
        }
      } else if (searchData.dayFilter !== "") {
        let byMonth = [];
        byMonth = checkByDaysCollection(
          newState.copyAllCollections,
          searchData.dayFilter
        );
        if (newState.selectedCatType.length > 0) {
          _.forEach(newState.selectedCatType, x => {
            const aa = byMonth.filter(y => y.category && y.category.name === x);
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byMonth;
        }
      } else {
        if (newState.selectedCatType.length > 0) {
          _.forEach(newState.selectedCatType, x => {
            const aa = newState.copyAllCollections.filter(
              y => y.category && y.category.name === x
            );
            filterArray = filterArray.concat(aa);
          });
        }
      }
      newState.allCollections = filterArray;
      return newState;
    }
    default:
      return state;
  }
};
