import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import InputField from "../../../../components/common/InputField";
import { downarrow } from "../../../../resources/images/index";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { RoutesObj } from "../../../../routes";
import {
  AddressAutoComplete,
  PopupDialog,
} from "../../../../components/common";
import Loader from "../../../../components/common/Loader";
import Grid from "@material-ui/core/Grid";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  resetbtn: {
    position: "absolute",
    top: "41px",
    right: "-54px",
    fontSize: "14px",
    color: "#3699FF",
    textTransform: "capitalize",
    zIndex: "10",
  },
  gridWrapper: {
    position: "relative",
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
  },
  accordianBody: {
    padding: "15px 50px !important",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "transparent !important",
    //   margin: '12px 0'
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

export default function AdminUserInformation() {
  const history = useHistory();
  let isDisable = [];
  const [expanded1, setExpanded] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [countryId, setCountryId] = useState("");
  const [cityId, setCityId] = useState("");
  const [stateId, setStateId] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [reSetPassword, setReSetPassword] = useState("");
  const [reSetCPassword, setReSetCPassword] = useState("");
  const [editUserId, setEditUserId] = useState(null);
  const [isEditAble, setIsEditAble] = useState([]);
  const [add, setAdd] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== password) {
        return false;
      } else {
        return true;
      }
    });
  }, [password]);

  useEffect(() => {
    ValidatorForm.addValidationRule("isResetPasswordMatch", value => {
      if (value !== reSetPassword) {
        return false;
      } else {
        return true;
      }
    });
  }, [reSetPassword]);

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (selectedUser?.id) {
      setLoading(true);
      dispatch(endUserActions.onGetAdminById(selectedUser.id)).then(data => {
        setLoading(false);
        if (data?.success) {
          setFirstName(data.data?.firstName);
          setLastName(data.data?.lastName);
          setAddress(data.data?.address);
          setEmail(data.data?.email);
          setPhoneNumber(data.data?.phoneNumber);
          setCityId(data.data?.cityId);
          setCountryId(data.data?.countryId);
          setStateId(data.data?.stateId);
          setZipCode(data.data?.zipCode);
          setEditUserId(selectedUser.id);
        } else {
          toast.error(data?.message);
        }
      });
    }
  }, [location]);

  const addAdminInformation = () => {
    const modal = {
      email: email,
      password: password,
      confirmEmail: email,
      confirmPassword: password,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber.toString(),
      address: address,
      countryName: countryId,
      cityName: cityId,
      stateName: stateId,
      zipCode: zipCode,
      id: editUserId,
      userType: 1,
    };
    if (editUserId) {
      setLoading(true);
      dispatch(endUserActions.onUpdateUserInfo(modal)).then(data => {
        if (data && data.success) {
          toast.success("Admin updated successfully.");
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
        setLoading(false);
      });
    } else {
      setLoading(true);
      dispatch(contactsActions.onAddCustomer(modal)).then(data => {
        if (data && data.success) {
          setEditUserId(data.data);
          toast.success("Admin added successfully.");
          history.push({
            pathname: RoutesObj.AdminOverview.path,
            userId: data.data,
            search: `id=${data.data}`,
          });
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
        setLoading(false);
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    addAdminInformation();
  };

  const resetPasswordHandle = e => {
    e.preventDefault();
    resetPasswordClick();
  };

  const resetPasswordClick = () => {
    const passwordPayload = {
      email: email,
      password: reSetPassword,
      confirmPassword: reSetCPassword,
    };
    setLoading(true);
    dispatch(endUserActions.onResetPassword(passwordPayload)).then(res => {
      setLoading(false);
      if (res?.success) {
        onClose();
        toast.success("Password reset successful!");
      } else {
        toast.error(res?.message);
      }
    });
  };

  const onClose = () => {
    setReSetCPassword("");
    setReSetPassword("");
    setIsPasswordReset(false);
  };

  return (
    <div className=''>
      {isLoading ? <Loader /> : null}
      <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
        <div className='contactWrapper contentBody'>
          <span className='addCompanyStar '>
            <span className='adminUserSaveBtn'>
              <Button color='primary' variant='contained' type='submit'>
                Save User
              </Button>
            </span>
          </span>
          <Accordion
            square
            expanded={expanded1}
            onChange={() => setExpanded(!expanded1)}>
            <AccordionSummary
              aria-controls='panel2d-content'
              id='panel2d-header'
              className='businessSaveButton'>
              <Typography className={classes.mainHeading}>
                <img src={downarrow} alt='' className={classes.headingIcon} />{" "}
                Admin User Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianBody}>
              <div className='fr-view'>
                <div className='formMainWrapper'>
                  <div className='envFormFields'>
                    <span>
                      <InputField
                        placeholder='Enter a name'
                        inputlabel='First name'
                        className={classes.gridWrapper}
                        id='firstname'
                        name='firstname'
                        value={firstName}
                        onChange={v => setFirstName(v.target.value)}
                        validators={[
                          "required",
                          "matchRegexp:^[A-Za-z']+$",
                          "matchRegexp:^.{0,50}$",
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Special characters and space are not allowed",
                          "Maximum length is 50 characters",
                        ]}
                      />

                      <InputField
                        placeholder='Last name'
                        inputlabel='Last name'
                        className={classes.gridWrapper}
                        id='lastname'
                        name='lastname'
                        value={lastName}
                        onChange={v => setLastName(v.target.value)}
                        validators={[
                          "required",
                          "matchRegexp:^[A-Za-z']+$",
                          "matchRegexp:^.{0,50}$",
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Special characters and space are not allowed",
                          "Maximum length is 50 characters",
                        ]}
                      />

                      <InputField
                        placeholder='Email Address'
                        inputlabel='Email Address'
                        className={classes.gridWrapper}
                        id='email'
                        name='email'
                        value={email}
                        onChange={v => setEmail(v.target.value)}
                        validators={[
                          "required",
                          "isEmail",
                          "matchRegexp:^.{0,50}$",
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Email is not valid",
                          "Maximum length is 50 characters",
                        ]}
                      />
                    </span>
                  </div>
                  <div className={classes.gridWrapper}>
                    <AddressAutoComplete
                      address={address}
                      autoComId={"popupEnvLocInput"}
                      onChange={e => {
                        setAddress(e.target.value);
                      }}
                      setPlace={p => {
                        isDisable = [];
                        setCityId("");
                        setStateId("");
                        setCountryId("");
                        setZipCode("");
                        const val =
                          document.getElementById("popupEnvLocInput")?.value;
                        p?.address_components?.forEach(x => {
                          if (x?.types?.includes("route")) {
                            setAddress(x?.long_name);
                          }
                          if (x?.types?.includes("locality")) {
                            setCityId(x?.long_name);
                            isDisable.push("city");
                          }
                          if (
                            x?.types?.includes("administrative_area_level_1")
                          ) {
                            setStateId(x?.long_name);
                            isDisable.push("state");
                          }
                          if (x?.types?.includes("country")) {
                            setCountryId(x?.long_name);
                            isDisable.push("country");
                          }
                          if (x?.types?.includes("postal_code")) {
                            setZipCode(x?.long_name);
                            isDisable.push("postalCode");
                          }
                        });
                        setIsEditAble(isDisable);
                      }}
                    />
                  </div>
                  <InputField
                    placeholder='phone number'
                    inputlabel={"Phone Number"}
                    className={[classes.gridWrapper.phonenumber]}
                    id='phonenumber'
                    name='phonenumber'
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    validators={[
                      "required",
                      "matchRegexp:^[0-9]*$",
                      "matchRegexp:^.{0,20}$",
                    ]}
                    errorMessages={[
                      "This field is required",
                      "Not valid number",
                      "Maximum length is 20 characters",
                    ]}
                  />

                  <InputField
                    placeholder='Enter City'
                    inputlabel='City'
                    validators={["required"]}
                    className={[classes.gridWrapper.phonenumber]}
                    errorMessages={["Please enter city"]}
                    value={cityId}
                    onChange={e => {
                      setCityId(e.target.value);
                    }}
                    // disabled={isEditAble.includes("city") ? true : false}
                  />
                  <InputField
                    placeholder='Zip code or postal code'
                    inputlabel='Zip code or postal code'
                    className={[classes.gridWrapper.phonenumber]}
                    id='zipCode'
                    name='zipCode'
                    value={zipCode}
                    onChange={e => {
                      setZipCode(e.target.value);
                    }}
                    validators={[
                      "matchRegexp:^(?:[A-Z0-9a-z ]+([-_ ]?[A-Z0-9a-z]+)*)?$",
                      "matchRegexp:^.{0,15}$",
                    ]}
                    errorMessages={[
                      "Special characters and multiple spaces are not allowed",
                      "Maximum length is 15 characters",
                    ]}
                    // disabled={isEditAble.includes("postalCode") ? true : false}
                  />
                  <InputField
                    placeholder='Enter State'
                    inputlabel='State'
                    validators={["required"]}
                    className={[classes.gridWrapper.phonenumber]}
                    errorMessages={["Please enter State"]}
                    value={stateId}
                    onChange={e => {
                      setStateId(e.target.value);
                    }}
                    // disabled={isEditAble.includes("state") ? true : false}
                  />

                  <InputField
                    placeholder='Enter Country or region'
                    inputlabel='Country or region'
                    validators={["required"]}
                    className={[classes.gridWrapper.phonenumber]}
                    errorMessages={["Please enter Country or region"]}
                    value={countryId}
                    onChange={e => {
                      setCountryId(e.target.value);
                    }}
                    // disabled={isEditAble.includes("country") ? true : false}
                  />
                  {editUserId === null && (
                    <InputField
                      placeholder='Password'
                      inputlabel='Password'
                      type='password'
                      className={[classes.gridWrapper.phonenumber]}
                      id='password'
                      name='password'
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      validators={[
                        "required",
                        "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                        "matchRegexp:^.{0,50}$",
                      ]}
                      errorMessages={[
                        "This field is required",
                        "Your password must contain at least 1 uppercase, lowercase, number and special character",
                        "Maximum character limit is 50",
                      ]}
                    />
                  )}
                  {editUserId === null && (
                    <InputField
                      placeholder='Verify Password'
                      inputlabel='Verify Password'
                      type='password'
                      className={[classes.gridWrapper.phonenumber]}
                      id='verifypassword'
                      name='verifypassword'
                      value={repeatPassword}
                      onChange={e => {
                        setRepeatPassword(e.target.value);
                      }}
                      validators={[
                        "isPasswordMatch",
                        "required",
                        "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                        "matchRegexp:^.{0,50}$",
                      ]}
                      errorMessages={[
                        "password mismatch",
                        "This field is required",
                        "Your password must contain at least 1 uppercase, lowercase, number and special character",
                        "Maximum character limit is 50",
                      ]}
                    />
                  )}
                  <span className='adminResetPassword'>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => setIsPasswordReset(true)}>
                      Reset Password
                    </Button>
                  </span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </ValidatorForm>
      {isPasswordReset && (
        <PopupDialog
          visible={isPasswordReset}
          onClose={onClose}
          maxWidth='800px'>
          <div className='passwordResetWrapper adminPasswordResetWrapper'>
            <ValidatorForm onSubmit={resetPasswordHandle}>
              <div className='passwordResetHeader'>
                <span className='innerHeading'>Admin User Reset Password</span>
                <Close
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => onClose()}
                />
              </div>
              <div className='resetPasswordInstructions'>
                <p>Are you sure you want to reset admin user's password?</p>
              </div>
              <Grid container spacing={2} className='resetPasswordForm'>
                <Grid item xs={4} className={classes.gridWrapper}>
                  <InputField
                    placeholder='Password'
                    inputlabel='Password'
                    className={classes.gridWrapper}
                    value={reSetPassword}
                    onChange={v => setReSetPassword(v.target.value)}
                    validators={[
                      "required",
                      "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                      "matchRegexp:^.{0,50}$",
                    ]}
                    errorMessages={[
                      "This field is required",
                      "Your password must contain at least 1 uppercase, lowercase, number and special character",
                      "Maximum character limit is 50",
                    ]}
                  />
                </Grid>
                <Grid item xs={4} className={classes.gridWrapper}>
                  <InputField
                    placeholder='Confirm Password'
                    inputlabel='Confirm Password'
                    className={classes.gridWrapper}
                    value={reSetCPassword}
                    onChange={v => setReSetCPassword(v.target.value)}
                    validators={[
                      "isResetPasswordMatch",
                      "required",
                      "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                      "matchRegexp:^.{0,50}$",
                    ]}
                    errorMessages={[
                      "password mismatch",
                      "This field is required",
                      "Your password must contain at least 1 uppercase, lowercase, number and special character",
                      "Maximum character limit is 50",
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={(classes.gridWrapper, "resetPasswordBtn")}>
                  <Button color='primary' variant='contained' type='submit'>
                    Reset Password
                  </Button>
                </Grid>
              </Grid>

              <div className='gap' />
            </ValidatorForm>
          </div>
        </PopupDialog>
      )}
    </div>
  );
}
