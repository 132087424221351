import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ArrowDropDown } from "@material-ui/icons";
import OutsideClickHandler from "react-outside-click-handler";
import classnames from "classnames";
import { LightenDarkenColor } from "lighten-darken-color";

const ConversationStatesDropDown = ({ value, setStatusId }) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [isLaunched, setIsLaunched] = useState(
    value === 1
      ? "Open"
      : value === 2
      ? "Draft"
      : value === 3
      ? "Closed"
      : "Closed & Archived"
  );
  const color =
    value === 1
      ? "#6FCF97"
      : value === 2
      ? "#E0E0E0"
      : value === 3
      ? "#4F4F4F"
      : value === 4
      ? "#4F4F4F"
      : "#E0E0E0";
  return (
    <OutsideClickHandler onOutsideClick={() => setIsDropdown(false)}>
      <span className="draftDropdown">
        <Button
          color="primary"
          variant="contained"
          onClick={() => setIsDropdown(!isDropdown)}
          className={classnames("")}
          // style={{
          //   background: `${LightenDarkenColor(color, 140)}!important`,
          //   color: `${color}`,
          // }}
          type="submit">
          {value === 1
            ? "Open"
            : value === 2
            ? "Draft"
            : value === 3
            ? "Closed"
            : "Closed & Archived"}
          <ArrowDropDown />
        </Button>

        {isDropdown && (
          <span className="draftInnerDropdown">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLaunched("Open");
                setIsDropdown(!isDropdown);
                setStatusId(1);
              }}>
              Open
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLaunched("Draft");
                setIsDropdown(!isDropdown);
                setStatusId(2);
              }}>
              Draft
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLaunched("Closed");
                setIsDropdown(!isDropdown);
                setStatusId(3);
              }}>
              Closed
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLaunched("Closed & Archived");
                setIsDropdown(!isDropdown);
                setStatusId(4);
              }}>
              Closed & Archived
            </Button>
          </span>
        )}
      </span>
    </OutsideClickHandler>
  );
};

export default ConversationStatesDropDown;
