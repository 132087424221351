import React from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import sampleImg from "../../../../../../resources/images/sampleImg.png";

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    width: "100%",
    overflowX: "auto",
    borderRadius: 0,
  },
  tableStyle: {
    minWidth: 500,
    borderRadius: 0,
  },
  gridHeaderRow: {
    backgroundColor: props => props?.mainRowTitlesBackColor || "#49B8EF",
    borderRadius: "none",
  },
  bottomBtnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
    marginTop: 20,
  },
}));

const PriceListQuickPreview = ({ plData, selectedColumns = [] }) => {
  const previewPriceProps = {
    mainRowTitlesBackColor: plData?.mainRowTitlesBackColor || null,
    mainRowTitlesTextColor: plData?.mainRowTitlesTextColor || null,
    parentCategoryBackColor: plData?.parentCategoryBackColor || null,
    parentCategoryTextColor: plData?.parentCategoryTextColor || null,
  };
  const classes = useStyles(previewPriceProps);

  return (
    <Paper className={classes.gridWrapper} style={{ marginTop: 16 }}>
      <Table className={classes.tableStyle}>
        <TableHead className={classes.gridHeader}>
          {selectedColumns?.length > 0 ? (
            <TableRow className={classes.gridHeaderRow}>
              {selectedColumns.map(sc => (
                <TableCell>
                  <span
                    style={{
                      color: previewPriceProps?.mainRowTitlesTextColor,
                    }}>
                    {sc?.value}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ) : (
            <TableRow className={classes.gridHeaderRow}>
              <TableCell>
                <span
                  style={{
                    color: previewPriceProps?.mainRowTitlesTextColor,
                  }}>
                  Product SKU
                </span>
              </TableCell>
              <TableCell>
                <span
                  style={{
                    color: previewPriceProps?.mainRowTitlesTextColor,
                  }}>
                  Product Name
                </span>
              </TableCell>
              <TableCell>
                <span
                  style={{
                    color: previewPriceProps?.mainRowTitlesTextColor,
                  }}>
                  Product Image
                </span>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell
              colSpan={selectedColumns?.length > 0 ? selectedColumns.length : 3}
              style={{ padding: 0 }}>
              <div
                style={{
                  backgroundColor:
                    previewPriceProps.parentCategoryBackColor || "#474D70",
                  width: "100%",
                  padding: 10,
                  fontSize: 20,
                  color: previewPriceProps.parentCategoryTextColor || "#ffffff",
                }}>
                Main Category
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={""} className='gridBodyRow'>
            {selectedColumns?.length > 0 ? (
              selectedColumns.map(sc => {
                return (
                  <TableCell className={classes.gridHeaderInner}>
                    {sc?.value?.includes("Product Name") ? (
                      "Prod Name X"
                    ) : sc?.value?.includes("Description") ? (
                      "Product Desc..."
                    ) : sc?.value?.includes("Product Image") ? (
                      <img src={sampleImg} width={36} height={36} alt='img' />
                    ) : sc?.value?.includes("Product SKU") ? (
                      "SKU-XXX"
                    ) : sc?.value?.includes("Price") ? (
                      `$${
                        Math.round(
                          (Math.random() * (99.99 - 10.0) + 10.0) * 100
                        ) / 100
                      }`
                    ) : (
                      "-"
                    )}
                  </TableCell>
                );
              })
            ) : (
              <>
                <TableCell className={classes.gridHeaderInner}>
                  SKU111
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  Product Name
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <img src={sampleImg} width={36} height={36} alt='img' />
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export { PriceListQuickPreview };
