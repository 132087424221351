import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { downarrow } from "../../../../resources/images/index";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ReactComponent as SearchIcon } from "../../../../resources/images/Search.svg";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddContactDataModal from "./AddContactData";
import { EditFieldPopup } from "./Modals/EditFieldPopup";
import { EditListingPopup } from "./Modals/EditListingPopup";
import { EditFieldWithRadioPopup } from "./Modals/EditFieldWithRadioPopup";
import { EditPasswordFieldPopup } from "./Modals/EditPasswordFieldPopup";
import { EditTermsAndConditionFieldPopup } from "./Modals/EditTermsAndConditionFieldPopup";
import { EditListingWithCheckboxPopup } from "./Modals/EditListingWithCheckboxPopup";
import OFishModulesModal from "./OFishModulesModal";
import OFishPortalModal from "./OFishPortalModal";
import { contactsTypesActions } from "../../../../store/contactTypes/contactTypesActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/common/Loader";
import { useHistory } from "react-router-dom";
import { ContactDataPopup } from "./Modals/ContactDataPopup";
import { EditFieldWithRadioPopupPassword } from "./Modals/EditFieldWithRadioPopupPassword";
import CloseIcon from "@material-ui/icons/Close";

const Accordion = withStyles({
  root: {
    width: "100%",
    padding: "16px 19px",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "&:not(:first-child)": {
      marginTop: "35px",
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      boxShadow: "0px 0px 2px 0px #70808f",
    },
    "&.MuiGrid-container": {
      width: "100%",
      display: "inline",
    },
    "&.MuiTextField-root": {
      width: "100% !important",
    },
  },
  expanded: {},
})(MuiAccordion);
const InputField = withStyles({
  root: {
    "&.MuiOutlinedInput-input": {
      height: "4px !important",
    },
    "&.MuiTextField-root": {
      width: "90% !important",
      background: "#FFFFFF",
      borderRadius: " 6px",
    },
  },
})(TextField);

export default function ContactTypesBasics() {
  const [contactAreas, setContactAreas] = useState([]);
  const [oFishRoles, setOfishRoles] = useState([]);
  const [dataFieldById, setDataFieldById] = useState({});

  const { _ID, dataModule, dataConnection, dataField } = useSelector(
    state => state.pubContactType
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contactsTypesActions.onGetAllContactAreas()).then(data => {
      if (data?.data?.success) {
        setContactAreas(data?.data?.data);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(contactsTypesActions.onGetAllOfishRoles()).then(data => {
      if (data?.data?.success) {
        setOfishRoles(data?.data?.data);
      }
    });
  }, []);

  const handleOpenModulesModal = fieldId => {
    setSelectedId(fieldId);
    handleData(fieldId);
  };
  const handleData = id => {
    if (id && id > 0) {
      dispatch(contactsTypesActions.onGetAllContactDataFieldsById(id)).then(
        data => {
          if (data?.data?.success) {
            setFieldSetting(data?.data?.data);
            if (id === 4) {
              setOpenModulesModal1(true);
            } else {
              setOpenModulesModal(true);
            }
          }
        }
      );
    }
  };

  const getContactTypesModulesFiledsSettingsById = id => {
    dispatch(
      contactsTypesActions.getContactTypesModulesFiledsSettingsById(id)
    ).then(data => {
      if (data?.data?.success) {
        setSelectedId(id);
        setContactModulesById(data?.data?.data);
        setIsOpen(true);
      }
    });
  };

  const [expandedContact, setExpandedContactType] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openContactData, setOpenContactData] = React.useState(false);
  const [openModulesModal, setOpenModulesModal] = React.useState(false);
  const [openModulesModal1, setOpenModulesModal1] = React.useState(false);
  const [openPortalModal, setOpenPortalModal] = React.useState(false);
  const [fieldSetting, setFieldSetting] = useState();
  const [isLoading, setLoading] = useState(false);
  const [contactModulesById, setContactModulesById] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const handleOpenPortalModal = () => setOpenPortalModal(true);

  const handleOpen = () => setOpen(true);
  const handleContactData = () => setOpenContactData(true);
  const [openEditListPopup, setOpenEditListPopup] = React.useState();
  const [openSelectRoleModal, setOpenSelectRoleModal] = React.useState();

  const [openEditFieldPopup, setOpenEditFieldPopup] = React.useState();
  const [openEditPasswordFieldPopup, setOpenEditPasswordFieldPopup] =
    React.useState();
  const [openEditFieldWithRadioPopup, setOpenEditFieldWithRadioPopup] =
    React.useState(false);
  const [
    openEditTermsAndConditionFieldPopup,
    setOpenEditTermsAndConditionFieldPopup,
  ] = React.useState();
  const [
    openEditListingWithCheckboxPopup,
    setOpenEditListingWithCheckboxPopup,
  ] = React.useState();
  const history = useHistory();

  const [dataFieldsId, setDataFieldsId] = useState();
  const [contactProps, setContactProps] = useState({
    id: 0,
    nameSingularInternal: "",
    nameSingularPublic: "",
    namePluralInternal: "",
    namePluralPublic: "",
    status: 0,
    contactAreaId: 1,
    oFishRoleId: 1,
    contactDataFieldInfo: [],
    contactTypeModuleInfo: [],
    contactTypeConnectionsInfos: [],
  });

  useEffect(() => {
    dispatch(contactsTypesActions.onChange("dataModule", []));
    const contactId = window.location.href.split("?id=")[1];
    if (contactId) {
      getContactTypeById(contactId);
    } else {
      onGetAllContactDataFields(2);
    }
  }, []);

  const onGetAllContactDataFields = id => {
    dispatch(contactsTypesActions.onChange("dataModule", []));
    dispatch(contactsTypesActions.onGetAllContactDataFields(id)).then(data => {
      if (data?.data?.success) {
        const dataFields = [];
        data?.data?.data?.forEach(x => {
          if (x.isRequired === 1) {
            if (x.id === 1) {
              const dm = {
                fieldId: x.id,
                fieldValue: x.fieldName,
                contactDataFieldSettingInfo: [
                  { dataFieldSettingId: 1, additionalData: x.fieldName },
                  { dataFieldSettingId: 2, additionalData: "Required" },
                ],
              };
              dataFields.push(dm);
            } else if (x.id === 2) {
              const dm = {
                fieldId: x.id,
                fieldValue: x.fieldName,
                contactDataFieldSettingInfo: [
                  { dataFieldSettingId: 4, additionalData: x.fieldName },
                  { dataFieldSettingId: 5, additionalData: "Required" },
                ],
              };
              dataFields.push(dm);
            } else if (x.id === 3) {
              const dm = {
                fieldId: x.id,
                fieldValue: x.fieldName,
                contactDataFieldSettingInfo: [
                  { dataFieldSettingId: 7, additionalData: x.fieldName },
                  { dataFieldSettingId: 8, additionalData: "Required" },
                ],
              };
              dataFields.push(dm);
            } else if (x.id === 15) {
              const dm = {
                fieldId: x.id,
                fieldValue: x.fieldName,
                contactDataFieldSettingInfo: [
                  { dataFieldSettingId: 37, additionalData: "Required" },
                ],
              };
              dataFields.push(dm);
            } else if (x.id === 11) {
              const dm = {
                fieldId: x.id,
                fieldValue: x.fieldName,
                contactDataFieldSettingInfo: [
                  { dataFieldSettingId: 11, additionalData: x.fieldName },
                ],
              };
              dataFields.push(dm);
            } else {
              const dm = {
                fieldId: x.id,
                fieldValue: x.fieldName,
                contactDataFieldSettingInfo: [
                  { dataFieldSettingId: 11, additionalData: "Required" },
                ],
              };
              dataFields.push(dm);
            }
          }
        });
        if (dataFields?.length > 0) {
          dispatch(contactsTypesActions.onChange("dataField", dataFields));
        }
      }
    });
  };

  const addUpdateContactType = () => {
    if (
      contactProps.nameSingularInternal !== "" &&
      contactProps.nameSingularPublic !== "" &&
      contactProps.namePluralInternal !== "" &&
      contactProps.namePluralInternal !== "" &&
      contactProps.status !== ""
    ) {
      setLoading(true);
      const contactTypeDataInfo = [];

      dataField.forEach(x => {
        const dm = {
          fieldId: x.fieldId,
          fieldValue: x.fieldValue,
          contactDataFieldSettingInfo: x?.contactDataFieldSettingInfo?.map(
            y => {
              return {
                dataFieldSettingId: y?.dataFieldSettingId,
                additionalData: y?.additionalData,
              };
            }
          ),
        };
        contactTypeDataInfo.push(dm);
      });
      contactProps.contactTypeModuleInfo = dataModule;
      contactProps.contactTypeConnectionsInfos = dataConnection;
      contactProps.contactDataFieldInfo = contactTypeDataInfo;
      dispatch(contactsTypesActions.onAddUpdateContactType(contactProps)).then(
        data => {
          setLoading(false);
          if (data?.data?.success) {
            toast.success("Contact type saved successfully");
            history.push("/contact-types");
          } else {
            toast.warn(data?.data?.message);
          }
        }
      );
    } else {
      toast.warn("Please add required field");
    }
  };

  const getContactTypeById = id => {
    setLoading(true);
    dispatch(contactsTypesActions.onGetContactTypesById(id)).then(data => {
      setLoading(false);
      if (data?.data?.success) {
        var rec = data?.data?.data[0];
        setContactProps({
          id: rec.id,
          nameSingularInternal: rec.nameSingularInternal,
          nameSingularPublic: rec.nameSingularPublic,
          namePluralInternal: rec.namePluralInternal,
          namePluralPublic: rec.namePluralPublic,
          status: rec.status,
          contactAreaId: rec.contactAreaId,
          oFishRoleId: rec.oFishRoleId,
          contactDataFieldInfo: rec.contactFieldsData,
          contactTypeModuleInfo: rec.contactModulesFieldsData,
          contactTypeConnectionsInfos: rec.contactConnectionsData,
        });
        const dataFields = [];
        rec?.contactFieldsData?.forEach(x => {
          const dm = {
            fieldId: x.fieldId,
            fieldValue: x.fieldName,
            contactDataFieldSettingInfo: x?.fieldSettings?.map(y => {
              return {
                dataFieldSettingId: y?.fieldSettingId,
                additionalData: y?.additionalData,
              };
            }),
          };
          dataFields.push(dm);
        });
        if (dataFields?.length > 0) {
          dispatch(contactsTypesActions.onChange("dataField", dataFields));
        }

        const dataModule = [];
        rec?.contactModulesFieldsData?.forEach(x => {
          const dm = {
            fieldId: x.moduleId,
            fieldValue: x.moduleName,
            contactTypeModuleFieldSettingInfo: x?.moduleFieldSettings?.map(
              y => {
                return {
                  moduleFieldSettingId: y?.moduleSettingId,
                  additionalData: y?.additionalData,
                };
              }
            ),
          };
          dataModule.push(dm);
        });
        if (dataModule?.length > 0) {
          dispatch(contactsTypesActions.onChange("dataModule", dataModule));
        }
        const dataConnection = [];
        rec?.contactConnectionsData?.forEach(x => {
          const dm = {
            fieldId: x.connectionsFieldId,
            fieldValue: x.connectionsFieldName,
          };
          dataConnection.push(dm);
        });
        dispatch(
          contactsTypesActions.onChange("dataConnection", dataConnection)
        );
      }
    });
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <FormControl
        sx={{
          m: 1,
          maxWidth: "100%",
        }}
        style={{ position: "absolute", top: "126px", right: "-800px" }}
        className=' contactTypeSelect'>
        <Select
          value={contactProps.status}
          onChange={v => {
            setContactProps({
              ...contactProps,
              status: v.target.value,
            });
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          variant='outlined'
          className=''
          style={{
            height: "40px",
            width: "105px",
            background: contactProps.status === 0 ? "#70808F" : "#27AE60",
            color: "#ffffff",
            fontWeight: "600",
          }}>
          <MenuItem value={0}>
            <em style={{ fontStyle: "normal" }}>Not In Use</em>
          </MenuItem>
          <MenuItem value={1}>In Use</MenuItem>
        </Select>
      </FormControl>
      <Button
        style={{
          position: "absolute",
          top: "126px",
          right: "30px",
          padding: "9px 30px",
        }}
        color='primary'
        variant='contained'
        onClick={() => {
          addUpdateContactType();
        }}>
        Save
      </Button>
      <Accordion
        square
        expanded={expandedContact}
        onChange={() => setExpandedContactType(!expandedContact)}
        className='contactTypesBasics'>
        <div className='' style={{ padding: "1%", width: "100%" }}>
          <MuiAccordionSummary
            aria-controls='summary'
            id='summary-header'
            className='accordialInnerTitle'>
            <p className='accordionTitle'>
              <img
                src={downarrow}
                alt='arrow icon'
                className={`arrow-icon ${expanded ? "arrow-invert" : ""}`}
              />
              Contact Type Basics
            </p>
          </MuiAccordionSummary>
        </div>

        <MuiAccordionDetails className='accordianDetails'>
          <Grid container spacing={1}>
            <Grid item sx={12} sm={12} md={4}>
              <p className='textfield-title'>
                Contact Type Name Singular (Internal)
                <span style={{ color: "red" }}>*</span>
              </p>
              <InputField
                id='outlined-basic'
                variant='outlined'
                placeholder='End User'
                value={contactProps.nameSingularInternal}
                onChange={v =>
                  setContactProps({
                    ...contactProps,
                    nameSingularInternal: v.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sx={12} sm={12} md={4}>
              <p className='textfield-title'>
                Contact Type Name Singular (Public)
                <span style={{ color: "red" }}>*</span>
              </p>

              <InputField
                id='outlined-basic'
                variant='outlined'
                placeholder='End User'
                value={contactProps.nameSingularPublic}
                onChange={v =>
                  setContactProps({
                    ...contactProps,
                    nameSingularPublic: v.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sx={12} sm={12} md={4}>
              <p className='textfield-title'>
                OFish Contact Area<span style={{ color: "red" }}>*</span>
              </p>

              <InputField
                id='outlined-basic'
                variant='outlined'
                value={
                  contactAreas?.find(x => x.id === contactProps.contactAreaId)
                    ?.name
                }
                placeholder='Select a Contact Area'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onClick={() => setOpenEditListPopup(!openEditListPopup)}
              />
            </Grid>
            <Grid item sx={12} sm={12} md={4}>
              <p className='textfield-title'>
                Contact Type Name Plural (Internal)
                <span style={{ color: "red" }}>*</span>
              </p>

              <InputField
                id='outlined-basic'
                variant='outlined'
                placeholder='End User'
                value={contactProps.namePluralInternal}
                onChange={v =>
                  setContactProps({
                    ...contactProps,
                    namePluralInternal: v.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sx={12} sm={12} md={4}>
              <p className='textfield-title'>
                Contact Type Name Plural (Public)
                <span style={{ color: "red" }}>*</span>
              </p>

              <InputField
                id='outlined-basic'
                variant='outlined'
                placeholder='End User'
                value={contactProps.namePluralPublic}
                onChange={v =>
                  setContactProps({
                    ...contactProps,
                    namePluralPublic: v.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sx={12} sm={12} md={4}>
              <p className='textfield-title'>OFish Role</p>

              <InputField
                id='outlined-basic'
                value={
                  oFishRoles?.find(x => x.id === contactProps.oFishRoleId)?.name
                }
                variant='outlined'
                placeholder='Select a Contact Area'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onClick={() => setOpenSelectRoleModal(!openSelectRoleModal)}
              />
            </Grid>
            <Grid item sx={12} sm={12} md={4}>
              <p className='textfield-title'>
                Status<span style={{ color: "red" }}>*</span>
              </p>

              <FormControl
                sx={{ m: 1, maxWidth: "100%" }}
                className='form-control'>
                <Select
                  value={contactProps.status}
                  style={{
                    background: contactProps.status === 0 ? "" : "#27AE60",
                    color: contactProps.status === 0 ? "" : "#FFF",
                  }}
                  onChange={v => {
                    setContactProps({
                      ...contactProps,
                      status: v.target.value,
                    });
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  variant='outlined'
                  className='mui-select'>
                  <MenuItem value={0}>
                    <em style={{ fontStyle: "normal" }}>Not In Use</em>
                  </MenuItem>
                  <MenuItem value={1}>In use</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded2} className=''>
        <div className='' style={{ padding: "1%", width: "100%" }}>
          <MuiAccordionSummary
            aria-controls='summary'
            id='summary-header'
            className='accordialInnerTitle'>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              className='contactDataWrapper'>
              <p className='accordionTitle'>
                <img
                  src={downarrow}
                  alt='arrow icon'
                  className={`arrow-icon ${expanded ? "arrow-invert" : ""}`}
                />
                Contact Data
              </p>
              <Button
                color='primary'
                variant='contained'
                startIcon={<AddIcon />}
                onClick={handleOpen}>
                Add Data
              </Button>
            </div>
          </MuiAccordionSummary>
        </div>
        <MuiAccordionDetails className='accordianDetails'>
          <Grid container spacing={1}>
            <p className='contactDataPara'>
              A Contact’s First & Last Name, Group, as well as E-mail Address
              are required data for OFish to work correctly. From here you can
              add the relevant data from fields to modules and more for this
              contact type.
            </p>
            <h4>Contact Basics</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "wrap",
              }}>
              <Grid container spacing={1}>
                {dataField?.length === 0 && (
                  <p onClick={handleOpenPortalModal}>No contact added</p>
                )}
                {dataField?.map(item => {
                  return (
                    <Grid
                      // sx={12}
                      // sm={12}
                      // md={4}
                      style={{
                        marginBottom: "15px",
                        cursor: "pointer",
                        marginRight: "12px",
                      }}>
                      <div className='contactDataField'>
                        <p
                          className='contactDataName'
                          onClick={() => {
                            handleOpenModulesModal(item.fieldId);
                          }}>
                          {item.fieldValue}
                        </p>
                        <p
                          className='contactDataRequired'
                          style={{
                            marginLeft: "5px",
                            marginRight: "0px",
                            color: "#3699FF",
                          }}>
                          {item.fieldId === 1
                            ? "Required"
                            : item.fieldId === 2
                            ? "Required"
                            : item.fieldId === 3
                            ? "Required"
                            : // : item.fieldId === 4
                            // ? "Required"
                            item.fieldId === 11
                            ? "Required"
                            : ""}
                        </p>
                        {item?.contactDataFieldSettingInfo?.map(x => {
                          if (
                            x?.dataFieldSettingId === 9 ||
                            x?.dataFieldSettingId === 10 ||
                            x?.dataFieldSettingId === 11 ||
                            x?.dataFieldSettingId === 12 ||
                            x?.dataFieldSettingId === 13 ||
                            x?.dataFieldSettingId === 15 ||
                            x?.dataFieldSettingId === 16 ||
                            x?.dataFieldSettingId === 18 ||
                            x?.dataFieldSettingId === 19 ||
                            x?.dataFieldSettingId === 21 ||
                            x?.dataFieldSettingId === 22 ||
                            x?.dataFieldSettingId === 24 ||
                            x?.dataFieldSettingId === 25 ||
                            x?.dataFieldSettingId === 28 ||
                            x?.dataFieldSettingId === 29 ||
                            x?.dataFieldSettingId === 31 ||
                            x?.dataFieldSettingId === 35 ||
                            x?.dataFieldSettingId === 36 ||
                            x?.dataFieldSettingId === 37 ||
                            x?.dataFieldSettingId === 38 ||
                            x?.dataFieldSettingId === 40 ||
                            x?.dataFieldSettingId === 41 ||
                            x?.dataFieldSettingId === 43 ||
                            x?.dataFieldSettingId === 44 ||
                            x?.dataFieldSettingId === 46 ||
                            x?.dataFieldSettingId === 47 ||
                            x?.dataFieldSettingId === 49 ||
                            x?.dataFieldSettingId === 50 ||
                            x?.dataFieldSettingId === 52 ||
                            x?.dataFieldSettingId === 53 ||
                            x?.dataFieldSettingId === 55 ||
                            x?.dataFieldSettingId === 56 ||
                            x?.dataFieldSettingId === 58 ||
                            x?.dataFieldSettingId === 59
                          )
                            return (
                              <>
                                <p
                                  className='contactDataRequired'
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "0",
                                    color:
                                      x?.additionalData === "Required"
                                        ? "#3699FF"
                                        : x?.additionalData === "Optional"
                                        ? "#25A969"
                                        : x?.additionalData === "Recommended"
                                        ? "#3699FF"
                                        : x?.additionalData ===
                                          "Not Recommended"
                                        ? "#25A969"
                                        : "#3699FF",
                                  }}
                                  onClick={() => {
                                    handleOpenModulesModal(item.fieldId);
                                  }}>
                                  {x?.additionalData === "Required"
                                    ? x?.additionalData
                                    : x?.additionalData === "Optional"
                                    ? x?.additionalData
                                    : x?.additionalData === "Recommended"
                                    ? x?.additionalData
                                    : x?.additionalData === "Not Recommended"
                                    ? x?.additionalData
                                    : ""}{" "}
                                </p>
                              </>
                            );
                        })}
                        {item.fieldId !== 1 &&
                          item.fieldId !== 2 &&
                          item.fieldId !== 3 &&
                          item.fieldId !== 14 &&
                          item.fieldId !== 11 &&
                          item.fieldId !== 15 && (
                            <CloseIcon
                              onClick={e => {
                                e.preventDefault();
                                var data = [];
                                dataField.map(x => {
                                  if (x.fieldId !== item.fieldId) {
                                    return data.push(x);
                                  }
                                });
                                dispatch(
                                  contactsTypesActions.onChange(
                                    "dataField",
                                    data
                                  )
                                );
                              }}
                              style={{
                                zIndex: 999,
                                marginLeft: "0",
                                marginRight: "0",
                              }}
                            />
                          )}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <h4>OFish Modules</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "wrap",
              }}>
              <Grid container spacing={1}>
                {dataModule?.length === 0 && (
                  <p onClick={handleOpenPortalModal}>No modules added</p>
                )}
                {dataModule?.map(item => {
                  return (
                    <Grid
                      // sx={12}
                      // sm={12}
                      // md={4}
                      style={{
                        marginBottom: "15px",
                        cursor: "pointer",
                        marginRight: "12px",
                      }}>
                      <div className='contactDataField'>
                        <p
                          className='contactDataName'
                          onClick={() =>
                            getContactTypesModulesFiledsSettingsById(
                              item.fieldId
                            )
                          }>
                          {item.fieldValue}
                        </p>
                        {item.contactTypeModuleFieldSettingInfo.map(x => {
                          return (
                            <p
                              className='contactDataRequired'
                              style={{
                                marginLeft: "5px",
                                color:
                                  x?.additionalData === "Required"
                                    ? "#3699FF"
                                    : x?.additionalData === "Optional"
                                    ? "#25A969"
                                    : "#3699FF",
                              }}
                              onClick={() =>
                                getContactTypesModulesFiledsSettingsById(
                                  item.fieldId
                                )
                              }>
                              {x?.additionalData}
                            </p>
                          );
                        })}
                        <CloseIcon
                          onClick={e => {
                            e.preventDefault();
                            var data = [];
                            dataModule.map(x => {
                              if (x.fieldId !== item.fieldId) {
                                return data.push(x);
                              }
                            });
                            dispatch(
                              contactsTypesActions.onChange("dataModule", data)
                            );
                          }}
                          style={{
                            zIndex: 999,
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <h4>OFish Contact Connections</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "wrap",
              }}>
              <Grid container spacing={1}>
                {dataConnection?.length === 0 && <p>No Connections Added</p>}
                {dataConnection?.map(item => {
                  return (
                    <Grid
                      // sx={12}
                      // sm={12}
                      // md={4}
                      style={{ marginBottom: "15px", marginRight: "12px" }}>
                      <div className='contactDataField'>
                        <p className='contactDataName'>{item.fieldValue}</p>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
        </MuiAccordionDetails>
      </Accordion>
      <AddContactDataModal
        open={open}
        setOpen={setOpen}
        dataFieldsId={dataFieldsId}
        setDataFieldsId={setDataFieldsId}
        areaId={contactProps?.contactAreaId}
      />
      <EditListingPopup
        ContactArea
        open={openEditListPopup}
        contactData={contactAreas}
        setContactAreas={contactAreas}
        value={contactProps?.contactAreaId}
        setValue={e => {
          setContactProps({
            ...contactProps,
            contactAreaId: e.id,
          });
          onGetAllContactDataFields(e.id);
        }}
      />
      <EditListingPopup
        save
        open={openSelectRoleModal}
        contactData={oFishRoles}
        showRadioButton={true}
        value={contactProps?.oFishRoleId}
        setValue={e => {
          setContactProps({
            ...contactProps,
            oFishRoleId: e.id,
          });
        }}
      />
      <EditFieldPopup open={openEditFieldPopup} />
      <EditFieldWithRadioPopup
        open={openEditFieldWithRadioPopup}
        setOpenModulesModal={t => {
          setOpenEditFieldWithRadioPopup(t);
        }}
        fieldSetting={fieldSetting}
      />
      <EditPasswordFieldPopup open={openEditPasswordFieldPopup} />
      <EditTermsAndConditionFieldPopup
        open={openEditTermsAndConditionFieldPopup}
      />
      <EditListingWithCheckboxPopup open={openEditListingWithCheckboxPopup} />
      {/* <OFishModulesModal
        open={openModulesModal}
        setOpenModulesModal={setOpenModulesModal}
      /> */}
      {/* <OFishPortalModal
        open={openPortalModal}
        setOpenPortalModal={setOpenPortalModal}
      /> */}
      {openModulesModal && (
        <EditFieldWithRadioPopup
          open={openModulesModal}
          setOpenModulesModal={setOpenModulesModal}
          fieldSetting={fieldSetting}
          selectedId={selectedId}
          selectedName={fieldSetting?.fieldName}
          selectedFieldSetting={dataField.find(x => x.fieldId === selectedId)}
        />
      )}
      {openModulesModal1 && (
        <EditFieldWithRadioPopupPassword
          open={openModulesModal1}
          setOpenModulesModal={setOpenModulesModal1}
          fieldSetting={fieldSetting}
          selectedId={selectedId}
          selectedName={fieldSetting?.fieldName}
          selectedFieldSetting={dataField.find(x => x.fieldId === selectedId)}
        />
      )}
      {isOpen && (
        <ContactDataPopup
          isOpen={isOpen}
          records={contactModulesById?.fieldSettings}
          setIsOpen={e => {
            setIsOpen(e);
          }}
          selectedId={selectedId}
          selectedName={contactModulesById?.moduleName}
          selectedFieldSetting={dataModule.find(x => x.fieldId === selectedId)}
        />
      )}
    </>
  );
}
