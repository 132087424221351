import _ from "lodash";
import { contactTypeTypes } from "./contactTypeTypes";
import { contactsApi } from "./contactTypesApi";
export const ON_SELECT_CONTACT_AREA = "ON_SELECT_CONTACT_AREA";

const onGetAllContactTypes = modal => async dispatch => {
  let data = await contactsApi.getAllContactTypes(modal);
  return data;
};
const onGetAllContactDataFields = id => async dispatch => {
  let data = await contactsApi.getAllContactDataFields(id);
  console.log("response", data);
  return data;
};
const onGetAllContactModules = id => async dispatch => {
  let data = await contactsApi.getAllContactModules(id);
  console.log("response", data);
  return data;
};
const onGetAllConnections = id => async dispatch => {
  let data = await contactsApi.getAllConnections(id);
  console.log("response", data);
  return data;
};
const onGetAllContactAreas = () => async dispatch => {
  let data = await contactsApi.getAllContactAreas();
  console.log("response", data);
  return data;
};
const onGetAllOfishRoles = () => async dispatch => {
  let data = await contactsApi.getAllOFisRoles();
  return data;
};
const onGetAllContactDataFieldsById = _ID => async dispatch => {
  console.log("-id", _ID);
  let data = await contactsApi.getAllContactDataFieldsById(_ID);
  console.log("response----????", data);
  return data;
};

const onSelectContactArea = value => async dispatch => {
  dispatch({
    type: "ON_SELECT_CONTACT_AREA",
    payload: value,
  });
};

const getContactTypesModulesFiledsSettingsById = id => async dispatch => {
  let data = await contactsApi.getContactTypesModulesFiledsSettingsById(id);
  return data;
};

const onChange = (prop, value) => async dispatch => {
  dispatch({
    type: contactTypeTypes.ON_CHANGE,
    payload: { prop, value },
  });
};

const onAddUpdateContactType = modal => async dispatch => {
  let data = await contactsApi.addUpdateContactType(modal);
  return data;
};

const onGetContactTypesById = id => async dispatch => {
  let data = await contactsApi.getContactTypesById(id);
  return data;
};

const onAddUpdateMarkFavouriteContactTypes = modal => async dispatch => {
  let data = await contactsApi.addUpdateMarkFavouriteContactTypes(modal);
  return data;
};

const onAddUpdateMarkFavouriteContactTypesModules = modal => async dispatch => {
  let data = await contactsApi.addUpdateMarkFavouriteContactTypesModules(modal);
  return data;
};

const onAddUpdateMarkFavouriteOfishConnections = modal => async dispatch => {
  let data = await contactsApi.addUpdateMarkFavouriteOfishConnections(modal);
  return data;
};

const onGetFavouriteUser = () => async dispatch => {
  let data = await contactsApi.getFavouriteUsers();
  return data;
};

const onGetStoreLocationsByCompanyId = id => async dispatch => {
  let data = await contactsApi.getStoreLocationsByCompanyId(id);
  return data;
};

const onGetLeadsPaginatedData = modal => async dispatch => {
  let data = await contactsApi.getLeadsPaginatedData(modal);
  return data;
};

const onUpdateLeadGeneration = (data) => async (dispatch) => {
  let response = await contactsApi.updateLeadGeneration(data);
  return response;
};

const onGetLeadGeneration = id => async dispatch => {
  let data = await contactsApi.getLeadGeneration(id);
  return data;
};

export const contactsTypesActions = {
  onGetAllContactTypes: onGetAllContactTypes,
  onGetAllContactDataFields: onGetAllContactDataFields,
  onGetAllContactModules: onGetAllContactModules,
  onGetAllConnections: onGetAllConnections,
  onGetAllContactAreas: onGetAllContactAreas,
  onGetAllOfishRoles: onGetAllOfishRoles,
  onSelectContactArea: onSelectContactArea,
  onGetAllContactDataFieldsById: onGetAllContactDataFieldsById,
  // onGetContactTypeDetailByID: onGetContactTypeDetailByID,
  // onAddContactType: onAddContactType,
  // onChangeContactTypeStatus: onChangeContactTypeStatus,
  // onUpdateContactType: onUpdateContactType,
  getContactTypesModulesFiledsSettingsById:
    getContactTypesModulesFiledsSettingsById,
  onChange: onChange,
  onAddUpdateContactType: onAddUpdateContactType,
  onGetContactTypesById: onGetContactTypesById,
  onAddUpdateMarkFavouriteContactTypes: onAddUpdateMarkFavouriteContactTypes,
  onAddUpdateMarkFavouriteContactTypesModules:
    onAddUpdateMarkFavouriteContactTypesModules,
  onAddUpdateMarkFavouriteOfishConnections:
    onAddUpdateMarkFavouriteOfishConnections,
  onGetFavouriteUser: onGetFavouriteUser,
  onGetStoreLocationsByCompanyId: onGetStoreLocationsByCompanyId,
  onGetLeadsPaginatedData:onGetLeadsPaginatedData,
  onUpdateLeadGeneration:onUpdateLeadGeneration,
  onGetLeadGeneration:onGetLeadGeneration,
};
