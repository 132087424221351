import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  ActionContent,
  Modal,
  SearchAndFilter,
} from "../../../../components/common";
import {
  ActionButtonAddXL,
  ActionButtonXL,
} from "../../../../components/common/ActionContent";
import ConvTemplateContent from "./ConvTemplateContent";
import { Pagination } from "@material-ui/lab";

const ConvTemplateModal = ({
  isOpen,
  setIsOpen,
  portals = [],
  priceListPortals = [],
  onAdd,
  setFilterByVal,
}) => {
  const [selectedPortals, setSelectedPortals] = useState(priceListPortals);
  
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const defaultState = {
    title: "",
    content: "",
  };
  const [addTemplate, setAddTemplate] = useState(defaultState);

  // useEffect(() => {
  //   return () => setFilterByVal("");
  // }, []);
  // 
  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      title={"OFish Conversation Template"}
      primaryHeading="Choose an OFish Conversation Template"
      secondaryHeading="To ensure a quick and consistent response to customers, use OFish Conversation Templates. Can’t find what you’re looking for? Create your own conversation template below."
    >
      {!isNewTemplate && (
        <>
          <SearchAndFilter
            placeholderText={"Search"}
            onFilterButtonClick={(val) => setFilterByVal(val)}
            tabButtons={[
              { id: 1, label: "All Templates", generic: "" },
              { id: 2, label: "General", generic: "General" },
              { id: 3, label: "Customer Support", generic: "Customer Support" },
              { id: 4, label: "Product Support", generic: "Product Support" },
            ]}
          />
          <Grid
            container
            justifyContent="space-evenly"
            className="conv-modal-grid"
          >
            <ActionButtonAddXL
              onButtonClick={() => {
                setAddTemplate(defaultState);
                setIsNewTemplate(true);
              }}
            />
            {portals?.map((x) => (
              <ActionButtonXL
                name={x?.title}
                description={x?.content}
                onButtonClick={() => {
                  setAddTemplate({
                    id: x?.id,
                    title: x?.title,
                    content: x?.content,
                  });
                  setIsNewTemplate(true);
                }}
              />
            ))}
            {/* {portals
              ?.filter(
                p =>
                  p?.name?.toLowerCase().includes(filterByVal?.toLowerCase()) ||
                  p?.description
                    ?.toLowerCase()
                    .includes(filterByVal?.toLowerCase())
              )
              ?.map(pls => (
                <Grid item xs={6}>
                  <ActionContent
                    IsPortal={true}
                    IsAdded={
                      selectedPortals?.find(sp => sp?.portalId === pls.id)
                        ? true
                        : false
                    }
                    name={pls?.name}
                    type={pls?.type}
                    description={pls?.description}
                    onAction={() => {
                      const newPL = [
                        ...selectedPortals,
                        {
                          id: 0,
                          portalId: pls.id,
                          description: pls?.description,
                          isAllAccess: false,
                          isViewOnlyAccess: false,
                          canDownloadCSVAccess: false,
                          canDownloadPDFAccess: false,
                          isMakeViewable: false,
                        },
                      ];
                      setSelectedPortals([...newPL]);
                    }}
                  />
                </Grid>
              ))} */}
          </Grid>
          <Grid
            container
            justifyContent="center"
            style={{ paddingTop: "32px" }}
          >
            <Pagination page={1} size="" count={6} color="secondary" shape="rounded" />
          </Grid>
          <Grid container justifyContent="flex-end">
            <button
            onClick={()=>setIsOpen(false)}
            className="cancelBtn">
              Cancle
            </button>
          </Grid>
        </>
      )}

      {isNewTemplate && (
        <ConvTemplateContent
          addTemplate={addTemplate}
          setAddTemplate={(x) => setAddTemplate(x)}
          clickback={() => setIsNewTemplate(false)}
          onAdd={(x) => onAdd(x)}
        />
      )}

      {/* <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <div>&nbsp;</div>
        <div className='bottomPageWrapper'>
          <p className='addCount'> {selectedPortals.length} added </p>
          <Button
            color='primary'
            variant='contained'
            className='dashboardButtons'
            onClick={() => {
              onAdd(selectedPortals);
              setIsOpen(!isOpen);
            }}>
            Add to Price List
          </Button>
        </div>
      </span> */}
    </Modal>
  );
};

export { ConvTemplateModal };
