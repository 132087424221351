import * as React from "react";
import { createComponent } from "@lit-labs/react";
import { RoundSlider } from "@thomasloven/round-slider";

export const MyRoundSlider = createComponent(
  React,
  "round-slider",
  RoundSlider,
  {
    // onactivate: 'activate',
    // onchange: 'change',
    valueChanged: "value-changed",
    valueChanging: "value-changing"
    // min : 'min',
    //     max : 'max',
    //     step : 'step',
    //     startAngle: 'startAngle',
    //     arcLength: 'arcLength',
    //     handleSize: 'handleSize',
    //     handleZoom: 'handleZoom'
    //     disabled : 'disabled',
    //     readonly : 'readonly'
    //     rtl
    //     valueLabe
    //     lowLabel
    //     highLabel
    //     value
    //     low
    //     high
  }
);
