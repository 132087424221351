import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getNewsroomDD() {
  return axios
    .get(endpoints.newsRoomAdmin.getNewsRoomDropdown)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getSettings() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getSettings}${"?newsRoomId=1"}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyOverview() {
  return axios
    .get(endpoints.newsRoomAdmin.getCompanyOverview)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getLeadership() {
  return axios
    .get(endpoints.newsRoomAdmin.getLeadership)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getContactInfo() {
  return axios
    .get(endpoints.newsRoomAdmin.getComContactInfo)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveSettings(
  internalName,
  externalName,
  newsRoomURL,
  newsRoomStatus,
  viewOnlyGroup,
  viewAndDownloadGroup
) {
  return axios
    .post(endpoints.newsRoomAdmin.saveSettings, {
      id: 1,
      internalName: internalName,
      externalName: externalName,
      newsRoomURL: newsRoomURL,
      newsRoomStatus: newsRoomStatus,
      viewOnlyGroup: viewOnlyGroup,
      viewAndDownloadGroup: viewAndDownloadGroup,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveCompanyOverview(des, imgUrl) {
  return axios
    .post(endpoints.newsRoomAdmin.saveCompOverview, {
      newsRoomId: 1,
      companyDesc: des,
      featuredImageURL: imgUrl,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function onSaveBoilerPlateDescription(des) {
  return axios
    .post(endpoints.newsRoomAdmin.saveNewsRoomBoilerplate, {
      newsRoomId: 1,
      companyBoilerplate: des,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveLeaderShip(leaderData) {
  return axios
    .post(endpoints.newsRoomAdmin.saveLeader, {
      leaderships: leaderData,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveComContact(comData) {
  return axios
    .post(endpoints.newsRoomAdmin.saveComContact, {
      newsRoomId: 1,
      pressContact: comData.pressContact,
      pressContactEmail: comData.pressContactEmail,
      imageURL: comData.imageURL,
      companyName: comData.companyName,
      address: comData.address,
      city: comData.city,
      state: comData.state,
      zipCode: comData.zipCode,
      country: comData.country,
      contacts: comData.contacts,
      faxNumber: comData.faxNumber,
      faxCountryCode: comData.faxCountryCode,
      email: comData.email,
      socials: comData.socials,
      businessHours: comData.businessHours,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getQuickLinks() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getQuickLinks}${"?newsRoomId=1"}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getFeaturedContent() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getFeaturedContent}${"?newsRoomId=1"}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getMenuOptions() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getMenuOptions}${"?newsRoomId=1"}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getSortMenuOptions() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getMenuByNewsRoomId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getNavigations() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getNavigations}${"?newsRoomId=1"}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveQuickLinks(comData) {
  return axios
    .post(endpoints.newsRoomAdmin.saveQuickLinks, {
      newsRoomId: 1,
      newsRoomQuickActionsIds: comData,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteNewsRoomContent(id) {
  return axios
    .delete(`${endpoints.newsRoomAdmin.deleteNewsRoomContent}${"?id="}${id}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveNewsRoomNavigation(data) {
  return axios
    .post(endpoints.newsRoomAdmin.saveNewsRoomNavigation, {
      newsRoomId: 1,
      newsRoomNavigations: data,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveFeaturedContent(
  contentId,
  slideTitle,
  slideSubTitle,
  bannerImageUrl,
  linkTo,
  url,
  urlTarget,
  showImageOnly
) {
  return axios
    .post(endpoints.newsRoomAdmin.saveFeaturedContent, {
      newsRoomId: 1,
      contentId: contentId,
      content: {
        newsRoomId: 1,
        slideTitle: slideTitle,
        slideSubTitle: slideSubTitle,
        bannerImageUrl: bannerImageUrl,
        linkTo: linkTo,
        url: url,
        urlTarget: urlTarget,
        showImageOnly: showImageOnly,
      },
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllNewsRoomContentByRoomId() {
  return axios
    .get(endpoints.newsRoomAdmin.getAllNewsRoomContentByRoomId)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getMenuByNewsRoomId() {
  return axios
    .get(endpoints.newsRoomAdmin.getMenuByNewsRoomId)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getNewsRoomQuickLinks() {
  return axios
    .get(endpoints.newsRoomAdmin.getNewsRoomQuickLinks)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

// async function getMenuOptions() {
//   return axios
//     .get(endpoints.newsRoomAdmin.getMenuOptions)
//     .then(response => {
//       let data = response.data;
//       return data;
//     })
//     .catch(error => {
//       return null;
//     });
// }

export const newsroomApi = {
  getNewsroomDD: getNewsroomDD,
  getCompanyOverview: getCompanyOverview,
  getLeadership: getLeadership,
  getContactInfo: getContactInfo,
  saveCompanyOverview: saveCompanyOverview,
  saveLeaderShip: saveLeaderShip,
  saveComContact: saveComContact,
  getQuickLinks: getQuickLinks,
  saveQuickLinks: saveQuickLinks,
  getAllNewsRoomContentByRoomId: getAllNewsRoomContentByRoomId,
  getMenuByNewsRoomId: getMenuByNewsRoomId,
  getNewsRoomQuickLinks: getNewsRoomQuickLinks,
  onSaveBoilerPlateDescription: onSaveBoilerPlateDescription,
  saveSettings: saveSettings,
  getSettings: getSettings,
  saveFeaturedContent: saveFeaturedContent,
  getFeaturedContent: getFeaturedContent,
  getMenuOptions: getMenuOptions,
  getSortMenuOptions: getSortMenuOptions,
  getNavigations: getNavigations,
  saveNewsRoomNavigation: saveNewsRoomNavigation,
  deleteNewsRoomContent: deleteNewsRoomContent,
};
