import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import SelectImagesTabs from "../../../../components/common/SelectImagesTabs";
import Leadership from "./Leadership";
import ContactInformation from "./ContactInformation";
import Grid from "@material-ui/core/Grid";
import EmptyTextarea from "../../../../../components/common/Textarea";
import { Button } from "@material-ui/core";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import CustomCheckbox from "../../../../../components/common/checkbox/CustomCheckbox";
import SelectImage from "./selectImage";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FroalaEditor from "react-froala-wysiwyg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  textArea: {
    minHeight: "470px",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

new FroalaEditor(".selector", {
  toolbarButtons: {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "inlineClass",
        "inlineStyle",
        "clearFormatting",
      ],
    },
    moreParagraph: {
      buttons: [
        "alignLeft",
        "alignCenter",
        "formatOLSimple",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",
        "paragraphFormat",
        "paragraphStyle",
        "lineHeight",
        "outdent",
        "indent",
        "quote",
      ],
    },
    moreRich: {
      buttons: [
        "insertLink",
        "insertImage",
        "insertVideo",
        "insertTable",
        "emoticons",
        "fontAwesome",
        "specialCharacters",
        "embedly",
        "insertFile",
        "insertHR",
      ],
    },
    moreMisc: {
      buttons: [
        "undo",
        "redo",
        "fullscreen",
        "print",
        "getPDF",
        "spellChecker",
        "selectAll",
        "html",
        "help",
      ],
      align: "right",
      buttonsVisible: 2,
    },
  },
});

var test = "";

export default function CompanyOverview({ startLoading, stopLoading }) {
  const dispatch = useDispatch();
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [companyImg, setCompanyImg] = useState("");
  const [comImgId, setComImgId] = useState(0);
  const [comDescription, setComDescription] = useState("");
  const [boilerPlateDescription, setBoilerPlateDescription] = useState("");
  const [isError, setIsError] = useState(false);

  const onHandleComDescription = (model) => {
    setComDescription(model);
  };

  const onHandleBoilerPlateDescription = (model) => {
    setBoilerPlateDescription(model);
  };

  const uploadFroalaImage = (file) => {};

  const loadData = () => {
    startLoading();
    dispatch(newsroomActions.onGetCompanyOverview()).then((data) => {
      if (data && data.success && data.data) {
        setComDescription(data.data.companyDesc);
        setBoilerPlateDescription(data.data.companyBoilerplate);
        setCompanyImg(data.data.featuredImageURL);
        dispatch(newsroomActions.onGetLeadership()).then((leader) => {
          if (leader && leader.success && leader.data) {
            dispatch(newsroomActions.onChange("leadersValue", leader.data));
          } else {
            toast.error(leader.message);
          }
          stopLoading();
        });
      } else {
        stopLoading();
        toast.error(data?.message);
      }
    });
  };
  useEffect(() => {
    loadData();
  }, []);

  const uploadCompanyImage = (file) => {
    startLoading();
    dispatch(contactsActions.onCompanyUploadImage(file)).then((data) => {
      if (data && data.success && data.data) {
        setComImgId(data.data.id);
        setCompanyImg(data.data.url);
        setShowFeatureImage(!showFeatureImage);
      }
      stopLoading();
    });
  };

  const onSaveOverView = () => {
    if (isError) {
      toast.error(
        "Company description characters length cannot be exceed from 1000 characters"
      );
    } else {
      startLoading();
      dispatch(
        newsroomActions.onSaveCompanyOverview(comDescription, companyImg)
      ).then((data) => {
        if (data && data.success) {
          toast.success("Company overview successfully updated");
        } else {
          toast.error(data?.message);
        }
        stopLoading();
      });
    }
  };

  const onSaveBoilerPlateDescription = () => {
    if (isError) {
      toast.error(
        "Boiler plate description characters length cannot be exceed from 1000 characters"
      );
    } else {
      startLoading();
      dispatch(
        newsroomActions.onSaveBoilerPlateDescription(boilerPlateDescription)
      ).then((data) => {
        if (data && data.success) {
          toast.success("Boiler plate description successfully updated");
        } else {
          toast.error(data?.message);
        }
        stopLoading();
      });
    }
  };

  const checkDesValue = () => {
    setComDescription(comDescription.trim());
    if (comDescription !== "" && comDescription.length > 1000) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const classes = useStyles();
  return (
    <div className="accordianOuterWrapper">
      <AccordionContainer title="Company Overview">
        <span className="customCheckboxWrapper">
          <CustomCheckbox
            checkedId="checkbox-01"
            pressCheck={() => console.log("hello")}
          />
          Match Information from OFish Company Profile
        </span>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridWrapper}>
              <FroalaEditor
                // ref={ref}
                model={comDescription}
                onModelChange={onHandleComDescription}
                tag="textarea"
                config={{
                  events: {
                    "image.inserted": function ($img, response) {
                      // $img[0].src =
                      //   "https://media0.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif?cid=790b76118e6134003746d6e19261c2868eb9cbe166fcac9f&rid=giphy.gif&ct=g";
                      $img[0].src =
                        "https://logosbynick.com/wp-content/uploads/2021/01/animated-gif.gif";
                      setTimeout(() => {
                        $img[0].src = test;
                        // setLoading(false);
                        // $img[0].currentSrc = test;
                      }, 7000);
                      // Do something here.
                      // this is the editor instance.
                      console.log(this);
                    },
                    "image.beforeUpload": function (images) {
                      uploadFroalaImage(images);
                      // Do something here.
                      // this is the editor instance.
                      console.log(this);
                    },
                  },
                  key: "VCC6kE4A3D3H3C7C7C6cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A3F3E3B3F2A5C3==",
                }}
              />
              {/* <EmptyTextarea
                className={classes.textArea}
                value={comDescription}
                onBlur={() => checkDesValue()}
                onChange={(e) => setComDescription(e.target.value)}
                textLabel="Company Description "
                textSubtitle="Enter a short description of your news release. This will be used in various areas across the newsroom to preview your release. (500 char. max)"
              /> */}
            </Grid>

            {/* left column */}
            <Grid item xs={8} className={classes.gridWrapper}>
              <Grid
                item
                xs={8}
                className={(classes.gridWrapper, classes.featuredImage)}
              >
                <label className={classes.featuredImageHeading}>
                  Featured Image
                </label>
                <div className="uploadImageWrapper">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.selectBtn}
                    onClick={() => setShowFeatureImage(!showFeatureImage)}
                  >
                    {companyImg !== "" && companyImg !== "string"
                      ? "Change"
                      : "Select"}
                  </Button>
                  {companyImg !== "" && (
                    <img
                      alt="feature iamge"
                      className="featureRoundImg"
                      src={companyImg}
                    />
                  )}
                </div>
                {showFeatureImage && (
                  <SelectImage
                    closePopup={() => setShowFeatureImage(false)}
                    openPopup={showFeatureImage}
                    title={"Upload Company Image"}
                    addProfileImage={(value) => uploadCompanyImage(value)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={[classes.gridWrapper, "saveChangesBtn"]}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSaveOverView()}
            >
              Save Changes
            </Button>
          </Grid>
        </div>
      </AccordionContainer>
      <AccordionContainer title="Company Boilerplate">
        <span className="customCheckboxWrapper">
          Use your Company’s Boilerplate at the bottom of news releases and
          official documents about your company.
        </span>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridWrapper}>
              <FroalaEditor
                model={boilerPlateDescription}
                onModelChange={onHandleBoilerPlateDescription}
                tag="textarea"
                config={{
                  events: {
                    "image.inserted": function ($img, response) {
                      $img[0].src =
                        "https://logosbynick.com/wp-content/uploads/2021/01/animated-gif.gif";
                      setTimeout(() => {
                        $img[0].src = test;
                      }, 7000);
                      console.log(this);
                    },
                    "image.beforeUpload": function (images) {
                      uploadFroalaImage(images);
                      console.log(this);
                    },
                  },
                  key: "VCC6kE4A3D3H3C7C7C6cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A3F3E3B3F2A5C3==",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={[classes.gridWrapper, "saveChangesBtn"]}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSaveBoilerPlateDescription()}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </div>
      </AccordionContainer>
      <div>
        <Leadership
          reloadData={() => loadData()}
          startLoading={() => startLoading()}
          stopLoading={() => stopLoading()}
        />
      </div>
      <div>
        <ContactInformation />
      </div>
    </div>
  );
}
