import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Box,
  TextField,
} from "@material-ui/core";
import { EditIcon } from "../../LeftNav/SvgIcons";
import { DeleteIcon } from "../../LeftNav/SvgIcons";

const rows = [
  {
    id: "1",
    date: "July 1, 2022",
    location: "San Francisco, CA",
    length: "3H 4M 2S",
    temp: "30° F",
  },
  {
    id: "2",
    date: "July 2, 2022",
    location: "San Francisco, CA",
    length: "3H 4M 2S",
    temp: "30° F",
  },
  {
    id: "3",
    date: "July 3, 2022",
    location: "San Francisco, CA",
    length: "3H 4M 2S",
    temp: "30° F",
  },
  {
    id: "4",
    date: "July 4, 2022",
    location: "San Francisco, CA",
    length: "3H 4M 2S",
    temp: "30° F",
  },
];

const useStyles = makeStyles(theme => ({
  tableHeader: {
    textAlign: "left",
    background: "#E5F2FF",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
    color: "#828282",
  },
  tripsTitle: {
    color: "#000",
    fontSize: "20px",
    fontWeight: "500",
    marginBottom: "15px",
  },
  tablerows: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    textAlign: "left",
  },
}));

export const Trips = () => {
  const [mokeData, setMokeData] = useState(rows);
  const [isData, setIsData] = useState(mokeData.map(() => false));
  const classes = useStyles();

  const onEditTable = index => {
    const newData = [...isData];
    newData[index] = !newData[index];
    setIsData(newData);
  };

  const onDeleteTableRow = id => {
    const newData = mokeData.filter(row => row.id !== id);
    setMokeData(newData);
    setIsData(newData.map(() => false));
  };

  const onInputChange = (event, index, key) => {
    const newData = [...mokeData];
    newData[index][key] = event.target.value;
    setMokeData(newData);
  };

  return (
    <Box className='trips'>
      <span className={classes.tripsTitle}>Trips</span>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableHeader}
                style={{ width: "216px" }}>
                Date
                <TableSortLabel />
              </TableCell>
              <TableCell
                className={classes.tableHeader}
                style={{
                  width: "335px",
                }}>
                Location
                <TableSortLabel />
              </TableCell>
              <TableCell
                className={classes.tableHeader}
                style={{
                  width: "183px",
                }}>
                Length (Time)
                <TableSortLabel />
              </TableCell>
              <TableCell
                className={classes.tableHeader}
                style={{
                  width: "216px",
                }}>
                Average Internal Temperature
                <TableSortLabel />
              </TableCell>
              <TableCell
                className={classes.tableHeader}
                style={{
                  width: "107px",
                }}>
                Actions
                <TableSortLabel />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mokeData.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  style={{ width: "216px" }}
                  className={classes.tablerows}
                  component='th'
                  scope='row'>
                  {isData[index] ? (
                    <TextField
                      style={{ width: "100%" }}
                      value={row.date}
                      type='text'
                      variant='outlined'
                      onChange={event => onInputChange(event, index, "date")}
                    />
                  ) : (
                    <Typography>{row.date}</Typography>
                  )}
                </TableCell>
                <TableCell
                  style={{ width: "335px" }}
                  className={classes.tablerows}
                  align='left'
                  component='th'
                  scope='row'>
                  {isData[index] ? (
                    <TextField
                      style={{ width: "100%" }}
                      value={row.location}
                      type='text'
                      variant='outlined'
                      onChange={event =>
                        onInputChange(event, index, "location")
                      }
                    />
                  ) : (
                    <Typography>{row.location}</Typography>
                  )}
                </TableCell>
                <TableCell
                  style={{ width: "183px" }}
                  className={classes.tablerows}
                  component='th'
                  scope='row'>
                  {isData[index] ? (
                    <TextField
                      style={{ width: "100%" }}
                      value={row.length}
                      type='text'
                      variant='outlined'
                      onChange={event => onInputChange(event, index, "length")}
                    />
                  ) : (
                    <Typography>{row.length}</Typography>
                  )}
                </TableCell>
                <TableCell
                  style={{ width: "216px" }}
                  className={classes.tablerows}
                  component='th'
                  scope='row'>
                  {isData[index] ? (
                    <TextField
                      style={{ width: "100%" }}
                      value={row.temp}
                      type='text'
                      variant='outlined'
                      onChange={event => onInputChange(event, index, "temp")}
                    />
                  ) : (
                    <Typography>{row.temp}</Typography>
                  )}
                </TableCell>
                <TableCell
                  style={{ width: "107px" }}
                  className={classes.tablerows}
                  component='th'
                  scope='row'>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <span onClick={() => onEditTable(index)}>
                      <EditIcon />
                    </span>
                    <span onClick={() => onDeleteTableRow(row.id)}>
                      <DeleteIcon />
                    </span>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
