import React from "react";
import { FormControl, InputAdornment, OutlinedInput } from "@material-ui/core";

const AdornmentInputField = ({
  inputlabel,
  unit,
  subtitle,
  required,
  placeholder,
  errorMessages,
  value,
  onChange
}) => {
  return (
    <div className='adornmentInput'>
      <FormControl variant='outlined'>
        {inputlabel && <label>{inputlabel}</label>}
        <OutlinedInput
          type='number'
          required={required}
          value={value}
          onChange={onChange}
          endAdornment={
            unit && <InputAdornment position='end'>{unit}</InputAdornment>
          }
          aria-describedby='outlined-weight-helper-text'
          inputProps={{
            "aria-label": "weight",
            inputMode: "numeric",
            min: "1",
            max: "999"
          }}
        />
        <span className='inputSubtitle'>{subtitle}</span>
      </FormControl>
    </div>
  );
};

export { AdornmentInputField };
