import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  ActionContent,
  Modal,
  SearchAndFilter,
} from "../../../../../../components/common";
// import { Pagination } from "../../../../../../components/common/Pagination";

const PortalsListModal = ({
  isOpen,
  setIsOpen,
  portals = [],
  priceListPortals = [],
  onAdd = () => {},
}) => {
  const [selectedPortals, setSelectedPortals] = useState(priceListPortals);
  const [filterByVal, setFilterByVal] = useState("");
  const [filterBySelectedTab, setFilterBySelectedTab] = useState("");

  useEffect(() => {
    return () => setFilterByVal("");
  }, []);

  const filteredPortals = filterBySelectedTab.includes("Added")
    ? portals.filter(p => selectedPortals.map(sp => sp.portalId).includes(p.id))
    : portals;

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      title={"Add Portal Visibility for This Price List"}
      primaryHeading='Choose Which Portals This Price List Is Visible'
      secondaryHeading='Select the portals where users can view and download this price list. Remember, Mobile Access includes PDF Downloads.'>
      <Grid
        container
        spacing={2}
        style={{
          height: "390px",
          overflow: "auto",
          display: "block",
        }}>
        <SearchAndFilter
          placeholderText={"Search by Portal Name or Portal Type"}
          onFilterButtonClick={val => setFilterByVal(val)}
          selectedTab={filterBySelectedTab}
          onTabButtonClick={selectedTab =>
            setFilterBySelectedTab(selectedTab?.generic)
          }
          tabButtons={[
            {
              id: 0,
              label: `Added (${selectedPortals.length})`,
              generic: "Added",
            },
            { id: 1, label: "All", generic: "" },
          ]}
        />

        <Grid container item xs={12}>
          {filteredPortals
            ?.filter(
              p =>
                p?.name?.toLowerCase().includes(filterByVal?.toLowerCase()) ||
                p?.description
                  ?.toLowerCase()
                  .includes(filterByVal?.toLowerCase())
            )
            ?.map(pls => (
              <Grid item xs={6}>
                <ActionContent
                  IsPortal={true}
                  IsAdded={
                    selectedPortals?.find(sp => sp?.portalId === pls.id)
                      ? true
                      : false
                  }
                  name={pls?.name}
                  type={pls?.type}
                  description={pls?.description}
                  onAction={() => {
                    const newPL = [
                      ...selectedPortals,
                      {
                        id: 0,
                        portalId: pls.id,
                        description: pls?.description,
                        isAllAccess: false,
                        isViewOnlyAccess: false,
                        canDownloadCSVAccess: false,
                        canDownloadPDFAccess: false,
                        isMakeViewable: false,
                      },
                    ];
                    setSelectedPortals([...newPL]);
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>

      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <div>&nbsp;</div>
        <div className='bottomPageWrapper'>
          {/* <Pagination /> */}
          <p className='addCount'> {selectedPortals.length} added </p>
          <Button
            color='primary'
            variant='contained'
            className='dashboardButtons'
            onClick={() => {
              onAdd(selectedPortals);
              setIsOpen(!isOpen);
            }}>
            Add to Price List
          </Button>
        </div>
      </span>
    </Modal>
  );
};

export { PortalsListModal };
