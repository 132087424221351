import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import queryString from "query-string";
import Dashbard from "../Dashbard";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { ProductsTab } from "./ProductsTab";
import { LaunchOverview } from "./LaunchOverview";
import { ReleaseTab } from "./ReleaseTab";
import { EventsTab } from "./EventsTab";
import { CollectionsTab } from "./CollectionsTab";
import { launchesActions } from "../../../../store/launches/launchesActions";
import Loader from "../../../../components/common/Loader";
import { LaunchActivities } from "./LaunchActivities";
import { LaunchNotes } from "./LaunchNotes";

const NewLaunch = memo(() => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [launch, setLaunch] = useState(null);
  const [activeTabId, setActiveTabId] = useState("1");
  const [excludedLaunchTabs, setExcludedLaunchTabs] = useState([]);

  useEffect(() => {
    const launchId = queryString.parse(location.search);
    if (launchId?.id) {
      setLoading(true);
      dispatch(launchesActions.onGetLaunchById(launchId?.id)).then(data => {
        setLoading(false);
        if (!data) return;
        const { data: existingLaunch } = data;
        setLaunch(existingLaunch);
      });
    }

    return () => {
      setLaunch(null);
    };
  }, [dispatch, location]);

  useEffect(() => {
    const launchDisabledTabs = [];
    if (!launch?.isNewsRelease) {
      launchDisabledTabs.push("2");
    }
    if (!launch?.isAssetCollection) {
      launchDisabledTabs.push("3");
    }
    if (!launch?.isEvent) {
      launchDisabledTabs.push("4");
    }
    if (!launch?.isProduct) {
      launchDisabledTabs.push("5");
    }
    setExcludedLaunchTabs(launchDisabledTabs);
  }, [launch]);

  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>{launch?.plan || `Launch Name`}</h1>
          <div
            style={{
              position: "absolute",
              top: "45px",
            }}>
            <Button
              color='primary'
              variant='text'
              onClick={() => history.push("/launches")}>
              <ArrowBackIos style={{ fontSize: "14px" }} />
              {`Back to All Launches`}
            </Button>
          </div>
        </div>
        <div className='tab-navigator' style={{ marginTop: "20px" }}>
          {launch ? (
            <TopTabNavigator
              getActiveTabId={tabId => {
                setActiveTabId(`${tabId}`);
              }}
              tabs={[
                {
                  id: "1",
                  title: "Overview",
                  component: (
                    <LaunchOverview
                      existinglaunch={launch}
                      isActive={activeTabId === "1"}
                    />
                  ),
                },
                {
                  id: "2",
                  title: "News Release",
                  component: (
                    <ReleaseTab
                      existinglaunch={launch}
                      isActive={activeTabId === "2"}
                    />
                  ),
                },
                {
                  id: "3",
                  title: "Assets",
                  component: (
                    <CollectionsTab
                      existinglaunch={launch}
                      isActive={activeTabId === "3"}
                    />
                  ),
                },
                {
                  id: "4",
                  title: "Events",
                  component: (
                    <EventsTab
                      existinglaunch={launch}
                      isActive={activeTabId === "4"}
                    />
                  ),
                },
                {
                  id: "5",
                  title: "Product",
                  component: (
                    <ProductsTab
                      existinglaunch={launch}
                      isActive={activeTabId === "5"}
                    />
                  ),
                },
                {
                  id: "6",
                  title: "Activity",
                  component: (
                    <LaunchActivities
                      launchId={launch?.id}
                      isActive={activeTabId === "6"}
                    />
                  ),
                },
                {
                  id: "7",
                  title: "Notes",
                  component: (
                    <LaunchNotes
                      launchId={launch?.id}
                      isActive={activeTabId === "7"}
                    />
                  ),
                },
              ].filter(t => !excludedLaunchTabs.includes(t.id))}
            />
          ) : (
            <TopTabNavigator
              getActiveTabId={tabId => {
                setActiveTabId(`${tabId}`);
              }}
              tabs={[
                {
                  id: "1",
                  title: "Overview",
                  component: <LaunchOverview />,
                },
              ]}
            />
          )}
        </div>
      </div>
    </Dashbard>
  );
});

export { NewLaunch };
