import React, { useState } from "react";

// import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import { Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { downarrow } from "../../../../resources/images/index";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select, { SelectChangeEvent } from "@material-ui/core/Select";
import { ReactComponent as SearchIcon } from "../../../../resources/images/Search.svg";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  List,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import AddContactDataModal from "./AddContactData";
import { EditFieldPopup } from "./Modals/EditFieldPopup";
import { EditListingPopup } from "./Modals/EditListingPopup";
import { EditFieldWithRadioPopup } from "./Modals/EditFieldWithRadioPopup";
import { EditPasswordFieldPopup } from "./Modals/EditPasswordFieldPopup";
import { EditTermsAndConditionFieldPopup } from "./Modals/EditTermsAndConditionFieldPopup";
import { EditListingWithCheckboxPopup } from "./Modals/EditListingWithCheckboxPopup";
import CustomCheckbox from "../../../../components/common/checkbox/CustomCheckbox";

const Accordion = withStyles({
  root: {
    width: "100%",
    padding: "16px 19px",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "&:not(:first-child)": {
      marginTop: "35px",
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      boxShadow: "0px 0px 2px 0px #70808f",
    },
    "&.MuiGrid-container": {
      width: "100%",
      display: "inline",
    },
    "&.MuiTextField-root": {
      width: "100% !important",
    },
  },
  expanded: {},
})(MuiAccordion);
const InputField = withStyles({
  root: {
    "&.MuiOutlinedInput-input": {
      height: "4px !important",
    },
    "&.MuiTextField-root": {
      width: "90% !important",
      background: "#FFFFFF",
      borderRadius: " 6px",
    },
  },
})(TextField);

let data = [
  {
    fieldName: "Contact First Nameeeeeeeeee",
    required: "Required",
  },
];

export default function ContactTypeAccess() {
  const [age, setAge] = React.useState("");

  const handleChange = e => {
    setAge(e.target.value);
  };
  //   const [expanded, setExpanded] = (React.useState < string) | (false > "panel1");

  //   const handleChange = panel => newExpanded => {
  //     setExpanded(newExpanded ? panel : false);
  //   };
  //   const classes = useStyles();
  const [expandedContact, setExpandedContactType] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  return (
    <>
      <Accordion
        square
        expanded={expandedContact}
        onChange={() => setExpandedContactType(!expandedContact)}
        className=''>
        <div
          className='accordianWithSaveBtn'
          style={{ padding: "1%", width: "100%" }}>
          <MuiAccordionSummary aria-controls='summary' id='summary-header'>
            <p className='accordionTitle'>
              <img
                src={downarrow}
                alt='arrow icon'
                className={`arrow-icon ${expanded ? "arrow-invert" : ""}`}
              />
              OFish Access Basics
            </p>
          </MuiAccordionSummary>
          <span className='accordianSaveBtn'>
            <Button color='primary' variant='contained' onClick={handleOpen}>
              Save
            </Button>
          </span>
        </div>
        <MuiAccordionDetails
          className='accordianDetails'
          style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <div className='contactTypeAccess'>
            <Box
              component='label'
              style={{
                borderBottom: "1px solid black",
                display: "inline-block",
              }}>
              Strict Access
            </Box>
            <List>
              <ListItem ContainerComponent='li'>
                <CustomCheckbox checkedId={"level"} />
                <ListItemText>
                  <Typography
                    component='h1'
                    style={{
                      display: "inline",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}>
                    Control OFish Access on a Contact Type Level ONLY -
                  </Typography>
                  <Typography component='span'>
                    Easily control OFish access and features <b> ONLY</b> based
                    on the Contact Type of a Contact.
                  </Typography>
                </ListItemText>
                <ListItemSecondaryAction></ListItemSecondaryAction>
              </ListItem>
              <ListItem ContainerComponent='li'>
                <CustomCheckbox checkedId={"group-level"} />
                <ListItemText>
                  <Typography
                    component='h1'
                    style={{
                      display: "inline",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}>
                    Control OFish Access on a Group Level ONLY -{" "}
                  </Typography>
                  <Typography component='span'>
                    Easily control OFish access and features <b> ONLY</b> based
                    on the Main Group of a Contact.
                  </Typography>
                </ListItemText>
                <ListItemSecondaryAction></ListItemSecondaryAction>
              </ListItem>
              <ListItem ContainerComponent='li'>
                <CustomCheckbox checkedId={"contsct-level"} />
                <ListItemText>
                  <Typography
                    component='h1'
                    style={{
                      display: "inline",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}>
                    Control OFish Access on an Individual Contact Level ONLY -{" "}
                  </Typography>
                  <Typography component='span'>
                    Easily control OFish access and features<b> ONLY</b> on an
                    Individual Contact Level.
                  </Typography>
                </ListItemText>
                <ListItemSecondaryAction></ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
          <div className='contactTypeAccess'>
            <Box
              component='label'
              style={{
                borderBottom: "1px solid black",
                display: "inline-block",
              }}>
              Customizable Access
            </Box>
            <div>
              <List>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"contact-type"} />
                  <ListItemText>
                    <Typography
                      component='h1'
                      style={{
                        display: "inline",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}>
                      Set Default Access Based On Contact Type, Allow
                      Modifications on a Group or Individual Contact Level -{" "}
                    </Typography>
                    <Typography component='span'>
                      When selected, OFish will override group and indivdual
                      access changes. Learn More
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </div>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        className=''>
        <div
          className='accordianWithSaveBtn'
          style={{ padding: "1%", width: "100%" }}>
          <MuiAccordionSummary aria-controls='summary' id='summary-header'>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}>
              <p className='accordionTitle'>
                <img
                  src={downarrow}
                  alt='arrow icon'
                  className={`arrow-icon ${expanded ? "arrow-invert" : ""}`}
                />
                OFish Feature Access
              </p>
            </div>
          </MuiAccordionSummary>
          <span className='accordianSaveBtn'>
            <Button color='primary' variant='contained' onClick={handleOpen}>
              Save
            </Button>
          </span>
        </div>
        <MuiAccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "70px",
          }}>
          <Grid container sx={12} spacing={1} style={{ display: "block" }}>
            <h4 style={{ margin: 0 }}>Martketing</h4>
            <div className='feature-access-list'>
              <List style={{ display: "flex" }}>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"news-release"} />
                  <ListItemText>
                    <Typography component='span'>News Release</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"asset-collection"} />
                  <ListItemText>
                    <Typography component='span'>Asset Collection</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"events"} />
                  <ListItemText>
                    <Typography component='span'>Events</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </Grid>
          <div></div>
          <Grid container spacing={1} style={{ display: "block" }}>
            <h4 style={{ margin: 0 }}>Product</h4>
            <div className='feature-access-list'>
              <List style={{ display: "flex" }}>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"product-news-rel"} />
                  <ListItemText>
                    <Typography component='span'>Purchase Products</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"product-asset-collection"} />
                  <ListItemText>
                    <Typography component='span'>Resell Products</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"product-events"} />
                  <ListItemText>
                    <Typography component='span'>
                      Share Product Access
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"product-events1"} />
                  <ListItemText>
                    <Typography component='span'>Register Products</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid container spacing={1} style={{ display: "block" }}>
            <h4 style={{ margin: 0 }}>Conversation</h4>
            <div className='feature-access-list'>
              <List style={{ display: "flex" }}>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"conversation-asset-collection"} />
                  <ListItemText>
                    <Typography component='span'>Join Conversations</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid container spacing={1} style={{ display: "block" }}>
            <h4 style={{ margin: 0 }}>Support</h4>
            <div className='feature-access-list'>
              <List style={{ display: "flex" }}>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"support-asset-collection"} />
                  <ListItemText>
                    <Typography component='span'>Support Articles</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
                <ListItem ContainerComponent='li'>
                  <CustomCheckbox checkedId={"support-asset-collection"} />
                  <ListItemText>
                    <Typography component='span'>Help Guides</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </Grid>
        </MuiAccordionDetails>
      </Accordion>
    </>
  );
}
