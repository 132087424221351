import React, { useRef, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { logoSign, loginLogo } from "../../../resources/images/index";
import { accountActions } from "../../../store/accounts/accountActions";
import Loader from "../../../components/common/Loader";
import curve from "../../../resources/images/curve.png";
import curveOverlay from "../../../resources/images/curveOverlay.png";
import WelcomeText from "./WelcomeText";

function Login(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    if (isAdmin !== null) {
      if (isAdmin === "admin") {
        props.history.push({
          pathname: "/launches",
          state: { isLogin: true },
        });
      } else {
        props.history.push("/");
      }
    }
  }, [props.history]);

  const handleSubmit = e => {
    // e.preventDefault();
    setLoading(true);
    dispatch(accountActions.onSubmit(email, password)).then(data => {
      if (data && data.data && data.data.success) {
        if (data.data.data.role === "admin") {
          props.history.push({
            pathname: "/dashboard",
            state: { isLogin: true },
          });
        } else if (
          ["customer", "company_user", "company_admin"].includes(
            data.data.data.role
          )
        ) {
          props.history.push("/");
        }
      } else {
        if (data && data.data) {
          toast.error(data.data.message);
        } else {
          toast.error("Password is not correct");
        }
      }

      setLoading(false);
    });
  };
  const loginForm = useRef("form");
  return (
    <div className='loggedinWrapper'>
      {isLoading ? <Loader /> : null}
      <div className='leftCol'>
        <WelcomeText />
        <span className='loginLeftshade' />
        <span className='loginRightShade'>
          <img src={curve} alt='' className='curve' />
          <img src={curveOverlay} alt='' className='curveOverlay' />
        </span>
      </div>
      <div className='rightCol'>
        <img src={loginLogo} alt='' />
        <img src={logoSign} alt='' style={{ marginLeft: "195px" }} />
        <span className='createAccount'>
          Sign In{" "}
          <i>
            or <Link to=''>Request Access</Link>
          </i>
        </span>
        {/* <form onSubmit={loginForm.handleSubmit}> */}
        <ValidatorForm ref={loginForm} onSubmit={handleSubmit}>
          <TextValidator
            id='email'
            label='Email or username'
            variant='outlined'
            name='email'
            fullWidth
            validators={["required", "isEmail"]}
            errorMessages={[
              "Please enter your email address",
              "Please enter a valid email address",
            ]}
            value={email}
            onChange={v => setEmail(v.target.value)}
          />

          <div className='gap' />
          <TextValidator
            id='password'
            label='Password'
            variant='outlined'
            name='password'
            fullWidth
            validators={["required"]}
            errorMessages={["Please enter your password"]}
            type={"password"}
            value={password}
            onChange={v => setPassword(v.target.value)}
          />

          <Button type='submit' color='primary' variant='contained'>
            Login
          </Button>
        </ValidatorForm>
        {/* </form> */}

        <span className='forgotpssword'>
          <Link to='/forgot-password'>Forgot Password?</Link>
        </span>
      </div>
    </div>
  );
}

export default withRouter(Login);
