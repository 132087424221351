import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import OutsideClickHandler from "react-outside-click-handler";
import InputField from "../common/InputField";

export default function AutoComplete({
  label,
  value,
  onChange,
  eventLocation,
  setEvent,
  onBlur,
  showRequired,
  isDisable,
  showLocationName = true,
}) {
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const showList = e => {
    setIsAutocomplete(true);
    onChange(e);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsAutocomplete(false)}>
      <div className='autoCompleteWrapper'>
        <Grid item xs={11} className='autompleteInner'>
          <InputField
            disabled={isDisable}
            placeholder={label}
            inputlabel={label}
            onChange={e => showList(e)}
            value={value}
            onBlur={onBlur}
            showRequired={showRequired}
          />
        </Grid>

        {isAutocomplete && (
          <div className='autoCompleteInnner'>
            <ul>
              {value !== "" &&
                eventLocation?.length > 0 &&
                eventLocation.map(x => (
                  <>
                    {x.value && x.value !== "" && (
                      <li
                        onClick={() => {
                          setIsAutocomplete(false);
                          setEvent(x);
                        }}>
                        {x.value}
                        {showLocationName &&
                          x.locationName &&
                          x.locationName !== "" && (
                            <span className='autoCompleLoc'>
                              {x.locationName}
                            </span>
                          )}
                      </li>
                    )}
                  </>
                ))}
              {eventLocation?.length === 0 && (
                <li onClick={() => setIsAutocomplete(false)}>
                  no record found
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}
