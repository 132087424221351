import { Button } from "@material-ui/core";
import React from "react";

function EventHeading({ eventDetail }) {
  return (
    <div className="InnerHeadingWrapper">
      <span>
        {eventDetail ? eventDetail.title : ""}

        <i>{eventDetail ? eventDetail.subTitle : ""}</i>
      </span>

      <span>
        <Button variant="contained">Tradeshows</Button>{" "}
      </span>
    </div>
  );
}

export default EventHeading;
