import React, { useState, useEffect, useCallback } from 'react';
import { Button, Divider, Box } from '@material-ui/core';
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import { ReactComponent as MediaCustomer } from '../../../../../resources/images/MediaC.svg';
import { ReactComponent as BusinessCustomer } from '../../../../../resources/images/BusinessC.svg';
import { ReactComponent as Vendor } from '../../../../../resources/images/Vendor.svg';
import { ReactComponent as AccountSearch } from '../../../../../resources/images/account-search.svg';
import { ReactComponent as AccountEndUser } from '../../../../../resources/images/AccountG.svg';
import { ReactComponent as Cross } from '../../../../../resources/images/x.svg';

import { Dialog, Checkbox, Input, Grid, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TopTabNavigator } from '../../../../../components/common/Tabs/TopTabNavigator';

export function EditListingPopup(props) {
  const Popup = withStyles({
    root: {
      '&.MuiDialog-paper': {
        maxWidth: '80% !important',
        width: '80% !important',
      },
    },
  })(Dialog);

  const [openModal, setOpenModal] = React.useState(props.open);
  useEffect(() => {
    if (props.open != undefined) {
      setOpenModal(true);
    }
  }, [props.open]);
  const handleOfishRole = item => {
    props.setValue(item);
  };
  const handleContactArea = item => {
    props.setValue(item);
    setOpenModal(false);
  };

  return (
    <div>
      <Popup
        style={{ maxWith: '70% !important' }}
        component='div'
        open={openModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className='edit-popup'>
        <Grid container>
          <Grid item sx={10} md={12} lg={12}>
            {props.ContactArea ? (
              <>
                <Typography
                  id='modal-modal-title'
                  style={{
                    padding: '3%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  variant='h6'
                  component='h1'>
                  Select OFish Contact Area
                  <Box
                    component='span'
                    onClick={() => setOpenModal(false)}
                    style={{
                      margin: '0',
                      display: 'inline-block',
                      fontFamily: 'cursive',
                      cursor: 'pointer',
                    }}>
                    <Cross />
                  </Box>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  id='modal-modal-title'
                  style={{
                    padding: '3%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  variant='h6'
                  component='h1'>
                  Select OFish Role
                  <Box
                    component='span'
                    onClick={() => setOpenModal(false)}
                    style={{
                      margin: '0',
                      display: 'inline-block',
                      fontFamily: 'cursive',
                      cursor: 'pointer',
                    }}>
                    <Cross />
                  </Box>
                </Typography>
              </>
            )}
            {/* <Typography
              id='modal-modal-title'
              style={{
                padding: '3%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              variant='h6'
              component='h1'>
              Select OFish Contact Area
              <Box
                component='span'
                onClick={() => setOpenModal(false)}
                style={{
                  margin: '0',
                  display: 'inline-block',
                  fontFamily: 'cursive',
                  cursor: 'pointer',
                }}>
                <Cross />
              </Box>
            </Typography> */}
          </Grid>
        </Grid>
        <Divider />
        <div className='contentDiv' style={{ padding: '0 2% 2% 2%' }}>
          <div>
            <div className='tab-navigator ' style={{ display: 'flex', alignItems: 'center' }}>
              <Divider />
              <TopTabNavigator
                style={{ marginBottom: '10px !important' }}
                tabs={[
                  {
                    id: '1',
                    title: 'All',
                    component: (
                      <>
                        <List style={{ height: '250px', overflowY: 'scroll' }}>
                          {props?.contactData?.map((item, index) => (
                            <>
                              <Divider />
                              <ListItem ContainerComponent='li'>
                                {props.showRadioButton == true ? (
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      marginRight: '2%',
                                    }}>
                                    <input
                                      type='radio'
                                      checked={props.value === item.id ? true : false}
                                      onClick={() => {
                                        handleOfishRole(item);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <ListItemIcon>
                                    {item.id === 1 && <MediaCustomer />}
                                    {item.id === 2 && <BusinessCustomer />}
                                    {item.id === 3 && <AccountSearch />}
                                    {item.id === 4 && <AccountEndUser />}
                                    {item.id === 5 && <Vendor />}
                                  </ListItemIcon>
                                )}
                                <ListItemText>
                                  <Typography
                                    onClick={() => {
                                      handleContactArea(item);
                                      {
                                        console.log('--->', item);
                                      }
                                    }}
                                    component='h1'
                                    style={{
                                      display: 'inline',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      cursor: 'pointer',
                                      color: props.value === item.id ? '#3699FF' : 'black',
                                    }}>
                                    {item.name} -{' '}
                                  </Typography>
                                  <Typography
                                    component='span'
                                    style={{
                                      color: props.value === item.id ? '#3699FF' : 'black',
                                    }}>
                                    {item.description}
                                  </Typography>
                                </ListItemText>
                                <ListItemSecondaryAction></ListItemSecondaryAction>
                              </ListItem>
                            </>
                          ))}
                          {props.save && (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                style={{
                                  // position: 'absolute',
                                  // top: '126px',
                                  // right: '30px',
                                  padding: '9px 30px',
                                  marginRight: '1%',
                                }}
                                color='primary'
                                variant='contained'
                                onClick={() => {
                                  setOpenModal(false);
                                }}>
                                Save
                              </Button>
                            </div>
                          )}
                        </List>

                        {props.showRadioButton == true ?? <input type='button' />}
                      </>
                    ),
                  },
                  {
                    id: '2',
                    title: 'OFish Present Contact Areas',
                    component: (
                      <>
                        <List style={{ height: '250px', overflowY: 'scroll' }}>
                          {props?.contactData?.map((item, index) => (
                            <>
                              <Divider />
                              <ListItem ContainerComponent='li'>
                                {props.showRadioButton == true ? (
                                  <input type='radio' />
                                ) : (
                                  <ListItemIcon>
                                    {item.id === 1 && <MediaCustomer />}
                                    {item.id === 2 && <BusinessCustomer />}
                                    {item.id === 3 && <AccountSearch />}
                                    {item.id === 4 && <AccountEndUser />}
                                    {item.id === 5 && <Vendor />}
                                  </ListItemIcon>
                                )}
                                <ListItemText>
                                  <Typography
                                    component='h1'
                                    style={{
                                      display: 'inline',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      color: props.value === item.id ? '#3699FF' : 'black',
                                    }}>
                                    {item.name} -{' '}
                                  </Typography>
                                  <Typography
                                    component='span'
                                    style={{
                                      color: props.value === item.id ? '#3699FF' : 'black',
                                    }}>
                                    {item.description}
                                  </Typography>
                                </ListItemText>
                                <ListItemSecondaryAction></ListItemSecondaryAction>
                              </ListItem>
                              <Divider />
                            </>
                          ))}
                        </List>
                      </>
                    ),
                  },
                  {
                    id: '3',
                    title: 'Your Other Areas',
                    component: <>abc</>,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}
