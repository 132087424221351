import { toast } from "react-toastify";
import { productInventoryApi } from "../product/productInventoryApi";
import { softwareBuildApi } from "./SoftwareBuildApi";
import { softwareBuildTypes } from "./SoftwareBuildTypes";


const onChange = (field, value) => {
    return dispatch => {
        dispatch({
            type: softwareBuildTypes.SOFTWAREUPDATES_ONCHANGE_BUILDS,
            payload: { prop: field, value: value },
        });
    };
};

const updateSelectedBuildContacts = (field, value) => {
    return {
        type: softwareBuildTypes.UPDATE_SELECTED_RELEASE_CONTACTS_BUILDS,
        payload: { prop: { key: field, value: value } },
    };
};

const removeSelectedReleaseContacts = (field, value) => {
    return {
        type: softwareBuildTypes.ON_REMOVE_SELECTED_PORTAL_CONTACT_BUILDS,
        payload: { prop: { key: field, value: value } },
    };
};

const onSearchUpdateProduct = (value) => {
    return dispatch => {
        dispatch({
            type: softwareBuildTypes.SEARCH_UPDATEPRODUCT_BUILDS,
            payload: { prop: value },
        });
    };
};

const onUpdateBuildTab = (value) => {
    return dispatch => {
        dispatch({
            type: softwareBuildTypes.BUILD_UPDATES_TAB_BUILDS,
            payload: { prop: value },
        });
    };
};

const onSelectedReleaseFieldChange = (value) => {
    return dispatch => {
        dispatch({
            type: softwareBuildTypes.ON_CHANGE_SELECTED_RELEASE_FIELD_BUILDS,
            payload: { prop: value }
        });
    };
};

const onPoratalAndContactFieldChange = (value) => {
    return dispatch => {
        dispatch({
            type: softwareBuildTypes.ON_CHANGE_PORTAL_CONTACT_FIELD_BUILDS,
            payload: { prop: value }
        });
    };
};
const onSaveBuildInfo = (releaseData) => {
    let isNew = true;
    if (releaseData.id) {
        isNew = false;
    }
    return async dispatch => {
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareBuildApi.addUpdateBuild(releaseData);
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuild', value: response.data } } });
            toast.success(isNew ? `Build ${releaseData.nameInternal} Added Successfully` : `Build ${releaseData.nameInternal} Updated Successfully`);
        } else {
            toast.error(response?.message ?? 'Something went wrong!');
        }
    };
};

const onSaveAddUpdateSRBuildPortalandContacts = (portalData) => {
    return async dispatch => {
        const response = await softwareBuildApi.addUpdateSRBuildPortalandContacts(portalData);
        if (response?.success) {
            toast.success('Software Build Requirements updated!');
            const portalsData = await softwareBuildApi.getSoftwareReleaseBuildPortalById(portalData.softwareReleaseBuildId);
            if (portalsData?.success) {
                const portalData = portalsData.data.map(x => { return { id: x.portal.id, portalId: x.id, name: x.portal.name, description: x.portal.description } });
                dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildPortal', value: portalData } } });
                dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'portalBuildIds', value: portalData.map(x => x.id) } } });
            }
        } else {
            toast.error(response?.message ?? 'Something went wrong!');
        }
    };
};

const onAddUpdateBuildProducts = (productsData) => {
    const isUpdated = productsData.find(product => product.id !== 0);
    const { softwareReleaseId, softwareReleaseBuildId } = productsData[0];
    return async dispatch => {
        const response = await softwareBuildApi.addUpdateSoftwareReleaseBuildProducts(productsData);
        if (response?.success) {
            toast.success(isUpdated ? 'Products updated successfully' : 'Products added to Build');
            const products = await softwareBuildApi.getSoftwareReleaseBuildProducts({
                softwareReleaseId,
                softwareReleaseBuildId,
                pagination: {
                    orderByKey: "Id",
                    orderByVal: 1
                }
            });
            if (products?.success) {
                dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildProducts', value: products.data } } });
            }
        } else {
            toast.error(response?.message ?? 'Something went wrong!')
        }
    };
};

const onGetReleaseCompatibleProducts = () => {
    return async dispatch => {
        const response = await softwareBuildApi.getReleaseCompatibleProducts();
        if (response?.success) {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'compatibleBuildProductsList', value: response?.data ?? [] } } })
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'productsBuildList', value: response.data } } })
        }
    };
};

const onGetNotificationsType = () => {
    return async dispatch => {
        const response = await softwareBuildApi.getNotificationsType();
        if (response?.success) {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'notificationBuildTypes', value: response?.data ?? [] } } });
        }
    };
};

const onAddUpdateSoftwareReleaseBuildNotification = (notificationData) => {
    return async dispatch => {
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareBuildApi.addUpdateSoftwareReleaseBuildNotification(notificationData);
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            toast.success(notificationData.id === 0 ? `New Notification Added Successfully` : `Notification Updated Successfully`);
            const notificationsData = await softwareBuildApi.getSoftwareReleaseBuildNotification(notificationData?.softwareReleaseBuildId);
            if (notificationsData?.success) {
                dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildNotifications', value: notificationsData.data } } });
            }
        } else {
            toast.error(response?.message ?? 'Something went wrong!');
        }
    };
};

const onGetSoftwareBuildById = (releaseId) => {
    return async dispatch => {
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareBuildApi.getSoftwareBuildReleaseById(releaseId);
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuild', value: response?.data ?? {} } } });
            dispatch({ type: softwareBuildTypes.ON_SOFTWARE_RELEASE_DATA_BUILDS, payload: { prop: { value: response?.data } } });
        } else {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'errorMessage', value: response?.message } } });
        }
    };
};

const onGetSoftwareContactsReleaseBuildById = (releaseId) => {
    return async dispatch => {
        const response = await softwareBuildApi.getSoftwareContactsReleaseBuildById(releaseId);
        if (response?.success) {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildContacts', value: response?.data } } })
        }
    }
};

const onGetSoftwareReleaseBuildPortalById = (releaseId) => {
    return async dispatch => {
        const response = await softwareBuildApi.getSoftwareReleaseBuildPortalById(releaseId);
        if (response?.success) {
            const portalData = response.data.map(x => { return { id: x.portal.id, portalId: x.id, name: x.portal.name, description: x.portal.description } });
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildPortal', value: portalData } } });
        }
    }
};

const onGetSoftwareReleaseBuildProducts = (queryData) => {
    return async dispatch => {
        const response = await softwareBuildApi.getSoftwareReleaseBuildProducts(queryData);
        if (response?.success) {
            const productsData = response.data.map(product => ({ ...product, value: product.productName, }))
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildProducts', value: productsData } } });
        }
    }
};

const onGetSoftwareReleaseBuildNotification = (releaseId) => {
    return async dispatch => {
        const response = await softwareBuildApi.getSoftwareReleaseBuildNotification(releaseId);
        if (response?.success) {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildNotifications', value: response.data } } });
        }
    };
};

const onResetSoftwareBuildData = () => {
    return async dispatch => {
        dispatch({ type: softwareBuildTypes.RESET_SOFTWARE_RELEASE_DATA_BUILDS })
    };
}

const onGetSoftwareReleaseBuildDetails = (releaseId) => {
    return async (dispatch) => {
        const response = await softwareBuildApi.getSoftwareReleaseBuildDetails(
            releaseId
        );
        if (response?.success) {
            dispatch({
                type: softwareBuildTypes.ON_CHANGE_BUILDS,
                payload: {
                    prop: { key: "softwareBuildDetials", value: response.data },
                },
            });
        }
    };
};

const onGetContactsForBuild = (customerType) => {
    return async (dispatch) => {
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: "isLoading", value: true } } });
        const response = await productInventoryApi.getEndCustomerGroups({
            CustomerTypeName: customerType,
        });
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: "isLoading", value: false } } });

        if (response?.success) {
            dispatch({
                type: softwareBuildTypes.ON_CHANGE_BUILDS,
                payload: { prop: { key: "customerContactBuildData", value: response.data } },
            });
        }
    };
};

const onAddSoftwareReleaseBuildDetails = (selectedData) => {
    return async dispatch => {
        const response = await softwareBuildApi.addSoftwareReleaseBuildDetails(selectedData);
        if (response?.success) {
            toast.success(selectedData.id === 0 ? `Build Plan Added Successfully` : `Build Plan Updated Successfully`);
        } else {
            toast.error(response?.message ?? 'Something went wrong!');
        }
    }
};

const onGetAllSoftwareReleasesBuild = (queryData) => {
    return async dispatch => {
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareBuildApi.getAllSoftwareReleases(queryData);
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildVersions', value: response.data.data } } });
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'paginationBuildResponse', value: response.data.pagination } } });
        } else {
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildVersions', value: [] } } });
            dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'paginationBuildResponse', value: {} } } });
            toast.error(response.message ?? 'Something went wrong!');
        }
    };
};

const onMarkUnMarkSoftwareReleaseBuildFavourite = (queryData) => {
    return async dispatch => {
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareBuildApi.markUnMarkSoftwareReleaseBuildFavourite(queryData);
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            toast.success('Software Build Updated Successfully!');
            dispatch({ type: softwareBuildTypes.ON_RELEASE_FAVOURITE_BUILDS, payload: { prop: { ...queryData } } });
        } else {
            toast.error(response.message ?? 'Something went wrong!');
        }
    };
};

const onDeleteSoftwareReleaseBuild = (releaseId) => {
    return async dispatch => {
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareBuildApi.deleteSoftwareReleaseBuild(releaseId);
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            dispatch({ type: softwareBuildTypes.ON_DELETE_RELEASE_DATA_BUILDS, payload: { prop: { releaseId } } })
            toast.success('Software Build Deleted Successfully!');
        } else {
            toast.error(response.message ?? 'Something went wrong!');
        }
    };
};

const onDeleteSoftwareReleaseBuildNotifiacation = (notificationId, releaseId) => {
    return async dispatch => {
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareBuildApi.deleteSoftwareReleaseBuildNotifiacation(notificationId);
        dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            toast.success('Software Build Notification Deleted Successfully!');
            const notificationData = await softwareBuildApi.getSoftwareReleaseBuildNotification(releaseId);
            if (notificationData?.success) {
                dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildNotifications', value: notificationData?.data ?? [] } } });
            } else {
                dispatch({ type: softwareBuildTypes.ON_CHANGE_BUILDS, payload: { prop: { key: 'selectedBuildNotifications', value: [] } } });
            }
        } else {
            toast.error(response?.message ?? 'Something went wrong while deleting notification!');
        }
    };
}

export const softwareBuildActions = {
  onChange: onChange,
  onSearchUpdateProduct: onSearchUpdateProduct,
  onUpdateBuildTab: onUpdateBuildTab,
  onSelectedReleaseFieldChange: onSelectedReleaseFieldChange,
  onPoratalAndContactFieldChange: onPoratalAndContactFieldChange,
  onSaveBuildInfo: onSaveBuildInfo,
  onGetReleaseCompatibleProducts: onGetReleaseCompatibleProducts,
  onGetContactsForBuild: onGetContactsForBuild,
  onGetNotificationsType: onGetNotificationsType,
  onAddUpdateSoftwareReleaseBuildNotification:
    onAddUpdateSoftwareReleaseBuildNotification,
  onGetSoftwareReleaseBuildNotification: onGetSoftwareReleaseBuildNotification,
  updateSelectedBuildContacts: updateSelectedBuildContacts,
  onGetSoftwareBuildById: onGetSoftwareBuildById,
  onGetSoftwareContactsReleaseBuildById: onGetSoftwareContactsReleaseBuildById,
  onGetSoftwareReleaseBuildPortalById: onGetSoftwareReleaseBuildPortalById,
  onGetSoftwareReleaseBuildProducts: onGetSoftwareReleaseBuildProducts,
  onSaveAddUpdateSRBuildPortalandContacts:
    onSaveAddUpdateSRBuildPortalandContacts,
  onAddUpdateBuildProducts: onAddUpdateBuildProducts,
  onAddSoftwareReleaseBuildDetails: onAddSoftwareReleaseBuildDetails,
  onGetSoftwareReleaseBuildDetails: onGetSoftwareReleaseBuildDetails,
  onResetSoftwareBuildData: onResetSoftwareBuildData,
  onGetAllSoftwareReleasesBuild: onGetAllSoftwareReleasesBuild,
  onDeleteSoftwareReleaseBuild: onDeleteSoftwareReleaseBuild,
  onMarkUnMarkSoftwareReleaseBuildFavourite: onMarkUnMarkSoftwareReleaseBuildFavourite,
  onDeleteSoftwareReleaseBuildNotifiacation: onDeleteSoftwareReleaseBuildNotifiacation,
  removeSelectedReleaseContacts: removeSelectedReleaseContacts,
};
