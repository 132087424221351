import React, { useState, useEffect } from "react";
import InputField from "../../../../../components/common/InputField";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Dropdown } from "../../../../../components/common";
import { EndUserCustomersModal } from "../EndUserCustomersModal";
import { FormattedDateInput } from "../../../../../components/common/FormattedDateInput";

function DeviceDetail({
  loadData,
  startLoading,
  stopLoading,
  userId,
  isEditOverView,
  hideProductDetail,
  deviceName,
  setDeviceName,
  issOverviewStatus,
  setIsOverviewStatus,
  warrantyExpiration,
  setWarrantyExpiration,
  env,
  setEnv,
  deviceType,
}) {
  const dispatch = useDispatch();
  const { deviceOverviewInfo, envWithDeviceCount } = useSelector(
    state => state.endUserReducer
  );

  const [dNameError, setDNameError] = useState(false);
  const [open, setOpen] = useState(false);
  const [isNewProductPricing, setIsNewProductPricing] = useState(false);
  const [isOwnership, setIsOwnership] = useState(true);
  useEffect(() => {
    if (!isEditOverView) {
      setDeviceName("");
      setDNameError(false);
      setOpen(false);
      setIsNewProductPricing(false);
      setIsOwnership(true);
    } else {
      setDeviceName(deviceOverviewInfo?.name);
    }
  }, [isEditOverView, deviceOverviewInfo]);

  const onCheckDevName = () => {
    setDeviceName(deviceName.trim());
    if (deviceName !== "" && deviceName.length > 25) {
      setDNameError(true);
    } else {
      setDNameError(false);
    }
  };
  const openEndUserModal = isbutton => {
    setOpen(true);
    if (isbutton === "Remove Device") {
      setIsNewProductPricing(false);
    } else {
      setIsNewProductPricing(true);
    }
  };

  return (
    <>
      {deviceOverviewInfo ? (
        <div className='deviceDetailMainContainer'>
          <div className='deviceDetailOverviewInner'>
            <div className='devicePicture'>
              {deviceOverviewInfo?.image && (
                <img
                  className=' devicedDtailImageOverView'
                  src={deviceOverviewInfo?.image}
                  alt=''
                />
              )}
            </div>

            <div className='deviceOverViewDetail'>
              <div className='headingDetail'>Device Overview</div>
              <span className='deviceDetailOverView'>
                <ul>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>Name</span>
                    {!isEditOverView && deviceOverviewInfo?.name}
                    {isEditOverView && (
                      <div className='autoCompleteInputContainer OverViewInputNameTitle'>
                        <InputField
                          value={deviceName}
                          onChange={e => setDeviceName(e.target.value)}
                          onBlur={() => onCheckDevName()}
                        />
                      </div>
                    )}
                    {dNameError ? (
                      <span style={{ color: "red" }}>
                        {"Name characters cannot exceed from 25."}
                      </span>
                    ) : null}
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>SKU</span>
                    <span className='deviceDetailProperty'>{`${
                      deviceOverviewInfo?.sku ?? "--"
                    }`}</span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Serial Number
                    </span>
                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.serialNo}
                    </span>
                  </li>
                  <li
                    style={{ position: "relative" }}
                    className='deviceDetailView'>
                    <span className='deviceDetailProperties'>Environment</span>

                    {isEditOverView ? (
                      <div className='autoCompleteInputContainer nameInputOverView'>
                        <Dropdown
                          value={env}
                          required={false}
                          customMenuItems={envWithDeviceCount}
                          handleChange={({ target }) => setEnv(target.value)}
                        />
                      </div>
                    ) : (
                      <span className='deviceDetailProperty'>
                        {deviceOverviewInfo?.environmentName}
                      </span>
                    )}
                  </li>
                  {deviceType === "Physical + IOT" && (
                    <>
                      <li className='deviceDetailView'>
                        <span className='deviceDetailProperties'>
                          Last Connected
                        </span>
                        <span className='deviceDetailProperty'>
                          {deviceOverviewInfo?.lastConnected ?? "--"}
                        </span>
                      </li>
                      <li className='deviceDetailView'>
                        <span className='deviceDetailProperties'>
                          Software/Firmware Version
                        </span>
                        <span className='deviceDetailProperty'>
                          {deviceOverviewInfo?.softwareVersion
                            ? deviceOverviewInfo?.softwareVersion
                            : "--"}
                        </span>
                      </li>
                    </>
                  )}
                  <li className='deviceDetailView'>
                    <span>Warranty Status</span>
                    {isEditOverView ? (
                      <div
                        className={
                          issOverviewStatus === "Pending"
                            ? "statusDropDownPending"
                            : null || issOverviewStatus === "Active"
                            ? "statusDropDownRegistration"
                            : null || issOverviewStatus === "Register"
                            ? "statusDropDownRegistration"
                            : null || issOverviewStatus === "Expired"
                            ? "statusDropDownExpired"
                            : null
                        }>
                        <Dropdown
                          selectlabel=''
                          value={issOverviewStatus}
                          handleChange={({ target }) => {
                            setIsOverviewStatus(target.value);
                          }}
                          customMenuItems={[
                            { id: "Active", name: "Active" },
                            { id: "Expired", name: "Expired" },
                          ]}
                        />
                      </div>
                    ) : (
                      <div
                        className={
                          issOverviewStatus === "Active"
                            ? "statusRegistration"
                            : null || issOverviewStatus === "Pending Approval"
                            ? "statusPending"
                            : null || issOverviewStatus === "Pending"
                            ? "statusPending"
                            : null || issOverviewStatus === "Register"
                            ? "statusRegistration"
                            : null || issOverviewStatus === "Expired"
                            ? "statusExpired"
                            : "warrantyStatus"
                        }>
                        {issOverviewStatus}
                      </div>
                    )}
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Warranty Expiration
                    </span>

                    {isEditOverView ? (
                      <div className='autoCompleteInputContainer nameInputOverView'>
                        <FormattedDateInput
                          date={warrantyExpiration}
                          setDate={setWarrantyExpiration}
                        />
                      </div>
                    ) : (
                      <span>{deviceOverviewInfo?.expiryDate}</span>
                    )}
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Warranty Remaining
                    </span>
                    <span className='deviceDetailProperty deviceDetailNotCap'>
                      {deviceOverviewInfo?.remainingDays}
                    </span>
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div className='deviceDetailbuttons'>
            {/* <Button
              className='transferOnwershipBtn'
              onClick={() => {
                openEndUserModal("Transfer Ownership");
              }}>
              Transfer Ownership
            </Button> */}
            <Button
              className='removeDeviceBtn'
              onClick={() => {
                openEndUserModal("Remove Device");
              }}>
              Remove Device
            </Button>
          </div>
          <EndUserCustomersModal
            open={open}
            enduserModal={() => {
              setOpen(false);
              setIsOwnership(true);
            }}
            isNewProductPricing={isNewProductPricing}
            setIsOwnership={setIsOwnership}
            isOwnership={isOwnership}
            hideProductDetail={hideProductDetail}
            devId={deviceOverviewInfo?.id}
          />
        </div>
      ) : (
        <div>
          <span>No Data available</span>
        </div>
      )}
    </>
  );
}

export default DeviceDetail;
