import React, { useState, useEffect } from "react";
import Textarea from "../../../../components/common/Textarea";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BiDotsVerticalRounded } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";
import { GridAddIcon } from "@material-ui/data-grid";

const useStyles = makeStyles(theme => ({
  activityButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
    backgroundColor: "#E5F2FF",
    padding: "8px 35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#E5F2FF",
    },
  },
}));

function CustomerNotes({ isActive, isOverview }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();

  const [isEdit, setIsEdit] = useState(false);
  const [isDotNav, setIsDotNav] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [allUserNotes, setAllUserNotes] = useState([]);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [editNote, setEditNote] = useState("");
  const [newNotes, setNewNotes] = useState("");

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (isActive) {
      if (selectedUser?.id) {
        setUserId(selectedUser.id);
        loadNotes(selectedUser.id);
      }
    }
  }, [location, isActive]);

  const loadNotes = userId => {
    const noOfRecord = isOverview ? 2 : 100;
    const getNoteUrl = `${"/Customers/GetCustomerNotes?UserId="}${userId}${"&PageNumber=1&NoOfRecords="}${noOfRecord}`;
    setLoading(true);
    dispatch(endUserActions.onGetUserNotes(getNoteUrl)).then(data => {
      if (data && data.success) {
        setAllUserNotes(data.data);
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
      setLoading(false);
    });
  };

  const updateNotePress = item => {
    if (editNote !== "") {
      const notesData = {
        id: item.id,
        title: "",
        notes: editNote,
        userId: userId,
        isDeleted: false,
      };
      setLoading(true);
      setIsEdit(false);
      dispatch(endUserActions.onAddUpdateUserNotes(notesData)).then(data => {
        setLoading(false);
        if (data && data.success) {
          setAllUserNotes(data.data);
          setEditNote("");
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
      });
    }
  };

  const addNotePress = () => {
    if (newNotes !== "") {
      const notesData = {
        id: 0,
        title: "",
        notes: newNotes,
        userId: userId,
        isDeleted: false,
      };
      setLoading(true);
      setIsEdit(false);
      dispatch(endUserActions.onAddUpdateUserNotes(notesData)).then(data => {
        if (data && data.success) {
          loadNotes(userId);
          setNewNotes("");
        } else {
          setLoading(false);
          if (data) {
            toast.error(data.message);
          }
        }
      });
    }
  };

  const onDeletePress = () => {
    setLoading(true);
    dispatch(endUserActions.onDeleteUserNotes(selectedNoteId)).then(data => {
      if (data && data.success) {
        loadNotes(userId);
      } else {
        setLoading(false);
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };

  const editNoteClick = item => {
    setIsEdit(true);
    setIsDotNav(false);
    setEditNote(item.notes);
  };

  return (
    <div className='customerNotesWrapper'>
      {isLoading ? <Loader /> : null}
      <div className='customerContactInner'>
        <div className='customerContactHeader'>
          Customer Notes (Internal){" "}
          <i>{`${allUserNotes ? allUserNotes.length : ""}${" notes"}`}</i>
        </div>
        <div className='editorWrapper '>
          <div className='notesInnerWrapper'>
            <span className='writerPic'></span>
            <span className='notesTextarea'>
              <Textarea
                value={newNotes}
                onChange={e => setNewNotes(e.target.value)}
                onBlur={() => setNewNotes(newNotes.trim())}
                placeholder='What do you want to say?'
              />
            </span>
          </div>
          <Button
            color='primary'
            variant='contained'
            style={{ padding: "8px 12px", minWidth: "50px" }}
            onClick={() => addNotePress()}>
            <GridAddIcon />
          </Button>
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            setSelectedNoteId(null);
            setIsDotNav(false);
            setIsEdit(false);
          }}>
          {allUserNotes &&
            allUserNotes.length > 0 &&
            allUserNotes.map((item, index) => {
              return (
                <div key={index} className='editorWrapper editorComments'>
                  <div className='picWrapper'>
                    <span className='navDots'>
                      <span className='dotNavInner'>
                        <BiDotsVerticalRounded
                          onClick={() => {
                            setIsEdit(false);
                            setSelectedNoteId(item.id);
                            setIsDotNav(!isDotNav);
                          }}
                        />
                        {isDotNav && selectedNoteId === item.id && (
                          <span className='editDropDown'>
                            <ul>
                              <li>
                                <Button onClick={() => editNoteClick(item)}>
                                  Edit
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => onDeletePress()}>
                                  Delete
                                </Button>
                              </li>
                            </ul>
                          </span>
                        )}
                      </span>
                    </span>

                    <span className='writerPic'>
                      <img
                        style={{ maxWidth: "44px", maxHeight: "44px" }}
                        src={item.image}
                        alt=''
                      />
                    </span>
                    <span className='name'>
                      {item.tItle ? item.tItle : ""} <i>{item.notesDate}</i>
                    </span>
                  </div>
                  {isEdit && selectedNoteId === item.id ? (
                    <>
                      <span className='notesTextarea'>
                        <Textarea
                          value={editNote}
                          onChange={e => setEditNote(e.target.value)}
                          placeholder='What do you want to say?'
                          onBlur={() =>
                            editNote !== "" ? setEditNote(editNote.trim()) : ""
                          }
                        />
                      </span>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => updateNotePress(item)}>
                        Update Note
                      </Button>
                    </>
                  ) : (
                    <p>{item.notes}</p>
                  )}
                </div>
              );
            })}
        </OutsideClickHandler>
        {/* <div className="editorWrapper editorComments">
          <div className="picWrapper">
            <OutsideClickHandler onOutsideClick={() => setIsDotNav(false)}>
              <span className="navDots">
                <span className="dotNavInner">
                  <BiDotsVerticalRounded
                    onClick={() => setIsDotNav(!isDotNav)}
                  />
                  {isDotNav && (
                    <span className="editDropDown">
                      <ul>
                        <li>
                          <Button onClick={editNote}>Edit</Button>
                        </li>
                        <li>
                          <Button>Delete</Button>
                        </li>
                      </ul>
                    </span>
                  )}
                </span>
              </span>
            </OutsideClickHandler>
            <span className="writerPic"></span>
            <span className="name">
              Grace Logan <i>Yesterday at 5:06 PM</i>
            </span>
          </div>
          {!isEdit && (
            <p>
              Outlines keep you honest. They stop you from indulging in poorly
              thought-out metaphors about driving and keep you focused on the
              overall structure of your post
            </p>
          )}

          {isEdit && (
            <>
              <span className="notesTextarea">
                <Textarea placeholder="What do you want to say?" />
              </span>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsEdit(false)}>
                Update Note
              </Button>
            </>
          )}
        </div>

        <div className="editorWrapper editorComments">
          <div className="picWrapper">
            <span className="writerPic"></span>
            <span className="name">
              Grace Logan <i>Yesterday at 5:06 PM</i>
            </span>
          </div>
          {!isEdit && (
            <p>
              Outlines keep you honest. They stop you from indulging in poorly
              thought-out metaphors about driving and keep you focused on the
              overall structure of your post
            </p>
          )}

          {isEdit && (
            <span className="notesTextarea">
              <Textarea placeholder="What do you want to say?" />
            </span>
          )}

          <Button
            color="primary"
            variant="contained"
            onClick={() => setIsEdit(true)}>
            Update Note
          </Button>
        </div> */}
      </div>
    </div>
  );
}

export default CustomerNotes;
