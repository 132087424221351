import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Tab } from "@material-ui/core";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import Loader from "../../../../components/common/Loader";
import { GreenButton } from "../../../../components/common";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import { SingleProductPricingNew } from "./SingleProductPricingNew";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { PricingAvailabilityPeriods } from "./PricingAvailabilityPeriods";
import { AccessAlarm, Add, Cancel, CheckCircle } from "@material-ui/icons";
import { TopTabNavigatorPricing } from "../../../../components/common/Tabs/TopTabNavigatorPricing";
import { ProductCustomerGroupDescription } from "./ProductCustomerGroupDescription";
import { makeStyles } from "@material-ui/core/styles";
import { searchIcon } from "../../../../resources/images";
import {
  FilterPricingIcon,
  GridListIcon,
  TableGrid,
  DropdowArrow,
} from "../../LeftNav/SvgIcons";
import InputField from "../../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddNewPricingModal from "./AddNewPricingModal";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { useDropdownValues } from "../../../../utils/use-dropdown-values";
import { endpoints } from "../../../../config/apiConfig";

const useStyless = makeStyles(theme => ({
  gridWrapper: {
    paddingTop: "40px",
    paddingBottom: "15px",
    borderBottom: "1px solid #E2E6E9",
    background: "red",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  dropdownBg: {
    background: "#F1F1F1",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    border: "1px solid #F3F6F9",
    backgroundColor: "#F3F6F9",
    borderRadius: "6px",
    "&:before": {
      borderBottom: "1px solid #F3F6F9",
      "&:hover": {
        borderBottom: "0 !important",
      },
    },
    "&:after": {
      borderBottom: "1px solid #F3F6F9",
    },
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9",
      },
    },
  },
  filterIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "38px",
    height: "24px",
    borderRadius: "6px",
    backgroundColor: "#3699FF",
    position: "absolute",
    right: "14px",
    top: "10px",
  },
}));

const ProductAttributesGridFilter = ({ onPressFilterBtn }) => {
  const [search, setSearch] = useState("");
  const itemSearch =(e)=>{
    setSearch(e.target.value);
    onPressFilterBtn(e.target.value);
  }

  return (
    <div className='pricingGridFilterContainer'>
      <ValidatorForm ref={useRef("form")}>
        <Grid className='filterMainSearch'>
          <img src={searchIcon} alt='' className='searchIcon' />
          <InputField
            inputlabel='Search'
            placeholder={"Search Pricing"}
            value={search}
              onChange={(e)=>{itemSearch(e)}}
          />
          {/* <Box
            className={classes.filterIcon}
            onClick={() => {
              onPressFilterBtn(search);
            }}>
            <FilterPricingIcon />
          </Box> */}
        </Grid>
      </ValidatorForm>
    </div>
  );
};

export const ProductPricingStoreNew = ({ activeProductId }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isMassEditing, setMassEditing] = useState(false);
  const [isNewProductPricing, setIsNewProductPricing] = useState(false);
  const [priceName, setPriceName] = useState("");
  const [productPricingGroups, setProductGroups] = useState([]);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [productPricesList, setProductPrices] = useState([]);
  const [allProductPrices, setAllProductPrices] = useState([]);
  const [productPricesDispList, setProductPricesDispList] = useState([]);
  const [visibilityGroups, setVisibilityGroups] = useState([]);
  const [allVisibilityGroups, setAllVisibilityGroups] = useState([]);

  const excludedCustomers = ["Everyone", "MSRP Price", "MAP Price"];
  // pagination states
  const [filters, setFilters] = useState({
    product: "",
    categoryId: null,
    dateCreated: null,
    status: null,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "",
    orderVal: 0,
  });
  const [selectedtTab, setTab] = useState("Current");
  const [isPriceAvailabilitySection, setPriceAvailability] = useState(false);

  // Description Tabs
  const [activeStoreDescriptionTabId, setStoreDescriptionTabId] = useState("");
  const [isProductDescSave, setIsProductDescSave] = useState(false);
  const [activePriceListDescTabId, setPriceListDescTabId] = useState("");
  const [isStorePriceDescSave, setIsStorePriceDescSave] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [allAvailableTimes, setAllAvailableTimes] = useState([]);
  const [open, setOpen] = useState(false);
  const [editRow, setEditableRow] = useState([]);
  const [massEditRows, setMassEditableRows] = useState([]);

  const [storeDescTabValue, setStoreDescTabValue] = useState("");
  const [priceListTabValue, setPriceListTabValue] = useState("");

  const handleChangetabs = (event, newValue, isStoreDescTab) => {
    if (isStoreDescTab) {
      setStoreDescTabValue(newValue);
    } else {
      setPriceListTabValue(newValue);
    }
  };

  const addPriceModal = () => {
    setOpen(!open);
  };

  const handleTabChange = (event, newValue) => {
    setEditableRow([]);
    setMassEditableRows([]);
    setMassEditing(false);
    setTab(newValue);
  };

  const handleSearch = searchKeyWord => {
    setLoading(true);
    if (!searchKeyWord.length) {
      setProductPricesDispList(productPricesList);
    } else {
      const filteredData = productPricesList.filter(price =>
        price.customer?.toLowerCase().includes(searchKeyWord.toLowerCase())
      );
      setProductPricesDispList(filteredData);
    }
    setLoading(false);
  };

  const updateAllPricing = () => {
    setLoading(true);
    dispatch(
      productInventoryActions.onAddUpdateBulkProductPricing(massEditRows)
    )
    .then(data => {
      setLoading(false);
      setEditableRow([]);
      setMassEditableRows([]);
      setMassEditing(false);
      if (data?.success) {
        loadData();
        loadAllPricingList();
        toast.success("Price List updated successfully!");
      } else {
        toast.error("Something went wrong, Please try later.");
      }
    });
  };


  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      productInventoryActions.onGetPricingByProductId({
        productId: activeProductId,
        period: selectedtTab,
      })
    ).then(data => {
      setProductPrices(data?.data || []);
      setProductPricesDispList(data?.data || []);
      loadDescriptionGroups();
      if (data?.success) {
        setPaginationResponse(data?.data?.pagination);
      } else {
        if (data?.message?.includes("No Data Found")) {
        } else toast.error("Something went wrong, Please try later.");
      }
      setSelectedItems([]);
      setLoading(false);
    });

    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      setAllVisibilityGroups(data?.data ?? []);
      setVisibilityGroups(data?.data ?? []);
    });
  }, [
    dispatch,
    selectedtTab,
    activeProductId,
  ]);

  const loadAllPricingList = useCallback(() => {
    dispatch(
      productInventoryActions.onGetPricingByProductId({
        productId: activeProductId,
        period: "all",
      })
    ).then(data => {
      setAllProductPrices(data?.data || []);
    });
  }, [dispatch, activeProductId]);

  const { dropdownValues: availabilityPeriodDD, loadDropdownList } =
    useDropdownValues({
      url: endpoints.productInventory.getPricingAvailabilityPeriodDropdownV2,
    });

    function findIndexOfFirstHyphen(inputString) {
      const indexOfHyphen = inputString.indexOf("-");
      return indexOfHyphen;
    }
    
    const updatedAvailabilityPeriodDD = availabilityPeriodDD.map((x) => {
      const indexOfHyphen = findIndexOfFirstHyphen(x.value) - 1;
      return {
        ...x,
        value: indexOfHyphen !== -1 ? x.value.slice(0, indexOfHyphen) : x.value,
      };
    }); 

  const loadAllAvailabilityTimes = useCallback(() => {
    dispatch(productInventoryActions.onLoadAllAvailabilityTimes()).then(
      data => {
        setAllAvailableTimes(data?.data ?? []);
      }
    );
  }, [dispatch]);

  useEffect(() => {
    loadAllAvailabilityTimes();
    loadData();
    loadAllPricingList();
  }, [dispatch, loadData, loadAllPricingList, loadAllAvailabilityTimes, page, rowsPerPage]);

  const handleCustomerTabChange = type => {
    setLoading(true);
    dispatch(productInventoryActions.onGetCustomerGroups(type)).then(data => {
      setVisibilityGroups(data?.data);
      setLoading(false);
    });
  };

  const loadDescriptionGroups = useCallback(() => {
    dispatch(
      productInventoryActions.onGetProductPricingGroup({
        productId: activeProductId,
      })
    ).then(data => {
      setProductGroups(data?.data || []);
      setStoreDescTabValue(data?.data[0].value);
      setPriceListTabValue(data?.data[0].value);
      if (data?.success) {
      } else {
        if (data?.message?.includes("No Data Found")) {
        } else toast.error("Something went wrong, Please try later.");
      }
      setLoading(false);
    });
  }, [activeProductId, dispatch]);

  useEffect(() => {
    loadDescriptionGroups();
  }, [loadDescriptionGroups]);

  useEffect(() => {
    if (productPricingGroups?.length > 0) {
      if (activeStoreDescriptionTabId) {
        setStoreDescriptionTabId(`${activeStoreDescriptionTabId}`);
      } else {
        setStoreDescriptionTabId(
          `${productPricingGroups[0]?.availabilityPeriods[0]?.pricingId}`
        );
      }
      if (activePriceListDescTabId) {
        setPriceListDescTabId(`${activePriceListDescTabId}`);
      } else {
        setPriceListDescTabId(
          `${productPricingGroups[0]?.availabilityPeriods[0]?.pricingId}`
        );
      }
    }
  }, [
    productPricingGroups,
    activeStoreDescriptionTabId,
    activePriceListDescTabId,
  ]);

  useEffect(() => {
    if (
      activeStoreDescriptionTabId &&
      activeStoreDescriptionTabId > 0 &&
      isStorePriceDescSave
    ) {
      setIsStorePriceDescSave(false);
    }
  }, [activeStoreDescriptionTabId]);

  useEffect(() => {
    if (
      activePriceListDescTabId &&
      activePriceListDescTabId > 0 &&
      isProductDescSave
    ) {
      setIsProductDescSave(false);
    }
  }, [activePriceListDescTabId]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const onMassEditing = () => {
    setMassEditableRows([...productPricesDispList]);
    const id = productPricesDispList.map(item => item.id);
    setEditableRow(id);
    setMassEditing(true);
  };

  const addUpdateDescription = (descriptionPayload, isLongDescription) => {
    setLoading(true);
    dispatch(
      productInventoryActions.onAddUpdateProductDescription({
        url: isLongDescription
          ? endpoints.productInventory.addUpdateProductDescription
          : endpoints.productInventory.addUpdatePriceDescription,
        payload: descriptionPayload,
      })
    ).then(res => {
      if (!res?.success) {
        setLoading(false);
        if (isLongDescription) {
          setIsStorePriceDescSave(false);
        } else if (!isLongDescription) {
          setIsProductDescSave(false);
        }
        toast.error(res?.message);
      } else {
        toast.success("Description updated successfully");

        loadDescriptionGroups();
        if (isLongDescription) {
          setStoreDescriptionTabId(
            `${res?.data?.[0]?.availabilityPeriods[0]?.pricingId}`
          );
          setIsStorePriceDescSave(false);
        } else if (!isLongDescription) {
          setPriceListDescTabId(
            `${res?.data?.[0]?.availabilityPeriods[0]?.pricingId}`
          );
          setIsProductDescSave(false);
        }
      }
    });
  };

  const storeDescTab = productPricingGroups.find(
    tab => tab.value === storeDescTabValue
  );
  const priceDescTab = productPricingGroups.find(
    tab => tab.value === priceListTabValue
  );

 const isDescriptoinActive = productPricesList?.some(a => { return a.papId !== 0 && a.papId !== -1});
 
  return (
    <div className={`productStorePricing wrapAccordian`}>
      {isLoading ? <Loader /> : null}

      {!isPriceAvailabilitySection ? (
        <div className='accoridanContainer'>
          <AccordionContainer
            title='Pricing/Store Availability'
            formButton={
              <>
                <div className='btn-container'>
                  <GreenButton
                    onClick={() => {
                      setPriceAvailability(!isPriceAvailabilitySection);
                    }}
                    title={"Availability Periods"}
                  />
                  {isMassEditing ? (
                    <>
                    <Button
                      className='canclePricing'
                      onClick={() => {
                        setEditableRow([]);
                        setMassEditing(false);
                      }}>
                      Cancel Editing
                    </Button>
                    <Button
                      className='massPricing'
                      onClick={updateAllPricing}
                    >
                      Update All
                    </Button>
                  </>
                  ) : (
                    <Button disabled={isNewProductPricing} className='massPricing' onClick={onMassEditing}>
                      Mass Price Editing
                    </Button>
                  )}
                </div>
                <div className='pricing-btn-container'>
                  <Button
                    disabled={isMassEditing}
                    color='primary'
                    variant='contained'
                    className='dashboardButtons'
                    style={{height:"36px"}}
                    onClick={addPriceModal}
                    startIcon={<Add fontSize='10px' />}
                    endIcon={<DropdowArrow />}>
                    Add New Pricing
                  </Button>
                </div>
              </>
            }>
            <AddNewPricingModal
              addPriceModal={addPriceModal}
              setIsNewProductPricing={setIsNewProductPricing}
              setPriceName={setPriceName}
              open={open}
            />
            <Box className="productAccordionTabs" sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={selectedtTab}>
                <Box style={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label='lab API tabs example'>
                     
                    <Tab
                      className='tabsItems'
                      label='Current Pricing'
                      iconPosition='start'
                      icon={
                        <CheckCircle
                          fontSize='small'
                          style={{
                            color: "#18BC6D",
                            marginBottom: 0,
                            minHeight: "unset",
                          }}
                        />
                      }
                      value='Current'
                    />
                    <Tab
                      className='tabsItems'
                      label='Upcoming Pricing'
                      iconPosition='start'
                      icon={
                        <AccessAlarm
                          fontSize='small'
                          style={{ color: "#9B51E0", marginBottom: 0 }}
                        />
                      }
                      value='Upcoming'
                    />
                    <Tab
                      className='tabsItems'
                      iconPosition='start'
                      icon={
                        <Cancel
                          fontSize='small'
                          style={{ color: "#FF3535", marginBottom: 0 }}
                        />
                      }
                      label='Expired Pricing'
                      value='Expired'
                    />
                     <Tab
                      className='tabsItems'
                      label='All Pricing '
                      value='All'
                    />
                    
                  </TabList>
                </Box>
                <Grid container className='value_filter'>
                  <Grid item xs={11}>
                    <ProductAttributesGridFilter
                      onPressFilterBtn={handleSearch}
                    />
                  </Grid>
                  <Grid item xs={1} className='grid_list'>
                    <TableGrid />
                    <GridListIcon />
                  </Grid>
                </Grid>

                <TabPanel value={selectedtTab}>
                  <SingleProductPricingNew
                    availabilityPeriodDD={updatedAvailabilityPeriodDD}
                    allAvailableTimes={allAvailableTimes}
                    isMassEditing={isMassEditing}
                    setMassEditing={setMassEditing}
                    massEditRows={massEditRows}
                    setMassEditableRows={setMassEditableRows}
                    className='tabPanel'
                    selectedtTab={selectedtTab}
                    activeProductId={activeProductId}
                    listData={productPricesDispList}
                    allProductPrices={allProductPrices}
                    allVisibilityGroups={allVisibilityGroups}
                    visibilityGroups={visibilityGroups}
                    loadData={() => loadData()}
                    order={order}
                    onOrderBy={setOrderBy}
                    setLoading={setLoading}
                    isNewProductPricing={isNewProductPricing}
                    setIsNewProductPricing={setIsNewProductPricing}
                    setPriceName={setPriceName}
                    priceName={priceName}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    editRow={editRow}
                    handleCustomerTabChange={handleCustomerTabChange}
                    setEditableRow={setEditableRow}
                    addPriceModal={addPriceModal}
                    excludedCustomers={excludedCustomers}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </AccordionContainer>
        </div>
      ) : (
        <PricingAvailabilityPeriods
          onBackClick={() => {
            setPriceAvailability(!isPriceAvailabilitySection);
            loadDropdownList(
              endpoints.productInventory.getPricingAvailabilityPeriodDropdownV2
            );
          }}
        />
      )}
      <div className='descriptionAccordionContainer'>
        <AccordionContainer
          title='Store Product Description'
          isExpanded={false}
          disabled={!isDescriptoinActive}
          subTitle='Customize the Store Product Description based on your Price Names/Groups.'>
          <Button
            color='primary'
            variant='contained'
            className='dashboardButtons'
            onClick={() => {
              setIsStorePriceDescSave(true);
            }}>
            Save
          </Button>
          <div className='tabsContainer'>
            <TabContext value={storeDescTabValue}>
              <TabList
                onChange={(event, newValue) =>
                  handleChangetabs(event, newValue, true)
                }
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
                indicatorColor='primary'>
                {productPricingGroups.map((t, index) => (
                  <Tab
                    className='tabsItems'
                    key={index}
                    label={t.value}
                    value={t.value}
                  />
                ))}
              </TabList>
              <TabPanel key={storeDescTabValue} value={storeDescTabValue}>
                <TopTabNavigatorPricing
                  getActiveTabId={tabId => {
                    setStoreDescriptionTabId(`${tabId}`);
                  }}
                  tabs={storeDescTab?.availabilityPeriods?.map(p => {
                    return {
                      id: `${p?.pricingId}`,
                      title: p?.periodName ?? "N/A",
                      component: (
                        <ProductCustomerGroupDescription
                          productPricingGroups={productPricingGroups}
                          isLongDescription
                          type={p?.generic ?? "N/A"}
                          activeProductId={activeProductId}
                          activeGroupId={
                            `${activeStoreDescriptionTabId}` ===
                            `${p?.pricingId}`
                              ? p?.pricingId
                              : null
                          }
                          isProductDescSave={isStorePriceDescSave}
                          onSave={descData => {
                            if (
                              isStorePriceDescSave &&
                              `${activeStoreDescriptionTabId}` ===
                                `${p.pricingId}` &&
                              !isLoading
                            ) {
                              addUpdateDescription(
                                { ...descData, type: p.generic },
                                true
                              );
                            }
                          }}
                        />
                      ),
                    };
                  })}
                />
              </TabPanel>
            </TabContext>
          </div>
        </AccordionContainer>
      </div>

      <div className='descriptionAccordionContainer'>
        <AccordionContainer
          isExpanded={false}
          disabled={!isDescriptoinActive}
          title='Price List Product Description'
          subTitle='Customize the Price List Product Description based on your Price Names/Groups.'>
          <Button
            color='primary'
            variant='contained'
            className='dashboardButtons'
            onClick={() => {
              setIsProductDescSave(true);
            }}>
            Save
          </Button>
          <div className='tabsContainer'>
            <TabContext value={priceListTabValue}>
              <TabList
                onChange={(event, newValue) =>
                  handleChangetabs(event, newValue, false)
                }
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
                indicatorColor='primary'>
                {productPricingGroups.map((t, index) => (
                  <Tab
                    className='tabsItems'
                    key={index}
                    label={t.value}
                    value={t.value}
                  />
                ))}
              </TabList>
              <TabPanel key={priceListTabValue} value={priceListTabValue}>
                <TopTabNavigatorPricing
                  getActiveTabId={tabId => {
                    setPriceListDescTabId(`${tabId}`);
                  }}
                  tabs={priceDescTab?.availabilityPeriods?.map(p => {
                    return {
                      id: `${p?.pricingId ?? 0}`,
                      title: p?.periodName ?? "N/A",
                      component: (
                        <ProductCustomerGroupDescription
                          productPricingGroups={productPricingGroups}
                          isStorePricingDesc={false}
                          activeProductId={activeProductId}
                          type={p?.generic ?? "N/A"}
                          activeGroupId={
                            `${activePriceListDescTabId}` === `${p?.pricingId}`
                              ? p?.pricingId
                              : null
                          }
                          isPriceDescSave={isProductDescSave}
                          onSave={descData => {
                            if (
                              isProductDescSave &&
                              `${activePriceListDescTabId}` ===
                                `${p.pricingId}` &&
                              !isLoading
                            ) {
                              addUpdateDescription(
                                { ...descData, type: p.generic },
                                false
                              );
                            }
                          }}
                        />
                      ),
                    };
                  })}
                />
              </TabPanel>
            </TabContext>
          </div>
        </AccordionContainer>
      </div>
    </div>
  );
};
