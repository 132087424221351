import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import Cards from "../../../components/common/Cards";
import Loader from "../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { pubCompanyActions } from "../../../store/pubCompany/pubCompanyActions";
import { toast } from "react-toastify";

function Leadership({ Active }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [leaderData, setLeaderData] = useState([]);
  useEffect(() => {
    if (Active) {
      setLoading(true);
      dispatch(pubCompanyActions.onGetPubLeaders()).then(data => {
        setLoading(false);
        if (data && data.success) {
          setLeaderData(data.data);
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
      });
    }
  }, [Active]);

  return (
    <div className="leadershipWrapper">
      {isLoading ? <Loader /> : null}
      <div className="boxMainWrapper eventsWrapper releaseInnerWrapper">
        <div className="cards smallCard">
          <Grid container spacing={3}>
            {leaderData &&
              leaderData.length > 0 &&
              leaderData.map((ldr, index) => (
                <Grid key={index} item md={4}>
                  <Cards
                    picture={
                      ldr.leadershipImageURL
                        ? ldr.leadershipImageURL
                        : "/static/images/cards/contemplative-reptile.jpg"
                    }
                    subheading={ldr.name}
                    cardtext={ldr.position}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Leadership;
