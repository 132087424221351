import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../components/common/InputField";
import EmptyTextarea from "../../../../components/common/Textarea";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import GeneralHeading from "../../../../components/common/GeneralHeading";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { LaunchCategoryVisibilityGroup } from "./LaunchCategoryVisibilityGroup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  eventCatTextarea: {},
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function CategoryOveriew() {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [heading, setHeading] = useState("Launch Categories");
  const [categoryNameInternal, setCategoryNameInternal] = useState("");
  const [categoryNamePublic, setCategoryNamePublic] = useState("");
  const [categoryURLSlug, setCategoryURLSlug] = useState("");
  const [categoryShortDescription, setCategoryShortDescription] = useState("");
  const [categoryColor, setCategoryColor] = useState("");
  const [newsRoomVisibilityId, selectCategoryNewsRoomVisibility] = useState("");
  const [visibilityGroups, setVisibilityGroups] = useState([]);
  const [launchVisibilityGroups, setLaunchVisibilityGroups] = useState([]);
  const [editCatData, setEditCatData] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const launchId = parseInt(window.location.href.split("?id=")[1]);

  const loadGroups = useCallback(() => {
    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      setVisibilityGroups(data?.data);
    });
  }, [dispatch]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  useEffect(() => {
    dispatch(launchesActions.onGetReleaseNewsRoomVisibilities()).then(res => {
      if (res && res.success && res.data && res.data.length > 0) {
        selectCategoryNewsRoomVisibility(res.data[0].id);
      }
    });
  }, []);

  useEffect(() => {
    if (
      visibilityGroups &&
      visibilityGroups.length > 0 &&
      editCatData?.categoryGroups?.length > 0
    ) {
      setLaunchVisibilityGroups(
        editCatData.categoryGroups.map(x => {
          return {
            name: visibilityGroups.find(y => y.id == x)?.value,
            VisibilityGroupId: x,
          };
        })
      );
    }
  }, [visibilityGroups, editCatData]);

  useEffect(() => {
    dispatch(launchesActions.onGetLaunchCategoryById(launchId)).then(res => {
      if (res && res.success && res.data) {
        setEditCatData(res.data);
        setHeading(res.data?.name);
        setCategoryNameInternal(res.data?.name);
        setCategoryNamePublic(res.data?.namePublic);
        setCategoryURLSlug(res.data?.urlSlug);
        setCategoryShortDescription(res.data?.description);
        setCategoryColor(res.data?.color);
      }
    });
  }, []);

  const onSaveCategory = () => {
    var groupId = [];
    launchVisibilityGroups.map(x => {
      groupId.push(x.VisibilityGroupId);
    });
    const modal = {
      id: 0,
      name: categoryNameInternal,
      namePublic: categoryNamePublic,
      urlSlug: categoryURLSlug,
      description: categoryShortDescription,
      color: categoryColor,
      groupIds: groupId,
      newsRoomId: newsRoomVisibilityId,
    };
    if (launchId) {
      modal.id = launchId;
    }
    setLoading(true);
    dispatch(launchesActions.onAddUpdateLaunchCategory(modal)).then(data => {
      setLoading(false);
      if (launchId) {
        toast.success("Launch category updated");
      } else toast.success("Launch category added");
      history.push("/launch-categories");
    });
  };

  const onChangeCategoryURLSlug = filteredValue => {
    setCategoryURLSlug(filteredValue);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <GeneralHeading
        headingName={heading}
        buttonText={launchId ? "Update Category" : "Save Category"}
        onClick={() => onSaveCategory()}
      />
      <div className='eventOverviewWrapper'>
        <AccordionContainer title='Category Overview'>
          <Grid container spacing={3}>
            <Grid item xs={11} className={classes.gridWrapper}>
              <InputField
                placeholder='Enter a Category Name (Internal)'
                inputlabel='Category Name (Internal)'
                value={categoryNameInternal}
                onChange={v => setCategoryNameInternal(v.target.value)}
              />
            </Grid>

            <Grid item xs={11} className={classes.gridWrapper}>
              <InputField
                placeholder='Enter a Category Name (Public)'
                inputlabel='Category Name (Public)'
                value={categoryNamePublic}
                onChange={v => setCategoryNamePublic(v.target.value)}
              />
            </Grid>

            <Grid item xs={11} className={classes.gridWrapper}>
              <label className='slugLabel'>Category URL Slug</label>
              <Grid container spacing={2} xs={12} className='slugWrapper'>
                <span style={{ marginLeft: "10px" }}>
                  newsroom.aquaticav.com/media/category/
                </span>
                <span
                  className='eventCatUrlSlug'
                  style={{ flex: "1", marginLeft: "10px" }}>
                  <InputField
                    inputlabel='Event URL'
                    placeholder='Enter a URL Slug'
                    value={categoryURLSlug}
                    onChange={e => {
                      const inputValue = e.target.value;
                      const filteredValue = inputValue.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      );
                      onChangeCategoryURLSlug(filteredValue);
                    }}
                  />
                </span>
              </Grid>
            </Grid>

            <Grid
              item
              xs={11}
              className={[classes.gridWrapper, "eventCatTextarea"]}>
              <EmptyTextarea
                textLabel='Short Description'
                placeholder='Enter description here'
                value={categoryShortDescription}
                onChange={v => setCategoryShortDescription(v.target.value)}
              />
            </Grid>

            <Grid
              item
              xs={11}
              className={[classes.gridWrapper, "eventCatTextarea"]}>
              <Grid container spacing={4} className='slugWrapper'>
                <Grid item xs={2} className={classes.gridWrapper}>
                  <label className='slugLabel' style={{ marginBottom: "0" }}>
                    Category color
                  </label>
                </Grid>
                <Grid
                  item
                  xs={10}
                  className={[classes.gridWrapper, "slugWrapper"]}>
                  <ul>
                    <li>
                      <button
                        className={
                          categoryColor === "#015dfe" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#015dfe");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#00d0ff" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#00d0ff");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#f9b900" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#f9b900");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#ff0092" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#ff0092");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#49b8ef" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#49b8ef");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#eb5757" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#eb5757");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#66de64" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#66de64");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#0a42e9" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#0a42e9");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#ef64b3" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#ef64b3");
                        }}
                      />
                      <button
                        className={
                          categoryColor === "#236681" ? "colorActive" : ""
                        }
                        onClick={() => {
                          setCategoryColor("#236681");
                        }}
                      />
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionContainer>
      </div>
      <div className='gap' />
      <div className='eventOverviewWrapper launchVisibilityGroup'>
        <LaunchCategoryVisibilityGroup
          newsRoomVisibilityId={newsRoomVisibilityId}
          releaseVisibilityGroups={launchVisibilityGroups}
          onNewsRoomVisibilityIdSelect={NewsRoomVisibilityId =>
            selectCategoryNewsRoomVisibility(NewsRoomVisibilityId)
          }
          onGroupSelection={groupList => {
            setLaunchVisibilityGroups(groupList);
          }}
        />
      </div>
    </>
  );
}
