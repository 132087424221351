import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import {
  ProductOwnedGridView,
  ProductOwnedListView,
} from "../../../LeftNav/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { ItemImage, Pagination } from "../../../../../components/common";
import ProductOwnedFilter from "../ProductOwnedFilter";
import { endUserActions } from "../../../../../store/endUser/endUserActions";

const endUserList = [
  {
    firstName: "Zaeem",
    lastName: "Ehsanullah",
    deviceRegistered: "1",
    groupName: "Test",
    groupColor: "",
    lastLoginDT: "1 mont ago",
    productMainImage: "",
  },
];

const useStyles = makeStyles((theme) => ({
  inenerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  content: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#434D56",
    padding: "16px 0px",
  },
  productContent: {
    padding: "32px 0px",
  },
  contentHeading: {
    color: "#181C32",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    paddingBottom: "32px",
  },

  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  topPagination:{
    color:"#70808F",
    fontSize:"12px",
    fontWeight:"500",
    display:"flex",
    justifyContent:"flex-end",
    marginBottom:"10px",
  },
  selectButtonCell:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    paddingTop:"10px",
  },
  popupSelectedButton:{
    backgroundColor:"#18bc6d",
  },
  popupSelectButton:{
    backgroundColor:"#3699FF",
   },
}));

const BussinesContactChooseLoction = ({ open, selectProduct }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { companyLocation } = useSelector((state) => state.ContactsReducer);
  const [selectedLoc, setSelectedLoc] = useState()
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchDevice, setSearchDevice] = useState("");
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });

  const onSelectProduct = (item) => {
    setSelectedLoc(item)
    dispatch(endUserActions.onChangeOfRegister("companyLocation", item))
  }

  return (
    <div>
      <div className={classes.productContent}>
        <Typography className={classes.contentHeading}>
          Choose Location
        </Typography>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <ProductOwnedFilter setSearchDevice={setSearchDevice} />
          <ProductOwnedGridView />
          <ProductOwnedListView />
        </div>
      </div>
      {/* <div className={classes.topPagination} >
        <span>
          {page}-{rowsPerPage} of&nbsp;
          {paginationResponse?.totalRecords}
        </span>
      </div> */}

      <Paper>
        <Table>
          <TableHead className={classes.gridHeader}>
            <TableRow className={classes.gridHeaderRow}>
              <TableCell className={classes.gridHeaderInner}>
                Location Nickname
                <TableSortLabel/>
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Show in Locator
                <TableSortLabel
                // active={order.orderByKey === "LastName"}
                // direction={order.orderVal === 1 ? "asc" : "desc"}
                // onClick={() =>
                //   onOrderBy({
                //     orderByKey: "LastName",
                //     orderVal: order.orderVal === 0 ? 1 : 0,
                //   })
                // }
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Address
                <TableSortLabel
                // active={order.orderByKey === "DeviceRegistered"}
                // direction={order.orderVal === 1 ? "asc" : "desc"}
                // onClick={() =>
                //   onOrderBy({
                //     orderByKey: "DeviceRegistered",
                //     orderVal: order.orderVal === 0 ? 1 : 0,
                //   })
                // }
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyLocation.map((item, index) => {
              return (
                <TableRow key={index} className="gridBodyRow">
                  <TableCell className={classes.gridBodyInner}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ItemImage imgSrc={""} />
                      <span>{item.nickName}</span>
                    </span>
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.showInLocator == true ? "Yes" : "No"}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.address}
                  </TableCell>

                  {/* <TableCell className={classes.gridBodyInner}>
                            {item.lastLoginDT !== null &&
                            item.lastLoginDT !== ""
                              ? moment(item.lastLoginDT).fromNow()
                              : ""}
                          </TableCell> */}
                      <TableCell className={`${classes.gridBodyInner} ${classes.selectButtonCell}`}>
                          <span
                          // onClick={() => redirectUrl(item)}
                          >
                              {/* <EditIcon /> */}
                              <Button
                                  color="primary"
                                  variant="contained"
                                  className={selectedLoc === item ? classes.popupSelectedButton : classes.popupSelectButton}
                                  onClick={() => onSelectProduct(item)}
                              >
                                  {selectedLoc === item
                                      ? "selected"
                                      : "Select"}
                              </Button>
                          </span>
                      </TableCell>
                </TableRow>
              );
            })}
            {companyLocation.length <= 0 && (
              <TableRow key="0" className="gridBodyRow">
                <TableCell
                  colSpan="7"
                  className={classes.gridBodyInner}
                  style={{ textAlign: "center" }}
                >
                  <span className="emptyGridText">
                    Uh Oh! You haven’t create any location.
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      {/* <Pagination
        recordCount={paginationResponse?.totalRecords}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      /> */}
    </div>
  );
};

export default BussinesContactChooseLoction;
