import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dashboard from "../Dashbard";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AdminUserInformation from "./AdminUserInformation";

const AdminOverview = () => {
  const history = useHistory();

  const redirectUrl = () => {
    history.push("/all-admin-users");
  };

  return (
    <Dashboard>
      <div className='body-content'>
        <div className='consumerHeading'>
          <h1> Add New Admin </h1>
          <Button color='primary' onClick={redirectUrl} variant='contained'>
            <ArrowBackIosIcon /> Back to Admin Users
          </Button>
        </div>
        <div className='enduserTabs'>
          <AdminUserInformation />
        </div>
      </div>
    </Dashboard>
  );
};

export default AdminOverview;
