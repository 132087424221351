export const ePriceSettingType = {
  1: "Fixed Price",
  2: "Same as",
  3: "Based on",
};
export const ePriceMethodType = {
  SamePrice: 1,
  MakeChange: 2,
};
export const ePriceValueType = {
  Percent: 0,
  DollarValue: 1,
};
export const eCustomerValueType = {
  2: "MAP Enforced",
  3: "Not Enforced",
};
