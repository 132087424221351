import { Button, Grid } from "@material-ui/core";
import { orderBy } from "lodash-es";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { ChipItem } from "../../../../../../components/common";
import { endpoints } from "../../../../../../config/apiConfig";
import {
  LocatorPath96Down,
  LocatorPath96Up,
} from "../../../../../../resources/images";
import { useLoadData } from "../../../../../../utils/use-load-data";
import { PriceListQuickPreview } from "./PriceListQuickPreview";

const PriceListLayout = ({
  plData,
  setPriceListData,
  addUpdatePriceListColumns,
  onPreview,
}) => {
  const { records: availableColumns, loadList } = useLoadData();
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    loadList({
      url: endpoints.priceListTool.getProductPricingColumnsV2,
      payload: {
        papId: `${plData?.papId}`,
        groupIds: [0],
      },
    });
  }, [loadList, plData?.papId]);

  useEffect(() => {
    if (plData?.priceListColumns?.length >= 0) {
      const mapColumns = plData?.priceListColumns
        ?.map((c, j) => {
          const column = availableColumns.find(
            ac => ac?.value && ac?.value === c?.name
          );
          return column ? { ...column, sort: j } : null;
        })
        .filter(f => f !== null);
      setSelectedColumns(mapColumns);
    }
    // else {
    //   const defaultColumns = availableColumns
    //     .map((ac, j) =>
    //       [
    //         "Product SKU",
    //         "Product Name",
    //         "Product Image",
    //         "Product Description",
    //         "MSRP Price",
    //       ].includes(ac?.value)
    //         ? { ...ac, sort: j }
    //         : null
    //     )
    //     .filter(f => f !== null);
    //   setSelectedColumns(defaultColumns);
    // }
  }, [availableColumns, plData?.priceListColumns]);

  return (
    <ValidatorForm
      onSubmit={async () => {
        if (selectedColumns?.length > 0) {
          const response = await addUpdatePriceListColumns({
            priceListId: plData.id,
            priceListColumns: selectedColumns.map(c => c?.value),
          });
          if (response.success) {
            toast.success(
              `Price List ${plData?.id ? "updated" : "created"} successfully`
            );
            setPriceListData({ ...response.data });
            if (onPreview) onPreview();
          }
        } else toast.error(`Please select columns`);
      }}>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          <label className='slugLabel' style={{ marginBottom: 12 }}>
            Available Columns
          </label>
          <b style={{ fontSize: "14px", color: "#434D56" }}>
            Add or Remove Available Columns to your price list from the list
            below
          </b>
        </Grid>
        <Grid item xs={12}>
          {availableColumns
            ?.filter(
              ac =>
                !selectedColumns?.map(scs => scs?.value)?.includes(ac?.value)
            )
            ?.map(ac => {
              return (
                <ChipItem
                  isAdd
                  key={ac?.value}
                  chipTitle={ac?.value || "---"}
                  chipSubTitle={ac?.generic}
                  onDelete={() => {
                    const newPlColumns = [
                      ...plData?.priceListColumns,
                      { ...ac, name: ac?.value, type: ac?.generic },
                    ];
                    setPriceListData({
                      ...plData,
                      priceListColumns: newPlColumns,
                    });
                  }}
                />
              );
            })}
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 16 }}>
          <label
            className='slugLabel showRequired'
            style={{ marginBottom: 12 }}>
            Columns on Price List
          </label>
          <b style={{ fontSize: "14px", color: "#434D56" }}>
            By default, OFish will display the Product Code/SKU, Product Name,
            Product Image/Description, and MSRP Prices with products that are
            available to the business groups or contacts you provided Price List
            Access. To customize the Price List further, use the area below for
            showing/hiding columns.
          </b>
        </Grid>
        <Grid item xs={4} className='sort-columns-wrapper'>
          {selectedColumns?.map((plc, i) => (
            <Grid item container alignItems='center'>
              <span className='sorting-arrows'>
                <span
                  className='MuiButton-label up'
                  onClick={() => {
                    if (i - 1 >= 0) {
                      const sMOpt = {
                        ...selectedColumns[i],
                      };
                      selectedColumns[i] = {
                        ...selectedColumns[i],
                        sort: selectedColumns[i - 1]?.sort,
                      };
                      selectedColumns[i - 1] = {
                        ...selectedColumns[i - 1],
                        sort: sMOpt?.sort,
                      };
                    }
                    const newList = orderBy(selectedColumns, ["sort"], ["asc"]);
                    setSelectedColumns([...newList]);
                  }}>
                  <img
                    alt='locator path'
                    className='LocatorPathIcon'
                    src={LocatorPath96Up}
                  />
                </span>
                <span
                  className='MuiButton-label down'
                  onClick={() => {
                    if (i + 1 <= selectedColumns.length) {
                      const sMOpt = {
                        ...selectedColumns[i],
                      };
                      selectedColumns[i] = {
                        ...selectedColumns[i],
                        sort: selectedColumns[i + 1]?.sort,
                      };
                      selectedColumns[i + 1] = {
                        ...selectedColumns[i + 1],
                        sort: sMOpt?.sort,
                      };
                    }
                    const newList = orderBy(selectedColumns, ["sort"], ["asc"]);
                    setSelectedColumns([...newList]);
                  }}>
                  <img
                    alt='locator path'
                    className='LocatorPathIcon'
                    src={LocatorPath96Down}
                  />
                </span>
              </span>
              <span
                style={{
                  color: "#3699FF",
                  fontSize: "36px",
                  fontWeight: 700,
                  marginRight: "13px",
                  marginLeft: "7px",
                }}>
                {i}
              </span>
              <ChipItem
                resetMargin={{
                  margin: "0px",
                }}
                key={plc?.value}
                chipTitle={plc?.value || "---"}
                chipSubTitle={plc?.generic}
                onDelete={() => {
                  const newPlColumns = selectedColumns
                    ?.filter(scs => scs?.value !== plc?.value)
                    .map(nplc => {
                      return {
                        ...nplc,
                        name: nplc?.value,
                        type: nplc?.generic,
                      };
                    });
                  setPriceListData({
                    ...plData,
                    priceListColumns: newPlColumns,
                  });
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid xs={8}>
          <b>Quick Preview Price List Columns</b>
          <PriceListQuickPreview
            plData={plData}
            selectedColumns={selectedColumns}
          />
        </Grid>
        <Grid container item sx={12} justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            className='dashboardButtons'
            type='submit'>
            Save & Preview
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export { PriceListLayout };
