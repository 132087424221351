import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { sortBy } from "lodash";
import { launchesActions } from "../store/launches/launchesActions";

const useDropdownValues = ({ url }) => {
  const dispatch = useDispatch();
  const [dropdownValues, setDropdownValues] = useState([]);

  const loadDropdownList = useCallback(
    url => {
      dispatch(
        launchesActions.onGetDropdownValues({
          url: url,
        })
      ).then(data => {
        if (data?.success) {
          if (data?.data?.length > 0) {
            const sortedList = sortBy(data?.data, item => {
              if (item.status === "Current") {
                return 1;
              } else if (item.status === "Upcoming") {
                return 2;
              } else if (item.status === "Expired") {
                return 3;
              }
              return 0; // For any other cases
            });
            setDropdownValues(sortedList);
          } else setDropdownValues(data?.data);
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    loadDropdownList(url);
  }, [loadDropdownList, url]);

  return { dropdownValues, loadDropdownList };
};

export { useDropdownValues };
