import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  Dialog,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  InputAdornment,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Cross } from "../../../../../resources/images/Close.svg";
import { contactsTypesActions } from "../../../../../store/contactTypes/contactTypesActions";

const Popup = withStyles({
  root: {
    "&.MuiDialog-paper": {
      minWidth: "80% !important",
      width: "80% !important",
    },
  },
})(Dialog);
const InputField = withStyles({
  root: {
    "&.MuiOutlinedInput-input": {},
    "&.MuiTextField-root": {
      width: "300px !important",
      background: "#FFFFFF",
      borderRadius: " 6px",
      border: "1px solid black",
    },
  },
})(TextField);

export function EditFieldWithRadioPopup({
  open,
  setOpenModulesModal,
  fieldSetting,
  selectedId,
  selectedName,
  selectedFieldSetting,
}) {
  const dispatch = useDispatch();
  const { dataField } = useSelector(state => state.pubContactType);
  const [selectedRadio, setSelectedRadio] = React.useState({});
  const [selectedRadio2, setSelectedRadio2] = React.useState(null);
  const [selectedinput, setSelectedInput] = React.useState(null);

  useEffect(() => {
    if (fieldSetting?.fieldId === 4) {
      const dataModules = [];
      dataField?.forEach(x => {
        if (x.fieldId === fieldSetting?.fieldId) {
          const dm = {
            fieldId: fieldSetting?.fieldId,
            fieldValue: fieldSetting?.fieldName,
            contactDataFieldSettingInfo: [
              {
                dataFieldSettingId: 10,
                additionalData: "Required",
              },
              {
                dataFieldSettingId: 12,
                additionalData: "Recommended",
              },
            ],
          };
          dataModules.push(dm);
        } else {
          dataModules.push({
            ...x,
          });
        }
      });
      dispatch(contactsTypesActions.onChange("dataField", dataModules));
    }
  }, [fieldSetting]);
  useEffect(() => {
    if (
      selectedFieldSetting &&
      selectedFieldSetting?.contactDataFieldSettingInfo?.length > 0
    ) {
      var inputData = fieldSetting?.fieldSettings?.[0]?.settings?.find(
        x => x.type === "input"
      );
      if (inputData) {
        const val = selectedFieldSetting?.contactDataFieldSettingInfo.find(
          x => x?.dataFieldSettingId === inputData.fieldId
        )?.additionalData;
        if (val) {
          setSelectedInput({
            dataFieldSettingId: inputData.fieldId,
            additionalData: val,
          });
        } else {
          const val = selectedFieldSetting?.contactDataFieldSettingInfo.find(
            x => x?.dataFieldSettingId === inputData.id
          )?.additionalData;
          setSelectedInput({
            dataFieldSettingId: inputData.fieldId,
            additionalData: val,
          });
        }
      }
      var radioData = fieldSetting?.fieldSettings?.[0]?.settings?.find(
        x =>
          x &&
          x.type !== "input" &&
          selectedFieldSetting?.contactDataFieldSettingInfo
            ?.map(a => a?.dataFieldSettingId)
            .includes(x.id)
      );
      if (radioData) {
        setSelectedRadio({
          dataFieldSettingId: radioData.id,
          additionalData:
            selectedFieldSetting?.contactDataFieldSettingInfo.find(
              x => x.dataFieldSettingId === radioData.id
            )?.additionalData,
        });
      }
    } else {
      var radioData1 = fieldSetting?.fieldSettings?.[0]?.settings?.find(
        x => x?.type !== "input"
      );
      if (radioData1) {
        setSelectedRadio({
          dataFieldSettingId: radioData1.id,
          additionalData: radioData1.subContent,
        });
        const dataModules = [];
        dataField?.forEach(x => {
          if (x.fieldId === selectedId) {
            const dm = {
              fieldId: selectedId,
              fieldValue: selectedName,
              contactDataFieldSettingInfo: [
                {
                  dataFieldSettingId: radioData1.id,
                  additionalData: radioData1.subContent,
                },
              ],
            };
            dataModules.push(dm);
          } else {
            dataModules.push({
              ...x,
            });
          }
        });
        dispatch(contactsTypesActions.onChange("dataField", dataModules));
      }
    }
  }, [selectedFieldSetting]);

  const onSaveClick = () => {
    if (
      selectedRadio?.dataFieldSettingId !== 10 &&
      selectedRadio?.dataFieldSettingId !== 11 &&
      selectedRadio?.dataFieldSettingId !== 12 &&
      selectedRadio?.dataFieldSettingId !== 13
    ) {
      if (selectedRadio || selectedinput) {
        const dataModules = [];
        if (dataField?.length > 0) {
          dataField?.forEach(x => {
            if (x.fieldId === selectedId) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactDataFieldSettingInfo: [selectedRadio, selectedinput],
              };
              dataModules.push(dm);
            } else {
              dataModules.push({
                ...x,
              });
            }
          });
        } else {
          const dm = {
            fieldId: selectedId,
            fieldValue: selectedName,
            contactDataFieldSettingInfo: [selectedRadio, selectedinput],
          };
          dataModules.push(dm);
        }
        dispatch(contactsTypesActions.onChange("dataField", dataModules));
      }
    } else {
      const dataModules = [];
      if (dataField?.length > 0) {
        dataField?.forEach(x => {
          if (x.fieldId === selectedId) {
            if (selectedRadio && selectedRadio2) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactDataFieldSettingInfo: [selectedRadio, selectedRadio2],
              };
              dataModules.push(dm);
            }
            if (selectedRadio && selectedRadio2 === null) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactDataFieldSettingInfo: [selectedRadio],
              };
              dataModules.push(dm);
            }
            if (selectedRadio2 && selectedRadio === null) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactDataFieldSettingInfo: [selectedRadio],
              };
              dataModules.push(dm);
            }
          } else {
            dataModules.push({
              ...x,
            });
          }
        });
      } else {
        if (selectedRadio && selectedRadio2) {
          const dm = {
            fieldId: selectedId,
            fieldValue: selectedName,
            contactDataFieldSettingInfo: [selectedRadio, selectedRadio2],
          };
          dataModules.push(dm);
        }
        if (selectedRadio && selectedRadio2 === null) {
          const dm = {
            fieldId: selectedId,
            fieldValue: selectedName,
            contactDataFieldSettingInfo: [selectedRadio],
          };
          dataModules.push(dm);
        }
        if (selectedRadio2 && selectedRadio === null) {
          const dm = {
            fieldId: selectedId,
            fieldValue: selectedName,
            contactDataFieldSettingInfo: [selectedRadio],
          };
          dataModules.push(dm);
        }
      }
      dispatch(contactsTypesActions.onChange("dataField", dataModules));
    }
    setOpenModulesModal(false);
  };

  const removeDataField = () => {
    var data = [];
    dataField.map(x => {
      if (x.fieldId !== fieldSetting.fieldId) {
        return data.push(x);
      }
    });
    dispatch(contactsTypesActions.onChange("dataField", data));
    setOpenModulesModal(false);
  };
  return (
    <>
      <div className='contactPopups'>
        <Popup
          style={{ maxWith: "70% !important" }}
          component='div'
          open={open}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          className='edit-popup'>
          <>
            <Grid container>
              <Grid item sx={10} md={11} lg={11}>
                <Typography
                  id='modal-modal-title'
                  style={{
                    padding: "3%",
                    width: "fit-content",
                    display: "inline-block",
                  }}
                  variant='h6'
                  component='h1'>
                  {fieldSetting?.heading}
                </Typography>
              </Grid>
              <Grid item sx={1}>
                <Cross
                  style={{
                    width: "15px",
                    padding: "10px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // setFieldSetting([]);
                    setOpenModulesModal(false);
                  }}
                />
              </Grid>
            </Grid>
            <Divider />
            <div className='contentDiv'>
              <FormControl style={{ marginLeft: "12px" }}>
                {fieldSetting?.fieldSettings?.map(item => {
                  return (
                    <>
                      {item?.settings.map(innerItem => {
                        return (
                          innerItem.type === "input" && (
                            <div style={{ padding: "1% 2%" }}>
                              <p>
                                {innerItem?.content}{" "}
                                <span
                                  style={{
                                    color:
                                      innerItem?.subContent === "Required"
                                        ? "#3699FF"
                                        : "#25A969",
                                  }}>
                                  {innerItem?.subContent === "Required"
                                    ? innerItem?.subContent
                                    : innerItem?.subContent === "Optional"
                                    ? innerItem?.subContent
                                    : ""}
                                </span>
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}>
                                <InputField
                                  id='outlined-basic'
                                  variant='outlined'
                                  placeholder={innerItem?.subContent}
                                  value={selectedinput?.additionalData}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='start'></InputAdornment>
                                    ),
                                  }}
                                  onChange={({ target }) => {
                                    // if (selectedinput?.dataFieldSettingId) {
                                    //   setSelectedInput({
                                    //     dataFieldSettingId:
                                    //       innerItem?.dataFieldSettingId,
                                    //     additionalData: target.value,
                                    //   });
                                    // }

                                    setSelectedInput({
                                      dataFieldSettingId: innerItem?.id,
                                      additionalData: target.value,
                                    });
                                  }}
                                />
                                {[1, 2, 3, 14].includes(selectedId) && (
                                  <span
                                    style={{
                                      color: "#3699FF",
                                      paddingLeft: "10px",
                                      fontWeight: "bold",
                                    }}>
                                    Required by OFish
                                  </span>
                                )}
                              </div>
                            </div>
                          )
                        );
                      })}
                      {item?.settings.filter(
                        x =>
                          x.type === "radio" && ![1, 2, 3].includes(selectedId)
                      )?.length > 0 && (
                        <>
                          {item?.section && (
                            <span>
                              <strong>{item?.section}</strong>
                            </span>
                          )}
                          <RadioGroup
                            name='rg-category-type'
                            value={selectedRadio?.dataFieldSettingId}
                            onChange={({ target }) => {
                              const selValue = parseInt(target.value);
                              if (
                                selValue !== 10 &&
                                selValue !== 11 &&
                                selValue !== 12 &&
                                selValue !== 13
                              ) {
                                const sel = item?.settings.find(
                                  x => x.id == selValue
                                )?.subContent;
                                setSelectedRadio({
                                  dataFieldSettingId: selValue,
                                  additionalData: sel,
                                });
                              } else {
                                if (selValue === 10 || selValue === 11) {
                                  const selValue = parseInt(target.value);
                                  const sel = item?.settings.find(
                                    x => x.id == selValue
                                  )?.subContent;
                                  setSelectedRadio({
                                    dataFieldSettingId: selValue,
                                    additionalData: sel,
                                  });
                                } else {
                                  const selValue = parseInt(target.value);
                                  const sel = item?.settings.find(
                                    x => x.id == selValue
                                  )?.subContent;
                                  setSelectedRadio2({
                                    dataFieldSettingId: selValue,
                                    additionalData: sel,
                                  });
                                }
                              }
                            }}>
                            {item?.settings
                              .filter(x => x.type === "radio")
                              ?.map(d => {
                                return (
                                  <FormControlLabel
                                    control={<Radio />}
                                    key={d.id}
                                    label={
                                      <span>
                                        {`${d?.content} `}
                                        <span
                                          style={{
                                            color:
                                              d?.subContent === "Required"
                                                ? "#3699FF"
                                                : "#25A969",
                                          }}>
                                          {d?.subContent}
                                        </span>
                                      </span>
                                    }
                                    value={d.id}
                                  />
                                );
                              })}
                          </RadioGroup>
                        </>
                      )}
                    </>
                  );
                })}
              </FormControl>
              <span className='contactPopupBtn'>
                <Button
                  variant='contained'
                  color='primary'
                  type='button'
                  onClick={() => {
                    onSaveClick();
                  }}>
                  Save
                </Button>
              </span>
            </div>
          </>
        </Popup>
      </div>
    </>
  );
}
