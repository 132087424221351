import { ReactComponent as Info } from "./Info.svg";
import { ReactComponent as GreenTick } from "./green-tick.svg";
import { ReactComponent as EllipsisDots } from "./ellipsis-dots.svg";

export { default as loginLogo } from "./loginLogo.png";
export { default as logo } from "./logo.png";
export { default as fotgotPassword } from "./fotgotPassword.png";
export { default as verificationcode } from "./verificationcode.png";
export { default as passwordReset } from "./password-reset.png";
export { default as logoSign } from "./logoSign.png";
export { default as headerBG } from "./headerBG.png";
export { default as downarrow } from "./downarrow.png";
export { default as instagram } from "./Instagram.png";
export { default as facebook } from "./facebook.png";
export { default as linkedin } from "./LinkedIn.png";
export { default as planefill } from "./planefill.png";
export { default as twitter } from "./twitter.png";
export { default as upload } from "./upload.png";
export { default as dashboard } from "./dashboard.png";
export { default as globe } from "./globe.png";
export { default as equalizer } from "./equalizer.png";
export { default as circle } from "./circle.png";
export { default as userpic } from "./userpic.png";
export { default as baloon } from "./baloon.png";
export { default as pictures } from "./pictures.png";
export { default as group } from "./group.png";
export { default as pdf } from "./pdf_file.png";
export { default as txt } from "./txt_file.png";
export { default as folder } from "./folder.png";
export { default as pngFile } from "./png_file.png";
export { default as doc } from "./doc_file.png";
export { default as genericImg } from "./img_file.png";
export { default as genericFile } from "./files_generic.png";
export { default as releasesPic } from "./releasesPic.png";
export { default as mediaPic } from "./mediaPic.png";
export { default as eventsPic } from "./eventsPic.png";
export { default as time } from "./time.png";
export { default as phone } from "./phone.png";
export { default as email } from "./email.png";
export { default as chatBubble } from "./chat-bubble.png";
export { default as building } from "./building.png";
export { default as grommetIcons } from "./grommet-icons.png";
export { default as connector } from "./connector.png";
export { default as proSportFront } from "./pro-sport-front.png";
export { default as noImage } from "./no_image_placeholder.jpeg";
export { default as calendar } from "./calendar.png";
export { default as launches } from "./launches.png";
export { default as mediaReleases } from "./media-releases.png";
export { default as searchIcon } from "./searchIcon.png";
export { default as ofishlogo } from "./OFishLogo.png";
export { default as controller } from "./controller.png";
export { default as trashIcon } from "./trash.png";
export { default as refreshIcon } from "./refresh.png";
export { default as chavronDown } from "./chavronDown.png";
export { default as star } from "./star.png";
export { default as starOutline } from "./starOutline.png";
export { default as starOutlineYellow } from "./starOutlineYellow.png";
export { default as productInventory } from "./Product-Inventory.png";
export { default as sortuparrow } from "./sortUparrow.png";
export { default as sortDownarrow } from "./sortDownarrow.png";
export { default as wavehand } from "./wavehand.png";
export { default as portal } from "./portal.png";
export { default as equalizerFill } from "./equalizer-o.png";
export { default as compiling } from "./compiling.png";
export { default as addUser } from "./add-user.png";
export { default as urgentMail } from "./urgent-mail.png";
export { default as button } from "./Button.png";
export { default as img1 } from "./image1.png";
export { default as img2 } from "./image2.png";
export { default as img3 } from "./image3.png";
export { default as img4 } from "./image4.png";
export { default as deviceOverview } from "./deviceOverview.png";
export { default as contactInsta } from "./contactInsta.png";
export { default as contactFacebook } from "./contactFacebook.png";
export { default as contactLinkedIn } from "./contactLinkedIn.png";
export { default as contactTwitter } from "./contactTwitter.png";
export { default as locatorTheme } from "./locatorTheme.png";
export { default as locatorVector } from "./locatorVector.png";
export { default as LocatorPath95 } from "./LocatorPath95.png";
export { default as bannerSlider } from "./bannerSlider.png";
export { default as newsroomTheme } from "./newsroomTheme.png";
export { default as locatorVectorLeft } from "./locatorVectorLeft.png";
export { default as LocatorPath96 } from "./LocatorPath96.png";
export { default as LocatorPath96Up } from "./LocatorPath96Up.png";
export { default as LocatorPath96Down } from "./LocatorPath96Down.png";
export { default as messagingActive } from "./MessagingActive.png";
export { default as conversationIcon } from "./conversationIcon.png";
export { default as LocatorPath95Left } from "./LocatorPath95Left.png";
export { default as mediaFile } from "./media-file.png";
export { default as chat } from "./chat.jpg";
export { default as saerchFilter } from "./saerchFilter.png";
export { default as chatSettings } from "./chatSettings.png";
export { default as settingsBlue } from "./settingsBlue.png";
export { default as calalderIcon } from "./calalderIcon.png";

export { default as gridIcon } from "./grid-icon.png";
export { default as listIcon } from "./list-icon.png";
export { default as crossImg } from "./cross.png";

export { default as filterGridIcon } from "./filterGridIcon.png";
export { default as filterListIcon } from "./filterListIcon.png";

const SVGIcons = {
  Info,
  GreenTick,
  EllipsisDots,
};
export { SVGIcons };
export { default as crossWhite } from "./crossWhite.png";
