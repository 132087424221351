import React from "react";
import Login from "../ofishloggedin/views/Login/Login";
import {
  Home,
  AllLatestAnnouncenments,
  AllUpcomingEvents,
  LatestReleases,
  MediaLibrary,
  AllSourceUnits,
  Events,
  UpcomingEvents,
  UpcomingProductRelease,
  CompanyInfo,
  LatestReleasesSearch,
  LatestReleaseDetail,
  AllCategories,
  MediaDetail,
  AllNewAssets,
  AllCollection,
  Calendar,
  EventDetail,
  VerificationCode,
  PasswordRest,
  ForgotPassword,
  AccountSettings,
} from "../ofishloggedin/views/index";
import {
  Releases,
  PastReleases,
  UpComingReleases,
  Launches,
  UpComingLaunches,
  PastLaunches,
  LaunchCategories,
  AllCollections,
  AssetManagement,
  NewCollectionCategory,
  AllUserGroup,
  NewMediaRelease,
  ReleaseCategory,
  NewCollection,
  CollectionCategory,
  MainDashboard,
  ConsumerOverview,
  CustomerOverview,
  DashboardEvents,
  EventCategories,
  NewEvent,
  NewCategory,
  NewsRoomPortal,
  LocatorWidget,
  Location,
  NewLocation,
  NewReleaseCategory,
  NewEndUserGroup,
  Conversation,
  ConversationSettings,
  YourViews,
  SpaProduct,
  AllAdminUsers,
  AdminOverview,
  AllEndUsers,
  SoftwareUpdates,
  NewSoftwareRelease,
  SoftwareBuildDashboard,
  NewSoftwareBuild,
} from "../ofishadmin/views/Dashboard";
import {
  ProductCategories,
  NewProductCategory,
} from "../ofishadmin/views/Dashboard/ProductCategories";
import {
  ProductInventory,
  NewProduct,
  ProductPricing,
  Brands,
} from "../ofishadmin/views/Dashboard/ProductInventory";
import {
  ProductSerials,
  ProductSerial,
} from "../ofishadmin/views/Dashboard/ProductSerials";
import {
  AllBusinessContacts,
  AllCompanies,
  BusinessGroup,
  NewBusinessGroup,
} from "../ofishadmin/views/Dashboard/BusinessConsumers";
import { CompanyInformation } from "../ofishadmin/views/Dashboard/BusinessConsumers/NewCompanyContacts/CompanyInformation";
import { NewLaunch } from "../ofishadmin/views/Dashboard/Launches";
import AddLaunchCategory from "../ofishadmin/views/Dashboard/Launches/AddLaunchCategory";
import {
  PriceList,
  NewPriceList,
  CreatePriceList,
} from "../ofishadmin/views/Dashboard/ToolsAndPortal/PricingList";
import ContactSettings from "../ofishadmin/views/Dashboard/Contacts/ContactSettings";
import NewContactTypes from "../ofishadmin/views/Dashboard/Contacts/NewContactType";
import AllConversations from "../ofishadmin/views/Dashboard/ConversationsRevamp/AllConversations";
import { FavoritesAssets } from "../ofishadmin/views/Dashboard/AssetManagement/FavoritesAssests";
import { ConversationsAssets } from "../ofishadmin/views/Dashboard/AssetManagement/ConversationsAssets";
import { SharedFilesAssets } from "../ofishadmin/views/Dashboard/AssetManagement/SharedFilesAssets";
import { AssetLibraryHome } from "../ofishadmin/views/Dashboard/AssetManagement/ AssetLibraryHome";
import { AssetsInternalFiles } from "../ofishadmin/views/Dashboard/AssetManagement/AssetsInternalFiles";
import { AssetsTrash } from "../ofishadmin/views/Dashboard/AssetManagement/AssetsTrash";
import { ConvInboxTypes } from "../config/Enums";
import { ConversationTypeEditPreview } from "../ofishadmin/views/Dashboard/Conversations/ConverstionSettings/ConversationTypeEditPreview";
import ConversationCustomizations from "../ofishadmin/views/Dashboard/Conversations/ConverstionSettings/ConversationCustomizations";
import { PastEvents } from "../ofishadmin/views/Dashboard/Events/PastEvents";
import { UpcommingEvent } from "../ofishadmin/views/Dashboard/Events/UpcommingEvent";
import BlockedBusinessLocation from "../ofishadmin/views/Dashboard/BusinessConsumers/NewCompanyContacts/BlockedBusinessLocation";
import UpdateBusinessLocation from "../ofishadmin/views/Dashboard/BusinessConsumers/NewCompanyContacts/UpdateBusinessLocation";
import NewLeadGeneration from "../ofishadmin/views/Dashboard/BusinessConsumers/Leads/NewLeadGeneration";
import LeadGeneration from "../ofishadmin/views/Dashboard/BusinessConsumers/Leads/LeadGeneration";

const routesObj = {
  Login: {
    path: "/login",
    component: <Login />,
    roles: [],
    fallback: null,
  },

  // Logged in User Route starts here.
  ForgotPassword: {
    path: "/forgot-password",
    component: <ForgotPassword />,
    roles: [],
    fallback: null,
  },
  PasswordRest: {
    path: "/password-reset",
    component: <PasswordRest />,
    roles: [],
    fallback: null,
  },
  VerificationCode: {
    path: "/verification-code",
    component: <VerificationCode />,
    roles: [],
    fallback: null,
  },
  Root: {
    path: "/",
    component: <Home />,
    roles: [],
    fallback: null,
  },
  LatestReleaseDetail: {
    path: "/releases/category",
    component: <LatestReleaseDetail />,
    roles: [],
    fallback: null,
  },
  LatestReleases: {
    path: "/releases",
    component: <LatestReleases />,
    roles: [],
    fallback: null,
  },
  LatestReleasesSearch: {
    path: "/latest-releases-search",
    component: <LatestReleasesSearch />,
    roles: [],
    fallback: null,
  },
  // AllReleases: {
  //   path: "/all-releases",
  //   component: <AllReleases />,
  //   roles: [],
  //   fallback: null,
  // },
  AllCategories: {
    path: "/all-categories",
    component: <AllCategories />,
    roles: [],
    fallback: null,
  },
  AllCollection: {
    path: "/all-collection",
    component: <AllCollection />,
    roles: [],
    fallback: null,
  },
  MediaDetail: {
    path: "/media-library/category",
    //path: "/media-detail/category",
    component: <MediaDetail />,
    roles: [],
    fallback: null,
  },
  MediaLibrary: {
    path: "/media-library",
    component: <MediaLibrary />,
    roles: [],
    fallback: null,
  },

  AllNewAssets: {
    path: "/all-new-assets",
    component: <AllNewAssets />,
    roles: [],
    fallback: null,
  },
  AllSourceUnits: {
    path: "/all-source-units",
    component: <AllSourceUnits />,
    roles: [],
    fallback: null,
  },
  EventDetail: {
    path: "/events/category",
    component: <EventDetail />,
    roles: [],
    fallback: null,
  },
  Events: {
    path: "/events",
    component: <Events />,
    roles: [],
    fallback: null,
  },
  Calendar: {
    path: "/events-calendar",
    component: <Calendar />,
    roles: [],
    fallback: null,
  },
  UpcomingEvents: {
    path: "/upcoming-events",
    component: <UpcomingEvents />,
    roles: [],
    fallback: null,
  },
  UpcomingProductRelease: {
    path: "/upcoming-product-release",
    component: <UpcomingProductRelease />,
    roles: [],
    fallback: null,
  },

  CompanyInfo: {
    path: "/company-info",
    component: <CompanyInfo />,
    roles: [],
    fallback: null,
  },
  AllLatestAnnouncenments: {
    path: "/all-latest-announcenments",
    component: <AllLatestAnnouncenments />,
    roles: [],
    fallback: null,
  },
  AllUpcomingEvents: {
    path: "/all-upcoming-events",
    component: <AllUpcomingEvents />,
    roles: [],
    fallback: null,
  },
  AccountSettings: {
    path: "/account-settings",
    component: <AccountSettings />,
    roles: [],
    fallback: null,
  },
  // Logged in User Route Ends here.

  // Admin Route starts here.

  Releases: {
    path: "/all-releases",
    component: <Releases />,
    roles: [],
    fallback: null,
  },
  PastReleases: {
    path: "/past-releases",
    component: <PastReleases />,
    roles: [],
    fallback: null,
  },
  UpComingReleases: {
    path: "/upcoming-releases",
    component: <UpComingReleases />,
    roles: [],
    fallback: null,
  },
  Launches: {
    path: "/launches",
    component: <Launches />,
    roles: [],
    fallback: null,
  },
  NewLaunch: {
    path: "/new-launch",
    component: <NewLaunch />,
    roles: [],
    fallback: null,
  },
  UpdateLaunch: {
    path: "/launch",
    component: <NewLaunch />,
    roles: [],
    fallback: null,
  },
  UpComingLaunches: {
    path: "/upcoming-launches",
    component: <UpComingLaunches />,
    roles: [],
    fallback: null,
  },
  PastLaunches: {
    path: "/past-launches",
    component: <PastLaunches />,
    roles: [],
    fallback: null,
  },
  LaunchCategories: {
    path: "/launch-categories",
    component: <LaunchCategories />,
    roles: [],
    fallback: null,
  },
  AddLaunchCategory: {
    path: "/add-launch-categories",
    component: <AddLaunchCategory />,
    roles: [],
    fallback: null,
  },
  AllCollections: {
    path: "/all-collections",
    component: <AllCollections />,
    roles: [],
    fallback: null,
  },
  AssetManagement: {
    path: "/assets-media-library",
    component: <AssetLibraryHome />,
    roles: [],
    fallback: null,
  },
  FavoritesAssets: {
    path: "/assets-media-library-favorite",
    component: <FavoritesAssets />,
    roles: [],
    fallback: null,
  },
  ConversationsAssets: {
    path: "/assets-media-library-conversations",
    component: <ConversationsAssets />,
    roles: [],
    fallback: null,
  },
  SharedFilesAssets: {
    path: "/assets-media-library-sharedfiles",
    component: <SharedFilesAssets />,
    roles: [],
    fallback: null,
  },
  AssetsInterFiles: {
    path: "/assets-media-library-internalFiles",
    component: <AssetsInternalFiles />,
    roles: [],
    fallback: null,
  },
  AssetsTrash: {
    path: "/assets-media-library-trash",
    component: <AssetsTrash />,
    roles: [],
    fallback: null,
  },
  NewCollection: {
    path: "/new-collections",
    component: <NewCollection />,
    roles: [],
    fallback: null,
  },
  CollectionCategory: {
    path: "/collection-category",
    component: <CollectionCategory />,
    roles: [],
    fallback: null,
  },
  NewCollectionCategory: {
    path: "/new-collection-category",
    component: <NewCollectionCategory />,
    roles: [],
    fallback: null,
  },
  ContactSettings: {
    path: "/contact-types",
    component: <ContactSettings />,
    roles: [],
    fallback: null,
  },
  CustomerContacts: {
    path: "/all-business-contacts",
    component: <AllBusinessContacts />,
    roles: [],
    fallback: null,
  },
  CustomerCompanies: {
    path: "/all-companies",
    component: <AllBusinessContacts activeTabSection={1} />,
    // component: <AllCompanies />,
    roles: [],
    fallback: null,
  },
  NewCompanyContacts: {
    path: "/new-company-contacts",
    component: <CompanyInformation />,
    roles: [],
    fallback: null,
  },
  BusinessCustomerNew: {
    path: "/new-customer",
    component: <ConsumerOverview />,
    roles: [],
    fallback: null,
  },
  BusinessCustomer: {
    path: "/business-customer",
    component: <ConsumerOverview />,
    roles: [],
    fallback: null,
  },
  LeadGeneration: {
    path: "/lead-generation",
    component: <LeadGeneration />,
    roles: [],
    fallback: null,
  },
  NewLeadGeneration: {
    path: "/new-lead-generation",
    component: <NewLeadGeneration />,
    roles: [],
    fallback: null,
  },
  UpdateLeadGeneration: {
    path: "/lead-generation/:id",
    component: <NewLeadGeneration />,
    roles: [],
    fallback: null,
  },
  AllEndUsers: {
    path: "/all-end-users",
    component: <AllEndUsers />,
    roles: [],
    fallback: null,
  },
  AllUserGroup: {
    path: "/all-users-groups",
    component: <AllUserGroup />,
    roles: [],
    fallback: null,
  },
  BusinessGroup: {
    path: "/business-group",
    component: <AllBusinessContacts activeTabSection={2} />,
    // component: <BusinessGroup />,
    roles: [],
    fallback: null,
  },
  BusinessContactFavorites: {
    path: "/business-contact-favorites",
    component: <AllBusinessContacts activeTabSection={3} />,
    roles: [],
    fallback: null,
  },
  BusinessContactRecentlyAdded: {
    path: "/business-contact-recently-added",
    component: <AllBusinessContacts activeTabSection={4} />,
    roles: [],
    fallback: null,
  },
  BusinessContactArchived: {
    path: "/business-contact-archived",
    component: <AllBusinessContacts activeTabSection={5} />,
    roles: [],
    fallback: null,
  },
  BusinessContactBlocked: {
    path: "/business-contact-blocked",
    component: <AllBusinessContacts activeTabSection={6} />,
    roles: [],
    fallback: null,
  },
  BusinessContactDeleted: {
    path: "/business-contact-deleted",
    component: <AllBusinessContacts activeTabSection={7} />,
    roles: [],
    fallback: null,
  },
  BlockedBusinessLocation: {
    path: "/blocked-business-location",
    component: <BlockedBusinessLocation  />,
    roles: [],
    fallback: null,
  },
  UpdateBusinessLocation: {
    path: "/blocked-business-location/:id/edit",
    component: <UpdateBusinessLocation  />,
    roles: [],
    fallback: null,
  },
  NewMediaRelease: {
    path: "/new-media-release",
    component: <NewMediaRelease />,
    roles: [],
    fallback: null,
  },
  UpdateMediaRelease: {
    path: "/update-media-release",
    component: <NewMediaRelease />,
    roles: [],
    fallback: null,
  },
  ReleaseCategory: {
    path: "/release-category",
    component: <ReleaseCategory />,
    roles: [],
    fallback: null,
  },
  MainDashboard: {
    path: "/dashboard",
    component: <MainDashboard />,
    roles: [],
    fallback: null,
  },
  ConsumerOverview: {
    path: "/consumer-overview",
    component: <ConsumerOverview />,
    roles: [],
    fallback: null,
  },
  CustomerOverview: {
    path: "/customer-overview",
    component: <CustomerOverview />,
    roles: [],
    fallback: null,
  },
  DashboardEvents: {
    path: "/dashboard-events",
    component: <DashboardEvents />,
    roles: [],
    fallback: null,
  },
  PastEvents: {
    path: "/past-events",
    component: <PastEvents />,
    roles: [],
    fallback: null,
  },
  UpcommingEvents: {
    path: "/upcomming-events",
    component: <UpcommingEvent />,
    roles: [],
    fallback: null,
  },
  EventCategories: {
    path: "/event-categories",
    component: <EventCategories />,
    roles: [],
    fallback: null,
  },
  NewEvent: {
    path: "/new-event",
    component: <NewEvent />,
    roles: [],
    fallback: null,
  },
  NewCategory: {
    path: "/new-category",
    component: <NewCategory />,
    roles: [],
    fallback: null,
  },
  ProductInventory: {
    path: "/product-inventory",
    component: <ProductInventory />,
    roles: [],
    fallback: null,
  },
  NewProduct: {
    path: "/new-product",
    component: <NewProduct />,
    roles: [],
    fallback: null,
  },
  UpdateProduct: {
    path: "/update-product",
    component: <NewProduct />,
    roles: [],
    fallback: null,
  },
  ProductCategoriesObj: {
    path: "/product-categories",
    component: <ProductCategories />,
    roles: [],
    fallback: null,
  },
  NewProductCategory: {
    path: "/new-product-category",
    component: <NewProductCategory />,
    roles: [],
    fallback: null,
  },
  UpdateProductCategory: {
    path: "/update-product-category",
    component: <NewProductCategory />,
    roles: [],
    fallback: null,
  },
  ProductSerials: {
    path: "/product-serials",
    component: <ProductSerials />,
    roles: [],
    fallback: null,
  },
  ProductSerial: {
    path: "/product-serial",
    component: <ProductSerial />,
    roles: [],
    fallback: null,
  },
  Brands: {
    path: "/brands",
    component: <Brands />,
    roles: [],
    fallback: null,
  },
  ProductPricing: {
    path: "/product-pricing",
    component: <ProductPricing />,
    roles: [],
    fallback: null,
  },
  NewsRoomPortal: {
    path: "/newsroom-portal",
    component: <NewsRoomPortal />,
    roles: [],
    fallback: null,
  },
  LocatorWidget: {
    path: "/locator-widget",
    component: <LocatorWidget />,
    roles: [],
    fallback: null,
  },
  PriceList: {
    path: "/price-list",
    component: <PriceList />,
    roles: [],
    fallback: null,
  },
  NewPriceList: {
    path: ["/new-price-list", "/update-price-list"],
    component: <NewPriceList />,
    roles: [],
    fallback: null,
  },
  CreatePriceList: {
    path: ["/create-price-list", "/edit-price-list"],
    component: <CreatePriceList />,
    roles: [],
    fallback: null,
  },
  Location: {
    path: "/location",
    component: <Location />,
    roles: [],
    fallback: null,
  },
  NewLocation: {
    path: "/new-location",
    component: <NewLocation />,
    roles: [],
    fallback: null,
  },
  NewReleaseCategory: {
    path: "/new-release-category",
    component: <NewReleaseCategory />,
    roles: [],
    fallback: null,
  },
  NewBusinessGroup: {
    path: "/new-business-group",
    component: <NewBusinessGroup />,
    roles: [],
    fallback: null,
  },
  NewEndUserGroup: {
    path: "/new-enduser-group",
    component: <NewEndUserGroup />,
    roles: [],
    fallback: null,
  },
  Conversation: {
    path: "/all-conversations",
    component: <Conversation />,
    roles: [],
    fallback: null,
  },
  AllConversation: {
    path: "/conversations-list",
    component: <AllConversations ConversationStatus={0} />,
    roles: [],
    fallback: null,
  },
  CompanyInbox: {
    path: ConvInboxTypes.Company.route,
    component: (
      <AllConversations
        ConversationStatus={0}
        filterBy={ConvInboxTypes.Company.value}
      />
    ),
    roles: [],
    fallback: null,
  },
  PressInbox: {
    path: ConvInboxTypes.Press.route,
    component: (
      <AllConversations
        ConversationStatus={0}
        filterBy={ConvInboxTypes.Press.value}
      />
    ),
    roles: [],
    fallback: null,
  },
  SupportInbox: {
    path: ConvInboxTypes.Support.route,
    component: (
      <AllConversations
        ConversationStatus={0}
        filterBy={ConvInboxTypes.Support.value}
      />
    ),
    roles: [],
    fallback: null,
  },
  SalesInbox: {
    path: ConvInboxTypes.SalesOrder.route,
    component: (
      <AllConversations
        ConversationStatus={0}
        filterBy={ConvInboxTypes.SalesOrder.value}
      />
    ),
    roles: [],
    fallback: null,
  },
  AssignedConversations: {
    path: "/assigned-conversations",
    component: <AllConversations ConversationStatus={null} />,
    roles: [],
    fallback: null,
  },
  UnAssignedConversations: {
    path: "/unassigned-conversations",
    component: <AllConversations ConversationStatus={null} />,
    roles: [],
    fallback: null,
  },
  DraftConversations: {
    path: "/draft-conversations",
    component: <AllConversations ConversationStatus={2} />,
    roles: [],
    fallback: null,
  },
  ClosedConversations: {
    path: "/closed-conversations",
    component: <AllConversations ConversationStatus={3} />,
    roles: [],
    fallback: null,
  },
  ArchivedConversations: {
    path: "/archived-conversations",
    component: <AllConversations ConversationStatus={4} />,
    roles: [],
    fallback: null,
  },
  SpamConversations: {
    path: "/spam-conversations",
    component: <AllConversations ConversationStatus={5} />,
    roles: [],
    fallback: null,
  },

  ArchiveConversations: {
    path: "/archive-conversations",
    component: <AllConversations ConversationStatus={6} />,
    roles: [],
    fallback: null,
  },
  TrashConversations: {
    path: "/trash-conversations",
    component: <AllConversations ConversationStatus={7} />,
    roles: [],
    fallback: null,
  },
  ConversationSettings: {
    path: "/conversation-settings",
    component: <ConversationSettings />,
    roles: [],
    fallback: null,
  },
  ConversationType: {
    path: "/conversation-type",
    component: <ConversationCustomizations />,
    roles: [],
    fallback: null,
  },
  ConversationTypeEditView: {
    path: "/conversation-type/:id",
    component: <ConversationTypeEditPreview />,
    roles: [],
    fallback: null,
  },
  ConversationTypeEdit: {
    path: "/conversation-type/:id/edit",
    component: <ConversationCustomizations />,
    roles: [],
    fallback: null,
  },
  SpaProduct: {
    path: "/spa-overview",
    component: <SpaProduct />,
    roles: [],
    fallback: null,
  },
  YourViews: {
    path: "/your-views",
    component: <YourViews />,
    roles: [],
    fallback: null,
  },
  NewContactTypes: {
    path: "/new-contact-types",
    component: <NewContactTypes />,
    roles: [],
    fallback: null,
  },
  AllAdminUsers: {
    path: "/all-admin-users",
    component: <AllAdminUsers />,
    roles: [],
    fallback: null,
  },
  AdminOverview: {
    path: "/admin-overview",
    component: <AdminOverview />,
    roles: [],
    fallback: null,
  },
  SoftwareReleases: {
    path: "/software-releases",
    component: <SoftwareUpdates />,
    roles: [],
    fallback: null,
  },
  SoftwareReleaseDetail: {
    path: "/software-releases/:id",
    component: <SoftwareUpdates />,
    roles: [],
    fallback: null,
  },
  SoftwareBuild: {
    path: "/software-builds/:id",
    component: <SoftwareBuildDashboard />,
    roles: [],
    fallback: null,
  },
  SoftwareBuildPreview: {
    path: "/software-builds/:id/:buildId",
    component: <SoftwareBuildDashboard />,
    roles: [],
    fallback: null,
  },
  SoftwareBuildEdit: {
    path: "/software-new-build/:id/:buildId",
    component: <NewSoftwareBuild />,
    roles: [],
    fallback: null,
  },
  SoftwareNewBuild: {
    path: "/software-new-build/:id",
    component: <NewSoftwareBuild />,
    roles: [],
    fallback: null,
  },
  NewSoftwareRelease: {
    path: "/new-software-release",
    component: <NewSoftwareRelease />,
    roles: [],
    fallback: null,
  },
  SoftwareReleaseEdit: {
    path: "/new-software-release/:id",
    component: <NewSoftwareRelease />,
    roles: [],
    fallback: null,
  },
  SoftwareReleaseDashBoard: {
    path: "/software-update-dashboard",
    component: <SoftwareUpdates />,
    roles: [],
    fallback: null,
  },
  SoftwareReleaseProduct: {
    path: "/software-connected-devices",
    component: <SoftwareUpdates />,
    roles: [],
    fallback: null,
  },
  SoftwareReleaseConnectFeature: {
    path: "/software-update-build",
    component: <SoftwareUpdates />,
    roles: [],
    fallback: null,
  },
  SoftwareReleasAnalytics: {
    path: "/software-update-analytics",
    component: <SoftwareUpdates/>,
    roles: [],
    fallback: null,
  },
  SoftwareReleaseOfishSetting: {
    path: "/software-update-settings",
    component: <SoftwareUpdates />,
    roles: [],
    fallback: null,
  },

  // Admin Route Ends here.
};
export default routesObj;
