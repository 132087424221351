import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";  

const BarChart = ({ labels, barData, title }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "chart",
        data: barData,
        backgroundColor: "rgba(45, 156, 219, 1)",
        barThickness: 30,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        formatter: function (value) {
          return value;
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#E1E3EA",
          borderDash: [7, 7],
        },
        title: {
          display: title ? true : false,
          text:title && title,
        },
      },
    },
  };

  return <Bar data={data} options={options} plugins={[ChartDataLabels]} />;
};

export default BarChart;
