import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getAllProductMedia(productId) {
  return axios
    .post(endpoints.productMedia.getProductMediaByProductId, {
      productId: productId
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

// async function getProductById(id) {
//   return axios
//     .post(endpoints.productInventory.getProductById, {
//       productId: id
//     })
//     .then(response => {
//       return response?.data;
//     })
//     .catch(error => {
//       console.error("error : ", error);
//       return null;
//     });
// }

async function addUpdateProductMedia(productMedia) {
  return axios
    .post(endpoints.productMedia.addupdateProductMedia, productMedia)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteProductMediaById(pmID) {
  return axios
    .post(endpoints.productMedia.deleteProductMediaById, {
      productMediaId: pmID
    })
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function markMainImage(productId, productMediaId) {
  return axios
    .post(endpoints.productMedia.updateProductMediaMainImage, {
      productId: productId,
      productMediaId: productMediaId
    })
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function markUseOnStore(productMediaId, useOnStore) {
  return axios
    .post(endpoints.productMedia.updateOnStoreProduct, {
      userMediaId: productMediaId,
      useOnStore: useOnStore
    })
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

export const productMediaApi = {
  getAllProductMedia: getAllProductMedia,
  // getProductById: getProductById,
  addUpdateProductMedia: addUpdateProductMedia,
  deleteProductMediaById: deleteProductMediaById,
  markMainImage: markMainImage,
  markUseOnStore: markUseOnStore
};
