import React from "react";
import AllAtributesGrid from "./AllAtributesGrid";

function AllAttributes(props) {
  return (
    <div className='attributesInnerWrapper'>
      <AllAtributesGrid />
    </div>
  );
}

export default AllAttributes;
