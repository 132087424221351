import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";
import {
  EditIcon,
  SortIcon,
  StarFill,
  StarOutline,
  ViewEye,
  Delete,
} from "../../LeftNav/SvgIcons";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { endpoints } from "../../../../config/apiConfig";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },

  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

const UsersGroupGrid = ({
  customerGroupsData,
  loadData,
  order,
  onOrderBy,
  onEditClick,
  EditableRecord,
  EditAbleRow,
  onViewClick,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const [isSelected, setSelection] = useState(false);

  const deleteGridList = () => {
    var userIds = [];
    customerGroupsData.map(x => {
      if (x.checked) {
        userIds.push(x.groupId);
      }
    });
    if (userIds.length > 0) {
      // startLoading();
      dispatch(contactsActions.onDeleteGroups(userIds)).then(data => {
        if (!data?.success) {
          toast.error(data?.message);
        } else {
          setIsDelete(false);
          loadData();
        }
      });
    } else {
      toast.error("Please select the group.");
    }
  };

  const deleteUserGroup = useCallback(
    ({ groupId }) => {
      if (groupId && groupId > 0) {
        dispatch(contactsActions.onDeleteGroups([groupId])).then(data => {
          if (!data?.success) {
            toast.error(data?.message);
          } else {
            setIsDelete(false);
            loadData();
          }
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    onSelectAll(customerGroupsData, isSelected);
  }, [isSelected]);

  const onSelectAll = (items, isSelected) => {
    const selectionList = items.map(x => {
      return {
        ...x,
        checked: isSelected,
      };
    });
    setIsDelete(isSelected);
    dispatch(contactsActions.onChange("endUserGroup", [...selectionList]));
  };

  const onMarkFavEndUserGroup = selectedGroup => {
    dispatch(
      contactsActions.onPostCustomersEndpoint({
        endpoint: endpoints.customers.markUnMarkFavouriteUserGroup,
        data: [
          {
            groupId: selectedGroup.id,
            isFavourite: !selectedGroup.isFavorite,
          },
        ],
      })
    ).then(data => {
      if (!data?.success) {
        toast.error(data?.message);
      } else {
        loadData();
      }
    });
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        <Grid item md={9} className={(classes.deleteItems, "tableGridActions")}>
          {isDelete && <Button onClick={() => deleteGridList()}>Delete</Button>}
        </Grid>
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox
                className={classes.checkBoxFont}
                onClick={() => setSelection(!isSelected)}
                checked={
                  customerGroupsData.length > 0 &&
                  customerGroupsData.length ===
                    customerGroupsData.filter(u => u.checked).length
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Group Name
              <TableSortLabel
                active={order.orderByKey === "GroupName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "GroupName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              No. of Contacts
              <TableSortLabel
                active={order.orderByKey === "TotalContacts"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "TotalContacts",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Date Added
              <TableSortLabel
                active={order.orderByKey === "DateAdded"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "DateAdded",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Group Color
            </TableCell>
            <TableCell
              className={
                (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
              }>
              Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerGroupsData &&
            customerGroupsData.length > 0 &&
            customerGroupsData.map((item, index) => {
              return EditableRecord?.id === item.id ? (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      padding: "0px",
                    }}
                    colSpan={12}>
                    {EditAbleRow}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={index}
                  className={`gridBodyRow endUserRow ${
                    item.checked ? "boxChecked" : " "
                  }`}>
                  <TableCell className={(classes.gridHeaderInner, "gridRow")}>
                    <Checkbox
                      className={(classes.checkBoxFont, "enduserCheckbox")}
                      checked={item.checked}
                      onClick={() => {
                        customerGroupsData.map((x, index) => {
                          if (x.id === item.id) {
                            x.checked = !item.checked;
                          }
                        });
                        var count = customerGroupsData.find(
                          x => x.checked === true
                        );
                        setIsDelete(count ? true : false);
                        dispatch(
                          contactsActions.onChange(
                            "endUserGroup",
                            customerGroupsData
                          )
                        );
                      }}
                    />
                    <span className='endUserPic'>
                      {item?.image ? (
                        <img src={item.image} alt='' />
                      ) : (
                        <i className='userInitials'>{`${item?.groupName
                          ?.charAt(0)
                          ?.toUpperCase()}`}</i>
                      )}
                    </span>
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.groupName}
                  </TableCell>
                  <TableCell
                    className={classes.gridBodyInner}
                    style={{
                      fontWeight: 700,
                      color: "#3699FF",
                    }}>
                    {item.totalContacts}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {moment(item.dateAdded, "DD-MM-YYYY").format("MM/DD/YYYY")}
                  </TableCell>

                  <TableCell className={classes.inUseYes}>
                    <span
                      style={{
                        height: "36px",
                        width: "36px",
                        borderRadius: "50%",
                        display: "flex",
                        backgroundColor: item.color ? item.color : "#EB5757",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    className={(classes.gridBodyInner, "quickLinkWrapper")}>
                    <div className='gridActions'>
                      <span
                        onClick={() => onMarkFavEndUserGroup(item)}
                        style={{
                          margin: "0px 5px",
                        }}>
                        {item?.isFavorite ? <StarFill /> : <StarOutline />}
                      </span>
                      <span
                        onClick={() => {
                          onEditClick(item);
                          // history.push("/new-enduser-group?id=" + item.id);
                        }}>
                        <EditIcon />
                      </span>
                      <span onClick={() => onViewClick(item)}>
                        <ViewEye />
                      </span>
                      <span
                        onClick={() => deleteUserGroup({ groupId: item.id })}>
                        <Delete />
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          {customerGroupsData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any group.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export { UsersGroupGrid };
