import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import { mediaReleases } from "../../../resources/images";
import { endUserActions } from "../../../store/endUser/endUserActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headingWrapper: {
    backgroundColor: "#E5F2FF !important",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    display: "flex",
    alignItems: "center",
  },
  svgIcon: {
    marginRight: "11px",
  },
  expanded: {
    backgroundColor: "rgba(24, 103, 185, 0.08)",
  },
  links: {
    flexDirection: "column",
    paddingBottom: "0",
  },
  boxes: {
    boxShadow: "none",
  },
  hyperLink: {
    marginLeft: "15px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#3699FF",
    },
  },
  activeTest: {
    color: "#3699FF",
  },
}));

export default function ToolsAndPortalNav() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { panel } = useSelector(state => state.endUserReducer);
  const [expanded, setExpanded] = useState(panel);

  const history = useHistory();
  const currentRoot = history ? history.location.pathname : null;

  useEffect(() => {
    if (
      [
        "/locator-widget",
        "/conversation-widget",
        "/price-list",
        "/create-price-list",
      ].includes(currentRoot)
    ) {
      setExpanded("panel1");
      dispatch(endUserActions.onChange("panel", "panel1"));
    }
    if (
      currentRoot === "/newsroom-portal" ||
      currentRoot === "/user-dashboard" ||
      currentRoot === "/online-store"
    ) {
      setExpanded("panel2");
      dispatch(endUserActions.onChange("panel", "panel2"));
    }

    if (currentRoot === "/all-templates") {
      setExpanded("panel3");
      dispatch(endUserActions.onChange("panel", "panel3"));
    }

    if (currentRoot === "/rules") {
      setExpanded("panel4");
      dispatch(endUserActions.onChange("panel", "panel4"));
    }
  }, []);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    dispatch(endUserActions.onChange("panel", panel));
  };

  return (
    <div className='subNavWrapper'>
      <h1>Tools &amp; Portal</h1>
      <div className={classes.root}>
        <Accordion
          className={classes.boxes}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}>
          <AccordionSummary
            expandIcon={<ExpandLess />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            //className='headingWrapper'
            className={
              [
                "/locator-widget",
                "/conversation-widget",
                "/price-list",
                "/new-price-list",
                "/update-price-list",
                "/create-price-list",
                "/edit-price-list",
              ].includes(currentRoot)
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              <img src={mediaReleases} alt='products' className='releaseIcon' />
              Ofish Tools
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='#'
              className={classes.hyperLink}
              // className={`${classes.hyperLink} ${
              //   ["/newsroom-portal"].includes(currentRoot)
              //     ? classes.activeTest
              //     : null
              // }`}
            >
              Conversation Widget
            </Link>

            <Link
              to='/locator-widget'
              // className={classes.hyperLink}
              className={`${classes.hyperLink} ${
                currentRoot === "/locator-widget" ? classes.activeTest : null
              }`}>
              Locator Widget
            </Link>
            <Link to='#' className={classes.hyperLink}>
              Forms
            </Link>
            <Link
              to='/price-list'
              className={`${classes.hyperLink} ${
                [
                  "/price-list",
                  "/new-price-list",
                  "/update-price-list",
                  "/create-price-list",
                  "/edit-price-list",
                ].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              Price Lists
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.boxes}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}>
          <AccordionSummary
            expandIcon={<ExpandLess />}
            aria-controls='panel2a-content'
            id='panel2a-header'
            className={
              currentRoot === "/newsroom-portal" ? classes.headingWrapper : null
            }>
            <Typography className={classes.heading}>
              <img
                src={mediaReleases}
                alt='inventory'
                className='releaseIcon'
              />
              Portals
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='/newsroom-portal'
              // className={classes.hyperLink}
              className={`${classes.hyperLink} ${
                currentRoot === "/newsroom-portal" ? classes.activeTest : null
              }`}>
              Newsroom Portals
            </Link>
            <Link to='/newsroom-portal' className={classes.hyperLink}>
              User Dashboard
            </Link>
            <Link to='#' className={classes.hyperLink}>
              Online Store
            </Link>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion
          className={classes.boxes}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel3a-content'
            id='panel3a-header'
            className={
              currentRoot === "/all-releases" ||
              currentRoot === "/upcoming-releases" ||
              currentRoot === "/past-releases" ||
              currentRoot === "/release-category" ||
              currentRoot === "/new-media-release"
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              <img
                src={mediaReleases}
                alt='inventory'
                className='releaseIcon'
              />
              E-mail Templates
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='#'
              className={classes.hyperLink}
              // className={`${classes.hyperLink} ${
              //   currentRoot === "/inventory" ||
              //   currentRoot === "/warehouses" ||
              //   currentRoot === "/purchase-orders"
              //     ? classes.activeTest
              //     : null
              // }`}
            >
              All templates
            </Link>
            <Link to='#' className={classes.hyperLink}>
              Lead Groups
            </Link>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          className={classes.boxes}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel4a-content'
            id='panel4a-header'
            className={
              currentRoot === "/all-releases" ||
              currentRoot === "/upcoming-releases" ||
              currentRoot === "/past-releases" ||
              currentRoot === "/release-category" ||
              currentRoot === "/new-media-release"
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              <img
                src={mediaReleases}
                alt='inventory'
                className='releaseIcon'
              />
              Workflows
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='#'
              className={classes.hyperLink}
              // className={`${classes.hyperLink} ${
              //   currentRoot === "/inventory" ||
              //   currentRoot === "/warehouses" ||
              //   currentRoot === "/purchase-orders"
              //     ? classes.activeTest
              //     : null
              // }`}
            >
              Rules
            </Link>
          </AccordionDetails>
        </Accordion> */}
      </div>
    </div>
  );
}
