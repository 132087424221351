import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "../../../components/common/checkbox/Checkbox";
import CustomCheckbox from "../../../components/common/checkbox/CustomCheckbox";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { pubReleaseActions } from "../../../store/pubRelease/pubReleaseActions";
import _ from "lodash";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

function LatestReleaseFilter({
  startLoading,
  stopLoading,
  showButton,
  hideButton,
  loadData,
  searchPage,
  onChange,
  value,
}) {
  const dispatch = useDispatch();
  const {
    pubReleaseCategory,
    relDateFilter,
    selectedPRCategory,
    selectedPubDateFilter,
  } = useSelector(state => state.PubReleaseReducer);
  const history = useHistory();

  const selectRelCategory = value => {
    startLoading();
    dispatch(
      pubReleaseActions.onSelectPubRelCategory(value, selectedPRCategory)
    ).then(data => {
      stopLoading();
    });
    opPresFindBtn();
  };

  const selectRelDateFilter = value => {
    dispatch(pubReleaseActions.onSelectPubRelDateFilter(value));
    opPresFindBtn();
  };

  const opPresFindBtn = () => {
    if (selectedPRCategory.length > 0) {
      startLoading();
      let selectedCat = [];
      let selectedDate = 0;
      _.forEach(selectedPRCategory, function (catId) {
        selectedCat.push(parseInt(catId.id));
      });
      const dateAll = selectedPubDateFilter.find(result => {
        return result.id === "0";
      });
      const dateYear = selectedPubDateFilter.find(result => {
        return result.id === "4";
      });
      const date6Month = selectedPubDateFilter.find(result => {
        return result.id === "3";
      });
      const date3Month = selectedPubDateFilter.find(result => {
        return result.id === "2";
      });
      const dateMonth = selectedPubDateFilter.find(result => {
        return result.id === "1";
      });
      if (dateAll) {
        selectedDate = dateAll.id;
      } else if (dateYear) {
        selectedDate = dateYear.id;
      } else if (date6Month) {
        selectedDate = date6Month.id;
      } else if (date3Month) {
        selectedDate = date3Month.id;
      } else if (dateMonth) {
        selectedDate = dateMonth.id;
      } else {
        selectedDate = 0;
      }
      const filterPayload = {
        releaseDate: selectedDate,
        categoryIds: selectedCat,
        take: 100,
        skip: 0,
        title: "",
        dataType: ["CategoryOnly"],
      };
      dispatch(pubReleaseActions.onGetPubRelWithCategory(filterPayload)).then(
        res => {
          stopLoading();
          hideButton();
        }
      );
    } else {
      //toast.error("Please select category first");
      loadData();
    }
  };

  const checkRelCategory = itemId => {
    const relExist = selectedPRCategory.find(releaseCat => {
      return releaseCat.id == itemId;
    });
    if (relExist) {
      return true;
    } else {
      showButton();
      return false;
    }
  };

  const checkRelDateFilter = itemName => {
    const relExist = selectedPubDateFilter.find(releaseFilter => {
      return releaseFilter.id == itemName;
    });
    if (relExist) {
      return true;
    } else {
      return false;
    }
  };

  const onClearFilter = () => {
    loadData();
    showButton();
    dispatch(pubReleaseActions.onChange("selectedPRCategory", []));
    dispatch(pubReleaseActions.onChange("selectedPubDateFilter", []));
  };

  const enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      searchPage();
    }
  };

  return (
    <div className="filterBar">
      <h2>Find A Release</h2>

      <span className="searchBar">
        <SearchIcon onClick={searchPage} />
        <input
          type="text"
          placeholder="Search release"
          onKeyPress={e => enterPressed(e)}
          onChange={onChange}
          value={value}
        />
      </span>

      <h2>
        Release Categories
        {selectedPRCategory?.length > 0 && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              history.push("/releases");
              showButton();
              loadData();
              dispatch(pubReleaseActions.onChange("selectedPRCategory", []));
            }}>
            Clear
          </Button>
        )}
      </h2>
      <ul>
        {pubReleaseCategory &&
          pubReleaseCategory.length > 0 &&
          pubReleaseCategory.map((item, index) => (
            <>
              {item.name === "No Category" && item.count === 0 ? null : (
                <li key={index}>
                  <span>
                    {/* <Checkbox
                      onClick={() => selectRelCategory(item)}
                      checked={checkRelCategory(item.id)}
                    /> */}
                    <CustomCheckbox
                      checkedId={`releaseCategoryCheckId-${index}`}
                      pressCheck={() => selectRelCategory(item)}
                      checkValue={checkRelCategory(item.id)}
                    />
                    {item.name}
                  </span>
                  <span>{item.count}</span>
                </li>
              )}
            </>
          ))}
      </ul>

      <span className="gap">&nbsp;</span>
      <h2>
        Released Dates
        {selectedPubDateFilter?.length > 0 && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              // showButton();
              // loadData();
              dispatch(pubReleaseActions.onChange("selectedPubDateFilter", []));
            }}>
            Clear
          </Button>
        )}
      </h2>
      <ul>
        {relDateFilter &&
          relDateFilter.length > 0 &&
          relDateFilter.map((item, index) => (
            <li key={index}>
              <span>
                {/* <Checkbox
                  onClick={() => selectRelDateFilter(item)}
                  checked={checkRelDateFilter(item.id)}
                /> */}
                <CustomCheckbox
                  checkedId={`releaseDateCheckId-${index}`}
                  pressCheck={() => selectRelDateFilter(item)}
                  checkValue={checkRelDateFilter(item.id)}
                />
                {item.value}
              </span>
              <span>{item.generic}</span>
            </li>
          ))}
      </ul>
      {/* <div className="filterFooter">
        <Button
          color="primary"
          variant="contained"
          onClick={() => opPresFindBtn()}>
          Find
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => onClearFilter()}>
          Clear
        </Button>
      </div> */}
    </div>
  );
}

export default LatestReleaseFilter;
