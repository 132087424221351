import { combineReducers } from "redux";
import AccountReducer from "./accounts/accountReducer";
import LaunchesReducer from "./launches/launchesReducer";
import AssetsReducer from "./assetsManagement/assetsReducer";
import ContactsReducer from "./contacts/contactsReducer";
import PubReleaseReducer from "./pubRelease/pubReleaseReducer";
import pubMediaLibraryReducer from "./pubMediaLibrary/pubMediaLibraryReducer";
import pubCompanyReducer from "./pubCompany/pubCompanyReducer";
import newsRoomReducer from "./newsroom/newsroomReducer";
import locationsReducer from "./locations/locationsReducer";
import endUserReducer from "./endUser/endUserReducer";
import pubEventReducer from "./pubEvent/pubEventReducer";
import conversationReducer from "./conversations/conversationReducer";
import softwareUpdatesReducer from "./softwareUpdates/softwareUpdatesReducer";
import pubContactType from "./contactTypes/contactTypesReducer";
import softwareBuildReducer from "./softwareUpdates/SoftwareBuildReducer";

export default combineReducers({
  AccountReducer: AccountReducer,
  LaunchesReducer: LaunchesReducer,
  AssetsReducer: AssetsReducer,
  ContactsReducer: ContactsReducer,
  PubReleaseReducer: PubReleaseReducer,
  pubMediaLibraryReducer: pubMediaLibraryReducer,
  pubCompanyReducer: pubCompanyReducer,
  newsRoomReducer: newsRoomReducer,
  LocationsReducer: locationsReducer,
  endUserReducer: endUserReducer,
  pubEventReducer: pubEventReducer,
  conversationReducer: conversationReducer,
  softwareUpdatesReducer: softwareUpdatesReducer,
  pubContactType: pubContactType,
  softwareBuildReducer:softwareBuildReducer,
});
