import React from "react";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import countriesPhone from "../../../../../resources/phoneCode";
import { BiX } from "react-icons/bi";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridOuterWrapper: {
    position: "relative",
  },
  gridWrapper: {
    marginBottom: "6px",
  },

  emptyLabel: {
    marginTop: "26px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  hoursWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

function ContactNumber(props) {
  const dispatch = useDispatch();

  const changeContact = (value, index, fieldName) => {
    dispatch(newsroomActions.onChangeContact(index, value, fieldName));
  };
  const classes = useStyles();
  const { contacts, faxNumber, faxCountryCode } = useSelector(
    state => state.newsRoomReducer
  );
  return (
    <>
      <div className='gap' />
      <div className='gap' />
      <label className='innerLabel'>Contact Number</label>
      <div className='contactWrapperOuter'>
        {contacts.length > 0 &&
          contacts.map((cntct, index) => {
            return (
              <Grid key={index} container spacing={3}>
                <Grid item xs={2} className={classes.gridWrapper}>
                  <label className='toolPortalWrapperlabelStyle'>
                    {`${"Phone Number "}${index + 1}`}
                  </label>
                  <Select
                    value={cntct.countryCode}
                    onChange={e =>
                      changeContact(e.target.value, index, "countryCode")
                    }
                    displayEmpty
                    className={[classes.selectEmpty, classes.emptyLabel]}
                    inputProps={{ "aria-label": "Without label" }}
                    fullWidth
                    variant={"outlined"}>
                    <MenuItem value=''>
                      <span className='customPlaceholder'>{"+1"}</span>
                    </MenuItem>
                    {countriesPhone &&
                      countriesPhone.length > 0 &&
                      countriesPhone.map((cntList, i) => {
                        return (
                          <MenuItem key={i} value={cntList.code}>
                            {cntList.code}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={[classes.gridWrapper, classes.gridOuterWrapper]}>
                  <InputField
                    placeholder='phone number'
                    inputlabel=' '
                    value={cntct.number}
                    onChange={e =>
                      changeContact(e.target.value, index, "number")
                    }
                  />
                  <span style={{ color: "red" }}>
                    {/^[0-9]*$/.test(cntct.number)
                      ? ""
                      : "Invalid phone number"}
                  </span>
                  <span className='deleteRow'>
                    <BiX
                      onClick={() =>
                        dispatch(newsroomActions.onRemoveContact(index))
                      }
                    />
                  </span>
                </Grid>
              </Grid>
            );
          })}
        <Grid item xs={2} className={[classes.gridWrapper, "addNumberBtn"]}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => dispatch(newsroomActions.onAddNewContact())}
            className={classes.selectBtn}>
            Add
          </Button>{" "}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={2} className={classes.gridWrapper}>
            <label className='toolPortalWrapperlabelStyle'>
              {"Fax Number"}
            </label>
            <Select
              value={faxCountryCode}
              onChange={e =>
                dispatch(
                  newsroomActions.onChange("faxCountryCode", e.target.value)
                )
              }
              displayEmpty
              className={[classes.selectEmpty, classes.emptyLabel]}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              variant={"outlined"}>
              <MenuItem value=''>
                <span className='customPlaceholder'>{"+1"}</span>
              </MenuItem>
              {countriesPhone &&
                countriesPhone.length > 0 &&
                countriesPhone.map((cntList, i) => {
                  return (
                    <MenuItem key={i} value={cntList.code}>
                      {cntList.code}
                    </MenuItem>
                  );
                })}
            </Select>
            {/* <SelectInput selectlabel="Fax Number" placeholder="+1" /> */}
          </Grid>
          <Grid item xs={4} className={classes.gridWrapper}>
            <InputField
              placeholder='phone number'
              inputlabel=' '
              value={faxNumber}
              onChange={e =>
                dispatch(newsroomActions.onChange("faxNumber", e.target.value))
              }
            />
            <span style={{ color: "red" }}>
              {/^[0-9]*$/.test(faxNumber) ? "" : "Invalid fax number"}
            </span>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ContactNumber;
