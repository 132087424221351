export const newsroomTypes = {
  NEWSROOM_ONCHANGE: "NEWSROOM_ONCHANGE",
  NEWSROOM_ADD_LEADER: "NEWSROOM_ADD_LEADER",
  NEWSROOM_ADD_QUICK_LINKS: "NEWSROOM_ADD_QUICK_LINKS",
  CHANGE_QUICK_LINKS_VALUE: "CHANGE_QUICK_LINKS_VALUE",
  NEWSROOM_REMOVE_QUICK_LINKS: "NEWSROOM_REMOVE_QUICK_LINKS",
  NEWSROOM_ADD_FEATURED_CONTENT: "NEWSROOM_ADD_FEATURED_CONTENT",
  CHANGE_FEATURED_CONTENT_VALUE: "CHANGE_FEATURED_CONTENT_VALUE",
  NEWSROOM_REMOVE_FEATURED_CONTENT: "NEWSROOM_REMOVE_FEATURED_CONTENT",
  NEWSROOM_REMOVE_LEADER: "NEWSROOM_REMOVE_LEADER",
  CHANGE_LEADER_VALUE: "CHANGE_LEADER_VALUE",
  NEWSROOM_ADD_CONTACT: "NEWSROOM_ADD_CONTACT",
  NEWSROOM_ADD_EMAIL: "NEWSROOM_ADD_EMAIL",
  NEWSROOM_ADD_SOCIALS: "NEWSROOM_ADD_SOCIALS",
  CHANGE_BUSINESS_HOURS_VALUE: "CHANGE_BUSINESS_HOURS_VALUE",
  CHANGE_CONTACT_VALUE: "CHANGE_CONTACT_VALUE",
  CHANGE_EMAIL_VALUE: "CHANGE_EMAIL_VALUE",
  CHANGE_SOCIALS_VALUE: "CHANGE_SOCIALS_VALUE",
  NEWSROOM_REMOVE_CONTACT: "NEWSROOM_REMOVE_CONTACT",
  NEWSROOM_REMOVE_EMAIL: "NEWSROOM_REMOVE_EMAIL",
  NEWSROOM_REMOVE_SOCIALS: "NEWSROOM_REMOVE_SOCIALS",
};
