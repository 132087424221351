import React from "react";

function CustomCheckbox({ checkedId, checkValue, pressCheck , disabled }) {
  return (
    <div>
      <input
        class='styled-checkbox'
        id={checkedId}
        disabled={disabled}
        type='checkbox'
        value='value2'
        checked={checkValue}
        onChange={e => pressCheck && pressCheck(e)}
      />
      <label for={checkedId} />
    </div>
  );
}

export default CustomCheckbox;
