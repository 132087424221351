import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { ExpandDownIcon } from "../../LeftNav/SvgIcons";
import { Button, Grid, List, ListItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import ReleaseBasicsAccordian from "./ReleaseBasicsAccordian";
import UserRequirementsAccordian from "./UserRequirementsAccordian";
import ReleaseNotesAccordian from "./ReleaseNotesAccordian";
import ProductsAccordian from "./ProductsAccordian";
import { CheckmarkIcon } from "../SpaProduct/SvgIcons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router";

const SoftwareReleaseEdit = ({
  formName,
  softwareReleaseData,
  selectedBuild,
  selectedRelease,
  fetchRequiredData,
  saveBasicInfo,
  isLoading,
  contactIds,
  groupIds,
  companyIds,
  portalIds,
  selectedReleasePortal,
  selectedReleaseContacts,
  poratlAndContactData,
  customerContactData,
  releaseBasicsProps,
  tempContactData,
  setUpdatedTempData,
  updatedTempData,
  handleDeletedChip,
  filteredProtalData,
  setFilteredProtalData,
  tempPortalData,
  onsubmitPortalContact,
  productsList,
  compatibleProductsList,
  selectedReleaseProducts,
  removeEligibleSelectedDevice,
  handleAddEligibleDevice,
  notificationTypes,
  selectedReleaseNotifications,
  softwareReleaseDetials,
  notificationData,
  setNotificationData,
  setReleaseNoteSelectedData,
  releaseNoteSelectedData,
  submitNotificationData,
  handleDeletedReleaseNotification,
  onSubmitRealsePlan,
  portalAndContactBasicsProps,
  portalListData,
  deletePortalSelectedDevices,
  changeContactTab,
  fetchReleaseData,
}) => {
  const [releaseContent, setRelaseContent] = useState(false);
  const [portalRequirment, setPortalRequirment] = useState(false);
  const [isCheckedWifi, setIsCheckedWifi] = useState(false);
  const [isCheckedBluetooth, setIsCheckedBluetooth] = useState(false);
  const [accordionName, setAccordionName] = useState("");
  const { id: paramId, buildId } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchRequiredData();
    if (formName === "Release") {
      fetchReleaseData();
    }
  }, []);

  const handleOnChangeWifi = () => {
    setIsCheckedWifi(!isCheckedWifi);
  };
  const handleOnChangeBluetooth = () => {
    setIsCheckedBluetooth(!isCheckedBluetooth);
  };

  const handleTestAndPreview = () => {
    buildId
      ? history.push(`/software-builds/${paramId}/${selectedRelease?.id}`)
      : history.push(`/software-releases/${selectedRelease?.id}`);
  };

  return (
    <Grid className="software_release">
      <Accordion
        defaultExpanded={paramId}
        expanded={paramId}
        className="softwareReleaseAccordion"
      >
        <AccordionSummary
          expandIcon={<ExpandDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => {
            setRelaseContent(!releaseContent);
          }}
        >
          <Grid className="user_requirements_header">
            <Typography>{buildId ? "Build" : "Release"} Basics</Typography>
            {!paramId && (
              <List>
                <ListItem className="accordianHeading">
                  Manage the Basics Regarding This Software{" "}
                  {buildId ? "Build" : "Release"}
                </ListItem>
              </List>
            )}
          </Grid>

          {paramId && (
            <Grid container alignItems="center" className="head">
              <Button
                variant="outlined"
                className="editModuleBtn"
                onClick={() => setAccordionName("releaseBasics")}
              >
                Edit Module
              </Button>
            </Grid>
          )}
          {!paramId && (
            <Grid container alignItems="center" className="head">
              <CheckmarkIcon />
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReleaseBasicsAccordian
            saveBasicInfo={saveBasicInfo}
            formName={formName}
            setRelaseContent={setRelaseContent}
            releaseContent={releaseContent}
            setPortalRequirment={setPortalRequirment}
            portalRequirment={portalRequirment}
            softwareReleaseData={softwareReleaseData}
            accordionName={accordionName}
            setAccordionName={setAccordionName}
            paramId={paramId}
            selectedRelease={selectedRelease}
            releaseBasicsProps={releaseBasicsProps}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={paramId} expanded={paramId}>
        <AccordionSummary
          expandIcon={<ExpandDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Grid className="user_requirements_header">
            <Typography>Portal/User Requirements</Typography>
            {!paramId && (
              <List>
                <ListItem className="accordianHeading">
                  Set minimum requirements for this{" "}
                  {buildId ? " Build " : " Release "}
                  so it runs on the correct Portal versions for the right users
                </ListItem>
              </List>
            )}
          </Grid>

          {paramId && (
            <Grid container alignItems="center" className="head">
              <Button
                variant="outlined"
                className="editModuleBtn"
                onClick={() => setAccordionName("portalAndContact")}
              >
                Edit Module
              </Button>
            </Grid>
          )}
          {!paramId && (
            <Grid container alignItems="center" className="head">
              <CheckmarkIcon />
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <UserRequirementsAccordian
            checkedWifi={isCheckedWifi}
            onChangeWifi={handleOnChangeWifi}
            checkedBluetooth={isCheckedBluetooth}
            onChangeBluetooth={handleOnChangeBluetooth}
            accordionName={accordionName}
            setAccordionName={setAccordionName}
            paramId={paramId}
            buildId={formName === "Build"}
            selectedRelease={selectedRelease}
            isLoading={isLoading}
            contactIds={contactIds}
            groupIds={groupIds}
            companyIds={companyIds}
            portalIds={portalIds}
            selectedReleasePortal={selectedReleasePortal}
            selectedReleaseContacts={selectedReleaseContacts}
            poratlAndContactData={poratlAndContactData}
            customerContactData={customerContactData}
            portalAndContactBasicsProps={portalAndContactBasicsProps}
            tempContactData={tempContactData}
            setUpdatedTempData={setUpdatedTempData}
            updatedTempData={updatedTempData}
            handleDeletedChip={handleDeletedChip}
            filteredProtalData={filteredProtalData}
            setFilteredProtalData={setFilteredProtalData}
            tempPortalData={tempPortalData}
            onsubmitPortalContact={onsubmitPortalContact}
            portalListData={portalListData}
            deletePortalSelectedDevices={deletePortalSelectedDevices}
            changeContactTab={changeContactTab}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={paramId} expanded={paramId}>
        <AccordionSummary
          expandIcon={<ExpandDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="eligibleDevices"
        >
          <Grid className="user_requirements_header">
            <Typography>Eligible Devices</Typography>
            {!paramId && (
              <List>
                <ListItem className="accordianHeading">
                  Select the products that are eligible to receive this{" "}
                  {buildId ? " build" : " release"}.
                </ListItem>
              </List>
            )}
          </Grid>

          {paramId && (
            <Grid container alignItems="center" className="head">
              <Button
                variant="outlined"
                className="editModuleBtn"
                onClick={() => setAccordionName("eligibleDevices")}
              >
                Edit Module
              </Button>
            </Grid>
          )}
          {!paramId && (
            <Grid container alignItems="center" className="head">
              <CheckmarkIcon />
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ProductsAccordian
            buildId={formName === "Build"}
            accordionName={accordionName}
            setAccordionName={setAccordionName}
            paramId={paramId}
            productsList={productsList}
            compatibleProductsList={compatibleProductsList}
            selectedReleaseProducts={selectedReleaseProducts}
            selectedRelease={selectedRelease}
            removeEligibleSelectedDevice={removeEligibleSelectedDevice}
            handleAddEligibleDevice={handleAddEligibleDevice}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={paramId} expanded={paramId}>
        <AccordionSummary
          expandIcon={<ExpandDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Grid className="user_requirements_header">
            <Typography>{buildId ? "Build " : "Release "} Plan</Typography>
            {!paramId && (
              <List>
                <ListItem className="accordianHeading">
                  Choose and customize how, when, and what information will be
                  sent to users regarding this {buildId ? " build" : " release"}
                  .
                </ListItem>
              </List>
            )}
          </Grid>

          {paramId && (
            <Grid container alignItems="center" className="head">
              <Button
                variant="outlined"
                className="editModuleBtn"
                onClick={() => setAccordionName("notificationRelease")}
              >
                Edit Module
              </Button>
            </Grid>
          )}

          {!paramId && (
            <Grid container alignItems="center" className="head">
              <CheckmarkIcon />
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReleaseNotesAccordian
            accordionName={accordionName}
            setAccordionName={setAccordionName}
            paramId={paramId}
            formName={formName}
            notificationTypes={notificationTypes}
            selectedRelease={selectedRelease}
            isLoading={isLoading}
            selectedReleasePortal={selectedReleasePortal}
            selectedReleaseNotifications={selectedReleaseNotifications}
            softwareReleaseDetials={softwareReleaseDetials}
            notificationData={notificationData}
            setNotificationData={setNotificationData}
            setReleaseNoteSelectedData={setReleaseNoteSelectedData}
            releaseNoteSelectedData={releaseNoteSelectedData}
            submitNotificationData={submitNotificationData}
            handleDeletedReleaseNotification={handleDeletedReleaseNotification}
            onSubmitRealsePlan={onSubmitRealsePlan}
          />
        </AccordionDetails>
      </Accordion>
      <Grid className="buttonContainer" container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleTestAndPreview();
          }}
          className="saveAndReviewButton"
        >
          Test & {buildId ? " Build" : " Release"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SoftwareReleaseEdit;
