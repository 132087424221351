import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { TopNavigator } from "../../../../components/common/Tabs/TopNavigator";
import Dashbard from "../Dashbard";
import { useHistory } from "react-router";
import { ArrowBackIos } from "@material-ui/icons";
import { useParams } from "react-router";
import { softwareBuildActions } from "../../../../store/softwareUpdates/SoftwareBuildActions";
import { useDispatch, useSelector } from "react-redux";
import { SoftwareBuild } from "./SoftwareBuild";
import { SoftwareReleasePreview } from "./SoftwareReleasePreview";
import { AnalyticsPreview } from "./AnalyticsPreview";
import { NotesPreview } from "./NotesPreview";
import { useDebounce } from "../../../../utils/use-debounce";

const SoftwareBuildDashboard = () => {
  const history = useHistory();
  const { id: selectedReleaseId, buildId } = useParams();
  const dispatch = useDispatch();
  const {
    selectedBuild,
    selectedBuildVersions,
    paginationBuildResponse,
    isLoading,
    selectedBuildPortal,
    selectedBuildContacts,
    selectedBuildProducts,
    poratlAndContactBuildData,
    softwareBuildDetials,
    selectedBuildNotifications,
    notificationBuildTypes,
  } = useSelector((state) => state.softwareBuildReducer);
  const [activeTab, setActiveTab] = useState(1);
  const [order, setOrderBy] = useState({
    orderByKey: "UpdateName",
    orderVal: 0,
  });
  const [tabValue, setTabValue] = useState("all");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [updatedTempData, setUpdatedTempData] = useState([]);

  const { debouncedValue } = useDebounce(search, 500);
  
  useEffect(() => {
    dispatch(
      softwareBuildActions.onGetAllSoftwareReleasesBuild({
        search: debouncedValue,
        tab: tabValue === "all" ? "" : tabValue,
        softwareReleaseId: selectedReleaseId,
        pagination: {
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
          pageSize: rowsPerPage,
          pageNo: page,
        },
      })
    );
  }, [
    dispatch,
    order.orderByKey,
    order.orderVal,
    page,
    rowsPerPage,
    debouncedValue,
    tabValue,
  ]);

  const fetchReleaseData = useCallback(() => {
    if (buildId) {
      dispatch(
        softwareBuildActions.onGetSoftwareBuildById(buildId)
      );
      dispatch(
        softwareBuildActions.onGetSoftwareContactsReleaseBuildById(buildId)
      );
      dispatch(
        softwareBuildActions.onGetSoftwareReleaseBuildPortalById(buildId)
      );
      dispatch(
        softwareBuildActions.onGetSoftwareReleaseBuildProducts({
          softwareReleaseId: Number(selectedReleaseId),
          softwareReleaseBuildId: Number(buildId),
          productName: "",
          liveVersion: "",
          brand: "",
          category: "",
          sku: "",
        })
      );
      dispatch(
        softwareBuildActions.onGetSoftwareReleaseBuildDetails(buildId)
      );
    }
  }, [dispatch, buildId, selectedReleaseId]);

  useEffect(() => {
    if (selectedReleaseId) {
      const updatedRecords = selectedBuildContacts.map((item) => {
        const newItem = {
          id: item.contactId
            ? item.contactId
            : item.companyId
              ? item.companyId
              : item.groupId,
          value: item.contactId
            ? `${item.contact.firstName} ${item.contact.lastName}`
            : item.companyId
              ? item.company.name
              : item.group.groupName,
          generic: item.contactId
            ? "Contact"
            : item.companyId
              ? "Company"
              : "Group",
        };
        let key = "";
        if (newItem.generic === "Contact") {
          key = "contactBuildIds";
        } else if (newItem.generic === "Group") {
          key = "groupBuildIds";
        } else {
          key = "companyBuildIds";
        }
        dispatch(
          softwareBuildActions.updateSelectedBuildContacts(key, newItem.id)
        );

        return newItem;
      });
      setUpdatedTempData([...updatedRecords]);
    }
  }, [selectedReleaseId, selectedBuildContacts]);

  const markUnMarkFavouriteDevices = (item) => {
    dispatch(
      softwareBuildActions.onMarkUnMarkSoftwareReleaseBuildFavourite({
        softwareReleaseId: item.softwareReleaseId,
        softwareReleaseBuildId: item.id,
        isFavourite: !item.isFavourite,
      })
    );
  };

  const deleteSoftwareReleaseBuild = (id) => {
    dispatch(
      softwareBuildActions.onDeleteSoftwareReleaseBuild(id)
    );
  }

  const buttonTextStyle = {
    color: "#3699FF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.28px",
  };

  return (
    <Dashbard>
      <div className="software_newDesign">
        <div className="body-content newDesign">
          <div className="contentHeading  launchBtn">
            <h1>
              {!buildId
                ? "Software Release Builds"
                : selectedBuild.nameInternal}
            </h1>
            <Button
              color="primary"
              variant="contained"
              className="newSoftwareReleaseBtn"
              startIcon={<AddIcon />}
              onClick={() => history.push(`/software-new-build/${selectedReleaseId}`)}
            >
              New Software Build
            </Button>
          </div>
          <Grid>
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                dispatch(softwareBuildActions.onResetSoftwareBuildData());
                !buildId
                  ? history.push(`/software-releases/${selectedReleaseId}`)
                  : history.push(`/software-builds/${selectedReleaseId}`);
              }}
            >
              <ArrowBackIos style={{ fontSize: "14px" }} />
              <span style={buttonTextStyle}>
                {!buildId
                  ? "Back to Software Preview"
                  : "Back to All Software Builds"}
              </span>
            </Button>
          </Grid>
          {!buildId ? (
            <Grid className="releasePlanTabs">
              <TopNavigator
                tabs={[
                  {
                    id: 1,
                    title: "All Version",
                    component: "",
                  },
                  {
                    id: 2,
                    title: "Latest Version",
                    component: "",
                  },
                  {
                    id: 3,
                    title: "In Testing",
                    component: "",
                  },
                  {
                    id: 4,
                    title: "Retired Versions",
                    component: "",
                  },
                ]}
                getActiveTabId={(tabId) => setActiveTab(tabId)}
              />
            </Grid>
          ) : (
            <Grid className="previewTopTabs">
              <TopNavigator
                tabs={[
                  {
                    id: 1,
                    title: "Build Information",
                    component: <SoftwareReleasePreview
                      buildId={buildId}
                      isLoading={isLoading}
                      selectedRelease={selectedBuild}
                      selectedReleasePortal={selectedBuildPortal}
                      selectedReleaseContacts={selectedBuildContacts}
                      selectedReleaseProducts={selectedBuildProducts}
                      poratlAndContactData={poratlAndContactBuildData}
                      selectedReleaseId={selectedReleaseId}
                      fetchReleaseData={fetchReleaseData}
                      softwareReleaseDetials={softwareBuildDetials}
                      selectedReleaseNotifications={selectedBuildNotifications}
                      notificationTypes={notificationBuildTypes}
                      updatedTempData={updatedTempData}
                    />,
                  },
                  {
                    id: 2,
                    title: "Analytics",
                    component: <AnalyticsPreview buildId={buildId} />,
                  },
                  {
                    id: 3,
                    title: "Notes",
                    component: <NotesPreview buildId={buildId} />,
                  },
                ]}
                getActiveTabId={(id) => {
                  if (id === 4) {
                    history.push(`/software-builds/${selectedReleaseId}`);
                  }
                }}
              />
            </Grid>
          )}
          {!buildId ? (
            <div className="softwareReleaseContainer">
              <SoftwareBuild
                activeTab={activeTab}
                formName={"Build"}
                selectedVersions={selectedBuildVersions}
                paginationResponse={paginationBuildResponse}
                isLoading={isLoading}
                setOrderBy={setOrderBy}
                order={order}
                setTabValue={setTabValue}
                tabValue={tabValue}
                search={search}
                setSearch={setSearch}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                markUnMarkFavouriteDevices={markUnMarkFavouriteDevices}
                deleteSoftwareRelease={deleteSoftwareReleaseBuild}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Dashbard>
  );
};
export default SoftwareBuildDashboard;
