import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { memo, useEffect, useState } from "react";

/**
 * A phone number input component.
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the input.
 * @param {string} props.placeholder - The placeholder text for the input.
 * @param {string} props.helperText - The helper text to display below the input.
 * @param {Object} props.style - The custom styles to apply to the component.
 * @param {string} props.type - The type of the input.
 * @param {boolean} props.required - Indicates if the input is required.
 * @param {string} props.value - The value of the input.
 * @param {function} props.onChange - The function to call on value change.
 * @returns {JSX.Element} The rendered PhoneInput component.
 */

const PhoneNumberInput = memo(
  ({
    disabled,
    label,
    placeholder,
    helperText,
    style,
    type,
    required,
    readOnly,
    helperInfo,
    func,
    handleChange,
    headComponent,
    contactData,
    value,
    onChange,
    ...rest
  }) => {
    
    /**
     * Event handler for onChange event of the phone number input.
     * @param {string} value - The phone number value entered by the user.
     * @param {object} countryData - The country data object.
     */

    return (
     <FormControl fullWidth>
      <MuiPhoneNumber
        defaultCountry={"us"}
        onChange={onChange}
        value={value ? `+${value}` : ''}
        name={rest.name}
        id={rest.name}
        data-testid={rest.name}
        disabled={disabled}
        fullWidth
        required
        error={rest.error}
        helperText={rest.helperText || helperText}
        inputProps={{ readOnly: readOnly }}
        {...rest}
      />
      {rest?.error && <FormHelperText style={{color:"#F44336"}}>{rest?.error}</FormHelperText>}
      </FormControl>
    );
  }
);

export default PhoneNumberInput;
