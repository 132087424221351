import { locatorWidgetApi } from "./locatorWidgetApi";
import { locatorWidgetTypes } from "./locatorWidgetTypes";

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: locatorWidgetTypes.LOCATORWIDGET_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onGetFilterOptionsKeyValuePair = (id) => async (dispatch) => {
  let response = await locatorWidgetApi.getFilterOptionsKeyValuePair(id);
  return response;
};

const onGetFilterOptions = () => async (dispatch) => {
  let response = await locatorWidgetApi.getFilterOptions();
  return response;
};

const onGetSelectedFilterOptions = (id) => async (dispatch) => {
  let response = await locatorWidgetApi.getSelectedFilterOptions(id);
  return response;
};

const onGetSettings = (id) => async (dispatch) => {
  let response = await locatorWidgetApi.getSettings(id);
  return response;
};

const onSaveFilters = (id, filters) => async (dispatch) => {
  let response = await locatorWidgetApi.saveFilters(id, filters);
  return response;
};

const onSaveFilterOption = (id, name, type) => async (dispatch) => {
  let response = await locatorWidgetApi.saveFilterOption(id, name, type);
  return response;
};

const onSaveSettings = (id, title, subtitle, status) => async (dispatch) => {
  let response = await locatorWidgetApi.saveSettings(
    id,
    title,
    subtitle,
    status
  );
  return response;
};

const onGetEmbedCode = (id) => async (dispatch) => {
  let response = await locatorWidgetApi.getEmbedCode(id);
  return response;
};

const onSaveEmbedCode = (id, embedCode) => async (dispatch) => {
  let response = await locatorWidgetApi.saveEmbedCode(id, embedCode);
  return response;
};

const onGetCompanyOverview = () => async (dispatch) => {
  let response = await locatorWidgetApi.getCompanyOverview();
  return response;
};

const onGetLeadership = () => async (dispatch) => {
  let response = await locatorWidgetApi.getLeadership();
  return response;
};

const onGetContactInfo = () => async (dispatch) => {
  let response = await locatorWidgetApi.getContactInfo();
  return response;
};

const onSaveCompanyOverview = (desc, imgUrl) => async (dispatch) => {
  let response = await locatorWidgetApi.saveCompanyOverview(desc, imgUrl);
  return response;
};

const onSaveBoilerPlateDescription = (desc) => async (dispatch) => {
  let response = await locatorWidgetApi.onSaveBoilerPlateDescription(desc);
  return response;
};

const onSaveLeaderShip = (leaderValue) => async (dispatch) => {
  let response = await locatorWidgetApi.saveLeaderShip(leaderValue);
  return response;
};

const onSaveComContact = (contactData) => async (dispatch) => {
  let response = await locatorWidgetApi.saveComContact(contactData);
  return response;
};

const onGetQuickLinks = () => async (dispatch) => {
  let response = await locatorWidgetApi.getQuickLinks();
  return response;
};

const onGetFeaturedContent = () => async (dispatch) => {
  let response = await locatorWidgetApi.getFeaturedContent();
  return response;
};

const onGetMenuOptions = () => async (dispatch) => {
  let response = await locatorWidgetApi.getMenuOptions();
  return response;
};

const onSaveQuickLinks = (contactData) => async (dispatch) => {
  let response = await locatorWidgetApi.saveQuickLinks(contactData);
  return response;
};

const onSaveFeaturedContent =
  (
    contentId,
    slideTitle,
    slideSubTitle,
    bannerImageUrl,
    linkTo,
    url,
    urlTarget,
    showImageOnly
  ) =>
  async (dispatch) => {
    let response = await locatorWidgetApi.saveFeaturedContent(
      contentId,
      slideTitle,
      slideSubTitle,
      bannerImageUrl,
      linkTo,
      url,
      urlTarget,
      showImageOnly
    );
    return response;
  };

export const locatorWidgetActions = {
  onChange: onChange,
  onGetCompanyOverview: onGetCompanyOverview,
  onGetLeadership: onGetLeadership,
  onGetContactInfo: onGetContactInfo,
  onSaveCompanyOverview: onSaveCompanyOverview,
  onSaveLeaderShip: onSaveLeaderShip,
  onSaveComContact: onSaveComContact,
  onGetQuickLinks: onGetQuickLinks,
  onSaveQuickLinks: onSaveQuickLinks,
  onSaveBoilerPlateDescription: onSaveBoilerPlateDescription,
  onSaveSettings: onSaveSettings,
  onGetSettings: onGetSettings,
  onSaveFeaturedContent: onSaveFeaturedContent,
  onGetFeaturedContent: onGetFeaturedContent,
  onGetMenuOptions: onGetMenuOptions,
  onGetEmbedCode: onGetEmbedCode,
  onSaveEmbedCode: onSaveEmbedCode,
  onGetFilterOptions: onGetFilterOptions,
  onGetFilterOptionsKeyValuePair: onGetFilterOptionsKeyValuePair,
  onSaveFilters: onSaveFilters,
  onGetSelectedFilterOptions: onGetSelectedFilterOptions,
  onSaveFilterOption: onSaveFilterOption,
};
