import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ArrowBackIos } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import queryString from "query-string";
import Dashbard from "../Dashbard";
import { NewCollectionForm } from "./NewCollectionForm";

function NewCollection() {
  const history = useHistory();
  const location = useLocation();
  const selectedCollection = queryString.parse(location.search);

  const [collectionHeading, setCollectionHeading] = useState("New Collection");

  return (
    <Dashbard>
      <div className='body-content'>
        <div className='contentHeading'>
          <h1>{collectionHeading} </h1>
          <div
            style={{
              position: "absolute",
              top: "40px"
            }}>
            <Button
              color='primary'
              variant='text'
              onClick={() => history.push("/all-collections")}>
              <ArrowBackIos style={{ fontSize: "14px" }} />
              {`Back to All Collections`}
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <NewCollectionForm
            activeCollectionId={selectedCollection?.id}
            isSaveCollBtnShown={false}
            onCollectionName={name => {
              setCollectionHeading(name);
            }}
          />
        </div>
      </div>
    </Dashbard>
  );
}

export default NewCollection;
