import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { StarOutline, StarFill } from "../../../LeftNav/SvgIcons";
import { useHistory } from "react-router-dom";
import {
  Pagination,
  ListViewTile,
  Spinner,
} from "../../../../../components/common";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import AllContactsGrid from "../AllContactsGrid";
import { AllBusinessContactsGridFilter } from ".";
import { RoutesObj } from "../../../../../routes";
import { AllCompaniesGrid } from "../Comapnies";
import { endpoints } from "../../../../../config/apiConfig";
import CustomerGroupGrid from "../CustomerGroupGrid";
import { AddNewBusinessGroup } from "../AddNewBusinessGroup";

const FavBusinessContactDataView = ({ isRecentlyAdded }) => {
  const dispatch = useDispatch();
  const { isBCListView } = useSelector(state => state.endUserReducer);

  const [bcList, setBCList] = useState([]);
  const [cList, setCList] = useState([]);
  const [bgList, setBGList] = useState([]);
  const [isListView, setIsListView] = useState(true);
  const [isRating, setIsRating] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [activeTabId, setActiveTabId] = useState("1");
  const [isFilterAppling, setIsFilterAppling] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterModel, setFilters] = useState({
    searchText: "",
    startDate: "",
    endDate: "",
    productCategories: [],
    productSubCategoryId: [],
    environmentId: [],
    productName: [],
    productSKU: [],
    countryId: [],
    stateId: [],
    cityId: [],
    address: [],
    firstName: [],
    lastName: [],
    email: [],
    lastLogin: null,
    deviceRegistered: [],
    rating: null,
    companyName: null,
    date: 0,
    groupIds: null,
    id: [],
    userStatus: [],
    isFavorite: !isRecentlyAdded,
  });
  const [bcPaginationResponse, setBCPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [cPaginationResponse, setCPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [bgPaginationResponse, setBGPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [bcPage, setBCPage] = useState(1);
  const [cPage, setCPage] = useState(1);
  const [bgPage, setBGPage] = useState(1);

  const [rowsPerPageBC, setRowsPerPageBC] = useState(10);
  const [rowsPerPageC, setRowsPerPageC] = useState(10);
  const [rowsPerPageBG, setRowsPerPageBG] = useState(10);

  const [bcOrderBy, setBCOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [cOrderBy, setCOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [bgOrderBy, setBGOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });

  const [editableRecord, setEditableRecord] = useState(null);

  const history = useHistory();

  const redirectToBusinessCContact = value => {
    history.push({
      pathname: RoutesObj.BusinessCustomer.path,
      companyId: value.companyId,
      search: `id=${value.companyId}`,
      state: { scrollTo: ".businessContacts" },
    });
  };

  const redirectToCompany = value => {
    history.push({
      pathname: RoutesObj.NewCompanyContacts.path,
      state: { company: value },
      search: `id=${value.companyId}`,
    });
  };

  const loadCustomerList = useCallback(() => {
    const modal = {
      ...filterModel,
      email:
        filterModel.email?.length > 0
          ? filterModel.email.map(em => em.email)
          : [],
      firstName:
        filterModel.firstName?.length > 0
          ? filterModel.firstName.map(fn => fn.id)
          : [],
      lastName:
        filterModel.lastName?.length > 0
          ? filterModel.lastName.map(ln => ln.id)
          : [],
      id: filterModel.id?.length > 0 ? filterModel.id.map(ln => ln.id) : [],
      productCategories:
        filterModel.productCategories?.length > 0
          ? filterModel.productCategories.map(pc => pc.id)
          : [],
      productSubCategoryId:
        filterModel.productSubCategoryId?.length > 0
          ? filterModel.productSubCategoryId.map(pc => pc.id)
          : [],
      deviceRegistered:
        filterModel.deviceRegistered?.length > 0
          ? filterModel.deviceRegistered.map(dr => dr.id)
          : [],
      environmentId:
        filterModel.environmentId?.length > 0
          ? filterModel.environmentId.map(env => env)
          : [],
      productName:
        filterModel.productName?.length > 0
          ? filterModel.productName.map(pn => pn.id)
          : [],
      productSKU:
        filterModel.productSKU?.length > 0
          ? filterModel.productSKU.map(ps => ps.id)
          : [],
      countryId:
        filterModel.countryId?.length > 0
          ? filterModel.countryId.map(ct => ct.value)
          : [],
      stateId:
        filterModel.stateId?.length > 0
          ? filterModel.stateId.map(st => st.value)
          : [],
      cityId:
        filterModel.cityId?.length > 0
          ? filterModel.cityId.map(ct => ct.value)
          : [],
      address:
        filterModel.address?.length > 0
          ? filterModel.address.map(ct => ct.value)
          : [],
      productStatus: [],
      pagination: {
        pageSize: rowsPerPageBC,
        pageNo: bcPage,
        orderByKey: bcOrderBy.orderByKey,
        orderByVal: bcOrderBy.orderVal,
      },
    };
    setLoading(true);

    Promise.all([
      dispatch(
        contactsActions.onGetCustomerList({
          ...modal,
          isFavorite: !isRecentlyAdded,
          isRecentlyAdded: isRecentlyAdded,
          pagination: {
            ...modal.pagination,
            pageSize: rowsPerPageBC,

            pageNo: bcPage,
            orderByKey: bcOrderBy.orderByKey,
            orderByVal: bcOrderBy.orderVal,
          },
        })
      ),
      dispatch(
        contactsActions.onGetComapnies({
          ...modal,
          isFavorite: !isRecentlyAdded,
          isRecentlyAdded: isRecentlyAdded,
          pagination: {
            ...modal.pagination,
            pageSize: rowsPerPageC,
            pageNo: cPage,
            orderByKey: cOrderBy.orderByKey,
            orderByVal: cOrderBy.orderVal,
          },
        })
      ),
      dispatch(
        contactsActions.onGetBusinessGroupListSearch({
          ...modal,
          isFavorite: !isRecentlyAdded,
          isRecentlyAdded: isRecentlyAdded,
          pagination: {
            ...modal.pagination,
            pageSize: rowsPerPageBG,
            pageNo: bgPage,
            orderByKey: bgOrderBy.orderByKey,
            orderByVal: bgOrderBy.orderVal,
          },
        })
      ),
    ]).then(([businessContacts, companies, businessGroups]) => {
      if (
        businessContacts &&
        businessContacts.success &&
        businessContacts.data?.data
      ) {
        setBCPaginationResponse(businessContacts?.data?.pagination);
        setBCList(businessContacts.data.data);
      }
      if (companies && companies.success && companies.data?.data) {
        setCPaginationResponse(companies?.data?.pagination);
        setCList(companies.data.data);
      }
      if (
        businessGroups &&
        businessGroups.success &&
        businessGroups.data?.data
      ) {
        setBGPaginationResponse(businessGroups?.data?.pagination);
        setBGList(businessGroups.data.data);
      }
      if (isFilterAppling) {
        setIsFilterApplied(true);
      }
      setIsFilterAppling(false);
      setLoading(false);
    });
  }, [
    filterModel,
    rowsPerPageBC,
    rowsPerPageC,
    rowsPerPageBG,
    bcPage,
    cPage,
    bgPage,
    bcOrderBy.orderByKey,
    bcOrderBy.orderVal,
    cOrderBy.orderByKey,
    cOrderBy.orderVal,
    bgOrderBy.orderByKey,
    bgOrderBy.orderVal,
    isRecentlyAdded,
    dispatch,
  ]);

  useEffect(() => {
    loadCustomerList();
  }, [loadCustomerList]);

  useEffect(() => {
    setIsListView(isBCListView);
  }, [isBCListView]);

  const onMarkFavouriteCustomer = ({ selectedCustomers }) => {
    setLoading(true);
    dispatch(contactsActions.onMarkFavouriteCustomer(selectedCustomers)).then(
      data => {
        setLoading(false);
        if (!data?.data?.success)
          toast.error(data?.message || "Something went wrong!");
        else loadCustomerList();
      }
    );
  };

  const onMarkFavouriteCompanies = ({ selectedCustomers }) => {
    setLoading(true);
    dispatch(
      contactsActions.onMarkUnMarkFavouriteCompanies(selectedCustomers)
    ).then(data => {
      setLoading(false);
      if (!data?.success) toast.error(data?.message || "Something went wrong!");
      else loadCustomerList();
    });
  };

  const onMarkFavEndUserGroup = selectedGroup => {
    setLoading(true);
    dispatch(
      contactsActions.onPostCustomersEndpoint({
        endpoint: endpoints.customers.markUnMarkFavouriteUserGroup,
        data: [
          {
            groupId: selectedGroup.groupId,
            isFavourite: !selectedGroup.isFavorite,
          },
        ],
      })
    ).then(data => {
      setLoading(false);
      if (!data?.success) {
        toast.error(data?.message);
      } else {
        loadCustomerList();
      }
    });
  };

  return (
    <div
      className='gridBody contentBody fav-business-c-c-g'
      style={{ width: "100%", paddingRight: "20px !important" }}>
      {isLoading ? <Spinner /> : null}
      <AllBusinessContactsGridFilter
        IsFilterApplied={isFilterApplied}
        // PaginationResponse={paginationResponse}
        AppliedFilters={filterModel}
        onApplyFilters={(filters, isFilterApplied) => {
          setFilters(filters);
          if (isFilterApplied) setIsFilterAppling(isFilterApplied);
          else {
            setIsFilterAppling(false);
            setIsFilterApplied(false);
          }
        }}
        ActiveTabId={activeTabId}
        setActiveTabId={setActiveTabId}
        isListView={isListView}
        onToggleListView={isLstView => setIsListView(isLstView)}
      />

      <h2 className='gridInnerHeading'>
        {`${isRecentlyAdded ? "Recently Added" : "Favorite"} Business Contacts`}
        <span className='gridTopPagination'>
          <Pagination
            recordCount={bcPaginationResponse?.totalRecords}
            page={bcPage - 1}
            rowsPerPage={rowsPerPageBC}
            setRowsPerPage={setRowsPerPageBC}
            rowsPerPageOptions={[]}
            backIconButtonProps={{ style: { display: "none" } }}
            nextIconButtonProps={{ style: { display: "none" } }}
          />
        </span>
      </h2>

      {isListView ? (
        <AllContactsGrid
          allContactsData={bcList}
          startLoading={() => setLoading(true)}
          endLoading={() => setLoading(false)}
          loadData={() => loadCustomerList()}
          order={bcOrderBy}
          onOrderBy={setBCOrderBy}
          onMarkFavouriteCustomer={onMarkFavouriteCustomer}
        />
      ) : (
        <Grid container spacing={2}>
          {bcList.map(item => {
            return (
              <Grid item md={3} key={item?.id}>
                <ListViewTile
                  companyname={item?.companyName}
                  contacts={item.contacts}
                  buttontxt={item.groupName}
                  backgroundcolor={item.groupColor}
                  textcolor={item.textcolor}
                  rating={item?.isFavourite ? <StarFill /> : <StarOutline />}
                  onRatingClick={() => setIsRating(!isRating)}
                  onClick={() => redirectToBusinessCContact(item)}
                  onMarkFavourite={() =>
                    onMarkFavouriteCustomer({
                      selectedCustomers: [
                        {
                          userId: item.userId,
                          isFavourite: !item?.isFavourite,
                        },
                      ],
                    })
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      <Pagination
        recordCount={bcPaginationResponse?.totalRecords}
        page={bcPage - 1}
        setPage={setBCPage}
        rowsPerPage={rowsPerPageBC}
        setRowsPerPage={setRowsPerPageBC}
      />

      <h2 className='gridInnerHeading'>
        {`${isRecentlyAdded ? "Recently Added" : "Favorite"} Companies`}
        <span className='gridTopPagination'>
          <Pagination
            recordCount={cPaginationResponse?.totalRecords}
            page={cPage - 1}
            rowsPerPage={rowsPerPageC}
            setRowsPerPage={setCPaginationResponse}
            rowsPerPageOptions={[]}
            backIconButtonProps={{ style: { display: "none" } }}
            nextIconButtonProps={{ style: { display: "none" } }}
          />
        </span>
      </h2>

      {isListView ? (
        <AllCompaniesGrid
          listData={cList}
          loadData={() => loadCustomerList()}
          order={cOrderBy}
          onOrderBy={setCOrderBy}
          onMarkFavouriteCompanies={onMarkFavouriteCompanies}
        />
      ) : (
        <Grid container spacing={2}>
          {cList.map((item, index) => {
            return (
              <Grid item md={3}>
                <ListViewTile
                  companyname={item.companyName}
                  contacts={
                    item?.totalUsers > 0
                      ? `${item?.totalUsers} Contact${
                          item?.totalUsers > 1 ? "s" : ""
                        }`
                      : "No Contact"
                  }
                  buttontxt={item.businessGroup}
                  backgroundcolor={item.backgroundcolor}
                  textcolor={item.textcolor}
                  rating={item?.isFavourite ? <StarFill /> : <StarOutline />}
                  onRatingClick={() => {}}
                  onClick={() => redirectToCompany(item)}
                  onMarkFavourite={() =>
                    onMarkFavouriteCompanies({
                      selectedCustomers: {
                        companyId: item.companyId,
                        isFavourite: !item?.isFavourite,
                      },
                    })
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Pagination
        recordCount={cPaginationResponse?.totalRecords}
        page={cPage - 1}
        setPage={setCPage}
        rowsPerPage={rowsPerPageC}
        setRowsPerPage={setRowsPerPageC}
      />

      <h2 className='gridInnerHeading'>
        {`${isRecentlyAdded ? "Recently Added" : "Favorite"} Business Groups`}
        <span className='gridTopPagination'>
          <Pagination
            recordCount={bgPaginationResponse?.totalRecords}
            page={bgPage - 1}
            rowsPerPage={rowsPerPageBG}
            setRowsPerPage={setRowsPerPageBG}
            rowsPerPageOptions={[]}
            backIconButtonProps={{ style: { display: "none" } }}
            nextIconButtonProps={{ style: { display: "none" } }}
          />
        </span>
      </h2>

      {isListView ? (
        <CustomerGroupGrid
          customerGroupsData={bgList}
          startLoading={() => setLoading(true)}
          stopLoading={() => setLoading(false)}
          loadData={() => loadCustomerList()}
          order={bgOrderBy}
          onOrderBy={setBGOrderBy}
          onEditClick={businessGroupEntity => {
            if (businessGroupEntity)
              setEditableRecord({ ...businessGroupEntity });
          }}
          onMarkFavEndUserGroup={onMarkFavEndUserGroup}
          EditableRecord={editableRecord}
          EditAbleRow={
            editableRecord && (
              <AddNewBusinessGroup
                id='add-new-business-group'
                businessGroupEntity={editableRecord}
                onAddUpdate={success => {
                  if (success) {
                    loadCustomerList();
                    setEditableRecord(null);
                  }
                }}
                onCancel={() => {
                  setEditableRecord(null);
                }}
              />
            )
          }
        />
      ) : (
        <Grid container spacing={2}>
          {bgList.map(item => {
            return (
              <Grid item md={3} key={item.id}>
                <ListViewTile
                  companyname={item?.groupName}
                  contacts={
                    item?.totalContacts > 0
                      ? `${item?.totalContacts} Contact${
                          item?.totalContacts > 1 ? "s" : ""
                        }`
                      : "No Contact"
                  }
                  buttontxt={item.customerType}
                  backgroundcolor={item.color}
                  textcolor={item.color}
                  rating={item?.isFavourite ? <StarFill /> : <StarOutline />}
                  onMarkFavourite={() =>
                    onMarkFavEndUserGroup({
                      ...item,
                    })
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      <Pagination
        recordCount={bgPaginationResponse?.totalRecords}
        page={bgPage - 1}
        setPage={setBGPage}
        rowsPerPage={rowsPerPageBG}
        setRowsPerPage={setRowsPerPageBG}
      />
    </div>
  );
};
export { FavBusinessContactDataView };
