import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { OpenInNew, Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditIcon, SortIcon, ViewEye } from "../../LeftNav/SvgIcons";
import { RoutesObj } from "../../../../routes";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import { ItemImage } from "../../../../components/common";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
      boxShadow: "none",
    },
  },
}));

function AllProductsGrid({ 
  listData, 
  loadData, 
  order, 
  onOrderBy, 
  selectedProducts, 
  setSelectedProducts }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.UpdateProduct.path,
      state: { product: value },
      search: `id=${value.id}`,
    });
  };

  const onItemSelect = itemId => {
    const itemExists =
      selectedProducts.length > 0 && selectedProducts.find(c => c === itemId);
    if (itemExists) {
      setSelectedProducts(selectedProducts.filter(c => c !== itemId));
    } else {
      setSelectedProducts([...selectedProducts, itemId]);
    }
  };

  const onItemSelectAll = () => {
    if (!selectedProducts.length) {
      const allIds = listData.map((product) => product.id);
      setSelectedProducts(allIds);
    } else {
      setSelectedProducts([]);
    }
  };

  const deleteProducts = () => {
    dispatch(
      productInventoryActions.onDeleteProducts({
        ids: selectedProducts,
      })
    ).then(res => {
      if (!res?.success) {
        toast.error(res.message);
      } else {
        loadData();
        setSelectedProducts([]);
      }
    });
  };

  function truncate(uploadeAssetName) {
    if (uploadeAssetName && uploadeAssetName.length > 15) {
      return uploadeAssetName.substring(0, 13) + "...";
    }
    return uploadeAssetName;
  }

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        {selectedProducts.length > 0 ? (
          <Grid item md={9} className={classes.deleteItems}>
            <span className='gridDeleteItems' onClick={() => deleteProducts()}>
              <DeleteIcon /> Delete
            </span>
          </Grid>
        ) : null}
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont}
               onClick={onItemSelectAll}
               checked={listData.length === selectedProducts.length && selectedProducts.length > 0}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PRODUCT NAME
              <TableSortLabel
                active={order.orderByKey === "ProductName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "ProductName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              SKU
              <TableSortLabel
                active={order.orderByKey === "SKU"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "SKU",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              CATEGORY
              <TableSortLabel
                active={order.orderByKey === "Category"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Category",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              UNITS SOLD
              <TableSortLabel
                active={order.orderByKey === "UnitsSold"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "UnitsSold",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              UNITS REGISTERED
              <TableSortLabel
                active={order.orderByKey === "UnitsRegistered"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "UnitsRegistered",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={
                classes.gridHeaderInner                   
                  // "quickLinkWrapper quickLinkHeader")
              }
              style={{textAlign:"end"}}>
              QUICK ACTIONS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listData?.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridHeaderInner}>
                  <Checkbox
                    className={classes.checkBoxFont}
                    onClick={() => onItemSelect(item.id)}
                    checked={
                      selectedProducts.length > 0 &&
                      selectedProducts?.includes(item.id)
                    }
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <ItemImage imgSrc={item?.productMainImage} />
                    <span>{truncate(item.productName)}</span>
                  </span>
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.sku}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <Button
                    variant='contained'
                    className={classes.pendingButtons}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <span>{item.category}</span>
                      <ItemImage isSmall imgSrc={item?.categoryImage} />
                    </span>
                  </Button>
                </TableCell>
                <TableCell className={classes.gridBodyInner} style={{textAlign:"center"}}>
                  {item.unitsSold}
                </TableCell>
                <TableCell className={classes.gridBodyInner} style={{textAlign:"center"}}>
                  {item.unitsRegistered}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions productsAction'>
                    <span onClick={() => redirectUrl(item)}>
                      <EditIcon />
                    </span>
                    <span>
                      <ViewEye />
                    </span>
                    <span>
                      <OpenInNew color='primary' />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {listData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any product.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export { AllProductsGrid };
