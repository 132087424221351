import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";
import {
  EditIcon,
  SortIcon,
  StarFill,
  StarOutline,
  ViewEye,
  Delete,
} from "../../LeftNav/SvgIcons";
import { RoutesObj } from "../../../../routes";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import MoveContactPopup from "./MoveContactPopup";
import { toast } from "react-toastify";
import { DeletePopup } from "../../../../components/common";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  businessGroup: {
    background: "#E5F2FF",
    color: "#8254E3",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
  lastLoginWrapper: {
    width: "110px",
  },
}));

function AllContactsGrid({
  allContactsData,
  startLoading,
  endLoading,
  loadData,
  order,
  onOrderBy,
  onMarkFavouriteCustomer,
  isDeleted = false,
  isArchived = false,
  isBlocked = false,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [permanentDeleteAble, setPermanentDeleteAble] = useState(null);

  const [showMovePopup, setShowMovePopup] = useState(false);
  const { customerGroups } = useSelector(state => state.ContactsReducer);

  const redirectUrl = (value, isView) => {
    history.push({
      pathname: RoutesObj.BusinessCustomer.path,
      companyId: value.companyId,
      search: `id=${value.companyId}${isView ? `&acitveTabId=${isView}` : ""}`,
      state: { scrollTo: ".businessContacts" },
    });
  };

  const deleteAllContacts = ({ contactIds, isParmanentDelete = false }) => {
    startLoading();
    dispatch(contactsActions.onDeleteUsers(contactIds, isParmanentDelete)).then(
      res => {
        if (!res?.success) {
          toast.error(res.message);
          endLoading();
        } else {
          loadData();
          setSelectedItems([]);
        }
      }
    );
  };

  const restoreAllContacts = ({ contactIds }) => {
    startLoading();
    dispatch(contactsActions.onUpdateRestoreUsers(contactIds)).then(res => {
      if (!res?.success) {
        toast.error(res?.message);
        endLoading();
      } else {
        loadData();
        setSelectedItems([]);
      }
    });
  };

  const archiveAllContacts = contactIds => {
    startLoading();
    dispatch(contactsActions.onUpdateMarkUserAsArchived(contactIds)).then(
      res => {
        if (!res?.success) {
          toast.error(res.message);
          endLoading();
        } else {
          loadData();
          setSelectedItems([]);
        }
      }
    );
  };

  const blockAllContacts = contactIds => {
    startLoading();
    dispatch(contactsActions.onBlockCustomer(contactIds)).then(res => {
      if (!res?.success) {
        toast.error(res.message);
        endLoading();
      } else {
        loadData();
        setSelectedItems([]);
      }
    });
  };

  const onItemSelect = itemId => {
    const itemExists =
      selectedItems.length > 0 && selectedItems.find(c => c === itemId);
    if (itemExists) {
      setSelectedItems(selectedItems.filter(c => c !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  useEffect(() => {
    if (isAllSelected === true) {
      const allIds = allContactsData.map(l => l.userId);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  }, [isAllSelected, allContactsData]);

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        {selectedItems.length > 0 && (
          <Grid
            item
            md={9}
            className={(classes.deleteItems, "tableGridActions")}>
            <Button
              onClick={() =>
                archiveAllContacts({
                  userIds: selectedItems,
                  isArchived: !isArchived,
                })
              }>
              {`${isArchived ? "UnArchive" : "Archive"}`}
            </Button>
            <Button
              onClick={() =>
                blockAllContacts({
                  userIds: selectedItems,
                  isBlocked: !isBlocked,
                })
              }>
              {`${isBlocked ? "UnBlock" : "Block"}`}
            </Button>
            {!isDeleted ? (
              <Button
                onClick={() =>
                  deleteAllContacts({ contactIds: selectedItems })
                }>
                Delete
              </Button>
            ) : (
              <Button
                onClick={() =>
                  restoreAllContacts({ contactIds: selectedItems })
                }>
                Restore
              </Button>
            )}
          </Grid>
        )}
      </Paper>

      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox
                className={classes.checkBoxFont}
                checked={isAllSelected}
                onClick={() => setIsAllSelected(!isAllSelected)}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              First Name
              <TableSortLabel
                active={order.orderByKey === "FirstName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "FirstName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Last Name
              <TableSortLabel
                active={order.orderByKey === "LastName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "LastName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Company
              <TableSortLabel
                active={order.orderByKey === "CompanyName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CompanyName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Business Group
              <TableSortLabel
                active={order.orderByKey === "GroupName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "GroupName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={`${classes.gridHeaderInner} ${classes.lastLoginWrapper}`}>
              Last Login
              <TableSortLabel
                active={order.orderByKey === "LastLogin"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "LastLogin",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={
                (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
              }>
              Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allContactsData?.map(item => {
            return (
              <TableRow
                key={item.userId}
                className={`gridBodyRow endUserRow ${
                  selectedItems.length > 0 &&
                  selectedItems?.includes(item.userId)
                    ? "boxChecked"
                    : " "
                }`}>
                <TableCell className={(classes.gridHeaderInner, "gridRow")}>
                  <Checkbox
                    className={(classes.checkBoxFont, "enduserCheckbox")}
                    onClick={() => onItemSelect(item.userId)}
                    checked={
                      selectedItems.length > 0 &&
                      selectedItems?.includes(item.userId)
                    }
                  />
                  <span className='endUserPic'>
                    {item.image ? (
                      <img src={item.image} alt='' />
                    ) : (
                      <i className='userInitials'>{`${item?.firstName
                        ?.charAt(0)
                        ?.toUpperCase()}${item?.lastName
                        ?.charAt(0)
                        ?.toUpperCase()}`}</i>
                    )}
                  </span>
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "capitalize-first")}>
                  {item.firstName}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "capitalize-first")}>
                  {item.lastName}
                </TableCell>
                <TableCell
                  className={
                    (classes.gridBodyInner,
                    "tableItemHighlight",
                    "capitalize-first")
                  }>
                  {item?.companyName || "-"}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "tableItemHighlight")}>
                  {item?.groupName || "-"}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "lastLoginWrapper")}>
                  {item.lastLoginDT !== null && item.lastLoginDT !== ""
                    ? moment(item.lastLoginDT).fromNow()
                    : "--"}
                </TableCell>

                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions'>
                    <span
                      onClick={() => {
                        onMarkFavouriteCustomer({
                          selectedCustomers: [
                            {
                              userId: item.userId,
                              isFavourite: !item?.isFavourite,
                            },
                          ],
                        });
                      }}>
                      {item?.isFavourite ? <StarFill /> : <StarOutline />}
                    </span>
                    <span onClick={() => redirectUrl(item)}>
                      <EditIcon />
                    </span>
                    <span onClick={() => redirectUrl(item, "1")}>
                      <ViewEye />
                    </span>
                    {/* <span
                      onClick={() => {
                        if (isDeleted) {
                          setPermanentDeleteAble(item);
                        } else deleteAllContacts({ contactIds: [item.userId] });
                      }}>
                      <Delete />
                    </span> */}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {allContactsData?.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any contact.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {showMovePopup && (
        <MoveContactPopup
          openPopup={showMovePopup}
          closePopup={() => setShowMovePopup(false)}
          startLoading={() => startLoading()}
          endLoading={() => endLoading()}
          loadData={() => loadData()}
          groupData={customerGroups}
        />
      )}

      {permanentDeleteAble?.id && (
        <DeletePopup
          maxwidth='1099px'
          isvisible={permanentDeleteAble ? true : false}
          oncloseaction={() => setPermanentDeleteAble(null)}
          heading='Permanently Delete User'
          deletemessage={`Are you sure you want to permanently delete ${permanentDeleteAble?.firstName} ${permanentDeleteAble?.lastName}?`}
          subheading='Once a user is permanently deleted, you will no longer have access to their information.'
          cancelbtn={() => setPermanentDeleteAble(null)}
          deletebtn={() => {
            deleteAllContacts({
              contactIds: [permanentDeleteAble.userId],
              isParmanentDelete: true,
            });
            setPermanentDeleteAble(null);
          }}
        />
      )}
    </Paper>
  );
}

export default AllContactsGrid;
