import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CompanyUserGrid from "./CompanyUserGrid";
import { RoutesObj } from "../../../../../routes";
import { SVGIcons } from "../../../../../resources/images";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";

const CompanyUsers = ({
  listUserData,
  setUserListData,
  listUserDataBackup,
  setUserListDataBackup,
  loadUser,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const [isEditAdr, setIsEditAdr] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCollpased, setIsCollpased] = useState(false);
  const selectedData = queryString.parse(location.search);

  const searching = value => {
    setSearch(value);
    if (value === "") {
      setUserListData(listUserDataBackup);
    } else {
      let filterRec = listUserDataBackup.filter(x =>
        x.contactName.includes(value)
      );
      setUserListData(filterRec);
    }
  };

  const deleteUser = id => {
    setLoading(true);
    dispatch(contactsActions.onDeleteUsers([id], false)).then(data => {
      setLoading(false);
      if (data?.success) {
        loadUser();
        toast.success("Connection deleted");
      } else {
        toast.error(data?.message);
      }
    });
  };

  return (
    <div className='newCompanySelectWrapper'>
      {listUserData?.length === 0 &&
        isCollpased &&
        location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) && (
          <span className='startBtnWrapper'>
            <Button
              disabled={!selectedData?.id}
              color='primary'
              variant='outlined'
              onClick={() => {
                document.getElementById("summary-header-user").click();
              }}>
              Start
            </Button>
          </span>
        )}

      <AccordionContainer
        title='Company Users'
        accordionId='summary-header-user'
        disabled={!selectedData?.id}
        isExpanded={selectedData?.id ? true : false}
        onCollapsed={collapsed => setIsCollpased(collapsed)}
        formButton={
          isCollpased ? (
            location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) &&
            listUserData?.length > 0 ? (
              <span className='green-tick-svg'>
                <SVGIcons.GreenTick />
              </span>
            ) : (
              !location?.pathname?.includes(
                RoutesObj.NewCompanyContacts.path
              ) && (
                <span className='companyConnectionsButtons'>
                  <Button
                    disabled={!selectedData?.id}
                    color='primary'
                    startIcon={<AddIcon />}
                    onClick={() => {
                      document.getElementById("summary-header-user").click();
                      setIsNewUser(true);
                      setIsEditAdr(false);
                    }}>
                    Add Users
                  </Button>
                </span>
              )
            )
          ) : (
            <span className='companyConnectionsButtons'>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setIsNewUser(true);
                  setIsEditAdr(false);
                }}>
                Add Users
              </Button>
            </span>
          )
        }>
        <CompanyUserGrid
          setIsNewUser={() => setIsNewUser(false)}
          isNewUser={isNewUser}
          setIsEditAdr={e => setIsEditAdr(e)}
          isEditAdr={isEditAdr}
          listData={listUserData}
          search={search}
          setSearch={s => {
            searching(s);
          }}
          isLoading={isLoading}
          setLoading={s => {
            setLoading(s);
          }}
          loadUser={loadUser}
          deleteUser={id => deleteUser(id)}
        />

        {listUserData?.length === 0 && (
          <span className='emptyGridData'>
            You haven’t added any OFish Users. Start by adding a{" "}
            <i
              onClick={() => {
                setIsNewUser(true);
                setIsEditAdr(false);
              }}>
              Company Users
            </i>
          </span>
        )}
        {/* {listUserData?.length > 0 && (
          <span className='saveNNestBtnWrapper'>
            {location?.pathname?.includes(
              RoutesObj.NewCompanyContacts.path
            ) && (
              <Button
                variant='outlined'
                className='skip-button'
                onClick={() => {
                  document.getElementById("summary-header-loc").click();
                  document.getElementById("summary-header-user").click();
                }}>
                Skip
              </Button>
            )}

            <Button
              onClick={() => {
                document.getElementById("summary-header-loc").click();
                document.getElementById("summary-header-user").click();
              }}>
              {location?.pathname?.includes(RoutesObj.NewCompanyContacts.path)
                ? "Next"
                : "Save"}
            </Button>
          </span>
        )} */}
      </AccordionContainer>
    </div>
  );
};

export { CompanyUsers };
