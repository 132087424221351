import React, { useState, useEffect } from "react";
import { logo } from "../../../resources/images/index";
import { Button } from "@material-ui/core";
import { Link, NavLink, useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { pubReleaseActions } from "../../../store/pubRelease/pubReleaseActions";
import { newsroomActions } from "../../../store/newsroom/newsroomActions";
import { pubMediaLibraryActions } from "../../../store/pubMediaLibrary/pubMediaLibraryActions";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import OutsideClickHandler from "react-outside-click-handler";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { sortBy } from "lodash";
import routesObj from "../../../routes/RoutesObj";
import Cookies from "js-cookie";

function LogHeader(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [isHeaderDropDown, setIsHeaderDropDown] = useState(false);
  const [quickLinkData, setQuickLinkData] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const currentRoot = history ? history.location.pathname : null;

  useEffect(() => {
    userVerification();
  }, []);

  const userVerification = async () => {
    const userEmail = localStorage.getItem("userEmail");
    const userAuthenticated = localStorage.getItem("isAuthenticated");
    const userName = localStorage.getItem("name");
    const userRole = localStorage.getItem("isAdmin");

    if (userEmail && userAuthenticated) {
      setIsLoggedIn(true);
      setUserName(userName);
      setUserRole(userRole);
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const userName = localStorage.getItem("name");
      console.log("name:", userName);
      setUserName(userName);
    }, 3000);
  }, [isLoggedIn]);

  const onLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("name");
    localStorage.removeItem("lName");
    localStorage.removeItem("id");
    Cookies.remove("access_token");
    setIsLoggedIn(false);
    setIsDropdown(false);
    history.push("/");
  };

  const onLatestReleaseClearFilter = () => {
    dispatch(pubReleaseActions.onGetPubFilterReleases()).then(res => {
      dispatch(pubReleaseActions.onPressPubClear());
    });
  };

  const onMediaLibraryClearFilter = () => {
    dispatch(pubMediaLibraryActions.onChange("selectedPMCategory", []));
    dispatch(pubMediaLibraryActions.onChange("selectedPubMedDateFilter", []));
  };

  const redirect = () => {
    history.push("/account-settings");
  };

  useEffect(() => {
    dispatch(newsroomActions.onGetNewsRoomQuickLinks()).then(data => {
      if (data?.success) {
        setQuickLinkData(data?.data);
      }
    });
    dispatch(newsroomActions.onGetSortMenuOptions()).then(data => {
      if (data?.success) {
        setMenuData(data?.data);
      }
    });
  }, []);
  const menuItems = {
    Home: (
      <Button
        className={
          currentRoot === "/" || currentRoot === "/all-latest-announcenments"
            ? "active"
            : ""
        }
        onClick={() => history.push("/")}>
        Home
      </Button>
    ),
    "Latest Releases": (
      <Button
        className={
          currentRoot === "/releases" ||
          currentRoot === "/all-releases" ||
          currentRoot.includes(routesObj.LatestReleaseDetail.path) ||
          currentRoot === "/latest-releases-search"
            ? "active"
            : ""
        }
        onClick={() => {
          onLatestReleaseClearFilter();
          history.push("/releases");
        }}>
        Latest Releases
      </Button>
    ),
    "Media Library": (
      <Button
        className={
          currentRoot === "/media-library" ||
          currentRoot === "/all-new-assets" ||
          currentRoot === "/all-source-units" ||
          currentRoot.includes(routesObj.MediaDetail.path)
            ? "active"
            : ""
        }
        onClick={() => {
          onMediaLibraryClearFilter();
          history.push("/media-library");
        }}>
        Media Library
      </Button>
    ),
    Events: (
      <Button
        className={
          currentRoot === "/events" ||
          currentRoot === "/events-calendar" ||
          currentRoot === "/events-detail" ||
          currentRoot.includes(routesObj.EventDetail.path)
            ? "active"
            : ""
        }
        onClick={() => history.push("/events")}>
        Events
      </Button>
    ),
    "Company Information": (
      <Button
        className={currentRoot === "/company-info" ? "active" : ""}
        onClick={() => history.push("/company-info")}>
        Company Info
      </Button>
    ),
    "Quick Links": (
      <OutsideClickHandler onOutsideClick={() => setIsHeaderDropDown(false)}>
        {quickLinkData?.length > 0 && (
          <Button
            className={`headerDropdown ${
              currentRoot === "/quick-links" ? "active" : ""
            }`}
            onClick={() => setIsHeaderDropDown(true)}>
            Quick Links <ExpandMoreIcon fontSize='small' />
            {isHeaderDropDown && (
              <span className='innerDropdown'>
                {quickLinkData.map((x, i) => {
                  return (
                    <Button
                      key={i}
                      onClick={() => window.open(x.url, "_blank")}>
                      {x.name}
                    </Button>
                  );
                })}
              </span>
            )}
          </Button>
        )}
      </OutsideClickHandler>
    ),
  };

  return (
    <div className='headerWrapper'>
      <div className='headerInner'>
        <span>
          <img src={logo} alt='' />
          <span>
            {sortBy(menuData, ["sort"]).map(x => menuItems[x.name])}

            {/* <Button
              className={
                currentRoot === "/" ||
                currentRoot === "/all-latest-announcenments"
                  ? "active"
                  : ""
              }
              onClick={() => history.push("/")}>
              Home
            </Button>

            <Button
              className={
                currentRoot === "/latest-releases" ||
                currentRoot === "/all-releases" ||
                currentRoot === "/latest-releases-detail" ||
                currentRoot === "/latest-releases-search"
                  ? "active"
                  : ""
              }
              onClick={() => {
                onLatestReleaseClearFilter();
                history.push("/latest-releases");
              }}>
              Latest Releases
            </Button> */}

            {/* <Button
              className={
                currentRoot === "/media-library" ||
                currentRoot === "/all-new-assets" ||
                currentRoot === "/all-source-units" ||
                currentRoot === "/media-detail"
                  ? "active"
                  : ""
              }
              onClick={() => {
                onMediaLibraryClearFilter();
                history.push("/media-library");
              }}>
              Media Library
            </Button> */}

            {/* <Button
              className={
                currentRoot === "/events" ||
                currentRoot === "/events-calendar" ||
                currentRoot === "/events-detail"
                  ? "active"
                  : ""
              }
              onClick={() => history.push("/events")}>
              Events
            </Button> */}

            {/* <Button
              className={currentRoot === "/company-info" ? "active" : ""}
              onClick={() => history.push("/company-info")}>
              Company Info
            </Button>
            <OutsideClickHandler
              onOutsideClick={() => setIsHeaderDropDown(false)}>
              <Button
                className={`headerDropdown ${
                  currentRoot === "/quick-links" ? "active" : ""
                }`}
                onClick={() => setIsHeaderDropDown(true)}>
                Quick Links <ExpandMoreIcon fontSize="small" />
                {isHeaderDropDown && (
                  <span className="innerDropdown">
                    {quickLinkData.map((x, i) => {
                      return (
                        <Button
                          key={i}
                          onClick={() => window.open(x.url, "_blank")}>
                          {x.name}
                        </Button>
                      );
                    })} */}
            {/* <Button>Your AAV Account</Button>
                      <Button>Contact Us</Button>
                      <Button>Aquatic AV Store</Button>
                      <Button>AquaticAV.com</Button> */}
            {/* </span>
                )}
              </Button>
            </OutsideClickHandler> */}
          </span>
        </span>
        <OutsideClickHandler onOutsideClick={() => setIsDropdown(false)}>
          <span className='loginWrapper'>
            <SearchIcon onClick={() => setIsSearch(!isSearch)} />
            {isSearch && <input type='text' />}
            {isLoggedIn ? (
              <Button
                color='primary'
                variant='contained'
                onClick={() => setIsDropdown(!isDropdown)}
                endIcon={<KeyboardArrowDownIcon fontSize='small' />}>
                {`${"Hi, "}${userName}`}
              </Button>
            ) : (
              <Button
                color='primary'
                variant='contained'
                component={Link}
                to='/login'>
                Login
              </Button>
            )}

            {isDropdown && (
              <div className='logoutDropdown'>
                {userRole === "admin" && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => history.push("/dashboard")}>
                    Dashboard
                  </Button>
                )}
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => redirect()}>
                  Account Settings
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => onLogout()}>
                  Logout
                </Button>
              </div>
            )}
          </span>
        </OutsideClickHandler>
      </div>
    </div>
  );
}

export default LogHeader;
