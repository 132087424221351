import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Close } from "@material-ui/icons";
import { FileUploader } from "react-drag-drop-files";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "PDF", "DOCX", "DOC"];

const ConversationMediaUploadPopup = ({
  closePopup,
  openPopup,
  isMultiple = true,
  setFileInfo,
}) => {
  const [uploadFile, setUploadFile] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    if (uploadFile !== "" && uploadFile.length > 0) {
      const imageList = Object.keys(uploadFile).map(k => uploadFile[k]);
      setFileInfo(imageList[0]?.name, imageList);
      closePopup();
    } else if (!uploadFile?.length && uploadFile.name) {
      setFileInfo(uploadFile.name, uploadFile);
    } else {
      toast.error("Please choose any file first");
    }
  };
  const uploadFileHandle = file => {
    if (file?.length > 0) setUploadFile(file);
    else if (!file?.length && file.name) {
      setUploadFile([file]);
    }
  };

  return (
    <div>
      <Dialog
        open={openPopup}
        onClose={closePopup}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
          <DialogTitle id='alert-dialog-title'>
            Upload
            <Close className='closePopup' onClick={closePopup} />
          </DialogTitle>
          <DialogContent className='addLaunchPopupWrapper'>
            <DialogContentText id='alert-dialog-description'>
              {/* <DropzoneArea
                onChange={v => uploadFileHandle(v)}
                filesLimit={3}
                maxFileSize={110000000}
              /> */}
              <FileUploader
                multiple={isMultiple}
                handleChange={uploadFileHandle}
                name='file'
                types={fileTypes}
              />
              <div>
                {!uploadFile?.length && uploadFile.name ? (
                  <>{uploadFile.name} </>
                ) : (
                  Object.keys(uploadFile)
                    .map(k => uploadFile[k])
                    .map(f => <p>{f?.name}</p>)
                )}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='popupFooter addCatFooter'>
            {uploadFile !== "" && uploadFile.length > 0 ? (
              <Button
                color='primary'
                variant='contained'
                autoFocus
                type='submit'>
                Add
              </Button>
            ) : (
              <Button
                color='primary'
                variant='contained'
                autoFocus
                type='submit'
                disabled>
                Add
              </Button>
            )}
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};

export default ConversationMediaUploadPopup;
