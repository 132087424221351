import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import { OpenInNew } from "@material-ui/icons";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import { toast } from "react-toastify";
import { LightenDarkenColor } from "lighten-darken-color";
import routesObj from "../../../../routes/RoutesObj";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },

  gridHeaderInnerCenter: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
}));

function AllCollectionGrid({
  allCollectionData,
  startLoading,
  loadData,
  stopLoading,
  selectedRecord,
  isDelete,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedAllCollection } = useSelector(state => state.AssetsReducer);
  const selectAllCollection = value => {
    dispatch(assetsActions.onSelectAllCollection(value));
  };
  const redirectUrl = value => {
    history.push({
      pathname: "/new-collections",
      state: { collection: value },
    });
  };
  const checkAllCollectionSelected = itemId => {
    const assetExist = selectedAllCollection.find(asset => {
      return asset.id === itemId;
    });
    if (assetExist) {
      return true;
    } else {
      return false;
    }
  };
  const deleteAllCollection = () => {
    var userIds = [];
    allCollectionData.map(x => {
      if (x.checked) {
        userIds.push(x.id);
      }
    });
    if (userIds.length > 0) {
      startLoading();
      dispatch(assetsActions.onDeleteCollection(userIds)).then(data => {
        if (data.success) {
          toast.success("Release category deleted.");
          loadData();
        } else {
          toast.error("Something went wrong, Please try latter.");
        }
      });
    } else {
      toast.error("Please select the release category.");
    }
  };
  const isCollectionChecked = Array.isArray(allCollectionData) && allCollectionData.some(
        collectionData => collectionData?.checked === false
    );
  const onSelectAllCollections = () => {
    const isCollectionChecked = Array.isArray(allCollectionData) && allCollectionData.some(
      collectionData => collectionData?.checked === true
  );
  if(!isCollectionChecked){
    allCollectionData?.forEach((collection) => {
      selectedRecord(collection);
    })}else{
      allCollectionData.forEach((collection) =>{
        selectedRecord({
          ...collection,
          checked: true,
        })
      })
    }
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        <>
          <Grid item md={9} className={classes.deleteItems}>
            {isDelete && (
              <span
                className='gridDeleteItems'
                onClick={() => deleteAllCollection()}>
                <DeleteIcon /> Delete
              </span>
            )}
          </Grid>
        </>
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont} 
                onClick={onSelectAllCollections}
                checked={!isCollectionChecked && allCollectionData.length > 0}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              COLLECTION NAME
              <TableSortLabel
                active={order.orderByKey === "Name"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Name",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              NO. OF ASSETS
              <TableSortLabel
                active={order.orderByKey === "NoOfAssets"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "NoOfAssets",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PUBLISH DATE
              <TableSortLabel
                active={order.orderByKey === "VisibilityDate"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "VisibilityDate",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={[
                classes.gridHeaderInner,
                classes.gridBodyInnerCenter,
              ]}>
              CATEGORY
              <TableSortLabel
                active={order.orderByKey === "CategoryName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CategoryName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={[
                classes.gridHeaderInner,
                classes.gridBodyInnerCenter,
              ]}>
              STATUS
              <TableSortLabel
                active={order.orderByKey === "Status"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Status",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allCollectionData &&
            allCollectionData.length > 0 &&
            allCollectionData.map((item, index) => {
              return (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell className={classes.gridHeaderInner}>
                    <Checkbox
                      className={classes.checkBoxFont}
                      onClick={() => selectedRecord(item)}
                      checked={item.checked}
                    />
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.name}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.noOfAssets}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {moment(item.createdOn).format("MMMM DD, YYYY")}
                  </TableCell>
                  <TableCell
                    className={[
                      classes.gridBodyInner,
                      classes.gridBodyInnerCenter,
                    ]}>
                    <span
                      style={{
                        backgroundColor: LightenDarkenColor(
                          item.categoryColor ? item.categoryColor : "#54b0e3",
                          140
                        ),
                        color: item.categoryColor
                          ? item.categoryColor
                          : "#54b0e3",
                        padding: "8px 15px",
                        borderRadius: "6px",
                        fontSize: "12px",
                        fontWeight: 700,
                      }}>
                      {item.categoryName}
                    </span>
                  </TableCell>

                  <TableCell
                    className={
                      (classes.gridBodyInner, classes.gridBodyInnerCenter)
                    }>
                    <span
                      className={
                        item.status === "Planning"
                          ? "eventPendingBtn"
                          : "eventLaunchedBtn"
                      }>
                      {item.status}
                    </span>

                    {/* <span className="eventLaunchedBtn">Launched</span> */}
                  </TableCell>

                  <TableCell className='editButton'>
                    <span
                      onClick={() => {
                        history.push("/new-collections?id=" + item.id);
                      }}>
                      <EditIcon />
                    </span>
                    <a
                      href={routesObj.MediaDetail.path + "/" + item.urlSlug}
                      target='_blank'>
                      <OpenInNew style={{ color: "rgb(54, 153, 255)" }} />
                    </a>
                  </TableCell>
                </TableRow>
              );
            })}
          {allCollectionData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t made any asset collections. Start planning
                  and{" "}
                  <Button onClick={() => history.push("/new-collections")}>
                    create a new asset collection
                  </Button>
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default AllCollectionGrid;
