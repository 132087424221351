import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { StarOutline, StarFill } from "../../../LeftNav/SvgIcons";
import { useHistory } from "react-router-dom";
import {
  Pagination,
  ListViewTile,
  Spinner,
  GridActionNotification,
} from "../../../../../components/common";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import AllContactsGrid from "../AllContactsGrid";
import { AllBusinessContactsGridFilter } from ".";
import { RoutesObj } from "../../../../../routes";

const BusinessContactDataView = ({ isArchived, isBlocked, isDeleted }) => {
  const dispatch = useDispatch();
  const { isBCListView } = useSelector(state => state.endUserReducer);

  const [customerList, setCustomerList] = useState([]);
  const [isListView, setIsListView] = useState(true);
  const [isRating, setIsRating] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [activeTabId, setActiveTabId] = useState("1");
  const [isFilterAppling, setIsFilterAppling] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterModel, setFilters] = useState({
    searchText: "",
    startDate: "",
    endDate: "",
    productCategories: [],
    productSubCategoryId: [],
    environmentId: [],
    productName: [],
    productSKU: [],
    countryId: [],
    stateId: [],
    cityId: [],
    address: [],
    firstName: [],
    lastName: [],
    email: [],
    lastLogin: null,
    deviceRegistered: [],
    rating: null,
    companyName: null,
    date: 0,
    groupIds: null,
    id: [],
    userStatus: [],
  });
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const history = useHistory();

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.BusinessCustomer.path,
      companyId: value.companyId,
      search: `id=${value.companyId}`,
      state: { scrollTo: ".businessContacts" },
    });
  };

  const loadCustomerList = useCallback(() => {
    let modal = {
      ...filterModel,
      email:
        filterModel.email?.length > 0
          ? filterModel.email.map(em => em.email)
          : [],
      firstName:
        filterModel.firstName?.length > 0
          ? filterModel.firstName.map(fn => fn.id)
          : [],
      lastName:
        filterModel.lastName?.length > 0
          ? filterModel.lastName.map(ln => ln.id)
          : [],
      id: filterModel.id?.length > 0 ? filterModel.id.map(ln => ln.id) : [],
      productCategories:
        filterModel.productCategories?.length > 0
          ? filterModel.productCategories.map(pc => pc.id)
          : [],
      productSubCategoryId:
        filterModel.productSubCategoryId?.length > 0
          ? filterModel.productSubCategoryId.map(pc => pc.id)
          : [],
      deviceRegistered:
        filterModel.deviceRegistered?.length > 0
          ? filterModel.deviceRegistered.map(dr => dr.id)
          : [],
      environmentId:
        filterModel.environmentId?.length > 0
          ? filterModel.environmentId.map(env => env)
          : [],
      productName:
        filterModel.productName?.length > 0
          ? filterModel.productName.map(pn => pn.id)
          : [],
      productSKU:
        filterModel.productSKU?.length > 0
          ? filterModel.productSKU.map(ps => ps.id)
          : [],
      countryId:
        filterModel.countryId?.length > 0
          ? filterModel.countryId.map(ct => ct.value)
          : [],
      stateId:
        filterModel.stateId?.length > 0
          ? filterModel.stateId.map(st => st.value)
          : [],
      cityId:
        filterModel.cityId?.length > 0
          ? filterModel.cityId.map(ct => ct.value)
          : [],
      address:
        filterModel.address?.length > 0
          ? filterModel.address.map(ct => ct.value)
          : [],
      productStatus: [],
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    setLoading(true);
    if (isDeleted) {
      modal = {
        ...modal,
        userStatus: [...modal?.userStatus, -1],
      };
    } else if (isBlocked) {
      modal = {
        ...modal,
        userStatus: [...modal?.userStatus, 2],
      };
    }
    dispatch(
      contactsActions.onGetCustomerList(
        isArchived ? { ...modal, isArchived: true } : modal
      )
    ).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          setPaginationResponse(data?.data?.pagination);
          setCustomerList(data.data.data);
          if (isFilterAppling) {
            setIsFilterApplied(true);
          }
          setIsFilterAppling(false);
        }
      }
      setLoading(false);
    });
  }, [
    filterModel,
    rowsPerPage,
    page,
    order.orderByKey,
    order.orderVal,
    isDeleted,
    isBlocked,
    dispatch,
    isArchived,
  ]);

  useEffect(() => {
    loadCustomerList();
  }, [loadCustomerList]);

  useEffect(() => {
    setIsListView(isBCListView);
  }, [isBCListView]);

  const onPressFilterBtn = searchText => {
    if (searchText !== "") {
      setLoading(true);
      dispatch(contactsActions.onGetCompanyByName(searchText)).then(data => {
        if (data && data.success) {
          if (data.data && data?.data?.length > 0) {
            const companiesList = data.data.map((x, index) => {
              return {
                id: index,
                businessGroup: x.businessGroup,
                companyId: x.companyId,
                companyName: x.companyName,
                phoneNumber: x.phoneNumber,
                totalUsers: x.totalUsers,
                checked: false,
                lastLoginDT: x.lastLoginDT,
                groupColor: x.groupColor,
              };
            });
            setCustomerList(companiesList);
          }
        }
        setLoading(false);
      });
    }
  };

  const onMarkFavouriteCustomer = ({ selectedCustomers }) => {
    setLoading(true);
    dispatch(contactsActions.onMarkFavouriteCustomer(selectedCustomers)).then(
      data => {
        setLoading(false);
        if (!data?.data?.success)
          toast.error(data?.message || "Something went wrong!");
        else loadCustomerList();
      }
    );
  };

  useEffect(() => {
    setPage(1);
}, [rowsPerPage])

  return (
    <div
      className='gridBody contentBody businessCustomerGrid'
      style={{ width: "100%", paddingRight: "20px !important" }}>
      {isLoading ? <Spinner /> : null}
      <AllBusinessContactsGridFilter
        IsFilterApplied={isFilterApplied}
        PaginationResponse={paginationResponse}
        AppliedFilters={filterModel}
        onApplyFilters={(filters, isFilterApplied) => {
          setFilters(filters);
          if (isFilterApplied) setIsFilterAppling(isFilterApplied);
          else {
            setIsFilterAppling(false);
            setIsFilterApplied(false);
          }
        }}
        ActiveTabId={activeTabId}
        setActiveTabId={setActiveTabId}
        isListView={isListView}
        onToggleListView={isLstView => setIsListView(isLstView)}
      />
      {(isArchived || isBlocked || isDeleted) && (
        <GridActionNotification
          notificationtext={
            isArchived
              ? "Users who are inactive for a year are automatically archived by OFish"
              : isBlocked
              ? "Blocked users are archived for data purposes and are not able to access OFish-powered services."
              : "Users who are deleted will be permanently deleted from OFish after 30 days."
          }
          learnmore={() => {}}
          bgcolor={isArchived ? "#3699FF" : isBlocked ? "#F2994A" : "#EB5757"}
        />
      )}

      <h2 className='gridInnerHeading'>
        {`${isArchived ? "Archived" : "All"} Business Contacts`}
          <span className='gridTopPagination'>
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={[]}
            backIconButtonProps={{ style: { display: "none" } }}
            nextIconButtonProps={{ style: { display: "none" } }}
          />
        </span>
      </h2>

      {isListView ? (
        <AllContactsGrid
          allContactsData={customerList}
          startLoading={() => setLoading(true)}
          endLoading={() => setLoading(false)}
          loadData={() => loadCustomerList()}
          onPressFilterBtn={searchText => {
            onPressFilterBtn(searchText);
          }}
          order={order}
          onOrderBy={setOrderBy}
          onMarkFavouriteCustomer={onMarkFavouriteCustomer}
          isDeleted={isDeleted}
          isArchived={isArchived}
          isBlocked={isBlocked}
        />
      ) : (
        <Grid container spacing={2}>
          {customerList.map(item => {
            return (
              <Grid item md={3} key={item?.id}>
                <ListViewTile
                  companyname={item?.companyName}
                  contacts={item.contacts}
                  buttontxt={item.groupName}
                  backgroundcolor={item.groupColor}
                  textcolor={item.textcolor}
                  rating={item?.isFavourite ? <StarFill /> : <StarOutline />}
                  onRatingClick={() => setIsRating(!isRating)}
                  onClick={() => redirectUrl(item)}
                  onMarkFavourite={() =>
                    onMarkFavouriteCustomer({
                      selectedCustomers: [
                        {
                          userId: item.userId,
                          isFavourite: !item?.isFavourite,
                        },
                      ],
                    })
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      <Pagination
        recordCount={paginationResponse?.totalRecords}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};
export { BusinessContactDataView };
