import React from "react";
import Switch from "@material-ui/core/Switch";

function ConversationTypeChannel({
  title,
  type,
  discription,
  isEnabled,
  onEnableCampaigns,
  isActive,
}) {
  return (
    <div
      className={
        isActive
          ? "conversationChannelTypeWrapper"
          : "conversationBlurChannelTypeWrapper"
      }>
      <div className="conversationChannelTypeInnerWrapper">
        <div>
          <div className="titleWrapper">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5>{title}</h5>
              <h5 style={{ color: "#3699ff", paddingLeft: "10px" }}> {type}</h5>
            </div>
            <div>
              <span className="toggleWrapper">
                {isEnabled === false ? "Disabled" : "Enabled"}
              </span>
              <Switch onChange={onEnableCampaigns} checked={isEnabled} />
            </div>
          </div>
          <div>{discription}</div>
        </div>
      </div>
    </div>
  );
}

export default ConversationTypeChannel;
