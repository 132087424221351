import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton, Typography, Chip } from "@material-ui/core";
import { Close, CloseChipIcon, UploadIcon } from "../../LeftNav/SvgIcons";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { softwareUpdateActions } from "../../../../store/softwareUpdates/softwareUpdatesActions";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    width: "400px",
    borderRadius: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 56,
    borderBottom: "2px solid #E2E5E9",
    paddingRight: 32,
    paddingLeft: 32,
    background: "#00A3FF",
    borderRadius: "20px 20px 0px 0px",
  },
  headerName: {
    fontSize: "18px",
    fontFamily: "Roboto",
    fontWeight: 800,
    color: "#fff",
  },
  inenerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  content: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Roboto",
    paddingLeft: 16,
    color: "#3699FF",
    paddingRight: 19,
    letterSpacing: "-0.01em",
  },
  closIcon: {
    "& .MuiIconButton-label": {
      width: "24px",
      height: "24px",
    },
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  fileInput: {
    display: "none",
  },
  chip: {
    "&.MuiChip-root": {
      background: "#F3F6F9 !important",
      padding: "8px 16px",
    },

    "& .MuiChip-label": {
      padding: 0,
      paddingRight: 10,
    },
  },
  chipFileName: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#70808F",
  },
  chipFileSpaName: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#3699FF",
  },
  chipContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "22px",
  },
}));

const SoftwareMediaUploadPopup = ({
  openPopup,
  closePopup,
  releaseBasicsProps,
}) => {
  
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    releaseBasicsProps("buildFileName" , selectedFile.name);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileChange({ target: { files } });
  };

  const handleDeleteFile = () => {
    setFile(null);
    setFileName("");
  };

  const submitMedia = (e) => {
    e.preventDefault()

    dispatch(endUserActions.onUploadRecipt(file)).then((data) => {
      if (data && data.success && data.data) {
        dispatch(
          softwareUpdateActions.onSelectedReleaseFieldChange(
            "buildUrl",
            data.data.url
          ));  
        releaseBasicsProps("buildUrl" , data.data.url);
      }});
    closePopup();
  };

  function truncate(uploadeAssetName) {
    if (uploadeAssetName && uploadeAssetName.length > 15) {
      return uploadeAssetName.substring(0, 13) + "...";
    }
    return uploadeAssetName;
  }

  return (
    <div className="softwareBuildUploadPopup">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPopup}
        onClose={() => closePopup()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPopup}>
          <div className={classes.paper}>
            <div className={classes.header}>
              <div className={classes.inenerContent}>
                <Typography className={classes.headerName}>
                  Upload File
                </Typography>
              </div>
              <IconButton
                className={classes.closIcon}
                onClick={() => closePopup()}
              >
                <Close />
              </IconButton>
            </div>
            <div className="softwareBuildUploadPopup">
              <div
                className="fileUploadContanier"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className="uplodadfileRoot">
                  <input
                    accept=".aq, .cfg, .txt, .gfx, .jpg, .bin"
                    className={classes.fileInput}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="contained-button-file">
                    <Button className="uploadFileBtn" component="span">
                      <UploadIcon />
                    </Button>
                  </label>

                  <div className="dragContent">
                    Drag files here or click to upload
                  </div>
                </div>
                {fileName && (
                  <div className={classes.chipContainer}>
                    <Chip
                      className={classes.chip}
                      label={
                        <div>
                          <span className={classes.chipFileName}>
                            {truncate(fileName)}
                          </span>
                        </div>
                      }
                      deleteIcon={
                        <i>
                          <CloseChipIcon />
                        </i>
                      }
                      onDelete={handleDeleteFile}
                    />
                  </div>
                )}
                <div className="submitBtnContainer">
                  <Button
                    className="submitBtn"
                    variant="contained"
                    color="primary"
                    disabled={fileName === ""}
                    onClick={submitMedia}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default SoftwareMediaUploadPopup;
