import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper, TableSortLabel } from "@material-ui/core";
import { Add, OpenInNew, Remove, Delete } from "@material-ui/icons";
import { toast } from "react-toastify";
import moment from "moment";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { useDispatch } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeaderInnerCenter: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#E06145",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  launchedButtons: {
    color: "#18BC6D",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
  newProductLaunch: {
    color: "#8254E3",
    background: "rgba(130, 84, 227, 0.25)",
    boxShadow: "none",
    "&:hover": {
      background: "rgba(130, 84, 227, 0.25)",
      boxShadow: "none",
    },
  },
}));

function EventsCategoryGrid({
  allEventsData,
  startLoading,
  endLoading,
  loadData,
  selectedRecord,
  isDelete,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsibleItem, setCollapsibleItem] = useState(null);

  const redirectUrl = () => {
    history.push("/new-category");
  };
  const deleteAllContacts = () => {
    var userIds = [];
    allEventsData.map(x => {
      if (x.checked) {
        userIds.push(x.id);
      }
    });
    if (userIds.length > 0) {
      startLoading();
      dispatch(launchesActions.onDeleteEventsCatById(userIds)).then(data => {
        if (data.success) {
          toast.success("Event category deleted.");
          loadData();
        } else {
          endLoading();
          toast.error(data.message);
        }
      });
    } else {
      toast.error("Please select the event category.");
    }
  };
  
  return (
    <Paper>
      <div className='gap' />
      <span className='gridDeleteItems' onClick={() => deleteAllContacts()}>
        {isDelete && (
          <>
            <Delete /> Delete
          </>
        )}
      </span>
      <ul className='releaseGridListHeader'>
        <li>
          CATEGORY NAME
          <TableSortLabel
            active={order.orderByKey === "Name"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "Name",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          NO. OF EVENTS
          <TableSortLabel
            active={order.orderByKey === "totalEvents"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "TotalEvents",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          DATE CREATED
          <TableSortLabel
            active={order.orderByKey === "CreatedDate"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "CreatedDate",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          COLOR
          <TableSortLabel
            active={order.orderByKey === "Color"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "Color",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          PUBLIC
          <TableSortLabel
            active={order.orderByKey === "IsPublic"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "IsPublic",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>&nbsp;</li>
      </ul>
      {allEventsData?.map((item, index) => {
        return (
          <>
            {item.id !== 1 ? (
              <ul
                className={`releaseGridListBody ${
                  collapsibleItem === item.id ? "selectedSubCategory" : ""
                }`}
                key={index}>
                <span style={{ display: "flex", width: "100%" }}>
                  <li>
                    {item.childrens?.length > 0 && (
                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => {
                          setCollapsibleItem(
                            collapsibleItem && collapsibleItem === item.id
                              ? null
                              : item?.id
                          );
                        }}>
                        {collapsibleItem && collapsibleItem === item.id ? (
                          <Remove />
                        ) : (
                          <Add />
                        )}
                      </IconButton>
                    )}
                    {item.name ?? "--"}
                  </li>
                  <li> {item.totalEvents}</li>
                  <li>{moment(item.createdDate).format("MMMM DD, YYYY")}</li>
                  <li>
                    <span
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "10px",
                        display: "flex",
                        backgroundColor: item.color ? item.color : "#EB5757",
                      }}
                    />
                  </li>
                  <li>
                    {item.isPublic ? (
                      <span className={classes.inUseYes}>Yes </span>
                    ) : (
                      <span className={classes.inUseNo}>No </span>
                    )}
                  </li>
                  <li>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push("/new-category?id=" + item.id);
                      }}>
                      <EditIcon />
                    </span>
                    <a href={"/events/" + item.urlSlug} target='_blank'>
                      <OpenInNew style={{ color: "rgb(54, 153, 255)" }} />
                    </a>
                  </li>
                </span>
                {collapsibleItem && collapsibleItem === item.id && (
                  <>
                    {item.childrens?.map(historyRow => {
                      return (
                        <>
                          {item.id !== 1 ? (
                            <ul
                              className='releaseGridListBody releaseSubCatRow'
                              key={index}>
                              <li> {historyRow.name}</li>
                              <li> {historyRow.totalEvents}</li>
                              <li>
                                {moment(historyRow.createdOn).format(
                                  "MMMM DD, YYYY"
                                )}
                              </li>
                              <li>
                                <span
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "10px",
                                    display: "flex",
                                    backgroundColor: historyRow.color
                                      ? historyRow.color
                                      : "#EB5757",
                                  }}
                                />
                              </li>
                              <li>
                                {historyRow.isPublic ? (
                                  <span className={classes.inUseYes}>Yes</span>
                                ) : (
                                  <span className={classes.inUseNo}>No </span>
                                )}
                              </li>
                              <li>
                                <span
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push(
                                      "/new-category?id=" + historyRow.id
                                    );
                                  }}>
                                  <EditIcon />
                                </span>
                                <a
                                  href={"/events/" + item.urlSlug}
                                  target='_blank'>
                                  <OpenInNew
                                    style={{ color: "rgb(54, 153, 255)" }}
                                  />
                                </a>
                              </li>
                            </ul>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                )}
              </ul>
            ) : null}
          </>
        );
      })}
      {/* <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont} disabled={true} />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              CATEGORY NAME
              <TableSortLabel
                active={order.orderByKey === "CategoryName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CategoryName",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              NO. OF EVENT
              <TableSortLabel
                active={order.orderByKey === "TotalEvents"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "TotalEvents",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              DATE CREATED
              <TableSortLabel
                active={order.orderByKey === "CreatedDate"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CreatedDate",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              COLOR
              <TableSortLabel
                active={order.orderByKey === "Color"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Color",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PUBLIC
              <TableSortLabel
                active={order.orderByKey === "IsPublic"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "IsPublic",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEventsData.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridHeaderInner}>
                  <Checkbox
                    className={classes.checkBoxFont}
                    onClick={() => selectedRecord(item)}
                    checked={item.checked}
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.categoryName}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.totalEvents}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {moment(item.createdDate).format("MMMM DD, YYYY")}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <span
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "10px",
                      display: "flex",
                      backgroundColor: item.color ? item.color : "#EB5757"
                    }}
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.isPublic ? (
                    <TableCell
                      className={[classes.inUseYes, classes.gridBodyInner]}>
                      Yes
                    </TableCell>
                  ) : (
                    <TableCell
                      className={[classes.inUseNo, classes.gridBodyInner]}>
                      No
                    </TableCell>
                  )}
                </TableCell>
                <TableCell className='editButton'>
                  <span
                    onClick={() => {
                      history.push("/new-category?id=" + item.id);
                    }}>
                    <EditIcon />
                  </span>
                  <span>
                    <OpenInNewIcon style={{ color: "rgb(54, 153, 255)" }} />
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
          {allEventsData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t created any event categories. Start by
                  creating a
                  <Button onClick={redirectUrl}>New Event Category</Button>
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table> */}
    </Paper>
  );
}

export default EventsCategoryGrid;
