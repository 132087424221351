import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { AddIconWithBGColor, SoftwareReleaseSortIcon, SortIcon } from '../../../LeftNav/SvgIcons';
import { SortedByFilterCheckbox } from '../../../../../components/common/checkbox/SortedByFilterCheckbox';
import { ItemImage } from '../../../../../components/common';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from "@material-ui/icons/Check";
import { s } from '@fullcalendar/core/internal-common';
import Loader from '../../../../../components/common/Loader';

const useStyles = makeStyles((theme) => ({
    gridTopNav: {
        padding: "10px 0 10px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "23px",
    },
    selectedItems: {
        textAlign: "right",
        color: "#8D99A5",
        fontSize: "14px",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    deleteItems: {
        display: "flex",
        alignItems: "center",
    },
    gridHeaderInner: {
        color: "#828282",
        fontSize: "12px",
        fontWeight: "700",
        borderRadius: "6px 0px 0px 6px",
    },
    gridMidHeading: {
        textAlign: "center",
        color: "#828282",
        fontSize: "12px",
        fontWeight: "700",
        paddingTop: "4px",
        paddingBottom: "4px",
    },
    gridHeaderInnerCenter: {
        color: "#828282",
        fontSize: "12px",
        fontWeight: "700",
        paddingTop: "4px",
        paddingBottom: "4px",
        textAlign: "center",
    },
    gridBodyInnerCenter: {
        textAlign: "center",
    },
    gridHeader: {
        background: "#E5F2FF",
        borderRadius: "6px",
        height: "43px",
    },
    gridContainer:{ 
        maxHeight: "260px", 
        overflow: "auto" , 
        marginTop:"16px",
    },
    gridHeaderRow: {
        '& .MuiTableCell-root': {
            padding: "0px 16px",
            height: "37px",
            borderBottom: "unset",
        },
    },
    checkBoxFont: {
        fontSize: "22px",
    },
    gridBodyInner: {
        color: "#464E5F",
        fontSize: "14px",
        fontWeight: "400",
        paddingTop: "4px",
        paddingBottom: "4px",
    },
    gridBodyInnerMid: {
        fontSize: "12px",
        color: "#2D3339",
        textAlign: 'center',
    },
    gridStatusColumn: {
        textAlign: "center",
    },
    gridProductColumn: {
        // fontWeight: "bold",
    },
    gridVersionColumn: {
        fontWeight: "bold",
        color: "#3699FF",
    },
    gridStyledColumn: {
        fontSize: "12px",
        color: "#2D3339",
    },
    pendingButtons: {
        color: "#FFAD4E",
        backgroundColor: "rgba(255, 173, 78, 0.25)",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "rgba(255, 173, 78, 0.25)",
            boxShadow: "none",
        },
    },
    launchedButtons: {
        color: "#00ABA3",
        backgroundColor: "rgba(0, 171, 163, 0.25)",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "rgba(0, 171, 163, 0.25)",
            boxShadow: "none",
        },
    },
    newProductLaunch: {
        color: "#8254E3",
        background: "rgba(130, 84, 227, 0.25)",
        boxShadow: "none",
        "&:hover": {
            background: "rgba(130, 84, 227, 0.25)",
            boxShadow: "none",
        },
    },
    editButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    iconButton: {
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    buttonCell: {
        textAlign: "center",
    },
    addedBtn: {
        fontSize: "12px",
        width: "80px",
        height: "30px",
        fontWeight: "500",
        "& .MuiButton-root": {

        },
        backgroundColor: "#18BC6D",
        "&:hover": {
            backgroundColor: "#18BC6D",
        },
    },
    productGridButtonCell: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight:"22px",
    },
    imageText: {
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        background: "#3699ff",
        color: "#fff",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "normal",
    },
    addedBtnContainer:{
        display:"flex",
        justifyContent:"flex-end",
    }
}));


export const ConversationPermissionPopupGrid = ({ 
    activeTabId , 
    checkboxSelectedPermission,
    onCheckboxAddPermission,
    addConversation,
    addMultiple,
    tempselectedItem,
    onSelectAllPermission,
    isLoading,
    order,
    setOrderBy,
}) => {
    const classes = useStyles();
    const {
        conversationPermissionGridData
      } = useSelector(
        state => state.conversationReducer
    ); 
    const tabData = [
        { cells: ["All User Roles",] },
        { cells: ["First Name", "Last Name", "User Role", "Group"] },
        { cells: ["All Groups"] },
        { cells: ["Group Name", "No. of Contacts"] },
        { cells: ["Group Name", "No. of Contacts"] },
    ];

    function truncate(input) {
        if (input && input.length > 15) {
          return input.substring(0, 13) + "...";
        }
        return input;
      }

    const getTableBodyData = (activeTab) => {
        if (activeTab === "1") {
            return <TableBody>
                {(conversationPermissionGridData && conversationPermissionGridData?.length > 0) ?
                    conversationPermissionGridData?.map((item, index) => {
                        return (
                            <TableRow key={index} className={classes.gridBodyRow}>
                                <TableCell className={classes.gridStyledColumn}>
                                    {truncate(item?.name) ?? "--"}
                                </TableCell>
                                <TableCell className={classes.buttonCell} >
                                    
                                    {tempselectedItem.find(x => x.id === item.id || x.role === item.id) ?<div className={classes.addedBtnContainer}> <Button
                                        className={classes.addedBtn}
                                        color="primary"
                                        variant="contained"
                                    >
                                        <CheckIcon viewBox="0 0 30 24" />  Added
                                    </Button> 
                                    </div>
                                    : (<div className={classes.productGridButtonCell}>
                                        <SortedByFilterCheckbox
                                            checked={checkboxSelectedPermission?.some(permission => permission.id === item.id)}
                                            handleCheckboxChange={() => onCheckboxAddPermission({
                                                ...item,
                                                permissionType:"Role"
                                            })}
                                        />
                                        <div
                                            style={{ cursor: "pointer", display: "flex" }}
                                            onClick={() => addConversation({
                                                ...item,
                                                permissionType:"Role"
                                            })}
                                        >
                                            <AddIconWithBGColor />
                                        </div>
                                    </div>)}
                                </TableCell>
                            </TableRow>
                        );
                    }) : (
                        <TableRow key="0" className="gridBodyRow">
                            <TableCell
                                colSpan="6"
                                className={classes.gridBodyInner}
                                style={{ textAlign: "center" }}
                            >
                                <span className="emptyGridText">
                                    Uh Oh! No Conversation Channels have been created.
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
            </TableBody>
        } else if (activeTab === "2") {
            return <TableBody>
                {(conversationPermissionGridData && conversationPermissionGridData?.length > 0) ?
                    conversationPermissionGridData?.map((item, index) => {
                        return (
                            <TableRow key={index} className={classes.gridBodyRow}>
                                <TableCell className={classes.gridStyledColumn}>
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "17px",
                                        }}
                                    >
                                        {
                                            item?.image ?
                                                <ItemImage
                                                    imgSrc={item?.image}
                                                    alt={item?.firstName}
                                                    width="32px"
                                                    height="32px"
                                                    paddding="unset"
                                                /> :
                                                <i className={classes.imageText}>{item?.firstName ? item?.firstName?.charAt(0).toUpperCase() : ""}</i>

                                        }
                                        <span>{ truncate(item.firstName) ?? "--"}</span>
                                    </span>
                                </TableCell>
                                <TableCell className={classes.gridBodyInnerMid}>
                                    { truncate(item.lastName) ?? "--"}
                                </TableCell>
                                <TableCell className={classes.gridBodyInnerMid}>
                                    {item?.role ?? "--"}
                                </TableCell>
                                <TableCell className={classes.gridBodyInnerMid}>
                                    {item?.groupName ?? "--"}
                                </TableCell>
                                <TableCell className={classes.buttonCell} >
                                    {
                                        tempselectedItem.find(x => x.id === item.id || x.userId === item.id) ?
                                            <div className={classes.addedBtnContainer}> <Button
                                                className={classes.addedBtn}
                                                color="primary"
                                                variant="contained"
                                            >
                                                <CheckIcon viewBox="0 0 30 24" />  Added
                                            </Button>
                                            </div>
                                            :
                                            <div className={classes.productGridButtonCell}>
                                                <SortedByFilterCheckbox
                                                checked={checkboxSelectedPermission?.some(permission => permission.id === item.id)}
                                                handleCheckboxChange={() => onCheckboxAddPermission({
                                                    ...item,
                                                    permissionType:"User"
                                                })}
                                                />
                                                <div
                                                    style={{ cursor: "pointer", display: "flex" }}
                                                    onClick={() => addConversation({
                                                        ...item,
                                                        permissionType:"User"
                                                    })}
                                                >
                                                    <AddIconWithBGColor />
                                                </div>
                                            </div>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    }) : (
                        <TableRow key="0" className="gridBodyRow">
                            <TableCell
                                colSpan="6"
                                className={classes.gridBodyInner}
                                style={{ textAlign: "center" }}
                            >
                                <span className="emptyGridText">
                                    Uh Oh! No Conversation Channels have been created.
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
            </TableBody>
        } else if (activeTab === "3") {
            return <TableBody>
                {(conversationPermissionGridData && conversationPermissionGridData?.length > 0) ?
                    conversationPermissionGridData?.map((item, index) => {
                        return (
                            <TableRow key={index} className={classes.gridBodyRow}>
                                <TableCell className={classes.gridStyledColumn}>
                                    {item?.value ?? "--"}
                                </TableCell>
                                <TableCell className={classes.buttonCell} >
                                    {
                                        tempselectedItem.find(x => x.id === item.id || x.groupId === item.id) ?
                                            <div className={classes.addedBtnContainer}> <Button
                                                className={classes.addedBtn}
                                                color="primary"
                                                variant="contained"
                                            >
                                                <CheckIcon viewBox="0 0 30 24" />  Added
                                            </Button>
                                            </div>
                                            :
                                            <div className={classes.productGridButtonCell}>
                                                <SortedByFilterCheckbox
                                                    checked={checkboxSelectedPermission?.some(permission => permission.id === item.id)}
                                                    handleCheckboxChange={() => onCheckboxAddPermission({
                                                        ...item,
                                                        permissionType:"Groups"
                                                    })}
                                                />
                                                <div
                                                    style={{ cursor: "pointer", display: "flex" }}
                                                    onClick={() => addConversation({
                                                        ...item,
                                                        permissionType:"Groups"
                                                    })}
                                                >
                                                    <AddIconWithBGColor />
                                                </div>
                                            </div>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    }) : (
                        <TableRow key="0" className="gridBodyRow">
                            <TableCell
                                colSpan="6"
                                className={classes.gridBodyInner}
                                style={{ textAlign: "center" }}
                            >
                                <span className="emptyGridText">
                                    Uh Oh! No Conversation Channels have been created.
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
            </TableBody>
        } else if (activeTab === "4") {
            return <TableBody>
                {(conversationPermissionGridData && conversationPermissionGridData?.length > 0) ?
                    conversationPermissionGridData?.map((item, index) => {
                        return (
                            <TableRow key={index} className={classes.gridBodyRow}>
                                <TableCell className={classes.gridStyledColumn}>
                                    <div className={classes.colorBox}>

                                    </div>
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "17px",
                                        }}
                                    >
                                        <i className={classes.imageText} style={{backgroundColor:item?.color}}></i>

                                        {item?.groupName ?? "--"}
                                    </span>
                                </TableCell>
                                <TableCell className={classes.gridBodyInnerMid}>
                                    {item?.totalContacts ?? "--"}
                                </TableCell>
                                <TableCell className={classes.buttonCell} >
                                    {/* <div className={classes.productGridButtonCell}>
                                        <SortedByFilterCheckbox />
                                        <div
                                            style={{ cursor: "pointer", display: "flex" }}
                                        // onClick={() => addDevice(item)}
                                        >
                                            <AddIconWithBGColor />
                                        </div>
                                    </div> */}
                                    {
                                        tempselectedItem.find(x => x.id === item.id || x.groupId === item.id) ?
                                            <div className={classes.addedBtnContainer}> <Button
                                                className={classes.addedBtn}
                                                color="primary"
                                                variant="contained"
                                            >
                                                <CheckIcon viewBox="0 0 30 24" />  Added
                                            </Button>
                                            </div>
                                            :
                                            <div className={classes.productGridButtonCell}>
                                                <SortedByFilterCheckbox
                                                    checked={checkboxSelectedPermission?.some(permission => permission.id === item.id)}
                                                    handleCheckboxChange={() => onCheckboxAddPermission({
                                                        ...item,
                                                        permissionType:"Business Customer"
                                                    })}
                                                />
                                                <div
                                                    style={{ cursor: "pointer", display: "flex" }}
                                                    onClick={() => addConversation({
                                                        ...item,
                                                        permissionType:"Business Customer"
                                                    })}
                                                >
                                                    <AddIconWithBGColor />
                                                </div>
                                            </div>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    }) : (
                        <TableRow key="0" className="gridBodyRow">
                            <TableCell
                                colSpan="6"
                                className={classes.gridBodyInner}
                                style={{ textAlign: "center" }}
                            >
                                <span className="emptyGridText">
                                    Uh Oh! No Conversation Channels have been created.
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
            </TableBody>
        }else if (activeTab === "5") {
            return <TableBody>
                {(conversationPermissionGridData && conversationPermissionGridData?.length > 0) ?
                    conversationPermissionGridData?.map((item, index) => {
                        return (
                            <TableRow key={index} className={classes.gridBodyRow}>
                                <TableCell className={classes.gridStyledColumn}>
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "17px",
                                        }}
                                    >
                                        <i className={classes.imageText} style={{backgroundColor:item.color}}></i>

                                        {item?.groupName ?? "--"}
                                    </span>
                                </TableCell>
                                <TableCell className={classes.gridBodyInnerMid}>
                                    {item?.totalContacts ?? "--"}
                                </TableCell>
                                <TableCell className={classes.buttonCell} >
                                    {
                                        tempselectedItem.find(x => x.id === item.id || x.groupId === item.id) ?
                                            <div className={classes.addedBtnContainer}> <Button
                                                className={classes.addedBtn}
                                                color="primary"
                                                variant="contained"
                                            >
                                                <CheckIcon viewBox="0 0 30 24" />  Added
                                            </Button>
                                            </div>
                                            :
                                            <div className={classes.productGridButtonCell}>
                                                <SortedByFilterCheckbox
                                                    checked={checkboxSelectedPermission?.some(permission => permission.id === item.id)}
                                                    handleCheckboxChange={() => onCheckboxAddPermission({
                                                        ...item,
                                                        permissionType:"End Customer"
                                                    })}
                                                />
                                                <div
                                                    style={{ cursor: "pointer", display: "flex" }}
                                                    onClick={() => addConversation({
                                                        ...item,
                                                        permissionType:"End Customer"
                                                    })}
                                                >
                                                    <AddIconWithBGColor />
                                                </div>
                                            </div>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    }) : (
                        <TableRow key="0" className="gridBodyRow">
                            <TableCell
                                colSpan="6"
                                className={classes.gridBodyInner}
                                style={{ textAlign: "center" }}
                            >
                                <span className="emptyGridText">
                                    Uh Oh! No Conversation Channels have been created.
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
            </TableBody>
        }
    }

    const getSortedTag1 = (headCell) => {
        if (headCell === "All User Roles") {
            return "Name"
        }
    }
    const getSortedTag2 = (headCell) => {
        if (headCell === "First Name") {
            return "FirstName"
        } else if (headCell === "Last Name") {
            return "LastName"
        } else if (headCell === "User Role") {
            return "Role"
        } else if (headCell === "Group") {
            return "GroupName"
        }
    }
    const getSortedTag3 = (headCell) => {
        if (headCell === "All Groups") {
            return "Value"
        }
    }
    const getSortedTag4 = (headCell) => {
        if (headCell === "Group Name") {
            return "GroupName"
        } else if (headCell === "No. of Contacts") {
            return "TotalContacts"
        }
    }

    return (
        <div className={classes.gridContainer}>
            {isLoading ? <Loader /> : null}
            <Table>
                <TableHead className={classes.gridHeader}>
                    <TableRow className={classes.gridHeaderRow}>
                        {
                            tabData[Number(activeTabId) - 1].cells.map((cellHead, index) => (
                                <TableCell key={cellHead} className={index === 0 ? classes.gridHeaderInner : classes.gridMidHeading}>
                                    {cellHead}
                                    <TableSortLabel
                                        active={
                                            activeTabId === "1" ? order?.orderByKey === getSortedTag1(cellHead) :
                                                activeTabId === "2" ? order?.orderByKey === getSortedTag2(cellHead) :
                                                    activeTabId === "3" ? order?.orderByKey === getSortedTag3(cellHead) :
                                                        activeTabId === "4" ? order?.orderByKey === getSortedTag4(cellHead) :
                                                            order?.orderByKey === getSortedTag4(cellHead)
                                        }
                                        direction={order?.orderVal === 1 ? "asc" : "desc"}
                                        onClick={() =>
                                            setOrderBy({
                                                orderByKey: activeTabId === "1" ? getSortedTag1(cellHead) :
                                                    activeTabId === "2" ? getSortedTag2(cellHead) :
                                                        activeTabId === "3" ? getSortedTag3(cellHead) :
                                                            activeTabId === "4" ? getSortedTag4(cellHead) :
                                                                getSortedTag4(cellHead),
                                                orderVal: order?.orderVal === 0 ? 1 : 0,
                                            })
                                        }
                                        IconComponent={SoftwareReleaseSortIcon}
                                        className="sortIcon"
                                    />
                                </TableCell>
                            ))
                        }
                        <TableCell className={classes.buttonCell} >
                            <div className={classes.productGridButtonCell}>
                                <SortedByFilterCheckbox
                                    handleCheckboxChange={onSelectAllPermission}
                                    checked={
                                        activeTabId === "1" ?
                                        conversationPermissionGridData?.every(item => checkboxSelectedPermission?.some(obj => obj.id === item.id) && checkboxSelectedPermission?.length > 0) :
                                        activeTabId === "2" ?
                                        conversationPermissionGridData?.every(item => checkboxSelectedPermission?.some(obj => obj.id === item.id) && checkboxSelectedPermission?.length > 0) :
                                        activeTabId === "3" ?
                                        conversationPermissionGridData?.every(item => checkboxSelectedPermission?.some(obj => obj.id === item.id) && checkboxSelectedPermission?.length > 0) :
                                        activeTabId === "4" ?
                                        conversationPermissionGridData?.every(item => checkboxSelectedPermission?.some(obj => obj.id === item.id) && checkboxSelectedPermission?.length > 0) :
                                        activeTabId === "5" ?
                                        conversationPermissionGridData?.every(item => checkboxSelectedPermission?.some(obj => obj.id === item.id) && checkboxSelectedPermission?.length > 0) :
                                        false
                                    }
                                />
                                <div
                                    style={{ cursor: "pointer", display: "flex" }}
                                    onClick={() => addMultiple()}
                                >
                                    <AddIconWithBGColor />
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>

                {
                    getTableBodyData(activeTabId)
                }


            </Table>
        </div>

    )
};