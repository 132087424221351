import { pubEventTypes } from "./pubEventTypes";
import { pubEventApi } from "./pubEventApi";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: pubEventTypes.PUB_EVENT_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onGetPubAllEvents = eventPayload => async dispatch => {
  let response = await pubEventApi.getPubAllEvents(eventPayload);
  if (response && response.success) {
    dispatch({
      type: pubEventTypes.PUB_EVENT_ONCHANGE,
      payload: { prop: "pubEventsWithCategory", value: response.data },
    });
  }
  return response;
};

const onGetPubOnlyEvents = eventPayload => async dispatch => {
  let response = await pubEventApi.getPubAllEvents(eventPayload);
  if (response && response.success) {
    dispatch({
      type: pubEventTypes.PUB_EVENT_ONCHANGE,
      payload: {
        prop: "allEvents",
        value: response.data[0] ? response.data[0].data : [],
      },
    });
  }
  return response;
};

const onGetPubEventCategories = () => async dispatch => {
  let response = await pubEventApi.getPubEventCategory();
  if (response && response.success) {
    dispatch({
      type: pubEventTypes.PUB_EVENT_ONCHANGE,
      payload: { prop: "pubEventsCategory", value: response.data },
    });
  }
  return response;
};

const onGetPubEventDateFilter = catIds => async dispatch => {
  let response = await pubEventApi.getPubEventDateFilter(catIds);
  if (response && response.success) {
    dispatch({
      type: pubEventTypes.PUB_EVENT_ONCHANGE,
      payload: { prop: "eventDateFilter", value: response.data },
    });
  }
  return response;
};

const onSelectPubEventCategory = (value, selectedCat) => async dispatch => {
  let catIds = [];
  dispatch({
    type: pubEventTypes.SELECTED_PUB_EVENT_CATEGORY,
    payload: value,
  });
  if (selectedCat && selectedCat.length > 0) {
    selectedCat.forEach(element => {
      catIds.push(element.id);
    });
  }
  let response = await pubEventApi.getPubEventDateFilter(catIds);
  if (response && response.success) {
    dispatch({
      type: pubEventTypes.PUB_EVENT_ONCHANGE,
      payload: { prop: "eventDateFilter", value: response.data },
    });
  }
  return response;
};
const onSelectPubEventDateFilter = value => async dispatch => {
  dispatch({
    type: pubEventTypes.SELECTED_PUB_EVENT_FILTER,
    payload: value,
  });
};

const onGetEventDetail = (eventId, urlSlug) => async dispatch => {
  let response = await pubEventApi.getPubEventDetail(eventId, urlSlug);
  return response;
};

const onGetPubEventCalander = clndrData => async dispatch => {
  let response = await pubEventApi.getCalenderData(clndrData);
  return response;
};

const onSelectCalendarCategory = value => async dispatch => {
  dispatch({
    type: pubEventTypes.SELECTED_PUB_CLNDR_CATEGORY,
    payload: value,
  });
};

const onUpdateEventStatus = eventStatus => async dispatch => {
  let response = await pubEventApi.addEventStatus(eventStatus);
  return response;
};

const onAddValueInFilter = value => async dispatch => {
  dispatch({
    type: pubEventTypes.SELECTED_PUB_EVENT_CATEGORY,
    payload: value,
  });
};

export const pubEventActions = {
  onChange: onChange,
  onGetPubAllEvents: onGetPubAllEvents,
  onGetPubEventCategories: onGetPubEventCategories,
  onGetPubEventDateFilter: onGetPubEventDateFilter,
  onSelectPubEventCategory: onSelectPubEventCategory,
  onSelectPubEventDateFilter: onSelectPubEventDateFilter,
  onGetEventDetail: onGetEventDetail,
  onGetPubOnlyEvents: onGetPubOnlyEvents,
  onGetPubEventCalander: onGetPubEventCalander,
  onSelectCalendarCategory: onSelectCalendarCategory,
  onUpdateEventStatus: onUpdateEventStatus,
  onAddValueInFilter: onAddValueInFilter,
};
