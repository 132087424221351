import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Chip } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { concat } from "lodash";
import Grid from "@material-ui/core/Grid";
import queryString from "query-string";
import Loader from "../../../../components/common/Loader";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import RadioButtons from "../../../../components/common/RadioButton/RadioButton";
import { Dropdown } from "../../../../components/common";
import InputField from "../../../../components/common/InputField";

const useStyles = makeStyles(theme => ({
  chipWrapper: {
    marginTop: "10px"
  }
}));

const SupportResources = ({ isActive }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [productId, setProductId] = useState(0);
  const [productRegistration, setProductRegistration] = useState(null);
  const [isProductRegistration, setisProductRegistration] = useState(
    productRegistration?.isProductRegistration || 0
  );
  const [regisrtationType, setregisrtationType] = useState(
    productRegistration?.registrationType || ""
  );

  const [productReturn, setProductReturn] = useState(null);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [endCustomerGroups, setEndCustomerGroups] = useState([]);

  const [supportLinks, setSupportLinks] = useState([]);

  const sepProductRegistrationProp = ({ propName, value }) => {
    setProductRegistration({
      ...productRegistration,
      [propName]: value
    });
  };

  const sepProductReturnProp = ({ propName, value }) => {
    setProductReturn({
      ...productReturn,
      [propName]: value
    });
  };

  useEffect(() => {
    if (isActive) {
      setLoading(true);
      const inventoryProduct = queryString.parse(location.search);
      dispatch(
        productInventoryActions.onGetProductRegistrationByProductId(
          inventoryProduct?.id
        )
      ).then(data => {
        setLoading(false);
        setProductId(inventoryProduct?.id);
        if (data) {
          const { data: pReg } = data;
          if (pReg) {
            setProductRegistration(pReg);
          }
        }
      });

      dispatch(
        productInventoryActions.onGetProductReturnByProductId(
          inventoryProduct?.id
        )
      ).then(data => {
        setLoading(false);
        setProductId(inventoryProduct?.id);
        if (data) {
          const { data: pReturn } = data;
          if (pReturn) {
            setProductReturn(pReturn);
            const scgs = pReturn.customerGroups.map(c => c?.id);
            setCustomerGroups(scgs);
          }
        }
      });

      dispatch(productInventoryActions.onGetEndCustomerGroups()).then(
        endCustGroups => {
          if (endCustGroups?.data) {
            setEndCustomerGroups(endCustGroups?.data);
          }
        }
      );

      dispatch(
        productInventoryActions.onGetProdSupportResources(inventoryProduct?.id)
      ).then(supportLinks => {
        if (supportLinks?.data) {
          setSupportLinks(supportLinks?.data);
        } else {
          setSupportLinks([
            {
              key: "",
              value: ""
            }
          ]);
        }
      });
    }
    return () => {
      setProductRegistration(null);
      setProductReturn(null);
    };
  }, [dispatch, location, isActive]);

  useEffect(() => {
    if (productRegistration) {
      setisProductRegistration(productRegistration?.isProductRegistration || 0);
      setregisrtationType(
        productRegistration?.registrationType || "Default Registration Process"
      );
    }
  }, [productRegistration]);

  const productRegistrationSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const newProductRegistration = {
      productId,
      isProductRegistration: productRegistration?.isProductRegistration || 0,
      registrationType:
        productRegistration?.registrationType || "Default Registration Process",
      isShowOnApp: productRegistration?.isShowOnApp || 0
    };
    dispatch(
      productInventoryActions.onAddUpdateProductRegistration(
        newProductRegistration
      )
    ).then(data => {
      if (data && data.success) {
        toast.success("Product Registration done successfully");
      } else if (!data?.success) {
        toast.error(data.message);
      }
      setLoading(false);
    });
  };

  const returnsSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const newProductReturn = {
      productId,
      isReturnAllowed: productReturn?.isReturnAllowed,
      groups: customerGroups
    };
    dispatch(
      productInventoryActions.onAddUpdateProductReturn(newProductReturn)
    ).then(data => {
      if (data && data.success) {
        toast.success("Product Returns done successfully");
      } else if (!data?.success) {
        toast.error(data.message);
      }
      setLoading(false);
    });
  };

  const supportLinksSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const newSupportLinks = {
      productId,
      keyValuePair: supportLinks
    };
    dispatch(
      productInventoryActions.onAddProductSupportResources(newSupportLinks)
    ).then(data => {
      if (data && data.success) {
        toast.success("Product Support Links added successfully");
      } else if (!data?.success) {
        toast.error(data.message);
      }
      setLoading(false);
    });
  };

  return (
    <div className={`supportResources accordianWrap`}>
      {isLoading ? <Loader /> : null}

      <AccordionContainer
        title='Returns'
        formButton={
          <Button
            color='primary'
            variant='contained'
            className='dashboardButtons'
            // type='submit'
            onClick={returnsSubmit}>
            Save
          </Button>
        }>
        <Grid container spacing={3}>
          <Grid item xs={12} spacing={3}>
            <div className='product-overview'>
              <Grid item xs={3}>
                <span className={"labelStyle"}>Returns allowed</span>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: "flex", alignItems: "center" }}>
                <RadioButtons
                  label={"Yes"}
                  value={1}
                  selectedValue={Boolean(productReturn?.isReturnAllowed)}
                  handleChange={({ target }) =>
                    sepProductReturnProp({
                      propName: "isReturnAllowed",
                      value: 1
                    })
                  }
                />
                <span className='checkboxLabel'>Yes</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <RadioButtons
                  label={"No"}
                  value={0}
                  selectedValue={!Boolean(productReturn?.isReturnAllowed)}
                  handleChange={({ target }) =>
                    sepProductReturnProp({
                      propName: "isReturnAllowed",
                      value: 0
                    })
                  }
                />
                <span className='checkboxLabel'>No</span>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} spacing={3}>
            <div className='product-overview'>
              <Grid item xs={3}>
                <span className={"labelStyle"}>Customer return groups</span>
                <div className='inputSubtitle'>
                  Customer groups who can return this product
                </div>
              </Grid>

              <Grid item xs={9}>
                <Grid item xs={4}>
                  <Dropdown
                    // selectlabel='Customer return groups'
                    placeholder={"Customer return groups"}
                    customMenuItems={endCustomerGroups.map(g => {
                      return {
                        ...g,
                        name: g.value
                      };
                    })}
                    value={customerGroups.length > 0 ? customerGroups : -1}
                    handleChange={({ target }) => {
                      const ifExists = customerGroups.find(
                        a => a === target.value
                      );
                      if (customerGroups.length === 0 || !ifExists) {
                        customerGroups.push(target.value);
                        setCustomerGroups([...customerGroups]);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={9} className={classes.chipWrapper}>
                  {customerGroups.map(g => (
                    <Chip
                      key={g}
                      className='chip-item'
                      label={
                        endCustomerGroups.find(c => `${c.id}` === `${g}`)?.value
                      }
                      onDelete={() =>
                        setCustomerGroups([
                          ...customerGroups.filter(cg => cg !== g)
                        ])
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </AccordionContainer>

      <ValidatorForm
        style={{ marginTop: "20px", marginBottom: "20px" }}
        onSubmit={supportLinksSubmit}>
        <AccordionContainer
          title='Support Articles'
          formButton={
            <Button
              color='primary'
              variant='contained'
              className='dashboardButtons'
              type='submit'>
              Save
            </Button>
          }>
          <Grid container spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                {supportLinks?.map((s, i) => {
                  return (
                    <Grid container spacing={3} key={i}>
                      <Grid item xs={3}>
                        <InputField
                          placeholder='Support Link Name'
                          inputlabel={`Support Link ${i + 1} Name`}
                          name={`supportLink${i + 1}Name`}
                          validators={["required"]}
                          errorMessages={["Please enter Name"]}
                          value={s.key}
                          onChange={({ target }) => {
                            const newSupportLinks = [...supportLinks];
                            newSupportLinks[i].key = target.value;
                            setSupportLinks([...newSupportLinks]);
                          }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <InputField
                          placeholder='Support Link URL'
                          inputlabel={`Support Link ${i + 1} URL`}
                          name={`supportLink${i + 1}URL`}
                          validators={["required"]}
                          errorMessages={["Please enter URL"]}
                          value={s.value}
                          onChange={({ target }) => {
                            const newSupportLinks = [...supportLinks];
                            newSupportLinks[i].value = target.value;
                            setSupportLinks([...newSupportLinks]);
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={2}>
                <Button
                  color='primary'
                  variant='contained'
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    setSupportLinks([
                      ...concat(supportLinks, {
                        key: "",
                        value: ""
                      })
                    ]);
                  }}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionContainer>
      </ValidatorForm>

      <ValidatorForm onSubmit={productRegistrationSubmit}>
        <AccordionContainer
          title='Product Registration'
          formButton={
            <Button
              color='primary'
              variant='contained'
              className='dashboardButtons'
              type='submit'>
              Save
            </Button>
          }>
          <Grid container spacing={3}>
            <Grid item xs={12} spacing={3}>
              <div className='product-overview'>
                <Grid item xs={3}>
                  <span className={"labelStyle"}>Registration status</span>
                </Grid>
                <Grid item xs={3}>
                  <Dropdown
                    placeholder='Please Select'
                    customMenuItems={[
                      {
                        id: 0,
                        name: "Disabled"
                      },
                      {
                        id: 1,
                        name: "Enabled"
                      }
                    ]}
                    value={isProductRegistration}
                    handleChange={({ target }) =>
                      sepProductRegistrationProp({
                        propName: "isProductRegistration",
                        value: target.value
                      })
                    }
                  />
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} spacing={3}>
              <div className='product-overview'>
                <Grid item xs={3}>
                  <span className={"labelStyle"}>
                    Product registration type
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <Dropdown
                    placeholder='Please Select'
                    customMenuItems={[
                      {
                        id: "Default Registration Process",
                        name: "Default Registration Process"
                      },
                      {
                        id: "Custom Registration Process",
                        name: "Custom Registration Process"
                      }
                    ]}
                    value={regisrtationType}
                    handleChange={({ target }) =>
                      sepProductRegistrationProp({
                        propName: "registrationType",
                        value: target.value
                      })
                    }
                  />
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} spacing={3}>
              <div className='product-overview'>
                <Grid item xs={3}>
                  <span className={"labelStyle"}>Show on App/Web</span>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", alignItems: "center" }}>
                  <RadioButtons
                    label={"Yes"}
                    value={1}
                    selectedValue={Boolean(productRegistration?.isShowOnApp)}
                    handleChange={({ target }) =>
                      sepProductRegistrationProp({
                        propName: "isShowOnApp",
                        value: 1
                      })
                    }
                  />
                  <span className='checkboxLabel'>Yes</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <RadioButtons
                    label={"No"}
                    value={0}
                    selectedValue={!Boolean(productRegistration?.isShowOnApp)}
                    handleChange={({ target }) =>
                      sepProductRegistrationProp({
                        propName: "isShowOnApp",
                        value: 0
                      })
                    }
                  />
                  <span className='checkboxLabel'>No</span>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </AccordionContainer>
      </ValidatorForm>
    </div>
  );
};

export { SupportResources };
