import React, { useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import { useDispatch } from "react-redux";
import { launchesActions } from "../../../store/launches/launchesActions";
import EmptyTextarea from "../Textarea";

// new FroalaEditor(".selector", {
//   toolbarButtons: {
//     moreText: {
//       buttons: [
//         "bold",
//         "italic",
//         "underline",
//         "strikeThrough",
//         "subscript",
//         "superscript",
//         "fontFamily",
//         "fontSize",
//         "textColor",
//         "backgroundColor",
//         "inlineClass",
//         "inlineStyle",
//         "clearFormatting",
//       ],
//     },
//     moreParagraph: {
//       buttons: [
//         "alignLeft",
//         "alignCenter",
//         "formatOLSimple",
//         "alignRight",
//         "alignJustify",
//         "formatOL",
//         "formatUL",
//         "paragraphFormat",
//         "paragraphStyle",
//         "lineHeight",
//         "outdent",
//         "indent",
//         "quote",
//       ],
//     },
//     moreRich: {
//       buttons: [
//         "insertLink",
//         "insertImage",
//         "insertVideo",
//         "insertTable",
//         "emoticons",
//         "fontAwesome",
//         "specialCharacters",
//         "embedly",
//         "insertFile",
//         "insertHR",
//       ],
//     },
//     moreMisc: {
//       buttons: [
//         "undo",
//         "redo",
//         "fullscreen",
//         "print",
//         "getPDF",
//         "spellChecker",
//         "selectAll",
//         "html",
//         "help",
//       ],
//       align: "right",
//       buttonsVisible: 2,
//     },
//   },
// });

const defaultToolbarButtons = {
  moreText: {
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "subscript",
      "superscript",
      "fontFamily",
      "fontSize",
      "textColor",
      "backgroundColor",
      "inlineClass",
      "inlineStyle",
      "clearFormatting",
    ],
  },
  moreParagraph: {
    buttons: [
      "alignLeft",
      "alignCenter",
      "formatOLSimple",
      "alignRight",
      "alignJustify",
      "formatOL",
      "formatUL",
      "paragraphFormat",
      "paragraphStyle",
      "lineHeight",
      "outdent",
      "indent",
      "quote",
    ],
  },
  moreRich: {
    buttons: [
      "insertLink",
      "insertImage",
      "insertVideo",
      "insertTable",
      "emoticons",
      "fontAwesome",
      "specialCharacters",
      "embedly",
      "insertFile",
      "insertHR",
    ],
  },
  moreMisc: {
    buttons: [
      "undo",
      "redo",
      "fullscreen",
      "print",
      "getPDF",
      "spellChecker",
      "selectAll",
      "html",
      "help",
    ],
    align: "right",
    buttonsVisible: 2,
  },
};

var testUrl = "";

const FrolaEditor = ({
  model,
  onHandleModelChange,
  charCounterMax = 100000,
  conversationPlateform,
  comDescription,
}) => {
  const dispatch = useDispatch();

  const uploadFroalaImage = file => {
    dispatch(launchesActions.uploadImage(file)).then(res => {
      if (res.success) {
        testUrl = res.data.url;
      }
    });
  };

  return (
    <div className='fr-view' style={{maxWidth:"700px" , overflow:"auto"}}>
      {
      conversationPlateform === "Facebook" ||
      conversationPlateform === "Instagram"  ? (
          <EmptyTextarea
          placeholder={`Type Something`}
          value={comDescription}
          onChange={onHandleModelChange}
        />
        ):(
          <FroalaEditor
          // ref={ref}
          model={model}
          onModelChange={onHandleModelChange}
          tag='textarea'
          config={{
            toolbarButtons:defaultToolbarButtons,
            events: {
              "image.inserted": function ($img, response) {
                // $img[0].src =
                //   "https://media0.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif?cid=790b76118e6134003746d6e19261c2868eb9cbe166fcac9f&rid=giphy.gif&ct=g";
                $img[0].src =
                  "https://logosbynick.com/wp-content/uploads/2021/01/animated-gif.gif";
                setTimeout(() => {
                  $img[0].src = testUrl;
                  // setLoading(false);
                  // $img[0].currentSrc = testUrl;
                }, 7000);
                // Do something here.
                // this is the editor instance.
                console.log(this);
              },
              "image.beforeUpload": function (images) {
                uploadFroalaImage(images);
                // Do something here.
                // this is the editor instance.
                console.log(this);
              },
            },
            charCounterMax: charCounterMax,
            enter: FroalaEditor.ENTER_BR,
            key: "VCC6kE4A3D3H3C7C7C6cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A3F3E3B3F2A5C3==",
          }}
         />

        )
      }

    </div>
  );
};

export { FrolaEditor };
