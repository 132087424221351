import React from "react";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

function Overview({ description, compImage }) {
  return (
    <div className="overviewWrapper">
      <div className="popupTextarea comProfileImage">
        <img src={compImage ? compImage : null} />
      </div>
      <h2>Details</h2>

      <p>
        <FroalaEditorView model={description} />
      </p>
    </div>
  );
}

export default Overview;
