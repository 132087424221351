import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, Tab } from "@material-ui/core";
import { toast } from "react-toastify";
import { GridFilterBar } from "./GridFilterBar";
import Loader from "../../../../components/common/Loader";
import { Pagination } from "../../../../components/common";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import {
  AccessAlarm,
  ArrowBackIos,
  Cancel,
  CheckCircle,
  Delete,
} from "@material-ui/icons";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { AvailabilityPeriodsGrid } from "./AvailabilityPeriodsGrid";
import moment from "moment";
import { endpoints } from "../../../../config/apiConfig";

const PricingAvailabilityPeriods = ({ onBackClick }) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [isNewAvailabilityPeriod, setIsNewAvailabilityPeriod] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [availabilityPeriods, setAvailabilityPeriods] = useState([]);

  // pagination states
  const [filters, setFilters] = useState({
    period: "Current",
    periodName: "",
    startDate: "",
    endDate: "",
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "",
    orderVal: 0,
  });
  const [selectedtTab, setTab] = useState("Current");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      productInventoryActions.onGetPricingAvailabilityPeriods({
        pageNumber: page,
        noOfRecoreds: rowsPerPage,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
        filters: {
          ...filters,
          period: selectedtTab,
          currentDate: moment().format("MM/DD/YYYY HH:mm:ss"),
        },
      })
    ).then(data => {
      setAvailabilityPeriods(data?.data?.data || []);
      setIsNewAvailabilityPeriod(false);
      if (data?.success) {
        setPaginationResponse(data?.data?.pagination);
      } else {
        if (data?.message?.includes("No Data Found")) {
        } else toast.error("Something went wrong, Please try later.");
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setSelectedItems([]);
      setLoading(false);
    });
  }, [
    dispatch,
    order.orderByKey,
    order.orderVal,
    page,
    rowsPerPage,
    filters,
    selectedtTab,
  ]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData, page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const onDeleteItems = () => {
    setLoading(true);
    dispatch(
      productInventoryActions.onDeleteItemsByIds({
        url: endpoints.productInventory.deletePricingAvailabilityPeriod,
        itemIds: selectedItems,
      })
    ).then(res => {
      if (!res?.success) {
        setLoading(false);
        toast.error(res?.message);
      } else {
        toast.success("Product pricing deleted successfully");
        loadData();
      }
    });
  };

  return (
    <div className='price-availability-period gridBody'>
      {isLoading ? <Loader /> : null}
      <div className='contentHeading'>
        <h3>Product Availability Periods</h3>
        <Button
          color='primary'
          onClick={() => setIsNewAvailabilityPeriod(true)}
          variant='contained'>
          New Availability Period
        </Button>
        <div
          style={{
            position: "absolute",
            top: "70px",
          }}>
          <Button color='primary' variant='text' onClick={onBackClick}>
            <ArrowBackIos style={{ fontSize: "14px" }} />
            {`Back to Product Pricing`}
          </Button>
        </div>
      </div>

      <div>
        <GridFilterBar
          filterBy={filters}
          onFilterButtonPress={customFilters =>
            setFilters({ periodName: customFilters?.product })
          }
          onClearButtonPress={() =>
            setFilters({
              product: "",
              periodName: "",
              categoryId: null,
              dateCreated: null,
              status: null,
            })
          }
          removeGridFilter={true}
        />
        {selectedItems.length > 0 ? (
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "15px",
              }}>
              <span
                className='gridDeleteItems'
                onClick={() => {
                  onDeleteItems();
                }}>
                <Delete /> Delete
              </span>
            </Grid>
          </Grid>
        ) : null}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={selectedtTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                <Tab
                  label='Currently Active'
                  iconPosition='start'
                  icon={
                    <CheckCircle
                      fontSize='small'
                      style={{ color: "#18BC6D" }}
                    />
                  }
                  value='Current'
                />
                <Tab
                  label='Upcoming Periods'
                  iconPosition='start'
                  icon={
                    <AccessAlarm
                      fontSize='small'
                      style={{ color: "#9B51E0" }}
                    />
                  }
                  value='Upcoming'
                />
                <Tab
                  iconPosition='start'
                  icon={
                    <Cancel fontSize='small' style={{ color: "#FF3535" }} />
                  }
                  label='Expired Periods'
                  value='Expired'
                />
              </TabList>
            </Box>
            <TabPanel className='tab-panel-root' value={selectedtTab}>
              <AvailabilityPeriodsGrid
                listData={availabilityPeriods}
                loadData={() => loadData()}
                order={order}
                onOrderBy={setOrderBy}
                isNewAvailabilityPeriod={isNewAvailabilityPeriod}
                setIsNewAvailabilityPeriod={setIsNewAvailabilityPeriod}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
              />
              <Pagination
                recordCount={paginationResponse?.totalRecords}
                page={page - 1}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </TabPanel>
            <TabPanel value='2'>Item Two</TabPanel>
          </TabContext>
        </Box>
        {/* <AllProductsGrid
            listData={products}
            loadData={() => loadData()}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          /> */}
      </div>
    </div>
  );
};

export { PricingAvailabilityPeriods };
