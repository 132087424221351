import { pubMediaLibraryTypes } from "./pubMediaLibraryTypes";
import { pubMediaLibraryApi } from "./pubMediaLibraryApi";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: pubMediaLibraryTypes.PUB_MEDIA_LIBRARY_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onGetPubAllAssets = assetPayload => async dispatch => {
  let response = await pubMediaLibraryApi.getPubAllAssets(assetPayload);
  if (response && response.success) {
    dispatch({
      type: pubMediaLibraryTypes.PUB_MEDIA_LIBRARY_ONCHANGE,
      payload: { prop: "mediaAssetsWithCategory", value: response.data },
    });
  }
  return response;
};

const onGetPubMedCategories = () => async dispatch => {
  let response = await pubMediaLibraryApi.getPubMedCategory();
  if (response && response.success) {
    dispatch({
      type: pubMediaLibraryTypes.PUB_MEDIA_LIBRARY_ONCHANGE,
      payload: { prop: "pubMediaCategory", value: response.data },
    });
  }
  return response;
};

const onGetPubMedDateFilter = catIds => async dispatch => {
  let response = await pubMediaLibraryApi.getPubMedDateFilter(catIds);
  if (response && response.success) {
    dispatch({
      type: pubMediaLibraryTypes.PUB_MEDIA_LIBRARY_ONCHANGE,
      payload: { prop: "medDateFilter", value: response.data },
    });
  }
  return response;
};

const onSelectPubMedCategory = (value, selectedCat) => async dispatch => {
  let catIds = [];
  dispatch({
    type: pubMediaLibraryTypes.SELECTED_PUB_ML_CATEGORY,
    payload: value,
  });
  if (selectedCat && selectedCat.length > 0) {
    selectedCat.forEach(element => {
      catIds.push(element.id);
    });
  }
  let response = await pubMediaLibraryApi.getPubMedDateFilter(catIds);
  if (response && response.success) {
    dispatch({
      type: pubMediaLibraryTypes.PUB_MEDIA_LIBRARY_ONCHANGE,
      payload: { prop: "medDateFilter", value: response.data },
    });
  }
  return response;
};

const onSelectPubMedDateFilter = value => async dispatch => {
  dispatch({
    type: pubMediaLibraryTypes.SELECTED_PUB_PML_FILTER,
    payload: value,
  });
};
const onGetPubCollectionDetail = (colId, urlSlug) => async dispatch => {
  let response = await pubMediaLibraryApi.getCollectionDetail(colId, urlSlug);
  return response;
};

const onDownLoadAsset = assetData => async dispatch => {
  let response = await pubMediaLibraryApi.downLoadAssets(assetData);
  return response;
};

const onAddValueInFilter = value => async dispatch => {
  dispatch({
    type: pubMediaLibraryTypes.SELECTED_PUB_ML_CATEGORY,
    payload: value,
  });
};

export const pubMediaLibraryActions = {
  onChange: onChange,
  onGetPubAllAssets: onGetPubAllAssets,
  onGetPubMedCategories: onGetPubMedCategories,
  onGetPubMedDateFilter: onGetPubMedDateFilter,
  onSelectPubMedCategory: onSelectPubMedCategory,
  onSelectPubMedDateFilter: onSelectPubMedDateFilter,
  onGetPubCollectionDetail: onGetPubCollectionDetail,
  onDownLoadAsset: onDownLoadAsset,
  onAddValueInFilter: onAddValueInFilter,
};
