import React from "react";
import { Grid, Paper } from "@material-ui/core";
import NewsUpdate from "./NewsUpdate";
import { logo, ofishlogo } from "../../../resources/images/index";
import {
  QuickLick,
  FooterNavigation,
  SocialConnects,
} from "./FooterNavigation";
import NavLink from "./NavLink";

function Footer(props) {
  return (
    <div className='footerWrapper'>
      <div className='footerBody'>
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <NewsUpdate
                heading='Stay Updated On All Things Aquatic AV'
                text='Learn about new products and deals when you '
                linkText='Get Our Newsletter'
                link={`/events`}
              />

              <NewsUpdate
                heading='Part of the Press? Access our full newsroom'
                text='Access Aquatic AV news early &amp; more '
                linkText='Request Press Access'
                link={`/events`}
              />
            </Grid>
            <Grid item xs={3}>
              <FooterNavigation heading='Aquatic AV Newsroom' />
            </Grid>
            <Grid item xs={2}>
              <QuickLick heading='Quick Links' />
            </Grid>

            <Grid item xs={2}>
              <SocialConnects heading='Let’s Connect' />
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className='footerBody footerPrivacyPolicy'>
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <img src={logo} alt='' />
              <ul>
                <li> © 2022 Aquatic AV</li>
                <li>
                  <NavLink text='Terms of Use' link={`/`} />
                </li>
                <li>
                  <NavLink text='Privacy Policy' link={`/`} />
                </li>
              </ul>
            </Grid>

            <Grid item xs={6} className='powerBy'>
              Powered by: <img src={ofishlogo} alt='' />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}

export default Footer;
