import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos } from "@material-ui/icons";
import React, { useEffect , useCallback } from "react";
import { useHistory } from "react-router-dom";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import Dashbard from "../Dashbard";
import SoftwareReleaseAccordian from "./SoftwareReleaseAccordian";
import { useState } from "react";
import { SelectInput } from "../../../../components/common";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SoftwareReleaseEdit from "./SoftwareReleaseEdit";
import { softwareUpdateActions } from "../../../../store/softwareUpdates/softwareUpdatesActions";
import { useDispatch, useSelector } from "react-redux";
import { useLoadDataGet } from "../../../../utils/use-load-data";
import { endpoints } from "../../../../config/apiConfig";

const releaseStatus = [
  { id: 1, name: "Creating"},
  { id: 2, name: "Live" },
  { id: 3, name: "Retired" },
  { id: 4, name: "In Testing" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
}));

const buttonTextStyle = {
  color: "#3699FF",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "0.28px",
};

const NewSoftwareRelease = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: paramId } = useParams();

  const {
    selectedRelease,
    isLoading,
    softwareReleaseData,
    contactIds,
    groupIds,
    companyIds,
    portalIds,
    selectedReleasePortal,
    selectedReleaseContacts,
    poratlAndContactData,
    customerContactData,
    productsList,
    compatibleProductsList,
    selectedReleaseProducts,
    notificationTypes,
    selectedReleaseNotifications,
    softwareReleaseDetials
  } = useSelector(state => state.softwareUpdatesReducer);
  const { records: portalsList } = useLoadDataGet({
    url: endpoints.portal.getAllPortals,
  });
  const [statusClass , setStatusClass] = useState("newSoftwareHeaderSelectCreating");
  const [updatedTempData, setUpdatedTempData] = useState([]);
  const [filteredProtalData, setFilteredProtalData] = useState([]);
  const [notificationData, setNotificationData] = useState({
    id: 0,
    portalId: 0,
    softwareReleaseId: selectedRelease?.id,
    notificationTypeId: 0,
    isImmediate: true,
    afterReleaseValue: 0,
    afterReleaseTimeValue: 1,
    subject: "",
    content: "",
  });
  const [releaseNoteSelectedData, setReleaseNoteSelectedData] = useState("");
  useEffect(() => {
    if (selectedRelease.id) {
      setNotificationData({...notificationData, softwareReleaseId: selectedRelease.id});
    }
  }, [selectedRelease]);

  useEffect(() => {
    return (() => {
      dispatch(softwareUpdateActions.onResetSoftwareReleaseData());
    });
  }, []);

  useEffect(() => {
    switch (selectedRelease?.version) {
      case 1:
        setStatusClass("newSoftwareHeaderSelectCreating");
        break;
      case 2:
        setStatusClass("newSoftwareHeaderSelectActive");
        break;
      case 3:
        setStatusClass("newSoftwareHeaderSelectInactive");
        break;
        case 4:
        setStatusClass("newSoftwareHeaderSelectDraft");
        break;
      default:
        setStatusClass("newSoftwareHeaderSelectCreating");
        break;
    }
  }, [selectedRelease.version]);

  const saveBasicInfo = (releaseData) => {
    const data = { ...releaseData, namePublic: releaseData.namePublic ? releaseData.namePublic.trim() : releaseData.nameInternal };
    dispatch(softwareUpdateActions.onSaveReleaseInfo(data));
  };
  
  const fetchRequiredData = useCallback(() => {
    dispatch(softwareUpdateActions.onGetReleaseCompatibleProducts());
    dispatch(softwareUpdateActions.onGetContactsForRelease('All'));
    dispatch(softwareUpdateActions.onGetNotificationsType());
  }, [dispatch]);


  const releaseBasicsProps = (propName, value) => {
    dispatch(softwareUpdateActions.onSelectedReleaseFieldChange({ propName, value }));
  };
  const portalAndContactBasicsProps = ({ propName, value }) => {
    dispatch(
      softwareUpdateActions.onPoratalAndContactFieldChange({ propName, value })
    );
  };

  const tempContactData = (newAddedData) => {

    let key = "";
    switch (newAddedData.generic) {
      case "Company":
        key = "companyIds";
        break;
      case "Business Customer":
      case "End Customer": {
        key = "groupIds";
        break;
      }
      default:
        key = "contactIds";
        break;
    }

    dispatch(
      softwareUpdateActions.updateSelectedReleaseContacts(key, newAddedData.id)
    );

    setUpdatedTempData([...updatedTempData, newAddedData]);
  };

  useEffect(() => {
    if (paramId) {
      const updatedRecords = selectedReleaseContacts.map((item) => {
        const newItem = {
          id: item.contactId
            ? item.contactId
            : item.companyId
            ? item.companyId
            : item.groupId,
          value: item.contactId
            ? `${item.contact.firstName} ${item.contact.lastName}`
            : item.companyId
            ? item.company.name
            : item.group.groupName,
          generic: item.contactId
            ? "Contact"
            : item.companyId
            ? "Company"
            : "Group",
        };
        let key = "";
        if (newItem.generic === "Contact") {
          key = "contactIds";
        } else if (newItem.generic === "Group") {
          key = "groupIds";
        } else {
          key = "companyIds";
        }
        dispatch(
          softwareUpdateActions.updateSelectedReleaseContacts(key, newItem.id)
        );

        return newItem;
      });
      setUpdatedTempData([...updatedRecords]);
    }
  }, [paramId, selectedReleaseContacts]);

  const handleDeletedChip = (removedItem) => {
    let key = "";
    switch (removedItem.generic) {
      case "Company":
        key = "companyIds";
        break;
      case "Group":
      case "Business Customer":
      case "End Customer": {
        key = "groupIds";
        break;
      }
      default:
        key = "contactIds";
        break;
    }

    dispatch(
      softwareUpdateActions.removeSelectedReleaseContacts(key, removedItem.id)
    );

    setUpdatedTempData((prevData) => prevData.filter((item) => item.id !== removedItem.id));
  };

  const tempPortalData = (id) => {
    const newAddedData = filteredProtalData.find((item) => item.id === id);

    dispatch(softwareUpdateActions.onChange("portalIds", [...portalIds, id]));
    dispatch(
      softwareUpdateActions.onChange("selectedReleasePortal", [
        ...selectedReleasePortal,
        newAddedData,
      ])
    );
  };

  const deletePortalSelectedDevices = (item) => {
    const newPortals = selectedReleasePortal.filter(
      (portal) => portal.id !== item.id
    );
    dispatch(
      softwareUpdateActions.onChange(
        "selectedReleasePortal",
        [...newPortals]
      )
    );
    dispatch(softwareUpdateActions.onChange("portalIds", newPortals.map(x => x.id)));
  }

  const onsubmitPortalContact = () => {
    dispatch(
      softwareUpdateActions.onSaveAddUpdateSRPortalandContacts({
        softwareReleaseId: selectedRelease.id,
        contactIds,
        groupIds: groupIds.map((contactId) => Number(contactId)),
        companyIds: companyIds.map((companyId) => Number(companyId)),
        portalIds,
        ...poratlAndContactData,
      })
    );
  };
  
  const changeContactTab = (newTab)=>{
    dispatch(softwareUpdateActions.onGetContactsForRelease(newTab))
  }
  
  const removeEligibleSelectedDevice = (device) => {
    const newDevices = selectedReleaseProducts.filter(
      (x) => x.productId !== device.productId
    );
    dispatch(softwareUpdateActions.onChange("selectedReleaseProducts", [...newDevices]))
  };

  const handleAddEligibleDevice = () => {
    const releaseProducts = selectedReleaseProducts?.map((device) => {
      const productId = parseInt(device.productId);
      return {
        softwareReleaseId: selectedRelease?.id,
        id: device.id ?? 0,
        productId: productId,
        liveVersion: "",
      };
    });
    dispatch(softwareUpdateActions.onAddUpdateReleaseProducts(releaseProducts));    
  };

  const submitNotificationData = (portalNotification) => {
    dispatch(softwareUpdateActions.onAddUpdateSoftwareReleaseNotification(portalNotification));
    setNotificationData({
      id: 0,
      portalId: 0,
      softwareReleaseId: selectedRelease?.id,
      notificationTypeId: 0,
      isImmediate: true,
      afterReleaseValue: 0,
      afterReleaseTimeValue: 1,
      subject: "",
      content: "",
    });
  };

  const handleDeletedReleaseNotification = (id) => {
    dispatch(softwareUpdateActions.onDeleteSoftwareReleaseNotification(id, selectedRelease.id));
  };

  const onSubmitRealsePlan = () => {
    
    dispatch(softwareUpdateActions.onAddSoftwareReleaseDetails(releaseNoteSelectedData));
    // setReleaseNoteSelectedData([]);
  }
  
  const fetchReleaseData = useCallback(() => {
    if (paramId) {
      dispatch(
        softwareUpdateActions.onGetSoftwareReleaseById(paramId)
      );
      dispatch(
        softwareUpdateActions.onGetSoftwareReleaseContacts(paramId)
      );
      dispatch(
        softwareUpdateActions.onGetSoftwareReleasePortal(paramId)
      );
      dispatch(
        softwareUpdateActions.onGetSoftwareReleaseProducts({
          softwareReleaseId: paramId,
          productName: "",
          liveVersion: "",
          brand: "",
          category: "",
          sku: "",
        })
      );
      dispatch(
        softwareUpdateActions.onGetSoftwareReleaseDetails(paramId)
      );
      dispatch(
        softwareUpdateActions.onGetReleaseNotifications(paramId)
      );
    }
  }, [dispatch, paramId]);

  return (
    <Dashbard>
      <div className="body-content newDesign software">
        <div className="contentHeading">
          <div className="newHead">
            <h1>
              {!paramId ? "New Software Release" : selectedRelease.nameInternal}
            </h1>
            <div
              style={{
                position: "absolute",
                top: "40px",
              }}
            >
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  history.push("/software-releases");
                }}
              >
                <ArrowBackIos style={{ fontSize: "14px" }} />
                <span style={buttonTextStyle}>
                  Back to All Software Releases
                </span>
              </Button>
            </div>
            <div className="newHeaderButtonContainer">
              <div>
                <Button
                  variant="outlined"
                  className="skipButton"
                  onClick={() => history.push("/software-releases")}
                >
                  Cancel
                </Button>
              </div>
              <div className={statusClass}>
                <SelectInput
                  placeholder="Select"
                  value={selectedRelease?.version ?? 1}
                  customMenuItems={releaseStatus}
                  disablePlaceholderOption={true}
                  removeSelectOption={true}
                  handleChange={({ target }) =>
                    dispatch(
                      softwareUpdateActions.onSelectedReleaseFieldChange({
                        propName: "version",
                        value: target.value ?? "Creating",
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Grid className={classes.root}>
          <TopTabNavigator
            tabs={[
              {
                id: 1,
                title: "New Software Release",
                component: [
                  !paramId ? (
                    <SoftwareReleaseAccordian
                      selectedRelease={selectedRelease}
                      formName={"Release"}
                      saveBasicInfo={saveBasicInfo}
                      isLoading={isLoading}
                      releaseBasicsProps={releaseBasicsProps}
                      fetchRequiredData={fetchRequiredData}
                      contactIds={contactIds}
                      groupIds={groupIds}
                      companyIds={companyIds}
                      portalIds={portalIds}
                      selectedReleasePortal={selectedReleasePortal}
                      selectedReleaseContacts={selectedReleaseContacts}
                      poratlAndContactData={poratlAndContactData}
                      customerContactData={customerContactData}
                      tempContactData={tempContactData}
                      setUpdatedTempData={setUpdatedTempData}
                      updatedTempData={updatedTempData}
                      handleDeletedChip={handleDeletedChip}
                      filteredProtalData={filteredProtalData}
                      setFilteredProtalData={setFilteredProtalData}
                      tempPortalData={tempPortalData}
                      onsubmitPortalContact={onsubmitPortalContact}
                      productsList={productsList}
                      compatibleProductsList={compatibleProductsList}
                      selectedReleaseProducts={selectedReleaseProducts}
                      removeEligibleSelectedDevice={
                        removeEligibleSelectedDevice
                      }
                      handleAddEligibleDevice={handleAddEligibleDevice}
                      notificationTypes={notificationTypes}
                      selectedReleaseNotifications={
                        selectedReleaseNotifications
                      }
                      softwareReleaseDetials={softwareReleaseDetials}
                      notificationData={notificationData}
                      setNotificationData={setNotificationData}
                      setReleaseNoteSelectedData={setReleaseNoteSelectedData}
                      releaseNoteSelectedData={releaseNoteSelectedData}
                      submitNotificationData={submitNotificationData}
                      handleDeletedReleaseNotification={
                        handleDeletedReleaseNotification
                      }
                      onSubmitRealsePlan={onSubmitRealsePlan}
                      portalAndContactBasicsProps={portalAndContactBasicsProps}
                      portalListData={portalsList}
                      deletePortalSelectedDevices={deletePortalSelectedDevices}
                      changeContactTab={changeContactTab}
                      fetchReleaseData={fetchReleaseData}
                    />
                  ) : (
                    <SoftwareReleaseEdit
                      formName={"Release"}
                      selectedRelease={selectedRelease}
                      fetchRequiredData={fetchRequiredData}
                      softwareReleaseData={softwareReleaseData}
                      saveBasicInfo={saveBasicInfo}
                      isLoading={isLoading}
                      releaseBasicsProps={releaseBasicsProps}
                      contactIds={contactIds}
                      groupIds={groupIds}
                      companyIds={companyIds}
                      portalIds={portalIds}
                      selectedReleasePortal={selectedReleasePortal}
                      selectedReleaseContacts={selectedReleaseContacts}
                      poratlAndContactData={poratlAndContactData}
                      customerContactData={customerContactData}
                      tempContactData={tempContactData}
                      setUpdatedTempData={setUpdatedTempData}
                      updatedTempData={updatedTempData}
                      handleDeletedChip={handleDeletedChip}
                      filteredProtalData={filteredProtalData}
                      setFilteredProtalData={setFilteredProtalData}
                      tempPortalData={tempPortalData}
                      onsubmitPortalContact={onsubmitPortalContact}
                      productsList={productsList}
                      compatibleProductsList={compatibleProductsList}
                      selectedReleaseProducts={selectedReleaseProducts}
                      removeEligibleSelectedDevice={
                        removeEligibleSelectedDevice
                      }
                      handleAddEligibleDevice={handleAddEligibleDevice}
                      notificationTypes={notificationTypes}
                      selectedReleaseNotifications={
                        selectedReleaseNotifications
                      }
                      softwareReleaseDetials={softwareReleaseDetials}
                      notificationData={notificationData}
                      setNotificationData={setNotificationData}
                      setReleaseNoteSelectedData={setReleaseNoteSelectedData}
                      releaseNoteSelectedData={releaseNoteSelectedData}
                      submitNotificationData={submitNotificationData}
                      handleDeletedReleaseNotification={
                        handleDeletedReleaseNotification
                      }
                      onSubmitRealsePlan={onSubmitRealsePlan}
                      portalAndContactBasicsProps={portalAndContactBasicsProps}
                      portalListData={portalsList}
                      deletePortalSelectedDevices={deletePortalSelectedDevices}
                      changeContactTab={changeContactTab}
                      fetchReleaseData={fetchReleaseData}
                    />
                  ),
                ],
              },
            ]}
          />
        </Grid>
      </div>
    </Dashbard>
  );
};

export default NewSoftwareRelease;
