import React from "react";
import { TextValidator } from "react-material-ui-form-validator";

function InputField({
  id,
  inputlabel,
  subtitle,
  placeholder,
  name,
  validators,
  errorMessages,
  value,
  onChange,
  required,
  className,
  onBlur,
  type,
  disabled,
  showRequired,
  onFocus,
  autoFocus,
  isVerified,
  autoComplete,
  readOnly=true,
  onKeyPress = () => {},
  onPaste = () => {},
  ...props
}) {
  return (
    <div className='inputWrapper phoneVarified'>
      {inputlabel && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <label className={`${showRequired ? "showRequired" : ""}`}>
            {inputlabel}
          </label>
          {isVerified ? (
            <span className='productRegistered phoneVerifiedTag'>Verified</span>
          ) : isVerified === false ? (
            <span className='productPending phoneVerifiedTag'>Unverified</span>
          ) : null}
        </div>
      )}
      <TextValidator
        id={id}
        required={required}
        variant='outlined'
        name={name}
        fullWidth
        placeholder={placeholder}
        validators={validators}
        errorMessages={errorMessages}
        value={value}
        onChange={onChange}
        className={className}
        onBlur={onBlur}
        type={type}
        disabled={disabled}
        onFocus={onFocus}
        autoFocus={autoFocus}
        onKeyPress={onKeyPress}
        autoComplete={autoComplete}
        onPaste={event => {
          // e.persist();
          if (!readOnly) {
            event.preventDefault();
          } else if (
            !navigator.userAgent.includes("Chrome") &&
            navigator.userAgent.includes("Safari")
          )
            event.clipboardData.items[1].getAsString(text => {
              onPaste(text);
            });
          else
            event.clipboardData.items[0].getAsString(text => {
              onPaste(text);
            });
        }}
        {...props}
      />
      <span className='inputSubtitle'>{subtitle}</span>
    </div>
  );
}

export default InputField;
