import React, { useState } from "react";
import { Button, Dialog, Checkbox, Input, Grid } from "@material-ui/core";
import ClockPic from "../../../resources/images/timeoutclock.jpg";
import crossImg from "../../../resources/images/cross.png";

const TimeOutPopup = ({ closePopup, openPopup }) => {
  return (
    <div className='assetsDialogueWrapper'>
      <Dialog
        open={openPopup}
        onClose={() => closePopup()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <div className='timoutCloseWrapper'>
          <img src={crossImg} onClick={closePopup} />
        </div>
        <div className='settimeOutWrapper'>
          <span>
            <img src={ClockPic} alt='clock' />
          </span>
          <span>
            <h2>Session timeout soon</h2>
            <p>
              You haven't been active on OFish for 30 minutes. <br /> Your
              session will expire soon.
            </p>
            <p className='timeoutPopupFooter'>
              <i>You will be logged out in 15 minites</i> unless you exit this
              box soon.
            </p>
          </span>
        </div>
      </Dialog>
    </div>
  );
};

export default TimeOutPopup;
