import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  AccessAlarm,
  Add,
  Cancel,
  CheckCircle,
  Delete,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import { GridFilterBar } from "../../ProductInventory/GridFilterBar";
import Dashboard from "../../Dashbard";
import { GreenButton } from "../../../../../components/common";
import { productInventoryActions } from "../../../../../store/product/productInventoryAction";
import { PricingAvailabilityPeriods } from "../../ProductInventory/PricingAvailabilityPeriods";
import Loader from "../../../../../components/common/Loader";
import { PricingListGrid } from "./PricingListGrid";
import { endpoints } from "../../../../../config/apiConfig";

const PriceList = () => {
  document.title = "OFish - Price Lists";

  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isPriceAvailabilitySection, setPriceAvailability] = useState(false);
  const [selectedtTab, setTab] = useState("All");
  const [selectedItems, setSelectedItems] = useState([]);

  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [priceList, setPricingList] = useState([]);

  // pagination states
  const [filters, setFilters] = useState({
    product: "",
    categoryId: null,
    dateCreated: null,
    status: null,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [period, setPeriod] = useState("All");
  const [order, setOrderBy] = useState({
    orderByKey: "",
    orderVal: 0,
  });

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  
  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      productInventoryActions.onGetPricingList({
        period: selectedtTab,
        pageNumber: page,
        orderByKey: order?.orderByKey,
        orderByVal: order?.orderVal,
        noOfRecoreds: rowsPerPage,
        periodName: filters?.periodName,
      })
    ).then(data => {
      setPricingList(data?.data || []);
      if (data?.success) {
        setPaginationResponse(data?.data?.pagination);
      } else {
        if (data?.message?.includes("No Data Found")) {
        } else toast.error("Something went wrong, Please try later.");
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setSelectedItems([]);
      setLoading(false);
    });
  }, [
    dispatch,
    page, 
    rowsPerPage, 
    selectedtTab, 
    filters?.periodName , 
    order.orderByKey,
    order.orderVal
  ]);

  const onDeleteItems = () => {
    setLoading(true);
    dispatch(
      productInventoryActions.onDeleteItemsByIds({
        url: endpoints.priceListTool.deletePriceLists,
        itemIds: selectedItems,
      })
    ).then(res => {
      if (!res?.success) {
        setLoading(false);
        toast.error(res?.message);
      } else {
        toast.success("Pricing list deleted successfully");
        loadData();
      }
    });
  };

  useEffect(() => {
    loadData();
  }, [dispatch, loadData, page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  return (
    <Dashboard>
      <div className='body-content pricing-list'>
        {isLoading ? <Loader /> : null}
        <div className="contentHeading gridBody pd0">
          <h1>Price Lists</h1>
          <span className='btn-span'>
          <Button
                  className="add-price-button"
                  color='primary'
                  variant='contained'
                  onClick={() => history.push("/create-price-list")}
                  startIcon={<Add />}
                // onClick={() => history.push("/new-price-list")}
                >
                  New Price List
                </Button>
          </span>
        </div>
        {!isPriceAvailabilitySection ? (
          <div className='gridBody contentBody product-pricing-container'>
            <div className='pc-header'>
              <span className='filter-span'>
                <GridFilterBar
                  filterBy={filters}
                  onFilterButtonPress={customFilters =>
                    setFilters({
                      periodName: customFilters?.product,
                    })
                  }
                  onClearButtonPress={() =>
                    setFilters({
                      product: "",
                      periodName: "",
                      categoryId: null,
                      dateCreated: null,
                      status: null,
                    })
                  }
                  removeGridFilter={true}
                />
              </span>
              <span>
              <GreenButton
              onClick={() => {
                setPriceAvailability(!isPriceAvailabilitySection);
              }}
              title={"Availability Periods"}
            />
              </span>
            </div>
            {selectedItems.length > 0 ? (
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "15px",
                  }}>
                  <span
                    className='gridDeleteItems'
                    onClick={() => {
                      onDeleteItems();
                    }}>
                    <Delete /> Delete
                  </span>
                </Grid>
              </Grid>
            ) : null}

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={selectedtTab}>
                <Box>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label='lab API tabs example'
                      className="tabindicator">
                      <Tab label='All Pricing' value='All' />
                      <Tab
                        label='Current Pricing'
                        iconPosition='start'
                        icon={
                          <CheckCircle
                            fontSize='small'
                            style={{ color: "#18BC6D" }}
                          />
                        }
                        value='Current'
                      />
                      <Tab
                        label='Upcoming Pricing'
                        iconPosition='start'
                        icon={
                          <AccessAlarm
                            fontSize='small'
                            style={{ color: "#9B51E0" }}
                          />
                        }
                        value='Upcoming'
                      />
                      <Tab
                        iconPosition='start'
                        icon={
                          <Cancel fontSize='small' style={{ color: "#FF3535" }} />
                        }
                        label='Expired Pricing'
                        value='Expired'
                      />
                    </TabList>
                  </Box>
                </Box>
                <TabPanel className='tab-panel-root' value={selectedtTab}>
                  <PricingListGrid
                    listData={priceList}
                    loadData={() => loadData()}
                    order={order}
                    onOrderBy={setOrderBy}
                    setLoading={setLoading}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                  {/* <Pagination
                  recordCount={paginationResponse?.totalRecords}
                  page={page - 1}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                /> */}
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        ) : (
          <PricingAvailabilityPeriods
            onBackClick={() => {
              setPriceAvailability(!isPriceAvailabilitySection);
            }}
          />
        )}
      </div>
    </Dashboard>
  );
};

export { PriceList };
