import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dashboard from "../Dashbard";
import Loader from "../../../../components/common/Loader";
import AddIcon from "@material-ui/icons/Add";
import {
  BusinessContactDataView,
  CompaniesDataView,
  FavBusinessContactDataView,
} from "./components";
import {
  BusinessContactsTabButtons,
  PopupDialog,
} from "../../../../components/common";
import { BusinessGroup } from "./BusinessGroup";
import { Close } from "../../LeftNav/SvgIcons";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { NewCompanyDialog } from "./NewCompanyContacts/NewCompanyDialog";
import BlockedLocationGrid from "./NewCompanyContacts/BlockedLocationGrid";

const AllBusinessContacts = ({ activeTabSection = 0 }) => {
  document.title = "OFish - Business Customers";

  const history = useHistory();

  const [isNewBusinessGroup, setIsNewBusinessGroup] = useState(false);

  const [activeTabId, setActiveTabId] = useState(`${activeTabSection}`);

  const [isLoading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);

  const [isNewContact, setIsNewContact] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setActiveTabId(`${activeTabSection}`);
  }, [activeTabSection]);

  useEffect(() => {
    if (isNewContact) {
      setLoading(true);
      dispatch(endUserActions.onGetContactTypesDDL(2)).then(data => {
        setLoading(false);
        if (!data) return;
        setContactList(data?.data);
      });
    }
  }, [isNewContact]);

  const onClose = () => {
    setIsNewContact(false);
  };

  const RenderSection = memo(() => {
    switch (`${activeTabSection}`) {
      case "0":
        return <BusinessContactDataView />;
      case "1":
        return <CompaniesDataView />;
      case "2":
        return (
          <BusinessGroup
            isNewBusinessGroup={isNewBusinessGroup}
            setIsNewBusinessGroup={setIsNewBusinessGroup}
          />
        );
      case "3":
        return <FavBusinessContactDataView />;
      case "4":
        return <FavBusinessContactDataView isRecentlyAdded />;
      case "5":
        return <BusinessContactDataView isArchived={true} />;
      case "6":
        return <BusinessContactDataView isBlocked={true} />;
      case "7":
        return <BusinessContactDataView isDeleted={true} />;
      case "8":
        return <BlockedLocationGrid />;

      default:
        return <h1>No project match</h1>;
    }
  });

  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>Business Contacts </h1>
          {`${activeTabId}` === "2" ? (
            <Button
              color='primary'
              onClick={() => setIsNewBusinessGroup(!isNewBusinessGroup)}
              startIcon={<AddIcon />}
              variant='contained'>
              New Business Group
            </Button>
          ) : (
            <Button
              color='primary'
              onClick={() => {
                history.push("/new-company-contacts");
              }}
              startIcon={<AddIcon />}
              variant='contained'>
              New Business Contact
            </Button>
          )}
        </div>
        <BusinessContactsTabButtons />
        <RenderSection />

        {isNewContact && (
          <PopupDialog
            visible={isNewContact}
            onClose={onClose}
            maxWidth='800px'>
            <div className='newContactPopupWrapper'>
              <div className='newContactPopupInnerWrapper'>
                <span className='innerHeading'>
                  New Company/Contact <i>Choose a Contact Type</i>
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    padding: "20px",
                    display: "flex",
                  }}
                  onClick={() => onClose()}>
                  {" "}
                  <Close />
                </span>
              </div>

              <NewCompanyDialog contactList={contactList} />
            </div>
          </PopupDialog>
        )}
      </div>
    </Dashboard>
  );
};

export { AllBusinessContacts };
