import React, { useState } from "react";
import { Tab, Paper } from "@material-ui/core";
import { TabList, TabPanel, TabContext } from "@material-ui/lab";
import WaterLifeReport from "./waterLifeReport";
import BarChart from "../EndUser/BarChart";
import Calendar from "../EndUser/Calendar";
import {
  ChemicalIcon,
  FilterLifeIcon,
  HistoryIcon,
  TemperatureIcon,
  WaterLifeIcon,
} from "../../LeftNav/SvgIcons";

const chartLabels = ["1 Aug", "3 Aug", "5 Aug", "7 Aug", "9 Aug", "11 Aug"];
const chartData = [22, 11, 33, 44, 15, 56];

function Reporting() {
  const [active, SetActive] = useState("Usage History");
  const [value, setValue] = useState("ph");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="gridReportingWrapper topSpaceControler">
        <Paper square>
          <div className="reportingHeaderContainer">
            <div className="activeTabsItem">{active}</div>
            <div className="waterTreatmentCalendar">
              <Calendar />
            </div>
          </div>

          <TabContext value={active}>
            <TabList
              className="reportingTabs"
              variant="scrollable"
              onChange={(event, newValue) => {
                SetActive(newValue);
              }}
              textColor="primary"
            >
              <Tab
                label="Use History"
                icon={
                  <HistoryIcon
                    style={{
                      fill: active === "Usage History" ? "#3699FF" : "#E1E3EA",
                    }}
                  />
                }
                value={"Usage History"}
              />
              <Tab
                label="Temperature"
                icon={
                  <TemperatureIcon
                    style={{
                      fill: active === "Temperature" ? "#3699FF" : "#E1E3EA",
                    }}
                  />
                }
                value={"Temperature"}
              />
              <Tab
                label="Chemical"
                icon={
                  <ChemicalIcon
                    style={{
                      fill: active === "Chemical" ? "#3699FF" : "#E1E3EA",
                    }}
                  />
                }
                value={"Chemical"}
              />
              <Tab
                label="Water Life"
                icon={
                  <WaterLifeIcon
                    style={{
                      fill: active === "Water Life" ? "#3699FF" : "#E1E3EA",
                    }}
                  />
                }
                value={"Water Life"}
              />
              <Tab
                label="Filter Life"
                icon={
                  <FilterLifeIcon
                    style={{
                      fill: active === "Filter Life" ? "#3699FF" : "#E1E3EA",
                    }}
                  />
                }
                value={"Filter Life"}
              />
            </TabList>
            <TabPanel value="Usage History" className="reportContent">
              <BarChart labels={chartLabels} barData={chartData} title="Minutes Used" />
            </TabPanel>
            <TabPanel value="Temperature" className="reportContent">
              <BarChart labels={chartLabels} barData={chartData} title="Temperature" />
            </TabPanel>
            <TabPanel value="Chemical" className="reportContent">
              <div className="waterTreatmentTabs">
                <TabContext value={value}>
                  <TabList
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="pH" value="ph" />
                    <Tab label="chlorine" value="chlorine" />
                    <Tab label="Alkalinity" value="alkalinity" />
                  </TabList>
                  <TabPanel value="ph">
                    <div className="borderManager">
                      <BarChart labels={chartLabels} barData={chartData} title="pH Level" />
                    </div>
                  </TabPanel>
                  <TabPanel value="chlorine">
                    <BarChart labels={chartLabels} barData={chartData}  title="Chlorine Level"  />
                  </TabPanel>
                  <TabPanel value="alkalinity">
                    <BarChart labels={chartLabels} barData={chartData}   title="Alkalinity Level" />
                  </TabPanel>
                </TabContext>
              </div>
            </TabPanel>
            <TabPanel value="Water Life" className="reportContent">
              <BarChart labels={chartLabels} barData={chartData}  title="pH Level"  />
            </TabPanel>
            <TabPanel value="Filter Life" className="reportContent">
              <BarChart labels={chartLabels} barData={chartData}  title="pH Level"  />
            </TabPanel>
          </TabContext>
        </Paper>
      </div>
    </>
  );
}

export default Reporting;
