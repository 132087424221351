// import { productActionTypes } from "./productActionTypes";
import { productInventoryApi } from "./productInventoryApi";

const onGetAllProducts =
  ({ pageNumber, noOfRecoreds, orderByKey, orderByVal, filters }) =>
  async dispatch => {
    return await productInventoryApi.getAllProducts({
      pageNumber,
      noOfRecoreds,
      orderByKey,
      orderByVal,
      filters,
    });
  };

  const onGetAllFavouriteProducts =
  () =>
  async dispatch => {
    return await productInventoryApi.getAllFavouriteProducts();
  };
  const onGetAllUserProducts =
  () =>
  async dispatch => {
    return await productInventoryApi.getAllUserProducts();
  };

const getProductById = id => async dispatch => {
  return await productInventoryApi.getProductById(id);
};

const onAddUpdateProduct = data => async dispatch => {
  return await productInventoryApi.addupdateProduct(data);
};

const onDeleteProducts = ids => async dispatch => {
  return await productInventoryApi.deleteProducts(ids);
};

//#region Serials/Devices
const onGetSerializedDevices =
  ({
    isSerialzed,
    productId,
    pageNumber,
    noOfRecoreds,
    orderByKey,
    orderByVal,
    filters,
  }) =>
  async dispatch => {
    return await productInventoryApi.getSerialzedDevices({
      isSerialzed,
      productId,
      pageNumber,
      noOfRecoreds,
      orderByKey,
      orderByVal,
      filters,
    });
  };

const onGetBlockedSerials =
  ({ pageNumber, noOfRecoreds, orderByKey, orderByVal, filters }) =>
  async dispatch => {
    return await productInventoryApi.getBlockedSerials({
      pageNumber,
      noOfRecoreds,
      orderByKey,
      orderByVal,
      filters,
    });
  };
//#endregion

//#region Support Resources
const onGetProductRegistrationByProductId = id => async dispatch => {
  return await productInventoryApi.getProductRegistrationByProductId(id);
};

const onAddUpdateProductRegistration = data => async dispatch => {
  return await productInventoryApi.addupdateProductRegistration(data);
};

const onGetProductReturnByProductId = id => async dispatch => {
  return await productInventoryApi.getProductReturnByProductId(id);
};

const onAddUpdateProductReturn = data => async dispatch => {
  return await productInventoryApi.addupdateProductReturn(data);
};

const onGetEndCustomerGroups = () => async dispatch => {
  return await productInventoryApi.getEndCustomerGroups({
    CustomerTypeName: "EndCustomer",
  });
};

const onGetCustomerGroups = (customerType) => async dispatch => {
  return await productInventoryApi.getEndCustomerGroups({
    CustomerTypeName: customerType,
  });
};

const onGetBusinessCustomerGroups = () => async dispatch => {
  return await productInventoryApi.getEndCustomerGroups({
    CustomerTypeName: "BusinessCustomer",
  });
};

const onGetProdSupportResources = pId => async dispatch => {
  return await productInventoryApi.getProdSupportResources({
    id: pId,
  });
};

const onAddProductSupportResources = data => async dispatch => {
  return await productInventoryApi.addProductSupportResources(data);
};
//#endregion

const onGetProductActivities = pId => async dispatch => {
  return await productInventoryApi.getProductActivities({
    productId: pId,
  });
};

const onGetProductNotes = pId => async dispatch => {
  return await productInventoryApi.getProductNotes({
    productId: pId,
  });
};

const onAddProductNotes = note => async dispatch => {
  return await productInventoryApi.addProductNotes(note);
};

const onDeleteProductNotes = noteId => async dispatch => {
  return await productInventoryApi.deleteProductNotes(noteId);
};

const onGetPricingAvailabilityPeriods =
  ({ pageNumber, noOfRecoreds, orderByKey, orderByVal, filters }) =>
  async dispatch => {
    return await productInventoryApi.getPricingAvailabilityPeriods({
      pageNumber,
      noOfRecoreds,
      orderByKey,
      orderByVal,
      filters,
    });
  };

const onAddUpdateAvailabilityPeriod = data => async dispatch => {
  return await productInventoryApi.addUpdateAvailabilityPeriod(data);
};

const onGetPricingByProductId =
  ({ productId, period }) =>
  async dispatch => {
    return await productInventoryApi.getPricingByProductId({
      productId,
      period,
    });
  };

  const onAddUpdateBulkProductPricing = (data) => async dispatch => {
    return await productInventoryApi.addUpdateBulkProductPricing(data);
  };

  const onAddFavoriteProduct =
  ({ productId}) =>
  async dispatch => {
    return await productInventoryApi.addFavoriteProduct({
      productId,
    });
  };

const onAddUpdateProductPricing = data => async dispatch => {
  return await productInventoryApi.addUpdateProductPricing(data);
};

const onLoadAllAvailabilityTimes = () => async dispatch => {
  return productInventoryApi.getAllAvailableTimes();
};

const onGetProductPricingGroup =
  ({ productId }) =>
  async dispatch => {
    return await productInventoryApi.getProductPricingGroup({
      productId,
    });
  };

const onGetProductDescriptionByProduct = payload => async dispatch => {
  return await productInventoryApi.getProductDescriptionByProduct(payload);
};

const onAddUpdateProductDescription =
  ({ url, payload }) =>
  async dispatch => {
    return await productInventoryApi.addUpdateProductDescription({
      url,
      payload,
    });
  };

const onGetProductPriceDescriptionByProduct = payload => async dispatch => {
  return await productInventoryApi.getProductPriceDescriptionByProduct(payload);
};

// Price List Tools
const onGetPricingList =
  ({ pageNumber, noOfRecoreds, period, periodName , orderByKey , orderByVal  }) =>
  async dispatch => {
    return await productInventoryApi.getPricingList({
      pageNumber,
      noOfRecoreds,
      period,
      periodName,
      orderByKey,
      orderByVal,
    });
  };

const onGetPriceListById = id => async dispatch => {
  return await productInventoryApi.getPriceListById(id);
};

const onLoadData =
  ({ url, payload, config = null }) =>
  async dispatch => {
    if (payload)
      return await productInventoryApi.loadData({ url, payload, config });
    else return await productInventoryApi.loadDataGet({ url });
  };

const onDeleteItemsByIds =
  ({ url, itemIds }) =>
  async dispatch => {
    return await productInventoryApi.deleteItemsByIds({ url, itemIds });
  };

export const productInventoryActions = {
  onGetAllProducts: onGetAllProducts,
  getProductById: getProductById,
  onAddUpdateProduct: onAddUpdateProduct,
  onDeleteProducts: onDeleteProducts,
  onGetProductActivities: onGetProductActivities,
  onGetProductNotes: onGetProductNotes,
  onAddProductNotes: onAddProductNotes,
  onDeleteProductNotes: onDeleteProductNotes,
  onGetSerializedDevices: onGetSerializedDevices,
  onGetBlockedSerials: onGetBlockedSerials,
  onGetProductRegistrationByProductId: onGetProductRegistrationByProductId,
  onAddUpdateProductRegistration: onAddUpdateProductRegistration,
  onGetProductReturnByProductId: onGetProductReturnByProductId,
  onAddUpdateProductReturn: onAddUpdateProductReturn,
  onGetEndCustomerGroups: onGetEndCustomerGroups,
  onGetCustomerGroups: onGetCustomerGroups,
  onGetBusinessCustomerGroups: onGetBusinessCustomerGroups,
  onGetProdSupportResources: onGetProdSupportResources,
  onAddProductSupportResources: onAddProductSupportResources,
  onGetPricingAvailabilityPeriods: onGetPricingAvailabilityPeriods,
  onAddUpdateAvailabilityPeriod: onAddUpdateAvailabilityPeriod,
  onGetPricingByProductId: onGetPricingByProductId,
  onAddFavoriteProduct:   onAddFavoriteProduct, 
  onAddUpdateProductPricing: onAddUpdateProductPricing,
  onGetProductPricingGroup: onGetProductPricingGroup,
  onGetProductDescriptionByProduct: onGetProductDescriptionByProduct,
  onAddUpdateProductDescription: onAddUpdateProductDescription,
  onGetProductPriceDescriptionByProduct: onGetProductPriceDescriptionByProduct,
  onLoadData: onLoadData,
  onGetPricingList: onGetPricingList,
  onGetPriceListById: onGetPriceListById,
  onDeleteItemsByIds: onDeleteItemsByIds,
  onLoadAllAvailabilityTimes: onLoadAllAvailabilityTimes,
  onGetAllFavouriteProducts: onGetAllFavouriteProducts,
  onGetAllUserProducts: onGetAllUserProducts,
  onAddUpdateBulkProductPricing: onAddUpdateBulkProductPricing,
};
