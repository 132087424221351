import {
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SoftwareGridFilter from "./SoftwareGridFilter";
import { ChevronRight } from "../../LeftNav/SvgIcons";
import RadioButtons from "../../../../components/common/RadioButton/RadioButton";
import { ReleaseDate } from "./ReleaseDate";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  notificationConatiner: {
    width: "100%",
    marginTop: "28px",
    marginBottom: "28px",
    display: "flex",
    justifyContent: "center",
  },
  notifictionText: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#000",
  },
  notifictionTextColor: {
    color: "#3699FF",
    cursor: "pointer",
  },

});

export const ReleasePlanPreview = ({
  buildId,
  notificationTypes,
  softwareReleaseDetials,
  selectedReleasePortal,
  selectedReleaseNotifications,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const [notifications, setNotifications] = useState([]);
  const [notificationFilter, setNotificationFilter] = useState("")
  const [selectedNotificationItem, setSelectedNotificationItem] = useState(3);
  const defaultItemsToShow = 3;
  const [selectedData, setSelectedData] = useState("");
  const handlePortalRequirements = () => {
    setSelectedNotificationItem((prevItems) =>
      prevItems === defaultItemsToShow
        ? filteredSelectedNotifications.length
        : defaultItemsToShow
    );
  };

  const filteredSelectedNotifications = notifications.filter(
    (X) =>
      X?.name?.toLowerCase().includes(notificationFilter.toLocaleLowerCase()) ||
      X?.type?.toLowerCase().includes(notificationFilter.toLocaleLowerCase())
  );

  useEffect(() => {
    if (selectedReleaseNotifications.length > 0 && notificationTypes.length > 0) {
      setNotifications(() => {
        return selectedReleaseNotifications.map((notification) => {
          const notificationType = notificationTypes.find(type => type.id === notification.notificationTypeId);
          return {
            ...notification,
            name: notificationType.name,
            type: notificationType.type,
          };
        })
      });
    }
  }, [selectedReleaseNotifications, notificationTypes]);

  useEffect(() => {
    if (softwareReleaseDetials.length) {
      const releaseDetails = softwareReleaseDetials[0];
      if (releaseDetails?.releaseDateForAll) {
        setSelectedData({
          ...releaseDetails,
          softwareReleasePortalDetails: [],
        });
      } else {
        setSelectedData({
          ...releaseDetails,
          releaseDate: "",
          releaseTime: "",
        });
      }
    }

  }, [id, softwareReleaseDetials]);

  return (
    <Grid className="releaseConatiner">
      <h2>{buildId ? "Build" : "Release"} Plan</h2>
      <h3>{buildId ? "Build" : "Release"} Details</h3>
      <h4 className="updateMethod">
        {buildId ? "Build" : "Release"} Date and Time
      </h4>
      {selectedData && (
        <>
          <Grid item className="software_checked">
            <Grid>
              <Grid item className="grid_checkbox forcreUpdate">
                <RadioButtons
                  label={"Yes"}
                  value="1"
                  selectedValue={selectedData?.releaseDateForAll === true}
                  // handleChange={() =>  }
                  disabled={id}
                />
                <List>
                  <ListItem className="radioBtntLable">
                    {buildId ? "Build" : "Release"} software on all portals at
                  </ListItem>
                </List>
              </Grid>
              <ReleaseDate
                disabled={id}
                value={selectedData?.releaseDate}
                timeValue={selectedData.releaseTime}
                id={0}
              />
              <Grid item className="grid_checkbox  forcreUpdate">
                <Grid className="releaseDateContainer">
                  <RadioButtons
                    label={"No"}
                    value="2"
                    selectedValue={selectedData?.releaseDateForAll === false}
                    disabled={id}
                  />
                </Grid>
                <List>
                  <ListItem className="radioBtntLable">
                    {buildId ? "Build" : "Release"} software on specific portals
                    at certain times
                  </ListItem>
                </List>
              </Grid>
              {selectedReleasePortal.map((portal) => (
                <Grid className="deviceContainer">
                  <span className="deviceBox">{portal.name}</span>
                  <ReleaseDate
                    disabled={id}
                    value={
                      selectedData?.softwareReleasePortalDetails?.find(
                        (x) => x.softwareReleasePortalId == portal.id
                      )?.releaseDate
                    }
                    timeValue={
                      selectedData?.softwareReleasePortalDetails?.find(
                        (x) => x.softwareReleasePortalId == portal.id
                      )?.releaseTime
                    }
                    id={portal.id}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <h4 className="updateMethod">{buildId ? "Build" : "Release"} Notes</h4>
          {selectedData?.releaseNotes.length > 0 ? (
            <Grid className="releaseNoteDetails">
              <span>{selectedData?.releaseNotes}</span>
            </Grid>
          ) : null}
        </>
      )}

      <h3 className="notifcationText">
        Software {buildId ? "Build" : "Release"} Notifications
      </h3>
      {selectedReleaseNotifications.length > 0 ? (
        <>
          <Typography className="notifcationAppliedText">
            {selectedReleaseNotifications?.length ?? 0} Notifications Applied{" "}
          </Typography>
          <Grid className="searchContainer">
            <SoftwareGridFilter
              search={notificationFilter}
              setSearch={setNotificationFilter}
            />
          </Grid>
          <Grid className="useRequirementsChips">
            <Grid className="chevronIcon">
              <IconButton onClick={handlePortalRequirements}>
                <ChevronRight />
              </IconButton>
            </Grid>
            <Grid className="portalItemGrid">
              {filteredSelectedNotifications
                .slice(0, selectedNotificationItem)
                .map((item) => (
                  <Grid className="portalChips">
                    <Typography className="chipsTypo">{item.name}</Typography>
                    &nbsp;
                    <Typography className="deviceName">{item.type}</Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid className={classes.notificationConatiner}>
          <span className={classes.notifictionText}>
            You haven’t added any notifications. Start by adding a{" "}
            <span className={classes.notifictionTextColor}>Notification</span>
          </span>
        </Grid>
      )}
    </Grid>
  );
};
