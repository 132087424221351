import React from "react";

import Grid from "@material-ui/core/Grid";

import { useSelector } from "react-redux";
import {
  WaterTreatmentReplace,
  WaterTreatmentTest,
  WaterTreatmentReplaced,
} from "./SvgIcons";
import { Typography } from "@material-ui/core";
import { TempratureInputGradient } from "./TempratureInputGradient";

export default function SpaWaterTreatment({
  isActive,
  onClickMange,
  activeTab,
}) {
  const {
    filterLifeStats,
    chemicalTestStats , 
    waterLifeStats
  } = useSelector((state) => state.endUserReducer);

  return (
    <Grid item md={12} className="gridMainSpaWrapper">
        <Grid className="gridRow watertreatmentstyle">
          <Grid className="gridSpaWaterTreatmentText">
            <span className="pumpsHeading">Water Treatment</span>
          </Grid>
        </Grid>
        <Grid className="spawateritems">
          <div className="waterTreatmentDetails">
            <WaterTreatmentReplace />
            <h4>{filterLifeStats?.replacedDays}</h4>
            <Typography>
              Days <br></br>since<br></br> replace
            </Typography>
          </div>
          <div className="waterTreatmentDetails">
            <WaterTreatmentTest />
            <h4>{chemicalTestStats?.replacedDays ?? "--"}</h4>
            <Typography>
              Days<br></br>since <br></br>test
            </Typography>
          </div>
          <div className="waterTreatmentDetails">
            <WaterTreatmentReplaced />
            <h4>{waterLifeStats?.replacedDays}</h4>
            <Typography>
              Days <br></br>since <br></br>replaced
            </Typography>
          </div>
        </Grid>
        <div className="gradientComponent">
          <TempratureInputGradient />
        </div>
      </Grid>
  );
}
