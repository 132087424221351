import React, { useRef } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { InputField } from "../../../../../components/common";
import { searchIcon } from "../../../../../resources/images";

const SelectedProductGrid = ({ search, onSearch, activeTabId, loading }) => {

  return (
    <div className="selecteProductdGird">
      <ValidatorForm ref={useRef("form")}>
        <Grid className="filterMainSearch">
        {loading ? (
             <CircularProgress className="customerLoderSearchIcon" />
          ):
          <img
            src={searchIcon}
            alt="searchIcon"
            className="customerSearchIcon"
          />
        }
          <InputField
            placeholder="Search"
            value={search}
            onChange={(event) => onSearch(event, activeTabId)}
          />

        </Grid>
      </ValidatorForm>
    </div>
  );
};

export default SelectedProductGrid;
