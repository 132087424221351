import React, { useState, useEffect, useCallback } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dashboard from "../Dashbard";
import ReleaseCategoryGrid from "./ReleaseCategoryGrid";
import AddCategoryPopup from "../../../../components/common/popUps/AddCategoryPopup";
import Loader from "../../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { Pagination } from "../../../../components/common";
import { GridFilterBar } from "./GridFilterBar";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";

function ReleaseCategory(props) {
  const [releaseCategories, setReleaseCategories] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByName, setFilterByName] = useState("");
  const [order, setOrderBy] = useState({
    orderByKey: "Name",
    orderVal: 0
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const loadReleaseCategory = useCallback(() => {
    setLoading(true);
    const modal = {
      name: filterByName,
      noOfRelease: null,
      createdOn: null,
      color: null,
      isPublic: null,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal
      }
    };
    dispatch(launchesActions.onGetReleaseCategory(modal)).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          const releaseList = data.data.data.map(x => {
            return {
              ...x,
              checked: false,
              isOpen: false
            };
          });
          setPaginationResponse(data?.data?.pagination);
          setReleaseCategories(releaseList);
          var count = releaseList.find(x => x.checked === true);
          setIsDelete(count ? true : false);
        }
      }
      setLoading(false);
    });
  }, [
    dispatch,
    page,
    rowsPerPage,
    filterByName,
    order.orderByKey,
    order.orderVal
  ]);

  useEffect(() => {
    loadReleaseCategory();
  }, [
    dispatch,
    page,
    rowsPerPage,
    filterByName,
    order.orderByKey,
    order.orderVal
  ]);

  const redirectUrl = () => {
    history.push("/new-release-category");
  };

  const checkEventGroupsSelected = item => {
    releaseCategories.map(x => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    var count = releaseCategories.find(x => x.checked === true);
    setIsDelete(count ? true : false);
    setReleaseCategories([...releaseCategories], releaseCategories);
  };

  const checkChildRecord = item => {
    releaseCategories.map(x => {
      if (x.id === item.id) {
        x.isOpen = !item.isOpen;
      }
    });
    setReleaseCategories([...releaseCategories], releaseCategories);
  };

  return (
    <Dashboard>
      <div className="body-content newDesign">
        {isLoading ? <Loader /> : null}
        <div className="contentHeading  launchBtn">
          <h1>Release Categories</h1>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={redirectUrl}>
            New Category
          </Button>
        </div>


        <TopTabNavigator 
        tabs = {[
          {
            id: 1,
            title: "All Releases",
            component: [ <ReleaseCategoryGrid
              releaseCategoryData={releaseCategories}
              startLoading={() => setLoading(true)}
              endLoading={() => setLoading(false)}
              loadData={() => loadReleaseCategory()}
              selectedRecord={item => {
                checkEventGroupsSelected(item);
              }}
              childRecord={item => {
                checkChildRecord(item);
              }}
              isDelete={isDelete}
              order={order}
              onOrderBy={setOrderBy}
            />,
            <div style={{width:"100%"}}>
            <Pagination
              recordCount={paginationResponse?.totalRecords}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
            </div>
            ]
          },
          {
            id: 2,
            title: "Upcoming Releases"
          },
          {
            id: 3,
            title: "Past Releases"
          },
          {
            id: 4,
            title: "Deleted"
          },
          {
            id: 5,
            title: "Release Category"
          },
        ]}
        
        />


        {/* <div className="gridBody contentBody"> */}
          {/* <GridFilterBar
            filterByName={filterByName}
            setFilterByName={setFilterByName}
          /> */}
          {/* <ReleaseCategoryGrid
            releaseCategoryData={releaseCategories}
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadReleaseCategory()}
            selectedRecord={item => {
              checkEventGroupsSelected(item);
            }}
            childRecord={item => {
              checkChildRecord(item);
            }}
            isDelete={isDelete}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          /> */}
        {/* </div> */}
      </div>
    </Dashboard>
  );
}

export default ReleaseCategory;
