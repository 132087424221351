import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { withStyles } from "@material-ui/core/styles";
import { DefaultStepper } from "../../../ofishadmin/views/Dashboard/SpaProduct/SvgIcons";
import { ActiveStepper } from "../../../ofishadmin/views/Dashboard/SpaProduct/SvgIcons";
import { InactiveStepper } from "../../../ofishadmin/views/Dashboard/SpaProduct/SvgIcons";
import { Typography } from "@material-ui/core";

const StepIconWithCustomIcon = withStyles((theme) => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    position: "relative",
    zIndex: 999, // Set zIndex to 999
  },
  active: {
    color: "#007bff",
  },
  completed: {
    color: "#4caf50",
  },
  line: {
    content: "''",
    position: "absolute",
    top: "23%",
    width: "100%",
    left: 30,
    right: 0,
    borderTop: `2px solid #007bff`,
    // zIndex: 1,
  },
  lineDefault: {
    content: "''",
    position: "absolute",
    top: "23%",
    width: "100%",
    left: 30,
    right: 0,
    borderTop: `2px solid #D1D5DB`,
    // zIndex: 1,
  },
  svgCircle:{
    background:"#fff"
  },

}))(({ classes, active, completed, index }) => {
  let iconComponent;

  if (completed) {
    iconComponent = <ActiveStepper />;
  } else if (active) {
    iconComponent = <DefaultStepper />;
  } else {
    iconComponent = <InactiveStepper />;
  }

  return (
    <div
      className={
        active || completed ? "MuiStepIcon-active" : "MuiStepIcon-inactive"
      }
    >
      <div className={classes.svgCircle}>{iconComponent}</div>
      {(active || completed) && index < 2 ? (
        <div className={classes.line} />
      ) : null}
      {!active && !completed && index < 2 ? (
        <div className={classes.lineDefault} />
      ) : null}
    </div>
  );
});

export const StepperComponent = ({ steps,activeStep }) => {
  return (
    <div className="commonStepper">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          return<Step key={label}>
            <StepIconWithCustomIcon
              completed={index < activeStep}
              active={index === activeStep}
              index={index}
            />
            <Typography className="lableStyle">{label}</Typography>
          </Step>
        })}
      </Stepper>
    </div>
  );
};
