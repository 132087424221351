import React, { useCallback, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import Dashboard from "../Dashbard";
import ReleaseCategoryGrid from "./ReleaseCategoryGrid";
import AddCategoryPopup from "../../../../components/common/popUps/AddCategoryPopup";
import { useHistory } from "react-router";
import { ReleaseGridSideFilter } from "./ReleaseGridSideFilter";
import AllReleaseGrid from "./AllReleaseGrid";
import { Pagination } from "../../../../components/common";
import { useDispatch } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import Loader from "../../../../components/common/Loader";

function UpComingReleases(props) {
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  document.title = "OFish - Releases";
  const redirectUrl = () => {
    history.push("/new-media-release");
  };
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByName, setFilterByName] = useState(null);
  const [selectedCategoryIds, setSelectedCategory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(0);
  const [releasesData, setReleasessData] = useState([]);
  const [pastReleasesData, setPastReleasessData] = useState([]);
  const [upcomingReleasesData, setUpcomingReleasessData] = useState([]);
  const [releaseCategory, setReleaseCategory] = useState([]);
  const [order, setOrderBy] = useState({
    orderByKey: "Title",
    orderVal: 0,
  });

  const loadAllReleases = useCallback(() => {
    setLoading(true);
    dispatch(launchesActions.onAllSelectReleases([]));
    const modal = {
      title: filterByName,
      announcementDate: null,
      type:"upcoming",
      category: null,
      status: 0,
      date: selectedDate,
      categoryIds: selectedCategoryIds === null ? [] : selectedCategoryIds,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    dispatch(launchesActions.onGetAllReleases(modal)).then(data => {
      if (data?.success) {
        if (data.data?.data) {
          setReleasessData(data?.data?.data);
          setPaginationResponse(data?.data?.pagination);
          setLoading(false);
        }
      }
    });
  }, [
    dispatch,
    page,
    rowsPerPage,
    order.orderByKey,
    order.orderVal,
    filterByName,
    selectedCategoryIds,
    selectedDate,
  ]);

  useEffect(() => {
    loadAllReleases();
    window.scrollTo(0, 0);
  }, [
    page,
    rowsPerPage,
    filterByName,
    selectedCategoryIds,
    order.orderByKey,
    order.orderVal,
    selectedDate,
  ]);

  useEffect(() => {
    dispatch(launchesActions.onGetReleaseCategories()).then(res => {
      if (res && res.success && res.data && res.data.length > 0) {
        const catList = res.data.map((x, index) => {
          return {
            id: x.id,
            value: x.value,
            generic: x.generic,
            checked: false,
          };
        });
        setReleaseCategory(catList);
      }
    });
  }, []);
  return (
    <Dashboard>
      {isLoading ? <Loader /> : null}
      <div className='body-content'>
        <div className='contentHeading  launchBtn'>
          <h1>Upcoming Releases </h1>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => history.push("/new-media-release")}>
            New Release
          </Button>
        </div>

        {/* <div className='contentBody'>
          {/* <ReleaseCategoryGrid /> 
          {isModal && (
            <AddCategoryPopup
              openPopup={isModal}
              closePopup={() => setIsModal(false)}
            />
          )}
        </div> */}
        <div className='gridBody contentBody '>
          <ReleaseGridSideFilter
          Categories={releaseCategory}
          setFilterByName={setFilterByName}
          setSelectedCategory={setSelectedCategory}
          setSelectedDate={setSelectedDate}
          />
          <AllReleaseGrid
          allReleaseData={releasesData}
          startLoading={() => setLoading(true)}
          endLoading={() => setLoading(false)}
          loadData={() => loadAllReleases()}
          order={order}
          onOrderBy={setOrderBy}
          />
          <div style={{ width: "100%" }}>
            <Pagination
            recordCount={paginationResponse?.totalRecords ? paginationResponse?.totalRecords : 0 }
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default UpComingReleases;
