import React, { useState } from "react";
import { Search } from "@material-ui/icons";
import { Dialog, Grid, Typography } from "@material-ui/core";
import { TextField, InputAdornment } from "@material-ui/core";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { ReactComponent as Cross } from "../../../../resources/images/Close.svg";
import DataFields from "./DataFields";
import ContactModules from "./ContactModulesFields";
import OFishConnections from "./OFishConnections";
import FavoritesData from "./FavoritesData";

export default function AddContactDataModal({
  open,
  setOpen,
  dataFieldsId,
  setDataFieldsId,
  areaId,
}) {
  const handleClose = () => setOpen(false);
  const [searchText, setSearchText] = useState("");
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className='contactDataDialogue contactDataWrapper'
        style={{ maxWidth: "100% !important", marginBottom: "5%" }}>
        <div className='addContactDataHeading'>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Add Contact Data
          </Typography>
          <Cross
            style={{
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </div>
        <div className='contentDiv'>
          <h1 className='findContactDataHeading'>Find Contact Data</h1>
          <p className='addContactDes'>
            You can find and add various types of data to your OFish Contact.
            Select from OFish Data Fields such as Account Basics, OFish Modules
            such as OFish Spaces and Products. You will be able to create your
            own fields soon. Confused? You can check our help articles on the
            capabilities of all fields. Learn More
          </p>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant='outlined'
                placeholder='Search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={e => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
                style={{ top: "20px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className='tab-navigator'>
                <TopTabNavigator
                  tabs={[
                    {
                      id: "1",
                      title: "Your Favorites",
                      component: (
                        <FavoritesData
                          searchText={searchText}
                          areaId={areaId}
                        />
                      ),
                    },
                    {
                      id: "2",
                      title: "Custom Fields",
                      component: <>Comming soon</>,
                    },
                    {
                      id: "3",
                      title: "OFish Data Fields",
                      component: (
                        <DataFields
                          dataFieldsId={dataFieldsId}
                          setDataFieldsId={setDataFieldsId}
                          searchText={searchText}
                          areaId={areaId}
                        />
                      ),
                    },
                    {
                      id: "4",
                      title: "OFish Modules",
                      component: (
                        <ContactModules
                          searchText={searchText}
                          areaId={areaId}
                        />
                      ),
                    },
                    {
                      id: "5",
                      title: "OFish Connections",
                      component: (
                        <OFishConnections
                          searchText={searchText}
                          areaId={areaId}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
}
