import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton, Typography, TextField } from "@material-ui/core";
import {
  Close,
} from "../../LeftNav/SvgIcons";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  EndUserPaper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "6px",
    width: "1100px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 80,
    borderBottom: "2px solid #E2E5E9",
    paddingRight: 20,
    paddingLeft: 28,
    background: "#00A3FF",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  newPricingTypo: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#fff",
  },
  inenerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Roboto",
    paddingLeft: 16,
    color: "#464E5F",
  },
  ownerInput: {
    width: "691px",
    marginBottom: "10px",
  },
  content: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Roboto",
    paddingLeft: 16,
    color: "#3699FF",
    paddingRight: 19,
    letterSpacing: "-0.01em",
  },
  newOwner: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: " -0.02em",
    textAlign: "left",
    marginBottom:'8px',
  },
  endUserContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 50,
    fontSize: 22,
    fontWeight: 700,
    fontFamily: "Roboto",
    color: "#181C32",
  },
  inputControler: {
    display: "flex",
    flexDirection: "column",
    marginBottom:'8px',
  },
  ownerShipBtns: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  hyperLink: {
    textDecoration: "none",
  },
  TypographyTxt:{
    fontSize: '14px',
    margin: '8px 0px',
  }
}));
export const EndUserCustomersModal = ({
  open,
  enduserModal,
  isNewProductPricing,
  isOwnership,
  setIsOwnership,
  hideProductDetail,
  devId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const onDeleteDevice = () => {
    dispatch(endUserActions.onDeleteDevice(devId)).then(data => {
      if (data && data.success) {
        hideProductDetail()
        toast.success("Delete Serial Successfully");
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };

  const onTransferDevice = () => {
    dispatch(endUserActions.onTransferOwnershipDevice({ email, deviceId: devId})).then(data => {
      if (data && data.success) {
        toast.success("Transfer request sent.");
        setEmail("");
        enduserModal();
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={enduserModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.EndUserPaper}>
            <div className={classes.header}>
              <div className={classes.inenerContent}>
                <Typography className={classes.newPricingTypo}>
                  {!isNewProductPricing ? "Remove Device" : "Transfer Ownership"}
                </Typography>
              </div>
              <IconButton className={classes.closIcon} onClick={enduserModal}>
                <Close />
              </IconButton>
            </div>
            <div className="transferOnwershipModalContainer">
            {!isNewProductPricing ? (
              <>
                <div className={classes.endUserContent}>
                  <span>
                    Are you sure you want to remove device?
                  </span>
                  <Typography className={classes.TypographyTxt}>
                    Make sure you’re in touch with them about your actions.
                  </Typography>
                  <Button
                    className="removeOwnerShip"
                    onClick={() => onDeleteDevice()}
                  >
                    Remove Device
                  </Button>
                </div>
              </>
            ) : null}
            {isNewProductPricing && isOwnership ? (
              <div className={classes.endUserContent}>
                <span>
                  Want to play it safer? Have transfer ownership.
                </span>
                <Typography className={classes.TypographyTxt}>
                  Allow the end user to transfer ownership of this device to
                  another user.
                </Typography>
                <Button
                  variant="contained"
                  className="transferOnwershipModalBtn"
                  onClick={() => setIsOwnership(!isOwnership)}
                >
                  Transfer Ownership
                </Button>
              </div>
            ) : null}
            {!isOwnership ? (
              <>
                <div className={classes.endUserContent}>
                  <span>
                    Enter the new owner’s email below to transfer device
                    ownership.
                  </span>
                  <Typography className={classes.TypographyTxt}>
                    Make sure you’re in touch with them about your actions.
                  </Typography>
                  <div className={classes.inputControler}>
                    <span className={classes.newOwner}>New Owner’s Email</span>
                    <TextField
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      id="outlined-basic"
                      variant="outlined"
                      className={classes.ownerInput}
                    />
                  </div>
                  <div className={classes.ownerShipBtns}>
                    <Button
                      variant="contained"
                      style={{ background: "#BDBDBD", color: "#fff" }}
                      onClick={() => setIsOwnership(!isOwnership)}
                    >
                      Cancel
                    </Button>
                    {/* <Button className="confirmTransferOwnerShipModalBtn" variant="contained" color="Primary" onClick={onTransferDevice}>
                      Transfer Ownership
                    </Button> */}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
