import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox } from "@material-ui/core";
import queryString from "query-string";
import hours from "../../../../../resources/businessHoursTime";
import {closingHours} from "../../../../../resources/businessHoursTime";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
    fontWeight: "700",
    flex: "1",
  },
}));

function CompanyBusinessHours({ businessHours, setBusinessHours, onClose }) {
  const classes = useStyles();
  const location = useLocation();
  const selectedData = queryString.parse(location.search);
  const [companyBusinessHours, setCompanyBusinessHours] = useState([
    {
      id: 0,
      day: "Monday",
      openingTime: "",
      closingTime: "",
      closed: false,
      companyId: parseInt(selectedData?.id),
      companyLocationId: 0,
    },
    {
      id: 0,
      day: "Tuesday",
      openingTime: "",
      closingTime: "",
      closed: false,
      companyId: parseInt(selectedData?.id),
      companyLocationId: 0,
    },
    {
      id: 0,
      day: "Wednesday",
      openingTime: "",
      closingTime: "",
      closed: false,
      companyId: parseInt(selectedData?.id),
      companyLocationId: 0,
    },
    {
      id: 0,
      day: "Thursday",
      openingTime: "",
      closingTime: "",
      closed: false,
      companyId: parseInt(selectedData?.id),
      companyLocationId: 0,
    },
    {
      id: 0,
      day: "Friday",
      openingTime: "",
      closingTime: "",
      closed: false,
      companyId: parseInt(selectedData?.id),
      companyLocationId: 0,
    },
    {
      id: 0,
      day: "Saturday",
      openingTime: "",
      closingTime: "",
      closed: false,
      companyId: parseInt(selectedData?.id),
      companyLocationId: 0,
    },
    {
      id: 0,
      day: "Sunday",
      openingTime: "",
      closingTime: "",
      closed: false,
      companyId: parseInt(selectedData?.id),
      companyLocationId: 0,
    },
  ]);

  const isSaveDisabled = companyBusinessHours.some(
    (day) => (day.openingTime && !day.closingTime) && (day.openingTime && !day.closingTime && !day.closed)
  );

  useEffect(() => {
    let arr = companyBusinessHours.map(obj => {
      const matchingHour = businessHours.find(h => h.day === obj.day);
      if (matchingHour) {
        return {
          ...obj,
          id: matchingHour?.id,
          day: matchingHour?.day,
          closed: matchingHour?.closed,
          companyId: matchingHour?.companyId,
          companyLocationId: matchingHour?.companyLocationId,
          openingTime: matchingHour.openingTime,
          closingTime: matchingHour.closingTime,
        };
      }
      return obj;
    });
    setCompanyBusinessHours(arr);
  }, []);

  const  convert12hrTo24hr = (time12hr)=>{
    if (typeof time12hr !== 'string') {
      return 'Invalid input';
    }
  
    const [time, period] = time12hr.split(' ');
  
    if (!time || !period) {
      return 'Missing time or period';
    }
  
    let [hours, minutes] = time.split(':');
  
    if (!hours || !minutes) {
      return 'Missing hours or minutes';
    }
  
    if (period === 'PM' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    } else if (period === 'AM' && hours === '12') {
      hours = '00';
    }
  
    return `${hours}:${minutes}`;
  }

  const handleClosedHours = (v , index) => {
    const updatedBusinessHours = [...companyBusinessHours];
    const isCurrentlyClosed = updatedBusinessHours[index].closed;

    updatedBusinessHours[index] = {
      ...updatedBusinessHours[index],
      closed: !isCurrentlyClosed,
      openingTime: !isCurrentlyClosed ? "" : updatedBusinessHours[index].openingTime,
      closingTime: !isCurrentlyClosed ? "" : updatedBusinessHours[index].closingTime,
    };
    setCompanyBusinessHours(updatedBusinessHours);
  }
  console.log({ companyBusinessHours })
  return (
    <div>
      {
        companyBusinessHours.map((time , index)=>{
        return(
          <Grid container spacing={3} className='businessHours'>
          <Grid item xs={3} className={classes.gridWrapper}>
            <Typography className={classes.days}>{time.day}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridWrapper}>
            <Select
              value={time?.openingTime}
              onChange={e => {
                const selectedTime = convert12hrTo24hr(e.target.value);
                const closingTime = convert12hrTo24hr(time.closingTime);
                const parsedTime1 = moment(selectedTime, 'HH:mm');
                const parsedTime2 = moment(closingTime, 'HH:mm');
                if(!parsedTime2.isValid()){
                  const updatedBusinessHours = [...companyBusinessHours];
                  updatedBusinessHours[index] = {
                    ...updatedBusinessHours[index],
                    openingTime: e.target.value,
                  };          
                  setCompanyBusinessHours(updatedBusinessHours);
                }else if ( parsedTime1.isBefore(parsedTime2) ) {
                  const updatedBusinessHours = [...companyBusinessHours];
                  updatedBusinessHours[index] = {
                    ...updatedBusinessHours[index],
                    openingTime: e.target.value,
                  };          
                  setCompanyBusinessHours(updatedBusinessHours);
                 
                } else {
                  toast.error("Opening time must be before closing time");
                }
              }}
              displayEmpty
              disabled={time.closed === true}
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              style={{color: time?.openingTime === "" || time.closed === true ? "#00000061" : "#000000DE"}}
              variant={"outlined"}
              // required
            >
              <MenuItem value='' disabled>
               <span>{"09:00 AM"}</span>
              </MenuItem>
              {hours &&
                hours.length > 0 &&
                hours.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.time} >
                      {item.time}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid item className={classes.gridWrapper}>
            to
          </Grid>
          <Grid item xs={2} className={classes.gridWrapper}>
            <Select
              value={time.closingTime}
              onChange={e => {
                const selectedTime = convert12hrTo24hr(e.target.value);
                const openingTime = convert12hrTo24hr(time.openingTime);
                const parsedTime1 = moment(openingTime, 'HH:mm');
                const parsedTime2 = moment(selectedTime, 'HH:mm');

                if (!parsedTime1.isValid() || !parsedTime2.isValid()) {
                  toast.error("Invalid time format");
                } else if (parsedTime1.isBefore(parsedTime2)) {
                  const updatedBusinessHours = [...companyBusinessHours];
                  updatedBusinessHours[index] = {
                    ...updatedBusinessHours[index],
                    closingTime: e.target.value,
                  };
                  setCompanyBusinessHours(updatedBusinessHours);
                } else {
                  toast.error("Closing time must be after opening time");
                }
              }}
              displayEmpty
              disabled={time.openingTime === "" || time.closed === true }
              className={classes.selectEmpty}
              style={{color: time?.closingTime === "" || time.closed === true ? "#00000061" : "#000000DE"}}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              variant={"outlined"}>
              <MenuItem value='' disabled >
                <span>{"10:00 AM"}</span>
              </MenuItem>
              {closingHours &&
                closingHours.length > 0 &&
                closingHours.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.time}>
                      {item.time}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid
            item
            xs={2}
            className={(classes.gridWrapper, "businessHoursCheckbox")}>
            <Checkbox
              checked={time.closed}
              onChange={v => {
                handleClosedHours(v , index)
              }}
            />
          </Grid>
        </Grid>
            
        )})
      }
      <Grid item xs={12} className={(classes.gridWrapper, "hoursSaveBtn")}>
        <Button
          color='primary'
          variant='contained'
          disabled={isSaveDisabled}
          onClick={() => {
            setBusinessHours(companyBusinessHours);
            onClose();
          }}>
          Save
        </Button>
      </Grid>

    </div>
  );
}

export default CompanyBusinessHours;
