import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";
import apiCall from "axios";

async function getUserOverview(userId) {
  return axios
    .get(`${endpoints.endUser.getUserOverView}${userId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getUserById(userId) {
  return axios
    .get(`${endpoints.endUser.getUserById}${userId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateUserInfo(userData) {
  return axios
    .post(endpoints.endUser.updateUserInfo, JSON.stringify(userData))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getStaticEnv() {
  return axios
    .post(endpoints.endUser.getUserStaticEnv)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getEnvIcons() {
  return axios
    .get(endpoints.endUser.getEnvIcons)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateEnv(endData) {
  return axios
    .post(endpoints.endUser.addUpdateEnv, endData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getUserEnvById(userId) {
  return axios
    .post(endpoints.endUser.getUserEnvById, {
      userId: userId,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getUserEnvs({ userId }) {
  return axios
    .post(`${endpoints.endUser.getUserEnvs}${userId}`)
    .then(response => response.data)
    .catch(error => {
      return null;
    });
}

async function getUserGroupDD() {
  return axios
    .get(endpoints.endUser.getEndUserGroupDD)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getEnvWithDeviceCount(userId) {
  return axios
    .post(endpoints.endUser.getEnvWithDeviceCount, {
      userId: userId,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getFrndAndFmlyList(userId) {
  return axios
    .get(`${endpoints.endUserFrndAndFamily.getFrndAndFamilyList}${userId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addFrndAndFmly(ffData) {
  return axios
    .post(endpoints.endUserFrndAndFamily.addFrndAndFamily, ffData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteFrnAndFmly(ffId) {
  return axios
    .delete(`${endpoints.endUserFrndAndFamily.deleteFrndAndFamily}${ffId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getFFDevicesList(userId) {
  return axios
    .post(`${endpoints.endUserFrndAndFamily.getDevicesLookup}${userId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getUserProdCat() {
  return axios
    .post(endpoints.endUser.getUserProductCategory)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getUserProdByCatId(catId) {
  return axios
    .post(endpoints.endUser.getUserProductByCategoryId, {
      id: catId,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getDuplicateSerial(srNumber) {
  return axios
    .post(endpoints.endUser.getUserDuplicateSerial, {
      serialNo: srNumber,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getDealers(search, catId) {
  return axios
    .post(endpoints.endUser.getUserDelears, {
      categoryId: catId,
      input: search,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveDevice(deviceData) {
  return axios
    .post(endpoints.endUser.saveDevice, {
      userId: deviceData.userId,
      envId: deviceData.envId,
      dealerLocationId: deviceData.companyLocationId,
      companyLocationId: deviceData.dealerLocationId,
      serialNo: deviceData.serialNo,
      purchaseDate: deviceData.purchaseDate,
      basePath: "",
      receiptStatus: deviceData.receipt && deviceData.receipt !== "" ? 0 : null,
      receipt: deviceData.receipt,
      receiptType: deviceData.receiptType,
      productId: deviceData.productId,
      installationDate: deviceData.installationDate,
      colors: [],
      name: deviceData.name,
      spaBrand: deviceData.spaBrand,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getUserDevices(userId) {
  return axios
    .post(endpoints.endUser.getUserDevices, {
      userId: userId,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCompanyDevices(userId) {
  return axios
    .post(endpoints.endUser.getCompanyDevices, {
      id: userId,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateUserDevice(deviceInfo) {
  return axios
    .post(endpoints.endUser.updateUserDevices, deviceInfo)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteDevice(deviceId) {
  return axios
    .post(endpoints.endUser.deleteDevice, {
      ids: [deviceId],
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function transferDeviceOwnerShip(data) {
  return axios
    .post(endpoints.devices.transferDeviceOwnerShip, data)
    .then(response => {
      return response?.data ?? null;
    })
    .catch(error => {
      return null;
    });
}

async function getDeviceOverview(deviceId) {
  return axios
    .post(endpoints.endUser.deviceOverview, {
      id: deviceId,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateReceipt(deviceInfo) {
  return axios
    .post(endpoints.endUser.updateReceipt, deviceInfo)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addDeviceColor(devId, colorCode) {
  return axios
    .post(endpoints.endUser.addDeviceColor, {
      deviceId: devId,
      colors: [colorCode],
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function updateDeviceColor(clrId, colorCode) {
  return axios
    .post(endpoints.endUser.updateDeviceColor, {
      colorId: clrId,
      color: colorCode,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getUserNotes(getNoteUrl) {
  return axios
    .get(`${endpoints.baseUrl}${getNoteUrl}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateNote(notesData) {
  return axios
    .post(endpoints.endUser.addUpdateUserNotes, notesData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateBUNote(notesData) {
  return axios
    .post(endpoints.companies.addUpdateBUNotes, notesData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteUserNotes(devId) {
  return axios
    .post(endpoints.endUser.deleteUserNotes, {
      id: devId,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function deleteBUNotes(ntsId) {
  return axios
    .post(`${endpoints.companies.deleteBUNotes}${ntsId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getUserActivities(userId, noOfRecord) {
  return axios
    .get(
      `${
        endpoints.endUser.getUserActivity
      }${userId}${"&PageNumber=1&NoOfRecords="}${noOfRecord}`
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getEUActivity(userId) {
  return axios
    .get(`${endpoints.endUser.getEUActivity}${userId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getBUActivity(compId) {
  return axios
    .get(`${endpoints.endUser.getBUActivity}${compId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getSpaInfo(deviceInfo) {
  return axios
    .post(endpoints.spaDevice.getSpaInfo, {
      id: deviceInfo,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateSpaSetting(spaData) {
  return axios
    .post(endpoints.spaDevice.addUpdateSetting, spaData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function changeWaterTreatment(wtrData) {
  return axios
    .post(endpoints.spaDevice.updateWaterTreatment, wtrData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function changeGlobalSetting(globalData) {
  return axios
    .post(endpoints.spaDevice.updateGlobalSetting, globalData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function changeTemperatureSetting(tempData) {
  return axios
    .post(endpoints.spaDevice.updateTemperatureSetting, tempData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getContactTypeInfo(contactId) {
  return axios
    .get(`${endpoints.endUser.getContactUserTypes}${contactId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAdrsByUser(userId) {
  return axios
    .get(`${endpoints.endUser.getAdrsByUId}${userId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateCusAddress(adrData) {
  return axios
    .post(endpoints.endUser.addCusAddress, adrData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function resetPassword(passData) {
  return axios
    .post(endpoints.endUser.passReset, passData)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAllAdmins() {
  return axios
    .get(endpoints.endUser.getAllAdmins)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAdminById(adminId) {
  return axios
    .get(`${endpoints.endUser.getAdminsById}${adminId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getContactTypesByDDL(contactTypeId) {
  return axios
    .get(`${endpoints.contactTypes.getContactTypesDD}${contactTypeId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getSpaChemicalTestingList(payload) {
  return axios
    .post(endpoints.spaDevice.getSpaChemicalTestingList, payload)
    .then(response => response.data)
    .catch(error => null);
}

async function getAllWaterLife(deviceId) {
  return axios
    .get(endpoints.spaDevice.getAllWaterLife, {
      params: { deviceId: deviceId },
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getChemicalTestingStats(deviceId) {
  return axios
    .post(`${endpoints.spaDevice.getChemicalTestingStats}?deviceId=${deviceId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAllFilterLife(deviceId) {
  return axios
    .get(endpoints.spaDevice.allFilterLife, {
      params: {
        deviceId: deviceId,
      },
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function addUpdateFilterLife(filterLife) {
  return axios
    .post(endpoints.spaDevice.addUpdateFilterLife, filterLife)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateWaterLife(filterLife) {
  return axios
    .post(endpoints.spaDevice.addUpdateWaterLife, filterLife)
    .then(response => {
      let data = response?.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateSPAChemicalTesting(filterLife) {
  return axios
    .post(endpoints.spaDevice.addUpdateSPAChemicalTesting, filterLife)
    .then(response => {
      let data = response?.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getSPAFilterLifeStats(deviceId) {
  return axios
    .get(`${endpoints.spaDevice.getFilterLifeStats}?deviceId=${deviceId}`)
    .then(response => response?.data)
    .catch(error => {
      return null;
    });
}

async function getSPAWaterLifeStats(deviceId) {
  return axios
    .get(`${endpoints.spaDevice.getWaterLifeStats}?deviceId=${deviceId}`)
    .then(response => response?.data)
    .catch(error => {
      return null;
    });
}

async function getSpaChemicalTestingHistoryWithDates({
  deviceId,
  startDate,
  endDate,
}) {
  return axios
    .post(endpoints.spaDevice.getSpaChemicalTestingHistoryWithDates, {
      deviceId,
      startDate,
      endDate,
    })
    .then(response => {
      let data = response.data ?? [];
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function uploadRecipt(image) {
  let formData = new FormData();
  formData.append("file", image);

  const token = localStorage.getItem("access_token");
  return apiCall({
    url: endpoints.artifacts.uploadReceipt,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      let data = response?.data ?? null;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function saveSpaSetting(spaSetting) {
  return axios
    .post(endpoints.spaDevice.updateSpaSetting, { spaSetting })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAllDealers(search, catId) {
  return axios
    .post(endpoints.endUser.getAllUserDelears, {
      categoryId: catId,
      input: search,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteCusAddress(id) {
  return axios
    .delete(`${endpoints.endUser.deleteCusAddress}/${id}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteEnv(devId) {
  return axios
    .post(endpoints.endUser.deleteEnv, devId)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
export const endUserApi = {
  getUserOverview: getUserOverview,
  getUserById: getUserById,
  updateUserInfo: updateUserInfo,
  getStaticEnv: getStaticEnv,
  getEnvIcons: getEnvIcons,
  addUpdateEnv: addUpdateEnv,
  getUserEnvById: getUserEnvById,
  getUserEnvs: getUserEnvs,
  getUserGroupDD: getUserGroupDD,
  getEnvWithDeviceCount: getEnvWithDeviceCount,
  getFrndAndFmlyList: getFrndAndFmlyList,
  addFrndAndFmly: addFrndAndFmly,
  deleteFrnAndFmly: deleteFrnAndFmly,
  getFFDevicesList: getFFDevicesList,
  getUserProdCat: getUserProdCat,
  getUserProdByCatId: getUserProdByCatId,
  getDuplicateSerial: getDuplicateSerial,
  getDealers: getDealers,
  saveDevice: saveDevice,
  getUserDevices: getUserDevices,
  getCompanyDevices: getCompanyDevices,
  updateUserDevice: updateUserDevice,
  deleteDevice: deleteDevice,
  getDeviceOverview: getDeviceOverview,
  updateReceipt: updateReceipt,
  addDeviceColor: addDeviceColor,
  updateDeviceColor: updateDeviceColor,
  getUserNotes: getUserNotes,
  addUpdateNote: addUpdateNote,
  addUpdateBUNote: addUpdateBUNote,
  deleteUserNotes: deleteUserNotes,
  deleteBUNotes: deleteBUNotes,
  getUserActivities: getUserActivities,
  getEUActivity: getEUActivity,
  getBUActivity: getBUActivity,
  getSpaInfo: getSpaInfo,
  addUpdateSpaSetting: addUpdateSpaSetting,
  changeWaterTreatment: changeWaterTreatment,
  changeGlobalSetting: changeGlobalSetting,
  changeTemperatureSetting: changeTemperatureSetting,
  getContactTypeInfo: getContactTypeInfo,
  getAdrsByUser: getAdrsByUser,
  addUpdateCusAddress: addUpdateCusAddress,
  resetPassword: resetPassword,
  getAllAdmins: getAllAdmins,
  getAdminById: getAdminById,
  getContactTypesByDDL: getContactTypesByDDL,
  getSpaChemicalTestingList: getSpaChemicalTestingList,
  getAllFilterLife: getAllFilterLife,
  getAllWaterLife: getAllWaterLife,
  getChemicalTestingStats: getChemicalTestingStats,
  getSPAFilterLifeStats: getSPAFilterLifeStats,
  getSPAWaterLifeStats: getSPAWaterLifeStats,
  getSpaChemicalTestingHistoryWithDates: getSpaChemicalTestingHistoryWithDates,
  addUpdateFilterLife: addUpdateFilterLife,
  addUpdateWaterLife: addUpdateWaterLife,
  addUpdateSPAChemicalTesting: addUpdateSPAChemicalTesting,
  uploadRecipt: uploadRecipt,
  transferDeviceOwnerShip: transferDeviceOwnerShip,
  saveSpaSetting: saveSpaSetting,
  getAllDealers: getAllDealers,
  deleteCusAddress:deleteCusAddress,
  deleteEnv:deleteEnv,
};
