import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";
import { AddAssetPop } from "./AddAssetPop";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ReleaseAssetGrid from "./ReleaseAssetGrid";
import CheckBox from "../../../../components/common/checkbox/Checkbox";

import { downarrow } from "../../../../resources/images/index";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "transparent !important",
    //   margin: '12px 0'
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions({
  uploadFeatureImg,
  checkedFeaturImage,
  getFeatureFiles,
  featureFiles,
  setShowFeatureImage,
  showFeatureImage,
  removeCollection,
}) {
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { assetCollectionArray } = useSelector(state => state.AssetsReducer);
  return (
    <div>
      <Accordion
        square
        expanded={expanded}
        // onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
          <Typography className={classes.mainHeading}>
            <img
              src={downarrow}
              alt=''
              className={classes.headingIcon}
              onClick={() => setExpanded(!expanded)}
            />
            Collection Assets
          </Typography>
          <div className='releaseAddPopup'>
            <Grid
              item
              xs={8}
              className={(classes.gridWrapper, classes.featuredImage)}>
              <Button
                variant='outlined'
                color='primary'
                className={classes.selectBtn}
                onClick={() => getFeatureFiles()}>
                <AddIcon /> Add Asset
              </Button>

              {showFeatureImage && (
                <span className='featuredImageTabs'>
                  {/* <h2 className={classes.featuredImageTabsHeading}>
                    Add Assets
                    <CloseIcon
                      className={classes.closeTabs}
                      onClick={() => setShowFeatureImage(false)}
                    />
                  </h2> */}
                  <AddAssetPop
                    title={"Add Assets"}
                    showFeatureImage={showFeatureImage}
                    setShowFeatureImage={() =>
                      setShowFeatureImage(!showFeatureImage)
                    }
                    onImageSelect={img => {
                      checkedFeaturImage(img);
                    }}
                  />
                </span>
              )}
            </Grid>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ReleaseAssetGrid
            assetData={assetCollectionArray}
            removeCollection={value => removeCollection(value)}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
