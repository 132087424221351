import React, { useState } from "react";
import InputField from "../../../../components/common/InputField";
import ProductImageView from "./ProductImageView";

function ProductRegistration(props) {
  const [isProduct, setIsProduct] = useState(true);
  const [isImage, setIsImage] = useState(false);

  const showImage = () => {
    setIsProduct(false);
    setIsImage(true);
  };

  return (
    <div className="deviceInner registrationWrapper">
      {isProduct && (
        <>
          {" "}
          <h2>Registered Product</h2>
          <span class="userProduct">awais's test new</span>
          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
            <span>
              <label className="purchaseRecWrapper">Serial Number</label>
              <InputField />
            </span>
          </span>
          <span className="loginWrapper__loginFormWrapper--inner  groupFields">
            <label className="purchaseRecWrapper">Purchase Location</label>
            <InputField />
          </span>
          <span className="loginWrapper__loginFormWrapper--inner  groupFields purchaseField">
            <label className="purchaseRecWrapper">Purchase Date</label>

            <InputField />
          </span>
          <span className="loginWrapper__loginFormWrapper--inner">
            <div className="upload-btn-wrapper replaceBtnWrapper">
              <label className="purchaseRecWrapper">Purchase Receipt</label>

              <span className="loginWrapper__loginFormWrapper--inner viewReplaceButton">
                <button className="" onClick={() => showImage()}>
                  View
                </button>
                {/* <i className="uploadWrapper">
                <button className="replaceBtn btn">Replace</button>
                <input type="file" name="myfile" accept="image/png" />
              </i> */}
              </span>

              <button className="btn">RELPACE</button>
              <input type="file" name="myfile" accept="image/png" />
            </div>
          </span>
        </>
      )}

      {isImage && <ProductImageView />}
    </div>
  );
}

export default ProductRegistration;
