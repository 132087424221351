const SerialsStatus = {
  0: "Registration Pending",
  1: "Registered",
  5: "Registration Denied",
  6: "Mark as Incomplete",
  3: "Receipt Missing"
};

const DateCreated = [
  { id: 0, name: "All Time" },
  { id: 1, name: "Within Month" },
  { id: 2, name: "Within Past Month" },
  { id: 3, name: "Within Past 3 Month" },
  { id: 4, name: "Within Past 6 Month" },
  { id: 5, name: "Within Past Last Month" },
];

const ProductsOwnedQtyList = [
  { id: 1, value: "1" },
  { id: 2, value: "2" },
  { id: 3, value: "3" },
  { id: 4, value: "4" },
  { id: 5, value: "5" },
  { id: 6, value: "6+" },
];

const FilterEStatus = {
  Deleted: -1,
  InActive: 0,
  Active: 1,
  Blocked: 2,
  Archive: 3,
  Draft: 4,
};

const ConvInboxTypes = {
  Company: {
    route: "/company-inbox",
    title: "Company Inbox",
    value: "Company",
  },
  Press: {
    route: "/press-inbox",
    title: "Press Inbox",
    value: "Press",
  },
  Support: {
    route: "/support-inbox",
    title: "Support Inbox",
    value: "Support",
  },
  SalesOrder: {
    route: "/sales-inbox",
    title: "Sales Order Inbox",
    value: "SalesOrder",
  },
};

export {
  SerialsStatus,
  DateCreated,
  ProductsOwnedQtyList,
  FilterEStatus,
  ConvInboxTypes,
};
