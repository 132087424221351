import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { endpoints } from "../config/apiConfig";
import { productInventoryActions } from "../store/product/productInventoryAction";
import { useLoadData } from "./use-load-data";

const DefaultPriceListData = {
  id: 0,
  priceListNameVisible: "",
  priceListNameInternal: "",
  isConfidential: true,
  shortDescription: "",
  papId: "-1",
  status: 0,
  groupAccessLevel: [],
  priceListColumns: [],
  releaseStatus: 1,
  preReleaseAccessDate: null,
};

const usePriceList = ({ priceListId }) => {
  const dispatch = useDispatch();
  const { records: pricingGroupsByPapId, loadList } = useLoadData();

  const [isProcessing, setIsProcessing] = useState(false);
  const [priceList, setPriceList] = useState(DefaultPriceListData);

  const getPriceListById = useCallback(() => {
    if (priceListId) {
      setIsProcessing(true);
      dispatch(productInventoryActions.onGetPriceListById(priceListId)).then(
        data => {
          setIsProcessing(false);
          if (!data) return;
          const { data: pl } = data;
          if (pl) {
            setPriceList(pl);
          }
        }
      );
    }
  }, [dispatch, priceListId]);

  useEffect(() => {
    getPriceListById();
  }, [getPriceListById]);

  useEffect(() => {
    loadList({
      url: endpoints.priceListTool.getPricingGroupsByAvailabilityPeriod,
      payload: {
        id: `${priceList?.papId}`,
      },
    });
  }, [loadList, priceList?.papId]);

  const addUpdatePriceList = useCallback(
    async priceListData => {
      setIsProcessing(true);
      const result = await dispatch(
        productInventoryActions.onLoadData({
          url: endpoints.priceListTool.addupdatePriceListV2,
          payload: priceListData,
        })
      );
      setIsProcessing(false);

      if (!result?.success) toast.error(result.message);
      else setPriceList(result?.data);
      return result;
    },
    [dispatch]
  );

  const addUpdatePriceListVisibility = useCallback(
    async priceListVisibilityData => {
      setIsProcessing(true);
      const result = await dispatch(
        productInventoryActions.onLoadData({
          url: endpoints.priceListTool.addUpdatePriceListVisibility,
          payload: priceListVisibilityData,
        })
      );
      setIsProcessing(false);

      if (!result?.success) toast.error(result.message);
      else setPriceList(result?.data);
      return result;
    },
    [dispatch]
  );

  const updatePriceListDesign = useCallback(
    async priceListDesign => {
      setIsProcessing(true);
      const result = await dispatch(
        productInventoryActions.onLoadData({
          url: endpoints.priceListTool.updatePriceListDesign,
          payload: priceListDesign,
        })
      );
      setIsProcessing(false);

      if (!result?.success) toast.error(result.message);
      else setPriceList(result?.data);
      return result;
    },
    [dispatch]
  );

  const addUpdatePriceListColumns = useCallback(
    async priceListColumns => {
      setIsProcessing(true);
      const result = await dispatch(
        productInventoryActions.onLoadData({
          url: endpoints.priceListTool.addUpdatePriceListColumns,
          payload: priceListColumns,
        })
      );
      setIsProcessing(false);

      if (!result?.success) toast.error(result?.message ?? 'Something went wrong!');
      else setPriceList(result?.data);
      return result;
    },
    [dispatch]
  );

  const deletePriceList = useCallback(
    async priceListIds => {
      setIsProcessing(true);
      const result = await dispatch(
        productInventoryActions.onDeleteItemsByIds({
          url: endpoints.priceListTool.deletePriceLists,
          itemIds: priceListIds,
        })
      );
      setIsProcessing(false);

      if (!result?.success) toast.error(result.message);
      return result;
    },
    [dispatch]
  );

  return {
    isProcessing,
    priceList,
    setPriceList,
    getPriceListById,
    pricingGroupsByPapId,
    addUpdatePriceList,
    addUpdatePriceListVisibility,
    updatePriceListDesign,
    addUpdatePriceListColumns,
    deletePriceList,
  };
};

export { usePriceList, DefaultPriceListData };
