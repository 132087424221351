import React from "react";
import { EditIcon } from "../../LeftNav/SvgIcons";

function CustomerInfo({ profileInfo }) {
  return (
    <div className='customerInfoWrapper'>
      <div className='customerContactInner'>
        <span className='infoHeader'>
          Customer Information{" "}
          {/* <span onClick={() => alert("ok")}>
            {" "}
            <EditIcon />
          </span> */}
        </span>

        <span className='phoneNumber'>
          <i>Phone Number</i>
          {profileInfo ? profileInfo.phoneNumber : "n/a"}
        </span>

        <span className='customerEmail'>
          <i>Email Address</i>
          {profileInfo ? profileInfo.email : "n/a"}
        </span>

        <span className='customerAddress'>
          <i>Address</i>
          {profileInfo ? profileInfo.address : "n/a"}
        </span>

        {/* <span className='friendlyness'>
          Friendliness
          <div className='statrRatting'>
            <fieldset className='rating'>
              <input type='radio' id='star5' name='rating' value='5' />
              <label
                className='full'
                for='star5'
                title='Awesome - 5 stars'></label>
              <input
                type='radio'
                id='star4half'
                name='rating'
                value='4 and a half'
              />
              <label
                className='half'
                for='star4half'
                title='Pretty good - 4.5 stars'></label>
              <input type='radio' id='star4' name='rating' value='4' />
              <label
                className='full'
                for='star4'
                title='Pretty good - 4 stars'></label>
              <input
                type='radio'
                id='star3half'
                name='rating'
                value='3 and a half'
              />
              <label
                className='half'
                for='star3half'
                title='Meh - 3.5 stars'></label>
              <input type='radio' id='star3' name='rating' value='3' />
              <label className='full' for='star3' title='Meh - 3 stars'></label>
              <input
                type='radio'
                id='star2half'
                name='rating'
                value='2 and a half'
              />
              <label
                className='half'
                for='star2half'
                title='Kinda bad - 2.5 stars'></label>
              <input type='radio' id='star2' name='rating' value='2' />
              <label
                className='full'
                for='star2'
                title='Kinda bad - 2 stars'></label>
              <input
                type='radio'
                id='star1half'
                name='rating'
                value='1 and a half'
              />
              <label
                className='half'
                for='star1half'
                title='Meh - 1.5 stars'></label>
              <input type='radio' id='star1' name='rating' value='1' />
              <label
                className='full'
                for='star1'
                title='Sucks big time - 1 star'></label>
              <input type='radio' id='starhalf' name='rating' value='half' />
              <label
                className='half'
                for='starhalf'
                title='Sucks big time - 0.5 stars'></label>
            </fieldset>
          </div>
          <i>Above Average</i>
        </span> */}
      </div>
    </div>
  );
}

export default CustomerInfo;
