import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Pagination } from "../../../../../components/common";
import Loader from "../../../../../components/common/Loader";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import AllContactsGrid from "./AllContactsGrid";
import { GridView, ListView } from "../../../LeftNav/SvgIcons";
import {  Grid } from "@material-ui/core";
import SoftwareGridFilter from "../../SoftwareUpdates/SoftwareGridFilter";
import { GridFilter } from "./GridFilter";
import { useDebounce } from "../../../../../utils/use-debounce";

function SettingsGrid({activeTab}) {
  const {
    inboxTypeList,
    allConversationChannels,
  } = useSelector(state => state.conversationReducer);
  const history = useHistory();
  const [tabName, setTabName] = useState("Conversation Types");
  const [isLoading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  // const [filterByName, setFilterByName] = useState("");
  const [search , setSearch] = useState("");
  const { debouncedValue } = useDebounce(search , 500)
  const [selectedCategoryId, setSelectedCategory] = useState(null);
  const [selectedDate, setSelectedDate] = useState(0);
  const [allCampGrid, setAllCampGrid] = useState([]);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [isConversationResponse , setIsConversationResponse] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const[conversationSizes , setConversationSizes] = useState({});
  const[conversationTags , setConversationTags] = useState({});
  const[assignedBoxes , setAssignedBoxes] = useState({});
  const[conversationChannels , setConversationChannels] = useState([]);
  const [isFilterApplied , setIsFilterApplied] = useState(false);
  const [appliedFilter , setAppliedFilter] = useState(0);
  const dispatch = useDispatch();

  var filtersCount = (conversationChannels.length > 0 ? conversationChannels.length : 0) +
  (conversationSizes.id > 0 ? 1 : 0) +
  (assignedBoxes.value ? 1 : 0) +
  (conversationTags.status ? 1 : 0);

  useEffect(()=>{
    if (filtersCount && isFilterApplied) {
      filterConversationSetting();
    } else {
      loadAllConversation();
    }
  },[
    dispatch,
    page,
    rowsPerPage,
    debouncedValue,
    selectedCategoryId,
    selectedDate,
    order.orderByKey,
    order.orderVal,
    isConversationResponse,
  ])

  const loadAllConversation = useCallback(() => {
    setLoading(true);
    dispatch(
      conversationAction.onGetAllCampaignsGrid({
        productId: 2,
        campaignName: debouncedValue,
        searchQuery: debouncedValue,        
        channels: conversationChannels.map(x => x.id),
        pagination: {
          pageSize: rowsPerPage,
          pageNo: page,
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
        },
      })
    ).then(data => {
      setIsConversationResponse(null);
      if (data?.responseMsg === "Success") {
        setAllCampGrid(data?.responseData?.data);
        setPaginationResponse(data?.responseData?.pagination);
      }
      setLoading(false);
    });
  }, [
    dispatch,
    page,
    rowsPerPage,
    debouncedValue,
    selectedCategoryId,
    selectedDate,
    order.orderByKey,
    order.orderVal,

  ]);

  const filterConversationSetting = ()=>{
    setAppliedFilter(filtersCount);
    setLoading(true);
    dispatch(
      conversationAction.onGetAllCampaignsGrid({
        productId: 2,
        campaignName: debouncedValue,
        searchQuery: debouncedValue,
        type: "",
        assignedInbox:assignedBoxes?.id?.toString() ?? "",
        status: conversationTags.status ?? "",
        isGroupChat: conversationSizes?.id === 1 ? false : conversationSizes?.id === 2 ? true : null ,
        channels: conversationChannels.map(x => x.id),
        pagination: {
          pageSize: rowsPerPage,
          pageNo: page,
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
        },
      })
    ).then(data => {
      setIsConversationResponse(null);
      if (data?.responseMsg === "Success") {
        setAllCampGrid(data?.responseData?.data);
        setPaginationResponse(data?.responseData?.pagination);
      }
      setLoading(false);
    });
  }
  

  useEffect(() => {
    if (activeTab == 1) {
      setTabName("General");
    } else if (activeTab == 2) {
      setTabName("Channels");
    } else if (activeTab == 3) {
      setTabName("Conversation Types");
    } else if (activeTab == 4) {
      setTabName("Tags");
    } else if(activeTab == 5){
      setTabName("Widget");
    }else{
      setTabName("Journeys")
    }
  }, [activeTab]);

  const onPressFilterBtn = () => {
    window.scroll(0, 0);
    // document.body.classList.add("bodyScroll");
    setIsFilter(true);
  };

  useEffect(() => {
    dispatch(
      conversationAction.onGetAllConversationChannels({
        searchQuery: "",
        channel: null,
        pagination: {
          pageSize: 1000,
          pageNo: 1,
          orderByKey: "",
          orderByVal: 0,
        },
      })
    );
  }, [])
  const convTags = [
    {
      id:0,
      status:"Active"
    },
    {
      id:1,
      status:"InActive"
    },
  ]
  const convSizes = [
    {
      id:0,
      value:"Please Select"
    },
    {
      id:1,
      value:"1:1 Chat"
    },
    {
      id:2,
      value:"1:1 + Group Chat"
    },
  ]
  
  useEffect(() => {
    
  }, [
    dispatch,
    page,
    rowsPerPage,
    debouncedValue,
    selectedCategoryId,
    selectedDate,
    order.orderByKey,
    order.orderVal,
    isConversationResponse,
  ]);

  const onClearFilter = ()=>{
    setIsFilterApplied(true);
    setPage(1)
    setRowsPerPage(10)
    setOrderBy({
        orderByKey: "Id",
        orderVal: 0,
      })
    setIsFilterApplied(false);
    setAppliedFilter(0);
    setConversationChannels([]);
    setConversationSizes({});
    setConversationTags({});
    setAssignedBoxes({});
    setSearch("")
    filtersCount=0;
    setLoading(true);
    dispatch(
      conversationAction.onGetAllCampaignsGrid({
        productId: 2,
        campaignName: "",
        searchQuery: "",
        type: "",
        assignedInbox:"",
        status: "",
        isGroupChat: null,
        channels: [],
        pagination: {
          pageSize: rowsPerPage,
          pageNo: page,
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
        },
      })
    ).then(data => {
      setIsConversationResponse(null);
      if (data?.responseMsg === "Success") {
        setAllCampGrid(data?.responseData?.data);
        setPaginationResponse(data?.responseData?.pagination);
      }
      setLoading(false);
    });
  }
  return (
    <div className='gridBody contentBody conversationSettingContainer'>
      {isLoading ? <Loader /> : null}
      {/* <GridFilterBarBusinessCustomers
        businessGroups={businessGroups}
        setFilterByName={setFilterByName}
        setSelectedCategory={setSelectedCategory}
        setSelectedDate={setSelectedDate}
      /> */}
      {/* <div className='conversationHeader'>
        <span className='conversationSearch'>
          <SearchIcon />
          <input
            type='text'
            placeholder='Seach Conversation Types'
            // onKeyPress={e => enterPressed(e)}
            onChange={e => {
              setFilterByName(e.target.value);
            }}
            value={filterByName}
          />
        </span>
        <span className='saerchFilter'>
          {/* <img
            src={saerchFilter}
            alt="search filter"
            onClick={() => {
              loadAllConversation();
            }}
          /> 
          <i
            onClick={() => {
              loadAllConversation();
            }}>
            <FilterIcon />
          </i>
        </span>
      </div> */}
      <Grid className="softwareGridFilterContainer">
        <Grid className="gridFilterBox">
          <SoftwareGridFilter
            search={search}
            setSearch={setSearch}
            isFilterTrue={true}
            placeholder={"Search"}
            onPressFilterBtn={onPressFilterBtn}
            isGridFilter={true}
            appliedFilter={appliedFilter}
          />
          <Grid item md={1} className={"gridIconWrapper"}>
            <Grid style={{ cursor: "pointer" }}>
              <GridView color={"#3699FF"} />
            </Grid>
            <Grid style={{ cursor: "pointer" }}>
              <ListView color={"#979797"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <GridFilter
          isFilter={isFilter}
          setIsFilter={setIsFilter}
          conversationChannels={conversationChannels}
          setConversationChannels={setConversationChannels}
          conversationSizes={conversationSizes}
          setConversationSizes={setConversationSizes}
          conversationTags={conversationTags}
          setConversationTags={setConversationTags}
          assignedBoxes={assignedBoxes}
          setAssignedBoxes={setAssignedBoxes}
          convSizes={convSizes}
          convTags={convTags}
          allConversationChannels={allConversationChannels}
          inboxTypeList={inboxTypeList}
          filtersCount={filtersCount}
          filterConversationSetting={filterConversationSetting}
          onClearFilter={onClearFilter}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          setOrderBy={setOrderBy}
          setIsFilterApplied={setIsFilterApplied}
        >
        </GridFilter>
      </Grid>
      <Grid className="tablePaginationtToolbar hideArrows">
        <span className="releaseHeading">{tabName}</span>
        <Pagination
          recordCount={paginationResponse?.totalRecords}
          page={page - 1}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={[]}
          backIconButtonProps={{ style: { visibility: "hidden" } }}
          nextIconButtonProps={{ style: { visibility: "hidden" } }}
        />
      </Grid>
      <AllContactsGrid
        allCampGrid={allCampGrid}
        order={order}
        onOrderBy={setOrderBy}
        setIsConversationResponse={setIsConversationResponse}
      />
      <Pagination
        recordCount={paginationResponse?.totalRecords}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}

export default SettingsGrid;
