import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { InputField, SelectInput } from "../../../../../components/common";
import { makeStyles } from "@material-ui/core/styles";
import { CloseRedIcon, SaveGreenIcon } from "../../../LeftNav/SvgIcons";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import OutsideClickHandler from "react-outside-click-handler";
import { Autocomplete } from "@material-ui/lab";
import { StatusActionSelectInput } from "../../../../../components/common/SelectInput";

const useStyles = makeStyles(theme => ({
  gridBoder: {
    borderBottom: "1px solid #e2e6e9",
  },
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
  inputGrid:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  }
}));

function StatusTypeForm({
  actionList,
  isNewStatusType,
  setIsNewStatustype,
  setIsEditStatus,
  isEditStatus,
  editData,
  paramId,
  onGetStatusActions,
  setAddUpdateResponse,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [statusName, setStatusName] = useState("");
  const [customAction, setCustomAction] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStatusText, setSelectedStatusText] = useState("");
  const [colorOption, setColorOption] = useState(false);
  const [inputAllowed, setInputAllowed] = useState(false);
  const classes = useStyles();

  const {
    conversationCompaginId,
  } = useSelector(
    state => state.conversationReducer
  );

  const handleAddressSubmit = e => {
    e.preventDefault();
    addUpdateStatus();
  };

  const addUpdateStatus = () => {
    const statusModel = {
      id: editData ? editData.id : 0,
      campaignId: paramId ? paramId : conversationCompaginId,
      name: statusName,
      color: statusColor,
      statusActionId: selectedStatus === "customId" ? null : selectedStatus,
      action: selectedStatus === "customId" ? "" : selectedStatusText,
      isCustomAction: selectedStatus === "customId" ? true : false,
      customAction: selectedStatus === "customId" ? customAction : "",
    };
    dispatch(conversationAction.onAddUpdateStatus(statusModel)).then(data => {
      if (data?.responseCode === 1) {
        setAddUpdateResponse(data?.responseCode);
        onGetStatusActions();
        setIsEditStatus(false);
        setIsNewStatustype(false);
      }
    });
  };

  useEffect(() => {
    if (isEditStatus) {
      setStatusName(editData?.name);
      setStatusColor(editData?.color);
      setSelectedStatus(editData?.statusActionId);
      setSelectedStatusText(editData?.action);
    }
  }, [isEditStatus]);

  const selectDDVal = value => {
    setSelectedStatus(value);
    const selectedVal = actionList.find(es => es.id == value);
    setSelectedStatusText(selectedVal.name);
  };

  const colors = [
    { color: "#2D6EC8" },
    { color: "#8254E3" },
    { color: "#00ABA3" },
    { color: "#E06145" },
    { color: "#FFAD4E" },
    { color: "#3699FF" },
    { color: "#18BC6D" },
    { color: "#36CFC9" },
    { color: "#54B0E3" },
    { color: "#80D7D3" },
    { color: "#FFEC3D" },
    { color: "#B867AB" },
    { color: "#439442" },
    { color: "#E23C3C" },
    { color: "#DDD587" },
    { color: "#E18DB0" },
    { color: "#FE157F" },
    { color: "#9FB6E5" },
    { color: "#F997EF" },
    { color: "#8FA3B5" },
  ];
  function handleKeyPress(event) {
    if (!inputAllowed) {
      event.preventDefault();
    }
  }

  return (
    <div className='borderBottom addressTickButton'>
      <ValidatorForm
        ref={React.useRef("addressForm")}
        onSubmit={handleAddressSubmit}>
        <Grid item md={12} className={classes.gridWrapper}>
          <Grid container spacing={3} style={{ padding: "0" }}>
            <Grid item md={7}>
              <InputField
                placeholder='Custome Color Name'
                inputlabel={"Status Name"}
                id='conversation-name'
                name='conversation-name'
                value={statusName}
                onChange={e => setStatusName(e.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </Grid>
            <Grid item md={5} className='colorContainer'>
              <InputField
                placeholder='Color Code'
                inputlabel={"Status Color"}
                id='conversation-color'
                name='conversation-color'
                value={statusColor}
                readOnly={false}
                onKeyPress={handleKeyPress}
                onChange={e => setStatusColor(e.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
              <OutsideClickHandler onOutsideClick={() => setColorOption(false)}>
                <span
                  className='colorBox'
                  style={{ background: `${statusColor}` }}
                  onClick={() => setColorOption(true)}
                />
                {colorOption && (
                  <div className='colorOptions'>
                    {colors.map((item, index) => {
                      return (
                        <span ket={item.id}>
                          <button
                            style={{ background: `${item.color}` }}
                            onClick={() => {
                              setStatusColor(item.color);
                              setColorOption(false);
                            }}
                          />
                        </span>
                      );
                    })}
                  </div>
                )}
              </OutsideClickHandler>
            </Grid>
          </Grid>

          <div className='gap' />
          <Grid item md={12}>
            {
              selectedStatus === "customId" ?
              <Grid className={classes.inputGrid}>
                <Grid md={10}>
                <InputField
                  placeholder='Custom Action'
                  inputlabel={"Custom Action"}
                  id='conversation-name'
                  name='conversation-name'
                  value={customAction}
                  onChange={e => setCustomAction(e.target.value)}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                />
                </Grid>
                  <IconButton
                    onClick={() => {
                      setSelectedStatus("")
                    }}>
                    <CloseRedIcon />
                  </IconButton> 
                </Grid>
                : <StatusActionSelectInput
                  selectlabel='Status Action'
                  value={selectedStatus}
                  customMenuItems={[...actionList, { id: "customId", isActive: true, name: "Create Custom Automation for Status Action" }]}
                  handleChange={event => {
                    selectDDVal(event.target.value);
                  }}
                />
            }
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-end'>
          <IconButton
            onClick={() => {
              setIsNewStatustype(false);
              setIsEditStatus(false);
            }}>
            <CloseRedIcon />
          </IconButton>
          <IconButton type='submit'>
            <SaveGreenIcon />
          </IconButton>
        </Grid>
      </ValidatorForm>
    </div>
  );
}

export default StatusTypeForm;
