import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import SettingsTab from "./SettingsTab";
import { ArrowBackIos } from "@material-ui/icons";
import ConversationSettingType from "./ConversationSettingType";
import Dashboard from "../../Dashbard";
import { SelectInput } from "../../../../../components/common";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import { productInventoryActions } from "../../../../../store/product/productInventoryAction";
import { endpoints } from "../../../../../config/conversationConfig";
import Loader from "../../../../../components/common/Loader";

const useStyles = makeStyles(theme => ({
  tabsHeader: {
    marginBottom: "16px",
    paddingBottom: "unset",
  },
}));

function ConversationCustomizations() {
  const { 
    basicConversationData,
    selectedConversationChannels,
  } = useSelector(
    state => state.conversationReducer
  );
  const classes = useStyles();
  const history = useHistory();
  const { id: compaginId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(1);
  const [statusClass, setStatusClass] = useState(
    "newSoftwareHeaderSelectActive"
  );

  const releaseStatus = [
    { id: 1, name: "Active" },
    { id: 0, name: "In Active" },
  ];

  useEffect(() => {
    if(basicConversationData?.status === 0 || basicConversationData?.status === "InActive"){
      setStatusClass("newSoftwareHeaderSelectInactive");
    }else{
      setStatusClass("newSoftwareHeaderSelectActive");
    }
  }, [basicConversationData?.status]);
  const loadInboxTyoeList = useCallback(
    ({ url, payload }) => {
      dispatch(
        productInventoryActions.onLoadData({
          url: url,
          payload: payload,
        })
      ).then(data => {
        if (data?.responseCode === 1)
          dispatch(
            conversationAction.onChange(
              "inboxTypeList",
              data?.responseData || []
            )
          );
      });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(conversationAction.onGetConversationCompatibleProducts());
    dispatch(
      conversationAction.onGetAllConversationChannels({
        searchQuery: "",
        channel: null,
        pagination: {
          pageSize: 1000,
          pageNo: 1,
          orderByKey: "",
          orderByVal: 0,
        },
      })
    );
    loadInboxTyoeList({
      url: endpoints.conversation.getInboxTypes,
      payload: {},
    });
  }, [loadInboxTyoeList]);

  const basicConversationProps = (propName, value) => {
    dispatch(
      conversationAction.onChangeBasicConversationFields({ propName, value })
    );
  };
  const handleConversationStatus = (status) => {
    basicConversationProps("status", status);
    if (compaginId) {
      setLoading(true);
        dispatch(
            conversationAction.onAddUpdateCampaignSettings({
                productId: 2,
                campaignId: compaginId,
                nameInternal: basicConversationData?.nameInternal || "",
                namePublic: basicConversationData?.namePublic || "",
                color: basicConversationData?.color,
                shortDescription: basicConversationData?.shortDescription || "",
                channels: selectedConversationChannels ? selectedConversationChannels?.map(channel => channel.id) : [],
                isPublic: basicConversationData?.isPublic || false,
                isGroupChat: basicConversationData?.isGroupChat || false,
                isDeplayedInAdminInbox: basicConversationData?.isDeplayedInAdminInbox || false,
                type: "",
                assignedInbox: basicConversationData?.assignedInbox?.toString() || "",
                status: status === "Active" ? 1 : status === "InActive" ? 0 : status === null ? 0  : status
            })
        ).then(data => {
            setLoading(false);
        }).catch(error => {
            setLoading(false);;
        });
    }
};
  return (
    <Grid className='gridBody conversationSetting'>
      {isLoading ? <Loader /> : null}
      <Dashboard>
        <div className='conversationSettingstypeWrapper'>
          <div className='newHeaderButtonContainer'>
            <span className='settingsHeader'>
              <h2 className='newConversationHeading'>New Conversation Type</h2>
              <Button
                color='primary'
                variant='contained'
                startIcon={<ArrowBackIos style={{ fontSize: "14px" }} />}
                onClick={() => history.push("/conversation-settings")}>
                Back to Conversation Types
              </Button>
            </span>
            <div className='statusConatiner'>
              <div>
                <Button
                  variant='outlined'
                  className='skipButton'
                  onClick={() => history.push("/conversation-settings")}>
                  Cancel
                </Button>
              </div>
              <div className={basicConversationData?.status === 0 || basicConversationData?.status === "InActive" ? 
              "newSoftwareHeaderSelectInactive" : "newSoftwareHeaderSelectActive"
            }>
                <SelectInput
                  placeholder='Select'
                  value={typeof basicConversationData?.status === "number" ? basicConversationData?.status :
                    basicConversationData?.status === "InActive" ? 0 : 1}
                  customMenuItems={releaseStatus}
                  disablePlaceholderOption={true}
                  removeSelectOption={true}
                  handleChange={({ target }) =>
                  handleConversationStatus(target.value)
                }
                />
              </div>
            </div>
          </div>
          <div className='conversationTypeTab'>
            <TopTabNavigator
              tabs={[
                {
                  id: 1,
                  title: `New Conversation Type`,
                  component: <ConversationSettingType />,
                },
                // {
                //   id: 2,
                //   title: `Conversation Type`,
                //   component: <SettingsTab />,
                // }
              ]}
            />
          </div>
        </div>
      </Dashboard>
    </Grid>
  );
}

export default ConversationCustomizations;
