import React from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

function EmptyTextarea({
  textLabel,
  textSubtitle,
  required,
  value,
  onChange,
  className,
  disabled,
  placeholder = "Enter description here",
  onBlur,
  maxLength = 1000,
}) {
  return (
    <div className='inputWrapper textAreaWrapper'>
      {textLabel && (
        <label className={`${required ? "showRequired" : ""}`}>
          {textLabel}
        </label>
      )}
      <TextareaAutosize
        maxRows={20}
        maxLength={maxLength}
        aria-label='maximum height'
        disabled={disabled}
        required={required}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
        onBlur={onBlur}
      />
      <span className='inputSubtitle'>{textSubtitle}</span>
    </div>
  );
}

export default EmptyTextarea;
