import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  mediaReleases,
  chatSettings,
  settingsBlue,
} from "../../../resources/images";
import { endUserActions } from "../../../store/endUser/endUserActions";
import { conversationAction } from "../../../store/conversations/conversationAction";
import { endpoints } from "../../../config/conversationConfig";
import { productInventoryActions } from "../../../store/product/productInventoryAction";
import { ConvInboxTypes } from "../../../config/Enums";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headingWrapper: {
    backgroundColor: "#E5F2FF !important",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    display: "flex",
    alignItems: "center",
  },
  svgIcon: {
    marginRight: "11px",
  },
  expanded: {
    backgroundColor: "rgba(24, 103, 185, 0.08)",
  },
  links: {
    flexDirection: "column",
    paddingBottom: "0",
  },
  allconversationlinks: {
    flexDirection: "column",
    paddingBottom: "0",
    paddingLeft: "0",
  },
  boxes: {
    boxShadow: "none",
  },
  hyperLink: {
    marginLeft: "15px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#3699FF",
    },
  },
  allconversationhyperLink: {
    marginLeft: "12px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#3699FF",
    },
  },
  activeTest: {
    color: "#3699FF",
  },
}));

export default function ConversationNav() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentRoot = history ? history.location.pathname : null;
  const { panel } = useSelector(state => state.endUserReducer);
  const { tabsList, selectedTab } = useSelector(
    state => state.conversationReducer
  );
  const [expanded, setExpanded] = useState(panel);
  const [inboxTypeList, setInboxTypeList] = useState([]);

  const loadInboxTyoeList = useCallback(
    ({ url, payload }) => {
      dispatch(
        productInventoryActions.onLoadData({
          url: url,
          payload: payload,
        })
      ).then(data => {
        if (data?.responseCode === 1)
          setInboxTypeList(data?.responseData || []);
          dispatch(
            conversationAction.onChange(
              "inboxTypeList",
              data?.responseData || []
            )
          );
      });
    },
    [dispatch]
  );

  useEffect(() => {
    loadInboxTyoeList({
      url: endpoints.conversation.getInboxTypes,
      payload: {},
    });
  }, [loadInboxTyoeList]);

  const redirectURL = () => {
    history.push("/conversation-settings");
  };

  const redirectConversation = () => {
    // history.push("/all-conversations");
    history.push("/conversations-list");
  };

  useEffect(() => {
    if (
      currentRoot === "/conversation-widget" ||
      currentRoot === "/product-categories" ||
      currentRoot === "/product-serials"
    ) {
      setExpanded("panel1");
      dispatch(endUserActions.onChange("panel", "panel1"));
    }
    if (
      currentRoot === "/newsroom-portal" ||
      currentRoot === "/user-dashboard" ||
      currentRoot === "/online-store"
    ) {
      setExpanded("panel2");
      dispatch(endUserActions.onChange("panel", "panel2"));
    }

    if (currentRoot === "/all-templates") {
      setExpanded("panel3");
      dispatch(endUserActions.onChange("panel", "panel3"));
    }

    if (currentRoot === "/rules") {
      setExpanded("panel4");
      dispatch(endUserActions.onChange("panel", "panel4"));
    }
  }, []);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    dispatch(endUserActions.onChange("panel", panel));
  };

  return (
    <div className='subNavWrapper'>
      <h1>
        Conversations
        {history.location.pathname === "/conversation-settings" ? (
          <img
            src={settingsBlue}
            className='chatSettingsIcon'
            alt='chat settings icon'
          />
        ) : (
          <img
            src={chatSettings}
            className='chatSettingsIcon'
            alt='chat settings icon'
            onClick={redirectURL}
          />
        )}
      </h1>
      <div className={classes.root}>
        <Accordion
        className={classes.boxes}
          expanded={true}
          onChange={handleChange("panel1")}>
          <AccordionSummary
            aria-controls='panel1a-content'
            id='panel1a-header'
            className={
              [
                "/all-conversations",
                "/conversations-list",
                "/company-inbox",
                "/press-inbox",
                "/support-inbox",
                "/sales-inbox",
                "/conversation-type"
              ].includes(currentRoot)
                ? classes.headingWrapper
                : null
            }>
            <Typography
              className={classes.heading}
              onClick={redirectConversation}>
              <img src={mediaReleases} alt='products' className='releaseIcon' />
              All Conversations
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.allconversationlinks}>
            {tabsList?.map((x, i) => (
              <span
                className={`${classes.allconversationhyperLink} ${
                  selectedTab == x.tabId ? classes.activeTest : null
                }`}
                onClick={() => {
                  dispatch(
                    conversationAction.onChange("selectedTab", `${x.tabId}`)
                  );
                  document
                    .getElementsByClassName(`rwt__tab conversation${i}`)[0]
                    ?.click();
                }}>
                {x.name}
              </span>
            ))}
            <Link
              to='/conversations-list'
              className={`${classes.hyperLink} ${
                currentRoot === "/conversations-list"
                  ? classes.activeTest
                  : null
              }`}>
              All Conversations
            </Link>
            {inboxTypeList?.map(itItem => (
              <Link
                to={ConvInboxTypes[itItem?.value].route}
                className={`${classes.hyperLink} ${
                  currentRoot === ConvInboxTypes[itItem?.value].route
                    ? classes.activeTest
                    : null
                }`}>
                {ConvInboxTypes[itItem?.value].title || "-"}
              </Link>
            ))}
            {/* <Link to='#' className={classes.allconversationhyperLink}>
              Press Inbox
            </Link>
            <Link to='#' className={classes.allconversationhyperLink}>
              Support Inbox
            </Link>
            <Link to='#' className={classes.allconversationhyperLink}>
              Sales / Orders Inbox
            </Link> */}
            {/* <NavLink
              to="#"
              className={classes.hyperLink}
              // className={`${classes.hyperLink} ${
              //   ["/product-serials", "/product-serial"].includes(currentRoot)
              //     ? classes.activeTest
              //     : null
              // }`}
            >
              Sales / Orders Inbox
            </NavLink> */}
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          className={classes.boxes}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
            className={
              currentRoot === "/newsroom-portal" ? classes.headingWrapper : null
            }>
            <Typography className={classes.heading}>
              <img
                src={mediaReleases}
                alt='inventory'
                className='releaseIcon'
              />
              Your Views
            </Typography>
          </AccordionSummary>
         <AccordionDetails className={classes.links}>
            <Link
              to="#"
              // className={classes.hyperLink}
              className={`${classes.hyperLink} ${
                currentRoot === "/newsroom-portal" ? classes.activeTest : null
              }`}>
              Support Conversations
            </Link>
            <Link to="#" className={classes.hyperLink}>
              Returns
            </Link>
          </AccordionDetails> 
        </Accordion>*/}

        <Accordion
          className={classes.boxes}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel3a-content'
            id='panel3a-header'
            className={
              currentRoot === "/all-releases" ||
              currentRoot === "/upcoming-releases" ||
              currentRoot === "/past-releases" ||
              currentRoot === "/release-category" ||
              currentRoot === "/new-media-release" 
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              <img
                src={mediaReleases}
                alt='inventory'
                className='releaseIcon'
              />
              Reviews/Feedbacks
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='#'
              className={classes.hyperLink}
              // className={`${classes.hyperLink} ${
              //   currentRoot === "/inventory" ||
              //   currentRoot === "/warehouses" ||
              //   currentRoot === "/purchase-orders"
              //     ? classes.activeTest
              //     : null
              // }`}
            >
              All Feedback
            </Link>
            <Link to='#' className={classes.hyperLink}>
              Feedback Types
            </Link>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
