import React, { useState, useEffect, useCallback } from "react";

import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashbard from "../Dashbard";
import LocationGrid from "./LocationGrid";
import NewLocation from "./NewLocation";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { locationsActions } from "../../../../store/locations/locationsActions";
import GridFilter from "./GridFilter";
import { Pagination } from "../../../../components/common";
import { GridLocationSideFilter } from "./GridLocationSideFilter";

function Location(props) {
  document.title = "OFish - Locations";

  const history = useHistory();
  const redirectUrl = () => {
    history.push("/new-location");
  };
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const {
    gridData,
    filterKey,
    fiterValue,
    paginationResponse,
    nameInternal,
    namePublic,
    dateCreated,
  } = useSelector(state => state.LocationsReducer);

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    key: "NameInternal",
    val: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      locationsActions.onGetLocationsList({
        nameInternal: nameInternal,
        namePublic: namePublic,
        dateCreated: dateCreated,
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.key,
        orderByVal: order.val,
      })
    ).then(data => {
      dispatch(locationsActions.onChange("locationId", 0));
      dispatch(locationsActions.onChange("isDeleted", 0));
      dispatch(locationsActions.onChange("locationNameInternal", ""));
      dispatch(locationsActions.onChange("locationNameTitle", "New Location"));
      dispatch(locationsActions.onChange("locationNamePublic", ""));
      dispatch(locationsActions.onChange("locationShortDescription", ""));
      dispatch(locationsActions.onChange("locationStreetAddress", ""));
      dispatch(locationsActions.onChange("locationCountryId", 0));
      dispatch(locationsActions.onChange("locationCityId", 0));
      dispatch(locationsActions.onChange("locationStateId", 0));
      dispatch(locationsActions.onChange("locationPostalCode", ""));
      dispatch(locationsActions.onChange("selectedLocations", []));
      dispatch(locationsActions.onChange("locationCountry", ""));
      dispatch(locationsActions.onChange("locationCity", ""));
      dispatch(locationsActions.onChange("locationState", ""));
      setLoading(false);
    });
  }, [
    dispatch,
    nameInternal,
    namePublic,
    dateCreated,
    page,
    rowsPerPage,
    order.key,
    order.val,
  ]);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, [dispatch, loadData, page, rowsPerPage]);
  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading  launchBtn'>
          <h1>Locations</h1>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={redirectUrl}>
            New Location
          </Button>
        </div>

        <div className='gridBody contentBody'>
          {/* <GridFilter loadData={() => loadData()} /> */}
          <GridLocationSideFilter loadData={() => loadData()} />
          <LocationGrid
            gridData={gridData}
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadData()}
            order={order}
            onOrderBy={setOrderBy}
          />

          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </div>
    </Dashbard>
  );
}

export default Location;
