import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import GeneralHeading from "../../../../components/common/GeneralHeading";
import { useDispatch, useSelector } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import Dashbard from "../Dashbard";
import LaunchCategoryGrid from "./LaunchCategoryGrid";
import { Pagination } from "../../../../components/common";
import Loader from "../../../../components/common/Loader";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  eventCatTextarea: {},
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function LaunchCategories() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [launcCategories, setLaunchCategories] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByName, setFilterByName] = useState("");
  const [order, setOrderBy] = useState({
    orderByKey: "Name",
    orderVal: 0,
  });

  const classes = useStyles();

  const loadLaunchCategory = useCallback(() => {
    setLoading(true);
    const modal = {
      name: filterByName,
      noOfLaunches: null,
      createdOn: null,
      color: null,
      isPublic: null,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    dispatch(launchesActions.onGetLaunchCategory(modal)).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          const launchList = data.data.data.map((x, index) => {
            return {
              id: x.id,
              name: x.name,
              noOfLaunches: x.noOfLaunches,
              createdOn: x.createdOn,
              color: x.color,
              isPublic: x.isPublic,
              checked: false,
            };
          });
          setPaginationResponse(data?.data?.pagination);
          setLaunchCategories(launchList);
          var count = launchList.find(x => x.checked === true);
          setIsDelete(count ? true : false);
        }
      }
      setLoading(false);
    });
  }, [
    dispatch,
    page,
    rowsPerPage,
    filterByName,
    order.orderByKey,
    order.orderVal,
  ]);

  useEffect(() => {
    loadLaunchCategory();
  }, [
    dispatch,
    page,
    rowsPerPage,
    filterByName,
    order.orderByKey,
    order.orderVal,
  ]);

  const redirectUrl = () => {
    history.push("/add-launch-categories");
  };

  const checkSelectedCategory = item => {
    launcCategories.map(x => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    var count = launcCategories.find(x => x.checked === true);
    setIsDelete(count ? true : false);
    setLaunchCategories([...launcCategories], launcCategories);
  };

  return (
    <Dashbard>
      <div className='body-content generalTabs'>
        {isLoading ? <Loader /> : null}
        <div className='consumerHeading backToCategory'>
          <Button color='primary' variant='contained'></Button>
        </div>
        <GeneralHeading
          headingName='Launch Categories'
          buttonText='Add New Category'
          onClick={redirectUrl}
        />
        <div className='gridBody contentBody'>
          <LaunchCategoryGrid
            launchCategoryData={launcCategories}
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadLaunchCategory()}
            selectedRecord={item => {
              checkSelectedCategory(item);
            }}
            isDelete={isDelete}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </div>
    </Dashbard>
  );
}
