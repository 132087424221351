import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, List, ListItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ExpandDownIcon } from "../../../LeftNav/SvgIcons";
import { CheckmarkIcon } from "../../SpaProduct/SvgIcons";
import { ConversationBasicsSetting } from "./ConversationBasicsSetting";
import { ConversationPermissionSetting } from "./ConversationPermissionSetting";
import ConversationSettingsStatusTypes from "./ConversationSettingsStatusTypes";
import { useHistory } from "react-router-dom";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../../../../components/common/Loader";

const ConversationSettingType = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: paramId } = useParams();
  const { 
    basicConversationData,
    conversationCompaginId,
    selectedConversationChannels,
  } = useSelector(
    state => state.conversationReducer
  );
  const [isAccordion1Open, setIsAccordion1Open] = useState(false);
  const [isAccordion2Open, setIsAccordion2Open] = useState(false);
  const [isAccordion3Open, setIsAccordion3Open] = useState(false);
  const [isAccordion1Done, setIsAccordion1Done] = useState(false);
  const [isAccordion2Done, setIsAccordion2Done] = useState(false);
  const [isAccordion3Done, setIsAccordion3Done] = useState(false);
  const [isAccordion1Edit, setIsAccordion1Edit] = useState(false);
  const [isAccordion2Edit, setIsAccordion2Edit] = useState(false);
  const [isAccordion3Edit, setIsAccordion3Edit] = useState(false);
  const [isNewStatusType, setIsNewStatustype] = useState(false);
  const [editId, setEditId] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const handelBasicAccordionSummary = () => {
    if (isAccordion1Done) {
      setIsAccordion1Open(!isAccordion1Open);
      setIsAccordion2Open(false);
      setIsAccordion3Open(false);
    }
  };
  const handelPermissionAccordionSummary = () => {
    if (isAccordion2Done) {
      setIsAccordion1Open(false);
      setIsAccordion2Open(!isAccordion2Open);
      setIsAccordion3Open(false);
    }
  };
  const handelStatusAccordionSummary = () => {
    if (isAccordion3Done) {
      setIsAccordion1Open(false);
      setIsAccordion2Open(false);
      setIsAccordion3Open(!isAccordion3Open);
    }
  };

  const basicConversationProps = (propName, value) => {
    dispatch(
      conversationAction.onChangeBasicConversationFields({ propName, value })
    );
  };

  const conversationSettingData = {
    productId: 2,
    campaignId: paramId ? parseInt(paramId) : 0,
    nameInternal: basicConversationData?.nameInternal || "",
    namePublic: basicConversationData?.namePublic || "",
    color: basicConversationData?.color,
    shortDescription: basicConversationData?.shortDescription || "",
    channels: selectedConversationChannels ? selectedConversationChannels?.map(channel => channel.id) : [],
    isPublic: basicConversationData?.isPublic || false,
    isGroupChat: basicConversationData?.isGroupChat || false,
    isDeplayedInAdminInbox:
      basicConversationData?.isDeplayedInAdminInbox || false,
    type: "",
    assignedInbox: basicConversationData?.assignedInbox?.toString() || "",
    status: basicConversationData?.status === "Active" ? 1 : basicConversationData?.status === "InActive" ? 0 : basicConversationData?.status === null ? 0  :basicConversationData?.status
  };
  const addUpdateConversation = () => {
    dispatch(
      conversationAction.onAddUpdateCampaignSettings(conversationSettingData)
    ).then(data => {
      if (data && data.responseCode === 1) {
        toast.success(data?.responseMsg);
        dispatch(
            conversationAction.onChange(
            "conversationCompaginId", data?.responseData.campaignId));
        setIsAccordion1Open(false);
        setIsAccordion1Done(true);
        setIsAccordion1Edit(false);
      } else {
        toast.error("Some thing went wrong!");
        setIsAccordion1Open(true);
        setIsAccordion1Done(false);
        setIsAccordion1Edit(true);
      }
    });
  };
  useEffect(() => {
    if (paramId) {
      var modal = {
        productId: 1,
        campaignId: paramId,
      };
      setLoading(true);
      dispatch(
        conversationAction.onGetCampaignSettingsByCampaignId(modal)
      ).then(data => {
        if (data?.responseMsg === "Success" && data?.responseData !== null) {
          setLoading(false);
          dispatch(
            conversationAction.onChange(
              "basicConversationData",
              data?.responseData
            )
          );

          const channels = data.responseData.channels?.map(obj => ({
            id: obj?.channelId,
            value: obj?.name,
            generic: obj?.category,
          }));
          dispatch(
            conversationAction.onChange(
              "selectedConversationChannels",
              channels ? channels : []
            )
          );
        }
      });
      dispatch(conversationAction.onGetConversationPermission(paramId))
    }
  }, [paramId]);

  return (
    <div className='conversationTypeInnerWrapper'>
      {isLoading ? <Loader /> : null}
      <h2 className='createConversationHeading'>
        Create a New Conversation Type
      </h2>
      <p className='createConversationDetial'>
        To create a new conversation type, enter basic details, permissions, and
        create status types that mark the status of the conversation.
      </p>
      <Grid className='conversationSettingAccordion'>
        <Accordion
          // defaultExpanded={true}
          expanded={Boolean(paramId) || isAccordion1Open}>
          <AccordionSummary
            expandIcon={<ExpandDownIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            onClick={handelBasicAccordionSummary}>
            <Grid className='conversationAccordianHeading'>
              <Typography>Conversation Basics</Typography>
              {!isAccordion1Open ? (
                <List>
                  <ListItem className='accordianHeading'>
                    Create a name, description, and choose conversation channels
                    and settings for this conversation type.
                  </ListItem>
                </List>
              ) : null}
            </Grid>
            {paramId ? (
              <Grid container alignItems='center' className='head'>
                <Button
                  color='primary'
                  variant='outlined'
                  className='editModuleBtn'
                  onClick={() => {
                    setIsAccordion1Edit(true);
                  }}>
                  Edit Module
                </Button>
              </Grid>
            ) : (
              <>
                {!isAccordion1Done ? (
                  <Grid container alignItems='center' className='head'>
                    {!isAccordion1Open ? (
                      <Button
                        color='primary'
                        variant='outlined'
                        className='startBtn'
                        onClick={() => {
                          setIsAccordion1Open(true);
                        }}>
                        start
                      </Button>
                    ) : null}
                  </Grid>
                ) : (
                  <Grid container alignItems='center' className='head'>
                    <CheckmarkIcon />
                  </Grid>
                )}
              </>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <ConversationBasicsSetting
              paramId={paramId}
              isAccordion1Edit={isAccordion1Edit}
              basicConversationProps={basicConversationProps}
              addUpdateConversation={addUpdateConversation}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          // defaultExpanded={true}
          expanded={Boolean(paramId) || isAccordion2Open}>
          <AccordionSummary
            expandIcon={<ExpandDownIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            onClick={handelPermissionAccordionSummary}>
            <Grid className='conversationAccordianHeading'>
              <Typography>Conversation Permissions</Typography>
              {!isAccordion2Open ? (
                <List>
                  <ListItem className='accordianHeading'>
                    Choose conversation permissions for different users and
                    groups.
                  </ListItem>
                </List>
              ) : null}
            </Grid>
            {paramId ? (
              <Grid container alignItems='center' className='head'>
                <Button
                  color='primary'
                  variant='outlined'
                  className='editModuleBtn'
                  onClick={() => {
                    setIsAccordion2Edit(true);
                  }}>
                  Edit Module
                </Button>
              </Grid>
            ) : (
              <>
                {!isAccordion2Done ? (
                  <Grid container alignItems='center' className='head'>
                    {!isAccordion2Open ? (
                      <Button
                        color='primary'
                        variant='outlined'
                        className='startBtn'
                        disabled={!isAccordion1Done}
                        onClick={() => {
                          setIsAccordion2Open(true);
                        }}>
                        start
                      </Button>
                    ) : null}
                  </Grid>
                ) : (
                  <Grid container alignItems='center' className='head'>
                    <CheckmarkIcon />
                  </Grid>
                )}
              </>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <ConversationPermissionSetting
              paramId={paramId}
              setIsAccordion2Done={setIsAccordion2Done}
              setIsAccordion2Open={setIsAccordion2Open}
              isAccordion2Edit={isAccordion2Edit}
              setIsAccordion2Edit={setIsAccordion2Edit}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          // defaultExpanded={true}
          expanded={Boolean(paramId) || isAccordion3Open}>
          <AccordionSummary
            expandIcon={<ExpandDownIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            onClick={handelStatusAccordionSummary}>
            <Grid className='conversationAccordianHeading'>
              <Typography>Conversation Status Types</Typography>
              {!isAccordion3Open ? (
                <List>
                  <ListItem className='accordianHeading'>
                    Choose OFish default or create custom status tags to
                    indicate the status of a conversation.
                  </ListItem>
                </List>
              ) : null}
            </Grid>

            {paramId && !isAccordion3Edit ? (
              <Grid container alignItems='center' className='head'>
                <Button
                  color='primary'
                  variant='outlined'
                  className='editModuleBtn'
                  onClick={() => {
                    setIsAccordion3Edit(true);
                    setIsAccordion3Open(true);
                  }}>
                  Edit Module
                </Button>
              </Grid>
            ) : (
              <>
                {!isAccordion3Done ? (
                  <Grid container alignItems='center' className='head'>
                    {!isAccordion3Open ? (
                      <Button
                        color='primary'
                        variant='outlined'
                        className='startBtn'
                        disabled={!isAccordion2Done}
                        onClick={() => {
                          setIsAccordion3Open(true);
                        }}>
                        start
                      </Button>
                    ) : null}
                    {isAccordion3Open ? (
                      <Button
                        color='primary'
                        variant='contained'
                        startIcon={<AddIcon />}
                        onClick={() => {
                          setIsNewStatustype(true);
                        }}>
                        New Status types
                      </Button>
                    ) : null}
                  </Grid>
                ) : (
                  <Grid container alignItems='center' className='head'>
                    <CheckmarkIcon />
                  </Grid>
                )}
              </>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <ConversationSettingsStatusTypes
              paramId={paramId}
              setIsAccordion3Done={setIsAccordion3Done}
              setIsAccordion3Open={setIsAccordion3Open}
              isAccordion3Edit={isAccordion3Edit}
              setIsAccordion3Edit={setIsAccordion3Edit}
              isNewStatusType={isNewStatusType}
              setIsNewStatustype={value => setIsNewStatustype(value)}
            />
          </AccordionDetails>
        </Accordion>
        <Grid className='buttonContainer' container justifyContent='flex-end'>
          {isAccordion3Done && !paramId ? (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                history.push(`/conversation-type/${conversationCompaginId}/edit`);
              }}
              className='saveAndReviewButton'>
              Save & Review
            </Button>
          ) : null}
        </Grid>
        <Grid className='buttonContainer' container justifyContent='flex-end'>
          {isAccordion3Done && paramId ? (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                history.push("/conversation-settings");
              }}
              className='saveAndReviewButton'>
              Finish
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};
export default ConversationSettingType;
