import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { PopupDialog } from "../../../../components/common/PopupDialog";
import { Chevron, Close, Cross, Magnifier } from "../../LeftNav/SvgIcons";
import { Add } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { conversationAction } from "../../../../store/conversations/conversationAction";
import { endUserActions } from "../../../../store/endUser/endUserActions";

const ConvAssigneModal = ({
  cancelbtn,
  deletebtn,
  oncloseaction,
  isvisible,
  maxwidth,
  roomDetail,
  setLoading,
  convAssigne,
  setConvAssigne,
  onUpdateAssignee,
}) => {
  
  const dispatch = useDispatch();
  const [selectedConvAssigne, setSelectedConvAssigne] = useState("");

  const onSaveButton = () => {
    setLoading(true);
    dispatch(
      conversationAction.onMarkRoomAssignee({
        userId: selectedConvAssigne?.id,
        roomId: roomDetail?.roomId,
      })
    ).then(statusesResponse => {
      setLoading(false);
      if (statusesResponse?.responseCode === 1) {
        onUpdateAssignee(selectedConvAssigne?.id);
        oncloseaction();
      }
    });
  };
  
  return (
    <PopupDialog
      visible={isvisible}
      onClose={oncloseaction}
      maxWidth={maxwidth}>
      <div className='newContactPopupWrapper deletePopupMainWrapper conTypePopupWrapper convTagsWrapper'>
        <div className='newContactPopupInnerWrapper'>
          <span className='innerHeading'>Conversation Assignee</span>
          <i onClick={oncloseaction}>
            {" "}
            <Close />
          </i>
        </div>
        <div className='deleteContactBody'>
          <div className='ConvTagsHeader'>
            <Chevron />
            <div className='selectedTagsWrapper'>
              {selectedConvAssigne !== "" && (
                <span>
                  {`${selectedConvAssigne?.firstName} ${selectedConvAssigne?.lastName} < ${selectedConvAssigne?.email} >`}
                  <i
                    onClick={() => {
                      setSelectedConvAssigne("");
                    }}>
                    <Cross />
                  </i>
                </span>
              )}

              {/* <span>
                Bug Report{" "}
                <i>
                  <Cross />
                </i>
              </span>
              <span>
                Tag Option{" "}
                <i>
                  <Cross />
                </i>
              </span> */}
            </div>
          </div>

          <span className='conTypeButtonWrapper tagsBtns'>
            <Grid
              container
              spacing={2}
              style={{ paddingLeft: "35px", paddingRight: "25px" }}>
              {convAssigne
                ?.filter(item => item?.id !== selectedConvAssigne?.id)
                ?.map(x => (
                  <Grid item xs={12}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      setSelectedConvAssigne(x);
                    }}>
                    {`${x?.firstName} ${x?.lastName} < ${x?.email} >`}
                  </Button>
                  </Grid>
                ))}
              {/*<Grid item xs={6}>
                <Button color='primary' variant='contained' endIcon={<Add />}>
                  Tag Type
                </Button>

                <Button color='primary' variant='contained' endIcon={<Add />}>
                  Example Tag
                </Button>

                <Button color='primary' variant='contained' endIcon={<Add />}>
                  Long Tag Name
                </Button>

                <Button color='primary' variant='contained' endIcon={<Add />}>
                  Tag Option
                </Button>
              </Grid>
               <Grid item xs={6}>
                <Button color='primary' variant='contained' endIcon={<Add />}>
                  Tag Option
                </Button>

                <Button color='primary' variant='contained' endIcon={<Add />}>
                  Tag Option
                </Button>

                <Button color='primary' variant='contained' endIcon={<Add />}>
                  Tag Option
                </Button>

                <Button color='primary' variant='contained' endIcon={<Add />}>
                  Long Tag Name
                </Button>
              </Grid> */}
            </Grid>
          </span>
        </div>

        <span className='deleteContactActions'>
          <Button onClick={oncloseaction}>Cancel</Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              onSaveButton();
            }}>
            Save
          </Button>
        </span>
      </div>
    </PopupDialog>
  );
};

export { ConvAssigneModal };
