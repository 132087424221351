import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Add, Close } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
} from "@material-ui/core";
import { EditIcon, Delete } from "../../LeftNav/SvgIcons";
import {
  InputField,
  Pagination,
  PopupDialog,
} from "../../../../components/common";
import Textarea from "../../../../components/common/Textarea";
import { GridFilterBarBusinessCustomers } from "../BusinessConsumers/components";
import { ValidatorForm } from "react-material-ui-form-validator";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import moment from "moment";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
}));

const EndUserNotesGrid = ({ isActive, isEndUser, isBusUser }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isNotesPopup, setIsNotesPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allUserNotes, setAllUserNotes] = useState([]);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [noteId, setNoteId] = useState(null);
  const [isBusinessUser, setIsBusinessUser] = useState(false);
  const [cntactId, setCntactId] = useState(null);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (isActive) {
      if (selectedUser?.id) {
        setCntactId(selectedUser?.id);
        if (isEndUser) {
          setIsBusinessUser(false);
          loadNotes(true, selectedUser?.id);
        }
        if (isBusUser) {
          setIsBusinessUser(true);
          loadNotes(false, selectedUser?.id);
        }
      }
    }
  }, [location, isActive, isEndUser, isBusUser]);

  const loadNotes = (isEndUsr, cntId) => {
    setIsLoading(true);
    let getNoteUrl = "";
    if (isEndUsr) {
      getNoteUrl = `${"/Customers/GetCustomerNotes?UserId="}${cntId}${"&PageNumber="}${page}${"&NoOfRecords="}${rowsPerPage}`;
    } else {
      getNoteUrl = `${"/Companies/GetCompanyNotes?CompanyId="}${cntId}${"&PageNumber="}${page}${"&NoOfRecords="}${rowsPerPage}`;
    }
    dispatch(endUserActions.onGetUserNotes(getNoteUrl)).then(data => {
      if (data && data.success) {
        setAllUserNotes(data.data);
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
      setIsLoading(false);
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (isEndUser) {
      addUpdateEUNotes();
    }
    if (isBusUser) {
      addUpdateBUNotes();
    }
  };

  const addUpdateEUNotes = () => {
    const noteModel = {
      id: noteId ? noteId : 0,
      title: noteTitle,
      notes: noteDescription,
      userId: cntactId,
      isDeleted: false,
    };
    setIsLoading(true);
    dispatch(endUserActions.onAddUpdateUserNotes(noteModel)).then(data => {
      setIsLoading(false);
      if (data && data.success) {
        setIsNotesPopup(false);
        toast.success("Note added successfully");
        loadNotes(true, cntactId);
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };

  const addUpdateBUNotes = () => {
    const noteModel = {
      id: noteId ? noteId : 0,
      title: noteTitle,
      notes: noteDescription,
      companyId: cntactId,
    };
    setIsLoading(true);
    dispatch(endUserActions.onAddUpdateBUNotes(noteModel)).then(data => {
      setIsLoading(false);
      if (data && data.success) {
        setIsNotesPopup(false);
        toast.success("Note added successfully");
        loadNotes(false, cntactId);
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };

  const onDeleteClick = value => {
    if (isEndUser) {
      onDeleteEUNotesPress(value);
    }
    if (isBusUser) {
      onDeleteBUNotesPress(value);
    }
  };

  const onDeleteEUNotesPress = selectedNoteId => {
    setIsLoading(true);
    dispatch(endUserActions.onDeleteUserNotes(selectedNoteId)).then(data => {
      if (data && data.success) {
        toast.success("Note deleted successfully");
        loadNotes(true, cntactId);
      } else {
        setIsLoading(false);
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };

  const onDeleteBUNotesPress = selectedNoteId => {
    setIsLoading(true);
    dispatch(endUserActions.onDeleteBUNotes(selectedNoteId)).then(data => {
      if (data && data.success) {
        toast.success("Note deleted successfully");
        loadNotes(false, cntactId);
      } else {
        setIsLoading(false);
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : null}

      <div
        className='gridBody contentBody businessCustomerGrid activityGridSearch'
        style={{ width: "100%" }}>
        <Button
          className='addNotesBtn'
          color='primary'
          variant='contained'
          onClick={() => {
            setIsNotesPopup(true);
            setNoteDescription("");
            setNoteTitle("");
            setNoteId(null);
          }}
          startIcon={<Add />}>
          New Note
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GridFilterBarBusinessCustomers />
          </Grid>
        </Grid>
        <hr className='horizontalSeperator' />
        <h2 className='gridInnerHeading'>Notes</h2>
        {/* <span className='gridPagination' style={{ marginTop: "18px" }}>
          <i>
            {page}-{rowsPerPage} of&nbsp;
            {paginationResponse?.totalRecords}
          </i>
        </span> */}

        <div className='gap' />

        <>
          <Table>
            <TableHead className={classes.gridHeader}>
              <TableRow className={classes.gridHeaderRow}>
                <TableCell className={classes.gridHeaderInner}>
                  Date
                  {/* <TableSortLabel
                active={order.orderByKey === "CompanyName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CompanyName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
              /> */}
                </TableCell>
                <TableCell className={classes.gridHeaderInner}>
                  Subject
                  {/* <TableSortLabel
                active={order.orderByKey === "TotalUsers"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "TotalUsers",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
              /> */}
                </TableCell>
                <TableCell className={classes.gridHeaderInner}>
                  Description
                </TableCell>

                <TableCell
                  className={classes.gridHeaderInner}
                  style={{ textAlign: "right" }}>
                  Quick Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allUserNotes?.map((item, index) => {
                return (
                  <TableRow key={index} className='gridBodyRow endUserRow'>
                    <TableCell className={classes.gridBodyInner}>
                      {moment(item.notesDate).format("MMMM DD, YYYY")}
                    </TableCell>
                    <TableCell className={classes.gridBodyInner}>
                      {item.title}
                    </TableCell>
                    <TableCell className={classes.gridBodyInner}>
                      {item.notes}
                    </TableCell>

                    <TableCell
                      className={classes.gridBodyInner}
                      style={{ textAlign: "right" }}>
                      <div
                        className='gridActions'
                        style={{
                          justifyContent: "flex-end",
                        }}
                        t>
                        <span
                          onClick={() => {
                            setIsNotesPopup(true);
                            setNoteTitle(item.title);
                            setNoteDescription(item.notes);
                            setNoteId(item.id);
                          }}>
                          <EditIcon />
                        </span>
                        <span onClick={() => onDeleteClick(item.id)}>
                          <Delete />
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {/* <Pagination
          recordCount={paginationResponse?.totalRecords}
          page={page - 1}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        /> */}
        </>

        {/* {!isListView && <EndUserNotesList />} */}

        {isNotesPopup && (
          <PopupDialog
            visible={isNotesPopup}
            maxWidth='1000px'
            onClose={() => setIsNotesPopup(false)}>
            <div className='NotesPopupWrapper'>
              <div className='audiancePopupInnerWrapper'>
                <span className='innerHeading'>New Note</span>
                <span className='closeBtn'>
                  <Close
                    style={{
                      cursor: "pointer",
                      padding: "20px",
                    }}
                    onClick={() => setIsNotesPopup(false)}
                  />
                </span>
              </div>
              <ValidatorForm onSubmit={handleSubmit}>
                <div className='addNotesFormWrapper'>
                  <InputField
                    inputlabel='Subject'
                    placeholder='Type an answer'
                    value={noteTitle}
                    onChange={e => setNoteTitle(e.target.value)}
                    validators={["required"]}
                    errorMessages={["Please enter title"]}
                  />
                  <div className='gap' />
                  <InputField
                    inputlabel='Description'
                    placeholder='Type an answer'
                    onChange={e => setNoteDescription(e.target.value)}
                    value={noteDescription}
                    validators={["required"]}
                    errorMessages={["Please enter description"]}
                  />
                  <div className='gap' />
                  <Textarea textLabel='Large Text Field Header' />
                </div>
                <span className='saveBtn'>
                  <Button color='primary' variant='contained' type='submit'>
                    Save
                  </Button>
                </span>
              </ValidatorForm>
            </div>
          </PopupDialog>
        )}
      </div>
    </>
  );
};

export default EndUserNotesGrid;
