export const launchesTypes = {
  LAUNCHES_ONCHANGE: "LAUNCHES_ONCHANGE",
  SELECTED_LAUNCHES: "SELECTED_LAUNCHES",
  SELECTED_RELEASES: "SELECTED_RELEASES",
  SELECTED_REL_CATEGORY: "SELECTED_REL_CATEGORY",
  SELECTED_COLLECTION_ASSET: "SELECTED_COLLECTION_ASSET",
  SELECT_RELEASE_VISIBILITY: "SELECT_RELEASE_VISIBILITY",
  PRE_RELEASES: "PRE_RELEASES",
  ALL_SELECTED_LANUCHES:"ALL_SELECTED_LANUCHES",
  ALL_SELECTED_RELEASES:"ALL_SELECTED_RELEASES",
};
