import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { CompanyUsers } from "./CompanyUsers";
import { OFishConnections } from "./OFishConnections";
import { CompanyBasics } from "./CompanyBasics";
import { CompanyLocations } from "./CompanyLocations";
import { contactsActions } from "../../../../../store/contacts/contactsActions";

const NewCompany = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedData = queryString.parse(location.search);
  const [bulkCopyData, setBulkCopyData] = useState([]);
  const [allBUConnections, setAllBUConnections] = useState([]);
  const [listData, setListData] = useState([]);
  const [listUserData, setUserListData] = useState([]);
  const [listLocationData, setListLocationData] = useState([]);
  const [listUserDataBackup, setUserListDataBackup] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (selectedData?.id) {
      loadUser();
    }
  }, []);

  const loadData = () => {
    if (selectedData?.id) {
      dispatch(contactsActions.onGetBUConnectionList(selectedData?.id)).then(
        data => {
          setAllBUConnections(data?.data);
          setListData(data?.data);
        }
      );
    }
  };

  const loadUser = () => {
    dispatch(
      contactsActions.onGetCompanyUsers(parseInt(selectedData?.id))
    ).then(data => {
      if (data?.success) {
        setUserListData(data?.data);
        setUserListDataBackup(data?.data);
      } else {
        setUserListData([]);
        setUserListDataBackup([]);
      }
    });
  };

  return (
    <>
      <CompanyBasics />
      <CompanyUsers
        connectionData={listData}
        listUserData={listUserData}
        setUserListData={setUserListData}
        listUserDataBackup={listUserDataBackup}
        setUserListDataBackup={setUserListDataBackup}
        loadUser={loadUser}
      />
      <CompanyLocations
        listUser={listUserData}
        listData={listLocationData}
        setListData={setListLocationData}
      />
      {/* <OFishConnections
        listUser={listUserData}
        bulkCopyData={bulkCopyData}
        setBulkCopyData={setBulkCopyData}
        allBUConnections={allBUConnections}
        setAllBUConnections={setAllBUConnections}
        listData={listData}
        setListData={setListData}
        loadData={loadData}
        listLocationData={listLocationData}
      /> */}
    </>
  );
};

export default NewCompany;
