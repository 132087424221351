import React, { useState, memo, useEffect, useRef } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Tabs, Tab, TabPanel } from "react-web-tabs";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import "react-web-tabs/dist/react-web-tabs.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginTop: "20px",
  },
  tabsHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    marginBottom: "15px",
    paddingBottom: "5px",
  },
  tabsHeaderTitle: {
    marginRight: "20px !important",
  },
  arrowIcon: {
    color: "#3699FF",
    fontSize: "15px",
  },
  iconButton: {
    "&.MuiIconButton-root:hover": {
      background: "transparent !important",
    },
  },
  iconButtonSelected: {
    marginBottom: "15px",
    "&.MuiIconButton-root:hover": {
      background: "transparent !important",
    },
  },
}));


export const TopTabNavigatorPricing = memo(
  ({ tabs, getActiveTabId }) => {
    const classes = useStyles();
    const scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);

    useEffect(() => {
      if (tabs?.length && getActiveTabId) {
        getActiveTabId(tabs[0].id)
      }
    }, []);

    //Slide click
    const slide = (shift) => {
      scrl.current.scrollLeft += shift;
      setscrollX(scrollX + shift);

      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
        scrl.current.offsetWidth
      ) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    };

    const scrollCheck = () => {
      setscrollX(scrl.current.scrollLeft);
      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
        scrl.current.offsetWidth
      ) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    };
    return (
      <>
        <Tabs
          defaultTab={tabs?.[0]?.id}
          // value={value}
          onChange={(tabId) => {
            getActiveTabId && getActiveTabId(tabId);
          }}
        >
          <div style={{ display: "flex" }}>
            {scrollX !== 0 && (
              <IconButton
                className={classes.iconButton}
                onClick={() => slide(-50)}
              >
                <ArrowBackIos className={classes.arrowIcon} />
              </IconButton>
            )}


            <div ref={scrl} onScroll={scrollCheck} className="mainTabs" >

              {tabs?.map((t, index) => (
                <Tab
                  className="pricing-tabs-container"
                  style={{ marginRight: "8px" }}
                  value={`${index}`}
                  key={index}
                  tabFor={t.id}
                >
                  {t.title} <i className="msgCount">{t.msgCount}</i>
                </Tab>
              ))}
            </div>
            {!scrolEnd && (
              <IconButton
                className={classes.iconButton}
                onClick={() => slide(+50)}
              >
                <ArrowForwardIos className={classes.arrowIcon} />
              </IconButton>
            )}
          </div>

          {tabs?.map((t) => (

            <TabPanel key={t.id} tabId={t.id} className="tab-panel">
              <Grid container className={classes.gridWrapper}>
                {t.component}
              </Grid>
            </TabPanel>
          ))}

        </Tabs>
      </>
    );
  }
);

