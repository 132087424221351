import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import _ from "lodash";
import InputField from "../../../../../components/common/InputField";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import BusinessHours from "./BusinessHours";
import SelectImage from "./selectImage";
import ContactEmail from "./ContactEmail";
import SocialMedia from "./SocialMedia";
import ContactNumber from "./ContactNumber";
import Loader from "../../../../../components/common/Loader";
import { AddressAutoComplete } from "../../../../../components/common";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "6px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  hoursWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function ContactInformation(props) {
  const dispatch = useDispatch();
  let isDisable = [];
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [pressImg, setPressImg] = useState("");
  const [pressImgId, setPressImgId] = useState(0);
  const [pressContactName, setPressContactName] = useState("");
  const [pressContactEmail, setPressContactEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [comState, setComState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [allCountries, setAllCountries] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [allState, setAllState] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [emailValidate, setEmailValidate] = useState(false);
  const [isEditAble, setIsEditAble] = useState([]);
  const classes = useStyles();

  const loadData = () => {
    dispatch(contactsActions.onGetCountries()).then(data => {
      if (data && data.success) {
        setAllCountries(data.data);
      }
    });
    dispatch(contactsActions.onGetCities()).then(data => {
      if (data && data.success) {
        setAllCities(data.data);
      }
    });
    dispatch(contactsActions.onGetProvinces()).then(data => {
      if (data && data.success) {
        setAllState(data.data);
      }
    });
    setLoading(true);
    dispatch(newsroomActions.onGetContactInfo()).then(cnt => {
      if (cnt && cnt.success && cnt.data) {
        setPressContactEmail(cnt.data.pressContactEmail);
        setPressContactName(cnt.data.pressContact);
        setPressImg(cnt.data.imageURL);
        setCompanyName(cnt.data.companyName);
        setAddress(cnt.data.address);
        setCity(cnt.data.city ? cnt.data.city : "");
        setComState(cnt.data.state ? cnt.data.state : "");
        setZipCode(cnt.data.zipCode);
        setCountry(cnt.data.country ? cnt.data.country : "");
        let finalEmails = [];
        _.forEach(cnt.data.emails, function (eml) {
          const res = { email: eml.email, isValid: true };
          finalEmails.push(res);
        });
        dispatch(newsroomActions.onChange("emails", finalEmails));
        dispatch(newsroomActions.onChange("contacts", cnt.data.contacts));
        dispatch(newsroomActions.onChange("faxNumber", cnt.data.faxNumber));
        dispatch(
          newsroomActions.onChange("faxCountryCode", cnt.data.faxCountryCode)
        );
        dispatch(newsroomActions.onChange("socials", cnt.data.socialMedia));
        dispatch(
          newsroomActions.onChange("businessHours", cnt.data.businessHours)
        );
      } else {
        toast.error(cnt?.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const uploadCompanyImage = file => {
    setLoading(true);
    dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
      if (data && data.success && data.data) {
        setPressImgId(data.data.id);
        setPressImg(data.data.url);
        setShowFeatureImage(!showFeatureImage);
      }
      setLoading(false);
    });
  };

  const onUpdateComContact = () => {
    let isDataValidate = false;
    if (
      pressContactName.length < 50 &&
      pressContactEmail.length < 50 &&
      companyName.length < 50 &&
      address.length < 100 &&
      zipCode.length < 50
    ) {
      const inValidExist = emails.find(ml => {
        return ml.isValid === false;
      });
      if (inValidExist || emailValidate === true) {
        toast.error("Your email is not correct");
      } else {
        let finalEmails = [];
        _.forEach(emails, function (eml) {
          if (eml.email && eml.email.trim() !== "") {
            if (eml.email.length > 50) {
              isDataValidate = true;
            }
            finalEmails.push(eml.email);
          }
        });
        let finalContacts = [];
        _.forEach(contacts, function (cont) {
          if (cont.number && cont.number.trim() !== "") {
            if (/^[0-9]*$/.test(cont.number)) {
              const res = {
                countryCode: cont.countryCode,
                number: cont.number,
              };
              finalContacts.push(res);
            } else {
              isDataValidate = true;
            }
          }
        });
        if (!/^[0-9]*$/.test(faxNumber)) {
          isDataValidate = true;
        }
        if (!/^(?:[A-Z0-9a-z ]+([-_ ]?[A-Z0-9a-z]+)*)?$/.test(zipCode)) {
          isDataValidate = true;
        }
        if (!isDataValidate) {
          setLoading(true);
          // finalEmails.reverse();
          let finalBusiness = [];
          _.forEach(businessHours, function (bshr) {
            const res = {
              day: bshr.day,
              openingTime: bshr.openingTime,
              closingTime: bshr.closingTime,
              closed: bshr.closed,
            };
            finalBusiness.push(res);
          });
          let finalSocials = [];
          _.forEach(socials, function (scl) {
            if (scl.link && scl.link.trim() !== "") {
              const res = { name: scl.name, link: scl.link };
              finalSocials.push(res);
            }
          });

          let finalDataUpdate = {
            pressContact: pressContactName,
            pressContactEmail: pressContactEmail,
            imageURL: pressImg,
            companyName: companyName,
            address: address,
            city: city,
            state: comState,
            zipCode: zipCode,
            country: country,
            contacts: finalContacts,
            faxNumber: faxNumber,
            faxCountryCode: faxCountryCode,
            email: finalEmails,
            socials: finalSocials,
            businessHours: finalBusiness,
          };
          dispatch(newsroomActions.onSaveComContact(finalDataUpdate)).then(
            res => {
              if (res && res.success) {
                loadData();
                toast.success("Contact information updated successfully");
              } else {
                toast.error(res.message);
              }
              setLoading(false);
            }
          );
        }
      }
    }
  };
  const checkEmailValidation = value => {
    if (/^\S+@\S+\.\S+$/.test(value)) {
      setEmailValidate(false);
    } else {
      setEmailValidate(true);
    }
  };
  const {
    emails,
    contacts,
    faxNumber,
    faxCountryCode,
    socials,
    businessHours,
  } = useSelector(state => state.newsRoomReducer);
  return (
    <div className='accordianOuterWrapper'>
      {isLoading ? <Loader /> : null}
      <AccordionContainer title='Contact Information'>
        <div className={classes.root}>
          <label className='innerLabel'>Press Contact</label>
          <Grid container spacing={3}>
            <Grid item xs={5} className={classes.gridWrapper}>
              <InputField
                placeholder='Press Contact Name '
                inputlabel='Press Contact'
                value={pressContactName}
                onChange={e => setPressContactName(e.target.value)}
              />
              {pressContactName.length > 50 && (
                <span style={{ color: "red" }}>
                  press contact name characters length cannot be exceed from 50
                  characters
                </span>
              )}
            </Grid>
            <Grid
              item
              xs={5}
              className={[
                classes.gridWrapper,
                `${emailValidate ? "formErrorWrapper" : ""}`,
              ]}>
              <InputField
                placeholder='Press E-mail'
                inputlabel='Press Contact E-mail'
                value={pressContactEmail}
                onChange={e => setPressContactEmail(e.target.value)}
                onBlur={e => checkEmailValidation(e.target.value)}
              />
              <span style={{ color: "red" }}>
                {emailValidate ? "InValid email format" : ""}
              </span>
              {pressContactEmail.length > 50 && (
                <span style={{ color: "red" }}>
                  press email characters length cannot be exceed from 50
                  characters
                </span>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              className={[classes.gridWrapper, "buttonOutlined"]}>
              <label>Press Contact Photo</label>
              <div className='leaderShipUploadPic'>
                {pressImg !== "" && (
                  <img
                    alt='feature iamge'
                    className='featureRoundImg'
                    src={pressImg}
                  />
                )}
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => setShowFeatureImage(!showFeatureImage)}
                  className={classes.selectBtn}>
                  {pressImg !== "" && pressImg !== "string"
                    ? "Change"
                    : "Select"}
                </Button>
              </div>
              {showFeatureImage && (
                <SelectImage
                  closePopup={() => setShowFeatureImage(false)}
                  openPopup={showFeatureImage}
                  title={"Upload Company Image"}
                  addProfileImage={value => uploadCompanyImage(value)}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <div className='gap' />
        <div className='gap' />
        <div>
          <label className='innerLabel'>Company Address</label>
          <Grid item xs={5} className={classes.gridWrapper}>
            <InputField
              placeholder='Aquatic AV  '
              inputlabel='Company Name  '
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
              validators={["matchRegexp:^.{0,50}$"]}
              errorMessages={["Maximum length is 50 characters"]}
            />
            {/* {companyName.length > 50 && (
              <span style={{ color: "red" }}>
                company name characters length cannot be exceed from 50
                characters
              </span>
            )} */}
          </Grid>
          <Grid item xs={10} className={classes.gridWrapper}>
            {/* <InputField
              placeholder='Dealers '
              inputlabel='Address  '
              value={address}
              onChange={e => setAddress(e.target.value)}
              validators={["matchRegexp:^.{0,100}$"]}
              errorMessages={["Maximum length is 100 characters"]}
            /> */}
            <AddressAutoComplete
              address={address}
              autoComId={"popupEnvLocInput"}
              onChange={e => {
                setAddress(e.target.value);
              }}
              setPlace={p => {
                isDisable = [];
                setCity("");
                setComState("");
                setCountry("");
                setZipCode("");
                const val = document.getElementById("popupEnvLocInput")?.value;
                p?.address_components?.forEach(x => {
                  if (x?.types?.includes("route")) {
                    setAddress(x?.long_name);
                  }
                  if (x?.types?.includes("locality")) {
                    setCity(x?.long_name);
                    isDisable.push("city");
                  }
                  if (x?.types?.includes("administrative_area_level_1")) {
                    setComState(x?.long_name);
                    isDisable.push("state");
                  }
                  if (x?.types?.includes("country")) {
                    setCountry(x?.long_name);
                    isDisable.push("country");
                  }
                  if (x?.types?.includes("postal_code")) {
                    setZipCode(x?.long_name);
                    isDisable.push("postalCode");
                  }
                });
                setIsEditAble(isDisable);
              }}
            />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.gridWrapper}>
              <InputField
                placeholder='Enter City'
                inputlabel='City'
                validators={["required"]}
                errorMessages={["Please enter city"]}
                value={city}
                onChange={v => setCity(v.target.value)}
                // disabled={isEditAble.includes("city") ? true : false}
              />
            </Grid>
            <Grid item xs={4} className={classes.gridWrapper}>
              <InputField
                placeholder='Enter State'
                inputlabel='State'
                validators={["required"]}
                errorMessages={["Please enter state"]}
                value={comState}
                onChange={v => setComState(v.target.value)}
                // disabled={isEditAble.includes("state") ? true : false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.gridWrapper}>
              <InputField
                placeholder='95112'
                inputlabel='Zip code or postal code'
                value={zipCode}
                onChange={e => setZipCode(e.target.value)}
                validators={[
                  "matchRegexp:^(?:[A-Z0-9a-z ]+([-_ ]?[A-Z0-9a-z]+)*)?$",
                  "matchRegexp:^.{0,15}$",
                ]}
                errorMessages={[
                  "Special characters and multiple spaces are not allowed",
                  "Maximum length is 15 characters",
                ]}
                // disabled={isEditAble.includes("postalCode") ? true : false}
              />
            </Grid>
            <Grid item xs={4} className={classes.gridWrapper}>
              <InputField
                placeholder='Enter country or region'
                inputlabel='Country or region'
                validators={["required"]}
                errorMessages={["Please enter country or region"]}
                value={country}
                onChange={v => setCountry(v.target.value)}
                // disabled={isEditAble.includes("country") ? true : false}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          {/* <CompanyAddress /> */}
          <ContactNumber />
          <ContactEmail />
          <BusinessHours />
          <SocialMedia />
        </div>
        <span className='saveChangesBtn'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => onUpdateComContact()}>
            Save Changes
          </Button>
        </span>
      </AccordionContainer>
    </div>
  );
}
