import React from "react";

function PressContact({
  picture,
  heading,
  subheading,
  email,
  inqemail,
  inquiry,
  emails
}) {
  return (
    <div className='pressContactWrapper inqWrapper'>
      <ul>
        <li>
          <span className='pressPicture'>
            <img src={picture} alt='pic' />
          </span>
        </li>
        <li>
          <h2>{heading}</h2>
          <span className='subheading'>{subheading}</span>
          <span className='email'>{email}</span>
        </li>
      </ul>

      <div className='inquiry'>
        <h2>{inquiry}</h2>
        <span className='email'>{inqemail}</span>
        {emails.map(em => (
          <span className='email'>{em.email}</span>
        ))}
      </div>
    </div>
  );
}

export default PressContact;
