import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Chip,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
} from "@material-ui/core";
import { ExpandMore, MoreHoriz } from "@material-ui/icons";
import { MdClear } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { searchIcon, crossImg } from "../../../../resources/images";
import { Address, FilterCreationDate, ProductDetails, UserDetails } from ".";
import { useDebounce } from "../../../../utils/use-debounce";
import InputField from "../../../../components/common/InputField";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import OutsideClickHandler from "react-outside-click-handler";
import {
    // FilterIcon,
    GridHide,
    ListShow,
    ListHide,
    GridShow,
    FilterArrow,
} from "../../LeftNav/SvgIcons";
import { endUserActions } from "../../../../store/endUser/endUserActions";

const ChipItem = ({ chipLabel, chipValue, onDelete }) => {
    const classes = useStyless();

    return (
        <Chip
            className={classes.MuiChip}
            deleteIcon={<MdClear style={{ width: "16px", height: "16px" }} />}
            label={
                <span className={classes.MuiChip}>
                    <b>{chipLabel}: </b>
                    {chipValue}
                </span>
            }
            onDelete={onDelete}
        />
    );
};

const useStyless = makeStyles(theme => ({
    gridWrapper: {
        paddingTop: "29px",
        paddingBottom: "20px",
        borderBottom: "1px solid #E2E6E9",
        position: "relative",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    MuiChip: {
        backgroundColor: "#CDE5FF",
        color: "#70808F",
        maxWidth: "99%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left",
        margin: "0px 5px 5px 0px",
    },
    chip: {
        margin: 2,
    },
    dropdownBg: {
        background: "#f1f1f1",
    },
    formControl: {
        minWidth: "100%",
        background: "transparent",
    },
    selectEmpty: {
        border: "1px solid #F3F6F9",
        backgroundColor: "#F3F6F9",
        borderRadius: "6px",
        "&:before": {
            borderBottom: "1px solid #F3F6F9",
            "&:hover": {
                borderBottom: "0 !important",
            },
        },
        "&:after": {
            borderBottom: "1px solid #F3F6F9",
        },

        MuiSvgIcon: {
            root: {
                fontSize: 20,
            },
        },
    },
    MuiSelect: {
        icon: {
            fontSize: "26px",
        },
        iconOutlined: {
            fontSize: "26px",
        },
    },
    MuiFilledInput: {
        root: {
            "&:hover": {
                backgroundColor: "#F3F6F9",
            },
        },
    },
    dropdownWrapper: {
        width: "650px",
        padding: "15px 37px 40px 37px",
        position: "fixed",
        boxShadow: "0px 0px 13px 0px #ccc",
        right: "95px",
        marginTop: "-55px",
    },
}));

const productStatusList = [
    { id: 0, name: "Registration Pending" },
    { id: 1, name: "Registered" },
    { id: 5, name: "Registration Denied" },
    { id: -2, name: "Receipt Missing" },
    { id: 6, name: "Mark as Incomplete" },
];

const handleSubmit = e => {
    e.preventDefault();
};

const colors = [
    { color: "#015dfe" },
    { color: "#00d0ff" },
    { color: "#f9b900" },
    { color: "#ff0092" },
    { color: "#49b8ef" },
    { color: "#eb5757" },
    { color: "#66de64" },
    { color: "#0a42e9" },
    { color: "#ef64b3" },
    { color: "#e8edef" }
];


const EndUserGroups = ({
    IsFilterApplied,
    PaginationResponse,
    AppliedFilters,
    onApplyFilters,
    ActiveTabId,
    setActiveTabId,
    isListView,
    onToggleListView,
    title,
}) => {

    const classes = useStyless();
    const dispatch = useDispatch();
    const [colorOption, setColorOption] = useState(false);
    const [inputAllowed, setInputAllowed] = useState(false);
    const [groupName, setGroupName] = useState("");
    const { debouncedValue: debouncedSearchTxt } = useDebounce(groupName, 500);
    const [isFilter, setIsFilter] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [color, setGroupColor] = useState("");
    const [companyName, setCompanyName] = useState("");

    let filtersIndex = 0;
    const filtersLimit = 4;
    const indexReference = useRef(filtersIndex);
    const [showFilters, setShowFilter] = useState(false);

    useEffect(() => {
        setGroupName(AppliedFilters?.groupName);
        setStartDate(AppliedFilters?.startDate || null);
        setEndDate(AppliedFilters?.endDate || null);
        setGroupColor(AppliedFilters?.color);
        setCompanyName(AppliedFilters?.companyName);
    }, [AppliedFilters]);

    const showFilter = () => {
        window.scroll(0, 0);
        setIsFilter(true);
    };

    const hideFilter = () => {
        setIsFilter(false);
    };

    const applyFilters = () => {
        onApplyFilters({
            groupName: groupName,
            startDate: startDate,
            endDate: endDate,
            color: color,
            companyName: companyName,
        },
            isFilterApplied()
        );
        hideFilter();
    };

    useEffect(() => {
        applyFilters();
    }, [debouncedSearchTxt]);

    const isFilterApplied = () => {
        return (
            startDate ||
            endDate ||
            color ||
            groupName ||
            companyName 
        );
    };

    const filtersCount =
        (color ? 1 : 0) +
        (companyName ? 1 : 0) +
        (startDate ? 1 : 0) +
        (endDate ? 1 : 0);

    indexReference.current = filtersIndex;

    function handleKeyPress(event) {
        if (!inputAllowed) {
          event.preventDefault();
        }
      }
    return (
        <div className={classes.gridWrapper}>
            <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        md={11}
                        className='filterMainSearch endUserSearchFilterMain'>
                        <img src={searchIcon} alt='' className='searchIcon' />
                        <InputField
                            placeholder='Search'
                            value={groupName}
                            onKeyPress={({ key }) => {
                                if (["Enter", "enter"].includes(key)) applyFilters();
                            }}
                            onChange={({ target }) => {
                                setGroupName(target.value);
                            }}
                            onPaste={value => {
                                const splitValue = value.split("\n");
                                setGroupName(splitValue.join(", "));
                            }}
                        />
                        <span className='endUserSearchFilter' onClick={showFilter}>
                            {(["4", "5", "6"].includes(ActiveTabId) && filtersCount > 1) ||
                                (!["4", "5", "6"].includes(ActiveTabId) && filtersCount > 0) ? (
                                <span>{`${filtersCount} Filter${filtersCount > 1 ? "(s)" : ""
                                    } Applied `}</span>
                            ) : null}
                            <FilterArrow color={"#fff"} />
                        </span>
                    </Grid>

                    <Grid item md={1} className='blockEndUserBtn gridFiltersButtons'>
                        <span
                            onClick={() => {
                                dispatch(endUserActions.onEUListViewChange(false));
                                onToggleListView(false);
                            }}>
                            {isListView ? <GridHide /> : <GridShow />}
                        </span>
                        <span
                            onClick={() => {
                                dispatch(endUserActions.onEUListViewChange(true));
                                onToggleListView(true);
                            }}>
                            {isListView ? <ListShow /> : <ListHide />}
                        </span>
                    </Grid>
                </Grid>
                {IsFilterApplied && isFilterApplied() && (
                    <div
                        style={{
                            fontSize: "13px",
                            fontWeight: "500",
                            marginTop: "6px",
                        }}>{`${PaginationResponse?.totalRecords || 0} Users Found`}</div>
                )}

                {isFilter && (
                    <div className='endUserFilterWrapper'>
                        <div className='endUserFilterInnerWrapper'>
                            <img
                                className='filterCrossImage'
                                src={crossImg}
                                alt='cross icon'
                                onClick={hideFilter}
                            />
                            <div className='formContainer'>
                                <h3 className='endUserFilterHeading'> { title } </h3>
                                <Accordion
                                    defaultExpanded
                                    id='end-user-filter-expandable'
                                    TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography>
                                            <span>Active Filter(s)</span>
                                            <span>
                                                <Button
                                                    className='filterClearBtn filterClearBtnStyle'
                                                    variant='outlined'
                                                    onClick={() => {
                                                        onApplyFilters(
                                                            {
                                                                searchText: "",
                                                                startDate: "",
                                                                endDate: "",
                                                            },
                                                            false
                                                        );
                                                        // if (ActiveTabId !== "1") setActiveTabId(`${1}`);
                                                        hideFilter();
                                                    }}>
                                                    Clear
                                                </Button>
                                            </span>
                                        </Typography>
                                    </AccordionSummary>
                                    <div>
                                        {filtersCount <= 0 && (
                                            <div className={`filtersChips`}>No Active Filter(s)</div>
                                        )}
                                        {(startDate || endDate) && (
                                            <ChipItem
                                                chipLabel='Date'
                                                chipValue={`${startDate
                                                    ? moment(startDate).format("MM/DD/yyyy")
                                                    : "?"
                                                    } - ${endDate ? moment(endDate).format("MM/DD/yyyy") : "?"
                                                    }`}
                                                onDelete={() => {
                                                    if (startDate) setStartDate(null);
                                                    if (endDate) setEndDate(null);
                                                }}
                                            />
                                        )}
                                        {color && (
                                            <ChipItem
                                                chipLabel='Group Color'
                                                chipValue={`${color}`}
                                                onDelete={() => {
                                                    if (color) setGroupColor("");
                                                }}
                                            />
                                        )}

                                        {companyName && (
                                            <ChipItem
                                                chipLabel='No. of Contacts'
                                                chipValue={`${companyName}`}
                                                onDelete={() => {
                                                    if (companyName) setCompanyName("");
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            textAlign: "right",
                                        }}>
                                        {filtersCount > filtersLimit && (
                                            <IconButton
                                                color={"primary"}
                                                onClick={() => setShowFilter(!showFilters)}>
                                                <MoreHoriz />
                                            </IconButton>
                                        )}
                                    </div>
                                </Accordion>

                                <h4 className='contactCreationDate'>Contact Creation Date</h4>
                                <FilterCreationDate
                                    StartDate={startDate}
                                    setStartDate={setStartDate}
                                    EndDate={endDate}
                                    setEndDate={setEndDate}
                                />
                                <Accordion defaultExpanded className='filterAccordiansInner'>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography>
                                            <h4 className='userDetailsText'>User Details</h4>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='userDetailsSearchField inputWrapper filterSelect'>
                                            {/* <FormControl className='ac-dd'>
                                                <Autocomplete
                                                    value={BusinessGroups.filter(eg =>
                                                        BusinessGroupIds.includes(eg.id)
                                                    )}
                                                    onChange={(event, value) => setBGIds(value?.map(g => g.id))}
                                                    multiple
                                                    filterSelectedOptions
                                                    id='business-group'
                                                    options={BusinessGroups}
                                                    getOptionLabel={option => option?.value || ""}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                                                            placeholder={"Business Group"} />
                                                    )}
                                                />
                                                {BusinessGroupIds?.length > 0 && (
                                                    <span className='countLabel'>{BusinessGroupIds?.length}</span>
                                                )}
                                            </FormControl> */}
                                            <FormControl className='ac-dd colorfilterContainer'>
                                                <InputField
                                                    placeholder='Group Color'
                                                    id='group-color'
                                                    name='group-color'
                                                    onChange={({ target }) =>
                                                        setGroupColor(target.value)
                                                    }
                                                    readOnly={false}
                                                    onKeyPress={handleKeyPress}
                                                    autoComplete={"off"}
                                                    onClick={() => {
                                                        setColorOption(true);
                                                    }}
                                                    value={color}
                                                />
                                                <OutsideClickHandler onOutsideClick={() => setColorOption(false)}>
                                                    <span
                                                        className='colorBox'
                                                        style={{ background: `${color}` }}
                                                        onClick={() => setColorOption(true)}
                                                    />
                                                    {colorOption && (
                                                        <div className='colorOptions'>
                                                            {colors.map((item, index) => {
                                                                return (
                                                                    <span ket={item.id}>
                                                                        <button
                                                                            style={{ background: `${item.color}` }}
                                                                            onClick={() => {
                                                                                setGroupColor(item.color);
                                                                                setColorOption(false);
                                                                            }}
                                                                        />
                                                                    </span>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </OutsideClickHandler>
                                            </FormControl>
                                            <FormControl className='ac-dd'>
                                                <InputField
                                                    placeholder='Company Name'
                                                    id='Contacts'
                                                    name='Contacts'
                                                    onChange={({ target }) => {
                                                      setCompanyName(target.value)
                                                    }}
                                                    autoComplete={"off"}
                                                    value={companyName}
                                                />
                                            </FormControl>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className='endUserfilrerBtn'>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        applyFilters();
                                    }}>
                                    Apply Filter
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </ValidatorForm>
        </div>
    )
}

export default EndUserGroups;