import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as signalR from "@microsoft/signalr";
import Chat from "../../../../components/common/Chat";
import Loader from "../../../../components/common/Loader";
import ConversationPopup from "./ConversationSetup/ConversationPopup";
import { conversationAction } from "../../../../store/conversations/conversationAction";

function Conversation(props) {
  document.title = "OFish - All Conversations";

  //const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { isPopupOpen } = useSelector(state => state.conversationReducer);
  const options = {
    // transport: transport,
    logMessageContent: true,
    logger: signalR.LogLevel.Trace,
    // skipNegotiation: true,
  };

  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(
  //     conversationAction.onGetConfigurationStatus({
  //       productId: 2,
  //     })
  //   ).then(data => {
  //     setLoading(false);
  //     if (data?.responseMsg === "Success") {
  //       dispatch(
  //         conversationAction.onChange(
  //           "isPopupOpen",
  //           data?.responseData?.isConfigured === false ? true : false
  //         )
  //       );
  //     }
  //   });
  // }, []);
  return (
    <div>
      {isLoading ? <Loader /> : null}

      <Chat
        // clientId={localStorage.getItem("id")}
        clientId={"d5083803-29d5-4bf0-bce7-fbaab34011a6"}
        //Staging user id
        // clientId={"2b2ae40c-8406-4ec6-bd83-13fb4168c6ba"}
        productId={2}
        // userName={localStorage.getItem("isAdmin")}
        userName={"Aquatic AV"}
        generic={"admin"}
        options={options}
      />
    </div>
  );
}

export default Conversation;
