import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { concat } from "lodash";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Close, Add, Search, Clear } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  MenuItem,
  Select,
  Button,
  Checkbox,
  FormControl,
  Input,
} from "@material-ui/core";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { downarrow } from "../../../../resources/images/index";
import { PopupDialog } from "../../../../components/common";
import Loader from "../../../../components/common/Loader";
import { useDebounce } from "../../../../utils/use-debounce";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  collectionLabels: {
    backgroundColor: "#F3F6F9",
    borderRadius: "36px",
    color: "#70808F",
    fontSize: "14px",
    fontWeight: "400",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 16px 16px 0",
  },
  collectionLabelsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginLeft: "40px",
  },
  deleteLabels: {
    marginLeft: "15px",
    display: "flex",
    cursor: "pointer",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "transparent !important",
    //   margin: '12px 0'
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

const EventsCategoryVisibilityGroup = memo(
  ({
    newsRoomVisibilityId,
    releaseVisibilityGroups = [],
    onNewsRoomVisibilityIdSelect,
    onGroupSelection,
    preReleaseVisibilityGroups,
    setPreReleaseVisibilityGroups,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState("panel2");
    const [isLoading, setIsLoading] = useState(false);
    const [isAddVisibilityGroup, setIsAddVisibilityGroup] = useState(false);
    const [visibilityGroups, setVisibilityGroups] = useState([]);
    const [filteredvisibilityGroups, setfFlteredVisibilityGroups] =
      useState(visibilityGroups);
    const [selectedVisibilityGroups, setSelectedVisibilityGroups] = useState(
      []
    );
    const [tempVisibilityGroups, setTempVisibilityGroups] = useState([]);

    const [newsRoomVisibility, selectNewsRoomVisibility] = useState(null);
    const [filterByName, setFilterByName] = useState("");
    const { debouncedValue } = useDebounce(filterByName, 500);

    useEffect(() => {
      if (debouncedValue) {
        setfFlteredVisibilityGroups([
          ...visibilityGroups?.filter(c =>
            c.value?.toLowerCase().includes(debouncedValue?.toLowerCase())
          ),
        ]);
      } else setfFlteredVisibilityGroups(visibilityGroups);
    }, [visibilityGroups, debouncedValue]);

    const handleChange = panel => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const { releaseNewsRoomVisibilities } = useSelector(
      state => state.LaunchesReducer
    );

    const loadGroups = useCallback(() => {
      setIsLoading(true);
      dispatch(launchesActions.onGetAllUserGroup()).then(data => {
        setVisibilityGroups(data?.data);
        setIsLoading(false);
      });
    }, [dispatch]);

    useEffect(() => {
      if (isAddVisibilityGroup) {
        loadGroups();
      }
    }, [isAddVisibilityGroup, loadGroups]);

    useEffect(() => {
      if (newsRoomVisibilityId) selectNewsRoomVisibility(newsRoomVisibilityId);
    }, [newsRoomVisibilityId]);

    useEffect(() => {
      const groupsList = [
        ...releaseVisibilityGroups.map(g => {
          return g.VisibilityGroupId
            ? { ...g }
            : {
                VisibilityGroupId: g.visibilityGroupId,
                name: g.visibilityGroup?.groupName,
              };
        }),
      ];
      setSelectedVisibilityGroups(groupsList);
      setTempVisibilityGroups(groupsList);
    }, [releaseVisibilityGroups]);

    const onCloseGroupSelection = () => {
      setIsAddVisibilityGroup(false);
      setSelectedVisibilityGroups(tempVisibilityGroups);
      onGroupSelection && onGroupSelection(tempVisibilityGroups);
    };

    const onClose = () => {
      setIsAddVisibilityGroup(false);
      setTempVisibilityGroups([...selectedVisibilityGroups]);
    };

    return (
      <div>
        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}>
          <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
            <Typography className={classes.mainHeading}>
              <img src={downarrow} alt='' className={classes.headingIcon} />
              Category Visibility
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isAddVisibilityGroup && (
              <PopupDialog visible={isAddVisibilityGroup} onClose={onClose}>
                <div style={{ width: "500px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #E2E5E9",
                    }}>
                    <span
                      style={{
                        marginLeft: "10px",
                        fontSize: "22px",
                        fontWeight: "500",
                      }}>
                      Add Audience
                    </span>
                    <Close
                      style={{
                        cursor: "pointer",
                        padding: "20px",
                      }}
                      onClick={onClose}
                    />
                  </div>
                  {isLoading && <Loader />}
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      placeholder='Search by name'
                      disableUnderline
                      style={{
                        padding: "17px",
                      }}
                      startAdornment={
                        <Search
                          style={{
                            marginRight: "5px",
                          }}
                        />
                      }
                      onChange={({ target }) => setFilterByName(target.value)}
                    />
                  </Grid>
                  {filteredvisibilityGroups.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "264px",
                        overflowY: "auto",
                      }}>
                      {filteredvisibilityGroups.map(g => {
                        return (
                          <span
                            style={{
                              padding: "10px 0px",
                              borderBottom: "1px solid #E2E5E9",
                            }}>
                            <Checkbox
                              key={g.id}
                              label={g.value}
                              checked={
                                tempVisibilityGroups.find(
                                  s => `${s.VisibilityGroupId}` === `${g.id}`
                                )
                                  ? true
                                  : false
                              }
                              onChange={({ target }) => {
                                if (target.checked) {
                                  // tempVisibilityGroups.push({
                                  //   VisibilityGroupId: g.id,
                                  //   name: g.value
                                  // });

                                  setTempVisibilityGroups([
                                    ...concat(tempVisibilityGroups, {
                                      VisibilityGroupId: g.id,
                                      name: g.value,
                                    }),
                                  ]);
                                } else {
                                  setTempVisibilityGroups([
                                    ...tempVisibilityGroups.filter(
                                      s =>
                                        `${s.VisibilityGroupId}` !== `${g.id}`
                                    ),
                                  ]);
                                }
                              }}
                            />
                            <b>{g.value}</b>
                            {g.generic ? ` ${g.generic}` : ""}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <span
                      style={{
                        margin: "20px",
                        display: "flex",
                      }}>
                      No groups available
                    </span>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      margin: "15px",
                    }}>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={tempVisibilityGroups.length <= 0}
                      onClick={() => onCloseGroupSelection()}>
                      Select
                    </Button>
                  </div>
                </div>
              </PopupDialog>
            )}
            <Grid
              item
              xs={4}
              className={classes.gridWrapper}
              style={{ marginLeft: "5px" }}>
              <div className='inputWrapper'>
                <FormControl className={classes.formControl}>
                  <label>{"Newsroom Visibility"}</label>
                  <Select
                    value={newsRoomVisibility}
                    onChange={e => {
                      selectNewsRoomVisibility(e.target.value);
                      onNewsRoomVisibilityIdSelect &&
                        onNewsRoomVisibilityIdSelect(e.target.value);
                    }}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                    fullWidth
                    variant={"outlined"}
                    required>
                    <MenuItem value='' disabled>
                      <span>{"Newsroom Visibility"}</span>
                    </MenuItem>
                    {releaseNewsRoomVisibilities?.length > 0 &&
                      releaseNewsRoomVisibilities.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.value}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <div
              style={{
                marginLeft: "40px",
                flexDirection: "column",
                display: "flex",
              }}>
              <Typography>
                <ul className='visabilityWrapperHeading'>
                  <li>
                    <span>View Release</span>
                  </li>
                </ul>
              </Typography>
              <Typography>
                If left empty, this category will be Public and visible to
                everyone. Add an Audience to control visibility of this
                category.
              </Typography>
              <div className='addReleasesGroups'>
                <Button
                  color='primary'
                  variant='outlined'
                  startIcon={<Add />}
                  onClick={() => setIsAddVisibilityGroup(true)}>
                  Add Audience
                </Button>
              </div>
            </div>
            {selectedVisibilityGroups && (
              <div className={classes.collectionLabelsWrapper}>
                {selectedVisibilityGroups.map((item, index) => {
                  return (
                    <div key={index} className={classes.collectionLabels}>
                      <span>{item.name}</span>
                      <span
                        className={classes.deleteLabels}
                        onClick={() => {
                          const filteredList = [
                            ...selectedVisibilityGroups.filter(
                              g =>
                                `${g.VisibilityGroupId}` !==
                                `${item.VisibilityGroupId}`
                            ),
                          ];
                          onGroupSelection && onGroupSelection(filteredList);
                        }}>
                        <Clear className='collectionLabelDeleteBtn' />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
);

export { EventsCategoryVisibilityGroup };
