import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import {
  EditIcon,
  StarFill,
  StarOutline,
  ViewEye,
  GridHide,
  ListShow,
  ListHide,
  GridShow,
  Delete,
} from "../../LeftNav/SvgIcons";
import { Pagination } from "../../../../components/common";
import { GridFilterBarBusinessCustomers } from "../BusinessConsumers/components";
import { EndUserActivityList } from "./EndUserActivityList";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
}));

const EndUserActivityGrid = () => {
  const classes = useStyles();
  const [isOrderList, setOrderList] = useState(false);
  const [isOrderGrid, setIsOrderGrid] = useState(true);
  const [isListView, setIsListView] = useState(true);
  const [isFavourite, setIsFavourite] = useState(false);
  const [filterByName, setFilterByName] = useState("");
  const [selectedCategoryId, setSelectedCategory] = useState(null);
  const [businessGroups, setBusinessGroups] = useState([]);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedDate, setSelectedDate] = useState(0);

  const onMarkFavouriteCustomer = () => {
    setIsFavourite(!isFavourite);
  };

  const data = [
    {
      firstname: "First name",
      lastname: "Last name",
      product: "Product Name",
      fulfillmentstatus: "Incomplete",
    },
    {
      firstname: "First name",
      lastname: "Last name",
      product: "Product Name",
      fulfillmentstatus: "Receipt Missing",
    },
    {
      firstname: "First name",
      lastname: "Last name",
      product: "Product Name",
      fulfillmentstatus: "Waiting for Approval",
    },
  ];

  return (
    <div
      className='gridBody contentBody businessCustomerGrid activityGridSearch'
      style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GridFilterBarBusinessCustomers
            businessGroups={businessGroups}
            setFilterByName={setFilterByName}
            setSelectedCategory={setSelectedCategory}
            setSelectedDate={setSelectedDate}
            onToggleListView={isLstView => setIsListView(isLstView)}
          />
        </Grid>
      </Grid>
      <hr className='horizontalSeperator' />
      <h2 className='gridInnerHeading'>John Doe’s User Activity</h2>
      <span className='gridPagination' style={{ marginTop: "18px" }}>
        <i>
          {page}-{rowsPerPage} of&nbsp;
          {paginationResponse?.totalRecords}
        </i>
      </span>

      <div className='gap' />
      {isListView && (
        <>
          <Table>
            <TableHead className={classes.gridHeader}>
              <TableRow className={classes.gridHeaderRow}>
                <TableCell className={classes.gridHeaderInner}>
                  First Name
                  {/* <TableSortLabel
                active={order.orderByKey === "CompanyName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CompanyName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
              /> */}
                </TableCell>
                <TableCell className={classes.gridHeaderInner}>
                  Last Name
                  {/* <TableSortLabel
                active={order.orderByKey === "TotalUsers"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "TotalUsers",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
              /> */}
                </TableCell>
                <TableCell className={classes.gridHeaderInner}>
                  Product
                </TableCell>
                <TableCell className={classes.gridHeaderInner}>
                  Registration Status
                </TableCell>

                <TableCell className={classes.gridHeaderInner}>
                  Quick Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => {
                return (
                  <TableRow key={index} className='gridBodyRow endUserRow'>
                    <TableCell
                      className={classes.gridBodyInner}
                      style={{ color: "#3699ff", fontWeight: "700" }}>
                      {item.firstname}
                    </TableCell>
                    <TableCell className={classes.gridBodyInner}>
                      {item.lastname}
                    </TableCell>
                    <TableCell className={classes.gridBodyInner}>
                      {item.product}
                    </TableCell>
                    <TableCell className={classes.gridBodyInner}>
                      <span
                        className={`${
                          (item.fulfillmentstatus == "Receipt Missing" &&
                            "missingStatus") ||
                          (item.fulfillmentstatus == "Waiting for Approval" &&
                            "waitingStatus") ||
                          (item.fulfillmentstatus == "Incomplete" &&
                            "calceledStatus")
                        }`}>
                        {item.fulfillmentstatus}
                      </span>
                    </TableCell>

                    <TableCell className={classes.gridBodyInner}>
                      <div className='gridActions'>
                        <span onClick={onMarkFavouriteCustomer}>
                          {isFavourite ? <StarFill /> : <StarOutline />}
                        </span>
                        <span>
                          <EditIcon />
                        </span>
                        <span>
                          <ViewEye />
                        </span>
                        <span>
                          <Delete />
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      )}

      {!isListView && <EndUserActivityList />}
    </div>
  );
};

export default EndUserActivityGrid;
