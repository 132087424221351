import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { productInventoryActions } from "../store/product/productInventoryAction";

const useLoadData = () => {
  const dispatch = useDispatch();
  const [records, setRecods] = useState([]);

  const loadList = useCallback(
    ({ url, payload }) => {
      dispatch(
        productInventoryActions.onLoadData({
          url: url,
          payload: payload,
        })
      ).then(data => {
        setRecods(data?.data || []);
      });
    },
    [dispatch]
  );

  return { records, loadList };
};

const useLoadDataGet = ({ url }) => {
  const dispatch = useDispatch();
  const [records, setRecods] = useState([]);

  const loadList = useCallback(
    url => {
      dispatch(
        productInventoryActions.onLoadData({
          url: url,
        })
      ).then(data => {
        setRecods(data?.data || []);
        return data?.data || [];
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (url) loadList(url);
  }, [loadList, url]);

  const loadListAsync = async url => {
    return dispatch(
      productInventoryActions.onLoadData({
        url: url,
      })
    );
  };

  return { records, setRecods, loadListAsync };
};

export { useLoadData, useLoadDataGet };
