import React, { useRef, useState, useEffect } from "react";
import { Button, Dialog, Checkbox, Input, Grid } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { proSportFront } from "../../../resources/images";
import { useDebounce } from "../../../utils/use-debounce";

const AddAssetsPopup = ({
  closePopup,
  openPopup,
  collectionData = [],
  releaseAssetCollection = [],
}) => {
  const [assetCollection, setAssetCollection] = useState(collectionData);
  const [selectedAssets, setSelectedAssets] = useState([
    ...releaseAssetCollection,
  ]);

  const [filterByName, setFilterByName] = useState("");
  const { debouncedValue } = useDebounce(filterByName, 500);

  useEffect(() => {
    if (debouncedValue) {
      setAssetCollection([
        ...collectionData.filter(c =>
          c.value.toLowerCase().includes(debouncedValue.toLowerCase())
        ),
      ]);
    } else setAssetCollection(collectionData);
  }, [collectionData, debouncedValue]);

  return (
    <div className="assetsDialogueWrapper">
      <Dialog
        open={openPopup}
        onClose={() => closePopup(releaseAssetCollection)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ValidatorForm ref={useRef("form")}>
          <div className="audiancePopupWrapper">
            <div className="audiancePopupInnerWrapper">
              <span className="innerHeading">Add Asset Collection</span>
              <Close
                style={{
                  cursor: "pointer",
                  padding: "20px",
                }}
                onClick={() => closePopup(releaseAssetCollection)}
              />
            </div>
            <Grid item xs={12}>
              <Input
                fullWidth
                placeholder="Search by name"
                disableUnderline
                style={{
                  padding: "17px",
                }}
                startAdornment={
                  <Search
                    style={{
                      marginRight: "5px",
                    }}
                  />
                }
                onChange={({ target }) => setFilterByName(target.value)}
              />
            </Grid>
            <span className="assetsCheckbox">
              {assetCollection?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "264px",
                    overflowY: "auto",
                  }}>
                  {assetCollection.map((item, index) => {
                    return (
                      <span className="assetInnerList">
                        <Checkbox
                          key={item.id}
                          checked={
                            selectedAssets.find(
                              s => `${s.collectionId}` === `${item.id}`
                            )
                              ? true
                              : false
                          }
                          onChange={({ target }) => {
                            if (target.checked) {
                              selectedAssets.push({
                                collectionId: item.id,
                                name: item.title,
                              });

                              setSelectedAssets([...selectedAssets]);
                            } else {
                              setSelectedAssets([
                                ...selectedAssets.filter(
                                  s => `${s.collectionId}` !== `${item.id}`
                                ),
                              ]);
                            }
                          }}
                        />
                        <span>
                          <img
                            src={
                              item.image && item.image === "string"
                                ? proSportFront
                                : item.image || proSportFront
                            }
                            alt="asset"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginRight: "10px",
                              borderRadius: "50%",
                            }}
                          />
                        </span>
                        {item.title}
                      </span>
                    );
                  })}
                </div>
              )}
            </span>
            <div className="audianceBtnWrapper">
              <Button
                variant="contained"
                color="primary"
                disabled={selectedAssets.length <= 0}
                onClick={() => closePopup(selectedAssets)}>
                Add
              </Button>
            </div>
          </div>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};

export default AddAssetsPopup;
