import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Grid, Tab } from "@material-ui/core";
import { ArrowBackIos, PersonAdd } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Dashboard from "../Dashbard";
import { RoutesObj } from "../../../../routes";
import {
  ListViewTile,
  Pagination,
  Spinner,
} from "../../../../components/common";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { DummyComp } from "./DummyComp";
import { UsersGroupGrid } from "./UsersGroupGrid";
import { AddNewBusinessGroup } from "../BusinessConsumers/AddNewBusinessGroup";
import { scrollToBottom } from "../../../../utils/helper";
import AllEndUserGridFilter from "./AllEndUserGridFilter";
import { endpoints } from "../../../../config/apiConfig";
import { toast } from "react-toastify";
import { StarFill, StarOutline } from "../../LeftNav/SvgIcons";
import { AllEndUserWrapper } from "./AllEndUsers";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import EndUserGroups from "./EndUserGroups";

const EndUserGroupsContacts = ({ selectedGroup }) => {
  const dispatch = useDispatch();
  const activeTabId = "1";
  const [selectedtTab, setSelectedTab] = useState("Contacts");

  const [isLoading, setLoading] = useState(false);
  const [endUserList, setEndUserList] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [isFilterAppling, setIsFilterAppling] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterModel, setFilters] = useState({
    searchText: "",
    startDate: "",
    endDate: "",
    productCategories: [],
    productSubCategoryId: [],
    productName: [],
    productSKU: [],
    countryId: [],
    stateId: [],
    cityId: [],
    address: [],
    firstName: [],
    lastName: [],
    email: [],
    lastLogin: null,
    deviceRegistered: [],
    rating: null,
    companyName: null,
    date: 0,
    groupIds: [selectedGroup?.id],
    id: [],
    userStatus: [],
  });

  const checkGroupsSelected = item => {
    endUserList.forEach(x => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    var count = endUserList.find(x => x.checked === true);
    setIsDelete(count ? true : false);
    setEndUserList([...endUserList], endUserList);
  };

  const onSelectAll = (items, isSelected) => {
    const selectionList = items.map(x => {
      return {
        ...x,
        checked: isSelected,
      };
    });
    setIsDelete(isSelected);
    setEndUserList([...selectionList]);
  };

  const loadCustomerList = useCallback(() => {
    const modal = {
      ...filterModel,
      email:
        filterModel.email?.length > 0
          ? filterModel.email.map(em => em.email)
          : [],
      firstName:
        filterModel.firstName?.length > 0
          ? filterModel.firstName.map(fn => fn.id)
          : [],
      lastName:
        filterModel.lastName?.length > 0
          ? filterModel.lastName.map(ln => ln.id)
          : [],
      id: filterModel.id?.length > 0 ? filterModel.id.map(ln => ln.id) : [],
      productCategories:
        filterModel.productCategories?.length > 0
          ? filterModel.productCategories.map(pc => pc.id)
          : [],
      productSubCategoryId:
        filterModel.productSubCategoryId?.length > 0
          ? filterModel.productSubCategoryId.map(pc => pc.id)
          : [],
      deviceRegistered:
        filterModel.deviceRegistered?.length > 0
          ? filterModel.deviceRegistered.map(dr => dr.id)
          : [],
      productName:
        filterModel.productName?.length > 0
          ? filterModel.productName.map(pn => pn.id)
          : [],
      productSKU:
        filterModel.productSKU?.length > 0
          ? filterModel.productSKU.map(ps => ps.id)
          : [],
      countryId:
        filterModel.countryId?.length > 0
          ? filterModel.countryId.map(ct => ct.value)
          : [],
      stateId:
        filterModel.stateId?.length > 0
          ? filterModel.stateId.map(st => st.value)
          : [],
      cityId:
        filterModel.cityId?.length > 0
          ? filterModel.cityId.map(ct => ct.value)
          : [],
      address:
        filterModel.address?.length > 0
          ? filterModel.address.map(ct => ct.value)
          : [],
      productStatus: [],
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    setLoading(true);
    dispatch(contactsActions.onGetEndUserList(modal)).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          const endUsers = data.data.data.map(x => {
            return {
              id: x.id,
              deviceRegistered: x.deviceRegistered,
              firstName: x.firstName,
              lastLogin: x.lastLogin,
              lastName: x.lastName,
              location: x.location,
              rating: x.rating,
              userId: x.userId,
              checked: false,
              groupName: x.groupName,
              lastLoginDT: x.lastLoginDT,
              groupColor: x.groupColor,
              image: x.image,
              isFavourite: x.isFavourite,
              registrationPendingDevices: x.registrationPendingDevices,
            };
          });
          setPaginationResponse(data?.data?.pagination);
          setEndUserList(endUsers);
          if (isFilterAppling) {
            setIsFilterApplied(true);
          }
          setIsFilterAppling(false);
        }
        setIsDelete(false);
      }
      setLoading(false);
    });
  }, [
    filterModel,
    rowsPerPage,
    page,
    order.orderByKey,
    order.orderVal,
    dispatch,
  ]);

  useEffect(() => {
    loadCustomerList();
  }, [loadCustomerList]);

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={selectedtTab}>
          <Box style={{ borderBottom: 1, marginLeft: "25px" }}>
            <TabList onChange={(_, value) => setSelectedTab(value)}>
              <Tab label='Contacts' value='Contacts' />
              <Tab label='Group Details' value='Group Details' />
              <Tab label='Activity' value='Activity' />
              <Tab label='Notes' value='Notes' />
            </TabList>
          </Box>

          <TabPanel value='Contacts' index={0}>
            <AllEndUserGridFilter
              IsFilterApplied={isFilterApplied}
              PaginationResponse={paginationResponse}
              AppliedFilters={filterModel}
              onApplyFilters={(filters, isFilterApplied) => {
                setFilters(filters);
                if (isFilterApplied) setIsFilterAppling(isFilterApplied);
                else {
                  setIsFilterAppling(false);
                  setIsFilterApplied(false);
                }
              }}
              ActiveTabId={`1`}
              isListView={isListView}
              onToggleListView={isLstView => setIsListView(isLstView)}
            />
            <AllEndUserWrapper
              isListView={isListView}
              activeTabId={activeTabId}
              endUserList={endUserList}
              checkGroupsSelected={checkGroupsSelected}
              isDelete={isDelete}
              order={order}
              setOrderBy={setOrderBy}
              isDeleted={`${activeTabId}` === `5`}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              loadCustomerList={loadCustomerList}
              onSelectAll={onSelectAll}
              paginationResponse={paginationResponse}
              setLoading={setLoading}
            />
          </TabPanel>
          {selectedtTab !== "Contacts" && (
            <TabPanel value={selectedtTab} index={1}>
              Coming Soon: {selectedtTab}
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </>
  );
};

const AllUserGroup = () => {
  document.title = "OFish - End Users Groups";

  const history = useHistory();
  const { isEUListView } = useSelector(state => state.endUserReducer);

  const [activeTabId, setActiveTabId] = useState("7");
  const [isLoading, setLoading] = useState(false);
  const [isNewGroup, setIsNewGroup] = useState(false);
  const [editableRecord, setEditableRecord] = useState(null);

  const [isListView, setIsListView] = useState(true);
  const [isFilterAppling, setIsFilterAppling] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterModel, setFilters] = useState({
    startDate: "",
    endDate: "",
    companyName: "",
    groupName: "",
    color: "",
  });

  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const dispatch = useDispatch();

  const loadCusGroups = useCallback(() => {
    setLoading(true);
    const modal = {
      ...filterModel,
      // totalContacts: 0,
      // totalCompanies: 0,
      // date: 0,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    dispatch(contactsActions.onGetEndUserGroup(modal)).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          // const businessGroupList = data.data.data.map((x, index) => {
          //   return {
          //     id: x.id,
          //     color: x.color,
          //     customerType: x.customerType,
          //     dateAdded: x.dateAdded,
          //     groupId: x.groupId,
          //     groupName: x.groupName,
          //     inUse: x.inUse,
          //     totalCompanies: x.totalCompanies,
          //     totalContacts: x.totalContacts,
          //     checked: false
          //   };
          // });
          setPaginationResponse(data?.data?.pagination);
          if (isFilterAppling) {
            setIsFilterApplied(true);
          }
          setIsFilterAppling(false);
          //setEndUserGroup(businessGroupList);
        }
      }
      setLoading(false);
    });
  }, [
    filterModel,
    rowsPerPage,
    page,
    order.orderByKey,
    order.orderVal,
    dispatch,
  ]);

  useEffect(() => {
    loadCusGroups();
  }, [loadCusGroups]);

  useEffect(() => {
    setIsListView(isEUListView);
  }, [isEUListView]);

  useEffect(() => {
    if (![`7`, `8`].includes(`${activeTabId}`))
      history.push({
        pathname: RoutesObj.AllEndUsers.path,
        state: { openTabId: `${activeTabId}` },
      });
  }, [activeTabId, history]);

  useEffect(() => {
    if (isNewGroup || editableRecord?.id)
      scrollToBottom({ elementId: "add-new-user-group" });
    if (editableRecord?.id) setIsNewGroup(false);
  }, [isNewGroup, editableRecord]);

  const { endUserGroup } = useSelector(state => state.ContactsReducer);

  const onMarkFavourite = selectedGroup => {
    setLoading(true);
    dispatch(
      contactsActions.onPostCustomersEndpoint({
        endpoint: endpoints.customers.markUnMarkFavouriteUserGroup,
        data: [
          {
            groupId: selectedGroup.id,
            isFavourite: !selectedGroup.isFavorite,
          },
        ],
      })
    ).then(data => {
      setLoading(false);
      if (!data?.success) {
        toast.error(data?.message);
      } else {
        loadCusGroups();
      }
    });
  };

  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Spinner /> : null}
        <div className='contentHeading'>
          <h1>End Users Groups</h1>
          <Button
            color='primary'
            variant='contained'
            className='newEndGroupButton'
            startIcon={<PersonAdd />}
            onClick={() => {
              // history.push("/new-enduser-group");
              setEditableRecord(null);
              setIsNewGroup(true);
            }}>
            New End User Group
          </Button>
        </div>
        <div>
          <TopTabNavigator
            defaultTabId={activeTabId}
            getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
            tabs={[
              {
                id: "1",
                title: "All End Users",
                component: <Spinner />,
              },
              {
                id: "7",
                title: "Groups",
                component: selectedGroup ? (
                  <div className='end-user-group-contacts'>
                    <div className='grid-header'>
                      <h2 className='gridInnerHeading'>
                        {selectedGroup.groupName}
                      </h2>
                      <Button
                        color='primary'
                        variant='text'
                        onClick={() => setSelectedGroup(null)}>
                        <ArrowBackIos style={{ fontSize: "14px" }} />
                        {`Back to End User Groups`}
                      </Button>
                    </div>
                    <EndUserGroupsContacts selectedGroup={selectedGroup} />
                  </div>
                ) : (
                  <div
                    className='gridBody contentBody'
                    style={{ width: "100%" }}>
                    <EndUserGroups
                      IsFilterApplied={isFilterApplied}
                      PaginationResponse={paginationResponse}
                      AppliedFilters={filterModel}
                      onApplyFilters={(filters, isFilterApplied) => {
                        setFilters(filters);
                        if (isFilterApplied)
                          setIsFilterAppling(isFilterApplied);
                        else {
                          setIsFilterAppling(false);
                          setIsFilterApplied(false);
                        }
                      }}
                      ActiveTabId={activeTabId}
                      setActiveTabId={setActiveTabId}
                      isListView={isListView}
                      onToggleListView={isLstView => setIsListView(isLstView)}
                      title={"End User Groups"}
                    />
                    <div className='grid-header'>
                      <h2 className='gridInnerHeading'>
                        End User Groups
                        <span className='gridTopPagination'>
                        <Pagination
                          recordCount={paginationResponse?.totalRecords}
                          page={page - 1}
                          rowsPerPage={rowsPerPage}
                          setRowsPerPage={setRowsPerPage}
                          rowsPerPageOptions={[]}
                          backIconButtonProps={{ style: { display: "none" } }}
                          nextIconButtonProps={{ style: { display: "none" } }}
                        />
                      </span>
                      </h2>
                    </div>
                    {isListView ? (
                      <UsersGroupGrid
                        customerGroupsData={endUserGroup}
                        loadData={() => loadCusGroups()}
                        order={order}
                        onOrderBy={setOrderBy}
                        onEditClick={businessGroupEntity => {
                          if (businessGroupEntity)
                            setEditableRecord({ ...businessGroupEntity });
                        }}
                        EditableRecord={editableRecord}
                        EditAbleRow={
                          editableRecord && (
                            <AddNewBusinessGroup
                              id='add-new-user-group'
                              businessGroupEntity={editableRecord}
                              onAddUpdate={success => {
                                if (success) {
                                  loadCusGroups();
                                  setEditableRecord(null);
                                }
                              }}
                              onCancel={() => {
                                setEditableRecord(null);
                              }}
                            />
                          )
                        }
                        onViewClick={seltedItem => setSelectedGroup(seltedItem)}
                      />
                    ) : (
                      <Grid container spacing={2}>
                        {endUserGroup.map(item => (
                          <Grid item md={3} key={item.id}>
                            <ListViewTile
                              companyname={item?.groupName}
                              contacts={
                                item?.totalContacts > 0
                                  ? `${item?.totalContacts} Contact${
                                      item?.totalContacts > 1 ? "s" : ""
                                    }`
                                  : "No Contact"
                              }
                              buttontxt={item.customerType}
                              backgroundcolor={item.color}
                              textcolor={item.color}
                              rating={
                                item?.isFavorite ? (
                                  <StarFill />
                                ) : (
                                  <StarOutline />
                                )
                              }
                              onMarkFavourite={() =>
                                onMarkFavourite({
                                  ...item,
                                })
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}

                    <div>
                      {isNewGroup && (
                        <AddNewBusinessGroup
                          isUserGroup
                          id='add-new-user-group'
                          businessGroupEntity={editableRecord}
                          onAddUpdate={success => {
                            if (success) {
                              loadCusGroups();
                              setIsNewGroup(false);
                              setEditableRecord(null);
                            }
                          }}
                          onCancel={() => {
                            setEditableRecord(null);
                            setIsNewGroup(false);
                          }}
                        />
                      )}
                    </div>
                    <Pagination
                      recordCount={paginationResponse?.totalRecords}
                      page={page - 1}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                    />
                  </div>
                ),
              },
              {
                id: "2",
                title: "Your Favorites",
                component: <Spinner />,
              },
              {
                id: "3",
                title: "Recently Added",
                component: <Spinner />,
              },
              {
                id: "4",
                title: "Archived ",
                component: <Spinner />,
              },
              {
                id: "5",
                title: "Deleted",
                component: <Spinner />,
              },
              {
                id: "6",
                title: "Blocked",
                component: <Spinner />,
              },
              {
                id: "8",
                title: "Journeys",
                component: <DummyComp />,
              },
            ]}
          />
        </div>
      </div>
    </Dashboard>
  );
};

export { AllUserGroup };
