import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { Add, Clear } from "@material-ui/icons";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import { downarrow } from "../../../../resources/images/index";
import AddAssetsPopup from "../../../../components/common/popUps/AddAssetsPopup";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  InnerSubHeading: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: "700",
    marginBottom: "16px",
    marginTop: "33px",
  },
  collectionLabels: {
    backgroundColor: "#F3F6F9",
    borderRadius: "36px",
    color: "#70808F",
    fontSize: "14px",
    fontWeight: "400",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 16px 16px 0",
  },
  collectionLabelsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    // marginLeft: "40px"
  },
  deleteLabels: {
    marginLeft: "15px",
    display: "flex",
    cursor: "pointer",
  },
  deleteStyle: {
    fontSize: "20px",
    fontWeight: "700",
  },
  viewReleaseWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "transparent !important",
    //   margin: '12px 0'
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

const AssetCollection = ({
  assetCollection,
  releaseAssetCollection,
  onReleaseAssetCollectionChange,
}) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [expanded, setExpanded] = useState("panel2");
  const [isModal, setIsModal] = useState(false);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // const { allCollections } = useSelector(state => state.AssetsReducer);
  // const { selectedCollectionAsset } = useSelector(
  //   state => state.LaunchesReducer
  // );

  const removeTag = item => {
    onReleaseAssetCollectionChange([
      ...releaseAssetCollection.filter(
        r => r.collectionId !== item.collectionId
      ),
    ]);
  };

  return (
    <div>
      <Accordion
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}>
        <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
          <Typography className={classes.mainHeading}>
            <img src={downarrow} alt='' className={classes.headingIcon} />{" "}
            Related Asset Collection
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.viewReleaseWrapper}>
          <Typography>
            Select related asset collections for this news release.
          </Typography>

          <div className='addAssets'>
            <Button
              color='primary'
              variant='outlined'
              startIcon={<Add />}
              onClick={() => setIsModal(true)}>
              Add Asset Collection
            </Button>
          </div>

          <label className={classes.InnerSubHeading}>Collections Added</label>

          <div className={classes.collectionLabelsWrapper}>
            {releaseAssetCollection?.length > 0 &&
              releaseAssetCollection.map((item, index) => {
                return (
                  <div key={index} className={classes.collectionLabels}>
                    <span>{item.name}</span>
                    <span
                      className={classes.deleteLabels}
                      onClick={() => removeTag(item)}>
                      <Clear className='collectionLabelDeleteBtn' />
                    </span>
                  </div>
                );
              })}
            {releaseAssetCollection.length <= 0 && (
              <span>
                No Related Asset Collections have been added to this release.
                Add one to complement your release.
              </span>
            )}
            {isModal && (
              <AddAssetsPopup
                openPopup={isModal}
                closePopup={slectedAssetCollection => {
                  onReleaseAssetCollectionChange(slectedAssetCollection);
                  setIsModal(false);
                }}
                collectionData={assetCollection}
                releaseAssetCollection={releaseAssetCollection}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export { AssetCollection };
