import React , { useEffect, useState } from "react";
import { TempratureIcon1, TempratureIcon2, TempratureIcon3 } from "./SvgIcons";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { toUpper } from "lodash";
import { Typography } from "@material-ui/core";
import { MusicIcon } from "./SvgIcons";
import { LightIcon } from "./SvgIcons";
import { TemperatureIcon } from "./SvgIcons";

export default function OverViewCard({ gateways,isActive }) {
  const {
    spaSettings,
    spaTemperatureSettings,
    spaDeviceDetails,
  } = useSelector((state) => state.endUserReducer);
  const [isCurrentTemprature , setIsCurrentTemprature] = useState("");

  useEffect(() => {
    setIsCurrentTemprature((!spaTemperatureSettings?.currentTemperature || spaTemperatureSettings?.currentTemperature === 0)
      ? 100 : spaTemperatureSettings?.currentTemperature);
}, [isActive]);

    return (
    <Grid item md={12} className="gridMainSpaWrapper">
      <Grid item md={12} className="gridSpaOverviewWrapper">
        <Grid item md={12} className="gridSpaOverviewWrapperBody">
          <Grid
            item
            md={12}
            className="gridSpaOverviewHeading gridSpaOverviewCardHeading"
          >
            <h4 className="overViewCardHeading">Spa Overview</h4>
          </Grid>
          <Grid item md={12} className="gridRow">
            <Grid item md={6} className="gridParentWidget">
              <Grid item md={10} className="gridImageSection">
                <img
                  src={
                    "https://media.istockphoto.com/photos/spa-beauty-treatment-and-wellness-background-with-massage-pebbles-picture-id1134916892?k=20&m=1134916892&s=612x612&w=0&h=b4FcTF-d68PJ7aQo9jrj4LQ3svcUApdDP944N0ENlBI="
                  }
                  alt="spa overview"
                />
              </Grid>
            </Grid>
            <Grid item md={12} className="gridSpaOverviewHeading">
              <h4>{spaDeviceDetails?.deviceName ?? "--"}</h4>
              <p>
                {spaSettings?.spaModel ?? "--"}, {spaSettings?.spaBrand ?? "--"}
              </p>
            </Grid>
          </Grid>
          <Grid className="gridRow">
            <Grid md={12} className="gridTempInfoSection">
              <div className="overViewButtons">
                <div className="spaStatus">
                  <span className="spaStatusbtn">
                    <span>
                      <TemperatureIcon />
                    </span>
                    <span>{toUpper(spaTemperatureSettings?.heatMode ?? "Heating")}</span>
                  </span>
                </div>
                <div className="spaStatus">
                  <span className="spaStatusbtn getwaybtn">
                    GATEWAY
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} className="gridRow">
            <Grid item md={11} className="gridTempInfoSection">
            <Grid md={12}>
                <div className="currentTemprature">
                {(!spaTemperatureSettings?.temperatureControl || spaTemperatureSettings?.temperatureControl === 1) ? isCurrentTemprature +" F": Math.floor(((isCurrentTemprature - 32) * 5) / 9)+" C"}&deg;
                </div>
                <p>Current Water Temperature</p>
              </Grid>
            </Grid>
            <Grid item md={1} className="gridInfoSectionActions">
              <Grid item md={12} className="gridRow tempratureInfo">
                <Grid md={12} className="recycleIcon">
                  <TempratureIcon1 />
                </Grid>
                <Grid md={12}>
                  <TempratureIcon3 />
                </Grid>
                <Grid md={12}>
                  <TempratureIcon2 />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <div className="overViewDetails">
              <div className="overViewDetailsItem">
                <Typography>Your Dealer</Typography>
                <span>{spaDeviceDetails.dealer ?? "--"}</span>
              </div>
              <div className="overViewDetailsItem">
                <Typography>Your Service</Typography>
                <span>{spaSettings?.spaService ?? "--"}</span>
              </div>
              <div className="overViewDetailsItem">
                <Typography>Gateway</Typography>
                <span className="isconnected">Connected</span>
              </div>
            </div>
          </Grid>
          <Grid className="spaMusic">
            <div className="spaMusicItems">
              <span>
                <MusicIcon />
              </span>
              <div className="spaMusicItemsDetails">
                <p>Now Playing</p>
                <p>As It Was - Harry Styles</p>
              </div>
            </div>
            <div className="spaLightItems">
              <div className="spaPartyLight">
              <span>
                <LightIcon />
              </span>
              <span className="spaLightsItemsDetails">
                <p>Lights</p>
                <p>Party</p>
              </span>
              </div>
              <div className="spaMusicLights">
                <div className="spalights"></div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
