import React from "react";
import { Grid } from "@material-ui/core";
import {
  LogHeader,
  MainHeading,
} from "../../../components/common/Header/index";

import Cards from "../../../components/common/Cards";

const speakers = [
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
];

function LogAllCollection(props) {
  return (
    <div className='latestReleaseDetailWrapper allCategory'>
      <LogHeader />
      <MainHeading headerSubHeading='All Collections'>
        <div className='popupBody'>
          <div className='cards'>
            <Grid container spacing={3}>
              {speakers.map((item, index) => (
                <Grid key={index} item md={4}>
                  <Cards
                    picture={item.picture}
                    subheading={item.subheading}
                    cardtext={item.cardtext}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </MainHeading>
    </div>
  );
}

export default LogAllCollection;
