import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { EditorState } from "draft-js";
import SearchIcon from "@material-ui/icons/Search";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AttachFile, Height } from "@material-ui/icons";
import ConversationMediaUploadPopup from "./ConversationMediaUploadPopup";

export default function TextEditor({
  onSendBtnClick,
  sendMsg,
  fileName,
  setFileName,
  isSendButtonDisable,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isUpload, setIsUpload] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  useEffect(() => {
    console.log(editorState);
  }, [editorState]);
  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, [sendMsg]);

  const myHandleReturn = e => {
    onSendBtnClick(editorState, fileName, uploadFile);
  };

  return (
    <div>
      <div
        className="editorInnerWrapper"
        style={{ margin: "2px", maxHeight: "275px" }}>
        <Editor
          editorState={editorState}
          onEditorStateChange={e => {
            setEditorState(e);
          }}
          toolbarClassName="toolbar-class"
          toolbarHidden={true}
          handleReturn={e => myHandleReturn(e)}
        />
        {/* <span className="editorHeaderr">
          <button>
            OFish Templates <SearchIcon />{" "}
          </button>
          <button>
            OFish Data <SearchIcon />{" "}
          </button>
        </span> */}
        <div
          className="editorFooter"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <div
            style={{
              color: "#2f80ed",
              display: "flex",
              flexDirection: "row",
              background: "transparent",
            }}
            onClick={() => {
              setIsUpload(true);
            }}>
            <div className="conversationFileUpload">{fileName}</div>
            <AttachFile />
          </div>
          <Button
            color="primary"
            variant="contained"
            className="dashboardButtons"
            style={{ padding: "0px 0 0 0px", height: "30px" }}
            onClick={() => {
              onSendBtnClick(editorState, fileName, uploadFile);
            }}
            disabled={isSendButtonDisable}>
            Send
          </Button>
        </div>
      </div>
      {isUpload && (
        <ConversationMediaUploadPopup
          //currentFolder={parent}
          openPopup={isUpload}
          closePopup={() => setIsUpload(false)}
          fileName={fileName}
          setFileInfo={(name, file) => {
            setFileName(name);
            setUploadFile(file);
          }}
          //startLoading={() => setLoading(true)}
          //endLoading={() => setLoading(false)}
          //loadData={() => loadData()}
          //parent={parent}
          //onParentId={setParent}
        />
      )}
    </div>
  );
}
