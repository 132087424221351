import { Button } from "@material-ui/core";
import React from "react";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import SelectInput from "../../../../../components/common/SelectInput";
import Dashbard from "../../Dashbard";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { ArrowBackIos } from "@material-ui/icons";
import NewCompany from "./NewCompany";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "12px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

const CompanyInformation = props => {
  const history = useHistory();
  return (
    <Dashbard>
      <div className='body-content'>
        <div className='contentHeading '>
          <h1>New Company</h1>

          <span className='newCompanyHeaderSelect'>
            {/* <Button color='primary' variant='contained' onClick={() => {}}>
              Save
            </Button> */}
          </span>
        </div>{" "}
        <span className='consumerHeading'>
          <Button
            color='primary'
            onClick={() => history.goBack()}
            variant='contained'>
            <ArrowBackIos /> Back to Business Customers
          </Button>
        </span>
        <div className='enduserTabs'>
          <TopTabNavigator
            tabs={[
              {
                id: "1",
                title: "Company Information",
                component: <NewCompany />,
              },
            ]}
          />
        </div>
      </div>
    </Dashbard>
  );
};

export { CompanyInformation };
