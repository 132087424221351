import React from "react";
import { Button } from "@material-ui/core";
const GeneralHeading = ({ headingName, buttonText, onClick }) => {
  return (
    <div className='contentHeading '>
      <h1>{headingName}</h1>

      <div className='dashboardNav'>
        <Button
          color='primary'
          variant='contained'
          className='dashboardButtons'
          onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default GeneralHeading;
