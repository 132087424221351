import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import { ConversationBasics } from "./ConversationBasics";
import { ConversationPermission } from "./ConversationPermission";
// import ConversationStatusTypes from "./ConversationStatusTypes";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { downarrow } from "../../../../../resources/images/index";
import StatusTypesGrid from "./StatusTypesGrid";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { conversationAction } from '../../../../../store/conversations/conversationAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../../../components/common/Loader";
import { Grid } from "@material-ui/core";
import SoftwareGridFilter from "../../SoftwareUpdates/SoftwareGridFilter";
import { GridView, ListView } from "../../../LeftNav/SvgIcons";
import { useDebounce } from "../../../../../utils/use-debounce";

function ConversationTypesDetail() {
  const{ id : paramId } = useParams();
  const dispatch = useDispatch();
  const { 
    basicConversationData,
    selectedConversationChannels,
    conversationPermissionData,
  } = useSelector(
      state => state.conversationReducer
  );
  const [search, setSearch] = useState("");
  const { debouncedValue } = useDebounce(search, 500);
  const [order, setOrderBy] = useState({
    orderByKey: "Name",
    orderVal: 0,
  });
  const [statusList, setStatusList] = useState([]);
  const [expandedContact, setExpandedContactType] = useState(true);
  const [expandedPermission, setExpandedPermissionType] = useState(true);
  const [expandedConversationType, setExpandedConversationType] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    var modal = {
      productId: 1,
      campaignId: paramId,
    };
    setLoading(true);
    dispatch(conversationAction.onGetCampaignSettingsByCampaignId(modal)).then(
      data => {
        if (data?.responseMsg === "Success" && data?.responseData !== null) {
          setLoading(false);
          dispatch(
            conversationAction.onChange(
              "basicConversationData",
              data?.responseData
            )
          );
          dispatch(
            conversationAction.onChange(
              "isSelectedDetialsFav",
              data?.responseData.isFavourite
            )
          );
        }
      } 
    );
    
    dispatch(conversationAction.onGetConversationPermission(paramId))
  }, [paramId]);
  useEffect(() => {
    setLoading(true)
    dispatch(
      conversationAction.onGetAllConversationStatus({
        productId: 2,
        campaignId: paramId,
        name: debouncedValue,
        status: null,
        pagination: {
          pageSize: 1000,
          pageNo: 1,
          orderByKey: order?.orderByKey,
          orderByVal: order?.orderVal,
        },
      })
    ).then(data => {
      setLoading(false);
      if (data?.responseCode === 1) {
        setStatusList(data?.responseData?.data);
      }
    });
  }, [paramId , debouncedValue , order]);

  return (
   <div className='conversationTypeInnerWrapper'>
     {isLoading ? <Loader /> : null}    
    <div className="conversationSettingAccordion">
      <Accordion
        square
        expanded={expandedContact}
        onChange={() => setExpandedContactType(!expandedContact)}
        className=""
      >
        <div
          className="accordianWithSaveBtn"
          style={{ padding: "1%", width: "100%" }}
        >
          <MuiAccordionSummary aria-controls="summary" id="summary-header">
            <p className="accordionTitle">
              <img
                src={downarrow}
                alt="arrow icon"
                className={`arrow-icon ${expanded ? "arrow-invert" : ""}`}
              />
              Conversation Basics
            </p>
          </MuiAccordionSummary>
        
        </div>
        <MuiAccordionDetails
          className="accordianDetails"
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <ConversationBasics 
          basicConversationData={basicConversationData}
          selectedConversationChannels={selectedConversationChannels}
          paramId={paramId}/>
        </MuiAccordionDetails>
      </Accordion>
      <div className="gap" />
      <Accordion
        square
        expanded={expandedPermission}
        onChange={() => setExpandedPermissionType(!expandedPermission)}
        className=""
      >
        <div
          className="accordianWithSaveBtn"
          style={{ padding: "1%", width: "100%" }}
        >
          <MuiAccordionSummary aria-controls="summary" id="summary-header">
            <p className="accordionTitle">
              <img
                src={downarrow}
                alt="arrow icon"
                className={`arrow-icon ${expanded ? "arrow-invert" : ""}`}
              />
              Conversation Permissions
            </p>
          </MuiAccordionSummary>
          
        </div>
        <MuiAccordionDetails
          className="accordianDetails"
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <ConversationPermission
          conversationPermissionData={conversationPermissionData}
          />
        </MuiAccordionDetails>
      </Accordion>

      <div className="gap" />
      <Accordion
        square
        expanded={expandedConversationType}
        onChange={() => setExpandedConversationType(!expandedConversationType)}
        className=""
      >
        <div
          className="accordianWithSaveBtn"
          style={{ padding: "1%", width: "100%" }}
        >
          <MuiAccordionSummary aria-controls="summary" id="summary-header">
            <p className="accordionTitle">
              <img
                src={downarrow}
                alt="arrow icon"
                className={`arrow-icon ${expanded ? "arrow-invert" : ""}`}
              />
              Conversation Status Types
            </p>
          </MuiAccordionSummary>
       
        </div>
        <MuiAccordionDetails
          className="accordianDetails"
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
        <Grid className='softwareGridFilterContainer convSettingsFilter'>
          <Grid className='gridFilterBox'>
            <SoftwareGridFilter
              search={search}
              setSearch={setSearch}
              isFilterTrue={false}
              placeholder={"Search"}
            />
            <Grid item md={1} className={"gridIconWrapper"}>
              <Grid style={{ cursor: "pointer" }}>
                <GridView color={"#3699FF"} />
              </Grid>
              <Grid style={{ cursor: "pointer" }}>
                <ListView color={"#979797"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StatusTypesGrid
          paramId={paramId}
          statusList={statusList}
          onOrderBy={setOrderBy}
          order={order}
        />
        </MuiAccordionDetails>
      </Accordion>
     
    </div>
    </div>
  );
}

export default ConversationTypesDetail;
