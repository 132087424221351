// import { productActionTypes } from "./productActionTypes";
import { productCategoriesApi } from "./productCategoriesApi";

const onGetAllBrands = () => async dispatch => {
  return await productCategoriesApi.getAllBrands();
};

const onGetAllProductCategories =
  ({ pageNumber, noOfRecoreds, orderByKey, orderByVal, filters }) =>
  async dispatch => {
    return await productCategoriesApi.getAllProductCategories({
      pageNumber,
      noOfRecoreds,
      orderByKey,
      orderByVal,
      filters,
    });
  };

const onGetAllProductSubCategories = id => async dispatch => {
  return await productCategoriesApi.getAllProductSubCategories(id);
};

const onGetAllProductsSubCategories = ids => async dispatch => {
  return await productCategoriesApi.getAllProductsSubCategories(ids);
};

const getProductCategoryById = id => async dispatch => {
  return await productCategoriesApi.getProductCategoryById(id);
};

const onCreateProductCategory = data => async dispatch => {
  return await productCategoriesApi.createProductCategory(data);
};

const onDeleteProductCategories = ids => async dispatch => {
  return await productCategoriesApi.deleteProductCategories(ids);
};

const onGetProductByCategoryId = id => async dispatch => {
  return await productCategoriesApi.getProductsByCategoryId(id);
};

export const productCategoriesActions = {
  onGetAllBrands: onGetAllBrands,
  onGetAllProductCategories: onGetAllProductCategories,
  onGetAllProductSubCategories: onGetAllProductSubCategories,
  onGetAllProductsSubCategories: onGetAllProductsSubCategories,
  getProductCategoryById: getProductCategoryById,
  onCreateProductCategory: onCreateProductCategory,
  onDeleteProductCategories: onDeleteProductCategories,
  onGetProductsByCategoryId: onGetProductByCategoryId,
};
