import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { InputField, SelectInput } from "../../../../../components/common";
import { CloseRedIcon, SaveGreenIcon } from "../../../LeftNav/SvgIcons";
import {
  SoftwareReleaseDeleteIcon,
  SoftwareReleaseEditIcon,
  SortIcon,
} from "../../../LeftNav/SvgIcons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import StatusTypeForm from "./StatusTypeForm";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeaderInnerCenter: {
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
    "& .MuiTableCell-root": {
      borderBottom: "unset",
    },
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#2D3339",
    fontSize: "12px",
    fontWeight: "400",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  statusInActiveContainer: {
    width: "75px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e06145",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "700",
  },
  statusActiveContainer: {
    width: "75px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#18bc6d",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "700",
  },
  gridBodyInnerName: {
    color: "#3699FF",
    fontSize: "14px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  businessGroup: {
    background: "#E5F2FF",
    color: "#8254E3",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
  editButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  firstChildRadius: {
    borderRadius: "6px 0px 0px 6px",
    fontSize: "12px",
    color: "#828282",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  lastChildRadius: {
    borderRadius: "0px 6px 6px 0px",
    textAlign: "left",
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  iconButton:{
    cursor:"pointer",
  }
}));

function StatusTypesGrid({
  paramId,
  isAccordion3Edit,
  allCampGrid,
  order,
  onOrderBy,
  onEditButtonClick,
  isNewStatusType,
  setIsNewStatustype,
  statusList,
  setAddUpdateResponse,
  setDeletedConversationType,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    conversationCompaginId,
  } = useSelector(
    state => state.conversationReducer
  );
  useEffect(() => {
     onGetStatusActions();
  }, [paramId , conversationCompaginId ]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [editData, setEditData] = useState("");

  const onGetStatusActions = () => {
    dispatch(conversationAction.onGetStatusAction()).then(data => {
      if (data?.responseCode === 1) {
        setActionList(data?.responseData);
      }
    });
  };

  function hexToRGBA(hex, opacity) {
    hex = hex?.replace('#', '');
    const r = parseInt(hex?.substring(0, 2), 16);
    const g = parseInt(hex?.substring(2, 4), 16);
    const b = parseInt(hex?.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity})`;
  }

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        <Grid item md={9} className={classes.deleteItems}>
          {selectedItems.length > 0 && (
            <span className='gridDeleteItems' onClick={() => {}}>
              <Delete /> Delete
            </span>
          )}
        </Grid>
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={[classes.firstChildRadius, "convStatusCol"]}>
              Status
              <TableSortLabel
                active={order?.orderByKey === "Name"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Name",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={[classes.gridHeaderInner, "convStatusActionCol"]}>
              Status Action
              <TableSortLabel
                active={order?.orderByKey === "Action"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Action",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Type
              <TableSortLabel
                active={order?.orderByKey === "Type"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Type",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.lastChildRadius}>
              Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusList.length ? statusList?.map((item, index) => {
            return isEditStatus && editData?.id === item.id ? (
              null
            ) : (
              <TableRow className='gridBodyRow' key={item.id}>
                <TableCell
                  className={[
                    classes.gridBodyInner,
                    "convStatusCol convStatusBtn",
                  ]}>
                  <Button className={item.name} style={{color:item.color , backgroundColor:hexToRGBA(item.color || "#54b0e3" , 0.4) }}>{item.name}</Button>
                </TableCell>
                <TableCell
                  className={[classes.gridBodyInner, "convStatusActionCol"]}>
                  {item.isCustomAction ? item.customAction : item.action}
                </TableCell>
                <TableCell className={classes.gridBodyInnerName}>
                  {item.isDefault ? "Ofish Default" : "Custom"}
                </TableCell>
                <TableCell className={classes.gridBodyInnerName}>
                  <IconButton
                    className={classes.iconButton}
                    disabled={item.isDefault}
                    onClick={() => {
                      setIsEditStatus(true);
                      setEditData(item);
                    }}
                    style={{ opacity: item.isDefault ? 0.5 : 1 }}>
                    <SoftwareReleaseEditIcon />
                  </IconButton>
                  <IconButton className={classes.iconButton} onClick={()=> setDeletedConversationType(item?.id)} disabled={!isAccordion3Edit || item.type === "Ofish Default"}>
                    <SoftwareReleaseDeleteIcon
                      style={{ cursor: "pointer", opacity: paramId ? 0.2 : 1 }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          }) : <TableRow key='0' className='gridBodyRow'>
          <TableCell
            colSpan='4'
            className={classes.gridBodyInner}
            style={{ textAlign: "center" }}>
            <span className='emptyGridText'>
            Uh Oh! No Conversation Status have been created.
            </span>
          </TableCell>
        </TableRow>}
        </TableBody>
      </Table>
      {statusList?.map((item, index) => {
        return isEditStatus && editData?.id === item.id ? (
          <Grid item md={7} className='newStatusFormWrapper'>
            <StatusTypeForm
              actionList={actionList}
              isNewStatusType={isNewStatusType}
              setIsNewStatustype={value => setIsNewStatustype(value)}
              setIsEditStatus={value => setIsEditStatus(value)}
              isEditStatus={isEditStatus}
              editData={editData}
              paramId={paramId}
              onGetStatusActions={onGetStatusActions}
              setAddUpdateResponse={setAddUpdateResponse}
            />
          </Grid>) : (null)
      })}
      {isNewStatusType ? (
        <Grid item md={7} xs={12} className='newStatusFormWrapper'>
          <Grid item md={12}>
            <StatusTypeForm
              actionList={actionList}
              isNewStatusType={isNewStatusType}
              setIsNewStatustype={value => setIsNewStatustype(value)}
              setIsEditStatus={value => setIsEditStatus(value)}
              isEditStatus={isEditStatus}
              editData={editData}
              paramId={paramId}
              onGetStatusActions={onGetStatusActions}
              setAddUpdateResponse={setAddUpdateResponse}
            />
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  );
}

export default StatusTypesGrid;
