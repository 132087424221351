import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { searchIcon } from "../../../resources/images";

const SearchAndFilter = ({
  placeholderText,
  onFilterButtonClick,
  selectedTab,
  onTabButtonClick,
  tabButtons = [
    { id: 1, label: "All", generic: "" },
    { id: 2, label: "Groups", generic: "Customer" },
    { id: 3, label: "Companies", generic: "Company" },
    { id: 4, label: "Contacts", generic: "Contact" },
  ],
}) => {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    onFilterButtonClick(searchText);
  }, [onFilterButtonClick, searchText]);

  return (
    <Grid  item xs={12} alignItems="center" style={{marginBottom:"32px"}}>
      <Grid item xs={4} >
        <span className='searchAndFilter'>
          <span>
            <img src={searchIcon} alt='' className='searchIcon' />
            <input
              type='text'
              placeholder={placeholderText}
              onChange={({ target }) => setSearchText(target.value)}
            />
            {/* <i onClick={() => onFilterButtonClick(searchText)}>
              <FilterIcon />
            </i> */}
          </span>
          {/* <span className='recentUploads'>
            <i>
              <img src={listIcon} alt='listIcon' />
              <img src={gridIcon} alt='gridIcon' />
            </i>
          </span> */}
        </span>
      </Grid>
      <Grid item>
        <div className='searchAndFilterTabs'>
          {tabButtons.map(btn => (
            <Button
              key={btn.id}
              color='primary'
              variant='contained'
              className={`accessButton ${
                selectedTab === btn.generic && "active"
              }`}
              onClick={() => {
                if (onTabButtonClick) onTabButtonClick(btn);
              }}>
              {btn.label}
            </Button>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export { SearchAndFilter };
