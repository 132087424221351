import React, { useEffect, useState } from "react";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert, Send } from "@material-ui/icons";
import moment from "moment";
import EmptyTextarea from "./Textarea";
import OutsideClickHandler from "react-outside-click-handler";
// import TextEditor from "./TextEditor";

const MenuOptions = {
  Edit: "Edit",
  Delete: "Delete"
};

const MoreMenuOption = ({ selectedOption, onOptionSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls='long-menu'
        aria-expanded={open ? "true" : undefined}
        aria-haspopup='true'
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "90px"
          }
        }}>
        {[MenuOptions.Edit, MenuOptions.Delete].map(option => (
          <MenuItem
            key={option}
            // selected={option === selectedOption}
            onClick={() => {
              handleClose();
              onOptionSelect(option);
            }}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const Notes = ({
  notes,
  onSend,
  disabled,
  noteText,
  setNoteText,
  onDeleteNote,
  onUpdateNote,
  enableMoreMenuOption
}) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    if (disabled) setNoteText("");
  }, [disabled, setNoteText]);

  const onOptionSelect = ({ note, slctdAction }) => {
    if (slctdAction) {
      if (slctdAction === MenuOptions.Edit) {
        setSelectedNote(note);
      } else if (slctdAction === MenuOptions.Delete) {
        onDeleteNote && onDeleteNote(note.id);
      }
    }
    setSelectedAction(slctdAction);
  };

  useEffect(() => {
    if (!selectedAction) setSelectedNote(null);
  }, [selectedAction]);

  const handleTimeChange = (time) => {
    const localStartTime = moment.utc(time).local().format("MMMM DD, YYYY hh:mm A");
    return localStartTime;
  };

  return (
    <div className='notes'>
      <span className='notes-count'>{notes?.length} notes</span>
      <div className='editor-wrapper'>
        <div className='notes-header'>
          <span className='editer-pic'></span>
        </div>
        <div>
          <EmptyTextarea
            disabled={disabled}
            placeholder={`What's on your mind?`}
            value={noteText}
            onChange={({ target }) => setNoteText(target.value)}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant='contained'
              color='primary'
              disabled={noteText?.toString().trim().length <= 0}
              onClick={() => onSend && onSend(noteText)}>
              Add Note
            </Button>
          </div>
        </div>
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          setSelectedAction(null);
        }}>
        {notes?.map(n => (
          <div className='editor-wrapper display-note'>
            <div className='pic-wrapper'>
              <span className='left-pan'>
                <span className='editer-pic'></span>
                <span className='name'>
                  {n?.name || n?.userId || "anonymous"}
                  <i>
                    { n?.updatedOn ?  `${handleTimeChange(n?.updatedOn)}` :
                      `${handleTimeChange(n?.createdOn) ||
                      moment(new Date()).format("MMMM DD, YYYY hh:mm A")}`
                    }
                  </i>
                </span>
              </span>
              <span>
                {enableMoreMenuOption && (
                  <MoreMenuOption
                    selectedOption={selectedAction}
                    onOptionSelect={o => {
                      onOptionSelect({ note: n, slctdAction: o });
                      // setSelectedAction(o);
                    }}
                  />
                )}
              </span>
            </div>
            {selectedNote?.id !== n.id && <p>{n.note}</p>}
            {selectedAction === MenuOptions.Edit && selectedNote.id === n.id && (
              <div>
                <EmptyTextarea
                  disabled={disabled}
                  placeholder={`What's on your mind?`}
                  value={selectedNote.note}
                  onChange={({ target }) =>
                    setSelectedNote({
                      ...selectedNote,
                      note: target.value
                    })
                  }
                />
                <div className='notes-header'>
                  <span></span>
                  <span>
                    <Button
                      variant='contained'
                      color='primary'
                      // disabled={noteText?.toString().trim().length <= 0}
                      onClick={() => {
                        onUpdateNote &&
                          onUpdateNote({
                            ...selectedNote,
                            notes: selectedNote.note
                          });
                        setSelectedAction(null);
                      }}>
                      Update Note
                    </Button>
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
      </OutsideClickHandler>
    </div>
  );
};

export { Notes };
