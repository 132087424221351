import _ from "lodash";
import { contactsTypes } from "./contactsTypes";
import { contactsApi } from "./contactsApi";

const onGetAllContacts = () => async dispatch => {
  let response = await contactsApi.getAllContacts();
  if (response && response.success) {
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: "allContacts", value: response.data },
    });
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: "copyAllContacts", value: response.data },
    });
  }
  return response;
};

const onSelectAllContacts = value => async dispatch => {
  dispatch({
    type: contactsTypes.SELECTED_ALL_CONTACTS,
    payload: value,
  });
};
const onDeleteCompanies = ids => async dispatch => {
  let response = await contactsApi.deleteCompanies(ids);
  return response;
};

const onGetCusGroups = () => async dispatch => {
  let response = await contactsApi.getCusGroups();
  if (response && response.success) {
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: "customerGroups", value: response.data },
    });
  }
  return response;
};

const onSelectCusGroups = value => async dispatch => {
  dispatch({
    type: contactsTypes.SELECTED_CUS_GROUPS,
    payload: value,
  });
};
const onDeleteCusGroups = selectedCusGroup => async dispatch => {
  const cusGroupId = [];
  _.forEach(selectedCusGroup, function (cusGroup) {
    cusGroupId.push(cusGroup.id);
  });
  let response = await contactsApi.deleteCusGroups(cusGroupId);
  if (response && response.success) {
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: "selectedCustomerGroups", value: [] },
    });
  }
  return response;
};

const onAddCusGroups = groupName => async dispatch => {
  let response = await contactsApi.addCusGroups(groupName);
  return response;
};
const onGetCountries = () => async dispatch => {
  let response = await contactsApi.getCountries();
  return response;
};
const onGetCities = () => async dispatch => {
  let response = await contactsApi.getCities();
  return response;
};

const onGetProvinces = () => async dispatch => {
  let response = await contactsApi.getProvinces();
  return response;
};

const onCompanyUploadImage = image => async dispatch => {
  let response = await contactsApi.uploadImage(image);
  return response;
};

const onSaveCompanyInformation =
  (desc, comImage, leader, comContact) => async dispatch => {
    let response = await contactsApi.saveCompanyInformation(
      desc,
      comImage,
      leader,
      comContact
    );
    return response;
  };

const onAddContact = value => async dispatch => {
  let response = await contactsApi.addContact(value);
  return response;
};

const onMoveContacts = (selectedContacts, parentId) => async dispatch => {
  const contactsId = [];
  _.forEach(selectedContacts, function (contact) {
    contactsId.push(contact.id);
  });
  let response = await contactsApi.moveContacts(contactsId, parentId);
  if (response && response.success) {
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: "selectedAllContacts", value: [] },
    });
  }
  return response;
};

const onUpdateContact = (value, contactId) => async dispatch => {
  let response = await contactsApi.updateContact(value, contactId);
  return response;
};

const onPressMoreLeader = () => async dispatch => {
  dispatch({
    type: contactsTypes.PRESS_ADD_MORE,
  });
};

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onChangeLeaderValue = (index, value, fieldName) => async dispatch => {
  dispatch({
    type: contactsTypes.CHANGE_LEADER_VALUE,
    payload: { index: index, value: value, fieldName: fieldName },
  });
};

const onGroupTypeSelect = value => async dispatch => {
  dispatch({
    type: contactsTypes.ON_SELECT_GROUP,
    payload: value,
  });
};

const onContactFilterButton = (dayFilter, searchText) => async dispatch => {
  dispatch({
    type: contactsTypes.ON_PRESS_FILTER_CONTACT,
    payload: { dayFilter: dayFilter, searchText: searchText },
  });
};
const onGetBusinessGroup = modal => async dispatch => {
  let response = await contactsApi.getBusinessGroup(modal);
  return response;
};
const onAddBusinessGroup = value => async dispatch => {
  let response = await contactsApi.addBusinessGroup(value);
  return response;
};
const onAddCompany = value => async dispatch => {
  let response = await contactsApi.addCompany(value);
  return response;
};
const onUpdateCompany = value => async dispatch => {
  let response = await contactsApi.updateCompany(value);
  return response;
};
const onGetProductSubCategories = () => async dispatch => {
  let response = await contactsApi.getProductSubCategories();
  return response;
};

const onGetPolicies = () => async dispatch => {
  let response = await contactsApi.getPolicies();
  return response;
};
const onAddCustomer = value => async dispatch => {
  let response = await contactsApi.addCustomer(value);
  return response;
};
const onAddContactNameForm = value => async dispatch => {
  dispatch({
    type: contactsTypes.ON_ADD_CONTACT_NAME_USER_FORM,
    payload: { value },
  });
};

const onGetComapnies = modal => async dispatch => {
  let response = await contactsApi.getCompanies(modal);
  return response;
};

const onGetBusinessGroupListSearch = modal => async dispatch => {
  return await contactsApi.getBusinessGroupListSearch(modal);
};

const onGetCustomerList = modal => async dispatch => {
  let response = await contactsApi.getCustomerList(modal);
  return response;
};

const onGetEndUserGroup = modal => async dispatch => {
  let response = await contactsApi.getEndUserGroup(modal);
  if (response) {
    if (response && response.success) {
      if (response.data?.data) {
        const endUsersGroup = response.data.data.map((x, index) => {
          return {
            ...x,
            id: x.id,
            color: x.color,
            customerType: x.customerType,
            dateAdded: x.dateAdded,
            groupId: x.groupId,
            groupName: x.groupName,
            inUse: x.inUse,
            totalCompanies: x.totalCompanies,
            totalContacts: x.totalContacts,
            checked: false,
          };
        });
        dispatch({
          type: contactsTypes.CONTACTS_ONCHANGE,
          payload: { prop: "endUserGroup", value: endUsersGroup },
        });
      }
    }
  }

  return response;
};
const onGetEndUserList = modal => async dispatch => {
  let response = await contactsApi.getEndUserList(modal);
  return response;
};
const onDeleteUsers =
  (value, isPermanent = true) =>
  async dispatch => {
    let response = await contactsApi.deleteUsers(value, isPermanent);
    return response;
  };
const onDeleteGroups = value => async dispatch => {
  let response = await contactsApi.deleteGroups(value);
  return response;
};
const onGetCompanyDetail = id => async dispatch => {
  let response = await contactsApi.getCompanyDetail(id);
  return response;
};
const onUpdateBusniessContact = data => async dispatch => {
  let response = await contactsApi.updateBusniessContact(data);
  return response;
};
const onUpdateUser = data => async dispatch => {
  let response = await contactsApi.updateUser(data);
  return response;
};
const onGetCompanyById = id => async dispatch => {
  let response = await contactsApi.getCompanyById(id);
  if (response && response.data) {
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: "businessCompanyInfo", value: response.data },
    });
  }
  return response;
};
const onGetCompanyByName = name => async dispatch => {
  let response = await contactsApi.getCompanyByName(name);
  if (response && response.data) {
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: "businessCompanyInfo", value: response.data },
    });
  }
  return response;
};
const onAddUpdateGroup = data => async dispatch => {
  let response = await contactsApi.addUpdateGroup(data);
  return response;
};
const onGetGroupById = id => async dispatch => {
  let response = await contactsApi.getGroupById(id);
  return response;
};
const onGetBusinessGroupDropDown = modal => async dispatch => {
  let response = await contactsApi.getBusinessGroupdropdown(modal);
  return response;
};

const onUpdateMarkUserAsArchived = modal => async dispatch => {
  let response = await contactsApi.updateMarkUserAsArchived(modal);
  return response;
};

const onPostCustomersEndpoint =
  ({ endpoint, data }) =>
  async dispatch =>
    await contactsApi.postCustomersEndpoint({ endpoint, data });

const onAddCompanyDetail = data => async dispatch => {
  let response = await contactsApi.addCompanyDetail(data);
  return response;
};
const onGetBUConnectionList = comId => async dispatch => {
  let response = await contactsApi.getBUConnectionList(comId);
  return response;
};
const onGetBUContactTypeById = typeId => async dispatch => {
  let response = await contactsApi.getContactsByContactTypeId(typeId);
  return response;
};

const onAddUpdateCompanyOFishConnections = data => async dispatch => {
  let response = await contactsApi.addUpdateCompanyOFishConnections(data);
  return response;
};

const onDeleteCompanyOFishConnections = data => async dispatch => {
  let response = await contactsApi.deleteCompanyOFishConnections(data);
  return response;
};

const onGetCompanyUsers = id => async dispatch => {
  let response = await contactsApi.getCompanyUsers(id);
  return response;
};

const onGetCompanyLocTypes = () => async dispatch => {
  let response = await contactsApi.getCompanyLocTypes();
  return response;
};

const onGetCompanyLocations = id => async dispatch => {
  let response = await contactsApi.getCompanyLocations(id);
  if (response && response.data) {
    dispatch({
      type: contactsTypes.CONTACTS_ONCHANGE,
      payload: { prop: "companyLocation", value: response.data },
    });
  }
  return response;
};

const onGetCompanyLocationsById = id => async dispatch => {
  let response = await contactsApi.getCompanyLocationsById(id);
  return response;
};

const onAddUpdateCompanyLocations = data => async dispatch => {
  let response = await contactsApi.addUpdateCompanyLocations(data);
  return response;
};

const onDeleteCompanyLocations = data => async dispatch => {
  let response = await contactsApi.deleteCompanyLocations(data);
  return response;
};

const onBlockCustomer = modal => async dispatch => {
  let response = await contactsApi.blockCustomer(modal);
  return response;
};

const onMarkFavouriteCustomer = modal => async dispatch => {
  return await contactsApi.markFavouriteCustomer(modal);
};

const onMarkUnMarkFavouriteCompanies = modal => async dispatch => {
  return await contactsApi.markUnMarkFavouriteCompanies(modal);
};

const onUpdateRestoreUsers = modal => async dispatch => {
  return await contactsApi.updateRestoreUsers(modal);
};

const onGetBlockedBusinessLocation = id => async dispatch => {
  let response = await contactsApi.getBlockedBusinessLocation(id);
  return response;
};

export const contactsActions = {
  onChange: onChange,
  onGetComapnies: onGetComapnies,
  onGetAllContacts: onGetAllContacts,
  onSelectAllContacts: onSelectAllContacts,
  onDeleteCompanies: onDeleteCompanies,
  onGetCusGroups: onGetCusGroups,
  onSelectCusGroups: onSelectCusGroups,
  onDeleteCusGroups: onDeleteCusGroups,
  onAddCusGroups: onAddCusGroups,
  onGetCountries: onGetCountries,
  onGetCities: onGetCities,
  onGetProvinces: onGetProvinces,
  onCompanyUploadImage: onCompanyUploadImage,
  onSaveCompanyInformation: onSaveCompanyInformation,
  onAddContact: onAddContact,
  onMoveContacts: onMoveContacts,
  onUpdateContact: onUpdateContact,
  onPressMoreLeader: onPressMoreLeader,
  onChangeLeaderValue: onChangeLeaderValue,
  onGroupTypeSelect: onGroupTypeSelect,
  onContactFilterButton: onContactFilterButton,
  onGetBusinessGroupListSearch: onGetBusinessGroupListSearch,
  onGetBusinessGroup: onGetBusinessGroup,
  onAddBusinessGroup: onAddBusinessGroup,
  onAddCompany: onAddCompany,
  onUpdateCompany: onUpdateCompany,
  onGetProductSubCategories: onGetProductSubCategories,
  onGetPolicies: onGetPolicies,
  onAddCustomer: onAddCustomer,
  onAddContactNameForm: onAddContactNameForm,
  onGetCustomerList: onGetCustomerList,
  onGetEndUserGroup: onGetEndUserGroup,
  onGetEndUserList: onGetEndUserList,
  onDeleteUsers: onDeleteUsers,
  onPostCustomersEndpoint: onPostCustomersEndpoint,
  onDeleteGroups: onDeleteGroups,
  onGetCompanyDetail: onGetCompanyDetail,
  onUpdateBusniessContact: onUpdateBusniessContact,
  onUpdateUser: onUpdateUser,
  onGetCompanyById: onGetCompanyById,
  onGetCompanyByName: onGetCompanyByName,
  onAddUpdateGroup: onAddUpdateGroup,
  onGetGroupById: onGetGroupById,
  onGetBusinessGroupDropDown: onGetBusinessGroupDropDown,
  onAddCompanyDetail: onAddCompanyDetail,
  onGetBUConnectionList: onGetBUConnectionList,
  onGetBUContactTypeById: onGetBUContactTypeById,
  onAddUpdateCompanyOFishConnections: onAddUpdateCompanyOFishConnections,
  onDeleteCompanyOFishConnections: onDeleteCompanyOFishConnections,
  onGetCompanyUsers: onGetCompanyUsers,
  onGetCompanyLocations: onGetCompanyLocations,
  onAddUpdateCompanyLocations: onAddUpdateCompanyLocations,
  onDeleteCompanyLocations: onDeleteCompanyLocations,
  onGetCompanyLocationsById: onGetCompanyLocationsById,
  onUpdateMarkUserAsArchived: onUpdateMarkUserAsArchived,
  onBlockCustomer: onBlockCustomer,
  onMarkFavouriteCustomer: onMarkFavouriteCustomer,
  onMarkUnMarkFavouriteCompanies: onMarkUnMarkFavouriteCompanies,
  onUpdateRestoreUsers: onUpdateRestoreUsers,
  onGetCompanyLocTypes: onGetCompanyLocTypes,
  onGetBlockedBusinessLocation:onGetBlockedBusinessLocation,
};
