import React from "react";
import { FormControl } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DateTimeSelector = ({
  label,
  selectedDateTime = new Date(),
  onDateTimeChanged,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className='inputWrapper'>
        <FormControl>
          {label && <label>{label}</label>}
          <DateTimePicker
            autoOk
            format='MM/dd/yyyy hh:mm a'
            value={selectedDateTime}
            onChange={onDateTimeChanged}
          />
        </FormControl>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export { DateTimeSelector };
