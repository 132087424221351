import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Delete, EditIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import AddressForm from "./AddressForm";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";
import { DeleteFilesOverlay } from "../../../../components/common/Modal/AssetsModals";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function AddressGrid({
  userId,
  setIsNewAddress,
  isNewAddress,
  countries,
  cities,
  provinces,
  setIsEditAdr,
  isEditAdr,
  listAddressData,
  setListAddressData,
  loadAddress,
  isLoading,
  setLoading,
}) {
  const dispatch = useDispatch();
  const [editItem, setEditItem] = useState(null);
  const [isDeletedItem , setIsDeletedItem] = useState(null);

  const classes = useStyles();

  const onPressDeleteAddress = (addressId)=>{
    setIsDeletedItem(false);
    setLoading(true);
    dispatch(endUserActions.onDeleteCusAddress(addressId)).then(data => {
      setLoading(false);
      if (data?.success) {
        loadAddress();
        toast.success("Address deleted successfully");
      } else {
        toast.error(data?.message);
      }
    });
  }

  return (
    <>
      <div className='gap' />
      {isLoading ? <Loader /> : null}
      <div className='addressGrid'>
        <span className='gridHeaader'>
          <ul>
            <li>Address Nickame</li>
            <li>Attached to Space?</li>
            <li>Address</li>
            <li>Actions</li>
          </ul>
        </span>

        <span className='gridBody'>
          {listAddressData?.map((item, index) => {
            return isEditAdr && editItem?.id === item.id ? (
              <AddressForm
                key={index}
                countries={countries}
                cities={cities}
                provinces={provinces}
                setIsNewAddress={() => {
                  setIsEditAdr(false);
                  setEditItem(null);
                }}
                editData={item}
                loadAddress={() => loadAddress()}
                setLoading={e => setLoading(e)}
              />
            ) : (
              <ul key={index}>
                <li className='highlishtedItem'>{item.addressNickname}</li>
                <li className='highlishtedItem'>Yes</li>
                <li> {item.streetAddress}</li>
                <li className="highlishtedItemActionButton">
                  <span
                    onClick={() => {
                      setIsEditAdr(true);
                      setEditItem(item);
                      setIsNewAddress(false);
                    }}>
                    <EditIcon />
                  </span>
                  <span
                  onClick={()=>setIsDeletedItem(item.id)}>
                    <Delete />
                  </span>
                </li>
              </ul>
            );
          })}
          {isNewAddress && (
            <AddressForm
              countries={countries}
              cities={cities}
              provinces={provinces}
              setIsNewAddress={val => setIsNewAddress(val)}
              loadAddress={() => loadAddress()}
              setLoading={e => setLoading(e)}
            />
          )}
          {(!isNewAddress && listAddressData?.length === 0) && (
            <span className='emptyGridText emptyGridData'>
              You haven’t added any Address. Start by adding a{" "}
              <i
                onClick={() => {
                  setIsNewAddress(true);
                  setEditItem(null);
                }}>
                New Address
              </i>
            </span>
          )}
        </span>
        {isDeletedItem ? (
        <DeleteFilesOverlay
          heading={"Delete Address"}
          openDeleteFilesOverlay={isDeletedItem}
          setOpenDeleteFilesOverlay={value => {
            setIsDeletedItem(value);
          }}
          deleteAssetFiles={() => {
            onPressDeleteAddress(isDeletedItem);
          }}
          overlayText1={"Are you sure you want to Delete Address?"}
          overlayText2={""}
          buttonText={"Delete"}
        />
      ) : null}
      </div>
    </>
  );
}

export default AddressGrid;
