import React, { useEffect, useState } from "react";
import {
  LogHeader,
  MainHeading,
  InnerHeader,
} from "../../../components/common/Header/index";
import LatestReleaseFilter from "./LatestReleaseFilter";
import { releasesPic } from "../../../resources/images/index";
import Cards from "../../../components/common/Cards";
import { Grid } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

function LogLatestReleasesSearch(props) {
  const history = useHistory();
  const location = useLocation();

  const [relCatData, setRelCatData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location && location.state && location.state.releaseCategory) {
      setRelCatData(location.state.releaseCategory);
    }
  }, []);

  const onDdetail = item => {
    history.push({
      pathname: "/latest-releases-detail",
      state: { release: item },
    });
  };

  return (
    <div className='releasesWrapper'>
      <LogHeader />
      <MainHeading headerSubHeading='Latest Releases'>
        <div className='publicBodyTwoColumn'>
          <div className='leftFiletBar'>
            <LatestReleaseFilter />
          </div>
          <div className='rightContentArea'>
            <InnerHeader
              picture={releasesPic}
              text='Find press releases and relevant assets from Aquatic AV'
              heading='Latest Releases'
            />

            <div className='boxMainWrapper eventsWrapper releaseInnerWrapper searchInner'>
              <h2>{`${
                relCatData.releases && relCatData.releases.length
              }${" results found for ‘"}${relCatData.name}${"‘"}`}</h2>
              <div className='cards smallCard'>
                <Grid container spacing={3}>
                  {relCatData.releases &&
                    relCatData.releases.length > 0 &&
                    relCatData.releases.map((item, index) => (
                      <Grid key={index} item md={4}>
                        <Cards
                          picture={
                            item.asset
                              ? item.asset.url
                              : "/static/images/cards/contemplative-reptile.jpg"
                          }
                          subheading={item.title}
                          cardtext={moment(item.preReleaseDate).format(
                            "MMMM, DD, YYYY"
                          )}
                          onClick={() => onDdetail(item)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </MainHeading>
    </div>
  );
}

export default LogLatestReleasesSearch;
