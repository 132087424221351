import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  Box,
  Grid,
  Tab,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { first } from "lodash";
import { toast } from "react-toastify";
import { ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PriceListLayout } from "./PriceListLayout";
import { PriceListQuickPreview } from "./PriceListQuickPreview";
import {
  AccordionContainer,
  ColorPickerTool,
} from "../../../../../../components/common";
import EmptyTextarea from "../../../../../../components/common/Textarea";
import { ReactComponent as SearchIcon } from "../../../../../../resources/images/Search.svg";
import { SVGIcons } from "../../../../../../resources/images";
import { RoutesObj } from "../../../../../../routes";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },

  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  dateLabel: {
    fontSize: "15px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
    minHeight: "19px",
    display: "inline-block",
  },
}));

const InputField = withStyles({
  root: {
    "&.MuiOutlinedInput-input": {
      height: "4px !important",
    },
    "&.MuiTextField-root": {
      width: "90% !important",
      background: "#FFFFFF",
      borderRadius: " 6px",
    },
  },
})(TextField);

const RenderStartAdornmentColor = ({ bgColor, onColorSelect }) => {
  const [openColorPicker, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [color, setColor] = useState(
    bgColor && bgColor !== "" ? bgColor : "#c4c4c4"
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget); // Open the Popover
  };

  const handleClose = () => {
    onColorSelect(color);
    setAnchorEl(null); // Close the Popover
  };

  const handleChange = color => {
    setColor(color.hex);
    onColorSelect(color);
  };

  useEffect(() => {
    setColor(bgColor);
  }, [bgColor]);

  return (
    <span
      className='priceListColorCircle'
      onClick={event => {
        setOpen(!openColorPicker);
        handleClick(event);
      }}
      style={{
        height: "16px",
        width: "16px",
        borderRadius: "50%",
        marginRight: "5px",
        backgroundColor: `#${color.replace(/^#/, "")}`,
        border: `1px solid #eee`,
        cursor: "crosshair",
      }}>
      {openColorPicker && (
        <ColorPickerTool
          color={`#${color.replace(/^#/, "")}`}
          handleChange={handleChange}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      )}
    </span>
  );
};

const PriceListDesign = ({
  activeStep,
  plData,
  setPriceListData,
  updatePriceListDesign,
  addUpdatePriceListColumns,
  onPreview,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [isCollpased, setIsCollpased] = useState(false);
  const [selectedtTab, setTab] = useState("1");

  const handleChange = (event, newValue) => {
    setTab(`${newValue}`);
  };

  return (
    <div className='newPriceListForm'>
      <AccordionContainer
        title='Price List Design'
        subTitle='Customize the Price List Design including columns and category colors'
        disabled={plData.id === 0}
        isExpanded={activeStep === 3}
        onCollapsed={collapsed => setIsCollpased(collapsed)}
        formButton={
          isCollpased &&
          pathname?.includes(first(RoutesObj.CreatePriceList.path)) &&
          plData?.id > 0 && (
            <span className='green-tick-svg'>
              <SVGIcons.GreenTick />
            </span>
          )
        }>
        <Grid container spacing={3}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={selectedtTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label='Price List Design'>
                  <Tab label='Branding/Colors' value='1' />
                  <Tab label='Price LIST LAYOUT' value='2' />
                </TabList>
                <TabPanel value={`1`} index={0}>
                  <ValidatorForm
                    onSubmit={async () => {
                      const response = await updatePriceListDesign({
                        priceListBranding: plData?.priceListBranding,
                        showConfidentialNotice: plData?.showConfidentialNotice,
                        confidentialWarning: plData?.confidentialWarning,
                        hideNonDiscountedProductsByDefault:
                          plData?.hideNonDiscountedProductsByDefault,
                        mainTextColor: plData?.mainTextColor || "#000000",
                        mainRowTitlesBackColor:
                          plData?.mainRowTitlesBackColor || "#49B8EF",
                        parentCategoryBackColor:
                          plData?.parentCategoryBackColor || "#474D70",
                        childCategoryL1BackColor:
                          plData?.childCategoryL1BackColor || "#3699FF",
                        childCategoryL2BackColor:
                          plData?.childCategoryL2BackColor || "#CAEEFD",
                        mainRowTitlesTextColor:
                          plData?.mainRowTitlesTextColor || "#FFFFFF",
                        parentCategoryTextColor:
                          plData?.parentCategoryTextColor || "#FFFFFF",
                        childCategoryL1TextColor:
                          plData?.childCategoryL1TextColor || "#FFFFFF",
                        childCategoryL2TextColor:
                          plData?.childCategoryL2TextColor || "#000000",
                        priceListId: plData.id,
                      });
                      if (response.success) {
                        toast.success(
                          `Price List ${
                            plData?.id ? "updated" : "created"
                          } successfully`
                        );
                        if (plData.id) setPriceListData({ ...response.data });
                        setTab(`${2}`);
                      }
                      // onSuccess(response);
                    }}>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={3}
                      className='settingsWrapper pricelistDatepicker'>
                      <Grid item sx={12} sm={12} md={4}>
                        <Grid item className='radioBtnText'>
                          <FormControl>
                            <label
                              className='slugLabel showRequired'
                              style={{ marginBottom: 12 }}>
                              Price List Branding
                            </label>
                            <RadioGroup
                              name='priceListBranding'
                              value={`${plData?.priceListBranding}`}
                              onChange={({ target }) => {
                                setPriceListData({
                                  ...plData,
                                  priceListBranding: target.value,
                                });
                              }}>
                              <FormControlLabel
                                control={<Radio />}
                                label={
                                  <>
                                    <b>Match My OFish Brand Colors</b> - We will
                                    use your OFish Brand’s default logo and
                                    colors on your Brand Profile to follow your
                                    Company’s Branding Guidelines
                                  </>
                                }
                                value={"0"}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label={
                                  <>
                                    <b>Customize Price List Colors</b> - Select
                                    Specific colors for text and rows of this
                                    Price List
                                  </>
                                }
                                value={"1"}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item>
                          <p className='textfield-title'>Main Text Color</p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            placeholder='#000000'
                            value={plData?.mainTextColor || "#000000"}
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  mainTextColor: target.value,
                                });
                            }}
                            InputProps={{
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={plData?.mainTextColor || "#000000"}
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      mainTextColor: hex,
                                    })
                                  }
                                />
                              ),
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <p className='textfield-title'>
                            Main Row/Titles Background Color
                          </p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            value={plData?.mainRowTitlesBackColor || "#49B8EF"}
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  mainRowTitlesBackColor: target.value,
                                });
                            }}
                            placeholder='#49B8EF'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={
                                    plData?.mainRowTitlesBackColor || "#49B8EF"
                                  }
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      mainRowTitlesBackColor: hex,
                                    })
                                  }
                                />
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <p className='textfield-title'>
                            Parent Category Background Color
                          </p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            placeholder='#474D70'
                            value={plData?.parentCategoryBackColor || "#474D70"}
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  parentCategoryBackColor: target.value,
                                });
                            }}
                            InputProps={{
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={
                                    plData?.parentCategoryBackColor || "#474D70"
                                  }
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      parentCategoryBackColor: hex,
                                    })
                                  }
                                />
                              ),
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <p className='textfield-title'>
                            Child Category Level 1 Background Color
                          </p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            placeholder='#3699FF'
                            value={
                              plData?.childCategoryL1BackColor || "#3699FF"
                            }
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  childCategoryL1BackColor: target.value,
                                });
                            }}
                            InputProps={{
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={
                                    plData?.childCategoryL1BackColor ||
                                    "#3699FF"
                                  }
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      childCategoryL1BackColor: hex,
                                    })
                                  }
                                />
                              ),
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <p className='textfield-title'>
                            Child Category Level 2 Background Color
                          </p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            placeholder='#CAEEFD'
                            value={
                              plData?.childCategoryL2BackColor || "#CAEEFD"
                            }
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  childCategoryL2BackColor: target.value,
                                });
                            }}
                            InputProps={{
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={
                                    plData?.childCategoryL2BackColor ||
                                    "#CAEEFD"
                                  }
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      childCategoryL2BackColor: hex,
                                    })
                                  }
                                />
                              ),
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item sx={12} sm={12} md={4}>
                        <Grid item className='radioBtnText'>
                          <FormControl>
                            <label
                              className='slugLabel showRequired'
                              style={{ marginBottom: 12 }}>
                              Show Confidential Notice/Reminder?
                            </label>
                            <RadioGroup
                              name='showConfidentialNotice'
                              value={`${plData?.showConfidentialNotice}`}
                              onChange={({ target }) => {
                                setPriceListData({
                                  ...plData,
                                  showConfidentialNotice: target.value,
                                });
                              }}>
                              <FormControlLabel
                                control={<Radio />}
                                label={
                                  <b>
                                    YES - Add a Confidential Notice/Reminder
                                  </b>
                                }
                                value={"true"}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label={
                                  <b>NO - Just show Short Description ONLY</b>
                                }
                                value={"false"}
                              />
                            </RadioGroup>
                          </FormControl>
                          <Grid
                            sx={12}
                            sm={6}
                            md={9}
                            style={{ marginBottom: 48 }}>
                            <span
                              className='slugLabel showRequired'
                              style={{ marginBottom: 4, marginTop: 18 }}>
                              Confidential Warning
                            </span>
                            <Grid
                              style={{
                                border: "1px solid black",
                                borderRadius: "4px",
                                fontSize: "14px",
                                fontWeight: "400",
                                padding: "10px 12px",
                              }}>
                              This document + pricing is Confidential. DO NOT
                              SHARE.
                            </Grid>
                          </Grid>
                        </Grid>
                        {plData?.showConfidentialNotice &&
                          JSON.parse(plData?.showConfidentialNotice) && (
                            <Grid item>
                              <Grid
                                item
                                xs={11}
                                className={[
                                  classes.gridWrapper,
                                  "priceListTextArea",
                                ]}>
                                <EmptyTextarea
                                  required={JSON.parse(
                                    plData?.showConfidentialNotice
                                  )}
                                  textLabel='Confidential Warning'
                                  value={plData?.confidentialWarning}
                                  onChange={({ target }) =>
                                    setPriceListData({
                                      ...plData,
                                      confidentialWarning: target.value,
                                    })
                                  }
                                  placeholder='This document + pricing is Confidential. DO NOT SHARE.'
                                />
                              </Grid>
                            </Grid>
                          )}
                        <Grid item>
                          <p className='textfield-title'>
                            Main Row/Titles Text Color
                          </p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            placeholder='#FFFFFF'
                            value={plData?.mainRowTitlesTextColor || "#FFFFFF"}
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  mainRowTitlesTextColor: target.value,
                                });
                            }}
                            InputProps={{
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={
                                    plData?.mainRowTitlesTextColor || "#FFFFFF"
                                  }
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      mainRowTitlesTextColor: hex,
                                    })
                                  }
                                />
                              ),
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <p className='textfield-title'>
                            Parent Category Text Color
                          </p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            placeholder='#FFFFFF'
                            value={plData?.parentCategoryTextColor || "#FFFFFF"}
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  parentCategoryTextColor: target.value,
                                });
                            }}
                            InputProps={{
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={
                                    plData?.parentCategoryTextColor || "#FFFFFF"
                                  }
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      parentCategoryTextColor: hex,
                                    })
                                  }
                                />
                              ),
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <p className='textfield-title'>
                            Child Category Level 1 Text Color
                          </p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            placeholder='#FFFFFF'
                            value={
                              plData?.childCategoryL1TextColor || "#FFFFFF"
                            }
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  childCategoryL1TextColor: target.value,
                                });
                            }}
                            InputProps={{
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={
                                    plData?.childCategoryL1TextColor ||
                                    "#FFFFFF"
                                  }
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      childCategoryL1TextColor: hex,
                                    })
                                  }
                                />
                              ),
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <p className='textfield-title'>
                            Child Category Level 2 Text Color
                          </p>
                          <InputField
                            contentEditable={false}
                            variant='outlined'
                            placeholder='#000000'
                            value={
                              plData?.childCategoryL2TextColor || "#000000"
                            }
                            onChange={({ target }) => {
                              if (target?.value?.length <= 7)
                                setPriceListData({
                                  ...plData,
                                  childCategoryL2TextColor: target.value,
                                });
                            }}
                            InputProps={{
                              startAdornment: (
                                <RenderStartAdornmentColor
                                  bgColor={
                                    plData?.childCategoryL2TextColor ||
                                    "#000000"
                                  }
                                  onColorSelect={({ hex }) =>
                                    setPriceListData({
                                      ...plData,
                                      childCategoryL2TextColor: hex,
                                    })
                                  }
                                />
                              ),
                              endAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item sx={12} sm={12} md={4}>
                        <Grid item className='radioBtnText'>
                          <FormControl>
                            <label
                              className='slugLabel showRequired'
                              style={{ marginBottom: 12 }}>
                              Hide Non-Discounted Products by Default?
                            </label>
                            <RadioGroup
                              name='hideNonDiscountedProductsByDefault'
                              value={`${plData?.hideNonDiscountedProductsByDefault}`}
                              onChange={({ target }) => {
                                setPriceListData({
                                  ...plData,
                                  hideNonDiscountedProductsByDefault:
                                    target.value,
                                });
                              }}>
                              <FormControlLabel
                                control={<Radio />}
                                label={
                                  <b>YES - Hide Non-Discounted Products</b>
                                }
                                value={"true"}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label={
                                  <b>
                                    NO - Show All Products Available to Users
                                    with Price List Access including
                                    Public/Everyone Products
                                  </b>
                                }
                                value={"false"}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid style={{ marginTop: 140 }}>
                          <b style={{ color: plData?.mainTextColor || null }}>
                            Quick Preview Price List Colors
                          </b>
                          <PriceListQuickPreview plData={plData} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item sx={12} justifyContent='flex-end'>
                      <Button
                        color='primary'
                        variant='contained'
                        className='dashboardButtons'
                        type='submit'>
                        Continue to Layout
                      </Button>
                    </Grid>
                  </ValidatorForm>
                </TabPanel>
                <TabPanel value={`2`} index={1}>
                  <PriceListLayout
                    plData={plData}
                    setPriceListData={setPriceListData}
                    addUpdatePriceListColumns={addUpdatePriceListColumns}
                    onPreview={onPreview}
                  />
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
        </Grid>
      </AccordionContainer>
    </div>
  );
};

export { PriceListDesign };
