import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import {
  CircleCheckedIcon,
  CircleCrossIcon,
  Close,
  DeleteOverlayCloseIcon,
} from "../../../ofishadmin/views/LeftNav/SvgIcons";
import {
  Button,
  DialogContent,
  FormControlLabel,
  Grid,
  Popover,
} from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import { SortedByFilterCheckbox } from "../checkbox/SortedByFilterCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../store/assetsManagement/assetsActions";

const StyledMenu = withStyles({
  position: "relative",
})(props => (
  <Menu
    className='modalContent'
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "center",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "left",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles(theme => ({
  customPaper: {
    borderRadius: 20,
  },
  previewBorderRadius: {
    borderRadius: 12,
  },
  toolTipContent: {
    backgroundColor: "transparent",
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function RightClickAssestPreview({
  rightManuBar,
  handleClose,
  assetsMenuBar,
}) {
  return (
    <div className='assetsMenuBar'>
      <StyledMenu
        id='customized-menu'
        anchorEl={rightManuBar}
        keepMounted
        open={Boolean(rightManuBar)}
        onClose={handleClose}>
        {assetsMenuBar.map(currValue => {
          const isFolder = currValue.type === "folder";
          return (
            <div>
              <MenuItem
                onClick={() => {
                  currValue.onClick();
                }}
                disabled={isFolder}>
                <ListItemIcon>{currValue.icon}</ListItemIcon>
                <ListItemText
                  className={
                    currValue.title === "Delete"
                      ? "deleteMenuItem"
                      : "otherMenuItem"
                  }
                  primary={currValue.title}
                />
              </MenuItem>
            </div>
          );
        })}
      </StyledMenu>
    </div>
  );
}

export const AssestPreview = ({ open, closeDialog, previewItem }) => {
  const classes = useStyles();
  const handleClose = () => {
    closeDialog();
  };

  const [fileType, setFileType] = useState("");
  const [pageNumber, setPageNumber] = useState([1]);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onPdfLoad = ({ numPages }) => {    
    const pagesArray = [];
    for (let i=1; i <= numPages; i++) {
      pagesArray.push(i);
    }
    setPageNumber([...pagesArray]);
  };

  useEffect(() => {
    if (previewItem?.type?.toLowerCase().includes("pdf")) {
      setFileType("pdf");
    } else if (
      previewItem?.type?.toLowerCase().includes("doc") ||
      previewItem?.type?.toLowerCase().includes("docx")
    ) {
      setFileType("doc");
    } else if (
      previewItem?.type?.toLowerCase().includes("jpg") ||
      previewItem?.type?.toLowerCase().includes("jpeg") ||
      previewItem?.type?.toLowerCase().includes("png") ||
      previewItem?.type?.toLowerCase().includes("gif") ||
      previewItem?.type?.toLowerCase().includes("jfif") ||
      previewItem?.type?.toLowerCase().includes("webp")
    ) {
      setFileType("image");
    } else if (
      previewItem?.type?.toLowerCase().includes("mp4") ||
      previewItem?.type?.toLowerCase().includes("3gp") 
    ) {
      setFileType("video");
    } else {
      setFileType("unknown");
    }
  }, [previewItem]);

  const renderFile = () => {
    switch (fileType) {
      case "pdf":
        return (
          <div
            style={{
              height: "500px",
              width: "400px",
              overflow: "scroll",
            }}>
            <Document file={previewItem?.url} onLoadSuccess={onPdfLoad}>
              {pageNumber.map(page => 
                <Page pageNumber={page} />
              )}
            </Document>
          </div>
        );
      case "doc":
      case "docx":
        return (
          <div>
            <iframe
              title={`${previewItem?.name}.${previewItem?.type}`}
              src={`https://aavazurestorage.blob.core.windows.net/ofishassetsstaging/file_55ab88c2-7f46-45c5-817e-777b099a2dd5`}></iframe>
          </div>
        );
      case "image":
        return <img src={previewItem?.url} alt='asset-icon' />;
      case "video":
        return (
          <video width='100%' height='auto' controls>
            <source src={previewItem?.url} />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return <p>Unsupported file type</p>;
    }
  };

  return (
    <div className='Preview'>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        maxWidth='sm'
        classes={{ paper: classes.previewBorderRadius }}>
        <MuiDialogTitle>
          <span style={{ marginRight: "10px" }}>{previewItem?.name}</span>
          <span
            disableRipple
            style={{ cursor: "pointer" }}
            aria-label='close'
            onClick={handleClose}>
            <Close width={"29"} height={"29"} />
          </span>
        </MuiDialogTitle>
        {renderFile()}
      </Dialog>
    </div>
  );
};

export const TooltipPreview = ({ item }) => {
  return (
    <>
      <Card className='smallPreview'>
        <Typography className='title'>{item?.name ?? "No Product"}</Typography>
        <div className='tooltipImg'>
          <img
            className='previewImageTooltip'
            src={item?.url}
            alt='Preview-Img'
          />
        </div>
      </Card>
    </>
  );
};

export const TooltipDescription = ({
  children,
  title,
  placement,
  disableHoverListener,
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={title}
      placement={placement}
      classes={{ tooltip: classes.toolTipContent }}
      disableHoverListener={disableHoverListener}>
      {children}
    </Tooltip>
  );
};

export const DeleteFilesOverlay = ({
  openDeleteFilesOverlay,
  setOpenDeleteFilesOverlay,
  deleteAssetFiles,
  overlayText1,
  overlayText2,
  buttonText,
  heading="Move Selected Files to Trash"
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        maxWidth='1100px'
        open={openDeleteFilesOverlay}
        onClose={() => {
          setOpenDeleteFilesOverlay(false);
        }}
        aria-labelledby='responsive-dialog-title'
        classes={{ paper: classes.customPaper }}>
        <Grid className='deleteFilesOverlay'>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            className='deleteHeaderBox'
            style={{
              padding: "24px 32px",
              backgroundColor: "#3699FF",
              color: "#FFFFFF",
            }}>
            <span className='deleteOvelayHeading'>
              {heading}
            </span>
            <IconButton
              disableRipple
              aria-label='close'
              onClick={() => {
                setOpenDeleteFilesOverlay(false);
              }}>
              <DeleteOverlayCloseIcon />
            </IconButton>
          </Grid>
          <Grid className='lowerBox'>
            <div className='firstTitle'>{overlayText1}</div>
            <div className='secondTitle'>{overlayText2}</div>
            <Button
              variant='contained'
              className='deleteButton'
              onClick={() => {
                deleteAssetFiles();
              }}>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export const AssetsActionDetials = ({
  id,
  text,
  removeAssetsActionDetials,
}) => {
  return (
    <div className='assetFilesActionUndo'>
      <div
        className='assetsActionDetials'
        style={{ backgroundColor: id === "trash" ? "#E06145" : "#18BC6D" }}>
        <div className='assestCheckedIcon'>
          <CircleCheckedIcon />
          <span>{text}</span>
        </div>
        <IconButton onClick={() => removeAssetsActionDetials()}>
          <CircleCrossIcon />
        </IconButton>
      </div>
    </div>
  );
};

export const SortByFilter = ({ isOpenSortByFilter, setIsOpenSortByFilter }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    orderByAlphabet,
    orderByRecentlyUpdated,
    orderByCreatedDate,
    orderByFileSize,
  } = useSelector(state => state.AssetsReducer);

  const handleClose = () => {
    setIsOpenSortByFilter(null);
  };

  const handleCheckboxChange = prop => {
    if (prop === "orderByAlphabet") {
      dispatch(assetsActions.onChange("orderByAlphabet", !orderByAlphabet));
    } else if (prop === "orderByRecentlyUpdated") {
      dispatch(
        assetsActions.onChange(
          "orderByRecentlyUpdated",
          !orderByRecentlyUpdated
        )
      );
    } else if (prop === "orderByCreatedDate") {
      dispatch(
        assetsActions.onChange("orderByCreatedDate", !orderByCreatedDate)
      );
    } else if (prop === "orderByFileSize") {
      dispatch(assetsActions.onChange("orderByFileSize", !orderByFileSize));
    }
  };

  // const open = Boolean(isOpenSortByFilter);
  const id = isOpenSortByFilter ? "simple-popover" : undefined;

  return (
    <div className='sortByFilter' style={{ borderRadius: "10px" }}>
      <Popover
        id={id}
        open={isOpenSortByFilter}
        anchorEl={isOpenSortByFilter}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.customPaper }}>
        <Grid className='filterBox'>
          <Grid className='fiterBoxHeader'>
            <span>Sort by</span>
          </Grid>
          <Grid
            container
            direction='column'
            justifyContent='flex-start'
            className='filterContent'>
            <FormControlLabel
              control={
                <SortedByFilterCheckbox
                  handleCheckboxChange={() =>
                    handleCheckboxChange("orderByAlphabet")
                  }
                  checked={orderByAlphabet}
                />
              }
              label='Alphabetical'
            />
            <FormControlLabel
              control={
                <SortedByFilterCheckbox
                  handleCheckboxChange={() =>
                    handleCheckboxChange("orderByRecentlyUpdated")
                  }
                  checked={orderByRecentlyUpdated}
                />
              }
              label='Recently Updated'
            />
            <FormControlLabel
              control={
                <SortedByFilterCheckbox
                  handleCheckboxChange={() =>
                    handleCheckboxChange("orderByCreatedDate")
                  }
                  checked={orderByCreatedDate}
                />
              }
              label='Created Date'
            />
            <FormControlLabel
              control={
                <SortedByFilterCheckbox
                  handleCheckboxChange={() =>
                    handleCheckboxChange("orderByFileSize")
                  }
                  checked={orderByFileSize}
                />
              }
              label='File Size'
            />
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};
