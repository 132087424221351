import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getFilterOptions() {
  return axios
    .get(`${endpoints.locatorWidget.getFilterOptions}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getSelectedFilterOptions(id) {
  return axios
    .get(
      `${
        endpoints.locatorWidget.getSelectedFilterOptions
      }${"?LocatorWidgetId="}${id}`
    )
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getFilterOptionsKeyValuePair(id) {
  return axios
    .get(
      `${endpoints.locatorWidget.getFilterOptionsKeyValuePair}${"?For="}${id}`
    )
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getSettings(id) {
  return axios
    .get(`${endpoints.locatorWidget.getSettings}${"?LocatorWidgetId="}${id}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveFilters(id, filters) {
  return axios
    .post(endpoints.locatorWidget.addUpdateLocatorWidgetFilter, {
      locatorWidgetId: id,
      locatorWidgetFilters: filters,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveFilterOption(id, name, type) {
  return axios
    .post(endpoints.locatorWidget.saveFilterOption, {
      id: id,
      name: name,
      type: type,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveSettings(id, title, subtitle, status) {
  return axios
    .post(endpoints.locatorWidget.saveSettings, {
      id: id,
      title: title,
      subtitle: subtitle,
      locatorStatus: status,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getEmbedCode(id) {
  return axios
    .get(`${endpoints.locatorWidget.getEmbedCode}${"?LocatorWidgetId="}${id}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveEmbedCode(id, embedCode) {
  return axios
    .post(endpoints.locatorWidget.saveEmbedCode, {
      locatorWidgetId: id,
      embedCode: embedCode,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getCompanyOverview() {
  return axios
    .get(endpoints.newsRoomAdmin.getCompanyOverview)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getLeadership() {
  return axios
    .get(endpoints.newsRoomAdmin.getLeadership)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getContactInfo() {
  return axios
    .get(endpoints.newsRoomAdmin.getComContactInfo)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveCompanyOverview(des, imgUrl) {
  return axios
    .post(endpoints.newsRoomAdmin.saveCompOverview, {
      newsRoomId: 1,
      companyDesc: des,
      featuredImageURL: imgUrl,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function onSaveBoilerPlateDescription(des) {
  return axios
    .post(endpoints.newsRoomAdmin.saveNewsRoomBoilerplate, {
      newsRoomId: 1,
      companyBoilerplate: des,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveLeaderShip(leaderData) {
  return axios
    .post(endpoints.newsRoomAdmin.saveLeader, {
      leaderships: leaderData,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveComContact(comData) {
  return axios
    .post(endpoints.newsRoomAdmin.saveComContact, {
      newsRoomId: 1,
      pressContact: comData.pressContact,
      pressContactEmail: comData.pressContactEmail,
      imageURL: comData.imageURL,
      companyName: comData.companyName,
      address: comData.address,
      city: comData.city,
      state: comData.state,
      zipCode: comData.zipCode,
      countryOrRegion: comData.countryOrRegion,
      contacts: comData.contacts,
      faxNumber: comData.faxNumber,
      faxCountryCode: comData.faxCountryCode,
      email: comData.email,
      socials: comData.socials,
      businessHours: comData.businessHours,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getQuickLinks() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getQuickLinks}${"?newsRoomId=1"}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getFeaturedContent() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getFeaturedContent}${"?newsRoomId=1"}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getMenuOptions() {
  return axios
    .get(`${endpoints.newsRoomAdmin.getMenuOptions}${"?newsRoomId=1"}`)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveQuickLinks(comData) {
  return axios
    .post(endpoints.newsRoomAdmin.saveQuickLinks, {
      newsRoomId: 1,
      newsRoomQuickActionsIds: comData,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function saveFeaturedContent(
  contentId,
  slideTitle,
  slideSubTitle,
  bannerImageUrl,
  linkTo,
  url,
  urlTarget,
  showImageOnly
) {
  return axios
    .post(endpoints.newsRoomAdmin.saveFeaturedContent, {
      newsRoomId: 1,
      contentId: contentId,
      content: {
        newsRoomId: 1,
        slideTitle: slideTitle,
        slideSubTitle: slideSubTitle,
        bannerImageUrl: bannerImageUrl,
        linkTo: linkTo,
        url: url,
        urlTarget: urlTarget,
        showImageOnly: showImageOnly,
      },
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

export const locatorWidgetApi = {
  getCompanyOverview: getCompanyOverview,
  getLeadership: getLeadership,
  getContactInfo: getContactInfo,
  saveCompanyOverview: saveCompanyOverview,
  saveLeaderShip: saveLeaderShip,
  saveComContact: saveComContact,
  getQuickLinks: getQuickLinks,
  saveQuickLinks: saveQuickLinks,
  onSaveBoilerPlateDescription: onSaveBoilerPlateDescription,
  saveSettings: saveSettings,
  getSettings: getSettings,
  saveFeaturedContent: saveFeaturedContent,
  getFeaturedContent: getFeaturedContent,
  getMenuOptions: getMenuOptions,
  getEmbedCode: getEmbedCode,
  saveEmbedCode: saveEmbedCode,
  getFilterOptions: getFilterOptions,
  getFilterOptionsKeyValuePair: getFilterOptionsKeyValuePair,
  saveFilters: saveFilters,
  getSelectedFilterOptions: getSelectedFilterOptions,
  saveFilterOption: saveFilterOption,
};
