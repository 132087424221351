import {
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import SoftwareGridFilter from "./SoftwareGridFilter";
import { Chevron, ChevronRight } from "../../LeftNav/SvgIcons";
import RadioButtons from "../../../../components/common/RadioButton/RadioButton";
import { FiChevronDown } from "react-icons/fi";
import { SelectInput } from "../../../../components/common";
import CustomCheckbox from "../../../../components/common/checkbox/CustomCheckbox";

const listItems = [
  { id: 1, name: "Day(s)" },
  { id: 2, name: "Week(s)" },
  { id: 3, name: "Month(s)" },
];

export const ReleaseRequirementsPreview = ({
  selectedReleasePortal,
  updatedTempData,
  poratlAndContactData,
  selectedReleaseId,
  buildId,
}) => {
  const [portalRequirmentItem, setportalRequirmentItem] = useState(3);
  const [contactRequirmentItem, setContactRequirmentItem] = useState(3);
  const [releaseInfo, setReleaseInfo] = useState({});
  
  

  const [searchSelectdPortals, setSearchSelectdPortals] =useState("")
  const [searchSelectdContacts, setSearchSelectdContacts] =useState("")

  const filetrdPortalsData = selectedReleasePortal.filter((X) => X?.name?.toLowerCase().includes(searchSelectdPortals.toLocaleLowerCase()) || X?.description.toLocaleLowerCase().includes(searchSelectdPortals.toLocaleLowerCase()));

  const filetrdContactsData = updatedTempData.filter((X) => X?.value?.toLowerCase().includes(searchSelectdContacts.toLocaleLowerCase()) || X?.generic.toLocaleLowerCase().includes(searchSelectdContacts.toLocaleLowerCase()));


  const defaultItemsToShow = 3;

  const handlePortalRequirements = () => {
    // Toggle between showing more or less items
    setportalRequirmentItem((prevItems) =>
      prevItems === defaultItemsToShow
        ? filetrdPortalsData.length
        : defaultItemsToShow
    );
  };

  const handleContactRequirements = () => {
    // Toggle between showing more or less items
    setContactRequirmentItem((prevItems) =>
      prevItems === defaultItemsToShow
        ? filetrdContactsData.length
        : defaultItemsToShow
    );
  };

  const releaseBasicsProps = ({ propName, value }) => {
    setReleaseInfo({
      ...releaseInfo,
      [propName]: value,
    });
  };

  return (
    <Grid className="releaseConatiner">
      <h2>{buildId ? "Build" : "Release"} Requirements</h2>
      <h3>Portal Requirements</h3>
      <p>{selectedReleasePortal?.length} OFish-Powered Portals Applied</p>
      <Grid className="searchContainer">
        <SoftwareGridFilter
          search={searchSelectdPortals}
          setSearch={setSearchSelectdPortals}
          placeholder={"Search"}
        />
      </Grid>
      <Grid className="useRequirementsChips">
        <Grid className="chevronIcon">
          <IconButton onClick={handlePortalRequirements}>
            <ChevronRight />
          </IconButton>
        </Grid>
        <Grid className="portalItemGrid">
          {filetrdPortalsData?.slice(0, portalRequirmentItem).map((item) => (
            <Grid className="portalChips">
              <Typography className="chipsTypo">{item.name}</Typography>
              &nbsp;
              <Typography className="deviceName">{item.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <h3>Contact Access</h3>
      <p>{updatedTempData?.length} Contact Types Applied</p>
      <Grid className="searchContainer">
        <SoftwareGridFilter
          search={searchSelectdContacts}
          setSearch={setSearchSelectdContacts}
          placeholder={"Search"}
        />
      </Grid>
      <Grid className="useRequirementsChips">
        <Grid className="chevronIcon">
          <IconButton onClick={handleContactRequirements}>
            <ChevronRight />
          </IconButton>
        </Grid>
        <Grid className="portalItemGrid">
          {filetrdContactsData.slice(0, contactRequirmentItem).map((item) => (
            <Grid className="portalChips">
              <Typography className="chipsTypo">{item.value}</Typography>
              &nbsp;
              <Typography className="deviceName">{item.generic}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <h3 className="updateOption">Update Options</h3>
      <h4 className="updateMethod">
        Update Methods<span className="stric">*</span>
      </h4>
      <Grid>
        <Grid className="checkOptionContainer">
          <CustomCheckbox
            checkedId={"bluetooth"}
            checkValue={poratlAndContactData.updateMethodBluetooth}
          />
          <span className="bluetoothText">
            Bluetooth{" "}
            <span>
              - Allow users to update their device via Bluetooth on an
              OFish-Powered mobile app
            </span>
          </span>
        </Grid>
        <Grid className="checkOptionContainer">
          <CustomCheckbox
            checkedId={"wifi"}
            checkValue={poratlAndContactData.updateMethodWifi}
          />
          <span className="bluetoothText">
            WiFi{" "}
            <span>
              - Allow the user to update directly from their device if connected
              to WiFi via a Gateway or Ofish API
            </span>
          </span>
        </Grid>

        <Grid item className="software_checked">
          <Typography variant="body2" className="updateMethod">
            Force Update?<span className="stric">*</span>
          </Typography>
          <Grid>
            <Grid item className="grid_checkbox forcreUpdate">
              <RadioButtons
                label={"Yes"}
                value={1}
                selectedValue={poratlAndContactData?.isForceUpdate === true}
                handleChange={() =>
                  releaseBasicsProps({
                    propName: "isForceUpdate",
                    value: 1,
                  })
                }
              />
              <List>
                <ListItem className="radioBtntLable">
                  Yes
                  <Typography variant="caption">
                    - Force users on previous {buildId ? "Build" : "Release"} to update within
                  </Typography>
                </ListItem>
              </List>
              <Grid item className="forceUser">
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    disabled={selectedReleaseId && true }
                    value={parseInt(poratlAndContactData.numberToForceUpdate)}
                  />
                </Grid>
                <Grid item className="releaseDropdown">
                  <SelectInput
                    IconComponent={<Chevron />}
                    disabled={selectedReleaseId && true }
                    placeholder="Please Select"
                    customMenuItems={listItems?.map((b) => {
                      return {
                        id: b.id,
                        name: b.name,
                      };
                    })}
                    value={
                      listItems.find(
                        (item) =>
                          item.name ===
                          poratlAndContactData?.typeToForceUpdate
                      )?.id
                    }
                    
                    InputProps={{
                      endAdornment: <FiChevronDown />,
                      style: { justifyContent: "flex-end" },
                    }}
                  />
                </Grid>
                <Typography variant="caption">of release</Typography>
              </Grid>
            </Grid>
            <Grid item className="grid_checkbox forcreUpdate">
              <RadioButtons
                label={"No"}
                value={poratlAndContactData?.isForceUpdate === false}
                
              />
              <List>
                <ListItem className="radioBtntLable">
                  No
                  <Typography variant="caption">
                    - Users can upgrade anytime at their choosing
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
