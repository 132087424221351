import React, { useState, useEffect, useCallback } from "react";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import {
  FrolaEditor,
  SelectInput,
  Spinner,
} from "../../../../components/common";
import { ConvTypeModal } from "./ConvTypeModal";
import { AttachFile } from "@material-ui/icons";
import {
  ChatClose,
  ChatPlus,
  ChatUser,
  CopyDataIcon,
  Cross,
  Dragable,
  EndUserStarFill,
  EndUserStarOutLine,
  ExpandCloseIcon,
  ExpandIcon,
  NoInbox,
} from "../../LeftNav/SvgIcons";
import ChatWindow from "./ChatWindow";
import nochatgraphic from "../../../../resources/images/noconversation.png";
import { ConvTagsModal } from "./ConvTagsModal";
import ConversationMediaUploadPopup from "../../../../components/common/Conversation/ConversationMediaUploadPopup";
import AutoComp from "../Conversations/AutoComp";
import { conversationAction } from "../../../../store/conversations/conversationAction";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../../../config/conversationConfig";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import { ConvAssigneModal } from "./ConvAssigneModal";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import TagsOverlay from "./TagsOverlay";
import { TooltipDescription } from "../../../../components/common/Modal/AssetsModals";
import { getTextColor } from "./ConvTypeModal";
import { ConversationModal } from "./ConversationModal";

const convStatusesClass = {
  1: "Active",
  2: "Draft",
  3: "Inactive",
  4: "Inactive",
};

function EndUserChat({
  isLoading,
  roomDetail,
  onConvStatusChange,
  roomMsgs,
  onexpandclick,
  oncloseclick,
  close,
  onSendMessage,
  isTemplateModal,
  setIsTemplate,
  addTemplate,
  setAddTemplate,
  isNewConversation,
  companyList,
  onGetCompanyAutoCompleteByName,
  setSelectedCompanyList,
  subject,
  setSubject,
  setIsStar,
  setLoading,
  selectedConversationType,
  setSelectedConversationType,
  expand,
}) {
  const dispatch = useDispatch();
  const { roomsData, conversationPlateform } = useSelector(
    state => state.conversationReducer
  );
  const [isTypeModal, setIsTypeModal] = useState(false);
  const [isTagModal, setIsTagModal] = useState(false);
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [comDescription, setComDescription] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [convStatuses, setConvStatuses] = useState([]);
  const [convTypes, setConvTypes] = useState([]);
  const [convAssigne, setConvAssigne] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [isAssigeeOpen, setIsAssigeeOpen] = useState(false);
  const [selectedInbox, setSelectedInbox] = useState(null);
  const [isInboxOpen, setIsInboxOpen] = useState(false);
  const [openConversationModal, setOpenCoversationModal] = useState(null);
  const [inboxTypeList, setInboxTypeList] = useState([]);
  const [isEmptyMessage , setIsEmptyMessage] = useState(false);

  const onHandleComDescription = model => {
    if(conversationPlateform?.platform === "Facebook" || 
      conversationPlateform?.platform === "Instagram"){
      setComDescription(model.target.value);
      setIsEmptyMessage(Boolean(model.target.value?.trim()))
    }else{
      setComDescription(model);
      let removedEntities = model?.replace(/&nbsp;/g, ' ');
      let withoutTags = removedEntities?.replace(/<[^>]*>/g, '');
      let cleanedStr = withoutTags?.replace(/\s+/g, '').trim();
      setIsEmptyMessage(Boolean(cleanedStr))
    }
  };

  useEffect(() => {
    dispatch(endUserActions.onGetAllAdmins()).then(data => {
      if (data?.success) {
        setConvAssigne(data.data);
      }
    });
  }, [dispatch]);

  // useEffect(() => {
  //   setSelectedInbox(roomDetail?.inbox);
  // }, [roomDetail?.inbox]);

  useEffect(() => {
    if (roomDetail?.assignee) {
      const selectedAssignee = convAssigne.find(
        x => x.id === roomDetail?.assignee
      );
      setSelectedAssignee(
        `${selectedAssignee.firstName} ${selectedAssignee.lastName}`
      );
    } else {
      setSelectedAssignee(null);
    }
    setSelectedInbox(roomDetail?.inbox);
  }, [roomDetail]);
  useEffect(() => {
    dispatch(
      productInventoryActions.onLoadData({
        url: endpoints.conversation.getConversationsDdl,
        payload: {
          name: "",
          productid: 2,
        },
      })
    ).then(data => {
      if (data?.responseCode === 1) setConvTypes(data?.responseData || []);
    });
    dispatch(
      conversationAction.onGetConversationStatuses({
        productid: 2,
        statusName: "",
      })
    ).then(statusesResponse => {
      if (statusesResponse?.responseCode === 1)
        setConvStatuses([...statusesResponse?.responseData]);
    });
  }, [dispatch]);

  useEffect(() => {
    setComDescription(addTemplate?.content);
  }, [addTemplate]);

  const loadInboxTyoeList = useCallback(
    ({ url, payload }) => {
      dispatch(
        productInventoryActions.onLoadData({
          url: url,
          payload: payload,
        })
      ).then(data => {
        if (data?.responseCode === 1)
          setInboxTypeList(data?.responseData || []);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    loadInboxTyoeList({
      url: endpoints.conversation.getInboxTypes,
      payload: {},
    });
  }, [loadInboxTyoeList]);

  const renderConvStatusClassname = () => {
    let eStatusClass = "newCompanyHeaderSelect";
    if (roomDetail?.statusId) {
      const selectedStatus = convStatusesClass[roomDetail?.statusId];
      if (selectedStatus)
        eStatusClass = `newCompanyHeaderSelect-${selectedStatus}`;
    }
    return eStatusClass;
  };

  const selectedRoom = roomsData.find(
    room => room?.roomId === roomDetail?.roomId
  );

  const handleChangeAssigee = assignee => {
    setSelectedAssignee(assignee);
    const newSelectedAssignee = convAssigne.find(
      x => `${x.firstName} ${x.lastName}` === assignee
    );
    setLoading(true);
    dispatch(
      conversationAction.onMarkRoomAssignee({
        userId: newSelectedAssignee?.id,
        roomId: roomDetail?.roomId,
      })
    ).then(statusesResponse => {
      dispatch(conversationAction.onChange("selectedAssignee", assignee));
      setLoading(false);
      if (statusesResponse?.responseCode === 1) {
        setIsAssigeeOpen(false);
      }
    });
  };

  const handleChangeInbox = inbox => {
    setSelectedInbox(inbox);
    setLoading(true);
    dispatch(
      conversationAction.onMarkInbox({
        inbox: inbox,
        roomId: roomDetail?.roomId,
      })
    ).then(statusesResponse => {
      setLoading(false);
      if (statusesResponse?.responseCode === 1) {
        setIsInboxOpen(false);
      }
    });
  };
  const onHandleCoversationModal = event => {
    setOpenCoversationModal(event.currentTarget);
  };

  function truncate(item) {
    if (item && item.length > 15) {
      return item.substring(0, 13) + "...";
    }
    return item;
  }

  return (
    <div className='chatMainWrapper'>
      {isLoading ? <Spinner /> : null}
      {(close || isNewConversation) && (
        <>
          <div className='conversationHeader'>
            <div className='headerNavigation'>
              <span>
                {/* {isNewConversation && ( */}
                <Tooltip
                  title={
                    selectedConversationType?.name?.length
                      ? selectedConversationType?.name
                      : "Unassigned"
                  }
                  placement='top'>
                  <Button
                    variant='contained'
                    style={{
                      backgroundColor: selectedConversationType?.color?.length
                        ? selectedConversationType.color
                        : "#e2e5e9",
                      color: getTextColor(selectedConversationType.color),
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => setIsTypeModal(true)}>
                    <span
                      style={{
                        color: getTextColor(selectedConversationType.color),
                      }}>
                      {selectedConversationType?.name?.length
                        ? truncate(selectedConversationType?.name)
                        : "Unassigned"}
                    </span>
                  </Button>
                </Tooltip>
                <div>
                  <Button
                    variant='outlined'
                    startIcon={<NoInbox />}
                    onClick={event => {
                      onHandleCoversationModal(event);
                      setIsInboxOpen(true);
                    }}>
                    {selectedInbox ?? "No Inbox"}
                  </Button>

                  {isInboxOpen ? (
                    <ConversationModal
                      openConversationModal={openConversationModal}
                      setOpenCoversationModal={setOpenCoversationModal}
                      handleChangeAssigee={handleChangeInbox}
                      selectedAssignee={selectedInbox}
                      convAssigne={inboxTypeList}
                      boxName='inbox'
                    />
                  ) : null}
                </div>
                {!isNewConversation && (
                  <>
                    <div>
                      <Button
                        variant='outlined'
                        startIcon={<ChatUser />}
                        onClick={event => {
                          onHandleCoversationModal(event);
                          setIsAssigeeOpen(true);
                        }}>
                        {selectedAssignee ?? "Assignee"}
                      </Button>

                      {isAssigeeOpen ? (
                        <ConversationModal
                          openConversationModal={openConversationModal}
                          setOpenCoversationModal={setOpenCoversationModal}
                          handleChangeAssigee={handleChangeAssigee}
                          selectedAssignee={selectedAssignee}
                          convAssigne={convAssigne}
                        />
                      ) : null}
                    </div>
                    <TooltipDescription
                      title={<TagsOverlay tags={roomDetail?.tags} />}
                      placement={"right-start"}>
                      <Button
                        variant='outlined'
                        startIcon={<ChatPlus />}
                        onClick={() => setIsTagModal(true)}>
                        Tags ({roomDetail?.tags?.length})
                      </Button>
                    </TooltipDescription>
                  </>
                )}
              </span>
              <span>
                <div className='headerFavContainer'>
                  {!isNewConversation && (
                    <>
                      <span
                        className=''
                        onClick={() =>
                          setIsStar(
                            !selectedRoom?.is_Favourite,
                            selectedRoom?.roomId
                          )
                        }>
                        {!selectedRoom?.is_Favourite ? (
                          <EndUserStarOutLine />
                        ) : (
                          <EndUserStarFill />
                        )}
                      </span>
                      {/* <Button endIcon={<Chevron />}>Closed</Button> */}
                      <span
                        className={`${renderConvStatusClassname()} coversationOpenButton`}>
                        <SelectInput
                          placeholder='Please Select'
                          disablePlaceholderOption
                          value={roomDetail?.statusId ?? 1}
                          customMenuItems={convStatuses.map(status => {
                            return {
                              ...status,
                              name: status?.nameInternal,
                            };
                          })}
                          handleChange={({ target }) =>
                            onConvStatusChange(target.value)
                          }
                        />
                      </span>
                    </>
                  )}
                  {expand ? (
                    <i onClick={onexpandclick}>
                      <ExpandCloseIcon />
                    </i>
                  ) : (
                    <i onClick={onexpandclick}>
                      <ExpandIcon />
                    </i>
                  )}
                  <i onClick={oncloseclick}>
                    <ChatClose />
                  </i>
                </div>
              </span>
            </div>
            {isNewConversation ? (
              <Grid container>
                <Grid item xs={6}>
                  <span className='chatToWrapper newChatToWrapper'>
                    <AutoComp
                      data={companyList}
                      onChangeClick={e => {
                        onGetCompanyAutoCompleteByName(e);
                      }}
                      onSetSelectedEmailList={e => {
                        setSelectedCompanyList(e);
                      }}
                      // autoComplete={autoComplete}
                    />
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <span className='convId'>
                    Conversation ID: <i>---</i>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className='newConvTitleWrapper'>
                    <input
                      type='text'
                      placeholder='Conversation Subject'
                      value={subject}
                      onChange={e => {
                        setSubject(e.target.value);
                      }}
                    />
                  </span>
                </Grid>
              </Grid>
            ) : (
              <>
                <span className='nameNId'>
                  <span>
                    {roomDetail.chatInfo[0]?.name !== ""
                      ? roomDetail.chatInfo[0]?.name
                      : "--"}
                  </span>
                  <span>
                    <i>Conversation ID:</i> {roomDetail?.roomId}
                  </span>
                </span>
                <h2>{roomMsgs[0]?.roomName ?? "--"}</h2>
              </>
            )}
          </div>
          <ChatWindow roomMsgs={roomMsgs} />
          <span className={
            conversationPlateform?.platform === "Facebook" ||
            conversationPlateform?.platform === "Instagram"           
            ?  'chatEditorSocial' : 'chatEditor'}>
            <Button
              variant='contained'
              className='open-templete'
              startIcon={<CopyDataIcon />}
              onClick={() => setIsTemplate(!isTemplateModal)}>
              OFish Template
            </Button>
            {/* <Button
              variant="contained"
              className="open-templete"
              startIcon={<SearchDataIcon />}
            >
              OFish Data
            </Button> */}
            <div>
              {/* {conversationPlateform?.platform !== "Social" && ( */}
                <span
                  className={
                    conversationPlateform?.platform === "Facebook" ||
                    conversationPlateform?.platform === "Instagram"  
                  ? "conv-attachment-icon-social" : "conv-attachment-icon"}
                  onClick={() => {
                    setIsUpload(true);
                  }}>
                  <div className='conversationFileUpload'>{fileName}</div>
                  <AttachFile />
                </span>
              {/* )} */}
              <FrolaEditor
                model={comDescription}
                conversationPlateform={conversationPlateform?.platform}
                onHandleModelChange={model => onHandleComDescription(model)}
                comDescription={comDescription}
              />
            </div>
            <i className='sendBtn'>
              {fileName && (
                // <Tooltip title={fileName} placement='left'>

                // </Tooltip>
                <>
                  <p className='textEditorAttachment'>
                    <IconButton
                      onClick={() => {
                        setUploadFile(null);
                        setFileName(null);
                      }}>
                      <Cross />
                    </IconButton>
                    {truncate(fileName)}
                  </p>

                  <IconButton
                    aria-label='delete'
                    style={{
                      backgroundColor: "transparent",
                    }}>
                    <AttachFile />
                  </IconButton>
                </>
              )}
              <Button
                color='primary'
                variant='contained'
                disabled={ !isEmptyMessage && !fileName }
                onClick={() => {
                  onSendMessage({
                    messageContent: comDescription,
                    clearContent: () => setComDescription(""),
                    fileName: fileName,
                    uploadFile: uploadFile,
                  });
                  setUploadFile(null);
                  setFileName(null);
                }}>
                Send
              </Button>
            </i>
          </span>

          <span className='drageble'>
            <Dragable />
          </span>
        </>
      )}

      {roomDetail === null && !isNewConversation && (
        <div className='noChatContainer'>
          <img src={nochatgraphic} alt='no chant graphic' />
        </div>
      )}

      {isTypeModal && convTypes?.length > 0 && (
        <ConvTypeModal
          convTypes={convTypes}
          isvisible={isTypeModal}
          oncloseaction={() => {
            setIsTypeModal(false);
            dispatch(conversationAction.onChange("loadCurrentListing", true));
          }}
          selectedConversationType={selectedConversationType}
          setSelectedConversationType={setSelectedConversationType}
          roomDetail={roomDetail}
        />
      )}

      {isTagModal && (
        <ConvTagsModal
          isvisible={isTagModal}
          oncloseaction={() => {
            setIsTagModal(false);
            dispatch(conversationAction.onChange("loadCurrentListing", true));
          }}
          roomDetail={roomDetail}
          setLoading={setLoading}
        />
      )}

      {isAssignModal && (
        <ConvAssigneModal
          isvisible={isAssignModal}
          oncloseaction={() => setIsAssignModal(false)}
          roomDetail={roomDetail}
          onUpdateAssignee={assignee => (roomDetail.assignee = assignee)}
          setLoading={setLoading}
          convAssigne={convAssigne}
          setConvAssigne={setConvAssigne}
        />
      )}

      {isUpload && (
        <ConversationMediaUploadPopup
          isMultiple={false}
          openPopup={isUpload}
          closePopup={() => setIsUpload(false)}
          fileName={fileName}
          setFileInfo={(name, file) => {
            setFileName(name);
            setUploadFile(file);
          }}
        />
      )}
    </div>
  );
}

export default EndUserChat;
