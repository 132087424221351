import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from "@material-ui/core";
import { first } from "lodash";
import { AccessAlarm, CheckCircle, PortableWifiOff } from "@material-ui/icons";
import InputField from "./InputField";

const useStyles = makeStyles(theme => ({
  formControl: {
    "& .MuiInput-root": {
      paddingRight: "15px",
      cursor: "pointer",
    },
    minWidth: "100%",
    background: "#f3f6f9 !important",
    '& .MuiInputBase-input': {
      border:"unset !important",
    }
  },
  selectEmpty: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "6px",
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
}));

const Dropdown = ({
  selectlabel,
  selectSubtitle,
  value,
  customMenuItems,
  disabled,
  handleChange,
  placeholder,
  showRequired,
}) => {
  const classes = useStyles();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const onDropDown = () => {
    if (!isDropdownOpen) {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  };

  return (
    <div className='inputWrapper'>
      {selectlabel && (
        <label className={`${showRequired ? "showRequired" : ""}`}>
          {selectlabel}
        </label>
      )}

      {disabled ? (
        <InputField
          placeholder={placeholder}
          disabled={disabled}
          // inputlabel={selectlabel}
          value={
            customMenuItems.find(mi => `${mi?.id}` === `${value}`)?.name
          }
        />
      ) : (
        <FormControl className={classes.formControl} onClick={onDropDown}>
          <Select
            open={isDropdownOpen}
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
            id={selectlabel}
            value={value}
            defaultValue={first(customMenuItems)?.id}
            className={classes.selectEmpty}
            onChange={handleChange}
            disabled={disabled}
            renderValue={val => {
              // console.log({ customMenuItems, val });
              const selectedVal = customMenuItems.find(c => c.id === val);
              if (selectedVal) return selectedVal?.name;
              else if (placeholder) return placeholder;
            }}>
            {placeholder && (
              <MenuItem value={-1} disabled>
                <span>{placeholder}</span>
              </MenuItem>
            )}
            {Boolean(customMenuItems) &&
              customMenuItems.map(mi => {
                return (
                  <MenuItem key={mi.id} value={mi.id} disabled={disabled}>
                    {mi.name}
                  </MenuItem>
                );
              })}
          </Select>
          {selectSubtitle && (
            <FormHelperText className='inputSubtitle'>
              {selectSubtitle}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </div>
  );
};

const AvailabilityStatus = ({ item }) => {
  return (
    <>
      {`${item?.id}` === `-1` ? (
        <PortableWifiOff fontSize='small' style={{ color: "#FFAD4E" }} />
      ) : item?.isUpComing ? (
        <AccessAlarm fontSize='small' style={{ color: "#9B51E0" }} />
      ) : (
        <CheckCircle fontSize='small' style={{ color: "#18BC6D" }} />
      )}
    </>
  );
};

const DropdownAvaiabilityPeriods = ({
  selectlabel,
  selectSubtitle,
  value,
  customMenuItems,
  required,
  handleChange,
  placeholder,
}) => {
  const classes = useStyles();

  return (
    <div className='inputWrapper'>
      {selectlabel && <label>{selectlabel}</label>}
      <FormControl className={classes.formControl}>
        <Select
          id={selectlabel}
          value={value}
          IconComponent={() => ""}
          defaultValue={first(customMenuItems)?.id}
          className={classes.selectEmpty}
          onChange={handleChange}
          renderValue={val => {
            // console.log({ customMenuItems, val });
            const selectedVal = customMenuItems.find(c => c.id === val);
            if (selectedVal) return selectedVal?.name;
            else if (placeholder) return placeholder;
          }}>
          {placeholder && (
            <MenuItem value={-1} disabled>
              <span>{placeholder}</span>
            </MenuItem>
          )}
          {Boolean(customMenuItems) &&
            customMenuItems.map(mi => {
              return (
                <MenuItem
                  key={mi.id}
                  value={mi.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <AvailabilityStatus item={mi} />
                  <span
                    style={{
                      marginLeft: "5px",
                    }}>
                    {mi.name}
                  </span>
                </MenuItem>
              );
            })}
        </Select>
        {selectSubtitle && (
          <FormHelperText className='inputSubtitle'>
            {selectSubtitle}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

const CopyStorePricingDropdown = ({
  selectlabel,
  selectSubtitle,
  value,
  customMenuItems,
  required,
  handleChange,
  placeholder,
  showRequired,
}) => {
  const classes = useStyles();

  return (
    <div className='inputWrapper'>
      {selectlabel && (
        <label className={`${showRequired ? "showRequired" : ""}`}>
          {selectlabel}
        </label>
      )}

      <FormControl className={classes.formControl}>
        <Select
          id={selectlabel}
          value={value}
          defaultValue={first(customMenuItems)?.id}
          className={classes.selectEmpty}
          onChange={handleChange}
          renderValue={val => {
            const selectedVal = customMenuItems.find(c => c.id === val);
            if (selectedVal) return selectedVal?.value;
            else if (placeholder) return placeholder;
          }}>
          {placeholder && (
            <MenuItem value={-1} disabled>
              <span>{placeholder}</span>
            </MenuItem>
          )}
          {Boolean(customMenuItems) &&
            customMenuItems.map(mi => {
              return (
                <MenuItem key={mi.id} value={mi.id}>
                  {mi.value}
                </MenuItem>
              );
            })}
        </Select>
        {selectSubtitle && (
          <FormHelperText className='inputSubtitle'>
            {selectSubtitle}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export { Dropdown, DropdownAvaiabilityPeriods, CopyStorePricingDropdown };
