import React, { useState, useEffect, useCallback } from "react";
import { Add } from "@material-ui/icons";
import { Button, Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Dashboard from "../Dashbard";
import AllContactsGrid from "./AllContactsGrid";
import AllEndUserGridFilter from "./AllEndUserGridFilter";
import { RoutesObj } from "../../../../routes";
import { DummyComp } from "./DummyComp";
import {
  GridActionNotification,
  ListViewTile,
  Pagination,
  Spinner,
} from "../../../../components/common";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { UsersGroupGrid } from "./UsersGroupGrid";
import { StarFill, StarOutline } from "../../LeftNav/SvgIcons";
import { AddNewBusinessGroup } from "../BusinessConsumers/AddNewBusinessGroup";
import { endpoints } from "../../../../config/apiConfig";

export const AllEndUserWrapper = ({
  isListView,
  activeTabId,
  endUserList,
  checkGroupsSelected,
  isDelete,
  order,
  setOrderBy,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loadCustomerList,
  onSelectAll,
  paginationResponse,
  setLoading,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToEndUser = (value, isView) => {
    history.push({
      pathname: RoutesObj.CustomerOverview.path,
      userId: value.userId,
      search: `id=${value.userId}&acitveTabId=${isView}`,
    });
  };

  const onMarkFavouriteCustomer = selectedCustomer => {
    setLoading(true);
    dispatch(
      contactsActions.onPostCustomersEndpoint({
        endpoint: endpoints.customers.markUnMarkFavouriteUsers,
        data: [
          {
            userId: selectedCustomer.id,
            isFavourite: !selectedCustomer.isFavourite,
          },
        ],
      })
    ).then(data => {
      setLoading(false);
      if (!data?.success) {
        toast.error(data?.message);
      } else {
        loadCustomerList();
      }
    });
  };

  return (
    <>
      {isListView ? (
        <AllContactsGrid
          endUserList={endUserList}
          selectedRecord={item => {
            checkGroupsSelected(item);
          }}
          isDelete={isDelete}
          order={order}
          onOrderBy={setOrderBy}
          loadCustomerLists={loadCustomerList}
          activeTabId={activeTabId}
          onSelectAll={onSelectAll}
          isDeleted={`${activeTabId}` === `5`}
        />
      ) : (
        <Grid container spacing={2}>
          {endUserList.map(item => {
            return (
              <Grid item md={3} key={item.id}>
                <ListViewTile
                  companyname={`${item?.firstName} ${item?.lastName}`}
                  contacts={
                    item?.deviceRegistered > 0
                      ? `${item?.deviceRegistered} Product${
                          item?.deviceRegistered > 1 ? "s" : ""
                        }`
                      : "No Product"
                  }
                  buttontxt={item.groupName || "-"}
                  backgroundcolor={item?.groupColor}
                  textcolor={item?.textcolor}
                  rating={item?.isFavourite ? <StarFill /> : <StarOutline />}
                  onRatingClick={() => {}}
                  onClick={() => redirectToEndUser(item, "2")}
                  onMarkFavourite={() => onMarkFavouriteCustomer(item)}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Pagination
        recordCount={paginationResponse?.totalRecords}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

const AllEndUsers = () => {
  document.title = "OFish - End Users";

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isEUListView } = useSelector(state => state.endUserReducer);

  const redirectUrl = () => {
    dispatch(endUserActions.onChange("envWithDeviceCount", []));
    history.push("/customer-overview");
  };
  const [isLoading, setLoading] = useState(false);
  const [endUserList, setEndUserList] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isNewGroup, setIsNewGroup] = useState(false);
  const [editableRecord, setEditableRecord] = useState("1");
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [activeTabId, setActiveTabId] = useState("1");
  const [isFilterAppling, setIsFilterAppling] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterModel, setFilters] = useState({
    searchText: "",
    startDate: "",
    endDate: "",
    productCategories: [],
    productSubCategoryId: [],
    environmentId: [],
    productName: [],
    productSKU: [],
    countryId: [],
    stateId: [],
    productStatus: [],
    cityId: [],
    address: [],
    firstName: [],
    lastName: [],
    email: [],
    lastLogin: null,
    deviceRegistered: [],
    rating: null,
    companyName: null,
    date: 0,
    groupIds: null,
    id: [],
    userStatus: [],
  });

  // End User Group
  const [paginationResponseEUG, setPaginationResponseEUG] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [pageEUG, setPageEUG] = useState(1);
  const [rowsPerPageEUG, setRowsPerPageEUG] = useState(10);
  const [orderEUG, setOrderByEUG] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });

  const loadCustomerList = useCallback(() => {
    const modal = {
      ...filterModel,
      email:
        filterModel.email?.length > 0
          ? filterModel.email.map(em => em.email)
          : [],
      firstName:
        filterModel.firstName?.length > 0
          ? filterModel.firstName.map(fn => fn.id)
          : [],
      lastName:
        filterModel.lastName?.length > 0
          ? filterModel.lastName.map(ln => ln.id)
          : [],
      id: filterModel.id?.length > 0 ? filterModel.id.map(ln => ln.id) : [],
      productCategories:
        filterModel.productCategories?.length > 0
          ? filterModel.productCategories.map(pc => pc.id)
          : [],
      productSubCategoryId:
        filterModel.productSubCategoryId?.length > 0
          ? filterModel.productSubCategoryId.map(pc => pc.id)
          : [],
      deviceRegistered:
        filterModel.deviceRegistered?.length > 0
          ? filterModel.deviceRegistered.map(dr => dr.id)
          : [],
      productName:
        filterModel.productName?.length > 0
          ? filterModel.productName.map(pn => pn.id)
          : [],
      productSKU:
        filterModel.productSKU?.length > 0
          ? filterModel.productSKU.map(ps => ps.id)
          : [],
      countryId:
        filterModel.countryId?.length > 0
          ? filterModel.countryId.map(ct => ct.value)
          : [],
      stateId:
        filterModel.stateId?.length > 0
          ? filterModel.stateId.map(st => st.value)
          : [],
      cityId:
        filterModel.cityId?.length > 0
          ? filterModel.cityId.map(ct => ct.value)
          : [],
      address:
        filterModel.address?.length > 0
          ? filterModel.address.map(ct => ct.value)
          : [],
      productStatus:filterModel.productStatus?.length > 0 ? filterModel.productStatus : [],
      recentlyAdded: `${activeTabId}` === `3` ? true : null,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    setLoading(true);
    dispatch(contactsActions.onGetEndUserList(modal)).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          const endUsers = data.data.data.map(x => {
            return {
              id: x.id,
              deviceRegistered: x.deviceRegistered,
              firstName: x.firstName,
              lastLogin: x.lastLogin,
              lastName: x.lastName,
              location: x.location,
              rating: x.rating,
              userId: x.userId,
              checked: false,
              groupName: x.groupName,
              lastLoginDT: x.lastLoginDT,
              groupColor: x.groupColor,
              image: x.image,
              isFavourite: x.isFavourite,
              registrationPendingDevices: x.registrationPendingDevices,
            };
          });
          setPaginationResponse(data?.data?.pagination);
          setEndUserList(endUsers);
          if (isFilterAppling) {
            setIsFilterApplied(true);
          }
          setIsFilterAppling(false);
        }
        setIsDelete(false);
      }
      setLoading(false);
    });

    // dispatch(
    //   contactsActions.onGetEndUserGroup({
    //     ...modal,
    //     isFavorite: `${activeTabId}` === `2`,
    //     pagination: {
    //       ...modal.pagination,
    //       pageNo: pageEUG,
    //       pageSize: rowsPerPageEUG,
    //       orderByKey: orderEUG.orderByKey,
    //       orderByVal: orderEUG.orderVal,
    //     },
    //   })
    // ).then(data => {
    //   if (data?.data?.data && data.success) {
    //     setPaginationResponseEUG(data?.data?.pagination);
    //   }
    //   setLoading(false);
    // });
  }, [
    filterModel,
    rowsPerPage,
    page,
    order.orderByKey,
    order.orderVal,
    pageEUG,
    rowsPerPageEUG,
    orderEUG.orderByKey,
    orderEUG.orderVal,
    dispatch,
  ]);

  useEffect(() => {
    loadCustomerList();
  }, [loadCustomerList]);

  useEffect(() => {
    setIsListView(isEUListView);
  }, [isEUListView]);

  const checkGroupsSelected = item => {
    endUserList.map(x => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    var count = endUserList.find(x => x.checked === true);
    setIsDelete(count ? true : false);
    setEndUserList([...endUserList], endUserList);
  };

  const onSelectAll = (items, isSelected) => {
    const selectionList = items.map(x => {
      return {
        ...x,
        checked: isSelected,
      };
    });
    setIsDelete(isSelected);
    setEndUserList([...selectionList]);
  };

  useEffect(() => {
    if (`${activeTabId}` === `1`) {
      setPage(1);
      setFilters({
        searchText: "",
        startDate: "",
        endDate: "",
        groupIds: null,
        userStatus: [],
        email: [],
        firstName: [],
        lastName: [],
        id: [],
        productCategories: [],
        productSubCategoryId: [],
        deviceRegistered: [],
        productName: [],
        productSKU: [],
        countryId: [],
        stateId: [],
        cityId: [],
        address: [],
        lastLogin: null,
        rating: null,
        companyName: null,
        date: 0,
        isFavorite: false,
      });
      setOrderBy({
        orderByKey: "Id",
        orderVal: 0,
      });
    } else if (`${activeTabId}` === `3`) {
      setPage(1);
      setFilters({
        searchText: "",
        startDate: "",
        endDate: "",
        groupIds: null,
        userStatus: [],
        email: [],
        firstName: [],
        lastName: [],
        id: [],
        productCategories: [],
        productSubCategoryId: [],
        deviceRegistered: [],
        productName: [],
        productSKU: [],
        countryId: [],
        stateId: [],
        cityId: [],
        address: [],
        lastLogin: null,
        rating: null,
        companyName: null,
        date: 0,
        isFavorite: false,
      });
      setOrderBy({
        orderByKey: "CreatedOn",
        orderVal: 0,
      });
    } else if (`${activeTabId}` === `4`) {
      setPage(1);
      setFilters({
        ...filterModel,
        userStatus: [3],
        isFavorite: false,
      });
    } else if (`${activeTabId}` === `5`) {
      setPage(1);
      setFilters({
        ...filterModel,
        userStatus: [-1],
        isFavorite: false,
      });
    } else if (`${activeTabId}` === `6`) {
      setPage(1);
      setFilters({
        ...filterModel,
        userStatus: [2],
        isFavorite: false,
      });
    } else if (`${activeTabId}` === `2`) {
      setFilters({
        ...filterModel,
        userStatus: [],
        isFavorite: true,
      });
      setPage(1);

      loadCusGroups();
    } else if (`${activeTabId}` === `7`) {
      history.push(RoutesObj.AllUserGroup.path);
    }
  }, [activeTabId]);

  useEffect(() => {
    if (location.state?.openTabId) {
      setActiveTabId(location.state?.openTabId);
      // history.replace(location.pathname);
    }
  }, [location.state]);

  const loadCusGroups = useCallback(() => {
    setLoading(true);
    const modal = {
      searchText: "",
      startDate: "",
      endDate: "",
      groupIds: null,
      userStatus: [],
      email: [],
      firstName: [],
      lastName: [],
      id: [],
      productCategories: [],
      productSubCategoryId: [],
      deviceRegistered: [],
      productName: [],
      productSKU: [],
      countryId: [],
      stateId: [],
      cityId: [],
      address: [],
      lastLogin: null,
      rating: null,
      companyName: null,
      date: 0,
      isFavorite: true,
      pagination: {
        pageSize: rowsPerPageEUG,
        pageNo: pageEUG,
        orderByKey: orderEUG.orderByKey,
        orderByVal: orderEUG.orderVal,
      },
    };
    dispatch(contactsActions.onGetEndUserGroup(modal)).then(data => {
      if (data?.data?.data && data.success) {
        setPaginationResponseEUG(data?.data?.pagination);
      }
      setLoading(false);
    });
  }, [dispatch]);

  const { endUserGroup } = useSelector(state => state.ContactsReducer);

  const onMarkFavourite = selectedGroup => {
    setLoading(true);
    dispatch(
      contactsActions.onPostCustomersEndpoint({
        endpoint: endpoints.customers.markUnMarkFavouriteUserGroup,
        data: [
          {
            groupId: selectedGroup.id,
            isFavourite: !selectedGroup.isFavorite,
          },
        ],
      })
    ).then(data => {
      setLoading(false);
      if (!data?.success) {
        toast.error(data?.message);
      } else {
        loadCusGroups();
      }
    });
  };

  useEffect(() => {
    setPage(1);
 }, [rowsPerPage])
   console.log({page , rowsPerPage , paginationResponse})
  const getTitle = useCallback(() => {
    return `${activeTabId}` === `3`
      ? "Recently Added End Users"
      : `${activeTabId}` === `4`
      ? "Archived End Users"
      : `${activeTabId}` === `6`
      ? "Blocked End Users"
      : `${activeTabId}` === `5`
      ? `Deleted End Users`
      : "";
  }, [activeTabId]);

  const renderFiltersAndGrid = isFavorite => (
    <div
      className='gridBody contentBody'
      style={{ width: `${activeTabId}` === `2` ? "auto" : "100%" }}>
      <AllEndUserGridFilter
        IsFilterApplied={isFilterApplied}
        PaginationResponse={paginationResponse}
        AppliedFilters={filterModel}
        onApplyFilters={(filters, isFilterApplied) => {
          setFilters(
            isFavorite ? { ...filters, isFavorite: isFavorite } : filters
          );
          if (isFilterApplied) setIsFilterAppling(isFilterApplied);
          else {
            setIsFilterAppling(false);
            setIsFilterApplied(false);
          }
        }}
        ActiveTabId={activeTabId}
        setActiveTabId={setActiveTabId}
        isListView={isListView}
        onToggleListView={isLstView => setIsListView(isLstView)}
      />
      {(`${activeTabId}` === `4` ||
        `${activeTabId}` === `5` ||
        `${activeTabId}` === `6`) && (
        <GridActionNotification
          notificationtext={
            `${activeTabId}` === `4`
              ? "Users who are inactive for a year are automatically archived by OFish"
              : `${activeTabId}` === `6`
              ? "Blocked users are archived for data purposes and are not able to access OFish-powered services."
              : "Users who are deleted will be permanently deleted from OFish after 30 days."
          }
          learnmore={() => {}}
          bgcolor={
            `${activeTabId}` === `4`
              ? "#3699FF"
              : `${activeTabId}` === `6`
              ? "#F2994A"
              : "#EB5757"
          }
        />
      )}
      <div className='grid-header'>
        <h2 className='gridInnerHeading' style={{ fontSize: "22px" }}>
          {isFavorite ? "Favorite End Users" : getTitle() || "All End Users"}
        </h2>
        <span className='gridTopPagination'>
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={[]}
            backIconButtonProps={{ style: { display: "none" } }}
            nextIconButtonProps={{ style: { display: "none" } }}
          />
        </span>
      </div>

      <AllEndUserWrapper
        isListView={isListView}
        activeTabId={activeTabId}
        endUserList={endUserList}
        checkGroupsSelected={checkGroupsSelected}
        isDelete={isDelete}
        order={order}
        setOrderBy={setOrderBy}
        isDeleted={`${activeTabId}` === `5`}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        loadCustomerList={loadCustomerList}
        onSelectAll={onSelectAll}
        paginationResponse={paginationResponse}
        setLoading={setLoading}
      />
    </div>
  );

  const renderFavEndUserGroups = () => (
    <div
      className='gridBody contentBody'
      style={{ width: `${activeTabId}` === `2` ? "auto" : "100%" }}>
      <div className='grid-header'>
        <h2 className='gridInnerHeading'>Favorite End User Groups</h2>
        <span className='gridPagination'>
          <i>
            {pageEUG}-{rowsPerPageEUG} of&nbsp;
            {paginationResponseEUG?.totalRecords}
          </i>
        </span>
      </div>

      {isListView ? (
        <UsersGroupGrid
          customerGroupsData={endUserGroup}
          loadData={() => loadCusGroups()}
          order={orderEUG}
          onOrderBy={setOrderByEUG}
          onEditClick={businessGroupEntity => {
            if (businessGroupEntity)
              setEditableRecord({ ...businessGroupEntity });
          }}
          EditableRecord={editableRecord}
          EditAbleRow={
            editableRecord && (
              <AddNewBusinessGroup
                id='add-new-user-group'
                businessGroupEntity={editableRecord}
                onAddUpdate={success => {
                  if (success) {
                    loadCusGroups();
                    setEditableRecord(null);
                  }
                }}
                onCancel={() => {
                  setEditableRecord(null);
                }}
              />
            )
          }
        />
      ) : (
        <Grid container spacing={2}>
          {endUserGroup.map(item => (
            <Grid item md={3} key={item.id}>
              <ListViewTile
                companyname={item?.groupName}
                contacts={
                  item?.totalContacts > 0
                    ? `${item?.totalContacts} Contact${
                        item?.totalContacts > 1 ? "s" : ""
                      }`
                    : "No Contact"
                }
                buttontxt={item.customerType}
                backgroundcolor={item.color}
                textcolor={item.color}
                rating={item?.isFavorite ? <StarFill /> : <StarOutline />}
                onMarkFavourite={() =>
                  onMarkFavourite({
                    ...item,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
      {isNewGroup && (
        <AddNewBusinessGroup
          isUserGroup
          id='add-new-user-group'
          businessGroupEntity={editableRecord}
          onAddUpdate={success => {
            if (success) {
              loadCusGroups();
              setIsNewGroup(false);
              setEditableRecord(null);
            }
          }}
          onCancel={() => {
            setEditableRecord(null);
            setIsNewGroup(false);
          }}
        />
      )}
      <Pagination
        recordCount={paginationResponseEUG?.totalRecords}
        page={pageEUG - 1}
        setPage={setPageEUG}
        rowsPerPage={rowsPerPageEUG}
        setRowsPerPage={setRowsPerPageEUG}
      />
    </div>
  );

  return (
    <Dashboard>
      <div className='body-content all-end-user'>
        {isLoading ? <Spinner /> : null}
        <div className='contentHeading'>
          <h1 style={{ fontWeight: 800, color: "#2D3339" }}>All End Users</h1>
          <Button
            color='primary'
            onClick={redirectUrl}
            variant='contained'
            style={{
              width: "143px",
              height: "36px",
              fontSize: "14px",
              fontWeight: "500",
              textWrap: "nowrap",
            }}
            startIcon={<Add />}>
            New End User
          </Button>
        </div>
        <div>
          <TopTabNavigator
            defaultTabId={activeTabId}
            value={activeTabId}
            getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
            tabs={[
              {
                id: "1",
                title: "All End Users",
                component: `${activeTabId}` === `1` && renderFiltersAndGrid(),
              },
              {
                id: "7",
                title: "Groups",
                component: <DummyComp />,
              },
              {
                id: "2",
                title: "Favorites",
                component: `${activeTabId}` === `2` && (
                  <div className='fav-end-user-and-group'>
                    {renderFiltersAndGrid(true)}
                    {renderFavEndUserGroups()}
                  </div>
                ),
              },
              {
                id: "3",
                title: "Recently Added",
                component: `${activeTabId}` === `3` && renderFiltersAndGrid(),
              },
              {
                id: "4",
                title: "Archived",
                component: `${activeTabId}` === `4` && renderFiltersAndGrid(),
                // component: <DummyComp isActive={activeTabId === "4"} />,
              },
              {
                id: "6",
                title: "Blocked",
                component: `${activeTabId}` === `6` && renderFiltersAndGrid(),
              },
              {
                id: "5",
                title: "Deleted",
                component: `${activeTabId}` === `5` && renderFiltersAndGrid(),
              },
              {
                id: "8",
                title: "Journeys",
                component: <DummyComp isActive={activeTabId === "8"} />,
              },
            ]}
          />
        </div>
      </div>
    </Dashboard>
  );
};

export { AllEndUsers };
