import { Button } from "@material-ui/core";
import { LightenDarkenColor } from "lighten-darken-color";
import React from "react";

function ListViewTile({
  companyname,
  contacts,
  buttontxt,
  backgroundcolor,
  textcolor,
  rating,
  onClick,
  onMarkFavourite,
}) {
  function hexToRGBA(hex, opacity) {
    hex = hex?.replace("#", "");
    const r = parseInt(hex?.substring(0, 2), 16);
    const g = parseInt(hex?.substring(2, 4), 16);
    const b = parseInt(hex?.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity})`;
  }

  return (
    <div className='listviewOuterWrapper'>
      <i onClick={onMarkFavourite} className='listviewTileStar'>
        {rating}
      </i>
      <div
        className='listViewTileWrapper'
        onClick={e => {
          if (onClick) onClick(e);
        }}>
        <span>
          {`${
            companyname?.length <= 45
              ? companyname
              : companyname?.slice(0, 42) + "..."
          }`}
          {/* <i onClick={onMarkFavourite}>{rating}</i> */}
        </span>
        <span>{contacts}</span>
        <span
          variant='contained'
          style={{
            backgroundColor: hexToRGBA(backgroundcolor || "#54b0e3", 0.3),
            color: backgroundcolor || "#54b0e3",
            padding: "8px 15px",
            borderRadius: "6px",
            fontSize: "12px",
            fontWeight: 700,
          }}>
          {buttontxt}
        </span>
      </div>
    </div>
  );
}

export { ListViewTile };
