import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getAllProductSerials({
  pageNumber,
  noOfRecoreds,
  orderByKey,
  orderByVal,
  filters,
}) {
  return axios
    .post(endpoints.productSerials.getAllSerials, {
      ...filters,
      pagination: {
        pageSize: noOfRecoreds,
        pageNo: pageNumber,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getDeviceBySerialNo(serialNo) {
  return axios
    .post(endpoints.productSerials.getDeviceBySerialNo, {
      id: serialNo,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getDealersDropdown() {
  return axios
    .post(endpoints.productSerials.getDealersDropdown)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function updateDevice(device) {
  return axios
    .post(endpoints.productSerials.updateDeviceInfo, device)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteDevices(ids) {
  return axios
    .post(endpoints.productSerials.deleteSerials, ids)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function updateReceipt(receipt) {
  return axios
    .post(endpoints.productSerials.updateReceipt, receipt)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function updateSerialRegisteredStatus(status) {
  return axios
    .post(endpoints.productSerials.updateSerialRegisteredStatus, status)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getDeviceActivityId(deviceId) {
  return postAPI({
    endpoint: endpoints.productSerials.getDevicesActivity,
    postData: {
      id: deviceId,
    },
  });
}

async function getDeviceNotesById(deviceId) {
  return postAPI({
    endpoint: endpoints.productSerials.getDeviceNotes,
    postData: {
      id: deviceId,
    },
  });
}

async function addNotes(notes) {
  return postAPI({
    endpoint: endpoints.productSerials.addProductDevicesNotes,
    postData: notes,
  });
}

// Generic Post API
const postAPI = async ({ endpoint, postData }) => {
  return axios
    .post(endpoint, postData)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
};

export const productSerialsApi = {
  getAllProductSerials: getAllProductSerials,
  getDeviceBySerialNo: getDeviceBySerialNo,
  getDealersDropdown: getDealersDropdown,
  updateDevice: updateDevice,
  deleteDevices: deleteDevices,
  updateReceipt: updateReceipt,
  updateStatus: updateSerialRegisteredStatus,
  getDeviceActivityId: getDeviceActivityId,
  getDeviceNotesById: getDeviceNotesById,
  addNotes: addNotes,
};
