import React from "react";
import { useSelector } from "react-redux";
import { Button, Tooltip } from "@material-ui/core";
import {
  equalizer,
  dashboard,
  globe,
  userpic,
} from "../../../../resources/images/index";
import moment from "moment";

function CustomerProfile(props) {
  const { businessCompanyInfo } = useSelector(state => state.ContactsReducer);
  var companyId = parseInt(window.location.href.split("?id=")[1]);
  return (
    <div className='customerProfileWrapper'>
      <div className='customerContactInner'>
        <span className='profileHeader'>
          <span className='userPicWrapper'>
            <span className='userPic'>
              {businessCompanyInfo?.comapnayInfo?.image !== "" ? (
                <img
                  src={businessCompanyInfo?.comapnayInfo?.image}
                  alt='user Picture'
                />
              ) : (
                <i className='userInitials'>{`${businessCompanyInfo?.comapnayInfo?.name
                  ?.charAt(0)
                  ?.toUpperCase()}`}</i>
              )}
            </span>
          </span>
        </span>

        {/* <span className='name'>
          {businessCompanyInfo
            ? businessCompanyInfo.comapnayInfo
              ? businessCompanyInfo.comapnayInfo.name
              : ""
            : ""}
        </span>
        <span className='companyName'>Company Name</span>
        <span className='companyId'>
          {companyId && `Company Id:${companyId}`}
        </span>
        <Tooltip
          title={
            businessCompanyInfo
              ? businessCompanyInfo.comapnayInfo
                ? businessCompanyInfo.comapnayInfo.bio
                : ""
              : ""
          }
          placement="top"
        >
          <span className="companyDesc">
            {businessCompanyInfo
              ? businessCompanyInfo.comapnayInfo
                ? businessCompanyInfo.comapnayInfo.bio
                : ""
              : ""}
          </span>
        </Tooltip> */}
        <span className='name'>
          {" "}
          {businessCompanyInfo
            ? businessCompanyInfo?.comapnayInfo
              ? businessCompanyInfo?.comapnayInfo?.name
                  ?.charAt(0)
                  ?.toUpperCase() +
                businessCompanyInfo?.comapnayInfo?.name?.slice(1)
              : ""
            : ""}
        </span>
        <span className='companyId'>
          Contact Id:{" "}
          <i style={{ color: "#3699ff" }}>
            {companyId && `Company Id:${companyId}`}
          </i>
        </span>
        <span className='companyName'>
          {businessCompanyInfo.comapnayInfo
            ? businessCompanyInfo?.comapnayInfo?.devicesRegistered
            : 0}
          {" Devices"}
        </span>

        <span className='profileActiveBtnWrapper'>
          {businessCompanyInfo?.comapnayInfo?.eStatus === 1 ? (
            <span className='productRegistered'>Active</span>
          ) : businessCompanyInfo?.comapnayInfo?.eStatus === 0 ? (
            <span className='productPending'>Inactive</span>
          ) : businessCompanyInfo?.comapnayInfo?.eStatus === -1 ? (
            <span className='productDelete'>Deleted</span>
          ) : businessCompanyInfo?.comapnayInfo?.eStatus === 2 ? (
            <span className='productBlock'>Blocked</span>
          ) : businessCompanyInfo?.comapnayInfo?.eStatus === 4 ? (
            <span className='productDelete'>Draft</span>
          ) : businessCompanyInfo?.comapnayInfo?.eStatus === 3 ? (
            <span className='productDelete'>Archive</span>
          ) : (
            <span className='productDelete'>DeActivate</span>
          )}
          {/* <Button color='primary' varient='contained'>
            Active
          </Button> */}
        </span>
        <ul>
          <li>
            <span className='icon'>
              <img src={equalizer} alt='equalizer' />
            </span>
            <span className='profileHeading'>
              Business Group
              <i className='profileSubHeading'>
                {businessCompanyInfo
                  ? businessCompanyInfo.comapnayInfo
                    ? businessCompanyInfo.comapnayInfo.groupName
                    : ""
                  : ""}
              </i>
            </span>
          </li>

          <li>
            <span className='icon'>
              <img src={dashboard} alt='dashboard' />
            </span>
            <span className='profileHeading'>
              Location
              <i className='profileSubHeading'>
                {businessCompanyInfo
                  ? businessCompanyInfo.comapnayInfo
                    ? businessCompanyInfo.comapnayInfo.address
                    : ""
                  : ""}
              </i>
            </span>
          </li>

          <li>
            <span className='icon'>
              <img src={globe} alt='globe' />
            </span>
            <span className='profileHeading'>
              Last Login
              <i className='profileSubHeading'>
                {businessCompanyInfo
                  ? businessCompanyInfo.comapnayInfo !== ""
                    ? moment(
                        businessCompanyInfo?.comapnayInfo?.lastLogin
                      ).fromNow()
                    : ""
                  : ""}
              </i>
            </span>
          </li>
        </ul>

        <span>
          {" "}
          <Button color='primary' variant='contained'>
            Message Customer
          </Button>
        </span>
      </div>
    </div>
  );
}

export default CustomerProfile;
