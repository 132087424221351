import { ON_SELECT_CONTACT_AREA } from "./contactTypesActions";
import { contactTypeTypes } from "./contactTypeTypes";

const initialState = {
  _ID: "",
  dataModule: [],
  dataConnection: [],
  dataField: [],
  isTabDisActive: false,
  isTabActive: false,
  count: 0,
  favoritesDataField: [],
  favoritesConnections: [],
  favoritesModules: [],
  refreshData: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ON_SELECT_CONTACT_AREA:
      console.log("action.payload", action.payload);
      return {
        ...state,
        _ID: action.payload,
      };
    case contactTypeTypes.ON_CHANGE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };

    default:
      return state;
  }
};
