import { makeStyles } from "@material-ui/core";
import React from "react";
import Autocomplete from "react-google-autocomplete";

const useStyles = makeStyles(() => ({
  filterAutoComplete: {
    backgroundColor: "#f3f6f9",
    marginBottom: "8px",
    padding: "10px 10px !important",
  },
}));

const AddressAutoComplete = ({
  address,
  onChange,
  setPlace,
  types = ["address"],
  autoComId,
  labelRequired = true,
  placeholder = "Enter a location",
}) => {
  const classes = useStyles();

  const val = address === true ? "" : address;

  return (
    <div className={`inputWrapper autoCompleteAddressWrapper`}>
      {labelRequired && <label className='envAutoLocLable'>Address</label>}
      <Autocomplete
        className={`autocomplete${
          !labelRequired ? ` ${classes.filterAutoComplete}` : ""
        }`}
        placeholder={placeholder}
        id={autoComId}
        apiKey={"AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"}
        options={{
          types,
        }}
        onPlaceSelected={place => {
          setPlace(place);
        }}
        onChange={onChange}
        // defaultValue={val}
        value={val}
      />
    </div>
  );
};

export { AddressAutoComplete };
