import React from "react";
import { Grid } from "@material-ui/core";
import EndUserOrdersGrid from "./EndUserOrdersGrid";
import EndUserOrderSummary from "./EndUserOrderSummary";
import EndUserWallet from "./EndUserWallet";
import EndUserCreditLine from "./EndUserCreditLine";
import EndUserStorePoints from "./EndUserStorePoints";

function EndUserOrders(props) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <EndUserOrderSummary />
        </Grid>

        <Grid item xs={5}>
          <EndUserWallet />
        </Grid>

        <Grid item xs={4}>
          <div className='creditStoreWrapper'>
            <EndUserCreditLine />
            <EndUserStorePoints />
          </div>
        </Grid>
      </Grid>

      <EndUserOrdersGrid />
    </>
  );
}

export default EndUserOrders;
