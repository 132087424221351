import React from "react";

import { Dialog, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { ReactComponent as Cross } from "../../../resources/images/Close.svg";

const Modal = ({
  title,
  primaryHeading,
  secondaryHeading,
  open,
  setOpen,
  children,
}) => {
  const handleClose = () => setOpen(false);
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='contactDataDialogue dialogueWrapper'>
      <div className='addContactDataHeading'>
        <Typography id='modal-title' className="title">
          {title}
        </Typography>
        <Cross
          style={{
            cursor: "pointer",
            opacity:0.3
          }}
          onClick={handleClose}
        />
      </div>
      <div className='contentDiv flexCol'>
        <h1 className='firstHeadingStyle'>{primaryHeading}</h1>
        <p className='secondHeadingStyle'>{secondaryHeading}</p>
        {children && <> {children}</>}
      </div>
    </Dialog>
  );
};

export { Modal };
