import React, { useState, useEffect } from "react";
import Dashbard from "../../ofishadmin/views/Dashboard/Dashbard";
import { Button } from "@material-ui/core";
import Loader from "./Loader";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import CustomerConversation from "./Conversation/CustomerConversation";
import { TopTabNavigator } from "../../components/common/Tabs/TopTabNavigator";
import { useDispatch, useSelector } from "react-redux";
import { conversationAction } from "../../store/conversations/conversationAction";

function Chat({ clientId, productId, userName, generic, options }) {
  const [isLoading, setLoading] = useState(false);
  const [selectedtTab, setTab] = useState("1");
  const [campaignsDdl, setCampaignsDdl] = useState([]);
  const dispatch = useDispatch();
  const { hubId, selectedTab } = useSelector(
    state => state.conversationReducer
  );

  useEffect(() => {
    dispatch(
      conversationAction.onGetCampaignsDdl({
        allowedOnCampaignsId: "",
        productId: 2,
        hubUserId: hubId,
      })
    ).then(res => {
      if (res?.responseMsg === "Success") {
        setCampaignsDdl(res?.responseData.filter(x => x.value.trim() !== ""));
        const arr = [];
        res?.responseData.map((x, i) => {
          if (x.generic > 0) {
            arr.push({ name: x.value, tabId: i + 1 });
          }
        });
        dispatch(conversationAction.onChange("tabsList", arr));
      }
    });
  }, [hubId]);

  return (
    <div>
      <Dashbard>
        {isLoading ? <Loader /> : null}

        <div className='body-content'>
          <div className='contentHeading'>
            <h1>All Conversations</h1>
            <span className='conversationSearch'>
              <SearchIcon />
              <input
                type='text'
                placeholder='Search all conversations'
                // onKeyPress={e => enterPressed(e)}
                // onChange={onChange}
                // value={value}
              />
            </span>
            <Button
              color='primary'
              onClick={() => {
                dispatch(
                  conversationAction.onChange("isNewConversation", true)
                );
              }}
              variant='contained'
              startIcon={<AddIcon />}>
              New Conversation
            </Button>
          </div>
          <div className='conversationTabsWrapper'>
            <TopTabNavigator
              getActiveTabId={tabId => {
                const tb =
                  tabId === "All"
                    ? 1
                    : tabId === "YourBrand"
                    ? 2
                    : tabId === "Favorite"
                    ? 3
                    : parseInt(tabId) + 3;
                dispatch(conversationAction.onChange("selectedTab", `${tb}`));
              }}
              tabs={campaignsDdl.map(c => {
                return {
                  id: c.id,
                  title: `${c.value}`,
                  msgCount: `(${c.generic})`,
                  component: (
                    <CustomerConversation
                      clientId={clientId}
                      productId={productId}
                      userName={userName}
                      generic={generic}
                      options={options}
                      campId={c.id}
                      // selectedtTab={selectedTab}
                      onfavButtonPress={e => {
                        const changeCampaignsDdl = campaignsDdl.map(x => {
                          if (["Favorite", "Favourite"].includes(x.value)) {
                            const fav = {
                              ...x,
                              generic: e.isStar ? x.generic + 1 : x.generic - 1,
                            };
                            return fav;
                          } else {
                            return x;
                          }
                        });
                        setCampaignsDdl(changeCampaignsDdl);
                      }}
                    />
                  ),
                };
              })}
              className={"conversation"}
            />
          </div>
        </div>
      </Dashbard>
    </div>
  );
}

export default Chat;
