import { makeStyles } from '@material-ui/core';
import React from 'react';
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
  } from "@material-ui/core";
import { DeleteIcon, EditIcon, SortIcon } from '../../LeftNav/SvgIcons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
    gridTopNav: {
      padding: "10px 0 10px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "23px",
    },
    selectedItems: {
      textAlign: "right",
      color: "#8D99A5",
      fontSize: "14px",
      fontWeight: "400",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    deleteItems: {
      display: "flex",
      alignItems: "center",
    },
    gridHeaderInner: {
      color: "#828282",
      fontSize: "12px",
      fontWeight: "700",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    gridHeaderInnerCenter: {
      color: "#828282",
      fontSize: "12px",
      fontWeight: "700",
      paddingTop: "4px",
      paddingBottom: "4px",
      textAlign: "center",
    },
    gridBodyInnerCenter: {
      textAlign: "center",
    },
    gridHeader: {
      background: "#E5F2FF",
      borderRadius: "6px",
      height: "43px",
    },
    gridHeaderRow: {
      borderRadius: "6px",
    },
    checkBoxFont: {
      fontSize: "22px",
    },
    gridBodyInner: {
      color: "#464E5F",
      fontSize: "14px",
      fontWeight: "400",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    gridStatusColumn: {
      textAlign: "center",
    },
    gridProductColumn: {
      // fontWeight: "bold",
    },
    gridVersionColumn: {
    //   fontWeight: "bold",
    //   color: "#3699FF",
    },
    gridStyledColumn: {
      fontSize: "12px",
      color: "#2D3339",
    },
    pendingButtons: {
      color: "#FFAD4E",
      backgroundColor: "rgba(255, 173, 78, 0.25)",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "rgba(255, 173, 78, 0.25)",
        boxShadow: "none",
      },
    },
    launchedButtons: {
      color: "#00ABA3",
      backgroundColor: "rgba(0, 171, 163, 0.25)",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "rgba(0, 171, 163, 0.25)",
        boxShadow: "none",
      },
    },
    newProductLaunch: {
      color: "#8254E3",
      background: "rgba(130, 84, 227, 0.25)",
      boxShadow: "none",
      "&:hover": {
        background: "rgba(130, 84, 227, 0.25)",
        boxShadow: "none",
      },
    },
    editButton:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    },
    iconButton:{
      '&:hover': {
        backgroundColor: 'unset',
      },
    //   '& .MuiIconButton-root ':hover {
    //     /* background-color: rgba(0, 0, 0, 0.04); */
    // }
    },
  }));

export const SoftwarePreviewGridData = ({
  allReleaseData,
  order,
  onOrderBy,
  editSoftwareRelease,
  previewMode,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper>
    <Table>
      <TableHead className={classes.gridHeader}>
        <TableRow className={classes.gridHeaderRow}>
          <TableCell className={classes.gridHeaderInner}>
          Date
            <TableSortLabel
              active={order.orderByKey === "Title"}
              direction={order.orderVal === 1 ? "asc" : "desc"}
              onClick={() =>
                onOrderBy({
                  orderByKey: "Title",
                  orderVal: order.orderVal === 0 ? 1 : 0,
                })
              }
              IconComponent={SortIcon}
                className="sortIcon"
            />
          </TableCell>
          <TableCell className={classes.gridHeaderInner}>
          Subject
            <TableSortLabel
              active={order.orderByKey === "VersionID"}
              direction={order.orderVal === 1 ? "asc" : "desc"}
              onClick={() =>
                onOrderBy({
                  orderByKey: "VersionID",
                  orderVal: order.orderVal === 0 ? 1 : 0,
                })
              }
              IconComponent={SortIcon}
                className="sortIcon"
            />
          </TableCell>
          <TableCell className={classes.gridHeaderInnerCenter}>
          Description
            <TableSortLabel
              active={order.orderByKey === "Status"}
              direction={order.orderVal === 1 ? "asc" : "desc"}
              onClick={() =>
                onOrderBy({
                  orderByKey: "Status",
                  orderVal: order.orderVal === 0 ? 1 : 0,
                })
              }
              IconComponent={SortIcon}
                className="sortIcon"
            />
          </TableCell>
          <TableCell className={classes.gridHeaderInnerCenter}>
          Quick Actions
            <TableSortLabel
              active={order.orderByKey === "Products"}
              direction={order.orderVal === 1 ? "asc" : "desc"}
              onClick={() =>
                onOrderBy({
                  orderByKey: "Products",
                  orderVal: order.orderVal === 0 ? 1 : 0,
                })
              }
              IconComponent={SortIcon}
                className="sortIcon"
            />
          </TableCell>
          
        </TableRow>
      </TableHead>
      <TableBody>
        {allReleaseData.length > 0 &&
          allReleaseData.map((item, index) => {
            return (
              <TableRow key={index} className="gridBodyRow">
                <TableCell className={classes.gridStyledColumn}>
                  {item.updateName}
                </TableCell>
                <TableCell
                  className={[
                    classes.gridBodyInner,
                    classes.gridVersionColumn,
                  ]}
                >
                  {item.versionID}
                </TableCell>
                <TableCell
                  className={[
                    classes.gridStyledColumn,
                    classes.gridBodyInnerCenter,
                  ]}
                >
                  {item.followed}
                </TableCell>
                <TableCell className={classes.editButton}>
                  
                  <IconButton
                    className={classes.iconButton}
                  >
                    <EditIcon />
                  </IconButton>
                  <DeleteIcon
                    style={{ color: "#EB5757", cursor: "pointer" }}

                  />
                </TableCell>
              </TableRow>
            );
          })}
        {allReleaseData.length <= 0 && (
          <TableRow key="0" className="gridBodyRow">
            <TableCell
              colSpan="6"
              className={classes.gridBodyInner}
              style={{ textAlign: "center" }}
            >
              <span className="emptyGridText">
                Uh Oh! No software releases have been created.. You can always
                <Button onClick={() => history.push('/new-software-release')}>
                  Add a New Software Version
                </Button>
              </span>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Paper>
  )
}
