import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashboard from "../../Dashbard";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import Customizations from "./ConversationCustomizations";
import SettingsGrid from "./SettingsGrid";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import ConversationPopup from "../ConversationSetup/ConversationPopup";
import Loader from "../../../../../components/common/Loader";
import DummyComp from "./DummyComp";
import { useHistory } from "react-router-dom";
import ConversationTypesOverview from "./ConversationTypesOverview";

function ConversationSettings(props) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { editChannelType } = useSelector(state => state.conversationReducer);
  const dispatch = useDispatch();
  const { isPopupOpen } = useSelector(state => state.conversationReducer);
  const [activeTabId] = useState("3");

  useEffect(() => {
    setLoading(true);
    dispatch(
      conversationAction.onGetConfigurationStatus({
        productId: 2,
      })
    ).then(data => {
      setLoading(false);
      if (data?.responseMsg === "Success") {
        dispatch(
          conversationAction.onChange(
            "isPopupOpen",
            data?.responseData?.isActive === 0 ? true : false
          )
        );
      }
    });
  }, []);

  return (
    <div className='conversationSetting'>
      <Dashboard>
        {isLoading ? <Loader /> : null}
        {isPopupOpen ? (
          <ConversationPopup
            isPopupOpen={isPopupOpen}
            closePopup={() => {
              dispatch(conversationAction.onChange("isPopupOpen", false));
            }}
          />
        ) : (
          !editChannelType && (
            <div className='body-content conversationSettingsWrapper'>
              <div className='contentHeading'>
                <h1 className='converstionSettingHeading'>
                  Conversation Settings
                </h1>
                <Button
                  color='primary'
                  variant='contained'
                  className='newConversationTypeBtn'
                  startIcon={<AddIcon />}
                  onClick={() => {
                    history.push("/conversation-type");
                    dispatch(
                      conversationAction.onChange("basicConversationData", {
                        productId: 0,
                        campaignId: 0,
                        nameInternal: "",
                        namePublic: "",
                        color: "",
                        shortDescription: "",
                        channels: [],
                        isPublic: false,
                        isGroupChat: false,
                        isDeplayedInAdminInbox: false,
                        type: "",
                        status: 1,
                      })
                    );
                    dispatch(
                      conversationAction.onChange("conversationPermissionData", [])
                    );
                    dispatch(
                      conversationAction.onChange("selectedStartConversationPermission", [])
                    );
                    dispatch(
                      conversationAction.onChange("selectedReciveNewConversationPermission", [])
                    );
                    dispatch(
                      conversationAction.onChange("selectedConversationChannels", [])
                    );
                    dispatch(
                      conversationAction.onChange("selectedConversationPermission", [])
                    );
                  }}>
                  New Conversation Type
                </Button>
              </div>

              <div className='conversationTabs'>
                <TopTabNavigator
                  tabs={[
                    {
                      id: 3,
                      title: `Conversation Types`,
                      component: (
                        <SettingsGrid
                          isActive={activeTabId === "3"}
                          activeTab={activeTabId}
                        />
                      ),
                      // component: <ConversationTypesOverview isActive={activeTabId === "3"} activeTab={activeTabId} />,
                    },
                    // {
                    //   id: 4,
                    //   title: `Tags`,
                    //   component: <DummyComp isActive={activeTabId === "4"} />,
                    // },
                    // {
                    //   id: 5,
                    //   title: `Widget`,
                    //   component: <DummyComp isActive={activeTabId === "5"} />,
                    // },
                    // {
                    //   id: 6,
                    //   title: `Journeys`,
                    //   component: <DummyComp isActive={activeTabId === "6"} />,
                    // },
                  ]}
                  value={activeTabId}
                  className={"con-settting"}
                />
              </div>
            </div>
          )
        )}
        {/* {editChannelType && (
        <Customizations
          onChatSetting={() => {
            dispatch(conversationAction.onChange("editChannelType", false));
          }}
          campId={0}
        />
      )} */}
      </Dashboard>
    </div>
  );
}

export default ConversationSettings;
