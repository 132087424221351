import React from "react";
import { TabButtons } from "./TabButtons";

const ProductTabButtons = () => (
  <TabButtons
    tabs={[
      { title: "All Products", to: "/product-inventory" },
      { title: "Brands", to: "/brands" },
      { title: "Product Categories", to: "/product-categories" },
      { title: "Product Serials", to: "/product-serials" },
      { title: "Product Pricing", to: "/product-pricing" },
    ]}
  />
);

export { ProductTabButtons };
