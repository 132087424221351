import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getCompanyInfo() {
  return axios
    .get(`${endpoints.pubCompany.getCompanyInfo}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubComContact() {
  return axios
    .get(`${endpoints.pubCompany.getPubComCont}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubComLeaders() {
  return axios
    .get(`${endpoints.pubCompany.getPubComLeader}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

export const pubCompanyApi = {
  getCompanyInfo: getCompanyInfo,
  getPubComContact: getPubComContact,
  getPubComLeaders: getPubComLeaders
};
