import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  EditIcon,
  FilterIcon,
  GridListIcon,
  TableGrid,
  Delete,
} from "../../../LeftNav/SvgIcons";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CompanyLoctionForm from "./CompanyLoctionForm";
import { searchIcon } from "../../../../../resources/images";
import InputField from "../../../../../components/common/InputField";
import { Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { RedCross, TickMark } from "../../../LeftNav/SvgIcons";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import Loader from "../../../../../components/common/Loader";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function CompanyLocationGrid({
  setIsNewAddress,
  isNewAddress,
  setIsEditAdr,
  isEditAdr,
  loadLocation,
  listData,
  search,
  setSearch,
  deleteLocation,
  isLoading,
  setLoading,
  listUserData,
  productCategories,
}) {
  const [editItem, setEditItem] = useState(false);

  const classes = useStyles();

  return (
    <>
      {" "}
      {isLoading ? <Loader /> : null}
      <Grid container spacing={2}>
        <Grid item md={11} className='filterMainSearch endUserSearchFilterMain'>
          <img src={searchIcon} alt='' className='searchIcon' />
          <TextField
            placeholder='Search'
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <span
            className='endUserSearchFilter'
            // onClick={() => setSearch(search)}
          >
            <FilterIcon />
          </span>
        </Grid>

        <Grid item md={1} className='blockEndUserBtn gridFiltersButtons'>
          <TableGrid />
          <GridListIcon />
        </Grid>
      </Grid>
      <div className='gap' />
      <div className='addressGrid'>
        <span className='gridHeaader'>
          <ul>
            <li>Location Nickname</li>
            <li>Show in Locator</li>
            <li>Address</li>
            <li />
          </ul>
        </span>
        <span className='gridBody'>
          {listData?.map((item, index) => {
            return isEditAdr && editItem?.id === item.id ? (
              <CompanyLoctionForm
                setIsNewAddress={() => {
                  setIsEditAdr(false);
                  setEditItem(null);
                }}
                editData={item}
                loadLocation={() => loadLocation()}
                setLoading={e => setLoading(e)}
                listUserData={listUserData}
                productCategories={productCategories}
              />
            ) : (
              <ul style={{ width: "100%" }}>
                <li className='highlishtedItem'>{item?.nickName}</li>
                <li className='highlishtedItem'>
                  {item?.showInLocator ? "Yes" : "No"}
                </li>
                <li>{item?.address}</li>
                <li
                  className='gridActions productsAction '
                  style={{
                    textAlign: "right",
                    display: "block",
                    justifyContent: "flex-end",
                  }}>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsEditAdr(true);
                      setEditItem(item);
                      setIsNewAddress();
                    }}>
                    <EditIcon />
                  </span>
                  <span
                      onClick={() => {
                        deleteLocation(item?.id);
                      }}
                    style={{
                      cursor: "pointer",
                    }}>
                    <Delete
                    />
                  </span>
                </li>
              </ul>
            );
          })}
          {isNewAddress && (
            <CompanyLoctionForm
              setIsNewAddress={() => {
                setIsNewAddress();
              }}
              loadLocation={() => loadLocation()}
              setLoading={e => setLoading(e)}
              listUserData={listUserData}
              productCategories={productCategories}
            />
          )}
        </span>
      </div>
      {/* {listData?.length === 0 && (
        <span className='emptyGridData'>
          You haven’t created any{" "}
          <i
            onClick={() => {
              setIsNewAddress(true);
              setIsEditAdr(false);
            }}>
            Ofish Locations
          </i>
          .
        </span>
      )} */}
    </>
  );
}

export default CompanyLocationGrid;
