import { productSerialsApi } from "./productSerialsApi";

const onGetAllItems =
  ({ pageNumber, noOfRecoreds, orderByKey, orderByVal, filters }) =>
  async dispatch => {
    return await productSerialsApi.getAllProductSerials({
      pageNumber,
      noOfRecoreds,
      orderByKey,
      orderByVal,
      filters
    });
  };

const onGetDeviceBySerialNo = serialNo => async dispatch => {
  return await productSerialsApi.getDeviceBySerialNo(serialNo);
};

const onGetDealersDropdown = () => async dispatch => {
  return await productSerialsApi.getDealersDropdown();
};

const onUpdateDevice = data => async dispatch => {
  return await productSerialsApi.updateDevice(data);
};

const onDeleteDevices = ids => async dispatch => {
  return await productSerialsApi.deleteDevices(ids);
};

const onUpdateReceipt = data => async dispatch => {
  return await productSerialsApi.updateReceipt(data);
};

const onRegisterSerial = data => async dispatch => {
  return await productSerialsApi.updateStatus(data);
};

const onUpdateBlockSerial = data => async dispatch => {
  return await productSerialsApi.updateStatus(data);
};

const onGetDeviceActivityById = deviceId => async dispatch => {
  return await productSerialsApi.getDeviceActivityId(deviceId);
};

const onGetDeviceNotesById = deviceId => async dispatch => {
  return await productSerialsApi.getDeviceNotesById(deviceId);
};

const onAddNotes = data => async dispatch => {
  return await productSerialsApi.addNotes(data);
};

export const productSerialActions = {
  onGetAllProductSerials: onGetAllItems,
  onGetDeviceBySerialNo: onGetDeviceBySerialNo,
  onGetDealersDropdown: onGetDealersDropdown,
  onAddUpdateItem: onUpdateDevice,
  onDeleteItems: onDeleteDevices,
  onUpdateReceipt: onUpdateReceipt,
  onRegisterSerial: onRegisterSerial,
  onUpdateBlockSerial: onUpdateBlockSerial,
  onGetDeviceActivityById: onGetDeviceActivityById,
  onGetDeviceNotesById: onGetDeviceNotesById,
  onAddNotes: onAddNotes
};
