import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Radio color='default' {...props} />);

const RadioButtons = ({ handleChange, selectedValue, disabled, value = 0 }) => {
  // const [selectedValue, setSelectedValue] = React.useState("a");

  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  // };

  return (
    <div>
      <Radio
        checked={selectedValue}
        onChange={handleChange}
        value={value}
        color='primary'
        name='radio-button-demo'
        inputProps={{ "aria-label": "E" }}
        size='medium'
        disabled={disabled}
      />
    </div>
  );
};

export default RadioButtons;
