import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
   noUnderline: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    fontSize:"10px",
  },
  convContainer:{
    width:"77px"
  },
  papers:{
    width:"120px"
  }
}));

export const ConversationModal = ({
  openConversationModal,
  setOpenCoversationModal,
  handleChangeAssigee,
  selectedAssignee,
  convAssigne,
  boxName,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpenCoversationModal(null);
  };

  const open = Boolean(openConversationModal);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={classes.paper}>
      <Popover
        id={id}
        open={open}
        anchorEl={openConversationModal}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          open
          disableClearable
          classes={{ paper: classes.papers }}
          value={selectedAssignee}
          onChange={(e, v) => handleChangeAssigee(v)}
          options={
            boxName === "inbox"
              ? convAssigne.map((option) => option.value)
              : convAssigne.map(
                  (option) => option.firstName + " " + option.lastName
                )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.noUnderline}
              style={{ width: "77px" }}
            />
          )}
        />
      </Popover>
    </div>
  );
};
