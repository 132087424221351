import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Dashbard from "../../Dashbard";
import Loader from "../../../../../components/common/Loader";
import GridSearchFilter, {
  AutoComplete,
  FilterAutoComplete,
  SelectedDateChip,
} from "../../../../../components/common/FilterLayout/GridFilter";
import LeadGenerationGrid from "./LeadGenerationGrid";
import { useDebounce } from "../../../../../utils/use-debounce";
import { InputField, Pagination } from "../../../../../components/common";
import { useDispatch } from "react-redux";
import { contactsTypesActions } from "../../../../../store/contactTypes/contactTypesActions";
import { DayTableModel } from "@fullcalendar/core/internal";
import { CommentSharp, ExpandMore, MoreHoriz } from "@material-ui/icons";
import { ChipItem } from "../../Conversations/ConverstionSettings/GridFilter";
import { values } from "lodash";
import { FilterCreationDate } from "../../EndUser";
import moment from "moment";

const LeadGeneration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let filtersIndex = 0;
  const filtersLimit = 4;
  const indexReference = useRef(filtersIndex);
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    platform: "",
    DateFrom: null,
    DateTo: null,
  });
  const { debouncedValue } = useDebounce(filters.name, 500);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState({
    orderByKey: "FirstName",
    orderVal: 1,
  });
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [showFilters, setShowFilter] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showFilterCounter, setShowFilterCounter] = useState(0);
  const [LeadsData, setLeadsData] = useState([]);

  const filtersCount =
    (filters.firstName ? 1 : 0) +
    (filters.lastName ? 1 : 0) +
    (filters.email ? 1 : 0) +
    (filters.platform ? 1 : 0) +
    (filters.DateFrom ? 1 : 0) +
    (filters.DateTo ? 1 : 0);

  const onOrderBy = newOrder => {
    setOrder(newOrder);
  };

  const loadLeadsGeneration = useCallback(() => {
    setLoading(true);
    dispatch(
      contactsTypesActions.onGetLeadsPaginatedData({
        pagination: {
          pageSize: rowsPerPage,
          pageNo: page,
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
        },
        firstName: filters.firstName,
        lastName: filters.lastName,
        email: filters.email,
        contactNo: "",
        platform: filters.platform,
        DateFrom: filters.DateFrom,
        DateTo: filters.DateTo,
        search: debouncedValue,
      })
    ).then(data => {
      if (data?.data?.success) {
        setLeadsData(data?.data?.data?.data);
        setPaginationResponse(data?.data?.data?.pagination);
      } else {
        setLeadsData([]);
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setLoading(false);
    });
  }, [
    dispatch,
    debouncedValue,
    page,
    rowsPerPage,
    order.orderByKey,
    order.orderVal,
  ]);

  useEffect(() => {
    loadLeadsGeneration();
  }, [debouncedValue, page, rowsPerPage, order]);

  const showFilter = () => {
    window.scroll(0, 0);
    // document.body.classList.add("bodyScroll");
    setIsFilter(true);
  };

  const platformOptions = [
    {
      id: 0,
      name: "Facebook",
    },
    {
      id: 1,
      name: "Shopify",
    },
  ];

  const hideFilter = () => {
    setIsFilter(false);
  };

  const applyFilters = () => {
    setLoading(true);
    setShowFilterCounter(filtersCount);
    hideFilter();
    dispatch(
      contactsTypesActions.onGetLeadsPaginatedData({
        pagination: {
          pageSize: rowsPerPage,
          pageNo: page,
          orderByKey: order.key,
          orderByVal: order.val,
        },
        firstName: filters.firstName,
        lastName: filters.lastName,
        email: filters.email,
        contactNo: "",
        platform: filters.platform,
        DateFrom: filters.DateFrom
          ? moment(filters.DateFrom).format("MM/DD/yyyy")
          : null,
        DateTo: filters.DateTo
          ? moment(filters.DateTo).format("MM/DD/yyyy")
          : null,
        search: debouncedValue,
      })
    ).then(data => {
      if (data?.data?.success) {
        setLeadsData(data?.data?.data?.data);
        setPaginationResponse(data?.data?.data?.pagination);
      } else {
        setLeadsData([]);
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setLoading(false);
    });
  };

  const onClearButtonPress = () => {
    setLoading(true);
    setPage(1);
    setRowsPerPage(10);
    setShowFilterCounter(0);
    setFilters({
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      platform: "",
      DateFrom: null,
      DateTo: null,
    });
    dispatch(
      contactsTypesActions.onGetLeadsPaginatedData({
        pagination: {
          pageSize: 10,
          pageNo: 1,
          orderByKey: "Id",
          orderByVal: 0,
        },
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        platform: "",
        search: "",
        DateFrom: null,
        DateTo: null,
      })
    ).then(data => {
      if (data?.data?.success) {
        setLeadsData(data?.data?.data?.data);
        setPaginationResponse(data?.data?.data?.pagination);
      } else {
        setLeadsData([]);
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setLoading(false);
    });
  };

  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading launchBtn'>
          <h1>All Leads</h1>
          {/* <Button
                        color='primary'
                        variant='contained'
                        startIcon={<AddIcon />}
                        onClick={() => history.push("/new-lead-generation")}
                        >
                        New Lead Generation
                     </Button> */}
        </div>
        <div className='gridBody contentBody '>
          <GridSearchFilter
            isFilterAdded={true}
            setFilters={setFilters}
            filters={filters}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            title={"All Leads Filter"}
            isFilter={isFilter}
            showFilter={showFilter}
            hideFilter={hideFilter}
            applyFilters={applyFilters}
            showFilterCounter={showFilterCounter}>
            <Accordion
              defaultExpanded
              id='end-user-filter-expandable'
              TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  <span>Active Filter(s)</span>
                  <span>
                    <Button
                      className='filterClearBtn filterClearBtnStyle'
                      variant='outlined'
                      onClick={() => {
                        hideFilter();
                        onClearButtonPress();
                      }}>
                      Clear
                    </Button>
                  </span>
                </Typography>
              </AccordionSummary>
              <div style={{ marginBottom: "10px" }}>
                {filtersCount <= 0 && (
                  <div className={`filtersChips`}>No Active Filter(s)</div>
                )}
              </div>

              <SelectedDateChip
                startDate={filters.DateFrom}
                endDate={filters.DateTo}
                setStartDate={date => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    DateFrom: date,
                  }));
                }}
                setEndDate={date => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    DateTo: date,
                  }));
                }}
                chipLabel={"Creation Date"}
              />
              {filters.firstName?.length > 0 &&
                (filtersIndex <= filtersLimit ||
                  (filtersIndex > filtersLimit && showFilters)) && (
                  <ChipItem
                    chipLabel='First Name'
                    chipValue={filters.firstName}
                    onDelete={() => {
                      setFilters(prevFilters => ({
                        ...prevFilters,
                        firstName: "",
                      }));
                    }}
                  />
                )}
              {filters.lastName?.length > 0 &&
                (filtersIndex <= filtersLimit ||
                  (filtersIndex > filtersLimit && showFilters)) && (
                  <ChipItem
                    chipLabel='Last Name'
                    chipValue={filters.lastName}
                    onDelete={() => {
                      setFilters(prevFilters => ({
                        ...prevFilters,
                        lastName: "",
                      }));
                    }}
                  />
                )}
              {filters.email?.length > 0 &&
                (filtersIndex <= filtersLimit ||
                  (filtersIndex > filtersLimit && showFilters)) && (
                  <ChipItem
                    chipLabel='Email'
                    chipValue={filters.email}
                    onDelete={() => {
                      setFilters(prevFilters => ({
                        ...prevFilters,
                        email: "",
                      }));
                    }}
                  />
                )}

              {filters.platform?.length > 0 &&
                (filtersIndex <= filtersLimit ||
                  (filtersIndex > filtersLimit && showFilters)) && (
                  <ChipItem
                    chipLabel='Email'
                    chipValue={filters.platform}
                    onDelete={() => {
                      setFilters(prevFilters => ({
                        ...prevFilters,
                        platform: "",
                      }));
                    }}
                  />
                )}

              <div
                style={{
                  textAlign: "right",
                }}>
                {filtersCount > filtersLimit && (
                  <IconButton
                    color={"primary"}
                    onClick={() => setShowFilter(!showFilters)}>
                    <MoreHoriz />
                  </IconButton>
                )}
              </div>
            </Accordion>
            <div className='userDetailsSearchField inputWrapper filterSelect'>
              <div style={{ marginBottom: "10px" }}>
                <h4>Product Creation Date</h4>
                <FilterCreationDate
                  StartDate={filters.DateFrom}
                  setStartDate={date => {
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      DateFrom: date,
                    }));
                  }}
                  EndDate={filters.DateTo}
                  setEndDate={date => {
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      DateTo: date,
                    }));
                  }}
                />
              </div>
              <InputField
                id='FirstName'
                name='FirstName'
                placeholder={"First Name"}
                value={filters.firstName}
                onChange={({ target }) =>
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    firstName: target.value,
                  }))
                }
              />
              <InputField
                id='LastName'
                name='LastName'
                placeholder={"Last Name"}
                value={filters.lastName}
                onChange={({ target }) =>
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    lastName: target.value,
                  }))
                }
              />
              <InputField
                id='Email'
                name='Email'
                placeholder={"Email"}
                value={filters.email}
                onChange={({ target }) =>
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    email: target.value,
                  }))
                }
              />
              <AutoComplete
                value={
                  platformOptions.filter(x =>
                    filters?.platform?.includes(x.name)
                  )?.id ?? null
                }
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    platform: value.name,
                  }));
                }}
                id='leads-filters'
                options={platformOptions}
                getOptionLabel={option => option?.name || ""}
                placeholder={"Platform"}
              />
            </div>
          </GridSearchFilter>
          <LeadGenerationGrid
            onOrderBy={onOrderBy}
            order={order}
            LeadsData={LeadsData}
          />

          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </div>
    </Dashbard>
  );
};

export default LeadGeneration;
