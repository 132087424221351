import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton, Typography } from "@material-ui/core";
import {
  Close,
} from "../../LeftNav/SvgIcons";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "20px",
    width: "573px",
    paddingBottom: 38,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 80,
    borderBottom: "2px solid #E2E5E9",
    paddingRight: 20,
    paddingLeft: 28,
    background: "#00A3FF",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  newPricingTypo: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#fff",
    letterSpacing: "-0.44px",
  },
  choosePricingTypo: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#3699FF",
    paddingLeft: "16px",
  },
  mainContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#F1FAFF",
    paddingRight: 13,
    height: 74,
    border: "2px dotted #00A3FF",
    margin: "20px 30px 0px 32px",
    borderRadius: 9,
  },
  inenerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Roboto",
    paddingLeft: 16,
    color: "#181C32",
  },
  content: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Roboto",
    paddingLeft: 16,
    color: "#3699FF",
    paddingRight: 19,
    letterSpacing: "-0.01em",
  },
  addButton:{
    padding:"10px 16px",
    height: 36,
  },
  priceType: {
    paddingTop: 28,
    paddingLeft: 30,
    fontSize: 22,
    fontWeight: 700,
    fontFamily: "Roboto",
    color: "#181C32",
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  hyperLink: {
    textDecoration: "none",
  },
}));

const AddNewPricingModal = ({ open, addPriceModal, setIsNewProductPricing, setPriceName }) => {
  const classes = useStyles();

  const addCustomerPrice = (price) => {
    setIsNewProductPricing(true);
    setPriceName(price)
    addPriceModal();
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={addPriceModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.header}>
              <div className={classes.inenerContent}>
                <Typography className={classes.newPricingTypo}>
                  Add New Pricing
                </Typography>
              </div>
              <IconButton className={classes.closIcon} onClick={addPriceModal}>
                <Close />
              </IconButton>
            </div>
            <Typography className={classes.priceType}>
              Choose a Price Type:
            </Typography>
            <div className={classes.mainContent}>
              <div className={classes.inenerContent}>
                <div>
                  <Typography className={classes.text}>
                    New Customer Pricing
                  </Typography>
                  <Typography className={classes.content}>
                    Add Pricing for Specific Contacts, Companies or Groups
                  </Typography>
                </div>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  // className=
                  className={`attributeCreate ${classes.addButton}`}
                  onClick={() => addCustomerPrice("")}
                >
                  Add
                </Button>

              </div>
            </div>
            <div className={classes.mainContent}>
              <div className={classes.inenerContent}>
                <div>
                  <Typography className={classes.text}>
                    New Public/Everyone Price
                  </Typography>
                  <Typography className={classes.content}>
                    Add Pricing for the Public including visitors + most End
                    Users
                  </Typography>
                </div>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  className={classes.addButton}
                  onClick={() => addCustomerPrice("Everyone")}
                >
                  Add
                </Button>
              </div>
            </div>
            <div className={classes.mainContent}>
              <div className={classes.inenerContent}>
                <div>
                  <Typography className={classes.text}>
                    New MSRP Price
                  </Typography>
                  <Typography className={classes.content}>
                    Add Manufacturer’s/Brand’s Suggested Retail Price
                  </Typography>
                </div>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  className={classes.addButton}
                  onClick={() => addCustomerPrice("MSRP Price")}
                >
                  Add
                </Button>
              </div>
            </div>
            <div className={classes.mainContent}>
              <div className={classes.inenerContent}>
                <div>
                  <Typography className={classes.text}>
                    New MAP Price
                  </Typography>
                  <Typography className={classes.content}>
                    Minimum Advertised Price for Resellers + OFish MAP Tools
                  </Typography>
                </div>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  className={classes.addButton}
                  onClick={() => addCustomerPrice("MAP Price")}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddNewPricingModal;
