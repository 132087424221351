import { Button } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { pubEventActions } from "../../../store/pubEvent/pubEventActions";
import { toast } from "react-toastify";

function Overview({ eventDetail, loadData, startLoading, stopLoading }) {
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState("");
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setUserId(localStorage.getItem("id"));
    setUserRole(localStorage.getItem("isAdmin"));
  }, []);

  const submitResponse = value => {
    const eventStatus = {
      eventId: eventDetail ? eventDetail.eventId : null,
      statusId: value,
    };
    startLoading();
    dispatch(pubEventActions.onUpdateEventStatus(eventStatus)).then(data => {
      if (data && data.success) {
        loadData();
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
      stopLoading();
    });
  };

  return (
    <div className='overviewWrapper'>
      <div className='popupTextarea comProfileImage'>
        <img src={eventDetail ? eventDetail.imageUrl : null} />
      </div>
      {eventDetail ? (
        <div className='dateNTime'>
          <span>
            <i>
              <QueryBuilderIcon />
              {`${moment(eventDetail.startDate).format(
                "dddd, MMM DD, YYYY"
              )}${" - "}${moment(eventDetail.endDate).format(
                "dddd, MMM DD, YYYY"
              )}`}
            </i>
            <i>
              <LocationOnIcon /> {eventDetail.location}
            </i>
          </span>

          {/* <ul>
            <li>
              <img src={instagram} alt="" />
            </li>
            <li>
              <img src={facebook} alt="" />
            </li>
            <li>
              <img src={twitter} alt="" />
            </li>
            <li>
              <img src={linkedin} alt="" />
            </li>
            <li>
              <img src={planefill} alt="" />
            </li>
          </ul> */}
        </div>
      ) : null}
      {["customer", "company_user", "company_admin"].includes(userRole) &&
      userId &&
      eventDetail ? (
        <span className='attendanceWrapper'>
          <span>
            Are You Attending?
            <i>Let us know!</i>
          </span>

          {(eventDetail.attendingStatus === 1 ||
            eventDetail.attendingStatus === 0) && (
            <div className='awesomeBtn'>
              <button onClick={() => submitResponse(1)}>Yes!</button>
              {eventDetail.attendingStatus === 1 ? (
                <span>
                  Awesome! See you soon.
                  <i onClick={() => submitResponse(0)}>
                    Change Your Attendance
                  </i>
                </span>
              ) : null}
            </div>
          )}

          {(eventDetail.attendingStatus === 2 ||
            eventDetail.attendingStatus === 0) && (
            <div className='awesomeBtn maybeBtn'>
              <button onClick={() => submitResponse(2)}>Maybe</button>
              {eventDetail.attendingStatus === 2 ? (
                <span>
                  Let us know if anything changes.
                  <i onClick={() => submitResponse(0)}>
                    Change Your Attendance
                  </i>
                </span>
              ) : null}
            </div>
          )}

          {(eventDetail.attendingStatus === 3 ||
            eventDetail.attendingStatus === 0) && (
            <div className='awesomeBtn noBtn'>
              <button onClick={() => submitResponse(3)}>No!</button>
              {eventDetail.attendingStatus === 3 ? (
                <span>
                  Sorry to hear, we’ll miss you!
                  <i onClick={() => submitResponse(0)}>
                    Change Your Attendance
                  </i>
                </span>
              ) : null}
            </div>
          )}
        </span>
      ) : null}
      <h2>Event Details</h2>
      <FroalaEditorView model={eventDetail ? eventDetail.content : ""} />
    </div>
  );
}

export default Overview;
