import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { locationsActions } from "../../../../store/locations/locationsActions";
import moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeaderInnerCenter: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#E06145",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  launchedButtons: {
    color: "#18BC6D",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  newProductLaunch: {
    color: "#8254E3",
    background: "rgba(130, 84, 227, 0.25)",
    boxShadow: "none",
    "&:hover": {
      background: "rgba(130, 84, 227, 0.25)",
      boxShadow: "none",
    },
  },
}));

function LocationGrid({
  gridData,
  startLoading,
  endLoading,
  loadData,
  order,
  onOrderBy,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { selectedLocations } = useSelector(state => state.LocationsReducer);

  const selectLocations = value => {
    dispatch(locationsActions.onSelectLocations(value));
  }; 

  const selectAllLocations = () => {
    if (selectedLocations.length === gridData.length) {
      dispatch(locationsActions.onChange("selectedLocations", []));
    } else {
      _.forEach(gridData, function (location) {
        var findItem = selectedLocations.find(x => x.id === location.id);
        if (!findItem) {
          // selectedLocations.push(location);
          dispatch(locationsActions.onSelectLocations(location));
        }
      });
    }
  };

  const checkLocationsSelected = itemId => {
    const assetExist = selectedLocations.find(loc => {
      return loc.id === itemId;
    });
    if (assetExist) {
      return true;
    } else {
      return false;
    }
  };

  const checkAllLocationsSelected = () => {
    if (selectedLocations.length === gridData.length) {
      return true;
    } else {
      return false;
    }
  };

  const deleteLocations = () => {
    startLoading();
    dispatch(locationsActions.onDeleteLocations(selectedLocations)).then(
      data => {
        if (data && !data.success) {
          toast.error(data.message);
          endLoading();
        } else {
          loadData();
          toast.success("Successfully deleted");
          endLoading();
        }
      }
    );
  };

  const redirectUrl = value => {
    startLoading();
    dispatch(locationsActions.onGetLocation(value)).then(loc => {
      if (loc && !loc.success) {
        toast.error(loc.message);
      } else {
        endLoading();
        history.push({
          pathname: "/new-location",
          state: { collection: loc.data },
        });
      }
    });
  };

  const onSelectAllLocations = () => {
    if (!selectedLocations.length) {
      dispatch(locationsActions.onAllSelectLocations(gridData));
    } else {
      dispatch(locationsActions.onAllSelectLocations([]));
    }
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        {selectedLocations.length > 0 ? (
          <>
            <Grid item md={9} className={classes.deleteItems}>
              <span
                className='gridDeleteItems'
                onClick={() => deleteLocations()}>
                <DeleteIcon /> Delete
              </span>
            </Grid>
          </>
        ) : null}
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont}
                  onClick={onSelectAllLocations}
                  checked={selectedLocations.length === gridData.length 
                    && selectedLocations.length > 0}
              />
              {/* {gridData && gridData.length > 0 && (
                <Checkbox
                  className={classes.checkBoxFont}
                  onClick={() => selectAllLocations()}
                  checked={checkAllLocationsSelected()}
                />
              )} */}
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              LOCATION NAME
              <TableSortLabel
                active={order.key === "NameInternal"}
                direction={order.val === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    key: "NameInternal",
                    val: order.val === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              LOCATION ADDRESS
              <TableSortLabel
                active={order.key === "Address"}
                direction={order.val === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    key: "Address",
                    val: order.val === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              NO OF USES
              <TableSortLabel
                active={order.key === "NoOfUses"}
                direction={order.val === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    key: "NoOfUses",
                    val: order.val === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gridData &&
            gridData.length > 0 &&
            gridData.map((item, index) => {
              return (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell className={classes.gridBodyInner}>
                    <Checkbox
                      className={classes.checkBoxFont}
                      onClick={() => selectLocations(item)}
                      checked={checkLocationsSelected(item.id)}
                    />
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.nameInternal}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.address}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {`${item.noOfUses}${" uses"}`}
                  </TableCell>
                  <TableCell className='editButton'>
                    <span onClick={() => redirectUrl(item.id)}>
                      <EditIcon />
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          {gridData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='5'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                Uh Oh! You haven’t created any location yet.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default LocationGrid;
