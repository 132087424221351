import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { ExitToApp, ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import {
  baloon,
  group,
  pictures,
  dashboard,
  productInventory,
  portal,
  messagingActive,
  integration

} from "../../../resources/images/index";
import {
  Dashboard,
  PRPlanning,
  AssetManagement,
  ToolsAndPortal,
  Contacts,
  Analytics,
  Messages,
  Support,
  // Settings,
  // Notification,
  // Help,
  ProductInventory,
  Integration,
  IntegrationHighlited,
} from "./SvgIcons";

import LunchesNav from "./LunchesNav";
import AssetManagementNav from "./AssetManagementNav";
import DashboardNav from "./DashboardNav";
import ProductInventoryNav from "./ProductInventoryNav";
import ToolsAndPortalNav from "./ToolsAndPortalNav";
import { BusinessCustomerNav } from "./BusinessCustomerNav";
import { endUserActions } from "../../../store/endUser/endUserActions";
import { softwareUpdateActions } from "../../../store/softwareUpdates/softwareUpdatesActions";
import { launchesActions } from "../../../store/launches/launchesActions";
import { RoutesObj } from "../../../routes";
import ConversationNav from "./ConversationNav";
import SoftwareUpdatesNav from "./SoftwareUpdatesNav";
import { Tooltip } from "@material-ui/core";

const LeftNav = ({ setOpen, openNav, startLoading, endLoading }) => {
  const dispatch = useDispatch();
  const [isLaunch, setIsLaunch] = useState(true);
  const [isAssetManagement, setIsAssetManagement] = useState(false);
  const [isContacts, setIsContacts] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const [isToolsNPortal, setIsToolNPortal] = useState(false);
  const [isConversation, setIsConversation] = useState(false);
  const [isSyncingAndIntegration, setIsSyncingAndIntegration] = useState(false);

  const history = useHistory();
  const {id , buildId} = useParams();

  const appDashboard = () => {
    setIsDashboard(true);
    setIsLaunch(false);
    setIsAssetManagement(false);
    setIsContacts(false);
    setIsSyncingAndIntegration(false);
    history.push("/dashboard");
  };

  const appPlanning = () => {
    setIsLaunch(true);
    setIsAssetManagement(false);
    setIsContacts(false);
    setIsSyncingAndIntegration(false);
    dispatch(launchesActions.onChange("panel1", true));
    dispatch(launchesActions.onChange("panel2", false));
    dispatch(launchesActions.onChange("panel3", false));
    dispatch(launchesActions.onChange("panel4", false));
    dispatch(launchesActions.onChange("panel5", false));
    history.push("/launches");
  };

  const assetManagement = () => {
    setIsAssetManagement(true);
    setIsLaunch(false);
    setIsContacts(false);
    setIsSyncingAndIntegration(false);
    history.push("/assets-media-library");
  };

  const customerContacts = () => {
    setIsContacts(true);
    setIsAssetManagement(false);
    setIsLaunch(false);
    setIsSyncingAndIntegration(false);
    dispatch(endUserActions.onChange("panel2", true));
    dispatch(endUserActions.onChange("panel4", false));
    history.push("/all-business-contacts");
  };

  const productIntentory = () => {
    setIsDashboard(true);
    setIsLaunch(false);
    setIsAssetManagement(false);
    setIsContacts(false);
    setIsSyncingAndIntegration(false);
    history.push("/product-inventory");
  };

  const toolAndPortal = () => {
    setIsToolNPortal(true);
    setIsLaunch(false);
    setIsAssetManagement(false);
    setIsContacts(false);
    setIsSyncingAndIntegration(false);
    history.push("/newsroom-portal");
  };
  const Conversation = () => {
    setIsConversation(true);
    setIsToolNPortal(false);
    setIsLaunch(false);
    setIsAssetManagement(false);
    setIsContacts(false);
    setIsSyncingAndIntegration(false);
    // history.push("all-conversations");
    history.push("/conversations-list");
  };
  const SyncingAndIntegrations = () => {
    setIsSyncingAndIntegration(true);
    setIsToolNPortal(false);
    setIsLaunch(false);
    setIsAssetManagement(false);
    setIsContacts(false);
    dispatch(softwareUpdateActions.onChange("panel1", true));
    dispatch(softwareUpdateActions.onChange("panel2", false));
    dispatch(softwareUpdateActions.onChange("panel3", false));
    dispatch(softwareUpdateActions.onChange("panel4", false));
    history.push("/software-releases");
  };

  const currentRoot = history ? history.location.pathname : null;

  return (
    <div className='leftNavWrapper'>
      <div className='leftNavLogo' />
      <div className='left-nav-bar align-items-center'>
        <Tooltip title='Dashboards' placement='right'>
          <div onClick={appDashboard} className='leftNavIcons'>
            {currentRoot === "/dashboard" ? (
              <img src={dashboard} alt='baloons' />
            ) : (
              <Dashboard />
            )}
          </div>
        </Tooltip>
        <Tooltip title='PR Planning' placement='right'>
          <div onClick={appPlanning} className='leftNavIcons '>
            {currentRoot === RoutesObj.Launches.path ||
            currentRoot === RoutesObj.NewLaunch.path ||
            currentRoot === RoutesObj.UpdateLaunch.path ||
            currentRoot === "/upcoming-launches" ||
            currentRoot === "/past-launches" ||
            currentRoot === "/all-releases" ||
            currentRoot === "/upcoming-releases" ||
            currentRoot === "/past-releases" ||
            currentRoot === "/release-category" ||
            currentRoot === "/dashboard-events" ||
            currentRoot === "/past-events" ||
            currentRoot === "/upcomming-events" ||
            currentRoot === "/new-event" ||
            currentRoot === "/new-media-release" ||
            currentRoot === "/update-media-release" ||
            currentRoot === "/all-collections" ||
            currentRoot === "/collection-category" ||
            currentRoot === "/new-collection-category" ||
            currentRoot === "/new-collections" ||
            currentRoot === "/event-categories" ||
            currentRoot === "/new-release-category" ||
            currentRoot === "/location" ||
            currentRoot === "/new-location" ||
            currentRoot === "/launch-categories" ||
            currentRoot === "/add-launch-categories" ||
            currentRoot === "/new-category" ? (
              <img src={baloon} alt='baloons' />
            ) : (
              <PRPlanning />
            )}
          </div>
        </Tooltip>
        <Tooltip title='Asset Management' placement='right'>
          <div onClick={assetManagement} className='leftNavIcons'>
            {currentRoot === "/assets-media-library" ||
            currentRoot === "/assets-media-library-favorite" ||
            currentRoot === "/assets-media-library-conversations" ||
            currentRoot === "/assets-media-library-sharedfiles" ? (
              <img src={pictures} alt='baloons' />
            ) : (
              <AssetManagement />
            )}
          </div>
        </Tooltip>
        <Tooltip title='Contacts' placement='right'>
          <div onClick={customerContacts} className='leftNavIcons'>
            {[
              "/consumer-overview",
              "/customer-overview",
              "/all-business-contacts",
              "/new-business-group",
              "/new-enduser-group",
              "/all-companies",
              "/new-company-contacts",
              "/new-customer",
              "/business-group",
              "/customer-group",
              "/new-contact",
              "/all-end-users",
              "/all-users-groups",
              "/company-information",
              "/ad-device-overview",
              "/business-customer",
              "/contact-types",
              "/new-contact-types",
              "/all-admin-users",
              "/admin-overview",
              `/blocked-business-location/${id}/edit`,
              `/lead-generation/${id}`,
              "/lead-generation",
              "/new-lead-generation",
              RoutesObj.BusinessContactFavorites.path,
              RoutesObj.BusinessContactRecentlyAdded.path,
              RoutesObj.BusinessContactArchived.path,
              RoutesObj.BusinessContactBlocked.path,
              RoutesObj.BusinessContactDeleted.path,
              RoutesObj.BlockedBusinessLocation.path,
            ].includes(currentRoot) ? (
              <img src={group} alt='baloons' />
            ) : (
              <Contacts />
            )}
          </div>
        </Tooltip>
        <Tooltip title='Products & Inventory' placement='right'>
          <div onClick={productIntentory} className='leftNavIcons'>
            {[
              "/product-inventory",
              "/new-product",
              "/update-product",
              "/product-categories",
              "/new-product-category",
              "/update-product-category",
              "/product-serials",
              "/product-serial",
              "/brands",
              RoutesObj.ProductPricing.path,
            ].includes(currentRoot) ? (
              <img src={productInventory} alt='baloons' />
            ) : (
              <ProductInventory />
            )}
          </div>
        </Tooltip>
        <Tooltip title='Conversations' placement='right'>
          <div className='leftNavIcons' onClick={Conversation}>
            {[
              "/your-views",
              "/conversation-settings",
              "/conversation-type",
              `/conversation-type/${id}`,
              `/conversation-type/${id}/edit`,
              "/all-conversations",
              "/conversations-list",
              "/company-inbox",
              "/press-inbox",
              "/support-inbox",
              "/sales-inbox",
              "/assigned-conversations",
              "/unassigned-conversations",
              "/spam-conversations",
              "/draft-conversations",
              "/archive-conversations",
              "/archived-conversations",
              "/trash-conversations",
            ].includes(currentRoot) ? (
              <img src={messagingActive} alt='messages' />
            ) : (
              <Messages />
            )}
          </div>
        </Tooltip>
        <Tooltip title='Support' placement='right'>
          <div className='leftNavIcons'>
            <Support />
          </div>
        </Tooltip>
        <Tooltip title=' Tools & Portal' placement='right'>
          <div onClick={toolAndPortal} className='leftNavIcons'>
            {[
              "/newsroom-portal",
              "/locator-widget",
              "/price-list",
              "/new-price-list",
              "/update-price-list",
              "/create-price-list",
              "/edit-price-list",
            ].includes(currentRoot) ? (
              <img src={portal} alt='portal' />
            ) : (
              <ToolsAndPortal />
            )}
          </div>
        </Tooltip>
        <div className='leftNavIcons'>
          <Analytics />
        </div>
        <div  onClick={SyncingAndIntegrations} className="leftNavIcons">
          {(currentRoot === "/software-releases"
           || currentRoot === "/new-software-release"
           || currentRoot === `/software-new-build/${id}`
           || currentRoot === `/software-builds/${id}`
           || currentRoot === `/software-builds/${id}/${buildId}`
           || currentRoot === `/software-new-build/${id}/${buildId}`
           || currentRoot === `/new-software-release/${id}`
           || currentRoot === `/software-releases/${id}`
           || currentRoot === "/software-update-dashboard"
           || currentRoot === "/software-connected-devices"
           || currentRoot === "/software-update-analytics"
           || currentRoot === "/software-update-build"
           || currentRoot === "/software-update-settings") ? (
            <IntegrationHighlited />
          ) : (
            <Integration />
          )}
        </div>

        <div className='notifications'>
          {/* <Link to="/">
            <ContactSupportIcon />
          </Link>
          <Link to="/">
            <SettingsIcon />
          </Link> */}
          {/* <Link
            to='/'
            onClick={() => {
              localStorage.removeItem("access_token");
              localStorage.removeItem("isAuthenticated");
              localStorage.removeItem("userEmail");
              localStorage.removeItem("isAdmin");
            }}>
            <ExitToApp />
           
          </Link> */}
        </div>
      </div>
      <div className='nextLevel'>
        {/* <TextField id="outlined-basic" label="Email or username" variant="filled" fullWidth/> */}

        {currentRoot === "/dashboard" && (
          <div className='nextlevelInner'>
            <span className='navOpenIcon' onClick={() => setOpen()}>
              {openNav ? <ArrowBackIos /> : <ArrowForwardIos />}
            </span>
            {openNav && (
              <div className='nextLevelMenu'>
                <DashboardNav />
              </div>
            )}
          </div>
        )}

        {(currentRoot === RoutesObj.Launches.path ||
          currentRoot === RoutesObj.NewLaunch.path ||
          currentRoot === RoutesObj.UpdateLaunch.path ||
          currentRoot === "/upcoming-launches" ||
          currentRoot === "/launch-categories" ||
          currentRoot === "/add-launch-categories" ||
          currentRoot === "/past-launches" ||
          currentRoot === "/all-releases" ||
          currentRoot === "/upcoming-releases" ||
          currentRoot === "/past-releases" ||
          currentRoot === "/release-category" ||
          currentRoot === "/dashboard-events" ||
          currentRoot === "/upcomming-events" ||
          currentRoot === "/past-events" ||
          currentRoot === "/event-categories" ||
          currentRoot === "/new-event" ||
          currentRoot === "/new-category" ||
          currentRoot === "/new-media-release" ||
          currentRoot === "/update-media-release" ||
          currentRoot === "/all-collections" ||
          currentRoot === "/collection-category" ||
          currentRoot === "/location" ||
          currentRoot === "/new-collection-category" ||
          currentRoot === "/new-location" ||
          currentRoot === "/new-release-category" ||
          currentRoot === "/new-collections") && (
          <div className='nextlevelInner'>
            <span className='navOpenIcon' onClick={() => setOpen()}>
              {openNav ? <ArrowBackIos /> : <ArrowForwardIos />}
            </span>
            {openNav && (
              <div className='nextLevelMenu'>
                <LunchesNav />
              </div>
            )}
          </div>
        )}

        {(currentRoot === "/assets-media-library" ||
          currentRoot === "/assets-media-library-favorite" ||
          currentRoot === "/assets-media-library-conversations" ||
          currentRoot === "/assets-media-library-sharedfiles" ||
          currentRoot === "/assets-media-library-internalFiles" ||
          currentRoot === "/assets-media-library-trash") && (
          <div className='nextlevelInner'>
            <span className='navOpenIcon' onClick={() => setOpen()}>
              {openNav ? <ArrowBackIos /> : <ArrowForwardIos />}
            </span>
            {openNav && (
              <div className='nextLevelMenu'>
                <AssetManagementNav
                  startLoading={() => startLoading()}
                  endLoading={() => endLoading()}
                />
              </div>
            )}
          </div>
        )}
        <div className="leftNavIcons">
          {(currentRoot === "/software-releases"
            || currentRoot === "/new-software-release"
            || currentRoot === "/software-update-dashboard"
            || currentRoot === "/software-update-build"
            || currentRoot === "/software-connected-devices"
            || currentRoot === "/software-update-settings"
            || currentRoot === "/software-update-analytics"
            || currentRoot === `/new-software-release/${id}`
            || currentRoot === `/software-releases/${id}`
            || currentRoot === `/software-builds/${id}`
            || currentRoot === `/software-builds/${id}/${buildId}`
            || currentRoot === `/software-new-build/${id}/${buildId}`
            || currentRoot === `/software-new-build/${id}`
          )
            && (
              <div className="nextlevelInner">
                <span className="navOpenIcon" onClick={() => setOpen()}>
                  {openNav ? <ArrowBackIos /> : <ArrowForwardIos />}
                </span>
                {openNav && (
                  <div className="nextLevelMenu">
                    <SoftwareUpdatesNav />
                  </div>
                )}
              </div>
            )}
        </div>

        {[
          "/consumer-overview",
          "/customer-overview",
          "/all-business-contacts",
          "/all-companies",
          "/new-company-contacts",
          "/new-customer",
          "/business-customer",
          "/business-group",
          "/new-business-group",
          "/new-enduser-group",
          "/customer-group",
          "/new-contact",
          "/all-end-users",
          "/all-users-groups",
          "/company-information",
          "/ad-device-overview",
          "/contact-types",
          "/new-contact-types",
          "/all-admin-users",
          "/admin-overview",
          `/blocked-business-location/${id}/edit`,
          `/lead-generation/${id}`,
          "/lead-generation" ,
          "/new-lead-generation" ,
          RoutesObj.BusinessContactFavorites.path,
          RoutesObj.BusinessContactRecentlyAdded.path,
          RoutesObj.BusinessContactArchived.path,
          RoutesObj.BusinessContactBlocked.path,
          RoutesObj.BusinessContactDeleted.path,
          RoutesObj.BlockedBusinessLocation.path,
        ].includes(currentRoot) && (
          <div className='nextlevelInner'>
            <span className='navOpenIcon' onClick={() => setOpen()}>
              {openNav ? <ArrowBackIos /> : <ArrowForwardIos />}
            </span>
            {openNav && (
              <div className='nextLevelMenu'>
                <BusinessCustomerNav />
              </div>
            )}
          </div>
        )}

        {[
          "/product-inventory",
          "/new-product",
          "/update-product",
          "/product-categories",
          "/new-product-category",
          "/update-product-category",
          "/product-serials",
          "/product-serial",
          "/brands",
          RoutesObj.ProductPricing.path,
        ].includes(currentRoot) && (
          <div className='nextlevelInner'>
            <span className='navOpenIcon' onClick={() => setOpen()}>
              {openNav ? <ArrowBackIos /> : <ArrowForwardIos />}
            </span>
            {openNav && (
              <div className='nextLevelMenu'>
                <ProductInventoryNav />
              </div>
            )}
          </div>
        )}

        {[
          "/newsroom-portal",
          "/locator-widget",
          "/price-list",
          "/new-price-list",
          "/update-price-list",
          "/create-price-list",
          "/edit-price-list",
        ].includes(currentRoot) && (
          <div className='nextlevelInner'>
            <span className='navOpenIcon' onClick={() => setOpen()}>
              {openNav ? <ArrowBackIos /> : <ArrowForwardIos />}
            </span>
            {openNav && (
              <div className='nextLevelMenu'>
                <ToolsAndPortalNav />
              </div>
            )}
          </div>
        )}

        {[
          "/your-views",
          "/conversation-settings",
          "/conversation-type",
          `/conversation-type/${id}`,
          `/conversation-type/${id}/edit`,
          "/all-conversations",
          "/conversations-list",
          "/company-inbox",
          "/press-inbox",
          "/support-inbox",
          "/sales-inbox",
          "/assigned-conversations",
          "/unassigned-conversations",
          "/spam-conversations",
          "/draft-conversations",
          "/archive-conversations",
          "/archived-conversations",
          "/trash-conversations",
        ].includes(currentRoot) && (
          <div className='nextlevelInner'>
            <span className='navOpenIcon' onClick={() => setOpen()}>
              {openNav ? <ArrowBackIos /> : <ArrowForwardIos />}
            </span>
            {openNav && (
              <div className='nextLevelMenu'>
                <ConversationNav />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftNav;
