import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  activityButton: {
    backgroundColor: "#E5F2FF",
    padding: "8px 35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#E5F2FF",
    },
  },
}));

function CustomerActivity(props) {
  const { businessCompanyInfo } = useSelector(state => state.ContactsReducer);
  const classes = useStyles();
  return (
    <div>
      <div className='customerContactInner'>
        <div className='customerContactHeader'>
          Recent Activity{" "}
          <span>
            <Button color='primary' className={classes.activityButton}>
              View All
            </Button>
          </span>
        </div>
        {businessCompanyInfo ? (
          businessCompanyInfo.customerActivities ? (
            businessCompanyInfo.customerActivities.map(x => (
              <div>
                <div className='activityBody'>
                  <span className='activityDate'>
                    <span
                      className='circle'
                      style={{ border: "4px solid #000" }}
                    />{" "}
                    08:42 PM
                  </span>
                  <span className='activityDesc'>
                    Customer viewed <i>Pro Sport Series</i> News Release
                  </span>
                </div>
                <div className='activityBody'>
                  <span className='activityDate'>
                    <span
                      className='circle'
                      style={{ border: "4px solid #1BC5BD" }}
                    />{" "}
                    08:42 PM
                  </span>
                  <span className='activityDesc'>
                    Customer viewed <i>Pro Sport Series</i> News Release
                  </span>
                </div>
                <div className='activityBody'>
                  <span className='activityDate'>
                    <span
                      className='circle'
                      style={{ border: "4px solid #F64E60" }}
                    />{" "}
                    January
                    <br /> 1, 2021
                  </span>
                  <span className='activityDesc'>
                    Customer viewed <i>Pro Sport Series</i> News Release
                  </span>
                </div>

                <div className='activityBody'>
                  <span className='activityDate'>
                    <span
                      className='circle'
                      style={{ border: "4px solid #49B8EF" }}
                    />{" "}
                    January
                    <br /> 1, 2021
                  </span>
                  <span className='activityDesc'>
                    Customer viewed <i>Pro Sport Series</i> News Release
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default CustomerActivity;
