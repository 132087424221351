import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ArrowDropDown } from "@material-ui/icons";
import OutsideClickHandler from "react-outside-click-handler";
import classnames from "classnames";

const ConversationStatesBtn = ({ setCompaginId }) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [isLaunched, setIsLaunched] = useState("General");

  return (
    <OutsideClickHandler onOutsideClick={() => setIsDropdown(false)}>
      <span className="draftDropdown">
        <Button
          color="primary"
          variant="contained"
          onClick={() => setIsDropdown(!isDropdown)}
          className={classnames("")}
          type="submit">
          {isLaunched === "General"
            ? "General"
            : isLaunched === "Press"
            ? "Press"
            : isLaunched === "Support"
            ? "Support"
            : "Sale"}{" "}
          <ArrowDropDown />
        </Button>

        {isDropdown && (
          <span className="draftInnerDropdown">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLaunched("General");
                setIsDropdown(!isDropdown);
                setCompaginId(1);
              }}>
              General
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLaunched("Press");
                setIsDropdown(!isDropdown);
                setCompaginId(2);
              }}>
              Press
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLaunched("Support");
                setIsDropdown(!isDropdown);
                setCompaginId(4);
              }}>
              Support
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setIsLaunched("Sale");
                setIsDropdown(!isDropdown);
                setCompaginId(5);
              }}>
              Sale
            </Button>
          </span>
        )}
      </span>
    </OutsideClickHandler>
  );
};

export default ConversationStatesBtn;
