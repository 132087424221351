/* eslint-disable import/no-anonymous-default-export */
import _, { constant } from "lodash";
import { assetsTypes } from "./assetsTypes";
import subDays from "date-fns/subDays";
import { accountTypes } from "../accounts/accountTypes";

function checkByDays(value, days) {
  let byDays = "";
  if (days === 30) {
    byDays = subDays(new Date(), 30);
    return value.filter((x) => byDays < new Date(x.uploadedAt));
  } else if (days === 90) {
    byDays = subDays(new Date(), 90);
    return value.filter((x) => byDays < new Date(x.uploadedAt));
  } else if (days === 180) {
    byDays = subDays(new Date(), 180);
    return value.filter((x) => byDays < new Date(x.uploadedAt));
  } else if (days === 365) {
    byDays = subDays(new Date(), 365);
    return value.filter((x) => byDays < new Date(x.uploadedAt));
  } else {
    return value;
  }
}

function checkByDaysCollection(value, days) {
  let byDays = "";
  if (days === 30) {
    byDays = subDays(new Date(), 30);
    return value.filter((x) => byDays < new Date(x.dateCreated));
  } else if (days === 90) {
    byDays = subDays(new Date(), 90);
    return value.filter((x) => byDays < new Date(x.dateCreated));
  } else if (days === 180) {
    byDays = subDays(new Date(), 180);
    return value.filter((x) => byDays < new Date(x.dateCreated));
  } else if (days === 365) {
    byDays = subDays(new Date(), 365);
    return value.filter((x) => byDays < new Date(x.dateCreated));
  } else {
    return value;
  }
}

const INITIAL_STATE = {
  errorMessage: "",
  mediaAssets: [],
  copyMediaAssets: [],
  selectedMediaAssets: [],
  uploadeAssetMedia: [],
  gridData: [],
  preViewDataList: [],
  showAllData: true,
  breadCrumbs: [],
  allCollections: [],
  copyAllCollections: [],
  selectedAllCollection: [],
  colCategories: [],
  selectedColCategory: [],
  assetCollectionArray: [],
  mediaAssetsFolder: [],
  collectionVisibilities: [],
  selectedFileType: [],
  selectedCatType: [],
  favoritesAssets: [],
  currentFolder: null,
  currentMoveFolder: 0,
  previousFolder: 0,
  parentId: null,
  orderByAlphabet: false,
  orderByRecentlyUpdated: false,
  orderByCreatedDate: false,
  orderByFileSize: false,
  previewId: "",
  type: "",
  search: "",
  time: "",
  isfavorite: false,
  isConversation: false,
  isSharedFile: false,
  isInternalFiles: false,
  isTrash: false,
  pageSize: 10,
  pageNo: 1,
  orderByKey: "Name",
  orderByVal: 0,
  locationId: 0,
  paginationResponse: {
    currentCount: 0,
    totalRecords: 0,
  },
  assetsStats:{},
  uploadFolderData:[],
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case assetsTypes.ASSETS_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    case assetsTypes.ASSETS_PREVIEWDATA:
    return {
      ...state,
      errorMessage: "",
      [action.payload.prop]: [...state.gridData, ...action.payload.value],
    };
    case assetsTypes.RESET_ASSETS_PREVIEW: {
      const newGridData = state.gridData.filter(asset => asset.parentId !== action.payload.value);
      return {
        ...state,
        errorMessage: "",
        gridData: [...newGridData],
      };
    };
    case assetsTypes.SELECTED_ASSETS: {
      const newState = { ...state };
      const mediaAssets = action.payload;
      const mediaAssetsExist = newState.selectedMediaAssets.find((mAssetId) => {
        return mAssetId === mediaAssets.id;
      });

      if (mediaAssetsExist && newState.selectedMediaAssets.length > 0) {
        _.remove(newState.selectedMediaAssets, (mAssetId) => {
          return mAssetId === mediaAssets.id;
        });
      } else {
        newState.selectedMediaAssets.push(mediaAssets.id);
      }
      return newState;
    }

    case assetsTypes.SELECT_ALL_ASSETS: {  
      const mediaAllAssets = action.payload;
      return {
        ...state,
        selectedMediaAssets: mediaAllAssets
      }      
    }

    case assetsTypes.SELECTED_ALL_COLLECTION: {
      const newState = { ...state };
      const allCollection = action.payload;
      const allCollectionExist = newState.selectedAllCollection.find(
        (allCol) => {
          return allCol.id === allCollection.id;
        }
      );

      if (allCollectionExist && newState.selectedAllCollection.length > 0) {
        _.remove(newState.selectedAllCollection, (allCol) => {
          return allCol.id === allCollection.id;
        });
      } else {
        newState.selectedAllCollection.push(allCollection);
      }
      return newState;
    }

    case assetsTypes.SELECTED_COL_CATEGORY: {
      const newState = { ...state };
      const colCategory = action.payload;
      const colCategoryExist = newState.selectedColCategory.find((colCat) => {
        return colCat.id === colCategory.id;
      });

      if (colCategoryExist && newState.selectedColCategory.length > 0) {
        _.remove(newState.selectedColCategory, (colCat) => {
          return colCat.id === colCategory.id;
        });
      } else {
        newState.selectedColCategory.push(colCategory);
      }
      return newState;
    }
    case assetsTypes.SELECT_COLLECTION_VISIBILITY: {
      const newState = { ...state };
      let a = [...newState.collectionVisibilities];
      const relVisibility = action.payload;
      let item = a[relVisibility.index];
      if (relVisibility.name === "viewRelease") {
        item = { ...item, viewRelease: !item.viewRelease };
      } else {
        item = { ...item, preReleaseAccess: !item.preReleaseAccess };
      }
      a[relVisibility.index] = item;
      newState.collectionVisibilities = a;
      return newState;
    }
    case assetsTypes.ON_SELECT_FILETYPE: {
      const newState = { ...state };
      const colCategory = action.payload;
      const colCategoryExist = newState.selectedFileType.find((colCat) => {
        return colCat === colCategory;
      });

      if (colCategoryExist && newState.selectedFileType.length > 0) {
        _.remove(newState.selectedFileType, (colCat) => {
          return colCat === colCategory;
        });
      } else {
        newState.selectedFileType.push(colCategory);
      }
      return newState;
    }
    case assetsTypes.ON_PRESS_FILTER: {
      const newState = { ...state };
      let filterArray = [];
      const searchData = action.payload;
      if (searchData.searchText !== "") {
        let byName = [];
        byName = _.filter(newState.copyMediaAssets, (o) => {
          if (o.name) {
            return o.name
              .toLowerCase()
              .includes(searchData.searchText.toLowerCase());
          }
        });
        if (searchData.dayFilter !== "") {
          byName = checkByDays(byName, searchData.dayFilter);
        }
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, (x) => {
            const aa = byName.filter((y) => y.type === x);
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byName;
        }
      } else if (searchData.dayFilter !== "") {
        let byMonth = [];
        byMonth = checkByDays(newState.copyMediaAssets, searchData.dayFilter);
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, (x) => {
            const aa = byMonth.filter((y) => y.type === x);
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byMonth;
        }
      } else {
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, (x) => {
            const aa = newState.copyMediaAssets.filter((y) => y.type === x);
            filterArray = filterArray.concat(aa);
          });
        }
      }
      newState.mediaAssets = filterArray;
      return newState;
    }
    case assetsTypes.ON_SELECT_CATTYPE: {
      const newState = { ...state };
      const colCategory = action.payload;
      const colCategoryExist = newState.selectedCatType.find((colCat) => {
        return colCat === colCategory;
      });

      if (colCategoryExist && newState.selectedCatType.length > 0) {
        _.remove(newState.selectedCatType, (colCat) => {
          return colCat === colCategory;
        });
      } else {
        newState.selectedCatType.push(colCategory);
      }
      return newState;
    }
    case assetsTypes.ON_PRESS_FILTER_CAT: {
      const newState = { ...state };
      let filterArray = [];
      const searchData = action.payload;
      if (searchData.searchText !== "") {
        let byName = [];
        byName = _.filter(newState.copyAllCollections, (o) => {
          if (o.title) {
            return o.title
              .toLowerCase()
              .includes(searchData.searchText.toLowerCase());
          }
        });
        if (searchData.dayFilter !== "") {
          byName = checkByDaysCollection(byName, searchData.dayFilter);
        }
        if (newState.selectedCatType.length > 0) {
          _.forEach(newState.selectedCatType, (x) => {
            const aa = byName.filter(
              (y) => y.category && y.category.name === x
            );
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byName;
        }
      } else if (searchData.dayFilter !== "") {
        let byMonth = [];
        byMonth = checkByDaysCollection(
          newState.copyAllCollections,
          searchData.dayFilter
        );
        if (newState.selectedCatType.length > 0) {
          _.forEach(newState.selectedCatType, (x) => {
            const aa = byMonth.filter(
              (y) => y.category && y.category.name === x
            );
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byMonth;
        }
      } else {
        if (newState.selectedCatType.length > 0) {
          _.forEach(newState.selectedCatType, (x) => {
            const aa = newState.copyAllCollections.filter(
              (y) => y.category && y.category.name === x
            );
            filterArray = filterArray.concat(aa);
          });
        }
      }
      newState.allCollections = filterArray;
      return newState;
    }
    default:
      return state;
  }
};
