import React, { useEffect } from "react";
import { TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  MuiSelectIcon: {
    left: "25px !important"
  }
}));

const Pagination = ({
  view,
  recordCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  backIconButtonProps,
  nextIconButtonProps,
  rowsPerPageOptions,
}) => {
  const classes = useStyles();
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = event => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <TablePagination
      labelRowsPerPage={view === "grid" ? "Records per page:" : "Rows per page:"}
      component='div'
      rowsPerPageOptions={rowsPerPageOptions}
      count={recordCount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      backIconButtonProps={backIconButtonProps}
      nextIconButtonProps={nextIconButtonProps}
      classes={{
        selectIcon: classes.MuiSelectIcon
      }}
    />
  );
};

export { Pagination };
