import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { contactsActions } from "../../../../../store/contacts/contactsActions";

const data = [
  {
    title: "ELITE Dealer",
    role: "Dealer",
  },
  {
    title: "AAV Distributor ",
    role: "Distributor",
  },
  {
    title: "AAV Authorized Dealer ",
    role: "Dealer",
  },
  {
    title: "OEM/Brand ",
    role: "OEM/Brand",
  },
];

const AllContactTypes = ({ contactList }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      {contactList?.map((item, index) => {
        return (
          <ul key={index}>
            <li className='roleTitle'>{item?.value}</li>
            <li className='newCompanyBtns'>
              <span>
                <label
                  className={`${
                    item.generic === "Direct Dealer" ||
                    item.generic === "Dealer (via Distribution or Rep.)"
                      ? "dealerRole"
                      : item.generic === "Distributor"
                      ? "dustributorRole"
                      : item.generic === "OEM/Brand"
                      ? "oemRole"
                      : " "
                  }`}>
                  {item?.generic}
                </label>
              </span>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  dispatch(contactsActions.onChange("contactTypes", item));
                  history.push("/new-company-contacts");
                }}>
                <AddIcon /> Create
              </Button>
            </li>
          </ul>
        );
      })}
    </>
  );
};

export default AllContactTypes;
