import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CustomerGroupGrid from "./CustomerGroupGrid";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import {
  ListViewTile,
  Pagination,
  Spinner,
} from "../../../../components/common";
import { AddNewBusinessGroup } from "./AddNewBusinessGroup";
import { StarFill, StarOutline } from "../../LeftNav/SvgIcons";
import { AllBusinessContactsGridFilter } from "./components";
import { endpoints } from "../../../../config/apiConfig";
import { scrollToBottom } from "../../../../utils/helper";
import EndUserGroups from "../EndUser/EndUserGroups";

const BusinessGroup = ({ isNewBusinessGroup, setIsNewBusinessGroup }) => {
  document.title = "OFish - Business Groups";

  const dispatch = useDispatch();
  const { isBCListView } = useSelector(state => state.endUserReducer);

  const [isLoading, setLoading] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [editableRecord, setEditableRecord] = useState(null);
  const [businessGroups, setBusinessGroups] = useState([]);
  const [activeTabId, setActiveTabId] = useState("2");
  const [isFilterAppling, setIsFilterAppling] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterModel, setFilters] = useState({
    startDate: "",
    endDate: "",
    companyName: "",
    groupName: "",
    color: "",
  });
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });

  const loadCusGroups = useCallback(() => {
    const modal = {
      ...filterModel,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    }
    setLoading(true);
    dispatch(contactsActions.onGetBusinessGroupListSearch(modal)).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          setPaginationResponse(data?.data?.pagination);
          setBusinessGroups(data.data.data);
          if (isFilterAppling) {
            setIsFilterApplied(true);
          }
          setIsFilterAppling(false);
        }
      }
      setLoading(false);
    });
  }, [
    filterModel,
    rowsPerPage,
    page,
    dispatch,
    order.orderByKey,
    order.orderVal,
  ]);

  useEffect(() => {
    loadCusGroups();
  }, [loadCusGroups]);

  useEffect(() => {
    setIsListView(isBCListView);
  }, [isBCListView]);

  useEffect(() => {
    if (isNewBusinessGroup || editableRecord?.id)
      scrollToBottom({ elementId: "add-new-business-group" });
  }, [isNewBusinessGroup, editableRecord]);

  const onMarkFavEndUserGroup = selectedGroup => {
    setLoading(true);
    dispatch(
      contactsActions.onPostCustomersEndpoint({
        endpoint: endpoints.customers.markUnMarkFavouriteUserGroup,
        data: [
          {
            groupId: selectedGroup.groupId,
            isFavourite: !selectedGroup.isFavorite,
          },
        ],
      })
    ).then(data => {
      setLoading(false);
      if (!data?.success) {
        toast.error(data?.message);
      } else {
        loadCusGroups();
      }
    });
  };

  return (
    <div
      className='gridBody contentBody businessCustomerGrid'
      style={{ width: "100%" }}>
      {isLoading ? <Spinner /> : null}
      <EndUserGroups
        IsFilterApplied={isFilterApplied}
        PaginationResponse={paginationResponse}
        AppliedFilters={filterModel}
        onApplyFilters={(filters, isFilterApplied) => {
          setFilters(filters);
          if (isFilterApplied) setIsFilterAppling(isFilterApplied);
          else {
            setIsFilterAppling(false);
            setIsFilterApplied(false);
          }
        }}
        ActiveTabId={activeTabId}
        setActiveTabId={setActiveTabId}
        isListView={isListView}
        onToggleListView={isLstView => setIsListView(isLstView)}
        title={"Business Groups"}
      />
      <h2 className='gridInnerHeading'>
        Business Groups
        <span className='gridTopPagination'>
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={[]}
            backIconButtonProps={{ style: { display: "none" } }}
            nextIconButtonProps={{ style: { display: "none" } }}
          />
        </span>
      </h2>

      {isListView ? (
        <>
          <CustomerGroupGrid
            customerGroupsData={businessGroups}
            startLoading={() => setLoading(true)}
            stopLoading={() => setLoading(false)}
            loadData={() => loadCusGroups()}
            order={order}
            onOrderBy={setOrderBy}
            onEditClick={businessGroupEntity => {
              if (businessGroupEntity)
                setEditableRecord({ ...businessGroupEntity });
            }}
            onMarkFavEndUserGroup={onMarkFavEndUserGroup}
            EditableRecord={editableRecord}
            EditAbleRow={
              editableRecord && (
                <AddNewBusinessGroup
                  id='add-new-business-group'
                  businessGroupEntity={editableRecord}
                  onAddUpdate={success => {
                    if (success) {
                      loadCusGroups();
                      setEditableRecord(null);
                    }
                  }}
                  onCancel={() => {
                    setEditableRecord(null);
                  }}
                />
              )
            }
          />
          {isNewBusinessGroup && (
            <AddNewBusinessGroup
              id='add-new-business-group'
              businessGroupEntity={editableRecord}
              onAddUpdate={success => {
                if (success) {
                  loadCusGroups();
                  setEditableRecord(null);
                }
              }}
              onCancel={() => {
                setEditableRecord(null);
                setIsNewBusinessGroup(false);
              }}
            />
          )}
        </>
      ) : (
        <Grid container spacing={2}>
          {businessGroups.map(item => (
            <Grid item md={3} key={item.id}>
              <ListViewTile
                companyname={item?.groupName}
                contacts={
                  item?.totalContacts > 0
                    ? `${item?.totalContacts} Contact${
                        item?.totalContacts > 1 ? "s" : ""
                      }`
                    : "No Contact"
                }
                buttontxt={item.customerType}
                backgroundcolor={item.color}
                textcolor={item.color}
                rating={item?.isFavorite ? <StarFill /> : <StarOutline />}
                onMarkFavourite={() =>
                  onMarkFavEndUserGroup({
                    ...item,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Pagination
        recordCount={paginationResponse?.totalRecords}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export { BusinessGroup };
