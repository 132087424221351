import { softwareUpdatesTypes } from "./softwareUpdatesTypes";

const INITIAL_STATE = {
  compatibleProductsList: [],
  buildData: [],
  compatibleProducts: [],
  selectedRelease: {
    id: 0,
    nameInternal: "",
    namePublic: "",
    releaseId: "",
    previousRelease: "",
    nextRelease: "",
    version: 1,
    releaseStatus: 1,
    isForceUpdate: false,
    numberToForceUpdate: 0,
    typeToForceUpdate: "",
    updateMethodBluetooth: false,
    updateMethodWifi: false,
    buildUrl: "",
    buildFileName: "",
  },
  selectedReleaseNotifications: [],
  notificationTypes: [],
  isLoading: false,
  selectedReleaseProducts: [],
  selectedReleasePortal: [],
  groupIds: [],
  companyIds: [],
  contactIds: [],
  portalIds: [],
  poratlAndContactData: {
    isForceUpdate: false,
    numberToForceUpdate: 0,
    typeToForceUpdate: "",
    updateMethodBluetooth: false,
    updateMethodWifi: false,
  },
  successMessage: "",
  errorMessage: "",
  selectedVersions: [],
  paginationResponse: {},
  productsList: [],
  portalsList: [],
  contactsList: [],
  selectedbuildList: [],
  customerContactData: [],
  selectedReleaseContacts: [],
  softwareReleaseData: {},
  softwarePortalsData: [],
  softwareConatactData: [],
  softwareReleaseDetials: [],
};

const softwareUpdatesReducer = (state, action) => {
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case softwareUpdatesTypes.SOFTWAREUPDATES_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };

    case softwareUpdatesTypes.UPDATE_SELECTED_RELEASE_CONTACTS: {
      const { key, value } = action.payload.prop;
      let updatedContacts = [...state[key], value];
      return {
        ...state,
        errorMessage: "",
        [key]: updatedContacts,
      };
    }

    case softwareUpdatesTypes.ON_REMOVE_SELECTED_PORTAL_CONTACT: {
      const { key, value } = action.payload.prop;
      const newArray = [...state[key].filter((x) => x !== value)];
      return {
        ...state,
        errorMessage: "",
        [key]: newArray,
      };
    }

    case softwareUpdatesTypes.SEARCH_UPDATEPRODUCT: {
      const { search } = action.payload.prop;
      let data = state.compatibleProductsList;

      let filteredSearchProductResults = data.filter((product) =>
        product.productName.toLowerCase().includes(search.toLowerCase())
      );
      return {
        ...state,
        errorMessage: "",
        productsList: filteredSearchProductResults,
      };
    }

    case softwareUpdatesTypes.ON_CHANGE: {
      const { key, value } = action.payload.prop;
      return {
        ...state,
        [key]: value,
      };
    }

    case softwareUpdatesTypes.ON_CHANGE_SELECTED_RELEASE_FIELD: {
      const { propName, value } = action.payload.prop;
      const updatedRelease = { ...state.selectedRelease, [propName]: value };
      return {
        ...state,
        errorMessage: "",
        selectedRelease: updatedRelease,
      };
    }

    case softwareUpdatesTypes.ON_CHANGE_PORTAL_CONTACT_FIELD: {
      const { propName, value } = action.payload.prop;
      const updatedRelease = {
        ...state.poratlAndContactData,
        [propName]: value,
      };
      return {
        ...state,
        errorMessage: "",
        poratlAndContactData: updatedRelease,
      };
    }

    case softwareUpdatesTypes.ON_SOFTWARE_RELEASE_DATA: {
      const { value } = action.payload.prop;

      return {
        ...state,
        errorMessage: "",
        softwareReleaseData: value,
        selectedRelease: {
          id: value.id,
          nameInternal: value.nameInternal,
          namePublic: value.namePublic,
          releaseId: value.releaseId,
          previousRelease: value.previousRelease,
          nextRelease: value.nextRelease,
          version: value.version,
          releaseStatus: value.releaseStatus,
          buildUrl: value.buildUrl,
          buildFileName: value.buildFileName,
        },
        poratlAndContactData: {
          isForceUpdate: value.isForceUpdate,
          numberToForceUpdate: value.numberToForceUpdate,
          typeToForceUpdate: value.typeToForceUpdate,
          updateMethodBluetooth: value.updateMethodBluetooth,
          updateMethodWifi: value.updateMethodWifi,
        },
      };
    }

    case softwareUpdatesTypes.ON_RELEASE_FAVOURITE: {
      const { softwareReleaseId, isFavourite } = action.payload.prop;
      const newVersions = state.selectedVersions.map((version) => {
        if (version.id === softwareReleaseId) {
          return {
            ...version,
            isFavourite: isFavourite,
          };
        } else {
          return version;
        }
      });
      return {
        ...state,
        selectedVersions: newVersions,
      };
    }

    case softwareUpdatesTypes.ON_DELETE_RELEASE_DATA: {
      const { releaseId } = action.payload.prop;
      const newVersions = state.selectedVersions.filter(
        (version) => version.id !== releaseId
      );
      return {
        ...state,
        selectedVersions: newVersions,
      };
    }

    case softwareUpdatesTypes.RESET_SOFTWARE_RELEASE_DATA: {
      return {
        ...state,
        errorMessage: "",
        selectedRelease: {},
        selectedReleasePortal: [],
        selectedReleaseContacts: [],
        selectedReleaseNotifications: [],
        selectedReleaseProducts: [],
        poratlAndContactData: {
          isForceUpdate: false,
          numberToForceUpdate: 0,
          typeToForceUpdate: "",
          updateMethodBluetooth: false,
          updateMethodWifi: false,
        },
        groupIds: [],
        companyIds: [],
        contactIds: [],
        portalIds: [],
        softwareReleaseDetials: [],
      };
    }

    default:
      return state;
  }
};

export default softwareUpdatesReducer;
