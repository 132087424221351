import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashboard from "../Dashbard";
import CollectionCategoryGrid from "./CollectionCategoryGrid";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import CollectionCategoryPopup from "./CollectionCategoryPopup";
import Loader from "../../../../components/common/Loader";
import { useHistory } from "react-router-dom";
import { Pagination } from "../../../../components/common";

function CollectionCategory(props) {
  const [isLoading, setLoading] = useState(false);
  const [addColCategory, setAddColCategory] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0
  });
  const dispatch = useDispatch();

  const history = useHistory();

  const loadAllColCategories = useCallback(() => {
    setLoading(true);
    const colCategeoryParam = {
      name: null,
      noOfCollections: null,
      createdOn: null,
      color: null,
      isPublic: null,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal
      }
    };
    dispatch(assetsActions.onGetColCategories(colCategeoryParam)).then(data => {
      if (data?.success) {
        if (data.data?.data) {
          setPaginationResponse(data?.data?.pagination);
        }
      }
      setLoading(false);
    });
  }, [dispatch, page, rowsPerPage, order.orderByKey, order.orderVal]);

  const redirectUrl = () => {
    history.push("/new-collection-category");
  };

  useEffect(() => {
    loadAllColCategories();
  }, [page, rowsPerPage, order.orderByKey, order.orderVal]);
  const { colCategories } = useSelector(state => state.AssetsReducer);

  return (
    <Dashboard>
      <div className="body-content">
        {isLoading ? <Loader /> : null}
        <div className="contentHeading">
          <h1>Collection Category </h1>
          <Button
            color="primary"
            variant="contained"
            onClick={() => redirectUrl()}
            startIcon={<AddIcon />}>
            New Category
          </Button>
        </div>
        {addColCategory && (
          <CollectionCategoryPopup
            openPopup={addColCategory}
            closePopup={() => setAddColCategory(false)}
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadAllColCategories()}
          />
        )}
        <div className="gridBody contentBody">
          <CollectionCategoryGrid
            colCategoryData={colCategories}
            startLoading={() => setLoading(true)}
            loadData={() => loadAllColCategories()}
            endLoading={() => setLoading(false)}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </div>
    </Dashboard>
  );
}

export default CollectionCategory;
