import React from "react";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import GeneralHeading from "../../../../components/common/GeneralHeading";

function Notes(props) {
  return (
    <>
      <GeneralHeading
        headingName="New Event Category"
        buttonText="Save Category"
      />
            <div className="eventOverviewWrapper">
            <AccordionContainer title="Notes"></AccordionContainer>
            </div>
      
    </>
  );
}

export default Notes;
