import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { ValidatorForm } from "react-material-ui-form-validator";
import { accountActions } from "../../../store/accounts/accountActions";
import InputField from "../../../components/common/InputField";
import Loader from "../../../components/common/Loader";
import { contactsActions } from "../../../store/contacts/contactsActions";
import { AddressAutoComplete } from "../../../components/common";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  resetbtn: {
    position: "absolute",
    top: "41px",
    right: "-54px",
    fontSize: "14px",
    color: "#3699FF",
    textTransform: "capitalize",
    zIndex: "10",
  },
  gridWrapper: {
    position: "relative",
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
  },
  accordianBody: {
    padding: "15px 50px !important",
  },
}));

function AccountSettingInfo(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  let isDisable = [];
  const [isEdit, setIsEdit] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [userAccountInfo, setUserAccountInfo] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [userState, setUserState] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isEditAble, setIsEditAble] = useState([]);

  useEffect(() => {
    dispatch(accountActions.onAddUserSession(window.location.href));
    loadData();
    getCountry();
    getCity();
    getState();
  }, []);

  const getCountry = () => {
    dispatch(contactsActions.onGetCountries()).then(data => {
      if (data && data.success) {
        if (data.data.length > 0) {
          setAllCountries(data.data);
        }
      }
    });
  };

  const getCity = () => {
    dispatch(contactsActions.onGetCities()).then(data => {
      if (data && data.success) {
        if (data.data.length > 0) {
          setAllCities(data.data);
        }
      }
    });
  };
  const getState = () => {
    dispatch(contactsActions.onGetProvinces()).then(data => {
      if (data && data.success) {
        if (data.data.length > 0) {
          setAllStates(data.data);
        }
      }
    });
  };

  const loadData = () => {
    setLoading(true);
    dispatch(accountActions.onGetUserAccountInfo()).then(data => {
      setLoading(false);
      if (data && data.success) {
        setUserAccountInfo(data.data);
        const userData = data.data;
        if (userData) {
          setFirstName(userData.firstName);
          setLastName(userData.lastName);
          setEmail(userData.email);
          setAddress(userData.address);
          setWebsite(userData.website);
          setZipCode(userData.zipCode);
          setCity(userData.cityName);
          setCountry(userData.countryName);
          setUserState(userData.stateName);
          setPhone(
            userData.phoneNumber !== "" && userData.phoneNumber !== null
              ? userData.phoneNumber.includes("-")
                ? userData.phoneNumber.split("-")[1]
                : userData.phoneNumber
              : ""
          );
        }
      }
    });
  };

  const isEditHandler = () => {
    setIsEdit(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateUserInfo();
  };

  const updateUserInfo = () => {
    const userObj = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: `${
        userAccountInfo.phoneNumber !== "" &&
        userAccountInfo.phoneNumber !== null &&
        userAccountInfo.phoneNumber.includes("-")
          ? userAccountInfo.phoneNumber.split("-")[0]
          : ""
      }${phone}`,
      website: website,
      address: address,
      cityId: city,
      stateId: userState,
      countryId: country,
      zipCode: zipCode,
    };
    setLoading(true);
    dispatch(accountActions.onUpdateUserAccountInfo(userObj)).then(data => {
      if (data) {
        if (data.success) {
          setIsEdit(false);
          loadData();
        } else {
          toast.error(data.message);
        }
      }
      setLoading(false);
    });
  };

  return (
    <div className='settingsTabsBody'>
      {isLoading ? <Loader /> : null}
      <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
        <h1>
          Account Information
          <span>
            {isEdit && (
              <Button color='primary' variant='contained' type='submit'>
                save changes
              </Button>
            )}
            {!isEdit && (
              <Button
                color='primary'
                variant='outlined'
                onClick={() => isEditHandler()}>
                edit account info
              </Button>
            )}
          </span>
        </h1>
        <div className='bodyInner'>
          {userAccountInfo ? (
            <ul>
              <li>
                <span className='leftCol'>Your Name</span>
                <span className='rightCol initialName'>
                  {isEdit ? (
                    <>
                      <InputField
                        placeholder='First Name'
                        id='firstname'
                        name='firstname'
                        value={firstName}
                        onChange={v => setFirstName(v.target.value)}
                        validators={[
                          "required",
                          "matchRegexp:^[A-Za-z]+$",
                          "matchRegexp:^.{0,50}$",
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Special characters and space are not allowed",
                          "Maximum length is 50 characters",
                        ]}
                      />
                      <InputField
                        placeholder='Last Name'
                        id='lastName'
                        name='lastName'
                        value={lastName}
                        onChange={v => setLastName(v.target.value)}
                        validators={[
                          "required",
                          "matchRegexp:^[A-Za-z]+$",
                          "matchRegexp:^.{0,50}$",
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Special characters and space are not allowed",
                          "Maximum length is 50 characters",
                        ]}
                      />
                    </>
                  ) : (
                    <>
                      {`${userAccountInfo.firstName}${" "}${
                        userAccountInfo.lastName
                      }`}
                    </>
                  )}
                </span>
              </li>

              <li>
                <span className='leftCol'>Your E-mail/Login</span>
                <span className='rightCol'>
                  {userAccountInfo.email}
                  {isEdit && <i>Contact Aquatic AV to change your E-mail</i>}
                </span>
              </li>
              <li>
                <span className='leftCol'>Your Password</span>
                <span className='rightCol clickHereBtn'>
                  Click <Button color='primary'>Here</Button> to reset your
                  password. Check your e-mail for instructions.
                </span>
              </li>
              <li>
                <span className='leftCol'>Your Company</span>
                <span className='rightCol'>
                  {userAccountInfo.companyName !== ""
                    ? userAccountInfo.companyName
                    : "N/A"}
                  {isEdit && (
                    <i>Contact Aquatic AV to change your Company Name</i>
                  )}
                </span>
              </li>
              <li>
                <span className='leftCol'>Your Phone</span>
                <span className='rightCol'>
                  {isEdit ? (
                    <InputField
                      placeholder='Phone Number'
                      id='phonenumber'
                      name='phonenumber'
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                      validators={[
                        "required",
                        "matchRegexp:^[0-9]*$",
                        "matchRegexp:^.{0,20}$",
                      ]}
                      errorMessages={[
                        "This field is required",
                        "Not valid number",
                        "Maximum length is 20 characters",
                      ]}
                    />
                  ) : (
                    <>
                      {userAccountInfo.phoneNumber !== "" &&
                      userAccountInfo.phoneNumber !== null
                        ? userAccountInfo.phoneNumber.includes("-")
                          ? userAccountInfo.phoneNumber.split("-")[1]
                          : userAccountInfo.phoneNumber
                        : ""}
                    </>
                  )}
                </span>
              </li>
              <li>
                <span className='leftCol'>Your Website</span>
                <span className='rightCol'>
                  {isEdit ? (
                    <InputField
                      placeholder='www.website.com'
                      id='website'
                      name='website'
                      value={website}
                      onChange={e => setWebsite(e.target.value)}
                      validators={["matchRegexp:^.{0,200}$"]}
                      errorMessages={["Maximum length is 200 characters"]}
                    />
                  ) : (
                    <>
                      {userAccountInfo.website !== ""
                        ? userAccountInfo.website
                        : "N/A"}
                    </>
                  )}
                </span>
              </li>
              <li>
                <span className='leftCol'>Street name</span>
                <span className='rightCol'>
                  {isEdit ? (
                    //
                    <>
                      <label className='envAutoLocLable'>Address</label>
                      <AddressAutoComplete
                        address={address}
                        autoComId={"popupEnvLocInput"}
                        onChange={e => {
                          setAddress(e.target.value);
                        }}
                        setPlace={p => {
                          isDisable = [];
                          setCity("");
                          setUserState("");
                          setCountry("");
                          setZipCode("");
                          const val =
                            document.getElementById("popupEnvLocInput")?.value;

                          p?.address_components?.forEach(x => {
                            if (x?.types?.includes("route")) {
                              setAddress(x?.long_name);
                            }
                            if (x?.types?.includes("locality")) {
                              setCity(x?.long_name);
                              isDisable.push("city");
                            }
                            if (
                              x?.types?.includes("administrative_area_level_1")
                            ) {
                              setUserState(x?.long_name);
                              isDisable.push("state");
                            }
                            if (x?.types?.includes("country")) {
                              setCountry(x?.long_name);
                              isDisable.push("country");
                            }
                            if (x?.types?.includes("postal_code")) {
                              setZipCode(x?.long_name);
                              isDisable.push("postalCode");
                            }
                          });
                          setIsEditAble(isDisable);
                        }}
                      />
                    </>
                  ) : (
                    <>{`${userAccountInfo.address}${
                      userAccountInfo.address !== "" && ", "
                    }${userAccountInfo.cityName}${
                      userAccountInfo.cityName !== "" && ", "
                    }${userAccountInfo.stateName}${
                      userAccountInfo.stateName !== "" && ", "
                    }${userAccountInfo.zipCode}${
                      userAccountInfo.zipCode !== "" &&
                      userAccountInfo.countryName !== "" &&
                      ", "
                    }${userAccountInfo.countryName}`}</>
                  )}
                </span>
              </li>

              {isEdit && (
                <>
                  <li>
                    <span className='leftCol'>City</span>
                    <span className='rightCol'>
                      <FormControl className='formControl'>
                        <InputField
                          placeholder='Enter City'
                          inputlabel='City'
                          validators={["required"]}
                          errorMessages={["Please enter city"]}
                          value={city}
                          onChange={v => setCity(v.target.value)}
                          // disabled={isEditAble.includes("city") ? true : false}
                        />
                      </FormControl>
                    </span>
                  </li>
                  <li>
                    <span className='leftCol'>State/Province</span>
                    <span className='rightCol'>
                      <FormControl className='formControl'>
                        <InputField
                          placeholder='Enter State'
                          inputlabel='State'
                          validators={["required"]}
                          errorMessages={["Please enter state"]}
                          value={userState}
                          onChange={v => setUserState(v.target.value)}
                          // disabled={isEditAble.includes("state") ? true : false}
                        />
                      </FormControl>
                    </span>
                  </li>
                  <li>
                    <span className='leftCol'>Country</span>
                    <span className='rightCol'>
                      <FormControl className='formControl'>
                        <InputField
                          placeholder='Enter country or region'
                          inputlabel='Country or region'
                          validators={["required"]}
                          errorMessages={["Please enter country or region"]}
                          value={country}
                          onChange={v => setCountry(v.target.value)}
                          // disabled={
                          //   isEditAble.includes("country") ? true : false
                          // }
                        />
                      </FormControl>
                    </span>
                  </li>
                  <li>
                    <span className='leftCol'>Zip code/Postal code</span>
                    <span className='rightCol'>
                      <InputField
                        placeholder='Zip code'
                        id='zipCode'
                        name='zipCode'
                        value={zipCode}
                        onChange={e => {
                          setZipCode(e.target.value);
                        }}
                        validators={["matchRegexp:^.{0,15}$"]}
                        errorMessages={["Maximum length is 15 characters"]}
                        // disabled={
                        //   isEditAble.includes("postalCode") ? true : false
                        // }
                      />
                    </span>
                  </li>{" "}
                </>
              )}

              <li>
                <span className='leftCol'>Account Type</span>
                <span className='rightCol'>
                  {userAccountInfo.groupName !== null
                    ? userAccountInfo.groupName
                    : ""}
                </span>
              </li>
            </ul>
          ) : null}
        </div>
      </ValidatorForm>
    </div>
  );
}

export default AccountSettingInfo;
