import React, { useState, useEffect, useCallback } from "react";
import Dashbard from "../Dashbard";
import { Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import GeneralSettings from "./LocatorWidget/GeneralSettings";
import Embed from "./LocatorWidget/Embed";
import Places from "./LocatorWidget/Places";
import LocatorDesign from "./LocatorWidget/LocatorDesign";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/common/Loader";
import SelectInput from "../../../../components/common/SelectInput";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { endUserActions } from "../../../../store/endUser/endUserActions";

const handleSubmit = (e) => {
  e.preventDefault();
};

function LocatorWidget(props) {
  const dispatch = useDispatch();
  const [activeTabId, setActiveTabId] = useState("3");
  const [isLoading, setLoading] = useState(false);
  const [endUserList, setEndUserList] = useState([]);
  const [filterByName, setFilterByName] = useState("");
  const [selectedDate, setSelectedDate] = useState(0);
  const [selectedCategoryId, setSelectedCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [endUserGroup, setEndUserGroup] = useState([]);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [isDelete, setIsDelete] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });

  const checkGroupsSelected = (item) => {
    endUserList.map((x) => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    var count = endUserList.find((x) => x.checked === true);
    setIsDelete(count ? true : false);
    setEndUserList([...endUserList], endUserList);
  };

  const loadData = useCallback(() => {
    const modal = {
      firstName: filterByName,
      lastName: "",
      lastLogin: null,
      deviceRegistered: 0,
      rating: null,
      companyName: null,
      date: selectedDate,
      groupIds: selectedCategoryId,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    setLoading(true);
    dispatch(contactsActions.onGetEndUserList(modal)).then((data) => {
      if (data && data.success) {
        if (data.data?.data) {
          const endUsers = data.data.data.map((x, index) => {
            return {
              id: x.id,
              deviceRegistered: x.deviceRegistered,
              firstName: x.firstName,
              lastLogin: x.lastLogin,
              lastName: x.lastName,
              location: x.location,
              rating: x.rating,
              userId: x.userId,
              checked: false,
              groupName: x.groupName,
              lastLoginDT: x.lastLoginDT,
              groupColor: x.groupColor,
            };
          });
          setPaginationResponse(data?.data?.pagination);
          setEndUserList(endUsers);
        }
        setIsDelete(false);
      }
      setLoading(false);
    });
  }, [
    dispatch,
    filterByName,
    selectedCategoryId,
    selectedDate,
    order.orderByKey,
    order.orderVal,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    setActiveTabId("3");
    loadData();
    dispatch(endUserActions.onGetUserGroupDD()).then((data) => {
      if (data && data.success) {
        const list = data?.data.map((x, index) => {
          return {
            id: x.id,
            value: x.value,
            generic: x.generic,
            checked: false,
          };
        });
        setEndUserGroup(list);
      }
    });
  }, [dispatch, loadData, page, rowsPerPage]);

  return (
    <div>
      <Dashbard>
        <div className="body-content">
          {isLoading ? <Loader /> : null}
          <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
            <div className="contentHeading">
              <div className="newsPortalHeaderWrapper">
                <h1>Locator Widget</h1>
              </div>
              <div className="dashboardNav">
                {/* <Button
                  color="primary"
                  variant="contained"
                  className="dashboardButtons"
                >
                  Save Settings
                </Button> */}
              </div>
            </div>
            <div className="tab-navigator ">
              <TopTabNavigator
                getActiveTabId={(tabId) => setActiveTabId(`${tabId}`)}
                tabs={[
                  {
                    id: "1",
                    title: "General Settings",
                    component: [
                      <GeneralSettings
                        startLoading={() => setLoading(true)}
                        stopLoading={() => setLoading(false)}
                      />,
                    ],
                  },
                  {
                    id: "2",
                    title: "Locator Design",
                    component: [
                      <LocatorDesign
                        startLoading={() => setLoading(true)}
                        stopLoading={() => setLoading(false)}
                      />,
                    ],
                  },
                  {
                    id: "3",
                    title: "Places Listed",
                    component: [
                      <Places
                        Categories={endUserGroup}
                        setFilterByName={setFilterByName}
                        setSelectedCategory={setSelectedCategory}
                        setSelectedDate={setSelectedDate}
                        endUserList={endUserList}
                        selectedRecord={(item) => {
                          checkGroupsSelected(item);
                        }}
                        isDelete={isDelete}
                        order={order}
                        onOrderBy={setOrderBy}
                        startLoading={() => setLoading(true)}
                        stopLoading={() => setLoading(false)}
                        recordCount={paginationResponse?.totalRecords}
                        page={page - 1}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        loadData={() => loadData()}
                      />,
                    ],
                  },
                  {
                    id: "4",
                    title: "Embed/API",
                    component: [
                      <Embed
                        startLoading={() => setLoading(true)}
                        stopLoading={() => setLoading(false)}
                      />,
                    ],
                  },
                ]}
              />
            </div>
          </ValidatorForm>
        </div>
      </Dashbard>
    </div>
  );
}

export default LocatorWidget;
