import React from "react";

import { ReactComponent as MarkFavStar } from "../../../resources/images/MarkFavStar.svg";
import { ReactComponent as Star } from "../../../resources/images/Star.svg";

const MarkFavButton = ({ isFav, markFav }) => {
  return (
    <span>
      {isFav ? (
        <Star
          style={{
            cursor: "pointer",
            padding: "0 5px",
          }}
          onClick={() => {}}
        />
      ) : (
        <MarkFavStar
          style={{
            cursor: "pointer",
            padding: "0 5px",
          }}
          onClick={() => {}}
        />
      )}
    </span>
  );
};

export { MarkFavButton };
