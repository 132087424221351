import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Chip,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  InputLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { MdClear } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { MenuProps } from "../../../../components/common/utils";
import { ValidatorForm } from "react-material-ui-form-validator";
import { searchIcon, crossImg } from "../../../../resources/images";
import { useDebounce } from "../../../../utils/use-debounce";
import InputField from "../../../../components/common/InputField";
import { FilterArrow } from "../../LeftNav/SvgIcons";
import { Autocomplete } from "@material-ui/lab";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { Dropdown } from "../../../../components/common";
import { DateCreated } from "../../../../config/Enums";
import { filter } from "lodash";
import { fi } from "date-fns/locale";
import { locationsActions } from "../../../../store/locations/locationsActions";

const ChipItem = ({ chipLabel, chipValue, onDelete }) => {
  const classes = useStyless();

  return (
    <Chip
      className={classes.MuiChip}
      deleteIcon={<MdClear style={{ width: "16px", height: "16px" }} />}
      label={
        <span className={classes.MuiChip}>
          <b>{chipLabel}: </b>
          {chipValue}
        </span>
      }
      onDelete={onDelete}
    />
  );
};

const useStyless = makeStyles((theme) => ({
  gridWrapper: {
    paddingTop: "29px",
    paddingBottom: "20px",
    width: "100%",
    borderBottom: "1px solid #E2E6E9",
    position: "relative",
    "& .MuiGrid-container": {
      padding: "unset",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  MuiChip: {
    backgroundColor: "#CDE5FF",
    color: "#70808F",
    maxWidth: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    margin: "0px 5px 5px 0px",
  },
  chip: {
    margin: 2,
  },
  dropdownBg: {
    background: "#f1f1f1",
  },
  formControl: {
    minWidth: "100%",
    background: "#f3f6f9",
    '& .MuiInputLabel-shrink':{
        display:"none",
    },
    '& .MuiInputLabel-formControl': {
        top: "-12px",
        left: "8px",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
    },
    '& .MuiFilledInput-root':{
        backgroundColor:'unset',
    },
    '& .MuiFilledInput-underline:hover:before':{
        borderBottom:"unset",
    },
    '& .MuiFilledInput-underline:before':{
        transition:"unset",
    },
    '& .MuiFormLabel-root':{
        color:"#010114 !important"
    }
  },
  selectEmpty: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    // backgroundColor: "#F3F6F9",
    width: "100%",
    borderRadius: "6px",
    "&:before": {
      borderBottom: "1px solid #F3F6F9",
      "&:hover": {
        borderBottom: "0 !important",
      },
    },
    "&:after": {
      borderBottom: "1px solid #F3F6F9",
    },
    '& .MuiInputBase-input':{
      border:"unset !important",
    },
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },

  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9",
      },
    },
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px",
  },
}));

const handleSubmit = (e) => {
  e.preventDefault();
};

export const GridLocationSideFilter = () => {
  const classes = useStyless();
  const dispatch = useDispatch();

  const [nameInternal, setNameInternal] = useState("");
  const [namePublic, setNamePublic] = useState("");
  const [dateCreated, setDateCreated] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showFilterCounter, setShowFilterCounter] = useState(0);

  const showFilter = () => {
    window.scroll(0, 0);
    setIsFilter(true);
  };
  const setSearch = (value) => {
    setNameInternal(value);
    setNamePublic(value);
  };

  const onPressFilterBtn = () => {
    dispatch(locationsActions.onChange("nameInternal", nameInternal));
    dispatch(locationsActions.onChange("namePublic", namePublic));
    dispatch(locationsActions.onChange("dateCreated", dateCreated));
    setShowFilterCounter(filterCount);
    hideFilter();
  };
  const onPressClear = () => {
    setNameInternal("");
    setNamePublic("");
    setDateCreated(null);
    setFilterCount(0);
    onPressFilterBtn();
  };
  const hideFilter = () => {
    setIsFilter(false);
  };

  return (
    <div className={classes.gridWrapper}>
      <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            className="filterMainSearch endUserSearchFilterMain"
          >
            <img src={searchIcon} alt="" className="searchIcon" />
            <InputField
              placeholder="Search"
              value={nameInternal}
              onChange={(v) => {
                setSearch(v.target.value);
                dispatch(
                  locationsActions.onChange("nameInternal", v.target.value)
                );
                dispatch(
                  locationsActions.onChange("namePublic", v.target.value)
                );
              }}
              onPaste={(value) => {
                const splitValue = value.split("\n");
                setSearch(splitValue.join(", "));
              }}
            />
            <span className="endUserSearchFilter" onClick={showFilter}>
              {showFilterCounter ? (
                <span>{`${showFilterCounter} Filter${
                  showFilterCounter > 1 ? "(s)" : ""
                } Applied `}</span>
              ) : null}
              <FilterArrow color={"#fff"} />
            </span>
          </Grid>
        </Grid>

        {isFilter && (
          <div className="endUserFilterWrapper">
            <div className="endUserFilterInnerWrapper">
              <img
                className="filterCrossImage"
                src={crossImg}
                alt="cross icon"
                onClick={hideFilter}
              />
              <div className="formContainer">
                <h3 className="endUserFilterHeading">Location Filters</h3>
                <Accordion
                  defaultExpanded
                  id="end-user-filter-expandable"
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>
                      <span>Active Filter(s)</span>
                      <span>
                        <Button
                          className="filterClearBtn filterClearBtnStyle"
                          variant="outlined"
                          onClick={() => {
                            onPressClear();
                            setFilterCount(0);
                          }}
                        >
                          Clear
                        </Button>
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <div>
                    {dateCreated !== null && (
                      <ChipItem
                        chipLabel="Time "
                        chipValue={
                          dateCreated === 0
                            ? "All Time"
                            : dateCreated === 1
                            ? "Within Month"
                            : dateCreated === 2
                            ? "Within Past Month"
                            : dateCreated === 3
                            ? "Within Past 3 Month"
                            : dateCreated === 4
                            ? "Within Past 6 Month"
                            : dateCreated === 5
                            ? "Within Past Year"
                            : null
                        }
                        onDelete={() => {
                          setDateCreated(null);
                          setFilterCount(filterCount - 1);
                        }}
                      />
                    )}
                  </div>
                  {filterCount === 0 && (
                    <div className={`filtersChips`}>No Active Filter(s)</div>
                  )}
                </Accordion>

                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    marginTop: "7px",
                  }}
                >
                  <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Select Time Period</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={dateCreated}
                      placeholder="Select Date"
                      onChange={(e) => {
                        setDateCreated(e.target.value);
                        if (dateCreated === null) {
                          setFilterCount(filterCount + 1);
                        }
                      }}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"filled"}
                      required
                    >
                      <MenuItem value="" disabled>
                        All Uploads
                      </MenuItem>
                      <MenuItem value={0}>All Uploads</MenuItem>
                      <MenuItem value={1}>Within Last Month</MenuItem>
                      <MenuItem value={2}>Within Past 3 Months</MenuItem>
                      <MenuItem value={3}>Within Past 6 Months</MenuItem>
                      <MenuItem value={4}>Within Past Last Year</MenuItem>
                    </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div className="endUserfilrerBtn">
                <Button
                  variant="contained"
                  id="test-button-44"
                  onClick={() => {
                    onPressFilterBtn();
                  }}
                >
                  Apply Filter
                </Button>
              </div>
            </div>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
};
