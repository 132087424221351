import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import EndUserActivityGrid from "./EndUserActivityGrid";

const useStyles = makeStyles(theme => ({
  activityButton: {
    backgroundColor: "#E5F2FF",
    padding: "8px 35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#E5F2FF",
    },
  },
}));

//#region Helper Methds
const isTodayDate = checkDate =>
  new Date(checkDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

export const stringToColor = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 3) - hash);
  }
  const color = Math.abs(hash).toString(16).substring(0, 6);

  return "#" + "1BC5BD".substring(0, 6 - color.length) + color;
};
//#endregion

function CustomerActivity({ isActive, isOverview }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [userId, setUserId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [allActivities, setAllActivities] = useState([]);

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (isActive) {
      if (selectedUser?.id) {
        setUserId(selectedUser.id);
        loadActivity(selectedUser.id);
      }
    }
  }, [location, isActive]);

  const loadActivity = userId => {
    const noOfRecord = isOverview ? 4 : 100;
    setLoading(true);
    dispatch(endUserActions.onGetUserActivity(userId, noOfRecord)).then(
      data => {
        if (data && data.success) {
          setAllActivities(data.data);
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
        setLoading(false);
      }
    );
  };

  const classes = useStyles();
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <div className='customerContactInner'>
        <div className='customerContactHeader'>
          John Doe’s Activity Log
          <span>
            <Button color='primary' variant='contained'>
              Clear
            </Button>
          </span>
        </div>
        {allActivities &&
          allActivities.length > 0 &&
          allActivities.map((item, index) => {
            return (
              <div key={index} className='activityBody'>
                <span className='activityDate'>
                  <span
                    className='circle'
                    style={{
                      border: `4px solid ${
                        !item.action
                          ? "#F64E60"
                          : stringToColor(
                              `${index}${moment(item.actionDate)
                                .format("HH:mm A")
                                .toString()}`
                            )
                      }`,
                    }}
                  />
                  {isTodayDate(item.actionDate)
                    ? moment(item.actionDate).format("hh:mm A")
                    : moment(item.actionDate).format("MMMM DD, YYYY")}
                </span>
                <span className='activityDesc'>{item.activity}</span>
              </div>
            );
          })}

        {/* <div className='activityBody'>
          <span className='activityDate'>
            <span className='circle' style={{ border: "4px solid #1BC5BD" }} />{" "}
            08:42 PM
          </span>
          <span className='activityDesc'>
            Customer viewed <i>Pro Sport Series</i> News Release
          </span>
        </div> */}
        {/* <div className='activityBody'>
          <span className='activityDate'>
            <span className='circle' style={{ border: "4px solid #F64E60" }} />{" "}
            January
            <br /> 1, 2021
          </span>
          <span className='activityDesc'>
            Customer viewed <i>Pro Sport Series</i> News Release
          </span>
        </div> */}
        {/* <div className='activityBody'>
          <span className='activityDate'>
            <span className='circle' style={{ border: "4px solid #49B8EF" }} />{" "}
            January
            <br /> 1, 2021
          </span>
          <span className='activityDesc'>
            Customer viewed <i>Pro Sport Series</i> News Release
          </span>
        </div> */}
      </div>

      {/* <EndUserActivityGrid /> */}
    </div>
  );
}

export default CustomerActivity;
