import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../../../components/common/InputField";
import Grid from "@material-ui/core/Grid";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { locatorWidgetActions } from "../../../../../store/locatorWidget/locatorWidgetActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SettingsOutlined } from "@material-ui/icons";
import { Checkbox, Switch, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  textArea: {
    minHeight: "470px",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function GeneralSettings({ startLoading, stopLoading }) {
  const dispatch = useDispatch();
  const [id, setId] = useState(1);
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [status, setStatus] = useState(true);
  const [isError, setIsError] = useState(false);

  const loadData = () => {
    startLoading();
    dispatch(locatorWidgetActions.onGetSettings(id)).then((cnt) => {
      if (cnt && cnt.success && cnt.data) {
        setId(cnt.data.id);
        setTitle(cnt.data.title);
        setSubTitle(cnt.data.subtitle);
        setStatus(cnt.data.locatorStatus);
      } else {
        toast.error(cnt?.message);
      }
      stopLoading();
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onUpdateSettings = () => {
    startLoading();
    dispatch(
      locatorWidgetActions.onSaveSettings(id, title, subtitle, status)
    ).then((data) => {
      if (data && data.success) {
        toast.success("Settings successfully updated");
      } else {
        toast.error(data?.message);
      }
      stopLoading();
    });
  };

  const classes = useStyles();
  return (
    <div className="accordianOuterWrapper locatorSettings">
      <AccordionContainer title="General Settings">
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={9} className={classes.gridWrapper}>
              <InputField
                placeholder="Dealer Locator"
                inputlabel="Title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={9} className={classes.gridWrapper}>
              <InputField
                placeholder="Use our dealer locator to find an authorized Aquatic AV reseller near you."
                inputlabel="Subtitle"
                name="subtitle"
                value={subtitle}
                onChange={(e) => setSubTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={9} className={classes.gridWrapper}>
              <label className="switchLabel">Locator Status</label>
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
              />
            </Grid>
          </Grid>
        </div>
        <span className="saveChangesBtn">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onUpdateSettings()}
          >
            Save Changes
          </Button>
        </span>
      </AccordionContainer>
    </div>
  );
}
