import React from "react";
import SearchIcon from "@material-ui/icons/Search";

function InnerHeader({
  picture,
  heading,
  text,
  searchPage,
  onChange,
  value,
  textPlace
}) {
  const enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      searchPage();
    }
  };
  return (
    <div className="innerHeaderWrapper">
      <div className="headerContent">
        <h1>{heading}</h1>
        <p>{text}</p>

        <span className="searchBar">
          <SearchIcon onClick={searchPage} />
          <input
            type="text"
            placeholder={textPlace}
            onKeyPress={e => enterPressed(e)}
            onChange={onChange}
            value={value}
          />
        </span>
      </div>
      <div className="releasePicture">
        <img src={picture} alt="" />
      </div>
    </div>
  );
}

export default InnerHeader;
