import React, { useEffect, useState, useCallback } from "react";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import debounce from "lodash.debounce";
import DateFnsUtils from "@date-io/date-fns";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../components/common/InputField";
import EmptyTextarea from "../../../../components/common/Textarea";
import AutoComplete from "../../../../components/common/AutoComplete";
import { Button, FormControl, FormHelperText } from "@material-ui/core";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import {
  Dropdown,
  ImageUploader,
  LaunchState,
} from "../../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import ReleaseContent from "./ReleaseContent";
import CustomCheckbox from "../../../../components/common/checkbox/CustomCheckbox";
import { useHistory } from "react-router-dom";
import { ReleaseVisabilityGroup } from "../ReleasesViews/ReleaseVisabilityGroup";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Add } from "@material-ui/icons";
import {
  MultiSelectReleaseCategories,
  ReleaseCategoryChip,
} from "../ReleasesViews/MultiSelectReleaseCategories";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  dateLabel: {
    fontSize: "15px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
    minHeight: "19px",
    display: "inline-block",
  },
}));

const NewEventForm = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activeEventId, onEventName } = props;

  const [isLoading, setLoading] = useState(false);
  const [avatarImg, setAvatarImG] = useState(null);
  const { eventCategory } = useSelector(state => state.LaunchesReducer);
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [showBannerImage, setShowBannerImage] = useState(false);

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [eventURL, setEventURL] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [description, setDiscription] = useState("");
  // const [selectedCategoryId, setSelectedCategory] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState(new Date());
  const [isEndDateEnable, setIsEndDateEnable] = useState(false);
  const [eventLocation, setEventLocation] = useState([]);
  const [eventLocationValue, setEventLocationValue] = useState("");
  const [newsRoomVisibilityId, selectReleaseNewsRoomVisibility] = useState("");
  const [releaseVisibilityGroups, setReleaseVisibilityGroups] = useState([]);
  const [preReleaseVisibilityGroups, setPreReleaseVisibilityGroups] = useState(
    []
  );
  const [eventType, setEventType] = useState([]);
  const [selectedEventTypeId, setSelectedEventTypeId] = useState("1");
  const [eventStatusId, setEventStatusId] = useState(1);
  const [releaseContent, setReleaseContent] = useState("");
  const [featureImgUpload, setFeatureImgUpload] = useState(null);
  const [bannerImgUpload, setBannerImgUpload] = useState(null);

  const [isAddReleaseCategory, setIsAddReleaseCategory] = useState(false);
  const [slectedCategories, setSlectedCategories] = useState([]);
  const [mainCategory, setMainCategory] = useState(null);

  const [errorMsg, setErrorMsg] = useState(false);
  const [visibilityGroups, setVisibilityGroups] = useState([]);
  const [eventEditData, setEventEditData] = useState(null);
  const [isPlanning, setIsPlanning] = useState(null);
  const [allDay, setAllDay] = useState(false);
  const [selectedLaunchId, setSelectedLaunch] = useState("1");
  const [relatedLaunches, setRelatedLaunches] = useState([]);
  const [isPrereleaseValidDate, setIsPrereleaseValidDate] = useState(
    new Date()
  );

  let stDate = new Date(startDate);
  const startTargetTime = new Date(startTime);
  stDate.setHours(startTargetTime.getHours());
  stDate.setMinutes(startTargetTime.getMinutes());
  let edDate = "";
  if (allDay) {
    edDate = new Date(new Date(startDate).setHours(11, 59));
  } else {
    edDate = new Date(endDate);
    const endTargetTime = new Date(endTime);
    edDate.setHours(endTargetTime.getHours());
    edDate.setMinutes(endTargetTime.getMinutes());
  }

  let entDate = new Date(eventDate);
  const eventTargetTime = new Date(eventTime);
  entDate.setHours(eventTargetTime.getHours());
  entDate.setMinutes(eventTargetTime.getMinutes());

  const hasValidPreRelease = preReleaseVisibilityGroups?.some(group => {
    const preReleaseDate = group?.preReleaseDate;
    return preReleaseDate?.getTime() > stDate?.getTime();
  });

  const checkValidDatePicker = ()=>{
    if(stDate.getTime() > edDate.getTime()){
      return true;
    }else if (stDate.getTime() > entDate.getTime()){
      return true;
    }else if (isEndDateEnable && entDate.getTime() >= edDate.getTime()){
    return true;
    }else if(isPrereleaseValidDate && isPrereleaseValidDate.getTime() >= stDate.getTime()){
      return true;
     }else if( hasValidPreRelease ){
      return true
     }else{ 
    return false;
  }}

  const loadGroups = useCallback(() => {
    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      setVisibilityGroups(data?.data);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(launchesActions.onGetEventType()).then(res => {
      if (res && res.success && res.data) {
        setEventType(res.data);
      }
    });
    dispatch(launchesActions.onGetEventsCategory());
  }, []);

  useEffect(() => {
    dispatch(launchesActions.onGetDropDownLaunches()).then(data => {
      if (data?.success && data?.data) {
        setRelatedLaunches(data?.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  useEffect(() => {
    if (
      visibilityGroups &&
      visibilityGroups.length > 0 &&
      eventEditData?.groupId?.length > 0
    ) {
      setReleaseVisibilityGroups(
        eventEditData.groupId.map(x => {
          return {
            name: visibilityGroups.find(y => y.id == x)?.value,
            VisibilityGroupId: x,
          };
        })
      );
    }
    if (
      visibilityGroups &&
      visibilityGroups.length > 0 &&
      eventEditData?.preReleaseData?.length > 0
    ) {
      setPreReleaseVisibilityGroups(
        eventEditData.preReleaseData.map(x => {
          var groups = [];
          x.groupId.map(y =>
            groups.push({
              name: visibilityGroups.find(z => z.id === y.toString())?.value,
              VisibilityGroupId: y,
            })
          );
          return {
            groupList: groups,
            preReleaseDate: new Date(x.preReleaseDate),
          };
        })
      );
    }
  }, [visibilityGroups, eventEditData]);

  const changeHandler = event => {
    setLoading(true);
    dispatch(
      launchesActions.onGetLocationsAutoComplete({
        input: event.target.value,
      })
    ).then(res => {
      setEventLocation(res?.data || []);
      setLoading(false);
    });
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  useEffect(() => {
    if (activeEventId) {
      setLoading(true);
      dispatch(launchesActions.onGetEventById(activeEventId)).then(res => {
        if (res && res.success && res.data) {
          setEventEditData(res.data);
          setTitle(res.data.title);
          setSubtitle(res.data.subTitle);
          setEventURL(res.data.eventURL);
          setFacebookUrl(res.data.fburl);
          setDiscription(res.data.description);
          setSlectedCategories(
            res.data?.eventCategoryIds?.map(r => r?.categoryId)
          );
          setMainCategory(
            res.data?.eventCategoryIds?.find(r => r?.isMain === true)
              ?.categoryId
          );
          setStartDate(new Date(res.data.startDate));
          setStartTime(new Date(res.data.startDate));
          setEndDate(new Date(res.data.endDate));
          setEndTime(new Date(res.data.endDate));
          if (res.data.endDate) {
            setIsEndDateEnable(true);
          }
          setEventDate(new Date(res.data.visibilityDate));
          setEventTime(new Date(res.data.visibilityDate));
          setFeatureImgUpload(res.data?.image || "");
          setBannerImgUpload(res.data?.bannerImage || "");
          setAvatarImG(res.data.image);
          setEventStatusId(res.data.eventStatusId);
          setIsPlanning(res.data.eventStatusId);
          setAllDay(res.data.isAllDay);
          setReleaseContent(res.data.eventContent);
          setSelectedEventTypeId(res.data.eventTypeId?.toString());
          setEventLocationValue({
            id: res.data?.locationId,
            value: res.data?.locationName,
          });
          onEventName && onEventName(res.data.title);
          setSelectedLaunch(`${res.data?.launchId}`);
        }
        setLoading(false);
      });
    } else {
      setEventEditData(null);
      setTitle("");
      setSubtitle("");
      setEventURL("");
      setFacebookUrl("");
      setDiscription("");
      setStartDate(new Date());
      setStartTime(new Date());
      setEndDate(new Date());
      setEndTime(new Date());
      setEventDate(new Date());
      setEventTime(new Date());
      setAvatarImG(null);
      setEventStatusId(1);
      setIsPlanning(null);
      setAllDay(false);
      setReleaseContent("");
      setSelectedEventTypeId("1");
      setEventLocationValue("");
    }
  }, [activeEventId, dispatch]);

  const uploadFeatureImg = value => {
    if (value.length > 0) {
      setLoading(true);
      dispatch(launchesActions.uploadImage(value)).then(data => {
        if (data && data.success && data.data) {
          setFeatureImgUpload(data.data);
          setAvatarImG(data.data.url ? data.data.url : "");
          setShowFeatureImage(false);
        }
        setLoading(false);
      });
    }
  };

  const onAddUpdateEvent = e => {
    e.preventDefault();
    if (
      title !== "" &&
      // selectedCategoryId > 0 &&
      selectedEventTypeId > 0 &&
      eventLocationValue?.id &&
      eventURL.length < 200 &&
      facebookUrl.length < 200 &&
      description.length < 1000
    ) {
      if (!featureImgUpload) {
        toast.error("Feature image is required");
        return;
      }
      if (slectedCategories?.length === 0 || !mainCategory) {
        toast.error("Main event category is required");
        return;
      }
      setLoading(true);
      const categoryIds = slectedCategories?.map(sCid => {
        return {
          categoryId: parseInt(sCid),
          isMain: sCid === mainCategory,
        };
      });

      let stDate = new Date(startDate);
      const startTargetTime = new Date(startTime);
      stDate.setHours(startTargetTime.getHours());
      stDate.setMinutes(startTargetTime.getMinutes());
      let edDate = "";
      if (allDay) {
        edDate = new Date(new Date(startDate).setHours(11, 59));
      } else {
        edDate = new Date(endDate);
        const endTargetTime = new Date(endTime);
        edDate.setHours(endTargetTime.getHours());
        edDate.setMinutes(endTargetTime.getMinutes());
      }
      let entDate = new Date(eventDate);
      const eventTargetTime = new Date(eventTime);
      entDate.setHours(eventTargetTime.getHours());
      entDate.setMinutes(eventTargetTime.getMinutes());

      var groupId = [];
      releaseVisibilityGroups.map(x => {
        groupId.push(x.VisibilityGroupId);
      });
      var preReleaseData = [];
      preReleaseVisibilityGroups.map(x => {
        var groupId = [];
        x.groupList.map(y => groupId.push(y.VisibilityGroupId));
        preReleaseData.push({
          groupId: groupId,
          preReleaseDate: moment(x.preReleaseDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        });
      });
      var modal = {
        id: activeEventId ? parseInt(activeEventId) : 0,
        title: title,
        subTitle: subtitle,
        eventURL: eventURL,
        fburl: facebookUrl,
        description: description,
        // eventCategoryId: selectedCategoryId,
        eventCategoryIds: categoryIds,
        eventStatusId: isPlanning,
        groupId: groupId,
        locationId: eventLocationValue?.id
          ? parseInt(eventLocationValue?.id)
          : 0,
        startDate: moment(stDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(edDate).format("YYYY-MM-DD HH:mm:ss"),
        visibilityDate: moment(entDate).format("YYYY-MM-DD HH:mm:ss"),
        preReleaseData: preReleaseData,
        image: featureImgUpload ? featureImgUpload?.url : "",
        bannerImage: bannerImgUpload ? bannerImgUpload?.url : "",
        isDeleted: false,
        eventContent: releaseContent,
        isAllDay: allDay,
        eventTypeId: selectedEventTypeId,
        launchId: parseInt(selectedLaunchId),
      };
      dispatch(launchesActions.onAddUpdateEvent(modal)).then(data => {
        if (data && data.success) {
          if (window.location.pathname === "/launch") {
            if (modal.id > 0) {
              toast.success("Event Updated");
              history.goBack();
            } else {
              toast.success("Event Added");
              history.goBack();
            }
          } else {
            if (activeEventId) {
              toast.success("Event updated");
              history.goBack();
            } else {
              history.push("/dashboard-events");
              toast.success("Event added");
            }
          }
        } else if (data?.message) {
          toast.warning(data?.message);
        }
        setLoading(false);
      });
    } else {
      if (!eventLocationValue?.id) {
        //toast.error("invalid location");
      }
      setErrorMsg(true);
    }
  };

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <ValidatorForm onSubmit={onAddUpdateEvent}>
        <AccordionContainer
          title='Event Overview'
          className='EventOverview'
          formButton={
            <div className='dashboardNav'>
              <LaunchState
                value={isPlanning === 2 ? 1 : isPlanning === 1 ? 0 : 2}
                onChange={status => {
                  setIsPlanning(status === 1 ? 2 : 1);
                }}
              />
              <Button
                color='primary'
                variant='contained'
                className='dashboardButtons'
                disabled={checkValidDatePicker()}
                type='submit'>
                {activeEventId ? "Update Event" : "Save Event"}
              </Button>
            </div>
          }>
          <Grid container spacing={3}>
            <Grid item xs={11} className={classes.gridWrapper}>
              <InputField
                showRequired
                placeholder='Enter Event Title'
                inputlabel='Event Title'
                validators={["required"]}
                errorMessages={["Please enter event title"]}
                value={title}
                onChange={v => setTitle(v.target.value)}
              />
              {errorMsg && title === "" && (
                <span className='errorMessage'>Please enter event title</span>
              )}
              {/* {title.length > 50 && (
                <span style={{ color: "red" }}>
                  Maximum length is 50 characters
                </span>
              )} */}
            </Grid>

            <Grid item xs={11} className={classes.gridWrapper}>
              <InputField
                showRequired
                placeholder='Enter Event Subtitle'
                inputlabel='Event Subtitle'
                validators={["required"]}
                errorMessages={["Please enter event title"]}
                value={subtitle}
                onChange={v => setSubtitle(v.target.value)}
              />
              {/* {subtitle.length > 50 && (
                <span style={{ color: "red" }}>
                  Maximum length is 50 characters
                </span>
              )} */}
            </Grid>

            <Grid item xs={11} className={classes.gridWrapper}>
              <label className='slugLabel'>
                Event URL <i className={"showRequired"}></i>
              </label>
              <Grid container spacing={2} xs={12} className='slugWrapper'>
                <span style={{ marginLeft: "10px" }}>
                  newsroom.aquaticav.com/events/category/
                </span>
                <span
                  className='eventCatUrlSlug'
                  style={{ flex: "1", marginLeft: "10px" }}>
                  <InputField
                    inputlabel='Event URL'
                    placeholder='Enter a URL Slug'
                    validators={["required"]}
                    errorMessages={["Please enter event title"]}
                    value={eventURL}
                    onChange={v =>
                      setEventURL(v.target?.value?.replace(/\s+/g, ""))
                    }
                  />
                  {eventURL.length > 200 && (
                    <span className='errorMessage'>
                      Maximum length is 200 characters
                    </span>
                  )}
                </span>
              </Grid>
              {/* 
            <InputField
              placeholder="newsroom.aquaticav.com/events/prouduct-launch/prosport/"
              inputlabel="Event URL"
              validators={["required"]}
              errorMessages={["Please enter event title"]}
              value={url}
              onChange={v => setUrl(v.target.value)}
            /> */}
            </Grid>

            <Grid item xs={11} className={classes.gridWrapper}>
              <InputField
                showRequired
                placeholder='newsroom.aquaticav.com/events/prouduct-launch/prosport/'
                inputlabel='Facebook URL'
                validators={["required"]}
                errorMessages={["Please enter event title"]}
                value={facebookUrl}
                onChange={v => setFacebookUrl(v.target.value)}
              />
              {facebookUrl.length > 200 && (
                <span className='errorMessage'>
                  Maximum length is 200 characters
                </span>
              )}
            </Grid>

            {/* right column */}
            <Grid item xs={12} className={classes.gridWrapper}>
              <Grid container spacing={2}>
                <Grid item xs={5} className={classes.gridWrapper}>
                  <Grid
                    item
                    xs={11}
                    className={[classes.gridWrapper, "eventTextarea"]}>
                    <EmptyTextarea
                      required
                      textLabel='Short Description'
                      value={description}
                      onChange={v => setDiscription(v.target.value)}
                    />
                    {description.length > 1000 && (
                      <span className='errorMessage'>
                        Maximum length is 1000 characters
                      </span>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={7} className={classes.gridWrapper}>
                  <Grid container spacing={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <label
                          className={classes.dateLabel}
                          style={{ marginTop: "12px" }}>
                          {"Event Start Date"}
                        </label>
                        <div className='inputWrapper dateField'>
                          <FormControl className={classes.formControl}>
                            <DatePicker
                              disableToolbar
                              variant='inline'
                              value={startDate}
                              onChange={time => {
                                setStartDate(time);
                                setStartTime(time);
                              }}
                              autoOk
                            />
                          </FormControl>
                        </div>
                      </Grid>

                      <Grid item xs={3} className={classes.gridWrapper}>
                        <span
                          className='dateCheckbox'
                          style={{ marginTop: "12px" }}>
                          <CustomCheckbox
                            className={classes.checkBoxFont}
                            checkedId={"checked"}
                            pressCheck={() => {
                              setAllDay(!allDay);
                              if (!allDay === true) {
                                setIsEndDateEnable(false);
                              }
                            }}
                            checkValue={allDay}
                          />{" "}
                          All Day
                        </span>
                        <div className='inputWrapper dateField'>
                          <FormControl className={classes.formControl}>
                            <TimePicker
                              //disableToolbar
                              // variant="inline"
                              value={startTime}
                              onChange={time => {
                                if (
                                  startDate &&
                                  endDate &&
                                  startDate.toDateString() ===
                                    endDate.toDateString()
                                ) {
                                  if (time >= endTime) {
                                    setStartDate(endTime);
                                    setStartTime(endTime);
                                  } else {
                                    setStartDate(time);
                                    setStartTime(time);
                                  }
                                } else {
                                  setStartDate(time);
                                  setStartTime(time);
                                }
                              }}
                              autoOk
                            />
                            {startDate >= endDate && (
                              <FormHelperText>
                                Event Start Date Time cannot be greater than
                                Event End Date Time.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      </Grid>
                    </MuiPickersUtilsProvider>

                    <Grid item xs={6} className={classes.gridWrapper}>
                      {!isEndDateEnable ? (
                        <Button
                          variant='outlined'
                          color='primary'
                          onClick={() => {
                            if (!allDay) {
                              setIsEndDateEnable(true);
                            }
                          }}
                          className='addEndDateBtn'>
                          <AddIcon /> Add End Date
                        </Button>
                      ) : (
                        <>
                          <Grid container spacing={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid item xs={6} className={classes.gridWrapper}>
                                <label
                                  className={classes.dateLabel}
                                  style={{ marginTop: "12px" }}>
                                  {"Event End Date"}
                                </label>
                                <div className='inputWrapper dateField'>
                                  <FormControl className={classes.formControl}>
                                    <DatePicker
                                      disableToolbar
                                      variant='inline'
                                      value={endDate}
                                      onChange={time => {
                                        setEndDate(time);
                                        setEndTime(time);
                                      }}
                                      autoOk
                                      minDate={startDate}
                                    />
                                  </FormControl>
                                </div>
                              </Grid>

                              <Grid item xs={6} className={classes.gridWrapper}>
                                <label
                                  style={{
                                    padding: "11px 11px 6px 11px",
                                    display: "inline-block",
                                  }}>
                                  &nbsp;
                                </label>
                                <div className='inputWrapper dateField'>
                                  <FormControl className={classes.formControl}>
                                    <TimePicker
                                      // disableToolbar
                                      // variant="inline"
                                      value={endTime}
                                      onChange={time => {
                                        if (
                                          startDate &&
                                          endDate &&
                                          startDate.toDateString() ===
                                            endDate.toDateString()
                                        ) {
                                          if (time <= startTime) {
                                            setEndDate(startTime);
                                            setEndTime(startTime);
                                          } else {
                                            setEndDate(time);
                                            setEndTime(time);
                                          }
                                        } else {
                                          setEndDate(time);
                                          setEndTime(time);
                                        }
                                      }}
                                      autoOk
                                    />
                                    {startDate >= endDate && (
                                      <FormHelperText>
                                        Event End Date Time cannot be less than
                                        Event Start Date Time.
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </div>
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "12px",
                }}>
                <Grid item xs={6}>
                  <span>
                    <label
                      className='slugLabel'
                      style={{ marginBottom: "7px" }}>
                      Event Categories <i className={"showRequired"}></i>
                    </label>
                    <Button
                      color='primary'
                      variant='outlined'
                      startIcon={<Add />}
                      onClick={() => setIsAddReleaseCategory(true)}>
                      Add Category
                    </Button>
                  </span>
                  <span>
                    {slectedCategories?.map(s => (
                      <ReleaseCategoryChip
                        mainReleaseCategory={mainCategory}
                        setMainReleaseCategory={setMainCategory}
                        category={{
                          id: s,
                          value: eventCategory.find(r => `${r.id}` === `${s}`)
                            ?.value,
                        }}
                        onRemove={cId => {
                          setSlectedCategories([
                            ...slectedCategories.filter(scId => scId !== cId),
                          ]);
                          if (mainCategory === cId) {
                            setMainCategory(null);
                          }
                        }}
                      />
                    ))}
                  </span>
                  <MultiSelectReleaseCategories
                    releaseCategories={eventCategory}
                    isVisible={isAddReleaseCategory}
                    slectedReleaseCategories={slectedCategories}
                    onSelect={slectedCategories => {
                      setSlectedCategories(slectedCategories);
                      setIsAddReleaseCategory(false);
                    }}
                    onClose={() => setIsAddReleaseCategory(false)}
                  />
                </Grid>
                {/* <Grid item xs={6} className={classes.gridWrapper}>
                  <Dropdown
                    selectlabel='Event Categories'
                    value={selectedCategoryId}
                    handleChange={({ target }) => {
                      setSelectedCategory(target.value);
                      const groupIds = eventCategory.find(
                        r => r.id === target.value
                      )?.generic;
                      if (groupIds?.length > 0) {
                        setReleaseVisibilityGroups(
                          groupIds.map(x => {
                            return {
                              name: visibilityGroups.find(y => y.id == x)
                                ?.value,
                              VisibilityGroupId: x
                            };
                          })
                        );
                      } else {
                        setReleaseVisibilityGroups([]);
                      }
                    }}
                    placeholder='Please Select'
                    customMenuItems={eventCategory?.map(c => {
                      return {
                        id: c.id,
                        name: c.value
                      };
                    })}
                  />
                  {errorMsg && selectedCategoryId === -1 && (
                    <span style={{ color: "red" }}>
                      Please enter event categories
                    </span>
                  )}
                </Grid> */}
                <Grid
                  item
                  xs={6}
                  className={classes.gridWrapper}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "space-evenly"
                  }}>
                  <Grid item xs={4} className={classes.gridWrapper}>
                    <label className={classes.dateLabel}>
                      {"Event Visibility Date"}
                    </label>
                    <div className='inputWrapper dateField'>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl className={classes.formControl}>
                          <DatePicker
                            disableToolbar
                            variant='inline'
                            value={eventDate}
                            onChange={time => {
                              setEventTime(time);
                              setEventDate(time);
                            }}
                            autoOk
                            minDate={startDate}
                            maxDate={isEndDateEnable ? endDate : undefined}
                          />
                        </FormControl>
                        {(stDate.getTime() > entDate.getTime()) && (
                          <FormHelperText style={{ color: "#E06145" }}>
                            Please select valid Date and Time.
                          </FormHelperText>
                        )}
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    className={classes.gridWrapper}
                    style={{
                      marginLeft: "10px",
                    }}>
                    <label
                      style={{
                        padding: "11px",
                        display: "inline-block",
                      }}
                    />
                    <div className='inputWrapper dateField'>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl className={classes.formControl}>
                          <TimePicker
                            value={eventTime}
                            onChange={time => {
                              setEventDate(time);
                              setEventTime(time);
                            }}
                            autoOk
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={classes.gridWrapper}>
                  <Dropdown
                    selectlabel='Event Type'
                    value={selectedEventTypeId}
                    handleChange={({ target }) =>
                      setSelectedEventTypeId(target.value)
                    }
                    placeholder='Please Select'
                    customMenuItems={eventType?.map(c => {
                      return {
                        id: c.id,
                        name: c.value,
                      };
                    })}
                  />
                  {errorMsg && selectedEventTypeId <= 0 && (
                    <span className='errorMessage'>
                      Please enter event type
                    </span>
                  )}
                </Grid>
                <Grid item xs={4} className={classes.gridWrapper}>
                  <Dropdown
                    selectlabel='Related Launch'
                    value={selectedLaunchId}
                    handleChange={({ target }) => {
                      setSelectedLaunch(target.value);
                    }}
                    placeholder='Please Select'
                    customMenuItems={relatedLaunches?.map(c => {
                      return {
                        id: c.id,
                        name: c.value,
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={4} className={classes.gridWrapper}>
                  <AutoComplete
                    showRequired
                    label='Event Location'
                    value={eventLocationValue?.value}
                    onChange={e => {
                      debouncedChangeHandler(e);
                      setEventLocationValue(e.target.value);
                    }}
                    eventLocation={eventLocation}
                    setEvent={e => {
                      setEventLocationValue(e);
                    }}
                  />
                  {errorMsg && eventLocationValue === "" && (
                    <span className='errorMessage'>
                      Please enter event location
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  className={classes.gridWrapper}
                  style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center"
                      }}>
                      <label
                        className='slugLabel'
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}>
                        Feature Image <i className={"showRequired"}></i>
                      </label>
                      <span>
                        <Button
                          variant='outlined'
                          color='primary'
                          className={classes.selectBtn}
                          onClick={() =>
                            setShowFeatureImage(!showFeatureImage)
                          }>
                          Select
                        </Button>
                      </span>
                    </div>

                    <span
                      className='releaseViewAvatarWrapper'
                      style={{ marginLeft: "10px" }}>
                      {(featureImgUpload?.url ||
                        featureImgUpload ||
                        avatarImg) && (
                        <img
                          src={
                            featureImgUpload?.url ||
                            featureImgUpload ||
                            avatarImg
                          }
                          alt=''
                        />
                      )}
                    </span>
                  </div>

                  <ImageUploader
                    title={"Featured Image"}
                    showFeatureImage={showFeatureImage}
                    setShowFeatureImage={() =>
                      setShowFeatureImage(!showFeatureImage)
                    }
                    onImageSelect={img => {
                      setFeatureImgUpload(img);
                    }}
                    infoText="Images are supported only. (JPEG, JPG and PNG)"
                  />
                </Grid>
                <Grid item xs={6} style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <label
                        className='slugLabel'
                        style={{ marginBottom: "10px", marginTop: "10px" }}>
                        Banner Image
                      </label>
                      <span>
                        <Button
                          variant='outlined'
                          color='primary'
                          className={classes.selectBtn}
                          onClick={() => setShowBannerImage(!showBannerImage)}>
                          Select
                        </Button>
                      </span>
                    </div>

                    <span
                      className='releaseViewAvatarWrapper'
                      style={{ marginLeft: "10px" }}>
                      {(bannerImgUpload?.url ||
                        bannerImgUpload ||
                        avatarImg) && (
                        <img
                          src={
                            bannerImgUpload?.url || bannerImgUpload || avatarImg
                          }
                          alt=''
                        />
                      )}
                    </span>
                  </div>

                  <ImageUploader
                    title={"Banner Image"}
                    showFeatureImage={showBannerImage}
                    setShowFeatureImage={() => {
                      setShowBannerImage(!showBannerImage);
                    }}
                    onImageSelect={img => {
                      setBannerImgUpload(img);
                    }}
                    infoText="Images are supported only. (JPEG, JPG and PNG)"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionContainer>
      </ValidatorForm>

      <div className='contentBody accordianBody'>
        <ReleaseVisabilityGroup
          newsRoomVisibilityId={newsRoomVisibilityId}
          releaseVisibilityGroups={releaseVisibilityGroups}
          onNewsRoomVisibilityIdSelect={NewsRoomVisibilityId => {
            selectReleaseNewsRoomVisibility(NewsRoomVisibilityId);
          }}
          onGroupSelection={groupList => {
            setReleaseVisibilityGroups(groupList);
          }}
          preReleaseVisibilityGroups={preReleaseVisibilityGroups}
          setPreReleaseVisibilityGroups={setPreReleaseVisibilityGroups}
          displayNewsRoomDD={false}
          mainTitle={"Event Visibility"}
          headingVisi={"View Event"}
          textVisi={
            "These users will have access to the event on the announcement date"
          }
          announceDate={eventDate}
          startDate={stDate}
          setIsPrereleaseValidDate={setIsPrereleaseValidDate}
        />
      </div>
      <div className='contentBody accordianBody'>
        <ReleaseContent
          model={releaseContent}
          handelModelChange={model => setReleaseContent(model)}
        />
      </div>
    </div>
  );
};

export { NewEventForm };
