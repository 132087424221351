import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ArrowDropDown } from "@material-ui/icons";
import OutsideClickHandler from "react-outside-click-handler";
import classnames from "classnames";

const LaunchState = ({
  value,
  onChange,
  States = {
    value1: "Planning",
    value2: "Launched",
    value3: ""
  },
}) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [isLaunched, setIsLaunched] = useState(value);

  useEffect(() => {
    setIsLaunched(value);
  }, [value]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsDropdown(false)}>
      <span className='draftDropdown'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => setIsDropdown(!isDropdown)}
          className={classnames(isLaunched === 1 ? "launchedBtn" : isLaunched === 2 ? "selectbutton" : "")}
          // type="submit"
        >
          {isLaunched === 1 ? States.value2 : isLaunched === 2 ? States.value3 : States.value1} 
          {isLaunched === 2 ? null : <ArrowDropDown /> }
        </Button>

        {isDropdown && (
          <span className='draftInnerDropdown'>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setIsLaunched(false);
                setIsDropdown(!isDropdown);
                onChange(0);
              }}>
              {States.value1}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setIsLaunched(true);
                setIsDropdown(!isDropdown);
                onChange(1);
              }}>
              {States.value2}
            </Button>
          </span>
        )}
      </span>
    </OutsideClickHandler>
  );
};

const EnforceState = ({ value, onChange }) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [isEnforced, setIsLaunched] = useState(null);

  useEffect(() => {
    if (value === 2) setIsLaunched(true);
    else if (value === 3) setIsLaunched(false);
    else setIsLaunched(null);
  }, [value]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsDropdown(false)}>
      <span className='draftDropdown'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => setIsDropdown(!isDropdown)}
          className={classnames(
            isEnforced === null ? "blueBtn" : isEnforced ? "greenBtn" : "redBtn"
          )}
          // type="submit"
        >
          {isEnforced === null
            ? "Select Status"
            : isEnforced
            ? "MAP Enforced"
            : "Not Enforced"}
          <ArrowDropDown />
        </Button>

        {isDropdown && (
          <span className='draftInnerDropdown enforceStateDropdown'>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setIsLaunched(true);
                setIsDropdown(!isDropdown);
                onChange(2);
              }}>
              MAP Enforced
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setIsLaunched(false);
                setIsDropdown(!isDropdown);
                onChange(3);
              }}>
              Not Enforced
            </Button>
          </span>
        )}
      </span>
    </OutsideClickHandler>
  );
};

export { LaunchState, EnforceState };
