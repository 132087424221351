export const assetsTypes = {
  ASSETS_ONCHANGE: "ASSETS_ONCHANGE",
  SELECTED_ASSETS: "SELECTED_ASSETS",
  SELECTED_ALL_COLLECTION: "SELECTED_ALL_COLLECTION",
  SELECTED_COL_CATEGORY: "SELECTED_COL_CATEGORY",
  SELECT_COLLECTION_VISIBILITY: "SELECT_COLLECTION_VISIBILITY",
  ON_SELECT_FILETYPE: "ON_SELECT_FILETYPE",
  ON_PRESS_FILTER: "ON_PRESS_FILTER",
  ON_SELECT_CATTYPE: "ON_SELECT_CATTYPE",
  ON_PRESS_FILTER_CAT: "ON_PRESS_FILTER_CAT",
  ASSETS_PREVIEWDATA : "ASSETS_PREVIEWDATA",
  RESET_ASSETS_PREVIEW: "RESET_ASSETS_PREVIEW",
  SELECT_ALL_ASSETS: "SELECT_ALL_ASSETS",
};
