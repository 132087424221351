import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
// import SelectImagesTabs from "./SelectImagesTabs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
import SelectInput from "../../../../../components/common/SelectInput";
import EmptyTextarea from "../../../../../components/common/Textarea";
import { Button } from "@material-ui/core";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import CustomCheckbox from "../../../../../components/common/checkbox/CustomCheckbox";
import SelectImage from "./selectImage";
import { BiX } from "react-icons/bi";
import { toast } from "react-toastify";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridOuterWrapper: {
    position: "relative",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function QuickLinks({ startLoading, stopLoading }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const classes = useStyles();

  const loadData = () => {
    startLoading();
    dispatch(newsroomActions.onGetQuickLinks()).then((response) => {
      if (response && response.success && response.data) {
        dispatch(newsroomActions.onChange("quickLinksValue", response.data));
      } else {
        toast.error(response.message);
      }
      stopLoading();
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const changeQuickLinksValue = (value, index, fieldName) => {
    dispatch(newsroomActions.onChangeQuickLinksValue(index, value, fieldName));
  };

  const onUpdateQuickLinksPress = (record) => {
    startLoading();
    dispatch(newsroomActions.onSaveQuickLinks(record)).then((data) => {
      if (data && data.success) {
        toast.success(data.message);
      }
      stopLoading();
    });
  };

  const { quickLinksValue } = useSelector((state) => state.newsRoomReducer);
  return (
    <>
      <div className="gap" />
      <div className="accordianOuterWrapper">
        <AccordionContainer title="Quick Links">
          <div className={classes.root}>
            {quickLinksValue.length > 0 &&
              quickLinksValue.map((item, index) => {
                return (
                  <Grid
                    container
                    key={index}
                    spacing={3}
                    className={classes.gridOuterWrapper}
                  >
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <InputField
                        placeholder={`${"Link "}${index + 1}${" Name"}`}
                        inputlabel={`${"Link "}${index + 1}${" Name"}`}
                        name="linkName"
                        value={item.name}
                        onChange={(e) =>
                          changeQuickLinksValue(e.target.value, index, "name")
                        }
                      />
                      {/* {item.name.length > 50 && (
                        <span style={{ color: "red" }}>
                          link name characters length cannot be exceed from 50
                          characters
                        </span>
                      )} */}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className={[
                        classes.gridWrapper,
                        classes.gridOuterWrapper,
                      ]}
                    >
                      <InputField
                        placeholder={`${"Link "}${index + 1}${" URL"}`}
                        inputlabel={`${"Link "}${index + 1}${" URL"}`}
                        name="linkUrl"
                        value={item.url}
                        onChange={(e) =>
                          changeQuickLinksValue(e.target.value, index, "url")
                        }
                      />
                      {/* {item.url.length > 50 && (
                        <span style={{ color: "red" }}>
                          link position characters length cannot be exceed
                          from 50 characters
                        </span>
                      )} */}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className={[classes.gridWrapper, "buttonOutlined"]}
                    >
                      <span className="customCheckboxWrapper quickLinksCheckbox">
                        <CustomCheckbox
                          checkedId={`${"checkbox-"}${index + 1}`}
                          checkValue={item.openInNewTab}
                          pressCheck={(e) =>
                            changeQuickLinksValue(
                              item.openInNewTab ? false : true,
                              index,
                              "openInNewTab"
                            )
                          }
                        />
                        Open in New Window
                      </span>
                      <span className="deleteRow">
                        <BiX
                          onClick={() =>
                            dispatch(newsroomActions.removeQuickLinks(index))
                          }
                        />
                      </span>
                    </Grid>
                  </Grid>
                );
              })}
            <div className="gap" />
            <span className="addleaderBtn">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => dispatch(newsroomActions.addNewQuickLinks())}
              >
                Add Quick Link
              </Button>
            </span>

            <span className="saveChangesBtn ">
              <Button
                variant="contained"
                color="primary"
                onClick={() => onUpdateQuickLinksPress(quickLinksValue)}
              >
                save changes
              </Button>
            </span>
          </div>
        </AccordionContainer>
      </div>
    </>
  );
}
