import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import FroalaEditor from "react-froala-wysiwyg";
import { makeStyles } from "@material-ui/core/styles";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { useDispatch } from "react-redux";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "transparent !important",
    //   margin: '12px 0'
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
  },
}))(MuiAccordionDetails);
// const config={{

// }}
new FroalaEditor(".selector", {
  toolbarButtons: {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "inlineClass",
        "inlineStyle",
        "clearFormatting",
      ],
    },
    moreParagraph: {
      buttons: [
        "alignLeft",
        "alignCenter",
        "formatOLSimple",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",
        "paragraphFormat",
        "paragraphStyle",
        "lineHeight",
        "outdent",
        "indent",
        "quote",
      ],
    },
    moreRich: {
      buttons: [
        "insertLink",
        "insertImage",
        "insertVideo",
        "insertTable",
        "emoticons",
        "fontAwesome",
        "specialCharacters",
        "embedly",
        "insertFile",
        "insertHR",
      ],
    },
    moreMisc: {
      buttons: [
        "undo",
        "redo",
        "fullscreen",
        "print",
        "getPDF",
        "spellChecker",
        "selectAll",
        "html",
        "help",
      ],
      align: "right",
      buttonsVisible: 2,
    },
  },
});
var test = "";
export default function CustomizedAccordions({ model, handelModelChange }) {
  const [expanded, setExpanded] = useState("panel2");
  // const [model, setModel] = useState("");
  const dispatch = useDispatch();
  const [uploadedImg, setUpload] = useState("");
  // const [isLoading, setLoading] = useState(false);
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const classes = useStyles();
  const onHandleModelChange = model => {
    handelModelChange(model);
  };

  const uploadFroalaImage = file => {
    dispatch(launchesActions.uploadImage(file)).then(res => {
      if (res.success) {
        test = res.data.url;
      }
    });
  };
  // console.log(uploadedImg);
  return (
    <div className="eventsAccordianOuterWrapper">
      <AccordionContainer title="Event Description/Content">
        <div className="fr-view">
          <FroalaEditor
            // ref={ref}
            model={model}
            onModelChange={onHandleModelChange}
            tag="textarea"
            config={{
              events: {
                "image.inserted": function ($img, response) {
                  // $img[0].src =
                  //   "https://media0.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif?cid=790b76118e6134003746d6e19261c2868eb9cbe166fcac9f&rid=giphy.gif&ct=g";
                  $img[0].src =
                    "https://logosbynick.com/wp-content/uploads/2021/01/animated-gif.gif";
                  setTimeout(() => {
                    $img[0].src = test;
                    // setLoading(false);
                    // $img[0].currentSrc = test;
                  }, 7000);
                  // Do something here.
                  // this is the editor instance.
                  console.log(this);
                },
                "image.beforeUpload": function (images) {
                  uploadFroalaImage(images);
                  // Do something here.
                  // this is the editor instance.
                  console.log(this);
                },
              },
              key: "VCC6kE4A3D3H3C7C7C6cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A3F3E3B3F2A5C3==",
            }}
          />
        </div>
      </AccordionContainer>
    </div>
  );
}
