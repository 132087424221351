import React from "react";
import { TabButtons } from "./TabButtons";
import routesObj from "../../../routes/RoutesObj";

const BusinessContactsTabButtons = () => (
  <TabButtons
    tabs={[
      { title: "All Business Contacts", to: routesObj.CustomerContacts.path },
      { title: "Companies", to: routesObj.CustomerCompanies.path },
      { title: "Groups", to: routesObj.BusinessGroup.path },
      { title: "Favorites", to: routesObj.BusinessContactFavorites.path },
      {
        title: "Recently Added",
        to: routesObj.BusinessContactRecentlyAdded.path,
      },
      { title: "Archived", to: routesObj.BusinessContactArchived.path },
      { title: "Blocked", to: routesObj.BusinessContactBlocked.path },
      { title: "Deleted", to: routesObj.BusinessContactDeleted.path },
      { title: "Blocked Dealers", to: routesObj.BlockedBusinessLocation.path },
    ]}
  />
);

export { BusinessContactsTabButtons };
