import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, FormControl, Grid } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { first } from "lodash";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import DateFnsUtils from "@date-io/date-fns";
import {
  AccordionContainer,
  AddButtonWithLabel,
  ChipItem,
  EmptyContentText,
  SectionDivider,
} from "../../../../../../components/common";
import { RoutesObj } from "../../../../../../routes";
import { SVGIcons } from "../../../../../../resources/images";
import { useDropdownValues } from "../../../../../../utils/use-dropdown-values";
import { endpoints } from "../../../../../../config/apiConfig";
import moment from "moment";
import format from 'date-fns/format';

const useStyles = makeStyles(theme => ({
  datePickerStyle: {
    border: "1px solid #70808F",
    borderRadius: "6px",
    padding: "2px 2px 1px",
    "& .MuiInput-underline:hover": {
      borderBottom: "none !important",
    },
    "& .MuiInput-underline:after, .MuiInput-underline:before": {
      borderBottom: "none !important",
    },
    "& .MuiInputBase-input": {
      color: "#70808F",
      paddingLeft: 10,
    },
  },
}));

const PortalVisibility = ({
  onAddPortalVisibility,
  priceListPortals = [],
  onRemovePortal,
}) => {
  return (
    <>
      <Grid container item xs={12}>
        <Grid item>
          <AddButtonWithLabel
            isRequired
            label={"Portal Visibility"}
            onClick={onAddPortalVisibility}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {priceListPortals.length > 0 ? (
          <>
            {priceListPortals.map((pls, i) => (
              <ChipItem
                key={`${pls?.name}${i}`}
                chipTitle={pls.name}
                chipSubTitle={pls.description || pls?.type}
                onDelete={() => {
                  const updatedList = priceListPortals.filter(
                    f => pls?.name !== f?.name && pls?.portalId !== f?.portalId
                  );
                  onRemovePortal(updatedList);
                }}
              />
            ))}
          </>
        ) : (
          <EmptyContentText
            text='No Portals Have Been Added Yet'
            buttonText='Add a Portal'
            onClick={onAddPortalVisibility}
          />
        )}
      </Grid>
    </>
  );
};

const PriceListAccess = ({
  groupAccessLevel,
  onAddUserGroups,
  onRemoveGroup,
}) => {
  return (
    <>
      <Grid container item xs={12}>
        <Grid item>
          <AddButtonWithLabel
            isRequired
            label={"Price List Access"}
            onClick={onAddUserGroups}
          />
        </Grid>
        <Grid
          item
          alignItems='center'
          style={{ display: "flex", padding: "0px 15px" }}
          className='info-helper-text'>
          <SVGIcons.Info /> &nbsp;&nbsp;&nbsp;
          <span>
            {`This Price List will be visible only to the users/groups below`}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <b style={{ fontSize: "14px" }}>
          Users/groups below will have access to the price list on the
          Availability Period Start Date.
        </b>
      </Grid>
      <Grid item xs={12}>
        {groupAccessLevel?.length > 0 ? (
          <>
            {groupAccessLevel
              .filter(f => !f?.isPreReleaseAccessGroup)
              .map((ga, i) => (
                <ChipItem
                  key={`${ga?.name}${i}`}
                  chipTitle={ga?.name}
                  chipSubTitle={ga?.type}
                  accessLevel={ga?.isAllAccess ? "Full Access" : "View Only"}
                  onDelete={() => {
                    const updatedList = groupAccessLevel
                      .filter(f => !f?.isPreReleaseAccessGroup)
                      .filter(f =>
                        ga?.name === f?.name && ga?.groupId === f?.groupId
                          ? null
                          : ga
                      );
                    onRemoveGroup([
                      ...groupAccessLevel.filter(
                        f => f?.isPreReleaseAccessGroup
                      ),
                      ...updatedList.filter(a => a !== null),
                    ]);
                  }}
                />
              ))}
          </>
        ) : (
          <EmptyContentText
            text='No Users/Groups have been provide viewing access yet.'
            buttonText='Add a User/Group'
            onClick={onAddUserGroups}
          />
        )}
      </Grid>
    </>
  );
};

const PreReleaseAccess = ({
  groupAccessLevel,
  onAddUserGroups,
  onRemoveGroup,
  preReleaseAccessDate,
  onPreReleaseDateChange,
  availabilityPeriodDD,
  priceList,
}) => {
  const classes = useStyles();

  const newAvailibilityPeriod = availabilityPeriodDD?.find(
    (a) => `${a.key}` === `${priceList.papId}`
  );

  function extractStartAndEndDate() {
    const dateRegex = /(\d{2}\/[a-zA-Z]+\/\d{4}) - (\d{2}\/[a-zA-Z]+\/\d{4})/;
    const match = newAvailibilityPeriod?.value?.match(dateRegex);
    if (match && match.length === 3) {
      const startDate = match[1];
      const endDate = match[2];
      
      return { startDate, endDate };
    } else {
      return null;
    }
  }
  const currentDate = new Date();
  const endDate = moment(extractStartAndEndDate()?.startDate).format("MMMM DD, YYYY");
  
  const isDateWithinRange = (date) => date >= currentDate && date < new Date(endDate);

  return (
    <>
      <Grid container item xs={12}>
        <Grid item>
          <AddButtonWithLabel
            label={"Pre-Release Access"}
            onClick={onAddUserGroups}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} className='pricelistDatepicker'>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <b style={{ fontSize: "14px", marginRight: "10px" }}>
            Users/Groups below will have access to the price list on:
          </b>
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl>
              <DatePicker
                autoOk
                format='MMMM dd, yyyy'
                placeholder='Select Date & Time'
                value={preReleaseAccessDate}
                onChange={onPreReleaseDateChange}
                className={classes.datePickerStyle}
                minDate={currentDate}
                maxDate={new Date(endDate)}
                shouldDisableDate={date => !isDateWithinRange(date)}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {groupAccessLevel?.length > 0 ? (
          <>
            {groupAccessLevel
              .filter(f => f?.isPreReleaseAccessGroup)
              .map(ga => (
                <ChipItem
                  key={ga?.name}
                  chipTitle={ga?.name}
                  chipSubTitle={ga?.type}
                  accessLevel={ga?.isAllAccess ? "Full Access" : "View Only"}
                  onDelete={() => {
                    const updatedList = groupAccessLevel
                      .filter(f => f?.isPreReleaseAccessGroup)
                      .filter(f =>
                        ga?.name === f?.name && ga?.groupId === f?.groupId
                          ? null
                          : ga
                      );
                    onRemoveGroup([
                      ...groupAccessLevel.filter(
                        f => !f?.isPreReleaseAccessGroup
                      ),
                      ...updatedList.filter(a => a !== null),
                    ]);
                  }}
                />
              ))}
          </>
        ) : (
          <EmptyContentText
            text='No Users/Groups have been provide viewing access yet.'
            buttonText='Add a User/Group'
            onClick={onAddUserGroups}
          />
        )}
      </Grid>
    </>
  );
};

const PriceListVisibility = ({
  plData,
  setPriceListData,
  onAddPortalVisibility,
  onAddPriceListAccess,
  onAddPriceListPreAccess,
  addUpdatePriceListVisibility,
  activeStep,
  setActiveStep,
}) => {
  const { pathname } = useLocation();
  const [isCollpased, setIsCollpased] = useState(false);
  const { dropdownValues: availabilityPeriodDD } =
  useDropdownValues({
    url: endpoints.productInventory.getPricingAvailabilityPeriodDropdownV2,
  });

  return (
    <div className='newPriceListForm'>
      <AccordionContainer
        title='Price List Visibility'
        subTitle='Set This Price List’s Visibility settings from within Portals + User Access'
        disabled={plData.id === 0}
        isExpanded={activeStep === 2}
        onCollapsed={collapsed => setIsCollpased(collapsed)}
        formButton={
          isCollpased &&
          pathname?.includes(first(RoutesObj.CreatePriceList.path)) &&
          plData?.id > 0 && (
            <span className='green-tick-svg'>
              <SVGIcons.GreenTick />
            </span>
          )
        }>
        {/* {isProcessing ? <Spinner /> : null} */}
        <ValidatorForm
          onSubmit={async () => {
            const response = await addUpdatePriceListVisibility({
              priceListId: plData.id,
              groupAccessLevel: [...plData?.groupAccessLevel],
              priceListPortals: [...plData?.priceListPortals],
              preReleaseAccessDate: "",
            });
            if (response.success) {
              toast.success(
                `Price List Visibility ${
                  plData?.id ? "updated" : "created"
                } successfully`
              );
              if (plData.id) setPriceListData({ ...response.data });
              setActiveStep(activeStep + 1);

              // else
              //   setPriceListData({
              //     ...response.data,
              //     priceListColumns:
              //       response.data?.priceListColumns ||
              //       DefaultPriceListData.priceListColumns,
              //     groupAccessLevel:
              //       response.data?.groupAccessLevel ||
              //       DefaultPriceListData.groupAccessLevel,
              //   });
            }
          }}>
          <Grid container spacing={3}>
            <PortalVisibility
              priceListPortals={plData?.priceListPortals}
              onAddPortalVisibility={onAddPortalVisibility}
              onRemovePortal={updatedList => {
                setPriceListData({
                  ...plData,
                  priceListPortals: updatedList,
                });
              }}
            />

            <Grid item xs={12}>
              <SectionDivider />
            </Grid>

            <PriceListAccess
              groupAccessLevel={plData?.groupAccessLevel}
              onAddUserGroups={onAddPriceListAccess}
              onRemoveGroup={updatedList => {
                setPriceListData({
                  ...plData,
                  groupAccessLevel: updatedList,
                });
              }}
            />

            <Grid item xs={12}>
              <SectionDivider />
            </Grid>

            <PreReleaseAccess
              groupAccessLevel={plData?.groupAccessLevel}
              preReleaseAccessDate={plData?.preReleaseAccessDate}
              onAddUserGroups={onAddPriceListPreAccess}
              onRemoveGroup={updatedList => {
                setPriceListData({
                  ...plData,
                  groupAccessLevel: updatedList,
                });
              }}
              onPreReleaseDateChange={selectedDate => {
                setPriceListData({
                  ...plData,
                  preReleaseAccessDate: selectedDate,
                });
              }}
              availabilityPeriodDD={availabilityPeriodDD}
              priceList={plData}
            />

            <Grid container item xs={12} justifyContent='space-between'>
              <div>&nbsp;</div>
              <Button
                color='primary'
                variant='contained'
                className='dashboardButtons'
                type='submit'>
                Save & Next
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </AccordionContainer>
    </div>
  );
};

export { PriceListVisibility };
