import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
// import {
//   facebook,
//   instagram,
//   twitter,
//   linkedin,
//   planefill,
// } from "../../../resources/images/index";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {
  LogHeader,
  MainHeading,
  // InnerHeader,
} from "../../../components/common/Header/index";
import PressContact from "./PressContact";
import RelatedAssets from "./RelatedAssets";
import RelatedEvents from "./RelatedEvents";
import { useHistory } from "react-router-dom";
import Cards from "../../../components/common/Cards";
import Loader from "../../../components/common/Loader";
import { pubReleaseActions } from "../../../store/pubRelease/pubReleaseActions";
import { pubCompanyActions } from "../../../store/pubCompany/pubCompanyActions";
import { accountActions } from "../../../store/accounts/accountActions";
import { newsroomActions } from "../../../store/newsroom/newsroomActions";
import routesObj from "../../../routes/RoutesObj";

function LogLatestReleaseDetail(props) {
  document.title = "OFish - Latest Releases";

  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);
  const [releaseContent, setReleaseContent] = useState("");
  const [comContactInfo, setComContactInfo] = useState({});
  const { slug } = useParams();
  const { releasesWithCategory } = useSelector(
    state => state.PubReleaseReducer
  );
  const [companyOverView, setCompanyOverView] = useState(null);
  var releaseId = parseInt(window.location.href.split("?id=")[1]);

  useEffect(() => {
    dispatch(accountActions.onAddUserSession(window.location.href));
    const releaseData = {
      releaseDate: 0,
      categoryIds: [],
      take: 3,
      skip: 0,
      title: "",
      dataType: ["LatestOnly"],
    };
    setIsloading(true);
    dispatch(
      pubReleaseActions.onGetPubRelDetail(
        releaseId ? releaseId : 0,
        slug ? slug : ""
      )
    ).then(data => {
      if (data?.data !== null && data.success) {
        setReleaseContent(data.data);
        dispatch(pubCompanyActions.onGetPubCompContact()).then(res => {
          if (res && res.success) {
            setComContactInfo(res.data);
            dispatch(
              pubReleaseActions.onGetPubRelWithCategory(releaseData)
            ).then(rel => {
              setIsloading(false);
            });
          } else {
            if (res) {
              toast.error(res.message);
            }
            setIsloading(false);
          }
        });
      } else {
        //if (data) {
        toast.error("No Records Found");
        history.push("/");
        //}
        setIsloading(false);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(newsroomActions.onGetCompanyOverview()).then(data => {
      if (data && data.success) {
        setCompanyOverView(data?.data);
      }
    });
  }, []);

  const onPressCollection = item => {
    if (item.urlSlug) {
      history.push(routesObj.MediaDetail.path + "/" + item.urlSlug);
    } else {
      toast.error("No record found");
    }
  };

  const onViewEvent = item => {
    history.push("/events/category/" + item.urlSlug);
  };

  return (
    <div className='latestReleaseDetailWrapper'>
      {isLoading ? <Loader /> : null}
      <LogHeader />
      <MainHeading
        headerSubHeading={releaseContent ? releaseContent.category : ""}
        icon={<KeyboardArrowLeftIcon fontSize='medium' />}
        backLink='Back to Latest Release'
        backNavHandler={() => history.push("/releases")}>
        <div className='popupBody'>
          <h2>{releaseContent !== "" ? releaseContent.title : ""}</h2>
          <span className='subHeading'>
            {releaseContent !== "" ? releaseContent.subTitle : ""}
          </span>
          <div className='dateNPlace'>
            <span>
              {releaseContent !== ""
                ? moment(releaseContent.announcementDate).format(
                    "MMMM DD, YYYY"
                  )
                : ""}
            </span>
            {releaseContent !== "" && releaseContent.location !== "" ? (
              <span>{" | "}</span>
            ) : null}
            <span>{releaseContent !== "" ? releaseContent.location : ""}</span>
          </div>

          <div className='popupTextarea previeImgBody'>
            {releaseContent !== "" && (
              <img
                src={releaseContent.imageUrl}
                className={"previeImg"}
                alt=''
              />
            )}
          </div>
          <p>
            {releaseContent !== "" && (
              <FroalaEditorView model={releaseContent.content} />
            )}
            {/* {companyOverView?.companyDesc ? (
              <FroalaEditorView model={companyOverView.companyDesc} />
            ) : (
              ""
            )} */}
            {companyOverView?.companyBoilerplate && <h2>Company Name</h2>}
            {companyOverView?.companyBoilerplate ? (
              <FroalaEditorView model={companyOverView.companyBoilerplate} />
            ) : (
              ""
            )}
          </p>

          {/* <div className="popupFooter">
            <Button color="primary" variant="contained">
              Download Release
            </Button>
            <span className="footerSocialMedia">
              <img src={instagram} alt="" />
              <img src={facebook} alt="" />
              <img src={twitter} alt="" />
              <img src={linkedin} alt="" />
              <img src={planefill} alt="" />
            </span>
          </div> */}
        </div>

        <div className='pressAssets'>
          <Grid container spacing={3} className='assetsInner'>
            <Grid item xs={4}>
              <h2>Press Contact</h2>
              {comContactInfo ? (
                <div className='innerColums'>
                  <PressContact
                    picture={
                      comContactInfo.imageURL ? comContactInfo.imageURL : ""
                    }
                    heading='Press Contact'
                    subheading={comContactInfo.pressContact}
                    email={comContactInfo.pressContactEmail}
                    inquiry='General Aquatic AV Inquires'
                    inqemail=' info@aquaticav.com'
                  />
                </div>
              ) : null}

              <div className='gap' />
              {releaseContent !== "" &&
              releaseContent.events &&
              releaseContent.events.title !== "No Event" ? (
                <div>
                  <h2>Releted Events</h2>
                  <div className='innerColums'>
                    <RelatedEvents
                      picture={
                        releaseContent.events.image
                          ? releaseContent.events.image
                          : ""
                      }
                      heading={releaseContent.events.title}
                      eventtime={moment(releaseContent.events.startDate).format(
                        "h:mm:ss a"
                      )}
                      eventdate={moment(releaseContent.events.startDate).format(
                        "YYYY/MM/DD"
                      )}
                      onPressEvent={() => onViewEvent(releaseContent.events)}
                    />
                  </div>
                </div>
              ) : null}
            </Grid>
            {releaseContent &&
              releaseContent.collections &&
              releaseContent.collections.length > 0 && (
                <Grid item xs={8}>
                  <h2>Related Assets</h2>
                  <div className='innerColums'>
                    {releaseContent.collections.map((item, index) => (
                      <>
                        <RelatedAssets
                          key={index}
                          picture={item.imageUrl ? item.imageUrl : ""}
                          heading={item.title}
                          subheading={`${item.assetCount}${" Assets"}`}
                          onPressAsset={() => onPressCollection(item)}
                        />
                      </>
                    ))}
                  </div>
                </Grid>
              )}
          </Grid>

          <div className='boxMainWrapper'>
            <h2>Latest Announcements</h2>

            <div className='cards'>
              {/* <Grid container spacing={3}>
                {data.map((item, index) => (
                  <Grid key={index} item md={4}>
                    <Cards
                      picture={item.picture}
                      subheading={item.subheading}
                      cardtext={item.cardtext}
                      date={item.date}
                    />
                  </Grid>
                ))}
              </Grid> */}
              <Grid container spacing={3}>
                {releasesWithCategory &&
                  releasesWithCategory.length > 0 &&
                  releasesWithCategory[0].data?.length > 0 &&
                  releasesWithCategory[0].data.map((item, index) => (
                    <Grid key={index} item md={4}>
                      <Cards
                        picture={
                          item.imageUrl
                            ? item.imageUrl
                            : "/static/images/cards/contemplative-reptile.jpg"
                        }
                        subheading={item.title}
                        cardtext={moment(item.announcementDate).format(
                          "MMMM, DD, YYYY"
                        )}
                      />
                    </Grid>
                  ))}
              </Grid>

              <div className='viewAllBtn'>
                <Button color='primary' size='small'>
                  View All
                </Button>
              </div>
            </div>
          </div>
        </div>
      </MainHeading>
    </div>
  );
}

export default LogLatestReleaseDetail;
