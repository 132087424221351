import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { Button, FormControl, Grid, makeStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ValidatorForm } from "react-material-ui-form-validator";
import { first, last } from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import queryString from "query-string";
import Dashbard from "../Dashbard";
import Loader from "../../../../components/common/Loader";
import AutoComplete from "../../../../components/common/AutoComplete";
import SelectInput from "../../../../components/common/SelectInput";
import InputField from "../../../../components/common/InputField";
import {
  Activities,
  ImageUploader,
  Notes,
  PopupDialog,
  Dropdown,
} from "../../../../components/common";
import { noImage } from "../../../../resources/images/index";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { productSerialActions } from "../../../../store/product/productSerialsAction";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { SerialsStatus } from "../../../../config/Enums";
import { Document, Page, pdfjs } from "react-pdf";
import { debounce } from "lodash";
import EmptyTextarea from "../../../../components/common/Textarea";

const useStyles = makeStyles(theme => ({
  buttonStyle: {
    minWidth: "150px",
    marginTop: "10px",
    padding: "5px 16px",
  },
  blockSerialButton: {
    minWidth: "150px",
    color: "#FF3535",
    border: "1px solid #FF3535",
    backgroundColor: "#ffffff",
    padding: "5px 16px",
    textTransform: "none",
    marginTop: "10px",
  },
  ownedBy: {
    color: "#70808F",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "10px",
  },
  emailByBottom: {
    color: "#70808F",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "10px",
    marginBottom: "10px",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  datePicker: {
    "& .MuiInputBase-input": {
      border: "1px solid rgba(0, 0, 0, 0.23) !important",
      padding: "11px 14px",
      marginBottom: "5px",
    },
  },
  receiptImage: {
    width: "100%",
    maxWidth: "600px",
    height: "100%",
    maxHeight: "700px",
    objectFit: "cover",
  },
}));

export const SerialContext = createContext();

const ProductSerial = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const [dealers, setDealers] = useState([]);
  const [activites, setActivites] = useState([]);
  const [notes, setNotes] = useState([]);

  // Setting service worker URL - otherwise pdf will not render
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const getDeviceBySerialNo = useCallback(() => {
    setLoading(true);
    const searchDevice = queryString.parse(location.search);
    dispatch(productSerialActions.onGetDeviceBySerialNo(searchDevice?.id)).then(
      data => {
        setLoading(false);
        if (data) {
          setDeviceData(first(data?.data));
        }
      }
    );
  }, [dispatch, location]);

  useEffect(() => {
    dispatch(endUserActions.onGetAllDelears("", 0)).then(data => {
      if (data) {
        setDealers(data?.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (deviceData?.deviceId)
      // need to replace param
      dispatch(
        productSerialActions.onGetDeviceActivityById(deviceData?.deviceId)
      ).then(data => {
        if (data) {
          setActivites(data?.data);
        }
      });
  }, [deviceData?.deviceId, dispatch]);

  const getDeviceNotesById = useCallback(() => {
    if (deviceData?.deviceId)
      dispatch(
        productSerialActions.onGetDeviceNotesById(deviceData?.deviceId)
      ).then(data => {
        if (data) {
          setNotes(data?.data);
        }
      });
  }, [deviceData?.deviceId, dispatch]);

  useEffect(() => {
    getDeviceNotesById();
  }, [getDeviceNotesById]);

  useEffect(() => {
    getDeviceBySerialNo();
  }, [getDeviceBySerialNo]);

  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='tab-navigator'>
          <SerialContext.Provider value={{ activites, notes }}>
            <TopTabNavigator
              defaultTabId={"2"}
              tabsTitle={`Serial No: ${deviceData?.serialNo || "-"}`}
              tabs={[
                {
                  id: "1",
                  title: "Overview",
                  component: (
                    <ProductSerialTabItem
                      activeTab={1}
                      deviceData={deviceData}
                      setLoading={setLoading}
                      getDeviceBySerialNo={getDeviceBySerialNo}
                    />
                  ),
                },
                {
                  id: "2",
                  title: "Purchasing",
                  component: (
                    <ProductSerialTabItem
                      activeTab={2}
                      deviceData={deviceData}
                      dealers={dealers}
                      setDealers={value => setDealers(value)}
                      getDeviceBySerialNo={getDeviceBySerialNo}
                    />
                  ),
                },
                {
                  id: "3",
                  title: "Activity",
                  component: (
                    <ProductSerialTabItem
                      activeTab={3}
                      deviceData={deviceData}
                    />
                  ),
                },
                {
                  id: "4",
                  title: "Notes",
                  component: (
                    <ProductSerialTabItem
                      activeTab={4}
                      deviceData={deviceData}
                      getDeviceNotesById={getDeviceNotesById}
                    />
                  ),
                },
              ]}
            />
          </SerialContext.Provider>
        </div>
      </div>
    </Dashbard>
  );
};

const ProductSerialTabItem = memo(
  ({
    activeTab,
    deviceData,
    getDeviceBySerialNo,
    getDeviceNotesById,
    setLoading,
    dealers = [],
    setDealers,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { activites, notes } = useContext(SerialContext);

    const [dealer, setDealer] = useState(0);
    const [purchaseLoc, setPurchaseLoc] = useState("");
    const [deviceInfo, setDeviceData] = useState({ ...deviceData });
    const [receiptStatus, setReceiptStatus] = useState(0);
    const [note , setNote] = useState("");
    const [showImageUploader, setShowImageUploader] = useState(false);
    const [viewReceipt, setViewReceipt] = useState(false);
    const [noteText, setNoteText] = useState("");
    const [pageNumber, setPageNumber] = useState([1]);

    const onPdfLoad = ({ numPages }) => {    
      const pagesArray = [];
      for (let i=1; i <= numPages; i++) {
        pagesArray.push(i);
      }
      setPageNumber([...pagesArray]);
    };

    const sepItemProp = ({ propName, value }) => {
      setDeviceData({
        ...deviceInfo,
        [propName]: value,
      });
    };

    useEffect(() => {
      if (deviceData) {
        setReceiptStatus(deviceData.serialStatus);
        setDeviceData(deviceData);
        setDealer(deviceData?.dealerId);
        setPurchaseLoc(deviceData?.dealer);
        setNote(deviceData?.notes);
      }
    }, [deviceData, deviceData?.serialStatus]);

    // useEffect(() => {
    //   if (deviceData && dealers.length > 0) {

    //   }
    // }, [deviceData, deviceData?.dealer, dealers]);

    const handleSubmit = e => {
      e.preventDefault();
      const deviceUpdate = {
        deviceId: deviceData?.deviceId,
        serialNo: deviceData?.serialNo,
        purchaseDate: moment(deviceInfo?.purchaseDate).format("MM/DD/YYYY"),
        registrationStatus: receiptStatus,
        dealerLocationId: dealer,
        notes:note,
      };
      dispatch(productSerialActions.onAddUpdateItem(deviceUpdate)).then(
        data => {
          if (data && data?.success && getDeviceBySerialNo) {
            getDeviceBySerialNo();
          } else if (!data?.success) {
            toast.error(data?.message);
          }
        }
      );
    };

    const updateReceipt = ({ receipt }) => {
      if (deviceData?.deviceId) {
        dispatch(
          productSerialActions.onUpdateReceipt({
            id: deviceData.deviceId,
            basePath: "",
            receipt: receipt?.url,
          })
        ).then(data => {
          if (data && data?.success && getDeviceBySerialNo) {
            getDeviceBySerialNo();
          } else if (!data?.success) {
            toast.error(data?.message);
          }
        });
      }
    };

    const updateRegistrationStatus = () => {
      if (deviceData?.deviceId) {
        if (setLoading) setLoading(true);
        dispatch(
          productSerialActions.onRegisterSerial({
            id: deviceData.deviceId,
            status: 1,
          })
        ).then(data => {
          if (data && data?.success && getDeviceBySerialNo) {
            getDeviceBySerialNo();
            toast.success("Registered successsfuly");
          } else if (!data?.success) {
            toast.error(data?.message);
          }
          setLoading(false);
        });
      }
    };

    const updateBlockStatus = blocketStatus => {
      if (deviceData?.deviceId) {
        if (setLoading) setLoading(true);
        dispatch(
          productSerialActions.onUpdateBlockSerial({
            id: deviceData.deviceId,
            status: blocketStatus,
          })
        ).then(data => {
          if (data && data?.success && getDeviceBySerialNo) {
            getDeviceBySerialNo();
          } else if (!data?.success) {
            toast.error(data?.message);
          } else {
            toast.warning(`API isn't working`);
          }
          setLoading(false);
        });
      }
    };

    const handleCommnetSubmit = comment => {
      const userId = localStorage.getItem("id");
      const addNote = {
        userId: userId,
        deviceId: deviceData?.deviceId,
        notes: comment,
      };
      dispatch(productSerialActions.onAddNotes(addNote)).then(data => {
        if (data && data.success && getDeviceNotesById) {
          getDeviceNotesById();
          setNoteText("");
        } else if (!data?.success) {
          toast.error(data.message);
        }
      });
    };

    const changeHandler = (event, catId) => {
      dispatch(endUserActions.onGetAllDelears(event.target.value, catId)).then(
        res => {
          setDealers(res?.data || []);
        }
      );
    };
    const debouncedChangeHandler = useCallback(
      debounce(changeHandler, 500),
      []
    );

    return (
      deviceData && (
        <div
          style={{
            width: "100%",
          }}>
          <AccordionContainer disabled={activeTab !== 1} title='Overview'>
            {deviceData && (
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <SerialItemDetail isSold deviceData={deviceData} />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}>
                  <span
                    style={{
                      color: "#8D99A5",
                      fontSize: "18px",
                      fontWeight: 500,
                      marginBottom: "5px",
                    }}>
                    {deviceData?.serialStatus === 1
                      ? "Registeration Approved"
                      : "Not Registered"}
                  </span>
                  {deviceData?.serialStatus === 0 && (
                    <span>
                      <Button
                        color='primary'
                        className={classes.buttonStyle}
                        disabled={activeTab !== 1}
                        onClick={updateRegistrationStatus}
                        variant='contained'>
                        Register
                      </Button>
                    </span>
                  )}
                  <span>
                    {activeTab !== 1 ? (
                      <Button
                        color='primary'
                        className={classes.buttonStyle}
                        disabled={activeTab !== 1}
                        variant='contained'>
                        Block Serial
                      </Button>
                    ) : (
                      <Button
                        className={classes.blockSerialButton}
                        disabled={activeTab !== 1}
                        onClick={() => {
                          if ([0, 1].includes(deviceData?.serialStatus))
                            updateBlockStatus(2);
                          else updateBlockStatus(0);
                        }}
                        variant='contained'>
                        {[0, 1].includes(deviceData?.serialStatus)
                          ? `Block `
                          : `Unblock `}
                        Serial
                      </Button>
                    )}
                  </span>
                </Grid>
              </Grid>
            )}
          </AccordionContainer>
          <AccordionContainer
            disabled={activeTab !== 2}
            title='Product Registration'
            formButton={
              <Button
                variant='outlined'
                color='primary'
                disabled={activeTab !== 2}
                onClick={handleSubmit}>
                Save
              </Button>
            }>
            {deviceData && (
              <ValidatorForm
                onSubmit={e => {
                  e.preventDefault();
                }}>
                <Grid
                  container
                  spacing={3}
                  style={{
                    padding: "0px 16px",
                  }}>
                  <Grid>
                    <SerialItemDetail deviceData={deviceData} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      }}>
                      <Grid item xs={6}>
                        <span className={"labelStyle"}>
                          Registration Status
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <Dropdown
                          // selectlabel='Brand'
                          disabled={activeTab !== 2}
                          customMenuItems={[
                            {
                              id: 5,
                              name: SerialsStatus[5],
                            },
                            {
                              id: 1,
                              name: SerialsStatus[1],
                            },
                            {
                              id: 0,
                              name: SerialsStatus[0],
                            },
                            {
                              id: 6,
                              name: SerialsStatus[6],
                            },
                            {
                              id: 3,
                              name: SerialsStatus[3],
                            },
                          ]}
                          value={receiptStatus ?? 3}
                          handleChange={({ target }) =>
                            setReceiptStatus(target.value)
                          }
                        />
                      </Grid>
                    </div>
                    <Grid item xs={12} className={classes.gridWrapper}>
                      <div className={classes.ownedBy}>{`Owned By`}</div>
                      <div
                        className={
                          classes.ownedBy
                        }>{`Name: ${deviceData?.userName}`}</div>
                      <div
                        className={
                          classes.emailByBottom
                        }>{`Email: ${deviceData?.email}`}</div>
                    </Grid>

                    <Grid item xs={12} className={classes.gridWrapper}>
                      <InputField
                        inputlabel='Serial Number'
                        id='SerialNumber'
                        name='SerialNumber'
                        disabled
                        validators={["required"]}
                        errorMessages={["Please enter product sku"]}
                        value={deviceData?.serialNo}
                        // onChange={({ target }) =>
                        //   sepProductProp({
                        //     propName: "productSKU",
                        //     value: target.value
                        //   })
                        // }
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridWrapper}>
                      {activeTab !== 2 ? (
                        <InputField
                          inputlabel='Purchase Location'
                          disabled
                          value={deviceData?.dealer}
                        />
                      ) : (
                        <Grid item xs={12}>
                          <span className={"labelStyle"}>
                            Purchase Location
                          </span>
                          <AutoComplete
                            value={purchaseLoc}
                            onChange={e => {
                              debouncedChangeHandler(e, 0);
                              setPurchaseLoc(e.target.value);
                            }}
                            eventLocation={dealers}
                            setEvent={v => {
                              setPurchaseLoc(v.value);
                              setDealer(v.companyId);
                            }}
                            showLocationName={true}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={`${classes.gridWrapper} ${classes.datePicker}`}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className='inputWrapper'>
                          <FormControl className={classes.formControl}>
                            <span className={"labelStyle"}>Purchase Date</span>
                            <DatePicker
                              autoOk
                              format='MMMM dd, yyyy'
                              value={new Date(deviceInfo?.purchaseDate)}
                              disabled={activeTab !== 2}
                              onChange={value => {
                                sepItemProp({
                                  propName: "purchaseDate",
                                  value: value,
                                });
                              }}
                            />
                          </FormControl>
                        </div>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    
                      <Grid item xs={12} className={`${classes.gridWrapper} productSerialNotes`}>
                      <span className="labelStyle">Note</span>
                      <EmptyTextarea
                        inputlabel='Note'
                        id='note'
                        name='note'
                        className='overviewTextarea'
                        placeholder='Write Notes'
                        disabled={activeTab !== 2}
                        validators={["required"]}
                        errorMessages={["Please enter product notes"]}
                        value={note}
                        onChange={({ target }) => setNote(target.value)}
                      />
                    </Grid>
                    
                    <Grid item xs={12} className={classes.gridWrapper}>
                      <span
                        className={"labelStyle"}
                        style={{ marginBottom: 0 }}>
                        Purchase Receipt
                      </span>
                      <div>
                        {deviceData?.receipt && (
                          <span style={{ marginRight: "10px" }}>
                            <Button
                              color='primary'
                              className={classes.buttonStyle}
                              disabled={activeTab !== 2 && !deviceData?.receipt}
                              onClick={() => setViewReceipt(!viewReceipt)}
                              variant='contained'>
                              View
                            </Button>
                          </span>
                        )}
                        <span>
                          <Button
                            variant='outlined'
                            color='primary'
                            disabled={activeTab !== 2}
                            className={classes.buttonStyle}
                            onClick={() =>
                              setShowImageUploader(!showImageUploader)
                            }>
                            {deviceData?.receipt ? "Replace" : "Attach Receipt"}
                          </Button>
                        </span>

                        <PopupDialog
                          // maxWidth='lg'
                          visible={viewReceipt}
                          onClose={() => setViewReceipt(!viewReceipt)}>
                          {deviceData?.receipt?.includes(".pdf") ? (
                            <Document file={deviceData?.receipt}onLoadSuccess={onPdfLoad}>
                            {pageNumber.map(page => 
                              <Page pageNumber={page} />
                            )}
                            </Document>
                          ) : (
                            <img
                              alt='Receipt'
                              className={classes.receiptImage}
                              src={deviceData?.receipt
                                  ? `${deviceData?.receipt}`
                                  : `${noImage}`
                              }
                            />
                          )}
                        </PopupDialog>

                        <ImageUploader
                          title={"Select Receipt"}
                          includeMediaListing={false}
                          showFeatureImage={showImageUploader}
                          setShowFeatureImage={setShowImageUploader}
                          onImageSelect={img => updateReceipt({ receipt: img })}
                          infoText="Images are supported only. (JPEG, JPG, PNG and PDF)"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </ValidatorForm>
            )}
          </AccordionContainer>
          <AccordionContainer disabled={activeTab !== 3} title='Activity'>
            <Activities listData={activites} />
          </AccordionContainer>
          <AccordionContainer disabled={activeTab !== 4} title='Notes'>
            <Notes
              noteText={noteText}
              setNoteText={setNoteText}
              notes={notes}
              disabled={activeTab !== 4}
              onSend={cmnt => handleCommnetSubmit(cmnt)}
            />
          </AccordionContainer>
        </div>
      )
    );
  }
);

const SerialItemDetail = memo(({ isSold, deviceData }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <span>
        <img
          className={deviceData?.basePath ? "prodSerialImage" : "noImageSerial"}
          src={
            deviceData?.basePath
              ? `${deviceData?.basePath}`
              : noImage
          }
          alt={deviceData?.product}
        />
      </span>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "30px",
        }}>
        <h1>{deviceData?.serialNo}</h1>
        <span
          style={{
            fontSize: "20px",
            fontWeight: "700",
          }}>
          {deviceData?.product}
        </span>
        <span
          style={{
            fontSize: "16px",
            fontWeight: "400",
            marginTop: "5px",
            marginBottom: "5px",
          }}>
          {deviceData?.productType}
        </span>
        <span
          style={{
            fontSize: "16px",
            color: "#8D99A5",
            marginBottom: "10px",
          }}>
          Speakers
        </span>
        {isSold && (
          <span>
            <Button
              color='primary'
              disabled
              // onClick={() => history.push("/new-product")}
              variant='contained'>
              SOLD
            </Button>
          </span>
        )}
      </span>
    </div>
  );
});

export { ProductSerial };
