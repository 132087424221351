import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Delete, EditIcon, Trash } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { BsPlus } from "react-icons/bs";
import { Paper, Button } from "@material-ui/core";
import FamilyNFriendForm from "./FamilyNFriendForm";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";
import { DeleteFilesOverlay } from "../../../../components/common/Modal/AssetsModals";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridActions:{
    display:"flex",
    alignItems:"center",
    justifyContent: "flex-start",
    marginRight: "0 !important",
   '& span':{
    cursor:"pointer",
   }
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function FriendFamilyGrid({
  userId,
  listFnFData,
  setListFnFData,
  loadFFGridData,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [isNewUser, setIsNewUser] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [devicesData, setDevicesData] = useState([]);
  const [isDeletedItem , setIsDeletedItem] = useState(null);

  useEffect(() => {
    if (userId) {
      loadUserDevices();
    }
  }, [userId]);

  const loadUserDevices = () => {
    dispatch(endUserActions.onGetFFDeviceList(userId)).then(data => {
      if (data?.success) {
        setDevicesData(data?.data);
      }
    });
  };

  const onDeletePress = ffId => {
    setIsDeletedItem(false);
    setLoading(true);
    dispatch(endUserActions.onDeleteFrndAndFmly(ffId)).then(data => {
      setLoading(false);
      if (data?.success) {
        loadFFGridData();
        toast.success("Contact deleted successfully");
      } else {
        toast.error(data?.message);
      }
    });
  };

  const capitalizeFirstLetter = (value) => {
    if (!value) return ""; // Handle empty values gracefully
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const classes = useStyles();

  return (
    <Paper className='addNewBtnWrapper'>
      {isLoading ? <Loader /> : null}
     <div className='gap' />
      <Button
        color='primary'
        variant='contained'
        onClick={() => {
          setIsNewUser(true);
          setIsEdit(false);
          setEditItem(null);
        }}>
        <BsPlus /> New Users
      </Button>
     
      <div className='addressGirdWrapper friendsFamilyGridWrapper'>
        <div className='addressGrid'>
          <div className='gridHeaader'>
            <ul>
              <li>Contact Name</li>
              <li> Access</li>
              <li>E-mail Address</li>
              <li>Actions</li>
            </ul>
          </div>

          <div className='gridBody'>
            {listFnFData?.map((item, index) => {
              return isEdit && editItem.id === item.id ? (
                <FamilyNFriendForm
                  key={index}
                  setIsEdit={() => setIsEdit(false)}
                  devicesData={devicesData}
                  editItem={editItem}
                  userId={userId}
                  loadFFGridData={() => loadFFGridData()}
                  startLoading={() => setLoading(true)}
                  stopLoading={() => setLoading(false)}
                />
              ) : (
                <ul>
                  <li className={classes.gridBodyInner}>{capitalizeFirstLetter(item.name)}</li>
                  <li className={classes.gridBodyInner}>Full</li>
                  <li className={classes.gridBodyInner}>e-mail@address.com</li>
                  <li className={classes.gridBodyInner}>
                    <div
                      className={classes.gridActions}>
                      <span
                        onClick={() => {
                          setIsNewUser(false);
                          setIsEdit(true);
                          setEditItem(item);
                        }}>
                        <EditIcon />
                      </span>
                        <span onClick={() => setIsDeletedItem(item.id)}>
                          <Delete />
                      </span>
                    </div>
                  </li>
                </ul>
              );
            })}
            {listFnFData?.length === 0 && (
              <span className='emptyGridText emptyGridData'>
                You haven’t added any OFish Users. Start by adding a{" "}
                <i
                  onClick={() => {
                    setIsNewUser(true);
                    setIsEdit(false);
                    setEditItem(null);
                  }}>
                  New User
                </i>
              </span>
            )}
          </div>
        </div>
      </div>

      {isNewUser && (
        <FamilyNFriendForm
          setIsEdit={() => {
            setIsEdit(false);
            setIsNewUser(false);
          }}
          devicesData={devicesData}
          userId={userId}
          loadFFGridData={() => loadFFGridData()}
          startLoading={() => setLoading(true)}
          stopLoading={() => setLoading(false)}
        />
      )}
      {isDeletedItem ? (
        <DeleteFilesOverlay
          heading={"Delete Family and Friends Contact"}
          openDeleteFilesOverlay={isDeletedItem}
          setOpenDeleteFilesOverlay={value => {
            setIsDeletedItem(value);
          }}
          deleteAssetFiles={() => {
            onDeletePress(isDeletedItem);
          }}
          overlayText1={"Are you sure you want to Delete Family and Friends Contact?"}
          overlayText2={""}
          buttonText={"Delete"}
        />
      ) : null}
    </Paper>
  );
}

export default FriendFamilyGrid;
