import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Button, TableSortLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditIcon, SortIcon, ViewEye } from "../../LeftNav/SvgIcons";
import { RoutesObj } from "../../../../routes";
import { productSerialActions } from "../../../../store/product/productSerialsAction";
import { Visibility } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  registeredButton: {
    color: "#219653",
    backgroundColor: "rgba(33, 150, 83, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(33, 150, 83, 0.25)",
    },
  },
  blockedButton: {
    color: "#EB5757",
    backgroundColor: "rgba(235, 87, 87, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(235, 87, 87, 0.25)",
    },
  },
  missingButton: {
    color: "#56CCF2",
    backgroundColor: "rgba(86, 204, 242, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(86, 204, 242, 0.25)",
    },
  },
}));

const getStatusClass = itemStatus => {
  if (itemStatus === "Not Registered") {
    return "pendingButtons";
  } else if (itemStatus === "Registered") {
    return "registeredButton";
  } else if (itemStatus === "Blocked") {
    return "blockedButton";
  } else {
    return "missingButton";
  }
};

function SerialsTabProductSerials({
  isBlockedSerialsGrid,
  listData,
  loadData,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.ProductSerial.path,
      state: { device: value },
      search: `id=${value.id}`,
    });
  };

  const onItemSelect = itemId => {
    const itemExists =
      selectedItems.length > 0 && selectedItems.find(c => c === itemId);
    if (itemExists) {
      setSelectedItems(selectedItems.filter(c => c !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const deleteItems = () => {
    dispatch(
      productSerialActions.onDeleteItems({
        ids: selectedItems,
      })
    ).then(() => {
      loadData();
      setSelectedItems([]);
    });
  };

  const unBlockSerial = ({ deviceData }) => {
    dispatch(
      productSerialActions.onUpdateBlockSerial({
        id: deviceData.id,
        status: 0,
      })
    ).then(data => {
      if (data && data.success) {
        loadData();
      } else if (!data?.success) {
        toast.error(data.message);
      } else {
        toast.warning(`API isn't working`);
      }
    });
  };

  return (
    <Paper>
      {selectedItems.length > 0 ? (
        <Paper className={classes.gridTopNav}>
          <Grid item md={9} className={classes.deleteItems}>
            <span className='gridDeleteItems' onClick={() => deleteItems()}>
              <DeleteIcon /> Delete
            </span>
          </Grid>
        </Paper>
      ) : null}
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont} disabled={true} />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              SERIAL NUMBER
              <TableSortLabel
                active={order.orderByKey === "SerialNo"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "SerialNo",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PO#
              <TableSortLabel
                active={order.orderByKey === "PO"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "PO",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            {!isBlockedSerialsGrid && (
              <TableCell className={classes.gridHeaderInner}>
                STATUS
                <TableSortLabel
                  active={order.orderByKey === "Status"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Status",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
            )}
            <TableCell className={classes.gridHeaderInner}>
              {isBlockedSerialsGrid ? "REASON" : "OWNER"}
            </TableCell>
            {
              <TableCell
                className={
                  (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
                }>
                QUICK ACTIONS
              </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {listData?.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridHeaderInner}>
                  <Checkbox
                    className={classes.checkBoxFont}
                    onClick={() => onItemSelect(item.id)}
                    checked={
                      selectedItems.length > 0 &&
                      selectedItems?.includes(item.id)
                    }
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.serialNo}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.po || "N/A"}
                </TableCell>
                {!isBlockedSerialsGrid && (
                  <TableCell className={classes.gridBodyInner}>
                    {item.status ? (
                      <Button
                        variant='contained'
                        className={classes[getStatusClass(item.status)]}>
                        {item.status}
                      </Button>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                )}
                <TableCell className={classes.gridBodyInner}>
                  {!isBlockedSerialsGrid
                    ? item?.owner || "N/A"
                    : item.reason || "N/A"}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions productsAction'>
                    {isBlockedSerialsGrid ? (
                      <Button
                        variant='outlined'
                        style={{
                          color: "#E06145",
                          borderColor: "#E06145",
                        }}
                        onClick={() => unBlockSerial({ deviceData: item })}>
                        Unblock
                      </Button>
                    ) : (
                      <>
                        <span>
                          <ViewEye />
                        </span>
                        <span onClick={() => redirectUrl(item)}>
                          <EditIcon />
                        </span>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

export { SerialsTabProductSerials };
