import React, { useEffect, useState } from "react";
import { Close, Search, Clear } from "@material-ui/icons";
import { Button, Checkbox, Grid, Input, makeStyles } from "@material-ui/core";
import { concat } from "lodash";
import { PopupDialog } from "../../../../components/common";
import { useDebounce } from "../../../../utils/use-debounce";

const MultiSelectReleaseCategories = ({
  isVisible,
  onClose,
  releaseCategories,
  onSelect,
  slectedReleaseCategories,
}) => {
  const [filteredList, setFilteredList] = useState([]);
  const [filterByName, setFilterByName] = useState("");
  const { debouncedValue } = useDebounce(filterByName, 500);

  const [slectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (debouncedValue) {
      setFilteredList([
        ...releaseCategories.filter(c =>
          c.value.toLowerCase().includes(debouncedValue.toLowerCase())
        ),
      ]);
    } else {
      setFilteredList(releaseCategories);
    }
  }, [debouncedValue, releaseCategories]);

  useEffect(() => {
    setFilteredList(releaseCategories);
  }, [releaseCategories]);

  useEffect(() => {
    setSelectedCategories([...slectedReleaseCategories]);
  }, [slectedReleaseCategories]);

  return (
    <PopupDialog visible={isVisible} onClose={onClose}>
      <div className="audiancePopupWrapper">
        <div className="audiancePopupInnerWrapper">
          <span className="innerHeading">News Release Categories</span>
          <Close
            style={{
              cursor: "pointer",
              padding: "20px",
            }}
            onClick={onClose}
          />
        </div>
        <Grid item xs={12} style={{ paddingLeft: "13px" }}>
          <Input
            fullWidth
            placeholder="Search by name"
            disableUnderline
            style={{
              padding: "17px",
            }}
            startAdornment={
              <Search
                style={{
                  marginRight: "5px",
                }}
              />
            }
            onChange={({ target }) => setFilterByName(target.value)}
          />
        </Grid>
        {filteredList?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "264px",
              overflowY: "auto",
            }}>
            {filteredList.map(g => {
              return (
                <span className="innerList">
                  <Checkbox
                    key={g.id}
                    label={g.value}
                    checked={
                      slectedCategories.find(s => s === parseInt(g.id))
                        ? true
                        : false
                    }
                    onChange={({ target }) => {
                      if (target.checked) {
                        setSelectedCategories([
                          ...concat(slectedCategories, parseInt(g.id)),
                        ]);
                      } else {
                        setSelectedCategories([
                          ...slectedCategories.filter(
                            s => s !== parseInt(g.id)
                          ),
                        ]);
                      }
                    }}
                  />
                  {g.value}
                </span>
              );
            })}
          </div>
        ) : (
          <span
            style={{
              margin: "20px",
              display: "flex",
            }}>
            No release category available
          </span>
        )}
        {filteredList?.length > 0 && (
          <div className="audianceBtnWrapper">
            <Button
              variant="contained"
              color="primary"
              //   disabled={tempVisibilityGroups.length <= 0}
              onClick={() => onSelect(slectedCategories)}>
              Select
            </Button>
          </div>
        )}
      </div>
    </PopupDialog>
  );
};

const ReleaseCategoryChip = ({
  category,
  mainReleaseCategory,
  setMainReleaseCategory,
  onRemove,
}) => {
  const classes = useStyles();

  return (
    <span className={classes.chipWrapper}>
      <span>{category?.value}</span>
      <Checkbox
        key={category.id}
        checked={mainReleaseCategory === category?.id}
        onChange={({ target }) => {
          setMainReleaseCategory(target.checked ? category.id : null);
        }}
      />
      <span
        className={classes.deleteLabels}
        onClick={() => onRemove(category?.id)}>
        <Clear />
      </span>
    </span>
  );
};

const useStyles = makeStyles(theme => ({
  chipWrapper: {
    backgroundColor: "#F3F6F9",
    borderRadius: "36px",
    color: "#70808F",
    fontSize: "14px",
    fontWeight: "400",
    padding: "0 15px",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 7px 10px 7px",
    display: "inline-flex",
  },
  deleteLabels: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#A9B2BC",
  },
}));

export { MultiSelectReleaseCategories, ReleaseCategoryChip };
