import instance from "axios";
import Cookies from "js-cookie";

let cancelToken = instance.CancelToken;
let source = cancelToken.source();
const axiosInstance = instance.create({
  cancelToken: source.token,
});

axiosInstance.interceptors.request.use(config => {
  let token = {
    access_token: localStorage.getItem("access_token"),
  };
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  var defaultHeaders = {
    "api-version": "1.0",
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    timezone: timeZone,
    "User-Platform": "Ofish-Web",
  };
  if (token.access_token) {
    config.headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${token.access_token}`,
    };
  } else {
    config.headers = defaultHeaders;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  response => {
    if (response && response.headers && response.headers.newtoken) {
      localStorage.setItem("access_token", response.headers.newtoken);
      Cookies.set("access_token", response.headers.newtoken);
    }
    return response;
  },
  error => {
    const { status } = error.response;
    if (status === 401 || status === 500) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("name");
      localStorage.removeItem("lName");
      localStorage.removeItem("id");
      window.location = "/";
    }
    if (status === 403) {
      return {
        status: 200,
        data: { success: false, message: "You can not perform this action." },
      };
    }
    return error.response;
  }
);

export const axios = axiosInstance;
