import React from "react";
import { Grid } from "@material-ui/core";
import {
  LogHeader,
  MainHeading,
} from "../../../components/common/Header/index";
import { useSelector } from "react-redux";
import moment from "moment";
import Cards from "../../../components/common/Cards";

function LogAllLatestAnnouncenments(props) {
  const { releases } = useSelector((state) => state.PubReleaseReducer);

  return (
    <div className="latestReleaseDetailWrapper">
      <LogHeader />
      <MainHeading headerSubHeading="All Latest Announcenments">
        <div className="popupBody">
          <div className="cards">
            <Grid container spacing={3}>
              {releases &&
                releases.length > 0 &&
                releases.map((item, index) => (
                  <Grid key={index} item md={4}>
                    <Cards
                      picture={
                        item.asset
                          ? item.asset.url
                          : "/static/images/cards/contemplative-reptile.jpg"
                      }
                      subheading={item.title}
                      cardtext={item.shortDescription}
                      date={moment(item.preReleaseDate).format(
                        "MMMM, DD, YYYY"
                      )}
                      // onClick={onDdetail}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </MainHeading>
    </div>
  );
}

export default LogAllLatestAnnouncenments;
