import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { pubMediaLibraryActions } from "../../../store/pubMediaLibrary/pubMediaLibraryActions";
import { pubReleaseActions } from "../../../store/pubRelease/pubReleaseActions";
import CustomCheckbox from "../../../components/common/checkbox/CustomCheckbox";
import RecentReleases from "../../../components/common/RecentReleases";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";

function MediaLibrarFilter({
  startLoading,
  stopLoading,
  showButton,
  hideButton,
  loadData,
  searchPage,
  onChange,
  value,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDotFill, setIsDotFill] = useState(false);
  const [allDotData, setAllDotData] = useState([]);
  const [displayDotData, setDisplayDotData] = useState([]);

  useEffect(() => {
    const releaseData = {
      releaseDate: 0,
      categoryIds: [],
      take: 4,
      skip: 0,
      title: "",
      dataType: ["LatestOnly"],
    };
    dispatch(pubReleaseActions.onGetPubRelWithCategory(releaseData)).then(
      res => {
        if (res && res.success && res.data?.length > 0) {
          if (res.data[0].data?.length > 0) {
            setAllDotData(res.data[0].data);
            setDisplayDotData(res.data[0].data.slice(0, 2));
          }
        }
      }
    );
  }, [dispatch]);

  const selectPMLCategory = value => {
    startLoading();
    dispatch(
      pubMediaLibraryActions.onSelectPubMedCategory(value, selectedPMCategory)
    ).then(() => {
      stopLoading();
    });
    opPresFindBtn();
  };

  const checkMLCategory = itemId => {
    const mlcExist = selectedPMCategory.find(mediaCat => {
      return mediaCat.id == itemId;
    });
    if (mlcExist) {
      return true;
    } else {
      return false;
    }
  };

  const selectMedDateFilter = value => {
    dispatch(pubMediaLibraryActions.onSelectPubMedDateFilter(value));
    opPresFindBtn();
  };
  const checkMedDateFilter = itemName => {
    const medExist = selectedPubMedDateFilter.find(medFilter => {
      return medFilter.id == itemName;
    });
    if (medExist) {
      return true;
    } else {
      return false;
    }
  };

  const opPresFindBtn = () => {
    if (selectedPMCategory.length > 0) {
      startLoading();
      let selectedCat = [];
      let selectedDate = 0;
      _.forEach(selectedPMCategory, function (catId) {
        selectedCat.push(parseInt(catId.id));
      });
      const dateAll = selectedPubMedDateFilter.find(result => {
        return result.id === "0";
      });
      const dateYear = selectedPubMedDateFilter.find(result => {
        return result.id === "4";
      });
      const date6Month = selectedPubMedDateFilter.find(result => {
        return result.id === "3";
      });
      const date3Month = selectedPubMedDateFilter.find(result => {
        return result.id === "2";
      });
      const dateMonth = selectedPubMedDateFilter.find(result => {
        return result.id === "1";
      });
      if (dateAll) {
        selectedDate = dateAll.id;
      } else if (dateYear) {
        selectedDate = dateYear.id;
      } else if (date6Month) {
        selectedDate = date6Month.id;
      } else if (date3Month) {
        selectedDate = date3Month.id;
      } else if (dateMonth) {
        selectedDate = dateMonth.id;
      } else {
        selectedDate = 0;
      }
      const filterPayload = {
        collectionDate: selectedDate,
        categoryIds: selectedCat,
        take: 100,
        skip: 0,
        title: "",
        dataType: ["CategoryOnly"],
      };
      dispatch(pubMediaLibraryActions.onGetPubAllAssets(filterPayload)).then(
        res => {
          stopLoading();
          hideButton();
        }
      );
    } else {
      // toast.error("Please select category first");
      loadData();
    }
  };

  // const onClearFilter = () => {
  //   loadData();
  //   showButton();
  //   dispatch(pubMediaLibraryActions.onChange("selectedPMCategory", []));
  //   dispatch(pubMediaLibraryActions.onChange("selectedPubMedDateFilter", []));
  // };

  const enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      searchPage();
    }
  };

  const onDotClick = value => {
    setIsDotFill(!isDotFill);
    if (value === 1) {
      setDisplayDotData(allDotData.slice(0, 2));
    } else if (value === 2) {
      setDisplayDotData(allDotData.slice(2, 4));
    }
  };

  const onViewRelease = item => {
    if (item.urlSlug) {
      history.push("/releases/category/" + item.urlSlug);
    } else {
      toast.error("No record found");
    }
  };

  const {
    pubMediaCategory,
    medDateFilter,
    selectedPMCategory,
    selectedPubMedDateFilter,
  } = useSelector(state => state.pubMediaLibraryReducer);

  return (
    <>
      <div className='filterBar'>
        <h2>Find Media Asset</h2>
        <span className='searchBar'>
          <SearchIcon onClick={searchPage} />
          <input
            type='text'
            placeholder='Search media library'
            onKeyPress={e => enterPressed(e)}
            onChange={onChange}
            value={value}
          />
        </span>

        <h2>
          Collection Categories
          {selectedPMCategory?.length > 0 && (
            <Button
              color='primary'
              variant='outlined'
              onClick={() => {
                history.push("/media-library");
                loadData();
                showButton();
                dispatch(
                  pubMediaLibraryActions.onChange("selectedPMCategory", [])
                );
              }}>
              Clear
            </Button>
          )}
        </h2>

        <ul>
          {pubMediaCategory &&
            pubMediaCategory.length > 0 &&
            pubMediaCategory.map((item, index) => (
              <>
                {item.name === "No Category" && item.count === 0 ? null : (
                  <li key={index}>
                    <span>
                      {/* <Checkbox
                      onClick={() => selectPMLCategory(item)}
                      checked={checkMLCategory(item.id)}
                    /> */}
                      <CustomCheckbox
                        checkedId={`mediaCategoryCheckId-${index}`}
                        pressCheck={() => selectPMLCategory(item)}
                        checkValue={checkMLCategory(item.id)}
                      />
                      {item.name}
                    </span>
                    <span>{item.count}</span>
                  </li>
                )}
              </>
            ))}
        </ul>

        <span className='gap'>&nbsp;</span>
        <h2>
          Media Collection Created{" "}
          {selectedPubMedDateFilter?.length > 0 && (
            <Button
              color='primary'
              variant='outlined'
              onClick={() =>
                dispatch(
                  pubMediaLibraryActions.onChange(
                    "selectedPubMedDateFilter",
                    []
                  )
                )
              }>
              Clear
            </Button>
          )}
        </h2>
        <ul>
          {medDateFilter &&
            medDateFilter.length > 0 &&
            medDateFilter.map((item, index) => (
              <li key={index}>
                <span>
                  {/* <Checkbox
                  onClick={() => selectMedDateFilter(item)}
                  checked={checkMedDateFilter(item.id)}
                /> */}
                  <CustomCheckbox
                    checkedId={`mediaDateCheckId-${index}`}
                    pressCheck={() => selectMedDateFilter(item)}
                    checkValue={checkMedDateFilter(item.id)}
                  />
                  {item.value}
                </span>
                <span>{item.generic}</span>
              </li>
            ))}
        </ul>
        {/* <div className="filterFooter">
          <Button
            color="primary"
            variant="contained"
            onClick={() => opPresFindBtn()}>
            Find
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onClearFilter()}>
            Clear
          </Button>
        </div> */}
      </div>

      <div className='filterBar'>
        <h2 style={{ margin: "0" }}>Recent Releases</h2>
        <span className='ownerName'>
          By Aquatic AV
          <span className='dots'>
            <ul>
              <li
                className={`${!isDotFill ? "dotFill" : ""}`}
                onClick={() => onDotClick(1)}
              />
              {allDotData && allDotData.length > 2 && (
                <li
                  className={`${isDotFill ? "dotFill" : ""}`}
                  onClick={() => onDotClick(2)}
                />
              )}
            </ul>
          </span>
        </span>
        {displayDotData &&
          displayDotData.length > 0 &&
          displayDotData.map(item => {
            return (
              <RecentReleases
                picture={item.imageUrl}
                heading={item.title}
                eventdate={moment(item.announcementDate).format(
                  "MMMM DD, yyyy"
                )}
                description={item.subTitle}
                onPressView={() => onViewRelease(item)}
              />
            );
          })}
      </div>
    </>
  );
}

export default MediaLibrarFilter;
