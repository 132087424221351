import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import StarIcon from "../../../../resources/images/star.png";
import { starOutline } from "../../../../resources/images/index";
import { contactsTypesActions } from "../../../../store/contactTypes/contactTypesActions";
import { EditFieldWithRadioPopup } from "./Modals/EditFieldWithRadioPopup";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

export default function DataFields({ searchText, areaId }) {
  const dispatch = useDispatch();
  const { dataField, refreshData } = useSelector(state => state.pubContactType);

  const [isLoading, setIsLoading] = useState(false);
  const [dataFields, setDataFields] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fieldSetting, setFieldSetting] = useState();
  const [openEditFieldWithRadioPopup, setOpenEditFieldWithRadioPopup] =
    useState(false);
  //const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    dispatch(contactsTypesActions.onGetAllContactDataFields(areaId)).then(
      data => {
        setDataFields(data?.data?.data);
      }
    );
  }, []);

  useEffect(() => {
    dispatch(contactsTypesActions.onGetAllContactDataFields(areaId)).then(
      data => {
        setDataFields(data?.data?.data);
        var filterData = [];
        data?.data?.data?.map(x => {
          if (x.isFavourite === true) {
            return filterData.push({
              ...x,
              type: "data",
            });
          }
        });
        dispatch(
          contactsTypesActions.onChange("favoritesDataField", filterData)
        );
      }
    );
  }, [refreshData]);

  useEffect(() => {
    if (dataFields?.length > 0) {
      setFilteredData(
        dataFields?.filter(value =>
          value.fieldName.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else if (!searchText || searchText !== "") {
      setFilteredData(dataFields);
    }
  }, [dataFields, searchText]);

  const onAddUpdateMarkFavouriteContactTypes = (checked, id) => {
    setIsLoading(true);
    var updatedDataFields = dataFields?.map(x => {
      if (x.id === id) {
        x.isFavourite = checked;
      }
      return x;
    });
    setDataFields(updatedDataFields);
    const data = [
      {
        contactTypeId: id,
        isFavourite: checked,
      },
    ];

    dispatch(
      contactsTypesActions.onAddUpdateMarkFavouriteContactTypes(data)
    ).then(res => {
      setIsLoading(false);
      if (!res?.data?.success) {
        toast.error(res?.data?.message);
      }
      //setRefreshData(!refreshData);
      dispatch(contactsTypesActions.onChange("refreshData", !refreshData));
    });
  };

  return (
    <>
      <Grid container className='contactTypesMainWrapper'>
        {isLoading && <Loader />}
        {filteredData?.map((item, index) => {
          return (
            <Grid item className='mainGrid' key={index}>
              <div className='dataFieldmainWrapper'>
                <div className='dataFieldName'>
                  <h4>
                    {item.fieldName} <span>*</span>
                    <span className='defaultValue'>
                      {item.default === true ? "OFish Default" : ""}
                    </span>
                    <span
                      onClick={() => {
                        onAddUpdateMarkFavouriteContactTypes(
                          !item.isFavourite,
                          item.id
                        );
                      }}>
                      {item.isFavourite === true ? (
                        <img src={StarIcon} alt={"StarIcom"} />
                      ) : (
                        <img src={starOutline} alt={"StarIcom"} />
                      )}
                    </span>
                  </h4>
                  <p className='dataFieldDescription'>{item.description}</p>
                </div>
                <div>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={
                      dataField.find(x => x.fieldId === item.id) ? true : false
                    }
                    startIcon={
                      dataField.find(x => x.fieldId === item.id) ? null : (
                        <Add />
                      )
                    }
                    onClick={() => {
                      const tempDataConnection = dataField;
                      let isExist = tempDataConnection.find(
                        x => x.fieldId === item.id
                      );
                      if (isExist) {
                      } else {
                        tempDataConnection.push({
                          fieldId: item.id,
                          fieldValue: item.fieldName,
                          contactDataFieldSettingInfo: [],
                        });
                      }
                      dispatch(
                        contactsTypesActions.onChange(
                          "dataField",
                          tempDataConnection
                        )
                      );
                    }}>
                    {dataField.find(x => x.fieldId === item.id)
                      ? "Added"
                      : "Add"}
                  </Button>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      {/* <EditTermsAndConditionFieldPopup
        dataFields={dataFields}
        open={openEditTermsAndConditionFieldPopup}
        setOpenEditTermsAndConditionFieldPopup={
          setOpenEditTermsAndConditionFieldPopup
        }
      /> */}
      <EditFieldWithRadioPopup
        open={openEditFieldWithRadioPopup}
        setOpenModulesModal={setOpenEditFieldWithRadioPopup}
        fieldSetting={fieldSetting}
        setFieldSetting={setFieldSetting}
      />
    </>
  );
}
