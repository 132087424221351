import React, { useState } from "react";
import { Button } from "@material-ui/core";
import moment from "moment";
import { Checkbox } from "@material-ui/core";
import { LightenDarkenColor } from "lighten-darken-color";

function ConversationList({
  checkbox,
  star,
  staroutline,
  pic,
  name,
  messagesubject,
  onclickHandler,
  date,
  key,
  isStar,
  setIsStar,
  selectedRecord,
  isChecked,
  lastMessage,
  campaignId,
  statusId,
  color,
}) {
  const localGMT = new Date(date?.date);
  return (
    <div className="conversationListWrapper fsfsdfsfs" key={key}>
      <div className="conversationRowClick" onClick={onclickHandler} />
      <div className="innerWrapper">
        <div className="startCheckboxWrapper">
          {/* <Checkbox
            style={{ fontSize: "22px" }}
            onClick={() => selectedRecord()}
            checked={isChecked}
          /> */}
          <span>{checkbox}</span>
          <span className="" onClick={() => setIsStar(!isStar)}>
            {!isStar ? (
              <img src={staroutline} alt="" />
            ) : (
              <img src={star} alt="" />
            )}
          </span>

          <div className="userNamenPicWrapper">
            <span className="userPicture">
              <img src={pic} alt="" />
            </span>
            <i className="socialIcons"></i>
            <span className="chatUserDetail">
              {name} <i>{messagesubject}</i>
              <i>
                {statusId === 1
                  ? "Open"
                  : statusId === 2
                  ? "Draft"
                  : statusId === 3
                  ? "Closed"
                  : statusId === 4
                  ? "Closed & Archived"
                  : ""}
              </i>
            </span>
          </div>
        </div>

        <div className="dealerButtonWrapper">
          <Button
            variant="outlined"
            color="primary"
            onClick={onclickHandler}
            style={{
              background: LightenDarkenColor(color ? color : "red", 140),
              color: `${color ? color : "red"}`,
            }}>
            {campaignId === 0
              ? "No Campaign"
              : campaignId === 1
              ? "General"
              : campaignId === 2
              ? "Press"
              : campaignId === 3
              ? "Facebook"
              : campaignId === 4
              ? "Support"
              : campaignId === 5
              ? "Sales"
              : "General"}
          </Button>

          <span className="dateNTime">
            {new Date(
              localGMT.setHours(localGMT.getHours() + 5)
            ).toLocaleString()}
            {/* {moment(date?.date).format("MMM DD")}
            <i>{moment(date?.date).format("h:mm a")}</i> */}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ConversationList;
