import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { folder } from "../../../../resources/images/index";
import { contactsActions } from "../../../../store/contacts/contactsActions";

const MoveContactPopup = ({
  closePopup,
  openPopup,
  startLoading,
  endLoading,
  loadData,
  groupData,
}) => {
  const dispatch = useDispatch();
  const { selectedAllContacts } = useSelector((state) => state.ContactsReducer);
  const [selectedContact, setSelectedContact] = useState("");

  const moveFiles = () => {
    startLoading();
    dispatch(
      contactsActions.onMoveContacts(selectedAllContacts, selectedContact.id)
    ).then((data) => {
      if (data && data.success) {
        closePopup();
        loadData();
      } else {
        endLoading();
      }
    });
  };

  const checkSelectedContact = (value) => {
    if (selectedContact.id === value.id) {
      return "moveSelectedFolder moveSelectedFolderHighlight";
    } else {
      return "moveSelectedFolder";
    }
  };

  return (
    <div>
      <Dialog
        open={openPopup}
        onClose={closePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="moveHeadrWrapper">
          Move 2 items to:
          <CloseIcon className="closePopup" onClick={closePopup} />
        </DialogTitle>
        <DialogContent className="addLaunchPopupWrapper zeroPadding">
          <DialogContentText id="alert-dialog-description">
            {groupData &&
              groupData.length > 0 &&
              groupData.map((item, index) => {
                return (
                  <div key={index} className="moveWrapper">
                    <div className={checkSelectedContact(item)}>
                      <span
                        className="moveItemSpan"
                        onClick={() => setSelectedContact(item)}
                      >
                        <span>{item.name}</span>
                      </span>
                    </div>
                  </div>
                );
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="popupFooter addCatFooter">
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={() => moveFiles()}
          >
            Move
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MoveContactPopup;
