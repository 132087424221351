import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { FolderIconLarge, PdFIcon, PdFIconLargeIcon, RcoveryIcon } from "../../LeftNav/SvgIcons";
import { PdfIconLarge } from "../../LeftNav/SvgIcons";
import { DocIconLarge } from "../../LeftNav/SvgIcons";
import {
  SortByFilter,
  TooltipDescription,
  TooltipPreview,
} from "../../../../components/common/Modal/AssetsModals";
import { star, starOutlineYellow } from "../../../../resources/images/index";
import { Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import VideocamIcon from '@material-ui/icons/Videocam';
import { Delete, MoveIcon } from "../../LeftNav/SvgIcons";
import moment from "moment";
import { SortByFilterIcon } from "../SpaProduct/SvgIcons";

export const AssetLibraryGridView = ({
  mediaAssetsData,
  setSelectedMediaFile,
  setFolderData,
  renderSelectedData,
  renderAllData,
  breadCrumbs,
  rightManuBar,
  closeTooltip,
  handleClick,
  handleClose,
  setCloseTooltip,
  markAsFav,
  isRenameAssest,
  renameAssetItem,
  setRenameAssetItem,
  saveRenameAssetItem,
  moveItem,
  selectedMoveItems,
  setSelectedMoveItems,
  selectedFolder,
  setSelectedFolder,
  cancelMoveFileAction,
  setOpenDeleteFilesOverlay,
  isMoveClicked,
  setIsMoveClicked,
  openGridViewOnUploadeAsset,
  isTrashedItem,
  recoverAssets,
  tabName,
  setSelectedItem,
  selectedItem,
}) => {
  const dispatch = useDispatch();
  const { selectedMediaAssets } = useSelector((state) => state.AssetsReducer);
  const [gridDataWithChecked, setGridDataWithChecked] = useState([]);
  const [isOpenSortByFilter, setIsOpenSortByFilter] = useState(null);
  const handleCheckboxChange = (checkedItem) => {
    const updatedGridData = gridDataWithChecked.map((item) => {
      if (item.id === checkedItem.id) {
        return { ...item, isChecked: !checkedItem.isChecked };
      }
      return item;
    });
    setGridDataWithChecked(updatedGridData);
  };

  useEffect(() => {
    const checkedData = mediaAssetsData.map((asset) => ({
      ...asset,
      isChecked: selectedMediaAssets.includes(asset.id),
    }));
    setGridDataWithChecked(checkedData);
  }, [mediaAssetsData, selectedMediaAssets]);

  const checkFileType = (fileType, url) => {
    if (fileType === "folder") {
      return <FolderIconLarge />;
    } else if (fileType?.toLowerCase() === "pdf") {
      return <PdfIconLarge />;
    } else if (fileType?.toLowerCase() === "doc" ||fileType?.toLowerCase() === "docx") {
      return <DocIconLarge />;
    } else if (fileType?.toLowerCase() === "mp4" || fileType === "3gp") {
      return (
        <div style={{ position: "relative" }}>
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(25,27,38,.5)",
              color: "#fff",
              width: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <VideocamIcon />
          </span>
          <video width="81px" height="66px">
            <source src={url} />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else if(fileType?.toLowerCase() === "png" || fileType?.toLowerCase() === "jpg" || fileType?.toLowerCase() === "jpeg"){
      return (
        <img
          className="assetsImgclipPath"
          src={url}
          width={46}
          height={61}
          alt=""
        />
      );
    }else{
      return(
        <span>
        <PdFIconLargeIcon style={{width:"50px" , height:"67px"}}/>
        </span>
      )
    }
  };

  const id = rightManuBar ? "customized-menu" : undefined;
  function truncate(input) {
    if (input && input.length > 15) {
      return input.substring(0, 13) + "...";
    }
    return input;
  }
  
  const getGridViewClasses = (item, moveItem, selectedMoveItems) => {
    if (
      (selectedFolder.id === item.id && item.type === "folder") ||
      (openGridViewOnUploadeAsset !== "" &&
        item.type === "folder" &&
        selectedFolder.id === item.id)
    ) {
      return "assetFolderGridSelect";
    } else if (moveItem?.id === item.id) {
      return "assetMainGridOnMoveSelect";
    } else if (selectedMoveItems?.includes(item.id)) {
      return "assetMainGridOnMoveSelect";
    } else if (item.isChecked === true) {
      return "assetMainGridOnChecked";
    } else if (
      !moveItem &&
      selectedMoveItems?.length === 0 &&
      openGridViewOnUploadeAsset === ""
    ) {
      return "assetMainGrid";
    } else if (item.type === "folder") {
      return "assetMainGrid";
    } else {
      return "assetMainGridOnMoveDisable";
    }
  };

  const onAssetsMove = () => {
    setSelectedMoveItems(selectedMediaAssets);
    setIsMoveClicked(false);
  };

  const sortedMediaAssets = gridDataWithChecked.slice().sort((a, b) => {
    if (a.type === "folder" && b.type !== "folder") {
      return -1;
    } else if (a.type !== "folder" && b.type === "folder") {
      return 1;
    } else {
      return 0;
    }
  });
  const openOverlayForDeleteAction = () => {
    setOpenDeleteFilesOverlay(true);
  };
  const openClickForSortByFilter = (event) => {
    setIsOpenSortByFilter(event.currentTarget);
  };

  return (
    <>
      <Grid className="assetsHeader">
        {openGridViewOnUploadeAsset === "openGridViewOnUploadeAsset" ? (
          <Grid className="assetGridMoveToFileHeading">
            Choose an Upload Location:
          </Grid>
        ) : (moveItem === null) & (selectedMoveItems?.length === 0) ? (
          <Grid className="assetGridHeading">
            {tabName === "Your Asset Library" ? "Asset Library Home" : tabName}
          </Grid>
        ) : (
          <Grid className="assetGridMoveToFileHeading">Move File To:</Grid>
        )}
        <Grid>
          <IconButton onClick={(event) => openClickForSortByFilter(event)}>
            <SortByFilterIcon />
          </IconButton>
        </Grid>
      </Grid>
      <SortByFilter
        isOpenSortByFilter={isOpenSortByFilter}
        setIsOpenSortByFilter={setIsOpenSortByFilter}
      />
      {selectedMediaAssets.length !== 0 &&
      isMoveClicked &&
      isTrashedItem === false ? (
        <Grid>
          <Button
            variant="contained"
            className={"assetMoveBtn"}
            startIcon={
              <MoveIcon stroke1={"white"} stroke2={"white"} size={"16"} />
            }
            onClick={() => onAssetsMove()}
          >
            Move
          </Button>
          <Button
            variant="contained"
            className="assetDeleteBtn"
            startIcon={<Delete color={"white"} opacity={"1"} size={"16"} />}
            onClick={() => {
              openOverlayForDeleteAction();
            }}
          >
            Trash
          </Button>
        </Grid>
      ) : null}

      {isTrashedItem === true && selectedMediaAssets.length !== 0 ? (
        <Grid>
          <Button
            variant="contained"
            className={"assetMoveBtn"}
            startIcon={<RcoveryIcon fill1="#ffffff" />}
            onClick={() => recoverAssets(selectedMediaAssets)}
          >
            Recover
          </Button>
          <Button
            variant="contained"
            className="assetDeleteBtn"
            startIcon={<Delete color={"white"} opacity={"1"} size={"16"} />}
            onClick={() => {
              openOverlayForDeleteAction();
            }}
          >
            Delete
          </Button>
        </Grid>
      ) : null}

      {breadCrumbs && breadCrumbs.length > 0 && (
        <div className="breadCrumbs__assetManagement breadCrumbs">
          <span className="breadCrumbsText breadCrumbs">
            {breadCrumbs && breadCrumbs.length > 0 && (
              <span
                onClick={() => renderAllData()}
                style={{ cursor: "pointer" }}
              >
                All
              </span>
            )}
          </span>
          {breadCrumbs &&
            breadCrumbs.length > 0 &&
            breadCrumbs.map((item, index) => {
              return (
                <span key={index} className="breadCrumbsText breadCrumbs">
                  {" / "}
                  <span
                    onClick={() => renderSelectedData(item, index)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.title}
                  </span>
                </span>
              );
            })}
        </div>
      )}
      <Grid container>
        {sortedMediaAssets.length > 0 &&
          sortedMediaAssets.map((item, index) => {
            return (
              <TooltipDescription
                title={
                  closeTooltip ? null : item.type.toLowerCase() === "png"
                  || item.type.toLowerCase() === "jpg"
                  || item.type.toLowerCase() === "jpeg"
                  ? <TooltipPreview item={item} /> : (
                    null
                  )
                }
                disableHoverListener={selectedMediaAssets.length !== 0}
              >
                <Grid
                  item
                  xs={2}
                  className={getGridViewClasses(
                    item,
                    moveItem,
                    selectedMoveItems
                  )}
                  key={index}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    handleClick(e, item, index);
                    setCloseTooltip(true);
                  }}
                  aria-describedby={id}
                  onMouseOver={() =>
                    item?.id !== selectedItem?.id ? setSelectedItem(item) : null
                  }
                  onMouseLeave={() => setSelectedItem(null)}
                  onClick={() => {
                    if (
                      selectedMoveItems?.length ||
                      openGridViewOnUploadeAsset !== "" ||
                      moveItem !== null
                    ) {
                      setSelectedFolder(item.type === "folder" ? item : "");
                    }
                  }}
                  style={{ margin: "12px 24px 12px 0px" }}
                >
                  {/* {(selectedItem !== item ) && (
                   <Grid className="assetHoverGrid">
                      <Grid>
                        {item.isfavorite && (
                          <img
                            src={star}
                            alt=""
                            style={{ marginLeft: 15, cursor: "pointer" }}
                            onClick={() => markAsFav(item, false)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )} */}
                  {selectedItem === item || item.isChecked === true ? (
                    <Grid className="assetHoverGrid">
                      <Grid>
                        {item.isfavorite ? (
                          <img
                            src={star}
                            alt=""
                            style={{ marginLeft: 15, cursor: "pointer" }}
                            onClick={() => {
                              markAsFav(item, false);
                            }}
                          />
                        ) : (
                          <img
                            src={starOutlineYellow}
                            alt=""
                            style={{
                              height: 18,
                              width: 18,
                              marginLeft: 15,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              markAsFav(item, true);
                            }}
                          />
                        )}
                      </Grid>
                      <Grid>
                        <Checkbox
                          onChange={() => {
                            dispatch(assetsActions.onSelectMediaAssets(item));
                            handleCheckboxChange(item);
                          }}
                          checked={item.isChecked}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid className="assetHoverFvtGrid">
                      <Grid>
                        {item.isfavorite && (
                          <img
                            src={star}
                            alt=""
                            style={{ marginLeft: 15, cursor: "pointer" }}
                            onClick={() => markAsFav(item, false)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid className="assetGrid">
                    <Grid
                      onClick={(e) => {
                        if (
                          item.type === "folder" &&
                          (moveItem === null &&
                            openGridViewOnUploadeAsset === "") &
                            (selectedMediaAssets.length === 0)
                        ) {
                          setFolderData(item);
                        } else if (item.type === "mp4") {
                          setSelectedMediaFile(item);
                        } else if (e.detail === 2) {
                          setFolderData(item);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {checkFileType(item.type, item.url)}
                    </Grid>
                    <Grid className="assetsName">
                      <Grid>
                        {isRenameAssest?.id === item?.id ? (
                          <input
                            type="text"
                            value={renameAssetItem.name}
                            className="renameInput"
                            onChange={(e) =>
                              setRenameAssetItem({
                                ...renameAssetItem,
                                name: e.target.value,
                              })
                            }
                            onKeyDown={(e) =>
                              e.key === "Enter"
                                ? saveRenameAssetItem(true)
                                : null
                            }
                          />
                        ) : (
                          truncate(item.name)
                        )}{" "}
                      </Grid>
                      <Grid>
                        <span className="assetCreatedDate">
                          {moment(item.uploadedAt).format("DD-MM-YYYY")}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TooltipDescription>
            );
          })}
      </Grid>
      {sortedMediaAssets.length <= 0 && (
        <span className="emptyGridText">
          Uh Oh! You haven’t created any media asset here.
        </span>
      )}
    </>
  );
};
