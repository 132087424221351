import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { AiOutlineClose } from "react-icons/ai";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import { endUserActions } from "../../../store/endUser/endUserActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "23px",
  },
  passwordFields: {
    marginTop: "31px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

const PasswordResetPopup = ({ closePopup, openPopup, userEmail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== password) {
        return false;
      } else {
        return true;
      }
    });
  }, [password]);

  const handleResetPassword = e => {
    e.preventDefault();
    resetPasswordClick();
  };

  const resetPasswordClick = () => {
    const passwordPayload = {
      email: userEmail,
      password: password,
      confirmPassword: repeatPassword,
    };
    setLoading(true);
    dispatch(endUserActions.onResetPassword(passwordPayload)).then(res => {
      setLoading(false);
      if (res?.success) {
        toast.success("Password reset successful!");
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <div className="assetsDialogueWrapper passwordReserWrapper">
      {isLoading ? <Loader /> : null}
      <Dialog
        open={openPopup}
        onClose={closePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="environmentDialog">
        <h2>Reset End User Password</h2>
        <AiOutlineClose onClick={closePopup} className="popupClosed" />
        <ValidatorForm
          ref={React.useRef("addressForm")}
          onSubmit={handleResetPassword}>
          <>
            <span className="gap" />
            <div className="">
              <h3>Are you sure you want to reset End User Name’s Password?</h3>
              <p>
                Make sure you’re in touch with them about your actions because
                they will be notified of a password change.
              </p>
            </div>
            <span className="gap" />
            <Grid
              item
              xs={12}
              className={(classes.gridWrapper, classes.passwordFields)}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <InputField
                    placeholder="New Password"
                    inputlabel="New Password"
                    type="password"
                    className={[classes.gridWrapper]}
                    id="password"
                    name="password"
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    validators={[
                      "required",
                      "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                      "matchRegexp:^.{0,50}$",
                    ]}
                    errorMessages={[
                      "This field is required",
                      "Your password must contain at least 1 uppercase, lowercase, number and special character",
                      "Maximum character limit is 50",
                    ]}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    placeholder="Confirm Password"
                    inputlabel="Confirm Password"
                    type="password"
                    className={[classes.gridWrapper]}
                    id="verifypassword"
                    name="verifypassword"
                    value={repeatPassword}
                    onChange={e => {
                      setRepeatPassword(e.target.value);
                    }}
                    validators={[
                      "isPasswordMatch",
                      "required",
                      "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                      "matchRegexp:^.{0,50}$",
                    ]}
                    errorMessages={[
                      "password mismatch",
                      "This field is required",
                      "Your password must contain at least 1 uppercase, lowercase, number and special character",
                      "Maximum character limit is 50",
                    ]}
                  />
                </Grid>

                <Grid item xs={3} className="ResetPasswordBtn">
                  <Button color="primary" variant="contained" type="submit">
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <span className="gap" />
            <h3 className="resetViaEmail">
              Want to play it safer? Reset Password via E-mail
            </h3>
            <p>
              Allow the end user to reset their own password by sending a
              Password Reset E-mail to End User Name.
            </p>

            <Grid item xs={3} className="ResetPasswordBtn">
              <Button color="primary" variant="contained">
                Reset Password Via E-mail
              </Button>
            </Grid>
          </>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};

export default PasswordResetPopup;
