import React from "react";
import Dashbard from "../Dashbard";
import { ValidatorForm } from "react-material-ui-form-validator";
import { ReleaseCategoryOverview } from "./ReleaseCategoryOverview";

const NewReleaseCategory = () => {
  return (
    <Dashbard>
      <div className='body-content generalTabs'>
        <ValidatorForm ref={React.useRef("form")}>
          <ReleaseCategoryOverview />
        </ValidatorForm>
      </div>
    </Dashbard>
  );
};

export { NewReleaseCategory };
