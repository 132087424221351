import React, { useRef, useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import { searchIcon } from "../../../../resources/images";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { FiChevronDown } from "react-icons/fi";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Dropdown } from "../../../../components/common";
import { MenuProps, useStyles } from "../../../../components/common/utils";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyless = makeStyles(theme => ({
  gridWrapper: {
    paddingTop: "40px",
    paddingBottom: "15px",
    borderBottom: "1px solid #E2E6E9",
    background: "red",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",

  },
  dropdownBg: {
    background: "#f1f1f1"
  },
  formControl: {
    minWidth: "100%",
    background: "transparent"
  },
  selectEmpty: {
    border: "1px solid #F3F6F9",
    backgroundColor: "#F3F6F9",
    borderRadius: "6px",
    "&:before": {
      borderBottom: "1px solid #F3F6F9",
      "&:hover": {
        borderBottom: "0 !important"
      }
    },
    "&:after": {
      borderBottom: "1px solid #F3F6F9"
    },

    MuiSvgIcon: {
      root: {
        fontSize: 20
      }
    }
  },
  MuiSelect: {
    icon: {
      fontSize: "26px"
    },
    iconOutlined: {
      fontSize: "26px"
    }
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9"
      }
    }
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px"
  }
}));

const handleSubmit = e => {
  e.preventDefault();
};
function ReleaseGridFilter({
  Categories,
  setFilterByName,
  setSelectedCategory,
  setSelectedDate
}) {
  const [cat, setCat] = useState();
  const [selected, setSelected] = useState([]);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [selectDate, setSelectDate] = useState(0);
  const classes = useStyless();
  const classes1 = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setCat(Categories);
  }, [Categories]);

  const handleChange = event => {};
  const [search, setSearch] = useState("");

  const onPressClear = () => {
    cat.map(x => {
      x.checked = false;
    });
    setCat([...cat], cat);
    setSearch("");
    setIsFilterShow(false);
    setSelectedCategory([]);
    setFilterByName(null);
    setSelectedDate(0);
    setSelectDate(0);
    setSelected([]);
    setAnchorEl(null);
  };

  const onPressFilterBtn = () => {
    setFilterByName(search);
    var selCatId = [];
    cat.map(x => {
      if (x.checked) {
        selCatId.push(x.id);
      }
    });
    setSelectedDate(selectDate);
    setSelectedCategory(selCatId);
    setAnchorEl(null);
  };
  const checkCatSelected = item => {
    var catName = [];
    cat.map(x => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    cat.map(x => {
      if (x.checked) {
        catName.push(x.value);
      }
    });
    setSelected(catName);
    setCat([...cat], cat);
  };
  return (
    <div className="filterWrapper" >
      <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={10} className="filterMainSearch">
            <img src={searchIcon} alt="" className="searchIcon" />
            <InputField
              inputlabel="Search"
              placeholder="Search"
              value={search}
              onChange={({ target }) =>{
                setSearch(target.value);
                setFilterByName(target.value);
              }}
            />
          </Grid>

          <Grid item md={2} className="searchByFilter" onClick={handleClick}>
            Search by Filter <FiChevronDown />
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}>
          <Paper className={classes.dropdownWrapper}>
            <Grid
              container
              spacing={2}
              className="filterByWrapper">
              <Grid item xs={7} className="filterBtnLabel">
                Filter by{" "}
              </Grid>
              <Grid item xs={5}>
                <span className="filterButtons" style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onPressFilterBtn();
                    }}>
                    Filter
                  </Button>
                  <Button variant="outlined" onClick={() => onPressClear()}>
                    Clear
                  </Button>
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="inputWrapper">
                  <FormControl className={classes1.formControl}>
                    <Select
                      labelId="mutiple-select-label"
                      multiple
                      value={selected}
                      className={classes1.selectEmpty}
                      onChange={e => handleChange(e)}
                      renderValue={selected => selected.join(", ")}
                      MenuProps={MenuProps}
                      variant={"filled"}
                      displayEmpty>                  
                      {cat &&
                        cat.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            <ListItemIcon>
                              <Checkbox
                                onClick={() => {
                                  checkCatSelected(option);
                                }}
                                checked={option.checked}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="inputWrapper">
                  <FormControl className={classes.formControl}>
                    <Select
                      value={selectDate}
                      onChange={v => {
                        setSelectDate(v.target.value);
                      }}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"filled"}
                      required>
                      <MenuItem value={0}>All Uploads</MenuItem>
                      <MenuItem value={1}>Within Last Month</MenuItem>
                      <MenuItem value={2}>Within Past 3 Months</MenuItem>
                      <MenuItem value={3}>Within Past 6 Months</MenuItem>
                      <MenuItem value={4}>Within Past Last Year</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
        
      </ValidatorForm>
    </div>
  );
}

export default ReleaseGridFilter;
