import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import moment from "moment";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { SpaDeviceOverView } from "./SpaDeviceOverView";
import { SpaProductRegistration } from "./SpaProductRegisteration";

const SpaDeviceSetting = ({
  isSpaEdit,
  setIsSpaEdit,
  spaProductHandler,
  hideSpaProducts,
  deviceId,
}) => {
  const dispatch = useDispatch();

  const {
    spaDeviceSetting,
    spaDeviceDetails,
    delearName,
    prchPrice,
    spaName,
    spaService,
    prchDate,
    address,
    city,
    province,
    country,
    zipCode,
    googleLocations,
    foucusAddress,
    spaLat,
    spaLon,
    deviceCatId,
    deviceDelearId,
    spaPurchaseLocId,
    spaDevicewarrantyStatus,
    spaRegistrationStatus,
    registrationNote,
    editUserName,
    spaTopsidePrchDate,
    spaDeviceInstallationDate,
    spaTopsideInstallDate,
    spaPurchaseLatitude,
    spaPurchaseLocation,
    spaPurchaseLongitude,
    topSidePurchaseLatitude,
    topSidePurchaseLocation,
    topSidePurchaseLongitude,
    controllerPurchaseLatitude,
    controllerPurchaseLocation,
    controllerPurchaseLongitude,
    spaTopside,
    spaTopsideWarrantyStatus,
    spaTopsideRegistrationStatus,
    spaTopsideRegistrationNote,
    spaController,
    spaControllerPrchDate,
    spaControllerinstalldate,
    spaControllerRegistration,
    spaControllerNote,
    spaControllerWarrantyStatus,
  } = useSelector(state => state.endUserReducer);

  useEffect(() => {
    setIsSpaEdit(false);
    dispatch(endUserActions.onResetSpaFields());
    getSpaDeviceInfo(deviceId);
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [allDelears, setAllDelears] = useState([]);
  const [activeTabId, setActiveTabId] = useState("1");
  const getSpaDeviceInfo = deviceId => {
    setLoading(true);
    dispatch(endUserActions.onGetSpaInfo(deviceId)).then(data => {
      setLoading(false);
      if (data?.success) {
        if (data?.data?.spaSettings.length > 0) {
          dispatch(
            endUserActions.onChange(
              "spaDeviceSetting",
              data.data.spaSettings[0]
            )
          );
        } else {
          dispatch(endUserActions.onChange("spaDeviceSetting", ""));
        }
      }
    });
  };
  const regisrtationStatusType = [
    {
      id: 0,
      name: "Pending",
      expectedResponse: "Pending",
      expectedResponse2: "Unregistered",
      expectedResponse3:"Registration Pending"
    },
    {
      id: 1,
      name: "Register",
      expectedResponse: "Approved",
      expectedResponse2: "Registered",
      expectedResponse3:"Registration Approved",
    },
    {
      id: 5,
      name: "Rejected",
      expectedResponse: "Expired",
      expectedResponse2: "Denied",
      expectedResponse3:"Registration Denied"
    },
    { id: 6, name: "Receipt Missing", expectedResponse2: "Receipt Missing" },
  ];

  const onValueChange = search => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${search}&key=AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ`
    )
      .then(res => res.json())
      .then(jsonResponse => {
        dispatch(
          endUserActions.onChange(
            "googleLocations",
            jsonResponse?.results || []
          )
        );
      })
      .catch(err => {
        console.log("location error", err);
      });
  };

  const setPurchaseDate = value => {
    dispatch(
      endUserActions.onChange("prchDate", moment(value).format("MM/DD/YYYY"))
    );
  };
  const changeHandler = (event, catId) => {
    setLoading(true);
    dispatch(endUserActions.onGetAllDelears(event.target.value, catId)).then(
      res => {
        setAllDelears(res?.data || []);
        setLoading(false);
      }
    );
  };
  const formattedPrchDate =
    prchDate != "" ? moment(prchDate).format("MM/DD/YYYY") : "";
  const formattedIntallDate =
    spaDeviceInstallationDate != ""
      ? moment(spaDeviceInstallationDate).format("MM/DD/YYYY")
      : "";
  const onSaveSpaInfo = delearId => {
    setLoading(true);
    setIsSpaEdit(false);
    let finalAddress = "";
    if (address.length) {
      finalAddress = `${address}${", "}${city}${", "}${zipCode}${", "}${province}${", "}${country}`;
    }
    if (delearId !== null) {
      const devicePayload = {
        price: prchPrice,
        spaService: spaService,
        deviceName: spaName,
        deviceId: deviceId,
        dealerId: spaPurchaseLocId,
        purchaseDate: formattedPrchDate,
        installationDate: formattedIntallDate,
        installationLocation: finalAddress,
        installationLongitude: spaPurchaseLongitude,
        installationLatitude: spaPurchaseLatitude,
        // spaBrand: spaDeviceSetting?.spaBrand,
        // spaSeries: spaDeviceSetting?.spaSeries,
        // spaModel: spaDeviceSetting?.spaModel,
        // spaSerialNo: spaDeviceDetails?.serialNo,
        // spaControllerModel: spaDeviceSetting?.spaControllerModel        ,
        // spaFirmwareVersion: spaDeviceSetting?.spaFirmwareVersion,
        spaDealerName: spaPurchaseLocation,
        // modelYear:spaDeviceSetting?.modelYear,
        // controllerSerialNo: spaDeviceSetting?.spaControllerSerialNo,
        warrantyStatus:typeof spaDevicewarrantyStatus=== "Number" ? spaDevicewarrantyStatus :
         spaDevicewarrantyStatus === "Active" ? 0 : spaDevicewarrantyStatus === "Expired" ? 1 : 3,
         notes:registrationNote,
        registrationStatus: typeof spaRegistrationStatus === "number"
        ? spaRegistrationStatus
        : regisrtationStatusType.find(
            item =>
              item.expectedResponse ===
              spaRegistrationStatus ||
              item.expectedResponse2 ===
              spaRegistrationStatus ||
                item.expectedResponse3 ===
                spaRegistrationStatus
          )?.id,
        purchaseLocation: spaPurchaseLocation,
        purchaseLongitude: spaPurchaseLongitude,
        purchaseLatitude: spaPurchaseLatitude,
        // purchasingPrice: prchPrice,
        // featureImage: spaDeviceDetails?.productImage,
        spaTopside: {
          purchaseDate: spaTopsidePrchDate,
          installationDate: spaTopsideInstallDate,
          purchaseLocation: topSidePurchaseLocation,
          purchaseLongitude: topSidePurchaseLatitude,
          purchaseLatitude: topSidePurchaseLongitude,
          model: spaTopside?.model,
          macId: spaTopside?.macId,
          warrantyDate: spaTopside?.warrantyExpiration,
          warrantyStatus: spaTopsideWarrantyStatus,
          registrationStatus: spaTopsideRegistrationStatus,
          // notes:spaTopsideRegistrationNote,
        },
        spaController: {
          model: spaController.model,
          purchaseDate: spaControllerPrchDate,
          warrantyDate: spaController?.warrantyExpiration,
          warrantyStatus: spaControllerWarrantyStatus,
          registrationStatus: spaControllerRegistration,
          // notes:spaControllerNote,
          installationDate: spaControllerinstalldate,
          purchaseLocation: controllerPurchaseLocation,
          purchaseLongitude: controllerPurchaseLongitude,
          purchaseLatitude: controllerPurchaseLatitude,
        },
        receipt: spaDeviceDetails?.receipt,
        receiptType: spaDeviceDetails?.receiptType,
      };

      setLoading(true);
      dispatch(endUserActions.onAddUpdateSpaSetting(devicePayload)).then(
        data => {
          setLoading(false);
          if (data?.success) {
            getSpaDeviceInfo(deviceId);
            toast.success("Product updated successfully");
          } else {
            toast.error(
              data?.message ?? "Something went wrong, Product not updated!"
            );
          }
        }
      );
    } else {
      toast.error("Please select valid delear");
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);
  const getGeoLocation = useCallback(debounce(onValueChange, 700), []);

  return (
    <div className='Dashboardmain-container productDetailWrapper'>
      {isLoading ? <Loader /> : null}

      <Grid className='endcustomeroverview'>
        <div className='consumerHeader'>
          <div className='endCustomerHeading'>{`${editUserName}${" Spa"}`}</div>
          <Button color='primary' onClick={hideSpaProducts} className='backbtn'>
            <ArrowBackIosIcon /> Back to Products Owned
          </Button>
        </div>
        <div className='deviceOverViewEdit'>
          {!isSpaEdit ? (
            <Button
              className='editButtonOverview'
              variant='contained'
              onClick={() => setIsSpaEdit(true)}>
              Edit
            </Button>
          ) : (
            <Button
              className='saveButtonOverview'
              variant='contained'
              onClick={() => onSaveSpaInfo(deviceDelearId)}>
              Save
            </Button>
          )}
        </div>
      </Grid>
      <div className='deviceOverviewMainContainer'>
        <div className='deviceOverviewContainer'>
          <TopTabNavigator
            getActiveTabId={tabId => {
              setActiveTabId(`${tabId}`);
              if (tabId === 3) spaProductHandler();
              setIsSpaEdit(false);
            }}
            tabs={[
              {
                id: 1,
                title: "Spa overview",
                component: (
                  <SpaDeviceOverView
                    isSpaEdit={isSpaEdit}
                    setIsSpaEdit={setIsSpaEdit}
                    spaProductHandler={spaProductHandler}
                    deviceId={deviceId}
                    hideSpaProducts={hideSpaProducts}
                  />
                ),
              },
              {
                id: 2,
                title: "product registration",
                component: (
                  <SpaProductRegistration
                    isSpaEdit={isSpaEdit}
                    setIsSpaEdit={setIsSpaEdit}
                    spaProductHandler={spaProductHandler}
                    deviceId={deviceId}
                    getSpaDeviceInfo={getSpaDeviceInfo}
                    debouncedChangeHandler={debouncedChangeHandler}
                    allDelears={allDelears}
                  />
                ),
              },
              {
                id: 3,
                title: "Spa ownership",
                component: "",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default SpaDeviceSetting;
