import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button, Checkbox } from "@material-ui/core";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { locatorWidgetActions } from "../../../../../store/locatorWidget/locatorWidgetActions";
import {
  locatorTheme,
  locatorVector,
  LocatorPath95,
  LocatorPath95Left,
} from "../../../../../resources/images/index";
import { concat, sortBy, orderBy, filter } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputField from "../../../../../components/common/InputField";
import { TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  textArea: {
    minHeight: "470px",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function LocatorDesign({ startLoading, stopLoading }) {
  const dispatch = useDispatch();
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [companyImg, setCompanyImg] = useState("");
  const [comImgId, setComImgId] = useState(0);
  const [comDescription, setComDescription] = useState("");
  const [isError, setIsError] = useState(false);
  const [isFilterOptionDetails, setIsFilterOptionDetails] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [filtersKeyValuePairs, setFiltersKeyValuePairs] = useState([]);
  const [tempFiltersKeyValuePairs, setTempFiltersKeyValuePairs] = useState([]);
  const [selectedAvailableFilter, setSelectedAvailableFilter] = useState(null);
  const [selectedAvailableFilterOption, setSelectedAvailableFilterOption] =
    useState(null);
  const [showKeyValuePairs, setShowKeyValuePairs] = useState(false);
  const [renameKeyValuePairs, setRenameKeyValuePairs] = useState(false);
  const [locatorWidgetId, setLocatorWidgetId] = useState(1);
  const [keyValuePairName, setKeyValuePairName] = useState("");
  const [keyValuePairType, setKeyValuePairType] = useState("string");

  const loadData = () => {
    startLoading();
    dispatch(locatorWidgetActions.onGetFilterOptions()).then((res) => {
      if (res && res.success && res.data) {
        setFilterOptions(res.data);
      }
    });
    dispatch(
      locatorWidgetActions.onGetSelectedFilterOptions(locatorWidgetId)
    ).then((res) => {
      if (res && res.success && res.data) {
        const smOpts = [];
        res.data?.forEach((mo) => {
          const opt = [];
          mo.options?.forEach((s) => {
            opt.push(s.id);
          });
          smOpts.push({
            id: mo.id,
            name: mo.name,
            type: mo.type,
            options: opt,
          });
        });
        setSelectedFilterOptions(smOpts);
      }
    });
    stopLoading();
  };

  useEffect(() => {
    loadData();
  }, []);

  const onUpdateSettings = () => {
    startLoading();
    const smOpts = [];
    selectedFilterOptions?.forEach((mo) => {
      smOpts.push({
        locatorWidgetId: locatorWidgetId,
        filterId: mo.id,
        filterOptionsKeyValuePair: mo.options,
      });
    });
    dispatch(locatorWidgetActions.onSaveFilters(locatorWidgetId, smOpts)).then(
      (data) => {
        if (data && data.success) {
          toast.success("Filters successfully updated");
          setSelectedAvailableFilterOption(null);
        } else {
          toast.error(data?.message);
        }
        stopLoading();
      }
    );
  };

  const onUpdateFilterOption = () => {
    startLoading();
    dispatch(
      locatorWidgetActions.onSaveFilterOption(
        selectedAvailableFilterOption?.id,
        keyValuePairName,
        keyValuePairType
      )
    ).then((data) => {
      if (data && data.success) {
        var selectedData = selectedAvailableFilterOption;
        selectedData.name = keyValuePairName;
        setSelectedAvailableFilterOption(selectedData);
        var filterOptionsData = selectedFilterOptions;
        const index = filterOptionsData.findIndex(
          (s) => s.id === selectedAvailableFilterOption.id
        );
        filterOptionsData[index] = selectedData;
        setSelectedFilterOptions(filterOptionsData);
        dispatch(locatorWidgetActions.onGetFilterOptions()).then((res) => {
          if (res && res.success && res.data) {
            setFilterOptions(res.data);
          }
        });
        setRenameKeyValuePairs(false);
        setSelectedAvailableFilterOption(null);
        setKeyValuePairName("");

        toast.success("Filter option successfully updated");
      } else {
        toast.error(data?.message);
      }
      stopLoading();
    });
  };

  const classes = useStyles();
  return (
    <div className="accordianOuterWrapper">
      <AccordionContainer title="Locator Theme">
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.gridWrapper}>
              <img
                alt="locator theme"
                className="locatorThemeImg"
                src={locatorTheme}
              />
              <div className="locatorThemeTitle">Simple Locator</div>
            </Grid>
            <Grid item xs={4} className={classes.gridWrapper}>
              <img
                alt="locator theme"
                className="locatorThemeImg"
                src={locatorTheme}
              />
              <div className="locatorThemeTitle">Simple Locator</div>
            </Grid>
            <Grid item xs={4} className={classes.gridWrapper}>
              <img
                alt="locator theme"
                className="locatorThemeImg"
                src={locatorTheme}
              />
              <div className="locatorThemeTitle">Simple Locator</div>
            </Grid>
          </Grid>
        </div>
      </AccordionContainer>
      <AccordionContainer title="Filters">
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={5} className={classes.gridWrapper}>
              <label className="locatorFilterTitle">Available Filters</label>
              <Grid item xs={12} className="locatorFilterContentGrid">
                {filterOptions.length > 0 &&
                  filterOptions.map((item) => {
                    return (
                      <div
                        className={`locatorFilterText ${
                          item?.id === selectedAvailableFilter?.id
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedAvailableFilter(item);
                        }}
                      >
                        {item.name}
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.gridWrapper}>
              <Grid item xs={12} className="locatorFilterVectorGrid">
                <img
                  alt="locator vector"
                  className="locatorVectorImg"
                  src={locatorVector}
                  onClick={() => {
                    const smOption = filterOptions.find(
                      (m) => m.id === selectedAvailableFilter?.id
                    );
                    const smSelectedOption = selectedFilterOptions.find(
                      (m) => m.id === selectedAvailableFilter?.id
                    );
                    if (smOption && !smSelectedOption) {
                      setSelectedFilterOptions([
                        ...orderBy(
                          concat(selectedFilterOptions, {
                            id: smOption.id,
                            name: smOption.name,
                            type: "string",
                            options: [],
                          })
                        ),
                      ]);
                      setSelectedAvailableFilter(null);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={5} className={classes.gridWrapper}>
              <label className="locatorFilterTitle">
                Filters for Locator (check which options to show)
              </label>
              <Grid item xs={12} className="locatorFilterContentGrid">
                {showKeyValuePairs > 0 ? (
                  <div className="locatorFilterOptionKeyValuePair">
                    <div className="locatorFilterOptionIcon">
                      <img
                        alt="locator path"
                        className="LocatorPathIcon"
                        src={LocatorPath95Left}
                        onClick={() => {
                          setShowKeyValuePairs(false);
                          var selectedData = selectedAvailableFilterOption;
                          selectedData.options = tempFiltersKeyValuePairs;
                          setSelectedAvailableFilterOption(selectedData);
                          var filterOptionsData = selectedFilterOptions;
                          const index = filterOptionsData.findIndex(
                            (s) => s.id === selectedAvailableFilterOption.id
                          );
                          filterOptionsData[index] = selectedData;
                          setSelectedFilterOptions(filterOptionsData);
                        }}
                      />
                    </div>
                    <div className="locatorFilterOptionText">
                      <h6>{selectedAvailableFilterOption.name}</h6>
                      <p>
                        {selectedAvailableFilterOption.name} .{" "}
                        {tempFiltersKeyValuePairs.length} options
                      </p>
                    </div>
                    {filtersKeyValuePairs.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "40px",
                        }}
                      >
                        {filtersKeyValuePairs.map((g) => {
                          return (
                            <span className="innerList">
                              <Checkbox
                                key={g.id}
                                label={g.value}
                                style={{ padding: "4px 10px" }}
                                checked={
                                  tempFiltersKeyValuePairs.find(
                                    (s) => `${s}` === `${g.id}`
                                  )
                                    ? true
                                    : false
                                }
                                onChange={({ target }) => {
                                  if (target.checked) {
                                    // tempVisibilityGroups.push({
                                    //   VisibilityGroupId: g.id,
                                    //   name: g.value
                                    // });

                                    setTempFiltersKeyValuePairs([
                                      ...concat(tempFiltersKeyValuePairs, g.id),
                                    ]);
                                  } else {
                                    setTempFiltersKeyValuePairs([
                                      ...tempFiltersKeyValuePairs.filter(
                                        (s) => `${s}` !== `${g.id}`
                                      ),
                                    ]);
                                  }
                                }}
                              />
                              {g.value}
                            </span>
                          );
                        })}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <div className="locatorSelectorFilterOption">
                    {selectedFilterOptions.length > 0 &&
                      selectedFilterOptions.map((item) => {
                        return (
                          <div
                            className={`locatorFilterOption ${
                              item?.id === selectedAvailableFilterOption?.id
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedAvailableFilterOption(item);
                            }}
                          >
                            <div className="locatorFilterOptionText">
                              {item?.id === selectedAvailableFilterOption?.id &&
                              renameKeyValuePairs ? (
                                <input
                                  style={{
                                    borderTop: "0px",
                                    borderLeft: "0px",
                                    borderRight: "0px",
                                  }}
                                  value={keyValuePairName}
                                  onChange={(e) => {
                                    setKeyValuePairName(e.target.value);
                                  }}
                                />
                              ) : (
                                <h6>{item.name}</h6>
                              )}
                              <p>
                                {item.name} . {item.options.length} options
                              </p>
                            </div>
                            <div className="locatorFilterOptionIcon">
                              <img
                                alt="locator path"
                                className="LocatorPathIcon"
                                src={LocatorPath95}
                                onClick={() => {
                                  startLoading();
                                  setSelectedAvailableFilterOption(item);
                                  dispatch(
                                    locatorWidgetActions.onGetFilterOptionsKeyValuePair(
                                      item.id
                                    )
                                  ).then((res) => {
                                    if (res && res.success && res.data) {
                                      setFiltersKeyValuePairs(res.data);
                                      setTempFiltersKeyValuePairs(item.options);
                                      setShowKeyValuePairs(true);
                                      stopLoading();
                                    }
                                  });
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </Grid>
              {!renameKeyValuePairs ? (
                showKeyValuePairs ? (
                  <Grid item xs={12} className="locatorFilterButtonGrid">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.selectBtn}
                      onClick={() => {
                        const smOpts = [];
                        filtersKeyValuePairs?.forEach((mo) => {
                          const smSelectedOption = smOpts.find(
                            (m) => m === mo?.id
                          );
                          if (!smSelectedOption) {
                            smOpts.push(mo?.id);
                          }
                        });
                        setTempFiltersKeyValuePairs(smOpts);
                      }}
                    >
                      Select all
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="locatorFilterButtonGrid">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.selectBtn}
                      onClick={(e) => {
                        setRenameKeyValuePairs(true);
                        setKeyValuePairName(
                          selectedAvailableFilterOption?.name
                        );
                      }}
                    >
                      Rename
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.selectBtn}
                      onClick={() => {
                        setSelectedFilterOptions(
                          selectedFilterOptions.filter(
                            (m) => m.id !== selectedAvailableFilterOption?.id
                          )
                        );
                        setSelectedAvailableFilterOption(null);
                      }}
                    >
                      Remove from List
                    </Button>
                  </Grid>
                )
              ) : (
                <Grid item xs={12} className="locatorFilterButtonGrid">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.selectBtn}
                    onClick={() => onUpdateFilterOption()}
                  >
                    Save
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        <span className="saveChangesBtn">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onUpdateSettings()}
          >
            Save Changes
          </Button>
        </span>
      </AccordionContainer>
    </div>
  );
}
