import { endUserApi } from "./endUserApi";
import { endUserTypes } from "./endUserTypes";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onChangeOfRegister = (field, value) => {
  return dispatch => {
    dispatch({
      type: endUserTypes.REGISTER_DEVICE_CHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onResetRegister = (field, value) => {
  return dispatch => {
    dispatch({
      type: endUserTypes.RESET_DEVICE_OVERVIEWINFO,
      payload: { prop: field, value: value },
    });
  };
};

const onSelectFFDevice = value => {
  return dispatch => {
    dispatch({
      type: endUserTypes.SELECT_FF_DEVICES,
      payload: { value },
    });
  };
};

const onGetUserOverview = userId => async dispatch => {
  let response = await endUserApi.getUserOverview(userId);
  if (response && response.data) {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "userOverview", value: response.data },
    });
  } else {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "userOverview", value: "" },
    });
  }
  return response;
};

const onGetUserById = userId => async dispatch => {
  let response = await endUserApi.getUserById(userId);
  return response;
};

const onUpdateUserInfo = userData => async dispatch => {
  let response = await endUserApi.updateUserInfo(userData);
  return response;
};

const onGetStaticEnv = () => async dispatch => {
  let response = await endUserApi.getStaticEnv();
  return response;
};

const onGetEnvIcons = () => async dispatch => {
  let response = await endUserApi.getEnvIcons();
  if (response && response.success) {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "envIcons", value: response.data },
    });
  } else {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "envIcons", value: null },
    });
  }
  return response;
};

const onAddUpdateEnv = envData => async dispatch => {
  let response = await endUserApi.addUpdateEnv(envData);
  return response;
};

const onGetUserEnvById = userId => async dispatch => {
  let response = await endUserApi.getUserEnvById(userId);
  if (response && response.success) {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "allUserEnv", value: response.data },
    });
  } else {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "allUserEnv", value: null },
    });
  }
  return response;
};

const onGetUserEnvs = () => async dispatch => {
  const userId = localStorage.getItem("id");
  return await endUserApi.getUserEnvs({ userId: userId });
};

const onGetUserGroupDD = () => async dispatch => {
  let response = await endUserApi.getUserGroupDD();
  return response;
};

const onGetEnvWithDeviceCount = userId => async dispatch => {
  let response = await endUserApi.getEnvWithDeviceCount(userId);
  if (response && response.success) {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "envWithDeviceCount", value: response.data },
    });
  } else {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "envWithDeviceCount", value: [] },
    });
  }
  return response;
};

const onGetFrndAndFamlyList = userId => async dispatch => {
  let response = await endUserApi.getFrndAndFmlyList(userId);
  return response;
};

const onAddFrndAndFmly = ffData => async dispatch => {
  let response = await endUserApi.addFrndAndFmly(ffData);
  return response;
};

const onDeleteFrndAndFmly = ffId => async dispatch => {
  let response = await endUserApi.deleteFrnAndFmly(ffId);
  return response;
};

const onGetFFDeviceList = userId => async dispatch => {
  let response = await endUserApi.getFFDevicesList(userId);
  return response;
};

const onGetUserProdCat = () => async dispatch => {
  let response = await endUserApi.getUserProdCat();
  return response;
};

const onGetProdByCatId = catId => async dispatch => {
  let response = await endUserApi.getUserProdByCatId(catId);
  return response;
};

const onGetDuplicateSr = srNumber => async dispatch => {
  let response = await endUserApi.getDuplicateSerial(srNumber);
  return response;
};

const onGetDelears = (dName, catId) => async dispatch => {
  let response = await endUserApi.getDealers(dName, catId);
  return response;
};

const onGetAllDelears = (dName, catId) => async dispatch => {
  let response = await endUserApi.getAllDealers(dName, catId);
  return response;
};

const onSaveDevice = dInfo => async dispatch => {
  let response = await endUserApi.saveDevice(dInfo);
  return response;
};
const onGetUserDevices = userId => async dispatch => {
  let response = await endUserApi.getUserDevices(userId);
  if (response && response.success) {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "userDevices", value: response.data },
    });
  } else {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "userDevices", value: null },
    });
  }
  return response;
};

const onGetCompanyDevices = userId => async dispatch => {
  let response = await endUserApi.getCompanyDevices(userId);
  if (response && response.success) {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "userDevices", value: response.data },
    });
  } else {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "userDevices", value: null },
    });
  }
  return response;
};

const onUpdateUserDevice = dInfo => async dispatch => {
  let response = await endUserApi.updateUserDevice(dInfo);
  return response;
};

const onDeleteDevice = devId => async dispatch => {
  let response = await endUserApi.deleteDevice(devId);
  return response;
};

const onTransferOwnershipDevice =
  ({ email, deviceId }) =>
  async dispatch => {
    let response = await endUserApi.transferDeviceOwnerShip({
      email,
      deviceId,
    });
    return response;
  };

const onGetDeviceOverview = devId => async dispatch => {
  let response = await endUserApi.getDeviceOverview(devId);
  if (response && response.success) {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "deviceOverviewInfo", value: response.data },
    });
  } else {
    dispatch({
      type: endUserTypes.ENDUSER_ONCHANGE,
      payload: { prop: "deviceOverviewInfo", value: "" },
    });
  }
  return response;
};

const onUpdateReceipt = receiptInfo => async dispatch => {
  let response = await endUserApi.updateReceipt(receiptInfo);
  return response;
};

const onAddDeviceColor = (devId, colorCode) => async dispatch => {
  let response = await endUserApi.addDeviceColor(devId, colorCode);
  return response;
};

const onUpdateDeviceColor = (clrId, colorCode) => async dispatch => {
  let response = await endUserApi.updateDeviceColor(clrId, colorCode);
  return response;
};
const onGetUserNotes = getNoteUrl => async dispatch => {
  let response = await endUserApi.getUserNotes(getNoteUrl);
  return response;
};
const onAddUpdateUserNotes = notesData => async dispatch => {
  let response = await endUserApi.addUpdateNote(notesData);
  return response;
};
const onAddUpdateBUNotes = notesData => async dispatch => {
  let response = await endUserApi.addUpdateBUNote(notesData);
  return response;
};
const onDeleteUserNotes = devId => async dispatch => {
  let response = await endUserApi.deleteUserNotes(devId);
  return response;
};
const onDeleteBUNotes = ntsId => async dispatch => {
  let response = await endUserApi.deleteBUNotes(ntsId);
  return response;
};
const onGetUserActivity = (userId, noOfRecord) => async dispatch => {
  let response = await endUserApi.getUserActivities(userId, noOfRecord);
  return response;
};

const onGetEUActivity = userId => async dispatch => {
  let response = await endUserApi.getEUActivity(userId);
  return response;
};

const onGetBUActivity = compId => async dispatch => {
  let response = await endUserApi.getBUActivity(compId);
  return response;
};

const onGetSpaInfo = deviceId => async dispatch => {
  let response = await endUserApi.getSpaInfo(deviceId);
  dispatch({
    type: endUserTypes.SET_SPA_LOCATION,
    payload: response?.data ?? {},
  });
  return response;
};

const onSelectSpaLocation = locationData => async dispatch => {
  dispatch({
    type: endUserTypes.SET_SELECT_LOCATION_SPA,
    payload: locationData,
  });
};
const onResetSpaFields = () => async dispatch => {
  dispatch({
    type: endUserTypes.SPA_RESET_FIELDS,
  });
};
const onAddUpdateSpaSetting = spaData => async dispatch => {
  let response = await endUserApi.addUpdateSpaSetting(spaData);
  return response;
};
const onChangeWaterTreatment = wtrData => async dispatch => {
  let response = await endUserApi.changeWaterTreatment(wtrData);
  return response;
};
const onUpdataGlobalSetting = globalData => async dispatch => {
  let response = await endUserApi.changeGlobalSetting(globalData);
  return response;
};
const onUpdataTempSetting = tempData => async dispatch => {
  let response = await endUserApi.changeTemperatureSetting(tempData);
  return response;
};
const onGetContactTypeById = contactId => async dispatch => {
  let response = await endUserApi.getContactTypeInfo(contactId);
  return response;
};
const onGetAdrsByUser = userId => async dispatch => {
  let response = await endUserApi.getAdrsByUser(userId);
  return response;
};
const onAddCusAdr = adrData => async dispatch => {
  let response = await endUserApi.addUpdateCusAddress(adrData);
  return response;
};
const onResetPassword = passData => async dispatch => {
  let response = await endUserApi.resetPassword(passData);
  return response;
};
const onGetAllAdmins = () => async dispatch => {
  let response = await endUserApi.getAllAdmins();
  return response;
};

const onGetAdminById = adminId => async dispatch => {
  let response = await endUserApi.getAdminById(adminId);
  return response;
};

const onGetContactTypesDDL = contactTypeId => async dispatch => {
  let response = await endUserApi.getContactTypesByDDL(contactTypeId);
  return response;
};

const onGetSpaChemicalTestingList = data => async dispatch => {
  const response = await endUserApi.getSpaChemicalTestingList(data);
  return response;
};

const onGetAllFilterLife = deviceId => async dispatch => {
  const response = await endUserApi.getAllFilterLife(deviceId);
  return response;
};

const onGetAllWaterLife = deviceId => async dispatch => {
  const response = await endUserApi.getAllWaterLife(deviceId);
  return response;
};

const onGetChemicalTestingStats = deviceId => async dispatch => {
  const response = await endUserApi.getChemicalTestingStats(deviceId);
  dispatch({
    type: endUserTypes.ENDUSER_ONCHANGE,
    payload: { prop: "chemicalTestStats", value: response?.data },
  });
  return response;
};

const onAddUpdateFilterLife = filterLife => async dispatch => {
  let response = await endUserApi.addUpdateFilterLife(filterLife);
  return response;
};

const onAddUpdateWaterLife = waterLife => async dispatch => {
  const response = await endUserApi.addUpdateWaterLife(waterLife);
  return response;
};

const onAddUpdateSPAChemicalTesting = waterLife => async dispatch => {
  const response = await endUserApi.addUpdateSPAChemicalTesting(waterLife);
  return response;
};

const onGetSpaChemicalHistoryWithDates =
  ({ deviceId, startDate, endDate }) =>
  async dispatch => {
    const response = await endUserApi.getSpaChemicalTestingHistoryWithDates({
      deviceId,
      startDate,
      endDate,
    });
    return response;
  };

const onGetFilterLifeStats = deviceId => async dispatch => {
  const response = await endUserApi.getSPAFilterLifeStats(deviceId);
  dispatch({
    type: endUserTypes.ENDUSER_ONCHANGE,
    payload: { prop: "filterLifeStats", value: response?.data },
  });
  return response;
};

const onGetWaterLifeStats = deviceId => async dispatch => {
  const response = await endUserApi.getSPAWaterLifeStats(deviceId);
  dispatch({
    type: endUserTypes.ENDUSER_ONCHANGE,
    payload: { prop: "waterLifeStats", value: response?.data },
  });
  return response;
};

const onUploadReciept = image => async dispatch => {
  const response = await endUserApi.uploadRecipt(image);
  return response;
};

const onSaveSpaSettings = settingInfo => async dispatch => {
  let response = await endUserApi.saveSpaSetting(settingInfo);
  return response;
};

const onBCListViewChange = isListView => async dispatch => {
  dispatch({
    type: endUserTypes.ENDUSER_ONCHANGE,
    payload: { prop: "isBCListView", value: isListView },
  });
};

const onEUListViewChange = isListView => async dispatch => {
  dispatch({
    type: endUserTypes.ENDUSER_ONCHANGE,
    payload: { prop: "isEUListView", value: isListView },
  });
};

const onDeleteCusAddress = devId => async dispatch => {
  let response = await endUserApi.deleteCusAddress(devId);
  return response;
};

const onDeleteEnv = devId => async dispatch => {
  let response = await endUserApi.deleteEnv(devId);
  return response;
};

export const endUserActions = {
  onChange: onChange,
  onChangeOfRegister: onChangeOfRegister,
  onResetRegister: onResetRegister,
  onGetUserOverview: onGetUserOverview,
  onGetUserById: onGetUserById,
  onUpdateUserInfo: onUpdateUserInfo,
  onGetStaticEnv: onGetStaticEnv,
  onGetEnvIcons: onGetEnvIcons,
  onAddUpdateEnv: onAddUpdateEnv,
  onGetUserEnvById: onGetUserEnvById,
  onGetUserEnvs: onGetUserEnvs,
  onGetUserGroupDD: onGetUserGroupDD,
  onGetEnvWithDeviceCount: onGetEnvWithDeviceCount,
  onGetFrndAndFamlyList: onGetFrndAndFamlyList,
  onAddFrndAndFmly: onAddFrndAndFmly,
  onDeleteFrndAndFmly: onDeleteFrndAndFmly,
  onGetFFDeviceList: onGetFFDeviceList,
  onSelectFFDevice: onSelectFFDevice,
  onGetUserProdCat: onGetUserProdCat,
  onGetProdByCatId: onGetProdByCatId,
  onGetDuplicateSr: onGetDuplicateSr,
  onGetDelears: onGetDelears,
  onSaveDevice: onSaveDevice,
  onGetUserDevices: onGetUserDevices,
  onGetCompanyDevices: onGetCompanyDevices,
  onUpdateUserDevice: onUpdateUserDevice,
  onDeleteDevice: onDeleteDevice,
  onGetDeviceOverview: onGetDeviceOverview,
  onUpdateReceipt: onUpdateReceipt,
  onAddDeviceColor: onAddDeviceColor,
  onUpdateDeviceColor: onUpdateDeviceColor,
  onGetUserNotes: onGetUserNotes,
  onAddUpdateUserNotes: onAddUpdateUserNotes,
  onAddUpdateBUNotes: onAddUpdateBUNotes,
  onDeleteUserNotes: onDeleteUserNotes,
  onDeleteBUNotes: onDeleteBUNotes,
  onGetUserActivity: onGetUserActivity,
  onGetEUActivity: onGetEUActivity,
  onGetBUActivity: onGetBUActivity,
  onGetSpaInfo: onGetSpaInfo,
  onSelectSpaLocation: onSelectSpaLocation,
  onResetSpaFields: onResetSpaFields,
  onAddUpdateSpaSetting: onAddUpdateSpaSetting,
  onChangeWaterTreatment: onChangeWaterTreatment,
  onUpdataGlobalSetting: onUpdataGlobalSetting,
  onUpdataTempSetting: onUpdataTempSetting,
  onGetContactTypeById: onGetContactTypeById,
  onGetAdrsByUser: onGetAdrsByUser,
  onAddCusAdr: onAddCusAdr,
  onResetPassword: onResetPassword,
  onGetAllAdmins: onGetAllAdmins,
  onGetAdminById: onGetAdminById,
  onGetContactTypesDDL: onGetContactTypesDDL,
  onGetSpaChemicalTestingList: onGetSpaChemicalTestingList,
  onGetAllFilterLife: onGetAllFilterLife,
  onGetAllWaterLife: onGetAllWaterLife,
  onGetChemicalTestingStats: onGetChemicalTestingStats,
  onGetFilterLifeStats: onGetFilterLifeStats,
  onGetWaterLifeStats: onGetWaterLifeStats,
  onGetSpaChemicalHistoryWithDates: onGetSpaChemicalHistoryWithDates,
  onAddUpdateFilterLife: onAddUpdateFilterLife,
  onAddUpdateWaterLife: onAddUpdateWaterLife,
  onAddUpdateSPAChemicalTesting: onAddUpdateSPAChemicalTesting,
  onUploadRecipt: onUploadReciept,
  onTransferOwnershipDevice: onTransferOwnershipDevice,
  onSaveSpaSettings: onSaveSpaSettings,
  onBCListViewChange: onBCListViewChange,
  onEUListViewChange: onEUListViewChange,
  onGetAllDelears: onGetAllDelears,
  onDeleteCusAddress: onDeleteCusAddress,
  onDeleteEnv: onDeleteEnv,
};
