import React, { useState, useEffect } from "react";
import { ChemicalHistoryChart } from "./ChemicalHistoryChart";
import { ChemicalTestTable } from "./ChemicalTestTable";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { ChemicalTestingBanner } from "./ChemicalTestingBanner";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function ChemicalTesting({ activeTab, deviceId }) {
  const [isUpdated, setIsUpdated] = useState(false);
  const dispatch = useDispatch();
  const [transformedData, setTransformedData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  const onTabChange = (tab, tabsData) => {
    let currentTab = "";
    switch (tab) {
      case 0:
        currentTab = "ph";
        break;
      case 1:
        currentTab = "chlorine";
        break;

      default:
        currentTab = "alkalinity";
        break;
    }
    if (Object.keys(tabsData).length) {
      const labels = Object.keys(tabsData[currentTab]);
      const data = labels.map((key) => tabsData[currentTab][key]);
      setChartLabels(labels);
      setChartData(data);
    }
  };

  const arrangeChemicalHistoryWithDates = (historyData) => {
    const alteredData = {};
    const labels = [];
    historyData.forEach(({ data }) => {
      data.forEach(({ day, chlorine, ph, alkalinity, month, year }) => {
        const monthYear = `${months[+month - 1]} ${year}`;
        if (!labels.includes(monthYear)) {
          labels.push(monthYear);
        }

        if (!alteredData.ph) alteredData.ph = {};
        if (!alteredData.alkalinity) alteredData.alkalinity = {};
        if (!alteredData.chlorine) alteredData.chlorine = {};

        alteredData.ph[monthYear] = (alteredData.ph[monthYear] || 0) + ph;
        alteredData.alkalinity[monthYear] =
          (alteredData.alkalinity[monthYear] || 0) + alkalinity;
        alteredData.chlorine[monthYear] =
          (alteredData.chlorine[monthYear] || 0) + chlorine;
      });
    });
    setTransformedData(alteredData);
    onTabChange(0, alteredData);
  };

  useEffect(() => {
    const currentDate = new Date().getDate();
    const year = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const endDateRequest = `${year}-${currentMonth}-${currentDate}T00:00:00.000Z`;
    dispatch(
      endUserActions.onGetSpaChemicalHistoryWithDates({
        deviceId: deviceId,
        startDate: `${year}-01-01T00:00:00.000Z`,
        endDate: `${endDateRequest}`,
      })
    ).then((res) => {
      arrangeChemicalHistoryWithDates(res?.data ?? []);
    });
  }, []);

  const afterPickupTheDate = ({ startDate, endDate }) => {
    dispatch(
      endUserActions.onGetSpaChemicalHistoryWithDates({
        deviceId: deviceId,
        startDate: startDate,
        endDate: endDate,
      })
    ).then((res) => {
      arrangeChemicalHistoryWithDates(res?.data ?? []);
    });
  };

  const onDatePick = (start, end) => {
    const currentDate = new Date().getDate();
    const startDate = start + "-01T00:00:00.000Z";
    const endDate = end + `-${currentDate}T00:00:00.000Z`;
    afterPickupTheDate({ startDate, endDate });
  };

  return (
    <>
      <div className="waterTreatment">
        <ChemicalTestingBanner deviceId={deviceId} isUpdated={isUpdated} />
      </div>
      {activeTab === "2" && (
        <ChemicalHistoryChart
          onDatePick={onDatePick}
          transformedData={transformedData}
          onTabChange={onTabChange}
          chartData={chartData}
          chartLabels={chartLabels}
        />
      )}
      <ChemicalTestTable
        deviceId={deviceId}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
        arrangeChemicalHistoryWithDates={arrangeChemicalHistoryWithDates}
      />
    </>
  );
}

export default ChemicalTesting;
