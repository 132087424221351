import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
import { Button, Switch } from "@material-ui/core";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import SelectImage from "./selectImage";
import {
  newsroomTheme,
  locatorVectorLeft,
  locatorVector,
  LocatorPath96Up,
  LocatorPath96Down,
} from "../../../../../resources/images/index";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/common/Loader";
import { toast } from "react-toastify";
import { concat, sortBy, orderBy } from "lodash";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { BiX } from "react-icons/bi";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "6px",
    float: "left",
    width: "100%",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  hoursWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function Design({ startLoading, stopLoading }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeTabId, setActiveTabId] = useState("tab1");
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [menuOptions, setMenuOptions] = useState([]);
  const [selectedAvailableMenu, setSelectedAvailableMenu] = useState(null);
  const [selectedMenuOptions, setSelectedMenuOptions] = useState([]);

  const loadData = () => {
    startLoading();
    dispatch(newsroomActions.onGetFeaturedContent()).then(response => {
      if (response && response.success && response.data) {
        dispatch(
          newsroomActions.onChange("featuredContentValue", response.data)
        );
      } else {
        toast.error(response?.message || "Somthing went wrong!");
      }
      dispatch(newsroomActions.onGetMenuOptions()).then(res => {
        if (res && res.success && res.data) {
          dispatch(newsroomActions.onGetNavigations()).then(resp => {
            if (resp && resp.success && resp.data) {
              if (res?.data.length > 0) {
                const smOpts = [];
                const Opts = [];
                res?.data?.forEach(mo => {
                  const sMO = resp?.data?.find(o => o.menuId === mo.id);
                  if (sMO) {
                    smOpts.push({
                      ...sMO,
                      id: mo.id,
                      url: mo.url,
                    });
                  } else {
                    Opts.push({
                      ...mo,
                    });
                  }
                });
                if (smOpts.length > 0)
                  setSelectedMenuOptions(sortBy(smOpts, ["sort"]));
                setMenuOptions([
                  ...Opts.map((o, i) => {
                    return {
                      ...o,
                      sort: smOpts.length + (i + 1),
                    };
                  }),
                ]);
              }
            }
          });
        }
      });

      stopLoading();
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const changeFeaturedContentValue = (value, index, fieldName) => {
    dispatch(
      newsroomActions.onChangeFeaturedContentValue(index, value, fieldName)
    );
  };

  const removeFeaturedContent = (index, id) => {
    startLoading();
    if (id > 0) {
      dispatch(newsroomActions.onDeleteNewsRoomContent(id)).then(data => {
        if (data && data.success) {
          toast.success("Content successfully deleted");
        } else {
          toast.error(data?.message);
        }
        dispatch(newsroomActions.removeFeaturedContent(index, id));
        stopLoading();
      });
    } else {
      dispatch(newsroomActions.removeFeaturedContent(index, id));
      stopLoading();
    }
  };

  const updateNewsRoomNavigation = data => {
    startLoading();
    dispatch(newsroomActions.onSaveNewsRoomNavigation(data)).then(data => {
      if (data && data.success) {
        toast.success("Navigation successfully updated");
      } else {
        toast.error(data?.message);
      }
      stopLoading();
    });
  };

  const onUpdateSettings = (
    contentId,
    slideTitle,
    slideSubTitle,
    bannerImageUrl,
    linkTo,
    url,
    urlTarget,
    showImageOnly
  ) => {
    startLoading();
    dispatch(
      newsroomActions.onSaveFeaturedContent(
        contentId,
        slideTitle,
        slideSubTitle,
        bannerImageUrl,
        linkTo,
        url,
        urlTarget,
        showImageOnly
      )
    ).then(data => {
      if (data && data.success) {
        toast.success("Content successfully updated");
      } else {
        toast.error(data?.message);
      }
      stopLoading();
    });
  };

  const uploadBannerImage = file => {
    startLoading();
    dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
      if (data && data.success && data.data) {
        dispatch(
          newsroomActions.onChangeFeaturedContentValue(
            imageIndex,
            data.data.url,
            "bannerImageUrl"
          )
        );
        setShowFeatureImage(!showFeatureImage);
      }
      stopLoading();
    });
  };

  const { featuredContentValue } = useSelector(state => state.newsRoomReducer);

  return (
    <div className='accordianOuterWrapper'>
      {isLoading ? <Loader /> : null}
      <AccordionContainer title='Homepage Featured Content'>
        <Tabs
          className='featuredContentTab'
          defaultTab={activeTabId}
          onChange={tabId => setActiveTabId(`${tabId}`)}>
          <div className={classes.tabsHeader}>
            <TabList>
              {featuredContentValue.length > 0 &&
                featuredContentValue.map((item, index) => {
                  return (
                    <Tab
                      style={{ marginRight: "10px" }}
                      key={`${"tab"}${index + 1}`}
                      tabFor={`${"tab"}${index + 1}`}>
                      {`${"SLIDE "}${index + 1}${" "}`}

                      {index > 1 && (
                        <BiX
                          onClick={() => {
                            setActiveTabId(`${"tab"}${index}`);
                            removeFeaturedContent(index, item.id);
                          }}
                        />
                      )}
                    </Tab>
                  );
                })}
              <Tab
                style={{ marginLeft: "20px" }}
                key='addTab'
                onClick={() =>
                  dispatch(newsroomActions.addNewFeaturedContent())
                }>
                + ADD SLIDE
              </Tab>
            </TabList>
          </div>
          {featuredContentValue.length > 0 &&
            featuredContentValue.map((item, index) => {
              return (
                <TabPanel
                  key={`${"tab"}${index + 1}`}
                  tabId={`${"tab"}${index + 1}`}>
                  <Grid container className={classes.gridWrapper}>
                    <div className={classes.root}>
                      <div className='npBannerWrap'>
                        <Grid item xs={12} className={classes.gridWrapper}>
                          <InputField
                            placeholder='Not Available'
                            inputlabel='Slide Text Title'
                            value={item.slideTitle}
                            onChange={e =>
                              changeFeaturedContentValue(
                                e.target.value,
                                index,
                                "slideTitle"
                              )
                            }
                            // validators={["matchRegexp:^.{0,150}$"]}
                            // errorMessages={["Maximum length is 50 characters"]}
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.gridWrapper}>
                          <InputField
                            placeholder='Not Available'
                            inputlabel='Slide Text Subtitle'
                            value={item.slideSubTitle}
                            onChange={e =>
                              changeFeaturedContentValue(
                                e.target.value,
                                index,
                                "slideSubTitle"
                              )
                            }
                            // validators={["matchRegexp:^.{0,100}$"]}
                            // errorMessages={["Maximum length is 100 characters"]}
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.gridWrapper}>
                          <label className='switchLabel'>
                            Hide Titles/Show Image Only
                          </label>
                          <Switch
                            checked={item.showImageOnly}
                            onChange={e =>
                              changeFeaturedContentValue(
                                e.target.checked,
                                index,
                                "showImageOnly"
                              )
                            }
                          />
                        </Grid>
                        <div>
                          <Grid item xs={6} className={classes.gridWrapper}>
                            <Grid container spacing={3}>
                              <Grid
                                item
                                xs={6}
                                className={
                                  (classes.gridWrapper, classes.featuredImage)
                                }>
                                <label className={classes.featuredImageHeading}>
                                  Banner Image*
                                </label>
                                <div className='uploadImageWrapper'>
                                  <Button
                                    variant='outlined'
                                    color='primary'
                                    className={classes.selectBtn}
                                    onClick={() => {
                                      setImageIndex(index);
                                      setShowFeatureImage(!showFeatureImage);
                                    }}>
                                    {item.bannerImageUrl !== "" &&
                                    item.bannerImageUrl !== "string"
                                      ? "Change"
                                      : "Select"}
                                  </Button>
                                </div>
                                {showFeatureImage && (
                                  <SelectImage
                                    closePopup={() =>
                                      setShowFeatureImage(false)
                                    }
                                    openPopup={showFeatureImage}
                                    title={"Upload Banner Image"}
                                    addProfileImage={value => {
                                      const slctd_img =
                                        document.getElementsByClassName(
                                          "MuiDropzonePreviewList-image"
                                        );
                                      if (
                                        slctd_img?.[0] &&
                                        slctd_img?.[0]?.naturalHeight >= 500 &&
                                        slctd_img?.[0]?.naturalWidth <= 400
                                      ) {
                                        uploadBannerImage(value);
                                      } else {
                                        toast.error(
                                          "Image height should be >= 500px & width should be <= 400px."
                                        );
                                      }
                                    }}
                                  />
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={
                                  (classes.gridWrapper, classes.featuredImage)
                                }>
                                {item.bannerImageUrl !== "" && (
                                  <img
                                    id='ban-img'
                                    alt='banner iamge'
                                    className='bannerImgNP'
                                    src={item.bannerImageUrl}
                                  />
                                )}
                              </Grid>
                            </Grid>
                            <h3>Preview</h3>
                          </Grid>
                          <Grid item xs={6} className={classes.gridWrapper}>
                            <Grid item xs={7} className={classes.gridWrapper}>
                              <div className='inputWrapper'>
                                <FormControl className={classes.formControl}>
                                  <label>{"Link To*"}</label>
                                  <Select
                                    value={item.linkTo}
                                    onChange={e =>
                                      changeFeaturedContentValue(
                                        e.target.value,
                                        index,
                                        "linkTo"
                                      )
                                    }
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    fullWidth
                                    variant={"outlined"}
                                    // required
                                  >
                                    <MenuItem key='0' value='' disabled>
                                      Link To
                                    </MenuItem>
                                    <MenuItem key='1' value='URL/Website'>
                                      URL/Website
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </Grid>
                            <Grid item xs={7} className={classes.gridWrapper}>
                              <InputField
                                placeholder='https://www.website.com'
                                inputlabel='URL*'
                                value={item.url}
                                onChange={e =>
                                  changeFeaturedContentValue(
                                    e.target.value,
                                    index,
                                    "url"
                                  )
                                }
                                // validators={["matchRegexp:^.{0,100}$"]}
                                // errorMessages={["Maximum length is 100 characters"]}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={classes.gridWrapper}
                              style={{ padding: "34px 10px" }}>
                              <label className='urlTargetLabel'>
                                {"Target:"}
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className={classes.gridWrapper}
                              style={{ padding: "25px 0px" }}>
                              <div className='inputWrapper urlTargetField'>
                                <FormControl className={classes.formControl}>
                                  <Select
                                    value={item.urlTarget}
                                    onChange={e =>
                                      changeFeaturedContentValue(
                                        e.target.value,
                                        index,
                                        "urlTarget"
                                      )
                                    }
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    style={{ color: "#3699ff" }}
                                    fullWidth
                                    // variant={"outlined"}
                                    // required
                                  >
                                    <MenuItem key='0' value='' disabled>
                                      Choose
                                    </MenuItem>
                                    <MenuItem key='1' value='Same Window'>
                                      Same Window
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        <Grid item xs={12}>
                          {/* <h3>Preview</h3> */}
                          <div className='previewContainer'>
                            <Grid item xs={8} className={classes.gridWrapper}>
                              {!item.showImageOnly && (
                                <div className='previewTextContainer'>
                                  <svg>
                                    <path
                                      id='MyPath'
                                      d='M260 150, 0 150, 0 0, 300 0 Q260 150, 260 150'
                                      stroke='transparent'
                                      fill='#142560'
                                    />
                                  </svg>
                                  <div className='previewText'>
                                    <h2>{item.slideTitle}</h2>
                                    <p>{item.slideSubTitle}</p>
                                  </div>
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={4} className={classes.gridWrapper}>
                              {item.bannerImageUrl !== "" && (
                                <img
                                  alt='BannerImage'
                                  className='BannerImage'
                                  src={item.bannerImageUrl}
                                />
                              )}
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <span className='saveChangesBtn'>
                            <Button
                              variant='contained'
                              color='primary'
                              onClick={() =>
                                onUpdateSettings(
                                  item.id,
                                  item.slideTitle,
                                  item.slideSubTitle,
                                  item.bannerImageUrl,
                                  item.linkTo,
                                  item.url,
                                  item.urlTarget,
                                  item.showImageOnly
                                )
                              }>
                              Save Changes
                            </Button>
                          </span>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </TabPanel>
              );
            })}
        </Tabs>
      </AccordionContainer>
      <AccordionContainer title='Newsroom Navigation'>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={5} className={classes.gridWrapper}>
              <label className='locatorFilterTitle'>
                Available Menu Options
              </label>
              <Grid item xs={12} className='locatorFilterContentGrid'>
                {menuOptions.length > 0 &&
                  menuOptions.map(item => {
                    return (
                      <div
                        className={`locatornewsroomOption ${
                          item?.id === selectedAvailableMenu?.id ? "active" : ""
                        }`}
                        onClick={() => {
                          setSelectedAvailableMenu(item);
                        }}>
                        <div className='locatorFilterOptionText'>
                          <h6>{item.name}</h6>
                        </div>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              className={classes.gridWrapper}
              style={{ padding: "110px 0px" }}>
              <Grid item xs={12} className='newsroomFilterVectorGrid'>
                <img
                  alt='locator vector left'
                  className='locatorVectorImg'
                  style={{ cursor: "pointer" }}
                  src={locatorVectorLeft}
                  onClick={() => {
                    const smOption = selectedMenuOptions.find(
                      m => m.id === selectedAvailableMenu?.id
                    );
                    if (smOption) {
                      setSelectedMenuOptions(
                        selectedMenuOptions.filter(
                          m => m.id !== selectedAvailableMenu?.id
                        )
                      );
                      setMenuOptions(
                        concat(menuOptions, {
                          id: smOption.menuId,
                          name: smOption.name,
                          url: smOption.url,
                        })
                      );
                      setSelectedAvailableMenu(null);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} className='newsroomFilterVectorGrid'>
                <img
                  alt='locator vector'
                  className='locatorVectorImg'
                  style={{ cursor: "pointer" }}
                  src={locatorVector}
                  onClick={() => {
                    const smOption = menuOptions.find(
                      m => m.id === selectedAvailableMenu?.id
                    );
                    if (smOption) {
                      setSelectedMenuOptions([
                        ...orderBy(
                          concat(selectedMenuOptions, {
                            newsRoomId: 1,
                            menuId: smOption.id,
                            sort: selectedMenuOptions.length + 1,
                            name: smOption.name,
                            url: smOption.url,
                          }),
                          ["sort"],
                          ["asc"]
                        ),
                      ]);
                      setMenuOptions(
                        menuOptions.filter(
                          m => m.id !== selectedAvailableMenu?.id
                        )
                      );
                      setSelectedAvailableMenu(null);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={5} className={classes.gridWrapper}>
              <label className='locatorFilterTitle'>Main Navigation</label>
              <Grid item xs={12} className='locatorFilterContentGrid'>
                {selectedMenuOptions.length > 0 &&
                  selectedMenuOptions.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={`locatornewsroomOption ${
                          item?.id === selectedAvailableMenu?.id ? "active" : ""
                        }`}
                        onClick={() => {
                          setSelectedAvailableMenu(item);
                        }}>
                        <div className='locatorFilterOptionText'>
                          <h6>{item.name}</h6>
                        </div>
                        <div
                          style={{
                            paddingRight: "20px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <span
                            className='MuiButton-label'
                            onClick={() => {
                              if (i - 1 >= 0) {
                                const sMOpt = { ...selectedMenuOptions[i] };
                                selectedMenuOptions[i] = {
                                  ...selectedMenuOptions[i],
                                  sort: selectedMenuOptions[i - 1]?.sort,
                                };
                                selectedMenuOptions[i - 1] = {
                                  ...selectedMenuOptions[i - 1],
                                  sort: sMOpt?.sort,
                                };
                              }
                              const newList = orderBy(
                                selectedMenuOptions,
                                ["sort"],
                                ["asc"]
                              );
                              setSelectedMenuOptions([...newList]);
                            }}>
                            <img
                              alt='locator path'
                              className='LocatorPathIcon'
                              src={LocatorPath96Up}
                            />
                          </span>
                          <span
                            className='MuiButton-label'
                            onClick={() => {
                              if (i + 1 <= selectedMenuOptions.length) {
                                const sMOpt = { ...selectedMenuOptions[i] };
                                selectedMenuOptions[i] = {
                                  ...selectedMenuOptions[i],
                                  sort: selectedMenuOptions[i + 1]?.sort,
                                };
                                selectedMenuOptions[i + 1] = {
                                  ...selectedMenuOptions[i + 1],
                                  sort: sMOpt?.sort,
                                };
                              }
                              const newList = orderBy(
                                selectedMenuOptions,
                                ["sort"],
                                ["asc"]
                              );
                              setSelectedMenuOptions([...newList]);
                            }}>
                            <img
                              alt='locator path'
                              className='LocatorPathIcon'
                              src={LocatorPath96Down}
                            />
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <span className='saveChangesBtn'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => updateNewsRoomNavigation(selectedMenuOptions)}>
            Save Changes
          </Button>
        </span>
      </AccordionContainer>
      <AccordionContainer title='Newsroom Theme'>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.gridWrapper}>
              <img
                alt='newsroom theme'
                className='newsroomThemeImg'
                src={newsroomTheme}
              />
              <div className='newsroomThemeTitle'>
                OFish Default “Wave” Newsroom
              </div>
            </Grid>
          </Grid>
        </div>
      </AccordionContainer>
    </div>
  );
}
