import React from 'react';
import {wavehand} from '../../../resources/images/index'

function WelcomeText(props) {
    return (
        <div className="welcomeText"> 
        <span className="heading">Hey There! <i><img src={wavehand} alt="" /> </i></span>
         <span className="welcomeBodyText">

Are you an authorized Aquatic AV reseller or member of the press? Login to our newsroom to unlock full-access to downloadable assets and releases.</span>
        </div>
    );
}

export default WelcomeText;