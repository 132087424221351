import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  EditIcon,
  SortIcon,
  StarFill,
  StarOutline,
  Delete,
} from "../../LeftNav/SvgIcons";
import { contactsActions } from "../../../../store/contacts/contactsActions";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },

  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

const CustomerGroupGrid = ({
  customerGroupsData,
  startLoading,
  stopLoading,
  loadData,
  onEditClick,
  order,
  onOrderBy,
  onMarkFavEndUserGroup,
  EditableRecord,
  EditAbleRow,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const onItemSelect = itemId => {
    const itemExists =
      selectedItems.length > 0 && selectedItems.find(c => c === itemId);
    if (itemExists) {
      setSelectedItems(selectedItems.filter(c => c !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  useEffect(() => {
    if (isAllSelected === true) {
      const allIds = customerGroupsData.map(l => l.id);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  }, [isAllSelected, customerGroupsData]);

  const deleteBusinessGroups = ({ idsList }) => {
    startLoading();
    dispatch(contactsActions.onDeleteGroups(idsList)).then(res => {
      if (!res?.success) {
        toast.error(res?.message);
        stopLoading();
      } else {
        loadData();
      }
    });
  };

  return (
    <Paper style={{ paddingTop: "0" }}>
      <Paper className={classes.gridTopNav}>
        {selectedItems.length > 0 && (
          <Grid
            item
            md={9}
            className={(classes.deleteItems, "tableGridActions")}>
            {/* <Button disabled>Archive</Button>
            <Button disabled>Block</Button> */}
            <Button
              onClick={() => deleteBusinessGroups({ idsList: selectedItems })}>
              Delete
            </Button>
          </Grid>
        )}
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox
                className={classes.checkBoxFont}
                checked={isAllSelected}
                onClick={() => setIsAllSelected(!isAllSelected)}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Group Name
              <TableSortLabel
                active={order.orderByKey === "GroupName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "GroupName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              No. of Contacts
              <TableSortLabel
                active={order.orderByKey === "TotalContacts"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "TotalContacts",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Date Added
              <TableSortLabel
                active={order.orderByKey === "DateCreated"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "DateCreated",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Group Color
            </TableCell>
            <TableCell
              className={
                (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
              }>
              Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerGroupsData?.map((item, index) => {
            return EditableRecord?.id === item.id ? (
              <TableRow key={index}>
                <TableCell
                  style={{
                    padding: "0px",
                  }}
                  colSpan={12}>
                  {EditAbleRow}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow
                key={index}
                className={`gridBodyRow endUserRow ${
                  selectedItems.length > 0 &&
                  selectedItems?.includes(item.userId)
                    ? "boxChecked"
                    : " "
                }`}>
                <TableCell className={(classes.gridHeaderInner, "gridRow")}>
                  <Checkbox
                    className={(classes.checkBoxFont, "enduserCheckbox")}
                    onClick={() => onItemSelect(item.id)}
                    checked={
                      selectedItems.length > 0 &&
                      selectedItems?.includes(item.id)
                    }
                  />
                  <span className='endUserPic'>
                    {item.image ? (
                      <img src={item.image} alt='' />
                    ) : (
                      <i className='userInitials'>{`${item?.groupName
                        ?.trim()
                        ?.charAt(0)
                        ?.toUpperCase()}`}</i>
                    )}
                  </span>
                </TableCell>

                <TableCell className={classes.gridBodyInner}>
                  {item.groupName}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "tableItemHighlight")}>
                  {`${item.totalContacts}${" contacts"}`}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {moment(item.dateAdded, "DD-MM-YYYY").format("MM/DD/YYYY")}
                </TableCell>
                <TableCell className={classes.inUseYes}>
                  <span
                    style={{
                      height: "36px",
                      width: "36px",
                      borderRadius: "50%",
                      display: "flex",
                      backgroundColor: item.color ? item.color : "#EB5757",
                    }}
                  />
                </TableCell>

                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions'>
                    <span
                      onClick={() =>
                        onMarkFavEndUserGroup({
                          ...item,
                        })
                      }>
                      {item?.isFavorite ? <StarFill /> : <StarOutline />}
                    </span>
                    <span
                      onClick={() => {
                        onEditClick(item);
                        // history.push("/new-business-group?id=" + item.id);
                      }}>
                      <EditIcon />
                    </span>
                    {/* <span>
                      <ViewEye />
                    </span> */}
                    <span
                      onClick={() =>
                        deleteBusinessGroups({ idsList: [item?.id] })
                      }>
                      <Delete />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {customerGroupsData?.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any customer group.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default CustomerGroupGrid;
