import React from "react";

const GreenButton = ({ onClick, title, icon }) => (
  <div className='clickable-button clickable-button--green' onClick={onClick}>
    {icon && 
     <span className='icon'>{icon}</span>
    }
    {title &&
     <span className='text'>{title}</span>
    }
  </div>
);

export { GreenButton };
