import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Checkbox, Grid, makeStyles } from "@material-ui/core";
import { first, last, uniq } from "lodash";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import InputField from "../../../../components/common/InputField";
import { Dropdown, ImageUploader } from "../../../../components/common";
import { star, starOutlineYellow } from "../../../../resources/images";
import { toast } from "react-toastify";
import { IoMdPersonAdd } from "react-icons/io";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import Loader from "../../../../components/common/Loader";
import { useLocation } from "react-router";
import { AddressAutoComplete } from "../../../../components/common";
import { StarFill, StarOutline } from "../../LeftNav/SvgIcons";

const useStyles = makeStyles(theme => ({
  formStyle: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  gridWrapper: {
    position: "relative",
    marginBottom: "0px",
  },
  withStarButtonWrap: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginLeft: "10px",
    },
  },
  accessCheckBoxs: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "149px",
    flexWrap: "wrap",
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
    position: "absolute",
    top: "80px",
  },
}));

const getField = ({ contactFieldsData = [], fieldId }) =>
  contactFieldsData.find(f => f.fieldId === fieldId);

export const getCustomFields = ({ contactFieldsData = [], fieldIds = [] }) => {
  const filedsInfo = {};
  fieldIds?.forEach(fId => {
    const f = getField({ contactFieldsData, fieldId: fId });
    if (f) {
      filedsInfo[fId] = {
        ...f,
        fieldLabel:
          (f.fieldSettings?.length > 0 &&
            f.fieldSettings.find(fk => ["label", "lable"].includes(fk.key))
              ?.additionalData) ||
          f.fieldName,
      };
    }
  });
  return filedsInfo;
};

const NewCompanyContacts = ({
  phonecodes,
  countries,
  cities,
  provinces,
  accessPolicies,
  companyData,
  comapnyContacts,
  onNewUser,
  contactTypesInfo,
  selectedContactType,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [companyContacts, setCompanyContacts] = useState(comapnyContacts);
  const [adminDetail, setAdminDetail] = useState({});
  const history = useLocation();

  useEffect(() => {
    setCompanyContacts([...comapnyContacts]);
    if (comapnyContacts.length > 0) {
      const adminObject = first(
        comapnyContacts.filter(c => c?.policies?.includes(2))
      );
      if (adminObject)
        setAdminDetail({
          ...adminObject,
        });
    }
  }, [comapnyContacts]);

  useLayoutEffect(() => {
    if (history?.state) {
      const {
        state: { scrollTo },
      } = history;
      if (scrollTo) {
        var targetElm = document.querySelector(scrollTo);
        if (targetElm) targetElm?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [history]);

  return (
    <ValidatorForm className='businessContacts'>
      {isLoading ? <Loader /> : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "25px 50px 10px",
          borderBottom: "1px solid #a9b2bc",
          backgroundColor: "white",
          borderRadius: "12px 12px 0px 0px",
        }}>
        <h2>Company Contacts</h2>
        <span>
          <Button
            color='primary'
            variant='contained'
            startIcon={<IoMdPersonAdd style={{ fontSize: "15px" }} />}
            onClick={() => {
              companyContacts.push({
                companyId: companyData?.id,
              });
              setCompanyContacts([...companyContacts]);
            }}>
            Add Contact
          </Button>
        </span>
      </div>

      {companyContacts.length === 0 && (
        <ContactForm
          key={adminDetail.id || 0}
          setLoading={setLoading}
          companyId={companyData?.id}
          companyData={companyData}
          userDetail={adminDetail}
          phonecodes={phonecodes}
          countries={countries}
          cities={cities}
          provinces={provinces}
          accessPolicies={accessPolicies}
          onNewUser={onNewUser}
          contactFieldsData={first(contactTypesInfo)?.["contactFieldsData"]}
          selectedContactType={selectedContactType}
        />
      )}

      {first(contactTypesInfo) &&
        companyContacts
          // .filter(c => c.id !== adminDetail.id)
          .map(cc => (
            <ContactForm
              isUserForm={!cc?.policies?.includes(2)}
              key={cc.id}
              setLoading={setLoading}
              companyId={companyData?.id}
              companyData={companyData}
              userDetail={cc}
              phonecodes={phonecodes}
              countries={countries}
              cities={cities}
              provinces={provinces}
              accessPolicies={accessPolicies}
              onNewUser={onNewUser}
              contactFieldsData={first(contactTypesInfo)?.["contactFieldsData"]}
              selectedContactType={selectedContactType}
            />
          ))}
    </ValidatorForm>
  );
};

const ContactForm = ({
  isUserForm,
  setLoading,
  companyId,
  companyData,
  userDetail,
  phonecodes,
  countries,
  cities,
  provinces,
  accessPolicies,
  onNewUser,
  contactFieldsData,
  selectedContactType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let isDisable = [];

  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [featureImgUpload, setFeatureImgUpload] = useState(null);
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryId, setCountryId] = useState("");
  const [cityId, setSelectCityId] = useState("");
  const [stateId, setSelectedStatesId] = useState("");
  const [adminDetail, setUserDetail] = useState({ ...userDetail });
  const [errorMessages, setErrorMessages] = useState({});
  const [selectedLotLong, setSelectedLocation] = useState({});
  const [customFields, setCustomFields] = useState({});
  const [isEditAble, setIsEditAble] = useState([]);

  const sepObjectProp = ({ propName, value }) => {
    setUserDetail({
      ...adminDetail,
      [propName]: value,
    });
  };

  useLayoutEffect(() => {
    if (userDetail?.id) {
      setFeatureImgUpload(userDetail.image);
      setCountryId(userDetail.country);
      setSelectCityId(userDetail.city);
      setSelectedStatesId(userDetail.state);
      if (userDetail.phoneNumber) {
        setPhoneCode(first(userDetail?.phoneNumber?.split("-")));
        setPhoneNumber(last(userDetail?.phoneNumber?.split("-")));
      }
    }
  }, [userDetail?.id]);

  useEffect(() => {
    if (adminDetail?.adminSameAddress === true) {
      setCountryId(companyData.countryName);
      setSelectCityId(companyData.cityName);
      setSelectedStatesId(companyData.stateName);
      setUserDetail({
        ...adminDetail,
        zipCode: companyData?.zipCode,
        address: companyData?.address,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adminDetail?.adminSameAddress,
    companyData?.address,
    companyData.city,
    companyData.country,
    companyData.state,
    companyData?.zipCode,
  ]);

  useEffect(() => {
    if (contactFieldsData?.length > 0)
      setCustomFields(
        getCustomFields({
          contactFieldsData,
          fieldIds: contactFieldsData.map(f => f.fieldId),
        })
      );
  }, [contactFieldsData]);

  const onSaveButtonPress = () => {
    if (companyId && companyId > 0) {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).*$/;
      const result = adminDetail?.password
        ? passwordRegex.test(adminDetail?.password)
        : true;
      if (
        isValidForm({
          firstName: adminDetail?.firstName,
          email: adminDetail?.email,
          password: adminDetail?.password,
        }) &&
        (adminDetail?.id || (!adminDetail?.id && (!customFields[4] || result)))
      ) {
        const imageURL = featureImgUpload ? featureImgUpload : "";
        setLoading(true);
        const userInfo = {
          id: 0,
          ...adminDetail,
          companyId: companyId,
          confirmEmail: adminDetail.email,
          password: adminDetail?.password ? adminDetail?.password : "P@ssword1",
          confirmPassword: adminDetail?.password
            ? adminDetail?.password
            : "P@ssword1",
          countryName: countryId,
          cityName: cityId,
          stateName: stateId,
          phoneNumber: `${phoneCode}-${phoneNumber}`,
          isAdmin: !isUserForm,
          groupId: companyData.groupId,
          policies:
            adminDetail?.policies?.length > 0 ? adminDetail?.policies : [],
          image: imageURL,
          latitude: selectedLotLong?.lat || adminDetail?.longitude,
          longitude: selectedLotLong?.lng || adminDetail?.longitude,
          userType: adminDetail?.policies?.includes(2) ? 3 : 4,
          contactTypeId: selectedContactType?.id,
        };
        if (adminDetail?.id) {
          // const updateUserInfo = {
          //   id:adminDetail.id,
          //   companyId: companyId,
          //   countryId: `${countryId > 0 ? countryId : ""}`,
          //   cityId: `${cityId > 0 ? cityId : ""}`,
          //   stateId: `${stateId > 0 ? stateId : ""}`,
          //   phoneNumber: `${phoneCode}-${phoneNumber}`,

          // };
          dispatch(contactsActions.onUpdateUser(userInfo)).then(data => {
            if (data && data.success) {
              toast.success("User updated successfully");
              onNewUser(true);
            } else {
              toast.error("Something went wrong, Please try again later!");
            }
            setLoading(false);
          });
        } else {
          dispatch(contactsActions.onAddCustomer(userInfo)).then(data => {
            if (data && data.success) {
              toast.success(data.message);
              onNewUser(true);
            } else {
              if (data.message === "Error while creating the user")
                toast.error("Please make your password strong");
              else if (data.message === "Email is already taken.") {
                toast.error("Email is already taken.");
              } else
                toast.error("Something went wrong, Please try again later!");
            }
            setLoading(false);
          });
        }
      }
      if (!result) {
        if (adminDetail?.id) {
        } else
          toast.error(
            "Password must contain at least 1 uppercase, lowercase, number and special character"
          );
      }
    } else {
      toast.warn("Please add the Company first");
    }
  };

  const isValidForm = ({ firstName = "", email = "", password = "" }) => {
    const errorObj = {};
    if (
      `${firstName?.trim()}`.length > 0 &&
      `${email?.trim()}`.length &&
      (!customFields[4] || `${password?.trim()}`.length)
    ) {
      return true;
    } else {
      if (`${firstName?.trim()}`.length <= 0) {
        errorObj["firstName"] = "required";
      }
      if (`${email?.trim()}`.length <= 0) {
        errorObj["email"] = "required";
      }
      if (`${password?.trim()}`.length <= 0 && customFields[4]) {
        errorObj["password"] = "required";
      }
    }
    setErrorMessages({ ...errorObj });
  };

  return (
    <AccordionContainer
      title={`Contact Name (${isUserForm ? "User" : "Company Admin"})`}
      className={`${!isUserForm ? "isAdminForm" : ""}`}
      formButton={
        <span className={classes.withStarButtonWrap}>
          <Button
            color='primary'
            variant='contained'
            onClick={e => {
              e.preventDefault();
              onSaveButtonPress();
            }}>
            Save
          </Button>
          {/* <img
            src={adminDetail?.isFavourite ? star : starOutlineYellow}
            alt='star'
            onClick={() => {
              sepObjectProp({
                propName: "isFavourite",
                value: !adminDetail?.isFavourite,
              });
            }}
          /> */}
          <span
            onClick={() => {
              sepObjectProp({
                propName: "isFavourite",
                value: !adminDetail?.isFavourite,
              });
            }}
            style={{ display: "block", marginLeft: "10px" }}>
            {adminDetail?.isFavourite ? <StarFill /> : <StarOutline />}
          </span>
        </span>
      }>
      <div className='fr-view'>
        {customFields[12] && (
          <div className='row'>
            <div className='uploadWrapper'>
              <div className='containers'>
                <div className='imageWrapper'>
                  <img
                    alt={
                      customFields[12]?.fieldLabel || "Contact Profile Photo"
                    }
                    className='image'
                    src={
                      featureImgUpload ||
                      adminDetail?.image ||
                      "http://via.placeholder.com/700x500"
                    }
                  />
                </div>
              </div>
              <Button
                variant='outlined'
                color='primary'
                className='file-upload'
                style={{ height: 50, alignSelf: "center" }}
                onClick={() => {
                  setShowFeatureImage(!showFeatureImage);
                }}>
                Upload avatar
              </Button>
              <ImageUploader
                title={"Upload profile image"}
                showFeatureImage={showFeatureImage}
                setShowFeatureImage={() =>
                  setShowFeatureImage(!showFeatureImage)
                }
                onImageSelect={img => setFeatureImgUpload(img.url)}
              />
            </div>
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item xs={4} className={classes.gridWrapper}>
            <InputField
              placeholder={customFields[1]?.fieldLabel || "First Name"}
              inputlabel={customFields[1]?.fieldLabel || "First Name"}
              className={classes.gridWrapper}
              showRequired={true}
              validators={[
                "required",
                "matchRegexp:^[A-Za-z']+$",
                "matchRegexp:^.{0,50}$",
              ]}
              errorMessages={[
                "This field is required",
                "Special characters and space are not allowed",
                "Maximum length is 50 characters",
              ]}
              value={adminDetail?.firstName}
              onBlur={() => setErrorMessages({})}
              onChange={({ target }) =>
                sepObjectProp({ propName: "firstName", value: target.value })
              }
            />
            {errorMessages["firstName"] && (
              <span className={classes.errorMessage}>
                {errorMessages["firstName"]}
              </span>
            )}
          </Grid>

          <Grid item xs={4} className={classes.gridWrapper}>
            <InputField
              placeholder={customFields[2]?.fieldLabel || "Last name"}
              inputlabel={customFields[2]?.fieldLabel || "Last Name"}
              showRequired={true}
              validators={[
                "required",
                "matchRegexp:^[A-Za-z']+$",
                "matchRegexp:^.{0,50}$",
              ]}
              errorMessages={[
                "This field is required",
                "Special characters and space are not allowed",
                "Maximum length is 50 characters",
              ]}
              className={classes.gridWrapper}
              value={adminDetail?.lastName}
              onChange={({ target }) =>
                sepObjectProp({ propName: "lastName", value: target.value })
              }
            />
          </Grid>
        </Grid>
        <div className='gap' />
        <Grid container spacing={3}>
          <Grid item xs={5} className={classes.gridWrapper}>
            <InputField
              placeholder={customFields[3]?.fieldLabel || "E-mail address"}
              inputlabel={customFields[3]?.fieldLabel || "E-mail address"}
              className={classes.gridWrapper}
              showRequired={true}
              name='emailaddress'
              value={adminDetail?.email}
              validators={["required", "isEmail", "matchRegexp:^.{0,50}$"]}
              errorMessages={[
                "This field is required",
                "Please enter a valid email",
                "Maximum length is 50 characters",
              ]}
              onBlur={() => setErrorMessages({})}
              onChange={({ target }) =>
                sepObjectProp({ propName: "email", value: target.value })
              }
            />
            {errorMessages["email"] && (
              <span className={classes.errorMessage}>
                {errorMessages["email"]}
              </span>
            )}
          </Grid>

          {customFields[6] && (
            <>
              <Grid item xs={2} className={classes.gridWrapper}>
                <Dropdown
                  selectlabel={"Country Code"}
                  placeholder={"Country Code"}
                  value={phoneCode}
                  required={false}
                  customMenuItems={phonecodes?.map(c => {
                    return {
                      id: c.code,
                      name: c.code,
                    };
                  })}
                  handleChange={({ target }) => setPhoneCode(target.value)}
                />
              </Grid>
              <Grid item xs={4} className={classes.gridWrapper}>
                <InputField
                  placeholder={customFields[6]?.filedName || "Phone Number"}
                  inputlabel={customFields[6]?.filedName || "Phone Number"}
                  className={[classes.gridWrapper.phonenumber]}
                  validators={["matchRegexp:^[0-9]+$", "matchRegexp:^.{0,20}$"]}
                  errorMessages={[
                    "Special characters and space are not allowed",
                    "Maximum length is 20 characters",
                  ]}
                  id='phonenumber'
                  name='phonenumber'
                  value={phoneNumber}
                  onChange={({ target }) => setPhoneNumber(target.value)}
                />
              </Grid>
            </>
          )}
          <div className='gap' />
        </Grid>
        {customFields[5] && (
          <>
            <div className='gap' />
            <span
              style={{
                position: "relative",
                left: "-10px",
              }}>
              <Checkbox
                label='Same address as Company'
                checked={adminDetail?.adminSameAddress === true ? true : false}
                onChange={({ target }) => {
                  sepObjectProp({
                    propName: "adminSameAddress",
                    value: target.checked,
                  });
                }}
              />
              Same address as Company
            </span>

            <Grid container spacing={3}>
              <Grid item xs={7} className={classes.gridWrapper}>
                {/* <LocationAutoComplete
                  label='Address'
                  address={adminDetail?.address}
                  onLocationSelect={({ formatted_address, latlong }) => {
                    sepObjectProp({
                      propName: "address",
                      value: formatted_address,
                    });
                    setSelectedLocation(latlong);
                  }}
                /> */}
                <AddressAutoComplete
                  address={adminDetail?.address}
                  autoComId={"popupEnvLocInput"}
                  onChange={e => {
                    sepObjectProp({
                      propName: "address",
                      value: e.target.value,
                    });
                  }}
                  setPlace={p => {
                    isDisable = [];
                    setSelectCityId("");
                    setSelectedStatesId("");
                    setCountryId("");
                    sepObjectProp({
                      propName: "zipCode",
                      value: "",
                    });
                    const val =
                      document.getElementById("popupEnvLocInput")?.value;

                    p?.address_components?.forEach(x => {
                      if (x?.types?.includes("route")) {
                        sepObjectProp({
                          propName: "address",
                          value: x?.long_name,
                        });
                      }
                      if (x?.types?.includes("locality")) {
                        setSelectCityId(x?.long_name);
                        isDisable.push("city");
                      }
                      if (x?.types?.includes("administrative_area_level_1")) {
                        setSelectedStatesId(x?.long_name);
                        isDisable.push("state");
                      }
                      if (x?.types?.includes("country")) {
                        setCountryId(x?.long_name);
                        isDisable.push("country");
                      }
                      if (x?.types?.includes("postal_code")) {
                        sepObjectProp({
                          propName: "zipCode",
                          value: x?.long_name,
                        });
                        isDisable.push("postalCode");
                      }
                    });
                    setIsEditAble(isDisable);
                  }}
                />
              </Grid>
              <Grid item xs={4} className={classes.gridWrapper}>
                <InputField
                  placeholder='Enter country or region'
                  inputlabel='Country or region'
                  validators={["required"]}
                  errorMessages={["Please enter country or region"]}
                  value={countryId}
                  onChange={v => setCountryId(v.target.value)}
                  // disabled={isEditAble.includes("country") ? true : false}
                />
              </Grid>
            </Grid>
            <div className='gap' />

            <Grid container spacing={3}>
              <Grid item xs={4} className={classes.gridWrapper}>
                <InputField
                  placeholder='Enter City'
                  inputlabel='City'
                  validators={["required"]}
                  errorMessages={["Please enter city"]}
                  value={cityId}
                  onChange={v => setSelectCityId(v.target.value)}
                  // disabled={isEditAble.includes("city") ? true : false}
                />
              </Grid>

              <Grid item xs={2} className={classes.gridWrapper}>
                <InputField
                  placeholder='Enter State'
                  inputlabel='State'
                  validators={["required"]}
                  errorMessages={["Please enter state"]}
                  value={stateId}
                  onChange={v => setSelectedStatesId(v.target.value)}
                  // disabled={isEditAble.includes("state") ? true : false}
                />
              </Grid>

              <Grid item xs={4} className={classes.gridWrapper}>
                <InputField
                  placeholder='Zip code or postal code'
                  inputlabel='Zip code or postal code'
                  className={[classes.gridWrapper.phonenumber]}
                  validators={[
                    "matchRegexp:^(?:[A-Z0-9a-z ]+([-_ ]?[A-Z0-9a-z]+)*)?$",
                    "matchRegexp:^.{0,15}$",
                  ]}
                  errorMessages={[
                    "Special characters and multiple spaces are not allowed",
                    "Maximum length is 15 characters",
                  ]}
                  id='zipcode'
                  name='zipcode'
                  value={adminDetail?.zipCode}
                  onChange={({ target }) =>
                    sepObjectProp({ propName: "zipCode", value: target.value })
                  }
                  // disabled={isEditAble.includes("postalCode") ? true : false}
                />
              </Grid>
            </Grid>
          </>
        )}
        <div className='gap' />

        {customFields[4] && (
          <Grid container spacing={3}>
            <Grid item xs={5} className={classes.gridWrapper}>
              <InputField
                placeholder={customFields[4]?.fieldLabel || "Password"}
                inputlabel={customFields[4]?.fieldLabel || "Password"}
                className={classes.gridWrapper}
                required={adminDetail?.id ? false : true}
                showRequired={adminDetail?.id ? false : true}
                type='password'
                validators={[
                  "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                ]}
                errorMessages={[
                  "Password must contain at least 1 uppercase, lowercase, number and special character",
                ]}
                disabled={adminDetail?.id ? true : false}
                onBlur={() => setErrorMessages({})}
                value={adminDetail?.password}
                onChange={({ target }) =>
                  sepObjectProp({ propName: "password", value: target.value })
                }
              />
              {errorMessages["password"] && (
                <span className={classes.errorMessage}>
                  {errorMessages["password"]}
                </span>
              )}
              <span className='resetBtn'>
                <button disabled onClick={() => alert("reset")}>
                  Reset
                </button>
              </span>
            </Grid>
          </Grid>
        )}

        {customFields[21] && (
          <div>
            <h3>{customFields[21]?.filedName || "OFish Access"}</h3>
            <Grid item xs={4} className={classes.accessCheckBoxs}>
              {accessPolicies.map(ap => {
                return (
                  <span className='checkboxLabel'>
                    <Checkbox
                      label={ap.value}
                      value={ap.id}
                      checked={
                        adminDetail?.policies?.includes(ap.id) ? true : false
                      }
                      onChange={({ target }) => {
                        if (adminDetail?.policies?.length > 0) {
                          if (target.checked) {
                            adminDetail?.policies.push(ap.id);
                            sepObjectProp({
                              propName: "policies",
                              value: uniq(adminDetail?.policies),
                            });
                          } else {
                            sepObjectProp({
                              propName: "policies",
                              value: adminDetail?.policies.filter(
                                p => p !== ap.id
                              ),
                            });
                          }
                        } else {
                          sepObjectProp({
                            propName: "policies",
                            value: [ap.id],
                          });
                        }
                      }}
                    />
                    {ap.value}
                  </span>
                );
              })}
            </Grid>
          </div>
        )}
      </div>
    </AccordionContainer>
  );
};

export { NewCompanyContacts };
