import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import EndUserActivityGrid from "./EndUserActivityGrid";
import { Chevron, Contacts, ProductInventory } from "../../LeftNav/SvgIcons";
import { Envelope } from "../SpaProduct/SvgIcons";

const useStyles = makeStyles(theme => ({
  activityButton: {
    backgroundColor: "#E5F2FF",
    padding: "8px 35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#E5F2FF",
    },
  },
}));

function EndUserActivityTab({ isActive, isEndUser, isBusUser }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [userId, setUserId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [allActivities, setAllActivities] = useState([]);
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (isActive) {
      if (selectedUser?.id) {
        setUserId(selectedUser.id);
        if (isEndUser) {
          loadActivity(selectedUser.id);
        }
        if (isBusUser) {
          loadBUActivity(selectedUser.id);
        }
      }
    }
  }, [location, isActive, isEndUser]);

  const loadActivity = userId => {
    setLoading(true);
    dispatch(endUserActions.onGetEUActivity(userId)).then(data => {
      if (data && data.success) {
        setAllActivities(data.data);
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
      setLoading(false);
    });
  };
  const loadBUActivity = compId => {
    setLoading(true);
    dispatch(endUserActions.onGetBUActivity(compId)).then(data => {
      if (data && data.success) {
        setAllActivities(data.data);
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
      setLoading(false);
    });
  };

  const classes = useStyles();
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <div className='customerContactInner '>
        <div className='customerContactHeader'>
          John Doe’s Activity Log
          <span className='transparentBtn'>
            <Button color='primary' variant='contained'>
              Clear
            </Button>
          </span>
        </div>
        {/* {allActivities &&
          allActivities.length > 0 &&
          allActivities.map((item, index) => {
            return (
              <div key={index} className='endUserActivityBody'>
                <span className='activityDate'>
                  <span
                    className='circle'
                    style={{
                      border: `4px solid ${
                        !item.action
                          ? "#F64E60"
                          : stringToColor(
                              `${index}${moment(item.actionDate)
                                .format("HH:mm A")
                                .toString()}`
                            )
                      }`,
                    }}
                  />
                  {isTodayDate(item.actionDate)
                    ? moment(item.actionDate).format("hh:mm A")
                    : moment(item.actionDate).format("MMMM DD, YYYY")}
                </span>
                <span className='activityDesc'>{item.activity}</span>
              </div>
            );
          })} */}
        <div
          className={`${!isShowMore ? "showLessContent" : "showFullContent"} `}>
          <div className='endUserActivityBody'>
            <span className='activityDate'>
              <span className='circle'>
                <Contacts />
              </span>
            </span>
            <span className='activityDesc'>
              <span>
                {" "}
                John Smith submitted product registration for{" "}
                <i>Spa Controller</i> via the AAV App
              </span>
              <span className='activityTime'>
                12:38 PM Today by <i>John Smith</i>
              </span>
            </span>
          </div>
          <div className='endUserActivityBody'>
            <span className='activityDate'>
              <span className='circle'>
                <Envelope />
              </span>
            </span>
            <span className='activityDesc'>
              <span>
                {" "}
                John Smith submitted product registration for{" "}
                <i>Spa Controller</i> via the AAV App
              </span>
              <span className='activityTime'>
                12:38 PM Today by <i>John Smith</i>
              </span>
            </span>
          </div>
          <div className='endUserActivityBody'>
            <span className='activityDate'>
              <span className='circle'>
                <ProductInventory />
              </span>
            </span>
            <span className='activityDesc'>
              <span>
                {" "}
                John Smith submitted product registration for{" "}
                <i>Spa Controller</i> via the AAV App
              </span>
              <span className='activityTime'>
                12:38 PM Today by <i>John Smith</i>
              </span>
            </span>
          </div>

          <div className='endUserActivityBody'>
            <span className='activityDate'>
              <span className='circle'>
                <ProductInventory />
              </span>
            </span>
            <span className='activityDesc'>
              <span>
                {" "}
                John Smith submitted product registration for{" "}
                <i>Spa Controller</i> via the AAV App
              </span>
              <span className='activityTime'>
                12:38 PM Today by <i>John Smith</i>
              </span>
            </span>
          </div>

          <div className='endUserActivityBody'>
            <span className='activityDate'>
              <span className='circle'>
                <ProductInventory />
              </span>
            </span>
            <span className='activityDesc'>
              <span>
                {" "}
                John Smith submitted product registration for{" "}
                <i>Spa Controller</i> via the AAV App
              </span>
              <span className='activityTime'>
                12:38 PM Today by <i>John Smith</i>
              </span>
            </span>
          </div>

          <div className='endUserActivityBody'>
            <span className='activityDate'>
              <span className='circle'>
                <ProductInventory />
              </span>
            </span>
            <span className='activityDesc'>
              <span>
                {" "}
                John Smith submitted product registration for{" "}
                <i>Spa Controller</i> via the AAV App
              </span>
              <span className='activityTime'>
                12:38 PM Today by <i>John Smith</i>
              </span>
            </span>
          </div>
        </div>

        <span
          className={`${
            isShowMore ? "rotateIcon" : "rotateUnset"
          } activityShowMore`}>
          <i onClick={() => setIsShowMore(!isShowMore)}>
            <Chevron />
          </i>
        </span>
      </div>

      {/* <EndUserActivityGrid /> */}
    </div>
  );
}

export default EndUserActivityTab;
