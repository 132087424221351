import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../../../components/common/Loader";
import AddAssetSelectImg from "./AddAssetSelectImg";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import { Dialog, DialogContent } from "@material-ui/core";
import { first } from "lodash";

const useStyles = makeStyles(theme => ({
  featuredImageTabs: {
    backgroundColor: "#ffffff",
    zIndex: "5",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "hidden",
    padding: 0,
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const AddAssetPop = ({
  title,
  showFeatureImage,
  setShowFeatureImage,
  onImageSelect,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [featureFiles, setFeatureFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [previousFolder, setPreviousFolder] = useState(null);

  const uploadFeatureImg = value => {
    setLoading(true);
    dispatch(launchesActions.uploadImage(value)).then(data => {
      if (data?.success && data?.data) {
        setShowFeatureImage(false);
      }
      onImageSelect(data?.data);
      setLoading(false);
    });
  };

  const getFolderData = useCallback(
    ({ folderId, isBack }) => {
      setLoading(true);
      dispatch(assetsActions.onGetMediaAllAssetById(folderId)).then(data => {
        if (data && data.success && data.data) {
          if (isBack)
            setPreviousFolder(first(data.data?.data)?.superParentId || 0);
          setFeatureFiles(data.data?.data);
        }
        setLoading(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!showFeatureImage) return;
    getFolderData({ folderId: selectedFolder?.id || 0, isBack: false });
  }, [dispatch, showFeatureImage, getFolderData, selectedFolder]);

  return (
    <>
      {isLoading ? <Loader /> : null}
      {showFeatureImage && (
        <Dialog open={showFeatureImage} onClose={setShowFeatureImage}>
          <DialogContent className={classes.featuredImageTabs}>
            <h2 className={classes.featuredImageTabsHeading}>
              {title || `Select Featured Image`}
              <Close
                style={{
                  cursor: "pointer",
                }}
                className={classes.closeTabs}
                onClick={() => setShowFeatureImage(false)}
              />
            </h2>
            <AddAssetSelectImg
              addFeatureImg={value => uploadFeatureImg(value)}
              featureData={featureFiles}
              selectedFeatureImg={value => {
                onImageSelect(value);
                setShowFeatureImage(false);
              }}
              selectedFolder={selectedFolder}
              setSelectedFolder={f => {
                setPreviousFolder(selectedFolder?.id);
                setSelectedFolder(f);
              }}
              onBackButtonPress={folderInfo => {
                if (previousFolder)
                  getFolderData({
                    folderId: previousFolder,
                    isBack: true,
                  });
                else {
                  setSelectedFolder(null);
                }
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export { AddAssetPop };
