import React, { useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  logoSign,
  loginLogo,
  fotgotPassword,
} from "../../../resources/images/index";
import { accountActions } from "../../../store/accounts/accountActions";
import curve from "../../../resources/images/curve.png";
import Loader from "../../../components/common/Loader";
import curveOverlay from "../../../resources/images/curveOverlay.png";

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = e => {
    setLoading(true);
    dispatch(accountActions.onForgotPassword(email)).then(data => {
      if (data && data.data && data.data.success) {
        toast.success(data.data.data.message);
        history.push({
          pathname: "/verification-code",
          state: { verificationCode: data.data.data.code },
        });
        // data.data.data.message
      }
      setLoading(false);
    });
  };

  const loginForm = useRef("form");

  return (
    <div className='loggedinWrapper'>
      {isLoading ? <Loader /> : null}
      <div className='leftCol'>
        <span className='loginLeftshade' />
        <span className='loginRightShade'>
          <img src={curve} alt='' className='curve' />
          <img src={curveOverlay} alt='' className='curveOverlay' />
        </span>
      </div>
      <div className='rightCol'>
        <img src={loginLogo} alt='' />
        <img src={logoSign} alt='' style={{ marginLeft: "195px" }} />
        <span className='createAccount centerHeading'>
          <img src={fotgotPassword} alt='' />
          Forgot Password?
        </span>
        {/* <form onSubmit={loginForm.handleSubmit}> */}
        <ValidatorForm ref={loginForm} onSubmit={handleSubmit}>
          <TextValidator
            id='email'
            label='Email or username'
            variant='outlined'
            name='email'
            fullWidth
            validators={["required", "isEmail"]}
            errorMessages={[
              "Please enter your email address",
              "Please enter a valid email address",
            ]}
            value={email}
            onChange={v => setEmail(v.target.value)}
          />

          <Button type='submit' color='primary' variant='contained'>
            Continue
          </Button>
        </ValidatorForm>
        {/* </form> */}
      </div>
    </div>
  );
}

export default withRouter(ForgotPassword);
