import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { EditIcon, BackupLabel, SortIcon } from "../../../LeftNav/SvgIcons";
import { useHistory } from "react-router";
import { RoutesObj } from "../../../../../routes";
import { last } from "lodash";
import {
  ViewAgenda, AccessAlarm,
  Cancel,
  CheckCircle,
  PortableWifiOff,
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "8px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
      boxShadow: "none",
    },
  },
  chipWrapper: {
    backgroundColor: "#F3F6F9",
    borderRadius: "36px",
    color: "#70808F",
    fontSize: "16px",
    fontWeight: "400",
    padding: "7px 14px",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 7px 10px 0px",
    display: "inline-flex",
  },
  sortIcon: {
    paddingLeft: "3px"
  }
}));

const AvailabilityStatus = ({ item }) => {
  return (
    item?.availabilityPeriodStatus === "Upcoming" ? (
      <AccessAlarm fontSize='small' style={{ color: "#9B51E0" }} />
    ) : (
      <>
        {item?.availabilityPeriodStatus === "Expired" ? (
          <Cancel fontSize='small' style={{ color: "#FF3535" }} />
        ) : item?.availabilityPeriodStatus === "" ? (
          <PortableWifiOff fontSize='small' style={{ color: "#FFAD4E" }} />
        ) : (
          <CheckCircle fontSize='small' style={{ color: "#18BC6D" }} />
        )}
      </>
    )
  );
};

const PricingListGrid = ({ listData, selectedItems, setSelectedItems ,   order,
  onOrderBy }) => {
  const classes = useStyles();
  const history = useHistory();


  const redirectUrl = item => {
    history.push({
      pathname: last(RoutesObj.CreatePriceList.path), // pathname: last(RoutesObj.NewPriceList.path),
      state: { pricingList: item },
      search: `id=${item.priceListId}`,
    });
  };

  const onItemSelect = itemId => {
    const itemExists =
      selectedItems.length > 0 && selectedItems.find(c => c === itemId);
    if (itemExists) {
      setSelectedItems(selectedItems.filter(c => c !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  return (
    <Table>
      <TableHead className={classes.gridHeader}>
        <TableRow className={classes.gridHeaderRow}>
          {/* <TableCell className={classes.gridHeaderInner}>
            <Checkbox className={classes.checkBoxFont} disabled={true} />
          </TableCell> */}
          <TableCell className={classes.gridHeaderInner}>
            Price List Name (Internal)
            <TableSortLabel
              active={order.orderByKey === "plInternal"}
              direction={order.orderVal === 1 ? "asc" : "desc"}
              onClick={() =>
                onOrderBy({
                  orderByKey: "plInternal",
                  orderVal: order.orderVal === 0 ? 1 : 0,
                })
              }
              IconComponent={SortIcon}
              className={classes.sortIcon}
            />
          </TableCell>
          <TableCell className={classes.gridHeaderInner}>
            Price List Name (Public)
            <TableSortLabel
              active={order.orderByKey === "plPublic"}
              direction={order.orderVal === 1 ? "asc" : "desc"}
              onClick={() =>
                onOrderBy({
                  orderByKey: "plPublic",
                  orderVal: order.orderVal === 0 ? 1 : 0,
                })
              }
              IconComponent={SortIcon}
              className={classes.sortIcon}
            />
          </TableCell>
          <TableCell className={classes.gridHeaderInner}>
            Customer Groups
            <TableSortLabel
              active={order.orderByKey === "customerGroups"}
              direction={order.orderVal === 1 ? "asc" : "desc"}
              onClick={() =>
                onOrderBy({
                  orderByKey: "customerGroups",
                  orderVal: order.orderVal === 0 ? 1 : 0,
                })
              }
              IconComponent={SortIcon}
              className={classes.sortIcon}
            />
          </TableCell>
          <TableCell className={classes.gridHeaderInner}>
            # of Products
            <TableSortLabel
               active={order.orderByKey === "numOfProducts"}
               direction={order.orderVal === 1 ? "asc" : "desc"}
               onClick={() =>
                 onOrderBy({
                   orderByKey: "numOfProducts",
                   orderVal: order.orderVal === 0 ? 1 : 0,
                 })
               }
              IconComponent={SortIcon}
              className={classes.sortIcon}
            />
          </TableCell>
          <TableCell
          className={classes.gridHeaderInner}
          style={{
            textAlign: 'center',
          }}
            // className={
            //   (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
            // }
            >
            Quick Actions
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {listData?.map((item, index) => {
          return (
            <TableRow key={index} className="gridBodyRow">
              {/* <TableCell className={classes.gridHeaderInner}>
                <Checkbox
                  className={classes.checkBoxFont}
                  onClick={() => onItemSelect(item.priceListId)}
                  checked={
                    selectedItems.length > 0 &&
                    selectedItems?.includes(item.priceListId)
                  }
                />
              </TableCell> */}
              <TableCell className={classes.gridBodyInner}>
                <span style={{ display: "flex", alignItems: "center" }}>
                  {item?.priceListNameInternal || "-"}
                  &nbsp;
                  <AvailabilityStatus item={item} />
                </span>
              </TableCell>
              <TableCell className={classes.gridBodyInner}>
                {item?.priceListNameVisible || "-"}
              </TableCell>
              <TableCell className={classes.gridBodyInner}>
                {`${item?.customerGroups} Groups`}
              </TableCell>
              <TableCell
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}
              >
                {item?.noOfProducts}
              </TableCell>
              <TableCell
                className={(classes.gridBodyInner, "quickLinkWrapper")}
              >
                <div
                  className="gridActions productsAction"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <span
                    style={{
                      cursor: "pointer",
                      paddingRight: "25px"
                    }}>
                    <BackupLabel />
                  </span> */}
                  <span
                    onClick={() => redirectUrl(item)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon />
                  </span>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
        {listData.length <= 0 && (
          <TableRow key='0' className='gridBodyRow'>
            <TableCell
              colSpan='7'
              className={classes.gridBodyInner}
              style={{ textAlign: "center" }}>
              <span className='emptyGridText'>
                Uh Oh! You haven’t create any product.
              </span>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export { PricingListGrid };
