import React from "react";
import Button from "@material-ui/core/Button";

function RelatedAssets({ picture, heading, subheading, onPressAsset }) {
  return (
    <div className="RelatedAssetsWrapper">
      <ul>
        <li>
          {" "}
          <span className="pressPicture">
            <img src={picture} />
          </span>{" "}
        </li>
        <li>
          {" "}
          <h2>{heading}</h2>
          <span className="subheading">{subheading}</span>
        </li>
      </ul>

      <Button
        onClick={() => onPressAsset()}
        variant="contained"
        color="primary">
        View Assets
      </Button>
    </div>
  );
}

export default RelatedAssets;
