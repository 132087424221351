import React, { useEffect, useRef, useState } from "react";
import { FormControl, TextField } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDebounce } from "../../../../utils/use-debounce";
import { Autocomplete } from "@material-ui/lab";
import { searchIcon } from "../../../../resources/images";

const handleSubmit = e => {
  e.preventDefault();
};

function Address({
  CountryId = [],
  setCountryId,
  StateId = [],
  setStateId,
  CityId = [],
  setCityId,
  StreetAddress = [],
  setStreetAddress,
  countryList = [],
  onCountryChange,
  stateList = [],
  onStateChange,
  cityList = [],
  onCityChange,
  streetList = [],
  onStreetChange,
  onChageZipCode,
  zipCodeList = [],
  zipCodeData = [],
  setZipCodeData,
}) {
  // const { countries, provinces, cities } = useCountryStateCities();

  const [countryTxt, setCountrySearch] = useState("");
  const { debouncedValue: countryDebaounceVal } = useDebounce(countryTxt, 500);

  const [stateTxt, setStateSearch] = useState("");
  const { debouncedValue: stateDebaounceVal } = useDebounce(stateTxt, 500);

  const [cityTxt, setCitySearch] = useState("");
  const { debouncedValue: cityDebaounceVal } = useDebounce(cityTxt, 500);

  const [zipCodeTxt, setZipCodeSearch] = useState("");
  const { debouncedValue: zipCodeDebaounceVal } = useDebounce(zipCodeTxt, 500);

  const [streetTxt, setStreetSearch] = useState("");
  const { debouncedValue: streetDebaounceVal } = useDebounce(streetTxt, 500);

  useEffect(() => {
    if (countryDebaounceVal) {
      onCountryChange(countryDebaounceVal);
    }
  }, [countryDebaounceVal]);

  useEffect(() => {
    if (stateDebaounceVal) {
      onStateChange(stateDebaounceVal);
    }
  }, [stateDebaounceVal]);

  useEffect(() => {
    if (cityDebaounceVal) {
      onCityChange(cityDebaounceVal);
    }
  }, [cityDebaounceVal]);

  useEffect(() => {
    if (streetDebaounceVal) {
      onStreetChange(streetDebaounceVal);
    }
  }, [streetDebaounceVal]);

  useEffect(() => {
    if (zipCodeDebaounceVal) {
      onChageZipCode(zipCodeDebaounceVal);
    }
  }, [zipCodeDebaounceVal]);

  return (
    <ValidatorForm
      ref={useRef("form")}
      onSubmit={handleSubmit}
      autoComplete='off'>
      <div className='userDetailsSearchField inputWrapper filterSelect endUserSearchfilter'>
        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={CountryId}
            onChange={(event, value) => {
              if (value.length > 0) setCountryId(value);
              else if (!value) {
                setCountryId([]);
                setCountrySearch("");
              }
            }}
            multiple
            filterSelectedOptions
            id='country-name-ac'
            options={countryList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder='Country'
                onChange={({ target }) => setCountrySearch(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {CountryId?.length > 0 && (
            <span className='countLabel'>{CountryId?.length}</span>
          )}
        </FormControl>

        {/* Powered by Google */}
        {/* <FormControl className='ac-dd'>
          <AddressAutoComplete
            placeholder='Country'
            labelRequired={false}
            types={["country"]}
            autoComId={"locCountryId"}
            setPlace={queryData => {
              if (queryData.formatted_address) {
                countryId.push({
                  id: queryData.place_id,
                  value: queryData.formatted_address,
                });
                let newArray = Array.from(
                  JSON.parse(JSON.stringify(countryId))
                );
                setCountryId([...newArray]);
              }
              const acCountry = document.getElementById("locCountryId");
              if (acCountry) acCountry.value = "";
            }}
          />
          {countryId?.length > 0 && (
            <span className='countLabel'>{countryId?.length}</span>
          )}
        </FormControl> */}
        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={StateId}
            onChange={(event, value) => {
              if (value.length > 0) setStateId(value);
              else if (!value) {
                setStateId([]);
                setStateSearch("");
              }
            }}
            multiple
            filterSelectedOptions
            id='state-name-ac'
            options={stateList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder='State'
                onChange={({ target }) => setStateSearch(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {StateId?.length > 0 && (
            <span className='countLabel'>{StateId?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={CityId}
            onChange={(event, value) => {
              if (value.length > 0) setCityId(value);
              else if (!value) {
                setCityId([]);
                setCitySearch("");
              }
            }}
            multiple
            filterSelectedOptions
            id='city-name-ac'
            options={cityList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
              inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                {...params}
                placeholder='City'
                onChange={({ target }) => setCitySearch(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {CityId?.length > 0 && (
            <span className='countLabel'>{CityId?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={zipCodeData}
            onChange={(event, value) => {
              if (value.length > 0) setZipCodeData(value);
              else if (!value) {
                setZipCodeData([]);
                setZipCodeSearch("");
              }
            }}
            multiple
            filterSelectedOptions
            id='zip-name-ac'
            options={zipCodeList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder='Zip Code'
                onChange={({ target }) => setZipCodeSearch(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {zipCodeData?.length > 0 && (
            <span className='countLabel'>{zipCodeData?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={StreetAddress}
            onChange={(event, value) => {
              if (value.length > 0) setStreetAddress(value);
              else if (!value) {
                setStreetAddress([]);
                setStreetSearch("");
              }
            }}
            multiple
            filterSelectedOptions
            id='street-name-ac'
            options={streetList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder='Street Address'
                onChange={({ target }) => setStreetSearch(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {StreetAddress?.length > 0 && (
            <span className='countLabel'>{StreetAddress?.length}</span>
          )}
        </FormControl>
      </div>
    </ValidatorForm>
  );
}

export { Address };
