import React, { useRef } from "react";
import { Clip } from "../../../ofishadmin/views/LeftNav/SvgIcons";
import moment from "moment";

function MessageSubject({
  pic,
  name,
  subheading,
  date,
  hubUserId,
  CurrentHubUserId,
  time,
  doc,
  message,
  key,
  onSendBtnClick,
  sendMsg,
  attachments,
  onAttachmentClick,
}) {
  const localGMT = new Date(date);
  return (
    <>
      {CurrentHubUserId === hubUserId ? (
        <div className="messageSubjectWrapper" key={key}>
          <div className="userDetail">
            <div className="userDetailInner">
              {/* <span className="userPic">{pic}</span> */}
              <span>
                {name}
                {/* <i> {subheading}</i> */}
                {attachments?.name ? (
                  <i
                    style={{ cursor: "pointer" }}
                    onClick={() => onAttachmentClick()}>
                    <Clip /> {attachments.name}{" "}
                  </i>
                ) : (
                  <></>
                )}
              </span>
            </div>
            {/* <div className="messageDatenTime">
             
              {new Date(
                localGMT.setHours(localGMT.getHours() + 5)
              ).toLocaleString()}
            </div> */}
          </div>
          <div className="msgReceiverWrapper">
            <div className="userDetail">
              <div className="userDetailInner">
                <span className="userPic">{pic}</span>
              </div>
            </div>
            <div className="messageBody">
              {/* Hi, {name}, */}

              <span>{message}</span>
            </div>
          </div>

          <div
            className="messageDatenTime"
            style={{ float: "left", marginTop: "5px", marginLeft: "43px" }}>
            {/* {moment(date).format("MMM DD, h:mm a")} */}
            {new Date(
              localGMT.setHours(localGMT.getHours() + 5)
            ).toLocaleString()}
          </div>
        </div>
      ) : (
        <div className=" senderWrapper" key={key}>
          <div className="userDetail">
            {/* <div className="messageDatenTime">
             
              {new Date(
                localGMT.setHours(localGMT.getHours() + 5)
              ).toLocaleString()}
            </div> */}

            <div className="userDetailInner" style={{ marginRight: "62px" }}>
              <span>
                {name}
                {/* <i> {subheading}</i> */}
                {attachments?.name ? (
                  <i
                    style={{ cursor: "pointer" }}
                    onClick={() => onAttachmentClick()}>
                    <Clip /> {attachments.name}{" "}
                  </i>
                ) : (
                  <></>
                )}
              </span>
              {/* <span
                className="userPic ererwerwe"
                style={{ marginLeft: "10px" }}>
                {pic}
              </span> */}
            </div>
          </div>

          <div
            className="msgSenderWrapper"
            style={{ background: "transparent" }}>
            <div className="messageBody" style={{ textAlign: "right" }}>
              {/* Hi, {name}, */}

              <span>{message}</span>
            </div>
            <div className="userDetail">
              <div className="userDetailInner">
                <span
                  className="userPic ererwerwe"
                  style={{ marginLeft: "10px" }}>
                  {pic}
                </span>
              </div>
            </div>
          </div>
          <div
            className="messageDatenTime"
            style={{ float: "right", margin: "4px 40px 0 0" }}>
            {/* {moment(date).format("MMM DD, h:mm a")} */}
            {new Date(
              localGMT.setHours(localGMT.getHours() + 5)
            ).toLocaleString()}
          </div>
        </div>
      )}
    </>
  );
}

export default MessageSubject;
