import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Divider, Grid, IconButton, List, ListItem, TextField, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SoftwareGridFilter from '../../SoftwareUpdates/SoftwareGridFilter'
import { Add, Tune } from '@material-ui/icons';
import { CustomConversationPopup } from './CustomConversationPopup';
import CustomCheckbox from '../../../../../components/common/checkbox/CustomCheckbox';
import { ChipCross, CloseRedIcon, ExpandDownIcon, SaveGreenIcon } from '../../../LeftNav/SvgIcons';
import { ConversationChip } from './ConversationChip';
import { ConversationPermissionPopupGrid } from './ConversationPermissionPopupGrid';
import { useDispatch, useSelector } from 'react-redux';
import { conversationAction } from '../../../../../store/conversations/conversationAction';
import { ConversationAccordionChips } from './ConversationAccordionChips';
import Loader from '../../../../../components/common/Loader';
import { useDebounce } from '../../../../../utils/use-debounce';

const useStyles = makeStyles((theme) => ({
  popupCheckBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
  },
  popupCheckBoxText: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#2D3339',
  },
  recommendedText: {
    color: '#18BC6D',
    fontSize: '14px',
    fontWeight: '600',
  },
  chipContiner: {
    marginTop: "16px",
    height: "70px",
    overflowY: "auto",
    "& .MuiChip-deleteIconSmall": {
      margin: 0,
    },
  },
  selectedChips: {
    marginRight: "16px",
    marginTop: "16px",
  },
  chipsBox: {
    marginBottom: "16px",
  },
  chipBox: {
    "& .MuiChip-sizeSmall": {
      height: "36px",
      padding: "8px 16px",
      backgroundColor: "#F3F6F9",
    },
    "& .MuiChip-label": {
      fontSize: "14px",
      fontWeight: "700",
      color: "#70808F"
    },
  },
  expandChipButton: {
    color: "#3699FF",
    fontSize: "14px",
    fontWeight: "700",
    cursor: "pointer",
  },
  noChipText: {
    color: "#BDBDBD",
    fontSize: "12px",
    fontWeight: "500",
    fontStyle: "italic",
    textAlign: "center",
  },
}))

export const ConversationPermissionSetting = ({ 
  paramId, 
  setIsAccordion2Done, 
  setIsAccordion2Open, 
  isAccordion2Edit, 
  setIsAccordion2Edit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    selectedConversationPermission,
    selectedReciveNewConversationPermission,
    selectedStartConversationPermission,
    conversationPermissionData,
    conversationPermissionGridData,
    basicConversationData,
    selectedConversationChannel,
    conversationCompaginId,
  } = useSelector(
    state => state.conversationReducer
);
  const [isNewPermission, setIsNewPermission] = useState(false);
  const [openPermissionAccordion , setIsOpenPermissionAccordion] = useState(null);
  const[search , setSearch]=useState("")
  const[searchPermission , setSearchPermission] = useState("")
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const { debouncedValue } = useDebounce(searchPermission , 500)

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [activeTabId, setActiveTabId] = useState("1");
  const [openPopupId , setOpenPopId] = useState("1");
  const [ openAccordionId , setOpenAccordionId] = useState(null);
  const [checkboxSelectedPermission, setCheckboxSelectedPermission] = useState([]);
  const [tempselectedItem , setTempSelectItem] = useState([]);

  const [checkboxSelectedPermission2, setCheckboxSelectedPermission2] = useState([]);
  const [tempselectedItem2 , setTempSelectItem2] = useState([]);

  const [checkboxSelectedPermission3, setCheckboxSelectedPermission3] = useState([]);
  const [tempselectedItem3, setTempSelectItem3] = useState([]);
  const [isLoading , setIsLoading] = useState(false);

  const [addUpdatePermissionData, setAddUpdatePermissionData] = useState({
    id: 0,
    name: "",
    value: "",
    userRequiredProductAccess: true,
    startConversationRequiredProductAccess: true,
    receiveConversationRequiredProductAccess: true,
    userEmailToProceed: true,
    startUserEmailToProceed: true,
    receiveUserEmailToProceed: true,
  });
  const handleCheckboxChange = (propertyName) => {
    setAddUpdatePermissionData(prevState => ({
      ...prevState,
      [propertyName]: !prevState[propertyName]
    }));
  };

  const allTabs = [
    {
      id: "1",
      title: "User Roles",
      component: "",
    },
    {
      id: "2",
      title: "All Users",
      component: "",
    },
    {
      id: "3",
      title: "All Groups",
      component: "",
    },
    {
      id: "4",
      title: "Business Groups",
      component: "",
    },
    {
      id: "5",
      title: "End User Groups",
      component: "",
    },
  ];
  const allTabsTilte = [
    {
      id: "1",
      title: "UserRoles",
    },
    {
      id: "2",
      title: "All",
    },
    {
      id: "3",
      title: "Groups",
    },
    {
      id: "4",
      title: "Business Customer",
    },
    {
      id: "5",
      title: "End Customer",
    },
  ];

  const handleNextButton = () => {
    setIsAccordion2Done(true);
    setIsAccordion2Open(false);
    setIsAccordion2Edit(false)
  }
  const handleSkipButton = () => {
    setIsAccordion2Done(true);
    setIsAccordion2Open(false);
    setIsAccordion2Edit(false);
  }
  const handleAddAndUpdateAccordion = ()=>{
    setOpenAccordionId(null) 
    setIsNewPermission(false);
  }

  const onCheckboxAddPermission = (item) => {

    if(openPopupId === "1"){
      const isPermissionSelected = checkboxSelectedPermission.some(permission => permission.id === item.id);
      if (isPermissionSelected) {
          const updatedPermissions = checkboxSelectedPermission.filter(permission => permission.id !== item.id);
          setCheckboxSelectedPermission(updatedPermissions);
      } else {
          setCheckboxSelectedPermission(prevPermissions => [...prevPermissions, item]);
      }   
    }else if(openPopupId === "2"){
      const isPermissionSelected = checkboxSelectedPermission2.some(permission => permission.id === item.id);
      if (isPermissionSelected) {
          const updatedPermissions = checkboxSelectedPermission2.filter(permission => permission.id !== item.id);
          setCheckboxSelectedPermission2(updatedPermissions);
      } else {
          setCheckboxSelectedPermission2(prevPermissions => [...prevPermissions, item]);
      }   
    }else if(openPopupId === "3"){
      const isPermissionSelected = checkboxSelectedPermission3.some(permission => permission.id === item.id);
      if (isPermissionSelected) {
          const updatedPermissions = checkboxSelectedPermission3.filter(permission => permission.id !== item.id);
          setCheckboxSelectedPermission3(updatedPermissions);
      } else {
          setCheckboxSelectedPermission3(prevPermissions => [...prevPermissions, item]);
      }   
    }
  };

const addConversation = (item) => {
  if(openPopupId === "1"){
    if (
      !tempselectedItem.some(
        (tempselectedItem) => tempselectedItem == item
      )
    ) {
      setTempSelectItem([...tempselectedItem , item])
    }
  }else if(openPopupId === "2"){
    if (
      !tempselectedItem2.some(
        (tempselectedItem2) => tempselectedItem2 == item
      )
    ) {
      setTempSelectItem2([...tempselectedItem2 , item])
    }
  }else if( openPopupId === "3"){
    if (
      !tempselectedItem3.some(
        (tempselectedItem3) => tempselectedItem3 == item
      )
    ) {
      setTempSelectItem3([...tempselectedItem3 , item])
    }
  }
  };
const addMultiple = () => {
  if(openPopupId === "1"){ 
    const uniqueCheckboxDevices = checkboxSelectedPermission.filter(
      (permission) =>
        !tempselectedItem.some(
          (tempItem) => tempItem.id === permission.id
        )
    );

    if (uniqueCheckboxDevices.length > 0) {
      setTempSelectItem([...tempselectedItem, ...uniqueCheckboxDevices])
    }
    setCheckboxSelectedPermission([]);

  }else if(openPopupId === "2"){
        const uniqueCheckboxDevices = checkboxSelectedPermission2.filter(
          (permission) =>
            !tempselectedItem2.some(
              (tempselectedItem2) => tempselectedItem2.id === permission.id
            )
        );
    
        if (uniqueCheckboxDevices.length > 0) {
          setTempSelectItem2([...tempselectedItem2, ...uniqueCheckboxDevices])
        }
        setCheckboxSelectedPermission2([]);



  }else if( openPopupId === "3"){

        const uniqueCheckboxDevices = checkboxSelectedPermission3.filter(
          (permission) =>
            !tempselectedItem3.some(
              (tempselectedItem3) => tempselectedItem3.id === permission.id
            )
        );
    
        if (uniqueCheckboxDevices.length > 0) {
          setTempSelectItem3([...tempselectedItem3 , ...uniqueCheckboxDevices])
        }
        setCheckboxSelectedPermission3([]);
  }

  };
  const onRemoveChips = (itemId , option)=>{
    if(option === "1"){
      const updatedPermissions = selectedConversationPermission.filter(x => x.id !== itemId && x.role !== itemId && x.userId !== itemId && x.groupId !== itemId);
      setTempSelectItem(updatedPermissions);
      dispatch(
        conversationAction.onChange(
          "selectedConversationPermission",
          updatedPermissions
        )
      );
    }else if(option === "2"){
      const updatedPermissions = tempselectedItem2.filter(x => x.id !== itemId && x.role !== itemId && x.userId !== itemId && x.groupId !== itemId);
      setTempSelectItem2(updatedPermissions);
      dispatch(
        conversationAction.onChange(
          "selectedStartConversationPermission",
          updatedPermissions
        )
      );
    }else if( option === "3"){
      const updatedPermissions = tempselectedItem3.filter(x => x.id !== itemId && x.role !== itemId && x.userId !== itemId && x.groupId !== itemId);
      setTempSelectItem3(updatedPermissions);
      dispatch(
        conversationAction.onChange(
          "selectedReciveNewConversationPermission",
          updatedPermissions
        )
      );
    }
  };
  const onTempRemoveChips = (itemId)=>{
    if (openPopupId === "1") {
      const updatedPermissions = tempselectedItem.filter(x => x.id !== itemId && x.role !== itemId && x.userId !== itemId && x.groupId !== itemId);
      setTempSelectItem(updatedPermissions);
    }else if(openPopupId === "2"){
      const updatedPermissions = tempselectedItem.filter(x => x.id !== itemId &&  x.role !== itemId && x.userId !== itemId || x.groupId !== itemId)
      setTempSelectItem2(updatedPermissions);
    }else if( openPopupId === "3"){
      const updatedPermissions = tempselectedItem.filter(x => x.id !== itemId && x.role !== itemId && x.userId !== itemId || x.groupId !== itemId)
      setTempSelectItem3(updatedPermissions);
    }
  };

  const onSubmitUserPermission = ()=>{
    if(openPopupId === "1"){
      dispatch(
        conversationAction.onChange(
          "selectedConversationPermission",
          tempselectedItem
        )
      );
      setIsOpen(false);
    }else if(openPopupId === "2"){
      dispatch(
        conversationAction.onChange(
          "selectedStartConversationPermission",
          tempselectedItem2
        )
      );
      setIsOpen2(false);
    }else if( openPopupId === "3"){
      dispatch(
        conversationAction.onChange(
          "selectedReciveNewConversationPermission",
          tempselectedItem3
        )
      );
      setIsOpen3(false);
    }
  }

  useEffect(()=>{
    setIsLoading(true);
    const activeTab = allTabsTilte.find(tab => tab.id === activeTabId);
    if (activeTab) {
      if(activeTab?.id === "1"){
        dispatch(conversationAction.onGetConversationRoles({
          search: debouncedValue,
          pagination: {
            pageSize: 1000,
            pageNo: 1,
            orderByKey: order.orderByKey,
            orderByVal: order.orderVal,
          },
        })).then((response)=>{
          if(response.status === 200){
            setIsLoading(false);
          }
        });
      }else{
        dispatch(conversationAction.onConversationGetEndCustomerGroups({
          CustomerTypeName:activeTab.title,
          search: debouncedValue,
          pagination: {
            pageSize: 1000,
            pageNo: 1,
            orderByKey: order.orderByKey,
            orderByVal: order.orderVal,
          },
        })).then((response)=>{
          if(response.success){
            setIsLoading(false);
          }
        });
      }
      setIsLoading(false);
    }
  },[activeTabId , debouncedValue , order.orderByKey ,order.orderVal ]);

  useEffect(()=>{
  setSearchPermission("");
  setOrderBy({
    orderByKey: "Id",
    orderVal: 0,
  });
  }, [activeTabId , openPopupId])
  
  function onSetAddUpdatePayload(data) {
    const updatedDate = data.map(item => {
      if (item.permissionType === "Role") {
        return {
          name: item.name,
          userId: "",
          groupId: 0,
          role: item.id,
          type: "Role"
        };
      } else if (item.permissionType === "User") {
        return {
          name: `${item.firstName} ${item.lastName}`,
          userId: item.id,
          groupId: 0,
          role: "",
          type: "User"
        };
      } else if (item.permissionType === "Groups") {
        return {
          name: item.value,
          userId: "",
          groupId: Number(item.id) ?? 0 ,
          role: "",
          type: "Groups"
        };
      } else if (item.permissionType === "Business Customer") {
        return {
          name: item.groupName,
          userId: "",
          groupId: Number(item.id) ?? 0 ,
          role: "",
          type: "Groups"
        };
      }else if (item.permissionType === "End Customer") {
        return {
          name: item.groupName,
          userId: "",
          groupId: Number(item.id) ?? 0 ,
          role: "",
          type: "Groups"
        };
      }else{
        return item;
      }
    }).filter(Boolean);
    return updatedDate;
  }

  
  const onAddUpdateConversationPermssion = ()=>{
  setIsLoading(true)
   dispatch(conversationAction.onAddUpdateConversationPermission({
    campaignId: conversationCompaginId ? conversationCompaginId : Number(paramId),
    ...addUpdatePermissionData,
    permissionsUserGroups:onSetAddUpdatePayload(selectedConversationPermission),
    permissionsStartUserGroups:onSetAddUpdatePayload(selectedStartConversationPermission),
    permissionsReceiveUserGroups:onSetAddUpdatePayload(selectedReciveNewConversationPermission),
   })).then((res)=>{
    setIsLoading(false);
    const newData = res.responseData;
    const existingDataIndex = conversationPermissionData.findIndex(item => item.id === newData.id);
    const updatedPermissionData = [...conversationPermissionData];
    updatedPermissionData.splice(existingDataIndex, 1);

    if(res.responseMsg === "Updated Successfully"){
      dispatch(conversationAction.onGetConversationPermission(res?.responseData.campaignId));
    }else{
      dispatch(conversationAction.onGetConversationPermission(res?.responseData.campaignId));
    }
    setIsNewPermission(false)
  })
  }
  const onSelectAllPermission = () => {
    if (openPopupId === "1") {
      const itemsToAdd = conversationPermissionGridData?.map(x => ({
        ...x,
        permissionType:
          activeTabId === "1" ? "Role" :
            activeTabId === "2" ? "User" :
              activeTabId === "3" ? "Groups" :
                activeTabId === "4" ? "Business Customer" : "End Customer"
      }));
      const uniqueItemsToAdd = itemsToAdd.filter(item => !checkboxSelectedPermission.some(permission => permission.id === item.id))
      setCheckboxSelectedPermission(prevPermissions => [...prevPermissions, ...uniqueItemsToAdd]);
    } else if (openPopupId === "2") {
      const itemsToAdd = conversationPermissionGridData.map(x => ({
        ...x,
        permissionType:
          activeTabId === "1" ? "Role" :
            activeTabId === "2" ? "User" :
              activeTabId === "3" ? "Groups" :
                activeTabId === "4" ? "Business Customer" : "End Customer"
      }));
      const itemsToAddIds = itemsToAdd.map(item => item.id);
      const itemsToAddAreSelected = itemsToAddIds.every(id => checkboxSelectedPermission2.some(permission => permission.id === id));
      if (itemsToAddAreSelected) {
        const updatedPermissions = checkboxSelectedPermission2.filter(permission => !itemsToAddIds.includes(permission.id));
        setCheckboxSelectedPermission2(updatedPermissions);
      } else {
        setCheckboxSelectedPermission2(prevPermissions => [...prevPermissions, ...itemsToAdd]);
      }
    } else if (openPopupId === "3") {
      const itemsToAdd = conversationPermissionGridData.map(x => ({
        ...x,
        permissionType:
          activeTabId === "1" ? "Role" :
            activeTabId === "2" ? "User" :
              activeTabId === "3" ? "Groups" :
                activeTabId === "4" ? "Business Customer" : "End Customer"
      }));
      const itemsToAddIds = itemsToAdd.map(item => item.id);
      const itemsToAddAreSelected = itemsToAddIds.every(id => checkboxSelectedPermission3.some(permission => permission.id === id));
      if (itemsToAddAreSelected) {
        const updatedPermissions = checkboxSelectedPermission3.filter(permission => !itemsToAddIds.includes(permission.id));
        setCheckboxSelectedPermission3(updatedPermissions);
      } else {
        setCheckboxSelectedPermission3(prevPermissions => [...prevPermissions, ...itemsToAdd]);
      }
    }

  };

  const onEditConversationPermission =(permission)=>{
    setIsOpenPermissionAccordion(permission);
    if(!paramId || isAccordion2Edit){
    setIsNewPermission(true);
    setAddUpdatePermissionData({
      campaignId:permission.campaignId,
      id: permission.id,
      name: permission.name,
      value: permission.value,
      userRequiredProductAccess: permission.userRequiredProductAccess,
      startConversationRequiredProductAccess: permission.startConversationRequiredProductAccess,
      receiveConversationRequiredProductAccess: permission.receiveConversationRequiredProductAccess,
      userEmailToProceed: permission.userEmailToProceed,
      startUserEmailToProceed: permission.startUserEmailToProceed,
      receiveUserEmailToProceed: permission.receiveUserEmailToProceed,
    })
    dispatch(
      conversationAction.onChange(
        "selectedConversationPermission",
        permission.permissionsUserGroups
      )
    );
    dispatch(
      conversationAction.onChange(
        "selectedStartConversationPermission",
        permission.permissionsStartUserGroups
      )
    );
    dispatch(
      conversationAction.onChange(
        "selectedReciveNewConversationPermission",
        permission.permissionsReceiveUserGroups
      )
    );
    setTempSelectItem(permission.permissionsUserGroups);
    setTempSelectItem2(permission.permissionsStartUserGroups);
    setTempSelectItem3(permission.permissionsReceiveUserGroups);
    }
  }
  const filteredPermissions = conversationPermissionData?.filter(permission =>
    permission.name.toLowerCase().includes(search.toLowerCase())
  );
  const onAddNewPermission = ()=>{
    setIsNewPermission(true);
    dispatch(
      conversationAction.onChange( "selectedConversationPermission", [] ));
    dispatch( conversationAction.onChange( "selectedStartConversationPermission", [] ));
    dispatch( conversationAction.onChange( "selectedReciveNewConversationPermission", [] ));
    setTempSelectItem([]);
    setTempSelectItem2([]);
    setTempSelectItem3([]);
    setAddUpdatePermissionData({
      id: 0,
      name: "",
      value: "",
      userRequiredProductAccess: true,
      startConversationRequiredProductAccess: true,
      receiveConversationRequiredProductAccess: true,
      userEmailToProceed: true,
      startUserEmailToProceed: true,
      receiveUserEmailToProceed: true,
    })
  }
  useEffect(() => {
   setActiveTabId("1");
  }, [openPopupId])

  return (
    <div className='conversationPermissionSetting'>
      {isLoading ? <Loader /> : null}
      <Grid>
        <Divider />
        <h3>Conversation Permissions</h3>
        <p className='convsationSettingInfo'>Choose conversation permissions for different users and groups.</p>
        <Grid className='permissionSearchBar'>
          <SoftwareGridFilter
            search={search}
            setSearch={setSearch}
            placeholder="Search"
          />
        </Grid>
        <Grid>
          {
            filteredPermissions.length ? filteredPermissions.map((x, i) =>
            (
              <Grid className='premissionAccordionContainer' onClick={()=>onEditConversationPermission(x)}>
                <Accordion key={i} expanded={openPermissionAccordion?.id === x?.id}>
                  <AccordionSummary
                    expandIcon={<ExpandDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid className="conversationAccordianHeading">
                      <Typography className='accordionHeading'>
                        {x.name} <span>Group</span>
                      </Typography>

                      <List>
                        <ListItem className="accordionDetials">
                          {x.value} Conversations
                        </ListItem>
                      </List>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid>
                      <p className='chipContainerHeading'>
                        User or Group
                      </p>
                      <ConversationAccordionChips
                        chipData={[
                          ...x?.permissionsUserGroups?.map(item => ({
                            id: item.userId || item.groupId,
                            value: item.name,
                            generic: item.role
                          }))
                        ]}
                        text="Added users and groups will appear here"
                      />
                      <p className='chipContainerHeading'>
                        Can Start Conversations With
                      </p>
                      <ConversationAccordionChips
                        chipData={[
                          ...x?.permissionsStartUserGroups.map(item => ({
                            id: item.userId || item.groupId,
                            value: item.name,
                            generic: item.role
                          }))
                        ]}
                        text="Added users and groups will appear here"
                      />
                      <p className='chipContainerHeading'>
                        Can Receive New Conversations From
                      </p>
                      <ConversationAccordionChips
                        chipData={[
                          ...x?.permissionsReceiveUserGroups.map(item => ({
                            id: item.userId || item.groupId,
                            value: item.name,
                            generic: item.role
                          }))
                        ]}
                        text="Added users and groups will appear here"
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
            )
            :  <span className="emptyGridText">
            Uh Oh! No Conversation Permissions have been created.
            </span>
          }
        </Grid>
        
        {
          isNewPermission ?

            <Grid>
              <h3 className='addPermissionHeading'>New Conversation Permission</h3>
              <Grid className='newPermissionConariner'>
                <Grid>
                  <input
                    className='permissionNameInput'
                    placeholder="Enter Conversation Permission Name"
                    type="text"
                    value={addUpdatePermissionData.name}
                    onChange={(event) => {
                      setAddUpdatePermissionData(prevState => ({
                        ...prevState,
                        "name": event.target.value
                      }));
                    }}
                  />
                  <Grid xs={12} className="conversationChannel">
                    <Button
                      color="primary"
                      className="permissionAddBtn"
                      variant="contained"
                      onClick={() => {
                        setIsOpen(true)
                        setOpenPopId("1");
                      }}
                    >
                      <Add />
                    </Button>
                    <Typography variant="body1" className="channelHeadings">
                      User or Group
                    </Typography>

                    <CustomConversationPopup
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      title="Add User or Group"
                      heading="OFish Users & Groups"
                      description="Select the users and groups this permission will apply to."
                      allTabs={allTabs}
                      activeTabId={activeTabId}
                      setActiveTabId={setActiveTabId}
                      searchChannels={searchPermission}
                      setSearchChannels={setSearchPermission}
                      chipData={[
                        ...tempselectedItem.map(item => {
                          switch (item.permissionType) {
                            case "Role":
                              return { id: item.id, value: item.name };
                            case "User":
                              return { id: item.id, value: `${item.firstName} ${item.lastName}`, generic: item.role };
                            case "Groups":
                              return item;
                            case "Business Customer":
                            case "End Customer":
                              return { id: item.id, value: item.groupName };
                            default:
                              return {
                                id: item.userId || item.groupId || item.role,
                                value: item.name,
                                generic: item.type
                            };
                          }
                        })
                      ]}
                      chipText ={"Added users and groups will appear here"}
                      onRemoveChips={(id) => { onTempRemoveChips(id) }}
                      onSubmit={onSubmitUserPermission}
                    >
                      <Grid className={classes.popupCheckBox}>
                        <Grid container alignItems='center'>
                          <CustomCheckbox
                            checkedId={"userRequiredProductAccess"}
                            pressCheck={() => handleCheckboxChange('userRequiredProductAccess')}
                            checkValue={addUpdatePermissionData.userRequiredProductAccess}
                          />
                          <span className={classes.popupCheckBoxText}>
                            Require Product Accessss
                          </span>
                        </Grid>
                        <Grid container alignItems='center'>
                          <CustomCheckbox
                            checkedId={"userEmailToProceed"}
                            pressCheck={() => handleCheckboxChange('userEmailToProceed')}
                            checkValue={addUpdatePermissionData.userEmailToProceed}
                          />
                          <span className={classes.popupCheckBoxText}>
                            Require e-mail to proceed to chat{" "}
                            <Typography variant="caption" className={classes.recommendedText}>
                              ( Recommended )
                            </Typography>

                          </span>
                        </Grid>
                      </Grid>

                      <ConversationPermissionPopupGrid
                        activeTabId={activeTabId}
                        checkboxSelectedPermission={checkboxSelectedPermission}
                        setCheckboxSelectedPermission={setCheckboxSelectedPermission}
                        onCheckboxAddPermission={onCheckboxAddPermission}
                        addConversation={addConversation}
                        addMultiple={addMultiple}
                        tempselectedItem={tempselectedItem}
                        onSelectAllPermission={onSelectAllPermission}
                        isLoading={isLoading}
                        order={order}
                        setOrderBy={setOrderBy}
                      />
                    </CustomConversationPopup>
                  </Grid>
                  <Typography className='channelDescription'>
                    Choose users or groups this conversation permission applies to.
                  </Typography>
                </Grid>
                <ConversationAccordionChips
                  chipData={[
                    ...selectedConversationPermission.map(item => {
                      switch (item.permissionType) {
                        case "Role":
                          return { id: item.id, value: item.name };
                        case "User":
                          return { id: item.id, value: `${item.firstName} ${item.lastName}`, generic: item.role };
                        case "Groups":
                          return item;
                        case "Business Customer":
                        case "End Customer":
                          return { id: item.id, value: item.groupName };
                        default:
                          return {
                            id: item.userId || item.groupId || item.role,
                            value: item.name,
                            generic: item.type
                        };
                      }
                    })
                  ]}
                  onRemoveChips={(id) => { onRemoveChips(id, "1") }}
                  text="Added users and groups will appear here"
                />
                <Grid>
                  <Grid xs={12} className="conversationChannel">
                    <Button
                      color="primary"
                      className="permissionAddBtn"
                      variant="contained"
                      onClick={() => {
                        setIsOpen2(true)
                        setOpenPopId("2");
                      }}
                    >
                      <Add />
                    </Button>
                    <Typography variant="body1" className="channelHeadings">
                      Can Start Conversations With
                    </Typography>
                    <CustomConversationPopup
                      isOpen={isOpen2}
                      setIsOpen={setIsOpen2}
                      title="Can Receive Conversations From"
                      heading="OFish Users & Groups"
                      description="Select the users and groups the previously selected users and groups can receive conversation requests from."
                      allTabs={allTabs}
                      activeTabId={activeTabId}
                      setActiveTabId={setActiveTabId}
                      searchChannels={searchPermission}
                      setSearchChannels={setSearchPermission}
                      chipData={[
                        ...tempselectedItem2.map(item => {
                          switch (item.permissionType) {
                            case "Role":
                              return { id: item.id, value: item.name };
                            case "User":
                              return { id: item.id, value: `${item.firstName} ${item.lastName}`, generic: item.role };
                            case "Groups":
                              return item;
                            case "Business Customer":
                            case "End Customer":
                              return { id: item.id, value: item.groupName };
                            default:
                              return {
                                id: item.userId || item.groupId || item.role,
                                value: item.name,
                                generic: item.type
                            };
                          }
                        })
                      ]}
                      chipText ={"Added users and groups will appear here"}
                      onRemoveChips={(id) => { onTempRemoveChips(id) }}
                      onSubmit={onSubmitUserPermission}
                    >
                      <Grid className={classes.popupCheckBox}>
                        <Grid container alignItems='center'>
                          <CustomCheckbox
                            checkedId={"startConversationRequiredProductAccess"}
                            pressCheck={() => handleCheckboxChange('startConversationRequiredProductAccess')}
                            checkValue={addUpdatePermissionData.startConversationRequiredProductAccess}
                          />
                          <span className={classes.popupCheckBoxText}>
                            Require Product Access
                          </span>
                        </Grid>
                        <Grid container alignItems='center'>
                          <CustomCheckbox
                            checkedId={"startUserEmailToProceed"}
                            pressCheck={() => handleCheckboxChange('startUserEmailToProceed')}
                            checkValue={addUpdatePermissionData.startUserEmailToProceed}
                          />
                          <span className={classes.popupCheckBoxText}>
                            Require e-mail to proceed to chat{" "}
                            <Typography variant="caption" className={classes.recommendedText}>
                              ( Recommended )
                            </Typography>

                          </span>
                        </Grid>
                      </Grid>
                      <ConversationPermissionPopupGrid
                        activeTabId={activeTabId}
                        selectedConversationPermission={selectedStartConversationPermission}
                        checkboxSelectedPermission={checkboxSelectedPermission2}
                        setCheckboxSelectedPermission={setCheckboxSelectedPermission2}
                        onCheckboxAddPermission={onCheckboxAddPermission}
                        addConversation={addConversation}
                        addMultiple={addMultiple}
                        tempselectedItem={tempselectedItem2}
                        onSelectAllPermission={onSelectAllPermission}
                        isLoading={isLoading}
                        order={order}
                        setOrderBy={setOrderBy}
                      />
                    </CustomConversationPopup>
                  </Grid>
                  <Typography className='channelDescription'>
                    Choose who these users or groups can start conversations with.
                  </Typography>
                </Grid>
                <ConversationAccordionChips
                  chipData={[
                    ...tempselectedItem2.map(item => {
                      switch (item.permissionType) {
                        case "Role":
                          return { id: item.id, value: item.name };
                        case "User":
                          return { id: item.id, value: `${item.firstName} ${item.lastName}`, generic: item.role };
                        case "Groups":
                          return item;
                        case "Business Customer":
                        case "End Customer":
                          return { id: item.id, value: item.groupName };
                        default:
                          return {
                            id: item.userId || item.groupId || item.role,
                            value: item.name,
                            generic: item.type
                        };
                      }
                    })
                  ]}
                  onRemoveChips={(id) => { onRemoveChips(id, "2") }}
                  text="Added users and groups will appear here"
                />
                <Grid>
                  <Grid xs={12} className="conversationChannel">
                    <Button
                      color="primary"
                      className="permissionAddBtn"
                      variant="contained"
                      onClick={() => {
                        setIsOpen3(true);
                        setOpenPopId("3");
                      }}
                    >
                      <Add />
                    </Button>
                    <Typography variant="body1" className="channelHeadings">
                      Can Receive New Conversations From
                    </Typography>
                    <CustomConversationPopup
                      isOpen={isOpen3}
                      setIsOpen={setIsOpen3}
                      title="Can Start Conversations With"
                      heading="OFish Users & Groups"
                      description="Select the users and groups the previously selected users and groups can start conversations with."
                      allTabs={allTabs}
                      activeTabId={activeTabId}
                      setActiveTabId={setActiveTabId}
                      searchChannels={searchPermission}
                      setSearchChannels={setSearchPermission}
                      chipData={[
                        ...tempselectedItem3.map(item => {
                          switch (item.permissionType) {
                            case "Role":
                              return { id: item.id, value: item.name };
                            case "User":
                              return { id: item.id, value: `${item.firstName} ${item.lastName}`, generic: item.role };
                            case "Groups":
                              return item;
                            case "Business Customer":
                            case "End Customer":
                              return { id: item.id, value: item.groupName };
                            default:
                              return {
                                id: item.userId || item.groupId || item.role,
                                value: item.name,
                                generic: item.type
                            };
                          }
                        })
                      ]}
                      chipText ={"Added users and groups will appear here"}
                      onRemoveChips={(id) => { onTempRemoveChips(id) }}
                      onSubmit={onSubmitUserPermission}
                    >
                      <Grid className={classes.popupCheckBox}>
                        <Grid container alignItems='center'>
                          <CustomCheckbox
                            checkedId={"receiveConversationRequiredProductAccess"}
                            pressCheck={() => handleCheckboxChange('receiveConversationRequiredProductAccess')}
                            checkValue={addUpdatePermissionData.receiveConversationRequiredProductAccess}
                          />
                          <span className={classes.popupCheckBoxText}>
                            Require Product Access
                          </span>
                        </Grid>
                        <Grid container alignItems='center'>
                          <CustomCheckbox
                            checkedId={"receiveUserEmailToProceed"}
                            pressCheck={() => handleCheckboxChange('receiveUserEmailToProceed')}
                            checkValue={addUpdatePermissionData.receiveUserEmailToProceed}
                          />
                          <span className={classes.popupCheckBoxText}>
                            Require e-mail to proceed to chat{" "}
                            <Typography variant="caption" className={classes.recommendedText}>
                              ( Recommended )
                            </Typography>

                          </span>
                        </Grid>
                      </Grid>
                      <ConversationPermissionPopupGrid
                        activeTabId={activeTabId}
                        selectedConversationPermission={selectedConversationPermission}
                        checkboxSelectedPermission={checkboxSelectedPermission3}
                        setCheckboxSelectedPermission={setCheckboxSelectedPermission3}
                        onCheckboxAddPermission={onCheckboxAddPermission}
                        addConversation={addConversation}
                        addMultiple={addMultiple}
                        tempselectedItem={tempselectedItem3}
                        onSelectAllPermission={onSelectAllPermission}
                        isLoading={isLoading}
                        order={order}
                        setOrderBy={setOrderBy}
                      />
                    </CustomConversationPopup>
                  </Grid>
                  <Typography className='channelDescription'>
                    Choose who these users or groups can receive new conversations from.
                  </Typography>
                </Grid>
                {/* <Grid className='permissionContainer'>
                  <p className='emptyResult'>Added users and groups will appear here</p>
                </Grid> */}
                <ConversationAccordionChips
                  chipData={[
                    ...tempselectedItem3.map(item => {
                      switch (item.permissionType) {
                        case "Role":
                          return { id: item.id, value: item.name };
                        case "User":
                          return { id: item.id, value: `${item.firstName} ${item.lastName}`, generic: item.role };
                        case "Groups":
                          return item;
                        case "Business Customer":
                        case "End Customer":
                          return { id: item.id, value: item.groupName };
                        default:
                          return {
                            id: item.userId || item.groupId || item.role,
                            value: item.name,
                            generic: item.type
                        };
                      }
                    })
                  ]}
                  onRemoveChips={(id) => { onRemoveChips(id, "3") }}
                  text="Added users and groups will appear here"
                />
                <Grid container justifyContent='flex-end'>
                  <IconButton onClick={() => { setIsNewPermission(false) }}>
                    <CloseRedIcon />
                  </IconButton>
                  <IconButton onClick={() => {
                    onAddUpdateConversationPermssion();
                    handleAddAndUpdateAccordion()
                  }} >
                    <SaveGreenIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            : null
        }

        {
          !paramId || isAccordion2Edit ? <>
            {
              (!paramId || isAccordion2Edit) ? <Grid className='newPermissionButtonContainer'>
                <Button
                  variant="outlined"
                  className="newPermissionButton"
                  disabled={isNewPermission}
                  style={{ opacity: isNewPermission ? 0.5 : 1 }}
                  onClick={() =>  onAddNewPermission() }
                >
                  New Permission
                </Button>
              </Grid>
                : null}

            <Grid item className="btn_save_next">
              <>
                <Button
                  variant="outlined"
                  className="skipButton"
                  // disabled={!paramId}
                  onClick={handleSkipButton}
                >
                  Skip
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="dashboardButtons saveBasicInfo"
                  type="submit"
                  // disabled={
                  //     !selectedRelease?.nameInternal?.length ||
                  //     !selectedRelease?.releaseId?.length
                  // }
                  onClick={handleNextButton}
                >
                  Next
                </Button>
              </>

            </Grid>
          </> : null
        }

      </Grid>
    </div>
  )
}
