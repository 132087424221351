import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import InputField from "../../../../components/common/InputField";
import MenuItem from "@material-ui/core/MenuItem";
import { BsPlus, BsThreeDotsVertical } from "react-icons/bs";
import queryString from "query-string";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, InputLabel } from "@material-ui/core";
import { SVGIcons, downarrow } from "../../../../resources/images/index";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import Loader from "../../../../components/common/Loader";
import SelectCompanyImagePopUp from "./SelectCompanyImagePopUp";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { first, last } from "lodash";
// import AddNewEnvironmentpopup from "../../../../components/common/popUps/AddNewEnvironmentpopup";
import PasswordResetPopup from "../../../../components/common/popUps/PasswordResetPopup";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { RoutesObj } from "../../../../routes";
import AddressGrid from "./AddressGrid";
import FriendFamilyGrid from "./FriendFamilyGrid";
import OutsideClickHandler from "react-outside-click-handler";
import NewEnvironmentGrid from "./NewEnvironmentGrid";
import {
  AddressAutoComplete,
  CustomDropdown,
  Dropdown,
} from "../../../../components/common";
import { StarFill, StarOutline } from "../../LeftNav/SvgIcons";
import SoftwareGridFilter from "../SoftwareUpdates/SoftwareGridFilter";
import PhoneNumberInput from "../../../../components/common/PhoneNumberInput/PhoneNumberInput";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  resetbtn: {
    position: "absolute",
    top: "41px",
    right: "-54px",
    fontSize: "14px",
    color: "#3699FF",
    textTransform: "capitalize",
    zIndex: "10",
  },
  gridWrapper: {
    position: "relative",
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
  },
  accordianBody: {
    padding: "15px 50px !important",
  },
  phoneNumberInputError:{
    opacity:"1",
    width:"120px !important",
    margin:"0 20px 8px 0 !important",
    border: "1px solid #F44336 !important",
    '& .MuiInput-underline:before':{
      borderBottom:"unset",
    },
    '& .formMainWrapper input':{
      border:"unset !important",
    },
    '& .MuiInputBase-input':{
      height:"37px",
      border:"none !important",
      flex:"0 0 88.5% !important",
      borderRadius:"0px 6px 6px 0px",
    },
    '& .MuiInputAdornment-root':{
      // borderRight:"1px solid rgba(0, 0, 0, 0.23)",
      // height:"37px",
    },
    "& MuiInput-root.Mui-disabled":{
      background: "#f9f9f9 !important",
      color: "#4f4f4f !important",
  }},
  phoneNumberInput:{
    opacity:"1",
    width:"120px !important",
    margin:"0 20px 33px 0 !important",
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    '& .MuiInput-underline:before':{
      borderBottom:"unset",
    },
    '& .formMainWrapper input':{
      border:"unset !important",
    },
    '& .MuiInputBase-input':{
      height:"37px",
      border:"none !important",
      flex:"0 0 88.5% !important",
      borderRadius:"0px 6px 6px 0px",
    },
    '& .MuiInputAdornment-root':{
      // borderRight:"1px solid rgba(0, 0, 0, 0.23)",
      // height:"37px",
    },
    "& MuiInput-root.Mui-disabled":{
      background: "#f9f9f9 !important",
      color: "#4f4f4f !important",
  },
  },
  disabledDropdown: {
    pointerEvents: "none",
    opacity: 1,
    width: "120px !important",
    margin: "0 20px 33px 0 !important",
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    '& .MuiInput-underline:before': {
      borderBottom: "unset",
    },
    '& .formMainWrapper input': {
      border: "unset !important",
    },
    '& .MuiInputBase-input': {
      height: "37px",
      border: "none !important",
      flex: "0 0 88.5% !important",
      borderRadius: "0px 6px 6px 0px",
    },
    '& .MuiInputAdornment-root': {
      // borderRight:"1px solid rgba(0, 0, 0, 0.23)",
      // height:"37px",
    },
    "& MuiInput-root.Mui-disabled": {
      background: "#f9f9f9 !important",
      color: "#4f4f4f !important",
    },
  },
  phoneNumberLable:{
    fontSize: "14px",
    color: "#2d3339",
    fontWeight: "500",
    marginBottom: "6px",
    minHeight: "19px",
    zIndex: 1,
  },
  requiredStar:{
    color: "#ff4500",
  }
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "transparent !important",
    //   margin: '12px 0'
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

const eStatuses = [
  {
    id: "1",
    name: "Active",
  },
  {
    id: "2",
    name: "Inactive",
  },
  {
    id: "3",
    name: "Draft",
  },
];

export default function Environments({ isAddNew }) {
  const { envWithDeviceCount } = useSelector(state => state.endUserReducer);
  let isDisable = [];
  const history = useHistory();
  const [expanded1, setExpanded] = useState(true);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [isPasswordModal, setIsPasswordModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [endUserGroup, setEndUserGroup] = useState([]);
  const [endUserGroupId, setEndUserGroupId] = useState(0);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode , setCountryCode] = useState("1");
  const [address, setAddress] = useState("");
  const [countryId, setCountryId] = useState("");
  const [cityId, setCityId] = useState("");
  const [stateId, setStateId] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isFavourite, setIsFavourite] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [isEditAdr, setIsEditAdr] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [userEStatus, setUserEStatus] = useState(1);
  const [contactFieldData, setContactFieldsData] = useState([]);
  const [selectPhnType, setSelectPhnType] = useState("mobile");
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [contactModuleData, setContactModuleData] = useState([]);
  const [listContactType, setListContactTypes] = useState([]);
  const [selectedContactType, setSelectedContactType] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [isEditEnv, setIsEditEnv] = useState(false);
  const [isEditAble, setIsEditAble] = useState([]);
  const [isActiveUser, setIsActiveUser] = useState(0);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [envList, setEnvList] = useState([]);
  const [listFnFData, setListFnFData] = useState([]);
  const [listAddressData, setListAddressData] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const selectedUser = queryString.parse(location.search);
  const [isEdit, setIsEdit] = useState(true);
  const [isUpdatedData, setIsUpdateData] = useState(false);
  const [tempAddressData, setTempAddressData] = useState(listAddressData);
  const [addressSearch, setAddressSearch] = useState("");
  const [tempEnvData, setTempEnvData] = useState(envWithDeviceCount);
  const [envSearch, setEnvSearch] = useState("");
  const [tempFriendData, setTempFriendData] = useState(listFnFData);
  const [friendSearch, setFriendSearch] = useState("");
  const [countryCodeError, setCountryCodeError] = useState('');

  useEffect(() => {
    dispatch(endUserActions.onGetContactTypesDDL(4)).then(data => {
      if (data?.success) {
        setListContactTypes(data.data);
        const selectedUser = queryString.parse(location.search);
        if (selectedUser?.id) {
        } else {
          if (data.data?.length > 0) {
            setSelectedContactType(data.data[0]);
            getContactTypeById(parseInt(data.data[0].id));
          }
        }
      }
    });
    loadData();
    loadEnvBE();
    //getCountry();
    //getCity();
    //getState();
  }, []);

  useEffect(() => {
    if (addressSearch !== "") {
      const updatedData = listAddressData?.filter(
        x =>
          x?.addressNickname
            .toLowerCase()
            .includes(addressSearch.toLowerCase()) ||
          x.streetAddress.toLowerCase().includes(addressSearch.toLowerCase())
      );
      setTempAddressData(updatedData);
    } else {
      setTempAddressData(listAddressData);
    }

    if (envSearch !== "") {
      const updatedData = envWithDeviceCount?.filter(x =>
        x?.name.toLowerCase().includes(envSearch.toLowerCase())
      );
      setTempEnvData(updatedData);
    } else {
      setTempEnvData(envWithDeviceCount);
    }

    if (friendSearch !== "") {
      const updatedData = listFnFData?.filter(x =>
        x?.name.toLowerCase().includes(friendSearch.toLowerCase())
      );
      setTempFriendData(updatedData);
    } else {
      setTempFriendData(listFnFData);
    }
  }, [
    listAddressData,
    envWithDeviceCount,
    listFnFData,
    addressSearch,
    envSearch,
    friendSearch,
  ]);

  const loadEnvBE = () => {
    dispatch(endUserActions.onGetEnvIcons()).then(data => {
      if (data && data.success) {
        setEnvList(data.data);
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };
  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== password) {
        return false;
      } else {
        return true;
      }
    });
  }, [password]);

  const getContactTypeById = cntTypeId => {
    setLoading(true);
    dispatch(endUserActions.onGetContactTypeById(cntTypeId)).then(data => {
      if (data?.success) {
        const contactArr = data.data[0]?.contactFieldsData;
        const aa = contactArr.sort(function (a, b) {
          return a.fieldId - b.fieldId;
        });
        setContactFieldsData(aa);
        setContactModuleData(data.data[0]?.contactModulesFieldsData);
      }
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    });
  };

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (selectedUser?.id) {
      setLoading(true);
      dispatch(endUserActions.onGetUserById(selectedUser.id)).then(data => {
        setTimeout(function () {
          setLoading(false);
        }, 3000);
        if (data && data.success) {
          if (data.data) {
            const record = data.data;
            setAddress(record.address);
            setCityId(record.cityName);
            setCountryId(record.countryName);
            setEmail(record.email);
            setFirstName(record.firstName);
            setEndUserGroupId(record.groupId);
            setProfileImg(record.image);
            setLastName(record.lastName);
            setCountryCode(record?.countryCode ? record?.countryCode : "1");
            setPhoneNumber(record?.phoneNumber);
            setEditUserId(selectedUser.id);
            setStateId(record.stateName);
            setZipCode(record.zipCode);
            loadUserEnv(selectedUser.id);
            loadFFGridData();
            loadAddress();
            setIsActiveUser(record.isDeleted);
            setIsValidEmail(record.emailConfirmed);
            setIsValidPhone(record.isSMSVerified);
            setIsFavourite(record.isFavourite);
            setUserEStatus(`${record.eStatus}`);
            dispatch(endUserActions.onChange("editUserName", record.firstName));

            if (listContactType.length > 0 && record.contactTypeId > 0) {
              const relExist = listContactType.find(cntct => {
                return record.contactTypeId === parseInt(cntct.id);
              });
              if (relExist) {
                setSelectedContactType(relExist.id);
                getContactTypeById(parseInt(relExist.id));
              }
            } else {
              // const constactType = {
              //   id: "1",
              //   value: "End User",
              // };
              setSelectedContactType("1");
              getContactTypeById(parseInt(1));
            }
          }
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
      });
    }
  }, [location, listContactType, isUpdatedData]);

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (selectedUser?.id) {
      loadFFGridData();
      loadAddress();
    }
  }, []);

  const loadFFGridData = () => {
    const selectedUser = queryString.parse(location.search);
    dispatch(endUserActions.onGetFrndAndFamlyList(selectedUser?.id)).then(
      data => {
        setLoading(false);
        if (data?.success) {
          setListFnFData(data.data);
        }
      }
    );
  };

  const loadAddress = () => {
    const selectedUser = queryString.parse(location.search);
    dispatch(endUserActions.onGetAdrsByUser(selectedUser?.id)).then(data => {
      setLoading(false);
      if (data?.success) {
        setListAddressData(data.data);
      }
    });
  };
  const loadUserEnv = userId => {
    setLoading(true);
    dispatch(endUserActions.onGetEnvWithDeviceCount(userId)).then(data => {
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    });
  };
  const loadData = () => {
    setLoading(true);
    dispatch(endUserActions.onGetUserGroupDD()).then(data => {
      if (data && data.success) {
        setEndUserGroup(data.data);
        setEndUserGroupId(
          data.data && data.data.length > 0 ? data.data[0].id : ""
        );
      }
    });
  };

  const getCountry = () => {
    dispatch(contactsActions.onGetCountries()).then(data => {
      if (data && data.success) {
        if (data.data.length > 0) {
          setCountries(data.data);
        }
      }
    });
  };
  const getCity = () => {
    dispatch(contactsActions.onGetCities()).then(data => {
      if (data && data.success) {
        if (data.data.length > 0) {
          setCities(data.data);
        }
      }
    });
  };
  const getState = () => {
    dispatch(contactsActions.onGetProvinces()).then(data => {
      if (data && data.success) {
        if (data.data.length > 0) {
          setProvinces(data.data);
        }
      }
    });
  };

  const uploadProfileImage = file => {
    setLoading(true);
    dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
      if (data && data.success && data.data) {
        setProfileImg(data.data.url);
        setShowFeatureImage(false);
      }
      setLoading(false);
    });
  };

  const onClickAddNewEnv = () => {
    if (editUserId) {
      setIsModal(true);
      setIsEditEnv(false);
    } else {
      toast.error("Please add user, then you can create environment");
    }
  };

  const addCustomerInformation = () => {
    const modal = {
      email: email,
      password: password === "" ? "" : password,
      confirmEmail: email,
      confirmPassword: password === "" ? "" : password,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber.toString(),
      countryCode: countryCode.toString(),
      address: address,
      countryName: countryId,
      cityName: cityId,
      stateName: stateId,
      zipCode: zipCode,
      groupId: endUserGroupId,
      isFavourite: isFavourite,
      image: profileImg,
      policies: [],
      isAdmin: false,
      id: editUserId,
      customerTypeName: "EndCustomer",
      contactTypeId: parseInt(selectedContactType),
      userType: 2,
      eStatus: userEStatus,
    };
    if (editUserId) {
      setLoading(true);
      dispatch(endUserActions.onUpdateUserInfo(modal)).then(data => {
        if (data && data.success) {
          setIsUpdateData(true);
          toast.success("Customer information updated.");
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
        setLoading(false);
        setIsUpdateData(false);
      });
    } else {
      setLoading(true);
      dispatch(contactsActions.onAddCustomer(modal)).then(data => {
        if (data && data.success) {
          setEditUserId(data.data);
          toast.success("Customer information added.");
          history.push({
            pathname: RoutesObj.CustomerOverview.path,
            userId: data.data,
            search: `id=${data.data}&acitveTabId=2`,
          });
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
        setLoading(false);
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    addCustomerInformation();
  };

  const findFeildContactType = value => {
    const relExist = contactFieldData.find(cntct => {
      return value == cntct.fieldId;
    });
    if (relExist) {
      return true;
    } else {
      return false;
    }
  };

  const findModuleContact = value => {
    const relExist = contactModuleData.find(cntct => {
      return value == cntct.moduleId;
    });
    if (relExist) {
      return true;
    } else {
      return false;
    }
  };
  const checkIsRequired = value => {
    const relExist = contactFieldData.find(cntct => {
      return value == cntct.fieldId;
    });
    const isRequir = relExist?.fieldSettings.find(cntct => {
      return "Required" == cntct.additionalData;
    });
    if (isRequir) {
      return true;
    } else {
      return false;
    }
  };

  const checkPasswordErrMsg = (value, typePass) => {
    const validatArr = [];
    validatArr.push("This field is required");
    if (typePass === "veri") {
      validatArr.push("Password mismatch");
    } else {
      validatArr.push(
        "Your password must contain at least 1 uppercase, lowercase, number and special character"
      );
      validatArr.push("Maximum character limit is 50");
    }
    return validatArr;
  };

  const displayLabel = (cntFeildId, feildName) => {
    const relExist = contactFieldData.find(cntct => {
      return cntFeildId == cntct.fieldId;
    });
    if (relExist?.fieldSettings?.length > 0) {
      const isRequir = relExist?.fieldSettings.find(cntct => {
        return "label" == cntct.key;
      });
      if (isRequir && isRequir.additionalData.trim() !== "") {
        return isRequir.additionalData;
      } else {
        return feildName;
      }
    } else {
      return feildName;
    }
  };

  const onArchiveCustomer = () => {
    const selectedUser = queryString.parse(location.search);
    if (selectedUser?.id) {
      const modal = { userIds: [selectedUser.id], isArchived: true };
      setLoading(true);
      dispatch(contactsActions.onUpdateMarkUserAsArchived(modal)).then(data => {
        setLoading(false);
        if (data?.success) {
          setIsActiveUser(3);
          toast.success("Customer mark as archive");
        } else {
          toast.error(data?.message);
        }
      });
    } else {
      toast.error("Please select the user.");
    }
  };

  const onMarkFavouriteCustomer = () => {
    const selectedUser = queryString.parse(location.search);
    if (selectedUser?.id) {
      setLoading(true);
      const modal = [
        {
          userId: selectedUser?.id,
          isFavourite: true,
        },
      ];
      dispatch(contactsActions.onMarkFavouriteCustomer(modal)).then(data => {
        setLoading(false);
        if (!data?.data?.success) {
          toast.error(data?.message);
        } else {
          setIsFavourite(!isFavourite);
          toast.success("Customer information updated!");
        }
      });
    } else {
      toast.error("Please select the user.");
    }
  };

  const onBlockCustomer = () => {
    const selectedUser = queryString.parse(location.search);
    if (selectedUser?.id) {
      setLoading(true);
      const modal = {
        userIds: [selectedUser?.id],
        isBlocked: true,
      };
      dispatch(contactsActions.onBlockCustomer(modal)).then(data => {
        setLoading(false);
        if (!data?.success) {
          toast.error(data?.message);
        } else {
          setIsActiveUser(4);
          toast.success("Customer mark as block");
        }
      });
    } else {
      toast.error("Please select the user.");
    }
  };

  const onDeleteCustomer = () => {
    const selectedUser = queryString.parse(location.search);
    var userIds = [selectedUser.id];
    if (userIds.length > 0) {
      setLoading(true);
      dispatch(contactsActions.onDeleteUsers(userIds, false)).then(data => {
        setLoading(false);
        if (!data?.success) {
          toast.error(data?.message);
        } else {
          setIsActiveUser(1);
          toast.success("Customer mark as deleted");
        }
      });
    } else {
      toast.error("Please select the user.");
    }
  };

  const renderEStatusClassName = () => {
    let eStatusClass = "newCompanyHeaderSelect";
    if (userEStatus) {
      const selectedStatus = eStatuses.find(s => s.id === userEStatus)?.name;
      if (selectedStatus)
        eStatusClass = `newCompanyHeaderSelect-${selectedStatus}`;
    }
    return eStatusClass;
  };

  function truncate(uploadeAssetName) {
    if (uploadeAssetName && uploadeAssetName.length > 15) {
      return uploadeAssetName.substring(0, 22) + "...";
    }
    return uploadeAssetName;
  }
  const handlePhoneChange = (value, countryData) => {
    if (!countryData.dialCode) {
      setCountryCodeError('Invalid Country Code');
    } else {
      setCountryCodeError('');
    }
    setCountryCode(countryData.dialCode);
  };

  return (
    <div className=''>
      {isLoading ? <Loader /> : null}
      <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
        <div className='contactWrapper contentBody enduserMainWrapper'>
          <span className='addCompanyStar'>
            {!expanded1 && selectedUser?.id && isAddNew ? (
              <span
                className='green-tick-svg'
                style={{
                  margin: "0px",
                }}>
                <SVGIcons.GreenTick />
              </span>
            ) : (
              <>
                {expanded1 && (
                  <span className={renderEStatusClassName()}>
                    <span
                      style={{
                        position: "absolute",
                        top: "-150px",
                        right: "3px",
                        display: "flex",
                      }}>
                      <span
                        className='editUserFavourite'
                        onClick={() => {
                          onMarkFavouriteCustomer();
                        }}>
                        {isFavourite ? <StarFill /> : <StarOutline />}
                      </span>
                      <CustomDropdown
                        value={userEStatus}
                        onChange={slctdItem => {
                          setUserEStatus(slctdItem.id);
                        }}
                      />
                    </span>
                    {/* <SelectInput
                      placeholder='Please Select'
                      value={userStatus}
                      customMenuItems={eStatuses}
                      handleChange={({ target }) => {
                        setUserStatus(target.value);
                      }}
                    /> */}
                    <span className='contactTypeWrapperBtn'>
                      {editUserId && (
                        <OutsideClickHandler
                          onOutsideClick={() => setIsDropdown(false)}>
                          <i onClick={() => setIsDropdown(!isDropdown)}>
                            <BsThreeDotsVertical />
                          </i>
                          {isDropdown && (
                            <ul className='dropdown'>
                              <li
                                onClick={() => {
                                  setIsEdit(false);
                                  setIsDropdown(false);
                                }}>
                                Edit User
                              </li>
                              <li
                                onClick={() => {
                                  setIsPasswordModal(true);
                                  setIsDropdown(false);
                                }}>
                                Reset Password
                              </li>
                              <li
                                onClick={() => {
                                  onMarkFavouriteCustomer();
                                  setIsDropdown(false);
                                }}>
                                Favorite User
                              </li>
                              <li
                                onClick={() => {
                                  onArchiveCustomer();
                                  setIsDropdown(false);
                                }}>
                                Archive User
                              </li>
                              <li
                                onClick={() => {
                                  onBlockCustomer();
                                  setIsDropdown(false);
                                }}
                                className='blockUser'>
                                Block User
                              </li>
                              <li
                                onClick={() => {
                                  onDeleteCustomer();
                                  setIsDropdown(false);
                                }}>
                                Delete User
                              </li>
                            </ul>
                          )}
                        </OutsideClickHandler>
                      )}
                    </span>
                  </span>
                )}
              </>
            )}
          </span>
          {!selectedUser?.id && !expanded1 && (
            <span className='startBtnWrapper'>
              <Button
                color='primary'
                variant='outlined'
                onClick={() => {
                  setExpanded(true);
                }}>
                Start
              </Button>
            </span>
          )}
          <Accordion
            square
            expanded={expanded1}
            onChange={() => setExpanded(!expanded1)}>
            <AccordionSummary
              aria-controls='panel2d-content'
              id='panel2d-header'
              className='businessSaveButton'>
              <Typography className={classes.mainHeading}>
                <img src={downarrow} alt='' className={classes.headingIcon} />{" "}
                Customer Information
                {isActiveUser === 1 ? (
                  <span className='productRegistered'>Active</span>
                ) : isActiveUser === 0 ? (
                  <span className='productPending'>Inactive</span>
                ) : isActiveUser === -1 ? (
                  <span className='productDelete'>Deleted</span>
                ) : isActiveUser === 2 ? (
                  <span className='productBlock'>Blocked</span>
                ) : isActiveUser === 4 ? (
                  <span className='productDelete'>Draft</span>
                ) : isActiveUser === 3 ? (
                  <span className='productDelete'>Archive</span>
                ) : (
                  <span className='productDelete'>DeActivate</span>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianBody}>
              <div className='fr-view'>
                <div className='formMainWrapper enduserFormWrapper'>
                  <div className='envFormFields'>
                    <div className='uploadOuterWrapper'>
                      <div
                        className={`${
                          editUserId &&
                          (profileImg === "" || profileImg === null)
                            ? "noProfileImg"
                            : " "
                        } imageWrapper`}>
                        {editUserId &&
                        (profileImg === "" || profileImg === null) ? (
                          <i
                            className='userInitials'
                            onClick={() => {
                              if (isEdit && selectedUser?.id) {
                              } else {
                                setShowFeatureImage(!showFeatureImage);
                              }
                            }}>
                            {`${firstName?.charAt(0)?.toUpperCase()}${lastName
                              ?.charAt(0)
                              ?.toUpperCase()}`}
                          </i>
                        ) : (
                          <>
                            <img
                              className='image'
                              src={
                                profileImg !== ""
                                  ? profileImg
                                  : "http://via.placeholder.com/700x500"
                              }
                            />
                            {(profileImg === "" || profileImg === null) && (
                              <Button
                                disabled={isEdit && selectedUser?.id}
                                variant='outlined'
                                color='primary'
                                className='file-upload'
                                style={{ height: 50, alignSelf: "center" }}
                                onClick={() =>
                                  setShowFeatureImage(!showFeatureImage)
                                }>
                                &#128247;
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                      {profileImg !== "" && profileImg !== null && (
                        <Button
                          variant='outlined'
                          color='primary'
                          className='file-upload changeBtn'
                          style={{ height: 50, alignSelf: "center" }}
                          onClick={() =>
                            setShowFeatureImage(!showFeatureImage)
                          }>
                          Change
                        </Button>
                      )}
                    </div>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent:
                          findFeildContactType(1) ||
                          findFeildContactType(2) ||
                          findFeildContactType(3)
                            ? "unset"
                            : "space-between",
                        flexWrap: "wrap",
                      }}>
                      {findFeildContactType(1) && (
                        <InputField
                          placeholder='Enter The First Name'
                          disabled={isEdit && selectedUser?.id}
                          inputlabel={displayLabel(1, "First name")}
                          className={classes.gridWrapper}
                          id='firstname'
                          name='firstname'
                          value={firstName}
                          showRequired={true}
                          onChange={v => setFirstName(v.target.value)}
                          validators={
                            checkIsRequired(1) === true
                              ? [
                                  "required",
                                  "matchRegexp:^[A-Za-z']+$",
                                  "matchRegexp:^.{0,50}$",
                                ]
                              : [
                                  "matchRegexp:^[A-Za-z']+$",
                                  "matchRegexp:^.{0,50}$",
                                ]
                          }
                          errorMessages={
                            checkIsRequired(1) === true
                              ? [
                                  "This field is required",
                                  "Special characters and space are not allowed",
                                  "Maximum length is 50 characters",
                                ]
                              : [
                                  "Special characters and space are not allowed",
                                  "Maximum length is 50 characters",
                                ]
                          }
                        />
                      )}
                      {findFeildContactType(2) && (
                        <InputField
                          placeholder='Enter The Last Name'
                          disabled={isEdit && selectedUser?.id}
                          inputlabel={displayLabel(2, "Last name")}
                          className={classes.gridWrapper}
                          id='lastname'
                          name='lastname'
                          value={lastName}
                          showRequired={true}
                          onChange={v => setLastName(v.target.value)}
                          validators={
                            checkIsRequired(2) === true
                              ? [
                                  "required",
                                  "matchRegexp:^[A-Za-z']+$",
                                  "matchRegexp:^.{0,50}$",
                                ]
                              : [
                                  "matchRegexp:^[A-Za-z']+$",
                                  "matchRegexp:^.{0,50}$",
                                ]
                          }
                          errorMessages={
                            checkIsRequired(2) === true
                              ? [
                                  "This field is required",
                                  "Special characters and space are not allowed",
                                  "Maximum length is 50 characters",
                                ]
                              : [
                                  "Special characters and space are not allowed",
                                  "Maximum length is 50 characters",
                                ]
                          }
                        />
                      )}
                      {findFeildContactType(3) && (
                        <InputField
                          placeholder='Email Address'
                          disabled={isEdit && selectedUser?.id}
                          inputlabel={displayLabel(3, "Email Address")}
                          className={classes.gridWrapper}
                          // required={true}
                          id='email'
                          name='email'
                          value={email}
                          showRequired={true}
                          onChange={v => setEmail(v.target.value)}
                          isVerified={isValidEmail}
                          validators={[
                            "required",
                            "isEmail",
                            "matchRegexp:^.{0,50}$",
                          ]}
                          errorMessages={[
                            "This field is required",
                            "Email is not valid",
                            "Maximum length is 50 characters",
                          ]}
                        />
                      )}
                      <Grid
                        item
                        xs={4}
                        className={(classes.gridWrapper, "requiredField")}>
                        <Dropdown
                          selectlabel='Contact Type'
                          placeholder='Please Select'
                          disabled={isEdit && selectedUser?.id}
                          value={selectedContactType}
                          required={false}
                          showRequired
                          customMenuItems={listContactType?.map(b => {
                            return {
                              id: b?.id,
                              name: b?.value,
                            };
                          })}
                          handleChange={({ target }) => {
                            setSelectedContactType(target.value);
                            getContactTypeById(parseInt(target.value));
                          }}
                        />
                      </Grid>
                      <div className='inputWrapper contactInputWrapper selectOptions'>
                        <FormControl className='countryRegion'>
                          <label>{"End Group"}</label>
                          <Select
                            disabled={isEdit && selectedUser?.id}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ "aria-label": "Without label" }}
                            fullWidth
                            variant={"outlined"}
                            value={endUserGroupId}
                            onChange={e => setEndUserGroupId(e.target.value)}>
                            {/* <MenuItem value=''>Select Group Name</MenuItem> */}
                            {endUserGroup &&
                              endUserGroup.length > 0 &&
                              endUserGroup.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {truncate(item.value)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    </span>
                  </div>
                  {findFeildContactType(5) &&
                    // <InputField
                    //   placeholder='Address'
                    //   inputlabel={displayLabel(5, "Address")}
                    //   className={classes.gridWrapper}
                    //   id='address'
                    //   name='address'
                    //   value={address}
                    //   showRequired={true}
                    //   onBlur={() =>
                    //     address !== "" ? setAddress(address.trim()) : ""
                    //   }
                    //   onChange={e => setAddress(e.target.value)}
                    //   validators={
                    //     checkIsRequired(5) === true
                    //       ? ["required", "matchRegexp:^.{0,50}$"]
                    //       : ["matchRegexp:^.{0,50}$"]
                    //   }
                    //   errorMessages={
                    //     checkIsRequired(5) === true
                    //       ? [
                    //           "This field is required",
                    //           "Maximum length is 50 characters",
                    //         ]
                    //       : ["Maximum length is 50 characters"]
                    //   }
                    // />
                    (isEdit && selectedUser?.id ? (
                      <InputField
                        disabled={isEdit && selectedUser?.id}
                        placeholder='Address'
                        inputlabel='Address'
                        className={classes.gridWrapper}
                        value={address}
                      />
                    ) : (
                      <AddressAutoComplete
                        address={address}
                        onChange={e => {
                          setAddress(e.target.value);
                        }}
                        autoComId={"popupEnvLocInput"}
                        setPlace={p => {
                          isDisable = [];
                          setCityId("");
                          setStateId("");
                          setCountryId("");
                          setZipCode("");
                          const val =
                            document.getElementById("popupEnvLocInput")?.value;
                          p?.address_components?.forEach(x => {
                            if (x?.types?.includes("route")) {
                              setAddress(x?.long_name);
                            }
                            if (x?.types?.includes("locality")) {
                              setCityId(x?.long_name);
                              isDisable.push("city");
                            }
                            if (
                              x?.types?.includes("administrative_area_level_1")
                            ) {
                              setStateId(x?.long_name);
                              isDisable.push("state");
                            }
                            if (x?.types?.includes("country")) {
                              setCountryId(x?.long_name);
                              isDisable.push("country");
                            }
                            if (x?.types?.includes("postal_code")) {
                              setZipCode(x?.long_name);
                              isDisable.push("postalCode");
                            }
                          });
                          setIsEditAble(isDisable);
                        }}
                      />
                    ))}
                  <div className=''>
                    {/* <FormControl className='countryRegion'> */}
                    <InputField
                      disabled={isEdit && selectedUser?.id}
                      showRequired={true}
                      placeholder='Enter City'
                      inputlabel='City'
                      validators={["required"]}
                      errorMessages={["Please enter city"]}
                      value={cityId}
                      onChange={v => setCityId(v.target.value)}
                      // disabled={isEditAble.includes("city") ? true : false}
                    />
                  </div>
                  <div className=''>
                    <InputField
                      disabled={isEdit && selectedUser?.id}
                      showRequired={true}
                      placeholder='Enter State'
                      inputlabel='State'
                      validators={["required"]}
                      errorMessages={["Please enter state"]}
                      value={stateId}
                      onChange={v => setStateId(v.target.value)}
                      // disabled={isEditAble.includes("state") ? true : false}
                    />
                  </div>
                  <InputField
                    disabled={isEdit && selectedUser?.id}
                    placeholder='Zip code or postal code'
                    inputlabel='Zip code or postal code'
                    className={[classes.gridWrapper.phonenumber]}
                    id='zipCode'
                    name='zipCode'
                    value={zipCode}
                    onChange={e => {
                      setZipCode(e.target.value);
                    }}
                    validators={[
                      "matchRegexp:^(?:[A-Z0-9a-z ]+([-_ ]?[A-Z0-9a-z]+)*)?$",
                      "matchRegexp:^.{0,15}$",
                    ]}
                    errorMessages={[
                      "Special characters and multiple spaces are not allowed",
                      "Maximum length is 15 characters",
                    ]}
                    // disabled={isEditAble.includes("postalCode") ? true : false}
                  />
                  <div className=''>
                    <InputField
                      disabled={isEdit && selectedUser?.id}
                      showRequired={true}
                      placeholder='Enter country or region'
                      inputlabel='Country or region'
                      validators={["required"]}
                      errorMessages={["Please enter country or region"]}
                      value={countryId}
                      onChange={v => setCountryId(v.target.value)}
                      // disabled={isEditAble.includes("country") ? true : false}
                    />
                    {/* <FormControl className='countryRegion'>
                      <label>{"Country or region"}</label>
                      <Select
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        variant={"outlined"}
                        value={countryId}
                        onChange={e => {
                          setCountryId(e.target.value);
                        }}>
                        <MenuItem value={0}>
                          <span className='customPlaceholder'>
                            {"Select Country"}
                          </span>
                        </MenuItem>
                        {countries &&
                          countries.length > 0 &&
                          countries.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={item.id}
                                name={item.value}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl> */}
                  </div>
                  {findFeildContactType(6) && (
                    <FormControl>
                      <span className={classes.phoneNumberLable}>
                        Country Code &nbsp;
                        <span className={classes.requiredStar}>*</span></span>
                      <PhoneNumberInput
                        className={isEdit && selectedUser?.id ?
                          classes.disabledDropdown : countryCodeError ?
                            classes.phoneNumberInputError : classes.phoneNumberInput}
                        id='phone-input'
                        name='phone-input'
                        placeholder="Please Enter Phone Number"
                        value={countryCode}
                        onChange={handlePhoneChange}
                        error={countryCodeError}
                        readOnly={true}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </FormControl>

                  )}
                  {findFeildContactType(6) && (
                    <InputField
                      placeholder='Enter Phone Number'
                      inputlabel={displayLabel(6, "Phone Number")}
                      className={classes.gridWrapper}
                      disabled={isEdit && selectedUser?.id}
                      id='phoneNumber'
                      name='phoneNumber'
                      value={phoneNumber}
                      showRequired={true}
                      onBlur={() =>
                        phoneNumber !== "" ? setPhoneNumber(phoneNumber.trim()) : ""
                      }
                      onChange={e => setPhoneNumber(e.target.value?.replace(/\D/g, ''))}
                      validators={
                        checkIsRequired(6) === true
                          ? ["required", `matchRegexp:^\\d{7,12}$`]
                          : [`matchRegexp:^\\d{7,12}$`]
                      }
                      errorMessages={
                        checkIsRequired(6) === true
                          ? [
                            "This field is required",
                            "Invalid Phone number",
                          ]
                          : ["Invalid Phone number"]
                      }
                    />

                  )}
                  {/* {findFeildContactType(6) && (
                    <div className='inputWrapper contactInputWrapper selectOptionstypes'>
                      <FormControl className='countryRegion'>
                        <label>{"Select Types"}</label>
                        <Select
                          disabled={isEdit && selectedUser?.id}
                          displayEmpty
                          className={classes.selectEmpty}
                          inputProps={{ "aria-label": "Without label" }}
                          fullWidth
                          variant={"outlined"}
                          value={selectPhnType}
                          onChange={e => {
                            setSelectPhnType(e.target.value);
                          }}>
                          <MenuItem value={"mobile"}>Mobile</MenuItem>
                          <MenuItem value={"home"}>Home</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )} */}
                  {editUserId === null && findFeildContactType(4) && (
                    <InputField
                      disabled={isEdit && selectedUser?.id}
                      placeholder='Password'
                      inputlabel='Password'
                      type='password'
                      className={[classes.gridWrapper.phonenumber]}
                      id='password'
                      name='password'
                      value={password}
                      showRequired={true}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      validators={[
                        "required",
                        "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                        "matchRegexp:^.{0,50}$",
                      ]}
                      errorMessages={checkPasswordErrMsg(4, "pass")}
                    />
                  )}
                  {editUserId === null && findFeildContactType(4) && (
                    <InputField
                      disabled={isEdit && selectedUser?.id}
                      placeholder='Verify Password'
                      inputlabel='Verify Password'
                      type='password'
                      className={[classes.gridWrapper.phonenumber]}
                      id='verifypassword'
                      name='verifypassword'
                      value={repeatPassword}
                      onChange={e => {
                        setRepeatPassword(e.target.value);
                      }}
                      showRequired={true}
                      validators={[
                        "required",
                        "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$",
                        "matchRegexp:^.{0,50}$",
                      ]}
                      errorMessages={checkPasswordErrMsg(4, "veri")}
                    />
                  )}
                  {/* <div className="addressCheckbox">
                    <span
                      className="dateCheckbox"
                      style={{ marginTop: "12px" }}>
                      <CustomCheckbox
                        className={classes.checkBoxFont}
                        checkedId={"checked"}
                      />
                      Let End User Create Their Own Password
                    </span>
                  </div> */}
                </div>

                {showFeatureImage && (
                  <SelectCompanyImagePopUp
                    closePopup={() => setShowFeatureImage(false)}
                    openPopup={showFeatureImage}
                    title={"Upload profile image"}
                    addProfileImage={value => uploadProfileImage(value)}
                  />
                )}
                {/* {selectedUser?.id && ( */}
                <span className='saveNNestBtnWrapper'>
                  {/* <Button
                      variant='outlined'
                      onClick={() => {
                        setExpanded(false);
                        setExpanded4(true);
                      }}>
                      Skip
                    </Button> */}
                  <span />
                  <Button disabled={isEdit && selectedUser?.id} type='submit'>
                    Save
                  </Button>
                </span>
                {/* )} */}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </ValidatorForm>
      {/* {findModuleContact(2) && editUserId && ( */}
      <div className='contactWrapper contentBody addEnvBtn enduserMainWrapper'>
        {!expanded4 ? (
          isAddNew && listAddressData.length > 0 ? (
            <span className='green-tick-svg'>
              <SVGIcons.GreenTick />
            </span>
          ) : (
            !isAddNew && (
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setExpanded4(true);
                  setIsNewAddress(true);
                  setIsEditAdr(false);
                }}>
                <BsPlus /> New Address
              </Button>
            )
          )
        ) : (
          <>
            {expanded4 && (
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setIsNewAddress(true);
                  setIsEditAdr(false);
                }}>
                <BsPlus /> New Address
              </Button>
            )}
          </>
        )}

        {(!listAddressData || listAddressData?.length === 0) &&
          !expanded4 &&
          isAddNew && (
            <span className='startBtnWrapper'>
              <Button
                color='primary'
                variant='outlined'
                disabled={!selectedUser?.id}
                onClick={() => {
                  setExpanded4(true);
                }}>
                Start
              </Button>
            </span>
          )}
        <Accordion
          square
          expanded={expanded4}
          disabled={!selectedUser?.id}
          onChange={() => setExpanded4(!expanded4)}>
          <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
            <Typography className={classes.mainHeading}>
              <img src={downarrow} alt='' className={classes.headingIcon} />
              Address
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordianBody}>
            <div className='addressGirdWrapper fr-view'>
              <SoftwareGridFilter
                search={addressSearch}
                setSearch={setAddressSearch}
                placeholder={"Search"}
              />
              <AddressGrid
                userId={editUserId}
                setIsNewAddress={val => setIsNewAddress(val)}
                isNewAddress={isNewAddress}
                countries={countries}
                cities={cities}
                provinces={provinces}
                setIsEditAdr={e => setIsEditAdr(e)}
                isEditAdr={isEditAdr}
                listAddressData={tempAddressData}
                setListAddressData={setListAddressData}
                loadAddress={loadAddress}
                isLoading={isLoading}
                setLoading={e => setLoading(e)}
              />
              {listAddressData?.length > 0 && (
                <span className='saveNNestBtnWrapper'>
                  {isAddNew && (
                    <Button
                      variant='outlined'
                      className='skip-button'
                      onClick={() => {
                        setExpanded4(false);
                        setExpanded3(true);
                      }}>
                      Skip
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      setExpanded4(false);
                      setExpanded3(true);
                    }}>
                    {isAddNew ? "Next" : "Save"}
                  </Button>
                </span>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* )} */}
      {/* {findModuleContact(1) && editUserId && ( */}
      <div className='contactWrapper contentBody enduserMainWrapper'>
        {!expanded3 ? (
          isAddNew && envWithDeviceCount.length > 0 ? (
            <span
              className='addEnvBtn'
              style={{
                position: "unset",
              }}>
              <span className='green-tick-svg'>
                <SVGIcons.GreenTick />
              </span>
            </span>
          ) : (
            !isAddNew && (
              <span
                className='addEnvBtn'
                style={{
                  position: "unset",
                }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setExpanded3(true);
                    onClickAddNewEnv();
                  }}>
                  <BsPlus /> New Environment
                </Button>
              </span>
            )
          )
        ) : (
          <>
            {expanded3 && (
              <span
                className='addEnvBtn'
                style={{
                  position: "unset",
                }}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => onClickAddNewEnv()}>
                  <BsPlus /> New Environment
                </Button>
              </span>
            )}
          </>
        )}

        {envWithDeviceCount?.length === 0 && !expanded3 && isAddNew && (
          <span className='startBtnWrapper'>
            <Button
              color='primary'
              variant='outlined'
              disabled={listAddressData?.length === 0}
              onClick={() => setExpanded3(true)}>
              Start
            </Button>
          </span>
        )}
        <Accordion
          square
          expanded={expanded3}
          disabled={!selectedUser?.id && listAddressData?.length === 0}
          onChange={() => setExpanded3(!expanded3)}>
          <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
            <Typography className={classes.mainHeading}>
              <img src={downarrow} alt='' className={classes.headingIcon} />{" "}
              Environments (OFish Spaces)
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordianBody}>
            <div className='fr-view'>
              <div className='environmentSearchBar'>
                <SoftwareGridFilter
                  search={envSearch}
                  setSearch={setEnvSearch}
                  placeholder={"Search"}
                />
              </div>
              <div className='environmentList endUserEnvList'>
                <NewEnvironmentGrid
                  userId={editUserId}
                  loadUserEnv={() => loadUserEnv(editUserId)}
                  setLoading={v => setLoading(v)}
                  userAddress={address}
                  setIsNewEnv={() => setIsModal(false)}
                  isNewEnv={isModal}
                  envList={envList}
                  tempEnvData={tempEnvData}
                  onClickAddNewEnv={onClickAddNewEnv}
                />
              </div>
              {envWithDeviceCount?.length > 0 && (
                <span className='saveNNestBtnWrapper'>
                  {isAddNew && (
                    <Button
                      variant='outlined'
                      className='skip-button'
                      onClick={() => {
                        setExpanded3(false);
                        setExpanded2(true);
                      }}>
                      Skip
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      setExpanded3(false);
                      setExpanded2(true);
                    }}>
                    {isAddNew ? "Next" : "Close"}
                  </Button>
                </span>
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        {/* {isModal && (
              <AddNewEnvironmentpopup
                openPopup={isModal}
                closePopup={() => setIsModal(false)}
                userId={editUserId}
                loadUserEnv={() => loadUserEnv(editUserId)}
              />
            )} */}
      </div>
      {/* )} */}
      <div className='contactWrapper contentBody addEnvBtn enduserMainWrapper familyFriendAccordion'>
        {/* <Button color='primary' variant='contained' onClick={() => {}}>
            <BsPlus /> New Users
          </Button> */}
        {!expanded2 && isAddNew && listFnFData.length > 0 && (
          <span className='green-tick-svg'>
            <SVGIcons.GreenTick />
          </span>
        )}

        {(!listFnFData || listFnFData?.length === 0) &&
          !expanded2 &&
          isAddNew && (
            <span className='startBtnWrapper'>
              <Button
                color='primary'
                variant='outlined'
                disabled={envWithDeviceCount?.length === 0}
                onClick={() => {
                  setExpanded2(true);
                }}>
                Start
              </Button>
            </span>
          )}

        {!expanded2 && !isAddNew && (
          <Button
            color='primary'
            variant='contained'
            onClick={() => setExpanded2(true)}>
            <BsPlus /> New Users
          </Button>
        )}
        <Accordion
          square
          expanded={expanded2}
          disabled={!selectedUser?.id && envWithDeviceCount?.length === 0}
          onChange={() => setExpanded2(!expanded2)}>
          <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
            <Typography className={classes.mainHeading}>
              <img src={downarrow} alt='' className={classes.headingIcon} />{" "}
              Family &amp; Friends
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordianBody}>
            <div className='environmentSearchBar'>
              <SoftwareGridFilter
                search={friendSearch}
                setSearch={setFriendSearch}
                placeholder={"Search"}
              />
            </div>
            <div className='addressGirdWrapper'>
              <FriendFamilyGrid
                userId={editUserId}
                listFnFData={tempFriendData}
                setListFnFData={setListFnFData}
                loadFFGridData={loadFFGridData}
              />
              {listFnFData?.length > 0 && (
                <span className='saveNNestBtnWrapper'>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setExpanded2(false);
                    }}>
                    Finish
                  </Button>
                </span>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {isPasswordModal && (
        <PasswordResetPopup
          openPopup={isPasswordModal}
          closePopup={() => setIsPasswordModal(false)}
          userEmail={email}
        />
      )}
    </div>
  );
}
