import React from "react";
import SwipeableViews from "react-swipeable-views";
import { Box, Button, MobileStepper, useTheme } from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const imagesStub = [
  {
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80",
  },
  {
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  },
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ImageAutoPlaySwipeable = ({ images = [] }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = step => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const autoPlayImages = images.length > 0 ? images : imagesStub;

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents>
        {autoPlayImages.map((step, index) => (
          <div
            key={step.label}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component='img'
                sx={{
                  maxWidth: 286,
                  maxHeight: 255,
                  display: "block",
                  overflow: "hidden",
                  // maxWidth: 400,
                  // width: "100%"
                }}
                src={step.imgPath}
                alt={"images"}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={autoPlayImages.length}
        position='static'
        activeStep={activeStep}
        style={{
          background: "transparent",
          marginTop: "30px",
        }}
        nextButton={
          <Button
            size='small'
            onClick={handleNext}
            disabled={activeStep === images.length - 1}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </Box>
  );
};

export { ImageAutoPlaySwipeable };
