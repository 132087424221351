import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import queryString from "query-string";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import Dashbard from "../Dashbard";
import { NewEventForm } from "./NewEventForm";
import Attendance from "./Attendance";
import Activity from "./Activity";
import Data from "./Data";
import Notes from "./Notes";

const NewEvent = () => {
  const history = useHistory();
  const location = useLocation();
  const selectedEvent = queryString.parse(location.search);

  const [eventHeading, setEventHeading] = useState("Event Name");
  var eventId = parseInt(window.location.href.split("?id=")[1]);

  return (
    <Dashbard>
      <div className='body-content generalTabsEvents'>
        <div className='contentHeading'>
          <h1>{eventHeading}</h1>
          <div
            style={{
              position: "absolute",
              top: "40px"
            }}>
            <Button
              color='primary'
              variant='text'
              onClick={() => history.push("/dashboard-events")}>
              <ArrowBackIos style={{ fontSize: "14px" }} />
              {`Back to All Events`}
            </Button>
          </div>
        </div>

        <div className='tab-navigator' style={{ marginTop: "20px" }}>
          {eventId ? (
            <TopTabNavigator
              tabs={[
                {
                  id: 1,
                  title: "Overview",
                  component: (
                    <NewEventForm
                      activeEventId={selectedEvent?.id}
                      onEventName={name => {
                        setEventHeading(name);
                      }}
                    />
                  )
                },
                {
                  id: 2,
                  title: "Attendance",
                  component: <Attendance />
                },
                {
                  id: 3,
                  title: "Activity",
                  component: <Activity />
                },
                {
                  id: 4,
                  title: "Date",
                  component: <Data />
                },
                {
                  id: 5,
                  title: "Notes",
                  component: <Notes />
                }
              ]}
            />
          ) : (
            <TopTabNavigator
              tabs={[
                {
                  id: 1,
                  title: "Overview",
                  component: (
                    <NewEventForm
                      onEventName={name => {
                        setEventHeading(name);
                      }}
                    />
                  )
                }
              ]}
            />
          )}
        </div>
      </div>
    </Dashbard>
  );
};

export { NewEvent };
