import React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EditIcon, SortIcon } from "../../../LeftNav/SvgIcons";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  gridTabel: {
    margin: "20px 0px",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  gridHeaderInnerCenter: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  gridBodyInnerCenter: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "16px",
    paddingBottom: "16px",
    textAlign: "center",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  gridIconCells: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    "& span": {
      cursor: "pointer",
    },
  },
}));

const LeadGenerationGrid = ({ onOrderBy, order, LeadsData }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Paper>
      <Table className={classes.gridTabel}>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              First Name
              <TableSortLabel
                active={order?.orderByKey === "FirstName"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "FirstName",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Last Name
              <TableSortLabel
                active={order?.orderByKey === "LastName"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "LastName",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
              Email
              <TableSortLabel
                active={order?.orderByKey === "Email"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Email",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
              Created On
              <TableSortLabel
                active={order?.orderByKey === "CreatedOn"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CreatedOn",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
              Platform
              <TableSortLabel
                active={order?.orderByKey === "Platform"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Platform",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            {/* <TableCell className={classes.gridHeaderInnerCenter}>
              QUICK ACTIONS
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {LeadsData?.length > 0 &&
            LeadsData?.map((item, index) => {
              return (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell className={classes.gridBodyInner}>
                    {item.firstName ? item.firstName : "--"}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.lastName ? item.lastName : "--"} 
                  </TableCell>
                  <TableCell className={classes.gridBodyInnerCenter}>
                    {item.email ? item.email : "--"}
                  </TableCell>
                  <TableCell className={classes.gridBodyInnerCenter}>
                    {item.createdOn
                      ? moment(item.createdOn).format("DD-MM-YYYY")
                      : "--"}
                  </TableCell>
                  <TableCell className={classes.gridBodyInnerCenter}>
                    {item.platform ?? "--"}
                  </TableCell>
                  {/* <TableCell className={classes.gridIconCells}>
                    <span 
                     onClick={()=> history.push(`/lead-generation/${item.id}`)}
                    >
                      <EditIcon />
                    </span>
                  </TableCell> */}
                </TableRow>
              );
            })}
          {LeadsData?.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  No Leads Available.
                  {/* <Button
                    // onClick={() => history.push("/new-media-release")}]
                    >
                    create a Lead Generation
                  </Button> */}
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default LeadGenerationGrid;
