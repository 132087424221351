import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {
  LogHeader,
  MainHeading,
} from "../../../components/common/Header/index";
import { accountActions } from "../../../store/accounts/accountActions";
import EventCalendar from "../../../components/common/EventCalendar/EventCalendar";

function LogCalendar() {
  document.title = "OFish - Events";

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountActions.onAddUserSession(window.location.href));
  }, [dispatch]);

  const redirect = () => {
    history.push("/events");
  };

  return (
    <div className='calendarWrapper'>
      <LogHeader />
      <MainHeading
        headerSubHeading='Events'
        icon={<KeyboardArrowLeftIcon fontSize='medium' />}
        backLink='Back to Events Home'
        backNavHandler={() => redirect()}>
        <div className='publicBody calendarInnerWrapper'>
          <EventCalendar />
        </div>
      </MainHeading>
    </div>
  );
}

export default LogCalendar;
