import React from "react";
import { Button } from "@material-ui/core";
import Dashbard from "../Dashbard";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import NewCollectionOveriew from "./NewCollectionCategoryOveriew";


function NewCollectionCategory(props) {
  return (
    <Dashbard>
      <div className="body-content">
        <ValidatorForm ref={React.useRef("form")}>
          {/* <div className="contentHeading">
            <h1>New Collection Category</h1>

            <div className="dashboardNav">
              <Button
                color="primary"
                variant="contained"
                className="dashboardButtons">
                Save Category
              </Button>
            </div>
          </div> */}
          <div className="collectionCategoryInner">
            <NewCollectionOveriew />
          </div>
        </ValidatorForm>
      </div>
    </Dashbard>
  );
}

export default NewCollectionCategory;
