import React from 'react'
import { ScienceFillo } from "../SpaProduct/SvgIcons";
import { useSelector } from 'react-redux';

export const ChemicalTestingBanner = ({ deviceId, isUpdated }) => {
  const {chemicalTestStats } = useSelector((state) => state.endUserReducer);

  return (
    <div className="watertratmentContainer">
    <div className="waterTreatmeantDetails replacementWaterLife">
      <ScienceFillo />
      <span className="daysWaterTreatment">
        {chemicalTestStats?.lastTestDays  ? chemicalTestStats?.lastTestDays + " Days": "-- Days"}<br></br>
        <i>since last test</i>
      </span>

      <span className="daysWaterRecommended">
        Recommended next test date:<br></br>
        <i>
          {chemicalTestStats?.daysRemamingInNextTest ? chemicalTestStats?.daysRemamingInNextTest + " days away" :"-- days away"}<span> |</span> { chemicalTestStats?.recommendedTestDate ? chemicalTestStats?.recommendedTestDate : "September 1, 2021"}
        </i>
      </span>
    </div>
  </div>
  )
}
