import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const ActionButtonXL = ({ name, description, onButtonClick = () => {} }) => {
  return (
    <Grid item xs={5} className='mainGrid'>
      <div className='dataFieldmainWrapper innerContentMainWrapper'>
        <div className='dataFieldName'>
          <h4>{name}</h4>
          <p className='innerContentHeading action-content-desc'>
            {description}
          </p>
        </div>

        <div style={{ display: "flex" }}>
          <Button color='primary' variant='contained' onClick={onButtonClick}>
            View
          </Button>
        </div>
      </div>
    </Grid>
  );
};

const ActionButtonAddXL = ({ onButtonClick = () => {} }) => {
  return (
    <Grid item xs={5} className='mainGrid'>
      <div
        style={{
          display: "flex",
          flex: "auto",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
        <Button
          color='primary'
          variant='contained'
          onClick={onButtonClick}
          startIcon={<Add />}>
          New template
        </Button>
      </div>
    </Grid>
  );
};

export { ActionButtonXL, ActionButtonAddXL };
