import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import queryString from "query-string";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/common/Loader";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { useLocation } from "react-router-dom";
import DeviceBox from "./DeviceBox";
import DeviceOverview from "./RequestProduct/DeviceOverview";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { toast } from "react-toastify";
import SpaProduct from "../SpaProduct/spaOverview";
import ChemicalTesting from "./ChemicalTesting";
import SpaReporting from "./SpaReporting";
import SpaDeviceSetting from "./SpaDeviceSetting";
import ProductOwnedFilter from "./ProductOwnedFilter";
import {
  ProductOwnedGridView,
  ProductOwnedListView,
} from "../../LeftNav/SvgIcons";
import { SectionDivider } from "../../../../components/common";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { FilterLife } from "./FilterLife";
import { WaterLife } from "./WaterLife";
import { SpaSettings } from "./SpaSetting";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    position: "relative",
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
  },
  accordianBody: {
    padding: "28px 20px 33px 20px !important",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export default function ProductsOwner({ isActive, isBusinessCustomers }) {

  const {
    userDevices,
    editUserName,
    filterLifeStats,
    chemicalTestStats , 
    waterLifeStats
  } = useSelector((state) => state.endUserReducer);

  const [isProductBox, setIsProductBox] = useState(true);
  const [isProductDetail, setIsProductDetail] = useState(false);
  const [isSpaCollection, setIsSpaCollection] = useState(false);
  const [spaProduct, setSpaProduct] = useState(false);
  const [productTiles, setProductTiles] = useState(true);
  const [userId, setUserId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedDeviceType, setSelectedDeviceType] = useState("");
  const [isSpaEdit, setIsSpaEdit] = useState(false);
  const [spaDeviceId, setDeviceId] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const [searchDevice, setSearchDevice] = useState("");
  const [spaSetting, setSpaSetting] = useState({
    tempratureControl: "",
    heatMode: "",
    temperatureLock: "",
    maintenanceLock: "",
    filter1Start: "",
    filter1Duration: "",
    filter2Start: "",
    filter2Duration: "",
  });

  const showSpaProducts = (deviceId) => {
    setProductTiles(false);
    setIsSpaCollection(true);
    setDeviceId(deviceId);
  };
  const spaProductHandler = () => {
    setIsSpaCollection(false);
    setSpaProduct(true);
    setActiveTab("1");
  };

  const spaCollectionHandler = () => {
    setIsSpaCollection(true);
    setSpaProduct(false);
  };

  const hideSpaProducts = () => {
    setProductTiles(true);
    setSpaProduct(false);
    setIsSpaCollection(false);
    loadUserDevices(selectedUser.id);
  };
  const selectedUser = queryString.parse(location.search);
  useEffect(() => {
    if (isActive && !isBusinessCustomers) {
      if (selectedUser?.id) {
        setUserId(selectedUser.id);
        loadUserDevices(selectedUser.id);
      } else {
        setUserId(null);
      }
    }
    if (isActive && isBusinessCustomers) {
      if (selectedUser?.id) {
        setUserId(selectedUser.id);
        loadCompanyDevices(selectedUser.id);
      } else {
        setUserId(null);
      }
    }
    if(isActive) {
      setIsProductDetail(false);
      setIsSpaCollection(false);
      setSpaProduct(false);
      setProductTiles(true);
      setIsProductBox(true)
    }
  }, [location, isActive]);
  /**
   * This effect runs on every `spaDeviceId` change
   * and fetches stats for `waterLife`, `filterLife` and `chemicalTesting`
   */
  useEffect(function getWaterTreatmentStats() {
    if (spaDeviceId) {
      dispatch(endUserActions.onGetFilterLifeStats(spaDeviceId));
      dispatch(endUserActions.onGetChemicalTestingStats(spaDeviceId));
      dispatch(endUserActions.onGetWaterLifeStats(spaDeviceId));
    }
  }, [spaDeviceId, dispatch]);
  

  
  const loadUserDevices = (userId) => {
    setLoading(true);
    dispatch(endUserActions.onGetUserDevices(userId)).then((data) => {
      setLoading(false);
    });
  };

  const loadCompanyDevices = (userId) => {
    setLoading(true);
    dispatch(endUserActions.onGetCompanyDevices(Number(userId))).then((data) => {
      setLoading(false);
    });
  };

  const loadUserNewDevices = (userId) => {
    setLoading(true);
    dispatch(endUserActions.onGetUserDevices(userId)).then((data) => {
      if (data && !data.success) {
        toast.error(data.message);
      }
      setLoading(false);
    });
  };

  const showProductDetail = (deviceId, deviceType) => {
    setSelectedDevice(deviceId);
    setIsProductBox(false);
    setSelectedDeviceType(deviceType);
    setIsProductDetail(true);
  };

  const hideProductDetail = () => {
    setSelectedDevice(null);
    setIsProductBox(true);
    setIsProductDetail(false);
    loadUserDevices(selectedUser.id);
  };
  const getSpaDeviceInfo = (deviceId) => {
    setLoading(true);
    dispatch(endUserActions.onGetSpaInfo(deviceId)).then((data) => {
      setLoading(false);
      if (data?.success) {
        if (data?.data?.spaSettings.length > 0) {
          dispatch(
            endUserActions.onChange(
              "spaDeviceSetting",
              data.data.spaSettings[0]
            )
          );
        } else {
          dispatch(endUserActions.onChange("spaDeviceSetting", ""));
        }
      }
    });
  };
  const onSaveSpaSettings = (activeTab) => {
    if (activeTab === "4") {
      const spaSettingData = {
        filter1Start: spaSetting.filter1Start,
        filter1Duration: spaSetting.filter1Duration,
        filter2Start: spaSetting.filter2Start,
        filter2Duration: spaSetting.filter2Duration,
        deviceId: spaDeviceId,
      };
      const tempModal = {
        deviceId: spaDeviceId,
        temperatureControl: spaSetting?.tempratureControl,
        heatMode: spaSetting?.heatMode,
        isTemperatureLock: spaSetting?.temperatureLock,
        isMaintenanceLock: spaSetting?.maintenanceLock,
      };
      setLoading(true);
      dispatch(endUserActions.onSaveSpaSettings(spaSettingData)).then(
        (data) => {
          if (data && !data.success) {
            toast.error(data.message);
          }
          setLoading(false);
          getSpaDeviceInfo(spaDeviceId);
        }
      );
      dispatch(endUserActions.onUpdataTempSetting(tempModal));
    } else return;
  };

  const filterDevices = userDevices?.filter((device) =>
   device.name.toLowerCase().includes(searchDevice.toLocaleLowerCase())
  );
  return(
    <div>
      {isLoading ? <Loader /> :  null}
      <ValidatorForm ref={React.useRef("form")}>
        {productTiles && (
          <>
            <div className="contactWrapper contentBody gridBody">
              {isProductBox && (
                <div>
                  <div className={classes.accordianBody}>
                    <Grid
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <ProductOwnedFilter setSearchDevice={setSearchDevice} />
                      <ProductOwnedGridView />
                      <ProductOwnedListView />
                    </Grid>
                    <div style={{ padding: "28px 0px 16px" }}>
                      <SectionDivider />
                    </div>
                    <div className="fr-view">
                      <Grid container spacing={3} className="overviewWrapper">
                        {filterDevices &&
                          filterDevices.length > 0 &&
                          filterDevices.map((device, index) => {
                            return (
                              <Grid
                                key={index}
                                item
                                xs={4}
                                className={classes.gridWrapper}
                              >
                                <DeviceBox
                                  deviceData={device}
                                  productDetail={() => {
                                    device.productType.substring(0, 3) === "SPA"
                                      ? showSpaProducts(device.id)
                                      : showProductDetail(
                                          device.id,
                                          device.productType
                                        );
                                  }}
                                  loadData={() => loadUserNewDevices(userId)}
                                  startLoading={() => setLoading(true)}
                                  stopLoading={() => setLoading(false)}
                                  userId={userId}
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                      {
                        filterDevices?.length === 0 || !filterDevices ?
                          <Grid container justifyContent="center" alignItems="center">
                            <span>No Device Registered Please Add a New Device</span>
                          </Grid> : null
                      }
                    </div>
                  </div>
                </div>
              )}
              {isProductDetail && (
                <DeviceOverview
                  hideProductDetail={() => hideProductDetail()}
                  selectedDevice={selectedDevice}
                  deviceType={selectedDeviceType}
                  userId={userId}
                />
              )}
            </div>
          </>
        )}

        {spaProduct && (
          <>
            <div className="gap" />
            <div className=" spaContentBody spaWrapper">
              <div className="spaHeader">
                <h1>
                  {`${editUserName}${" Spa"}`}{" "}
                  <div>
                    {activeTab === "2" || activeTab === "4" ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onSaveSpaSettings(activeTab)}
                      >
                        Save
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </h1>
                <span className="spackBackBtn">
                  <Button
                    color="primary"
                    onClick={spaCollectionHandler}
                    className="backbtn"
                  >
                    <ArrowBackIosIcon /> Back to Spa Overview
                  </Button>
                </span>
              </div>
              <div className="spaBody">
                <div className="tab-navigator SpaTabs">
                  <TopTabNavigator
                    getActiveTabId={(tabId) => setActiveTab(`${tabId}`)}
                    value={activeTab}
                    defaultTabId={activeTab}
                    className={"spa-tab-"}
                    tabs={[
                      {
                        id: "Ownership",
                        title: "SPA Ownership",
                        disabled: true,
                        component: "",
                      },
                      {
                        id: "1",
                        title: "Overview",
                        component: (
                          <SpaProduct
                          
                            isActive={true}
                            activeTab={activeTab}
                            onClickMange={(e) => {
                              setActiveTab("1");
                            }}
                          />
                        ),
                      },
                      {
                        id: "2",
                        title: "water treatment",
                        component: (
                          <div className="overviewWatertMainContent">
                            <div className="overviewWatertreatment waterTreatmentTabSpacing">
                              <h1>Chemical Testing</h1>
                              <ChemicalTesting
                                activeTab={activeTab}
                                deviceId={spaDeviceId}
                              />
                            </div>
                            <div className="overviewWatertreatment waterTreatmentTabSpacing">
                              <FilterLife
                                activeTab={activeTab}
                                deviceId={spaDeviceId}
                              />
                            </div>
                            <div className="overviewWatertreatment waterTreatmentTabSpacing">
                              <WaterLife
                                activeTab={activeTab}
                                deviceId={spaDeviceId}
                              />
                            </div>
                          </div>
                        ),
                      },
                      {
                        id: "3",
                        title: "reporting",
                        component: <SpaReporting />,
                      },
                      {
                        id: "4",
                        title: "spa settings",
                        component: (
                          <div className="overviewWatertMainContent">
                            <div className="overviewWatertreatment">
                              <SpaSettings
                                spaSetting={spaSetting}
                                setSpaSetting={setSpaSetting}
                                activeTab={activeTab}
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {isSpaCollection && (
          <div className="contactWrapper contentBody gridBody">
            <SpaDeviceSetting
              isSpaEdit={isSpaEdit}
              setIsSpaEdit={setIsSpaEdit}
              spaProductHandler={spaProductHandler}
              hideSpaProducts={hideSpaProducts}
              deviceId={spaDeviceId}
            />
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
