import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import queryString from "query-string";
import Dashbard from "../../Dashbard";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import { NewPriceOverview } from "./NewPriceOverview";
import { PreviewPriceList } from "./PreviewPriceList";
import { useDispatch } from "react-redux";
import { productInventoryActions } from "../../../../../store/product/productInventoryAction";
import Loader from "../../../../../components/common/Loader";

const NewPriceList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const pricingList = queryString.parse(location.search);

  const [activeTabId, setActiveTabId] = useState("1");
  const [priceList, setPriceList] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    if (pricingList?.id) {
      setLoading(true);
      dispatch(
        productInventoryActions.onGetPriceListById(pricingList?.id)
      ).then(data => {
        setLoading(false);
        if (!data) return;
        const { data: pl } = data;
        if (pl) {
          setPriceList(pl);
        }
      });
    }

    return () => {
      setPriceList(null);
    };
  }, [dispatch, location, pricingList?.id]);

  useEffect(() => {
    if (previewData) setPreviewData(previewData => previewData);
  }, [activeTabId, previewData]);

  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>
            {priceList?.priceListNameVisible ||
              priceList?.priceListTitle ||
              "New Price List"}
          </h1>
          <div
            style={{
              position: "absolute",
              top: "40px",
            }}>
            <Button
              color='primary'
              variant='text'
              onClick={() => history.push("/price-list")}>
              <ArrowBackIos style={{ fontSize: "14px" }} />
              {`Back to All Price Lists`}
            </Button>
          </div>
        </div>
        <TopTabNavigator
          getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
          tabs={[
            {
              id: "1",
              title: "Overview",
              component: (
                <NewPriceOverview
                  activeProductId={pricingList?.id}
                  isActive={activeTabId === "1"}
                  plData={priceList}
                  onplDataChanged={pdDataChange => {
                    setPreviewData(pdDataChange);
                  }}
                />
              ),
            },
            {
              id: "2",
              title: "Preview",
              component: (
                <PreviewPriceList
                  isActive={activeTabId === "2"}
                  priceListId={pricingList?.id}
                  plData={previewData}
                />
              ),
            },
          ]}
        />
      </div>
    </Dashbard>
  );
};

export { NewPriceList };
