import React, {useState} from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function MediaDetailCard({
  heading,
  subheading,
  mediaDescription,
  category,
  skuNumber,
  color,
  available
}) {

  const [isPanelOpen, setIsPanelOpen] = useState(false)

  return (
    <div className={`mediaCardWrapper ${isPanelOpen ? "panelExpended" : "panelCollapsed"}`}>
       <span className="mediaChavron">
         {isPanelOpen ? <ExpandMoreIcon onClick={()=> setIsPanelOpen(!isPanelOpen)}/> : <ExpandLessIcon onClick={()=> setIsPanelOpen(!isPanelOpen)}/>}
         
         </span>
      <h2>{heading}</h2>
      {isPanelOpen && <>
        <h3>{subheading}</h3>
      <span className="mediaCardDesc">{mediaDescription}</span>
      <span className="mediaCardPropsWrapper">
        <span>Category </span>
        <span>{category}</span>
      </span>
      
  

      {/* <span className="mediaCardPropsWrapper">
            <span>SKU </span>
                <span>{skuNumber}</span>
            </span>

            <span className="mediaCardPropsWrapper">
            <span>Color</span>
                <span>{color}</span>
            </span> */}

      <span className="mediaCardPropsWrapper">
        <span> Available </span>
        <span>{available}</span>
      </span> 
      </>}
    </div>
  );
}

export default MediaDetailCard;
