import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getAllBrands() {
  return axios
    .post(endpoints.productCategories.getAllBrands)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getAllProductCategories({
  pageNumber,
  noOfRecoreds,
  orderByKey,
  orderByVal,
  filters,
}) {
  return axios
    .post(endpoints.productCategories.getAllProductCategories, {
      ...filters,
      pagination: {
        pageSize: noOfRecoreds,
        pageNo: pageNumber,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getAllProductSubCategories(parentCategoryId) {
  return axios
    .post(endpoints.productCategories.getAllProductSubCategories, {
      productCategoryId: parentCategoryId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getAllProductsSubCategories(parentCategoryIds) {
  return axios
    .post(
      endpoints.productCategories.getProductSubCategoryByCategoryIds,
      parentCategoryIds
    )
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProductCategoryById(id) {
  return axios
    .post(endpoints.productCategories.getProductCategoryById, {
      productCategoryId: id,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function createProductCategory(productCategory) {
  return axios
    .post(endpoints.productCategories.addProductCategory, productCategory)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteProductCategories(ids) {
  return axios
    .post(endpoints.productCategories.deleteProductCategories, ids)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProductByCategoryId(id) {
  return axios
    .post(endpoints.productCategories.getProductsByCategoryId, {
      productCategoryId: id,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

export const productCategoriesApi = {
  getAllBrands: getAllBrands,
  getAllProductCategories: getAllProductCategories,
  getAllProductSubCategories: getAllProductSubCategories,
  getAllProductsSubCategories: getAllProductsSubCategories,
  getProductCategoryById: getProductCategoryById,
  createProductCategory: createProductCategory,
  deleteProductCategories: deleteProductCategories,
  getProductsByCategoryId: getProductByCategoryId,
};
