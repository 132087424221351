import React from "react";
import { Button } from "@material-ui/core";
import { PopupDialog } from "./PopupDialog";
import { ReactComponent as Cross } from "../../resources/images/Close.svg";

const DeletePopup = ({
  deletemessage,
  subheading,
  cancelbtn,
  deletebtn,
  oncloseaction,
  isvisible,
  heading,
  maxwidth,
}) => {
  return (
    <PopupDialog
      visible={isvisible}
      onClose={oncloseaction}
      maxWidth={maxwidth}>
      <div className='newContactPopupWrapper deletePopupMainWrapper'>
        <div className='newContactPopupInnerWrapper'>
          <span className='innerHeading'>{heading}</span>
          <Cross
            style={{
              cursor: "pointer",
            }}
            onClick={oncloseaction}
          />
        </div>
        <div className='deleteContactBody'>
          {deletemessage}
          <span className='deleteContactSubheading'>{subheading}</span>
          <span className='deleteContactActions'>
            <Button onClick={cancelbtn}>Cancel</Button>
            <Button onClick={deletebtn}>Delete</Button>
          </span>
        </div>
      </div>
    </PopupDialog>
  );
};

export { DeletePopup };
