import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: "2rem",
        height: "2rem"
      }
    }
  }
}));

export default function Checkboxes({ onClick, checked, label }) {
  // const [checked, setChecked] = React.useState(true);

  const classes = useStyles();

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <div>
      <Checkbox
        defaultChecked
        color="primary"
        inputProps={{ "aria-label": "secondary" }}
        fontSize="large"
        onClick={onClick}
        checked={checked}
      />
      <span className="checkboxLabel">{label} </span>
    </div>
  );
}
