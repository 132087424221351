import React, { useState, useRef } from "react";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import Grid from "@material-ui/core/Grid";
import queryString from "query-string";
import InputField from "../../../../../components/common/InputField";
import Textarea from "../../../../../components/common/Textarea";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Close } from "@material-ui/icons";
import {
  AddressAutoComplete,
  Dropdown,
  PopupDialog,
} from "../../../../../components/common";
import { RedCross, TickMark } from "../../../LeftNav/SvgIcons";
import { Button, Checkbox } from "@material-ui/core";
import CompanyBusinessHours from "./CompanyBusinessHours";
import AttributeDialog from "./AttributeDialog";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import SelectCompanyImagePopUp from "../../EndUser/SelectCompanyImagePopUp";
import { useEffect } from "react";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
  },
}));
const CompanyLoctionForm = ({
  setIsNewAddress,
  editData,
  loadLocation,
  setLoading,
  listUserData,
  productCategories,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [profileImg, setProfileImg] = useState("");
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [isBusinessHour, setIsBusinessHour] = useState(false);
  const [isAttributes, setIsAttributes] = useState(false);
  const selectedData = queryString.parse(location.search);
  const [product, setProduct] = useState(productCategories);
  const [addressDetail, setAddressDetail] = useState({
    address: "",
    city: "",
    state: "",
    postalCode: "",
    countryRegion: "",
    latValue: "",
    lngValue: "",
  });
  const [companyUsers, setCompanyUsers] = useState([]);
  const [companyLocTypes, setCompanyLocTypes] = useState([]);
  const [selectedComLocType, setSelectedComLocType] = useState(1);
  const [locationDetail, setLocationDetail] = useState({
    nickName: "",
    publicName: "",
    mainContactId: null,
    locationBio: "",
    website: "",
    emailAddress: "",
    phoneNo: "",
    isBillingAddress: false,
    isShippingAddress: false,
    isInstallmentEnvironment: false,
    isShowinLocator: false,
    status: 1,
    phoneType: 1,
    productCat: [],
    isAuthorized: false,
    isUnauthorized: false,
    isBlocked: false,
    isElite: false,
  });

  const [businessHours, setBusinessHours] = useState([]);
  useEffect(() => {
    if (editData) {
      setLoading(true);
      dispatch(
        contactsActions.onGetCompanyLocationsById(parseInt(editData?.id))
      ).then(data => {
        setLoading(false);
        if (data && data.success) {
          setLocationDetail({
            nickName: data?.data?.nickName,
            publicName: data?.data?.publicName,
            mainContactId: data?.data?.mainContactId,
            locationBio: data?.data?.locationBio,
            website: data?.data?.website,
            emailAddress: data?.data?.emailAddress,
            phoneNo: data?.data?.phoneNo,
            isBillingAddress: data?.data?.isBillingAddress,
            isShippingAddress: data?.data?.isShippingAddress,
            isInstallmentEnvironment: data?.data?.isInstallmentEnvironment,
            isShowinLocator: data?.data?.isShowinLocator,
            status: data?.data?.status,
            phoneType: data?.data?.phoneType,
            productCat: data?.data?.productCategories,
          });
          setAddressDetail({
            address: data?.data?.locationAddress,
            city: data?.data?.city,
            state: data?.data?.state,
            postalCode: data?.data?.postalCode,
            countryRegion: data?.data?.country,
            latValue: data?.data?.latitude,
            lngValue: data?.data?.longitude,
          });
          setSelectedComLocType(
            data?.data?.type !== null && parseInt(data?.data?.type)
          );
          setProfileImg(data?.data?.image);
          if (data?.data?.companyBusinessHours?.length > 0) {
            setBusinessHours(data?.data?.companyBusinessHours);
          }
        }
      });
    }
  }, [editData]);
  useEffect(() => {
    dispatch(
      contactsActions.onGetCompanyUsers(parseInt(selectedData?.id))
    ).then(data => {
      if (data && data.success) {
        setCompanyUsers(data?.data);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(contactsActions.onGetCompanyLocTypes()).then(data => {
      if (data?.success) {
        setCompanyLocTypes(data?.data);
      }
    });
  }, []);

  const onClose = () => {
    setIsBusinessHour(false);
  };

  const uploadProfileImage = file => {
    setLoading(true);
    dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
      if (data && data.success && data.data) {
        setProfileImg(data.data.url);
        setShowFeatureImage(false);
      }
      setLoading(false);
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    addCompanyLocation();
  };
  const addCompanyLocation = () => {
    setLoading(true);
    if (parseInt(selectedData?.id) > 0) {
      const modal = {
        id: editData ? editData?.id : 0,
        nickName: locationDetail?.nickName,
        publicName: locationDetail?.publicName,
        locationBio: locationDetail?.locationBio,
        locationAddress: addressDetail?.address,
        city: addressDetail?.city,
        state: addressDetail?.state,
        postalCode: addressDetail?.postalCode,
        country: addressDetail?.countryRegion,
        latitude: addressDetail?.latValue,
        longitude: addressDetail?.lngValue,
        website: locationDetail?.website,
        emailAddress: locationDetail?.emailAddress,
        phoneNo: locationDetail?.phoneNo,
        image: profileImg,
        isBillingAddress: locationDetail?.isBillingAddress,
        isShippingAddress: locationDetail?.isShippingAddress,
        isInstallmentEnvironment: locationDetail?.isInstallmentEnvironment,
        isShowinLocator: locationDetail?.isShowinLocator,
        mainContactId: locationDetail?.mainContactId,
        status: 1,
        companyId: parseInt(selectedData?.id),
        companyBusinessHours: businessHours,
        phoneType: locationDetail?.phoneType,
        productCategories: locationDetail?.productCat,
        type: selectedComLocType,
      };
      dispatch(contactsActions.onAddUpdateCompanyLocations(modal)).then(
        data => {
          setLoading(false);
          if (data?.success) {
            loadLocation();
            setIsNewAddress();
            if (editData?.id) {
              toast.success("Company location updated");
            } else {
              toast.success("Company location added");
            }
          }
        }
      );
    }
  };
  return (
    <>
      <ValidatorForm
        ref={useRef("locationForm")}
        onSubmit={handleSubmit}
        className='locationFormWrapper'>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridWrapper}>
            <h2 class='innerHeading'>Location Information</h2>
            <Grid container spacing={2}>
              {/* <Grid item className={classes.gridWrapper}>
                <span className='companyAddressCheckbox'>
                  <Checkbox
                    checked={locationDetail.isBillingAddress}
                    onChange={v => {
                      setLocationDetail({
                        ...locationDetail,
                        isBillingAddress: !locationDetail.isBillingAddress,
                      });
                    }}
                  />
                  No address available
                </span>
              </Grid> */}
              <Grid
                item
                xs={6}
                className={(classes.gridWrapper, "requiredField")}>
                <InputField
                  placeholder='Main Address'
                  inputlabel='Location Nickname'
                  className={classes.gridWrapper}
                  value={locationDetail?.nickName}
                  showRequired={true}
                  onChange={v =>
                    setLocationDetail({
                      ...locationDetail,
                      nickName: v.target.value,
                    })
                  }
                  validators={["required", "matchRegexp:^.{0,50}$"]}
                  errorMessages={[
                    "This field is required",
                    "Maximum length is 50 characters",
                  ]}
                />
              </Grid>

              <Grid item xs={6} className={classes.gridWrapper}>
                <InputField
                  placeholder='Elite Dealer Company Name'
                  inputlabel='Location Name (Public)'
                  className={classes.gridWrapper}
                  value={locationDetail?.publicName}
                  onChange={v =>
                    setLocationDetail({
                      ...locationDetail,
                      publicName: v.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} className={classes.gridWrapper}>
                <Dropdown
                  selectlabel='Main Contact'
                  placeholder='Please Select'
                  value={locationDetail?.mainContactId}
                  required={false}
                  customMenuItems={listUserData?.map(c => {
                    return {
                      id: c.id,
                      name: c.emailAddress,
                    };
                  })}
                  handleChange={({ target }) => {
                    setLocationDetail({
                      ...locationDetail,
                      mainContactId: target.value,
                    });
                  }}
                />
                {/* <InputField
                  placeholder='Select a Main Contact'
                  inputlabel='Main Contact'
                  className={classes.gridWrapper}
                  value={locationDetail?.mainContactId}
                  onChange={v =>
                    setLocationDetail({
                      ...locationDetail,
                      mainContactId: v.target.value,
                    })
                  }
                /> */}
              </Grid>

              <Grid item xs={3} className={classes.gridWrapper}>
                <InputField
                  placeholder='Enter Main Location E-mail Address'
                  inputlabel='Main Location E-mail Address'
                  className={classes.gridWrapper}
                  value={locationDetail?.emailAddress}
                  onChange={v =>
                    setLocationDetail({
                      ...locationDetail,
                      emailAddress: v.target.value,
                    })
                  }
                />
              </Grid>

              <Grid
                item
                xs={3}
                className={(classes.gridWrapper, "changeAction")}>
                <InputField
                  disabled
                  placeholder=''
                  inputlabel='Business Hours'
                  className={classes.gridWrapper}
                />
                <span
                  style={{
                    top: "30px",
                    left: "10px",
                    width: "87%",
                    padding: "14px 10px",
                    background: "white",
                    boxSizing: "border-box",
                  }}
                  onClick={() => setIsBusinessHour(true)}>
                  Set Business Hours
                </span>
              </Grid>
              <Grid item xs={12} className={classes.gridWrapper}>
                <Textarea
                  className='overviewTextarea'
                  placeholder='Location Bio entered here!'
                  textLabel='Location Bio'
                  value={locationDetail?.locationBio}
                  onChange={v =>
                    setLocationDetail({
                      ...locationDetail,
                      locationBio: v.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className='gap' />
        <div className='gap' />
        <h2 class='innerHeading'>Location Address</h2>
        <Grid container spacing={3}>
          <Grid item xs={4} className={(classes.gridWrapper, "requiredField")}>
            <AddressAutoComplete
              autoComId={"popupCompanyLocationByIdInput"}
              address={addressDetail?.address}
              onChange={e => {
                setAddressDetail({
                  ...addressDetail,
                  address: e.target.value,
                  latValue: "",
                  lngValue: "",
                });
              }}
              setPlace={p => {
                let address = "";
                let city = "";
                let state = "";
                let countryRegion = "";
                let postalCode = "";
                let latVal = "";
                let lngVal = "";
                p?.address_components?.forEach(x => {
                  if (x?.types?.includes("route")) {
                    address = x?.long_name;
                  }
                  if (x?.types?.includes("locality")) {
                    city = x?.long_name;
                  }
                  if (x?.types?.includes("administrative_area_level_1")) {
                    state = x?.long_name;
                  }
                  if (x?.types?.includes("country")) {
                    countryRegion = x?.long_name;
                  }
                  if (x?.types?.includes("postal_code")) {
                    postalCode = x?.long_name;
                  }
                });
                if (p?.geometry?.location?.lat()) {
                  latVal = p.geometry.location.lat();
                }
                if (p?.geometry?.location?.lng()) {
                  lngVal = p.geometry.location.lng();
                }
                setAddressDetail({
                  ...addressDetail,
                  address: address,
                  city: city,
                  state: state,
                  countryRegion: countryRegion,
                  postalCode: postalCode,
                  latValue: latVal,
                  lngValue: lngVal,
                });
              }}
            />
          </Grid>
          <Grid item xs={3} className={(classes.gridWrapper, "requiredField")}>
            <InputField
              placeholder=''
              inputlabel='City'
              className={classes.gridWrapper}
              showRequired={true}
              value={addressDetail?.city}
              onChange={v =>
                setAddressDetail({
                  ...addressDetail,
                  city: v.target.value,
                })
              }
              validators={["required", "matchRegexp:^.{0,200}$"]}
              errorMessages={[
                "This field is required",
                "Maximum length is 200 characters",
              ]}
            />
          </Grid>
          <Grid item xs={3} className={(classes.gridWrapper, "requiredField")}>
            <InputField
              placeholder=''
              inputlabel='State'
              className={classes.gridWrapper}
              showRequired={true}
              value={addressDetail?.state}
              onChange={v =>
                setAddressDetail({
                  ...addressDetail,
                  state: v.target.value,
                })
              }
              validators={["required", "matchRegexp:^.{0,200}$"]}
              errorMessages={[
                "This field is required",
                "Maximum length is 200 characters",
              ]}
            />
          </Grid>

          <Grid item xs={2} className={(classes.gridWrapper, "requiredField")}>
            <InputField
              placeholder=' '
              inputlabel='Zip Code'
              className={classes.gridWrapper}
              showRequired={true}
              value={addressDetail?.postalCode}
              onChange={v =>
                setAddressDetail({
                  ...addressDetail,
                  postalCode: v.target.value,
                })
              }
              validators={["required", "matchRegexp:^.{0,200}$"]}
              errorMessages={[
                "This field is required",
                "Maximum length is 200 characters",
              ]}
            />
          </Grid>
        </Grid>

        <div className='gap' />
        <Grid container spacing={3}>
          <Grid item xs={3} className={(classes.gridWrapper, "requiredField")}>
            {/* <Dropdown
              selectlabel='Country'
              placeholder='Please Select'
              value={addressDetail?.countryRegion}
              required={false}
              // showRequired
              // customMenuItems={addressDetail?.map(c => {
              //   return {
              //     id: parseInt(c.id),
              //     name: c.value,
              //   };
              // })}
              handleChange={v =>
                setAddressDetail({
                  ...addressDetail,
                  countryRegion: v.target.value,
                })
              }
            /> */}
            <InputField
              placeholder=''
              inputlabel='Country'
              className={classes.gridWrapper}
              showRequired={true}
              value={addressDetail?.countryRegion}
              onChange={v =>
                setAddressDetail({
                  ...addressDetail,
                  countryRegion: v.target.value,
                })
              }
              validators={["required", "matchRegexp:^.{0,50}$"]}
              errorMessages={[
                "This field is required",
                "Maximum length is 50 characters",
              ]}
            />
          </Grid>

          <Grid item xs={6} className={classes.gridWrapper}>
            <InputField
              placeholder='Enter Main Phone Number'
              inputlabel='Location Phone Number'
              type='number'
              className={classes.input}
              value={locationDetail?.phoneNo}
              onChange={v =>
                setLocationDetail({
                  ...locationDetail,
                  phoneNo: v.target.value,
                })
              }
              inputProps={{ min: 0 }}
            />
          </Grid>

          <Grid item xs={3} className={classes.gridWrapper}>
            <Dropdown
              selectlabel='Phone Number Type'
              placeholder='Please Select'
              value={locationDetail?.phoneType}
              required={false}
              customMenuItems={[
                { id: 1, name: "Mobile" },
                { id: 2, name: "Home" },
              ]}
              handleChange={v =>
                setLocationDetail({
                  ...locationDetail,
                  phoneType: v.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={4} className={classes.gridWrapper}>
            <Dropdown
              selectlabel='Dealer Status'
              placeholder='Please Select'
              value={selectedComLocType}
              required={false}
              showRequired
              customMenuItems={companyLocTypes?.map(c => {
                return {
                  id: c.id,
                  name: c.name,
                };
              })}
              handleChange={({ target }) => {
                setSelectedComLocType(target.value);
              }}
            />
          </Grid>

          {/* <Grid item xs={7} className={classes.gridWrapper}>
            <InputField
              placeholder='Enter a Company Website'
              inputlabel='Location Website'
              className={classes.gridWrapper}
              value={locationDetail?.website}
              onChange={v =>
                setLocationDetail({
                  ...locationDetail,
                  website: v.target.value,
                })
              }
            />
          </Grid> */}
        </Grid>

        <div className='gap' />
        {/* <Grid container spacing={3}>
          <Grid item xs={4} className={classes.gridWrapper}>
            <InputField
              placeholder='Enter Main Phone Number'
              inputlabel='Location Phone Number'
              className={classes.gridWrapper}
              value={locationDetail?.phoneNo}
              onChange={v =>
                setLocationDetail({
                  ...locationDetail,
                  phoneNo: v.target.value,
                })
              }
            />
          </Grid>
        </Grid> */}

        <div className='gap' />
        <Grid spacing={3}>
          <Grid item xs={4} className={classes.gridWrapper}>
            <h3>Address*</h3>
            <p>Select use types of this location: </p>
            <Grid item className={classes.gridWrapper}>
              <span className='companyAddressCheckbox'>
                <Checkbox
                  checked={locationDetail.isBillingAddress}
                  onChange={v => {
                    setLocationDetail({
                      ...locationDetail,
                      isBillingAddress: !locationDetail.isBillingAddress,
                    });
                  }}
                />
                Billing Address
              </span>
              <span className='companyAddressCheckbox'>
                <Checkbox
                  checked={locationDetail.isShippingAddress}
                  onChange={v =>
                    setLocationDetail({
                      ...locationDetail,
                      isShippingAddress: !locationDetail.isShippingAddress,
                    })
                  }
                />
                Shipping Address
              </span>

              {/* <span className='companyAddressCheckbox'>
                <Checkbox
                  checked={locationDetail.isInstallmentEnvironment}
                  onChange={v =>
                    setLocationDetail({
                      ...locationDetail,
                      isInstallmentEnvironment:
                        !locationDetail.isInstallmentEnvironment,
                    })
                  }
                />
                Installation Environment
              </span> */}

              <span className='companyAddressCheckbox'>
                <Checkbox
                  checked={locationDetail.isShowinLocator}
                  onChange={v => {
                    let arr = [];
                    arr = product.map(x => x.id);
                    setLocationDetail({
                      ...locationDetail,
                      productCat: arr,
                      isShowinLocator: !locationDetail.isShowinLocator,
                    });
                  }}
                />
                Show in Locator
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.gridWrapper}>
            <div className='collectionLabelsWrapper'>
              {locationDetail.isShowinLocator &&
                product?.map(x => (
                  <>
                    {locationDetail?.productCat.includes(x?.id) && (
                      <span className='collectionLabels'>
                        {x?.value}{" "}
                        <sapn className='cross'>
                          <Close
                            onClick={() => {
                              let arr = [];
                              arr = locationDetail?.productCat.filter(
                                z => z !== x?.id
                              );
                              setLocationDetail({
                                ...locationDetail,
                                productCat: arr,
                              });
                            }}
                          />
                        </sapn>
                        {/* <i>Dealer Locator</i> */}
                      </span>
                    )}
                  </>
                ))}
            </div>
          </Grid>
          <Grid item xs={8} className={(classes.gridWrapper, "addAttribute")}>
            <h3>
              Attributes{" "}
              <span>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    //setIsAttributes(true)
                  }}>
                  +
                </Button>
              </span>
            </h3>
          </Grid>
          <Grid item xs={12} className={classes.gridWrapper}>
            <p>Choose which attributes apply to this location.</p>
            <div className='collectionLabelsWrapper'>
              <span className='collectionLabels'>
                <i>AAV Resellers: </i> Dealer Locator{" "}
                <sapn className='cross'>
                  <Close />
                </sapn>
              </span>
            </div>
            <p>
              No Attributes have been added to this location. Add Attributes to
              sort this location within filters
            </p>
          </Grid>
        </Grid>
        <span className='thumbIcon tickMarkCross businessAddress locationFormAction '>
          <span
            onClick={() => {
              setIsNewAddress();
            }}>
            <RedCross />
          </span>
          <Button type='submit'>
            <TickMark />
          </Button>
        </span>
        {showFeatureImage && (
          <SelectCompanyImagePopUp
            closePopup={() => setShowFeatureImage(false)}
            openPopup={showFeatureImage}
            title={"Upload profile image"}
            addProfileImage={value => uploadProfileImage(value)}
          />
        )}
      </ValidatorForm>
      {/* <span className='saveNNestBtnWrapper' style={{ marginTop: "38px" }}>
        <Button variant='outlined'>Skip</Button>
        <Button type='submit'>Next</Button>
      </span> */}
      <div className='companyHoursPopup'>
        {isBusinessHour && (
          <PopupDialog
            visible={isBusinessHour}
            onClose={onClose}
            maxWidth='800px'>
            <div className='companyBusinessHours'>
              <div className='newContactPopupInnerWrapper'>
                <span className='innerHeading'>Set Business Hours</span>
                <Close
                  style={{
                    cursor: "pointer",
                    padding: "20px",
                  }}
                  onClick={() => onClose()}
                />
              </div>
              <div className='businessHoursInnerHeading'>
                <ul>
                  <li>Business Hours (Based on Location Time Zone)</li>
                  <li>Closed</li>
                </ul>
              </div>
              <CompanyBusinessHours
                businessHours={businessHours}
                setBusinessHours={e => {
                  setBusinessHours(e);
                }}
                onClose={() => onClose()}
              />
            </div>
          </PopupDialog>
        )}
      </div>

      {isAttributes && (
        <div className='attributesPopup'>
          <PopupDialog
            visible={isAttributes}
            onClose={onClose}
            maxWidth='800px'>
            <div className='attributesPopupWrapper'>
              <div className='newContactPopupInnerWrapper'>
                <span className='innerHeading'>Add Attributes</span>
                <Close
                  style={{
                    cursor: "pointer",
                    padding: "20px",
                  }}
                  onClick={() => setIsAttributes(false)}
                />
              </div>
              <h1>Add Attributes to your Contact</h1>
              <p>
                Select the attributes you want collected with this user and
                continue on to next page to add values to your contact
              </p>
              <AttributeDialog />
            </div>
          </PopupDialog>
        </div>
      )}
    </>
  );
};

export default CompanyLoctionForm;
