import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import Grid from "@material-ui/core/Grid";
import queryString from "query-string";
import Loader from "../../../../components/common/Loader";
import { GridFilterBar } from "./GridFilterBar";
import {
  Dropdown,
  GridSearchFilterBar,
  Pagination,
} from "../../../../components/common";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { SerialsTabProductSerials } from "./SerialsTabProductSerials";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";

const SerialsTab = ({ activeTabId, isActive }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const inventoryProduct = queryString.parse(location.search);

  const [isLoading, setLoading] = useState(false);
  const [isSerialized, setIsSerialized] = useState(1);
  const [viewBlockedSerials, setViewBlockedSerials] = useState(false);

  const [devicesList, setDevicesList] = useState([]);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });

  const [blockedDevicesList, setBlockDevicesList] = useState([]);

  // pagination states
  const [filters, setFilters] = useState({
    name: "",
    categoryId: null,
    dateCreated: null,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "SerialNo",
    orderVal: 0,
  });

  const loadBlockedData = useCallback(() => {
    setLoading(true);
    // const searchDevice = queryString.parse(location.search);
    dispatch(
      productInventoryActions.onGetBlockedSerials({
        pageNumber: page,
        noOfRecoreds: rowsPerPage,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
        filters: filters,
      })
    ).then(data => {
      setBlockDevicesList(data?.data?.data || []);
      if (data.success) {
        setPaginationResponse(data?.data?.pagination);
      } else {
        toast.error("Something went wrong, Please try latter.");
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setLoading(false);
    });
  }, [dispatch, filters, order.orderByKey, order.orderVal, page, rowsPerPage]);

  const loadData = useCallback(() => {
    if (isActive && !viewBlockedSerials) {
      setLoading(true);
      // const searchDevice = queryString.parse(location.search);
      dispatch(
        productInventoryActions.onGetSerializedDevices({
          isSerialized,
          productId: inventoryProduct?.id || 0,
          pageNumber: page,
          noOfRecoreds: rowsPerPage,
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
          filters: filters,
        })
      ).then(data => {
        setDevicesList(data?.data?.data || []);
        if (data.success) {
          setPaginationResponse(data?.data?.pagination);
        } else {
          // toast.error("Something went wrong, Please try latter.");
          setPaginationResponse({
            currentCount: 0,
            totalRecords: 0,
          });
        }
        setLoading(false);
      });
    } else if (isActive && viewBlockedSerials) {
      loadBlockedData();
    }
  }, [
    dispatch,
    filters,
    inventoryProduct?.id,
    isActive,
    isSerialized,
    loadBlockedData,
    order.orderByKey,
    order.orderVal,
    page,
    rowsPerPage,
    viewBlockedSerials,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData, isActive, activeTabId]);

  return (
    <div className={`accordianWrap`}>
      {isLoading ? <Loader /> : null}
      {!viewBlockedSerials ? (
        <>
          <AccordionContainer
            title='Product Serials'
            formButton={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <Button
                  variant='text'
                  style={{
                    color: "#E06145",
                    marginRight: "5px",
                    border: "1px solid #E06145",
                  }}
                  onClick={() => {
                    setPage(1);
                    setViewBlockedSerials(!viewBlockedSerials);
                  }}>
                  Manage Blocked Serials
                </Button>
                <Button
                  color='primary'
                  variant='outlined'
                  className='dashboardButtons'
                  disabled
                  onClick={() => {}}>
                  Receive product
                </Button>
              </div>
            }>
            <div>
              <GridSearchFilterBar
                filterBy={filters}
                onFilterButtonPress={({ name }) =>
                  setFilters({
                    name: name,
                  })
                }
                onClearButtonPress={() =>
                  setFilters({
                    name: "",
                    categoryId: null,
                    dateCreated: null,
                  })
                }
              />
              <SerialsTabProductSerials
                listData={devicesList}
                loadData={() => loadData()}
                order={order}
                onOrderBy={setOrderBy}
              />
              {devicesList?.length > 0 && (
                <Pagination
                  recordCount={paginationResponse?.totalRecords}
                  page={page - 1}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              )}
            </div>
          </AccordionContainer>

          <AccordionContainer title='Serial Settings'>
            <Grid item xs={12} spacing={3}>
              <div>
                <Grid item xs={3}>
                  <Dropdown
                    selectlabel='Serialized'
                    customMenuItems={[
                      {
                        id: 0,
                        name: "No",
                      },
                      {
                        id: 1,
                        name: "Yes, Created once received",
                      },
                    ]}
                    value={isSerialized}
                    handleChange={({ target }) => {
                      setIsSerialized(target.value);
                    }}
                  />
                </Grid>
              </div>
            </Grid>
          </AccordionContainer>
        </>
      ) : (
        <AccordionContainer title='Blocked Serials'>
          <div
            style={{
              position: "absolute",
              top: "75px",
            }}>
            <Button
              color='primary'
              variant='text'
              className='dashboardButtons'
              // type='submit'
              onClick={() => setViewBlockedSerials(!viewBlockedSerials)}>
              <ArrowBackIos style={{ fontSize: "14px" }} />
              {`Back to Product Serials`}
            </Button>
          </div>
          <div>
            <GridFilterBar
              // onFilterButtonPress={filterBy => {
              //   if (filterBy?.selectedCategoryId > 0) {
              //     const { selectedCategoryId } = filterBy;
              //     filterProductsBy({ categoryId: selectedCategoryId });
              //   }
              // }}
              onClearButtonPress={() => loadData()}
            />
            <SerialsTabProductSerials
              isBlockedSerialsGrid
              listData={blockedDevicesList}
              loadData={() => loadBlockedData()}
              order={order}
              onOrderBy={setOrderBy}
            />
            {blockedDevicesList?.length > 0 && (
              <Pagination
                recordCount={paginationResponse?.totalRecords}
                page={page - 1}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            )}
          </div>
        </AccordionContainer>
      )}
    </div>
  );
};

export { SerialsTab };
