export const softwareBuildTypes = {
    SOFTWAREUPDATES_ONCHANGE_BUILDS: "SOFTWAREUPDATES_ONCHANGE_BUILDS",
    SEARCH_UPDATE_BUILDS: "SEARCH_UPDATE_BUILDS",
    SEARCH_UPDATEPRODUCT_BUILDS: "SEARCH_UPDATEPRODUCT_BUILDS",
    SEARCH_BUILDUPDATE_BUILDS: "SEARCH_BUILDUPDATE_BUILDS",
    ON_CHANGE_BUILDS: "ON_CHANGE_BUILDS",
    ON_LOAD_CHANGE_BUILDS: "ON_LOAD_CHANGE_BUILDS",
    BUILD_UPDATES_TAB_BUILDS:"BUILD_UPDATES_TAB_BUILDS",
    ADD_UPDATE_SOFTWARE_RELEASE_BUILDS: "ADD_UPDATE_SOFTWARE_RELEASE_BUILDS",
    ADD_UPDATE_RELEASE_REQUIREMENTS_BUILDS: "ADD_UPDATE_RELEASE_REQUIREMENTS_BUILDS",
    ADD_UPDATE_RELEASE_PRODUCTS_BUILDS: "ADD_UPDATE_RELEASE_PRODUCTS_BUILDS",
    SET_SELECTED_RELEASE_BUILDS: "SET_SELECTED_RELEASE_BUILDS",
    ON_CHANGE_SELECTED_RELEASE_FIELD_BUILDS: "ON_CHANGE_SELECTED_RELEASE_FIELD_BUILDS",
    ON_CHANGE_SELECTED_RELEASE_CONTACTS_BUILDS: "ON_CHANGE_SELECTED_RELEASE_CONTACTS_BUILDS",
    ON_CHANGE_SELECTED_RELEASE_PORTAL_BUILDS: "ON_CHANGE_SELECTED_RELEASE_PORTAL_BUILDS",
    ON_CHANGE_SELECTED_RELEASE_PRODUCTS_BUILDS: "ON_CHANGE_SELECTED_RELEASE_PRODUCTS_BUILDS",
    UPDATE_SELECTED_RELEASE_CONTACTS_BUILDS : 'UPDATE_SELECTED_RELEASE_CONTACTS_BUILDS',
    ON_CHANGE_PORTAL_CONTACT_FIELD_BUILDS:"ON_CHANGE_PORTAL_CONTACT_FIELD_BUILDS",
    ON_SOFTWARE_RELEASE_DATA_BUILDS:"ON_SOFTWARE_RELEASE_DATA_BUILDS",
    RESET_SOFTWARE_RELEASE_DATA_BUILDS:"RESET_SOFTWARE_RELEASE_DATA_BUILDS",
    ON_RELEASE_FAVOURITE_BUILDS: "ON_RELEASE_FAVOURITE_BUILDS",
    ON_DELETE_RELEASE_DATA_BUILDS:"ON_DELETE_RELEASE_DATA_BUILDS",
    ON_REMOVE_SELECTED_PORTAL_CONTACT_BUILDS:"ON_REMOVE_SELECTED_PORTAL_CONTACT_BUILDS",
  };
  