import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import Overview from "./Overview";
import ConversationChannel from "./ConversationChannel";
import ConversationTypes from "./ConversationTypes";
import Notifications from "./Notifications";
import Customizations from "./Customizations";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import { useEffect } from "react";

function ConversationPopup({ isPopupOpen, closePopup }) {
  const [activeTabId2, setActiveTabId] = useState("1");
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const dispatch = useDispatch();

  const { activeTabId } = useSelector(state => state.conversationReducer);

  useEffect(() => {
    if (activeTabId) {
      const activetab = document.getElementsByClassName(
        `conversation-tab-${activeTabId - 1}`
      );
      activetab?.[0]?.click();
    }
  }, [activeTabId]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsAutocomplete(false)}>
      <div className="conversationPopupWrapper">
        <div className="conversationPopupInner">
          <div className="header">
            <span className="headerTitle">Conversations Setup: Overview</span>
            <span className="popupCloseBtn">
              <CloseIcon onClick={closePopup} />
            </span>
          </div>
          <div className="conversationTabs">
            {" "}
            <TopTabNavigator
              getActiveTabId={tabId => {
                // setActiveTabId(`${tabId}`);
                dispatch(
                  conversationAction.onChange("activeTabId", `${tabId}`)
                );
              }}
              tabs={[
                {
                  id: "1",
                  title: "Overview",
                  component: <Overview isActive={activeTabId === "1"} />,
                },
                {
                  id: "2",
                  title: "Conversation Channel",
                  component: (
                    <ConversationChannel isActive={activeTabId === "2"} />
                  ),
                },
                {
                  id: "3",
                  title: "Conversation Types",
                  component: (
                    <ConversationTypes isActive={activeTabId === "3"} />
                  ),
                },
                {
                  id: "4",
                  title: "Customizations",
                  component: <Customizations isActive={activeTabId === "4"} />,
                },
                {
                  id: "5",
                  title: "Notifications",
                  component: <Notifications isActive={activeTabId === "5"} />,
                },
              ]}
              value={activeTabId}
              className="conversation-tab-"
            />
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export default ConversationPopup;
