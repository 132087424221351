import React from "react";
import AddAttributeTextBox from "./AddAttributeTextBox";
import { Button, Grid } from "@material-ui/core";
import GroupAvatar from "./GroupAvatar";

const data = [
  {
    title: "Environment",
    option: "4",
    avatar: <GroupAvatar />,
  },
  {
    title: "Product Category",
    option: "5",
    avatar: <GroupAvatar />,
  },
  {
    title: "Services Offered",
    option: "6",
    avatar: <GroupAvatar />,
  },
  {
    title: "Place Holder",
    option: "3",
    avatar: <GroupAvatar />,
  },
];

function TextOption({ onclick }) {
  return (
    <>
      <div className='textOptionWrapper'>
        <Grid container spacing={2} xs={12}>
          {data.map((item, index) => {
            return (
              <Grid item xs={6}>
                <AddAttributeTextBox
                  title={item.title}
                  option={item.option}
                  avatar={<GroupAvatar />}
                  onclick={onclick}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className='attributeFooter attributesTabsfooter'>
        <span>
          <Button color='primary' variant='contained'>
            Next
          </Button>
        </span>
      </div>
    </>
  );
}

export default TextOption;
