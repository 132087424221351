import React, { useState, useEffect, useCallback } from "react";

import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashbard from "../Dashbard";
import EventsGrid from "./EventsGrid";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/common/Loader";
import { useDispatch } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import GridFilter from "./GridFilter";
import { Pagination } from "../../../../components/common";
import { ReleaseGridSideFilter } from "../ReleasesViews/ReleaseGridSideFilter";

function DashboardEvents(props) {
  document.title = "OFish - Events";

  const history = useHistory();
  const redirectUrl = () => {
    history.push("/new-event");
  };
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [eventsCategory, setEventsCategory] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByName, setFilterByName] = useState("");
  const [selectedCategoryId, setSelectedCategory] = useState(null);
  const [selectedDate, setSelectedDate] = useState(0);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });

  const loadAllEvents = useCallback(() => {
    setLoading(true);
    const modal = {
      eventName: filterByName,
      date: selectedDate,
      type:"",
      eventCategory: selectedCategoryId,
      status: null,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    dispatch(launchesActions.onGetAllEvents(modal)).then(data => {
      if (data?.success) {
        if (data.data?.data) {
          const eventList = data.data.data.map((x, index) => {
            return {
              id: x.id,
              eventName: x.eventName,
              startDate: x.startDate,
              endDate: x.endDate,
              eventCategory: x.eventCategory,
              status: x.status,
              checked: false,
              categoryColor: x.categoryColor,
              urlSlug: x.urlSlug,
            };
          });
          setPaginationResponse(data?.data?.pagination);
          setEventsData(eventList);
          var count = eventList.find(x => x.checked === true);
          setIsDelete(count ? true : false);
        }
      }
      setLoading(false);
    });
  }, [
    dispatch,
    page,
    rowsPerPage,
    filterByName,
    selectedCategoryId,
    selectedDate,
    order.orderByKey,
    order.orderVal,
  ]);

  useEffect(() => {
    loadAllEvents();
    window.scrollTo(0, 0);
  }, [
    dispatch,
    page,
    rowsPerPage,
    filterByName,
    selectedCategoryId,
    selectedDate,
    order.orderByKey,
    order.orderVal,
  ]);

  useEffect(() => {
    dispatch(launchesActions.onGetEventsCategory()).then(data => {
      if (data) {
        const catList = data.data.map((x, index) => {
          return {
            id: x.id,
            value: x.value,
            generic: x.generic,
            checked: false,
          };
        });
        setEventsCategory(catList);
      }
    });
  }, []);
  const checkBusGroupsSelected = item => {
    eventsData.map(x => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    var count = eventsData.find(x => x.checked === true);
    setIsDelete(count ? true : false);
    setEventsData([...eventsData], eventsData);
  };

  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading  launchBtn'>
          <h1>All Events </h1>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={redirectUrl}>
            New Event
          </Button>
        </div>

        <div className='gridBody contentBody'>
          {/* {showMedia && (
            <div className="showMediaRelease">
              <NewMediaRelease />{" "}
            </div>
          )} */}
          {/* <GridFilter
            categories={eventsCategory}
            setFilterByName={setFilterByName}
            setSelectedCategory={setSelectedCategory}
            setSelectedDate={setSelectedDate}
          /> */}
            <ReleaseGridSideFilter
            Categories={eventsCategory}
            setFilterByName={setFilterByName}
            setSelectedCategory={setSelectedCategory}
            setSelectedDate={setSelectedDate}
            filterTitle={"Events"}
          />
          <EventsGrid
            allEventsData={eventsData}
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadAllEvents()}
            selectedRecord={item => {
              checkBusGroupsSelected(item);
            }}
            isDelete={isDelete}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </div>
    </Dashbard>
  );
}

export default DashboardEvents;
