import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Divider } from "@material-ui/core";
import { Dialog, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import { ReactComponent as Cross } from "../../../../../resources/images/Close.svg";
import { contactsTypesActions } from "../../../../../store/contactTypes/contactTypesActions";
import { useDispatch, useSelector } from "react-redux";
import { flatten, remove } from "lodash";
import RadioGroup from "@material-ui/core/RadioGroup";

const Popup = withStyles({
  root: {
    "&.MuiDialog-paper": {
      maxWidth: "80% !important",
      width: "80% !important",
    },
  },
})(Dialog);

const InputField = withStyles({
  root: {
    "&.MuiOutlinedInput-input": {},
    "&.MuiTextField-root": {
      width: "300px !important",
      background: "#FFFFFF",
      borderRadius: " 6px",
      border: "1px solid black",
    },
  },
})(TextField);

export function ContactDataPopup({
  props,
  isOpen,
  records,
  setIsOpen,
  selectedId,
  selectedName,
  selectedFieldSetting,
}) {
  const { dataModule } = useSelector(state => state.pubContactType);
  const [radioOption, setRadioOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      selectedFieldSetting &&
      selectedFieldSetting?.contactTypeModuleFieldSettingInfo?.length > 0
    ) {
      setSelectedOptions(
        selectedFieldSetting?.contactTypeModuleFieldSettingInfo
      );
      setRadioOption(
        selectedFieldSetting?.contactTypeModuleFieldSettingInfo[0]
      );
    } else {
      var radioData = records?.[0]?.settings?.find(x => x?.type !== "search");
      if (radioData) {
        setRadioOption({
          moduleFieldSettingId: radioData?.fieldSettingId,
          additionalData: "",
        });
      }
    }
  }, [selectedFieldSetting]);

  const onSaveClick = () => {
    if (selectedOptions) {
      const dataModules = [];
      if (dataModule?.length > 0) {
        dataModule?.forEach(x => {
          if (x.fieldId === selectedId) {
            if (
              radioOption &&
              records[0]?.settings?.find(r => r.type === "radio")
            ) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactTypeModuleFieldSettingInfo: [radioOption],
              };
              dataModules.push(dm);
            } else {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactTypeModuleFieldSettingInfo: [...selectedOptions],
              };
              dataModules.push(dm);
            }
          } else {
            dataModules.push({
              ...x,
            });
          }
        });
      } else {
        const dm = {
          fieldId: selectedId,
          fieldValue: selectedName,
          contactTypeModuleFieldSettingInfo: [...selectedOptions],
        };
        dataModules.push(dm);
      }
      dispatch(contactsTypesActions.onChange("dataModule", dataModules));
      setSelectedOptions([]);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const existingValues = flatten(
      dataModule
        ?.filter(x => x.fieldId === selectedId)
        .map(d => {
          return d?.contactTypeModuleFieldSettingInfo.map(y => {
            return {
              moduleFieldSettingId: y.moduleFieldSettingId,
              content: y?.content,
            };
          });
        })
    );
    setSelectedOptions(existingValues);
  }, [dataModule]);

  const removeDataField = () => {
    var data = [];
    dataModule.map(x => {
      if (x.fieldId !== selectedId) {
        return data.push(x);
      }
    });
    dispatch(contactsTypesActions.onChange("dataModule", data));
    setIsOpen(false);
  };

  console.log("radioOption", radioOption);
  return (
    <>
      <div>
        <Popup
          style={{ maxWith: "70% !important" }}
          component='div'
          open={isOpen}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          className='edit-popup'>
          <>
            <Grid container>
              <Grid item sx={10} md={11} lg={11}>
                <Typography
                  id='modal-modal-title'
                  style={{
                    padding: "3%",
                    width: "fit-content",
                    display: "inline-block",
                  }}
                  variant='h6'
                  component='h1'>
                  OFish
                </Typography>
                <Box
                  component='span'
                  style={{
                    color: "#F23F44",
                    fontSize: "14px",
                    fontWeight: "800",
                  }}>
                  {selectedName}
                </Box>
              </Grid>
              <Grid item sx={1}>
                <Cross
                  style={{
                    width: "15px",
                    padding: "10px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsOpen(false)}
                />
              </Grid>
            </Grid>
            <Divider />
            <div className='contentDiv'>
              {props?.searchField && (
                <div style={{ padding: "1% 2%" }}>
                  <p>
                    Contact Field Name<span className='text-danger'>*</span>
                  </p>
                  <InputField
                    id='outlined-basic'
                    variant='outlined'
                    placeholder='Select a Contact Area'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'></InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
              <FormControl style={{ marginLeft: "12px" }}>
                {/* <RadioGroup name="required" column> */}
                {records?.map(item => {
                  console.log(
                    "item?.settings",
                    item?.settings.filter(x => x.type === "radio")
                  );
                  return (
                    <>
                      {item?.settings.map(innerItem => {
                        return (
                          innerItem.type === "checkbox" && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    selectedOptions.find(
                                      x =>
                                        x.moduleFieldSettingId ===
                                        innerItem.fieldSettingId
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={({ target }) => {
                                    if (target.checked) {
                                      selectedOptions.push({
                                        moduleFieldSettingId: parseInt(
                                          innerItem.fieldSettingId
                                        ),
                                        additionalData: records?.find(
                                          x =>
                                            x.fieldSettingId ===
                                            innerItem.fieldSettingId
                                        )?.subContent,
                                      });
                                    } else {
                                      remove(
                                        selectedOptions,
                                        v =>
                                          v.moduleFieldSettingId ===
                                          innerItem.fieldSettingId
                                      );
                                    }
                                    setSelectedOptions([...selectedOptions]);
                                  }}
                                  name={innerItem?.content}
                                />
                              }
                              label={
                                <>
                                  <span>{innerItem?.content}</span>
                                  <span>{innerItem?.subContent}</span>
                                </>
                              }
                            />
                          )
                        );
                      })}
                      {item?.settings.filter(x => x.type === "radio")?.length >
                        0 && (
                        <>
                          {item?.section && (
                            <span>
                              <strong>{item?.section}</strong>
                            </span>
                          )}
                          <RadioGroup
                            name='rg-category-type'
                            value={radioOption?.moduleFieldSettingId}
                            onChange={({ target }) => {
                              const selValue = parseInt(target.value);
                              const sel = item?.settings.find(
                                x => x.fieldSettingId == selValue
                              )?.subContent;
                              setRadioOption({
                                moduleFieldSettingId: selValue,
                                additionalData: sel,
                              });
                            }}>
                            {item?.settings
                              .filter(x => x.type === "radio")
                              ?.map(d => {
                                return (
                                  <>
                                    <FormControlLabel
                                      key={d.fieldSettingId}
                                      control={<Radio />}
                                      label={
                                        <span>
                                          {`${d?.content} `}
                                          <span
                                            style={{
                                              color:
                                                d?.subContent === "Required"
                                                  ? "#3699FF"
                                                  : "#25A969",
                                            }}>
                                            {d?.subContent}
                                          </span>
                                        </span>
                                      }
                                      value={d.fieldSettingId}
                                    />
                                  </>
                                );
                              })}
                          </RadioGroup>
                        </>
                      )}
                    </>
                  );
                })}
                {records?.length === 0 && <span>No record found</span>}
              </FormControl>
            </div>
          </>

          <span className='contactPopupBtn'>
            <Button
              variant='contained'
              color='primary'
              type='button'
              onClick={() => {
                onSaveClick();
              }}>
              Save
            </Button>
          </span>
        </Popup>
      </div>
    </>
  );
}
