import React from "react";
import { SoftwareReleaseDeleteIcon, SoftwareReleaseEditIcon, SoftwareReleaseEyeIcon, SoftwareReleaseFavFilledIcon, SoftwareReleaseOulinedFavIcon, SoftwareReleaseSortIcon, } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeaderInnerCenter: {
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
    height: "43px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridStatusColumn: {
    textAlign: "center",
  },
  gridProductColumn: {
    // fontWeight: "bold",
  },
  gridVersionColumn: {
    fontWeight: "bold",
    color: "#3699FF",
  },
  gridStyledColumn: {
    fontSize: "12px",
    color: "#2D3339",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
      boxShadow: "none",
    },
  },
  launchedButtons: {
    color: "#00ABA3",
    backgroundColor: "rgba(0, 171, 163, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(0, 171, 163, 0.25)",
      boxShadow: "none",
    },
  },
  newProductLaunch: {
    color: "#8254E3",
    background: "rgba(130, 84, 227, 0.25)",
    boxShadow: "none",
    "&:hover": {
      background: "rgba(130, 84, 227, 0.25)",
      boxShadow: "none",
    },
  },
  editButton:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  iconButton:{
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));

function SoftwareGrid({
  allReleaseData,
  order,
  onOrderBy,
  formName,
  markUnMarkFavouriteDevices,
  deleteSoftwareRelease,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { id: selectedRelaseId } = useParams();

  return (
    <Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              Update Name
              <TableSortLabel
                active={order?.orderByKey === "UpdateName"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "UpdateName",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SoftwareReleaseSortIcon}
                  className="sortIcon"
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Version ID
              <TableSortLabel
                active={order?.orderByKey === "VersionId"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "VersionId",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SoftwareReleaseSortIcon}
                  className="sortIcon"
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
            Eligible Devices
              <TableSortLabel
                active={order?.orderByKey === "Products"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Products",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SoftwareReleaseSortIcon}
                  className="sortIcon"
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
            Status
              <TableSortLabel
                active={order?.orderByKey === "Status"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Status",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SoftwareReleaseSortIcon}
                  className="sortIcon"
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
            Release Date
              <TableSortLabel
                active={order?.orderByKey === "ReleaseDate"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Date",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SoftwareReleaseSortIcon}
                  className="sortIcon"
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
            Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(allReleaseData && allReleaseData?.length > 0) ?
            allReleaseData?.map((item, index) => {
              return (
                <TableRow key={index} className="gridBodyRow">
                  <TableCell className={classes.gridStyledColumn}>
                    {item?.updateName ?? "--"}
                  </TableCell>
                  <TableCell
                    className={[
                      classes.gridBodyInner,
                      classes.gridVersionColumn,
                    ]}
                  >
                    {item.versionId ?? "--"}
                  </TableCell>
                  <TableCell
                    className={[
                      classes.gridProductColumn,
                      classes.gridBodyInnerCenter,
                    ]}
                  >
                    {item.products ?? "0"}
                  </TableCell>
                  <TableCell className={classes.gridStatusColumn}>
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "capitalize",
                        backgroundColor:
                          (item.status === "Creating" && "#ddd") ||
                          (item.status === "Live" &&
                            "rgba(33, 150, 83, 0.25)") ||
                          (item.status === "EndOfLife" &&
                            "rgba(255, 205, 205, 1)") ||
                          (item.status === "InTesting" &&
                            "rgba(242, 201, 76, 0.25)"),
                        color:
                          (item.status === "Creating" &&
                            "rgb(141, 153, 165)") ||
                          (item.status === "Live" && "#219653") ||
                          (item.status === "EndOfLife" && "#FF3535") ||
                          (item.status === "InTesting" && "#F2994A")||
                          (item.status === "" &&
                          "rgb(141, 153, 165)"),
                        padding: "8px 15px",
                        borderRadius: "6px",
                        fontSize: "12px",
                        fontWeight: 700,
                      }}
                    >
                      {item.status !== "" ? (item.status === "InTesting"
                        ? "In-Testing"
                        : item.status === "EndOfLife"
                        ? "Retired"
                        : item.status) : "Creating"}
                    </Button>
                  </TableCell>
                  <TableCell
                    className={[
                      classes.gridStyledColumn,
                      classes.gridBodyInnerCenter,
                    ]}
                  >
                    {item.releaseDate}
                  </TableCell>
                  <TableCell className={classes.editButton}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() =>
                        markUnMarkFavouriteDevices(item)
                      }
                    >
                      {item.isFavourite ? (
                        <SoftwareReleaseFavFilledIcon />
                      ) : (
                        <SoftwareReleaseOulinedFavIcon />
                      )}
                    </IconButton>

                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        history.push( formName === "Build" ?
                        `/software-new-build/${selectedRelaseId}/${item.id}`
                      :
                        `/new-software-release/${item.id}`)
                      }}
                    >
                      <SoftwareReleaseEditIcon />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        formName === "Build"
                          ? history.push(
                              `/software-builds/${selectedRelaseId}/${item.id}`
                            )
                          : history.push(`/software-releases/${item.id}`);
                      }}
                    >
                      <SoftwareReleaseEyeIcon />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() =>
                        deleteSoftwareRelease(item.id)
                      }
                    >
                      <SoftwareReleaseDeleteIcon
                        style={{ cursor: "pointer" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            }) :  (
            <TableRow key="0" className="gridBodyRow">
              <TableCell
                colSpan="6"
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}
              >
                <span className="emptyGridText">
                  Uh Oh! No software releases have been created.. You can always
                  <Button onClick={() => {
                        history.push( formName === "Build" ?
                        `/software-new-build/${selectedRelaseId}`
                      :
                        `/new-software-release`)
                      }}>
                    Add a New Software Version
                  </Button>
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default SoftwareGrid;
