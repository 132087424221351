import React from 'react'
import Dashboard from "../../Dashbard";
import { Grid } from '@material-ui/core';
import ConversationTypesOverview from './ConversationTypesOverview';
export const ConversationTypeEditPreview = () => {
  return (
    <Dashboard>
        <div className='body-content'>
        <ConversationTypesOverview />,
     </div>
    </Dashboard>
  )
}
