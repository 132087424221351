import { axios } from '../../config/axiosConfig';
import { endpoints } from '../../config/apiConfig';
import apiCall from 'axios';

async function addUpdateRelease(release) {
    return axios.post(endpoints.softwareRelease.addUpdateSoftwareRelease, release)
        .then(response => response.data)
        .catch(error => error);
};

async function addUpdateReleaseProducts(productsData) {
    return axios.post(endpoints.softwareRelease.addUpdateReleaseProducts, productsData)
        .then(response => response.data)
        .catch(error => error);
};

async function addUpdateSRPortalandContacts(requirementsData) {
    return axios.post(endpoints.softwareRelease.addUpdateSRPortalandContacts, requirementsData)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleaseById(releaseId) {
    return axios.post(`${endpoints.softwareRelease.getSoftwareReleaseById}/${releaseId}`)
        .then(response => response.data)
        .catch(error => error);
};

async function getReleaseCompatibleProducts() {
    return axios.post(endpoints.softwareRelease.getReleaseCompatibleProducts)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareContactsReleaseById(releaseId) {
    return axios.post(`${endpoints.softwareRelease.getSoftwareContactsReleaseById}/${releaseId}`)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleasePortalById(releaseId) {
    return axios.post(`${endpoints.softwareRelease.getSoftwareReleasePortalById}/${releaseId}`)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleaseProducts(queryData) {
    return axios.post(endpoints.softwareRelease.getSoftwareReleaseProducts, queryData)
        .then(response => response.data)
        .catch(error => error);
};

async function addUpdateReleaseFile(buildFile) {
    // This function can be changed or removed, so far we have no endpoints for the build file
    const token = localStorage.getItem('access_token');
    return apiCall({
        url: endpoints.softwareRelease.addUpdateSoftwareReleaseBuild,
        method: 'post',
        data: buildFile,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => response.data)
        .catch(error => error)
};

async function getNotificationsType() {
    return axios.post(endpoints.softwareRelease.getNotificationsType)
        .then(response => response.data)
        .catch(error => error);
};

async function addUpdateSoftwareReleaseNotification(notificationData) {
    return axios.post(endpoints.softwareRelease.addUpdateSoftwareReleaseNotification, notificationData)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleaseNotifications(releaseId) {
    return axios.post(`${endpoints.softwareRelease.getSoftwareReleaseNotification}/${releaseId}`)
        .then(response => response.data)
        .catch(error => error);
};

async function addSoftwareReleaseDetails(selectedData) {
    return axios.post(endpoints.softwareRelease.addSoftwareReleaseDetails, selectedData)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleaseDetails(releaseId) {
    return axios
        .post(
            `${endpoints.softwareRelease.getSoftwareReleaseDetails}/${releaseId}`
        )
        .then((response) => response.data)
        .catch((error) => error);
};

async function getAllSoftwareReleases(queryData) {
    return axios
        .post(endpoints.softwareRelease.getAllSoftwareRelease, queryData)
        .then((response) => response.data)
        .catch((error) => error);
};

async function deleteSoftwareRelease(releaseId) {
    return axios
        .post(`${endpoints.softwareRelease.deleteSoftwareRelease}/${releaseId}`)
        .then((response) => response.data)
        .catch((error) => error);
};

async function markUnMarkFavouriteSoftwareRelease(queryData) {
    return axios
        .post(endpoints.softwareRelease.markUnMarkSoftwareReleaseFavourite, queryData)
        .then((response) => response.data)
        .catch((error) => error);
};

async function deleteSoftwareReleaseNotifiacation(notificationId) {
    return axios
        .post(`${endpoints.softwareRelease.deleteSoftwareReleaseNotifiacation}/${notificationId}`)
        .then((response) => response?.data)
        .catch((error) => error);
};

export const softwareUpdatesApi = {
    addUpdateRelease,
    addUpdateReleaseProducts,
    addUpdateSRPortalandContacts,
    getSoftwareReleaseById,
    getReleaseCompatibleProducts,
    getSoftwareContactsReleaseById,
    getSoftwareReleasePortalById,
    getSoftwareReleaseProducts,
    addUpdateReleaseFile,
    addUpdateSoftwareReleaseNotification,
    getNotificationsType,
    getSoftwareReleaseNotifications,
    addSoftwareReleaseDetails,
    getSoftwareReleaseDetails,
    getAllSoftwareReleases,
    deleteSoftwareRelease,
    markUnMarkFavouriteSoftwareRelease,
    deleteSoftwareReleaseNotifiacation,
};