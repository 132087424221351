import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { first } from "lodash";
import Loader from "../../../../../components/common/Loader";
import { endpoints } from "../../../../../config/apiConfig";
import { productInventoryActions } from "../../../../../store/product/productInventoryAction";

const PreviewPriceList = ({ plData, isActive, priceListId = 0 }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [priceListPreview, setPriceListPreview] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (isActive && priceListId > 0) {
      setLoading(true);
      dispatch(
        productInventoryActions.onLoadData({
          url: endpoints.priceListTool.previewPriceListById,
          payload: { id: priceListId },
        })
      ).then(data => {
        setLoading(false);
        if (!data || !data?.success) return;
        setPriceListPreview(data?.data);
      });
    }
  }, [dispatch, isActive, priceListId]);

  useEffect(() => {
    if (priceListPreview?.length > 0) {
      setColumns(first(priceListPreview).columns);
    }
  }, [priceListPreview]);

  const downloadedPriceList = (isCsv = false) => {
    setLoading(true);
    dispatch(
      productInventoryActions.onLoadData({
        url: isCsv
          ? endpoints.priceListTool.downloadCSV
          : endpoints.priceListTool.downloadPDF,
        payload: {id: plData?.id},
        config: { responseType: "blob" },
      })
    ).then(data => {
      setLoading(false);
      if (!data?.success && data?.message) toast.error(data?.message);
      else if (data)
        downloadFile({
          filename: plData?.priceListNameVisible || `price-list-${priceListId}`,
          data: data,
          isCsv: isCsv,
        });
      else toast.warning(`Unable to download price list.`);
    });
  };

  const downloadFile = ({ filename, data, isCsv }) => {
    // let byteArray = new Uint8Array(data);
    // var file = new Blob([data], { type: "application/vnd.ms-excel" });
    // var fileURL = URL.createObjectURL(file);
    // window.open(fileURL);
    // return;
    const blob = new Blob([data], {
      type: isCsv ? "application/vnd.ms-excel" : "application/pdf",
    });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement("a");
      elem.style.display = "none";
      elem.href = window.URL.createObjectURL(blob, { oneTimeOnly: true });
      elem.setAttribute("download", `${filename}.${isCsv ? "csv" : "pdf"}`);
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  return (
    <Grid container spacing={2} className='previewPriceList'>
      {isLoading ? <Loader /> : null}
      <Grid item sm={7}>
        <h1
          style={{
            color: plData?.mainTextColor || null,
          }}>
          {priceListPreview?.priceListName ||
            plData?.priceListNameVisible ||
            "N/A"}
        </h1>
      </Grid>
      <Grid item sm={5} className='action-buttons'>
        <Button
          color='primary'
          variant='contained'
          onClick={() => downloadedPriceList(true)}>
          Download as CSV
        </Button>

        <Button
          color='primary'
          variant='contained'
          style={{
            marginLeft: "10px",
          }}
          onClick={() => downloadedPriceList(false)}>
          Download as PDF
        </Button>
      </Grid>
      <Grid container item xs={12} className='paraText'>
        <Grid item xs={9}>
          <p
            style={{
              color: plData?.mainTextColor || null,
            }}>
            {priceListPreview?.priceListDescription ||
              plData?.shortDescription ||
              "Description is not added, Please update description"}
          </p>
        </Grid>
        {plData?.isConfidential && (
          <Grid item xs={3} className='redText'>
            {plData?.showConfidentialNotice ? (
              <p>{plData?.confidentialWarning}</p>
            ) : (
              <>
                <p>This Document Is Confidential. </p>
                <p>DO NOT SHARE.</p>
              </>
            )}
          </Grid>
        )}
      </Grid>

      <div className='previewPriceListTable'>
        <div className='previewPriceListBody'>
          <div
            className='previewPriceListHeader'
            style={{
              background: plData?.mainRowTitlesBackColor || null,
            }}>
            {columns.map(col => (
              <span
                className='col-title'
                style={{
                  color: plData?.mainRowTitlesTextColor || null,
                }}>
                {col}
              </span>
            ))}
          </div>
          {priceListPreview.map(pl => (
            <>
              <div
                className='title'
                style={{
                  background: plData?.parentCategoryBackColor || null,
                  color: plData?.parentCategoryTextColor || null,
                }}>
                {pl?.category}
              </div>
              {pl.data?.map(d => (
                <div className='previewPriceListBodyData'>
                  {columns.map(col => (
                    <span
                      className='data-text'
                      style={
                        {
                          // width: `${100 / columns.length}%`,
                          // overflowWrap: "anywhere",
                        }
                      }>
                      {col.includes("Product Image") ? (
                        <img
                          style={{
                            width: "55px",
                            height: "55px",
                          }}
                          src={d[col]}
                          alt={d[col]}
                          loading='lazy'
                        />
                      ) : (
                        d[col] || "-"
                      )}
                    </span>
                  ))}
                </div>
              ))}
            </>
          ))}
        </div>
      </div>
    </Grid>
  );
};

export { PreviewPriceList };
