import { Button, Grid } from "@material-ui/core";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiUpload } from "react-icons/bi";
import Dashbard from "../Dashbard";
import GridFilter from "./GridFilter";
import Loader from "../../../../components/common/Loader";
import MediaLibraryList from "./MediaLibraryList";
import MediaUploadPopup from "../../../../components/common/popUps/MediaUploadPopup";
import NewFolderPopup from "../../../../components/common/popUps/NewFolerPopup";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import { Pagination } from "../../../../components/common";
import LibraryOverview from "./LibraryOverview";
import { AssetLibraryGridView } from "./AssetLibraryGridView";
import { useLocation, useHistory } from "react-router-dom";
import AnimatedTabs from "../../../../components/common/Tabs/AnimatedTabs";
import RightClickAssestPreview, {
  AssestPreview,
  AssetsActionDetials,
  DeleteFilesOverlay,
} from "../../../../components/common/Modal/AssetsModals";
import {
  Delete,
  DownloadIcon,
  FavoriteIcon,
  MoveIcon,
  PreviewIcon,
  RcoveryIcon,
  RenameIcon,
} from "../../LeftNav/SvgIcons";
import MoveMediaFilesPopup from "./MoveMediaFilesPopup";
import { toast } from "react-toastify";
import _ from "lodash";

const ASSETS_TABS = [
  {
    id: 0,
    label: "Your Asset Library",
    value: "/assets-media-library",
  },
  {
    id: 1,
    label: "Favorites",
    value: "/assets-media-library-favorite",
  },
  {
    id: 2,
    label: "Conversation Attachments",
    value: "/assets-media-library-conversations",
  },
  {
    id: 3,
    label: "Shared Files",
    value: "/assets-media-library-sharedfiles",
  },
  {
    id: 4,
    label: "Internal Files",
    value: "/assets-media-library-internalFiles",
  },
  {
    id: 5,
    label: "Trash",
    value: "/assets-media-library-trash",
  },
];

function AssetManagement({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrderBy,
  loadData,
  isLoading,
  setLoading,
  parent,
  setParent,
}) {
  document.title = "OFish - Assets Media Library";

  const dispatch = useDispatch();
  const {
    paginationResponse,
    gridData,
    parentId,
    breadCrumbs,
    selectedMediaAssets,
  } = useSelector((state) => state.AssetsReducer);
  
  const location = useLocation();
  const history = useHistory();
  const [addFolder, setAddFolder] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [view, setView] = useState("list");
  const [selectedMediaFile, setSelectedMediaFile] = useState(null);
  const [folderPreView, setFolderPreView] = useState(false);
  const [rightManuBar, setRightMenuBar] = useState(null);
  const [closeTooltip, setCloseTooltip] = useState(false);
  const [rightClickItem, setRightClickItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState(null);
  const [showMovePopup, setShowMovePopup] = useState(false);
  const [isRenameAssest, setIsRenameAsset] = useState(false);
  const [renameAssetItem, setRenameAssetItem] = useState("");
  const [moveItem, setMoveItem] = useState(null);
  const [isDraggableState, setIsDraggableState] = useState(false);
  const [selectedMoveItems, setSelectedMoveItems] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [openDeleteFilesOverlay, setOpenDeleteFilesOverlay] = useState(false);
  const [openAssetActionUndo, setOpenAssetActionUndo] = useState("");
  const [isMoveClicked, setIsMoveClicked] = useState(true);
  const [isTrashedItem , setIsTrashedItem] = useState(false);
  const [tabName, setTabName] = useState("Asset Library Home");
  const [selectedItem, setSelectedItem] = useState(null);
  const [onFavClick , setOpenFavClick] = useState(false);
  const [openGridViewOnUploadeAsset, setOpenGridViewOnUploadeAsset] =
    useState("");

  const selectedTab = useMemo(
    () => ASSETS_TABS.find((tab) => tab.value === location.pathname).id,
    [location]
  );

  const setFolderData = (item) => {
    dispatch(assetsActions.onChange("parentId", item.id));
    dispatch(assetsActions.onChange("isfavorite", item.isfavorite));
    addNewBreadCrumbItem(item.id, item.name);
    setPage(1);
  };
 
  const addNewBreadCrumbItem = (id, folderName) => {
    const breadCrumbsData = [...breadCrumbs];
    const existingItemIndex = breadCrumbsData.findIndex((item) => item.id === id);
    if (existingItemIndex !== -1) {
      breadCrumbsData.splice(existingItemIndex, 1);
    }

    let data = {
      title: folderName,
      id: id,
    };
    breadCrumbsData.push(data);
    dispatch(assetsActions.onChange("breadCrumbs", breadCrumbsData));
  };

  const renderSelectedData = (item, index) => {
    dispatch(assetsActions.onChange("parentId", item.id));
    var breadCrumbsData = breadCrumbs;
    let bread = breadCrumbsData.splice(0, index + 1);
    breadCrumbsData = bread;
    dispatch(assetsActions.onChange("breadCrumbs", breadCrumbsData));
    setPage(1);
  };

  const renderAllData = () => {
    dispatch(assetsActions.onChange("parentId", null));
    var breadCrumbsData = [];
    dispatch(assetsActions.onChange("breadCrumbs", breadCrumbsData));
    setPage(1);
    dispatch(assetsActions.onChange("preViewData", []));
    setFolderPreView(false);
  };

  // const getFolders = () => {
  //   setLoading(true);
  //   dispatch(
  //     assetsActions.onGetAssetManagementListForPopup(parentId, "folder")
  //   ).then((data) => {
  //     setShowMovePopup(true);
  //     setLoading(false);
  //   });
  // };

  const handleTabChange = (event, newValue) => {
    // setTabName(ASSETS_TABS[newValue].label);
    history.push(ASSETS_TABS[newValue].value);
    
  };
  const markAsFav = (item, isFavorite) => {
    setOpenFavClick(true);
    setLoading(true);
    dispatch(assetsActions.addFavorite(item.id, isFavorite)).then((data) => {
      loadData();
    });
    
  };

  const deleteAssetsMedia = (deleteClickItem) => {
    setLoading(true);
    dispatch(assetsActions.onDeleteMediaAssets(deleteClickItem)).then(
      (data) => {
        if (data && !data.success) {
          toast.error(data.message);
          setLoading(false);
        } else {
          setOpenAssetActionUndo("trash");
          setTimeout(() => {
            setOpenAssetActionUndo("");
          }, 3000);
          loadData();
        }
      }
    );
  };
  
  const deleteAssetsPermanently = (deleteClickItem) => {
    setLoading(true);
    dispatch(assetsActions.onDeleteMediaAssetsPermanently(deleteClickItem)).then(
      (data) => {
        if (data && !data.success) {
          toast.error(data.message);
          setLoading(false);
        } else {
          loadData();
          setOpenAssetActionUndo("delete");
          setTimeout(() => {
            setOpenAssetActionUndo("");
          }, 3000);
        }
      }
    )
    handleClose();
  };

  const recoverAssets = (recoverItem) => {
    setLoading(true);
    dispatch(assetsActions.onRecoverMediaAsset(recoverItem)).then(
      (data) => {
        if (data && !data.success) {
          toast.error(data.message);
          setLoading(false);
        } else {
          loadData();
          setOpenAssetActionUndo("recover");
          setTimeout(() => {
            setOpenAssetActionUndo("");
          }, 3000);
        }
      }
    )
    handleClose();
  };

  const handleClose = () => {
    setRightMenuBar(null);
    setCloseTooltip(false);
    setRightClickItem(null);
    setIsDraggableState(false);
  };

  const markasfavorate = () => {
    const isFavorite = rightClickItem.isfavorite === true ? false : true;
    markAsFav(rightClickItem, isFavorite);
    handleClose();
  };

  const onClickPreview = () => {
    if (rightClickItem.type === "folder") {
      setOpen(false);
    } else {
      setOpen(true);
      setPreviewItem(rightClickItem);
    }
    handleClose();
  };
  const handleClosePreview = () => {
    setOpen(false);
  };

  const onMoveFolder = () => {
    setMoveItem(rightClickItem);
    dispatch(assetsActions.onSelectMediaAssets(rightClickItem));
    handleClose();
  };

  const onDeleteAssets = (assetData) => {
    setOpenDeleteFilesOverlay(true);
    dispatch(assetsActions.onSelectMediaAssets(assetData));
    handleClose();
  };

  const onPermanetlyDeleteAssets = (assetData) => {
    setOpenDeleteFilesOverlay(true);
    dispatch(assetsActions.onSelectMediaAssets(assetData));
    handleClose();
  };

  const renameAssestFun = () => {
    setIsRenameAsset(rightClickItem);
    setRenameAssetItem(rightClickItem);
    handleClose();
  };
  const saveRenameAssetItem = () => {
    dispatch(
      assetsActions.updateAssestName(renameAssetItem)
    ).then((data) => {
      loadData();
    });
    setIsRenameAsset(false);
    setRenameAssetItem();
  };

  function downloadMedia(rightClickItem) {
    if (rightClickItem.type !== "folder") {
      fetch(rightClickItem?.url)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          const blob = new Blob([data]);
          return saveAs(
            blob,
            `${rightClickItem?.name}.${rightClickItem?.type}`
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      handleClose();
    }
  }

  function saveAs(blob, name) {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = name;
    document.body.appendChild(a);
    a.target = "_blank";
    a.style.display = "none";
    a.click();
    document.body.removeChild(a);
    handleClose();
  }

  const recoveryMenuBar = [
    {
      title: "Recover",
      icon: <RcoveryIcon />,
      onClick: () => recoverAssets([rightClickItem?.id]),
    },
    {
      title: "Delete",
      icon: <Delete fontSize="small" />,
      onClick: ()=>onPermanetlyDeleteAssets(rightClickItem),
    },
  ];

  const assetsMenuBar = [
    {
      title: "Preview",
      icon: <PreviewIcon />,
      onClick: onClickPreview,
      type:
        rightClickItem?.type === "folder" ||
        rightClickItem?.type === "doc" ||
        rightClickItem?.type === "docx"
          ? "folder"
          : "",
    },
    {
      title: "Favorite",
      icon: <FavoriteIcon fontSize="small" />,
      onClick: markasfavorate,
    },
    {
      title: "Download",
      icon: <DownloadIcon fontSize="small" />,
      onClick: () => {
        downloadMedia(rightClickItem);
      },
      type: rightClickItem?.type === "folder" ? "folder" : "",
    },
    {
      title: "Rename",
      icon: <RenameIcon fontSize="small" />,
      onClick: renameAssestFun,
    },
    {
      title: "Move",
      icon: <MoveIcon fontSize="small" />,
      onClick: onMoveFolder,
    },
    {
      title: "Delete",
      icon: <Delete fontSize="small" />,
      onClick: () => onDeleteAssets(rightClickItem),
    },
  ];

  const handleClick = (e, item) => {
    setRightMenuBar(e.currentTarget);
    setRightClickItem(item);
    // dispatch(assetsActions.onSelectMediaAssets(item));
  };
  const cancelMoveFileAction = () => {
    setMoveItem(null);
    setSelectedMoveItems([]);
    setSelectedFolder("");
    setIsMoveClicked(true);
    setOpenGridViewOnUploadeAsset("");
    dispatch(assetsActions.onChange("selectedMediaAssets", []));
  };

  const moveFiles = () => {
    var moveAssetStatus = true;
    var fileDestinationFoler = 0;
    if (selectedFolder !== "" && selectedFolder.id !== undefined) {
      fileDestinationFoler = selectedFolder.id;
    }
    setLoading(true);
    _.forEach(selectedMediaAssets, function (assetId) {
      if (assetId === fileDestinationFoler) {
        moveAssetStatus = false;
      }
    });
    if (moveAssetStatus) {
      dispatch(
        assetsActions.onMoveMediaFiles(
          selectedMediaAssets,
          fileDestinationFoler
        )
      ).then((data) => {
        if (data.success) {
          setOpenAssetActionUndo("move");
          setTimeout(() => {
            setOpenAssetActionUndo("");
          }, 3000);
        }
        dispatch(assetsActions.onChange("selectedMediaAssets", []));
        setFolderData(selectedFolder);
        loadData();
        setSelectedFolder("");
      });
    } else {
      toast.error("Already in the same folder!");
      dispatch(assetsActions.onChange("selectedMediaAssets", []));
      setSelectedFolder("");
      
    }
    setIsMoveClicked(true);
    setSelectedMoveItems([]);
    setLoading(false);
  };

  const deleteAssetFiles = () => {
    setOpenDeleteFilesOverlay(false);
    cancelMoveFileAction();
    if(isTrashedItem){
      deleteAssetsPermanently(selectedMediaAssets);
    }else{
      deleteAssetsMedia(selectedMediaAssets);
    }
  };

  const uploadAssetsFolderSelection = () => {
    setIsUpload(true);
  };

  const loadFoldersForUpload = useCallback(() => {
    dispatch(
      assetsActions.onGetAssetManagementListForPopup(
        parentId === null ? 0 : parentId,
        "folder"
      )
    );
  }, [parentId, dispatch]);

  useEffect(() => {
    loadFoldersForUpload();
  }, [loadFoldersForUpload, parentId, isUpload]);

  const removeAssetsActionDetials = () => {
    setOpenAssetActionUndo("");
  };

  const actionSuccessToast = () => {
    if (openAssetActionUndo === "trash") {
      return (
        <AssetsActionDetials
          id={"trash"}
          text={"files successfully moved to trash"}
          removeAssetsActionDetials={removeAssetsActionDetials}
        />
      );
    } else if (openAssetActionUndo === "delete") {
      return (
        <AssetsActionDetials
          id={"trash"}
          text={"file(s) successfully permanently deleted"}
          removeAssetsActionDetials={removeAssetsActionDetials}
        />
      );
    } else if (openAssetActionUndo === "move") {
      return (
        <AssetsActionDetials
          text={"file(s) successfully moved"}
          id={"move"}
          removeAssetsActionDetials={removeAssetsActionDetials}
        />
      );
    } else if (openAssetActionUndo === "upload") {
      return (
        <AssetsActionDetials
          text={"file(s) successfully uploaded"}
          id={"upload"}
          removeAssetsActionDetials={removeAssetsActionDetials}
        />
      );
    }else if (openAssetActionUndo === "recover") {
      return (
        <AssetsActionDetials
          text={"file(s) successfully recovered"}
          id={"upload"}
          removeAssetsActionDetials={removeAssetsActionDetials}
        />
      );
    }
  };
  console.log({gridData ,order , view})
  return (
    <Dashbard>
      <div className="body-content">
        {isLoading ? <Loader /> : null}
        <div className=" hedingAsstesHome gridBody1">
          <span>Aquatic AV’s Asset Library</span>

          <span className="mediaLibNav">
            <Button
              color="primary"
              variant="outlined"
              className="headerButton"
              onClick={() => setAddFolder(true)}
            >
              New Folder
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="headerButton"
              startIcon={<BiUpload />}
              onClick={() => uploadAssetsFolderSelection()}
            >
              Upload Assets
            </Button>
          </span>
        </div>
        <AnimatedTabs
          tabLabels={ASSETS_TABS}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          setIsTrashedItem={setIsTrashedItem}
          setTabName={setTabName}
        />
        <div className="gridBody contentBody">
          <LibraryOverview />
        </div>

        {actionSuccessToast()}

        <div className="gridBody contentBody">
          <GridFilter
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadData()}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            order={order}
            setView={(value) => {
              setOrderBy({...order, orderVal: 1});
              setView(value);
              loadData();
            }}
            view={view}
          />
          {view === "list" ? (
            <MediaLibraryList
              mediaAssetsData={gridData}
              startLoading={() => setLoading(true)}
              endLoading={() => setLoading(false)}
              loadData={() => loadData()}
              order={order}
              onOrderBy={setOrderBy}
              parent={parent}
              setPage={setPage}
              onParentId={setParent}
              setSelectedMediaFile={setSelectedMediaFile}
              setFolderData={setFolderData}
              renderSelectedData={renderSelectedData}
              renderAllData={renderAllData}
              breadCrumbs={breadCrumbs}
              selectedMediaFile={selectedMediaFile}
              page={page}
              rowsPerPage={rowsPerPage}
              folderPreView={folderPreView}
              setFolderPreView={setFolderPreView}
              markAsFav={markAsFav}
              setOpenDeleteFilesOverlay={setOpenDeleteFilesOverlay}
              onDeleteAssets={onDeleteAssets}
              onPermanetlyDeleteAssets={onPermanetlyDeleteAssets}
              isTrashedItem={isTrashedItem}
              MediaLibraryList={MediaLibraryList}
              recoverAssets={recoverAssets}
              tabName={tabName}
              setIsMoveClicked={setIsMoveClicked}
              setSelectedMoveItems={setSelectedMoveItems}
              isMoveClicked={isMoveClicked}
              moveItem={moveItem}
              selectedMoveItems={selectedMoveItems}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              openGridViewOnUploadeAsset={openGridViewOnUploadeAsset}
            />
          ) : (
            <AssetLibraryGridView
              mediaAssetsData={gridData}
              startLoading={() => setLoading(true)}
              endLoading={() => setLoading(false)}
              loadData={() => loadData()}
              order={order}
              onOrderBy={setOrderBy}
              parent={parent}
              setPage={setPage}
              onParentId={setParent}
              setSelectedMediaFile={setSelectedMediaFile}
              setFolderData={setFolderData}
              renderSelectedData={renderSelectedData}
              renderAllData={renderAllData}
              breadCrumbs={breadCrumbs}
              rightManuBar={rightManuBar}
              closeTooltip={closeTooltip}
              setCloseTooltip={setCloseTooltip}
              handleClick={handleClick}
              handleClose={handleClose}
              markAsFav={markAsFav}
              isRenameAssest={isRenameAssest}
              renameAssetItem={renameAssetItem}
              setRenameAssetItem={setRenameAssetItem}
              saveRenameAssetItem={saveRenameAssetItem}
              moveItem={moveItem}
              setIsDraggableState={setIsDraggableState}
              isDraggableState={isDraggableState}
              // selectMediaAssets={selectMediaAssets}
              // checkMediaAssetsSelected={checkMediaAssetsSelected}
              rightClickItem={rightClickItem}
              selectedMoveItems={selectedMoveItems}
              setSelectedMoveItems={setSelectedMoveItems}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              cancelMoveFileAction={cancelMoveFileAction}
              setOpenDeleteFilesOverlay={setOpenDeleteFilesOverlay}
              isMoveClicked={isMoveClicked}
              setIsMoveClicked={setIsMoveClicked}
              openGridViewOnUploadeAsset={openGridViewOnUploadeAsset}
              isTrashedItem={isTrashedItem}
              recoverAssets={recoverAssets}
              tabName={tabName}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              onFavClick={onFavClick}
              // setUploadeFolderData={setUploadeFolderData}
            />
            // ) : (
            //   <AssetLibraryGridView
            //     mediaAssetsData={gridData}
            //     startLoading={() => setLoading(true)}
            //     endLoading={() => setLoading(false)}
            //     loadData={() => loadData()}
            //     order={order}
            //     onOrderBy={setOrderBy}
            //     parent={parent}
            //     setPage={setPage}
            //     onParentId={setParent}
            //     setSelectedMediaFile={setSelectedMediaFile}
            //     setFolderData={setFolderData}
            //     renderSelectedData={renderSelectedData}
            //     renderAllData={renderAllData}
            //     breadCrumbs={breadCrumbs}
            //     markAsFav={markAsFav}
            //   />
          )}
          {isTrashedItem ? (
            <DeleteFilesOverlay
              openDeleteFilesOverlay={openDeleteFilesOverlay}
              setOpenDeleteFilesOverlay={setOpenDeleteFilesOverlay}
              deleteAssetFiles={deleteAssetFiles}
              overlayText1={
                "Are you sure you want to Delete the selected file(s) permanently?"
              }
              overlayText2={"Deleted file(s) will not be recovered"}
              buttonText={"Delete Permanently"}
            />
          ) : (
            <DeleteFilesOverlay
              openDeleteFilesOverlay={openDeleteFilesOverlay}
              setOpenDeleteFilesOverlay={setOpenDeleteFilesOverlay}
              deleteAssetFiles={deleteAssetFiles}
              overlayText1={
                "Are you sure you want to move the selected files to Trash?"
              }
              overlayText2={
                "Files moved to Trash can be recovered within 30 days."
              }
              buttonText={"Move to Trash"}
            />
          )}

          {selectedFolder === "" && openGridViewOnUploadeAsset === "" ? (
            <Pagination
              recordCount={paginationResponse?.totalRecords}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              view={view}
            />
          ) : (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => cancelMoveFileAction()}
                style={{ margin: "25px 5px" }}
              >
                Cancel
              </Button>
              {openGridViewOnUploadeAsset === "" ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    if (selectedFolder !== "") {
                      moveFiles();
                      cancelMoveFileAction();
                    } else if (openGridViewOnUploadeAsset !== "") {
                      setIsUpload(true);
                    }
                  }}
                  style={{ margin: "25px 5px" }}
                  color="primary"
                >
                  Choose
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    if (openGridViewOnUploadeAsset !== "") {
                      setFolderData(selectedFolder);
                      setIsUpload(true);
                    }
                  }}
                  disabled={
                    selectedFolder === "" && openGridViewOnUploadeAsset !== ""
                      ? true
                      : false
                  }
                  style={{ margin: "25px 5px" }}
                  color="primary"
                >
                  Choose
                </Button>
              )}
            </Grid>
          )}

          {addFolder && (
            <NewFolderPopup
              currentFolder={parent}
              openPopup={addFolder}
              closePopup={() => setAddFolder(false)}
              startLoading={() => setLoading(true)}
              endLoading={() => setLoading(false)}
              loadData={() => loadData()}
              parent={parent}
              onParentId={setParent}
            />
          )}

          {isUpload && (
            <MediaUploadPopup
              currentFolder={parent}
              openPopup={isUpload}
              closePopup={() => setIsUpload(false)}
              startLoading={() => setLoading(true)}
              endLoading={() => setLoading(false)}
              loadData={() => loadData()}
              parent={parent}
              onParentId={setParent}
              setView={(value) => {
                setView(value);
                loadData();
              }}
              setOpenGridViewOnUploadeAsset={setOpenGridViewOnUploadeAsset}
              setIsMoveClicked={setIsMoveClicked}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              setOpenAssetActionUndo={setOpenAssetActionUndo}
              setFolderData={setFolderData}
            />
          )}
        </div>
        <div className="assetsMenuBar">
          <RightClickAssestPreview
            rightManuBar={rightManuBar}
            handleClose={handleClose}
            assetsMenuBar={isTrashedItem ? recoveryMenuBar : assetsMenuBar}
          />
        </div>
      </div>
      <AssestPreview
        open={open}
        closeDialog={handleClosePreview}
        previewItem={previewItem}
      />
      {showMovePopup && (
        <MoveMediaFilesPopup
          openPopup={showMovePopup}
          closePopup={() => setShowMovePopup(false)}
          startLoading={() => setLoading(true)}
          endLoading={() => setLoading(false)}
          loadData={() => loadData()}
        />
      )}
    </Dashbard>
  );
}

export default AssetManagement;
