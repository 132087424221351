import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
// import SelectImagesTabs from "./SelectImagesTabs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
import SelectInput from "../../../../../components/common/SelectInput";
import EmptyTextarea from "../../../../../components/common/Textarea";
import { Button } from "@material-ui/core";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import CustomCheckbox from "../../../../../components/common/checkbox/CustomCheckbox";
import SelectImage from "./selectImage";
import { BiX } from "react-icons/bi";
import { toast } from "react-toastify";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridOuterWrapper: {
    position: "relative",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function Leadership({ reloadData, startLoading, stopLoading }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const classes = useStyles();

  const changeLeaderValue = (value, index, fieldName) => {
    dispatch(newsroomActions.onChangeLeaderValue(index, value, fieldName));
  };

  const uploadCompanyImage = (file) => {
    startLoading();
    dispatch(contactsActions.onCompanyUploadImage(file)).then((data) => {
      if (data && data.success && data.data) {
        dispatch(
          newsroomActions.onChangeLeaderValue(
            imageIndex,
            data.data.url,
            "leadershipImageURL"
          )
        );
        setShowFeatureImage(!showFeatureImage);
      }
      stopLoading();
    });
  };

  const onUpdateLeadershipPress = () => {
    let isError = false;
    let leader = [];
    _.forEach(leadersValue, function (ldVal) {
      if (ldVal.name.trim() !== "") {
        if (ldVal.name.length > 50 || ldVal.position.length > 50) {
          isError = true;
        } else {
          const res = {
            newsRoomId: 1,
            name: ldVal.name,
            position: ldVal.position,
            leadershipImageURL: ldVal.leadershipImageURL,
          };
          leader.push(res);
        }
      }
    });
    if (!isError) {
      startLoading();
      dispatch(newsroomActions.onSaveLeaderShip(leader)).then((ldr) => {
        stopLoading();
        if (ldr && ldr.success) {
          reloadData();
          toast.success("Leadership updated successfully");
        } else {
          toast.error(ldr.message);
        }
      });
    }
  };

  const { leadersValue } = useSelector((state) => state.newsRoomReducer);
  return (
    <>
      <div className="gap" />
      <div className="accordianOuterWrapper">
        <AccordionContainer title="Leadership">
          <div className={classes.root}>
            {leadersValue.length > 0 &&
              leadersValue.map((item, index) => {
                return (
                  <Grid
                    container
                    key={index}
                    spacing={3}
                    className={classes.gridOuterWrapper}
                  >
                    <Grid item xs={5} className={classes.gridWrapper}>
                      <InputField
                        placeholder={`${"Leader "}${index + 1}${" Name"}`}
                        inputlabel={`${"Leader "}${index + 1}${" Name"}`}
                        name="leaderName"
                        value={item.name}
                        onChange={(e) =>
                          changeLeaderValue(e.target.value, index, "name")
                        }
                      />
                      {item.name.length > 50 && (
                        <span style={{ color: "red" }}>
                          leader name characters length cannot be exceed from 50
                          characters
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      className={[
                        classes.gridWrapper,
                        classes.gridOuterWrapper,
                      ]}
                    >
                      <InputField
                        placeholder={`${"Leader "}${index + 1}${" Position"}`}
                        inputlabel={`${"Leader "}${index + 1}${" Position"}`}
                        name="leaderPosition"
                        value={item.position}
                        onChange={(e) =>
                          changeLeaderValue(e.target.value, index, "position")
                        }
                      />
                      {item.position.length > 50 && (
                        <span style={{ color: "red" }}>
                          leader position characters length cannot be exceed
                          from 50 characters
                        </span>
                      )}
                      <span className="deleteRow">
                        <BiX
                          onClick={() =>
                            dispatch(newsroomActions.removeLeader(index))
                          }
                        />
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={[classes.gridWrapper, "buttonOutlined"]}
                    >
                      <label>{`${"Leader "}${index + 1}${" Photo"}`}</label>
                      <div className="leaderShipUploadPic">
                        {item.leadershipImageURL !== "" && (
                          <img
                            className="featureRoundImg"
                            src={item.leadershipImageURL}
                          />
                        )}
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.selectBtn}
                          onClick={() => {
                            setImageIndex(index);
                            setShowFeatureImage(!showFeatureImage);
                          }}
                        >
                          {item.leadershipImageURL !== "" &&
                          item.leadershipImageURL !== "string"
                            ? "Change"
                            : "Select"}
                        </Button>
                      </div>
                      {showFeatureImage && (
                        <SelectImage
                          closePopup={() => setShowFeatureImage(false)}
                          openPopup={showFeatureImage}
                          title={"Upload Leader Image"}
                          addProfileImage={(value) => uploadCompanyImage(value)}
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            <div className="gap" />
            <span className="addleaderBtn">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => dispatch(newsroomActions.addNewLeader())}
              >
                Add Leader
              </Button>
            </span>

            <span className="saveChangesBtn ">
              <Button
                variant="contained"
                color="primary"
                onClick={() => onUpdateLeadershipPress()}
              >
                save changes
              </Button>
            </span>
          </div>
        </AccordionContainer>
      </div>
    </>
  );
}
