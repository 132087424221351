import React from "react";
import { WaterTreatmentReplace } from "../SpaProduct/SvgIcons";
import moment from "moment";
import { useSelector } from "react-redux";

export const WaterReplaceTableBanner = () => {
  const { filterLifeStats } = useSelector((state) => state.endUserReducer);

  return (
    <div className="watertratmentContainer">
      <h1>Filter Life</h1>
      <div className="waterTreatmeantDetails replacementBannerTable">
        <WaterTreatmentReplace />
        <span className="daysWaterTreatment">
          {filterLifeStats?.replacedDays ?? '0'} Days<br></br>
          <i>since filter has been replaced</i>
        </span>

        {filterLifeStats?.recommendedCleaningDate && <span className="daysWaterRecommended">
          Recommended Cleaning:<br></br>
          <i>
            {moment(filterLifeStats?.recommendedCleaningDate).diff(moment(new Date()), 'days')} days away <span> |</span> {filterLifeStats?.recommendedCleaningDate ?? "MM DD, YYYY"}
          </i>
        </span>}
        {(filterLifeStats?.recommendedReplacingDate && !filterLifeStats?.recommendedCleaningDate) && <span className="daysWaterRecommended">
          Recommended Replacing:<br></br>
          <i>
            {moment(filterLifeStats?.recommendedReplacingDate).diff(moment(new Date()), 'days')} days away <span> |</span> {filterLifeStats?.recommendedReplacingDate ?? "MM DD, YYYY"}
          </i>
        </span>}
        <span className="daysWaterReplacementTable">
          Last Cleaned:<br></br>
          <i>{filterLifeStats?.lastCleaned ?? '--'}</i>
        </span>
      </div>
    </div>
  );
};
