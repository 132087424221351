import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { downarrow } from "../../resources/images/index";

const Accordion = withStyles({
  root: {
    // boxShadow: "0px 2px 7px 0px #70808f",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "&:not(:first-child)": {
      marginTop: "35px",
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      boxShadow: "0px 0px 2px 0px #70808f",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      margin: "12px 0",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "transparent !important",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: "block",
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    color:"#000",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  AccordionTypography: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subTitle: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#2D3339",
    marginLeft: "35px",
  },
}));

const AccordionContainer = ({
  title,
  subTitle,
  children,
  formButton,
  className,
  disabled = false,
  isExpanded = true,
  onCollapsed = () => {},
  accordionId = "summary-header",
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(isExpanded);

  useEffect(() => {
    if (disabled === true) setExpanded(false);
  }, [disabled]);

  useEffect(() => {
    onCollapsed(!expanded);
  }, [expanded, onCollapsed]);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <Accordion
      square
      expanded={expanded}
      disabled={disabled}
      onChange={() => setExpanded(!expanded)}
      className={`contentBody accordianBody${
        className ? ` ${className}` : ""
      }`}>
      <div className={classes.AccordionTypography}>
        <AccordionSummary aria-controls='summary' id={accordionId}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.mainHeading}>
                <img
                  src={downarrow}
                  alt='arrow icon'
                  className={`arrow-icon ${expanded ? "arrow-invert" : ""}`}
                />
                {title || "Title Here"}
              </Typography>
            </Grid>
            {subTitle && (
              <Grid item className={classes.subTitle}>
                {subTitle}
              </Grid>
            )}
          </Grid>
        </AccordionSummary>

        {formButton && <div className='formButton'>{formButton}</div>}
      </div>

      <AccordionDetails className='accordianDetails accordianDetailsCenter'>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export { AccordionContainer };
