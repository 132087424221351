import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos } from "@material-ui/icons";
import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import Dashbard from "../Dashbard";
import SoftwareReleaseAccordian from "./SoftwareReleaseAccordian";
import { useState } from "react";
import { SelectInput } from "../../../../components/common";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SoftwareReleaseEdit from "./SoftwareReleaseEdit";
import { useDispatch, useSelector } from "react-redux";
import { useLoadDataGet } from "../../../../utils/use-load-data";
import { endpoints } from "../../../../config/apiConfig";
import { softwareBuildActions } from "../../../../store/softwareUpdates/SoftwareBuildActions";


const releaseStatus = [
  { id: 1, name: "Creating" },
  { id: 2, name: "Live" },
  { id: 3, name: "Retired" },
  { id: 4, name: "In Testing" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
}));

const buttonTextStyle = {
  color: "#3699FF",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "0.28px",
};

const NewSoftwareBuild = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    selectedBuild,
    isLoading,
    softwareReleaseBuildData,
    contactBuildIds,
    groupBuildIds,
    companyBuildIds,
    portalBuildIds,
    selectedBuildPortal,
    selectedBuildContacts,
    poratlAndContactBuildData,
    customerContactBuildData,
    productsBuildList,
    compatibleBuildProductsList,
    selectedBuildProducts,
    notificationBuildTypes,
    selectedBuildNotifications,
    softwareBuildDetials
  } = useSelector(state => state.softwareBuildReducer);

  const { records: portalsList } = useLoadDataGet({
    url: endpoints.portal.getAllPortals,
  });
  const [statusClass, setStatusClass] = useState("newSoftwareHeaderSelectCreating");
  const [updatedTempData, setUpdatedTempData] = useState([]);
  const [filteredProtalData, setFilteredProtalData] = useState([]);
  const [releaseNoteSelectedData, setReleaseNoteSelectedData] = useState("");
  const { id: softwareReleaseId, buildId } = useParams();
  const [notificationData, setNotificationData] = useState({
    id: 0,
    portalId: 0,
    softwareReleaseId: Number(softwareReleaseId),
    softwareReleaseBuildId: Number(buildId),
    notificationTypeId: 0,
    isImmediate: true,
    afterReleaseValue: 0,
    afterReleaseTimeValue: 1,
    subject: "",
    content: "",
  });

  useEffect(() => {
    return () => {
      dispatch(softwareBuildActions.onResetSoftwareBuildData());
    };
  }, []);

  useEffect(() => {
    if (selectedBuild.id) {
      setNotificationData({
        ...notificationData,
        softwareReleaseId: selectedBuild.softwareReleaseId,
        softwareReleaseBuildId: selectedBuild.id
      });
    }
  }, [selectedBuild]);

  useEffect(() => {
    releaseBasicsProps('softwareReleaseId', Number(softwareReleaseId));
  }, [softwareReleaseId]);

  useEffect(() => {
    switch (selectedBuild?.version) {
      case 1:
        setStatusClass("newSoftwareHeaderSelectCreating");
        break;
      case 2:
        setStatusClass("newSoftwareHeaderSelectActive");
        break;
      case 3:
        setStatusClass("newSoftwareHeaderSelectInactive");
        break;
      case 4:
        setStatusClass("newSoftwareHeaderSelectDraft");
        break;
      default:
        setStatusClass("newSoftwareHeaderSelectCreating");
        break;
    }
  }, [selectedBuild.version]);

  const saveBasicInfo = (releaseData) => {
    const data = { ...releaseData, namePublic: releaseData.namePublic ? releaseData.namePublic.trim() : releaseData.nameInternal };
    dispatch(softwareBuildActions.onSaveBuildInfo(data));
  };

  const fetchRequiredData = useCallback(() => {
    dispatch(softwareBuildActions.onGetReleaseCompatibleProducts());
    dispatch(softwareBuildActions.onGetContactsForBuild('All'));
    dispatch(softwareBuildActions.onGetNotificationsType());
  }, [dispatch]);


  const releaseBasicsProps = (propName, value) => {
    dispatch(softwareBuildActions.onSelectedReleaseFieldChange({ propName, value }));
  };

  const portalAndContactBasicsProps = ({ propName, value }) => {
    dispatch(
      softwareBuildActions.onPoratalAndContactFieldChange({ propName, value })
    );
  };

  const tempContactData = (newAddedData) => {
    let key = "";
    switch (newAddedData.generic) {
      case "Company":
        key = "companyBuildIds";
        break;
      case "Business Customer":
      case "End Customer": {
        key = "groupBuildIds";
        break;
      }
      default:
        key = "contactBuildIds";
        break;
    }

    dispatch(
      softwareBuildActions.updateSelectedBuildContacts(key, newAddedData.id)
    );

    setUpdatedTempData([...updatedTempData, newAddedData]);
  };
  
  const changeContactTab = (newTab)=>{
    dispatch(softwareBuildActions.onGetContactsForBuild(newTab))
  }

  useEffect(() => {
    if (buildId) {
      const updatedRecords = selectedBuildContacts.map((item) => {
        const newItem = {
          id: item.contactId
            ? item.contactId
            : item.companyId
              ? item.companyId
              : item.groupId,
          value: item.contactId
            ? `${item.contact.firstName} ${item.contact.lastName}`
            : item.companyId
              ? item.company.name
              : item.group.groupName,
          generic: item.contactId
            ? "Contact"
            : item.companyId
              ? "Company"
              : "Group",
        };
        let key = "";
        if (newItem.generic === "Contact") {
          key = "contactBuildIds";
        } else if (newItem.generic === "Group") {
          key = "groupBuildIds";
        } else {
          key = "companyBuildIds";
        }
        
        dispatch(
          softwareBuildActions.updateSelectedBuildContacts(key, newItem.id)
        );

        return newItem;
      });
      setUpdatedTempData([...updatedRecords]);
    }
  }, [buildId, selectedBuildContacts]);

  const handleDeletedChip = (removedItem) => {
    let key = "";
    switch (removedItem.generic) {
      case "Company":
        key = "companyBuildIds";
        break;
      case "Group":
      case "Business Customer":
      case "End Customer": {
        key = "groupBuildIds";
        break;
      }
      default:
        key = "contactBuildIds";
        break;
    }

    dispatch(
      softwareBuildActions.removeSelectedReleaseContacts(key, removedItem.id)
    );

    setUpdatedTempData((prevData) => prevData.filter((item) => item.id !== removedItem.id));
  };

  const tempPortalData = (id) => {
    const newAddedData = filteredProtalData.find((item) => item.id === id);

    dispatch(softwareBuildActions.onChange("portalBuildIds", [...portalBuildIds, id]));
    dispatch(
      softwareBuildActions.onChange("selectedBuildPortal", [
        ...selectedBuildPortal,
        newAddedData,
      ])
    );
  };

  const deletePortalSelectedDevices = (item) => {
    const newPortals = selectedBuildPortal.filter(
      (portal) => portal.id !== item.id
    );
    dispatch(softwareBuildActions.onChange("portalBuildIds", [...portalBuildIds.filter(portalId => portalId !== item.id)]));
    dispatch(
      softwareBuildActions.onChange(
        "selectedBuildPortal",
        [...newPortals]
      )
    );
  }

  const onsubmitPortalContact = () => {
    const requirementsData = {
      softwareReleaseId: selectedBuild?.softwareReleaseId,
      softwareReleaseBuildId: selectedBuild?.id,
      contactIds: contactBuildIds,
      groupIds: groupBuildIds.map((contactId) => Number(contactId)),
      companyIds: companyBuildIds.map((companyId) => Number(companyId)),
      portalIds: portalBuildIds,
      ...poratlAndContactBuildData,
    };
    dispatch(softwareBuildActions.onSaveAddUpdateSRBuildPortalandContacts(requirementsData));
  };
   
  const removeEligibleSelectedDevice = (device) => {
    const newDevices = selectedBuildProducts.filter(
      (x) => x.productId !== device.productId
    );
    dispatch(softwareBuildActions.onChange("selectedBuildProducts", [...newDevices]))
  };

  const handleAddEligibleDevice = () => {
    const releaseProducts = selectedBuildProducts?.map((device) => {
      const productId = parseInt(device.productId);
      return {
        softwareReleaseId: selectedBuild?.softwareReleaseId,
        softwareReleaseBuildId: selectedBuild?.id,
        id: device.id ?? 0,
        productId: productId,
        liveVersion: "",
      };
    });

    dispatch(softwareBuildActions.onAddUpdateBuildProducts(releaseProducts));
  };

  const submitNotificationData = (portalNotification) => {
    dispatch(softwareBuildActions.onAddUpdateSoftwareReleaseBuildNotification(portalNotification));
    setNotificationData({id: 0,
      portalId: 0,
      softwareReleaseId: Number(softwareReleaseId),
      softwareReleaseBuildId: Number(buildId),
      notificationTypeId: 0,
      isImmediate: true,
      afterReleaseValue: 0,
      afterReleaseTimeValue: 1,
      subject: "",
      content: "",
    });
  };

  const handleDeletedReleaseNotification = (id) => {
    dispatch(softwareBuildActions.onDeleteSoftwareReleaseBuildNotifiacation(id, selectedBuild.id));
  };

  const onSubmitRealsePlan = () => {
    dispatch(softwareBuildActions.onAddSoftwareReleaseBuildDetails(releaseNoteSelectedData));
    setReleaseNoteSelectedData("");
  }

  useEffect(() => {
    if (buildId) {
      dispatch(softwareBuildActions.onGetSoftwareBuildById(buildId));
      dispatch(softwareBuildActions.onGetSoftwareContactsReleaseBuildById(buildId));
      dispatch(softwareBuildActions.onGetSoftwareReleaseBuildPortalById(buildId));
      dispatch(
        softwareBuildActions.onGetSoftwareReleaseBuildProducts({
          softwareReleaseId: softwareReleaseId,
          softwareReleaseBuildId: buildId,
          productName: "",
          liveVersion: "",
          brand: "",
          category: "",
          sku: "",
        })
      );
      dispatch(softwareBuildActions.onGetSoftwareReleaseBuildDetails(buildId));
      dispatch(softwareBuildActions.onGetSoftwareReleaseBuildNotification(buildId));
    }
  }, [buildId]);

  return (
    <Dashbard>
      <div className="body-content newDesign software">
        <div className="contentHeading">
          <div className="newHead">
            <h1>
              {!buildId ? "New Software Build" : selectedBuild.nameInternal}
            </h1>
            <div
              style={{
                position: "absolute",
                top: "40px",
              }}
            >
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  history.push(`/software-builds/${softwareReleaseId}`);
                }}
              >
                <ArrowBackIos style={{ fontSize: "14px" }} />
                <span style={buttonTextStyle}>Back to All Software Builds</span>
              </Button>
            </div>
            <div className="newHeaderButtonContainer">
              <div>
                <Button
                  variant="outlined"
                  className="skipButton"
                  onClick={() => history.push("/software-releases")}
                >
                  Cancel
                </Button>
              </div>
              <div className={statusClass}>
                <SelectInput
                  placeholder="Please Select"
                  value={selectedBuild?.version ?? 1}
                  customMenuItems={releaseStatus}
                  disablePlaceholderOption={true}
                  handleChange={({ target }) =>
                    dispatch(
                      softwareBuildActions.onSelectedReleaseFieldChange({
                        propName: "version",
                        value: target.value,
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Grid className={classes.root}>
          <TopTabNavigator
            tabs={[
              {
                id: 1,
                title: "New Software Build",
                component: [
                  !buildId ? (
                    <SoftwareReleaseAccordian
                      formName={"Build"}
                      selectedRelease={selectedBuild}
                      saveBasicInfo={saveBasicInfo}
                      isLoading={isLoading}
                      releaseBasicsProps={releaseBasicsProps}
                      fetchRequiredData={fetchRequiredData}
                      contactIds={contactBuildIds}
                      groupIds={groupBuildIds}
                      companyIds={companyBuildIds}
                      portalIds={portalBuildIds}
                      selectedReleasePortal={selectedBuildPortal}
                      selectedReleaseContacts={selectedBuildContacts}
                      poratlAndContactData={poratlAndContactBuildData}
                      customerContactData={customerContactBuildData}
                      tempContactData={tempContactData}
                      setUpdatedTempData={setUpdatedTempData}
                      updatedTempData={updatedTempData}
                      handleDeletedChip={handleDeletedChip}
                      filteredProtalData={filteredProtalData}
                      setFilteredProtalData={setFilteredProtalData}
                      tempPortalData={tempPortalData}
                      onsubmitPortalContact={onsubmitPortalContact}
                      productsList={productsBuildList}
                      compatibleProductsList={compatibleBuildProductsList}
                      selectedReleaseProducts={selectedBuildProducts}
                      removeEligibleSelectedDevice={
                        removeEligibleSelectedDevice
                      }
                      handleAddEligibleDevice={handleAddEligibleDevice}
                      notificationTypes={notificationBuildTypes}
                      selectedReleaseNotifications={
                        selectedBuildNotifications
                      }
                      softwareReleaseDetials={softwareBuildDetials}
                      notificationData={notificationData}
                      setNotificationData={setNotificationData}
                      setReleaseNoteSelectedData={setReleaseNoteSelectedData}
                      releaseNoteSelectedData={releaseNoteSelectedData}
                      submitNotificationData={submitNotificationData}
                      handleDeletedReleaseNotification={
                        handleDeletedReleaseNotification
                      }
                      onSubmitRealsePlan={onSubmitRealsePlan}
                      portalAndContactBasicsProps={portalAndContactBasicsProps}
                      portalListData={portalsList}
                      deletePortalSelectedDevices={deletePortalSelectedDevices}
                      changeContactTab={changeContactTab}
                    />
                  ) : (
                    <SoftwareReleaseEdit
                      formName={"Build"}
                      selectedRelease={selectedBuild}
                      fetchRequiredData={fetchRequiredData}
                      softwareReleaseData={softwareReleaseBuildData}
                      saveBasicInfo={saveBasicInfo}
                      isLoading={isLoading}
                      releaseBasicsProps={releaseBasicsProps}
                      contactIds={contactBuildIds}
                      groupIds={groupBuildIds}
                      companyIds={companyBuildIds}
                      portalIds={portalBuildIds}
                      selectedReleasePortal={selectedBuildPortal}
                      selectedReleaseContacts={selectedBuildContacts}
                      poratlAndContactData={poratlAndContactBuildData}
                      customerContactData={customerContactBuildData}
                      tempContactData={tempContactData}
                      setUpdatedTempData={setUpdatedTempData}
                      updatedTempData={updatedTempData}
                      handleDeletedChip={handleDeletedChip}
                      filteredProtalData={filteredProtalData}
                      setFilteredProtalData={setFilteredProtalData}
                      tempPortalData={tempPortalData}
                      onsubmitPortalContact={onsubmitPortalContact}
                      productsList={productsBuildList}
                      compatibleProductsList={compatibleBuildProductsList}
                      selectedReleaseProducts={selectedBuildProducts}
                      removeEligibleSelectedDevice={
                        removeEligibleSelectedDevice
                      }
                      handleAddEligibleDevice={handleAddEligibleDevice}
                      notificationTypes={notificationBuildTypes}
                      selectedReleaseNotifications={
                        selectedBuildNotifications
                      }
                      softwareReleaseDetials={softwareBuildDetials}
                      notificationData={notificationData}
                      setNotificationData={setNotificationData}
                      setReleaseNoteSelectedData={setReleaseNoteSelectedData}
                      releaseNoteSelectedData={releaseNoteSelectedData}
                      submitNotificationData={submitNotificationData}
                      handleDeletedReleaseNotification={
                        handleDeletedReleaseNotification
                      }
                      onSubmitRealsePlan={onSubmitRealsePlan}
                      portalAndContactBasicsProps={portalAndContactBasicsProps}
                      portalListData={portalsList}
                      deletePortalSelectedDevices={deletePortalSelectedDevices}
                      changeContactTab={changeContactTab}
                    />
                  ),
                ],
              },
            ]}
          />
        </Grid>
      </div>
    </Dashbard>
  );
};

export default NewSoftwareBuild;
