import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#3699FF"
    },
    secondary: {
      main: "#1867b9"
    }
  },
  typography: {
    fontSize: 12
    // fontSize: "32px"
  },

  props: {
    MuiButton: {
      disableRipple: "true"
    },
    MuiTextField: {
      disableRipple: "true"
    },
    MuiButtonBase: {
      disableRipple: "true"
    },
    MuiInput: {},
    MuiPaper: {
      disableRipple: "true",
      root: {
        boxShadow: "none"
      }
    },
    MuiTouchRipple: {
      disableRipple: "true"
    },
    MuiCard: {},
    MuiList: {}
  },

  shape: {
    borderRadius: "6px"
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: 14
      }
    },
    MuiInputBase: {
      input: {
        // border: "1px solid  #70808F",
        borderRadius: "6px"
      }
    },
    MuiButton: {
      root: {
        fontSize: "12px",
        padding: "8px 16px",
        fontWeight: "700"
      },
      label: {
        marginTop: "0 !important"
      },
      containedPrimary: {
        color: "#ffffff",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#49B8EF"
        }
      }
    },
    MuiButtonBase: {
      root: {
        disableRipple: "true"
      }
    },

    MuiCardActionArea: {
      root: {
        disableRipple: "true"
      }
    },
    MuiTextField: {
      root: {
        background: "#ffffff",
        //marginTop: "15px",
        borderRadius: "6px",
        disableRipple: "true"
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none"
      },
      root: {
        boxShadow: "none"
      }
    },
    // Mui: {
    //   expanded: {
    //     backgroundColor: "rgba(24, 103, 185, 0.08)",
    //   },
    // },

    MuiSelect: {
      selectMenu: {
        "&:focus": {
          backgroundColor: "transparent",
          border: "none"
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        // borderColor: 'transparent'
      }
    }
    // MuiSvgIcon:{
    //   root:{
    //     width: '2rem',
    //     height: '2rem'
    //   }
    // },
  }
});
