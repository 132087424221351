import React from "react";
import { Button, Grid } from "@material-ui/core";
import GroupAvatar from "./GroupAvatar";
import AddAttributeDateBox from "./AddAttributeDateBox";

const data = [
  {
    title: "Online Marketplace",
    option: "4",
    avatar: <GroupAvatar />,
  },
];

function DateFields({ onclick }) {
  return (
    <>
      <div className='textOptionWrapper'>
        <Grid container spacing={2} xs={12}>
          {data.map((item, index) => {
            return (
              <Grid item xs={6}>
                <AddAttributeDateBox
                  title={item.title}
                  option={item.option}
                  avatar={<GroupAvatar />}
                  onclick={onclick}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className='attributeFooter  attributesTabsfooter'>
        <span>
          <Button color='primary' variant='contained'>
            Next
          </Button>
        </span>
      </div>
    </>
  );
}

export default DateFields;
