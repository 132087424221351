import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { TopNavigator } from "../../../../components/common/Tabs/TopNavigator";
import Dashbard from "../Dashbard";
import { useHistory } from "react-router";
import SoftwareReleases from "./SoftwareReleases";
import { SoftwareReleasePreview } from "./SoftwareReleasePreview";
import { ArrowBackIos } from "@material-ui/icons";
import { AnalyticsPreview } from "./AnalyticsPreview";
import { NotesPreview } from "./NotesPreview";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { softwareUpdateActions } from "../../../../store/softwareUpdates/softwareUpdatesActions";
import { useDebounce } from "../../../../utils/use-debounce";


const SoftwareUpdates = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    selectedRelease,
    selectedVersions,
    paginationResponse,
    isLoading,
    selectedReleasePortal,
    selectedReleaseContacts,
    selectedReleaseProducts,
    poratlAndContactData,
    softwareReleaseDetials,
    selectedReleaseNotifications,
    notificationTypes,
  } = useSelector((state) => state.softwareUpdatesReducer);
  const [activeTab, setActiveTab] = useState(1);
  const { id: selectedReleaseId } = useParams();
  const [order, setOrderBy] = useState({
    orderByKey: "UpdateName",
    orderVal: 0,
  });
  const [tabValue, setTabValue] = useState("all");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [updatedTempData, setUpdatedTempData] = useState([]);

  const { debouncedValue } = useDebounce(search, 500);
  useEffect(() => {
    dispatch(
      softwareUpdateActions.onGetAllReleases({
        search: debouncedValue,
        tab: tabValue === "all" ? "" : tabValue,
        pagination: {
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
          pageSize: rowsPerPage,
          pageNo: page,
        },
      })
    );
  }, [
    dispatch,
    order.orderByKey,
    order.orderVal,
    page,
    rowsPerPage,
    debouncedValue,
    tabValue,
  ]);

  const fetchReleaseData = useCallback(() => {
    if (selectedReleaseId) {
      dispatch(
        softwareUpdateActions.onGetSoftwareReleaseById(selectedReleaseId)
      );
      dispatch(
        softwareUpdateActions.onGetSoftwareReleaseContacts(selectedReleaseId)
      );
      dispatch(
        softwareUpdateActions.onGetSoftwareReleasePortal(selectedReleaseId)
      );
      dispatch(
        softwareUpdateActions.onGetSoftwareReleaseProducts({
          softwareReleaseId: selectedReleaseId,
          productName: "",
          liveVersion: "",
          brand: "",
          category: "",
          sku: "",
        })
      );
      dispatch(
        softwareUpdateActions.onGetSoftwareReleaseDetails(selectedReleaseId)
      );
      dispatch(
        softwareUpdateActions.onGetReleaseNotifications(selectedReleaseId)
      );
    }
  }, [dispatch, selectedReleaseId]);

  useEffect(() => {
    if (selectedReleaseId) {
      const updatedRecords = selectedReleaseContacts.map((item) => {
        const newItem = {
          id: item.contactId
            ? item.contactId
            : item.companyId
              ? item.companyId
              : item.groupId,
          value: item.contactId
            ? `${item.contact.firstName} ${item.contact.lastName}`
            : item.companyId
              ? item.company.name
              : item.group.groupName,
          generic: item.contactId
            ? "Contact"
            : item.companyId
              ? "Company"
              : "Group",
        };
        let key = "";
        if (newItem.generic === "Contact") {
          key = "contactIds";
        } else if (newItem.generic === "Group") {
          key = "groupIds";
        } else {
          key = "companyIds";
        }
        dispatch(
          softwareUpdateActions.updateSelectedReleaseContacts(key, newItem.id)
        );

        return newItem;
      });
      setUpdatedTempData([...updatedRecords]);
    }
  }, [selectedReleaseId, selectedReleaseContacts]);

  const markUnMarkFavouriteDevices = (item) => {
    dispatch(
      softwareUpdateActions.onMarkReleaseFavoriteUnFavorite({
        softwareReleaseId: item.id,
        isFavourite: !item.isFavourite,
      }
      )
    )
  }

  const deleteSoftwareRelease = (id) => {
    dispatch(
      softwareUpdateActions.onDeleteSoftwareRelease(id)
    )
  }

  const buttonTextStyle = {
    color: "#3699FF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.28px",
  };

  return (
    <Dashbard>
      <div className="software_newDesign">
        <div className="body-content newDesign">
          <div className="contentHeading  launchBtn">
            <h1>{!selectedReleaseId ? 'Software Releases' : selectedRelease.nameInternal}</h1>
            <Button
              color="primary"
              variant="contained"
              className="newSoftwareReleaseBtn"
              startIcon={<AddIcon />}
              onClick={() => history.push("/new-software-release")}
            >
              New Software Release
            </Button>
          </div>

          {!selectedReleaseId ? (
            <Grid className="releasePlanTabs">
              <TopNavigator
                tabs={[
                  {
                    id: 1,
                    title: "ALL Version",
                    component: "",
                  },
                  {
                    id: 2,
                    title: "Latest Version",
                    component: "",
                  },
                  {
                    id: 3,
                    title: "In Testing",
                    component: "",
                  },
                  {
                    id: 4,
                    title: "Retired Versions",
                    component: "",
                  },
                ]}
                getActiveTabId={(tabId) => setActiveTab(tabId)}
              />
            </Grid>
          ) : (
            <Grid className="previewTopTabs">
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  history.push("/software-releases")
                }}
              >
                <ArrowBackIos style={{ fontSize: "14px" }} />
                <span style={buttonTextStyle}>Back to All Software Releases</span>
              </Button>

              <TopNavigator
                tabs={[
                  {
                    id: 1,
                    title: "Release Information",
                    component:
                      <SoftwareReleasePreview
                        isLoading={isLoading}
                        selectedRelease={selectedRelease}
                        selectedReleasePortal={selectedReleasePortal}
                        selectedReleaseContacts={selectedReleaseContacts}
                        selectedReleaseProducts={selectedReleaseProducts}
                        poratlAndContactData={poratlAndContactData}
                        selectedReleaseId={selectedReleaseId}
                        fetchReleaseData={fetchReleaseData}
                        updatedTempData={updatedTempData}
                        softwareReleaseDetials={softwareReleaseDetials}
                        selectedReleaseNotifications={selectedReleaseNotifications}
                        notificationTypes={notificationTypes}
                      />,
                  },
                  {
                    id: 2,
                    title: "Analytics",
                    component: <AnalyticsPreview />,
                  },
                  {
                    id: 3,
                    title: "Notes",
                    component: <NotesPreview />,
                  },
                  {
                    id: 4,
                    title: "Software Build",
                    component: null,
                  },
                ]}
                getActiveTabId={(id) => {
                  if (id === 4) {
                    history.push(`/software-builds/${selectedReleaseId}`)
                  }
                }}
              />
            </Grid>
          )}
          {!selectedReleaseId ? (
            <div className="softwareReleaseContainer">
              <SoftwareReleases
                activeTab={activeTab}
                selectedVersions={selectedVersions}
                paginationResponse={paginationResponse}
                isLoading={isLoading}
                setOrderBy={setOrderBy}
                order={order}
                setTabValue={setTabValue}
                tabValue={tabValue}
                search={search}
                setSearch={setSearch}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                markUnMarkFavouriteDevices={markUnMarkFavouriteDevices}
                deleteSoftwareRelease={deleteSoftwareRelease}
              />
            </div>
          ) : (
            null
          )}
        </div>
      </div>
    </Dashbard>
  );
};
export default SoftwareUpdates;
