import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { ArrowBackIos } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../components/common/InputField";
import EmptyTextarea from "../../../../components/common/Textarea";
import SelectInput from "../../../../components/common/SelectInput";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import GeneralHeading from "../../../../components/common/GeneralHeading";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/common/Loader";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  eventCatTextarea: {},
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function BusinessGroupOverview() {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [groupNameInternal, setGroupNameInternal] = useState("");
  const [groupNamePublic, setGroupNamePublic] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [groupColor, setCategoryColor] = useState("#015dfe");
  const [eventHeading, setEventHeading] = useState("New Business Group");
  const [errorMsg, setErrorMsg] = useState(false);

  const redirectUrl = () => {
    history.push("/business-group");
  };

  const dispatch = useDispatch();
  const groupId = parseInt(window.location.href.split("?id=")[1]);

  const classes = useStyles();

  useEffect(() => {
    if (groupId) {
      setLoading(true);
      dispatch(contactsActions.onGetGroupById(groupId)).then(res => {
        if (res && res.success && res.data) {
          setGroupNameInternal(res.data.groupName);
          setGroupNamePublic(res.data.groupNamePublic);
          setShortDescription(res.data.description);
          setCategoryColor(res.data.color);
          setEventHeading(res.data.groupName);
        }
        setLoading(false);
      });
    }
  }, []);
  const onSaveCategory = () => {
    if (groupNameInternal !== "") {
      const modal = {
        id: 0,
        groupName: groupNameInternal,
        groupNamePublic: groupNamePublic,
        description: shortDescription,
        color: groupColor,
        customerTypeName: "BusinessCustomer",
        isActive: true,
      };
      if (groupId) {
        modal.id = groupId;
      }
      setLoading(true);
      setErrorMsg(false);
      dispatch(contactsActions.onAddUpdateGroup(modal)).then(data => {
        if (data?.success) {
          if (groupId) {
            toast.success("Group updated");
          } else toast.success("Group added");
          history.push("/business-group");
        }
        setLoading(false);
      });
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      {isLoading ? <Loader /> : null}
      <GeneralHeading
        headingName={groupId ? eventHeading : "New Business Group"}
        buttonText={groupId ? "Update Group" : "Save Group"}
        onClick={() => {
          onSaveCategory();
        }}
      />{" "}
      <div
        className='consumerHeading backToCategory'
        style={{ marginTop: "0" }}>
        <Button color='primary' onClick={redirectUrl} variant='contained'>
          <ArrowBackIos /> Back to Business-Group
        </Button>
      </div>
      <AccordionContainer title='Group Overview'>
        <Grid container spacing={3}>
          <Grid item xs={11} className={classes.gridWrapper}>
            <InputField
              placeholder='Group Name (Internal)'
              inputlabel='Group Name (Internal)'
              value={groupNameInternal}
              onChange={v => setGroupNameInternal(v.target.value)}
            />
            {errorMsg && groupNameInternal === "" && (
              <span style={{ color: "red" }}>
                Please enter group name (Internal)
              </span>
            )}
          </Grid>

          <Grid item xs={11} className={classes.gridWrapper}>
            <InputField
              placeholder='Group Name (Public)'
              inputlabel='Group Name (Public)'
              value={groupNamePublic}
              onChange={v => setGroupNamePublic(v.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={11}
            className={[classes.gridWrapper, "eventCatTextarea"]}>
            <EmptyTextarea
              textLabel='Short Description'
              placeholder='Enter description here'
              value={shortDescription}
              onChange={v => setShortDescription(v.target.value)}
            />
          </Grid>

          <Grid
            item
            xs={11}
            className={[classes.gridWrapper, "eventCatTextarea"]}>
            <Grid container spacing={4} className='slugWrapper'>
              <Grid item xs={2} className={classes.gridWrapper}>
                <label className='slugLabel' style={{ marginBottom: "0" }}>
                  Group color
                </label>
              </Grid>
              <Grid
                item
                xs={10}
                className={[classes.gridWrapper, "slugWrapper"]}>
                <ul>
                  <li>
                    <button
                      className={groupColor === "#015dfe" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#015dfe");
                      }}
                    />
                    <button
                      className={groupColor === "#00ffc1" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#00ffc1");
                      }}
                    />
                    <button
                      className={groupColor === "#f9b900" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#f9b900");
                      }}
                    />
                    <button
                      className={groupColor === "#ff0092" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#ff0092");
                      }}
                    />
                    <button
                      className={groupColor === "#49b8ef" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#49b8ef");
                      }}
                    />
                    <button
                      className={groupColor === "#eb5757" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#eb5757");
                      }}
                    />
                    <button
                      className={groupColor === "#66de64" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#66de64");
                      }}
                    />
                    <button
                      className={groupColor === "#0a42e9" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#0a42e9");
                      }}
                    />
                    <button
                      className={groupColor === "#ef64b3" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#ef64b3");
                      }}
                    />
                    <button
                      className={groupColor === "#236681" ? "colorActive" : ""}
                      onClick={() => {
                        setCategoryColor("#236681");
                      }}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionContainer>
    </>
  );
}
