import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { RoutesObj } from "../routes";

class Routes extends Component {
  authAdminComponent(componentObj) {
    const isAdmin = localStorage.getItem("isAdmin");
    if (isAdmin === "admin") {
      return componentObj;
    } else {
      return <Redirect to='/' />;
    }
  }
  authCustomerComponent(componentObj) {
    const isAdmin = localStorage.getItem("isAdmin");
    if (["customer", "company_user", "company_admin"].includes(isAdmin)) {
      return componentObj;
    } else {
      return <Redirect to='/' />;
    }
  }
  render() {
    return (
      <Router>
        {/* {isAdmin === "admin" ? ( */}
        <Switch>
          (
          {/* <Route
            exact
            path={RoutesObj.Root.path}
            render={() => RoutesObj.Root.component}
          /> */}
          <Route
            exact
            path={RoutesObj.Launches.path}
            render={() => this.authAdminComponent(RoutesObj.Launches.component)}
          />
          <Route
            exact
            path={[RoutesObj.NewLaunch.path, RoutesObj.UpdateLaunch.path]}
            render={() =>
              this.authAdminComponent(RoutesObj.NewLaunch.component)
            }
          />
          <Route
            exact
            path={RoutesObj.UpComingLaunches.path}
            render={() =>
              this.authAdminComponent(RoutesObj.UpComingLaunches.component)
            }
          />
          <Route
            exact
            path={RoutesObj.LaunchCategories.path}
            render={() =>
              this.authAdminComponent(RoutesObj.LaunchCategories.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AddLaunchCategory.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AddLaunchCategory.component)
            }
          />
          <Route
            exact
            path={RoutesObj.PastLaunches.path}
            render={() =>
              this.authAdminComponent(RoutesObj.PastLaunches.component)
            }
          />
          <Route
            exact
            path={RoutesObj.Releases.path}
            render={() => this.authAdminComponent(RoutesObj.Releases.component)}
          />
          <Route
            exact
            path={RoutesObj.UpComingReleases.path}
            render={() =>
              this.authAdminComponent(RoutesObj.UpComingReleases.component)
            }
          />
          <Route
            exact
            path={RoutesObj.PastReleases.path}
            render={() =>
              this.authAdminComponent(RoutesObj.PastReleases.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AllCollections.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AllCollections.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AssetManagement.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AssetManagement.component)
            }
          />
          <Route
            exact
            path={RoutesObj.FavoritesAssets.path}
            render={() =>
              this.authAdminComponent(RoutesObj.FavoritesAssets.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ConversationsAssets.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ConversationsAssets.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SharedFilesAssets.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SharedFilesAssets.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewCollectionCategory.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewCollectionCategory.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AssetsInterFiles.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AssetsInterFiles.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AssetsTrash.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AssetsTrash.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ContactSettings.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ContactSettings.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewContactTypes.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewContactTypes.component)
            }
          />
          <Route
            exact
            path={RoutesObj.CustomerContacts.path}
            render={() =>
              this.authAdminComponent(RoutesObj.CustomerContacts.component)
            }
          />
          <Route
            exact
            path={RoutesObj.CustomerCompanies.path}
            render={() =>
              this.authAdminComponent(RoutesObj.CustomerCompanies.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewCompanyContacts.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewCompanyContacts.component)
            }
          />
          <Route
            exact
            path={RoutesObj.BusinessCustomerNew.path}
            render={() =>
              this.authAdminComponent(RoutesObj.BusinessCustomerNew.component)
            }
          />
          <Route
            exact
            path={RoutesObj.BusinessCustomer.path}
            render={() =>
              this.authAdminComponent(RoutesObj.BusinessCustomer.component)
            }
          />
          <Route
            exact
            path={RoutesObj.BusinessGroup.path}
            render={() =>
              this.authAdminComponent(RoutesObj.BusinessGroup.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AllEndUsers.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AllEndUsers.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AllUserGroup.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AllUserGroup.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewMediaRelease.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewMediaRelease.component)
            }
          />
          <Route
            exact
            path={RoutesObj.LeadGeneration.path}
            render={() =>
              this.authAdminComponent(RoutesObj.LeadGeneration.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewLeadGeneration.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewLeadGeneration.component)
            }
          />
          <Route
            exact
            path={RoutesObj.UpdateLeadGeneration.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewLeadGeneration.component)
            }
          />
          <Route
            exact
            path={RoutesObj.UpdateMediaRelease.path}
            render={() =>
              this.authAdminComponent(RoutesObj.UpdateMediaRelease.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ReleaseCategory.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ReleaseCategory.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewCollection.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewCollection.component)
            }
          />
          <Route
            exact
            path={RoutesObj.CollectionCategory.path}
            render={() =>
              this.authAdminComponent(RoutesObj.CollectionCategory.component)
            }
          />
          <Route
            exact
            path={RoutesObj.DashboardEvents.path}
            render={() =>
              this.authAdminComponent(RoutesObj.DashboardEvents.component)
            }
          />
          <Route
            exact
            path={RoutesObj.PastEvents.path}
            render={() =>
              this.authAdminComponent(RoutesObj.PastEvents.component)
            }
          />
          <Route
            exact
            path={RoutesObj.UpcommingEvents.path}
            render={() =>
              this.authAdminComponent(RoutesObj.UpcommingEvents.component)
            }
          />
          <Route
            exact
            path={RoutesObj.EventCategories.path}
            render={() =>
              this.authAdminComponent(RoutesObj.EventCategories.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewEvent.path}
            render={() => this.authAdminComponent(RoutesObj.NewEvent.component)}
          />
          <Route
            exact
            path={RoutesObj.NewCategory.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewCategory.component)
            }
          />
          <Route
            exact
            path={RoutesObj.Location.path}
            render={() => this.authAdminComponent(RoutesObj.Location.component)}
          />
          <Route
            exact
            path={RoutesObj.NewLocation.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewLocation.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewBusinessGroup.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewBusinessGroup.component)
            }
          />
          <Route
            exact
            path={RoutesObj.BusinessContactFavorites.path}
            render={() =>
              this.authAdminComponent(
                RoutesObj.BusinessContactFavorites.component
              )
            }
          />
          <Route
            exact
            path={RoutesObj.BusinessContactRecentlyAdded.path}
            render={() =>
              this.authAdminComponent(
                RoutesObj.BusinessContactRecentlyAdded.component
              )
            }
          />
          <Route
            exact
            path={RoutesObj.BusinessContactArchived.path}
            render={() =>
              this.authAdminComponent(
                RoutesObj.BusinessContactArchived.component
              )
            }
          />
          <Route
            exact
            path={RoutesObj.BusinessContactBlocked.path}
            render={() =>
              this.authAdminComponent(
                RoutesObj.BusinessContactBlocked.component
              )
            }
          />
          <Route
            exact
            path={RoutesObj.BusinessContactDeleted.path}
            render={() =>
              this.authAdminComponent(
                RoutesObj.BusinessContactDeleted.component
              )
            }
          />
          <Route
            exact
            path={RoutesObj.BlockedBusinessLocation.path}
            render={() =>
              this.authAdminComponent(
                RoutesObj.BlockedBusinessLocation.component
              )
            }
          />
          <Route
            exact
            path={RoutesObj.UpdateBusinessLocation.path}
            render={() =>
              this.authAdminComponent(
                RoutesObj.UpdateBusinessLocation.component
              )
            }
          />
          <Route
            exact
            path={RoutesObj.NewEndUserGroup.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewEndUserGroup.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewReleaseCategory.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewReleaseCategory.component)
            }
          />
          <Route
            exact
            path={RoutesObj.LocatorWidget.path}
            render={() =>
              this.authAdminComponent(RoutesObj.LocatorWidget.component)
            }
          />
          <Route
            exact
            path={RoutesObj.PriceList.path}
            render={() =>
              this.authAdminComponent(RoutesObj.PriceList.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewPriceList.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewPriceList.component)
            }
          />
          <Route
            exact
            path={RoutesObj.CreatePriceList.path}
            render={() =>
              this.authAdminComponent(RoutesObj.CreatePriceList.component)
            }
          />
          <Route
            exact
            path={RoutesObj.NewsRoomPortal.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewsRoomPortal.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ProductSerials.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ProductSerials.component)
            }
          />
          <Route
            exact
            path={RoutesObj.MainDashboard.path}
            render={() =>
              this.authAdminComponent(RoutesObj.MainDashboard.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ConsumerOverview.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ConsumerOverview.component)
            }
          />
          <Route
            exact
            path={RoutesObj.CustomerOverview.path}
            render={() =>
              this.authAdminComponent(RoutesObj.CustomerOverview.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ProductInventory.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ProductInventory.component)
            }
          />
          <Route
            exact
            path={RoutesObj.Brands.path}
            render={() => this.authAdminComponent(RoutesObj.Brands.component)}
          />
          <Route
            exact
            path={RoutesObj.ProductCategoriesObj.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ProductCategoriesObj.component)
            }
          />
          <Route
            exact
            path={[
              RoutesObj.NewProductCategory.path,
              RoutesObj.UpdateProductCategory.path,
            ]}
            render={() =>
              this.authAdminComponent(RoutesObj.NewProductCategory.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ProductSerial.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ProductSerial.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ProductPricing.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ProductPricing.component)
            }
          />
          <Route
            exact
            path={RoutesObj.Conversation.path}
            render={() =>
              this.authAdminComponent(RoutesObj.Conversation.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SoftwareReleases.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareReleases.component)
            }
            />
          <Route
            exact
            path={RoutesObj.AllConversation.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AllConversation.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SoftwareReleaseDetail.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareReleaseDetail.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SoftwareBuild.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareBuild.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SoftwareBuildPreview.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareBuildPreview.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SoftwareBuildEdit.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareBuildEdit.component)
            }
          />
           <Route
            exact
            path={RoutesObj.SoftwareNewBuild.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareNewBuild.component)
            }
          />
          <Route
            exact
            path={RoutesObj.CompanyInbox.path}
            render={() =>
              this.authAdminComponent(RoutesObj.CompanyInbox.component)
            }
          />
          <Route
            exact
            path={RoutesObj.PressInbox.path}
            render={() =>
              this.authAdminComponent(RoutesObj.PressInbox.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SupportInbox.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SupportInbox.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SalesInbox.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SalesInbox.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AssignedConversations.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AssignedConversations.component)
            }
          />
          <Route
            exact
            path={RoutesObj.UnAssignedConversations.path}
            render={() =>
              this.authAdminComponent(RoutesObj.UnAssignedConversations.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SpamConversations.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SpamConversations.component)
            }
          />
          <Route
            exact
            path={RoutesObj.DraftConversations.path}
            render={() =>
              this.authAdminComponent(RoutesObj.DraftConversations.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ArchiveConversations.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ArchiveConversations.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ArchivedConversations.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ArchivedConversations.component)
            }
          />
          <Route
            exact
            path={RoutesObj.TrashConversations.path}
            render={() =>
              this.authAdminComponent(RoutesObj.TrashConversations.component)
            }
            />
          <Route
            exact
            path={RoutesObj.NewSoftwareRelease.path}
            render={() =>
              this.authAdminComponent(RoutesObj.NewSoftwareRelease.component)
            }
          />
           <Route
            exact
            path={RoutesObj.SoftwareReleaseEdit.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareReleaseEdit.component)
            }
          />
           <Route
            exact
            path={RoutesObj.SoftwareReleaseDashBoard.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareReleaseDashBoard.component)
            }
          />
            <Route
            exact
            path={RoutesObj.SoftwareReleaseProduct.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareReleaseProduct.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SoftwareReleaseConnectFeature.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareReleaseConnectFeature.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SoftwareReleasAnalytics.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareReleasAnalytics.component)
            }
          />
           <Route
            exact
            path={RoutesObj.SoftwareReleaseOfishSetting.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SoftwareReleaseOfishSetting.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ConversationSettings.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ConversationSettings.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ConversationType.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ConversationType.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ConversationTypeEdit.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ConversationTypeEdit.component)
            }
          />
          <Route
            exact
            path={RoutesObj.ConversationTypeEditView.path}
            render={() =>
              this.authAdminComponent(RoutesObj.ConversationTypeEditView.component)
            }
          />
          <Route
            exact
            path={RoutesObj.CompanyInbox.path}
            render={() =>
              this.authAdminComponent(RoutesObj.CompanyInbox.component)
            }
          />
          <Route
            exact
            path={RoutesObj.SupportInbox.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SupportInbox.component)
            }
          />
          <Route
            exact
            path={RoutesObj.PressInbox.path}
            render={() =>
              this.authAdminComponent(RoutesObj.PressInbox.component)
            }
          />
          <Route
            exact
            path={RoutesObj.YourViews.path}
            render={() =>
              this.authAdminComponent(RoutesObj.YourViews.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AllAdminUsers.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AllAdminUsers.component)
            }
          />
          <Route
            exact
            path={RoutesObj.AdminOverview.path}
            render={() =>
              this.authAdminComponent(RoutesObj.AdminOverview.component)
            }
          />
        </Switch>

        <Switch>
          <Route
            exact
            path={RoutesObj.Root.path}
            render={() => RoutesObj.Root.component}
          />
        </Switch>
        {/* )} */}
        <Switch>
          <Route
            exact
            path={RoutesObj.ForgotPassword.path}
            render={() => RoutesObj.ForgotPassword.component}
          />
          <Route
            exact
            path={RoutesObj.PasswordRest.path}
            render={() => RoutesObj.PasswordRest.component}
          />
          <Route
            exact
            path={RoutesObj.VerificationCode.path}
            render={() => RoutesObj.VerificationCode.component}
          />
          <Route
            exact
            path={RoutesObj.Login.path}
            render={() => RoutesObj.Login.component}
          />
          <Route
            exact
            path={[
              RoutesObj.LatestReleases.path,
              `${RoutesObj.LatestReleases.path}/:slug`,
            ]}
            render={() => RoutesObj.LatestReleases.component}
          />
          <Route
            exact
            path={RoutesObj.LatestReleasesSearch.path}
            render={() => RoutesObj.LatestReleasesSearch.component}
          />
          <Route
            exact
            path={[
              RoutesObj.LatestReleaseDetail.path,
              `${RoutesObj.LatestReleaseDetail.path}/:slug`,
            ]}
            render={() => RoutesObj.LatestReleaseDetail.component}
          />
          {/* <Route
            exact
            path={RoutesObj.AllReleases.path}
            render={() => RoutesObj.AllReleases.component}
          /> */}
          <Route
            exact
            path={RoutesObj.AllCategories.path}
            render={() => RoutesObj.AllCategories.component}
          />
          <Route
            exact
            path={[
              RoutesObj.MediaLibrary.path,
              `${RoutesObj.MediaLibrary.path}/:slug`,
            ]}
            render={() => RoutesObj.MediaLibrary.component}
          />
          <Route
            exact
            path={[
              RoutesObj.MediaDetail.path,
              `${RoutesObj.MediaDetail.path}/:slug`,
            ]}
            render={() => RoutesObj.MediaDetail.component}
          />
          <Route
            exact
            path={RoutesObj.AllNewAssets.path}
            render={() => RoutesObj.AllNewAssets.component}
          />
          <Route
            exact
            path={RoutesObj.AllCollection.path}
            render={() => RoutesObj.AllCollection.component}
          />
          <Route
            exact
            path={RoutesObj.AllSourceUnits.path}
            render={() => RoutesObj.AllSourceUnits.component}
          />
          <Route
            exact
            path={[RoutesObj.Events.path, `${RoutesObj.Events.path}/:slug`]}
            render={() => RoutesObj.Events.component}
          />
          <Route
            exact
            path={RoutesObj.Calendar.path}
            render={() => RoutesObj.Calendar.component}
          />
          <Route
            exact
            path={RoutesObj.UpcomingEvents.path}
            render={() => RoutesObj.UpcomingEvents.component}
          />
          <Route
            exact
            path={RoutesObj.UpcomingProductRelease.path}
            render={() => RoutesObj.UpcomingProductRelease.component}
          />
          <Route
            exact
            path={RoutesObj.CompanyInfo.path}
            render={() => RoutesObj.CompanyInfo.component}
          />
          <Route
            exact
            path={RoutesObj.AllLatestAnnouncenments.path}
            render={() => RoutesObj.AllLatestAnnouncenments.component}
          />
          <Route
            exact
            path={RoutesObj.AllUpcomingEvents.path}
            render={() => RoutesObj.AllUpcomingEvents.component}
          />
          <Route
            exact
            path={[
              RoutesObj.EventDetail.path,
              `${RoutesObj.EventDetail.path}/:slug`,
            ]}
            render={() => RoutesObj.EventDetail.component}
          />

          <Route
            exact
            path={[RoutesObj.NewProduct.path, RoutesObj.UpdateProduct.path]}
            render={() => RoutesObj.NewProduct.component}
          />

          <Route
            exact
            path={RoutesObj.AccountSettings.path}
            render={() => RoutesObj.AccountSettings.component}
          />
          <Route
            exact
            path={RoutesObj.SpaProduct.path}
            render={() =>
              this.authAdminComponent(RoutesObj.SpaProduct.component)
            }
          />
        </Switch>
      </Router>
    );
  }
}

export default Routes;
