import React, { memo, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import { makeStyles } from "@material-ui/core/styles";
import "react-web-tabs/dist/react-web-tabs.css";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginTop: "20px",
  },
  tabsHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    marginBottom: "15px",
    paddingBottom: "5px",
  },
  tabsHeaderTitle: {
    marginRight: "20px !important",
  },
}));

const TopTabNavigator = memo(
  ({
    tabs,
    tabsTitle,
    getActiveTabId,
    value,
    className,
    defaultTabId,
    tabId,
    defaultActive,
    msgCount
  }) => {
    const classes = useStyles();

    useEffect(() => {
      if (value === "1" && className === "spa-tab-") {
        const activetab = document.getElementsByClassName(
          `${className}${value}`
        );
        activetab?.[0]?.click();
      }
      if (value === "3" && className === "con-settting") {
        const activetab = document.getElementById("3-tab");
        activetab?.click();
      }
      if (value === "1" && className === "contactType") {
        const activetab = document.getElementsByClassName(
          `${className}${value}`
        );
        activetab?.[0]?.click();
      }
      if (tabId) {
        const activetab = document.getElementById(tabId);
        activetab?.click();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <Tabs
        className='tabs-container'
        defaultTab={defaultTabId || tabs?.[0]?.id}
        value={value}
        onChange={tabId => {
          getActiveTabId && getActiveTabId(tabId);
        }}>
        <div className={className ? className : classes.tabsHeader}>
          {tabsTitle && (
            <h1 className={classes.tabsHeaderTitle}>{tabsTitle}</h1>
          )}
          <TabList>
            {tabs?.map((t, index) => (
              <Tab
                className={`${className}${index}`}
                style={{
                  marginRight: "10px",
                  color:"#8D99A5 !important",
                }}
                disabled={t.disabled ?? false}
                value={`${index}`}
                key={t.id}
                tabFor={t.id}>
                {t.title} <i className='msgCount'>{t.msgCount}</i>
              </Tab>
            ))}
          </TabList>
        </div>

        {tabs?.map(t => (
          <TabPanel key={t.id} tabId={t.id} className='tab-panel'>
            <Grid container className={classes.gridWrapper}>
              {t.component}
            </Grid>
          </TabPanel>
        ))}
      </Tabs>
    );
  }
);

export { TopTabNavigator };
