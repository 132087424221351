import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { first } from "lodash";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ProductsGrid } from ".";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";

const ProductsListing = ({ categoryId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  const loadData = useCallback(() => {
    dispatch(
      productCategoriesActions.onGetProductsByCategoryId(categoryId)
    ).then(data => setProducts(first(data?.data)));
  }, [categoryId, dispatch]);

  useEffect(() => {
    categoryId && categoryId > 0 && loadData();
  }, [categoryId, loadData, dispatch]);

  return (
    <AccordionContainer
      title='Products'
      formButton={
        <Button
          variant='outlined'
          color='primary'
          onClick={() => history.push("/new-product")}>
          <AddIcon /> Add Products
        </Button>
      }>
      <ProductsGrid listData={products} loadData={loadData} />
    </AccordionContainer>
  );
};

export { ProductsListing };
