import React, { useState } from "react";
import Dashboard from "../Dashbard";
import Loader from "../../../../components/common/Loader";
import { ProductTabButtons } from "../../../../components/common";

const Brands = () => {
  document.title = "OFish - All Brands";

  const [isLoading] = useState(false);

  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h2>All Brands</h2>
          {/* <Button
            color='primary'
            onClick={() => history.push("/new-product")}
            variant='contained'>
            New Product
          </Button> */}
        </div>

        <ProductTabButtons />

        <div className='contentBody'>
          <h1>Coming Soon!</h1>
        </div>
      </div>
    </Dashboard>
  );
};

export { Brands };
