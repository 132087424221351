import React, { useState, useEffect } from "react";
import { LogHeader, MainHeading } from "../../../components/common/Header";
import { Button, Grid } from "@material-ui/core";
import AccountSettingInfo from "./AccountSettingInfo";
import CompanyUsers from "./CompanyUsers";
import NewsroomNotification from "./NewsroomNotification";

function AccountSettings(props) {
  const [isAccountSettings, setIsAccountSettings] = useState(true);
  const [isCompanyUsers, setIsCompanyUsers] = useState(false);
  const [isNotifications, setIsNotifications] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const name = localStorage.getItem("name");
    setUserName(name);
  }, []);

  const accountSettings = () => {
    setIsAccountSettings(true);
    setIsCompanyUsers(false);
    setIsNotifications(false);
  };

  const companyUsers = () => {
    setIsAccountSettings(false);
    setIsCompanyUsers(true);
    setIsNotifications(false);
  };

  const notifications = () => {
    setIsAccountSettings(false);
    setIsCompanyUsers(false);
    setIsNotifications(true);
  };

  return (
    <div>
      <LogHeader />
      <MainHeading headerSubHeading={`${"Hi, "}${userName}${"!"}`} />
      <div className="boxMainWrapper companyInfo accountSettings">
        <div className="infoTabs accountSettingsTabs">
          <ul className="intoTabsHeader">
            <li>
              <Button
                color="primary"
                variant="outlined"
                className={isAccountSettings ? "active" : null}
                onClick={accountSettings}>
                Account Settings
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                variant="outlined"
                className={isCompanyUsers ? "active" : null}
                onClick={companyUsers}>
                Company users
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                variant="outlined"
                className={isNotifications ? "active" : null}
                onClick={notifications}>
                newsroom notifications
              </Button>
            </li>
          </ul>
        </div>
        {isAccountSettings && <AccountSettingInfo />}
        {isCompanyUsers && <CompanyUsers />}
        {isNotifications && <NewsroomNotification />}
      </div>
    </div>
  );
}

export default AccountSettings;
