import React from "react";

export const NewProductDetail = ({ deviceOverviewInfo }) => {
  return (
    <>
      {deviceOverviewInfo ? (
        <div className='deviceDetailMainContainer'>
          <div className='deviceDetailOverviewInner'>
            <div className='devicePicture'>
              {deviceOverviewInfo?.image && (
                <img
                  className='devicedDtailImage'
                  src={deviceOverviewInfo?.image}
                  alt=''
                />
              )}
            </div>

            <div className='deviceOverViewDetail'>
              <div className='headingDetail'>Device Overview</div>
              <span className='deviceDetailOverView'>
                <ul>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>Name</span>
                    {deviceOverviewInfo?.deviceName}
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>SKU</span>
                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.slectedDevice?.sku}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Serial Number
                    </span>
                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.serialNumber}
                    </span>
                  </li>
                  <li
                    style={{ position: "relative" }}
                    className='deviceDetailView'>
                    <span className='deviceDetailProperties'>Environment</span>

                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.DeviceEnv?.name}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Purchase Location
                    </span>
                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.purchaseLocation}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Purchase Date
                    </span>
                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.purchaseDate}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Installation Date
                    </span>
                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.installationDate}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span>Warranty Status</span>
                    <div className='pendingStatus'>
                      {deviceOverviewInfo?.slectedDevice?.warrantyExpiration ??
                        "Pending"}
                    </div>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Warranty Expiration:{" "}
                    </span>

                    <span>
                      {deviceOverviewInfo?.slectedDevice?.warrantyExpiration ??
                        "--"}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Warranty Remaining
                    </span>
                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.slectedDevice?.warrantyRemaining ??
                        "--"}
                    </span>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span>No Data available</span>
        </div>
      )}
    </>
  );
};
