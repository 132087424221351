import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import queryString from "query-string";
import CompanyConnectionsGrid from "./CompanyConnectionsGrid";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { Spinner } from "../../../../../components/common";
import { SVGIcons } from "../../../../../resources/images";
import { RoutesObj } from "../../../../../routes";

const OFishConnections = ({
  bulkCopyData,
  setBulkCopyData,
  allBUConnections,
  setAllBUConnections,
  listData,
  setListData,
  loadData,
  listLocationData,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { contactTypes } = useSelector(state => state.ContactsReducer);
  const selectedData = queryString.parse(location.search);
  const [editBulk, setEditBulk] = useState(false);
  const [addNewCon, setAddNewCon] = useState(false);

  const [allContactTypeNames, setAllContactTypeNames] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isCollpased, setIsCollpased] = useState(false);
  const [search, setSearch] = useState("");
  
  useEffect(() => {
    if (contactTypes?.id) {
      dispatch(contactsActions.onGetBUContactTypeById(contactTypes.id)).then(
        data => {
          setAllContactTypeNames(data?.data);
        }
      );
    }
  }, [contactTypes?.id]);

  const addUpdateCompanyOFishConnections = (id, contactName, personName) => {
    let modal = [
      {
        id: id,
        canPurchaseProducts:
          personName.filter(x => x === "Can purchase products").length > 0
            ? true
            : false,
        canProductAccessProvided:
          personName.filter(x => x === "Product access provided").length > 0
            ? true
            : false,
        canResellProducts:
          personName.filter(x => x === "Can resell products").length > 0
            ? true
            : false,
        canReturnProducts:
          personName.filter(x => x === "Can return products").length > 0
            ? true
            : false,
        canSalesRepresentative:
          personName.filter(x => x === "Sales representative").length > 0
            ? true
            : false,
        contactId: contactName,
        companyId: selectedData?.id,
        status: 0,
      },
    ];
    setLoading(true);
    dispatch(contactsActions.onAddUpdateCompanyOFishConnections(modal)).then(
      data => {
        setLoading(false);
        if(data && data.success){
        toast.success("Connection Added");
        }else{
          toast.error("Connection Failed");
        }
        setAddNewCon(false);
        loadData();
      }
    );
  };
  const searching = value => {
    setSearch(value);
    if (value === "") {
      setListData(allBUConnections);
    } else {
      let filterRec = allBUConnections.filter(x => x.contact.includes(value));
      setListData(filterRec);
    }
  };

  const deleteConnection = id => {
    setLoading(true);
    dispatch(contactsActions.onDeleteCompanyOFishConnections([id])).then(
      data => {
        setLoading(false);
        loadData();
        if (data?.success) {
          toast.success("Connection deleted");
        } else {
          toast.error(data?.message);
        }
      }
    );
  };

  return (
    <div className='newCompanySelectWrapper'>
      {isLoading ? <Spinner /> : null}
      {(!listData || listData?.length === 0) &&
        isCollpased &&
        location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) && (
          <span className='startBtnWrapper'>
            <Button
              disabled={listLocationData?.length === 0}
              color='primary'
              variant='outlined'
              onClick={() => {
                document.getElementById("summary-header-connection").click();
              }}>
              Start
            </Button>
          </span>
        )}
      <AccordionContainer
        title='OFish Connections'
        accordionId='summary-header-connection'
        disabled={
          location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) &&
          listLocationData?.length === 0
        }
        isExpanded={!location?.pathname?.includes(RoutesObj.NewCompanyContacts.path)
          ? true
          : false && listLocationData?.length}
        onCollapsed={collapsed => setIsCollpased(collapsed)}
        formButton={
          isCollpased ? (
            location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) &&
            allBUConnections?.length > 0 ? (
              <span className='green-tick-svg'>
                <SVGIcons.GreenTick />
              </span>
            ) : (
              !location?.pathname?.includes(
                RoutesObj.NewCompanyContacts.path
              ) && (
                <span className='companyConnectionsButtons'>
                  <Button
                    disabled={listLocationData?.length === 0}
                    color='primary'
                    startIcon={<AddIcon />}
                    onClick={() => {
                      document.getElementById("summary-header-con").click();
                      setAddNewCon(true);
                    }}>
                    Add Connections
                  </Button>
                </span>
              )
            )
          ) : (
            selectedData?.id && (
              <span className='companyConnectionsButtons'>
                <Button
                  onClick={() => {
                    setAddNewCon(true);
                  }}
                  startIcon={<AddIcon />}>
                  Add Connections
                </Button>
              </span>
            )
          )
        }>
        <CompanyConnectionsGrid
          editBulk={editBulk}
          addNewCon={addNewCon}
          onPressCross={() => {
            setEditBulk(false);
            setAddNewCon(false);
          }}
          allBUConnections={allBUConnections}
          listData={listData}
          allContactTypeNames={allContactTypeNames}
          bulkCopyData={bulkCopyData}
          addUpdateCompanyOFishConnections={(id, contactName, personName) =>
            addUpdateCompanyOFishConnections(id, contactName, personName)
          }
          search={search}
          setSearch={s => {
            searching(s);
          }}
          deleteConnection={id => deleteConnection(id)}
        />

        {(!listData || listData?.length === 0) && (
          <span className='emptyGridData'>
            You haven’t added any OFish Connections. Start by adding a{" "}
            <i
              onClick={() => {
                setAddNewCon(true);
              }}>
              Company Connections
            </i>
          </span>
        )}
        {listData?.length > 0 && (
          <span className='saveNNestBtnWrapper'>
            <Button
              onClick={() => {
                document.getElementById("summary-header-connection").click();
              }}>
              Finish
            </Button>
          </span>
        )}
      </AccordionContainer>
    </div>
  );
};

export { OFishConnections };
