import React from "react";
import {
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  CloseIconCustomPopup,
} from "../../LeftNav/SvgIcons";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import CustomerGroupGridFilter from "../ProductInventory/CustomerGroupGridFilter";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: "20px",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "20px",
    width: "1100px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #E2E5E9",
    padding: "23px 33px 23px 41px;",
    background: "#3699FF",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  customerTypo: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#fff",
  },
  innerBody: {
    overflowY: "auto",
  },
  chooseCustomerHeading: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#181C32",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Roboto",
    margin:"16px 0px",
    color: "#434D56",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    padding: "13px 18px 17px 20px",
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  mainCustomerPricing: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  mainBody: {
    padding: "32px",
  },
  customReleaseFilter: {
    fontSize: "12px",
    "& .MuiOutlinedInput-input": {
      padding: "13px 12px 13px 30px !important",
    },
  },
 
}));

export const CustomSoftwareReleasePopup = ({
  title,
  heading,
  description,
  filterPlaceholder,
  openPopup,
  setOpenPopup,
  children,
  allTabs,
  setFilterReleaseByName,
  tabChanged,
  onPopupClose,
  isSelectedDevice,
}) => {
  const classes = useStyles();

  const onClose = () => {
    setOpenPopup(false);
    if (onPopupClose) {
      onPopupClose();
    }
  };
  return (
    <Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPopup}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPopup}>
          <Grid container className={classes.paper}>
            <Grid item xs={12} className={classes.header}>
              <Typography className={classes.customerTypo}>{title}</Typography>
              <IconButton className={classes.closIcon} onClick={onClose}>
                <CloseIconCustomPopup />
              </IconButton>
            </Grid>
            <Grid xs={12} className={classes.mainBody}>
              <Grid item>
                <Typography className={classes.chooseCustomerHeading}>
                  {heading}
                </Typography>
                <Typography className={classes.text}>{description}</Typography>
              </Grid>
              <Grid>
                { !isSelectedDevice && (
                  <Grid className={`customerSearchfilter ${classes.customReleaseFilter}`}>
                    <CustomerGroupGridFilter
                      id="softwareReleases"
                      filterPlaceholder={filterPlaceholder}
                      setFilterReleaseByName={setFilterReleaseByName}
                    />
                    <Grid style={{marginLeft:"16px"}} className="customPopupsTabs">
                      <TopTabNavigator
                        getActiveTabId={(tabId) => {
                          tabChanged(tabId);
                        }}
                        tabs={allTabs}
                        // defaultTabId={activeTabId}
                        className="tabsHeader"
                      />
                    </Grid>
                  </Grid>
                )} 
              </Grid>

              <Grid>{children}</Grid>
              
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </Grid>
  );
};
