import { DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { PopupDialog } from "../../../../components/common";

const PlayMedia = ({ selectedMediaFile, onClose }) => {
  console.log("PlayMedia : ", selectedMediaFile);
  return (
    <PopupDialog maxWidth={true} visible={selectedMediaFile} onClose={onClose}>
      <DialogTitle id='alert-dialog-title'>
        {selectedMediaFile?.name || "Play Media"}
        <Close className='closePopup' onClick={onClose} />
      </DialogTitle>
      <div
        style={{
          padding: "10px",
        }}>
        <video controls width='450'>
          <source
            src={
              selectedMediaFile?.url ||
              "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
            }
            //   type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </PopupDialog>
  );
};

export { PlayMedia };
