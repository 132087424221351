import React from "react";
import moment from "moment";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  activityButton: {
    backgroundColor: "#E5F2FF",
    padding: "8px 35px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#E5F2FF",
    },
  },
}));

//#region Helper Methds
const isTodayDate = checkDate =>
  new Date(checkDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

export const stringToColor = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 3) - hash);
  }
  const color = Math.abs(hash).toString(16).substring(0, 6);

  return "#" + "1BC5BD".substring(0, 6 - color.length) + color;
};
//#endregion

const Activities = ({ listData, header }) => {
  const classes = useStyles();

  return (
    <div className='activities'>
      {header && (
        <div className='activitiestHeader'>
          Customer Activity
          <span>
            <Button color='primary' className={classes.activityButton}>
              View All Activity
            </Button>
          </span>
        </div>
      )}
      {listData?.length > 0 ? (
        listData.map((activity, i) => {
          return (
            <div key={i} className='activityBody'>
              <span className='activityDate'>
                <span
                  className='circle'
                  style={{
                    border: `4px solid ${
                      !activity.action
                        ? "#F64E60"
                        : stringToColor(
                            `${i}${moment(activity.actionDate)
                              .format("HH:mm A")
                              .toString()}`
                          )
                    }`,
                  }}
                />
                {isTodayDate(activity.actionDate)
                  ? moment(activity.actionDate).format("hh:mm A")
                  : moment(activity.actionDate).format("MMMM DD, YYYY")}
              </span>
              <span className='activityDesc'>{activity.action}</span>
            </div>
          );
        })
      ) : (
        <h1>No activity</h1>
      )}
    </div>
  );
};

export { Activities };
