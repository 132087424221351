import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionSummary, Button, IconButton, Typography } from "@material-ui/core";
import Dashboard from "../Dashbard";
import Loader from "../../../../components/common/Loader";

import { AllCategoriesGrid } from "./AllCategoriesGrid";
import { Pagination, ProductTabButtons } from "../../../../components/common";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { GridFilterBar } from "../ProductInventory/GridFilterBar";
import ProductInventoryFilter, { FilterAutoComplete, SelectedChiptItems, SelectedDateChip } from "../ProductInventory/ProductInventoryFilter";
import { useDebounce } from "../../../../utils/use-debounce";
import { toast } from "react-toastify";
import { FilterCreationDate } from "../EndUser";
import { ExpandMore, MoreHoriz } from "@material-ui/icons";

const ProductCategories = props => {
  document.title = "OFish - Product Categories";

  const history = useHistory();
  const dispatch = useDispatch();

  const redirectUrl = () => {
    history.push("/new-product-category");
  };
  const [isLoading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filterCategories , setFilterCategories] = useState([])
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });

  const [filters, setFilters] = useState({
    categoryId:[],
    product: "",
    status: [],
    createdOnFrom:null,
    createdOnTo:null,
    brandId:[],
    showOnStore:[],
    showonRegistration:[],
    brand:"",
    categoryName:"",
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "CategoryName",
    orderVal: 0,
  });

  let filtersIndex = 0;
  const filtersLimit = 4;
  const indexReference = useRef(filtersIndex);
  const [showFilters, setShowFilter] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showFilterCounter , setShowFilterCounter] = useState(0);
  const [brands, setBrands] = useState([]);
  const { debouncedValue: debouncedSearchTxt } = useDebounce(filters.product, 500);

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      productCategoriesActions.onGetAllProductCategories({
        pageNumber: page,
        noOfRecoreds: rowsPerPage,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
        filters: filters,
      })
    ).then(data => {
      setCategories(data?.data?.data || []);
      if (data?.success) {
        setPaginationResponse(data?.data?.pagination);
      } else {
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setLoading(false);
    });
  }, [dispatch, debouncedSearchTxt, order.orderByKey, order.orderVal, page, rowsPerPage]);

  useEffect(() => {
    if(filtersCount){
      applyFilters();
    }else{
      loadData();
    }
  }, [dispatch, loadData]);

  const showFilter = () => {
    window.scroll(0, 0);
    // document.body.classList.add("bodyScroll");
    setIsFilter(true);
  };

  const hideFilter = () => {
    setIsFilter(false);
  };

  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllProductSubCategories(0)).then(
      (data) => setFilterCategories(data?.data)
    );
    dispatch(productCategoriesActions.onGetAllBrands()).then(data =>{
     const updatedBrand = data?.data.map(item => ({
        id:item.brandId,
        value: item.brandName
      }))
      setBrands(updatedBrand)
    });
  }, [dispatch]);

  const filterOptions =[
    { id: 0, value: "No" },
    { id: 1, value: "Yes" },
  ]
  const statusOption = [
    { id: 0, name: "In Active" },
    { id: 1, name: "Active" },
  ]

const filtersCount =
(filters.categoryId?.length ? filters.categoryId.length : 0) +
(filters.status?.length ? filters.status.length : 0) +
(filters.brandId?.length ? filters.brandId.length : 0) +
(filters.showOnStore?.length ? filters.showOnStore.length : 0) +
(filters.showonRegistration?.length ? filters.showonRegistration.length : 0) +
(filters.createdOnFrom ? 1 : 0) +
(filters.createdOnTo ? 1 : 0);

indexReference.current = filtersIndex;

const applyFilters = ()=>{
  setLoading(true);
  setShowFilterCounter(filtersCount)
  hideFilter();
  dispatch(
    productCategoriesActions.onGetAllProductCategories({
      pageNumber: page,
      noOfRecoreds: rowsPerPage,
      orderByKey: order.orderByKey,
      orderByVal: order.orderVal,
      filters: filters,
    })
  ).then(data => {
    setCategories(data?.data?.data || []);
    if (data?.success) {
      setPaginationResponse(data?.data?.pagination);
    } else {
      setPaginationResponse({
        currentCount: 0,
        totalRecords: 0,
      });
    }
    setLoading(false);
  });
}
const onClearButtonPress = ()=>{
  setLoading(true);
  setPage(1);
  setRowsPerPage(10);
  setShowFilterCounter(0)
  setFilters({
    categoryId:[],
    product: "",
    status: [],
    createdOnFrom:null,
    createdOnTo:null,
    brandId:[],
    showOnStore:[],
    showonRegistration:[],
    brand:"",
    categoryName:"",
  })
  dispatch(
    productCategoriesActions.onGetAllProductCategories({
      pageNumber: page,
      noOfRecoreds: rowsPerPage,
      orderByKey: order.orderByKey,
      orderByVal: order.orderVal,
      filters:{
        categoryId:[],
        product: "",
        status: [],
        createdOnFrom:null,
        createdOnTo:null,
        brandId:[],
        showOnStore:[],
        showonRegistration:[],
        brand:"",
        categoryName:"",
      }})
  ).then(data => {
    setCategories(data?.data?.data || []);
    if (data?.success) {
      setPaginationResponse(data?.data?.pagination);
    } else {
      if (data?.message?.includes("No Data Found")) {
      } else toast.error("Something went wrong, Please try later.");
      setPaginationResponse({
        currentCount: 0,
        totalRecords: 0,
      });
    }
    setLoading(false);
  });
}

  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>Product Categories</h1>
          <Button color='primary' onClick={redirectUrl} variant='contained'>
            New Product Category
          </Button>
        </div>

        <ProductTabButtons />

        <div className='gridBody contentBody'>
          {/* <GridFilterBar
            onFilterButtonPress={customFilters => setFilters(customFilters)}
            onClearButtonPress={() =>
              setFilters({
                product: "",
                categoryId: null,
                dateCreated: null,
                status: null,
              })
            }
          /> */}
          <ProductInventoryFilter
            onFilterButtonPress={customFilters => setFilters(customFilters)}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            filters={filters}
            setFilters={setFilters}
            searchKey={"product"}
            title={"Product Categories Filters"}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            showFilter={showFilter}
            hideFilter={hideFilter}
            applyFilters={applyFilters}
            showFilterCounter={showFilterCounter}
          >
            <Accordion
              defaultExpanded
              id="end-user-filter-expandable"
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  <span>Active Filter(s)</span>
                  <span>
                    <Button
                      className="filterClearBtn filterClearBtnStyle"
                      variant="outlined"
                      onClick={() => {
                        hideFilter();
                        onClearButtonPress();
                      }}
                    >
                      Clear
                    </Button>
                  </span>
                </Typography>
              </AccordionSummary>
              <div>
                {filtersCount <= 0 && (
                  <div className={`filtersChips`}>No Active Filter(s)</div>
                )}
              </div>

              <SelectedDateChip
                startDate={filters.createdOnFrom}
                endDate={filters.createdOnTo}
                setStartDate={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    createdOnFrom: date,
                  }));
                }}
                setEndDate={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    createdOnTo: date,
                  }));
                }}
                chipLabel={"Creation Date"}
              />

              <SelectedChiptItems
                selectedItems={filters.categoryId}
                items={filterCategories}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"categoryId"}
                chipLabel={"Product"}
              />
              <SelectedChiptItems
                selectedItems={filters.status}
                items={[...statusOption.map(item => ({
                  ...item,
                  value: item.name
                }))]}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"status"}
                chipLabel={"Category Status"}
              />
              {/* <SelectedChiptItems
                selectedItems={filters.brandId}
                items={brands}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"brandId"}
                chipLabel={"Product Brand"}
              /> */}
              <SelectedChiptItems
                selectedItems={filters.brandId}
                items={brands}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"brandId"}
                chipLabel={"Product Brand"}
              />
              <SelectedChiptItems
                selectedItems={filters.showOnStore}
                items={filterOptions}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"showOnStore"}
                chipLabel={"Show On Store"}
              />
              <SelectedChiptItems
                selectedItems={filters.showonRegistration}
                items={filterOptions}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"showonRegistration"}
                chipLabel={"Show On Registration"}
              />

              <div
                style={{
                  textAlign: "right",
                }}>
                {filtersCount > filtersLimit && (
                  <IconButton
                    color={"primary"}
                    onClick={() => setShowFilter(!showFilters)}>
                    <MoreHoriz />
                  </IconButton>
                )}
              </div>
            </Accordion>

            <div className='userDetailsSearchField inputWrapper filterSelect'>
              <div style={{marginBottom:"10px"}}>
                <h4>Product Creation Date</h4>
                <FilterCreationDate
                  StartDate={filters.createdOnFrom}
                  setStartDate={(date) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      createdOnFrom: date,
                    }));
                  }}
                  EndDate={filters.createdOnTo}
                  setEndDate={(date) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      createdOnTo: date,
                    }));
                  }}
                />
              </div>

              <FilterAutoComplete
                value={filterCategories.filter(eg => filters?.categoryId?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    categoryId: value.map(cat => cat.id)
                  }));
                }}
                id='product-category-dd'
                options={filterCategories}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Product Category"}
                selectedFilter={filters.categoryId?.length}
              />
              <FilterAutoComplete
                value={statusOption.filter(eg => filters.status?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    status: value.map(cat => cat.id)
                  }));
                }}
                id='product-status-dd'
                options={statusOption}
                getOptionLabel={option => option?.name || ""}
                placeholder={"Category Status"}
                selectedFilter={filters.status?.length}
              />
              <FilterAutoComplete
                value={brands.filter(eg => filters.brandId?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    brandId: value.map(cat => cat.id)
                  }));
                }}
                id='product-brandId-dd'
                options={brands}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Product Brand"}
                selectedFilter={filters.brandId?.length}
              />
              <FilterAutoComplete
                value={filterOptions.filter(eg => filters.showonRegistration?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    showonRegistration: value.map(cat => cat.id)
                  }));
                }}
                id='product-launch-dd'
                options={filterOptions}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Product Launch"}
                selectedFilter={filters.showonRegistration?.length}
              />
              <FilterAutoComplete
                value={filterOptions.filter(eg => filters.showOnStore?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    showOnStore: value.map(cat => cat.id)
                  }));
                }}
                id='product-launch-dd'
                options={filterOptions}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Store Availability"}
                selectedFilter={filters.showOnStore?.length}
              />
            </div>

          </ProductInventoryFilter>
          <AllCategoriesGrid
            listData={categories}
            loadData={() => loadData()}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          {/* {categories?.length <= 0 ? (
            <EmptyGridText
              text={"product category"}
              buttonText={"Product Category"}
              onClick={() => history.push("/new-product-category")}
            />
          ) : (
            <Pagination
              recordCount={paginationResponse?.totalRecords}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )} */}
        </div>
      </div>
    </Dashboard>
  );
};

export { ProductCategories };
