import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import { productMediaAction } from "../../../../store/product/productMediaAction";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { ProductMediaGrid } from "./ProductMediaGrid";
import { ImageUploader } from "../../../../components/common/ImageUploader";
import Loader from "../../../../components/common/Loader";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectBtn: {
    marginRight: "20px",
  },
}));

const ProductMediaListing = ({ productId, onProductMediaLoad }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [productMediaList, setProductMediaList] = useState([]);
  const [showFeatureImage, setShowFeatureImage] = useState(false);

  const loadData = useCallback(() => {
    dispatch(productMediaAction.onGetProductMedia(productId)).then(data => {
      setProductMediaList(data?.data);
      onProductMediaLoad && onProductMediaLoad(data?.data || []);
    });
  }, [dispatch, productId]);

  useEffect(() => {
    productId && productId > 0 && loadData();
  }, [productId, loadData, dispatch]);

  const addUpdateProductsMedia = ({ mediaFile }) => {
    const newMediaFile = {
      productMediaId: 0,
      fileName: mediaFile?.url,
      useOnStore: 0,
      fileType: mediaFile?.type,
      fileSize: mediaFile?.size,
      productId: productId,
      status: 0,
      isMainImage: 0,
      compressedBlob: mediaFile?.compressedBlob,
      compressBlobUrl: mediaFile?.compressBlobUrl,
    };
    if (productId && newMediaFile?.fileName) {
      setLoading(true);
      dispatch(productMediaAction.onAddUpdateProductMedia(newMediaFile)).then(
        data => {
          loadData();
          setLoading(false);
        }
      );
    }
  };

  return (
    <AccordionContainer
      title='Product Media'
      formButton={
        <Button
          variant='outlined'
          color='primary'
          className={classes.selectBtn}
          disabled={productId <= 0 || !productId}
          onClick={() => setShowFeatureImage(!showFeatureImage)}>
          <Add /> Add Photos
        </Button>
      }>
      {isLoading ? <Loader /> : null}
      <ImageUploader
        title={"Add Photos"}
        showFeatureImage={showFeatureImage}
        setShowFeatureImage={setShowFeatureImage}
        onImageSelect={img => addUpdateProductsMedia({ mediaFile: img })}
      />
      <ProductMediaGrid
        productId={productId}
        listData={productMediaList}
        loadData={loadData}
        setLoading={setLoading}
      />
    </AccordionContainer>
  );
};

export { ProductMediaListing };
