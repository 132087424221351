import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { EditIcon, SortIcon } from "../../../LeftNav/SvgIcons";
import moment from "moment";
import { LightenDarkenColor } from "lighten-darken-color";
import { RoutesObj } from "../../../../../routes";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  businessGroup: {
    background: "#E5F2FF",
    color: "#8254E3",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function PlacesGrid({
  startLoading,
  stopLoading,
  endUserList,
  selectedRecord,
  isDelete,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [showMovePopup, setShowMovePopup] = useState(false);

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.CustomerOverview.path,
      userId: value.userId,
      search: `id=${value.userId}`,
    });
  };

  const deleteAllPlaces = () => {};

  const onItemSelect = itemId => {};

  useEffect(() => {});

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        <Grid item md={9} className={classes.deleteItems}>
          {selectedItems.length > 0 && (
            <span className='gridDeleteItems' onClick={() => deleteAllPlaces()}>
              <Delete /> Delete
            </span>
          )}
        </Grid>
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont} disabled={true} />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              FIRST NAME
              <TableSortLabel
                active={order.orderByKey === "FirstName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "FirstName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              LAST NAME
              <TableSortLabel
                active={order.orderByKey === "LastName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "LastName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PRODUCTS OWNED
              <TableSortLabel
                active={order.orderByKey === "DeviceRegistered"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "DeviceRegistered",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              END USER GROUP
              <TableSortLabel
                active={order.orderByKey === "GroupName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "GroupName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              LAST LOGIN
            </TableCell>
            <TableCell className={classes.gridHeaderInner}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {endUserList.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridHeaderInner}>
                  <Checkbox
                    className={classes.checkBoxFont}
                    checked={item.checked}
                    onClick={() => selectedRecord(item)}
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.firstName}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.lastName}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.deviceRegistered}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <span
                    style={{
                      backgroundColor: LightenDarkenColor(
                        item.groupColor ? item.groupColor : "#54b0e3",
                        140
                      ),
                      color: item.groupColor ? item.groupColor : "#54b0e3",
                      padding: "8px 15px",
                      borderRadius: "6px",
                      fontSize: "12px",
                      fontWeight: 700,
                    }}>
                    {item?.groupName || "-"}
                  </span>
                  {/* {item.groupName} */}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.lastLoginDT !== null && item.lastLoginDT !== ""
                    ? moment(item.lastLoginDT).fromNow()
                    : ""}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <span onClick={() => redirectUrl(item)}>
                    <EditIcon />
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
          {endUserList.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any user.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default PlacesGrid;
