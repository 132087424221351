import { Grid } from "@material-ui/core";
import React from "react";
import InputField from "../../../../../components/common/InputField";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import { searchIcon } from "../../../../../resources/images";
import { FilterIcon, GridListIcon, TableGrid } from "../../../LeftNav/SvgIcons";
import AllAttributes from "./AllAttributes";
import ColorOption from "./ColorOption";
import DateFields from "./DateFields";
import TextOption from "./TextOption";

const AttributeDialog = ({ onclick }) => {
  return (
    <div className='newContactTabsWrapper'>
      <span className='attrbutesSearch'>
        <Grid container spacing={2}>
          <Grid
            item
            md={11}
            className='filterMainSearch endUserSearchFilterMain'>
            <img src={searchIcon} alt='' className='searchIcon' />
            <InputField placeholder='Search' />
            <span
              className='endUserSearchFilter'
              onClick={() => alert("clicked")}>
              <FilterIcon />
            </span>
          </Grid>

          <Grid item md={1} className='blockEndUserBtn gridFiltersButtons'>
            <TableGrid />
            <GridListIcon />
          </Grid>
        </Grid>
      </span>
      <TopTabNavigator
        tabs={[
          {
            id: "1",
            title: "All",
            component: <AllAttributes />,
          },
          {
            id: "2",
            title: "Text Option",
            component: (
              <TextOption onclick={() => alert("text option clicked")} />
            ),
          },
          {
            id: "3",
            title: "Color Option",
            component: (
              <ColorOption onclick={() => alert("color option clicked")} />
            ),
          },
          {
            id: "4",
            title: "Numerical/Sizing",
            component: "Numerical/Sizing",
          },
          {
            id: "5",
            title: "OFish Data Fields",
            component: (
              <DateFields onclick={() => alert("date field clicked")} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default AttributeDialog;
