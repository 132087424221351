import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Dashbard from '../../Dashbard';
import Loader from '../../../../../components/common/Loader';
import { InputField, SelectInput } from '../../../../../components/common';
import { conversationAction } from '../../../../../store/conversations/conversationAction';
import { contactsTypesActions } from '../../../../../store/contactTypes/contactTypesActions';

const useStyles = makeStyles(theme => ({
    heading: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        alignItems: "flex-start"
    },
    selectInputContiner: {
        '& .MuiInput-formControl': {
            border: "1px solid rgba(0, 0, 0, 0.23) !important",
            minHeight: "unset"
        },
        '& .MuiSelect-select.MuiSelect-select': {
            border: "unset !important"
        }
    },
    saveBtn: {
        background: "#3699FF",
        color: "#fff",
        marginTop: "10px",
        '&:hover': {
            background: "#3699FF",
            color: "#fff",
        }
    },
}))

const NewLeadGeneration = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id: paramId } = useParams();
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [leadStatuses, setLeadStatuses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(1);
    const [leadGenerationData, setLeadGenerationData] = useState({
        id:"",
        firstName: "",
        lastName: "",
        email: "",
    })

    useEffect(() => {
        dispatch(
            conversationAction.onGetConversationStatuses({
                productid: 2,
                statusName: "",
            })
        ).then(statusesResponse => {
            if (statusesResponse?.responseCode === 1)
                setLeadStatuses([...statusesResponse?.responseData]);
        });
    }, []);

    useEffect(() => {
        // setLoading(true);
        dispatch(
            contactsTypesActions.onGetLeadGeneration(paramId)
        ).then(data => {
            // setLoading(false);
            if (data?.data && data?.data?.success) {
                setLeadGenerationData(data?.data);
            }
        });
    }, [paramId]);

    const submitChatUserData = () => {
        // setLoading(true);
        dispatch(contactsTypesActions.onUpdateLeadGeneration(leadGenerationData))
            .then((res) => {
                if (res.responseCode === 1) {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log("There is some issue in chat Detail", error);
            })
        setLoading(false);
    }

    return (
        <Dashbard>
            <div className='body-content'>
                {isLoading ? <Loader /> : null}
                <div className={`contentHeading ${classes.heading}`} style={{

                }}>
                    <h1>New Lead Generation</h1>
                    <div>
                        <Button
                            color='primary'
                            variant='text'
                            onClick={() => history.push("/lead-generation")}
                        >
                            <ArrowBackIos style={{ fontSize: "14px" }} />
                            {`Back to All Lead Generations`}
                        </Button>
                    </div>
                </div>
                <div className='' style={{
                    background: "#fff",
                    padding: "20px",
                    borderRadius: "12px",
                }}>
                    <ValidatorForm
                        ref={useRef("form")}
                    >
                        <h2>Lead Generation Information</h2>
                        <Grid container spacing={2} className="">
                            {/* <Divider /> */}
                            <Grid item xs={6}>
                                <InputField
                                    showRequired={true}
                                    placeholder="Type an answer"
                                    inputlabel={"Enter First Name"}
                                    id="lead-name"
                                    name="lead-name"
                                    validators={["required"]}
                                    onChange={({ target }) =>
                                    setLeadGenerationData((prevValue) => ({
                                        ...prevValue,
                                        firstName: target.value
                                    }))
                                    }
                                    value={leadGenerationData?.firstName}
                                    errorMessages={[`Please enter Name`]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    showRequired={true}
                                    placeholder="Type an answer"
                                    inputlabel={"Enter Last Name"}
                                    id="lead-name"
                                    name="lead-name"
                                    validators={["required"]}
                                    onChange={({ target }) =>
                                    setLeadGenerationData((prevValue) => ({
                                        ...prevValue,
                                        lastName: target.value
                                    }))
                                    }
                                    value={leadGenerationData?.lastName}
                                    errorMessages={[`Please enter Name`]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    showRequired={true}
                                    placeholder="Type an answer"
                                    inputlabel={"Enter Email"}
                                    id="lead-email"
                                    name="lead-emial"
                                    validators={["required"]}
                                    type={"email"}
                                    onChange={({ target }) =>
                                    setLeadGenerationData((prevValue) => ({
                                        ...prevValue,
                                        email: target.value
                                    }))
                                    }
                                    value={leadGenerationData?.email}
                                    errorMessages={[`Please enter Email`]}
                                />
                            </Grid>
                            {/* <Grid item xs={6} className={classes.selectInputContiner}>
                                <SelectInput
                                    placeholder='Please Select'
                                    disablePlaceholderOption
                                    selectlabel="Status"
                                    value={selectedStatus ?? 1}
                                    customMenuItems={leadStatuses.map(status => {
                                        return {
                                            ...status,
                                            name: status?.nameInternal,
                                        };
                                    })}
                                    handleChange={({ target }) =>
                                        setSelectedStatus(target.value)
                                    }
                                />
                            </Grid> */}

                        </Grid>
                        <Grid container justifyContent='flex-end'>
                            <Button
                                type='submit'
                                className={classes.saveBtn}
                                disabled={!leadGenerationData.firstName || !leadGenerationData.lastName || !leadGenerationData.email} 
                                onClick={() => submitChatUserData()}
                            >
                                Save
                            </Button>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
        </Dashbard>
    )
}

export default NewLeadGeneration;