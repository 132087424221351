import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, TableSortLabel, IconButton } from "@material-ui/core";
import { Add, OpenInNew, Remove, Delete } from "@material-ui/icons";
import { toast } from "react-toastify";
import moment from "moment";
import CollectionCategoryPopup from "./CollectionCategoryPopup";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
  gridHeaderInnerCenter: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
}));

function CollectionCategoryGrid({
  colCategoryData,
  startLoading,
  loadData,
  endLoading,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsibleItem, setCollapsibleItem] = useState(null);

  const { selectedColCategory } = useSelector(state => state.AssetsReducer);

  const redirectUrl = () => {
    history.push("/new-collection-category");
  };
  const selectColCollection = value => {
    dispatch(assetsActions.onSelectColCategory(value));
  };

  const checkColCollectionSelected = itemId => {
    const assetExist = selectedColCategory.find(asset => {
      return asset.id === itemId;
    });
    if (assetExist) {
      return true;
    } else {
      return false;
    }
  };
  const deleteColCategory = () => {
    startLoading();
    dispatch(assetsActions.onDeleteColCategories(selectedColCategory)).then(
      data => {
        if (data && data.success) {
          loadData();
        } else {
          toast.error(data.message);
          endLoading();
        }
      }
    );
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        {selectedColCategory.length > 0 && (
          <Grid item md={9} className={classes.deleteItems}>
            <span
              className='gridDeleteItems'
              onClick={() => deleteColCategory()}>
              <Delete /> Delete
            </span>
          </Grid>
        )}
      </Paper>
      <ul className='releaseGridListHeader'>
        <li>
          CATEGORY NAME
          <TableSortLabel
            active={order.orderByKey === "Name"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "Name",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          NO. OF COLLECTIONS
          <TableSortLabel
            active={order.orderByKey === "NoOfCollections"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "NoOfCollections",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          DATE CREATED
          <TableSortLabel
            active={order.orderByKey === "CreatedOn"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "CreatedOn",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          COLOR
          <TableSortLabel
            active={order.orderByKey === "Color"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "Color",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          PUBLIC
          <TableSortLabel
            active={order.orderByKey === "IsPublic"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "IsPublic",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>&nbsp;</li>
      </ul>
      {colCategoryData?.map((item, index) => {
        console.log("item : ", item);
        return (
          <>
            {item.id !== 1 ? (
              <ul
                className={`releaseGridListBody ${
                  collapsibleItem === item.id ? "selectedSubCategory" : ""
                }`}
                key={index}>
                <span style={{ display: "flex", width: "100%" }}>
                  <li>
                    {item.childrens?.length > 0 && (
                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => {
                          setCollapsibleItem(
                            collapsibleItem && collapsibleItem === item.id
                              ? null
                              : item?.id
                          );
                        }}>
                        {collapsibleItem && collapsibleItem === item.id ? (
                          <Remove />
                        ) : (
                          <Add />
                        )}
                      </IconButton>
                    )}
                    {item.name}
                  </li>
                  <li> {item.noOfCollections}</li>
                  <li>{moment(item.createdOn).format("MMMM DD, YYYY")}</li>
                  <li>
                    <span
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "10px",
                        display: "flex",
                        backgroundColor: item.color ? item.color : "#EB5757",
                      }}
                    />
                  </li>
                  <li>
                    {item.isPublic ? (
                      <span className={classes.inUseYes}>Yes </span>
                    ) : (
                      <span className={classes.inUseNo}>No </span>
                    )}
                  </li>
                  <li>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push("/new-collection-category?id=" + item.id);
                      }}>
                      <EditIcon />
                    </span>
                    {/* <span
                      onClick={() => {
                        window.open(
                          window.location.host +
                            "/media-library/" +
                            item.urlSlug
                        );
                      }}>
                      <OpenInNew style={{ color: "rgb(54, 153, 255)" }} />
                    </span> */}
                    <a href={"/media-library/" + item.urlSlug} target='_blank'>
                      <OpenInNew style={{ color: "rgb(54, 153, 255)" }} />
                    </a>
                  </li>
                </span>
                {collapsibleItem && collapsibleItem === item.id && (
                  <>
                    {item.childrens?.map(historyRow => {
                      return (
                        <>
                          {item.id !== 1 ? (
                            <ul
                              className='releaseGridListBody releaseSubCatRow'
                              key={index}>
                              <li> {historyRow.name}</li>
                              <li> {historyRow.noOfCollections}</li>
                              <li>
                                {moment(historyRow.createdOn).format(
                                  "MMMM DD, YYYY"
                                )}
                              </li>
                              <li>
                                <span
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "10px",
                                    display: "flex",
                                    backgroundColor: historyRow.color
                                      ? historyRow.color
                                      : "#EB5757",
                                  }}
                                />
                              </li>
                              <li>
                                {historyRow.isPublic ? (
                                  <span className={classes.inUseYes}>Yes</span>
                                ) : (
                                  <span className={classes.inUseNo}>No </span>
                                )}
                              </li>
                              <li>
                                <span
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push(
                                      "/new-collection-category?id=" +
                                        historyRow.id
                                    );
                                  }}>
                                  <EditIcon />
                                </span>
                                {/* <span>
                                  <OpenInNew
                                    style={{ color: "rgb(54, 153, 255)" }}
                                  />
                                </span> */}
                                <a
                                  href={"/media-library/" + item.urlSlug}
                                  target='_blank'>
                                  <OpenInNew
                                    style={{ color: "rgb(54, 153, 255)" }}
                                  />
                                </a>
                              </li>
                            </ul>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                )}
              </ul>
            ) : null}
          </>
        );
      })}
      {/* <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}></TableCell>
            <TableCell className={classes.gridHeaderInner}>
              CATEGORY
              <TableSortLabel
                active={order.orderByKey === "Name"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Name",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              NO. OF COLLECTIONS
              <TableSortLabel
                active={order.orderByKey === "NoOfCollections"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "NoOfCollections",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              DATE CREATED
              <TableSortLabel
                active={order.orderByKey === "DateCreated"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "DateCreated",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              COLOR{" "}
              <TableSortLabel
                active={order.orderByKey === "Color"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Color",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PUBLIC{" "}
              <TableSortLabel
                active={order.orderByKey === "IsPublic"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "IsPublic",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {colCategoryData &&
            colCategoryData.length > 0 &&
            colCategoryData.map((item, index) => {
              return (
                <>
                  {item.id !== 1 ? (
                    <TableRow key={index} className='gridBodyRow'>
                      <TableCell className={classes.gridHeaderInner}>
                        <Checkbox
                          className={classes.checkBoxFont}
                          onClick={() => selectColCollection(item)}
                          checked={checkColCollectionSelected(item.id)}
                        />
                      </TableCell>
                      <TableCell className={classes.gridBodyInner}>
                        {item.name}
                      </TableCell>
                      <TableCell className={classes.gridBodyInner}>
                        {item.noOfCollections}
                      </TableCell>
                      <TableCell className={classes.gridBodyInner}>
                        {moment(item.dateCreated).format("MMMM DD, YYYY")}
                      </TableCell>
                      <TableCell className={classes.gridBodyInner}>
                        <span
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "10px",
                            display: "flex",
                            backgroundColor: item.color ? item.color : "#EB5757"
                          }}
                        />
                      </TableCell>
                      {item.isPublic ? (
                        <TableCell
                          className={[classes.inUseYes, classes.gridBodyInner]}>
                          Yes
                        </TableCell>
                      ) : (
                        <TableCell
                          className={[classes.inUseNo, classes.gridBodyInner]}>
                          No
                        </TableCell>
                      )}

                      <TableCell className='editButton'>
                        <span
                          onClick={() => {
                            history.push(
                              "/new-collection-category?id=" + item.id
                            );
                          }}>
                          <EditIcon />
                        </span>
                        <span>
                          <OpenInNewIcon
                            style={{ color: "rgb(54, 153, 255)" }}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          {colCategoryData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t created any collection categories. Start by
                  <Button onClick={redirectUrl}>
                    creating a new collection category
                  </Button>
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table> */}
    </Paper>
  );
}

export default CollectionCategoryGrid;
