import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Add, ArrowBackIos } from "@material-ui/icons";
import queryString from "query-string";
import { Environments } from ".";
import Dashboard from "../Dashbard";
import ProductsOwner from "./ProductsOwner";
import CustomerNotes from "./CustomerNotes";
import EndCustomerConversation from "./EndCustomerConversation";
import EndUserOverview from "./EndUserOverview";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import EndUserOrders from "./EndUserOrders/EndUserOrders";
import { AddNewProduct } from "./RegisterNewProduct/AddNewProduct";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import EndUserActivityTab from "./EndUserActivityTab";
import EndUserNotesGrid from "./EndUserNotesGrid";

const CustomerOverview = () => {
  // var userId = queryString.parse(location.search);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [userId, setUserId] = useState(null);
  const [activeTabId, setActiveTabId] = useState(null);
  const [isNewProduct, setIsNewProduct] = useState(false);

  const redirectUrl = () => {
    history.push("/all-end-users");
  };

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (selectedUser?.id) {
      setUserId(selectedUser.id);
      setActiveTabId(selectedUser.acitveTabId);
    } else {
      setUserId(null);
      setActiveTabId("2");
    }
  }, [location]);

  const registerNew = () => {
    setIsNewProduct(true);
    dispatch(endUserActions.onResetRegister("deviceOverviewInfo", ""));
  };

  const { editUserName } = useSelector(state => state.endUserReducer);

  return (
    <Dashboard>
      <div className='body-content'>
        <div className='endUserHeanding'>
          <div className='consumerHeading '>
            <h1>
              {userId !== null && editUserName !== ""
                ? editUserName?.charAt(0).toUpperCase() + editUserName?.slice(1)
                : "Customer Name"}
            </h1>
            <Button color='primary' onClick={redirectUrl} variant='contained'>
              <ArrowBackIos /> Back to End Users
            </Button>
          </div>
          {activeTabId === "3" && (
            <div>
              <Button
                color='primary'
                variant='contained'
                onClick={() => registerNew()}
                startIcon={<Add />}>
                Register Product
              </Button>
            </div>
          )}
        </div>
        <div className='enduserTabs'>
          {userId && activeTabId ? (
            <TopTabNavigator
              getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
              value={activeTabId}
              defaultTabId={activeTabId}
              tabs={[
                {
                  id: "1",
                  title: "Overview",
                  component: <EndUserOverview isActive={activeTabId === "1"} />,
                },
                {
                  id: "2",
                  title: "Customer Information",
                  component: <Environments isActive={activeTabId === "2"} />,
                },
                {
                  id: "3",
                  title: "Products Owned",
                  component: isNewProduct ? (
                    <AddNewProduct
                      userId={userId}
                      setIsNewProduct={setIsNewProduct}
                      isBusinessCustomers={false}
                    />
                  ) : (
                    <ProductsOwner
                      isActive={activeTabId === "3"}
                      isBusinessCustomers={false}
                    />
                  ),
                },
                // {
                //   id: 4,
                //   title: "Orders",
                //   component: <EndUserOrders />,
                // },
                // {
                //   id: "5",
                //   title: "Conversation",
                //   component: (
                //     <EndCustomerConversation
                //       name={editUserName || "Customer Name"}
                //       isActive={activeTabId === "5"}
                //     />
                //   ),
                // },
                // {
                //   id: "6",
                //   title: "Activities",
                //   component: (
                //     <EndUserActivityTab
                //       isActive={activeTabId === "6"}
                //       isEndUser={true}
                //     />
                //   ),
                // },
                // {
                //   id: "7",
                //   title: "Notes",
                //   // component: <CustomerNotes isActive={activeTabId === "7"} />,
                //   component: (
                //     <EndUserNotesGrid
                //       isActive={activeTabId === "7"}
                //       isEndUser={true}
                //     />
                //   ),
                // },
              ]}
            />
          ) : (
            activeTabId && (
              <TopTabNavigator
                getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
                value={activeTabId}
                tabs={[
                  {
                    id: "2",
                    title: "Customer Information",
                    component: (
                      <Environments
                        isActive={activeTabId === "2"}
                        isAddNew={true}
                      />
                    ),
                  },
                ]}
              />
            )
          )}
        </div>
      </div>
    </Dashboard>
  );
};

export { CustomerOverview };
