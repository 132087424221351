import React from 'react';
import {Link} from 'react-router-dom';


function NavLink({text, link, imagUrl}) {
    return (
        <div className="navLinks">
            <img src={imagUrl} alt="" />
            <Link to={link} >{text}</Link>
        </div>
    );
}

export default NavLink;