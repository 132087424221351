import React, { useCallback, useEffect, useState } from 'react';
import Dashbard from '../../Dashbard';
import Loader from '../../../../../components/common/Loader';
import { BusinessContactsTabButtons } from '../../../../../components/common';
import BlockedLocationGrid from './BlockedLocationGrid';
import { contactsActions } from '../../../../../store/contacts/contactsActions';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../../../utils/use-debounce';
import { toast } from 'react-toastify';

const BlockedBusinessLocation = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [locList , setLocList] = useState([]);
  const [search, setSearch] = useState("");
  const { debouncedValue } = useDebounce(search , 500);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    key: "Name",
    val: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      contactsActions.onGetBlockedBusinessLocation(3)
    ).then(data => {
      setLocList(data?.data)
      setLoading(false);
    });
  }, [dispatch, debouncedValue, page, rowsPerPage, order.key, order.val]);

  const deleteLocation = id => {
    setLoading(true);
    dispatch(contactsActions.onDeleteCompanyLocations(id)).then(data => {
      setLoading(false);
      if (data?.success) {
        loadData();
        toast.success("Location deleted");
      } else {
        toast.error(data?.message);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, [dispatch, loadData, page, rowsPerPage]);

  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>Business Contacts</h1>

        </div>
        <BusinessContactsTabButtons />
        <BlockedLocationGrid
        search={search}
        setSearch={setSearch}
        deleteLocation={deleteLocation}
        locList={locList}
        />
      </div>
    </Dashbard>
  )
}

export default BlockedBusinessLocation;