import React from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import { OpenInNew } from "@material-ui/icons";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { RoutesObj } from "../../../../routes";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { LightenDarkenColor } from "lighten-darken-color";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeaderInnerCenter: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
      boxShadow: "none",
    },
  },
  launchedButtons: {
    color: "#00ABA3",
    backgroundColor: "rgba(0, 171, 163, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(0, 171, 163, 0.25)",
      boxShadow: "none",
    },
  },
  newProductLaunch: {
    color: "#8254E3",
    background: "rgba(130, 84, 227, 0.25)",
    boxShadow: "none",
    "&:hover": {
      background: "rgba(130, 84, 227, 0.25)",
      boxShadow: "none",
    },
  },
}));

function AllReleaseGrid({
  allReleaseData,
  startLoading,
  endLoading,
  loadData,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedRelease } = useSelector(state => state.LaunchesReducer);
  const history = useHistory();

  const redirectUrl = value => {
    history?.push({
      pathname: RoutesObj.UpdateMediaRelease.path,
      state: { release: value },
      search: `id=${value.id}`,
    });
  };

  const selectReleases = value => {
    dispatch(launchesActions.onSelectReleases(value));
  };

  const onSelectAllReleases = () => {
    if (!selectedRelease?.length) {
      dispatch(launchesActions.onAllSelectReleases(allReleaseData));
    } else {
      dispatch(launchesActions.onAllSelectReleases([]));
    }
  };

  const checkReleaseSelected = itemId => {
    const releaseExist = selectedRelease.find(release => {
      return release.id === itemId;
    });
    if (releaseExist) {
      return true;
    } else {
      return false;
    }
  };
  const deleteReleases = () => {
    startLoading();
    dispatch(launchesActions.onDeleteRelease(selectedRelease)).then(data => {
      if (data && !data.success) {
        toast.error(data.message);
      }
      loadData();
      toast.success("Release(s) deleted successfully")
    });
  };
  
  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        {selectedRelease?.length > 0 ? (
          <>
            <Grid item md={9} className={classes.deleteItems}>
              <span
                className='gridDeleteItems'
                onClick={() => deleteReleases()}>
                <DeleteIcon /> Delete
              </span>
              {/* <span className="gridDeleteItems">
            <CancelIcon /> Cancel Launch
          </span> */}
            </Grid>
            {/* <Grid item md={3} className={classes.selectedItems}>
          <span className="gridSelection">
            <CloseIcon /> 2
          </span>{" "}
          selected
        </Grid> */}
          </>
        ) : null}
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont}
              onClick={onSelectAllReleases}
              checked={allReleaseData?.length > 0 && selectedRelease?.length === allReleaseData?.length}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              RELEASE TITLE
              <TableSortLabel
                active={order?.orderByKey === "Title"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Title",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              RELEASE DATE
              <TableSortLabel
                active={order?.orderByKey === "AnnouncementDate"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "AnnouncementDate",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
              CATEGORY
              <TableSortLabel
                active={order?.orderByKey === "Category"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Category",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
              STATUS
              <TableSortLabel
                active={order?.orderByKey === "state"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "state",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
              QUICK ACTIONS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allReleaseData?.length > 0 &&
            allReleaseData?.map((item, index) => {
              return (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell className={classes.gridHeaderInner}>
                    <Checkbox
                      onClick={() => selectReleases(item)}
                      className={classes.checkBoxFont}
                      checked={checkReleaseSelected(item.id)}
                    />
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.title}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.announcementDate
                      ? moment(item.announcementDate).format("MMMM DD, YYYY")
                      : ""}
                  </TableCell>
                  <TableCell
                    className={[
                      classes.gridBodyInner,
                      classes.gridBodyInnerCenter,
                    ]}>
                    <Button
                      variant='contained'
                      style={{
                        backgroundColor: LightenDarkenColor(
                          item.categoryColor ? item.categoryColor : "#54b0e3",
                          140
                        ),
                        color: item.categoryColor
                          ? item.categoryColor
                          : "#54b0e3",
                        padding: "8px 15px",
                        borderRadius: "6px",
                        fontSize: "12px",
                        fontWeight: 700,
                      }}>
                      {item.category}
                    </Button>
                  </TableCell>
                  <TableCell className={classes.gridBodyInnerCenter}>
                    {item.state === 1 ? (
                      <Button
                        variant='contained'
                        className={classes.pendingButtons}>
                        Planning
                      </Button>
                    ) : (
                      <Button
                        variant='contained'
                        className={classes.launchedButtons}>
                        Launched
                      </Button>
                    )}
                  </TableCell>
                  <TableCell className='editButton'>
                    <span onClick={() => redirectUrl(item)}>
                      <EditIcon />
                    </span>
                    <a
                      href={"/releases/category/" + item.urlSlug}
                      target='_blank'>
                      <OpenInNew style={{ color: "rgb(54, 153, 255)" }} />
                    </a>
                  </TableCell>
                </TableRow>
              );
            })}
          {allReleaseData?.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t made any media releases. Start planning and{" "}
                  <Button onClick={() => history.push("/new-media-release")}>
                    create a new release
                  </Button>
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default AllReleaseGrid;
