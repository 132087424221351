import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as signalR from "@microsoft/signalr";
import Dashbard from "../Dashbard";
import ConversationListing from "./ConversationListing";
import EndUserChat from "./EndUserChat";
import { conversationAction } from "../../../../store/conversations/conversationAction";
import {
  ConversationConfig,
  endpoints,
} from "../../../../config/conversationConfig";
import AddIcon from "@material-ui/icons/Add";
import { TabButtons } from "../../../../components/common/TabButtons/TabButtons";
import { RoutesObj } from "../../../../routes";
import { conversationApi } from "../../../../store/conversations/conversationApi";

const AllConversations = ({ ConversationStatus, filterBy }) => {
  const { conversationPlateform } = useSelector(
    state => state.conversationReducer
  );
  const dispatch = useDispatch();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { connectionUrl } = endpoints.conversation;
  const { hubId } = useSelector(state => state.conversationReducer);

  const [isLoading, setLoading] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isCross, setIsCross] = useState(true);
  const [isNewConversation, setIsNewConversation] = useState(false);
  const [connection, setConnection] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const selectedRoomRef = useRef(null);

  const [isTemplateModal, setIsTemplate] = useState(false);
  const [roomMsgs, setRoomMsgs] = useState([]);
  const [addTemplate, setAddTemplate] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [subject, setSubject] = useState("");
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [selectedConversationType, setSelectedConversationType] = useState({
    id: 1,
    name: "General",
  });

  const [roomConversationCategories, setRoomConversationCategories] = useState(
    []
  );
  const [isFavoriteRoom, setIsFavoriteRoom] = useState(null);
  const onExpandclick = () => {
    setIsExpand(!isExpand);
    // setIsCross(false);
  };
  const buildConnection = useCallback(() => {
    console.log("Building Connection");
    const protocol = new signalR.JsonHubProtocol();
    // const transport = signalR.HttpTransportType.WebSockets;
    const connectURL = `${connectionUrl}?ClientId=${ConversationConfig.clientId}&ProductId=${ConversationConfig.productId}&ClientName=${ConversationConfig.userName}&generic=${ConversationConfig.generic}`;
    const connect = new signalR.HubConnectionBuilder()
      .withUrl(connectURL, {
        logMessageContent: true,
        logger: signalR.LogLevel.Trace,
        // transport: transport,
        // skipNegotiation: true,
      })
      .withAutomaticReconnect()
      .withHubProtocol(protocol)
      .build();
    setConnection(connect);
    connect.on("send", data => {});
    connect
      .start()
      .then(() => {})
      .catch(err => {
        console.log("error while establishing signalr connection: " + err);
      });
  }, [connectionUrl]);

  useEffect(() => {
    buildConnection();
  }, [buildConnection]);

  useEffect(() => {
    if (!!connection) {
      connection.on("onUserConnected", data => {
        console.log("hubId : ", data);
        if (data)
          dispatch(conversationAction.onChange("hubId", data?.HubUserId));
      });
      connection.on("MessageRecieved", data => {
        loadRoomChat(selectedRoomRef.current);
        dispatch(conversationAction.onChange("loadCurrentListing", true));
      });
    }
  }, [connection, dispatch]);

  const loadRoomChat = useCallback(
    chatDetail => {
      setLoading(true);
      dispatch(
        conversationAction.onGetChatDetailsByRoomId({
          roomId: chatDetail?.roomId,
          productId: ConversationConfig.productId,
          hubUserId: chatDetail?.hubUserId,
          take: 10000,
          skip: 0,
        })
      ).then(res => {
        setLoading(false);
        if (res?.responseCode === 1) {
          setRoomMsgs(res.responseData);
        }
      });
    },
    [dispatch]
  );

  const updateConversationStatusInRoom = useCallback(
    statusId => {
      setLoading(true);
      dispatch(
        conversationAction.onUpdateConversationStatusInRoom({
          roomId: selectedRoom?.roomId,
          statusId: statusId,
        })
      ).then(convResp => {
        console.log("UPDATE CONV RESPONSE : ", convResp);
        setSelectedRoom({
          ...selectedRoom,
          statusId: statusId,
        });
      });
    },
    [dispatch, selectedRoom]
  );

  useEffect(() => {
    if (!!selectedRoom) loadRoomChat(selectedRoom);
  }, [loadRoomChat, selectedRoom?.roomId, selectedRoom?.statusId]);

  const onGetCompanyAutoCompleteByName = compnayName => {
    dispatch(
      conversationAction.onGetCompanyAutoCompleteByName(compnayName)
    ).then(res => {
      if (res?.data) {
        setCompanyList(res?.data?.length > 0 ? res?.data : []);
      }
    });
  };

  const onSendMessage = async ({
    messageContent,
    clearContent,
    fileName,
    uploadFile,
  }) => {
    setLoading(true);
    let removedEntities = messageContent?.replace(/&nbsp;/g, ' ');
    let withoutTags = removedEntities?.replace(/<[^>]*>/g, '');
    let cleanedStr = withoutTags?.replace(/\s+/g, '').trim();
    if (!messageContent?.trim() && !Boolean(fileName)) {
      setLoading(false);
      return;
    }
    let attachments = [];

    if (fileName && fileName !== "") {
      const savedAttachments = await dispatch(
        conversationAction.onUploadFile(uploadFile)
      );
      if (savedAttachments?.responseCode === 1) {
        attachments.push({
          name: fileName,
          url: savedAttachments.responseData?.url,
        });
      }
    }

    if (!selectedRoom) {
      let to = [];
      selectedCompanyList.map(x => {
        to.push({
          id: x.id.toString(),
          name: x.name,
          allowedOnCampaignsId: selectedConversationType?.id.toString(),
          generic: "Customer",
        });
      });
      const data = {
        content: Boolean(cleanedStr) ? messageContent : "",
        // roomName: selectedRoom?.roomName,
        roomName: subject,
        messageFrom: {
          id: ConversationConfig.clientId,
          name: ConversationConfig.userName,
          allowedOnCampaignsId: selectedConversationType?.id.toString(),
          generic: ConversationConfig.userName,
        },
        messageTo: to,
        productId: ConversationConfig.productId,
        attachments: attachments,
      };
      dispatch(conversationAction.onSendNewMsg(data)).then(res => {
        setLoading(false);
        if (res?.responseCode === 1) {
          setSubject("");
          setCompanyList([]);
          setSelectedCompanyList([]);
          dispatch(conversationAction.onChange("loadCurrentListing", true));
          loadRoomChat(res?.responseData);
          clearContent();
          setIsNewConversation(false);
        }
      });
    } else {
      const data = {
        roomId: selectedRoom?.roomId, //roomId.current,
        hubUserId: hubId,
        content: Boolean(cleanedStr) ? messageContent : "",
        tTimeZone: timeZone,
        attachments: attachments.length
          ? [{ name: fileName, path: attachments[0].url }]
          : [],
      };
      if (conversationPlateform?.platform === "Facebook") {
        let removeBRTag = messageContent?.replace(/<br\s*\/?>/gi, '\n');
        let removedEntities = removeBRTag?.replace(/&nbsp;/g, ' ');
        let content = removedEntities?.replace(/<[^>]*>/g, '').trim();
        dispatch(
          conversationAction.onSendMessages({
            messageFrom: "Aquatic AV",
            messageTo: conversationPlateform?.roomName,
            message: content ?? "",
            attachments: attachments.length
            ? [{ name: fileName, path: attachments[0].url }]
            : [],
          })
        ).then(res => {
          setLoading(false);
          if (res?.responseMsg === "Success") {
            loadRoomChat(selectedRoom);
            clearContent();
          }
        });
      }if(conversationPlateform?.platform === "Instagram"){
        let removeBRTag = messageContent?.replace(/<br\s*\/?>/gi, '\n');
        let removedEntities = removeBRTag?.replace(/&nbsp;/g, ' ');
        let content = removedEntities?.replace(/<[^>]*>/g, '').trim();
        dispatch(
          conversationAction.onSendInstaMessages({
            messageFrom: "Aquatic AV",
            messageTo: conversationPlateform?.roomName,
            message: content ?? "",
            attachments: attachments.length
            ? [{ name: fileName, path: attachments[0].url }]
            : [],
          })
        ).then(res => {
          setLoading(false);
          if (res?.responseMsg === "Success") {
            loadRoomChat(selectedRoom);
            clearContent();
          }
        });
      } else {
        connection.send("SendMessage", data).then(x => {
          setLoading(false);
          loadRoomChat(selectedRoom);
          clearContent();
        });
      }
      dispatch(conversationAction.onChange("loadCurrentListing", true));
    }
  };

  const setIsStar = (isStar, roomId) => {
    setLoading(true);
    dispatch(
      conversationAction.onMakeRoomFavorite({
        roomId: roomId,
        isFavorite: isStar,
        hubUserId: hubId,
      })
    ).then(res => {
      setLoading(false);
      setIsFavoriteRoom(isStar);
      if (res?.responseCode === 1) {
        roomMsgs.map(x => {
          if (x.roomId === roomId) {
            x.is_Favourite = isStar;
          }
        });
        setRoomMsgs([...roomMsgs], roomMsgs);
        //loadChat(hubUserId.current);
      }
      setIsFavoriteRoom(null);
    });
  };

  return (
    <Dashbard>
      <div className='body-content'>
        <h1>
          All conversations
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => {
              dispatch(
                conversationAction.onChange("conversationPlateform", {})
              );
              setSelectedRoom(null);
              setIsCross(true);
              setIsNewConversation(true);
              setRoomMsgs([]);
              setSelectedConversationType({
                id: 1,
                name: "General",
                color: "#E0D4F8",
              });
            }}>
            New Conversation
          </Button>
        </h1>
        <TabButtons
          tabs={[
            { title: "All Conversations", to: RoutesObj.AllConversation.path },
            {
              title: "Assigned to You",
              to: RoutesObj.AssignedConversations.path,
            },
            {
              title: "Unassigned",
              to: RoutesObj.UnAssignedConversations.path,
            },
            { title: "Spam", to: RoutesObj.SpamConversations.path },
            { title: "Drafts", to: RoutesObj.DraftConversations.path },
            { title: "Archived", to: RoutesObj.ArchivedConversations.path },
            { title: "Trash", to: RoutesObj.TrashConversations.path },
          ]}
        />
        <Grid container>
          <Grid
            item
            xs={isExpand === false && isCross === false ? 12 : 5}
            style={{ display: isExpand ? "none" : "initial" }}>
            <ConversationListing
              filterByInbox={filterBy}
              conversationStatus={ConversationStatus}
              selectedRoom={selectedRoom}
              onChatClick={async room => {
                // if (isExpand) setIsExpand(!isExpand);
                if (!isCross) setIsCross(!isCross);
                setIsNewConversation(false);
                setSelectedRoom(room);
                selectedRoomRef.current = room;

                if (room?.campaignName !== "") {
                  setSelectedConversationType({
                    id: room?.campaignId,
                    name: room?.campaignName,
                    color: room?.color,
                  });
                } else {
                  setSelectedConversationType({
                    id: 0,
                    name: "Unassigned",
                    color: "#e2e5e9",
                  });
                }
              }}
              setLoading={x => setLoading(x)}
              isTemplateModal={isTemplateModal}
              setIsTemplate={x => setIsTemplate(x)}
              setAddTemplate={x => setAddTemplate(x)}
              isFavoriteRoom={isFavoriteRoom}
              setRoomConversationCategories={setRoomConversationCategories}
              setIsStar={setIsStar}
            />
          </Grid>

          {/* {!isExpand && ( */}
          <Grid
            item
            xs={isExpand ? 12 : 7}
            style={{ display: isCross === false ? "none" : "initial" }}>
            <EndUserChat
              isLoading={isLoading}
              roomDetail={selectedRoom}
              onConvStatusChange={convStatusId =>
                updateConversationStatusInRoom(convStatusId)
              }
              roomMsgs={roomMsgs}
              onexpandclick={onExpandclick}
              expand={isExpand}
              oncloseclick={() => {
                setIsCross(!isCross);
                setIsExpand(false);
                // setSelectedRoom(null);
                // setIsNewConversation(false);
              }}
              close={selectedRoom === null ? false : isCross}
              onSendMessage={onSendMessage}
              isTemplateModal={isTemplateModal}
              setIsTemplate={x => setIsTemplate(x)}
              addTemplate={addTemplate}
              setAddTemplate={setAddTemplate}
              isNewConversation={isNewConversation}
              companyList={companyList}
              onGetCompanyAutoCompleteByName={onGetCompanyAutoCompleteByName}
              setSelectedCompanyList={setSelectedCompanyList}
              subject={subject}
              setSubject={setSubject}
              setIsStar={setIsStar}
              setLoading={x => setLoading(x)}
              selectedConversationType={selectedConversationType}
              setSelectedConversationType={async convType => {
                setSelectedConversationType(convType);
                const convTypeRes =
                  await conversationApi.changeRoomCoversationType({
                    roomId: selectedRoom.roomId,
                    campaignId: convType.id,
                  });
                if (convTypeRes) {
                  loadRoomChat(selectedRoom);
                }
              }}
              roomConversationCategories={roomConversationCategories}
            />
          </Grid>
          {/* )} */}
        </Grid>
      </div>
    </Dashbard>
  );
};

export default AllConversations;
