import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getPubReleases(releasePayload) {
  return axios
    .post(endpoints.pubReleases.getAllPubReleases, releasePayload)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubRelCategory() {
  return axios
    .post(endpoints.pubReleases.getAllPubRelCategory)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubRelDateFilter(catIds) {
  return axios
    .post(endpoints.pubReleases.getAllPubDateRelease, {
      categoryIds: catIds,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubRelDetail(relId, urlSlug) {
  return axios
    .post(endpoints.pubReleases.getPubRelDetail, {
      releaseId: relId,
      urlSlug: urlSlug,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubAllFilterData() {
  return axios
    .get(endpoints.pubReleases.getPubAllFilterRelease)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

export const pubReleaseApi = {
  getPubReleases: getPubReleases,
  getPubRelCategory: getPubRelCategory,
  getPubRelDateFilter: getPubRelDateFilter,
  getPubRelDetail: getPubRelDetail,
  getPubAllFilterData: getPubAllFilterData,
};
