import { useDispatch } from "react-redux";
import { productInventoryActions } from "../store/product/productInventoryAction";

const useCRUD = () => {
  const dispatch = useDispatch();

  // TODO, READ data
  // const loadList = useCallback(
  //   (url, payload) => {
  //     dispatch(
  //       productInventoryActions.onLoadData({
  //         url: url,
  //         payload: payload,
  //       })
  //     ).then(data => {
  //       setRecods(data?.data);
  //     });
  //   },
  //   [dispatch]
  // );

  // useEffect(() => {
  //   loadList(url, payload);
  // }, [loadList, url, payload.papId]);

  const postData = ({ url, payload }) => {
    return dispatch(
      productInventoryActions.onLoadData({
        url: url,
        payload: payload,
      })
    );
  };

  return { postData };
};

export { useCRUD };
