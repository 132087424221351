import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 370,
    padding: "20px",
    boxSizing: "border-box",

    "&:hover": {
      pointerEvent: "none",
    },
  },
  media: {
    height: 140,
    backgroundSize: "contain",
    backgroundColor: "#F3F6F9",
  },
  CardContent: {
    padding: "10px 0",
    "&:hover": {
      pointerEvent: "none",
      backgroundColor: "transparent",
    },
  },
});

function Cards({
  subheading,
  cardtext,
  date,
  picture,
  pictitle,
  time,
  onClick,
  headingClick,
  cardDescription,
}) {
  const classes = useStyles();

  return (
    <div className="cardWrapper gridListWrapper">
      <Card className={classes.root} onClick={onClick} disableripple>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={picture}
            title={pictitle}
          />
          <CardContent className={(classes.CardContent, "cardContentwrapper")}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className="cardHeading"
              onClick={headingClick}>
              {subheading}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className="cardDescription">
              {cardDescription}
            </Typography>

            <span className="listViewAllBtn">
              <Typography variant="body2" color="textSecondary" component="p">
                {cardtext}
              </Typography>

              {/* <Button
                color="primary"
                size="small"
                // onClick={() => viewAllData(item)}
              >
                View All
              </Button> */}
            </span>

            <Typography variant="body2" color="textSecondary" component="p">
              {time}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className="cardFooterWrapper">
          <div className="cardFooter">{date}</div>
        </CardActions>
      </Card>
    </div>
  );
}

export default Cards;
