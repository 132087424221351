import React, {useEffect} from "react";

export const FormattedDateTimePicker = ({ formattedDateTime, setFormattedDateTime, calculateDuration }) => {


   const date = new Date();

  const options = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZoneName: 'short'
  };

  const formattedDate = date.toLocaleString('en-US', options).replace(',', ' @') + ' ';

  useEffect(()=> {
    setFormattedDateTime(formattedDate);
  },[])
 



  const FormatDateAndTime = (event) => {
    const input = event.target.value;
    const date = new Date(input);

    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZoneName: 'short'
    };

    const formattedDateTime = date.toLocaleString('en-US', options).replace(',', ' @') + ' ';
    setFormattedDateTime(formattedDateTime);
    calculateDuration()

  }

  return (
    <div className="dateContainer">
      <span className="date-formate">{formattedDateTime}</span>
    </div>
  )
}