import React from "react";
import { proSportFront } from "../../resources/images";

const ItemImage = ({ imgSrc, isSmall, width, height , paddding="5px" }) => {
  const hw = `${isSmall ? 18 : 40}px`;
  return (
    <span
      style={{
        display: "flex",
      }}
    >
      {imgSrc?.includes("http") && imgSrc ? (
        <img
          alt=""
          src={(imgSrc?.includes("http") && imgSrc) || proSportFront}
          style={{
            height:height ?? hw ,
            width: width ?? hw ,
            borderRadius: "50%",
            padding: paddding,
          }}
        />
      ) : (
        <span
          style={{
            height:height ?? hw ,
            width: width ?? hw ,
            borderRadius: "50%",
            margin: "5px",
            backgroundColor: "#e2e5e9",
          }}
        />
      )}
    </span>
  );
};

export { ItemImage };
