import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import SoftwareGridFilter from "./SoftwareGridFilter";
import SoftwareGrid from "./SoftwareGrid";
import { Pagination } from "../../../../components/common";
import { GridView, ListView } from "../../LeftNav/SvgIcons";
import Loader from "../../../../components/common/Loader";

const SoftwareReleases = ({
  activeTab,
  previewMode,
  selectedVersions,
  paginationResponse,
  isLoading,
  setOrderBy,
  order,
  setTabValue,
  tabValue,
  search,
  setSearch,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  markUnMarkFavouriteDevices,
  deleteSoftwareRelease,
}) => {

  const [tabName, setTabName] = useState("All Software Releases");
  const totalRecords = paginationResponse.totalRecords ?? 0;

  useEffect(() => {
    if (activeTab === 1) {
      setTabName("All Software Releases");
      setTabValue("all");
    } else if (activeTab === 2) {
      setTabName("Latest Software Release");
      setTabValue("Live");
    } else if (activeTab === 3) {
      setTabName("In Testing");
      setTabValue("InTesting");
    } else if (activeTab === 4) {
      setTabName("Retired Software Releases");
      setTabValue("EndOfLife");
    } else {
      setTabName("All Software Releases");
      setTabValue("all");
    }
  }, [activeTab]);

  return (
    <>
      {isLoading && <Loader />}
      <Grid className="softwareGridFilterContainer">
        <Grid className="gridFilterBox">
          <SoftwareGridFilter
            search={search}
            setSearch={setSearch}
            isFilterTrue={false}
            placeholder={"Search"}
          />
          <Grid item md={1} className={"gridIconWrapper"}>
            <Grid style={{ cursor: "pointer" }}>
              <GridView color={"#3699FF"} />
            </Grid>
            <Grid style={{ cursor: "pointer" }}>
              <ListView color={"#979797"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="tablePaginationtToolbar hideArrows">
        <span className="releaseHeading">{tabName}</span>
        <Pagination
          recordCount={totalRecords}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={[]}
          backIconButtonProps={{ style: { visibility: "hidden" } }}
          nextIconButtonProps={{ style: { visibility: "hidden" } }}
        />
      </Grid>
      <SoftwareGrid
        allReleaseData={selectedVersions}
        order={order}
        onOrderBy={setOrderBy}
        previewMode={previewMode}
        markUnMarkFavouriteDevices={markUnMarkFavouriteDevices}
        deleteSoftwareRelease={deleteSoftwareRelease}
      />
      <Grid className="tablePaginationtToolbar">
        <Pagination
          recordCount={totalRecords}
          page={page - 1}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Grid>
    </>
  );
};

export default SoftwareReleases;
