import React, { useEffect, useState } from "react";
import NavLink from "./NavLink";
import {
  contactInsta,
  contactFacebook,
  contactLinkedIn,
  contactTwitter,
} from "../../../resources/images/index";
import { sortBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { pubCompanyActions } from "../../../store/pubCompany/pubCompanyActions";

export const FooterNavigation = ({ heading }) => {
  const { menuHeader } = useSelector(state => state.newsRoomReducer);
  const menuItems = {
    Home: (
      <li>
        <NavLink text="Home" link={`/`} />
      </li>
    ),
    "Latest Releases": (
      <li>
        {" "}
        <NavLink text="Latest Releases" link={`/releases`} />
      </li>
    ),
    "Media Library": (
      <li>
        {" "}
        <NavLink text="Media Library" link={`/media-library`} />
      </li>
    ),
    Events: (
      <li>
        {" "}
        <NavLink text="Events" link={`/events`} />
      </li>
    ),
    "Company Info": (
      <li>
        {" "}
        <NavLink text="Company Information" link={`/company-info`} />
      </li>
    ),
  };
  return (
    <div className="footerNavWrapper">
      <h3>{heading}</h3>
      <ul>
        {sortBy(menuHeader, ["sort"]).map(x => menuItems[x.name])}
        {/* <li>
          <NavLink text="Home" link={`/events-detail`} />
        </li>
        <li>
          {" "}
          <NavLink text="Latest Releases" link={`/events-detail`} />
        </li>
        <li>
          {" "}
          <NavLink text="Media Library" link={`/events-detail`} />
        </li>
        <li>
          {" "}
          <NavLink text="Events" link={`/events-detail`} />
        </li>
        <li>
          {" "}
          <NavLink text="Company Information" link={`/events-detail`} />
        </li> */}
      </ul>
    </div>
  );
};

export const QuickLick = ({ heading }) => {
  const { quickLinks } = useSelector(state => state.newsRoomReducer);
  return (
    <div className="footerNavWrapper">
      <h3>{heading}</h3>
      <ul>
        {quickLinks.map((x, i) => (
          <li key={i}>
            <a href={x.url} target="_blank">
              {x.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const SocialConnects = ({ heading }) => {
  const dispatch = useDispatch();
  const [contactInfo, setContactInfo] = useState([]);

  useEffect(() => {
    dispatch(pubCompanyActions.onGetPubCompContact()).then(data => {
      if (data && data.success && data.data) {
        setContactInfo(data.data?.socialMedia);
      }
    });
  }, []);
  const returnSocialImg = value => {
    if (value === "LinkedIn") {
      return contactLinkedIn;
    } else if (value === "Instagram") {
      return contactInsta;
    } else if (value === "Facebook") {
      return contactFacebook;
    } else {
      return contactTwitter;
    }
  };
  return (
    <div className="socialConnects">
      <h3>{heading}</h3>
      <ul>
        {contactInfo.length > 0 &&
          contactInfo.map((scl, index) => (
            <li key={index}>
              <img
                className="soicalImgMarginRigh"
                src={returnSocialImg(scl.name)}
                alt=""
              />
              <a href={scl.link} target="_blank">
                {scl.name}
              </a>
            </li>
          ))}

        {/* <li>
          <NavLink
            text="Twitter"
            link={`/events-detail`}
            imagUrl={contactTwitter}
          />
        </li>
        <li>
          <NavLink
            text="Instagram"
            link={`/events-detail`}
            imagUrl={contactInsta}
          />
        </li>
        <li>
          <NavLink
            text="LinkedIn"
            link={`/events-detail`}
            imagUrl={contactLinkedIn}
          />
        </li> */}
      </ul>
    </div>
  );
};
