import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { newsroomActions } from "../../../../store/newsroom/newsroomActions";
import Dashbard from "../Dashbard";
import Overiew from "./NewsRoomPortal/Overiew";
import QuickLinks from "./NewsRoomPortal/QuickLinks";
import GeneralSettings from "./NewsRoomPortal/GeneralSettings";
import Design from "./NewsRoomPortal/Design";
import Loader from "../../../../components/common/Loader";
import SelectInput from "../../../../components/common/SelectInput";

const handleSubmit = e => {
  e.preventDefault();
};

function NewsRoomPortal(props) {
  document.title = "OFish - Newsroom Portals";

  const dispatch = useDispatch();
  const [activeTabId, setActiveTabId] = useState("3");
  const [allPortals, setAllPortals] = useState([]);
  const [selectedPortal, setSelectedPortal] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(newsroomActions.onGetNewRoomDD()).then(data => {
      if (data && data.success && data.data) {
        setAllPortals(data.data);
        if (data.data.length > 0) {
          setSelectedPortal(data.data[0].id);
        }
      }
      setActiveTabId("3");
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Dashbard>
        <div className='body-content'>
          {isLoading ? <Loader /> : null}
          <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
            <div className='contentHeading'>
              <div className='newsPortalHeaderWrapper'>
                <h1>Newsroom Portals</h1>
                <SelectInput
                  placeholder='AAV Main Newsroom'
                  customMenuItems={allPortals?.map(b => {
                    return {
                      id: b.id,
                      name: b.value,
                    };
                  })}
                  value={selectedPortal}
                  handleChange={({ target }) => setSelectedPortal(target.value)}
                />
              </div>
              {/* <div className="dashboardNav">
                <Button
                  color="primary"
                  variant="contained"
                  className="dashboardButtons"
                >
                  Save Settings
                </Button>
              </div> */}
            </div>
            <div className='tab-navigator '>
              <TopTabNavigator
                getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
                tabs={[
                  {
                    id: "1",
                    title: "General Settings",
                    component: [
                      <GeneralSettings
                        startLoading={() => setLoading(true)}
                        stopLoading={() => setLoading(false)}
                      />,
                    ],
                  },
                  {
                    id: "2",
                    title: "Newsroom Design",
                    component: [
                      <Design
                        startLoading={() => setLoading(true)}
                        stopLoading={() => setLoading(false)}
                      />,
                    ],
                  },
                  {
                    id: "3",
                    title: "Company Information",
                    component: [
                      <Overiew
                        startLoading={() => setLoading(true)}
                        stopLoading={() => setLoading(false)}
                      />,
                    ],
                  },
                  {
                    id: "4",
                    title: "Quick Links",
                    component: [
                      <QuickLinks
                        startLoading={() => setLoading(true)}
                        stopLoading={() => setLoading(false)}
                      />,
                    ],
                  },
                ]}
              />
            </div>
          </ValidatorForm>
        </div>
      </Dashbard>
    </div>
  );
}

export default NewsRoomPortal;
