import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import { mediaReleases } from "../../../resources/images";
import { softwareUpdateActions } from "../../../store/softwareUpdates/softwareUpdatesActions";
import { useParams } from "react-router-dom";
import { DynamicIntegrationIcon, OFishNavIcon, PaymentProcessorIcon, ShippingProvidersIcon } from "./SvgIcons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  headingWrapper: {
    backgroundColor: "#E5F2FF !important",
  },
  heading: {
    fontSize: "16px !important",
    fontWeight: "500",
    color: "#2D3339",
    display: "flex",
    alignItems: "center",
  },
  svgIcon: {
    marginRight: "11px",
  },
  expanded: {
    backgroundColor: "transparent",
  },
  links: {
    flexDirection: "column",
    paddingBottom: "0",
  },
  boxes: {
    boxShadow: "none",
  },
  hyperLink: {
    marginLeft: "15px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#3699FF",
    },
  },
  activeTest: {
    color: "#3699FF",
  },
}));

export default function SoftwareUpdatesNav({ startLoading, endLoading }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { panel1, panel2, panel3, panel4 } =
    useSelector(state => state.softwareUpdatesReducer);
  const [expanded1, setExpanded1] = useState(panel1);
  const [expanded2, setExpanded2] = useState(panel2);
  const [expanded3, setExpanded3] = useState(panel3);
  const [expanded4, setExpanded4] = useState(panel4);
  const { id , buildId } = useParams();

  const isSoftwareReleaseActive = (match, location) => {
    return match || location.pathname.includes("/new-software-release");
  };

  const history = useHistory();
  const currentRoot = history ? history.location.pathname : null;

  return (
    <div className="subNavWrapper">
      <h1>Syncing & Integrations</h1>
      <div className={classes.root}>
        <Accordion
          className={classes.boxes}
          expanded={expanded1}
          onChange={() => {
            setExpanded1(expanded1 ? false : true);
            dispatch(
              softwareUpdateActions.onChange("panel1", expanded1 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
            className={
              [
                "/software-releases",
                `/software-releases/${id}`,
                "/software-update-dashboard",
                "/software-update-settings",
                "/software-update-analytics",
                "/software-connected-devices",
                "/software-update-build",
                "/new-software-release",
                `/new-software-release/${id}`,
                `software-new-build/${id}`,
                `/software-builds/${id}`,
                `/software-builds/${id}/${buildId}`,
                `/software-new-build/${id}/${buildId}`,
              ].includes(currentRoot)
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              <OFishNavIcon />
              OFish Connected
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <NavLink to="/software-update-dashboard" className={classes.hyperLink} >
            Dashboard
            </NavLink>

            <NavLink to="/software-connected-devices" className={classes.hyperLink}>
              OTA Devices
            </NavLink>
            <NavLink to="/software-update-build" className={classes.hyperLink}>
              Software Builds
            </NavLink>
            <NavLink to="/software-releases" isActive={isSoftwareReleaseActive} className={classes.hyperLink}>
              Software Releases
            </NavLink>
            <NavLink to="/software-update-analytics" className={classes.hyperLink}>
              Analytics
            </NavLink>
            <NavLink to="/software-update-settings" className={classes.hyperLink}>
              OFish Settings
            </NavLink>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded2}
          onChange={() => {
            setExpanded2(expanded2 ? false : true);
            dispatch(
              softwareUpdateActions.onChange("panel2", expanded2 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
            className={classes.heading}>
            <Typography className={classes.heading}>
              <DynamicIntegrationIcon />
              Dynamics Integration
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link to="/software-releases" className={classes.hyperLink}>
              Dashboard
            </Link>
            <Link to="/software-releases" className={classes.hyperLink}>
              Module Sync Settings
            </Link>
            <Link to="/software-releases" className={classes.hyperLink}>
              Sync History
            </Link>
            <Link to="/software-releases" className={classes.hyperLink}>
              Integration Settings
            </Link>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded3}
          onChange={() => {
            setExpanded3(expanded3 ? false : true);
            dispatch(
              softwareUpdateActions.onChange("panel3", expanded3 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
            className={classes.heading}>
            <Typography className={classes.heading}>
              {/* <img src={mediaReleases} alt="launches" className="releaseIcon" /> */}
              <PaymentProcessorIcon />
              Payment Processors
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link to="/software-releases" className={classes.hyperLink}>
              PayPal
            </Link>

            <Link to="/software-releases" className={classes.hyperLink}>
              Stripe
            </Link>

          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded4}
          onChange={() => {
            setExpanded4(expanded4 ? false : true);
            dispatch(
              softwareUpdateActions.onChange("panel4", expanded4 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
            className={classes.heading}>
            <Typography className={classes.heading}>
              <ShippingProvidersIcon />
              Shipping Providers
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link to="/software-releases" className={classes.hyperLink}>
            FedEx
            </Link>
          </AccordionDetails>
        </Accordion>

      </div>
    </div>
  );
}
