import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "./Loader";
import SelectImagesTabs from "./SelectImagesTabs";
import { launchesActions } from "../../store/launches/launchesActions";
import { assetsActions } from "../../store/assetsManagement/assetsActions";
import { Dialog, DialogContent } from "@material-ui/core";
import { first } from "lodash";
import { endUserActions } from "../../store/endUser/endUserActions";

const useStyles = makeStyles(theme => ({
  featuredImageTabs: {
    backgroundColor: "#ffffff",
    zIndex: "5",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "632px",
    overflowY: "hidden",
    padding: 0,
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userNote: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#eb5757",
    position: "absolute",
    bottom: "70px",
    zIndex: 999,
    left: "20px",
  },
  fieldsOption: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "4px",
  },
}));

const ImageUploader = ({
  title,
  showFeatureImage,
  setShowFeatureImage,
  onImageSelect,
  includeMediaListing = true,
  infoText = "",
  showInfoTxt = true,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [featureFiles, setFeatureFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [previousFolder, setPreviousFolder] = useState(null);
  const [file, setFile] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const uploadFeatureImg = value => {
    const newFile = value[0];
    setLoading(true);
    dispatch(endUserActions.onUploadRecipt(file)).then(data => {
      if (data?.success && data?.data) {
        setShowFeatureImage(false);
      }
      onImageSelect(data?.data);
      setLoading(false);
    });
  };

  const getFolderData = useCallback(
    ({ folderId, isBack }) => {
      setLoading(true);
      dispatch(assetsActions.onGetMediaAssetById(folderId)).then(data => {
        if (data && data.success && data.data) {
          if (isBack)
            setPreviousFolder(first(data.data?.data)?.superParentId || 0);
          setFeatureFiles(data.data?.data);
        }
        setLoading(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!showFeatureImage || !includeMediaListing) return;
    getFolderData({ folderId: selectedFolder?.id || 0, isBack: false });
  }, [
    dispatch,
    showFeatureImage,
    includeMediaListing,
    getFolderData,
    selectedFolder,
  ]);
  console.log({ includeMediaListing });
  return (
    <>
      {isLoading ? <Loader /> : null}
      {showFeatureImage && (
        <Dialog open={showFeatureImage} onClose={setShowFeatureImage}>
          <DialogContent className={classes.featuredImageTabs}>
            <h2 className={classes.featuredImageTabsHeading}>
              {title || `Select Featured Image`}
              <Close
                style={{
                  cursor: "pointer",
                }}
                className={classes.closeTabs}
                onClick={() => setShowFeatureImage(false)}
              />
            </h2>
            <SelectImagesTabs
              includeMediaListing={includeMediaListing}
              addFeatureImg={value => uploadFeatureImg(value)}
              featureData={featureFiles}
              setFile={setFile}
              selectedFeatureImg={value => {
                onImageSelect(value);
                setShowFeatureImage(false);
              }}
              selectedFolder={selectedFolder}
              setSelectedFolder={f => {
                setPreviousFolder(selectedFolder?.id);
                setSelectedFolder(f);
              }}
              onBackButtonPress={folderInfo => {
                if (previousFolder)
                  getFolderData({
                    folderId: previousFolder,
                    isBack: true,
                  });
                else {
                  setSelectedFolder(null);
                }
              }}
              setTabValue={value => setTabValue(value)}
            />
            {showInfoTxt && infoText && tabValue ? (
              <span className={classes.userNote}>
                {infoText} <br></br>
                Please ensure the following fields are properly filled on the
                receipt:
                <div className={classes.fieldsOption}>
                  <span>1. Price</span>
                  <span>2. Model Number</span>
                  <span>3. Seller Name</span>
                  <span>4. Date of Purchase</span>
                </div>
              </span>
            ) : null}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export { ImageUploader };
