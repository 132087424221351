import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    // border: "1px solid #0000003b",
    borderRadius: "6px",
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },

  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  customOption:{
   color:"#3699FF",
   fontSize:"12px",
   fontWeight:"700"
  },
}));

export default function SimpleSelect({
  selectlabel,
  selectSubtitle,
  placeholder,
  variant,
  value,
  menuItems,
  customMenuItems,
  menuitem,
  required,
  handleChange,
  showRequired,
  disabled,
  disablePlaceholderOption = false,
  removeSelectOption = false,
}) {
  const classes = useStyles();
  return (
    <div className='inputWrapper'>
      <FormControl className={(classes.formControl, "newCompanySelecttype")}>
        <label>
          {selectlabel} <i className={showRequired ? "showRequired" : ""}></i>
        </label>
        <Select
          value={value}
          onChange={handleChange}
          // displayEmpty
          className={classes.selectEmpty}
          inputProps={{ "aria-label": "Without label" }}
          fullWidth
          variant={variant}
          required={required}
          disabled={disabled}
        >
          {!removeSelectOption ? 
            <MenuItem disabled={disablePlaceholderOption} value={0}>
              <span className='customPlaceholder'>{placeholder}</span>
          </MenuItem>
            : null}

          {Boolean(customMenuItems) ? (
            customMenuItems.map(mi => (
              <MenuItem key={mi.id} value={mi.id}>
                {mi.name}
              </MenuItem>
            ))
          ) : Boolean(menuItems) ? (
            menuItems.map((mi, i) => (
              <MenuItem key={mi} value={i}>
                {mi}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={menuitem} value={1}>
              {menuitem}
            </MenuItem>
          )}
        </Select>
        <FormHelperText className='inputSubtitle'>
          {selectSubtitle}
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export const  StatusActionSelectInput = ({
  selectlabel,
  selectSubtitle,
  placeholder,
  variant,
  value,
  menuItems,
  customMenuItems,
  menuitem,
  required,
  handleChange,
  showRequired,
  disabled,
})=>{
  const classes = useStyles();

  return (
    <div className='inputWrapper'>
      <FormControl className={(classes.formControl, "newCompanySelecttype")}>
        <label>
          {selectlabel} <i className={showRequired ? "showRequired" : ""}></i>
        </label>
        <Select
          value={value}
          onChange={handleChange}
          // displayEmpty
          className={classes.selectEmpty}
          inputProps={{ "aria-label": "Without label" }}
          fullWidth
          variant={variant}
          required={required}
          disabled={disabled} 
        >
          {customMenuItems.map(mi => (
            mi.id !== "customId" ?
            <MenuItem key={mi.id} value={mi.id}>
              {mi.name}
            </MenuItem> :
            <MenuItem key={mi.id} value={mi.id}>
            <span 
            className={classes.customOption}
            >
             {mi.name}
            </span>
          </MenuItem>
          ))}
        </Select>
        <FormHelperText className='inputSubtitle'>
          {selectSubtitle}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
