import React, { useState, useEffect } from "react";

import {
  EditIcon,
  FilterIcon,
  GridListIcon,
  TableGrid,
  Delete,
} from "../../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import { searchIcon } from "../../../../../resources/images";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";

import { RedCross, TickMark } from "../../../LeftNav/SvgIcons";
import { Button } from "@material-ui/core";
import CompanyUserForm from "./CompanyUserForm";
import Loader from "../../../../../components/common/Loader";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function CompanyUserGrid({
  setIsNewUser,
  isNewUser,
  setIsEditAdr,
  isEditAdr,
  listData,
  search,
  setSearch,
  isLoading,
  setLoading,
  loadUser,
  deleteUser,
}) {
  const [editItem, setEditItem] = useState(false);
  const classes = useStyles();
  return (
    <>
      {isLoading ? <Loader /> : null}
      <Grid container spacing={2}>
        <Grid item md={11} className='filterMainSearch endUserSearchFilterMain'>
          <img src={searchIcon} alt='' className='searchIcon' />
          <TextField
            placeholder='Search'
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <span className='endUserSearchFilter'>
            <FilterIcon />
          </span>
        </Grid>

        <Grid item md={1} className='blockEndUserBtn gridFiltersButtons'>
          <TableGrid />
          <GridListIcon />
        </Grid>
      </Grid>
      <div className='gap' />
      <div className='addressGrid'>
        <span className='gridHeaader'>
          <ul>
            <li>CompanyUserGrid</li>
            <li>Access</li>
            <li>Email Address</li>
            <li />
          </ul>
        </span>
        <span className='gridBody enduserGrid'>
          {listData?.map((item, index) => {
            return isEditAdr && editItem?.id === item.id ? (
              <CompanyUserForm
                setIsNewUser={() => {
                  setIsEditAdr(false);
                  setEditItem(null);
                }}
                editData={item}
                loadUser={() => loadUser()}
                setLoading={e => setLoading(e)}
              />
            ) : (
              <ul>
                <li className='highlishtedItem connectionGrid'>
                  <i>{item?.contactName}</i> {item?.role}*
                </li>
                <li className='highlishtedItem connectionGrid'>
                  {item?.accessType}
                </li>
                <li className='connectionGrid'>{item?.emailAddress}</li>
                <li
                  className='gridActions connectionGrid'
                  style={{
                    textAlign: "right",
                    cursor: "pointer",
                    justifyContent: "flex-end",
                    alignItems:"center",
                  }}>
                  <span
                    onClick={() => {
                      setIsEditAdr(true);
                      setEditItem(item);
                      setIsNewUser();
                    }}>
                    <EditIcon />
                  </span>
                  <span
                    style={{
                      cursor: "pointer",
                      
                    }}
                    onClick={() => {
                      deleteUser(item?.id);
                    }}
                    >
                    <Delete />
                  </span>
                </li>
              </ul>
            );
          })}
          {isNewUser && (
            <CompanyUserForm
              setIsNewUser={() => setIsNewUser(false)}
              loadUser={() => loadUser()}
              setLoading={e => setLoading(e)}
            />
          )}
        </span>
      </div>
    </>
  );
}

export default CompanyUserGrid;
