import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { saveAs } from "file-saver";
import fileDownload from "js-file-download";
import axios from "axios";
import moment from "moment";
import { pubMediaLibraryActions } from "../../../store/pubMediaLibrary/pubMediaLibraryActions";
import { accountActions } from "../../../store/accounts/accountActions";
import {
  LogHeader,
  MainHeading,
} from "../../../components/common/Header/index";
import Loader from "../../../components/common/Loader";
import Carousel from "../../../components/common/MediaCarousel";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
    background: "#fff !important",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridBodyHeading: {
    display: "block",
    fontWeight: 700,
    color: "#181C32",
    fontSize: 14,
  },
  gridBodySubHeading: {
    color: "#49B8EF",
    display: "block",
    fontWeight: 400,
    fontSize: 14,
  },
  gridFileType: {
    color: "#BDBDBD",
    display: "block",
    fontWeight: 400,
    fontSize: 14,
  },
  gridBodyDate: {
    color: "#49B8EF",
    display: "block",
    fontWeight: 700,
    fontSize: 14,
  },
  gridDateMain: {
    display: "flex",
    marginTop: "8px",
  },
}));

function LogMediaDetail() {
  document.title = "OFish - Media Library";

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [pmlAssetsData, setPMLAssetsData] = useState({});
  const [mdAssets, setMdAssets] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [userId, setUserId] = useState(null);
  const { slug } = useParams();

  var collectionId = parseInt(window.location.href.split("?id=")[1]);
  useEffect(() => {
    if (collectionId || slug) {
      dispatch(accountActions.onAddUserSession(window.location.href));
      setLoading(true);
      dispatch(
        pubMediaLibraryActions.onGetPubCollectionDetail(
          collectionId ? collectionId : 0,
          slug ? slug : ""
        )
      ).then(data => {
        if (data?.data !== null && data.success) {
          setPMLAssetsData(data.data);
          if (data.data && data.data.assets) {
            setMdAssets(data.data.assets);
          }
        } else {
          //if (data) {
          toast.error("No Records Found");
          history.push("/");
          //}
        }
        setLoading(false);
      });
      setUserId(localStorage.getItem("id"));
      setUserRole(localStorage.getItem("isAdmin"));
    }
  }, []);

  const downloadAllAsset = () => {
    var zipFilename = pmlAssetsData.title;
    if (mdAssets && mdAssets.length > 0) {
      let assets = [];
      let assetName = [];
      mdAssets.forEach((url, index) => {
        let fileName = `${url.name}${"."}${url.type}`;
        let finalAssets = assetName.filter(x => x === fileName).length;
        assetName.push(fileName);
        if (finalAssets > 0) {
          fileName = `${url.name}${"("}${finalAssets}${")"}${"."}${url.type}`;
        }
        assets.push({
          name: fileName,
          path: url.imageUrl,
        });
      });
      if (assets.length > 0) {
        setLoading(true);
        dispatch(pubMediaLibraryActions.onDownLoadAsset(assets)).then(
          response => {
            if (response) {
              const blob = new Blob([response.data], {
                type: "application/zip",
              });
              const file = new File([blob], zipFilename, {
                type: "application/zip",
              });
              saveAs(file);
            }
            setLoading(false);
          }
        );
      }
    }
  };

  const downloadSingleFile = (url, filename) => {
    axios
      .get(
        url,
        {
          responseType: "blob",
        },
        {
          headers: {
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(res => {
        fileDownload(res.data, filename);
      });
  };
  return (
    <div className='latestReleaseDetailWrapper'>
      {isLoading ? <Loader /> : null}
      <LogHeader />
      <MainHeading
        headerSubHeading={pmlAssetsData ? pmlAssetsData.categoryName : ""}
        icon={<KeyboardArrowLeft fontSize='medium' />}
        backLink='Back to Media Library'
        backNavHandler={() => history.push("/media-library")}>
        <div className='publicBody mediaCarousel'>
          <Carousel assetData={pmlAssetsData} />
        </div>
        {["customer", "company_user", "company_admin"].includes(userRole) &&
        userId &&
        mdAssets &&
        mdAssets.length > 0 ? (
          <div className='logMediaDetailWrapper'>
            <div className='logMediaDetailInner'>
              <div className='assetsHeader'>
                <span>
                  <div className='mainHeading'>Assets</div>
                  <div className='subHeading'>
                    Find Product Overview and Media Assets
                  </div>
                </span>

                <span>
                  <Button
                    color='primary'
                    onClick={() => downloadAllAsset()}
                    variant='contained'>
                    Download All
                  </Button>
                </span>
              </div>

              <div className='assetsGridBody'>
                <Table>
                  <TableHead className={classes.gridHeader}>
                    <TableRow className={classes.gridHeaderRow}>
                      <TableCell
                        className={classes.gridHeaderInner}></TableCell>
                      <TableCell className={classes.gridHeaderInner}>
                        ASSET NAME
                      </TableCell>
                      <TableCell className={classes.gridHeaderInner}>
                        FILE TYPE
                      </TableCell>
                      <TableCell className={classes.gridHeaderInner}>
                        UPLOADED
                      </TableCell>
                      <TableCell
                        className={classes.gridHeaderInner}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mdAssets &&
                      mdAssets.length > 0 &&
                      mdAssets.map((item, index) => {
                        return (
                          <TableRow key={index} className='gridBodyRow'>
                            <TableCell
                              className={classes.gridHeaderInner}></TableCell>
                            <TableCell className={classes.gridBodyInner}>
                              <span className={classes.gridBodyHeading}>
                                {item.name}
                              </span>
                              <span className={classes.gridBodySubHeading}>
                                {`${item.name}${"."}${item.type}`}
                              </span>
                            </TableCell>
                            <TableCell className={classes.gridBodyInner}>
                              <span className={classes.gridBodyHeading}>
                                {`${"."}${item.type}`}
                              </span>
                            </TableCell>
                            <TableCell
                              className={[
                                classes.gridBodyInner,
                                classes.gridDateMain,
                              ]}>
                              <span className={classes.gridBodyDate}>
                                {item.uploadedAt
                                  ? moment(item.uploadedAt).format("MM/DD/YYYY")
                                  : ""}
                              </span>
                            </TableCell>
                            <TableCell>
                              <button
                                style={{
                                  background: "transparent",
                                  border: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  downloadSingleFile(
                                    item.imageUrl,
                                    `${item.name}${"."}${item.type}`
                                  )
                                }>
                                <span className={classes.gridBodyDate}>
                                  Download
                                </span>
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        ) : null}
      </MainHeading>
    </div>
  );
}

export default LogMediaDetail;
