import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import { FrolaEditor } from "../../../../components/common";
import EmptyTextarea from "../../../../components/common/Textarea";
import { useDispatch } from "react-redux";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import { toast } from "react-toastify";
import { Autocomplete } from "@material-ui/lab";

const ProductCustomerGroupDescription = ({
  productPricingGroups,
  isLongDescription = false,
  isStorePricingDesc = true,
  activeProductId,
  type,
  activeGroupId,
  isProductDescSave,
  isPriceDescSave,
  onSave,
}) => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem("id");
  const textSubtitle = "(5000 characters max.)";

  const [descriptionData, setData] = useState({
    id: 0,
    productId: activeProductId,
    pricingId: activeGroupId,
  });
  const [releaseContent, setReleaseContent] = useState("");
  const [selectedShortOption, setSelectedShortOption] = useState(null);
  const [selectedLongOption, setSelectedLongOption] = useState(null);
  const [selectedPriceListOption, setSelectedPriceListOption] = useState(null);

  useEffect(() => {
    setData({
      ...descriptionData,
      pricingId: activeGroupId,
    });
  }, [activeGroupId]);

  useEffect(() => {
    setReleaseContent(descriptionData?.longDescription);
  }, [descriptionData?.longDescription]);

  const getDescriptionFromAPI = (groupId, state = "double") => {
    if (isLongDescription && activeGroupId && activeProductId) {
      dispatch(
        productInventoryActions.onGetProductDescriptionByProduct({
          productId: activeProductId,
          pricingId: groupId,
          type: type,
        })
      ).then((data) => {
        if (data?.success) {
          if (data.data?.length > 0) {
            if (state === "double") {
              setData(data?.data[data.data?.length - 1]);
            } else if (state === "short") {
              const descData = data?.data[data.data?.length - 1];
              setData({...descriptionData, shortDescription: descData.shortDescription});
            } else {
              const descData = data?.data[data.data?.length - 1];
              setData({...descriptionData, longDescription: descData.longDescription});
            }
          }
        } else {
          if (data?.message?.includes("No Data Found")) {
          } else toast.error("Something went wrong, Please try later.");
        }
      });
    } else if (!isLongDescription && activeGroupId && activeProductId) {
      dispatch(
        productInventoryActions.onGetProductPriceDescriptionByProduct({
          productId: activeProductId,
          pricingId: groupId,
          type: type,
        })
      ).then((data) => {
        if (data?.success) {
          if (data.data?.length > 0) {
            setData(data?.data[data.data?.length - 1]);
          }
        } else {
          if (data?.message?.includes("No Data Found")) {
          } else toast.error("Something went wrong, Please try later.");
        }
      });
    }
  };

  useEffect(() => {
    getDescriptionFromAPI(activeGroupId);
  }, [activeProductId, activeGroupId, dispatch, isLongDescription]);

  useEffect(() => {
    if (isProductDescSave || isPriceDescSave) {
      onSave({
        ...descriptionData,
        longDescription: releaseContent,
        userId: userId,
      });
    }
  }, [onSave, isProductDescSave, isPriceDescSave]);

  const dropdownArray = productPricingGroups
    .map((group) =>
      group.availabilityPeriods.map((period) => ({
        ...period,
        groupName: group.value,
      }))
    )
    .flat()
    .filter((group) => group.pricingId !== activeGroupId);

  const availablePriceListDescriptions = dropdownArray.filter(period => period.productDescription.length > 0 && period.longDescription.length > 0);
  const availableShortDescriptions = dropdownArray.filter(period => period.productDescription[0]?.shortDescription.length > 0);
  const availableLongDescriptions = dropdownArray.filter(period => period.productDescription[0]?.longDescription.length > 0);


  return (
    <Grid container>
      <Grid item xs={12} className={"shortDescriptionStore"}>
        {isStorePricingDesc ? (
          <>
            <Grid item xs={12} className="mainLable">
              <div className="copyFormContainer">
                <label>Short Description (On Previews)</label>
                <span className="copyFormBox">
                  <Button color="primary" className="copyBtn">
                    Copy From
                  </Button>
                  {availableShortDescriptions.length === 0 ?
                    <p className="customText">None/Custom Text</p>
                    :

                    <Autocomplete
                      popupIcon={""}
                      value={selectedShortOption}
                      onChange={(event, value) => {
                        setSelectedShortOption(value);
                        value && getDescriptionFromAPI(value?.pricingId, "short");
                      }}
                      options={availableShortDescriptions}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="combo-box-demo"
                      getOptionLabel={(option) =>
                        `${option.groupName} - ${option.periodName}`
                      }
                      renderOption={(option) =>
                      (
                        <div>
                          <>
                            <span>
                              {option.groupName} - {option.periodName}
                            </span>
                          </>
                        </div>
                      )
                      }
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter a Price Name"
                          variant="outlined"
                        />
                      )}
                    />
                  }
                </span>
              </div>

              <span className="inputSubtitle">{textSubtitle}</span>
            </Grid>
            <EmptyTextarea
              className="textAria"
              maxLength={5000}
              value={descriptionData?.shortDescription}
              onChange={({ target }) => {
                setData({
                  ...descriptionData,
                  shortDescription: target.value,
                });
              }}
            />
            {isLongDescription && (
              <Grid item xs={12}>
                <Grid item xs={12} className="mainLable">
                  <div className="copyFormContainer">
                    <label>Long Description (On Product Page)</label>
                    <span className="copyFormBox">
                      <Button color="primary" className="copyBtn">
                        Copy From
                      </Button>
                      {availableLongDescriptions.length === 0 ?
                        <p className="customText">None/Custom Text</p>
                        :
                        <Autocomplete
                          popupIcon={""}
                          value={selectedLongOption}
                          onChange={(event, value) => {
                            setSelectedLongOption(value);
                            value &&
                              getDescriptionFromAPI(value?.pricingId, "long");
                          }}
                          options={availableLongDescriptions}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="combo-box-demo"
                          getOptionLabel={(option) =>
                            `${option.groupName} - ${option.periodName}`
                          }
                          renderOption={(option) =>
                            `${option.groupName} - ${option.periodName}`
                          }
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter a Price Name"
                              variant="outlined"
                            />
                          )}
                        />
                      }
                    </span>
                  </div>

                  <span className="inputSubtitle">
                    {" "}
                    (100,000 characters max.)
                  </span>
                </Grid>
                <Grid className="editor">
                  <FrolaEditor
                    charCounterMax={100000}
                    model={releaseContent}
                    onHandleModelChange={(model) => setReleaseContent(model)}
                  />
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Grid item xs={12} className="mainLable">
                <div className="copyFormContainer">
                  <label>
                    Price List Product Description (Shown On Price Lists)
                  </label>
                  <span className="copyFormBox">
                    <Button color="primary" className="copyBtn">
                      Copy From
                    </Button>
                    {availablePriceListDescriptions.length === 0
                      ? <p className="customText">None/Custom Text</p> :
                      <Autocomplete
                        value={selectedPriceListOption}
                        onChange={(event, value) => {
                          setSelectedPriceListOption(value);
                          value && getDescriptionFromAPI(value?.pricingId);
                        }}
                        options={availablePriceListDescriptions}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="combo-box-demo"
                        getOptionLabel={(option) =>
                          `${option.groupName} - ${option.periodName}`
                        }
                        renderOption={(option) =>
                        (
                          <div>
                            <>
                              <span>
                                {option.groupName} - {option.periodName}
                              </span>
                            </>
                          </div>
                        )
                        }
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter a Price Name"
                            variant="outlined"
                          />
                        )}
                      />}
                  </span>
                </div>

                <span className="inputSubtitle">
                  {" "}
                  (50,000 characters max.)
                </span>
              </Grid>
              <Grid className="editor">
                <FrolaEditor
                  charCounterMax={50000}
                  model={descriptionData?.shortDescription}
                  onHandleModelChange={(model) =>
                    setData({ ...descriptionData, shortDescription: model })
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export { ProductCustomerGroupDescription };
