import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ValidatorForm } from "react-material-ui-form-validator";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { InputField } from "../../../../components/common";

const handleSubmit = e => {
  e.preventDefault();
};

const colors = [
  { color: "#015dfe" },
  { color: "#00d0ff" },
  { color: "#f9b900" },
  { color: "#ff0092" },
  { color: "#49b8ef" },
  { color: "#eb5757" },
  { color: "#66de64" },
  { color: "#0a42e9" },
  { color: "#ef64b3" },
  { color: "#e8edef" }
];

const CategoryFiltersSection = ({
  SelectedPC,
  setSelectedPC,
  SubCategories,
  SelectedSCs,
  setSelectedSC,
  BusinessGroups,
  BusinessGroupIds,
  setBGIds,
  Environments,
  EnvironmentIds,
  setEnvIds,
  noOfContacts,
  setNoOfContacts,
  companyName,
  setCompanyName,
  hideFilters,
}) => {
  const dispatch = useDispatch();

  const [parentCategories, setParentCategories] = useState([]);
  const [colorOption, setColorOption] = useState(false);
  const [inputAllowed, setInputAllowed] = useState(false);
  const loadProductCategories = useCallback(
    ({ parentCatId = 0 }) => {
      dispatch(
        productCategoriesActions.onGetAllProductSubCategories(parentCatId)
      ).then(data => {
        if (data?.success) {
          if (parentCatId === 0) {
            setParentCategories(data?.data);
          }
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    loadProductCategories({ parentCatId: 0 });
  }, [loadProductCategories]);

  function handleKeyPress(event) {
    if (!inputAllowed) {
      event.preventDefault();
    }
  }

  return (
    <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
      {
        hideFilters !== "businessGroup"  ?
          (<div className='userDetailsSearchField inputWrapper filterSelect'>
            <FormControl className='ac-dd'>
              <Autocomplete
                value={SelectedPC}
                onChange={(event, value) => {
                  setSelectedPC(value);
                }}
                multiple
                filterSelectedOptions
                id='product-category-dd'
                options={parentCategories}
                getOptionLabel={option => option?.value || ""}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                    placeholder={"Product Category"} />
                )}
              />
              {SelectedPC?.length > 0 && (
                <span className='countLabel'>{SelectedPC?.length}</span>
              )}
            </FormControl>


            <FormControl className='ac-dd'>
              <Autocomplete
                value={SelectedSCs}
                onChange={(event, value) => setSelectedSC(value)}
                multiple
                id='prod-sc-ac'
                options={SubCategories}
                getOptionLabel={option => option?.value || ""}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                    placeholder={"Product Sub Category"} />
                )}
              />
              {SelectedSCs?.length > 0 && (
                <span className='countLabel'>{SelectedSCs?.length}</span>
              )}
            </FormControl>

            <FormControl className='ac-dd'>
              <Autocomplete
                value={BusinessGroups.filter(eg =>
                  BusinessGroupIds.includes(eg.id)
                )}
                onChange={(event, value) => setBGIds(value?.map(g => g.id))}
                multiple
                filterSelectedOptions
                id='business-group'
                options={BusinessGroups}
                getOptionLabel={option => option?.value || ""}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                    placeholder={"Business Group"} />
                )}
              />
              {BusinessGroupIds?.length > 0 && (
                <span className='countLabel'>{BusinessGroupIds?.length}</span>
              )}
            </FormControl>

            <FormControl className='ac-dd'>
              <Autocomplete
                value={Environments.filter(eg => EnvironmentIds.includes(eg.id))}
                onChange={(event, value) => setEnvIds(value?.map(g => g.id))}
                multiple
                filterSelectedOptions
                id='environment'
                options={Environments}
                getOptionLabel={option => option?.value || ""}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                    placeholder={"Environment"} />
                )}
              />
              {EnvironmentIds?.length > 0 && (
                <span className='countLabel'>{EnvironmentIds?.length}</span>
              )}
            </FormControl>
          </div>) :
            (<div className='userDetailsSearchField inputWrapper filterSelect'>
              <FormControl className='ac-dd'>
                <Autocomplete
                  value={BusinessGroups.filter(eg =>
                    BusinessGroupIds.includes(eg.id)
                  )}
                  onChange={(event, value) => setBGIds(value?.map(g => g.id))}
                  multiple
                  filterSelectedOptions
                  id='business-group'
                  options={BusinessGroups}
                  getOptionLabel={option => option?.value || ""}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                      placeholder={"Business Group"} />
                  )}
                />
                {BusinessGroupIds?.length > 0 && (
                  <span className='countLabel'>{BusinessGroupIds?.length}</span>
                )}
              </FormControl>
              <FormControl className='ac-dd'>
                <InputField
                  placeholder='No. of Contacts'
                  id='Contacts'
                  name='Contacts'
                  onChange={({ target }) => {
                    if (!isNaN(target.value)) {
                      setNoOfContacts(target.value);
                    }
                  }}
                  autoComplete={"off"}
                  value={noOfContacts}
                />
              </FormControl>
              <FormControl className='ac-dd'>
                <InputField
                  placeholder='Company Name'
                  id='CompanyName'
                  name='CompanyName'
                  onChange={({ target }) => {
                      setCompanyName(target.value);
                  }}
                  autoComplete={"off"}
                  value={companyName}
                />
              </FormControl>
            </div>) 
      }
    </ValidatorForm>
  );
};

export { CategoryFiltersSection };
