import apiCall from "axios";
import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getLaunchesList({
  launchPlan,
  type,
  pageSize,
  pageNo,
  orderByKey,
  orderByVal,
}) {
  return axios
    .post(endpoints.launches.getLaunchesList, {
      launch_plan: launchPlan,
      type:type,
      paging: {
        pageSize: pageSize,
        pageNo: pageNo,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getLaunches() {
  return axios
    .get(endpoints.launches.getLaunches)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getDropDownLaunches() {
  return axios
    .post(endpoints.launches.getDropDownLanches)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getLaunchById(id) {
  return axios
    .post(`${endpoints.launches.getLaunchById}?Id=${id}`)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addupdateLaunch(launch) {
  return axios
    .post(endpoints.launches.addupdateLaunch, launch)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getLaunchCategoriesDD() {
  return axios
    .post(endpoints.launches.getLaunchCategories)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getRegistrationPendingApprovalProducts(
  pageSize,
  pageNo,
  orderByKey,
  orderByVal
) {
  return axios
    .post(endpoints.devices.getRegistrationPendingApprovalProducts, {
      pagination: {
        pageSize: pageSize,
        pageNo: pageNo,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getNewestApprovedRegistrationProducts(
  pageSize,
  pageNo,
  orderByKey,
  orderByVal
) {
  return axios
    .post(endpoints.devices.getNewestApprovedRegistrationProducts, {
      pagination: {
        pageSize: pageSize,
        pageNo: pageNo,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addLaunches(planName) {
  const userEmail = localStorage.getItem("userEmail");
  return axios
    .post(endpoints.launches.getLaunches, {
      plan: planName,
      startedBy: userEmail,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateLaunches(planName, planDate, launchId) {
  const userEmail = localStorage.getItem("userEmail");
  return axios
    .put(`${endpoints.launches.updateLaunches}${launchId}`, {
      id: launchId,
      plan: planName,
      launchDate: planDate,
      startedBy: userEmail,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getLaunchActivities({ launchId }) {
  return axios
    .post(endpoints.launches.getLaunchActivities, {
      id: launchId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getLaunchNotes({ launchId }) {
  return axios
    .post(endpoints.launches.getLaunchNotes, {
      launchId: launchId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addUpdateNotes(note) {
  return axios
    .post(endpoints.launches.addUpdateNotes, note)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteLaunchtNote(noteId) {
  return axios
    .post(endpoints.launches.deleteLaunchtNote, [noteId])
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getReleaseLocation() {
  return axios
    .get(endpoints.releaseLocations.getReleaseLocation)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getReleaseRelatedEvents() {
  return axios
    .get(endpoints.releases.getReleaseRelatedEvents)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getReleaseNewsRoomVisibilities() {
  return axios
    .get(endpoints.newsRoomAdmin.getNewsRoomDropdown)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

// async function getReleaseCategory() {
//   return axios
//     .get(endpoints.releaseCategory.getReleaseCategory)
//     .then(response => {
//       let data = response.data;
//       return data;
//     })
//     .catch(error => {
//       return null;
//     });
// }

async function getReleaseCategories() {
  return axios
    .post(endpoints.releaseCategory.getReleaseCategories)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return null;
    });
}

async function addReleaseCategory(planName) {
  return axios
    .post(endpoints.releaseCategory.addReleaseCategory, {
      name: planName,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateReleaseCategory(planName, releaseId) {
  return axios
    .put(`${endpoints.releaseCategory.updateReleaseCategory}${releaseId}`, {
      id: releaseId,
      name: planName,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAllReleases(modal) {
  return axios
    .post(endpoints.releases.getAllReleases, modal)
    .then(response => {
      let data = response?.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getReleases() {
  return axios
    .get(endpoints.releases.getReleases)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getReleaseById(id) {
  return axios
    .post(`${endpoints.releases.getReleasById}?Id=${id}`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteLaunches(ids) {
  return axios
    .post(endpoints.launches.deleteLaunches, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteReleases(ids) {
  return axios
    .post(endpoints.releases.deleteReleases, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteRelCategory(ids) {
  return axios
    .post(endpoints.releaseCategory.deleteReleaseCategory, JSON.stringify(ids))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function uploadImage(image) {
  const formData = new FormData();
  formData.append("file", image[0]);
  const fileName = image[0].name.split(".")[0];

  const token = localStorage.getItem("access_token");
  return apiCall({
    url: `${endpoints.artifacts.uploadFile}${"?name="}${fileName}`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => response?.data)
    .catch(error => {
      console.log("uploadImage error : ", error);
      return null;
    });
}

async function createRelease(value) {
  return axios
    .post(endpoints.releases.createReleases, {
      ...value,
      // title: value.title,
      // subTitle: value.subTitle,
      // shortDescription: value.shortDescription,
      // AnnouncementDate: value.AnnouncementDate,
      // PreReleaseDate: value.PreReleaseDate,
      // content: value.content,
      // locationId: value.locationId,
      // eventId: value.eventId,
      // assetId: value.assetId,
      // categoryId: value.categoryId,
      // launchId: value.launchId,
      // state: value.state,
      // collections: value.collections,
      // releaseVisibilities: value.releaseVisibilities,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getFeaturFiles() {
  return axios
    .post(`${endpoints.releases.newGetFeatureFile}${"?query=files"}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateRelease(value, releaseId) {
  return axios
    .put(`${endpoints.releases.updateReleases}${releaseId}`, {
      id: releaseId,
      // title: value.title,
      // subTitle: value.subTitle,
      // shortDescription: value.shortDescription,
      // AnnouncementDate: value.AnnouncementDate,
      // PreReleaseDate: value.PreReleaseDate,
      // content: value.content,
      // locationId: value.locationId,
      // eventId: value.eventId,
      // assetId: value.assetId,
      // categoryId: value.categoryId,
      // launchId: value.launchId,
      // state: value.state,
      // collections: value.collections,
      // releaseVisibilities: value.releaseVisibilities,
      ...value,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getReleaseVisibility() {
  return axios
    .get(`${endpoints.releases.getReleaseVisi}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAllEvents(modal) {
  return axios
    .post(endpoints.events.getEvents, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getEventsCategory() {
  return axios
    .get(`${endpoints.events.getEventsCategory}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateEvent(value) {
  return axios
    .post(endpoints.events.addUpdateEvent, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteEvents(value) {
  return axios
    .post(endpoints.events.deleteEvents, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getEventById(id) {
  return axios
    .get(`${endpoints.events.getEventsById}${"?EventId=" + id}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getLocationsAutoComplete(name) {
  return axios
    .post(endpoints.generic.getLocationsAutoComplete, JSON.stringify(name))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getEventCategories(modal) {
  return axios
    .post(endpoints.events.getEventCategories, modal)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function addUpdateEventCategory(value) {
  return axios
    .post(endpoints.events.addUpdateEventCategory, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getEventCatById(id) {
  return axios
    .get(`${endpoints.events.getEventsCatById}${"?EventCategoryId=" + id}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function deleteEventsCatById(value) {
  return axios
    .post(endpoints.events.deleteEventsCatById, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getEventType() {
  return axios
    .get(`${endpoints.events.getEventType}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateReleaseCategory(value) {
  return axios
    .post(
      endpoints.releaseCategory.addUpdateReleaseCategory,
      JSON.stringify(value)
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getReleaseCategory(value) {
  return axios
    .post(endpoints.releaseCategory.getReleaseCategory, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getReleaseCatById(id) {
  return axios
    .post(`${endpoints.releaseCategory.getReleaseCategoryById}${"?Id=" + id}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getEventDropDown() {
  return axios
    .post(endpoints.events.getEventDropDown)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateLaunchCat(value) {
  return axios
    .post(
      endpoints.launchCategory.addUpdateLaunchCategory,
      JSON.stringify(value)
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getLaunchCategoryById(id) {
  return axios
    .post(`${endpoints.launchCategory.getLaunchCategoryById}${"?Id=" + id}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getLaunchCategory(value) {
  return axios
    .post(endpoints.launchCategory.getLaunchCategory, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteLaunchCategory(value) {
  return axios
    .post(endpoints.launchCategory.deleteCategoryById, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

const getDropdownValues = async ({ url }) => {
  return axios
    .post(url)
    .then(response => response?.data)
    .catch(error => {
      console.error("getDropdownValues : ", { url, error });
      return null;
    });
};

export const launchesApi = {
  getLaunches: getLaunches,
  getLaunchById: getLaunchById,
  addupdateLaunch: addupdateLaunch,
  getLaunchCategoriesDD: getLaunchCategoriesDD,
  addLaunches: addLaunches,
  updateLaunches: updateLaunches,
  getLaunchActivities: getLaunchActivities,
  getLaunchNotes: getLaunchNotes,
  addUpdateNotes: addUpdateNotes,
  deleteLaunchtNote: deleteLaunchtNote,
  getReleaseLocation: getReleaseLocation,
  getReleaseCategory: getReleaseCategory,
  addReleaseCategory: addReleaseCategory,
  updateReleaseCategory: updateReleaseCategory,
  getAllReleases: getAllReleases,
  getReleases: getReleases,
  getReleaseCategories: getReleaseCategories,
  getReleaseById: getReleaseById,
  deleteLaunches: deleteLaunches,
  deleteReleases: deleteReleases,
  deleteRelCategory: deleteRelCategory,
  uploadImage: uploadImage,
  createRelease: createRelease,
  getFeaturFiles: getFeaturFiles,
  updateRelease: updateRelease,
  getReleaseVisibility: getReleaseVisibility,
  getReleaseRelatedEvents: getReleaseRelatedEvents,
  getReleaseNewsRoomVisibilities: getReleaseNewsRoomVisibilities,
  getAllEvents: getAllEvents,
  getEventsCategory: getEventsCategory,
  addUpdateEvent: addUpdateEvent,
  deleteEvents: deleteEvents,
  getEventById: getEventById,
  getLocationsAutoComplete: getLocationsAutoComplete,
  getEventCategories: getEventCategories,
  addUpdateEventCategory: addUpdateEventCategory,
  getEventCatById: getEventCatById,
  deleteEventsCatById: deleteEventsCatById,
  getEventType: getEventType,
  addUpdateReleaseCategory: addUpdateReleaseCategory,
  getReleaseCatById: getReleaseCatById,
  getEventDropDown: getEventDropDown,
  getLaunchesList: getLaunchesList,
  getRegistrationPendingApprovalProducts:
    getRegistrationPendingApprovalProducts,
  getNewestApprovedRegistrationProducts: getNewestApprovedRegistrationProducts,
  getDropDownLaunches: getDropDownLaunches,
  getDropdownValues: getDropdownValues,
  addUpdateLaunchCat: addUpdateLaunchCat,
  getLaunchCategoryById: getLaunchCategoryById,
  getLaunchCategory: getLaunchCategory,
  deleteLaunchCategory: deleteLaunchCategory,
};
