export const softwareUpdatesTypes = {
  SOFTWAREUPDATES_ONCHANGE: "SOFTWAREUPDATES_ONCHANGE",
  SEARCH_UPDATE: "SEARCH_UPDATE",
  SEARCH_UPDATEPRODUCT: "SEARCH_UPDATEPRODUCT",
  SEARCH_BUILDUPDATE: "SEARCH_BUILDUPDATE",
  ON_CHANGE: "ON_CHANGE",
  ON_LOAD_CHANGE: "ON_LOAD_CHANGE",
  BUILD_UPDATES_TAB:"BUILD_UPDATES_TAB",
  ADD_UPDATE_SOFTWARE_RELEASE: "ADD_UPDATE_SOFTWARE_RELEASE",
  ADD_UPDATE_RELEASE_REQUIREMENTS: "ADD_UPDATE_RELEASE_REQUIREMENTS",
  ADD_UPDATE_RELEASE_PRODUCTS: "ADD_UPDATE_RELEASE_PRODUCTS",
  SET_SELECTED_RELEASE: "SET_SELECTED_RELEASE",
  ON_CHANGE_SELECTED_RELEASE_FIELD: "ON_CHANGE_SELECTED_RELEASE_FIELD",
  ON_CHANGE_SELECTED_RELEASE_CONTACTS: "ON_CHANGE_SELECTED_RELEASE_CONTACTS",
  ON_CHANGE_SELECTED_RELEASE_PORTAL: "ON_CHANGE_SELECTED_RELEASE_PORTAL",
  ON_CHANGE_SELECTED_RELEASE_PRODUCTS: "ON_CHANGE_SELECTED_RELEASE_PRODUCTS",
  UPDATE_SELECTED_RELEASE_CONTACTS : 'UPDATE_SELECTED_RELEASE_CONTACTS',
  ON_CHANGE_PORTAL_CONTACT_FIELD:"ON_CHANGE_PORTAL_CONTACT_FIELD",
  ON_SOFTWARE_RELEASE_DATA:"ON_SOFTWARE_RELEASE_DATA",
  RESET_SOFTWARE_RELEASE_DATA:"RESET_SOFTWARE_RELEASE_DATA",
  ON_RELEASE_FAVOURITE: "ON_RELEASE_FAVOURITE",
  ON_DELETE_RELEASE_DATA:"ON_DELETE_RELEASE_DATA",
  ON_REMOVE_SELECTED_PORTAL_CONTACT:"ON_REMOVE_SELECTED_PORTAL_CONTACT",
};
