import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { concat } from "lodash";
import addDays from "date-fns/addDays";
import DateFnsUtils from "@date-io/date-fns";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import { Close, Add, Search, Clear } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  MenuItem,
  Select,
  Button,
  Checkbox,
  FormControl,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { downarrow } from "../../../../resources/images/index";
import { PopupDialog } from "../../../../components/common";
import Loader from "../../../../components/common/Loader";
import { useDebounce } from "../../../../utils/use-debounce";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  collectionLabels: {
    backgroundColor: "#F3F6F9",
    borderRadius: "36px",
    color: "#70808F",
    fontSize: "14px",
    fontWeight: "400",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 16px 16px 0",
  },
  collectionLabelsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    // marginLeft: "40px",
  },
  deleteLabels: {
    marginLeft: "15px",
    display: "flex",
    cursor: "pointer",
  },
  viewReleaseWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    backgroundColor: "transparent !important",
    //   margin: '12px 0'
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

const ReleaseVisabilityGroup = memo(
  ({
    audianceList = [],
    newsRoomVisibilityId,
    releaseVisibilityGroups = [],
    onNewsRoomVisibilityIdSelect,
    onGroupSelection,
    preReleaseVisibilityGroups,
    setPreReleaseVisibilityGroups,
    displayNewsRoomDD,
    mainTitle,
    headingVisi,
    textVisi,
    startDate,
    setIsPrereleaseValidDate,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState("panel2");
    const [isLoading, setIsLoading] = useState(false);
    const [isAddVisibilityGroup, setIsAddVisibilityGroup] = useState(false);
    const [visibilityGroups, setVisibilityGroups] = useState([...audianceList]);
    const [filteredEventVisibilityGroups, setFlteredEventVisibilityGroups] =
      useState(visibilityGroups);
    const [selectedEventVisibilityGroups, setSelectedEventVisibilityGroups] = useState(
      []
    );
    const [tempEventVisibilityGroups, setTempEventVisibilityGroups] = useState([]);
    const [newsRoomVisibility, selectNewsRoomVisibility] = useState(null);
    const [filterByName, setFilterByName] = useState("");
    const { debouncedValue } = useDebounce(filterByName, 500);

    useEffect(() => {
      if (debouncedValue) {
        setFlteredEventVisibilityGroups([
          ...visibilityGroups.filter(c =>
            c?.value?.toLowerCase().includes(debouncedValue?.toLowerCase())
          ),
        ]);
      } else setFlteredEventVisibilityGroups(visibilityGroups);
    }, [visibilityGroups, debouncedValue]);

    const handleChange = panel => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const { releaseNewsRoomVisibilities } = useSelector(
      state => state.LaunchesReducer
    );

    const loadGroups = useCallback(() => {
      setIsLoading(true);
      dispatch(launchesActions.onGetAllUserGroup()).then(data => {
        setVisibilityGroups(data?.data);
        setIsLoading(false);
      });
    }, [dispatch]);

    useEffect(() => {
      if (isAddVisibilityGroup || releaseVisibilityGroups.length > 0) {
        loadGroups();
      }
    }, [isAddVisibilityGroup, loadGroups, releaseVisibilityGroups.length]);

    useEffect(() => {
      if (newsRoomVisibilityId) selectNewsRoomVisibility(newsRoomVisibilityId);
    }, [newsRoomVisibilityId]);

    useEffect(() => {
      const groupsList = [
        ...releaseVisibilityGroups.map(g => {
          if (g.VisibilityGroupId) {
            return g;

          } else {
            return {
              VisibilityGroupId: g,
              name: visibilityGroups.find(z => z.id === g.toString())?.value,
            };
          }
        }),
      ];
      setSelectedEventVisibilityGroups(groupsList);
      setTempEventVisibilityGroups(groupsList);
    }, [releaseVisibilityGroups, visibilityGroups]);

    const onSelectEventGroupSelection = () => {
      setIsAddVisibilityGroup(false);
      setSelectedEventVisibilityGroups([...tempEventVisibilityGroups]);
      onGroupSelection && onGroupSelection(tempEventVisibilityGroups);
    };

    const onEventPopupClose = () => {
      setIsAddVisibilityGroup(false);
      setTempEventVisibilityGroups([...selectedEventVisibilityGroups]);
    };

    return (
      <div>
        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}>
          <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
            <Typography className={classes.mainHeading}>
              <img src={downarrow} alt='' className={classes.headingIcon} />
              {mainTitle}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isAddVisibilityGroup && (
              <PopupDialog visible={isAddVisibilityGroup} onClose={onEventPopupClose}>
                <div className='audiancePopupWrapper'>
                  <div className='audiancePopupInnerWrapper'>
                    <span className='innerHeading'>Add Audience</span>
                    <Close
                      style={{
                        cursor: "pointer",
                        padding: "20px",
                      }}
                      onClick={onEventPopupClose}
                    />
                  </div>
                  {isLoading && <Loader />}
                  <Grid item xs={12} style={{ paddingLeft: "13px" }}>
                    <Input
                      fullWidth
                      placeholder='Search by name'
                      disableUnderline
                      style={{
                        padding: "17px",
                      }}
                      startAdornment={
                        <Search
                          style={{
                            marginRight: "5px",
                          }}
                        />
                      }
                      onChange={({ target }) => setFilterByName(target.value)}
                    />
                  </Grid>
                  {filteredEventVisibilityGroups.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "264px",
                        overflowY: "auto",
                      }}>
                      {filteredEventVisibilityGroups.map(g => {
                        return (
                          <span className='innerList'>
                            <Checkbox
                              key={g.id}
                              label={g.value}
                              checked={
                                tempEventVisibilityGroups.find(
                                  s => `${s.VisibilityGroupId}` === `${g.id}`
                                )
                                  ? true
                                  : false
                              }
                              onChange={({ target }) => {
                                if (target.checked) {
                                  // tempVisibilityGroups.push({
                                  //   VisibilityGroupId: g.id,
                                  //   name: g.value
                                  // });

                                  setTempEventVisibilityGroups([
                                    ...tempEventVisibilityGroups, {
                                      VisibilityGroupId: g.id,
                                      name: g.value,
                                    }
                                  ]);
                                } else {
                                  setTempEventVisibilityGroups([
                                    ...tempEventVisibilityGroups.filter(
                                      s =>
                                        `${s.VisibilityGroupId}` !== `${g.id}`
                                    ),
                                  ]);
                                }
                              }}
                            />
                            <b>{g.value}</b>
                            {g.generic ? ` ${g.generic}` : ""}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <span
                      style={{
                        margin: "20px",
                        display: "flex",
                      }}>
                      No groups available
                    </span>
                  )}
                  <div className='audianceBtnWrapper'>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={tempEventVisibilityGroups.length <= 0}
                      onClick={() => onSelectEventGroupSelection()}>
                      Select
                    </Button>
                  </div>
                </div>
              </PopupDialog>
            )}
            {displayNewsRoomDD && (
              <Grid
                item
                xs={4}
                className={[classes.gridWrapper, classes.viewReleaseWrapper]}>
                <div className='inputWrapper'>
                  <FormControl className={classes.formControl}>
                    <label>
                      {"Newsroom Visibility"} <i className={"showRequired"}></i>
                    </label>
                    <Select
                      value={newsRoomVisibility}
                      onChange={e => {
                        selectNewsRoomVisibility(e.target.value);
                        onNewsRoomVisibilityIdSelect &&
                          onNewsRoomVisibilityIdSelect(e.target.value);
                      }}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"outlined"}
                      required>
                      <MenuItem value='' disabled>
                        <span>{"Newsroom Visibility"}</span>
                      </MenuItem>
                      {releaseNewsRoomVisibilities?.length > 0 &&
                        releaseNewsRoomVisibilities.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>
                              {item.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            )}

            <Grid item xs={12} className={classes.viewReleaseWrapper}>
              <Typography>
                <ul className='visabilityWrapperHeading'>
                  <li>
                    <span>{headingVisi}</span>
                  </li>
                </ul>
              </Typography>
              <Typography>{textVisi}</Typography>
              <div className='addReleasesGroups'>
                <Button
                  color='primary'
                  variant='outlined'
                  startIcon={<Add />}
                  onClick={() => setIsAddVisibilityGroup(true)}>
                  Add Audience
                </Button>
              </div>
            </Grid>
            {selectedEventVisibilityGroups && (
              <div className={classes.collectionLabelsWrapper}>
                {selectedEventVisibilityGroups.map((item, index) => {
                  return (
                    <div key={index} className={classes.collectionLabels}>
                      <span>{item.name}</span>
                      <span
                        className={classes.deleteLabels}
                        onClick={() => {
                          const filteredList = [
                            ...selectedEventVisibilityGroups.filter(
                              g =>
                                `${g.VisibilityGroupId}` !==
                                `${item.VisibilityGroupId}`
                            ),
                          ];
                          onGroupSelection && onGroupSelection(filteredList);
                        }}>
                        <Clear className='collectionLabelDeleteBtn' />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
            <div>
              <div className='addPreRelease' style={{ marginLeft: "40px" }}>
                <Button
                  color='primary'
                  variant='outlined'
                  startIcon={<Add />}
                  onClick={() => {
                    preReleaseVisibilityGroups.push({
                      preReleaseDate: addDays(new Date(), 15),
                      isDeleted: false,
                      groupList: [],
                    });
                    setPreReleaseVisibilityGroups([
                      ...preReleaseVisibilityGroups,
                    ]);
                  }}>
                  Add Pre-Release
                </Button>
              </div>

              {preReleaseVisibilityGroups?.map((prvg, i) => (
                <PreReleaseSection
                  audianceList={audianceList}
                  preReleaseVisibilityGroup={prvg}
                  onPreReleaseGroupSelection={updatedData => {
                    preReleaseVisibilityGroups[i] = updatedData;
                    setPreReleaseVisibilityGroups([
                      ...preReleaseVisibilityGroups,
                    ]);
                  }}
                  startDate={startDate}
                  setIsPrereleaseValidDate={setIsPrereleaseValidDate}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
);

const PreReleaseSection = ({
  audianceList = [],
  preReleaseVisibilityGroup,
  onPreReleaseGroupSelection,
  startDate,
  setIsPrereleaseValidDate,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [visibilityGroups, setVisibilityGroups] = useState([...audianceList]);
  const [preReleaseDate, setPreReleaseDate] = useState(
    preReleaseVisibilityGroup.preReleaseDate
  );
  // preReleaseVisibilityGroup.preReleaseDate.includes("z")
  //   ? preReleaseVisibilityGroup.preReleaseDate
  //   : `${preReleaseVisibilityGroup.preReleaseDate}z`
  useEffect(()=>{
    setIsPrereleaseValidDate(preReleaseDate)
  },[])

  const [isAddVisibilityGroup, setIsAddVisibilityGroup] = useState(false);
  const [selectedVisibilityGroups, setSelectedVisibilityGroups] = useState([
    ...preReleaseVisibilityGroup.groupList,
  ]);
  const [tempVisibilityGroups, setTempVisibilityGroups] = useState([
    ...preReleaseVisibilityGroup.groupList,
  ]);
 
  const [filteredvisibilityGroups, setfFlteredVisibilityGroups] =
    useState(visibilityGroups);
  const [filterByName, setFilterByName] = useState("");
  const { debouncedValue } = useDebounce(filterByName, 500);

  useEffect(() => {
    if (debouncedValue) {
      setfFlteredVisibilityGroups([
        ...visibilityGroups.filter(c =>
          c?.value?.toLowerCase().includes(debouncedValue?.toLowerCase())
        ),
      ]);
    } else setfFlteredVisibilityGroups(visibilityGroups);
  }, [visibilityGroups, debouncedValue]);

  useEffect(() => {
    setTempVisibilityGroups([...preReleaseVisibilityGroup.groupList]);
  }, [preReleaseVisibilityGroup.groupList]);

  const loadGroups = useCallback(() => {
    setIsLoading(true);
    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      setVisibilityGroups(data?.data);
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (isAddVisibilityGroup) {
      loadGroups();
    }
  }, [isAddVisibilityGroup, loadGroups]);

  const onCloseGroupSelection = () => {
    setIsAddVisibilityGroup(false);
    setSelectedVisibilityGroups([...tempVisibilityGroups]);
    if (tempVisibilityGroups.length >= 0)
      onPreReleaseGroupSelection &&
        onPreReleaseGroupSelection({
          ...preReleaseVisibilityGroup,
          preReleaseDate: preReleaseDate,
          groupList: tempVisibilityGroups,
          groupIds: tempVisibilityGroups.map(g =>
            parseInt(g.VisibilityGroupId)
          ),
        });
  };

  const onClose = () => {
    setIsAddVisibilityGroup(false);
    setTempVisibilityGroups([...selectedVisibilityGroups]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderTop: "1px solid rgb(141, 153, 165, 0.3)",
        marginTop: "10px",
      }}
    >
      {isAddVisibilityGroup && (
        <PopupDialog visible={isAddVisibilityGroup} onClose={() => onClose()}>
          <div className="audiancePopupWrapper">
            <div className="audiancePopupInnerWrapper">
              <span className="innerHeading">Add Audience</span>
              <Close
                style={{
                  cursor: "pointer",
                  padding: "20px",
                }}
                onClick={() => onClose()}
              />
            </div>
            {isLoading && <Loader />}
            <Grid item xs={12}>
              <Input
                fullWidth
                placeholder="Search by name"
                disableUnderline
                style={{
                  padding: "17px",
                }}
                startAdornment={
                  <Search
                    style={{
                      marginRight: "5px",
                    }}
                  />
                }
                onChange={({ target }) => setFilterByName(target.value)}
              />
            </Grid>
            {filteredvisibilityGroups.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "264px",
                  overflowY: "auto",
                }}
              >
                {filteredvisibilityGroups.map((g) => {
                  return (
                    <span className="innerList">
                      <Checkbox
                        key={g.id}
                        label={g.value}
                        checked={
                          tempVisibilityGroups.find(
                            (s) => `${s.VisibilityGroupId}` === `${g.id}`
                          )
                            ? true
                            : false
                        }
                        onChange={({ target }) => {
                          if (target.checked) {
                            setTempVisibilityGroups([
                              ...concat(tempVisibilityGroups, {
                                VisibilityGroupId: g.id,
                                name: g.value,
                              }),
                            ]);
                          } else {
                            setTempVisibilityGroups([
                              ...tempVisibilityGroups.filter(
                                (s) => `${s.VisibilityGroupId}` !== `${g.id}`
                              ),
                            ]);
                          }
                        }}
                      />
                      <b>{g.value}</b>
                      {g.generic ? ` ${g.generic}` : ""}
                    </span>
                  );
                })}
              </div>
            ) : (
              <span
                style={{
                  margin: "20px",
                  display: "flex",
                }}
              >
                No groups available
              </span>
            )}
            <div className="audianceBtnWrapper">
              <Button
                variant="contained"
                color="primary"
                disabled={tempVisibilityGroups.length <= 0}
                onClick={() => onCloseGroupSelection()}
              >
                Select
              </Button>
            </div>
          </div>
        </PopupDialog>
      )}
      <Grid item xs={12} className={classes.viewReleaseWrapper}>
        <Typography>
          <ul className="visabilityWrapperHeading">
            <li>
              <span>Pre-Release</span>
            </li>
          </ul>
        </Typography>
        <div className="preReleaseWrapper">
          <div className="preReleaseContentWrapper">
            <Grid item xs={3} className={classes.gridWrapper}>
              <Typography>
                These users will have access to the release on:
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridWrapper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container direction="row">
                  <Grid item xs={3} className={classes.gridWrapper}>
                    <div className="inputWrapper">
                      <FormControl className={classes.formControl}>
                        <DatePicker
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          value={preReleaseDate}
                          minDate={moment(new Date()).format("MM/DD/YYYY")}
                          maxDate={moment(new Date(startDate)).format(
                            "MM/DD/YYYY"
                          )}
                          onChange={(value) => {
                            setPreReleaseDate(value);
                            setIsPrereleaseValidDate(value);
                            onPreReleaseGroupSelection &&
                              onPreReleaseGroupSelection({
                                ...preReleaseVisibilityGroup,
                                preReleaseDate: value,
                                groupList: selectedVisibilityGroups,
                              });
                          }}
                          autoOk
                        />
                        {(startDate?.getTime()  < new Date(preReleaseDate)?.getTime()) && (
                          <FormHelperText style={{ color: "#E06145" }}>
                            Please select valid Date and Time.
                          </FormHelperText>
                        )}
                        {/* <DateTimePicker
                    variant='inline'
                    format='yyyy/MM/dd hh:mm a'
                    value={preReleaseDate}
                    onChange={value => {
                      setPreReleaseDate(value);
                      onPreReleaseGroupSelection &&
                        onPreReleaseGroupSelection({
                          ...preReleaseVisibilityGroup,
                          preReleaseDate: value,
                          groupList: selectedVisibilityGroups
                        });
                    }}
                  /> */}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className={classes.gridWrapper}
                    style={{ marginLeft: "10px" }}
                  >
                    <div className="inputWrapper">
                      <FormControl className={classes.formControl}>
                        <TimePicker
                          value={preReleaseDate}
                          onChange={(value) => {
                            setPreReleaseDate(value);
                            onPreReleaseGroupSelection &&
                              onPreReleaseGroupSelection({
                                ...preReleaseVisibilityGroup,
                                preReleaseDate: value,
                                groupList: selectedVisibilityGroups,
                              });
                          }}
                          autoOk
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </div>

          <Grid item className={classes.gridWrapper}>
            <div className="addReleasesGroups">
              <Button
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                onClick={() => setIsAddVisibilityGroup(true)}
              >
                Add Audience
              </Button>
            </div>
            {selectedVisibilityGroups && (
              <div
                className={classes.collectionLabelsWrapper}
                style={{
                  marginLeft: "0px",
                }}
              >
                {selectedVisibilityGroups.map((s, index) => {
                  return (
                    <div key={index} className={classes.collectionLabels}>
                      <span>{s.name}</span>
                      <span
                        className={classes.deleteLabels}
                        onClick={() => {
                          const filteredList = [
                            ...selectedVisibilityGroups.filter(
                              (g) => g.VisibilityGroupId !== s.VisibilityGroupId
                            ),
                          ];
                          setSelectedVisibilityGroups(filteredList);
                          onPreReleaseGroupSelection &&
                            onPreReleaseGroupSelection({
                              ...preReleaseVisibilityGroup,
                              preReleaseDate: preReleaseDate,
                              groupList: filteredList,
                              groupIds: filteredList.map((g) =>
                                parseInt(g.VisibilityGroupId)
                              ),
                            });
                        }}
                      >
                        <Clear className="collectionLabelDeleteBtn" />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export { ReleaseVisabilityGroup };
