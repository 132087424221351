import React from "react";
import OrderSummary from "./OrderSummary";
import { Grid } from "@material-ui/core";
import CompanyWallet from "./CompanyWallet";
import CreditLine from "./CreditLine";
import StorePoints from "./StorePoints";
import ConsumerOrdersGrid from "./ConsumerOrdersGrid";

function ConsumerOrders(props) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <OrderSummary />
        </Grid>

        <Grid item xs={5}>
          <CompanyWallet />
        </Grid>

        <Grid item xs={4}>
          <div className='creditStoreWrapper'>
            <CreditLine />
            <StorePoints />
          </div>
        </Grid>
      </Grid>

      <ConsumerOrdersGrid />
    </>
  );
}

export default ConsumerOrders;
