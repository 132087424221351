import React, { useRef, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import {
  logo,
  logoSign,
  loginLogo,
  verificationcode,
} from "../../../resources/images/index";
import { useDispatch, useSelector } from "react-redux";
import { accountActions } from "../../../store/accounts/accountActions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withRouter, Link, useLocation } from "react-router-dom";
import { RoutesObj } from "../../../routes";
import { Curve, CurveOverlay } from "../../../components/common/LoginSVG";
import curve from "../../../resources/images/curve.png";
import curveOverlay from "../../../resources/images/curveOverlay.png";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";

function VerificationCode(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [veriCode, setVeriCode] = useState("");
  const [resCode, setResCode] = useState("");
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (location && location.state && location.state.verificationCode) {
      setResCode(location.state.verificationCode);
    }
  }, []);
  const handleSubmit = e => {
    if (parseInt(veriCode) === resCode) {
      props.history.push("/password-reset");
    } else {
      toast.error("Sorry wrong code.");
    }
  };
  const resendEmail = () => {
    const userEmail = localStorage.getItem("userEmail");
    setLoading(true);
    dispatch(accountActions.onForgotPassword(userEmail)).then(data => {
      if (data && data.data) {
        if (data.data.success) {
          setResCode(data.data.data?.code);
          toast.success(data.data.data?.message);
        } else {
          toast.error(data.data.message);
        }
      }
      setLoading(false);
    });
  };
  const loginForm = useRef("form");
  return (
    <div className="loggedinWrapper">
      {isLoading ? <Loader /> : null}
      <div className="leftCol">
        <span className="loginLeftshade" />
        <span className="loginRightShade">
          <img src={curve} alt="" className="curve" />
          <img src={curveOverlay} alt="" className="curveOverlay" />
        </span>
      </div>
      <div className="rightCol">
        <img src={loginLogo} alt="" />
        <img src={logoSign} alt="" style={{ marginLeft: "195px" }} />
        <span className="createAccount centerHeading">
          <img src={verificationcode} alt="" />
          Verification Code Sent!
          <i> A verification code has been sent to your e-mail</i>
          <i className="resendWrapper">
            Not Received?{" "}
            <i className="resend">
              <a onClick={() => resendEmail()}>RESEND</a>
            </i>
          </i>
        </span>
        {/* <form onSubmit={loginForm.handleSubmit}> */}
        <ValidatorForm ref={loginForm} onSubmit={handleSubmit}>
          <TextValidator
            id="veriCode"
            label="Verification Code"
            variant="outlined"
            name="veriCode"
            fullWidth
            validators={["required"]}
            errorMessages={["Please enter verification code."]}
            value={veriCode}
            onChange={v => setVeriCode(v.target.value)}
          />

          <Button type="submit" color="primary" variant="contained">
            continue
          </Button>
        </ValidatorForm>
        {/* </form> */}
      </div>
    </div>
  );
}

export default withRouter(VerificationCode);
