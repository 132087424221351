import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { useDispatch, useSelector } from "react-redux";
import "react-web-tabs/dist/react-web-tabs.css";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import CustomerProfile from "./CustomerProfile";
import CustomerInfo from "./CustomerInfo";
import CustomerActivity from "./CustomerActivity";
import CustomerNotes from "./CustomerNotes";
import Loader from "../../../../components/common/Loader";
import RecentConversations from "./RecentConversations";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

const EndUserOverview = ({ userId , isActive }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [eStatus , setEStatus] = useState(1);

  useEffect(() => {
    const selectedUser = queryString.parse(location.search);
    if (selectedUser?.id) {
      setLoading(true);
      dispatch(endUserActions.onGetUserOverview(selectedUser?.id)).then(
        data => {
          if (data && data.success && data.data && data.data.customerInfo) {
            dispatch(
              endUserActions.onChange(
                "editUserName",
                data.data.customerInfo.firstName
              )
            );
          }

          setLoading(false);

          if (data && data.success === false) {
            toast.error("Unable to load customer overview");
          }
        }
      );
      dispatch(endUserActions.onGetUserById(selectedUser.id)).then(data => {
        if (data?.success) {
          setProfileImg(data?.data?.image);
          setEStatus(data?.data?.eStatus);
        }
      });
    }
  }, [dispatch, location , isActive]);
  const { userOverview } = useSelector(state => state.endUserReducer);
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {isLoading ? <Loader /> : null}
      <Grid item xs={4} className={classes.gridWrapper}>
        <CustomerProfile
          profileImage={profileImg}
          profileInfo={
            userOverview && userOverview.customerInfo
              ? userOverview.customerInfo
              : null
          }
          eStatus={eStatus}
        />
        <CustomerInfo
          profileInfo={
            userOverview && userOverview.customerInfo
              ? userOverview.customerInfo
              : null
          }
        />
      </Grid>

      <Grid item xs={8} className={classes.gridWrapper}>
        <RecentConversations />
        <CustomerActivity isActive={true} isOverview={true} />
        <CustomerNotes isActive={true} isOverview={true} />
      </Grid>
    </Grid>
  );
};

export default EndUserOverview;
