import React, { useState, useEffect } from "react";
import {
  InputField,
  Pagination,
  SelectInput,
} from "../../../../../components/common";
import Loader from "../../../../../components/common/Loader";
import {
  GridView,
  ListView,
} from "../../../LeftNav/SvgIcons";
import { Button, Grid, IconButton } from "@material-ui/core";
import SoftwareGridFilter from "../../SoftwareUpdates/SoftwareGridFilter";
import StatusTypesGrid from "./StatusTypesGrid";
import { useDispatch, useSelector } from "react-redux";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import { useDebounce } from "../../../../../utils/use-debounce";
import { DeleteFilesOverlay } from "../../../../../components/common/Modal/AssetsModals";
import { toast } from "react-toastify";

function ConversationSettingsStatusTypes({
  paramId,
  setIsAccordion3Done,
  setIsAccordion3Open,
  isAccordion3Edit,
  setIsAccordion3Edit,
  isNewStatusType,
  setIsNewStatustype,
}) {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [search, setSearch] = useState("");
  const { debouncedValue } = useDebounce(search, 500);
  const [order, setOrderBy] = useState({
    orderByKey: "Name",
    orderVal: 0,
  });
  const [addUpdateResponse, setAddUpdateResponse] = useState(null);
  const [deletedConversationType, setDeletedConversationType] = useState(null);
  const [isStatusTypeDeleted, setIsStatusTypeDelete] = useState(null);
  const {
    conversationCompaginId,
  } = useSelector(
    state => state.conversationReducer
);

  const handleNextButton = () => {
    setIsAccordion3Done(true);
    setIsAccordion3Open(false);
    setIsAccordion3Edit(false);
  };
  const handleSkipButton = () => {
    setIsAccordion3Done(true);
    setIsAccordion3Open(false);
    setIsAccordion3Edit(false);
  };

  useEffect(() => {
    setLoading(true)
    dispatch(
      conversationAction.onGetAllConversationStatus({
        productId: 2,
        campaignId: paramId ? paramId : conversationCompaginId,
        name: debouncedValue,
        status: null,
        pagination: {
          pageSize: 1000,
          pageNo: 1,
          orderByKey: order?.orderByKey,
          orderByVal: order?.orderVal,
        },
      })
    ).then(data => {
      setLoading(false);
      setAddUpdateResponse(null);
      if (data?.responseCode === 1) {
        setStatusList(data?.responseData?.data);
      }
    });
  }, [ 
    paramId, 
    debouncedValue, 
    addUpdateResponse, 
    order, 
    conversationCompaginId,
    isStatusTypeDeleted,
  ]);

  const onDeleteConversationStatusType = () => {
    dispatch(
      conversationAction.onDeleteConversationStatus(deletedConversationType)
    )
      .then(data => {
        if (data.responseCode === 1) {
          setIsStatusTypeDelete(data.responseCode);
          toast.success(
            "Conversation Status Type is Deleted Successfully"
          );
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch(error => {
        console.error("Error occurred:", error);
        toast.error("An error occurred. Please try again later.");
      });
     setIsStatusTypeDelete(null);
     setDeletedConversationType(null);
  };
 
  return (
    <div className='gridBody contentBody'>
      {isLoading ? <Loader /> : null}
      <Grid className='softwareGridFilterContainer convSettingsFilter'>
        <Grid className='gridFilterBox'>
          <SoftwareGridFilter
            search={search}
            setSearch={setSearch}
            isFilterTrue={false}
            placeholder={"Search"}
          />
          <Grid item md={1} className={"gridIconWrapper"}>
            <Grid style={{ cursor: "pointer" }}>
              <GridView color={"#3699FF"} />
            </Grid>
            <Grid style={{ cursor: "pointer" }}>
              <ListView color={"#979797"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <StatusTypesGrid
        paramId={paramId}
        isAccordion3Edit={isAccordion3Edit}
        isNewStatusType={isNewStatusType}
        setIsNewStatustype={value => setIsNewStatustype(value)}
        statusList={statusList}
        onOrderBy={setOrderBy}
        order={order}
        setAddUpdateResponse={setAddUpdateResponse}
        setDeletedConversationType={setDeletedConversationType}
      />
      <Grid item className='btn_save_next'>
        {!paramId || isAccordion3Edit ? (
          <>
            <Button
              variant='outlined'
              className='skipButton'
              onClick={handleSkipButton}>
              Skip
            </Button>
            <Button
              color='primary'
              variant='contained'
              className='dashboardButtons saveBasicInfo'
              type='submit'
              onClick={handleNextButton}>
              Next
            </Button>
          </>
        ) : null}
      </Grid>
      {deletedConversationType ? (
        <DeleteFilesOverlay
          heading={"Delete Conversation Status"}
          openDeleteFilesOverlay={deletedConversationType}
          setOpenDeleteFilesOverlay={value => {
            setDeletedConversationType(value);
          }}
          deleteAssetFiles={() => {
            onDeleteConversationStatusType();
          }}
          overlayText1={"Are you sure you want to Delete Conversation Status Type?"}
          overlayText2={""}
          buttonText={"Delete"}
        />
      ) : null}
    </div>
  );
}

export default ConversationSettingsStatusTypes;
