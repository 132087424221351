import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Checkbox,
  Button,
  Grid,
} from "@material-ui/core";
import { Delete, MoveIcon } from "../../LeftNav/SvgIcons";
import MoveMediaFilesPopup from "./MoveMediaFilesPopup";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import {
  pdf,
  txt,
  pngFile,
  doc,
  genericFile,
  folder,
  star,
  starOutlineYellow,
  mediaFile,
} from "../../../../resources/images/index";
import { PlayMedia } from "./PlayMedia";
import {
  SortIcon,
  QuickEyeIcon,
  PdFIcon,
  FolderIcon,
  RcoveryIcon,
} from "../../LeftNav/SvgIcons";
import { reduce } from "lodash";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { AssestPreview } from "../../../../components/common/Modal/AssetsModals";
import { assetsTypes } from "../../../../store/assetsManagement/assetsTypes";

const useStyles = makeStyles((theme) => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  gridBodyNameCell: {
    border: "1px solid #000000",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function MediaLibraryList({
  mediaAssetsData,
  startLoading,
  endLoading,
  loadData,
  order,
  onOrderBy,
  parent,
  onParentId,
  setPage,
  setSelectedMediaFile,
  setFolderData,
  renderSelectedData,
  renderAllData,
  breadCrumbs,
  selectedMediaFile,
  page,
  rowsPerPage,
  markAsFav,
  // deleteAssetsMedia,
  onDeleteAssets,
  isTrashedItem,
  isMoveClicked,
  onPermanetlyDeleteAssets,
  recoverAssets,
  tabName,
  setIsMoveClicked,
  setSelectedMoveItems,
  setOpenDeleteFilesOverlay,
  moveItem,
  selectedMoveItems,
  selectedFolder,
  setSelectedFolder,
  openGridViewOnUploadeAsset,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedMediaAssets } = useSelector((state) => state.AssetsReducer);
  const { gridData, search, time, type, isfavorite } = useSelector(
    (state) => state.AssetsReducer
  );
  const [open, setOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState(new Set());

  const onClickPreview = (item) => {
    if (item.type === "folder") {
      setOpen(false);
    } else {
      setOpen(true);
      setPreviewItem(item);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const selectMediaAssets = (value) => {
    dispatch(assetsActions.onSelectMediaAssets(value));
  };
  console.log({selectedMediaAssets})
  const selectAllMediaListItems = () => {
    if (!selectedMediaAssets.length) {
      const allIds = gridData.map((product) => product.id);
      dispatch(assetsActions.onSelectAllMediaAssets(allIds));
    } else {
      dispatch(assetsActions.onSelectAllMediaAssets([]));
    }
  };

  function truncate(input) {
    if (input && input.length > 15) {
      return input.substring(0, 13) + "...";
    }
    return input;
  }
 
  const checkMediaAssetsSelected = (itemId) => {
    const assetExist = selectedMediaAssets.find((asset) => {
      return asset === itemId;
    });
    console.log({ assetExist });
    if (assetExist) {
      return true;
    } else {
      return false;
    }
  };
  
  // console.log("selectedMediaAssets" , selectedMediaAssets , checkMediaAssetsSelected());
  // const checkFileType = fileType => {
  //   if (fileType === "folder") {
  //     return (<><FolderIcon /> Folder</>);
  //   } else {
  //     return (<> <PdFIcon /> {fileType.toUpperCase()}</>);
  //   }
  // };

  const previewData = (item) => {
    dispatch(
      assetsActions.onGetFolderPreview({
        parentId: item.id,
        type: type,
        search: search,
        time: time,
        isfavorite: isfavorite,
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      })
    );
  };
  //   // addNewBreadCrumbItem(item.id, item.name);
  //   // setPage(1);
  const checkFileType = (item) => {
    if (item?.type === "folder") {
      return (
        <>
          <FolderIcon /> &nbsp; {truncate(item.name)}
        </>
      );
    } else {
      return (
        <>
          {" "}
          <PdFIcon /> &nbsp; {truncate(item.name)}
        </>
      );
    }
  };

  // const setFolderData = (item) => {
  //   dispatch(assetsActions.onChange("parentId", item.id));
  //   addNewBreadCrumbItem(item.id, item.name);
  //   setPage(1);
  // };

  // const renderAllData = () => {
  //   dispatch(assetsActions.onChange("parentId", 0));
  //   var breadCrumbsData = [];
  //   dispatch(assetsActions.onChange("breadCrumbs", breadCrumbsData));
  //   setPage(1);
  // };

  // const renderSelectedData = (item, index) => {
  //   dispatch(assetsActions.onChange("parentId", item.id));
  //   var breadCrumbsData = breadCrumbs;
  //   let bread = breadCrumbsData.splice(0, index + 1);
  //   breadCrumbsData = bread;
  //   dispatch(assetsActions.onChange("breadCrumbs", breadCrumbsData));
  //   setPage(1);
  // };

  // const addNewBreadCrumbItem = (id, folderName) => {
  //   var breadCrumbsData = breadCrumbs;
  //   let data = {
  //     title: folderName,
  //     id: id,
  //   };
  //   breadCrumbsData.push(data);
  //   dispatch(assetsActions.onChange("breadCrumbs", breadCrumbsData));
  // };
  console.log({ selectedMoveItems , moveItem})
  const getGridViewClasses = (item, moveItem, selectedMoveItems) => {
    if (
      selectedFolder.id === item.id && item.type === "folder"
    ) {
      return "assetFolderGridSelect";
    } else if (moveItem?.id === item.id) {
      return "assetMainGridOnMoveSelect";
    } else if (selectedMoveItems?.includes(item.id)) {
      return "assetMainGridOnMoveSelect";
    } else if (item.isChecked === true) {
      return "assetMainGridOnChecked";
    } else if (
      !moveItem &&
      selectedMoveItems?.length === 0 
    ) {
      return "assetMainGrid";
    } else if (item.type === "folder") {
      return "assetMainGrid";
    } else {
      return "assetMainGridOnMoveDisable";
    }

  };
 
  const onAssetsMove = () => {
    setSelectedMoveItems(selectedMediaAssets);
    setIsMoveClicked(false);
  };
  const openOverlayForDeleteAction = () => {
    setOpenDeleteFilesOverlay(true);
  };
  const toggleFolder = (folderId) => {
    const newExpandedFolders = new Set(expandedFolders);
    if (expandedFolders.has(folderId)) {
      newExpandedFolders.delete(folderId);
    } else {
      newExpandedFolders.add(folderId);
    }
    setExpandedFolders(newExpandedFolders);
  };

  const renderFolderRow = (folder, level) => (
    <React.Fragment key={folder.id}>
      <TableRow className={getGridViewClasses(
          folder,
          moveItem,
          selectedMoveItems
        )}
        onClick={() => {
          if (
            selectedMoveItems?.length ||
            openGridViewOnUploadeAsset !== "" ||
            moveItem !== null
          ) {
            setSelectedFolder(folder.type === "folder" ? folder : "");
          }
        }}
        >
        <TableCell className={classes.gridBodyInner}>
        <Checkbox className={classes.checkBoxFont}
        onClick={() => selectMediaAssets(folder)}
        checked={
          selectedMediaAssets.length > 0 &&
          checkMediaAssetsSelected(folder.id)
        }
        />
        </TableCell>
        <TableCell
          className={classes.gridBodyInner}
          style={{ cursor: "pointer" }}
        >
          <Box style={{ marginLeft: `${level * 20}px` }}>
            {expandedFolders.has(folder.id) ? (
              <KeyboardArrowDownIcon
                style={{
                  marginRight: 7,
                  cursor: "pointer",
                  color: "#3699FF",
                }}
                onClick={() => {
                  toggleFolder(folder.id);
                  dispatch({
                    type: assetsTypes.RESET_ASSETS_PREVIEW,
                    payload: { value: folder.id },
                  });
                }}
              />
            ) : (
              level < 4 && (
                <KeyboardArrowRightIcon
                  style={{
                    marginRight: 7,
                    cursor: "pointer",
                    color: "#3699FF",
                  }}
                  onClick={() => {
                    toggleFolder(folder.id);
                    previewData(folder);
                  }}
                />
              )
            )}
            <span
              onClick={() => {
                setFolderData(folder);
              }}
            >
              {checkFileType(folder)}
            </span>
          </Box>
        </TableCell>
        <TableCell className={classes.gridBodyInner}>{folder.type}</TableCell>
        <TableCell className={classes.gridBodyInner}>
          {moment(folder?.uploadedAt).format("MM/DD/YYYY")}
        </TableCell>
        <TableCell className={classes.gridBodyInner}>{folder.size}</TableCell>
        <TableCell className={classes.gridBodyInner}>
          <span className="quickActionSpan">
            {isTrashedItem ? (
              <IconButton
                onClick={() => {
                  recoverAssets([folder.id]);
                }}
              >
                <RcoveryIcon />
              </IconButton>
            ) : (
              <span className="quickActionSpan">
                {folder.isfavorite ? (
                  <img
                    src={star}
                    alt=""
                    style={{ marginLeft: 15, cursor: "pointer" }}
                    onClick={() => markAsFav(folder, false)}
                  />
                ) : (
                  <img
                    src={starOutlineYellow}
                    alt=""
                    style={{
                      height: 18,
                      width: 18,
                      marginLeft: 15,
                      cursor: "pointer",
                    }}
                    onClick={() => markAsFav(folder, true)}
                  />
                )}
                <IconButton
                  onClick={() => {
                    onClickPreview(folder);
                  }}
                >
                  <QuickEyeIcon fill1="#ebebe4" fill2="#ebebe4" />
                </IconButton>
              </span>
            )}
            <DeleteIcon
              style={{ color: "#EB5757", cursor: "pointer" }}
              onClick={() => {
                isTrashedItem
                  ? onPermanetlyDeleteAssets(folder)
                  : onDeleteAssets(folder);
              }}
            />
          </span>
        </TableCell>
      </TableRow>
      {expandedFolders.has(folder.id) &&
        gridData
          .filter((item) => item.parentId === folder.id)
          .map((child) => {
            if (child.type === "folder") {
              return renderFolderRow(child, level + 1);
            } else {
              return renderAssetRow(child, level + 1);
            }
          })}
    </React.Fragment>
  );

  const renderAssetRow = (item, level) => (
    <TableRow
      className={`gridBodyRow ${getGridViewClasses(
        item,
        moveItem,
        selectedMoveItems
      )}`}
    >
      <TableCell className={classes.gridHeaderInner}>
        <Checkbox
          className={classes.checkBoxFont}
          onClick={() => selectMediaAssets(item)}
          checked={
            selectedMediaAssets.length > 0 && checkMediaAssetsSelected(item.id)
          }
        />
      </TableCell>
      <TableCell className={classes.gridBodyInner}>
        <Box style={{ marginLeft: `${level * 20}px` }}>
          <span
            onClick={() => {
              if (item.type === "folder") {
                setFolderData(item);
              } else if (item.type === "mp4") {
              }
            }}
          >
            {checkFileType(item)}
          </span>
        </Box>
      </TableCell>

      <TableCell className={classes.gridBodyInner}>{item.type}</TableCell>

      <TableCell className={classes.gridBodyInner}>
        {moment(item.uploadedAt).format("MM/DD/YYYY")}
      </TableCell>
      <TableCell className={classes.gridBodyInner}>{item.size}</TableCell>
      <TableCell className={classes.gridBodyInner}>
        <span className="quickActionSpan">
          {isTrashedItem ? (
            <IconButton
              onClick={() => {
                recoverAssets([item.id]);
              }}
            >
              <RcoveryIcon />
            </IconButton>
          ) : (
            <span className="quickActionSpan">
              {item.isfavorite ? (
                <img
                  src={star}
                  alt=""
                  style={{ marginLeft: 15, cursor: "pointer" }}
                  onClick={() => markAsFav(item, false)}
                />
              ) : (
                <img
                  src={starOutlineYellow}
                  alt=""
                  style={{
                    height: 18,
                    width: 18,
                    marginLeft: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => markAsFav(item, true)}
                />
              )}
              <IconButton
                disabled={item?.type === "doc" || item?.type === "docx"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  onClickPreview(item);
                }}
              >
                {item.type === "doc" || item.type === "docx" ? (
                  <QuickEyeIcon fill1="#ebebe4" fill2="#ebebe4" />
                ) : (
                  <QuickEyeIcon />
                )}
              </IconButton>
            </span>
          )}
          <DeleteIcon
            style={{ color: "#EB5757", cursor: "pointer" }}
            onClick={() => {
              isTrashedItem
                ? onPermanetlyDeleteAssets(item)
                : onDeleteAssets(item);
            }}
          />
        </span>
      </TableCell>
    </TableRow>
  );

  return (
    <div>
      {/* <div className="assetGridHeading" style={{ margin: "24px 0px" }}>
        {tabName === "Your Asset Library" ? "Asset Library Home" : tabName}
      </div> */}
      <Grid className="assetsHeader">
        {openGridViewOnUploadeAsset === "openGridViewOnUploadeAsset" ? (
          <Grid className="assetGridMoveToFileHeading">
            Choose an Upload Location:
          </Grid>
        ) : (moveItem === null) & (selectedMoveItems?.length === 0) ? (
          <Grid className="assetGridHeading">
            {tabName === "Your Asset Library" ? "Asset Library Home" : tabName}
          </Grid>
        ) : (
          <Grid className="assetGridMoveToFileHeading">Move File To:</Grid>
        )}
      </Grid>
      {isTrashedItem === true && selectedMediaAssets.length !== 0 ? (
        <Grid>
          <Button
            variant="contained"
            className={"assetMoveBtn"}
            startIcon={<RcoveryIcon fill1="#ffffff" />}
            onClick={() => recoverAssets(selectedMediaAssets)}
          >
            Recover
          </Button>
          <Button
            variant="contained"
            className="assetDeleteBtn"
            startIcon={<Delete color={"white"} opacity={"1"} size={"16"} />}
            onClick={() => {
              openOverlayForDeleteAction();
            }}
          >
            Delete
          </Button>
        </Grid>
      ) : null}
      {selectedMediaAssets.length !== 0 &&
            isMoveClicked &&
            isTrashedItem === false ? (
              <Grid>
                <Button
                  variant="contained"
                  className={"assetMoveBtn"}
                  startIcon={
                    <MoveIcon stroke1={"white"} stroke2={"white"} size={"16"} />
                  }
                  onClick={() => onAssetsMove()}
                >
                  Move
                </Button>
                <Button
                  variant="contained"
                  className="assetDeleteBtn"
                  startIcon={
                    <Delete color={"white"} opacity={"1"} size={"16"} />
                  }
                  onClick={() => {
                    openOverlayForDeleteAction();
                  }}
                >
                  Trash
                </Button>
              </Grid>
            ) : null}
      <Paper>
        {/* <div
          className="breadCrumbs__assetManagement">
          <span className="breadCrumbsText">
            <span onClick={() => renderAllData()} style={{ cursor: "pointer" }}>
              Asset Library Home
            </span>
          </span>
          {breadCrumbs &&
            breadCrumbs.length > 0 &&
            breadCrumbs.map((item, index) => {
              return (
                <span key={index} className="breadCrumbsText">
                  {" > "}
                  <span
                    onClick={() => renderSelectedData(item, index)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.title}
                  </span>
                </span>
              );
            })}
          
        </div> */}
        {breadCrumbs && breadCrumbs.length > 0 && (
          <div className="breadCrumbs__assetManagement breadCrumbs">
            <span className="breadCrumbsText breadCrumbs">
              {breadCrumbs && breadCrumbs.length > 0 && (
                <span
                  onClick={() => renderAllData()}
                  style={{ cursor: "pointer" }}
                >
                  All
                </span>
              )}
            </span>
            {breadCrumbs &&
              breadCrumbs.length > 0 &&
              breadCrumbs.map((item, index) => {
                return (
                  <span key={index} className="breadCrumbsText breadCrumbs">
                    {" / "}
                    <span
                      onClick={() => renderSelectedData(item, index)}
                      style={{ cursor: "pointer" }}
                    >
                      {item.title}
                    </span>
                  </span>
                );
              })}
          </div>
        )}
        <Table>
          <TableHead className={classes.gridHeader}>
            <TableRow className={classes.gridHeaderRow}>
              <TableCell className={classes.gridHeaderInner}>
                <Checkbox
                  className={classes.checkBoxFont}
                  onClick={() => selectAllMediaListItems()}
                  checked={gridData.length > 0 && gridData.length === selectedMediaAssets.length}
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Name
                <TableSortLabel
                  active={order.orderByKey === "Name"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Name",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className="sortIcon"
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                KIND
                <TableSortLabel
                  active={order.orderByKey === "typeOf"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "typeOf",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className="sortIcon"
                />
              </TableCell>

              <TableCell className={classes.gridHeaderInner}>
                Date Uploaded
                <TableSortLabel
                  active={order.orderByKey === "UploadedAt"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "UploadedAt",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className="sortIcon"
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                File Size
                <TableSortLabel
                  active={order.orderByKey === "SizeInBytes"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "SizeInBytes",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className="sortIcon"
                />
              </TableCell>
              <TableCell
                className={classes.gridHeaderInner}
                style={{ textAlign: "center" }}
              >
                QUICK ACTIONS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gridData
              .filter((item) => item.level === 1)
              .map((item) => {
                if (item.type === "folder") {
                  return renderFolderRow(item, 0);
                } else {
                  return renderAssetRow(item, 0);
                }
              })}
            {gridData.length <= 0 && (
              <TableRow key="0" className="gridBodyRow">
                <TableCell
                  colSpan="7"
                  className={classes.gridBodyInner}
                  style={{ textAlign: "center" }}
                >
                  <span className="emptyGridText">
                    Uh Oh! You haven’t created any media asset here.
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <AssestPreview
        open={open}
        closeDialog={handleClose}
        previewItem={previewItem}
      />

      {selectedMediaFile && (
        <PlayMedia
          selectedMediaFile={selectedMediaFile}
          onClose={() => {
            setSelectedMediaFile(null);
          }}
        />
      )}
    </div>
  );
}

export default MediaLibraryList;
