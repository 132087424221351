import { Button } from "@material-ui/core";

const EmptyGridText = ({ text, buttonText, onClick }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px",
    }}>
    <span
      style={{
        fontSize: "18px",
        fontWeight: "400",
      }}>
      {`Uh Oh! You haven’t created any ${
        text || "(pass text)"
      }. Start by creating a`}
    </span>
    <Button
      variant='text'
      color='primary'
      style={{
        fontSize: "16px",
        fontWeight: "400",
        marginLeft: "2px",
        marginTop: "2px",
        textTransform: "capitalize",
      }}
      onClick={onClick && onClick}>
      {`New ${buttonText || "Item"}`}
    </Button>
  </div>
);

const EmptyContentText = ({
  text = "No Portals Have Been Added Yet",
  buttonText = "Add a Portal",
  onClick = () => {},
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontSize: "14px",
    }}>
    <label>{text}</label>
    <Button
      variant='text'
      color='primary'
      style={{
        fontWeight: "700",
        marginLeft: "2px",
        marginTop: "2px",
        textTransform: "unset",
      }}
      onClick={onClick && onClick}>
      {buttonText}
    </Button>
  </div>
);

export { EmptyGridText, EmptyContentText };
