import React, { useState, useEffect } from "react";
import {
  LogHeader,
  MainHeading,
} from "../../../components/common/Header/index";
import Cards from "../../../components/common/Cards";
import Carousel from "../../../components/common/Carousel";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { pubReleaseActions } from "../../../store/pubRelease/pubReleaseActions";
import { pubEventActions } from "../../../store/pubEvent/pubEventActions";
import { accountActions } from "../../../store/accounts/accountActions";
import moment from "moment";
import { useHistory } from "react-router";
import Footer from "../../../components/common/Footer/Footer";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const events = [
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Event Title",
    cardtext: "SAT, APR 18, 2021",
    date: "This Saturday",
    time: "10 AM PST",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Event Title",
    cardtext: "SAT, APR 18, 2021",
    date: "This Saturday",
    time: "10 AM PST",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Event Title",
    cardtext: "SAT, APR 18, 2021",
    date: "This Saturday",
    time: "10 AM PST",
  },
];

function LogHome(props) {
  document.title = "OFish Newsroom";

  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const token = Cookies.get("access_token");
  //   if (token) {
  //     tokenLogin(token);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(accountActions.onAddUserSession(window.location.href));
    loadRelease();
    loadEvents();
  }, []);

  const tokenLogin = token => {
    // e.preventDefault();
    setLoading(true);
    dispatch(accountActions.onLoginWithToken(token)).then(data => {
      if (data && data.data && data.data.success) {
        if (data.data.data.role === "admin") {
          history.push({
            pathname: "/dashboard",
            state: { isLogin: true },
          });
        } else if (
          ["customer", "company_user", "company_admin"].includes(
            data.data.data.role
          )
        ) {
          history.push("/");
        }
      } else {
        if (data && data.data) {
          toast.error(data.data.message);
        } else {
          toast.error("Password is not correct");
        }
      }

      setLoading(false);
    });
  };

  const loadRelease = () => {
    setLoading(true);
    const homeRelease = {
      releaseDate: 0,
      categoryIds: [],
      take: 3,
      skip: 0,
      title: "",
      dataType: ["LatestOnly"],
    };
    dispatch(pubReleaseActions.onGetPubReleases(homeRelease)).then(data => {
      setLoading(false);
    });
  };

  const loadEvents = () => {
    setLoading(true);
    const eventData = {
      eventDate: 0,
      categoryIds: [],
      take: 3,
      skip: 0,
      title: "",
      dataType: ["LatestOnly"],
    };
    dispatch(pubEventActions.onGetPubOnlyEvents(eventData)).then(res => {
      setLoading(false);
    });
  };

  const onDdetail = item => {
    if (item.urlSlug) {
      history.push("/releases/category/" + item.urlSlug);
    } else {
      toast.error("No record found");
    }
  };

  const onEventDdetail = item => {
    if (item.urlSlug) {
      history.push("/events/category/" + item.urlSlug);
    } else {
      toast.error("No record found");
    }
  };

  const { releases } = useSelector(state => state.PubReleaseReducer);
  const { allEvents } = useSelector(state => state.pubEventReducer);

  return (
    <div>
      {isLoading ? <Loader /> : null}

      <LogHeader />
      <MainHeading headerSubHeading='Aquatic AV Newsroom'>
        <div className='publicBody'>
          <Carousel />
        </div>
        <div className='boxMainWrapper'>
          <h2>Latest Announcements</h2>

          <div className='cards'>
            <Grid container spacing={3}>
              {releases?.length > 0 &&
                releases
                  .filter(x => x.status !== "Draft")
                  .slice(0, 3)
                  .map((rel, index) => (
                    <Grid key={index} item md={4}>
                      <Cards
                        picture={
                          rel.imageUrl
                            ? rel.imageUrl
                            : "/static/images/cards/contemplative-reptile.jpg"
                        }
                        subheading={rel.title}
                        cardtext={rel.subTitle}
                        date={moment(rel.preReleaseDate).format(
                          "MMMM, DD, YYYY"
                        )}
                        onClick={() => onDdetail(rel)}
                      />
                    </Grid>
                  ))}
            </Grid>
            {/* <Grid container spacing={3}>
              {data.map((item, index) => (
                <Grid key={index} item md={4}>
                  <Cards
                    picture={item.picture}
                    subheading={item.subheading}
                    cardtext={item.cardtext}
                    date={item.date}
                  />
                </Grid>
              ))}
            </Grid> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
                paddingRight: 15,
              }}>
              <div className='viewAllBtn'>
                <Button
                  color='primary'
                  size='small'
                  onClick={() => history.push("/releases")}
                  // component={Link}
                  // to="/all-latest-announcenments"
                >
                  View All
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className='boxMainWrapper eventsWrapper'>
          <h2>Upcoming Events</h2>

          <div className='cards'>
            <Grid container spacing={3}>
              {allEvents &&
                allEvents.length > 0 &&
                allEvents.map((evnt, index) => (
                  <Grid key={index} item md={4}>
                    <Cards
                      picture={evnt.imageUrl}
                      subheading={evnt.title}
                      cardtext={moment(evnt.startDate).format(
                        "ddd, MMM DD, YYYY"
                      )}
                      time={moment(evnt.startDate).format("h:mm:ss a")}
                      onClick={() => onEventDdetail(evnt)}
                    />
                  </Grid>
                ))}
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
                paddingRight: 15,
              }}>
              <div className='viewAllBtn viewAllNewBtn'>
                <Button
                  color='primary'
                  size='small'
                  onClick={() => history.push("/events")}
                  // component={Link}
                  // to="/all-upcoming-events"
                >
                  View All
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </MainHeading>
    </div>
  );
}

export default LogHome;
