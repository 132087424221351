import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { ShieldCross } from "../../ofishadmin/views/LeftNav/SvgIcons";
import { crossWhite } from "../../resources/images/index";

const GridActionNotification = ({ notificationtext, learnmore, bgcolor }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    isVisible && (
      <div className='gridActionNotificationWrapper'>
        <span style={{ background: `${bgcolor}` }}>
          <ShieldCross /> Note: {notificationtext}
          {/* <Button onClick={learnmore}>Learn More.</Button> */}
          <img src={crossWhite} alt='' onClick={() => setIsVisible(false)} />
        </span>
      </div>
    )
  );
};

export { GridActionNotification };
