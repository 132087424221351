import { pubMediaLibraryTypes } from "./pubMediaLibraryTypes";
import _ from "lodash";

const INITIAL_STATE = {
  errorMessage: "",
  mediaAssetsWithCategory: [],
  pubMediaCategory: [],
  medDateFilter: [],
  selectedPMCategory: [],
  selectedPubMedDateFilter: [],
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case pubMediaLibraryTypes.PUB_MEDIA_LIBRARY_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };

    case pubMediaLibraryTypes.SELECTED_PUB_ML_CATEGORY: {
      const newState = { ...state };
      const medCategory = action.payload;
      const catExist = newState.selectedPMCategory.find(cat => {
        return cat.id == medCategory.id;
      });
      if (catExist && newState.selectedPMCategory.length > 0) {
        _.remove(newState.selectedPMCategory, cate => {
          return cate.id == medCategory.id;
        });
      } else {
        newState.selectedPMCategory.push(medCategory);
      }
      return newState;
    }

    case pubMediaLibraryTypes.SELECTED_PUB_PML_FILTER: {
      const newState = { ...state };
      const medCategory = action.payload;
      const relExist = newState.selectedPubMedDateFilter.find(cat => {
        return cat.id == medCategory.id;
      });
      if (relExist && newState.selectedPubMedDateFilter.length > 0) {
        _.remove(newState.selectedPubMedDateFilter, cate => {
          return cate.id == medCategory.id;
        });
      } else {
        newState.selectedPubMedDateFilter.push(medCategory);
      }
      return newState;
    }

    default:
      return state;
  }
};
