import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashbard from "../Dashbard";
import AllReleaseGrid from "./AllReleaseGrid";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { Pagination } from "../../../../components/common";
import ReleaseGridFilter from "./ReleaseGridFilter";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { ReleaseGridSideFilter } from "./ReleaseGridSideFilter";
import { es } from "date-fns/locale";

function Releases(props) {
  document.title = "OFish - Releases";

  const history = useHistory();
  const redirectUrl = () => {
    history.push("/new-media-release");
  };
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByName, setFilterByName] = useState(null);
  const [selectedCategoryIds, setSelectedCategory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(0);
  const [releasesData, setReleasessData] = useState([]);
  const [pastReleasesData, setPastReleasessData] = useState([]);
  const [upcomingReleasesData, setUpcomingReleasessData] = useState([]);
  const [releaseCategory, setReleaseCategory] = useState([]);
  const [order, setOrderBy] = useState({
    orderByKey: "Title",
    orderVal: 0,
  });

  const loadAllReleases = useCallback(() => {
    setLoading(true);
    dispatch(launchesActions.onAllSelectReleases([]));
    const modal = {
      title: filterByName,
      type:"",
      announcementDate: null,
      category: null,
      status: 0,
      date: selectedDate,
      categoryIds: selectedCategoryIds === null ? [] : selectedCategoryIds,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    dispatch(launchesActions.onGetAllReleases(modal)).then(data => {
      if (data?.success) {
        if (data.data?.data) {
          setReleasessData(data?.data?.data);
          setPaginationResponse(data?.data?.pagination);
          setLoading(false);
          const currentDate = new Date();
          data.data?.data?.foreach(release => {
            const announcementDate = new Date(release.announcementDate);
            if(announcementDate < currentDate){
              setPastReleasessData([...pastReleasesData , release]) 
              console.log({ release })
            }else{
              setUpcomingReleasessData([...upcomingReleasesData , release]) 
            }

          })
        }
      }
      
    });
  }, [
    dispatch,
    page,
    rowsPerPage,
    order.orderByKey,
    order.orderVal,
    filterByName,
    selectedCategoryIds,
    selectedDate,
  ]);

  useEffect(() => {
    loadAllReleases();
    window.scrollTo(0, 0);
  }, [
    page,
    rowsPerPage,
    filterByName,
    selectedCategoryIds,
    order.orderByKey,
    order.orderVal,
    selectedDate,
  ]);

  useEffect(() => {
    dispatch(launchesActions.onGetReleaseCategories()).then(res => {
      if (res && res.success && res.data && res.data.length > 0) {
        const catList = res.data.map((x, index) => {
          return {
            id: x.id,
            value: x.value,
            generic: x.generic,
            checked: false,
          };
        });
        setReleaseCategory(catList);
      }
    });
  }, []);
 
  return (
    <Dashbard>
      <div className='body-content newDesign'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading  launchBtn'>
          <h1>All Releases </h1>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={redirectUrl}>
            New Release
          </Button>
        </div>

        {/* <TopTabNavigator
          tabs={[
            {
              id: 1,
              title: "All Releases",
              component: [
                // <ReleaseGridFilter
                //   Categories={releaseCategory}
                //   setFilterByName={setFilterByName}
                //   setSelectedCategory={setSelectedCategory}
                //   setSelectedDate={setSelectedDate}
                // />,
                <ReleaseGridSideFilter
                Categories={releaseCategory}
                setFilterByName={setFilterByName}
                setSelectedCategory={setSelectedCategory}
                setSelectedDate={setSelectedDate}
                />,
                 <AllReleaseGrid
                  allReleaseData={releasesData}
                  startLoading={() => setLoading(true)}
                  endLoading={() => setLoading(false)}
                  loadData={() => loadAllReleases()}
                  order={order}
                  onOrderBy={setOrderBy}
                />,
                <div style={{width:"100%"}}>
                <Pagination
                  recordCount={paginationResponse?.totalRecords}
                  page={page - 1}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
                </div>
                ,
              ],
            },
            {
              id: 2,
              title: "Upcoming Releases",
            },
            {
              id: 3,
              title: "Past Releases",
            },
            {
              id: 4,
              title: "Release Category",
            },
          ]}
        /> */}
        <div className='gridBody contentBody '>
        <ReleaseGridSideFilter
          Categories={releaseCategory}
          setFilterByName={setFilterByName}
          setSelectedCategory={setSelectedCategory}
          setSelectedDate={setSelectedDate}
        />
        <AllReleaseGrid
          allReleaseData={releasesData}
          startLoading={() => setLoading(true)}
          endLoading={() => setLoading(false)}
          loadData={() => loadAllReleases()}
          order={order}
          onOrderBy={setOrderBy}
        />
        <div style={{ width: "100%" }}>
          <Pagination
            recordCount={paginationResponse?.totalRecords ? paginationResponse?.totalRecords : 0 }
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
        </div>
      </div>
    </Dashbard>
  );
}

export default Releases;
