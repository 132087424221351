import React from "react";
import AddAttributeColorBox from "./AddAttributeColorBox";
import { Button, Grid } from "@material-ui/core";

const data = [
  {
    title: "Spa Front Colors",
    option: "4",
  },
  {
    title: "OEM NAME Spa Color",
    option: "5",
  },
  {
    title: "Remote Color",
    option: "6",
  },
];

function ColorOption({ onclick }) {
  return (
    <>
      <div className='textOptionWrapper'>
        <Grid container spacing={2} xs={12}>
          {data.map((item, index) => {
            return (
              <Grid item xs={6}>
                <AddAttributeColorBox
                  title={item.title}
                  option={item.option}
                  onclick={onclick}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>

      <div className='attributeFooter  attributesTabsfooter'>
        <span>
          <Button color='primary' variant='contained'>
            Next
          </Button>
        </span>
      </div>
    </>
  );
}

export default ColorOption;
