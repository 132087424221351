import _ from "lodash";
import { locationsApi } from "./locationsApi";
import { locationsTypes } from "./locationsTypes";

const onChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: locationsTypes.LOCATIONS_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onGetLocationsList =
  ({
    nameInternal,
    namePublic,
    dateCreated,
    pageSize,
    pageNo,
    orderByKey,
    orderByVal,
  }) =>
  async (dispatch) => {
    let response = await locationsApi.getLocationsList({
      nameInternal,
      namePublic,
      dateCreated,
      pageSize,
      pageNo,
      orderByKey,
      orderByVal,
    });
    if (response && response.success) {
      dispatch({
        type: locationsTypes.LOCATIONS_ONCHANGE,
        payload: { prop: "gridData", value: response.data.data },
      });
      dispatch({
        type: locationsTypes.LOCATIONS_ONCHANGE,
        payload: {
          prop: "paginationResponse",
          value: response.data.pagination,
        },
      });
    } else {
      dispatch({
        type: locationsTypes.LOCATIONS_ONCHANGE,
        payload: { prop: "gridData", value: [] },
      });
      dispatch({
        type: locationsTypes.LOCATIONS_ONCHANGE,
        payload: {
          prop: "paginationResponse",
          value: {
            currentCount: 0,
            totalRecords: 0,
          },
        },
      });
    }
    return response;
  };

const onSelectLocations = (value) => async (dispatch) => {
  dispatch({
    type: locationsTypes.SELECTED_LOCATIONS,
    payload: value,
  });
};

const onAllSelectLocations = (value) => async (dispatch) => {
  dispatch({
    type: locationsTypes.ALL_SELECTED_LOCATIONS,
    payload: [...value],
  });
};

const onDeleteLocations = (selectedLocations) => async (dispatch) => {
  const locationsId = [];
  _.forEach(selectedLocations, function (location) {
    locationsId.push(location.id);
  });
  let response = await locationsApi.deleteLocations(locationsId);
  return response;
};

const onSelectAllLocations = (value) => async (dispatch) => {
  dispatch({
    type: locationsTypes.SELECTED_ALL_LOCATIONS,
    payload: value,
  });
};

const onAddUpdateLocation =
  (
    locationId,
    isDeleted,
    locationNameInternal,
    locationNamePublic,
    locationShortDescription,
    locationStreetAddress,
    locationCountryId,
    locationCityId,
    locationStateId,
    locationPostalCode
  ) =>
  async (dispatch) => {
    let response = await locationsApi.addUpdateLocations(
      locationId,
      isDeleted,
      locationNameInternal,
      locationNamePublic,
      locationShortDescription,
      locationStreetAddress,
      locationCountryId,
      locationCityId,
      locationStateId,
      locationPostalCode
    );
    return response;
  };

const onGetLocation = (id) => async (dispatch) => {
  let response = await locationsApi.getLocationDataById(id);
  return response;
};

export const locationsActions = {
  onChange: onChange,
  onGetLocationsList: onGetLocationsList,
  onSelectLocations: onSelectLocations,
  onDeleteLocations: onDeleteLocations,
  onSelectAllLocations: onSelectAllLocations,
  onAddUpdateLocation: onAddUpdateLocation,
  onGetLocation: onGetLocation,
  onAllSelectLocations:onAllSelectLocations,
};
