import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
// import MuiPhoneInput from "material-ui-phone-number";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  AddressAutoComplete,
  PopupDialog,
  Dropdown,
} from "../../../../../components/common";
import { PasswordConfirm, RedCross, TickMark } from "../../../LeftNav/SvgIcons";
import { Button, Checkbox } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import SelectCompanyImagePopUp from "../../EndUser/SelectCompanyImagePopUp";
import queryString from "query-string";
import { Close } from "@material-ui/icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { endUserActions } from "../../../../../store/endUser/endUserActions";
import { toast } from "react-toastify";
import { contactsTypesActions } from "../../../../../store/contactTypes/contactTypesActions";
import { accountActions } from "../../../../../store/accounts/accountActions";
import { trim } from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
  },
}));
const CompanyUserForm = ({ setIsNewUser, editData, loadUser, setLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [profileImg, setProfileImg] = useState("");
  const location = useLocation();
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const selectedData = queryString.parse(location.search);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [locations, setLocations] = useState([]);
  // const [isUserCreatePassword, setIsUserCreatePassword] = useState(false);
  let matchRegexp =
    "^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$";
  const [addressDetail, setAddressDetail] = useState({
    address: "",
    city: "",
    state: "",
    postalCode: "",
    countryRegion: "",
  });

  const [userDetail, setUserDetail] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNo: "",
    status: 1,
    accessType: "3",
    password: "",
    phoneType: "1",
  });

  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(false);
  const [userCurrentEmail, setUserCurrentEmail] = useState("");

  useEffect(() => {
    if (editData) {
      setLoading(true);
      dispatch(endUserActions.onGetUserById(editData?.id)).then(data => {
        setLoading(false);
        if (data && data.success) {
          setUserDetail({
            firstName: data?.data?.firstName,
            lastName: data?.data?.lastName,
            emailAddress: data?.data?.email,
            phoneNo: data?.data?.phoneNumber,
            accessType: data?.data?.accessType || "3",
            phoneType: data?.data?.phoneType || "1",
            // password: data?.data?.emailAddress, // TODO: Faizan, What is this behaviour PUJA
          });
          setUserCurrentEmail(data?.data?.email);
          setAddressDetail({
            address: data?.data?.address,
            city: data?.data?.cityName,
            state: data?.data?.stateName,
            postalCode: data?.data?.zipCode,
            countryRegion: data?.data?.countryName,
          });
          setProfileImg(data?.data?.image);
        }
      });
    }
  }, [editData]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      contactsTypesActions.onGetStoreLocationsByCompanyId(selectedData?.id)
    ).then(data => {
      setLoading(false);
      if (data?.data && data?.data?.success) {
        setLocations(data?.data?.data);
      }
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    addCompanyUser();
  };

  const addCompanyUser = () => {
    setLoading(true);
    if (parseInt(selectedData?.id) > 0) {
      const modal = {
        email: userDetail?.emailAddress,
        confirmEmail: userDetail?.emailAddress,
        confirmPassword: userDetail?.password,
        id: editData ? editData?.id : 0,
        firstName: userDetail?.firstName,
        lastName: userDetail?.lastName,
        phoneNumber: userDetail?.phoneNo,
        address: addressDetail?.address,
        city: addressDetail?.city,
        country: addressDetail?.countryRegion,
        state: addressDetail?.state,
        zipCode: addressDetail?.postalCode,
        groupId: 0,
        contactTypeId: null,
        customerTypeName: userDetail?.firstName,
        companyId: selectedData?.id,
        isFavourite: true,
        image: profileImg,
        rating: "1",
        latitude: "1",
        longitude: "1",
        type: "1",
        policies: [],
        userType: userDetail?.accessType,
        cityName: addressDetail?.city,
        stateName: addressDetail?.state,
        countryName: addressDetail?.countryRegion,
        workEmailAddress: userDetail?.emailAddress,
        accessType: userDetail?.accessType,
        addressType: "1",
        postalCode: addressDetail?.postalCode,
        phoneType: userDetail?.phoneType,
        // isUserCreatePassword: isUserCreatePassword,
      };
      if (editData?.id) {
        dispatch(contactsActions.onUpdateUser(modal)).then(data => {
          setLoading(false);
          if (data?.success) {
            loadUser();
            setIsNewUser();
          } else {
            toast.error(data?.message);
          }
        });
      } else {
        modal.password = userDetail?.password;
        dispatch(contactsActions.onAddCustomer(modal)).then(data => {
          setLoading(false);
          if (data?.success) {
            loadUser();
            setIsNewUser();
          } else {
            toast.error(data?.message);
          }
        });
      }
    }
  };

  const uploadProfileImage = file => {
    setLoading(true);
    dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
      if (data && data.success && data.data) {
        setProfileImg(data.data.url);
        setShowFeatureImage(false);
      }
      setLoading(false);
    });
  };

  const onClose = () => {
    setPassword("");
    setConfirmPassword("");
    setIsPasswordReset(false);
  };

  const onConfirmPasswordClose = () => {
    setIsPasswordConfirm(false);
  };

  const changePassword = () => {
    if (
      password !== "" &&
      password === confirmPassword &&
      password.match(matchRegexp)
    ) {
      setLoading(true);
      const modal = {
        email: userCurrentEmail,
        password: password,
        confirmPassword: confirmPassword,
      };
      dispatch(endUserActions.onResetPassword(modal)).then(data => {
        setLoading(false);
        if (data && data.success) {
          setIsPasswordConfirm(true);
          onClose();
        } else {
          toast.error(data?.message);
        }
      });
    } else {
      toast.error(
        `${
          password === ""
            ? "Please enter the password"
            : password !== confirmPassword
            ? "password and confirmPassword are not match"
            : "Your password must contain at least 1 uppercase, lowercase, number and special character"
        }`
      );
    }
  };

  const SendPasswordInEmail = () => {
    setLoading(true);
    let email = userDetail?.emailAddress;
    dispatch(accountActions.onForgotPassword(email)).then(data => {
      setLoading(false);
      if (data?.data && data?.data?.success) {
        toast.success(data?.data?.data?.message);
        onClose();
      } else {
        toast.error(data?.message);
      }
    });
  };

  return (
    <ValidatorForm
      ref={useRef("userForm")}
      onSubmit={handleSubmit}
      className='locationFormWrapper'>
      <span className='thumbIcon tickMarkCross businessAddress locationFormAction'>
        <span
          onClick={() => {
            setIsNewUser();
          }}>
          <RedCross />
        </span>
        <Button type='submit'>
          <TickMark />
        </Button>
      </span>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridWrapper}>
          <h2 class='innerHeading'>User Information</h2>
          <Grid container spacing={3}>
            <Grid
              item
              xs={6}
              className={(classes.gridWrapper, "requiredField")}>
              <InputField
                placeholder='Name'
                inputlabel='First Name'
                className={classes.gridWrapper}
                value={userDetail?.firstName}
                showRequired={true}
                onChange={v =>
                  setUserDetail({
                    ...userDetail,
                    firstName: trim(v.target.value),
                  })
                }
                validators={["required", "matchRegexp:^.{0,50}$"]}
                errorMessages={[
                  "This field is required",
                  "Maximum length is 50 characters",
                ]}
              />
            </Grid>

            <Grid
              item
              xs={6}
              className={(classes.gridWrapper, "requiredField")}>
              <InputField
                placeholder='Lastname'
                inputlabel='Last Name'
                className={classes.gridWrapper}
                value={userDetail?.lastName}
                showRequired={true}
                onChange={v =>
                  setUserDetail({
                    ...userDetail,
                    lastName: trim(v.target.value),
                  })
                }
                validators={["required", "matchRegexp:^.{0,50}$"]}
                errorMessages={[
                  "This field is required",
                  "Maximum length is 50 characters",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={(classes.gridWrapper, "requiredField")}>
              <InputField
                placeholder='working@address.com'
                inputlabel='Work E-mail Address'
                className={classes.gridWrapper}
                value={userDetail?.emailAddress}
                showRequired={true}
                onChange={v =>
                  setUserDetail({
                    ...userDetail,
                    emailAddress: trim(v.target.value),
                  })
                }
                validators={["required", "isEmail"]}
                errorMessages={[
                  "Please enter your email address",
                  "Please enter a valid email address",
                ]}
              />
            </Grid>

            <Grid item xs={6} className={classes.gridWrapper}>
              <Dropdown
                selectlabel='Role/Access Type'
                placeholder='Please Select'
                value={`${userDetail?.accessType}`}
                showRequired
                customMenuItems={[
                  { id: "3", name: "Admin" },
                  { id: "4", name: "Limited" },
                ]}
                handleChange={v =>
                  setUserDetail({
                    ...userDetail,
                    accessType: v.target.value,
                  })
                }
              />
            </Grid>

            <Grid
              item
              xs={6}
              className={(classes.gridWrapper, "changePassword")}>
              <InputField
                placeholder='Password'
                inputlabel='Password'
                // disabled={isUserCreatePassword || editData}
                className={classes.gridWrapper}
                required={false}
                value={editData ? "*****" : userDetail?.password}
                onChange={v =>
                  setUserDetail({
                    ...userDetail,
                    password: trim(v.target.value),
                  })
                }
              />
              <Button
                color='primary'
                variant='contained'
                disabled={editData === undefined ? true : false}
                onClick={() => {
                  if (editData?.id) {
                    setIsPasswordReset(true);
                  }
                }}>
                Change
              </Button>
            </Grid>
            {/* {editData === undefined && (
              <Grid item xs={9} style={{ paddingTop: "0", paddingLeft: "0" }}>
                <span className='companyAddressCheckbox'>
                  <Checkbox
                    checked={isUserCreatePassword}
                    onChange={v => {
                      setIsUserCreatePassword(!isUserCreatePassword);
                    }}
                  />
                  Let end user create their own password
                </span>
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </Grid>
      <div className='gap' />
      <div className='gap' />
      <Grid item xs={6} className={classes.gridWrapper}>
        <h2 className='innerHeading'>Contact Information</h2>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.gridWrapper}>
          <AddressAutoComplete
            autoComId={"popupCompanyUserInput"}
            address={addressDetail?.address}
            onChange={e => {
              setAddressDetail({
                ...addressDetail,
                address: e.target.value,
              });
            }}
            setPlace={p => {
              let address = "";
              let city = "";
              let state = "";
              let countryRegion = "";
              let postalCode = "";
              p?.address_components?.forEach(x => {
                if (x?.types?.includes("route")) {
                  address = x?.long_name;
                }
                if (x?.types?.includes("locality")) {
                  city = x?.long_name;
                }
                if (x?.types?.includes("administrative_area_level_1")) {
                  state = x?.long_name;
                }
                if (x?.types?.includes("country")) {
                  countryRegion = x?.long_name;
                }
                if (x?.types?.includes("postal_code")) {
                  postalCode = x?.long_name;
                }
              });
              setAddressDetail({
                ...addressDetail,
                address: address,
                city: city,
                state: state,
                countryRegion: countryRegion,
                postalCode: postalCode,
              });
            }}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridWrapper}>
          <InputField
            placeholder=''
            inputlabel='City'
            className={classes.gridWrapper}
            showRequired={true}
            value={addressDetail?.city}
            onChange={v =>
              setAddressDetail({
                ...addressDetail,
                city: v.target.value,
              })
            }
            validators={["required", "matchRegexp:^.{0,200}$"]}
            errorMessages={[
              "This field is required",
              "Maximum length is 200 characters",
            ]}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridWrapper}>
          <InputField
            placeholder=''
            inputlabel='State'
            className={classes.gridWrapper}
            showRequired={true}
            value={addressDetail?.state}
            onChange={v =>
              setAddressDetail({
                ...addressDetail,
                state: v.target.value,
              })
            }
            validators={["required", "matchRegexp:^.{0,200}$"]}
            errorMessages={[
              "This field is required",
              "Maximum length is 200 characters",
            ]}
          />
        </Grid>

        <Grid item xs={2} className={classes.gridWrapper}>
          <InputField
            placeholder=''
            inputlabel='Zip code'
            className={classes.gridWrapper}
            showRequired={true}
            value={addressDetail?.postalCode}
            onChange={v =>
              setAddressDetail({
                ...addressDetail,
                postalCode: v.target.value,
              })
            }
            validators={["required", "matchRegexp:^.{0,200}$"]}
            errorMessages={[
              "This field is required",
              "Maximum length is 200 characters",
            ]}
          />
        </Grid>
        {/* <Grid item xs={3} className={classes.gridWrapper}>
            <Dropdown
              selectlabel='Same As'
              placeholder='Please Select'
              // value={isAddress}
              required={true}
              showRequired
              customMenuItems={locations?.map((c, i) => {
                return { id: i, name: c.address };
              })}
              handleChange={e => {
                var val = locations.at(e.target.value);
                setAddressDetail({
                  address: val?.address,
                  city: val?.city,
                  state: val?.state,
                  postalCode: val?.zipCode,
                  countryRegion: val?.data?.country,
                });
              }}
            />
          </Grid> */}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={3} className={(classes.gridWrapper, "requiredField")}>
          {/* <Dropdown
              selectlabel='Country'
              placeholder='Please Select'
              value={addressDetail?.countryRegion}
              required={false}
              // showRequired
              // customMenuItems={addressDetail?.map(c => {
              //   return {
              //     id: parseInt(c.id),
              //     name: c.value,
              //   };
              // })}
              handleChange={v =>
                setAddressDetail({
                  ...addressDetail,
                  countryRegion: v.target.value,
                })
              }
            /> */}
          <InputField
            placeholder=''
            inputlabel='Country/Region'
            className={classes.gridWrapper}
            showRequired={true}
            value={addressDetail?.countryRegion}
            onChange={v =>
              setAddressDetail({
                ...addressDetail,
                countryRegion: v.target.value,
              })
            }
            validators={["required", "matchRegexp:^.{0,50}$"]}
            errorMessages={[
              "This field is required",
              "Maximum length is 50 characters",
            ]}
          />
        </Grid>

        <Grid item xs={6} className={classes.gridWrapper}>
          <InputField
            placeholder='Enter Main Phone Number'
            inputlabel='Phone Number'
            type='number'
            className={classes.input}
            value={userDetail?.phoneNo}
            onChange={v =>
              setUserDetail({
                ...userDetail,
                phoneNo: v.target.value,
              })
            }
            inputProps={{ min: 0 }}
          />
        </Grid>

        <Grid item xs={3} className={(classes.gridWrapper, "requiredField")}>
          <Dropdown
            selectlabel='Phone Number Type'
            placeholder='Please Select'
            value={`${userDetail?.phoneType}`}
            customMenuItems={[
              { id: "1", name: "Mobile" },
              { id: "2", name: "Home" },
            ]}
            handleChange={v =>
              setUserDetail({
                ...userDetail,
                phoneType: v.target.value,
              })
            }
          />
        </Grid>

        {/* <Grid item xs={7} className={classes.gridWrapper}>
            <InputField
              placeholder='Enter a Company Website'
              inputlabel='Location Website'
              className={classes.gridWrapper}
              value={locationDetail?.website}
              onChange={v =>
                setLocationDetail({
                  ...locationDetail,
                  website: v.target.value,
                })
              }
            />
          </Grid> */}
      </Grid>

      {/* <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item xs={3} className={(classes.gridWrapper, "requiredField")}>
            <InputField
              placeholder=''
              inputlabel='Country/Region'
              className={classes.gridWrapper}
              showRequired={true}
              value={addressDetail?.countryRegion}
              onChange={v =>
                setAddressDetail({
                  ...addressDetail,
                  countryRegion: v.target.value,
                })
              }
              validators={["required", "matchRegexp:^.{0,50}$"]}
              errorMessages={[
                "This field is required",
                "Maximum length is 50 characters",
              ]}
            />
          </Grid>
        </Grid> */}

      <div className='gap' />
      <Grid item xs={8} className={(classes.gridWrapper, "addAttribute")}>
        <h3>
          Attributes
          <span>
            <Button color='primary' variant='outlined'>
              +
            </Button>
          </span>
        </h3>

        <div className='collectionLabelsWrapper'>
          <span className='collectionLabels'>
            AAV Resellers &nbsp; <i>Dealer Locator</i>
          </span>
        </div>
        <p>
          No Attributes have been added to this location. Add Attributes to sort
          this location within filters
        </p>
      </Grid>
      {showFeatureImage && (
        <SelectCompanyImagePopUp
          closePopup={() => setShowFeatureImage(false)}
          openPopup={showFeatureImage}
          title={"Upload profile image"}
          addProfileImage={value => uploadProfileImage(value)}
        />
      )}

      {isPasswordReset && (
        <PopupDialog
          visible={isPasswordReset}
          onClose={onClose}
          maxWidth='800px'>
          <div className='passwordResetWrapper'>
            <div className='passwordResetHeader'>
              <span className='innerHeading'>Reset End User Password</span>
              <Close
                style={{
                  cursor: "pointer",
                }}
                onClick={() => onClose()}
              />
            </div>
            <div className='resetPasswordInstructions'>
              <p>Are you sure you want to reset End User Name’s password?</p>
              <i>
                Make sure you’re in touch with them about your actions. They can
                always reset their password anytime via e-mail.
              </i>
            </div>
            <Grid container spacing={2} className='resetPasswordForm'>
              <Grid item xs={5} className={classes.gridWrapper}>
                <InputField
                  placeholder='Password'
                  inputlabel='Password'
                  className={classes.gridWrapper}
                  value={password}
                  onChange={v => setPassword(v.target.value)}
                />
              </Grid>
              <Grid item xs={5} className={classes.gridWrapper}>
                <InputField
                  placeholder='Confirm Password'
                  inputlabel='Confirm Password'
                  className={classes.gridWrapper}
                  value={confirmPassword}
                  onChange={v => setConfirmPassword(v.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={(classes.gridWrapper, "resetPasswordBtn")}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    changePassword();
                  }}>
                  Set Password
                </Button>
              </Grid>
            </Grid>

            <span className='resetPasswordInstructions'>
              <p>
                Want to play it safer? Have End User Name reset their password.
              </p>
              <i>
                Allow the end user to reset their own password by sending a
                Password Reset E-mail to End User Name.
              </i>

              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  SendPasswordInEmail();
                }}>
                Reset Password Via E-Mail
              </Button>
            </span>
          </div>
        </PopupDialog>
      )}

      {isPasswordConfirm && (
        <PopupDialog
          visible={isPasswordConfirm}
          onClose={onConfirmPasswordClose}
          maxWidth='800px'>
          <div className='passwordResetWrapper'>
            <div className='passwordResetHeader'>
              <span className='innerHeading'>Password Reset Confirmation</span>
              <Close
                style={{
                  cursor: "pointer",
                }}
                onClick={() => onConfirmPasswordClose()}
              />
            </div>
            <div className='resetPasswordInstructions passwordConfirmWrapper'>
              <span>
                <p>
                  We let End User Name know via e-mail their password has been
                  reset.
                </p>
                <i
                  style={{
                    paddingTop: "5px",
                    display: "inline-block",
                    marginBottom: "0",
                  }}>
                  Make sure you’re in touch with them about your actions. They
                  can always reset their password anytime via e-mail.
                </i>
              </span>
              <span>
                <PasswordConfirm />{" "}
              </span>
            </div>

            <span className='confirmPasswordInstructions'>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setIsPasswordConfirm(false);
                }}>
                Done
              </Button>
            </span>
          </div>
        </PopupDialog>
      )}
    </ValidatorForm>
  );
};

export default CompanyUserForm;
