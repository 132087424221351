import React from "react";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import GeneralHeading from "../../../../components/common/GeneralHeading";
function Attendance(props) {
  return (
    <>
      <GeneralHeading
        headingName="New Event Category"
        buttonText="Save Category"
      />
        <div className="eventOverviewWrapper">
        <AccordionContainer title="Attendance"></AccordionContainer>
        </div>
     
    </>
  );
}

export default Attendance;
