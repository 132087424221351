import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, FormControl } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { ImageAutoPlaySwipeable } from "../../../../components/common";
import EmptyTextarea from "../../../../components/common/Textarea";
import InputField from "../../../../components/common/InputField";
import Loader from "../../../../components/common/Loader";
import SelectInput from "../../../../components/common/SelectInput";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { AdornmentInputField } from "../../../../components/common/AdornmentInputField";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import { ProductMediaListing } from "./ProductMediaListing";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { useDropdownValues } from "../../../../utils/use-dropdown-values";
import { endpoints } from "../../../../config/apiConfig";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
}));

const ProductOverview = ({ activeProductId, onProductLoad , existinglaunch }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [product, setProduct] = useState({
    brandId: 1,
    launchDate: new Date(),
  });
  const [, setCategoryId] = useState(0);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [relatedLaunches, setRelatedLaunches] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);

  const sepProductProp = ({ propName, value }) => {
    setProduct({
      ...product,
      [propName]: value,
    });
  };
  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllBrands()).then(data =>
      setBrands(data?.data)
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllProductSubCategories(0)).then(
      data => setCategories(data?.data)
    );
  }, [dispatch]);

  const { dropdownValues: prductTypesDD } = useDropdownValues({
    url: endpoints.generic.getProductTypeDropdown,
  });

  useEffect(() => {
    dispatch(
      productCategoriesActions.onGetAllProductSubCategories(
        product?.productCategoryId
      )
    ).then(data => setSubCategories(data?.data));
  }, [dispatch, product?.productCategoryId]);

  useEffect(() => {
    dispatch(launchesActions.onGetDropDownLaunches()).then(data => {
      if (data?.success && data?.data) {
        setRelatedLaunches(data?.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (!activeProductId) return;
    setLoading(true);
    dispatch(productInventoryActions.getProductById(activeProductId)).then(
      data => {
        setLoading(false);
        if (!data) return;
        const { data: product } = data;
        console.log({ product })
        if (product) {
          setProduct({ ...product, launchDate: product.launchDate || null });
          setCategoryId(product.productCategoryId);
        }
      }
    );
    return () => {
      setProduct(null);
    };
  }, [dispatch, activeProductId]);

  useEffect(() => {
    if (product?.id > 0 && onProductLoad)
      onProductLoad({
        id: product?.id,
        productName: product?.productName,
        productSKU: product?.productSKU,
        isSerialized: product?.isSerialized,
        featureImage: product?.featureImage,
        userFavouriteProducts: product?.userFavouriteProducts,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.id]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!product?.productType && !product.productCategoryId) {
      toast.warn("Please select the product type and category.");
      return;
    } else if (!product?.productType) {
      toast.warn("Please select the product type.");
      return;
    } else if (!product?.productCategoryId) {
      toast.warn("Please select the product category.");
      return;
    }
    setLoading(true);
    // const fileName = featureImgUpload?.url;
    // fileName = fileName ? last(fileName.split("/")) : "";
    const newProduct = {
      ...product,
      productId: product ? product.id : 0,
      productCategoryId: product?.productCategoryId || 0,
      productSubCategoryId: product?.productSubCategoryId || 0,
      status: product?.status || 0,
      launchId: existinglaunch?.id ? existinglaunch?.id :  product?.launchId || "1",
      launchDate: product?.launchDate
        ? moment(product?.launchDate).format("YYYY/MM/DD")
        : null,
    };
    dispatch(productInventoryActions.onAddUpdateProduct(newProduct)).then(
      data => {
        if (data && data.success) {
          history.goBack();
        } else if (!data?.success) {
          toast.error(data.message);
        }
        setLoading(false);
      }
    );
  };
  console.log({ existinglaunch })
  return (
    <div className={`productsInverntory`}>
      {isLoading ? <Loader /> : null}
      <ValidatorForm onSubmit={handleSubmit}>
        <AccordionContainer
          title='Product Overview'
          formButton={
            <Button
              color='primary'
              variant='contained'
              className='dashboardButtons'
              type='submit'>
              Save Product
            </Button>
          }>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={3}>
              <Grid container spacing={3}>
                {mediaFiles.length > 0 && (
                  <Grid
                    item
                    xs={3}
                    style={{
                      marginTop: "26px",
                    }}>
                    <ImageAutoPlaySwipeable images={mediaFiles} />
                  </Grid>
                )}
                <Grid item xs={mediaFiles.length > 0 ? 9 : 12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <SelectInput
                        showRequired={true}
                        selectlabel='Brand'
                        placeholder='Please Select'
                        customMenuItems={brands?.map(b => {
                          return {
                            id: b.brandId,
                            name: b.brandName,
                          };
                        })}
                        required={true}
                        value={product?.brandId}
                        handleChange={({ target }) =>
                          sepProductProp({
                            propName: "brandId",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <InputField
                        showRequired={true}
                        inputlabel='Product SKU'
                        id='ProductSKU'
                        name='productSKU'
                        validators={["required", "matchRegexp:^.{0,50}$"]}
                        errorMessages={[
                          "Please enter product sku",
                          "Maximum length is 50 characters",
                        ]}
                        value={product?.productSKU || ""}
                        onChange={({ target }) =>
                          sepProductProp({
                            propName: "productSKU",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <InputField
                        showRequired={true}
                        inputlabel='Product Slug'
                        id='ProductSlug'
                        name='ProductSlug'
                        validators={["required", "matchRegexp:^.{0,50}$"]}
                        errorMessages={[
                          "Please enter product slug",
                          "Maximum length is 50 characters",
                        ]}
                        value={product?.productSlug || ""}
                        onChange={({ target }) =>
                          sepProductProp({
                            propName: "productSlug",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={8}
                      className={(classes.gridWrapper, "productNamefield")}>
                      <InputField
                        showRequired={true}
                        inputlabel='Product Name'
                        id='productName'
                        name='productName'
                        validators={["required", "matchRegexp:^.{0,100}$"]}
                        errorMessages={[
                          "Please enter category name",
                          "Maximum length is 100 characters",
                        ]}
                        value={product?.productName || ""}
                        onChange={({ target }) =>
                          sepProductProp({
                            propName: "productName",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className={classes.gridWrapper}
                      style={{ marginTop: "9px" }}>
                      <SelectInput
                        selectlabel='Related Launch'
                        placeholder='Please Select'
                        value={ existinglaunch?.id ? existinglaunch?.id : product?.launchId }
                        required={true}
                        customMenuItems={relatedLaunches?.map(c => {
                          return {
                            id: c.id,
                            name: c.value,
                          };
                        })}
                        handleChange={({ target }) =>
                          sepProductProp({
                            propName: "launchId",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={(classes.gridWrapper, "shortDescription")}>
                    <EmptyTextarea
                      textLabel='Short Description'
                      textSubtitle='(500 characters max)'
                      maxLength={500}
                      value={product?.shortDescription || ""}
                      onChange={({ target }) =>
                        sepProductProp({
                          propName: "shortDescription",
                          value: target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3} className={classes.gridWrapper}>
                <SelectInput
                  selectlabel='Status'
                  required={false}
                  value={product?.status || 0}
                  customMenuItems={[
                    { id: 0, name: "Inactive" },
                    { id: 1, name: "Active" },
                  ]}
                  handleChange={({ target }) =>
                    sepProductProp({
                      propName: "status",
                      value: target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.gridWrapper}>
                <SelectInput
                  showRequired={true}
                  selectlabel='Product Type'
                  placeholder='Please Select'
                  required={true}
                  value={product?.productType || 0}
                  customMenuItems={prductTypesDD?.map(c => {
                    return {
                      id: c.key,
                      name: c.value,
                    };
                  })}
                  handleChange={({ target }) =>
                    sepProductProp({
                      propName: "productType",
                      value: target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.gridWrapper}>
                <SelectInput
                  showRequired={true}
                  selectlabel='Product Category'
                  placeholder='Please Select'
                  value={product?.productCategoryId || 0}
                  required={true}
                  customMenuItems={categories?.map(c => {
                    return {
                      id: c.id,
                      name: c.value,
                    };
                  })}
                  handleChange={({ target }) =>
                    sepProductProp({
                      propName: "productCategoryId",
                      value: target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.gridWrapper}>
                <SelectInput
                  selectlabel='Product Sub-Category'
                  placeholder='Please Select'
                  value={product?.productSubCategoryId || 0}
                  required={false}
                  customMenuItems={subCategories?.map(c => {
                    return {
                      id: c.id,
                      name: c.value,
                    };
                  })}
                  handleChange={({ target }) =>
                    sepProductProp({
                      propName: "productSubCategoryId",
                      value: target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={3} className={classes.gridWrapper}>
                <SelectInput
                  selectlabel='Serialized'
                  required={false}
                  value={product?.isSerialized || 0}
                  customMenuItems={[
                    { id: 0, name: "No" },
                    { id: 1, name: "Yes, created once received" },
                  ]}
                  handleChange={({ target }) => {
                    sepProductProp({
                      propName: "isSerialized",
                      value: target.value,
                    });
                    onProductLoad({
                      id: product?.id,
                      productName: product?.productName,
                      productSKU: product?.productSKU,
                      isSerialized: target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={3} className={classes.gridWrapper}>
                <InputField
                  inputlabel='Product Registration'
                  id='ProductRegistration'
                  name='ProductRegistration'
                  // validators={["required"]}
                  // errorMessages={["Please enter category name"]}
                  value={product?.productionRegistration || ""}
                  onChange={({ target }) =>
                    sepProductProp({
                      propName: "productionRegistration",
                      value: target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.gridWrapper}>
                <SelectInput
                  selectlabel='Store Availability'
                  required={false}
                  value={product?.storeAvailability || 0}
                  customMenuItems={[
                    { id: 0, name: "Not Available" },
                    { id: 1, name: "Available" },
                  ]}
                  handleChange={({ target }) =>
                    sepProductProp({
                      propName: "storeAvailability",
                      value: target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={3} className={classes.gridWrapper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className='inputWrapper'>
                    <FormControl className={classes.formControl}>
                      <label>{"Launch Date"}</label>
                      <DatePicker
                        autoOk
                        placeholder='Please Select'
                        format='MMMM dd, yyyy'
                        value={product?.launchDate}
                        onChange={value => {
                          sepProductProp({
                            propName: "launchDate",
                            value: value,
                          });
                        }}
                      />
                    </FormControl>
                  </div>
                </MuiPickersUtilsProvider>
              </Grid>

              {/* <Grid
                item
                xs={4}
                className={(classes.gridWrapper, classes.featuredImage)}>
                <label className={classes.featuredImageHeading}>
                  Featured Image
                </label>
                {(featureImgUpload?.url || product?.featureImage) && (
                  <img
                    alt='feature iamge'
                    className='featureRoundImg'
                    src={
                      featureImgUpload?.url ||
                      (product?.featureImage &&
                        `https://aavazurestorage.blob.core.windows.net/ofishassetsstaging/${product?.featureImage}`)
                    }
                  />
                )}
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.selectBtn}
                  onClick={() => setShowFeatureImage(!showFeatureImage)}>
                  Select
                </Button>
                <span className={classes.selectSubtitle}>
                  Select a featured image from your media library.
                </span>

                <ImageUploader
                  showFeatureImage={showFeatureImage}
                  setShowFeatureImage={() =>
                    setShowFeatureImage(!showFeatureImage)
                  }
                  onImageSelect={img => setFeatureImgUpload(img)}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </AccordionContainer>
        <AccordionContainer title='Product Details'>
          <div className='productPackingWrap'>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Grid item xs={12} className={classes.gridWrapper}>
                  <Grid
                    item
                    xs={12}
                    className={`${classes.gridWrapper} blue-title`}>
                    Single Product
                  </Grid>

                  <Grid container spacing={3} className={classes.gridWrapper}>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <SelectInput
                        selectlabel='Packing Type'
                        required={false}
                        customMenuItems={[
                          { id: "Giftbox Packing", name: "Giftbox Packing" },
                          { id: "Regular Packing", name: "Regular Packing" },
                        ]}
                        value={product?.sPackagingType || ""}
                        handleChange={({ target }) =>
                          sepProductProp({
                            propName: "sPackagingType",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <AdornmentInputField
                        inputlabel='Product Weight'
                        unit='lbs'
                        value={product?.sProductWeight || ""}
                        onChange={({ target }) =>
                          sepProductProp({
                            propName: "sProductWeight",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container item className={classes.gridWrapper}>
                    <label>{`Product Dimensions`}</label>
                    <Grid container spacing={3}>
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <AdornmentInputField
                          unit='in'
                          subtitle='Length'
                          value={product?.sProductDimensionLength || ""}
                          onChange={({ target }) =>
                            sepProductProp({
                              propName: "sProductDimensionLength",
                              value: target.value,
                            })
                          }
                        />
                      </Grid>
                      <span className='xMultiplication'>X</span>
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <AdornmentInputField
                          unit='in'
                          subtitle='Width'
                          value={product?.sProductDimensionWidth || ""}
                          onChange={({ target }) =>
                            sepProductProp({
                              propName: "sProductDimensionWidth",
                              value: target.value,
                            })
                          }
                        />
                      </Grid>
                      <span className='xMultiplication'>X</span>
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <AdornmentInputField
                          unit='in'
                          subtitle='Height'
                          value={product?.sProductDimensionHeight || ""}
                          onChange={({ target }) =>
                            sepProductProp({
                              propName: "sProductDimensionHeight",
                              value: target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridWrapper}>
                  <div className='product-packing'></div>
                  <div style={{ textAlign: "center", marginTop: "15px" }}>
                    Single Product
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12} className={classes.gridWrapper}>
                  <Grid
                    item
                    xs={12}
                    className={`${classes.gridWrapper} blue-title`}>
                    Master Carton
                  </Grid>

                  <Grid container spacing={3} className={classes.gridWrapper}>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <SelectInput
                        selectlabel='Packing Type'
                        required={false}
                        customMenuItems={[
                          { id: "Bulk Packing", name: "Bulk Packing" },
                        ]}
                        value={product?.mPackagingType || ""}
                        handleChange={({ target }) =>
                          sepProductProp({
                            propName: "mPackagingType",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <AdornmentInputField
                        inputlabel='No. per master carton'
                        unit='units'
                        value={product?.noPerMasterCarton || ""}
                        onChange={({ target }) =>
                          sepProductProp({
                            propName: "noPerMasterCarton",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container item className={classes.gridWrapper}>
                    <label>{`Master Carton Dimensions`}</label>
                    <Grid container spacing={3}>
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <AdornmentInputField
                          unit='in'
                          subtitle='Length'
                          value={product?.mProductDimensionLength || ""}
                          onChange={({ target }) =>
                            sepProductProp({
                              propName: "mProductDimensionLength",
                              value: target.value,
                            })
                          }
                        />
                      </Grid>
                      <span className='xMultiplication'>X</span>
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <AdornmentInputField
                          unit='in'
                          subtitle='Width'
                          value={product?.mProductDimensionWidth || ""}
                          onChange={({ target }) =>
                            sepProductProp({
                              propName: "mProductDimensionWidth",
                              value: target.value,
                            })
                          }
                        />
                      </Grid>
                      <span className='xMultiplication'>X</span>
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <AdornmentInputField
                          unit='in'
                          subtitle='Height'
                          value={product?.mProductDimensionHeight || ""}
                          onChange={({ target }) =>
                            sepProductProp({
                              propName: "mProductDimensionHeight",
                              value: target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridWrapper}>
                  <div className='product-packing'></div>
                  <div style={{ textAlign: "center", marginTop: "15px" }}>
                    Master Carton
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </AccordionContainer>
        <ProductMediaListing
          productId={product?.id}
          onProductMediaLoad={mediaFiles => {
            if (mediaFiles.length > 0)
              setMediaFiles(
                mediaFiles.map(m => {
                  return {
                    // imgPath: `https://aavazurestorage.blob.core.windows.net/ofishassetsstaging/${m?.fileName}`
                    imgPath: m?.fileName,
                  };
                })
              );
          }}
        />
      </ValidatorForm>
    </div>
  );
};

export { ProductOverview };
