import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { endUserActions } from "../../../../../store/endUser/endUserActions";
import { toast } from "react-toastify";
import { ColorPicker } from "../../../../../components/common";

function FavoriteColor({
  loadData,
  startLoading,
  stopLoading,
  isActive,
  isEditColor,
  setIsEditColor,
}) {
  const dispatch = useDispatch();
  const { deviceOverviewInfo } = useSelector(state => state.endUserReducer);
  useEffect(() => {
    if (isActive) {
      let colorArray = [];
      let remainingColor = 25;
      if (deviceOverviewInfo) {
        if (
          deviceOverviewInfo?.colors &&
          deviceOverviewInfo?.colors.length > 0
        ) {
          deviceOverviewInfo?.colors.forEach(colr => {
            colorArray.push(colr);
          });
          remainingColor = 25 - deviceOverviewInfo?.colors.length;
        }
        if (remainingColor > 0) {
          for (let step = 0; step < remainingColor; step++) {
            colorArray.push("#c4c4c4");
          }
        }
        setColorList(colorArray);
      }
    }
  }, [isActive]);

  const onColorChange = (colorCode, item) => {
    if (item && item.colorId) {
      updateColor(item.colorId, colorCode);
    } else {
      addColor(colorCode);
    }
  };
  const addColor = clrCode => {
    startLoading();
    dispatch(
      endUserActions.onAddDeviceColor(deviceOverviewInfo?.id, clrCode)
    ).then(data => {
      stopLoading();
      if (data && data.success) {
        loadData();
        toast.success("Color added successfully");
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };
  const updateColor = (clrId, clrCode) => {
    startLoading();
    dispatch(endUserActions.onUpdateDeviceColor(clrId, clrCode)).then(data => {
      stopLoading();
      if (data && data.success) {
        loadData();
        toast.success("Color updated successfully");
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };
  const [colorList, setColorList] = useState([]);

  return (
    <div className='deviceColorWrapper'>
      <div className='colorDevicePicture'>
        <img
          className='devicedDtailImageOverView'
          src={deviceOverviewInfo?.image}
          alt=''
        />
      </div>
      <div className='colorPickerContainer'>
        <h1>Favorite Colors</h1>
        <ul>
          {colorList &&
            colorList.length > 0 &&
            colorList.map((item, index) => {
              return (
                <li key={index}>
                  <ColorPicker
                    onColorSelect={colorHex => {
                      onColorChange(colorHex, item);
                    }}
                    saveColor={item.color ? item.color : item}
                    edit={isEditColor}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}

export default FavoriteColor;
