import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import {
  ChartDownloadIcon,
  ChartAddIcon,
  ChartEditIcon,
} from "../SpaProduct/SvgIcons";
import { WaterReplaceTableBanner } from "./WaterReplaceTableBanner";
import { useDispatch, useSelector } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { Dropdown, InputField } from "../../../../components/common";
import { CancleIcon, TickIcon } from "../../LeftNav/SvgIcons";
import { toast } from "react-toastify";
import CSVDownloadButton from "./CSVDownloadButton";
import moment from "moment";

export const FilterLife = ({ deviceId ,filterStats}) => {

  const [addNewItem, setAddNewItem] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [testDate, setTestDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [cleaned, setCleaned] = useState(true);
  const [replaced, setReplaced] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getFilterLifeInfo(deviceId);
  }, []);
   
  const handleEdit = () => {
    setEditItem(selectedRow);
    setTestDate(selectedRow?.date == null ? "" : selectedRow?.date);
    setCleaned(selectedRow?.cleaned == null ? true : selectedRow?.cleaned);
    setReplaced(selectedRow?.replaced == null ? false : selectedRow?.replaced);
  };



  const getFilterLifeInfo = (deviceId) => {
    //   setLoading(true);
    dispatch(endUserActions.onGetAllFilterLife(deviceId)).then((data) => {
      if (data?.success) {
        if (data?.data?.length > 0) {
          dispatch(endUserActions.onChange("filterLife", data.data));
        }
      } else {
        dispatch(endUserActions.onChange("filterLife", []));
      }
    });
  };
  const { filterLife } = useSelector((state) => state.endUserReducer);
  const formattedDataFilterLife = filterLife.map((item) => ({
    ...item,
    date: moment(item.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
  }));
  const handleAddUpdateFilter = () => {
    const modal = {
      id: editItem ? editItem?.id : 0,
      deviceId: deviceId,
      testDate: testDate,
      cleaned: cleaned,
      replaced: replaced,
    };
    dispatch(endUserActions.onAddUpdateFilterLife(modal)).then((data) => {
      if (data && data.success) {
        toast.success("Filter Life added successfully");
        dispatch(endUserActions.onGetFilterLifeStats(deviceId));
        getFilterLifeInfo(deviceId);
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
    setAddNewItem(false);
    setEditItem(null);
    setSelectedRow(null);
  };

  const addNewWaterLife = () => {
    setTestDate(moment(Date.now()).format("YYYY-MM-DD"))
    setCleaned(true)
    setReplaced(false)
    setAddNewItem(true);
  }
  return (
    <>
      <div className="waterTreatment">
        <div className="spacingAdjust"></div>
        <WaterReplaceTableBanner filterLifeStats={filterStats} />
        <Box className="chemicalTestTab">
          <div className="buttonsGroupSection">
            <div>
              <span>Your Filters</span>
            </div>
            <div className="buttonsGroup">
              <Button
                variant="contained"
                color="primary"
                className="chartBtns"
                startIcon={<ChartDownloadIcon />}
              >
                <CSVDownloadButton isfilterCleaned={"yes"}stockData={filterLife.map(x => {
                  return {
                    date: x.date,
                    cleaned: x.cleaned ? "Yes" : "No",
                    replaced: x.replaced ? "Yes" : "No"
                  };
                })} />
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="chartBtns"
                onClick={() => {
                  if (selectedRow === null && editItem === null) {
                    addNewWaterLife()
                  } else {
                    return null;
                  }
                }}
                startIcon={<ChartAddIcon />}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="chartBtns"
                disabled={!selectedRow}
                onClick={() => handleEdit()}
                startIcon={<ChartEditIcon />}
              >
                Edit
              </Button>
            </div>
          </div>
          <TableContainer component={Paper} className="chemicaltableContainer">
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeader" align="left">
                    DATE
                  </TableCell>
                  <TableCell className="tableHeader" align="center">
                    FITER CLEANED
                  </TableCell>
                  <TableCell className="tableHeader" align="right">
                    FITER REPLACED
                  </TableCell>
                  {addNewItem && (
                    <TableCell
                      className="tableHeader"
                      align="right"
                    ></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {addNewItem && (
                  <TableRow className="tableCellsList">
                    <TableCell className="tableFirstInput">
                      <InputField
                        value={testDate}
                        type={"date"}
                        onChange={(e) => {
                          if(e.target.value > moment(Date.now()).format("YYYY-MM-DD")){
                            return
                          } else {
                            setTestDate(e.target.value)
                          }}}
                      />
                    </TableCell>
                    <TableCell className="tableSecondInput">
                      <Dropdown
                        selectlabel=""
                        placeholder=""
                        customMenuItems={[
                          { id: true, name: "Yes", value: true },
                          { id: false, name: "No", value: false },
                        ]}
                        value={cleaned}
                        handleChange={({ target }) => {
                          setCleaned(target?.value);
                        }}
                      />
                    </TableCell>
                    <TableCell className="tableThirdInput">
                      <Dropdown
                        selectlabel=""
                        placeholder=""
                        customMenuItems={[
                          { id: true, name: "Yes", value: true },
                          { id: false, name: "No", value: false },
                        ]}
                        value={replaced}
                        handleChange={({ target }) => {
                          setReplaced(target?.value);
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <div style={{ display: "flex", gap: 10 }}>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setAddNewItem(false)}
                        >
                          <CancleIcon />
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleAddUpdateFilter}
                        >
                          <TickIcon />
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {formattedDataFilterLife.map((elm) => (
                  <TableRow
                    key={elm.id}
                    className={
                      elm.id === selectedRow?.id && !editItem
                        ? "selectedRow"
                        : ""
                    }
                    onClick={() =>
                      setSelectedRow((prevSelectedRow) => {
                        if (!addNewItem) {
                          if (prevSelectedRow?.id === elm.id) {
                            return null;
                          } else {
                            return elm;
                          }
                        } else {
                          return null;
                        }
                      })
                    }
                  >
                    <TableCell
                      className="tablerows"
                      scope="row"
                      align="left"
                      width="25%"
                    >
                      <Typography>
                        {" "}
                        {editItem?.id === elm.id ? (
                          <InputField
                            value={testDate}
                            type={"date"}
                            onChange={(e) =>{
                              if(e.target.value > moment(Date.now()).format("YYYY-MM-DD")){
                                return
                              } else {
                                setTestDate(e.target.value)
                              }}}
                          />
                        ) : (
                          moment(elm.date).format("MMMM D, YYYY")
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="tablerows tableSecondInput"
                      align="center"
                      scope="row"
                      width="50%"
                    >
                      {editItem?.id === elm.id ? (
                        <Typography>
                          {" "}
                          <Dropdown
                            selectlabel=""
                            placeholder=""
                            customMenuItems={[
                              { id: true, name: "Yes", value: true },
                              { id: false, name: "No", value: false },
                            ]}
                            value={cleaned}
                            handleChange={({ target }) => {
                              setCleaned(target?.value);
                            }}
                          />
                        </Typography>
                      ) : elm.cleaned === true ? (
                        <Typography>YES</Typography>
                      ) : (
                        <Typography>NO</Typography>
                      )}
                    </TableCell>
                    <TableCell
                      className="tablerows tablerowMerg tableThirdInput"
                      scope="row"
                      align="right"
                      width="25%"
                    >
                      {editItem?.id === elm.id ? (
                        <Typography>
                          <Dropdown
                            selectlabel=""
                            placeholder=""
                            customMenuItems={[
                              { id: true, name: "Yes", value: true },
                              { id: false, name: "No", value: false },
                            ]}
                            value={replaced}
                            handleChange={({ target }) => {
                              setReplaced(target?.value);
                            }}
                          />{" "}
                        </Typography>
                      ) : elm.replaced === true ? (
                        <Typography>Yes</Typography>
                      ) : (
                        <Typography>No</Typography>
                      )}
                    </TableCell>
                    {editItem?.id === elm.id && (
                      <TableCell>
                        <div style={{ display: "flex", gap: 10 }}>
                          <span
                            className="editModel"
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setEditItem(null);
                              setSelectedRow(null);
                            }}
                          >
                            <CancleIcon />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleAddUpdateFilter();
                            }}
                          >
                            <TickIcon />
                          </span>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </>
  );
};
