import React, { useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid, Button, Radio, Input, Checkbox } from "@material-ui/core";
import InputField from "../../../../../components/common/InputField";
import Textarea from "../../../../../components/common/Textarea";
import { Add, Clear, Close, Search } from "@material-ui/icons";
import { concat } from "lodash";
import { AccordionContainer } from "../../../../../components/common";

const data = [];

function CustomizationWrapper({
  name,
  description,
  pressConversation,
  setPressConversation,
  onSectionCompletedButtonPress,
  endUserGroup,
}) {
  return (
    <div>
      <AccordionContainer title={name}>
        <div>
          <span>{description}</span>

          <div className='customisationWrapper'>
            <h3>Conversation Basics</h3>

            <div></div>

            <ValidatorForm ref={useRef("form")} onSubmit={() => {}}>
              <Grid container spacing={3}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item md={4}>
                    <InputField
                      inputlabel='Conversation Type Name (Internal)'
                      placeholder='Press'
                      value={pressConversation.nameInternal}
                      onChange={v =>
                        setPressConversation({
                          ...pressConversation,
                          nameInternal: v.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item md={4}>
                    <InputField
                      inputlabel='Conversation Type Name (Public)'
                      placeholder='Press'
                      value={pressConversation.namePublic}
                      onChange={v =>
                        setPressConversation({
                          ...pressConversation,
                          namePublic: v.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item md={4}>
                    <Grid container className='slugWrapper'>
                      <Grid
                        container
                        item
                        xs={12}
                        style={{ alignItems: "center" }}>
                        <Grid item xs={12}>
                          <label className='slugLabel'>Category color</label>
                        </Grid>
                        <Grid item xs={12}>
                          <ul>
                            <li>
                              <button
                                className={
                                  pressConversation.categoryColor === "#015dfe"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#015dfe",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#00d0ff"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#00d0ff",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#f9b900"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#f9b900",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#ff0092"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#ff0092",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#49b8ef"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#49b8ef",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#eb5757"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#eb5757",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#66de64"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#66de64",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#0a42e9"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#0a42e9",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#ef64b3"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#ef64b3",
                                  });
                                }}
                              />
                              <button
                                className={
                                  pressConversation.categoryColor === "#236681"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setPressConversation({
                                    ...pressConversation,
                                    categoryColor: "#236681",
                                  });
                                }}
                              />
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Textarea
                    textLabel='Step Description'
                    direction='(5000 characters max)'
                    placeholder='Manage your Aquatic AV Notification settings via e-mail, web, or within the AAV App.'
                    value={pressConversation.shortDescription}
                    onChange={v =>
                      setPressConversation({
                        ...pressConversation,
                        shortDescription: v.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item md={12} className='addChannelGroups'>
                  <h3>Conversation Channels*</h3>{" "}
                  <Button
                    color='primary'
                    variant='outlined'
                    startIcon={<Add />}
                    onClick={() => {}}>
                    Add Channels
                  </Button>
                </Grid>

                <Grid item md={12} className='channelLabels'>
                  {data.map((item, index) => {
                    return (
                      <div className='collectionLabelsWrapper' key={index}>
                        <div className='collectionLabels'>
                          <span>
                            {item.label} <i>{item.category}</i>
                          </span>
                          <span className='deleteLabels' onClick={() => {}}>
                            <Clear className='collectionLabelDeleteBtn' />
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid item md={12}>
                <h3>Conversation Settings</h3>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={3}
                  className='settingsWrapper'>
                  <Grid item md={4}>
                    <span className='settingTitle'>
                      Can visitors participate in this conversation type?
                    </span>
                    <i className='settingDesc'>{}</i>
                  </Grid>
                  <Grid item md={8} className='radioBtnWrapper'>
                    <span>
                      <Radio
                        checked={pressConversation.isPublic === true}
                        onChange={() => {
                          setPressConversation({
                            ...pressConversation,
                            isPublic: true,
                          });
                        }}
                      />
                      Yes - Visitors are allowed to chat with this Conversation
                      Type
                    </span>
                    <span>
                      <Radio
                        checked={pressConversation.isPublic === false}
                        onChange={() => {
                          setPressConversation({
                            ...pressConversation,
                            isPublic: false,
                          });
                        }}
                      />
                      No - Users must have an account/Logged-In to access this
                      Conversation Type
                    </span>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={3}
                  className='settingsWrapper'>
                  <Grid item md={4}>
                    <span className='settingTitle'>
                      Does this conversation allow for more than two
                      participants?
                    </span>
                    <i className='settingDesc'>{}</i>
                  </Grid>
                  <Grid item md={8} className='radioBtnWrapper'>
                    <span>
                      <Radio
                        checked={pressConversation.isGroupChat === true}
                        onChange={() => {
                          setPressConversation({
                            ...pressConversation,
                            isGroupChat: true,
                          });
                        }}
                      />
                      Yes - Group Chats are allowed between 3+ users (Visitors
                      are not able to join group conversations)
                    </span>
                    <span>
                      <Radio
                        checked={pressConversation.isGroupChat === false}
                        onChange={() => {
                          setPressConversation({
                            ...pressConversation,
                            isGroupChat: false,
                          });
                        }}
                      />
                      No - 1:1 Chats Between Two Users Only Allowed"
                    </span>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={3}
                  className='settingsWrapper'>
                  <Grid item md={4}>
                    <span className='settingTitle'>
                      Hide Conversations Your Brand is Not In?
                    </span>
                    <i className='settingDesc'>
                      If you allow customers to message each other, such as
                      family members or B2B customers, you can hide their
                      conversations from your main inbox.
                    </i>
                  </Grid>
                  <Grid item md={8} className='radioBtnWrapper'>
                    <span>
                      <Radio
                        checked={
                          pressConversation.isDeplayedInAdminInbox === true
                        }
                        onChange={() => {
                          setPressConversation({
                            ...pressConversation,
                            isDeplayedInAdminInbox: true,
                          });
                        }}
                      />
                      Yes - Hide Conversations Not Involving My Brand (You can
                      still find them in your archive or on individual contact
                      profiles)
                    </span>
                    <span>
                      <Radio
                        checked={
                          pressConversation.isDeplayedInAdminInbox === false
                        }
                        onChange={() => {
                          setPressConversation({
                            ...pressConversation,
                            isDeplayedInAdminInbox: false,
                          });
                        }}
                      />
                      No - Show in my Conversations Inboxes
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>

          <Grid item md={12}>
            <div className='customisationWrapper '>
              <h3>Conversation Permissions</h3>
              <Grid item md={12} className='addChannelGroups'>
                <h3>Who at Aquatic AV can start this conversation?*</h3>{" "}
                <Button
                  color='primary'
                  variant='outlined'
                  startIcon={<Add />}
                  onClick={() => {
                    setPressConversation({
                      ...pressConversation,
                      whoCon: !pressConversation.whoCon,
                    });
                  }}>
                  Add Users or Groups
                </Button>
                {pressConversation.whoCon && (
                  <div className='popupInnerContainer'>
                    <div className='audiancePopupWrapper'>
                      <div className='audiancePopupInnerWrapper'>
                        <span className='innerHeading'>
                          Add Users or Groups
                        </span>
                        <Close
                          style={{
                            cursor: "pointer",
                            padding: "20px",
                          }}
                          onClick={() => {
                            setPressConversation({
                              ...pressConversation,
                              whoCon: false,
                            });
                          }}
                        />
                      </div>
                      <Grid item xs={12} style={{ paddingLeft: "13px" }}>
                        <Input
                          fullWidth
                          placeholder='Search by Contact, Company or Group'
                          disableUnderline
                          style={{
                            padding: "17px",
                          }}
                          startAdornment={
                            <Search
                              style={{
                                marginRight: "5px",
                              }}
                            />
                          }
                          onChange={({ target }) => {}}
                        />
                      </Grid>
                      {endUserGroup.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "264px",
                            overflowY: "auto",
                          }}>
                          {endUserGroup.map(g => {
                            return (
                              <span className='innerList'>
                                <Checkbox
                                  key={g.id}
                                  label={g.value}
                                  checked={
                                    pressConversation.tempWhoCon.find(
                                      s => `${s.GroupId}` === `${g.id}`
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={({ target }) => {
                                    if (target.checked) {
                                      setPressConversation({
                                        ...pressConversation,
                                        tempWhoCon: [
                                          ...concat(
                                            pressConversation.tempWhoCon,
                                            {
                                              GroupId: g.id,
                                              name: g.value,
                                            }
                                          ),
                                        ],
                                      });
                                    } else {
                                      setPressConversation({
                                        ...pressConversation,
                                        tempWhoCon: [
                                          ...pressConversation.tempWhoCon.filter(
                                            s => `${s.GroupId}` !== `${g.id}`
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                />
                                <b>{g.value}</b>
                                {g.generic ? ` ${g.generic}` : ""}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <span
                          style={{
                            margin: "20px",
                            display: "flex",
                          }}>
                          No Users or Groups available
                        </span>
                      )}
                      <div className='audianceBtnWrapper'>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            setPressConversation({
                              ...pressConversation,
                              whoCon: false,
                            });
                          }}>
                          Add
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>

              <Grid item md={12} className='channelLabels'>
                {pressConversation.tempWhoCon.map((item, index) => {
                  return (
                    <div className='collectionLabelsWrapper' key={index}>
                      <div className='collectionLabels'>
                        <span>
                          {item.name} <i>{item.category}</i>
                        </span>
                        <span
                          className='deleteLabels'
                          onClick={() => {
                            const filteredList = [
                              ...pressConversation.tempWhoCon.filter(
                                g => `${g.GroupId}` !== `${item.GroupId}`
                              ),
                            ];
                            setPressConversation({
                              ...pressConversation,
                              tempWhoCon: filteredList,
                            });
                          }}>
                          <Clear className='collectionLabelDeleteBtn' />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <div className='gap' />

              <Grid item md={12} className='addChannelGroups'>
                <h3>Aquatic AV can start conversations with*</h3>{" "}
                <Button
                  color='primary'
                  variant='outlined'
                  startIcon={<Add />}
                  onClick={() => {
                    //setWithCon(true);
                    setPressConversation({
                      ...pressConversation,
                      withCon: !pressConversation.withCon,
                    });
                  }}>
                  Add Users or Groups
                </Button>
                {pressConversation.withCon && (
                  <div className='popupInnerContainer'>
                    <div className='audiancePopupWrapper'>
                      <div className='audiancePopupInnerWrapper'>
                        <span className='innerHeading'>
                          Add Users or Groups
                        </span>
                        <Close
                          style={{
                            cursor: "pointer",
                            padding: "20px",
                          }}
                          onClick={() => {
                            //setWithCon(false);
                            setPressConversation({
                              ...pressConversation,
                              withCon: false,
                            });
                          }}
                        />
                      </div>
                      <Grid item xs={12} style={{ paddingLeft: "13px" }}>
                        <Input
                          fullWidth
                          placeholder='Search by Contact, Company or Group'
                          disableUnderline
                          style={{
                            padding: "17px",
                          }}
                          startAdornment={
                            <Search
                              style={{
                                marginRight: "5px",
                              }}
                            />
                          }
                          onChange={({ target }) => {}}
                        />
                      </Grid>
                      {endUserGroup.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "264px",
                            overflowY: "auto",
                          }}>
                          {endUserGroup.map(g => {
                            return (
                              <span className='innerList'>
                                <Checkbox
                                  key={g.id}
                                  label={g.value}
                                  checked={
                                    pressConversation.tempWithCon.find(
                                      s => `${s.GroupId}` === `${g.id}`
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={({ target }) => {
                                    if (target.checked) {
                                      setPressConversation({
                                        ...pressConversation,
                                        tempWithCon: [
                                          ...concat(
                                            pressConversation.tempWithCon,
                                            {
                                              GroupId: g.id,
                                              name: g.value,
                                            }
                                          ),
                                        ],
                                      });
                                    } else {
                                      setPressConversation({
                                        ...pressConversation,
                                        tempWithCon: [
                                          ...pressConversation.tempWithCon.filter(
                                            s => `${s.GroupId}` !== `${g.id}`
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                />
                                <b>{g.value}</b>
                                {g.generic ? ` ${g.generic}` : ""}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <span
                          style={{
                            margin: "20px",
                            display: "flex",
                          }}>
                          No Users or Groups available
                        </span>
                      )}
                      <div className='audianceBtnWrapper'>
                        <Button
                          variant='contained'
                          color='primary'
                          // disabled={tempVisibilityGroups.length <= 0}
                          onClick={() => {
                            setPressConversation({
                              ...pressConversation,
                              withCon: false,
                            });
                          }}>
                          Add
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>

              <Grid item md={12} className='channelLabels'>
                {pressConversation.tempWithCon.map((item, index) => {
                  return (
                    <div className='collectionLabelsWrapper' key={index}>
                      <div className='collectionLabels'>
                        <span>
                          {item.name} <i>{item.category}</i>
                        </span>
                        <span
                          className='deleteLabels'
                          onClick={() => {
                            const filteredList = [
                              ...pressConversation.tempWithCon.filter(
                                g => `${g.GroupId}` !== `${item.GroupId}`
                              ),
                            ];
                            setPressConversation({
                              ...pressConversation,
                              tempWithCon: filteredList,
                            });
                          }}>
                          <Clear className='collectionLabelDeleteBtn' />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <div className='gap' />

              <Grid item md={12} className='addChannelGroups'>
                <h3>Aquatic AV can receive new conversations from* </h3>{" "}
                <Button
                  color='primary'
                  variant='outlined'
                  startIcon={<Add />}
                  onClick={() => {
                    setPressConversation({
                      ...pressConversation,
                      fromCon: true,
                    });
                  }}>
                  Add Users or Groups
                </Button>
                {pressConversation.fromCon && (
                  <div className='popupInnerContainer'>
                    <div className='audiancePopupWrapper'>
                      <div className='audiancePopupInnerWrapper'>
                        <span className='innerHeading'>
                          Add Users or Groups
                        </span>
                        <Close
                          style={{
                            cursor: "pointer",
                            padding: "20px",
                          }}
                          onClick={() => {
                            setPressConversation({
                              ...pressConversation,
                              fromCon: false,
                            });
                          }}
                        />
                      </div>
                      <Grid item xs={12} style={{ paddingLeft: "13px" }}>
                        <Input
                          fullWidth
                          placeholder='Search by Contact, Company or Group'
                          disableUnderline
                          style={{
                            padding: "17px",
                          }}
                          startAdornment={
                            <Search
                              style={{
                                marginRight: "5px",
                              }}
                            />
                          }
                          onChange={({ target }) => {}}
                        />
                      </Grid>
                      {endUserGroup.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "264px",
                            overflowY: "auto",
                          }}>
                          {endUserGroup.map(g => {
                            return (
                              <span className='innerList'>
                                <Checkbox
                                  key={g.id}
                                  label={g.value}
                                  checked={
                                    pressConversation.tempFromCon.find(
                                      s => `${s.GroupId}` === `${g.id}`
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={({ target }) => {
                                    if (target.checked) {
                                      setPressConversation({
                                        ...pressConversation,
                                        tempFromCon: [
                                          ...concat(
                                            pressConversation.tempFromCon,
                                            {
                                              GroupId: g.id,
                                              name: g.value,
                                            }
                                          ),
                                        ],
                                      });
                                    } else {
                                      setPressConversation({
                                        ...pressConversation,
                                        tempFromCon: [
                                          ...pressConversation.tempFromCon.filter(
                                            s => `${s.GroupId}` !== `${g.id}`
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                />
                                <b>{g.value}</b>
                                {g.generic ? ` ${g.generic}` : ""}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <span
                          style={{
                            margin: "20px",
                            display: "flex",
                          }}>
                          No Users or Groups available
                        </span>
                      )}
                      <div className='audianceBtnWrapper'>
                        <Button
                          variant='contained'
                          color='primary'
                          // disabled={tempVisibilityGroups.length <= 0}
                          onClick={() => {
                            setPressConversation({
                              ...pressConversation,
                              fromCon: false,
                            });
                          }}>
                          Add
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>

              <Grid item md={12} className='channelLabels'>
                {pressConversation.tempFromCon.map((item, index) => {
                  return (
                    <div className='collectionLabelsWrapper' key={index}>
                      <div className='collectionLabels'>
                        <span>
                          {item.name} <i>{item.category}</i>
                        </span>
                        <span
                          className='deleteLabels'
                          onClick={() => {
                            const filteredList = [
                              ...pressConversation.tempFromCon.filter(
                                g => `${g.GroupId}` !== `${item.GroupId}`
                              ),
                            ];
                            setPressConversation({
                              ...pressConversation,
                              tempFromCon: filteredList,
                            });
                          }}>
                          <Clear className='collectionLabelDeleteBtn' />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Grid>
            </div>
          </Grid>

          <Grid item md={12}>
            <div className='conversationStatusWrapper '>
              <h3>Understanding Conversation Statuses</h3>

              <div className='gap' />

              <Grid
                container
                item
                xs={12}
                spacing={3}
                className='convStatusWrapper'>
                <Grid item md={3}>
                  <Button className='btnOpen'>Open</Button>
                </Grid>
                <Grid item md={9}>
                  Leaves Conversation Open for Replies OFish Default
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                spacing={3}
                className='convStatusWrapper'>
                <Grid item md={3}>
                  <Button className='btnDraft'>Draft</Button>
                </Grid>
                <Grid item md={9}>
                  Conversations Not Yet Sent OFish Default
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                spacing={3}
                className='convStatusWrapper'>
                <Grid item md={3}>
                  <Button className='btnClosed'>Closed</Button>
                </Grid>
                <Grid item md={9}>
                  Closes the Conversation + Prevents Customer Replies unless
                  Re-Opened OFish Default
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                spacing={3}
                className='convStatusWrapper'>
                <Grid item md={3}>
                  <Button className='btnClosed'>Closed &amp; Archived</Button>
                </Grid>
                <Grid item md={9}>
                  Once a Conversation has been closed for 30 Days OFish will
                  Archive it from your Main Inbox. It will still be visible on
                  Customer Profiles OFish Default
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>

        <Button
          color='primary'
          variant='contained'
          autoFocus
          type='submit'
          onClick={() => {
            onSectionCompletedButtonPress(pressConversation);
          }}>
          submit
        </Button>
      </AccordionContainer>
    </div>
  );
}

export default CustomizationWrapper;
