//Production api link:
const rootURL = "https://ofishproductionapi.azurewebsites.net/api";
//Staging URL
// const rootURL = "https://ofishstagingapi.azurewebsites.net/api";
//Dev URL
// const rootURL = "https://ofishstagingapiv2.azurewebsites.net/api";
//Dev new URL
// const rootURL = "https://aavofishdevapi.azurewebsites.net/api";
//Client URL
// const rootURL = "https://outdoorplusofishapi.azurewebsites.net/api";
// const rootURL = "https://1eba-103-173-28-20.ngrok.io/api";

export const endpoints = {
  accounts: {
    login: `${rootURL}/Accounts/login`,
    addUserSessions: `${rootURL}/Sessions/AddSessions`,
    getUserAccountInfo: `${rootURL}/Customers/GetUserAccountInformation_NR`,
    updateUserAccountInfo: `${rootURL}/Customers/UpdateUserInfo_NR`,
    getAllCounts: `${rootURL}/Sessions/GetAllCounts`,
    forgotPassword: `${rootURL}/Accounts/SendPasswordResetLink?email=`,
    resetPassword: `${rootURL}/Accounts/ResetPassword`,
    tokenLogin: `${rootURL}/Accounts/DecryptToken`,
  },
  artifacts: {
    uploadFile: `${rootURL}/artifacts`,
    uploadImage: `${rootURL}/artifacts/image`,
    uploadGeneralFile: `${rootURL}/artifacts/file`,
    uploadReceipt: `${rootURL}/artifacts/receiptimage`,
  },
  launches: {
    getLaunchesList: `${rootURL}/Launches/GetLaunchGrid`,
    getLaunches: `${rootURL}/Launches`,
    getLaunchById: `${rootURL}/Launches/GetLaunchById`,
    addupdateLaunch: `${rootURL}/Launches/AddUpdateLaunch`,
    getLaunchCategories: `${rootURL}/Launches/GetCategoryDropdown`,
    deleteLaunches: `${rootURL}/Launches/DeleteLaunchByIds`,
    updateLaunches: `${rootURL}/Launches/`,
    getLaunchActivities: `${rootURL}/Launches/GetLaunchActivities`,
    getLaunchNotes: `${rootURL}/Launches/GetNotesByLaunchId`,
    addUpdateNotes: `${rootURL}/Launches/AddUpdateLaunchNote`,
    deleteLaunchtNote: `${rootURL}/Launches/DeleteLaunchNotesByIds`,
    getDropDownLanches: `${rootURL}/Launches/GetLaunchesDropdown`,
  },
  launchCategory: {
    addUpdateLaunchCategory: `${rootURL}/Launches/AddUpdateCategory`,
    getLaunchCategory: `${rootURL}/Launches/GetAllCategories`,
    getLaunchCategoryById: `${rootURL}/Launches/GetCategoryById`,
    deleteCategoryById: `${rootURL}/Launches/DeleteCategoryByIds`,
  },
  devices: {
    getRegistrationPendingApprovalProducts: `${rootURL}/Devices/GetRegistrationPendingApprovalProducts`,
    getNewestApprovedRegistrationProducts: `${rootURL}/Devices/GetNewestApprovedRegistrationProducts`,
    transferDeviceOwnerShip: `${rootURL}/Devices/TransferOwnershipDevice`,
  },
  releaseCategory: {
    getReleaseCategory: `${rootURL}/ReleaseCategories/GetAllCategories`,
    addUpdateReleaseCategory: `${rootURL}/ReleaseCategories/AddUpdateCategory`,
    deleteReleaseCategory: `${rootURL}/ReleaseCategories/DeleteCategoryByIds`,
    updateReleaseCategory: `${rootURL}/ReleaseCategories/`,
    getReleaseCategoryById: `${rootURL}/ReleaseCategories/GetCategoryById`,
    getReleaseCategories: `${rootURL}/ReleaseCategories/GetCategoryDropdown`,
    getReleaseCategoriesDD: `${rootURL}/ReleaseCategories/GetReleaseCategoryDropDown_NR`,
  },
  releaseLocations: {
    getReleaseLocation: `${rootURL}/locations`,
    addReleaseLocation: `${rootURL}/locations`,
    deleteReleaseLocation: `${rootURL}/locations`,
    updateReleaseLocation: `${rootURL}/locations/`,
  },
  releases: {
    getAllReleases: `${rootURL}/Releases/GetAllReleases`,
    getReleases: `${rootURL}/Releases`,
    getReleasById: `${rootURL}/Releases/GetReleaseById`,
    deleteReleases: `${rootURL}/Releases/DeleteReleaseByIds`,
    createReleases: `${rootURL}/Releases/AddUpdateRelease`,
    getFeatureFile: `${rootURL}/assets`,
    updateReleases: `${rootURL}/releases/`,
    getReleaseVisi: `${rootURL}/ReleaseVisibilities`,
    getReleaseNewsRoomVisi: `${rootURL}/NewsRoomVisibility`,
    getReleaseRelatedEvents: `${rootURL}/Events/GetEvents?PageNumber=1&NoOfRecords=20`,
    newGetFeatureFile: `${rootURL}/assets/GetAssets`,
  },
  assetsManagement: {
    getMediaAssetsList: `${rootURL}/Assets/GetAllAssets`,
    getMediaAssets: `${rootURL}/Assets/GetAssets`,
    getMediaAsset: `${rootURL}/Assets/GetAsset`,
    getParentMediaAssets: `${rootURL}/Assets/GetAsset`,
    deleteMediaAssets: `${rootURL}/Assets/DeleteAsset`,
    deleteMediaAssetsPermanently: `${rootURL}/Assets/DeleteAssetPermanent`,
    recoverMediaAsset: `${rootURL}/Assets/RecoverDeleted`,
    addMediaAssets: `${rootURL}/Assets/AddAsset`,
    moveAssetsFiles: `${rootURL}/Assets/Parent/`,
    getMediaAssetsFolder: `${rootURL}/Assets/GetAssets?query=folders`,
    getCollectionVisi: `${rootURL}/CollectionVisibilities`,
    getFavoriteAssets: `${rootURL}/Assets/GetFavoriteAssets`,
    addFavorite: `${rootURL}/Assets/favorite`,
    getAllAssetsForPopup: `${rootURL}/Assets/GetAllAssetsForPopup`,
    updateAssestName: `${rootURL}/Assets/UpdateAsset`,
  },
  collectionCategory: {
    addCollectionCategory: `${rootURL}/CollectionCategories/AddUpdateCategory`,
    getCollectionCategory: `${rootURL}/CollectionCategories/GetAllCategories`,
    deleteCollectionCategory: `${rootURL}/CollectionCategories/DeleteCategoryByIds`,
    updateCollectionCategory: `${rootURL}/CollectionCategories/`,
    getCollectionCategoryById: `${rootURL}/CollectionCategories/GetCategoryById?Id=`,
    getCollectionCategoryDropdown: `${rootURL}/CollectionCategories/GetCategoryDropdown/`,
  },
  allCollection: {
    getAllCollection: `${rootURL}/Collections`,
    addCollection: `${rootURL}/Collections`,
    updateCollection: `${rootURL}/Collections/`,
    deleteCollection: `${rootURL}/Collections`,
    newGetAllCollection: `${rootURL}/Collections/GetCollections`,
    newAddCollection: `${rootURL}/Collections/AddCollection`,
    newUpdateCollection: `${rootURL}/Collections/UpdateCollection/`,
    newDeleteCollection: `${rootURL}/Collections/DeleteCollection`,
    addUpdateCollection: `${rootURL}/Collections/AddUpdateCollection`,
    getAllAssertCollection: `${rootURL}/Collections/GetAllCollections`,
    getAssertCollectionById: `${rootURL}/Collections/GetCollectionById?Id=`,
    deleteAllAssertCollection: `${rootURL}/Collections/DeleteCollectionByIds`,
    getCollectionsDropdown: `${rootURL}/Collections/GetCollectionsDropdown`,
    getCategoryPopup: `${rootURL}/CollectionCategories/GetCategoryPopup`,
  },
  customers: {
    getAllContacts: `${rootURL}/Customers`,
    deleteAllContacts: `${rootURL}/Customers`,
    addContact: `${rootURL}/Customers`,
    moveContacts: `${rootURL}/Customers/customerGroup/`,
    updateContact: `${rootURL}/Customers/`,
    getBusinessGroupListSearch: `${rootURL}/Customers/GetGroupListSearch/BusinessCustomer`,
    getBusinessGroup: `${rootURL}/Customers/GetGroupByCustomerTypeNameSearch/BusinessCustomer`,
    addBusinessGroup: `${rootURL}/Customers/AddGroup`,
    getPolicies: `${rootURL}/Customers/GetPolicies?PolicyType=Customer`,
    addCustomer: `${rootURL}/Accounts/register`,
    getCustomerList: `${rootURL}/Customers/GetCustomerListSearch/BusinessCustomer`,
    getEndUserGroup: `${rootURL}/Customers/GetGroupByCustomerTypeNameSearch/EndCustomer`,
    markUnMarkFavouriteUserGroup: `${rootURL}/Customers/MarkUnMarkFavouriteGroup`,
    getEndUserList: `${rootURL}/Customers/GetCustomerListSearch/EndCustomer`,
    deleteUsers: `${rootURL}/Customers/DeleteUsers`,
    deleteGroups: `${rootURL}/Customers/DeleteGroups`,
    updateUser: `${rootURL}/Customers/UpdateUser`,
    getAllUserGroup: `${rootURL}/Customers/GetGroupDropdown?CustomerTypeName=all`,
    addUpdateGroup: `${rootURL}/Customers/AddUpdateGroup`,
    getBUComConnectionList: `${rootURL}/Companies/GetCompanyOFishConnections?id`,
    getBUContactTypeById: `${rootURL}/Companies/GetContactsByContactTypeId?id`,
    getGroupById: `${rootURL}/Customers/GetGroupDetailsById?GroupId`,
    GetGroupDropdownForBC: `${rootURL}/Customers/GetGroupDropdown?CustomerTypeName=BusinessCustomer`,
    getUserAutoCompleteByEmail: `${rootURL}/Customers/GetUserAutoCompleteByEmail?Email=`,
    getUserAutoCompleteByKV: `${rootURL}/Customers/GetUserAutoComplete/EndCustomer`,
    blockUnBlockCustomers: `${rootURL}/Customers/BlockUnBlockCustomers`,
    markUnMarkFavouriteUsers: `${rootURL}/Customers/MarkUnMarkFavouriteUsers`,
    getFavouriteUsers: `${rootURL}/Customers/GetUserAllFavourites`,
    markUserAsArchived: `${rootURL}/Customers/MarkUserAsArchived`,
    RestoreUsers: `${rootURL}/Customers/RestoreUsers`,
  },
  endUserFrndAndFamily: {
    getDevicesLookup: `${rootURL}/Devices/GetDevicesLookup/User/`,
    getFrndAndFamilyList: `${rootURL}/FriendAndFamilyContact/User/`,
    addFrndAndFamily: `${rootURL}/FriendAndFamilyContact`,
    deleteFrndAndFamily: `${rootURL}/FriendAndFamilyContact/`,
  },
  customerGroups: {
    getCustomerGroups: `${rootURL}/CustomerGroups`,
    deleteCustomerGroups: `${rootURL}/CustomerGroups`,
    addCustomerGroups: `${rootURL}/CustomerGroups`,
  },
  companies: {
    saveCompany: `${rootURL}/Companies`,
    addBusinessCompany: `${rootURL}/Companies`,
    getCompanies: `${rootURL}/Companies/GetCompanyCustomerCountSearch`,
    getCompanyDetail: `${rootURL}/Companies/GetCompanyDetail`,
    deleteCompanies: `${rootURL}/Companies/DeleteCompanies`,
    updateCompany: `${rootURL}/Companies/UpdateCompany`,
    getCompanyById: `${rootURL}/Companies/GetCompanyOverview?ComapnyId`,
    getCompanyByName: `${rootURL}/Companies/GetCustomerCountByCompanyName?CompanyName`,
    getCompanyAutoCompleteByName: `${rootURL}/Companies/GetCompanyAutoCompleteByName?Name=`,
    getEndUserCompanyAutoComplete: `${rootURL}/Companies/GetChatUsers`,
    getCompanyOFishConnections: `${rootURL}/Companies/GetCompanyOFishConnections`,
    addUpdateCompanyOFishConnections: `${rootURL}/Companies/AddUpdateCompanyOFishConnections`,
    deleteCompanyOFishConnections: `${rootURL}/Companies/DeleteCompanyOFishConnections`,
    getCompanyUsers: id => `${rootURL}/Companies/GetCompanyUsers?id=${id}`,
    getCompanyLocations: id =>
      `${rootURL}/Companies/GetCompanyLocations?id=${id}`,
    getCompanyLocationsById: id =>
      `${rootURL}/Companies/GetCompanyLocationsById?id=${id}`,
    addUpdateCompanyLocations: `${rootURL}/Companies/AddUpdateCompanyLocations`,
    deleteCompanyLocations: `${rootURL}/Companies/DeleteCompanyLocations/`,
    markUnMarkFavouriteCompanies: `${rootURL}/Companies/MarkUnMarkFavouriteCompanies`,
    addUpdateBUNotes: `${rootURL}/Companies/AddUpdateCompanyNotes`,
    deleteBUNotes: `${rootURL}/Companies/DeleteCompanyNotes/`,
    getCompanyLocTypes: `${rootURL}/Companies/GetCompanyLocationTypes`,
    getBlockedBusinessLocation: `${rootURL}/Devices/FindDealersByTypeId`,
  },
  pubReleases: {
    getAllPubReleases: `${rootURL}/Releases/GetAllReleases_NR`,
    getAllPubRelCategory: `${rootURL}/ReleaseCategories/GetReleaseCategoryDropDown_NR`,
    getAllPubDateRelease: `${rootURL}/Releases/GetReleaseDateFilter_NR`,
    getPubRelDetail: `${rootURL}/Releases/GetReleaseDetailsById_NR`,
    getPubAllFilterRelease: `${rootURL}/Releases?FilteredByMonths=true`,
  },
  pubMediaLibrary: {
    getAllPubMediaAssets: `${rootURL}/Collections/GetAllCollections_NR`,
    getAllPubMedCategory: `${rootURL}/CollectionCategories/GetCollectionsCategoryDropDown_NR`,
    getAllPubDateMedia: `${rootURL}/Collections/GetCollectionDateFilter_NR`,
    getCollectionDetail: `${rootURL}/Collections/GerCollectionDetailsById_NR`,
    downloadAssets: `${rootURL}/Generic/DownloadFilesAsZip`,
  },
  pubEvents: {
    getAllPubEvents: `${rootURL}/Events/GetAllEvents_NR`,
    getAllPubEventCategory: `${rootURL}/Events/GetEventsCategoryDropDown_NR`,
    getAllPubDateEvent: `${rootURL}/Events/GetEventDateFilter_NR`,
    getPubEventDetail: `${rootURL}/Events/GerEventDetailsById_NR`,
    getPubEventCalendar: `${rootURL}/Events/GetEventsCalendar_NR`,
    updatePubEventAttend: `${rootURL}/Events/UpdateEventAttendingStatus_NR`,
  },
  pubCompany: {
    getCompanyInfo: `${rootURL}/NewsRoom/GetNewsRoomOverview?newsRoomId=1`,
    getPubComCont: `${rootURL}/NewsRoom/GetNewsRoomContactInfo?newsRoomId=1`,
    getPubComLeader: `${rootURL}/NewsRoom/GetNewsRoomLeadership?newsRoomId=1`,
  },
  generic: {
    getProductSubCategories: `${rootURL}/Generic/GetProductCategories`,
    getCountries: `${rootURL}/Generic/GetCountries`,
    getCities: `${rootURL}/Generic/GetCities`,
    getProvinces: `${rootURL}/Generic/GetProvinces`,
    getLocationsAutoComplete: `${rootURL}/Generic/GetLocationsAutoComplete`,
    getProductTypeDropdown: `${rootURL}/Generic/GetProductTypeDropdown`,
  },
  productCategories: {
    getAllBrands: `${rootURL}/Products/GetBrandsInfo`,
    getAllProductCategories: `${rootURL}/Products/GetProductCategories`,
    getAllProductSubCategories: `${rootURL}/Products/GetProductSubCategory`,
    getProductSubCategoryByCategoryIds: `${rootURL}/Products/GetProductSubCategoryByCategoryIds`,
    addProductCategory: `${rootURL}/Products/AddProductCategory`,
    getProductCategoryById: `${rootURL}/Products/GetProductCategoryById`,
    deleteProductCategories: `${rootURL}/Products/DeleteProductCategories`,
    getProductsByCategoryId: `${rootURL}/Products/GetProductsByProductCategories`,
  },
  productInventory: {
    getAllUserProducts: `${rootURL}/Products/GetUserProducts`,
    getAllFavouriteProducts: `${rootURL}/Products/GetFavouriteProducts`,
    getAllProducts: `${rootURL}/Products/GetProductList`,
    addupdateProduct: `${rootURL}/Products/AddUpdateProductsInfo`,
    getProductById: `${rootURL}/Products/GetProductsInfoById`,
    deleteProducts: `${rootURL}/Products/DeleteProducts`,
    getProductActivities: `${rootURL}/Products/GetProductActivities`,
    getProductNotes: `${rootURL}/Products/GetProductNotes`,
    addProductNotes: `${rootURL}/Products/AddProductNotes`,
    deleteProductNotes: `${rootURL}/Products/DeleteProductNotes`,
    getPricingAvailabilityPeriodList: `${rootURL}/Products/GetPricingAvailabilityPeriodList`,
    addupdatePricingAvailabilityPeriod: `${rootURL}/Products/AddUpdatePricingAvailabilityPeriod`,
    getPricingAvailabilityPeriodDropdown: `${rootURL}/Products/GetPricingAvailabilityPeriodDropdown`,
    getPricingAvailabilityPeriodDropdownV2: `${rootURL}/Products/GetPricingAvailabilityPeriodDropdown/v2`,
    getFollowedByPeriodsDropdown: `${rootURL}/Products/GetFollowedByPeriodsDropdown`,
    getProductPricingById: `${rootURL}/Products/GetProductPricingById`,
    addUpdateBulkProductPricing: `${rootURL}/Products/AddUpdateBulkProductPricing`,
    favoriteProduct: `${rootURL}/products/{$id}/Favourite`,
    addUpdateProductPricing: `${rootURL}/Products/AddUpdateProductPricing`,
    getProductPricingGroup: `${rootURL}/Products/GetProductPricingGroup`,
    getProductDescriptionByProduct: `${rootURL}/Products/GetProductDescriptionByProduct`,
    addUpdateProductDescription: `${rootURL}/Products/AddUpdateProductDescription`,
    getProductPriceDescriptionByProduct: `${rootURL}/Products/GetProductPriceDescriptionByProduct`,
    addUpdatePriceDescription: `${rootURL}/Products/AddUpdateProductPriceDescription`,
    deleteProductPricing: `${rootURL}/Products/DeleteProductPricing`,
    deletePricingAvailabilityPeriod: `${rootURL}/Products/DeletePricingAvailabilityPeriod`,
    getProductsAutoComplete: `${rootURL}/Products/GetProductsAutoComplete`,
  },
  priceListTool: {
    getPriceList: `${rootURL}/PriceListTool/GetPriceListToolList`,
    getPriceListById: `${rootURL}/PriceListTool/v2/GetPriceListDetailsById`,
    getProductPricingColumns: `${rootURL}/PriceListTool/GetProductPricingColumns`,
    getProductPricingColumnsV2: `${rootURL}/PriceListTool/v2/GetProductPricingColumns`,
    getPricingGroupsByAvailabilityPeriod: `${rootURL}/PriceListTool/v2/GetPricingGroupsByAvailabilityPeriod`,
    previewPriceListById: `${rootURL}/PriceListTool/PriceListPreviewById`,
    addupdatePriceList: `${rootURL}/PriceListTool/AddUpdatePriceList`,
    addupdatePriceListV2: `${rootURL}/PriceListTool/v2/AddUpdatePriceList`,
    getPriceListForCopyAccess: `${rootURL}/PriceListTool/GetPriceListForCopyAccess`,
    copyPriceListAccess: `${rootURL}/PriceListTool/CopyPriceListAccess`,
    downloadPDF: `${rootURL}/PriceListTool/DownloadPDF`,
    downloadCSV: `${rootURL}/PriceListTool/DownloadCSV`,
    deletePriceLists: `${rootURL}/PriceListTool/DeletePriceLists`,
    addUpdatePriceListVisibility: `${rootURL}/PriceListTool/AddUpdatePriceListVisibility`,
    updatePriceListDesign: `${rootURL}/PriceListTool/UpdatePriceListDesign`,
    addUpdatePriceListColumns: `${rootURL}/PriceListTool/AddUpdatePriceListColumns`,
  },
  productMedia: {
    getProductMediaByProductId: `${rootURL}/Products/GetProductMediaByProductId`,
    addupdateProductMedia: `${rootURL}/Products/AddUpdateProductsMedia`,
    deleteProductMediaById: `${rootURL}/Products/DeleteProductMediaByProductMediaId`,
    updateProductMediaMainImage: `${rootURL}/Products/UpdateProductMediaMainImage`,
    updateOnStoreProduct: `${rootURL}/Products/UpdateOnStoreProduct`,
  },
  supportResources: {
    getProductRegistrationByProductId: `${rootURL}/Products/GetProductRegistrationByProductId`,
    addupdateProductRegistration: `${rootURL}/Products/AddUpdateProductRegistration`,
    getProductReturnByProductId: `${rootURL}/Products/GetReturnAndReturnGroups`,
    addupdateProductReturn: `${rootURL}/Products/AddReturnAndReturGroups`,
    getGroupDropdown: `${rootURL}/Customers/GetGroupDropdown`,
    getProductSupportResources: `${rootURL}/Products/GetProductSupportResources`,
    addProductSupportResources: `${rootURL}/Products/AddProductSupportResources`,
  },
  productSerials: {
    getAllSerials: `${rootURL}/Products/GetSerialsList`,
    getDeviceBySerialNo: `${rootURL}/Products/GetDeviceBySerialNo`,
    getDealersDropdown: `${rootURL}/Products/GetDealersDropdown`,
    updateDeviceInfo: `${rootURL}/Products/UpdateDevicesInfo`,
    deleteSerials: `${rootURL}/Products/DeleteSerials`,
    getProductSerialList: `${rootURL}/Products/GetProductSerialList`,
    getBlockedSerials: `${rootURL}/Products/GetBlockedSerials`,
    updateReceipt: `${rootURL}/Products/UpdateReceipt`,
    updateSerialRegisteredStatus: `${rootURL}/Products/UpdateSerialStatus`,
    getDevicesActivity: `${rootURL}/Products/GetDevicesActivity`,
    getDeviceNotes: `${rootURL}/Products/GetProductDevicesNotes`,
    addProductDevicesNotes: `${rootURL}/Products/AddProductDevicesNotes`,
  },
  events: {
    getEvents: `${rootURL}/Events/GetEventsSearch`,
    getEventsCategory: `${rootURL}/Events/EventCategory`,
    addUpdateEvent: `${rootURL}/Events/AddUpdateEvent`,
    deleteEvents: `${rootURL}/Events/DeleteEvents`,
    getEventsById: `${rootURL}/Events/GetEventDetail`,
    getEventCategories: `${rootURL}/Events/GetEventCategoriesSearch`,
    addUpdateEventCategory: `${rootURL}/Events/AddUpdateEventCategory`,
    getEventsCatById: `${rootURL}/Events/GetEventCategoryDetail`,
    deleteEventsCatById: `${rootURL}/Events/DeleteEventCategories`,
    getEventType: `${rootURL}/Events/GetEventType`,
    getEventDropDown: `${rootURL}/Events/GetEventDropDown`,
  },
  locations: {
    getLocationsList: `${rootURL}/Locations/GetLocationsList`,
    addUpdateLocations: `${rootURL}/Locations/AddUpdateLocations`,
    getLocationDataById: `${rootURL}/Locations/GetLocationDataById`,
    deleteLocations: `${rootURL}/Locations/DeleteLocations`,
  },
  locatorWidget: {
    getSettings: `${rootURL}/LocatorWidget/GetLocatorWidgetGeneralSettings`,
    saveSettings: `${rootURL}/LocatorWidget/AddUpdateLocatorWidget`,
    getEmbedCode: `${rootURL}/LocatorWidget/GetLocatorWidgetEmbedCode`,
    saveEmbedCode: `${rootURL}/LocatorWidget/AddUpdateLocatorWidgetEmbedCode`,
    getFilterOptions: `${rootURL}/LocatorWidget/GetLocatorWidgetAllFiltersOptions`,
    getFilterOptionsKeyValuePair: `${rootURL}/LocatorWidget/GetLocationWidgetFilterOptionsKeyValuePair`,
    addUpdateLocatorWidgetFilter: `${rootURL}/LocatorWidget/AddUpdateLocatorWidgetFilter`,
    getSelectedFilterOptions: `${rootURL}/LocatorWidget/GetLocatorWidgetFiltersByWidgetId`,
    saveFilterOption: `${rootURL}/LocatorWidget/AddUpdateLocatorWidgetFilterOptions`,
  },
  newsRoomAdmin: {
    getNewsRoomDropdown: `${rootURL}/NewsRoom/GetNewsRoomDropdown`,
    getCompanyOverview: `${rootURL}/NewsRoom/GetNewsRoomOverview?newsRoomId=1`,
    getLeadership: `${rootURL}/NewsRoom/GetNewsRoomLeadership?newsRoomId=1`,
    getComContactInfo: `${rootURL}/NewsRoom/GetNewsRoomContactInfo?newsRoomId=1`,
    getSettings: `${rootURL}/NewsRoom/GetNewsRoomSettings`,
    saveSettings: `${rootURL}/NewsRoom/AddUpdateNewsRoomSettings`,
    saveCompOverview: `${rootURL}/NewsRoom/AddUpdateNewsRoomOverview`,
    saveNewsRoomBoilerplate: `${rootURL}/NewsRoom/AddUpdateNewsRoomBoilerplate`,
    saveLeader: `${rootURL}/NewsRoom/AddUpdateNewsRoomLeadership`,
    saveComContact: `${rootURL}/NewsRoom/AddUpdateNewsRoomContactInfo`,
    getAllNewsRoomContentByRoomId: `${rootURL}/NewsRoom/getAllNewsRoomContentByRoomId?newsRoomId=1`,
    getMenuByNewsRoomId: `${rootURL}/NewsRoom/GetMenuByNewsRoomId?newsRoomId=1`,
    getNewsRoomQuickLinks: `${rootURL}/NewsRoom/GetNewsRoomQuickLinks?newsRoomId=1`,
    getMenuOptions: `${rootURL}/NewsRoom/GetMenuOptions`,
    getQuickLinks: `${rootURL}/NewsRoom/GetNewsRoomQuickLinks`,
    getFeaturedContent: `${rootURL}/NewsRoom/GetAllNewsRoomContentByRoomId`,
    getNavigations: `${rootURL}/NewsRoom/GetNewsRoomNavigation`,
    saveQuickLinks: `${rootURL}/NewsRoom/AddUpdateNewsRoomQuickLinks`,
    saveFeaturedContent: `${rootURL}/NewsRoom/AddUpdateSingleNewsRoomHomePageContent`,
    saveNewsRoomNavigation: `${rootURL}/NewsRoom/AddUpdateNewsRoomNavigation`,
    deleteNewsRoomContent: `${rootURL}/NewsRoom/DeleteNewsRoomContent`,
  },
  endUser: {
    getUserOverView: `${rootURL}/Customers/GetCustomerOverview?UserId=`,
    getUserById: `${rootURL}/Customers/GetUser?UserId=`,
    updateUserInfo: `${rootURL}/Customers/UpdateUser`,
    getUserStaticEnv: `${rootURL}/Environment/GetStaticEnvironments`,
    getEnvIcons: `${rootURL}/Environment/GetEnvironmentIcons`,
    addUpdateEnv: `${rootURL}/Environment/AddUpdateEnvironments`,
    getUserEnvById: `${rootURL}/Environment/GetEnvironments`,
    getUserEnvs: `${rootURL}/Environment/User/`,
    getEnvWithDeviceCount: `${rootURL}/Environment/GetMyEnvironmentsWithDevicesCount`,
    getEndUserGroupDD: `${rootURL}/Customers/GetGroupDropdown?CustomerTypeName=EndCustomer`,
    getUserProductCategory: `${rootURL}/Devices/GetDevicesProductCategories`,
    getUserProductByCategoryId: `${rootURL}/Devices/GetDevicesProducts`,
    getUserDuplicateSerial: `${rootURL}/Devices/GetDuplicateSerialNo`,
    getUserDelears: `${rootURL}/Devices/GetDealers`,
    getAllUserDelears: `${rootURL}/Devices/GetDealers_v2`,
    saveDevice: `${rootURL}/Devices/RegisterSerial`,
    getUserDevices: `${rootURL}/Devices/GetUserDevicesList`,
    getCompanyDevices: `${rootURL}/Devices/GetCompanyDevices_MB`,
    updateUserDevices: `${rootURL}/Devices/UpdateDevicesInfo`,
    deleteDevice: `${rootURL}/Devices/DeleteSerials`,
    deviceOverview: `${rootURL}/Devices/GetDeviceOverviewInfo`,
    updateReceipt: `${rootURL}/Devices/UpdateReceipt`,
    addDeviceColor: `${rootURL}/Devices/AddDevicesColors`,
    updateDeviceColor: `${rootURL}/Devices/UpdateDevicesColors`,
    // getUserNotes: `${rootURL}/Customers/GetCustomerNotes?UserId=`,
    addUpdateUserNotes: `${rootURL}/Customers/AddCustomerNotes`,
    deleteUserNotes: `${rootURL}/Customers/DeleteCustomerNotes`,
    getUserActivity: `${rootURL}/Customers/GetCustomerActivity?UserId=`,
    getEUActivity: `${rootURL}/Customers/GetActivity?UserId=`,
    getBUActivity: `${rootURL}/Customers/GetActivityByCompanyId/`,
    getContactUserTypes: `${rootURL}/ContactTypes/GetContactTypesInfoById?ContactTypeId=`,
    getAdrsByUId: `${rootURL}/Customers/GetCustomersAddressesByUserId?userId=`,
    addCusAddress: `${rootURL}/Customers/AddUpdateCustomerAddresses`,
    passReset: `${rootURL}/Accounts/ForceResetPassword`,
    getAllAdmins: `${rootURL}/Customers/GetAdminUsers`,
    getAdminsById: `${rootURL}/Customers/GetAdminUserById?id=`,
    deleteCusAddress: `${rootURL}/Customers/DeleteCustomerAddresses`,
    deleteEnv: `${rootURL}/Environment/DeleteEnvironment`,
  },
  spaDevice: {
    getSpaInfo: `${rootURL}/Devices/GetSPADeviceDetailById`,
    addUpdateSetting: `${rootURL}/Devices/AddUpdateSPASettings`,
    updateWaterTreatment: `${rootURL}/Devices/AddUpdateSPAWaterTreatment`,
    updateGlobalSetting: `${rootURL}/Devices/AddUpdateSPAGlobalSettings`,
    updateTemperatureSetting: `${rootURL}/Devices/AddUpdateTemperatureSettings`,
    getSpaChemicalTestingList: `${rootURL}/Devices/GetSPAChemicalTestingList`,
    allFilterLife: `${rootURL}/Devices/GetAllFilterLife`,
    addUpdateFilterLife: `${rootURL}/Devices/AddUpdateFilterLife`,
    addUpdateWaterLife: `${rootURL}/Devices/AddUpdateWaterLife`,
    addUpdateSPAChemicalTesting: `${rootURL}/Devices/AddUpdateSPAChemicalTesting`,
    getAllWaterLife: `${rootURL}/Devices/GetAllWaterLife`,
    getChemicalTestingStats: `${rootURL}/Devices/GetChemicalTestingStats`,
    getFilterLifeStats: `${rootURL}/Devices/GetFilterLifeStats`,
    getWaterLifeStats: `${rootURL}/Devices/GetWaterLifeStats`,
    getSpaChemicalTestingHistoryWithDates: `${rootURL}/Devices/GetSPAChemicalTestingGraphDetailsWithDates`,
    saveSpaSetting: `${rootURL}/Devices/AddUpdateSPAFilterSettings`,
    updateSpaSetting: `${rootURL}/Devices/AddUpdateSPAFilterSettings`,
  },
  contactTypes: {
    getAllContactTypes: `${rootURL}/ContactTypes/GetAllContactTypes`,
    getAllContactDataFields: `${rootURL}/ContactTypes/GetAllContactDataFields?ContactAreaId=`,
    getAllContactModules: `${rootURL}/ContactTypes/GetAllContactModules?ContactAreaId=`,
    getAllConnections: `${rootURL}/ContactTypes/GetAllOFishConnections?ContactAreaId=`,
    getAllContactAreas: `${rootURL}/ContactTypes/GetContactArea`,
    getAllOfishRoles: `${rootURL}/ContactTypes/GetOFishRoles`,
    getAllContactDataFieldsById: `${rootURL}/ContactTypes/GetContactDataFiledSettingsById`,
    getContactTypesModulesFiledsSettingsById: `${rootURL}/ContactTypes/GetContactTypesModuleSettingsById`,
    addUpdateContactType: `${rootURL}/ContactTypes/AddUpdateContactType`,
    getContactTypesById: `${rootURL}/ContactTypes/GetContactTypeById`,
    getContactTypesDdl: `${rootURL}/ContactTypes/GetContactTypesDdl`,
    getContactTypesDD: `${rootURL}/ContactTypes/GetContactTypesDdl?ContactTypeAreaId=`,
    addUpdateMarkFavouriteContactTypes: `${rootURL}/ContactTypes/MarkFavouriteContactTypes`,
    addUpdateMarkFavouriteContactTypesModules: `${rootURL}/ContactTypes/MarkFavouriteContactTypesModules`,
    addUpdateMarkFavouriteOfishConnections: `${rootURL}/ContactTypes/MarkFavouriteOfishConnections`,
    getStoreLocationsByCompanyId: id =>
      `${rootURL}/Companies/GetStoreLocationsByCompanyId?id=${id}`,
  },
  leadGenerations:{
    getLeadsPaginatedData:`${rootURL}/Leads/GetLeadsPaginatedData`,
   updateLeadGeneration:`${rootURL}/Leads/UpdateLeadGeneration`,
   getLeadGeneration:`${rootURL}/Leads/GetLeadGeneration`,
  },
  softwareRelease: {
    addUpdateSoftwareRelease: `${rootURL}/SoftwareRelease/AddUpdateSoftwareRelease`,
    addUpdateReleaseProducts: `${rootURL}/SoftwareRelease/AddUpdateSoftwareReleaseProducts`,
    addUpdateSRPortalandContacts: `${rootURL}/SoftwareRelease/AddUpdateSRPortalandContacts`,
    addSoftwareReleaseDetails: `${rootURL}/SoftwareRelease/AddSoftwareReleaseDetails`,
    addUpdateSoftwareReleaseNotification: `${rootURL}/SoftwareRelease/AddUpdateSoftwareReleaseNotification`,
    getSoftwareReleaseNotification: `${rootURL}/SoftwareRelease/GetSoftwareReleaseNotification`,
    getNotificationsType: `${rootURL}/SoftwareRelease/GetNotificationsType`,
    getSoftwareReleaseById: `${rootURL}/SoftwareRelease/GetSoftwareReleaseById`,
    getReleaseCompatibleProducts: `${rootURL}/SoftwareRelease/GetCompatibleProducts`,
    getSoftwareContactsReleaseById: `${rootURL}/SoftwareRelease/GetSoftwareContactsReleaseById`,
    getSoftwareReleasePortalById: `${rootURL}/SoftwareRelease/GetSoftwareReleasePortalById`,
    getSoftwareReleaseProducts: `${rootURL}/SoftwareRelease/GetSoftwareReleaseProducts`,
    addUpdateSoftwareReleaseBuild: `${rootURL}/SoftwareRelease/AddUpdateSoftwareReleaseBuild`,
    getSoftwareReleaseDetails: `${rootURL}/SoftwareRelease/GetSoftwareReleaseDetails`,
    getAllSoftwareRelease: `${rootURL}/SoftwareRelease/GetAllSoftwareReleases`,
    deleteSoftwareRelease: `${rootURL}/SoftwareRelease/DeleteSoftwareRelease`,
    deleteSoftwareReleaseNotifiacation: `${rootURL}/SoftwareRelease/DeleteSoftwareReleaseNotification`,
    markUnMarkSoftwareReleaseFavourite: `${rootURL}/SoftwareRelease/MarkUnMarkSoftwareReleaseFavourite`,
    addUpdateSoftwareBuild: `${rootURL}/SoftwareRelease/AddUpdateSoftwareBuildRelease`,
    getSoftwareBuildReleaseById: `${rootURL}/SoftwareRelease/GetSoftwareBuildReleaseById`,
    addUpdateSRBuildPortalandContacts: `${rootURL}/SoftwareRelease/AddUpdateSRBuildPortalandContacts`,
    getSoftwareContactsReleaseBuildById: `${rootURL}/SoftwareRelease/GetSoftwareContactsReleaseBuildById`,
    getSoftwareReleaseBuildPortalById: `${rootURL}/SoftwareRelease/GetSoftwareReleaseBuildPortalById`,
    addUpdateSoftwareReleaseBuildProducts: `${rootURL}/SoftwareRelease/AddUpdateSoftwareReleaseBuildProducts`,
    getSoftwareReleaseBuildProducts: `${rootURL}/SoftwareRelease/GetSoftwareReleaseBuildProducts`,
    addSoftwareReleaseBuildDetails: `${rootURL}/SoftwareRelease/AddSoftwareReleaseBuildDetails`,
    getSoftwareReleaseBuildDetails: `${rootURL}/SoftwareRelease/GetSoftwareReleaseBuildDetails`,
    addUpdateSoftwareReleaseBuildNotification: `${rootURL}/SoftwareRelease/AddUpdateSoftwareReleaseBuildNotification`,
    getSoftwareReleaseBuildNotification: `${rootURL}/SoftwareRelease/GetSoftwareReleaseBuildNotification`,
    markUnMarkSoftwareReleaseBuildFavourite: `${rootURL}/SoftwareRelease/MarkUnMarkSoftwareReleaseBuildFavourite`,
    getAllSoftwareReleasesBuild: `${rootURL}/SoftwareRelease/GetAllSoftwareReleasesBuild`,
    deleteSoftwareReleaseBuild: `${rootURL}/SoftwareRelease/DeleteSoftwareReleaseBuild`,
    deleteSoftwareReleaseBuildNotifiacation: `${rootURL}/SoftwareRelease/DeleteSoftwareReleaseNotificationBuild`,
  },
  portal: {
    getAllPortals: `${rootURL}/Portal`,
  },
  baseUrl: rootURL,
};
