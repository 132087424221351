import React, { useEffect, useState , useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Button, FormControl, Checkbox } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import EmptyTextarea from "../../../../components/common/Textarea";
import InputField from "../../../../components/common/InputField";
import Loader from "../../../../components/common/Loader";
import SelectInput from "../../../../components/common/SelectInput";
import { AccordionContainer, LaunchState } from "../../../../components/common";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { toast } from "react-toastify";
import queryString from "query-string";
import moment from "moment";

const CheckBox = ({ title, value, onChange }) => {
  return (
    <Grid container item xs={12}>
      <Grid item xs={7}>
        {title}
      </Grid>
      <Grid item xs={5}>
        <Checkbox checked={value} onChange={onChange} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectInputError:{
    color:"#f44336",
    marginLeft:"14px",
    fontSize:"10px",
  },
}));

const LaunchOverview = ({ existinglaunch }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const launchId = queryString.parse(location.search);

  const [isLoading, setLoading] = useState(false);
  const [launch, setLaunch] = useState({
    state: launchId.id ? 2 : 0,
    isNewsRelease: false,
    isAssetCollection: false,
    isEvent: false,
    isProduct: false,
    isSocialPlan: false,
    isCoveragePlan: false,
  });
  const [categoryId, setCategoryId] = useState(1);
  const [categories, setCategories] = useState([]);

  const setItemProp = ({ propName, value }) => {
    setLaunch({
      ...launch,
      [propName]: value,
    });
  };

  useEffect(() => {
    dispatch(launchesActions.onGetLaunchCategories()).then(data => {
      setCategories(data?.data);
    });
  }, [dispatch]);

  useEffect(() => {
    if (existinglaunch) {
      setLaunch(existinglaunch);
      setCategoryId(existinglaunch?.categoryId || 1);
    }
  }, [existinglaunch]);

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   setLoading(true);

  //   // ToDo: Add/Update Launch
  //   const newLaunch = {
  //     id: launch?.id || 0,
  //     state: launch?.state || 0,
  //     plan: launch?.plan,
  //     categoryId: categoryId,
  //     description: launch?.description,
  //     launchDate: launch?.launchDate ? launch?.launchDate : new Date(),
  //     isNewsRelease: launch?.isNewsRelease,
  //     isAssetCollection: launch?.isAssetCollection,
  //     isEvent: launch?.isEvent,
  //     isProduct: launch?.isProduct,
  //     isSocialPlan: launch?.isSocialPlan,
  //     isCoveragePlan: launch?.isCoveragePlan,
  //   };
  //   dispatch(launchesActions.onAddUpdateLaunch(newLaunch)).then(data => {
  //     if (data && data.success) {
  //       history.goBack();
  //     } else if (!data?.success) {
  //       toast.error(data.message);
  //     }
  //     setLoading(false);
  //   });
  // };
  
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    formRef.current.isFormValid(false).then(isValid => {
      if (isValid) {
        // ToDo: Add/Update Launch
        const newLaunch = {
          id: launch?.id || 0,
          state: launch?.state || 0,
          plan: launch?.plan,
          categoryId: categoryId,
          description: launch?.description,
          launchDate: launch?.launchDate ? moment(launch?.launchDate).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          isNewsRelease: launch?.isNewsRelease,
          isAssetCollection: launch?.isAssetCollection,
          isEvent: launch?.isEvent,
          isProduct: launch?.isProduct,
          isSocialPlan: launch?.isSocialPlan,
          isCoveragePlan: launch?.isCoveragePlan,
        };
        dispatch(launchesActions.onAddUpdateLaunch(newLaunch)).then(data => {
          if (data && data.success) {
            history.goBack();
          } else if (!data?.success) {
            toast.error(data.message);
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div className={`launchoverview`}>
      {isLoading ? <Loader /> : null}
      <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
        <AccordionContainer
          title='Launch Overview'
          formButton={
            <div className='dashboardNav'>
              <LaunchState
                value={launch.state}
                onChange={status => {
                  setItemProp({
                    propName: "state",
                    value: status,
                  });
                }}
              />
              <Button
                color='primary'
                variant='contained'
                className='dashboardButtons'
                disabled={new Date(launch?.launchDate) < new Date()}
                type='submit'>
                Save Launch
              </Button>
            </div>
          }>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.gridWrapper}>
              <InputField
                showRequired={true}
                inputlabel='What Are You Launching'
                validators={["required", "matchRegexp:^.{0,50}$"]}
                errorMessages={[
                  "Please enter launch name",
                  "Maximum length is 50 characters",
                ]}
                value={launch?.plan}
                onChange={({ target }) =>
                  setItemProp({
                    propName: "plan",
                    value: target.value,
                  })
                }
              />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid
                item
                xs={7}
                className={(classes.gridWrapper, "shortDescription")}>
                <EmptyTextarea
                  required
                  maxLength={500}
                  textLabel='Short Description'
                  textSubtitle='(500 characters max)'
                  value={launch?.description}
                  onChange={({ target }) =>
                    setItemProp({
                      propName: "description",
                      value: target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.gridWrapper}>
                    <SelectInput
                      showRequired
                      selectlabel='Launch Category'
                      placeholder='Please Select'
                      value={categoryId}
                      required={true}
                      customMenuItems={categories?.map(c => {
                        return {
                          id: c.id,
                          name: c.value,
                        };
                      })}
                      handleChange={({ target }) => {
                        setItemProp({
                          propName: "categoryId",
                          value: target.value,
                        });
                        setCategoryId(target.value);
                      }}
                    />
                    {categoryId === 0 && <span className={classes.selectInputError}>
                      Please select a category
                    </span>}
                  </Grid>
                  <Grid item xs={12} className={classes.gridWrapper}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className='inputWrapper dateField'>
                        <FormControl className={classes.formControl}>
                          <label>{"Launch Date"}</label>
                          <DateTimePicker
                            autoOk
                            format='MM/dd/yyyy hh:mm a'
                            value={ moment(launch?.launchDate).format('YYYY-MM-DD HH:mm:ss') || moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}
                            onChange={value => {
                              setItemProp({
                                propName: "launchDate",
                                value: value,
                              });
                            }}
                            minDate={new Date()}
                          />
                        </FormControl>
                      </div>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionContainer>
        <AccordionContainer title='Launch Plan'>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              What resources do you need for this launch?
            </Grid>
            <Grid container item xs={6} spacing={2}>
              <Grid container item xs={12} style={{ marginBottom: "10px" }}>
                <Grid item xs={6}>
                  <strong>Resource Type</strong>
                </Grid>
                <Grid item xs={6}>
                  <strong>Resource Needed</strong>
                </Grid>
              </Grid>

              <CheckBox
                title='News Release'
                value={launch?.isNewsRelease}
                onChange={e => {
                  setItemProp({
                    propName: "isNewsRelease",
                    value: e.target.checked,
                  });
                }}
              />
              <CheckBox
                title='Asset Collection'
                value={launch?.isAssetCollection}
                onChange={e => {
                  setItemProp({
                    propName: "isAssetCollection",
                    value: e.target.checked,
                  });
                }}
              />
              <CheckBox
                title='Event'
                value={launch?.isEvent}
                onChange={e => {
                  setItemProp({
                    propName: "isEvent",
                    value: e.target.checked,
                  });
                }}
              />
              <CheckBox
                title='Product'
                value={launch?.isProduct}
                onChange={e => {
                  setItemProp({
                    propName: "isProduct",
                    value: e.target.checked,
                  });
                }}
              />
              <CheckBox
                title='Social Plan'
                value={launch?.isSocialPlan}
                onChange={e => {
                  setItemProp({
                    propName: "isSocialPlan",
                    value: e.target.checked,
                  });
                }}
              />
              <CheckBox
                title='Coverage Plan'
                value={launch?.isCoveragePlan}
                onChange={e => {
                  setItemProp({
                    propName: "isCoveragePlan",
                    value: e.target.checked,
                  });
                }}
              />
            </Grid>
          </Grid>
        </AccordionContainer>
      </ValidatorForm>
    </div>
  );
};

export { LaunchOverview };
