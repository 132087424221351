import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { FiChevronDown } from "react-icons/fi";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../../components/common/InputField";
import { searchIcon } from "../../../../../resources/images";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MenuProps, useStyles } from "../../../../../components/common/utils";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";

const useStyless = makeStyles((theme) => ({
  gridWrapper: {
    paddingTop: "40px",
    paddingBottom: "15px",
    borderBottom: "1px solid #E2E6E9",
  },
  filterModal: {
    minWidth: "500px",
    boxShadow: `0px 0px 10px rgb(0 0 0 / 14%)`,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    backgroundColor: "#fff",
    right: "30px",
    padding: "29px  33px",
    boxSizing: "border-box",
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px",
  },
}));

const handleSubmit = (e) => {
  e.preventDefault();
};
const GridFilterBarPlaces = ({
  businessGroups,
  setFilterByName,
  setSelectedCategory,
  setSelectedDate,
}) => {
  const classes = useStyless();
  const dispatch = useDispatch();
  const [cat, setCat] = useState();
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [dayFilter, setDayFilter] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setCat(businessGroups);
  }, [businessGroups]);

  const handleChange = (event) => {
    // setDayFilter(event.target.value);
  };

  const onPressFilterBtn = () => {
    setFilterByName(searchText);
    setAnchorEl(null);
    var selCatId = [];
    cat.map((x) => {
      if (x.checked) {
        selCatId.push(parseInt(x.id));
      }
    });
    setSelectedCategory(selCatId?.length > 0 ? selCatId : null);
    setSelectedDate(dayFilter);
  };

  const onPressClearBtn = () => {
    setSearchText("");
    setFilterByName(null);
    setSelected([]);
    cat.map((x) => {
      x.checked = false;
    });
    setCat([...cat], cat);
    setSelectedDate(0);
    setDayFilter(0);
    setSelectedCategory(null);
  };

  return (
    <div className={classes.gridWrapper}>
      <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={10} className="filterMainSearch">
            <img src={searchIcon} alt="" className="searchIcon" />
            <InputField
              placeholder="Search"
              value={searchText}
              onChange={(v) => setSearchText(v.target.value)}
            />
          </Grid>

          <Grid item md={2} className="searchByFilter" onClick={handleClick}>
            Search by Filter <FiChevronDown />{" "}
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Paper className={classes.dropdownWrapper}>
            <Grid container spacing={2} className="filterByWrapper">
              <Grid item xs={7} className="filterBtnLabel">
                Filter by{" "}
              </Grid>
              <Grid item xs={5}>
                <span className="filterButtons" style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    // onClick={() => onPressFilterBtn()}
                  >
                    Filter
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onPressClearBtn();
                    }}
                  >
                    Clear
                  </Button>
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="inputWrapper">
                  <FormControl className={classes.formControl}>
                    <Select
                      value={dayFilter}
                      onChange={(event) => {
                        setDayFilter(event.target.value);
                      }}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"filled"}
                      required
                    >
                      <MenuItem value="" disabled>
                        All Uploads
                      </MenuItem>
                      <MenuItem value={0}>All Uploads</MenuItem>
                      <MenuItem value={1}>Within Last Month</MenuItem>
                      <MenuItem value={2}>Within Past 3 Months</MenuItem>
                      <MenuItem value={3}>Within Past 6 Months</MenuItem>
                      <MenuItem value={4}>Within Past Last Year</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
      </ValidatorForm>
    </div>
  );
};

export { GridFilterBarPlaces };
