export const endUserTypes = {
  ENDUSER_ONCHANGE: "ENDUSER_ONCHANGE",
  REGISTER_DEVICE_CHANGE: "REGISTER_DEVICE_CHANGE",
  RESET_DEVICE_OVERVIEWINFO: "RESET_DEVICE_OVERVIEWINFO",
  SELECT_FF_DEVICES: "SELECT_FF_DEVICES",
  SET_SPA_LOCATION: "SET_SPA_LOCATION",
  SET_SELECT_LOCATION_SPA: "SET_SELECT_LOCATION_SPA",
  SPA_RESET_FIELDS: "SPA_RESET_FIELDS",
  SET_USER_DATA : 'SET_USER_DATA',
};
