import React, { useState, useMemo } from "react";
import { CalenderIcon } from "../SpaProduct/SvgIcons";

const Calendar = ({handlers}) => {
  const year = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const startDate = `${year}-01`;
  const endDate = `${year}-${currentMonth > 9 ? currentMonth : '0' + currentMonth}`;
  const [inputDateFrom, setInputDateFrom] = useState(startDate);
  const [inputDateTo, setInputDateTo] = useState(endDate);

  const handleFromDateChange = (event) => {
    setInputDateFrom(event.target.value);
    handlers(event.target.value, inputDateTo)
  };
  const handleToDateChange = (event) => {
    setInputDateTo(event.target.value);
    handlers(inputDateFrom, event.target.value)
  };

  const formatMonth = (month) => {
    const [year, monthNum] = month.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${monthNames[parseInt(monthNum) - 1]} ${year}`;
  };

  return (
    <div className="calDesing">
      <div className="calenderInputs">
        <div className="calenderInputsFrom">
          <input
            type="month"
            value={inputDateFrom}
            onChange={handleFromDateChange}
            max={inputDateTo}
          />
        </div>
        <div className="calenderInputsTo">
          <input
            type="month"
            value={inputDateTo}
            onChange={handleToDateChange}
            max={endDate}
            min={inputDateFrom }
          />
        </div>
      </div>
      <button>
        {formatMonth(inputDateFrom)} - {formatMonth(inputDateTo)}{" "}
        <CalenderIcon />
      </button>
    </div>
  );
};

export default Calendar;
