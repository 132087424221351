import React from "react";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { BiX } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridOuterWrapper: {
    position: "relative",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "6px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  hoursWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

function SocialMedia(props) {
  const dispatch = useDispatch();
  // onChangeSocials
  const changeSocial = (value, index, fieldName) => {
    dispatch(newsroomActions.onChangeSocials(index, value, fieldName));
  };
  const classes = useStyles();
  const { socials } = useSelector((state) => state.newsRoomReducer);
  return (
    <>
      <div className="contactWrapperOuter">
        <div className="gap" />
        <div className="gap" />
        <label className="innerLabel">Social Media</label>
        {socials.length > 0 &&
          socials.map((socl, index) => {
            return (
              <Grid key={index} container spacing={3}>
                <Grid
                  item
                  xs={2}
                  className={[classes.gridWrapper, "socialWrapper "]}
                >
                  <Select
                    value={socl.name}
                    onChange={(e) =>
                      changeSocial(e.target.value, index, "name")
                    }
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                    fullWidth
                    variant={"outlined"}
                  >
                    <MenuItem value="Twitter">
                      <span>{"Twitter"}</span>
                    </MenuItem>
                    <MenuItem value="LinkedIn">
                      <span>{"LinkedIn"}</span>
                    </MenuItem>
                    <MenuItem value="Instagram">
                      <span>{"Instagram"}</span>
                    </MenuItem>
                    <MenuItem value="Facebook">
                      <span>{"Facebook"}</span>
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={5}
                  className={[
                    classes.gridWrapper,
                    classes.gridOuterWrapper,
                    "socialWrapper",
                  ]}
                >
                  <InputField
                    placeholder="Link"
                    value={socl.link}
                    onChange={(e) =>
                      changeSocial(e.target.value, index, "link")
                    }
                  />

                  <span className="socialDeleteIcon">
                    <BiX
                      onClick={() =>
                        dispatch(newsroomActions.onRemoveSocials(index))
                      }
                    />
                  </span>
                </Grid>
              </Grid>
            );
          })}
        <Grid item xs={2} className={[classes.gridWrapper, "addSocialBtn"]}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: "0", padding: "6px 15px" }}
            onClick={() => dispatch(newsroomActions.onAddNewSocials())}
            className={classes.selectBtn}
          >
            Add
          </Button>
        </Grid>
      </div>
    </>
  );
}

export default SocialMedia;
