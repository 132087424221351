import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ValidatorForm } from "react-material-ui-form-validator";
import { InputField, Spinner } from "../../../../components/common";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { RedCross, TickMark } from "../../LeftNav/SvgIcons";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  eventCatTextarea: {},
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  addNewBusinessGroupInput: {
    "& .MuiOutlinedInput-input": {
      height: "0px !important",
    },
    "& .MuiInputBase-input::-webkit-input-placeholder": {
      fontStyle: "normal !important",
      color: "#7E8299 !important",
      fontWeight: "400",
      fontSize: "12px !important",
    },
  },
}));

const TickCross = ({ onCancel }) => {
  return (
    <span className='tickMarkCross'>
      <button
        onClick={e => {
          e.preventDefault();
          onCancel(e);
        }}>
        <RedCross />
      </button>
      <button type='submit'>
        <TickMark />
      </button>
    </span>
  );
};

const AddNewBusinessGroup = ({
  id,
  businessGroupEntity,
  onCancel,
  onAddUpdate,
  isUserGroup,
}) => {
  const classes = useStyles();

  const [groupNameInternal, setGroupNameInternal] = useState("");
  const [groupNameVisible, setGroupNameVisible] = useState("");
  const [groupColor, setCategoryColor] = useState("#015dfe");
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (businessGroupEntity && businessGroupEntity?.id) {
      setGroupNameInternal(businessGroupEntity?.groupName);
      setGroupNameVisible(businessGroupEntity?.groupNamePublic);
      setCategoryColor(businessGroupEntity?.color || "");
    }
  }, [businessGroupEntity]);

  const handleSubmit = e => {
    e.preventDefault();
    if (groupNameInternal !== "") {
      let modal = {
        id: 0,
        groupName: groupNameInternal,
        groupNamePublic: groupNameVisible,
        description: shortDescription,
        color: groupColor,
        customerTypeName: isUserGroup ? "EndCustomer" : "BusinessCustomer",
        isActive: true,
      };
      if (businessGroupEntity?.id) {
        modal = {
          ...businessGroupEntity,
          groupName: groupNameInternal,
          groupNamePublic: groupNameVisible,
          color: groupColor,
        };
      }
      setLoading(true);
      setErrorMsg(false);
      dispatch(contactsActions.onAddUpdateGroup(modal)).then(data => {
        if (data?.success) {
          toast.success(
            `Group ${
              businessGroupEntity?.id ? "updated" : "created"
            } succefully`
          );
        }
        onAddUpdate(data?.success);
        setLoading(false);
      });
    } else {
      setErrorMsg(true);
      onAddUpdate(false);
    }
  };

  return (
    <>
      <h2 className='newEndUserGroup'>
        {isUserGroup
          ? `${businessGroupEntity?.id ? "Update" : "New"} End User Group`
          : `${businessGroupEntity?.id ? "Update" : "New"} Business Group`}
      </h2>
      <div className='addNewGroupWrapper' id={id}>
        {isLoading ? <Spinner /> : null}
        <h2 className='groupInformation'>
          {isUserGroup
            ? // ? `${businessGroupEntity?.id ? "Update" : "New"} End User Group`
              "Group Information"
            : `${businessGroupEntity?.id ? "Update" : "New"} Business Group`}
        </h2>
        <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
          <>
            {/* <BusinessGroupOverview /> */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} className={classes.gridWrapper}>
                <InputField
                  className={classes.addNewBusinessGroupInput}
                  placeholder='Group Name (Internal)'
                  inputlabel='Group Name (Internal)'
                  value={groupNameInternal}
                  onChange={v => setGroupNameInternal(v.target.value)}
                />
                {errorMsg && groupNameInternal === "" && (
                  <span style={{ color: "red" }}>
                    Please enter group name (Internal)
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridWrapper}>
                <InputField
                  className={classes.addNewBusinessGroupInput}
                  placeholder='Group Name (Visible)'
                  inputlabel='Group Name (Visible)'
                  value={groupNameVisible}
                  onChange={v => setGroupNameVisible(v.target.value)}
                />
                {errorMsg && groupNameVisible === "" && (
                  <span style={{ color: "red" }}>
                    Please enter group name (Internal)
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "10px" }}
              item
              xs={11}
              className={[classes.gridWrapper, "eventCatTextarea"]}>
              <Grid container spacing={2} className='slugWrapper'>
                <Grid
                  item
                  xs={6}
                  className={[
                    classes.gridWrapper,
                    "slugWrapper newBusinessGroupColor",
                  ]}>
                  <span>Color</span>
                  <ul>
                    <li>
                      <button
                        className={
                          groupColor === "#015dfe" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#015dfe");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#00d0ff" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#00d0ff");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#f9b900" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#f9b900");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#ff0092" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#ff0092");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#49b8ef" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#49b8ef");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#eb5757" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#eb5757");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#66de64" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#66de64");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#0a42e9" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#0a42e9");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#ef64b3" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#ef64b3");
                        }}
                      />
                      <button
                        className={
                          groupColor === "#236681" ? "colorActive" : ""
                        }
                        onClick={e => {
                          e.preventDefault();
                          setCategoryColor("#236681");
                        }}
                      />
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={2} className={classes.gridWrapper}>
                  <InputField
                    className={classes.addNewBusinessGroupInput}
                    placeholder='HEX Code'
                    inputlabel='HEX Code'
                    value={`${groupColor}`}
                    onChange={v => setCategoryColor(v.target.value)}
                  />
                </Grid>
              </Grid>
              <div className='addNewGroupActions'>
                <TickCross onCancel={onCancel} />
              </div>
            </Grid>
          </>
        </ValidatorForm>
      </div>
    </>
  );
};

export { AddNewBusinessGroup };
