import React from "react";
import Button from "@material-ui/core/Button";

function RelatedEvents({
  picture,
  heading,
  eventtime,
  eventdate,
  onPressEvent
}) {
  return (
    <div className="RelatedAssetsWrapper">
      <ul>
        <li>
          <span className="pressPicture">
            <img src={picture} />
          </span>
        </li>
        <li>
          <h2>{heading}</h2>
          <span className="subheading">{eventtime}</span>
          <span className="eventdate">{eventdate}</span>
        </li>
      </ul>

      <Button
        onClick={() => onPressEvent()}
        variant="contained"
        color="primary"
        style={{ padding: "10px 15px !important" }}>
        View Event
      </Button>
    </div>
  );
}

export default RelatedEvents;
