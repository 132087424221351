import Cookies from "js-cookie";
import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";
import apiCall from "axios";

async function login(email, password) {
  return axios
    .post(endpoints.accounts.login, {
      email: email,
      password: password,
    })
    .then(response => {
      let data = response.data;
      if (data && data.data) {
        localStorage.setItem("access_token", data.data.accessToken.token);
        Cookies.set("access_token", data.data.accessToken.token);
        localStorage.setItem("isAuthenticated", data.success);
        localStorage.setItem("userEmail", data.data.email);
        localStorage.setItem("isAdmin", data.data.role);
        localStorage.setItem("id", data.data.id);
        localStorage.setItem(
          "name",
          data.data.firstName ? data.data.firstName : ""
        );
        localStorage.setItem(
          "lName",
          data.data.lastName ? data.data.lastName : ""
        );
      }
      return response;
    })
    .catch(error => {
      return null;
    });
}

async function addUserSession(pageUrl) {
  const userId = localStorage.getItem("id");
  const guid = localStorage.getItem("guid");
  return axios
    .post(endpoints.accounts.addUserSessions, {
      deviceId: guid,
      userId: userId ? userId : "",
      url: pageUrl,
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return null;
    });
}

async function getAllCounts(date) {
  return axios
    .post(endpoints.accounts.getAllCounts, {
      date: date,
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return null;
    });
}

async function getUserAccountInfo() {
  return axios
    .post(endpoints.accounts.getUserAccountInfo)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return null;
    });
}

async function updateUserAccountInfo(userData) {
  return axios
    .post(endpoints.accounts.updateUserAccountInfo, userData)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return null;
    });
}

async function forgotPassword(email) {
  localStorage.setItem("userEmail", email);
  const userToken = localStorage.getItem("access_token");
  return axios
    .get(`${endpoints.accounts.forgotPassword}${email}`)
    .then(response => {
      let data = response.data;
      if (data.success && data.data) {
        localStorage.setItem("access_token", userToken);
        Cookies.set("access_token", userToken);
      }
      return response;
    })
    .catch(error => {
      return null;
    });
}
async function resetPassword(pass, conPass) {
  const userEmail = localStorage.getItem("userEmail");
  const userToken = localStorage.getItem("access_token");
  return axios
    .post(endpoints.accounts.resetPassword, {
      email: userEmail,
      password: pass,
      confirmPassword: conPass,
      token: userToken,
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return null;
    });
}

async function tokenLogin(token) {
  return apiCall({
    url: endpoints.accounts.tokenLogin,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      let data = response.data;
      if (data && data.data) {
        localStorage.setItem("access_token", token);
        localStorage.setItem("isAuthenticated", data?.success);
        localStorage.setItem("name", data?.data?.firstName);
        localStorage.setItem("userEmail", data?.data?.email);
        localStorage.setItem("isAdmin", data?.data?.role);
        localStorage.setItem("id", data?.data?.id);
        localStorage.setItem("lName", data?.data?.lastName);
      }
      return response;
    })
    .catch(error => {
      return null;
    });
}

export const accountApi = {
  login: login,
  addUserSession: addUserSession,
  getUserAccountInfo: getUserAccountInfo,
  updateUserAccountInfo: updateUserAccountInfo,
  getAllCounts: getAllCounts,
  forgotPassword: forgotPassword,
  resetPassword: resetPassword,
  tokenLogin: tokenLogin,
};
