import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { ExpandDownIcon } from "../../LeftNav/SvgIcons";
import { Button, Grid, List, ListItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import ReleaseBasicsAccordian from "./ReleaseBasicsAccordian";
import UserRequirementsAccordian from "./UserRequirementsAccordian";
import ReleaseNotesAccordian from "./ReleaseNotesAccordian";
import ProductsAccordian from "./ProductsAccordian";
import { CheckmarkIcon } from "../SpaProduct/SvgIcons";
import { useHistory } from "react-router";

const SoftwareReleaseAccordian = ({
  formName,
  selectedRelease,
  saveBasicInfo,
  isLoading,
  releaseBasicsProps,
  fetchRequiredData,
  contactIds,
  groupIds,
  companyIds,
  portalIds,
  selectedReleasePortal,
  selectedReleaseContacts,
  poratlAndContactData,
  customerContactData,
  setUpdatedTempData,
  updatedTempData,
  handleDeletedChip,
  filteredProtalData,
  setFilteredProtalData,
  tempPortalData,
  onsubmitPortalContact,
  productsList,
  compatibleProductsList,
  selectedReleaseProducts,
  removeEligibleSelectedDevice,
  handleAddEligibleDevice,
  notificationTypes,
  selectedReleaseNotifications,
  softwareReleaseDetials,
  notificationData,
  setNotificationData,
  setReleaseNoteSelectedData,
  releaseNoteSelectedData,
  submitNotificationData,
  handleDeletedReleaseNotification,
  onSubmitRealsePlan,
  tempContactData,
  portalAndContactBasicsProps,
  portalListData,
  deletePortalSelectedDevices,
  changeContactTab,
  fetchReleaseData,
}) => {
  const [releaseContent, setRelaseContent] = useState(false);
  const [portalRequirment, setPortalRequirment] = useState(false);
  const [releaseContentDone, setRelaseContentDone] = useState(false);
  const [eligibleContentDone, setEligibleContentDone] = useState(false);
  const [releasePlanDone, setReleasePlanDone] = useState(false);
  const [portalRequirmentDone, setPortalRequirmentDone] = useState(false);
  const [accordionKey, setAccordionKey] = useState(5);
  const [accordionName, setAccordionName] = useState("");

  const history = useHistory();
  const handleAccordionButtonClick = (key) => {
    setAccordionKey(key);
  };
  console.info({ softwareReleaseDetials });
  const handleReleaseReview = () => {
    formName === "Build"
      ? history.push(
          `/software-new-build/${selectedRelease?.softwareReleaseId}/${selectedRelease?.id}`
        )
      : history.push(`/new-software-release/${selectedRelease?.id}`);
  };

  useEffect(() => {
    fetchRequiredData();
    if(formName !== "Build"){
    fetchReleaseData();
    }
  }, []);
  
  return (
    <Grid className="software_release">
      <Accordion expanded={accordionName === "releaseBasics"}>
        <AccordionSummary
          expandIcon={<ExpandDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid className="user_requirements_header">
            <Typography>
              {`${formName} `}Basics
            </Typography>
            {accordionName !== "releaseBasics" && (
              <List>
                <ListItem className="accordianHeading">
                  Manage the Basics Regarding This Software {` ${formName}`}
                </ListItem>
              </List>
            )}
          </Grid>
          {accordionName !== "releaseBasics" && !releaseContentDone && (
            <Grid container alignItems="center" className="head">
              <Button
                color="primary"
                variant="outlined"
                className="startBtn"
                disabled={accordionKey === 1}
                onClick={() => setAccordionName("releaseBasics")}
              >
                start
              </Button>
            </Grid>
          )}
          {releaseContentDone && (
            <Grid container alignItems="center" className="head">
              <CheckmarkIcon />
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReleaseBasicsAccordian
            saveBasicInfo={saveBasicInfo}
            formName={formName}
            setRelaseContentDone={setRelaseContentDone}
            setRelaseContent={setRelaseContent}
            releaseContent={releaseContent}
            setPortalRequirment={setPortalRequirment}
            portalRequirment={portalRequirment}
            accordionName={accordionName}
            setAccordionName={setAccordionName}
            setAccordionKey={setAccordionKey}
            isLoading={isLoading}
            selectedRelease={selectedRelease}
            releaseBasicsProps={releaseBasicsProps}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={accordionName === "portalAndContact"}>
        <AccordionSummary
          expandIcon={<ExpandDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          onClick={() => {
            handleAccordionButtonClick(6);
          }}
        >
          <Grid className="user_requirements_header">
            <Typography>Portal/User Requirements</Typography>
            {accordionName !== "portalAndContact" && (
              <List>
                <ListItem className="accordianHeading2">
                  Set minimum requirements for this
                  {` ${formName.toLowerCase()} `}
                  so it runs on the correct Portal versions for the right users
                </ListItem>
              </List>
            )}
          </Grid>
          {accordionName !== "portalAndContact" && !portalRequirmentDone && (
            <Grid container alignItems="center" className="head">
              <Button
                color="primary"
                variant="outlined"
                className="startBtn"
                disabled={accordionKey !== 2}
                onClick={() => setAccordionName("portalAndContact")}
              >
                start
              </Button>
            </Grid>
          )}
          {portalRequirmentDone && (
            <Grid container alignItems="center" className="head">
              <CheckmarkIcon />
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <UserRequirementsAccordian
            buildId={formName === "Build"}
            setPortalRequirmentDone={setPortalRequirmentDone}
            accordionName={accordionName}
            setAccordionName={setAccordionName}
            setAccordionKey={setAccordionKey}
            selectedRelease={selectedRelease}
            isLoading={isLoading}
            contactIds={contactIds}
            groupIds={groupIds}
            companyIds={companyIds}
            portalIds={portalIds}
            selectedReleasePortal={selectedReleasePortal}
            selectedReleaseContacts={selectedReleaseContacts}
            poratlAndContactData={poratlAndContactData}
            customerContactData={customerContactData}
            portalAndContactBasicsProps={portalAndContactBasicsProps}
            setUpdatedTempData={setUpdatedTempData}
            updatedTempData={updatedTempData}
            handleDeletedChip={handleDeletedChip}
            filteredProtalData={filteredProtalData}
            setFilteredProtalData={setFilteredProtalData}
            tempPortalData={tempPortalData}
            onsubmitPortalContact={onsubmitPortalContact}
            tempContactData={tempContactData}
            portalListData={portalListData}
            deletePortalSelectedDevices={deletePortalSelectedDevices}
            changeContactTab={changeContactTab}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={accordionName === "eligibleDevices"}>
        <AccordionSummary
          expandIcon={<ExpandDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="eligibleDevices"
        >
          <Grid className="user_requirements_header">
            <Typography>Eligible Devices</Typography>
            {accordionName !== "eligibleDevices" && (
              <List>
                <ListItem className="accordianHeading2">
                  Select the products that are eligible to receive this
                  {` ${formName.toLowerCase()}`}.
                </ListItem>
              </List>
            )}
          </Grid>
          {accordionName !== "eligibleDevices" && !eligibleContentDone && (
            <Grid container alignItems="center" className="head">
              <Button
                color="primary"
                variant="outlined"
                className="startBtn"
                disabled={accordionKey !== 3}
                onClick={() => setAccordionName("eligibleDevices")}
              >
                start
              </Button>
            </Grid>
          )}
          {eligibleContentDone && (
            <Grid container alignItems="center" className="head">
              <CheckmarkIcon />
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ProductsAccordian
            buildId={formName === "Build"}
            setEligibleContentDone={setEligibleContentDone}
            accordionName={accordionName}
            setAccordionName={setAccordionName}
            setAccordionKey={setAccordionKey}
            productsList={productsList}
            compatibleProductsList={compatibleProductsList}
            selectedReleaseProducts={selectedReleaseProducts}
            selectedRelease={selectedRelease}
            removeEligibleSelectedDevice={removeEligibleSelectedDevice}
            handleAddEligibleDevice={handleAddEligibleDevice}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={accordionName === "notificationRelease"}>
        <AccordionSummary
          expandIcon={<ExpandDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Grid className="user_requirements_header">
            <Typography>
            {`${formName} `}Plan
            </Typography>
            {accordionName !== "notificationRelease" && (
              <List>
                <ListItem className="accordianHeading">
                  Choose and customize how, when, and what information will be
                  sent to users regarding this
                  {` ${formName.toLowerCase()}`}.
                </ListItem>
              </List>
            )}
          </Grid>

          {accordionName !== "notificationRelease" && !releasePlanDone && (
            <Grid container alignItems="center" className="head">
              <Button
                color="primary"
                variant="outlined"
                className="startBtn"
                disabled={accordionKey !== 4}
                onClick={() => setAccordionName("notificationRelease")}
              >
                start
              </Button>
            </Grid>
          )}

          {releasePlanDone && (
            <Grid container alignItems="center" className="head">
              <CheckmarkIcon />
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ReleaseNotesAccordian
            setReleasePlanDone={setReleasePlanDone}
            accordionName={accordionName}
            setAccordionName={setAccordionName}
            setAccordionKey={setAccordionKey}
            formName={formName}
            notificationTypes={notificationTypes}
            selectedRelease={selectedRelease}
            isLoading={isLoading}
            selectedReleasePortal={selectedReleasePortal}
            selectedReleaseNotifications={selectedReleaseNotifications}
            softwareReleaseDetials={softwareReleaseDetials}
            notificationData={notificationData}
            setNotificationData={setNotificationData}
            setReleaseNoteSelectedData={setReleaseNoteSelectedData}
            releaseNoteSelectedData={releaseNoteSelectedData}
            submitNotificationData={submitNotificationData}
            handleDeletedReleaseNotification={handleDeletedReleaseNotification}
            onSubmitRealsePlan={onSubmitRealsePlan}
          />
        </AccordionDetails>
      </Accordion>
      <Grid className="buttonContainer" container justifyContent="flex-end">
        {releasePlanDone ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleReleaseReview();
            }}
            className="saveAndReviewButton"
          >
            Save & Review
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};
export default SoftwareReleaseAccordian;
