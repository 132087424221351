import _ from "lodash";
import { launchesTypes } from "./launchesTypes";
import { launchesApi } from "./launchesApi";
import { contactsApi } from "../contacts/contactsApi";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: field, value: value }
    });
  };
};

const onGetLaunchesList =
  ({ launchPlan,type , pageSize, pageNo, orderByKey, orderByVal }) =>
  async dispatch => {
    let response = await launchesApi.getLaunchesList({
      launchPlan,
      type,
      pageSize,
      pageNo,
      orderByKey,
      orderByVal
    });
    if (response && response.success) {
      dispatch({
        type: launchesTypes.LAUNCHES_ONCHANGE,
        payload: { prop: "launches", value: response?.data?.data }
      });
      dispatch({
        type: launchesTypes.LAUNCHES_ONCHANGE,
        payload: {
          prop: "paginationResponse",
          value: response?.data?.pagination
        }
      });
    } else {
      dispatch({
        type: launchesTypes.LAUNCHES_ONCHANGE,
        payload: { prop: "launches", value: [] }
      });
      dispatch({
        type: launchesTypes.LAUNCHES_ONCHANGE,
        payload: {
          prop: "paginationResponse",
          value: {
            currentCount: 0,
            totalRecords: 0
          }
        }
      });
    }
    return response;
  };

const onGetLaunches = () => async dispatch => {
  let response = await launchesApi.getLaunches();
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "launches", value: response.data }
    });
  }
  return response;
};
const onGetDropDownLaunches = () => async dispatch => {
  let response = await launchesApi.getDropDownLaunches();
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "launches", value: response.data }
    });
  }
  return response;
};

const onGetLaunchById = id => async dispatch => {
  return await launchesApi.getLaunchById(id);
};

const onGetRegistrationPendingApprovalProducts =
  (pageSize, pageNo, orderByKey, orderByVal) => async dispatch => {
    let response = await launchesApi.getRegistrationPendingApprovalProducts(
      pageSize,
      pageNo,
      orderByKey,
      orderByVal
    );
    if (response && response.success) {
      dispatch({
        type: launchesTypes.LAUNCHES_ONCHANGE,
        payload: { prop: "pendingApprovalProducts", value: response.data.data }
      });
    }
    return response;
  };

const onGetNewestApprovedRegistrationProducts =
  (pageSize, pageNo, orderByKey, orderByVal) => async dispatch => {
    let response = await launchesApi.getNewestApprovedRegistrationProducts(
      pageSize,
      pageNo,
      orderByKey,
      orderByVal
    );
    if (response && response.success) {
      dispatch({
        type: launchesTypes.LAUNCHES_ONCHANGE,
        payload: { prop: "newestApprovedProducts", value: response.data.data }
      });
    }
    return response;
  };

const onAddUpdateLaunch = data => async dispatch => {
  return await launchesApi.addupdateLaunch(data);
};

const onGetLaunchCategories = () => async dispatch => {
  return await launchesApi.getLaunchCategoriesDD();
};

const onAddLaunches = planName => async dispatch => {
  let response = await launchesApi.addLaunches(planName);
  return response;
};

const onUpdateLaunches = (planName, planDate, launchId) => async dispatch => {
  let response = await launchesApi.updateLaunches(planName, planDate, launchId);
  return response;
};

const onGetLaunchActivities = lId => async dispatch => {
  return await launchesApi.getLaunchActivities({
    launchId: lId
  });
};

const onGetLaunchNotes = lId => async dispatch => {
  return await launchesApi.getLaunchNotes({
    launchId: lId
  });
};

const onAddUpdateNotes = note => async dispatch => {
  return await launchesApi.addUpdateNotes(note);
};

const onDeleteLaunchNote = noteId => async dispatch => {
  return await launchesApi.deleteLaunchtNote(noteId);
};

const onSelectLaunches = value => async dispatch => {
  dispatch({
    type: launchesTypes.SELECTED_LAUNCHES,
    payload: value
  });
};

const onAllSelectedLaunches = allSelectedLanches => async dispatch => {
  dispatch({
    type: launchesTypes.ALL_SELECTED_LANUCHES,
    payload: [...allSelectedLanches]
  });
};

const onSelectReleases = value => async dispatch => {
  dispatch({
    type: launchesTypes.SELECTED_RELEASES,
    payload: value
  });
};

const onAllSelectReleases = value => async dispatch => {
  dispatch({
    type: launchesTypes.ALL_SELECTED_RELEASES,
    payload: [...value]
  });
};

const onSelectRelCategory = value => async dispatch => {
  dispatch({
    type: launchesTypes.SELECTED_REL_CATEGORY,
    payload: value
  });
};

const onGetReleaseLocation = () => async dispatch => {
  let response = await launchesApi.getReleaseLocation();
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "releaseLocations", value: response.data }
    });
  }
  return response;
};

const onGetReleaseRelatedEvents = () => async dispatch => {
  let response = await launchesApi.getReleaseRelatedEvents();
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "releaseRelatedEvents", value: response.data }
    });
  }
  return response;
};

const onGetReleaseNewsRoomVisibilities = () => async dispatch => {
  let response = await launchesApi.getReleaseNewsRoomVisibilities();
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "releaseNewsRoomVisibilities", value: response.data }
    });
  }
  return response;
};

// const onGetReleaseCategory = () => async dispatch => {
//   let response = await launchesApi.getReleaseCategory();
//   if (response && response.success) {
//     dispatch({
//       type: launchesTypes.LAUNCHES_ONCHANGE,
//       payload: { prop: "releaseCategories", value: response.data }
//     });
//   }
//   return response;
// };

const onGetReleaseCategories = () => async dispatch => {
  let response = await launchesApi.getReleaseCategories();
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "releaseCategories", value: response.data }
    });
  }
  return response;
};

const onAddReleaseCategory = planName => async dispatch => {
  let response = await launchesApi.addReleaseCategory(planName);
  return response;
};

const onUpdateReleaseCategory = (planName, releaseId) => async dispatch => {
  let response = await launchesApi.updateReleaseCategory(planName, releaseId);
  return response;
};

const onGetAllReleases = modal => async dispatch => {
  let response = await launchesApi.getAllReleases(modal);
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "allReleases", value: response.data.data }
    });
  }
  return response;
};

const onGetReleases = () => async dispatch => {
  let response = await launchesApi.getReleases();
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "releases", value: response.data }
    });
  }
  return response;
};

const onGetReleasById = id => async dispatch => {
  let response = await launchesApi.getReleaseById(id);
  if (response && response.success) {
    console.log("release data : ", response.data);
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "specificRelease", value: response.data }
    });
  }
  return response;
};

const onDeleteLaunches = selectedLaunches => async dispatch => {
  const launchId = [];
  _.forEach(selectedLaunches, function (launch) {
    launchId.push(launch.id);
  });
  let response = await launchesApi.deleteLaunches(launchId);
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "selectedLaunches", value: [] }
    });
  }
  return response;
};

const onDeleteRelease = selectedReleases => async dispatch => {
  const releaseId = [];
  _.forEach(selectedReleases, function (release) {
    releaseId.push(release.id);
  });
  let response = await launchesApi.deleteReleases(releaseId);
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "selectedRelease", value: [] }
    });
  }
  return response;
};

const onDeleteRelCategory = ids => async dispatch => {
  let response = await launchesApi.deleteRelCategory(ids);
  return response;
};
const uploadImage = image => async dispatch => {
  // console.log("actions.upload", image);
  let response = await launchesApi.uploadImage(image);
  // console.log("actions.upload.response", response);
  if (response && response.success) {
    // console.log("Imgae Upload Success");
  }
  return response;
};

const onCreateReleases = data => async dispatch => {
  let response = await launchesApi.createRelease(data);
  return response;
};

const onGetFeatureFiles = () => async dispatch => {
  let response = await launchesApi.getFeaturFiles();
  return response;
};

const onUpdateReleases = (data, releaseId) => async dispatch => {
  let response = await launchesApi.updateRelease(data, releaseId);
  return response;
};

const onSelectCollectionAsset = value => async dispatch => {
  dispatch({
    type: launchesTypes.SELECTED_COLLECTION_ASSET,
    payload: value
  });
};

const onGetReleaseVisibility = () => async dispatch => {
  let response = await launchesApi.getReleaseVisibility();
  if (response && response.success) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "releaseVisibilities", value: response.data }
    });
  }
  return response;
};

const onSelectReleaseVisibility = (index, name) => async dispatch => {
  dispatch({
    type: launchesTypes.SELECT_RELEASE_VISIBILITY,
    payload: { index: index, name: name }
  });
};

const onAddPreRelease = () => async dispatch => {
  dispatch({
    type: launchesTypes.PRE_RELEASES
  });
};

const onGetAllUserGroup = () => async dispatch => {
  return await contactsApi.getAllUserGroup();
};

const onGetAllEvents = modal => async dispatch => {
  let response = await launchesApi.getAllEvents(modal);
  return response;
};
const onGetEventsCategory = () => async dispatch => {
  let response = await launchesApi.getEventsCategory();
  if (response) {
    dispatch({
      type: launchesTypes.LAUNCHES_ONCHANGE,
      payload: { prop: "eventCategory", value: response.data }
    });
  }
  return response;
};

const onAddUpdateEvent = data => async dispatch => {
  let response = await launchesApi.addUpdateEvent(data);
  return response;
};
const onDeleteEvents = data => async dispatch => {
  let response = await launchesApi.deleteEvents(data);
  return response;
};
const onGetEventById = id => async dispatch => {
  let response = await launchesApi.getEventById(id);
  return response;
};
const onGetLocationsAutoComplete = name => async dispatch => {
  let response = await launchesApi.getLocationsAutoComplete(name);
  return response;
};
const onGetEventCategories = modal => async dispatch => {
  let response = await launchesApi.getEventCategories(modal);
  return response;
};
const onAddUpdateEventCategory = data => async dispatch => {
  let response = await launchesApi.addUpdateEventCategory(data);
  return response;
};
const onGetEventCatById = id => async dispatch => {
  let response = await launchesApi.getEventCatById(id);
  return response;
};

const onDeleteEventsCatById = id => async dispatch => {
  let response = await launchesApi.deleteEventsCatById(id);
  return response;
};
const onGetEventType = () => async dispatch => {
  let response = await launchesApi.getEventType();
  return response;
};

const onAddUpdateReleaseCategory = data => async dispatch => {
  let response = await launchesApi.addUpdateReleaseCategory(data);
  return response;
};

const onGetReleaseCategory = data => async dispatch => {
  let response = await launchesApi.getReleaseCategory(data);
  return response;
};

const onGetReleaseCatById = data => async dispatch => {
  let response = await launchesApi.getReleaseCatById(data);
  return response;
};
const onGetEventDropDown = value => async dispatch => {
  let response = await launchesApi.getEventDropDown(value);
  return response;
};

const onGetDropdownValues =
  ({ url }) =>
  async dispatch => {
    return await launchesApi.getDropdownValues({ url });
  };
const onAddUpdateLaunchCategory = data => async dispatch => {
  let response = await launchesApi.addUpdateLaunchCat(data);
  return response;
};

const onGetLaunchCategoryById = id => async dispatch => {
  let response = await launchesApi.getLaunchCategoryById(id);
  return response;
};

const onGetLaunchCategory = data => async dispatch => {
  let response = await launchesApi.getLaunchCategory(data);
  return response;
};

const onDeleteLaunchCategory = data => async dispatch => {
  let response = await launchesApi.deleteLaunchCategory(data);
  return response;
};

export const launchesActions = {
  onGetLaunches: onGetLaunches,
  onGetLaunchById: onGetLaunchById,
  onAddUpdateLaunch: onAddUpdateLaunch,
  onGetLaunchCategories: onGetLaunchCategories,
  onAddLaunches: onAddLaunches,
  onUpdateLaunches: onUpdateLaunches,
  onGetLaunchActivities: onGetLaunchActivities,
  onGetLaunchNotes: onGetLaunchNotes,
  onAddUpdateNotes: onAddUpdateNotes,
  onDeleteLaunchNote: onDeleteLaunchNote,
  onSelectLaunches: onSelectLaunches,
  onChange: onChange,
  onGetReleaseLocation: onGetReleaseLocation,
  onAddReleaseCategory: onAddReleaseCategory,
  onUpdateReleaseCategory: onUpdateReleaseCategory,
  onGetAllReleases: onGetAllReleases,
  onGetReleases: onGetReleases,
  onGetReleasById: onGetReleasById,
  onGetReleaseCategories: onGetReleaseCategories,
  onDeleteLaunches: onDeleteLaunches,
  onSelectReleases: onSelectReleases,
  onDeleteRelease: onDeleteRelease,
  onSelectRelCategory: onSelectRelCategory,
  onDeleteRelCategory: onDeleteRelCategory,
  uploadImage: uploadImage,
  onCreateReleases: onCreateReleases,
  onGetFeatureFiles: onGetFeatureFiles,
  onUpdateReleases: onUpdateReleases,
  onSelectCollectionAsset: onSelectCollectionAsset,
  onGetReleaseVisibility: onGetReleaseVisibility,
  onSelectReleaseVisibility: onSelectReleaseVisibility,
  onAddPreRelease: onAddPreRelease,
  onGetReleaseRelatedEvents: onGetReleaseRelatedEvents,
  onGetReleaseNewsRoomVisibilities: onGetReleaseNewsRoomVisibilities,
  onGetAllUserGroup: onGetAllUserGroup,
  onGetAllEvents: onGetAllEvents,
  onGetEventsCategory: onGetEventsCategory,
  onAddUpdateEvent: onAddUpdateEvent,
  onDeleteEvents: onDeleteEvents,
  onGetEventById: onGetEventById,
  onGetLocationsAutoComplete: onGetLocationsAutoComplete,
  onGetEventCategories: onGetEventCategories,
  onAddUpdateEventCategory: onAddUpdateEventCategory,
  onGetEventCatById: onGetEventCatById,
  onDeleteEventsCatById: onDeleteEventsCatById,
  onGetEventType: onGetEventType,
  onAddUpdateReleaseCategory: onAddUpdateReleaseCategory,
  onGetReleaseCategory: onGetReleaseCategory,
  onGetReleaseCatById: onGetReleaseCatById,
  onGetEventDropDown: onGetEventDropDown,
  onGetLaunchesList: onGetLaunchesList,
  onGetRegistrationPendingApprovalProducts:
    onGetRegistrationPendingApprovalProducts,
  onGetNewestApprovedRegistrationProducts:
    onGetNewestApprovedRegistrationProducts,
  onGetDropDownLaunches: onGetDropDownLaunches,
  onGetDropdownValues: onGetDropdownValues,
  onGetLaunchCategoryById: onGetLaunchCategoryById,
  onAddUpdateLaunchCategory: onAddUpdateLaunchCategory,
  onGetLaunchCategory: onGetLaunchCategory,
  onDeleteLaunchCategory: onDeleteLaunchCategory,
  onAllSelectedLaunches:onAllSelectedLaunches,
  onAllSelectReleases:onAllSelectReleases,
};
