import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from "@material-ui/core";
import { ItemImage } from "../../../../components/common";
import { AddIconWithBGColor, SortIcon } from "../../LeftNav/SvgIcons";
import { SortedByFilterCheckbox } from "../../../../components/common/checkbox/SortedByFilterCheckbox";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    background: "#E5F2FF",
  },
  productGridButtonCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",

    "& .makeStyles-icon-335": {
      background: "#E1E3EA",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      fontSize: "12px",
      fontWeight: "400",
      color: "#2D3339",
    },
  },
  buttonCell:{
    textAlign: "center",
  },
  addedBtn: {
    fontSize: "12px",
      width:"80px",
      height: "30px",      
      fontWeight: "500",
    "& .MuiButton-root": {
      
    },
    backgroundColor: "#18BC6D",
    "&:hover": {
      backgroundColor: "#18BC6D",
    },  
  },
}));

const SoftwareProductGrid = ({
  listData,
  order,
  onOrderBy,
  addDevice,
  onCheckboxaddDevice,
  checkboxSelectedDevices,
  addMultiple,
  setSelectAllCheckBox,
  selectAllCheckBox,
  selectedReleaseProducts,
}) => {
  const classes = useStyles();
  return (
    <Paper
      style={{ maxHeight: "268px", overflow: "auto" }}
      className="eligibDeviceTable"
    >
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow className="eligibleTableCell">
            <TableCell className="first_cell eligibleTableCell">
              Product Name
              <TableSortLabel
                active={order.orderByKey === "ProductName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "ProductName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className="sortIcon"
              />
            </TableCell>
            <TableCell className="eligibleTableCell">
              SKU
              <TableSortLabel
                active={order.orderByKey === "SKU"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "SKU",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className="sortIcon"
              />
            </TableCell>
            <TableCell>
              Brand Name
              <TableSortLabel
                active={order.orderByKey === "Brand Name"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Brand Name",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className="sortIcon"
              />
            </TableCell>
            <TableCell>
              Category
              <TableSortLabel
                active={order.orderByKey === "Category"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Category",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className="sortIcon"
              />
            </TableCell>
            <TableCell >
              <div className={classes.productGridButtonCell}>
              <SortedByFilterCheckbox 
               handleCheckboxChange={() => { 
                setSelectAllCheckBox(!selectAllCheckBox);
                listData.forEach((x)=> onCheckboxaddDevice(x))
              }}
               checked={selectAllCheckBox}
              />
              <div style={{ cursor: "pointer",display:"flex"}} onClick={() => addMultiple()}>
                <AddIconWithBGColor />
              </div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {listData?.map((item, index) => {
            return (
              <TableRow key={index} className="gridBodyRow">
                <TableCell>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "17px",
                    }}
                  >
                    <ItemImage
                      imgSrc={item?.image}
                      alt={item?.name}
                      width="32px"
                      height="32px"
                      paddding="unset"
                    />
                    <span>{item.name ? item.name : "--"}</span>
                  </span>
                </TableCell>
                <TableCell>{item.sku ?? "--"}</TableCell>
                <TableCell className="brand_column">
                  {item.brand ?? "Aquatic AV"}
                </TableCell>
                <TableCell>{item.category ?? "--"}</TableCell>
                <TableCell className={classes.buttonCell} >
                  {selectedReleaseProducts.find(x => Number(x.productId) === Number(item.id)) ? <Button
                          className={classes.addedBtn}
                          color="primary"
                          variant="contained"
                        >
                        <CheckIcon viewBox="0 0 30 24" />  Added
                        </Button> : (<div className={classes.productGridButtonCell}>
                    <SortedByFilterCheckbox
                      checked={checkboxSelectedDevices.includes(item)}
                      handleCheckboxChange={() => onCheckboxaddDevice(item)}
                    />
                    <div
                      style={{ cursor: "pointer", display: "flex" }}
                      onClick={() => addDevice(item)}
                    >
                      <AddIconWithBGColor />
                    </div>
                  </div>)}
                </TableCell>
              </TableRow>
            );
          })}
          {listData.length <= 0 && (
            <TableRow key="0" className="gridBodyRow">
              <TableCell colSpan="7" style={{ textAlign: "center" }}>
                <span className="emptyGridText">
                  Uh Oh! You haven’t create any product.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SoftwareProductGrid;
