import React, { useRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ValidatorForm } from "react-material-ui-form-validator";
import CloseIcon from "@material-ui/icons/Close";
import ProductRegistration from "../../../ofishadmin/views/Dashboard/EndUser/ProductRegistration";
import ProductImageView from "../../../ofishadmin/views/Dashboard/EndUser/ProductImageView";
import crossBtn from "../../../resources/images/cross.png";

const ProductRegistrationPopup = ({ closePopup, openPopup }) => {
  return (
    <div>
      <Dialog
        open={openPopup}
        onClose={closePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ValidatorForm ref={useRef("form")} onSubmit={() => alert("ok")}>
          <DialogTitle id="alert-dialog-title">
            <img
              src={crossBtn}
              className="viewProductPopup"
              onClick={closePopup}
              alt=""
            />
            {/* <CloseIcon className="closePopup" onClick={closePopup} /> */}
          </DialogTitle>
          <DialogContent className="addLaunchPopupWrapper">
            <DialogContentText id="alert-dialog-description">
              <ProductRegistration />
            </DialogContentText>
          </DialogContent>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};

export default ProductRegistrationPopup;
