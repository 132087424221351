import _ from "lodash";
import { assetsApi } from "./assetsApi";
import { assetsTypes } from "./assetsTypes";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: field, value: value }
    });
  };
};

const onGetAssetManagementList =
  ({
    parentId,
    isDeleted,
    type,
    search,
    time,
    isfavorite,
    pageSize,
    pageNo,
    orderByKey,
    orderByVal,
    orderByAlphabet,
    orderByRecentlyUpdated,
    orderByCreatedDate,
    orderByFileSize,
  }) =>
  async dispatch => {
    let response = await assetsApi.getAssetManagementList({
      parentId,
      isDeleted,
      type,
      search,
      time,
      isfavorite,
      pageSize,
      pageNo,
      orderByKey,
      orderByVal,
      orderByAlphabet,
      orderByRecentlyUpdated,
      orderByCreatedDate,
      orderByFileSize,
    });
    if (response && response.success) {
      dispatch({
        type: assetsTypes.ASSETS_ONCHANGE,
        payload: { prop: "gridData", value: response.data.data.map(x => ({ ...x, isChecked: false, level: 1 })) }
      });
      dispatch({
        type: assetsTypes.ASSETS_ONCHANGE,
        payload: {
          prop: "paginationResponse",
          value: response.data.pagination
        }
      });
      dispatch({
        type: assetsTypes.ASSETS_ONCHANGE,
        payload: {
          prop: "assetsStats",
          value: response.data.assetsStats,
        }
        
      });
    } else {
      dispatch({
        type: assetsTypes.ASSETS_ONCHANGE,
        payload: { prop: "gridData", value: [] }
      });
      dispatch({
        type: assetsTypes.ASSETS_ONCHANGE,
        payload: {
          prop: "paginationResponse",
          value: {
            currentCount: 0,
            totalRecords: 0
          }
        }
      });
    }
    return response;
  };

  const onGetFolderPreview =
  ({
    parentId,
    type,
    search,
    time,
    isfavorite,
    pageSize,
    pageNo,
    orderByKey,
    orderByVal
  }) =>
  async dispatch => {
    let response = await assetsApi.getAssetManagementList({
      parentId,
      type,
      search,
      time,
      isfavorite,
      pageSize,
      pageNo,
      orderByKey,
      orderByVal
    });
    if (response && response.success) {
      dispatch({
        type: assetsTypes.ASSETS_PREVIEWDATA,
        payload: { prop: "gridData", value: response.data.data }
      });
    }
    return response;
  };

const getFavoriteAssets =
  (
    parentId,
    type,
    search,
    time,
    isfavorite,
    pageSize,
    pageNo,
    orderByKey,
    orderByVal
  ) =>
  async dispatch => {
    let response = await assetsApi.getAssetManagementList(
      parentId,
      type,
      search,
      time,
      isfavorite,
      pageSize,
      pageNo,
      orderByKey,
      orderByVal
    );
    if (response && response.success) {
      if (response.data) {
        dispatch({
          type: assetsTypes.ASSETS_ONCHANGE,
          payload: { prop: "favoritesAssets", value: response.data.data }
        });
      }
    }
    return response;
  };

const onGetAssetManagementListForPopup = (parentId, type) => async dispatch => {
  let response = await assetsApi.getAssetManagementListForPopup(parentId, type);
  if (response && response.success) {
    if (response.data) {
      dispatch({
        type: assetsTypes.ASSETS_ONCHANGE,
        payload: { prop: "mediaAssetsFolder", value: response.data.data }
      });
      if (response.data.superParentId) {
        dispatch(
          assetsActions.onChange("previousFolder", response.data.superParentId)
        );
      } else {
        dispatch(assetsActions.onChange("previousFolder", 0));
      }
    }
  }
  return response;
};

const onGetFoldersOnly = assestId => async dispatch => {
  let response = null;
  if (assestId == null) {
    response = await assetsApi.getFoldersOnly();
  } else {
    response = await assetsApi.getParentFoldersOnly(assestId);
  }
  if (response && response.success) {
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: "mediaAssetsFolder", value: response.data }
    });
  }
  return response;
};

const onGetMediaAssets = () => async dispatch => {
  let response = await assetsApi.getMediaAssets();
  if (response && response.success) {
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: "gridData", value: response.data }
    });
  }
  return response;
};

const onSelectMediaAssets = value => async dispatch => {
  dispatch({
    type: assetsTypes.SELECTED_ASSETS,
    payload: value
  });
};
const onSelectAllMediaAssets = value => async dispatch => {
  dispatch({
    type: assetsTypes.SELECT_ALL_ASSETS,
    payload: [...value]
  })
};
const onDeleteMediaAssets = selectedAssets => async dispatch => {
  // const assetsId = [];
  // _.forEach(selectedAssets, function (assets) {
  //   assetsId.push(assets?.id);
  // });
  let response = await assetsApi.deleteMediaAssets(selectedAssets);
  return response;
};
const onDeleteMediaAssetsPermanently = selectedAssets => async dispatch => {
  let response = await assetsApi.deleteMediaAssetsPermanently(selectedAssets);
  return response;
};
const onRecoverMediaAsset = selectedAssets => async dispatch => {
  let response = await assetsApi.recoverMediaAsset(selectedAssets);
  return response;
};
const onAddAssetsFolder = (folderName, folderId, isfavorite) => async dispatch => {
  let response = await assetsApi.addAssetsFolder(folderName, folderId, isfavorite);
  return response;
};

const onGetAllCollection = () => async dispatch => {
  let response = await assetsApi.getAllCollection();
  if (response && response.success) {
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: "allCollections", value: response.data }
    });
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: "copyAllCollections", value: response.data }
    });
  }
  return response;
};

const onSelectAllCollection = value => async dispatch => {
  dispatch({
    type: assetsTypes.SELECTED_ALL_COLLECTION,
    payload: [...value]
  });
};

const onDeleteAllCollection = selectedCollection => async dispatch => {
  const collectionId = [];
  _.forEach(selectedCollection, function (assets) {
    collectionId.push(assets.id);
  });
  let response = await assetsApi.deleteAllCollection(collectionId);
  if (response && response.success) {
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: "selectedAllCollection", value: [] }
    });
  }
  return response;
};

const onGetColCategories = colCategeoryParam => async dispatch => {
  let response = await assetsApi.getColCategories(colCategeoryParam);
  if (response && response.success) {
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: "colCategories", value: response.data.data }
    });
  }
  return response;
};

const onSelectColCategory = value => async dispatch => {
  dispatch({
    type: assetsTypes.SELECTED_COL_CATEGORY,
    payload: value
  });
};
const onDeleteColCategories = selectedColCollection => async dispatch => {
  const collectionId = [];
  _.forEach(selectedColCollection, function (assets) {
    collectionId.push(assets.id);
  });
  let response = await assetsApi.deleteColCategories(collectionId);
  if (response && response.success) {
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: "selectedColCategory", value: [] }
    });
  }
  return response;
};

const uploadImage = (image, parentId) => async dispatch => {
  let response = await assetsApi.uploadImage(image, parentId);
  if (response && response.success) {
  }
  return response;
};

const onMoveMediaFiles = (selectedAssets, parentId) => async dispatch => {
  // const assetsId = [];
  // _.forEach(selectedAssets, function (assets) {
  //   assetsId.push(assets.id);
  // });
  let response = await assetsApi.moveMediaFiles(selectedAssets, parentId);
  return response;
};

const onAddColCategory = categoryName => async dispatch => {
  let response = await assetsApi.addColCategory(categoryName);
  return response;
};

const onUpdateColCategory = (categoryName, categoryId) => async dispatch => {
  let response = await assetsApi.updateColCategory(categoryName, categoryId);
  return response;
};

const onAddCollection = collection => async dispatch => {
  let response = await assetsApi.addCollection(collection);
  return response;
};

const onUpdateCollection = (collection, collectionId) => async dispatch => {
  let response = await assetsApi.updateCollection(collection, collectionId);
  return response;
};

const onGetMediaAsset = assestId => async dispatch => {
  let response = await assetsApi.getMediaAsset(assestId);
  return response;
};

const onGetMediaAssetById = assestId => async dispatch =>
  await assetsApi.getMediaAssetById(assestId);

const onGetMediaAllAssetById = assestId => async dispatch =>
  await assetsApi.getMediaAllAssetById(assestId);

const onGetCollectionVisibility = () => async dispatch => {
  let response = await assetsApi.getCollectionVisibility();
  if (response && response.success) {
    dispatch({
      type: assetsTypes.ASSETS_ONCHANGE,
      payload: { prop: "collectionVisibilities", value: response.data }
    });
  }
  return response;
};

const onSelectCollectionVisibility = (index, name) => async dispatch => {
  dispatch({
    type: assetsTypes.SELECT_COLLECTION_VISIBILITY,
    payload: { index: index, name: name }
  });
};

const onFileTypeSelect = value => async dispatch => {
  dispatch({
    type: assetsTypes.ON_SELECT_FILETYPE,
    payload: value
  });
};

const onPressFilterButton = (dayFilter, searchText) => async dispatch => {
  dispatch({
    type: assetsTypes.ON_PRESS_FILTER,
    payload: { dayFilter: dayFilter, searchText: searchText }
  });
};

const onCatTypeSelect = value => async dispatch => {
  dispatch({
    type: assetsTypes.ON_SELECT_CATTYPE,
    payload: value
  });
};

const onCollectionFilterButton = (dayFilter, searchText) => async dispatch => {
  dispatch({
    type: assetsTypes.ON_PRESS_FILTER_CAT,
    payload: { dayFilter: dayFilter, searchText: searchText }
  });
};

const addFavorite = (assetId, isFavrotie) => async dispatch => {
  let response = await assetsApi.addFavorite(assetId, isFavrotie);
  // if (response && response.success) {
  //   dispatch(onGetMediaAssets());
  //   dispatch(getFavoriteAssets());
  // }
  return response;
};
const updateAssestName = (asset)=> async dispatch =>{
  const response = await assetsApi.updateAssestName(asset);
  return response;
};

const getAllAssetsForPopup = (uploadSelectionParentId) => async (dispatch) => {
  let response = await assetsApi.getAllAssetsForPopup(uploadSelectionParentId);
  return response;
};

const onGetColCatById = colCatId => async dispatch => {
  let response = await assetsApi.getColCatById(colCatId);
  return response;
};

const onGetAllAssertCollection = value => async dispatch => {
  let response = await assetsApi.getAllAssertCollection(value);
  return response;
};

const onGetCollectionsDropdown = value => async dispatch => {
  return await assetsApi.getCollectionsDropdown(value);
};

const onGetCollectionsPopup = value => async dispatch => {
  return await assetsApi.getCollectionsPopup(value);
};

const onAddUpdateCollections = value => async dispatch => {
  let response = await assetsApi.addupdateCollection(value);
  return response;
};
const onGetCollectionCategoryDropdown = value => async dispatch => {
  let response = await assetsApi.getCollectionCategoryDropdown(value);
  return response;
};
const onDeleteCollection = value => async dispatch => {
  let response = await assetsApi.deleteCollection(value);
  return response;
};
const onGetAssertCollectionById = value => async dispatch => {
  let response = await assetsApi.getAssertCollectionById(value);
  return response;
};

export const assetsActions = {
  onChange: onChange,
  onGetMediaAssets: onGetMediaAssets,
  onGetMediaAssetById: onGetMediaAssetById,
  onSelectMediaAssets: onSelectMediaAssets,
  onDeleteMediaAssets: onDeleteMediaAssets,
  onDeleteMediaAssetsPermanently:onDeleteMediaAssetsPermanently,
  onRecoverMediaAsset:onRecoverMediaAsset,
  onAddAssetsFolder: onAddAssetsFolder,
  onGetAllCollection: onGetAllCollection,
  onGetCollectionsDropdown: onGetCollectionsDropdown,
  onGetCollectionsPopup: onGetCollectionsPopup,
  onSelectAllCollection: onSelectAllCollection,
  onGetColCategories: onGetColCategories,
  onSelectColCategory: onSelectColCategory,
  onDeleteAllCollection: onDeleteAllCollection,
  onDeleteColCategories: onDeleteColCategories,
  uploadImage: uploadImage,
  onMoveMediaFiles: onMoveMediaFiles,
  onAddColCategory: onAddColCategory,
  onUpdateColCategory: onUpdateColCategory,
  onAddCollection: onAddCollection,
  onUpdateCollection: onUpdateCollection,
  onGetFoldersOnly: onGetFoldersOnly,
  onGetCollectionVisibility: onGetCollectionVisibility,
  onSelectCollectionVisibility: onSelectCollectionVisibility,
  onFileTypeSelect: onFileTypeSelect,
  onPressFilterButton: onPressFilterButton,
  onCatTypeSelect: onCatTypeSelect,
  onCollectionFilterButton: onCollectionFilterButton,
  getFavoriteAssets: getFavoriteAssets,
  addFavorite: addFavorite,
  updateAssestName:updateAssestName,
  getAllAssetsForPopup:getAllAssetsForPopup,
  onGetMediaAsset: onGetMediaAsset,
  onGetColCatById: onGetColCatById,
  onGetAllAssertCollection: onGetAllAssertCollection,
  onAddUpdateCollections: onAddUpdateCollections,
  onGetCollectionCategoryDropdown: onGetCollectionCategoryDropdown,
  onDeleteCollection: onDeleteCollection,
  onGetAssertCollectionById: onGetAssertCollectionById,
  onGetAssetManagementList: onGetAssetManagementList,
  onGetAssetManagementListForPopup: onGetAssetManagementListForPopup,
  onGetMediaAllAssetById: onGetMediaAllAssetById,
  onGetFolderPreview: onGetFolderPreview,
  onSelectAllMediaAssets:onSelectAllMediaAssets,
};
