import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { Add } from "@material-ui/icons";
import { useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import CloseIcon from "@material-ui/icons/Close";
import { Chevron, ChevronRight, ChipCross } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import SoftwareGridFilter from "./SoftwareGridFilter";
import CustomCheckbox from "../../../../components/common/checkbox/CustomCheckbox";
import RadioButtons from "../../../../components/common/RadioButton/RadioButton";
import { InputField, SelectInput } from "../../../../components/common";
import { FiChevronDown } from "react-icons/fi";
import { CustomSoftwareReleasePopup } from "./CustomSoftwareReleasePopup";
import { useDebounce } from "../../../../utils/use-debounce";
import Loader from "../../../../components/common/Loader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const portalTabs = [
  {
    id: "all",
    title: "ALL",
    component: "",
  },
  {
    id: "MobileApps",
    title: "Mobile Apps",
    component: "",
  },
  {
    id: "WebPortals",
    title: "Web Portals",
    component: "",
  },
  {
    id: "Newsrooms",
    title: "Newsrooms",
    component: "",
  },
  {
    id: "Stores",
    title: "Stores",
    component: "",
  },
];

const contactsTabs = [
  {
    id: "All",
    title: "All",
    component: "",
  },
  {
    id: "OFish Presets",
    title: "OFish Presets",
    component: "",
  },
  {
    id: "Groups",
    title: "Groups",
    component: "",
  },
  {
    id: "Company",
    title: "Companies",
    component: "",
  },
  {
    id: "Contact",
    title: "Contacts",
    component: "",
  },
];

const listItems = [
  { id: 1, name: "Day(s)" },
  { id: 2, name: "Week(s)" },
  { id: 3, name: "Month(s)" },
];

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      width: "150px",
      height: "36px",
      marginLeft: "10px",
    },
    '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: "0",
      },
    "& .MuiAutocomplete-inputRoot": {
      flexWrap: "nowrap",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      border: "none !important",
    },
  },
  mainCustomerPricing: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "32px",
  },
  dataContiner: {
    maxHeight: "300px",
    overflow: "auto",
    paddingRight: "10px",
  },
  customerGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: " space-between",
    padding: "19px 19px 19px 12px",
    background: "#F1FAFF",
    border: " 1px dashed #00A3FF",
    borderRadius: 9,
    marginBottom: 16,
    width: 460,
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customerPricingTypo: {
    fontSize: 18,
    fontWeight: 700,
    color: "#181C32",
    fontFamily: "Roboto",
    display: "flex",
    alignItems: "center",
  },
  pricingTypoBody: {
    fontSize: 16,
    fontWeight: 700,
    color: "#3699FF",
  },
  chipContiner: {
    height:"70px",
    overflowY:'auto',
    display: "flex",
    gap: "16px",
    "& .makeStyles-chipContiner-1581 ": {
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
  noChipText: {
    marginTop: "16px",
    marginBottom: "16px",
    color: "#BDBDBD",
    fontSize: "12px",
    fontWeight: "500",
    fontStyle: "italic",
    textAlign: "center",
  },
  seeAllBtn: {
    color: "#3699FF",
    fontSize: "14px",
    fontWeight: "700",
    textTransform: "capitalize",
  },
  chipBox: {
    marginTop: "16px",
    marginBottom: "12px",
    "& .MuiChip-sizeSmall": {
      height: "36px",
      padding: "8px 16px",
      backgroundColor: "#F3F6F9",
    },
    "& .MuiChip-label": {
      fontSize: "14px",
      fontWeight: "700",
      color: "#70808F",
    },
  },
  addedBtn: {
    fontSize: "14px",
      width:"78px",
      height: "36px",      
      fontWeight: "500",
    "& .MuiButton-root": {
      
    },
    backgroundColor: "#18BC6D",
    "&:hover": {
      backgroundColor: "#18BC6D",
    },  
  },
  addBtn:{
    "&.MuiButton-root": {
      fontSize: "14px",
      fontWeight: "500",
      width:"78px",
      height: "36px",
    },
   },
   applyButton:{
    "&.MuiButton-root": {
    width: "69px",
    minWidth: "unset",
    height: "36px",
    fontSize: "14px",
    fontWeight: "500",
    }
  }
}));

const UserRequirementsAccordian = ({
  setPortalRequirmentDone,
  accordionName,
  setAccordionName,
  setAccordionKey,
  buildId,
  contactIds,
  groupIds,
  companyIds,
  portalIds,
  selectedReleasePortal,
  selectedReleaseContacts,
  isLoading,
  poratlAndContactData,
  selectedRelease,
  customerContactData,
  portalAndContactBasicsProps,
  tempContactData,
  updatedTempData,
  handleDeletedChip,
  filteredProtalData,
  setFilteredProtalData,
  tempPortalData,
  onsubmitPortalContact,
  portalListData,
  setPortalTabs,
  deletePortalSelectedDevices,
  changeContactTab,
}) => {
  const classes = useStyles();
  const { id: paramId, buildId: selectedBuildId } = useParams();
  const [filterReleaseByName, setFilterReleaseByName] = useState("");
  const [portalArray, setPortal] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openContactAccessPopup, setOpenContactAccessPopup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [portalRequirmentItem, setportalRequirmentItem] = useState(3);
  const [contactAccessItem, setContactAccessItem] = useState(3);
  const defaultItemsToShow = 3;
  const [searchSelectdPortals, setSearchSelectdPortals] = useState("");
  const [searchSelectdContacts, setSearchSelectdContacts] = useState("");
  const [portalData , setPortalData] = useState(filteredProtalData)
  const [selectedportalTabs , setSelectedPortalTabs] = useState("All");
  
  useEffect(() => {
    switch (selectedportalTabs) {
      case "MobileApps":
        const mobileAppData = filteredProtalData.filter((device) =>
          device.description.includes("Mobile App")
        );
        setPortalData(mobileAppData);
        break;
      case "WebPortals":
        const WebPortalData = filteredProtalData.filter((device) =>
          device.description.includes("Web Portal")
        );
        setPortalData(WebPortalData);
        break;
      case "Newsrooms":
        const newRoomsData = filteredProtalData.filter((device) =>
          device.description.includes("Newsroom")
        );
        setPortalData(newRoomsData);
        break;
      case "Stores":
        const newStoreData = filteredProtalData.filter((device) =>
          device.description.includes("Store")
        );
        setPortalData(newStoreData);
        break;
      default:
        setPortalData(filteredProtalData);
        break;
    }
  }, [ filteredProtalData , selectedportalTabs ]);

  const filetrdPortalsData = selectedReleasePortal?.filter(
    (X) =>
      X?.name
        ?.toLowerCase()
        .includes(searchSelectdPortals.toLocaleLowerCase()) ||
      X?.description
        .toLocaleLowerCase()
        .includes(searchSelectdPortals.toLocaleLowerCase())
  );
 
  const filetrdContactsData = updatedTempData.filter(
    (X) =>
      X?.value
        ?.toLowerCase()
        .includes(searchSelectdContacts.toLocaleLowerCase()) ||
      X?.generic
        .toLocaleLowerCase()
        .includes(searchSelectdContacts.toLocaleLowerCase())
  );

  const { debouncedValue } = useDebounce(filterReleaseByName, 500);
  
  const handlePortalRequirements = () => {
    setportalRequirmentItem((prevItems) =>
      prevItems === defaultItemsToShow
        ? selectedReleasePortal?.length
        : defaultItemsToShow
    );
  };

  const handleContactAccess = () => {
    setContactAccessItem((prevItems) =>
      prevItems === defaultItemsToShow
        ? updatedTempData.length
        : defaultItemsToShow
    );
  };

  useEffect(() => {
    if (filterReleaseByName !== "") {
      const filter = portalListData.filter((c) =>
        c.name.toLowerCase().includes(filterReleaseByName.toLowerCase())
      );
      setFilteredProtalData(filter);
    } else {
      setFilteredProtalData(portalListData);
    }

    if (filterReleaseByName !== "") {
      const filter = customerContactData.filter((c) =>
        c.value?.toLowerCase().includes(filterReleaseByName?.toLowerCase()) ||
        c.generic?.toLowerCase().includes(filterReleaseByName?.toLowerCase())
      );

      setFilteredData(filter);
    } else {
      setFilteredData(customerContactData);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (openContactAccessPopup) {
      setFilteredData(customerContactData);
    }
  }, [openContactAccessPopup, customerContactData]);

  const handlePortalData = () => {
    setOpenPopup(false);
  };

  const handleContactData = () => {
    setOpenPopup(false);
  };

 
  const onSetAccordationAction = () => {
    setAccordionName("");
    if ((!buildId && !paramId) || (buildId && !selectedBuildId)) {
      setAccordionKey(3);
      setPortalRequirmentDone(true);
    }
  };

  
  return (
    <ValidatorForm ref={useRef("form")}>
      <Grid container className="userRequirementsContainer">
        <Divider />
        <Grid item xs={12} className="portalContactContainer">
        <Grid className="portalAddContainer">
          {accordionName === "portalAndContact" && (
            <Button
              color="primary"
              className="addBtn"
              onClick={() => {
                const item = { testingItem: "Testing" };
                const portal = [...portalArray, item];
                setPortal(portal);
                setOpenPopup(true);
                setFilteredProtalData(portalListData);
              }}
              variant="contained"
              startIcon={<Add />}
            />
          )}
          <Typography variant="body1" className="portalHeadings">
            Portal Requirements
          </Typography>
          </Grid>
          <CustomSoftwareReleasePopup
            title={"Portal Requirements"}
            heading={"OFish-Powered Portals"}
            description={
              `Select the portals required for users to use this ${buildId ? "build" : "release"}.`
            }
            filterPlaceholder={"Search"}
            allTabs={portalTabs}
            tabChanged={(newTab) =>
              setSelectedPortalTabs(newTab)
            }
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setFilterReleaseByName={setFilterReleaseByName}
          >
            <Grid>
              {selectedReleasePortal?.length > 0 ? (
                <Grid>
                  <Grid container className={classes.chipContiner}>
                    {selectedReleasePortal
                      .slice(0, portalRequirmentItem)
                      .map((item) => (
                        <Grid className={classes.chipBox}>
                          <Chip
                            size="small"
                            label={item.name}
                            onDelete={() => {
                              deletePortalSelectedDevices(item);
                            }}
                            deleteIcon={
                              <i>
                                <ChipCross />
                              </i>
                            }
                          />
                        </Grid>
                      ))}
                  </Grid>
                  <Grid>
                    <Button
                      onClick={handlePortalRequirements}
                      className={classes.seeAllBtn}
                    >
                      {portalRequirmentItem === defaultItemsToShow
                        ? "See All"
                        : "See Less"}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.noChipText}>
                  Added portals will appear here
                </Grid>
              )}
            </Grid>

            <Grid className={classes.dataContiner}>
              <Grid item xs={12} className={classes.mainCustomerPricing}>
                {portalData.map((currValue) => (
                  <Grid key={`${currValue?.id}-${currValue?.description}`}>
                    <Grid className={classes.customerGrid}>
                      <Box className={classes.innerBox}>
                        <Box>
                          <Typography className={classes.customerPricingTypo}>
                            {currValue?.name}
                          </Typography>
                          <Typography className={classes.pricingTypoBody}>
                            {currValue?.description}
                          </Typography>
                        </Box>
                      </Box>
                      {selectedReleasePortal?.find(
                        (item) => item.id === currValue.id
                      ) ? (
                        <Button
                          className={classes.addedBtn}
                          color="primary"
                          variant="contained"
                        >
                        <CheckIcon viewBox="0 0 30 24" />  Added
                        </Button>
                      ) : (
                        <Button
                          className={classes.addBtn}
                          color="primary"
                          variant="contained"
                          startIcon={<Add />}
                          onClick={() => {
                            tempPortalData(currValue?.id);
                          }}
                        >
                          Add
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid className="notificationPopupButton">
              <Button
                color="primary"
                variant="contained"
                className="skipButton"
                onClick={() => setOpenPopup(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => handlePortalData()}
                variant="contained"
                className={classes.applyButton}
              >
                Apply
              </Button>
            </Grid>
          </CustomSoftwareReleasePopup>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body2">
            Select the OFish-Powered portals that this {buildId ? " build" : " release"} can be downloaded
            from.
          </Typography>
        </Grid>
        {selectedReleasePortal?.length > 0 ? (
          <>
            <Grid xs={12} className="userRequirementSearch">
              <Typography variant="body1">
                {selectedReleasePortal?.length ?? 0} OFish-Powered Portals
                Applied
              </Typography>
              <SoftwareGridFilter
                search={searchSelectdPortals}
                setSearch={setSearchSelectdPortals}
                placeholder="Search"
              />
            </Grid>

            <Grid item className="useRequirementsChips">
              <List>
                <ListItem>
                  <Grid className="chevronIcon">
                    <IconButton onClick={handlePortalRequirements}>
                      <ChevronRight />
                    </IconButton>
                  </Grid>
                  <Grid className="portalItemGrid">
                    {filetrdPortalsData
                      .slice(0, portalRequirmentItem)
                      .map((item) => (
                        <Grid className="portalChips">
                          <Typography className="chipsTypo">
                            {item.description}
                          </Typography>
                          &nbsp;
                          <Typography className="deviceName">
                            {item.name}
                          </Typography>
                          <div
                            className="closeBtn"
                            onClick={() => {
                              deletePortalSelectedDevices(item);
                            }}
                          >
                            <CloseIcon />
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </>
        ) : (
          <Grid xs={12} className="addChip">
            <span className="content">
              You haven’t added any portals. Start by adding a 
            </span>
            &nbsp;
            <span
              className="portal"
              onClick={() => {
                const item = { testingItem: "Testing" };
                const portal = [...portalArray, item];
                setPortal(portal);
                setOpenPopup(true);
                setFilteredProtalData(portalListData);
              }}
            >
              Portal
            </span>
          </Grid>
        )}
        <Grid item xs={12} className="portalContactContainer">
        <Grid className="portalAddContainer">
          {accordionName === "portalAndContact" && (
            <Button
              color="primary"
              onClick={() => {
                setOpenContactAccessPopup(true);
              }}
              className="addBtn"
              variant="contained"
              startIcon={<Add />}
            />
          )}
          
          <Typography variant="body1" className="portalHeadings">
            Contact Access
          </Typography>
          </Grid>
          <CustomSoftwareReleasePopup
            title={"Contact Access"}
            heading={"Contacts"}
            description={
              "Select the contacts that will have access to this release."
            }
            filterPlaceholder={"Search"}
            allTabs={contactsTabs}
            tabChanged={
              (newTab) => changeContactTab(newTab)
            }
            openPopup={openContactAccessPopup}
            setOpenPopup={setOpenContactAccessPopup}
            setFilterReleaseByName={setFilterReleaseByName}
          >
            <Grid>
              {isLoading && <Loader />}
              {updatedTempData.length > 0 ? (
                <Grid>
                  <Grid container className={classes.chipContiner}>
                    {updatedTempData
                      .slice(0, contactAccessItem)
                      .map((currValue) => (
                        <Grid className={classes.chipBox}>
                          <Chip
                            size="small"
                            label={currValue?.value}
                            onDelete={() => {
                              handleDeletedChip(currValue);
                            }}
                            deleteIcon={
                              <i>
                                <ChipCross />
                              </i>
                            }
                            disabled={accordionName !== "portalAndContact"}
                          />
                        </Grid>
                      ))}
                  </Grid>
                  <Grid>
                    <Button
                      onClick={handleContactAccess}
                      className={classes.seeAllBtn}
                    >
                      {contactAccessItem === defaultItemsToShow
                        ? "See All"
                        : "See Less"}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.noChipText}>
                  Added contacts will appear here
                </Grid>
              )}
            </Grid>

            <Grid className={classes.dataContiner}>
              {filteredData.length ? (
                <Grid item xs={12} className={classes.mainCustomerPricing}>
                  {filteredData.map((item) => (
                    <Grid key={`${item.id}-${item.generic}`}>
                      <Grid className={classes.customerGrid}>
                        <Box className={classes.innerBox}>
                          <Box>
                            <Typography className={classes.customerPricingTypo}>
                              {item.generic}
                            </Typography>
                            <Typography className={classes.pricingTypoBody}>
                              {item.value}
                            </Typography>
                          </Box>
                        </Box>
                        {updatedTempData.find(
                          (currValue) =>
                            (
                              item.generic !== 'Contact' &&
                              Number(currValue.id) === Number(item.id)
                            ) || (
                              item.generic === 'Contact' &&
                              currValue.id === item.id
                            )
                        ) ? (
                          <Button
                            className={classes.addedBtn}
                            color="primary"
                            variant="contained"
                          >
                           <CheckIcon viewBox="0 0 30 24" /> Added 
                          </Button>
                        ) : (
                          <Button
                            className="addBtnContained"
                            color="primary"
                            variant="contained"
                            startIcon={<Add />}
                            onClick={() => {
                              tempContactData(item);
                            }}
                          >
                            Add
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.noChipText}>
                  No contacts found!
                </Grid>
              )}
            </Grid>

            <Grid className="notificationPopupButton">
              <Button
                color="primary"
                variant="contained"
                className="skipButton"
                onClick={() => setOpenContactAccessPopup(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleContactData();
                  setOpenContactAccessPopup(false);
                }}
                variant="contained"
                className={classes.applyButton}
              >
                Apply
              </Button>
            </Grid>
          </CustomSoftwareReleasePopup>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body2">
            Select the contacts that will have access to this {buildId ? " build" : " release"}.
          </Typography>
        </Grid>
        {updatedTempData?.length > 0 ? (
          <>
            <Grid xs={12} className="userRequirementSearch">
              <Typography variant="body1">
                {updatedTempData.length} Contact Types Applied
              </Typography>
              <SoftwareGridFilter
                search={searchSelectdContacts}
                setSearch={setSearchSelectdContacts}
                placeholder="Search"
              />
            </Grid>

            <Grid className="useRequirementsChips">
              <List>
                <ListItem>
                  <Grid className="chevronIcon">
                    <IconButton onClick={handleContactAccess}>
                      <ChevronRight />
                    </IconButton>
                  </Grid>
                  <Grid className="portalItemGrid">
                    {filetrdContactsData
                      .slice(0, contactAccessItem)
                      .map((item) => (
                        <Grid className="portalChips">
                          <Typography className="chipsTypo">
                            {item.value}
                          </Typography>
                          &nbsp;
                          <Typography className="deviceName">
                            {item.generic}
                          </Typography>
                          <div
                            className="closeBtn"
                            onClick={() => {
                              handleDeletedChip(item);
                            }}
                          >
                            <CloseIcon />
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </>
        ) : (
          <Grid xs={12} className="addChip">
            <span className="content">
              You haven’t added any contact. Start by adding a
            </span>
            &nbsp;
            <span
              className="portal"
              onClick={() => {
                setOpenContactAccessPopup(true);
              }}
            >
              Contact
            </span>
          </Grid>
        )}
        <Grid xs={12} className="updateOptionsContainer">
          <Typography variant="body1">Update Options</Typography>
          <Typography variant="body2">
            Select the method for distributing this update to users' devices and
            choose if it will be mandatory upon {buildId ? " build" : " release"}.
          </Typography>

          <Grid item>
            <Grid item className="software_checked">
              <Typography variant="body2">
                Update Methods<span className="staric">*</span>
              </Typography>
              <Grid>
                <Grid item className="grid_checkbox updateItemList customCheckbox">
                  <CustomCheckbox
                    checkedId={"bluetooth"}
                    pressCheck={() =>
                      portalAndContactBasicsProps({
                        propName: "updateMethodBluetooth",
                        value: !poratlAndContactData?.updateMethodBluetooth,
                      })
                    }
                    disabled={accordionName !== "portalAndContact"}
                    checkValue={poratlAndContactData?.updateMethodBluetooth}
                  />
                  <List>
                    <ListItem className="checkList">
                      Bluetooth{" "}
                      <Typography variant="caption">
                        - Allow users to update their device via Bluetooth on an
                        OFish-Powered Mobile App
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item className="grid_checkbox customCheckbox">
                  <CustomCheckbox
                    checkedId={"wifi"}
                    pressCheck={() => {
                      portalAndContactBasicsProps({
                        propName: "updateMethodWifi",
                        value: !poratlAndContactData.updateMethodWifi,
                      });
                    }}
                    disabled={accordionName !== "portalAndContact"}
                    checkValue={poratlAndContactData.updateMethodWifi}
                  />
                  <List>
                    <ListItem className="checkList">
                      WIFI{" "}
                      <Typography variant="caption">
                        - Allow the user to update directly from their device if
                        connected to WiFi via a Gateway or OFish API
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="software_checked">
              <Typography variant="body2">
                Force Update?<span className="staric">*</span>
              </Typography>
              <Grid>
                <Grid item className="grid_checkbox forcreUpdate">
                  <RadioButtons
                    label={"Yes"}
                    value={true}
                    disabled={accordionName !== "portalAndContact"}
                    selectedValue={poratlAndContactData?.isForceUpdate === true}
                    handleChange={() =>
                      portalAndContactBasicsProps({
                        propName: "isForceUpdate",
                        value: true,
                      })
                    }
                  />
                  <List>
                    <ListItem className="radioBtntLable">
                      Yes
                      <Typography variant="caption">
                        - Force users on previous {buildId ? " build" : " release"}s to update within
                      </Typography>
                    </ListItem>
                  </List>
                  <Grid item className="force_user">
                    <Grid item>
                      <InputField
                        required={!poratlAndContactData.isForceUpdate}
                        disabled={
                          !poratlAndContactData.isForceUpdate ||
                          accordionName !== "portalAndContact"
                        }
                        value={parseInt(
                          poratlAndContactData?.numberToForceUpdate ?? 0
                        )}
                        type={"number"}
                        onChange={({ target }) =>{
                        const inputValue = parseInt(target.value);
                        const validInput = isNaN(inputValue) ? '' : Math.max(0, inputValue);
                          portalAndContactBasicsProps({
                            propName: "numberToForceUpdate",
                            value: validInput,
                          })
                        }}
                      />
                    </Grid>
                    <Grid item className="releaseDropdown">
                      <SelectInput
                        required={!poratlAndContactData.isForceUpdate}
                        IconComponent={<Chevron />}
                        placeholder="Select"
                        selectlabel="Contact" 
                        disabled={
                          !poratlAndContactData.isForceUpdate ||
                          accordionName !== "portalAndContact"
                        }
                        handleChange={(e) =>
                          portalAndContactBasicsProps({
                            propName: "typeToForceUpdate",
                            value:
                              listItems.find(
                                (item) => item.id === e.target.value
                              )?.name || "",
                          })
                        }
                        value={
                          listItems.find(
                            (item) =>
                              item.name ===
                              poratlAndContactData?.typeToForceUpdate
                          )?.id || 0
                        }
                        customMenuItems={listItems}
                        InputProps={{
                          endAdornment: <FiChevronDown />,
                          style: { justifyContent: "flex-end" },
                        }}
                      />
                    </Grid>
                    <Typography variant="caption">of {buildId ? " build" : " release"}</Typography>
                  </Grid>
                </Grid>
                <Grid item className="grid_checkbox forcreUpdate">
                  <RadioButtons
                    label={"No"}
                    value={false}
                    selectedValue={
                      poratlAndContactData?.isForceUpdate === false
                    }
                    disabled={accordionName !== "portalAndContact"}
                    handleChange={() =>
                      portalAndContactBasicsProps({
                        propName: "isForceUpdate",
                        value: false,
                      })
                    }
                  />
                  <List>
                    <ListItem className="radioBtntLable">
                      No
                      <Typography variant="caption">
                        - Users can upgrade anytime at their choosing
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className="btn_save_next">
            {accordionName === "portalAndContact" && (
              <>
                <Button
                  variant="outlined"
                  className="skipButton"
                  onClick={() => {onSetAccordationAction()}}
                >
                  Skip
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="dashboardButtons saveBasicInfo"
                  type="submit"
                  disabled={
                    selectedReleasePortal?.length === 0 &&
                    (contactIds.length === 0 ||
                      companyIds.length === 0 ||
                      groupIds.length === 0)
                  }
                  onClick={() =>{ 
                    onsubmitPortalContact();
                    onSetAccordationAction();
                  }}
                >
                  Next
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default UserRequirementsAccordian;
