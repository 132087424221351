import React from "react";
import Button from "@material-ui/core/Button";

function RecentReleases({
  picture,
  heading,
  description,
  eventdate,
  onPressView
}) {
  return (
    <div className="RelatedReleasesWrapper">
      <ul>
        <li>
          <span className="pressPicture">
            <img src={picture} />
          </span>
          <span className="recentRelBtn">
            <Button onClick={() => onPressView()} variant="contained">
              View
            </Button>
          </span>
        </li>
        <li>
          <h2>{heading}</h2>
          <span className="subheading">{description}</span>
          <span className="eventdate">{eventdate}</span>
        </li>
      </ul>
    </div>
  );
}

export default RecentReleases;
