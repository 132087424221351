import React, { useEffect, useState } from "react";
import { concat } from "lodash";
import { TabNavigator } from "../../../../components/common/Tabs/TabNavigator";
import { NewMediaReleaseForm } from "../ReleasesViews/NewMediaReleaseForm";

const ReleaseTab = ({ isActive, existinglaunch }) => {
  const [activeRecordIndex, setActiveRecord] = useState(0);
  const [releases, setRelease] = useState([
    {
      index: 0
    }
  ]);

  useEffect(() => {
    if (existinglaunch?.releaseIds?.length > 0) {
      setRelease(
        existinglaunch?.releaseIds?.map((e, i) => {
          return {
            index: i,
            releaseId: e
          };
        })
      );
    }
  }, [existinglaunch?.releaseIds]);

  return (
    isActive && (
      <div style={{ flex: 1 }}>
        <TabNavigator
          tabsTitle='Release'
          tabs={releases}
          onAdd={newR => setRelease(concat(releases, newR))}
          onActiveTabClick={tabIndex => {
            setActiveRecord(tabIndex);
          }}
        />
        <div>
          <NewMediaReleaseForm
            activeReleaseId={releases[activeRecordIndex]?.releaseId || null}
            existinglaunch={existinglaunch}
          />
        </div>
      </div>
    )
  );
};
export { ReleaseTab };
