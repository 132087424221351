import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";
import apiCall from "axios";

async function getAssetManagementList({
  parentId,
  isDeleted,
  type,
  search,
  time,
  isfavorite,
  pageSize,
  pageNo,
  orderByKey,
  orderByVal,
  orderByAlphabet,
  orderByRecentlyUpdated,
  orderByCreatedDate,
  orderByFileSize,
}) {
  return axios
    .post(endpoints.assetsManagement.getMediaAssetsList, {
      parentId: parentId,
      isDeleted,
      type: type,
      search: search,
      time: time,
      isfavorite: isfavorite,
      pagination: {
        pageSize: pageSize,
        pageNo: pageNo,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
        orderByAlphabet,
        orderByRecentlyUpdated,
        orderByCreatedDate,
        orderByFileSize,
      },
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAssetManagementListForPopup(parentId, type) {
  return axios
    .post(endpoints.assetsManagement.getAllAssetsForPopup, {
      parentId: parentId,
      type: type,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getMediaAssets() {
  return axios
    .post(endpoints.assetsManagement.getMediaAssets)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function deleteMediaAssets(ids) {
  return axios
    .post(`${endpoints.assetsManagement.deleteMediaAssets}`, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
 
async function deleteMediaAssetsPermanently(ids) {
  return axios
    .post(`${endpoints.assetsManagement.deleteMediaAssetsPermanently}`, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function recoverMediaAsset(ids) {
  return axios
    .post(`${endpoints.assetsManagement.recoverMediaAsset}`, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addAssetsFolder(value, id, isfavorite) {
  return axios
    .post(endpoints.assetsManagement.addMediaAssets, {
      parentId: id,
      type: "folder",
      name: value,
      isInUse: true,
      isfavorite: isfavorite,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllCollection() {
  return axios
    .post(endpoints.allCollection.newGetAllCollection)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

// async function getCollectionsDropdown() {
//   return axios
//     .post(endpoints.allCollection.getCollectionsDropdown)
//     .then(response => {
//       return response?.data;
//     })
//     .catch(error => {
//       console.error("error : ", error);
//       return null;
//     });
// }

async function deleteAllCollection(ids) {
  return axios
    .post(endpoints.allCollection.newDeleteCollection, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getColCategories(colCategeoryParam) {
  return axios
    .post(endpoints.collectionCategory.getCollectionCategory, colCategeoryParam)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function deleteColCategories(ids) {
  return axios
    .post(endpoints.collectionCategory.deleteCollectionCategory, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function uploadImage(image, parentId) {
  // const formData = new FormData();
  // formData.append("file", image[0]);
  // const fileName = image[0].name.split(".")[0];
  // var url = `${
  //   endpoints.artifacts.uploadFile
  // }${"?name="}${fileName}${"&parentId="}${parentId}`;
  // if (parentId === 0) {
  //   url = `${endpoints.artifacts.uploadFile}${"?name="}${fileName}`;
  // }

  // let formData = new FormData();
  // formData.append("file", image[0]);
  const token = localStorage.getItem("access_token");
  const cusEndPoints = image.map(m => {
    const formData = new FormData();
    const fileName = m.name.split(".")[0];
    const url =
      parentId === 0
        ? `${endpoints.artifacts.uploadFile}${"?name="}${fileName}`
        : `${
            endpoints.artifacts.uploadFile
          }${"?name="}${fileName}${"&parentId="}${parentId}`;

    formData.append("file", m);
    return apiCall({
      url: url,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  });
  return Promise.all(cusEndPoints)
    .then(response => {
      return response;
    })
    .catch(error => {
      return null;
    });
}

async function moveMediaFiles(ids, parentId) {
  return axios
    .post(`${endpoints.assetsManagement.moveAssetsFiles}${parentId}`, ids)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addColCategory(value) {
  return axios
    .post(
      endpoints.collectionCategory.addCollectionCategory,
      JSON.stringify(value)
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateColCategory(value, catId) {
  return axios
    .put(`${endpoints.collectionCategory.updateCollectionCategory}${catId}`, {
      id: catId,
      name: value,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addCollection(value) {
  return axios
    .post(
      endpoints.allCollection.newAddCollection,
      JSON.stringify({
        title: value.title,
        subtitle: value.subtitle,
        description: value.description,
        collectionCategoryId: value.collectionCategoryId,
        launchId: value.launchId,
        collectionVisibilities: value.collectionVisibilities,
        assets: value.assets,
      })
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateCollection(value, collectionId) {
  return axios
    .post(
      `${endpoints.allCollection.newUpdateCollection}${collectionId}`,
      JSON.stringify(value)
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getFoldersOnly() {
  return axios
    .post(endpoints.assetsManagement.getMediaAssetsFolder)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getParentFoldersOnly(assestId) {
  return axios
    .post(
      `${endpoints.assetsManagement.getParentMediaAssets}/${assestId}?type=folders`
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getMediaAsset(assestId) {
  return axios
    .post(`${endpoints.assetsManagement.getParentMediaAssets}/${assestId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getMediaAssetById(assestId) {
  return axios
    .post(`${endpoints.assetsManagement.getAllAssetsForPopup}`, {
      parentId: assestId,
      type: "image",
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCollectionVisibility() {
  return axios
    .get(`${endpoints.assetsManagement.getCollectionVisi}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getFavoriteAssets() {
  return axios
    .post(endpoints.assetsManagement.getFavoriteAssets)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addFavorite(assestId, isFavorite) {
  return axios
    .post(
      `${endpoints.assetsManagement.addFavorite}/${assestId}?isfavorite=${isFavorite}`
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateAssestName(asset) {
  const assestId = asset.id;
  return axios
    .post(`${endpoints.assetsManagement.updateAssestName}/${assestId}`, asset)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getColCatById(colCatId) {
  return axios
    .post(
      `${endpoints.collectionCategory.getCollectionCategoryById}${colCatId}`
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getAllAssertCollection(value) {
  return axios
    .post(endpoints.allCollection.getAllAssertCollection, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCollectionsDropdown() {
  return axios
    .post(endpoints.allCollection.getCollectionsDropdown)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCollectionsPopup() {
  return axios
    .post(endpoints.allCollection.getCategoryPopup)
    .then(response => response.data)
    .catch(error => {
      return null;
    });
}

async function addupdateCollection(value) {
  return axios
    .post(endpoints.allCollection.addUpdateCollection, JSON.stringify(value))
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCollectionCategoryDropdown() {
  return axios
    .post(endpoints.collectionCategory.getCollectionCategoryDropdown)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function deleteCollection(value) {
  return axios
    .post(
      endpoints.allCollection.deleteAllAssertCollection,
      JSON.stringify(value)
    )
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAssertCollectionById(colId) {
  return axios
    .post(`${endpoints.allCollection.getAssertCollectionById}${colId}`)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getMediaAllAssetById(assestId) {
  return axios
    .post(`${endpoints.assetsManagement.getAllAssetsForPopup}`, {
      parentId: assestId,
      type: "",
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

export const assetsApi = {
  getMediaAssets: getMediaAssets,
  getMediaAssetById: getMediaAssetById,
  deleteMediaAssets: deleteMediaAssets,
  deleteMediaAssetsPermanently:deleteMediaAssetsPermanently,
  recoverMediaAsset:recoverMediaAsset,
  addAssetsFolder: addAssetsFolder,
  getAllCollection: getAllCollection,
  getCollectionsDropdown: getCollectionsDropdown,
  getCollectionsPopup: getCollectionsPopup,
  getColCategories: getColCategories,
  deleteAllCollection: deleteAllCollection,
  deleteColCategories: deleteColCategories,
  uploadImage: uploadImage,
  moveMediaFiles: moveMediaFiles,
  addColCategory: addColCategory,
  updateColCategory: updateColCategory,
  addCollection: addCollection,
  updateCollection: updateCollection,
  getFoldersOnly: getFoldersOnly,
  getCollectionVisibility: getCollectionVisibility,
  getFavoriteAssets: getFavoriteAssets,
  addFavorite: addFavorite,
  getParentFoldersOnly: getParentFoldersOnly,
  getMediaAsset: getMediaAsset,
  getColCatById: getColCatById,
  getAllAssertCollection: getAllAssertCollection,
  addupdateCollection: addupdateCollection,
  getCollectionCategoryDropdown: getCollectionCategoryDropdown,
  deleteCollection: deleteCollection,
  getAssertCollectionById: getAssertCollectionById,
  getAssetManagementList: getAssetManagementList,
  getAssetManagementListForPopup: getAssetManagementListForPopup,
  getMediaAllAssetById: getMediaAllAssetById,
  updateAssestName: updateAssestName,
};
