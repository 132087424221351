import React from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  LogHeader,
  MainHeading,
} from "../../../components/common/Header/index";
import Cards from "../../../components/common/Cards";
import routesObj from "../../../routes/RoutesObj";

function LogAllNewAssets(props) {
  const history = useHistory();

  const mediaDetailLink = item => {
    if (item.noOfAssets > 0) {
      history.push({
        pathname: routesObj.MediaDetail.path,
        state: {
          colAssets: item,
          catName: item.category ? item.category.name : "",
        },
      });
    } else {
      toast.warning("No asset is available");
    }
  };
  const { mediaAssets } = useSelector(state => state.pubMediaLibraryReducer);

  return (
    <div className='latestReleaseDetailWrapper allCategory'>
      <LogHeader />
      <MainHeading headerSubHeading='All Assets'>
        <div className='popupBody'>
          <div className='cards'>
            <Grid container spacing={3}>
              {mediaAssets &&
                mediaAssets.length > 0 &&
                mediaAssets.map((pml, index) => (
                  <Grid key={index} item md={4}>
                    <Cards
                      picture={"/static/images/cards/contemplative-reptile.jpg"}
                      subheading={pml.title}
                      cardtext={`${pml.noOfAssets}${" assets"}`}
                      onClick={() => mediaDetailLink(pml)}
                    />
                    {/* <Cards
                    picture={item.picture}
                    subheading={item.subheading}
                    date={item.date}
                  /> */}
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </MainHeading>
    </div>
  );
}

export default LogAllNewAssets;
