import React, { useState, useEffect } from "react";
import { Add } from "@material-ui/icons";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { contactsTypesActions } from "../../../../store/contactTypes/contactTypesActions";
import StarIcon from "../../../../resources/images/star.png";
import { starOutline } from "../../../../resources/images/index";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

export default function OFishConnections({ searchText, areaId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [contactConnections, setContactConnections] = useState({});
  const { dataConnection, refreshData } = useSelector(
    state => state.pubContactType
  );
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contactsTypesActions.onGetAllConnections(areaId)).then(data => {
      setContactConnections(data?.data?.data);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(contactsTypesActions.onGetAllConnections(areaId)).then(data => {
      setContactConnections(data?.data?.data);
      var filterData = [];
      data?.data?.data?.map(x => {
        if (x.isFavourite === true) {
          return filterData.push({
            ...x,
            fieldName: x.connectionName,
            type: "connection",
          });
        }
      });
      dispatch(
        contactsTypesActions.onChange("favoritesConnections", filterData)
      );
    });
  }, [refreshData]);

  useEffect(() => {
    if (contactConnections?.length > 0) {
      setFilteredData(
        contactConnections?.filter(value =>
          value.connectionName.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else if (!searchText || searchText !== "") {
      setFilteredData(contactConnections);
    }
  }, [contactConnections, searchText]);

  const addUpdateMarkFavouriteOfishConnections = (checked, id) => {
    setIsLoading(true);
    var updatedDataFields = contactConnections?.map(x => {
      if (x.id === id) {
        x.isFavourite = checked;
      }
      return x;
    });
    setContactConnections(updatedDataFields);
    const data = [
      {
        contactTypeConnectionId: id,
        isFavourite: checked,
      },
    ];
    dispatch(
      contactsTypesActions.onAddUpdateMarkFavouriteOfishConnections(data)
    ).then(res => {
      setIsLoading(false);
      if (!res?.data?.success) {
        toast.error(res?.data?.message);
      }
      dispatch(contactsTypesActions.onChange("refreshData", !refreshData));
    });
  };

  return (
    <Grid container className='contactTypesMainWrapper'>
      {isLoading && <Loader />}
      {filteredData?.length > 0 &&
        filteredData?.map((item, index) => {
          return (
            <Grid item className='mainGrid' key={index}>
              <div className='dataFieldmainWrapper'>
                <div className='dataFieldName'>
                  <h4>
                    {item.connectionName} <span>*</span>
                    <span className='defaultValue'>
                      {item.default === true ? "OFish Default" : ""}{" "}
                    </span>
                    <span
                      onClick={() => {
                        addUpdateMarkFavouriteOfishConnections(
                          !item.isFavourite,
                          item.id
                        );
                      }}>
                      {item.isFavourite === true ? (
                        <img src={StarIcon} alt={"StarIcom"} />
                      ) : (
                        <img src={starOutline} alt={"StarIcom"} />
                      )}
                    </span>
                  </h4>
                  <p className='dataFieldDescription'>{item.description}</p>
                </div>
                <div>
                  <Button
                    color='primary'
                    variant='contained'
                    startIcon={<Add />}
                    disabled={
                      dataConnection.find(x => x.fieldId === item.id)
                        ? true
                        : false
                    }
                    startIcon={
                      dataConnection.find(x => x.fieldId === item.id) ? null : (
                        <Add />
                      )
                    }
                    onClick={() => {
                      const tempDataConnection = dataConnection;
                      let isExist = tempDataConnection.find(
                        x => x.fieldId === item.id
                      );
                      if (isExist) {
                      } else {
                        tempDataConnection.push({
                          fieldId: item.id,
                          fieldValue: item.connectionName,
                        });
                      }
                      dispatch(
                        contactsTypesActions.onChange(
                          "dataConnection",
                          tempDataConnection
                        )
                      );
                    }}>
                    {dataConnection.find(x => x.fieldId === item.id)
                      ? "Added"
                      : "Add"}
                  </Button>
                </div>
              </div>
            </Grid>
          );
        })}
    </Grid>
  );
}
