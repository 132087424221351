import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { launches, mediaReleases } from "../../../resources/images";
import { endUserActions } from "../../../store/endUser/endUserActions";
import { RoutesObj } from "../../../routes";
import { InventoryIcon, ProductsIcon } from "../Dashboard/SpaProduct/SvgIcons";

const useStyles = makeStyles(theme => ({
  topHeading:{
   fontWeight:"700 !important",
  },
  root: {
    width: "100%",
  },
  headingWrapper: {
    backgroundColor: "#E5F2FF !important",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    display: "flex",
    alignItems: "center",
    gap:9,
    '& svg':{
     width:"24px !important",
    }
  },
  svgIcon: {
    marginRight: "11px",
  },
  expanded: {
    backgroundColor: "rgba(24, 103, 185, 0.08)",
  },
  links: {
    flexDirection: "column",
    paddingBottom: "0",
  },
  boxes: {
    boxShadow: "none",
  },
  hyperLink: {
    marginLeft: "15px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#3699FF",
    },
  },
  activeTest: {
    color: "#3699FF",
  },
}));

export default function ProductInventoryNav() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { panel } = useSelector(state => state.endUserReducer);
  const [expanded, setExpanded] = useState(panel);
  const [expand1 , setExpand1] = useState(true);
  const [expand2 , setExpand2] = useState(false);
  
  useEffect(() => {
    if (
      currentRoot === "/product-inventory" ||
      currentRoot === "/new-product" ||
      currentRoot === "/update-product" ||
      currentRoot === "/product-categories" ||
      currentRoot === "/new-product-category" ||
      currentRoot === "/update-product-category" ||
      currentRoot === RoutesObj.ProductPricing.path ||
      currentRoot === "/product-serials" ||
      currentRoot === "/product-serial" ||
      currentRoot === "/brands"
    ) {
      setExpanded("panel1");
      dispatch(endUserActions.onChange("panel", "panel1"));
    }
    if (
      currentRoot === "/all-inventory" ||
      currentRoot === "/product-order" ||
      currentRoot === "/incoming-inventory" ||
      currentRoot === "/warehouses"
    ) {
      setExpanded("panel2");
      dispatch(endUserActions.onChange("panel", "panel2"));
    }
  }, []);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    dispatch(endUserActions.onChange("panel", panel));
  };

  const history = useHistory();
  const currentRoot = history ? history.location.pathname : null;

  return (
    <div className='subNavWrapper'>
      <h1 className={classes.topHeading}>Products & Inventory</h1>
      <div className={classes.root}>
        <Accordion
          className={classes.boxes}
          defaultExpanded={true}
          expanded={expand1}
          onChange={()=>{handleChange("panel1"); setExpand1(!expand1)}}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className={
              [
                "/product-inventory",
                "/new-product",
                "/update-product",
                "/product-categories",
                "/new-product-category",
                "/update-product-category",
                RoutesObj.ProductPricing.path,
                "/product-serials",
                "/product-serial",
                "/brands",
              ].includes(currentRoot)
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              {/* <img src={mediaReleases} alt='products' className='releaseIcon' /> */}
              <ProductsIcon />
              <span style={{fontSize:"16px"}}>Products</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <NavLink
              to='/product-inventory'
              className={`${classes.hyperLink} ${
                [
                  "/product-inventory",
                  "/new-product",
                  "/update-product",
                ].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              All Products
            </NavLink>
            <NavLink
              to='/brands'
              className={`${classes.hyperLink} ${
                ["/brands"].includes(currentRoot) ? classes.activeTest : null
              }`}>
              Brands
            </NavLink>
            <NavLink
              to='/product-categories'
              className={`${classes.hyperLink} ${
                [
                  "/product-categories",
                  "/new-product-category",
                  "/update-product-category",
                ].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              Product Categories
            </NavLink>
            <NavLink
              to='/product-serials'
              className={`${classes.hyperLink} ${
                ["/product-serials", "/product-serial"].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              Product Serials
            </NavLink>
            <NavLink
              to='/product-pricing'
              className={`${classes.hyperLink} ${
                [RoutesObj.ProductPricing.path].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              Product Pricing
            </NavLink>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.boxes}
          expanded={expand2 === true}
          onChange={()=>{handleChange("panel2"); setExpand2(!expand2)}}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
            className={
              currentRoot === "/all-releases" ||
              currentRoot === "/upcoming-releases" ||
              currentRoot === "/past-releases" ||
              currentRoot === "/release-category" ||
              currentRoot === "/new-media-release"
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              {/* <img
                src={mediaReleases}
                alt='inventory'
                className='releaseIcon'
              /> */}
              <InventoryIcon />
              <span style={{fontSize:"16px"}}>Inventory</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='/product-inventory'
              className={`${classes.hyperLink} ${
                currentRoot === "/inventory" ||
                currentRoot === "/warehouses" ||
                currentRoot === "/purchase-orders"
                  ? classes.activeTest
                  : null
              }`}>
              All Inventory
            </Link>
            <Link to='/product-inventory' className={classes.hyperLink}>
              Warehouses
            </Link>
            <Link to='/product-inventory' className={classes.hyperLink}>
              Purchase Orders
            </Link>
            <Link to='/product-inventory' className={classes.hyperLink}>
              Incoming Inventory
            </Link>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
