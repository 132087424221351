import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import SoftwareGridFilter from './SoftwareGridFilter'
import { GridView, ListView } from '../../LeftNav/SvgIcons';
import { Pagination } from '../../../../components/common';
import SoftwareGrid from './SoftwareGrid';
import { useSelector } from 'react-redux';
import { SoftwarePreviewGridData } from './SoftwarePreviewGridData';

export const NotesPreview = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [softwareUpdataData, setSoftwareUpdataData] = useState([]);
  
  const onPressFilterBtn = () => {
  };

  const [order, setOrderBy] = useState({
    orderByKey: "Title",
    orderVal: 0,
  });
  const { selectedVersions } = useSelector(
    (state) => state.softwareUpdatesReducer
  );
  const TotalRecords = selectedVersions.length;
  useEffect(() => {
    let sortedVersions = [];

    if (order.orderByKey === "Title" && order.orderVal === 1) {
      sortedVersions = selectedVersions.sort((a, b) => (a.updateName > b.updateName) ? 1 : -1)
    }
    else if (order.orderByKey === "Title" && order.orderVal === 0) {
      sortedVersions = selectedVersions.sort((a, b) => (a.updateName > b.updateName) ? 1 : -1).reverse()

    }
    else if (order.orderByKey === "VersionID" && order.orderVal === 1) {
      sortedVersions = selectedVersions.sort((a, b) => (a.versionID > b.versionID) ? 1 : -1)
    }
    else if (order.orderByKey === "VersionID" && order.orderVal === 0) {
      sortedVersions = selectedVersions.sort((a, b) => (a.versionID > b.versionID) ? 1 : -1).reverse()
    }
    else if (order.orderByKey === "Status" && order.orderVal === 1) {
      sortedVersions = selectedVersions.sort((a, b) => (a.updateStatus > b.updateStatus) ? 1 : -1)
    }
    else if (order.orderByKey === "Status" && order.orderVal === 0) {
      sortedVersions = selectedVersions.sort((a, b) => (a.updateStatus > b.updateStatus) ? 1 : -1).reverse()
    }
    else if (order.orderByKey === "Products" && order.orderVal === 1) {
      sortedVersions = selectedVersions.sort((a, b) => (Number(a.compatibleProducts) > Number(b.compatibleProducts)) ? 1 : -1)
    }
    else if (order.orderByKey === "Products" && order.orderVal === 0) {
      sortedVersions = selectedVersions.sort((a, b) => (Number(a.compatibleProducts) > Number(b.compatibleProducts)) ? 1 : -1).reverse()
    }

    const startIndex = rowsPerPage * (page - 1);
    const endIndex = startIndex + rowsPerPage - 1;
    setPage(page);
    const sliceArray = sortedVersions.slice(startIndex, endIndex)
    setSoftwareUpdataData(sliceArray)

  }, [selectedVersions, order.orderByKey, order.orderVal, search.length === 0, page, rowsPerPage]);
  return (
    <Grid style={{padding:"28px 20px 33px 20px", width:"100%"}}>
      <Grid className="softwareGridFilterContainer">
        <Grid className="gridFilterBox">
          <SoftwareGridFilter
            search={search}
            setSearch={setSearch}
            isFilterTrue
            placeholder={
              "Search"
            }
            onPressFilterBtn={onPressFilterBtn}
          />
          <Grid item md={1} className={"gridIconWrapper"}>
            <Grid
              style={{ cursor: "pointer" }}
              // onClick={() => view !== "grid" && setView("grid")}
            >
              <GridView color={"#3699FF"} />
            </Grid>
            <Grid
              style={{ cursor: "pointer" }}
              // onClick={() => view !== "list" && setView("list")}
            >
              <ListView color={"#979797"} />
            </Grid>
          </Grid>
          
        </Grid>
      </Grid>
      <Grid className="tablePaginationtToolbar hideArrows">
        <span className="releaseHeading">Notes</span>
        <Pagination
          recordCount={TotalRecords}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={[]}
          backIconButtonProps={{ style: { visibility: "hidden" } }}
          nextIconButtonProps={{ style: { visibility: "hidden" } }}
        />
      </Grid>
      <SoftwarePreviewGridData
        allReleaseData={softwareUpdataData}
        // startLoading={() => setLoading(true)}
        // endLoading={() => setLoading(false)}
        order={order}
        onOrderBy={setOrderBy}
      />
      <Grid className="tablePaginationtToolbar">
        <Pagination
          recordCount={TotalRecords}
          page={page - 1}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Grid>
    </Grid>
    
  )
}
