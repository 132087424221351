import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import OutsideClickHandler from "react-outside-click-handler";
import {
  AccordionContainer,
  AddressAutoComplete,
  CustomDropdown,
  Dropdown,
  EmptyTextArea,
  InputField,
  Spinner,
} from "../../../../../components/common";
import { Button, Grid } from "@material-ui/core";
// import MuiPhoneInput from "material-ui-phone-number";
import { useDispatch, useSelector } from "react-redux";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { endUserActions } from "../../../../../store/endUser/endUserActions";
import { SVGIcons } from "../../../../../resources/images";
import SelectCompanyImagePopUp from "../../EndUser/SelectCompanyImagePopUp";
import { RoutesObj } from "../../../../../routes";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
  },
  selectEmpty: {
    border: "0",
  },
}));

const eStatuses = [
  {
    id: "1",
    name: "Active",
  },
  {
    id: "0",
    name: "Inactive",
  },
  {
    id: "4",
    name: "Draft",
  },
];

const CompanyBasics = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let isDisable = [];
  const { contactTypes } = useSelector(state => state.ContactsReducer);
  const [comResponse, setComResponse] = useState(null);
  const [profileImg, setProfileImg] = useState("");
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [contactType, setContactType] = useState("0");
  const [contactFieldData, setContactFieldsData] = useState([]);
  const [contactModuleData, setContactModuleData] = useState([]);
  const [businessGroups, setBusinessGroups] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const selectedData = queryString.parse(location.search);
  const [isCollpased, setIsCollpased] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isDisabledForm, setIsDisabledForm] = useState(true);
  const [eStatus, setEStatus] = useState(1);
  const [addressDetail, setAddressDetail] = useState({
    address: "",
    city: "",
    state: "",
    postalCode: "",
    countryRegion: "",
  });
  const [companyDetail, setCompanyDetail] = useState({
    companyName: "",
    ofishHandle: "",
    businessGroup: "",
    companyBio: "",
    companyLegalName: "",
    mainCompanyEmailAddress: "",
    companyWebsite: "",
    phoneNumber: "",
    selectPhnType: "1",
  });

  useEffect(() => {
    if (selectedData?.id) {
      setLoading(true);
      dispatch(
        contactsActions.onGetCompanyDetail(parseInt(selectedData?.id))
      ).then(data => {
        if (data && data.success) {
          const rec = data?.data;
          setContactType(`${rec?.contactTypeId}`);
          getContactTypeById(parseInt(rec?.contactTypeId));
          dispatch(
            contactsActions.onChange("contactTypes", {
              id: parseInt(rec?.contactTypeId),
            })
          );
          setTimeout(() => {
            setComResponse(rec);
            setLoading(false);
          }, 1000);
        } else {
          setLoading(false);
          toast.error(`Company dosen't exist`);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (comResponse !== null) {
      setCompanyDetail({
        id: comResponse?.id,
        companyName: comResponse?.name,
        ofishHandle: comResponse?.oFishHandle,
        businessGroup: comResponse?.groupId,
        companyBio: comResponse?.bio,
        companyLegalName: comResponse?.legalName,
        mainCompanyEmailAddress: comResponse?.mainEmailAddress,
        companyWebsite: comResponse?.website,
        phoneNumber: comResponse?.phoneNumber,
        selectPhnType: comResponse?.phoneType,
      });
      setAddressDetail({
        address: comResponse?.address,
        city: comResponse?.cityName,
        state: comResponse?.stateName,
        postalCode: comResponse?.zipCode,
        countryRegion: comResponse?.countryName,
      });
      setEStatus(
        comResponse?.eStatus ? comResponse?.eStatus : `${comResponse?.status}`
      );
      setProfileImg(comResponse?.image);
    }
  }, [comResponse]);

  useEffect(() => {
    setLoading(true);
    dispatch(endUserActions.onGetContactTypesDDL(2)).then(data => {
      if (!data) return;
      setContactList(data?.data);
      if (selectedData?.id) {
        setLoading(false);
      } else {
        if (contactTypes?.id) {
          setContactType(contactTypes?.id);
          getContactTypeById(parseInt(contactTypes?.id));
        } else {
          setContactType(data?.data[0]?.id);
          getContactTypeById(parseInt(data.data[0].id));
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   if (selectedData?.id) {
  //     setLoading(true);
  //     dispatch(
  //       contactsActions.onGetCompanyDetail(parseInt(selectedData?.id))
  //     ).then(data => {
  //       if (data && data.success) {
  //         const rec = data?.data;
  //         setCompanyDetail({
  //           id: rec?.id,
  //           companyName: rec?.name,
  //           ofishHandle: rec?.oFishHandle,
  //           businessGroup: rec?.groupId,
  //           companyBio: rec?.bio,
  //           companyLegalName: rec?.legalName,
  //           mainCompanyEmailAddress: rec?.mainEmailAddress,
  //           companyWebsite: rec?.website,
  //           phoneNumber: rec?.phoneNumber,
  //           selectPhnType: "1",
  //         });
  //         setAddressDetail({
  //           address: rec?.address,
  //           city: rec?.cityName,
  //           state: rec?.stateName,
  //           postalCode: rec?.zipCode,
  //           countryRegion: rec?.countryName,
  //         });
  //         setProfileImg(rec?.image);
  //         setContactType(rec?.contactTypeId);
  //         getContactTypeById(parseInt(rec?.contactTypeId));
  //       } else {
  //         toast.error(`Company dosen't exist`);
  //       }
  //       setLoading(false);
  //     });
  //   }
  // }, [contactType]);

  useEffect(() => {
    dispatch(contactsActions.onGetBusinessGroupDropDown()).then(data => {
      if (!data) return;
      setBusinessGroups(data?.data);
      setCompanyDetail({
        ...companyDetail,
        businessGroup: parseInt(data?.data[0]?.id),
      });
    });
  }, []);

  const getContactTypeById = cntTypeId => {
    setLoading(true);
    dispatch(endUserActions.onGetContactTypeById(cntTypeId)).then(data => {
      if (data?.success) {
        const contactArr = data.data[0]?.contactFieldsData;
        const aa = contactArr?.sort(function (a, b) {
          return a.fieldId - b.fieldId;
        });
        setContactFieldsData(aa);
        setContactModuleData(data.data[0]?.contactModulesFieldsData);
      }
      setTimeout(function () {
        setLoading(false);
      }, 5000);
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    addCompanyInformation();
  };
  const addCompanyInformation = () => {
    setLoading(true);
    const modal = {
      id: selectedData?.id ? selectedData?.id : 0,
      name: companyDetail?.companyName,
      showInLocator: false,
      isDeleted: 0,
      isFavourite: false,
      bio: companyDetail?.companyBio,
      groupId: companyDetail.businessGroup,
      groupName: "",
      address: addressDetail?.address,
      friendliness: 0,
      countryId: addressDetail?.countryRegion,
      cityId: addressDetail?.city,
      stateId: addressDetail?.state,
      countryName: addressDetail?.countryRegion,
      cityName: addressDetail?.city,
      stateName: addressDetail?.state,
      zipCode: addressDetail?.postalCode,
      website: companyDetail?.companyWebsite,
      phoneNumber: companyDetail?.phoneNumber,
      productId: [],
      image: profileImg,
      users: [],
      latitude: "",
      longitude: "",
      type: "",
      allowOFish: false,
      contactTypeId: contactType,
      oFishHandle: companyDetail?.ofishHandle,
      legalName: companyDetail?.companyLegalName,
      mainEmailAddress: companyDetail?.mainCompanyEmailAddress,
      ePhoneType: companyDetail?.selectPhnType,
      eStatus: eStatus,
    };
    if (selectedData?.id > 0) {
      dispatch(contactsActions.onUpdateCompany(modal)).then(data => {
        setLoading(false);
        if (data?.data?.id) {
          //history.push(`/new-company-contacts?id=${data?.data?.id}`);
          toast.success("Company updated");
          document.getElementById("summary-header").click();
          document.getElementById("summary-header-con").click();
          dispatch(contactsActions.onGetCompanyById(selectedData?.id));
        } else {
          toast.error("Company not added");
        }
      });
    } else {
      dispatch(contactsActions.onAddCompanyDetail(modal)).then(data => {
        setLoading(false);
        if (data?.data?.id) {
          history.push(`/new-company-contacts?id=${data?.data?.id}`);
          toast.success("Company added");
        } else {
          toast.error("Company not added");
        }
      });
    }
  };

  const findFeildContactType = value => {
    const relExist = contactFieldData?.find(cntct => {
      return value == cntct.fieldId;
    });
    if (relExist) {
      return true;
    } else {
      return false;
    }
  };

  const displayLabel = (cntFeildId, feildName) => {
    const relExist = contactFieldData?.find(cntct => {
      return cntFeildId == cntct.fieldId;
    });
    if (relExist?.fieldSettings?.length > 0) {
      const isRequir = relExist?.fieldSettings.find(cntct => {
        return "label" == cntct.key;
      });
      if (isRequir && isRequir.additionalData.trim() !== "") {
        return isRequir.additionalData;
      } else {
        return feildName;
      }
    } else {
      return feildName;
    }
  };

  const checkIsRequired = value => {
    const relExist = contactFieldData?.find(cntct => {
      return value == cntct.fieldId;
    });
    const isRequir = relExist?.fieldSettings?.find(cntct => {
      return "Required" == cntct.additionalData;
    });
    if (isRequir) {
      return true;
    } else {
      return false;
    }
  };

  const uploadProfileImage = file => {
    setLoading(true);
    dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
      if (data && data.success && data.data) {
        setProfileImg(data.data.url);
        setShowFeatureImage(false);
      }
      setLoading(false);
    });
  };

  const renderEStatusClassName = () => {
    let eStatusClass = "newCompanyHeaderSelect";
    console.log("Company eStatussssssssssss", eStatus);
    if (eStatus) {
      const selectedStatus = eStatuses.find(s => s.id === eStatus)?.name;
      if (selectedStatus)
        eStatusClass = `newCompanyHeaderSelect-${selectedStatus}`;
    }
    return eStatusClass;
  };

  const onCompanyStatusChange = (status, type) => {
    dispatch(
      contactsActions.onDeleteCompanies({
        ids: [selectedData?.id],
        status: status,
      })
    ).then(res => {
      if (!res?.success) {
        toast.error(res.message);
      } else {
        toast.success(`Company mark as ${type}`);
      }
    });
  };

  const onMarkFavouriteCustomer = () => {
    setLoading(true);
    dispatch(
      contactsActions.onMarkUnMarkFavouriteCompanies({
        companyId: selectedData?.id,
        isFavourite: true,
      })
    ).then(data => {
      setLoading(false);
      if (!data?.success) toast.error(data?.message || "Something went wrong!");
      else toast.success("Company mark as favourite");
    });
  };

  return (
    <div className='newCompanySelectWrapper'>
      {isLoading ? <Spinner /> : null}
      <AccordionContainer
        title='Company Basics'
        onCollapsed={collapsed => setIsCollpased(collapsed)}
        formButton={
          isCollpased ? (
            location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) &&
            selectedData?.id > 0 && (
              <span className='green-tick-svg'>
                <SVGIcons.GreenTick />
              </span>
            )
          ) : (
            <span className={renderEStatusClassName()}>
              <span
                style={{ position: "absolute", top: "-120px", right: "3px" }}>
                <CustomDropdown
                  value={eStatus}
                  onChange={slctdItem => {
                    setEStatus(slctdItem.id);
                    // setCompanyDetail({
                    //   ...companyDetail,
                    //   eStatus: ,
                    // });
                  }}
                />
                {/* <SelectInput
                  placeholder='Please Select'
                  value={companyDetail?.eStatus}
                  customMenuItems={eStatuses}
                  handleChange={({ target }) => {
                    setCompanyDetail({
                      ...companyDetail,
                      eStatus: target.value,
                    });
                  }}
                /> */}
              </span>
              <span className='contactTypeWrapperBtn'>
                {selectedData?.id && (
                  <OutsideClickHandler
                    onOutsideClick={() => setIsDropdown(false)}>
                    <i onClick={() => setIsDropdown(!isDropdown)}>
                      <BsThreeDotsVertical />
                    </i>
                    {isDropdown && (
                      <ul className='dropdown' style={{ width: "80px" }}>
                        <li
                          onClick={() => {
                            setIsDisabledForm(false);
                            setIsDropdown(false);
                          }}>
                          Edit Company
                        </li>
                        <li
                          onClick={() => {
                            onMarkFavouriteCustomer();
                            setIsDropdown(false);
                          }}>
                          Favorite Company
                        </li>
                        <li
                          onClick={() => {
                            onCompanyStatusChange(3, "archive");
                            setIsDropdown(false);
                          }}>
                          Archive Company
                        </li>
                        <li
                          onClick={() => {
                            onCompanyStatusChange(2, "block");
                            setIsDropdown(false);
                          }}
                          className='blockUser'>
                          Block Company
                        </li>
                        <li
                          onClick={() => {
                            onCompanyStatusChange(-1, "deleted");
                            setIsDropdown(false);
                          }}>
                          Delete Company
                        </li>
                      </ul>
                    )}
                  </OutsideClickHandler>
                )}
              </span>
            </span>
          )
        }
        expanded>
        <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={2}
              className={(classes.gridWrapper, "formMainWrapper")}>
              <div className='uploadOuterWrapper'>
                <div
                  className={`${
                    editUserId && (profileImg === "" || profileImg === null)
                      ? "noProfileImg"
                      : " "
                  } imageWrapper`}>
                  {editUserId && (profileImg === "" || profileImg === null) ? (
                    <i
                      className='userInitials'
                      onClick={() => {
                        if (isDisabledForm && selectedData?.id) {
                        } else {
                          setShowFeatureImage(!showFeatureImage);
                        }
                      }}>
                      {`${companyDetail?.companyName
                        ?.charAt(0)
                        ?.toUpperCase()}`}
                    </i>
                  ) : (
                    <>
                      <img
                        className='image'
                        alt='placeholder'
                        src={
                          profileImg !== ""
                            ? profileImg
                            : "http://via.placeholder.com/700x500"
                        }
                      />
                      {(profileImg === "" || profileImg === null) && (
                        <Button
                          variant='outlined'
                          color='primary'
                          className='file-upload'
                          style={{ height: 50, alignSelf: "center" }}
                          onClick={() => {
                            setShowFeatureImage(!showFeatureImage);
                          }}>
                          &#128247;
                        </Button>
                      )}
                    </>
                  )}
                </div>
                {profileImg !== "" && profileImg !== null && (
                  <Button
                    variant='outlined'
                    disabled={isDisabledForm && selectedData?.id}
                    color='primary'
                    className='file-upload changeBtn'
                    style={{ height: 50, alignSelf: "center" }}
                    onClick={() => setShowFeatureImage(!showFeatureImage)}>
                    Change
                  </Button>
                )}
              </div>
              {/* <div class='uploadOuterWrapper'>
                <div class='noProfileImg imageWrapper'>
                  <img
                    className='image'
                    src='http://via.placeholder.com/700x500'
                  />

                  <Button
                    variant='outlined'
                    color='primary'
                    className='file-upload'
                    style={{ height: 50, alignSelf: "center" }}>
                    &#128247;
                  </Button>
                </div>
              </div> */}
            </Grid>
            {/* <Grid container spacing={3}> */}
            <Grid item xs={10} className={classes.gridWrapper}>
              <h2 className='innerHeading'>Company Information</h2>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={6}
                  className={(classes.gridWrapper, "requiredField")}>
                  {findFeildContactType(14) && (
                    <InputField
                      placeholder='Company Name'
                      inputlabel={displayLabel(14, "Company Name")}
                      className={classes.gridWrapper}
                      value={companyDetail?.companyName}
                      showRequired={true}
                      disabled={isDisabledForm && selectedData?.id}
                      onChange={v =>
                        setCompanyDetail({
                          ...companyDetail,
                          companyName: v.target.value,
                        })
                      }
                      validators={
                        checkIsRequired(14) === true
                          ? ["required", "matchRegexp:^.{0,50}$"]
                          : ["required", "matchRegexp:^.{0,50}$"]
                      }
                      errorMessages={
                        checkIsRequired(14) === true
                          ? [
                              "This field is required",
                              "Maximum length is 50 characters",
                            ]
                          : [
                              "This field is required",
                              "Maximum length is 50 characters",
                            ]
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={6} className={classes.gridWrapper}>
                  <InputField
                    placeholder='Enter an OFish Handle'
                    inputlabel='OFish Handle'
                    disabled={isDisabledForm && selectedData?.id}
                    className={classes.gridWrapper}
                    value={companyDetail?.ofishHandle}
                    onChange={v => {
                      const input = v.target.value;
                      const prefix = "@";
                      if (input === "") {
                        setCompanyDetail({
                          ...companyDetail,
                          ofishHandle: input,
                        });
                      } else {
                        setCompanyDetail({
                          ...companyDetail,
                          ofishHandle: prefix + input.substr(prefix.length),
                        });
                      }
                    }}
                  />
                </Grid>
                {/* <Grid
                  item
                  xs={4}
                  className={(classes.gridWrapper, "requiredField")}>
                  <Dropdown
                    selectlabel='Business Group'
                    placeholder='Please Select'
                    value={parseInt(companyDetail.businessGroup)}
                    required={false}
                    showRequired
                    customMenuItems={businessGroups?.map(c => {
                      return {
                        id: parseInt(c.id),
                        name: c.value,
                      };
                    })}
                    handleChange={({ target }) => {
                      setCompanyDetail({
                        ...companyDetail,
                        businessGroup: target.value,
                      });
                    }}
                  />
                  {/* <InputField
                    placeholder='Enter a Business Group Name'
                    inputlabel='Business Group'
                    className={classes.gridWrapper}
                    showRequired={true}
                    value={companyDetail?.businessGroup}
                    onChange={v =>
                      setCompanyDetail({
                        ...companyDetail,
                        businessGroup: v.target.value,
                      })
                    }
                    validators={["required", "matchRegexp:^.{0,50}$"]}
                    errorMessages={[
                      "This field is required",
                      "Maximum length is 50 characters",
                    ]}
                  /> 
                </Grid> */}

                {findFeildContactType(13) && (
                  <Grid item xs={4} className={classes.gridWrapper}>
                    <InputField
                      placeholder='Enter a Company Legal Name'
                      disabled={isDisabledForm && selectedData?.id}
                      inputlabel={displayLabel(13, "Company Legal Name")}
                      className={classes.gridWrapper}
                      value={companyDetail?.companyLegalName}
                      onChange={v =>
                        setCompanyDetail({
                          ...companyDetail,
                          companyLegalName: v.target.value,
                        })
                      }
                      validators={
                        checkIsRequired(13) === true
                          ? ["required", "matchRegexp:^.{0,50}$"]
                          : ["matchRegexp:^.{0,50}$"]
                      }
                      errorMessages={
                        checkIsRequired(13) === true
                          ? [
                              "This field is required",
                              "Maximum length is 50 characters",
                            ]
                          : ["Maximum length is 50 characters"]
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={4}
                  className={(classes.gridWrapper, "requiredField")}>
                  <Dropdown
                    selectlabel='Contact Type'
                    placeholder='Please Select'
                    disabled={isDisabledForm && selectedData?.id}
                    value={`${contactType}`}
                    required={false}
                    showRequired
                    customMenuItems={contactList?.map(b => {
                      return {
                        id: b?.id,
                        name: b?.value,
                      };
                    })}
                    handleChange={({ target }) => {
                      setContactType(target.value);
                      getContactTypeById(parseInt(target.value));
                      dispatch(
                        contactsActions.onChange("contactTypes", {
                          id: target.value,
                        })
                      );
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  className={(classes.gridWrapper, "requiredField")}>
                  <Dropdown
                    selectlabel='Business Group'
                    placeholder='Please Select'
                    disabled={isDisabledForm && selectedData?.id}
                    value={parseInt(companyDetail.businessGroup)}
                    required={false}
                    showRequired
                    customMenuItems={businessGroups?.map(c => {
                      return {
                        id: parseInt(c.id),
                        name: c.value,
                      };
                    })}
                    handleChange={({ target }) => {
                      setCompanyDetail({
                        ...companyDetail,
                        businessGroup: target.value,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12} className={classes.gridWrapper}>
                  {findFeildContactType(23) && (
                    <EmptyTextArea
                      className='overviewTextarea'
                      placeholder='Write something about Bio'
                      textLabel={displayLabel(23, "Company Bio")}
                      value={companyDetail?.companyBio}
                      disabled={isDisabledForm && selectedData?.id}
                      onChange={v =>
                        setCompanyDetail({
                          ...companyDetail,
                          companyBio: v.target.value,
                        })
                      }
                      validators={
                        checkIsRequired(23) === true
                          ? ["required", "matchRegexp:^.{0,500}$"]
                          : ["matchRegexp:^.{0,500}$"]
                      }
                      errorMessages={
                        checkIsRequired(23) === true
                          ? [
                              "This field is required",
                              "Maximum length is 500 characters",
                            ]
                          : ["Maximum length is 500 characters"]
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={12} className={classes.gridWrapper}>
                  <div className='gap' />
                  <div className='gap' />
                  <h2 className='innerHeading'>Contact Information</h2>

                  {findFeildContactType(5) && (
                    <Grid container spacing={3}>
                      <Grid item xs={4} className={classes.gridWrapper}>
                        {isDisabledForm && selectedData?.id ? (
                          <InputField
                            disabled={isDisabledForm && selectedData?.id}
                            placeholder='Address'
                            inputlabel='Address'
                            className={classes.gridWrapper}
                            value={addressDetail?.address}
                          />
                        ) : (
                          <AddressAutoComplete
                            autoComId={"popupCompanyLocInput"}
                            address={addressDetail?.address}
                            onChange={e => {
                              setAddressDetail({
                                ...addressDetail,
                                address: e.target.value,
                              });
                            }}
                            setPlace={p => {
                              isDisable = [];
                              let address = "";
                              let city = "";
                              let state = "";
                              let countryRegion = "";
                              let postalCode = "";
                              p?.address_components?.forEach(x => {
                                if (x?.types?.includes("route")) {
                                  address = x?.long_name;
                                }
                                if (x?.types?.includes("locality")) {
                                  city = x?.long_name;
                                  isDisable.push("city");
                                }
                                if (
                                  x?.types?.includes(
                                    "administrative_area_level_1"
                                  )
                                ) {
                                  state = x?.long_name;
                                  isDisable.push("state");
                                }
                                if (x?.types?.includes("country")) {
                                  countryRegion = x?.long_name;
                                  isDisable.push("country");
                                }
                                if (x?.types?.includes("postal_code")) {
                                  postalCode = x?.long_name;
                                  isDisable.push("postalCode");
                                }
                              });
                              setAddressDetail({
                                ...addressDetail,
                                address: address,
                                city: city,
                                state: state,
                                countryRegion: countryRegion,
                                postalCode: postalCode,
                              });
                            }}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        className={(classes.gridWrapper, "requiredField")}>
                        <InputField
                          placeholder=''
                          disabled={isDisabledForm && selectedData?.id}
                          inputlabel='City'
                          className={classes.gridWrapper}
                          showRequired={true}
                          value={addressDetail?.city}
                          onChange={v =>
                            setAddressDetail({
                              ...addressDetail,
                              city: v.target.value,
                            })
                          }
                          validators={["required", "matchRegexp:^.{0,200}$"]}
                          errorMessages={[
                            "This field is required",
                            "Maximum length is 200 characters",
                          ]}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className={(classes.gridWrapper, "requiredField")}>
                        <InputField
                          placeholder=''
                          disabled={isDisabledForm && selectedData?.id}
                          inputlabel='State'
                          className={classes.gridWrapper}
                          showRequired={true}
                          value={addressDetail?.state}
                          onChange={v =>
                            setAddressDetail({
                              ...addressDetail,
                              state: v.target.value,
                            })
                          }
                          validators={["required", "matchRegexp:^.{0,50}$"]}
                          errorMessages={[
                            "This field is required",
                            "Maximum length is 50 characters",
                          ]}
                        />
                      </Grid>

                      {findFeildContactType(5) && (
                        <Grid item xs={2} className={classes.gridWrapper}>
                          <InputField
                            placeholder=' '
                            disabled={isDisabledForm && selectedData?.id}
                            inputlabel='Postal Code'
                            className={classes.gridWrapper}
                            value={addressDetail?.postalCode}
                            onChange={v =>
                              setAddressDetail({
                                ...addressDetail,
                                postalCode: v.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid container spacing={3}>
                    {findFeildContactType(5) && (
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <InputField
                          placeholder=''
                          disabled={isDisabledForm && selectedData?.id}
                          inputlabel='Country'
                          className={classes.gridWrapper}
                          showRequired={true}
                          value={addressDetail?.countryRegion}
                          required={false}
                          onChange={v =>
                            setAddressDetail({
                              ...addressDetail,
                              countryRegion: v.target.value,
                            })
                          }
                          validators={["required", "matchRegexp:^.{0,50}$"]}
                          errorMessages={[
                            "This field is required",
                            "Maximum length is 50 characters",
                          ]}
                        />
                        {/* 
                        <Dropdown
                          selectlabel='Country'
                          placeholder='Please Select'
                          value={addressDetail?.countryRegion}
                          required={false}
                          // showRequired
                          // customMenuItems={addressDetail?.map(c => {
                          //   return {
                          //     id: parseInt(c.id),
                          //     name: c.value,
                          //   };
                          // })}
                          handleChange={v =>
                            setAddressDetail({
                              ...addressDetail,
                              countryRegion: v.target.value,
                            })
                          }
                        /> */}
                      </Grid>
                    )}

                    {findFeildContactType(19) && (
                      <Grid item xs={5}>
                        <InputField
                          disabled={isDisabledForm && selectedData?.id}
                          placeholder='Enter Main Phone Number'
                          inputlabel='Location Phone Number'
                          type='number'
                          className={classes.input}
                          value={companyDetail?.phoneNumber}
                          onChange={v =>
                            setCompanyDetail({
                              ...companyDetail,
                              phoneNumber: v.target.value,
                            })
                          }
                          inputProps={{ min: 0 }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={4} className={classes.gridWrapper}>
                      <Dropdown
                        disabled={isDisabledForm && selectedData?.id}
                        selectlabel='Phone Number Type'
                        placeholder='Phone Number Type'
                        className='newCompanySelecttype'
                        customMenuItems={[
                          { id: 1, name: "Mobile" },
                          { id: 2, name: "Home" },
                        ]}
                        required={false}
                        value={companyDetail.selectPhnType}
                        handleChange={({ target }) => {
                          setCompanyDetail({
                            ...companyDetail,
                            selectPhnType: target.value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div className='gap' />
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={6}
                      className={(classes.gridWrapper, "requiredField")}>
                      {findFeildContactType(17) && (
                        <InputField
                          placeholder='Enter Main Company E-mail Address'
                          disabled={isDisabledForm && selectedData?.id}
                          inputlabel={displayLabel(
                            17,
                            "Main Company E-mail Address"
                          )}
                          className={classes.gridWrapper}
                          showRequired={true}
                          value={companyDetail?.mainCompanyEmailAddress}
                          onChange={v =>
                            setCompanyDetail({
                              ...companyDetail,
                              mainCompanyEmailAddress: v.target.value,
                            })
                          }
                          validators={
                            checkIsRequired(17) === true
                              ? ["required", "matchRegexp:^.{0,50}$"]
                              : ["matchRegexp:^.{0,50}$"]
                          }
                          errorMessages={
                            checkIsRequired(17) === true
                              ? [
                                  "This field is required",
                                  "Maximum length is 50 characters",
                                ]
                              : ["Maximum length is 50 characters"]
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={6} className={classes.gridWrapper}>
                      {findFeildContactType(20) && (
                        <InputField
                          disabled={isDisabledForm && selectedData?.id}
                          placeholder='Enter a Company Website'
                          inputlabel={displayLabel(20, "Company Website")}
                          className={classes.gridWrapper}
                          value={companyDetail?.companyWebsite}
                          onChange={v =>
                            setCompanyDetail({
                              ...companyDetail,
                              companyWebsite: v.target.value,
                            })
                          }
                          validators={
                            checkIsRequired(20) === true
                              ? ["required", "matchRegexp:^.{0,50}$"]
                              : ["matchRegexp:^.{0,50}$"]
                          }
                          errorMessages={
                            checkIsRequired(20) === true
                              ? [
                                  "This field is required",
                                  "Maximum length is 50 characters",
                                ]
                              : ["Maximum length is 50 characters"]
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className='gap' />
            </Grid>
          </Grid>

          <div className='gap' />

          <div className='gap' />
          <Grid container spacing={3}></Grid>
          <span className='saveNNestBtnWrapper'>
            {!selectedData?.id && (
              <Button
                variant='outlined'
                className='skip-button'
                onClick={() => {
                  document.getElementById("summary-header").click();
                  document.getElementById("summary-header-con").click();
                }}>
                Skip
              </Button>
            )}
            <Button
              type='submit'
              onChange={() => {
                handleSubmit();
              }}>
              {location?.pathname?.includes(RoutesObj.NewCompanyContacts.path)
                ? "Next"
                : "Save"}
            </Button>
          </span>
          {showFeatureImage && (
            <SelectCompanyImagePopUp
              closePopup={() => setShowFeatureImage(false)}
              openPopup={showFeatureImage}
              title={"Upload profile image"}
              addProfileImage={value => uploadProfileImage(value)}
            />
          )}
        </ValidatorForm>
      </AccordionContainer>
    </div>
  );
};

export { CompanyBasics };
