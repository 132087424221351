import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  IconButton,
  Typography,
} from "@material-ui/core";
import { concat } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TableGrid, GridListIcon } from "../../LeftNav/SvgIcons";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import CustomerGroupGridFilter from "./CustomerGroupGridFilter";
import { Close } from "../../LeftNav/SvgIcons";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: "20px",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "20px",
    width: "1100px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #E2E5E9",
    padding: "25px 33px 25px 41px;",
    background: "#3699FF",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  customerTypo: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#fff",
  },
  customerGridTab:{
    '& .rwt__tablist button':{
      color:"#8D99A5 !important",
      letterSpacing: "0.28px",
    },
    '& .msgCount':{
      color:"#8D99A5 !important",
    },
    '& .rwt__tab[aria-selected=true]':{
      color: "#3699ff !important",
    }
  },
  innerBody: {
    height: "calc(100vh - 410px)",
    overflowY: "auto",
  },
  chooseCustomerHeading: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#181C32",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Roboto",
    // lineHeight: "16.41px",
    color: "#434D56",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    padding: "13px 18px 17px 20px",
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  mainCustomerPricing: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  mainBody: {
    padding: "28px 3px 0px 37px",
  },
  customerGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: " space-between",
    // padding: "19px 19px 19px 12px",
    padding:"12px",
    background: "#F1FAFF",
    border: " 1px dashed #00A3FF",
    borderRadius: 9,
    marginRight: 30,
    marginBottom: 20,
  },
  coustomerBox: {
    width: 36,
    height: 36,
    background: "#D8D8D8",
    borderRadius: 3,
    marginRight: 12,
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customerPricingTypo: {
    fontSize: 16,
    fontWeight: 700,
    color: "#181C32",
    fontFamily: "Roboto",
  },
  pricingTypoBody: {
    fontSize: 14,
    fontWeight: 700,
    color: "#3699FF",
    fontFamily: "Roboto",
  },
  typoGrid: {
    paddingLeft: 13,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 31,
    paddingTop: 28,
    paddingBottom: 28,
    alignItems: "center",
  },
  addedTypo: {
    marginRight: 24,
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#3699FF",
  },
  addedBtn: {
    backgroundColor: "#18BC6D",
    "&:hover": {
      backgroundColor: "#18BC6D",
    },
  },
}));

const allTabs = [
  {
    id: "all",
    title: "ALL",
    component: "",
  },
  {
    id: "Groups",
    title: "Groups",
    component: "",
  },
  {
    id: "Company",
    title: "Companies",
    component: "",
  },
  {
    id: "Contact",
    title: "Contacts",
    component: "",
  },
];

const CustomerGroupsPopup = ({
  selectedValues = [],
  setIsViewAll,
  isViewAll,
  handleCustomerTabChange,
  isAddVisibilityGroup,
  setIsAddVisibilityGroup,
  allVisibilityGroups,
  visibilityGroups,
  onGroupSelection,
}) => {
  const [filterByName, setFilterByName] = useState("");
  const [isAddedTab, setIsAddedTab] = useState(isViewAll);
  const [filteredvisibilityGroups, setFilteredVisibilityGroups] = useState(
    visibilityGroups ?? []
  );
  const [tempVisibilityGroups, setTempVisibilityGroups] =
    useState(selectedValues);
  const classes = useStyles();
  const [activeTabId, setActiveTabId] = useState(isViewAll ? "added" : "all");

  const onClose = () => {
    setIsAddVisibilityGroup(false);
  };

  const onCloseGroupSelection = () => {
    setIsAddVisibilityGroup(false);
    onGroupSelection && onGroupSelection(tempVisibilityGroups);
  };

  const addNewGroup = group => {
    setTempVisibilityGroups([...concat(tempVisibilityGroups, group)]);
  };

  useEffect(() => {
    if (filterByName !== "") {
      if (isAddedTab) {
        showAddedCustomers();
      } else {
        setFilteredVisibilityGroups([
          ...visibilityGroups.filter(c =>
            c.value?.toLowerCase().includes(filterByName?.toLowerCase())
          ),
        ]);
      }
    } else {
      if (isAddedTab) {
        showAddedCustomers();
      } else {
        setFilteredVisibilityGroups([...visibilityGroups]);
      }
    }
  }, [visibilityGroups, filterByName]);

  const handleTabChange = tabId => {
    if (tabId === "added") {
      setIsAddedTab(true);
    } else {
      handleCustomerTabChange(tabId);
      setIsViewAll(false);
      setIsAddedTab(false);
    }
  };

  const showAddedCustomers = () => {
    const addedGroups = allVisibilityGroups
      .filter(vGroup =>
        tempVisibilityGroups.some(
          tGroup =>
            tGroup?.visibilityGroupId === vGroup?.id &&
            tGroup?.type === vGroup?.generic
        )
      )
      .filter(c => c.value?.toLowerCase().includes(filterByName?.toLowerCase()));

    setFilteredVisibilityGroups(addedGroups);
  };

  useEffect(() => {
    if (isAddedTab) {
      showAddedCustomers();
    }
  }, [isAddedTab]);

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={isAddVisibilityGroup}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isAddVisibilityGroup}>
        <Grid container className={classes.paper}>
          <Grid item xs={12} className={classes.header}>
            <Typography className={classes.customerTypo}>
              Add Customers to Distributor Price
            </Typography>
            <IconButton className={classes.closIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid xs={12} className={classes.mainBody}>
            <Grid item>
              <Typography className={classes.chooseCustomerHeading}>
                Choose Customers to Add to Distributor Price
              </Typography>
              <Typography className={classes.text}>
                Select the customers you want Distributor Price to apply to & be
                visible to on price lists. Customers will always get the lowest
                price available to them.
              </Typography>
            </Grid>
            <Grid className='customerSearchfilter'
            style={{
              marginTop:"22px",
              marginBottom:"39px",
            }}
            >
              <CustomerGroupGridFilter setFilterByName={setFilterByName} />
              <Grid className='grid_list'>
                <GridListIcon />
                <TableGrid />
              </Grid>
              <Grid >
                <TopTabNavigator
                  getActiveTabId={tabId => {
                    setActiveTabId(`${tabId}`);
                    handleTabChange(tabId);
                  }}
                  tabs={[
                    {
                      id: "added",
                      title: "Added ",
                      msgCount: `(${tempVisibilityGroups?.length})`,
                      component: "",
                    },
                    ...allTabs,
                  ]}
                  defaultTabId={activeTabId}
                  className={`tabsHeader ${classes.customerGridTab}`}
                />
              </Grid>
            </Grid>
            <Grid xs={12} className={classes.innerBody}>
              <Grid item xs={12} className={classes.mainCustomerPricing}>
                {filteredvisibilityGroups.map(item => (
                  <Grid xs={6}>
                    <Grid key={item.id} className={classes.customerGrid}>
                      <Box className={classes.innerBox}>
                        <Box className={classes.coustomerBox}></Box>
                        <Box>
                          <Typography className={classes.customerPricingTypo}>
                            {item.value}
                          </Typography>
                          <Typography className={classes.pricingTypoBody}>
                            {item.generic}
                          </Typography>
                        </Box>
                      </Box>
                      {tempVisibilityGroups?.find(
                        customer =>
                          customer.visibilityGroupId === item.id &&
                          customer.type === item.generic
                      ) ? (
                        <Button
                          className={classes.addedBtn}
                          color='primary'
                          startIcon={<CheckIcon />}
                          variant='contained'>
                          Added
                        </Button>
                      ) : (
                        <Button
                          color='primary'
                          variant='contained'
                          startIcon={<AddIcon />}
                          onClick={() =>
                            addNewGroup({
                              visibilityGroupId: item.id,
                              type: item.generic,
                            })
                          }>
                          Add
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid className={classes.btnContainer}>
              <Typography className={classes.addedTypo}>
                {tempVisibilityGroups?.length} Added
              </Typography>
              <Button
                color='primary'
                variant='contained'
                disabled={tempVisibilityGroups?.length <= 0}
                onClick={() => onCloseGroupSelection()}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export { CustomerGroupsPopup };
