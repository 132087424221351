import React, { useState } from "react";
import ProductRegistrationPopup from "../../../../components/common/popUps/ProductRegistrationPopup";
import { Grid, Tooltip, Typography } from "@material-ui/core";

const DeviceBox = ({ productDetail, deviceData }) => {
  const [isModal, setIsModal] = useState(false);

  return (
    <div
      className='endUserDeviceBox'
      onClick={() => {
        productDetail();
      }}>
      <div className='deviceBoxMainHeader endUserBox'>
        <div className='prodPic'>
          {deviceData.productImage && (
            <img src={deviceData.productImage} alt='' className='deviceImg' />
          )}
        </div>
        <div className='deviceCardDetails'>
          <Tooltip title={deviceData.name} placement='top'>
            <span className='deviceName'>{deviceData.name}</span>
          </Tooltip>

          <span className='deviceSubtitle'>{deviceData.productType}</span>
        </div>
      </div>

      <span className='boxMainlocated'>
        <Grid className='deviceDetail'>
          <Typography className='deviceProperties'>SKU</Typography>
          <Typography className='devicePropertyDetail'>
            {" "}
            {deviceData.productSKU ?? "--"}
          </Typography>
        </Grid>
        <Grid className='deviceDetail'>
          <Typography className='deviceProperties'>Serial Number</Typography>
          <Typography className='devicePropertyDetail'>
            {" "}
            {deviceData.serialNo}
          </Typography>
        </Grid>
        <Grid className='deviceDetail'>
          <Typography className='deviceProperties'>Environment</Typography>
          <Typography className='devicePropertyDetail'>
            {" "}
            {deviceData.environment}
          </Typography>
        </Grid>
        <span className='productStatus'>
          {deviceData.status === "Registration Denied" ? (
            <span className='productRejected'>Rejected</span>
          ) : deviceData.status === "Registration Pending" ? (
            <span className='productPending'>Pending</span>
          ) : deviceData.status === "Registered" ? (
            <span className='productRegistered'>Registered</span>
          ) : deviceData.status === "Incomplete" ? (
            <span className='productPending'>Incomplete</span>
          ) : (
            <span className='productMissing'>Receipt Missing</span>
          )}
        </span>
      </span>

      <ProductRegistrationPopup
        openPopup={isModal}
        closePopup={() => setIsModal(false)}
      />
    </div>
  );
};

export default DeviceBox;
