import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Divider, Grid, IconButton, List, ListItem, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import SoftwareGridFilter from '../../SoftwareUpdates/SoftwareGridFilter'
import { Add } from '@material-ui/icons';
import { CustomConversationPopup } from './CustomConversationPopup';
import CustomCheckbox from '../../../../../components/common/checkbox/CustomCheckbox';
import { ChipCross, CloseRedIcon, ExpandDownIcon, SaveGreenIcon } from '../../../LeftNav/SvgIcons';
import { ConversationChip } from './ConversationChip';
import { ConversationPermissionPopupGrid } from './ConversationPermissionPopupGrid';
import { ConversationAccordionChips } from './ConversationAccordionChips';

const useStyles = makeStyles((theme) => ({
  popupCheckBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
  },
  popupCheckBoxText: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#2D3339',
  },
  recommendedText: {
    color: '#18BC6D',
    fontSize: '14px',
    fontWeight: '600',
  },
  chipContiner: {
    // marginTop: "16px",
    // height: "70px",
    // overflowY: "auto",
    // "& .MuiChip-deleteIconSmall": {
    //   margin: 0,
    // },
    display:"flex",
  },
  selectedChips: {
    marginRight: "16px",
    marginTop: "16px",
  },
  chipsBox: {
    marginBottom: "16px",
  },
  chipBox: {
    "& .MuiChip-sizeSmall": {
      height: "36px",
      padding: "8px 16px",
      backgroundColor: "#F3F6F9",
    },
    "& .MuiChip-label": {
      fontSize: "14px",
      fontWeight: "700",
      color: "#70808F"
    },
  },
  expandChipButton: {
    color: "#3699FF",
    fontSize: "14px",
    fontWeight: "700",
    cursor: "pointer",
  },
  noChipText: {
    color: "#BDBDBD",
    fontSize: "12px",
    fontWeight: "500",
    fontStyle: "italic",
    textAlign: "center",
  },
}))

export const ConversationPermission = ({
  conversationPermissionData,
}) => {
  const[search , setSearch]=useState("")

  const allTabs = [
    {
      id: "1",
      title: "User Roles",
      component: "",
    },
    {
      id: "2",
      title: "All Users",
      component: "",
    },
    {
      id: "3",
      title: "All Groups",
      component: "",
    },
    {
      id: "4",
      title: "Business Groups",
      component: "",
    },
    {
      id: "5",
      title: "End User Groups",
      component: "",
    },
  ];
  const filteredPermissions = conversationPermissionData.filter(permission =>
    permission.name.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <div className='conversationPermissionSetting'>
      <Grid>
        <Divider />
        <h3>Conversation Permissions</h3>
        <p className='convsationSettingInfo'>Choose conversation permissions for different users and groups.</p>
        <Grid className='permissionSearchBar'>
          <SoftwareGridFilter
            search={search}
            setSearch={setSearch}
            placeholder="Search"
          />
        </Grid>
        <Grid>
          {
           filteredPermissions.length ? filteredPermissions.map((x, i) =>
            (
              <Grid className='premissionAccordionContainer'>
                <Accordion key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid className="conversationAccordianHeading">
                      <Typography className='accordionHeading'>
                        {x.name} <span>Group</span>
                      </Typography>

                      <List>
                        <ListItem className="accordionDetials">
                          {x.value} Conversations
                        </ListItem>
                      </List>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid>
                      <p className='chipContainerHeading'>
                        User or Group
                      </p>
                      <ConversationAccordionChips
                        chipData={[
                          ...x?.permissionsUserGroups.map(item => ({
                            id: item.userId || item.groupId || item.role,
                            value: item.name,
                            generic: item.type,
                        }))
                        ]}
                        text="Added users and groups will appear here"
                      />
                      <p className='chipContainerHeading'>
                        Can Start Conversations With
                      </p>
                      <ConversationAccordionChips
                        chipData={[
                          ...x?.permissionsStartUserGroups.map(item => ({
                            id: item.userId || item.groupId,
                            value: item.name,
                            generic: item.role
                        }))
                        ]}
                        text="Added users and groups will appear here"
                      />
                      <p className='chipContainerHeading'>
                        Can Receive New Conversations From
                      </p>
                      <ConversationAccordionChips
                        chipData={[
                          ...x?.permissionsReceiveUserGroups.map(item => ({
                            id: item.userId || item.groupId,
                            value: item.name,
                            generic: item.role
                        }))
                        ]}
                        text="Added users and groups will appear here"
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
            ) : <span className="emptyGridText">
            Uh Oh! No Conversation Permissions have been created.
            </span>
          }
        </Grid>
      </Grid>
    </div>
  )
}
