import React from 'react';

function NewsroomNotification(props) {
    return (
        <div className="settingsTabsBody">
           <h1>Newsroom notification</h1> 
        </div>
    );
}

export default NewsroomNotification;