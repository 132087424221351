import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import { RoutesObj } from "../../../../routes";
import { productSerialActions } from "../../../../store/product/productSerialsAction";
// import MoveIcon from "../../../../resources/images/move.png";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  registeredButton: {
    color: "#219653",
    backgroundColor: "rgba(33, 150, 83, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(33, 150, 83, 0.25)",
    },
  },
  blockedButton: {
    color: "#EB5757",
    backgroundColor: "rgba(235, 87, 87, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(235, 87, 87, 0.25)",
    },
  },
  missingButton: {
    color: "#56CCF2",
    backgroundColor: "rgba(86, 204, 242, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(86, 204, 242, 0.25)",
    },
  },
}));

const getStatusClass = (status) => {
  switch (status) {
    case 0:
    case "Pending":
    case "Unregistered":
    case "Registration Pending":
      return "pendingButtons";
    case 1:
    case "Approved":
    case "Register":
    case "Registered":
    case "Registration Approved":
      return "registeredButton";
    case 5:
    case "Expired":
    case "Denied":
    case "Rejected":
    case "Registration Denied":
      return "blockedButton";
    case 6:
    case "Incomplete":
    case "Mark as Incomplete":
      return "pendingButtons";
    case 2:
    case "Blocked":
      return "blockedButton";
    default:
      return 'missingButton';
  }
};

function AllProductSerials({ listData, loadData, order, onOrderBy }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.ProductSerial.path,
      state: { device: value },
      search: `id=${value.id}`,
    });
  };

  const onItemSelect = itemId => {
    const itemExists =
      selectedItems.length > 0 && selectedItems.find(c => c === itemId);
    if (itemExists) {
      setSelectedItems(selectedItems.filter(c => c !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const deleteItems = () => {
    dispatch(
      productSerialActions.onDeleteItems({
        ids: selectedItems,
      })
    ).then(() => {
      loadData();
      setSelectedItems([]);
    });
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        {selectedItems.length > 0 ? (
          <Grid item md={9} className={classes.deleteItems}>
            <span className='gridDeleteItems' onClick={() => deleteItems()}>
              <DeleteIcon /> Delete
            </span>
            {/* <span className='gridMoveItems' onClick={() => {}}>
              <img src={MoveIcon} alt='' /> Move to
            </span> */}
          </Grid>
        ) : null}
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont} disabled={true} />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              SERIAL NUMBER
              <TableSortLabel
                active={order.orderByKey === "SerialNo"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "SerialNo",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PRODUCT NAME
              <TableSortLabel
                active={order.orderByKey === "ProductName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "ProductName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              SKU
              <TableSortLabel
                active={order.orderByKey === "SKU"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "SKU",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PRODUCT CATEGORY
              <TableSortLabel
                active={order.orderByKey === "CategoryName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CategoryName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              STATUS
              <TableSortLabel
                active={order.orderByKey === "Status"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Status",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell
              className={
                (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
              }>
              QUICK ACTIONS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listData?.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridHeaderInner}>
                  <Checkbox
                    className={classes.checkBoxFont}
                    onClick={() => onItemSelect(item.id)}
                    checked={
                      selectedItems.length > 0 &&
                      selectedItems?.includes(item.id)
                    }
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.serialNo}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.productName}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.sku}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.categoryName || "--"}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.status ? (
                    <Button
                      variant='contained'
                      className={classes[getStatusClass(item.status)]}>
                      {item.status}
                    </Button>
                  ) : (
                    "--"
                  )}
                </TableCell>
                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions productsAction'>
                    <span onClick={() => redirectUrl(item)}>
                      <EditIcon />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}

          {listData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any Serial.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export { AllProductSerials };
