export const contactsTypes = {
  CONTACTS_ONCHANGE: "CONTACTS_ONCHANGE",
  SELECTED_ALL_CONTACTS: "SELECTED_ALL_CONTACTS",
  SELECTED_CUS_GROUPS: "SELECTED_CUS_GROUPS",
  PRESS_ADD_MORE: "PRESS_ADD_MORE",
  CHANGE_LEADER_VALUE: "CHANGE_LEADER_VALUE",
  ON_SELECT_GROUP: "ON_SELECT_GROUP",
  ON_PRESS_FILTER_CONTACT: "ON_PRESS_FILTER_CONTACT",
  ON_ADD_CONTACT_NAME_USER_FORM: "ON_ADD_CONTACT_NAME_USER_FORM"
};
