import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import { ThumbUpOutlined } from "@material-ui/icons";
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import InputField from "../../../../components/common/InputField";
import {
  ActionContent,
  DateTimeSelector,
  Dropdown,
  Modal,
} from "../../../../components/common";
import { useDropdownValues } from "../../../../utils/use-dropdown-values";
import { endpoints } from "../../../../config/apiConfig";
import { AddAvailabilityPeriodForm } from "../ToolsAndPortal/PricingList/components/AddAvailabilityPeriodForm";
import { useAvailabilityPeriod } from "../../../../utils/use-availability-period";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "8px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
      boxShadow: "none",
    },
  },
}));

const EditableRow = ({ index, item, followedByPeriods, onItemSave }) => {
  const classes = useStyles();
  const [availabilityPeriod, setAvailabilityPeriod] = useState(item);
  const [localFollowedByPeriods, setfollowedByPeriods] = useState([]);

  useEffect(() => {
    setAvailabilityPeriod({
      ...item,
      startPeriodDate: item?.startDate,
      endPeriodDate: item?.endDate,
    });
  }, [item]);

  const setProp = ({ propName, value }) => {
    setAvailabilityPeriod({
      ...availabilityPeriod,
      [propName]: value,
    });
  };

  useEffect(() => {
    setfollowedByPeriods([
      { id: "0", name: "None" },
      [
        followedByPeriods?.length > 0
          ? followedByPeriods?.map(p => {
              return {
                id: p.id,
                name: p.name,
              };
            })
          : [],
      ],
    ]);
  }, [followedByPeriods]);

  useEffect(() => {
    if (availabilityPeriod.startPeriodDate) {
      setfollowedByPeriods([
        { id: "0", name: "None" },
        ...followedByPeriods
          ?.filter(
            fp =>
              new Date(availabilityPeriod?.startPeriodDate) >=
              new Date(fp.endDate)
          )
          ?.map(p => {
            return {
              id: p.id,
              name: p.name,
            };
          }),
      ]);
    }
  }, [availabilityPeriod.startPeriodDate, followedByPeriods]);

  return (
    <TableRow key={index} className='gridBodyRow'>
      <TableCell className={classes.gridHeaderInner} />
      <TableCell className={classes.gridBodyInner}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <InputField
            autoFocus={true}
            placeholder='Enter Time Period Name'
            id='TimePeriodName'
            name='TimePeriodName'
            validators={["required", "matchRegexp:^.{0,50}$"]}
            errorMessages={[
              "Please Enter Time Period Name",
              "Maximum length is 50 characters",
            ]}
            value={availabilityPeriod?.periodName || ""}
            onChange={({ target }) => {
              setProp({
                propName: "periodName",
                value: target.value,
              });
            }}
          />
        </span>
      </TableCell>
      <TableCell className={classes.gridBodyInner}>
        <DateTimeSelector
          selectedDateTime={availabilityPeriod?.startPeriodDate}
          onDateTimeChanged={value => {
            setProp({
              propName: "startPeriodDate",
              value: value,
            });
          }}
        />
      </TableCell>
      <TableCell className={classes.gridBodyInner}>
        <DateTimeSelector
          selectedDateTime={availabilityPeriod?.endPeriodDate}
          onDateTimeChanged={value => {
            setProp({
              propName: "endPeriodDate",
              value: value,
            });
          }}
        />
      </TableCell>
      <TableCell className={classes.gridBodyInner}>
        {"Add This Period to Products"}
      </TableCell>
      <TableCell className={classes.gridBodyInner}>
        <span>
          {/* <InputField
            placeholder='Seach Periods'
            id='SeachPeriods'
            name='SeachPeriods'
            validators={["required", "matchRegexp:^.{0,50}$"]}
            errorMessages={[
              "Please Enter Time Period Name",
              "Maximum length is 50 characters",
            ]}
            value={availabilityPeriod?.followedBy || ""}
            onChange={({ target }) => {
              setProp({
                propName: "followedBy",
                value: target.value,
              });
            }}
          /> */}
          <Dropdown
            placeholder='Please Select'
            value={`${availabilityPeriod?.followedBy}`}
            required={false}
            customMenuItems={localFollowedByPeriods}
            handleChange={({ target }) =>
              setProp({
                propName: "followedBy",
                value: target.value,
              })
            }
          />
        </span>
      </TableCell>
      <TableCell
        // className={classes.gridBodyInner}
        style={{
          textAlign: "center",
          lineHeight: "unset",
        }}>
        <span
          onClick={() => {
            if (availabilityPeriod?.periodName) {
              const userId = localStorage.getItem("id");

              onItemSave({
                id: availabilityPeriod?.id || 0,
                periodName: availabilityPeriod?.periodName,
                startPeriodDate: availabilityPeriod?.startPeriodDate
                  ? moment(availabilityPeriod?.startPeriodDate).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )
                  : "",
                endPeriodDate: availabilityPeriod?.endPeriodDate
                  ? moment(availabilityPeriod?.endPeriodDate).format(
                      "MM/DD/YYYY HH:mm:ss"
                    )
                  : "",
                followedBy: availabilityPeriod?.followedBy,
                userId: userId,
              });
            } else toast.error("Please fill period data to save record");
          }}
          style={{
            cursor: "pointer",
          }}>
          <ThumbUpOutlined color='primary' />
        </span>
      </TableCell>
    </TableRow>
  );
};

const AvailabilityPeriodsGrid = ({
  listData,
  loadData,
  order,
  onOrderBy,
  isNewAvailabilityPeriod,
  setIsNewAvailabilityPeriod,
  selectedItems,
  setSelectedItems,
}) => {
  const classes = useStyles();
  const [editRow, setEditableRow] = useState(null);
  const [isAddAvailabilityPeriod, setAddAvailabilityPeriod] = useState(false);
  const [isDuplicateAvailabilityPeriod, setDuplicateAvailabilityPeriod] =
    useState(false);

  const { dropdownValues: followedByPeriodsDD } = useDropdownValues({
    url: endpoints.productInventory.getFollowedByPeriodsDropdown,
  });

  const { dropdownValues: availabilityPeriodDD, loadDropdownList } =
    useDropdownValues({
      url: endpoints.productInventory.getPricingAvailabilityPeriodDropdownV2,
    });

  const { addUpdateAvailabilityPeriod } = useAvailabilityPeriod({
    AvailabilityPeriod: editRow,
  });

  useEffect(() => {
    if (editRow?.id) {
      setAddAvailabilityPeriod(!isAddAvailabilityPeriod);
      if ([0, 1].includes(editRow?.duplicateAvailabilitityPeriod))
        setDuplicateAvailabilityPeriod(true);
    }
  }, [editRow?.id]);

  const onItemSelect = itemId => {
    const itemExists =
      selectedItems.length > 0 && selectedItems.find(c => c === itemId);
    if (itemExists) {
      setSelectedItems(selectedItems.filter(c => c !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const onItemSave = async editedRow => {
    const rowSuccess = await addUpdateAvailabilityPeriod(editedRow);
    if (rowSuccess) {
      setEditableRow(null);
      if (isNewAvailabilityPeriod) setIsNewAvailabilityPeriod(false);
      loadData();
    }
  };

  useEffect(() => {
    if (!isAddAvailabilityPeriod) {
      setEditableRow(null);
      setDuplicateAvailabilityPeriod(false);
    }
  }, [isAddAvailabilityPeriod]);

  const onItemSelectAll = () => {
    if (!selectedItems.length) {
      const allIds = listData.map((product) => product.id);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <Paper>
      <ValidatorForm onSubmit={() => {}}>
        <Table>
          <TableHead className={classes.gridHeader}>
            <TableRow className={classes.gridHeaderRow}>
              <TableCell className={classes.gridHeaderInner}>
                <Checkbox className={classes.checkBoxFont} 
                  onClick={onItemSelectAll}
                  checked={listData.length === selectedItems.length}
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Availability Period Name
                <TableSortLabel
                  active={order.orderByKey === "PeriodName"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "PeriodName",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Pricing Start Date
                <TableSortLabel
                  active={order.orderByKey === "StartDate"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "StartDate",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Pricing End Date
                <TableSortLabel
                  active={order.orderByKey === "EndDate"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "EndDate",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Number of Products
                <TableSortLabel
                  active={order.orderByKey === "Products"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Products",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
              {/* <TableCell className={classes.gridHeaderInner}>
                Followed By
                <TableSortLabel
                  active={order.orderByKey === "FollowedBy"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "FollowedBy",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                />
              </TableCell> */}
              <TableCell
              style={{textAlign:"center"}}
                className={
                  classes.gridHeaderInner
                    //  "quickLinkWrapper quickLinkHeader")
                }>
                Quick Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listData?.map((item, index) => {
              return editRow?.id === item.id ? (
                <EditableRow
                  index={index}
                  item={item}
                  followedByPeriods={[
                    ...followedByPeriodsDD.filter(p => p?.id !== editRow?.id),
                  ]}
                  onItemSave={onItemSave}
                />
              ) : (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell className={classes.gridHeaderInner}>
                    <Checkbox
                      className={classes.checkBoxFont}
                      onClick={() => onItemSelect(item.id)}
                      checked={
                        selectedItems.length > 0 &&
                        selectedItems?.includes(item.id)
                      }
                    />
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.periodName}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {`${moment(item.startDate).format("M/D/YYYY")} ${moment(
                      item.startDate
                    ).format("hh:mm A")}`}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {`${moment(item.endDate).format("M/D/YYYY")} ${moment(
                      item.endDate
                    ).format("hh:mm A")}`}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}
                  style={{textAlign: 'center'}}>
                    {item.products}
                  </TableCell>
                  {/* <TableCell className={classes.gridBodyInner}>
                    {(["None", "0", null].includes(item?.followedBy)
                      ? "None"
                      : followedByPeriodsDD.find(
                          f => `${f.id}` === `${item.followedBy}`
                        )?.name) || "-"}
                  </TableCell> */}
                  <TableCell
                    className={(classes.gridBodyInner, "quickLinkWrapper")}>
                    <div className='gridActions productsAction'
                    style={{
                      justifyContent:"center",
                    }}
                    >
                      <span
                        onClick={() =>
                          setEditableRow({
                            ...item,
                            startPeriodDate: item.startDate,
                            endPeriodDate: item.endDate,
                          })
                        }
                        style={{
                          cursor: "pointer",
                        }}>
                        <EditIcon />
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
            {isNewAvailabilityPeriod && (
              <EditableRow
                index={listData?.length + 1}
                item={{
                  id: 0,
                }}
                followedByPeriods={[...followedByPeriodsDD]}
                onItemSave={onItemSave}
              />
            )}
            {listData.length <= 0 && (
              <TableRow key='0' className='gridBodyRow'>
                <TableCell
                  colSpan='7'
                  className={classes.gridBodyInner}
                  style={{ textAlign: "center" }}>
                  <span className='emptyGridText'>
                    Uh Oh! You haven’t any availability period.
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ValidatorForm>
      {isAddAvailabilityPeriod ? (
        <AddAvailabilityPeriodForm
          isAddAvailabilityPeriod={isAddAvailabilityPeriod}
          setAddAvailabilityPeriod={setAddAvailabilityPeriod}
          isDuplicateAvailabilityPeriod={isDuplicateAvailabilityPeriod}
          availabilityPeriodDD={availabilityPeriodDD?.filter(
            ap => !["0", "-1"].includes(ap?.key)
          )}
          selectedAvailabilityPeriod={editRow}
          onSuccess={success => {
            if (success) {
              setAddAvailabilityPeriod(false);
              setEditableRow(null);
              loadData();
              loadDropdownList(
                endpoints.productInventory
                  .getPricingAvailabilityPeriodDropdownV2
              );
            }
          }}
        />
      ) : (
        <Modal
          open={isNewAvailabilityPeriod}
          setOpen={setIsNewAvailabilityPeriod}
          title={"Add New Availability Period"}
          primaryHeading='Choose How You Want To Create A New Availability Period'
          secondaryHeading='Start from scratch with blank availability period and no pricing or customers or duplicate an existing availability period.'>
          <Grid container>
            <ActionContent
              mainHeading='Create a New Availability Period from Scratch'
              secondaryHeading='Start with a Blank Availability Period where you can add dates of Availability now + add pricing & customers later.'
              onAction={() => {
                setDuplicateAvailabilityPeriod(false);
                setAddAvailabilityPeriod(!isAddAvailabilityPeriod);
              }}
            />
            <ActionContent
              mainHeading='Duplicate/Copy Existing Availability Period'
              secondaryHeading='Create a New Availability by duplicating both pricing and customers from a past availability period to a future one.'
              onAction={() => {
                setDuplicateAvailabilityPeriod(true);
                setAddAvailabilityPeriod(!isAddAvailabilityPeriod);
              }}
            />
          </Grid>
        </Modal>
      )}
    </Paper>
  );
};

export { AvailabilityPeriodsGrid };
