import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getPubAllAssets(assetPayload) {
  return axios
    .post(endpoints.pubMediaLibrary.getAllPubMediaAssets, assetPayload)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubMedCategory() {
  return axios
    .post(endpoints.pubMediaLibrary.getAllPubMedCategory)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubMedDateFilter(catIds) {
  return axios
    .post(endpoints.pubMediaLibrary.getAllPubDateMedia, {
      categoryIds: catIds,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCollectionDetail(colId, urlSlug) {
  return axios
    .post(endpoints.pubMediaLibrary.getCollectionDetail, {
      collectionId: colId,
      urlSlug: urlSlug,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function downLoadAssets(assetData) {
  return axios
    .post(endpoints.pubMediaLibrary.downloadAssets, assetData, {
      responseType: "blob",
    })
    .then(response => {
      // let data = response.data;
      return response;
    })
    .catch(error => {
      return null;
    });
}

export const pubMediaLibraryApi = {
  getPubAllAssets: getPubAllAssets,
  getPubMedCategory: getPubMedCategory,
  getPubMedDateFilter: getPubMedDateFilter,
  getCollectionDetail: getCollectionDetail,
  downLoadAssets: downLoadAssets,
};
