import React, { useEffect, useState } from "react";
import { concat } from "lodash";
import { TabNavigator } from "../../../../components/common/Tabs/TabNavigator";
import { NewCollectionForm } from "../AssetManagement/NewCollectionForm";

const CollectionsTab = ({ isActive, existinglaunch }) => {
  const [activeRecordIndex, setActiveRecord] = useState(0);
  const [collection, setCollection] = useState([
    {
      index: 0
    }
  ]);

  useEffect(() => {
    if (existinglaunch?.collectionsIds?.length > 0) {
      setCollection(
        existinglaunch?.collectionsIds?.map((e, i) => {
          return {
            index: i,
            collectionId: e
          };
        })
      );
    }
  }, [existinglaunch?.collectionsIds]);

  return (
    isActive && (
      <div style={{ flex: 1 }}>
        <TabNavigator
          tabsTitle='Collection'
          tabs={collection}
          onAdd={newC => setCollection(concat(collection, newC))}
          onActiveTabClick={tabIndex => setActiveRecord(tabIndex)}
        />
        <div>
          <NewCollectionForm
            existinglaunch={existinglaunch}
            activeCollectionId={
              collection[activeRecordIndex]?.collectionId || null
            }
            existinglaunch={existinglaunch}
          />
        </div>
      </div>
    )
  );
};
export { CollectionsTab };
