import React from "react";
import Grid from "@material-ui/core/Grid";
import StorageBreakdown from "../../../../components/common/storageBreakdown/StorageBreakdown";
import { useSelector } from "react-redux";

const LibraryOverview = () => {
  const { assetsStats } = useSelector((state) => state.AssetsReducer);

  return (
      <div className="libraryOverview">
        <span className="libraryOverviewTitle">Library Overview</span>
        <Grid container className="libraryOverviewContent">
          <Grid item xs={7} className="storageBreakDown">
            <p className="storageBreakDownTitle">
              <span className="storageBreakDownTextColor">
                Library Storage Breakdown
              </span> &nbsp;
              {assetsStats?.storageUsed}
            </p>
            <StorageBreakdown
              value1={assetsStats?.assetsFilesSizePercentage}
              value11={assetsStats?.assetsFilesSize}
              value2={assetsStats?.conversationFilesSizePercentage}
              value22={assetsStats?.conversationFilesSize}
              value3={assetsStats?.productRegistrationFilesSizePercentage}
              value33={assetsStats?.productRegistrationFilesSize}
            />
          </Grid>
          <Grid xs={5} className="libraryOverviewDataContent">
          <Grid className="libraryOverviewDataPart">
            <Grid className="libraryOverviewData">              
              <Grid>
                {assetsStats?.photosCount}
              </Grid>
              <Grid className="libraryOverviewMidData">
                {assetsStats?.videosCount}
              </Grid>
              <Grid>
                {assetsStats?.pdfCount}
              </Grid>
            </Grid>
            <Grid className="libraryOverviewDataName libraryOverviewData">
              <Grid>
                Photo Files
              </Grid>
              <Grid className="libraryOverviewMidData">
                Video Files
              </Grid>
              <Grid>
                ALL PDFs
              </Grid>
            </Grid>
            </Grid>
            <Grid className="libraryOverviewDataPart">
            <Grid className="libraryOverviewData">
              <Grid>
                {assetsStats?.doucmentsCount}
              </Grid>
              <Grid className="libraryOverviewMidData">
                {assetsStats?.conversationAttachmentsCount}
              </Grid>
              <Grid>
                {assetsStats?.productPhotosCount}
              </Grid>
            </Grid>
            <Grid className={"libraryOverviewDataName libraryOverviewData"}>
              <Grid>
                Documents
              </Grid>
              <Grid className="libraryOverviewMidData">
                Conversation Attachments
              </Grid>
              <Grid>
                Product Registration Photos
              </Grid>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
  );
};

export default LibraryOverview;
