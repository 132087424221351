import { accountTypes } from "./accountTypes";

const INITIAL_STATE = {
  errorMessage: "",
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case accountTypes.ACCOUNT_LOGIN_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    default:
      return state;
  }
};
