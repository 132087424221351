import React, { useEffect, useState } from "react";
import { concat } from "lodash";
import { TabNavigator } from "../../../../components/common/Tabs/TabNavigator";
import { ProductOverview } from "../ProductInventory";

const ProductsTab = ({ existinglaunch, isActive }) => {
  const [activeRecordIndex, setActiveRecord] = useState(0);
  const [products, setProducts] = useState([
    {
      index: 0
    }
  ]);

  useEffect(() => {
    if (existinglaunch?.productIds?.length > 0) {
      setProducts(
        existinglaunch?.productIds?.map((e, i) => {
          return {
            index: i,
            productId: e
          };
        })
      );
    }
  }, [existinglaunch?.productIds]);

  return (
    isActive && (
      <div style={{ flex: 1 }}>
        <TabNavigator
          tabsTitle='Product'
          tabs={products}
          onAdd={newP => setProducts(concat(products, newP))}
          onActiveTabClick={tabIndex => setActiveRecord(tabIndex)}
        />
        <div>
          <ProductOverview
            activeProductId={products[activeRecordIndex]?.productId || null}
            existinglaunch={existinglaunch}
            onProductLoad={p => {
              console.log(p);
            }}
          />
        </div>
      </div>
    )
  );
};
export { ProductsTab };
