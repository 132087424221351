import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { searchIcon } from "../../../../resources/images";
import { ValidatorForm } from "react-material-ui-form-validator";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { useDebounce } from "../../../../utils/use-debounce";
import { ProductsOwnedQtyList } from "../../../../config/Enums";

const handleSubmit = e => {
  e.preventDefault();
};

function ProductDetails({
  SelectedPC,
  setSelectedPC,
  SubCategories,
  SelectedSCs,
  setSelectedSC,
  DeviceRegisteredQty,
  setDeviceRegisteredQty,
  environmentData,
  setEnvironmentData,
  onEnvirnmentDataChange,
  environmentList,
  ProductName,
  setProductName,
  ProductSKU,
  productStatus,
  setProductStatus,
  productStatusList,
  setProductSKU,
  onProdNameChange,
  prodtNamesList = [],
  onProdSKUChange,
  prodSKUList = [],
}) {
  const dispatch = useDispatch();

  const [environmentTxt, setEnvironmentSearch] = useState("");
  const { debouncedValue : environmentTxtDebounce } = useDebounce(environmentTxt, 500);

  const [productNameTxt, setProdNameSearch] = useState("");
  const { debouncedValue } = useDebounce(productNameTxt, 500);

  const [productSKUTxt, setProdSKUSearch] = useState("");
  const { debouncedValue: skuDebounce } = useDebounce(productSKUTxt, 500);

  const [parentCategories, setParentCategories] = useState([]);

  const loadProductCategories = useCallback(
    ({ parentCatId = 0 }) => {
      dispatch(
        productCategoriesActions.onGetAllProductSubCategories(parentCatId)
      ).then(data => {
        if (data?.success) {
          if (parentCatId === 0) {
            setParentCategories(data?.data);
          }
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    loadProductCategories({ parentCatId: 0 });
  }, [loadProductCategories]);

  useEffect(() => {
    if (debouncedValue) {
      onProdNameChange(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (skuDebounce) {
      onProdSKUChange(skuDebounce);
    }
  }, [skuDebounce]);

  useEffect(() => {
    if (environmentTxtDebounce) {
      onEnvirnmentDataChange(environmentTxtDebounce);
    }
  }, [environmentTxtDebounce]);

  return (
    <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
      <div className='userDetailsSearchField inputWrapper filterSelect endUserSearchfilter'>
        
      <FormControl className='ac-dd'>
          <Autocomplete
            value={environmentList.filter(eg => environmentData?.includes(eg.id))}
            onChange={(event, value) => setEnvironmentData(value?.map(g => g.id))}
            multiple
            filterSelectedOptions
            id='product-category-dd'
            options={environmentList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField 
              {...params} 
              placeholder={"Environments"}
              />
            )}
          />
          {environmentData?.length > 0 && (
            <span className='countLabel'>{environmentData?.length}</span>
          )}
        </FormControl>
        
        <FormControl className='ac-dd'>
          <Autocomplete
            value={SelectedPC}
            onChange={(event, value) => {
              setSelectedPC(value);
            }}
            multiple
            filterSelectedOptions
            id='product-category-dd'
            options={parentCategories}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField {...params} placeholder={"Product Category"} />
            )}
          />
          {SelectedPC?.length > 0 && (
            <span className='countLabel'>{SelectedPC?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd'>
          <Autocomplete
            value={SelectedSCs}
            onChange={(event, value) => setSelectedSC(value)}
            multiple
            id='prod-sc-ac'
            options={SubCategories}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField {...params} placeholder={"Product Sub Category"} />
            )}
          />
          {SelectedSCs?.length > 0 && (
            <span className='countLabel'>{SelectedSCs?.length}</span>
          )}
        </FormControl>

        {/* <FormControl className='ac-dd'>
          <Autocomplete
            value={DeviceRegisteredQty}
            onChange={(event, value) => {
              setDeviceRegisteredQty(value);
            }}
            multiple
            filterSelectedOptions
            id='product-owned-dd'
            options={ProductsOwnedQtyList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField {...params} placeholder={"Products Owned Qty"} />
            )}
          />
          {DeviceRegisteredQty?.length > 0 && (
            <span className='countLabel'>{DeviceRegisteredQty?.length}</span>
          )}
        </FormControl> */}

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={ProductName}
            onChange={(event, value) => {
              if (value.length > 0) setProductName(value);
              else if (!value) {
                setProductName([]);
                setProdNameSearch("");
              }
            }}
            multiple
            filterSelectedOptions
            id='prod-name-ac'
            options={prodtNamesList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={"Product Name"}
                onChange={({ target }) => setProdNameSearch(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {ProductName?.length > 0 && (
            <span className='countLabel'>{ProductName?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={ProductSKU}
            onChange={(event, value) => {
              if (value.length > 0) setProductSKU(value);
              else if (!value) {
                setProductSKU([]);
                setProdSKUSearch("");
              }
            }}
            multiple
            filterSelectedOptions
            id='prod-sku-ac'
            options={prodSKUList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={"Product SKU"}
                onChange={({ target }) => setProdSKUSearch(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {ProductSKU?.length > 0 && (
            <span className='countLabel'>{ProductSKU?.length}</span>
          )}
        </FormControl>
        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={productStatusList.filter(eg => productStatus?.includes(eg.id))}
            onChange={(event, value) => setProductStatus(value?.map(g => g.id))}
            multiple
            filterSelectedOptions
            id='prod-sku-ac'
            options={productStatusList}
            getOptionLabel={option => option?.name || ""}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={"Product Status"}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' />  */}
          {productStatus?.length > 0 && (
            <span className='countLabel'>{productStatus?.length}</span>
          )} 
        </FormControl>
      </div>
    </ValidatorForm>
  );
}

export { ProductDetails };
