import { newsroomApi } from "./newsroomApi";
import { newsroomTypes } from "./newsroomTypes";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: newsroomTypes.NEWSROOM_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const addNewQuickLinks = () => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_ADD_QUICK_LINKS,
  });
};

const addNewFeaturedContent = () => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_ADD_FEATURED_CONTENT,
  });
};

const removeQuickLinks = index => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_REMOVE_QUICK_LINKS,
    payload: index,
  });
};

const removeFeaturedContent = index => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_REMOVE_FEATURED_CONTENT,
    payload: index,
  });
};

const onChangeFeaturedContentValue =
  (index, value, fieldName) => async dispatch => {
    dispatch({
      type: newsroomTypes.CHANGE_FEATURED_CONTENT_VALUE,
      payload: { index: index, value: value, fieldName: fieldName },
    });
  };

const onChangeQuickLinksValue = (index, value, fieldName) => async dispatch => {
  dispatch({
    type: newsroomTypes.CHANGE_QUICK_LINKS_VALUE,
    payload: { index: index, value: value, fieldName: fieldName },
  });
};

const addNewLeader = () => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_ADD_LEADER,
  });
};

const removeLeader = index => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_REMOVE_LEADER,
    payload: index,
  });
};

const onChangeLeaderValue = (index, value, fieldName) => async dispatch => {
  dispatch({
    type: newsroomTypes.CHANGE_LEADER_VALUE,
    payload: { index: index, value: value, fieldName: fieldName },
  });
};

const onAddNewContact = () => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_ADD_CONTACT,
  });
};
const onRemoveContact = index => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_REMOVE_CONTACT,
    payload: index,
  });
};
const onAddNewEmail = () => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_ADD_EMAIL,
  });
};
const onRemoveEmail = index => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_REMOVE_EMAIL,
    payload: index,
  });
};
const onAddNewSocials = () => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_ADD_SOCIALS,
  });
};
const onRemoveSocials = index => async dispatch => {
  dispatch({
    type: newsroomTypes.NEWSROOM_REMOVE_SOCIALS,
    payload: index,
  });
};

const onChangeBusinessHour = (index, value, fieldName) => async dispatch => {
  dispatch({
    type: newsroomTypes.CHANGE_BUSINESS_HOURS_VALUE,
    payload: { index: index, value: value, fieldName: fieldName },
  });
};
const onChangeContact = (index, value, fieldName) => async dispatch => {
  dispatch({
    type: newsroomTypes.CHANGE_CONTACT_VALUE,
    payload: { index: index, value: value, fieldName: fieldName },
  });
};

const onChangeEmails = (index, value, fieldName) => async dispatch => {
  dispatch({
    type: newsroomTypes.CHANGE_EMAIL_VALUE,
    payload: { index: index, value: value, fieldName },
  });
};

const onChangeSocials = (index, value, fieldName) => async dispatch => {
  dispatch({
    type: newsroomTypes.CHANGE_SOCIALS_VALUE,
    payload: { index: index, value: value, fieldName: fieldName },
  });
};

const onGetNewRoomDD = () => async dispatch => {
  let response = await newsroomApi.getNewsroomDD();
  return response;
};

const onGetSettings = () => async dispatch => {
  let response = await newsroomApi.getSettings();
  return response;
};

const onGetCompanyOverview = () => async dispatch => {
  let response = await newsroomApi.getCompanyOverview();
  return response;
};

const onGetLeadership = () => async dispatch => {
  let response = await newsroomApi.getLeadership();
  return response;
};

const onGetContactInfo = () => async dispatch => {
  let response = await newsroomApi.getContactInfo();
  return response;
};

const onSaveSettings =
  (
    internalName,
    externalName,
    newsRoomURL,
    newsRoomStatus,
    viewOnlyGroup,
    viewAndDownloadGroup
  ) =>
  async dispatch => {
    let response = await newsroomApi.saveSettings(
      internalName,
      externalName,
      newsRoomURL,
      newsRoomStatus,
      viewOnlyGroup,
      viewAndDownloadGroup
    );
    return response;
  };

const onSaveCompanyOverview = (desc, imgUrl) => async dispatch => {
  let response = await newsroomApi.saveCompanyOverview(desc, imgUrl);
  return response;
};

const onSaveBoilerPlateDescription = desc => async dispatch => {
  let response = await newsroomApi.onSaveBoilerPlateDescription(desc);
  return response;
};

const onSaveLeaderShip = leaderValue => async dispatch => {
  let response = await newsroomApi.saveLeaderShip(leaderValue);
  return response;
};

const onSaveComContact = contactData => async dispatch => {
  let response = await newsroomApi.saveComContact(contactData);
  return response;
};
const onGetAllNewsRoomContentByRoomId = () => async dispatch => {
  let response = await newsroomApi.getAllNewsRoomContentByRoomId();
  return response;
};
const onGetMenuByNewsRoomId = () => async dispatch => {
  let response = await newsroomApi.getMenuByNewsRoomId();
  return response;
};

const onGetNewsRoomQuickLinks = () => async dispatch => {
  let response = await newsroomApi.getNewsRoomQuickLinks();
  if (response?.success) {
    dispatch({
      type: newsroomTypes.NEWSROOM_ONCHANGE,
      payload: { prop: "quickLinks", value: response?.data },
    });
  }
  return response;
};
const onGetSortMenuOptions = () => async dispatch => {
  let response = await newsroomApi.getSortMenuOptions();
  if (response?.success) {
    dispatch({
      type: newsroomTypes.NEWSROOM_ONCHANGE,
      payload: { prop: "menuHeader", value: response?.data },
    });
  }
  return response;
};

const onGetQuickLinks = () => async dispatch => {
  let response = await newsroomApi.getQuickLinks();
  return response;
};

const onGetFeaturedContent = () => async dispatch => {
  let response = await newsroomApi.getFeaturedContent();
  return response;
};

const onGetMenuOptions = () => async dispatch => {
  let response = await newsroomApi.getMenuOptions();
  return response;
};

const onGetNavigations = () => async dispatch => {
  let response = await newsroomApi.getNavigations();
  return response;
};

const onSaveQuickLinks = contactData => async dispatch => {
  let response = await newsroomApi.saveQuickLinks(contactData);
  return response;
};

const onDeleteNewsRoomContent = id => async dispatch => {
  let response = await newsroomApi.deleteNewsRoomContent(id);
  return response;
};

const onSaveNewsRoomNavigation = data => async dispatch => {
  let response = await newsroomApi.saveNewsRoomNavigation(data);
  return response;
};

const onSaveFeaturedContent =
  (
    contentId,
    slideTitle,
    slideSubTitle,
    bannerImageUrl,
    linkTo,
    url,
    urlTarget,
    showImageOnly
  ) =>
  async dispatch => {
    let response = await newsroomApi.saveFeaturedContent(
      contentId,
      slideTitle,
      slideSubTitle,
      bannerImageUrl,
      linkTo,
      url,
      urlTarget,
      showImageOnly
    );
    return response;
  };

export const newsroomActions = {
  onChange: onChange,
  addNewLeader: addNewLeader,
  removeLeader: removeLeader,
  onChangeLeaderValue: onChangeLeaderValue,
  onAddNewContact: onAddNewContact,
  onRemoveContact: onRemoveContact,
  onAddNewEmail: onAddNewEmail,
  onRemoveEmail: onRemoveEmail,
  onAddNewSocials: onAddNewSocials,
  onChangeBusinessHour: onChangeBusinessHour,
  onChangeContact: onChangeContact,
  onChangeEmails: onChangeEmails,
  onChangeSocials: onChangeSocials,
  onRemoveSocials: onRemoveSocials,
  onGetNewRoomDD: onGetNewRoomDD,
  onGetCompanyOverview: onGetCompanyOverview,
  onGetLeadership: onGetLeadership,
  onGetContactInfo: onGetContactInfo,
  onSaveCompanyOverview: onSaveCompanyOverview,
  onSaveLeaderShip: onSaveLeaderShip,
  onSaveComContact: onSaveComContact,
  onGetAllNewsRoomContentByRoomId: onGetAllNewsRoomContentByRoomId,
  onGetMenuByNewsRoomId: onGetMenuByNewsRoomId,
  onGetNewsRoomQuickLinks: onGetNewsRoomQuickLinks,
  addNewQuickLinks: addNewQuickLinks,
  removeQuickLinks: removeQuickLinks,
  onChangeQuickLinksValue: onChangeQuickLinksValue,
  onGetQuickLinks: onGetQuickLinks,
  onSaveQuickLinks: onSaveQuickLinks,
  onSaveBoilerPlateDescription: onSaveBoilerPlateDescription,
  onSaveSettings: onSaveSettings,
  onGetSettings: onGetSettings,
  onChangeFeaturedContentValue: onChangeFeaturedContentValue,
  addNewFeaturedContent: addNewFeaturedContent,
  removeFeaturedContent: removeFeaturedContent,
  onSaveFeaturedContent: onSaveFeaturedContent,
  onGetFeaturedContent: onGetFeaturedContent,
  onGetMenuOptions: onGetMenuOptions,
  onGetNavigations: onGetNavigations,
  onSaveNewsRoomNavigation: onSaveNewsRoomNavigation,
  onDeleteNewsRoomContent: onDeleteNewsRoomContent,
  onGetSortMenuOptions: onGetSortMenuOptions,
};
