import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import Dashbard from "../Dashbard";
import { ValidatorForm } from "react-material-ui-form-validator";
import Overiew from "./Overiew";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { locationsActions } from "../../../../store/locations/locationsActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

function NewLocation(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const redirectUrl = () => {
    history.push("/location");
  };
  const {
    locationId,
    isDeleted,
    locationNameTitle,
    locationNameInternal,
    locationNamePublic,
    locationShortDescription,
    locationStreetAddress,
    locationCountryId,
    locationCityId,
    locationStateId,
    locationPostalCode,
    locationCountry,
    locationCity,
    locationState,
  } = useSelector(state => state.LocationsReducer);
  const onUpdateLocation = () => {
    setLoading(true);
    if (locationNameInternal === "") {
      toast.error("Location Name (Internal) must not be empty!");
      setLoading(false);
    } else if (locationStreetAddress === "") {
      toast.error("Location Street Address must not be empty!");
      setLoading(false);
    } else if (locationCountry === "") {
      toast.error("Choose any country or region!");
      setLoading(false);
    } else if (locationCity === "") {
      toast.error("Choose any city!");
      setLoading(false);
    } else if (locationState === "") {
      toast.error("Choose any state!");
      setLoading(false);
    } else if (locationPostalCode === "") {
      toast.error("Zip code or postal code must not be empty!");
      setLoading(false);
    } else {
      dispatch(
        locationsActions.onAddUpdateLocation(
          locationId,
          isDeleted,
          locationNameInternal,
          locationNamePublic,
          locationShortDescription,
          locationStreetAddress,
          locationCountry,
          locationCity,
          locationState,
          locationPostalCode
        )
      ).then(data => {
        if (data && !data.success) {
          setLoading(false);
          toast.error(data.message);
        } else {
          setLoading(false);
          redirectUrl();
          if (locationId === 0) {
            toast.success("Successfully added!");
          } else {
            toast.success("Successfully updated!");
          }
        }
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location && location.state && location.state.collection) {
      const editData = location.state.collection;
      dispatch(locationsActions.onChange("locationId", editData.id));
      dispatch(locationsActions.onChange("isDeleted", editData.isDeleted));
      dispatch(
        locationsActions.onChange("locationNameInternal", editData.nameInternal)
      );
      dispatch(
        locationsActions.onChange("locationNameTitle", editData.nameInternal)
      );
      dispatch(
        locationsActions.onChange("locationNamePublic", editData.namePublic)
      );
      dispatch(
        locationsActions.onChange(
          "locationShortDescription",
          editData.shortDescription
        )
      );
      dispatch(
        locationsActions.onChange(
          "locationStreetAddress",
          editData.streetAddress
        )
      );
      dispatch(
        locationsActions.onChange("locationCountryId", editData.countryId)
      );
      dispatch(locationsActions.onChange("locationCityId", editData.cityId));
      dispatch(locationsActions.onChange("locationStateId", editData.stateId));
      dispatch(
        locationsActions.onChange("locationPostalCode", editData.postalCode)
      );
      dispatch(
        locationsActions.onChange("locationCountry", editData.countryId)
      );
      dispatch(locationsActions.onChange("locationCity", editData.cityId));
      dispatch(locationsActions.onChange("locationState", editData.stateId));
    }
  }, []);
  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <ValidatorForm ref={React.useRef("form")} onSubmit={onUpdateLocation}>
          <div className='contentHeading'>
            <span>
              <h1>{locationNameTitle}</h1>
              <div className='consumerHeading'>
                <Button
                  color='primary'
                  onClick={redirectUrl}
                  variant='contained'>
                  <ArrowBackIosIcon /> Back to Locations
                </Button>
              </div>
            </span>
            <div className='dashboardNav'>
              <Button
                color='primary'
                variant='contained'
                className='dashboardButtons'
                type='submit'
                // onClick={() => onUpdateLocation()}
              >
                Save Location
              </Button>
            </div>
          </div>
          <Overiew />
        </ValidatorForm>
      </div>
    </Dashbard>
  );
}

export default NewLocation;
