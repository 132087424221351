import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashbard from "../Dashbard";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import { Pagination } from "../../../../components/common";
import Loader from "../../../../components/common/Loader";
import CollectionGridFilter from "./CollectionGridFilter";
import AllCollectionGrid from "./AllCollectionGrid";
import { ReleaseGridSideFilter } from "../ReleasesViews/ReleaseGridSideFilter";
// import GridFilter from "./GridFilter";
// import MediaLibraryList from "./MediaLibraryList";
// import AllColGridFilter from "./AllColGridFilter";
// import NewFolderPopup from "../../../../components/common/popUps/NewFolerPopup";
// import MediaUploadPopup from "../../../../components/common/popUps/MediaUploadPopup";

function AllCollections(props) {
  document.title = "OFish - Collections";

  const [allCollection, setAllCollection] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByName, setFilterByName] = useState("");
  const [selectedCategoryId, setSelectedCategory] = useState(null);
  const [collectionCategory, setCollectionCategory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(0);
  const [order, setOrderBy] = useState({
    orderByKey: "Name",
    orderVal: 0,
  });
  const dispatch = useDispatch();

  const loadAllCollection = useCallback(() => {
    setLoading(true);
    var resetPage =
      filterByName !== "" && filterByName === null ? (resetPage = 0) : page - 1;
    const modal = {
      name: filterByName,
      categoryIds: selectedCategoryId,
      noOfAssets: null,
      date: selectedDate,
      status: null,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    dispatch(assetsActions.onGetAllAssertCollection(modal)).then(data => {
      if (data && data.success) {
        if (data.data?.data) {
          const collectionList = data.data.data.map((x, index) => {
            return {
              ...x,
              id: x.id,
              name: x.name,
              noOfAssets: x.noOfAssets,
              createdOn: x.visibilityDate,
              categoryName: x.categoryName,
              status: x.status,
              checked: false,
              categoryColor: x.categoryColor,
              urlSlug: x.urlSlug,
            };
          });
          setPaginationResponse(data?.data?.pagination);
          if (data?.data?.pagination?.totalRecords < 10) {
            setPage(1);
          }
          setAllCollection(collectionList);
          var count = collectionList.find(x => x.checked === true);
          setIsDelete(count ? true : false);
        }
      }
      setLoading(false);
    });
  }, [
    dispatch,
    page,
    rowsPerPage,
    filterByName,
    selectedCategoryId,
    selectedDate,
    order.orderByKey,
    order.orderVal,
  ]);

  useEffect(() => {
    loadAllCollection();
    window.scrollTo(0, 0);
  }, [
    page,
    rowsPerPage,
    filterByName,
    selectedCategoryId,
    selectedDate,
    order.orderByKey,
    order.orderVal,
  ]);
  useEffect(() => {
    dispatch(assetsActions.onGetCollectionCategoryDropdown()).then(data => {
      if (data && data.success && data.data) {
        const catList = data.data.map((x, index) => {
          return {
            id: x.id,
            value: x.value,
            generic: x.generic,
            checked: false,
          };
        });
        setCollectionCategory(catList);
      }
    });
  }, []);

  const checkEventGroupsSelected = item => {
    allCollection.map(x => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    var count = allCollection.find(x => x.checked === true);
    setIsDelete(count ? true : false);
    setAllCollection([...allCollection], allCollection);
  };

  const history = useHistory();
  const redirectUrl = () => {
    history.push("/new-collections");
  };
  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>All Collections </h1>
          <span className='progress'>
            {/* <div className="progressBarValues">
              <span>50%</span>
              <span>250MB of Unlimited</span>
            </div>
            <div className="progresBar">
              <span className="progressBarInner" />
            </div> */}
          </span>
          <span className='mediaLibNav'>
            {/* <Button color="primary" variant="outlined" startIcon={<AddIcon />} onClick={() => setAddFolder(true)}>
            New Folder
          </Button> */}
            <Button
              color='primary'
              variant='contained'
              startIcon={<AddIcon />}
              onClick={redirectUrl}>
              New Collection
            </Button>
          </span>
        </div>

        <div className='gridBody contentBody'>
          {/* <AllColGridFilter /> */}
          {/* <CollectionGridFilter
            Categories={collectionCategory}
            setFilterByName={setFilterByName}
            setSelectedCategory={setSelectedCategory}
            setSelectedDate={setSelectedDate}
          /> */}
          <ReleaseGridSideFilter
            Categories={collectionCategory}
            setFilterByName={setFilterByName}
            setSelectedCategory={setSelectedCategory}
            setSelectedDate={setSelectedDate}
            filterTitle={"Collections"}
          />
          <AllCollectionGrid
            allCollectionData={allCollection}
            startLoading={() => setLoading(true)}
            loadData={() => loadAllCollection()}
            stopLoading={() => setLoading(false)}
            selectedRecord={item => {
              checkEventGroupsSelected(item);
            }}
            isDelete={isDelete}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={v => {
              setPage(v);
            }}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </div>
    </Dashbard>
  );
}

export default AllCollections;
