import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../components/common/InputField";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import AutoComplete from "../../../../components/common/AutoComplete";
import { debounce } from "lodash";
import { AddressAutoComplete } from "../../../../components/common";
import { FormattedDateInput } from "../../../../components/common/FormattedDateInput";
import moment from "moment";
import { EndUserCustomersModal } from "./EndUserCustomersModal";

export const SpaDeviceOverView = ({
  isSpaEdit,
  setIsSpaEdit,
  spaProductHandler,
  hideSpaProducts,
  deviceId,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isNewProductPricing, setIsNewProductPricing] = useState(false);
  const [isOwnership, setIsOwnership] = useState(true);
  const [removeId, setRemoveId] = useState("")

  let isDisable = [];

  const {
    spaDeviceSetting,
    spaDeviceDetails,
    spaTopside,
    spaController,
    delearName,
    spaName,
    spaService,
    prchPrice,
    prchDate,
    address,
    city,
    province,
    country,
    zipCode,
    spaLat,
    spaLon,
    deviceCatId,
    spaDeviceInstallationDate
  } = useSelector((state) => state.endUserReducer);

  useEffect(() => {
    setIsSpaEdit(false);
    dispatch(endUserActions.onResetSpaFields());
    getSpaDeviceInfo();
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [allDelears, setAllDelears] = useState([]);
  const getSpaDeviceInfo = () => {
    setLoading(true);
    dispatch(endUserActions.onGetSpaInfo(deviceId)).then((data) => {
      setLoading(false);
      if (data?.success) {
        if (data?.data?.spaSettings.length > 0) {
          dispatch(
            endUserActions.onChange(
              "spaDeviceSetting",
              data.data.spaSettings[0]
            )
          );
        } else {
          dispatch(endUserActions.onChange("spaDeviceSetting", ""));
        }
        if (data?.data?.spaDeviceDetails) {
          onGetAddressLatLng(
            data.data.spaDeviceDetails.installationLatitude,
            data.data.spaDeviceDetails.installationLongitude
          );
        }
        if (data?.data?.spaTopside.length > 0) {
          dispatch(endUserActions.onChange("spaTopside", data?.data?.spaTopside[data?.data?.spaTopside?.length - 1]));
          dispatch(
            endUserActions.onChange("spaTopsideInstallDate", moment(spaTopside?.installdate).format("MM/DD/YYYY"))
          );
          dispatch(
            endUserActions.onChange("spaTopsideWarrantyStatus", spaTopside?.WarrantyStatus)
          );
          dispatch(
            endUserActions.onChange("spaTopsideRegistrationStatus", spaTopside?.RegistrationStatus)
          );
          dispatch(
            endUserActions.onChange("spaTopsideRegistrationNote", spaTopside?.notes)
          );
        } else {
          dispatch(endUserActions.onChange("spaTopside", {}));
        }
        if (data?.data?.spaController.length > 0) {
          dispatch(endUserActions.onChange("spaController", data?.data?.spaController[data?.data?.spaController?.length - 1]));
          dispatch(
            endUserActions.onChange("spaControllerPrchDate", moment(spaController?.purchaseDate).format("MM/DD/YYYY"))
          );
          dispatch(
            endUserActions.onChange("spaControllerinstalldate", moment(spaController?.installDate).format("MM/DD/YYYY"))
          );
          dispatch(
            endUserActions.onChange("spaControllerRegistration", spaController?.registrationStatus)
          );
          dispatch(
            endUserActions.onChange("spaControllerNote", spaController?.notes)
          );
          dispatch(
            endUserActions.onChange("spaControllerWarrantyStatus", spaController?.warrantyStatus)
          );
        } else {
          dispatch(endUserActions.onChange("spaController", {}));
        }
      }
    });
  };

  const onGetAddressLatLng = (lat, lng) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat}${","}${lng}&key=AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ`
      )
        .then((res) => res.json())
        .then((jsonResponse) => {
          dispatch(
            endUserActions.onChange(
              "googleLocations",
              jsonResponse?.results || []
            )
          );
        })
        .catch((err) => {
          console.log("location error", err);
        });
  };

  const onValueChange = (search) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${search}&key=AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ`
    )
      .then((res) => res.json())
      .then((jsonResponse) => {
        dispatch(
          endUserActions.onChange(
            "googleLocations",
            jsonResponse?.results || []
          )
        );
      })
      .catch((err) => {
        console.log("location error", err);
      });
  };

  const changeHandler = (event, catId) => {
    setLoading(true);
    dispatch(endUserActions.onGetDelears(event.target.value, catId)).then(
      (res) => {
        setAllDelears(res?.data || []);
        setLoading(false);
      }
    );
  };

  const setValueOnAddress = (value) => {
    dispatch(endUserActions.onChange("address", value));
    if (spaLon !== null || spaLat !== null) {
      dispatch(endUserActions.onChange("spaLon", null));
      dispatch(endUserActions.onChange("spaLat", null));
    }
  };

  const openEndUserModal = (isbutton, id) => {
    setRemoveId(id)
    setOpen(true);
    if (isbutton === "Remove Device") {
      setIsNewProductPricing(false);
    } else {
      setIsNewProductPricing(true);
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);
  const getGeoLocation = useCallback(debounce(onValueChange, 700), []);

  return (
    <div className="spaCollectionBody deviceDetailMainContainer mainGridContainer">
      <Grid
        container
        className="spaCollectionWrapper deviceDetailOverviewInner"
        style={{display:"flex",gap:36}}
      >
        <Grid item md={2}>
          <div className="spaDevicePicture">
            <img
              className="devicedDtailImage"
              src={spaDeviceDetails?.productImage}
              alt=""
            />
          </div>
        </Grid>
        <Grid item md={10} className="deviceOverViewDetail">
          <div className="spaHedaingOverViewMain">Spa Overview</div>
          <span className="deviceDetailOverView">
            <div className="deviceDetailView">
              <span>Spa Name</span>
              {isSpaEdit ? (
                  <div className="autoCompleteInputContainer">
                    <InputField
                      value={spaName}
                      type="text"
                      onChange={({ target }) => {
                        dispatch(
                          endUserActions.onChange("spaName", target.value)
                        );
                      }}
                    />
                  </div>
                ) : (
                    <span>
                    {spaDeviceDetails?.deviceName}
                  </span>
                )}
            </div>
            <div className="deviceDetailView">
              <span>Spa Brand</span>
              <span>
                { spaDeviceSetting?.spaBrand ??  "--"}
              </span>
            </div>
            <div className="deviceDetailView">
              <span>Spa series</span>
              <span>
                {spaDeviceSetting?.spaSeries ?? "--"}
              </span>
            </div>
            <div className="deviceDetailView">
              <span>Model Year</span>
              <span>
                {spaDeviceSetting?.modelYear ? spaDeviceSetting?.modelYear : "--"}
              </span>
            </div>
            <div className="deviceDetailView">
              <span>Last Connected</span>
              <span>
                {spaDeviceDetails?.lastConnected ?? "--"}
              </span>
            </div>
            <div className="deviceDetailView">
              <span> Software/Firmware Version</span>
              <span>
                {spaDeviceDetails?.softwareVersion ?? "--"}
              </span>
            </div>
            <div className="deviceDetailView">
              <span>Product SKU</span>
              <span> {spaDeviceDetails?.productSKU ?? "--"}</span>
            </div>
            <div className="deviceDetailView">
              <span>Spa Serial Number</span>
              <span>
                {spaDeviceDetails?.serialNo ?? "--"}
              </span>
            </div>
            <div className="deviceDetailView">
              <span>Dealer</span>
              {isSpaEdit ? (
                <div className="autoCompleteInputContainer">
                  <AutoComplete
                    value={delearName}
                    onChange={(e) => {
                      debouncedChangeHandler(e, deviceCatId);
                      dispatch(
                        endUserActions.onChange("delearName", e.target.value)
                      );
                      dispatch(endUserActions.onChange("deviceDelearId", null));
                    }}
                    eventLocation={allDelears}
                    setEvent={(v) => {
                      dispatch(endUserActions.onChange("delearName", v.value));
                      dispatch(endUserActions.onChange("deviceDelearId", v.id));
                    }}
                  />
                </div>
              ) : (
                <span>{delearName ?? "--"}</span>
              )}
            </div>
            <div className="deviceDetailView">
              <span>Service</span>
              {isSpaEdit ? (
                 <div className="autoCompleteInputContainer">
                 <InputField
                   value={spaService}
                   type="text"
                   onChange={({ target }) => {
                     dispatch(
                       endUserActions.onChange("spaService", target.value)
                     );
                   }}
                 />
               </div>
              ) : (
                <span>{spaService  === "" ?  "--" : spaService}</span>
              )}
            </div>

            <div className="deviceDetailView">
              <span>Purchase date</span>
              {isSpaEdit ? (
                <div className="autoCompleteInputContainer">
                  <FormattedDateInput 
                  date={moment(prchDate).format("YYYY-MM-DD")}
                  setDate={(target) => {
                    if (!spaDeviceInstallationDate || moment(spaDeviceInstallationDate).diff(moment(target), 'days') >= 0) {
                      dispatch(endUserActions.onChange("prchDate", target));
                    } else {
                       return;
                    }
                  }}
                  />
                </div>
              ) : (
                <span>{prchDate ?? "--"}</span>
              )}
            </div>

            <div className="spaSettingDivContanier">
              <div className="innerContainerSpa">
                <span>Purchase price</span>
                {isSpaEdit ? (
                  <div className="autoCompleteInputContainer ">
                    <InputField
                      value={prchPrice}
                      type="number"
                      onChange={({ target }) => {
                        dispatch(
                          endUserActions.onChange("prchPrice", target.value)
                        );
                      }}
                    />
                  </div>
                ) : (
                  <span>{`${prchPrice ? "$"+prchPrice : "--"}`}</span>
                )}
              </div>
              {isSpaEdit && (
                <div className="deviceDetailbuttons btnContainerOwnership">
                  {/* <Button className="transferOnwershipBtn" 
                   onClick={() => {
                    openEndUserModal("Transfer Ownership");
                  }}>
                    Transfer Ownership
                  </Button> */}
                  <Button className="removeDeviceBtn" 
                  onClick={() => {
                    openEndUserModal("Remove Device", deviceId);
                  }}
                  >Remove Device</Button>
                </div>
              )}
            </div>
              <div className="spaOverViewInnerHeading">SPA Topside</div>
            <div className="deviceDetailView">
              <span>Model</span>
              <span>{spaTopside?.model ? spaTopside?.model : "--"}</span>
            </div>

            <div className="deviceDetailView">
              <span>Product SKU</span>
              <span>{spaTopside?.productSku ? spaTopside?.productSku : "--"}</span>
            </div>

            <div className="deviceDetailView">
              <span>Serial Number</span>
              <span>{spaTopside?.serialNo ? spaTopside?.serialNo : "--"}</span>
            </div>

            <div className="deviceDetailView">
              <span>MAC ID</span>
              <span>{spaTopside?.macId ? spaTopside?.macId : "--"}</span>
            </div>
            {isSpaEdit && spaTopside?.id && (
              <div className="deviceDetailbuttons">
                {/* <Button className="transferOnwershipBtn"
                 onClick={() => {
                  openEndUserModal("Transfer Ownership");
                }}
                >
                  Transfer Ownership
                </Button> */}
                <Button className="removeDeviceBtn"
                onClick={() => {
                  openEndUserModal("Remove Device", spaTopside?.id);
                }}
                >Remove Device</Button>
              </div>
            )}
            <div>
              <div className="spaHedaingControler">Spa Controller/Pack</div>
            </div>

            <div className="deviceDetailView">
              <span>Model</span>
              <span>{spaController?.model ? spaController?.model : "--"}</span>
            </div>

            <div className="deviceDetailView">
              <span>Product SKU</span>
              <span>{spaController?.productSku ? spaController?.productSku : "--"}</span>
            </div>

            <div className="deviceDetailView">
              <span>Serial Number</span>
              <span>{spaController?.serialNo ? spaController?.serialNo : "--"}</span>
            </div>
            {isSpaEdit && spaController?.id &&(
              <div className="deviceDetailbuttons">
                {/* <Button className="transferOnwershipBtn"
                 onClick={() => {
                  openEndUserModal("Transfer Ownership");
                }}>
                  Transfer Ownership
                </Button> */}
                <Button className="removeDeviceBtn"
                onClick={() => {
                  openEndUserModal("Remove Device", spaController?.id);
                }}
                >Remove Device</Button>
              </div>
            )}
            <div>
              <div className="spaHedaingControler">Install Location</div>
            </div>

            <div className="deviceDetailView">
              <span>Address</span>
              {isSpaEdit ? (
                <div className="addressAutoComplete autoCompleteInputContainer ">
                  <AddressAutoComplete
                    address={address}
                    autoComId={"popupEnvLocInput"}
                    onChange={(e) => {
                      setValueOnAddress(e.target.value);
                      getGeoLocation(e.target.value);
                    }}
                    setPlace={(p) => {
                      isDisable = [];
                      dispatch(endUserActions.onChange("city", ""));
                      dispatch(endUserActions.onChange("province", ""));
                      dispatch(endUserActions.onChange("country", ""));
                      dispatch(endUserActions.onChange("zipCode", ""));
                      const val =
                        document.getElementById("popupEnvLocInput")?.value;
                      p?.address_components?.forEach((x) => {
                        if (x?.types?.includes("route")) {
                          setValueOnAddress(x?.long_name);
                        }
                        if (x?.types?.includes("locality")) {
                          dispatch(
                            endUserActions.onChange("city", x?.long_name)
                          );
                          isDisable.push("city");
                        }
                        if (x?.types?.includes("administrative_area_level_1")) {
                          dispatch(
                            endUserActions.onChange("province", x?.long_name)
                          );
                          isDisable.push("state");
                        }
                        if (x?.types?.includes("country")) {
                          dispatch(
                            endUserActions.onChange("country", x?.long_name)
                          );
                          isDisable.push("country");
                        }
                        if (x?.types?.includes("postal_code")) {
                          dispatch(
                            endUserActions.onChange("zipCode", x?.long_name)
                          );
                          isDisable.push("postalCode");
                        }
                      });
                    }}
                  />
                  
                </div>
              ) : (
                <span>{address === "" ? "--" : address}</span>
              )}
            </div>

            <div className="deviceDetailView">
              <span>City</span>
              {isSpaEdit ? (
                < div className="autoCompleteInputContainer">
                  <InputField
                    placeholder="Enter City"
                    value={city}
                    onChange={(v) =>
                      dispatch(endUserActions.onChange("city", v.target.value))
                    }
                  />
                </div>
              ) : (
                <span>{city === "" ? "--" : city}</span>
              )}
            </div>

            <div className="deviceDetailView">
              <span>State/Province</span>
              {isSpaEdit ? (
                 <div className="autoCompleteInputContainer">
                  <InputField
                    placeholder="Enter State"
                    value={province}
                    onChange={(v) =>
                      dispatch(
                        endUserActions.onChange("province", v.target.value)
                      )
                    }
                  />
                </div>
              ) : (
                <span>{province === "" ? "--" : province}</span>
              )}
            </div>

            <div className="deviceDetailView">
              <span>Country</span>
              {isSpaEdit ? (
                 <div className="autoCompleteInputContainer">
                  <InputField
                    placeholder="Enter Country"
                    value={country}
                    onChange={(v) =>
                      dispatch(
                        endUserActions.onChange("country", v.target.value)
                      )
                    }
                  />
                </div>
              ) : (
                <span>{country === "" ? "--" : country}</span>
              )}
            </div>

            <div className="deviceDetailView">
              <span>Zip code/Postal code</span>
              {isSpaEdit ? (
                  <div className="autoCompleteInputContainer">
                  <InputField
                    value={zipCode}
                    onChange={({ target }) => {
                      dispatch(
                        endUserActions.onChange("zipCode", target.value)
                      );
                    }}
                    disabled={spaLat !== null && spaLon !== null ? true : false}
                  />
                </div>
              ) : (
                <span>{zipCode === "" ? "--" : zipCode}</span>
              )}
            </div>
          </span>
          <EndUserCustomersModal
            open={open}
            enduserModal={() => {
              setOpen(false);
              setIsOwnership(true);
            }}
            isNewProductPricing={isNewProductPricing}
            setIsOwnership={setIsOwnership}
            isOwnership={isOwnership}
            hideProductDetail={hideSpaProducts}
            devId={removeId}
          />
        </Grid>
      </Grid>
    </div>
  );
};
