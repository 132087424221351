import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  AddIconWithBGColor,
  SoftwareReleaseSortIcon,
  SortIcon,
} from "../../../LeftNav/SvgIcons";
import { ItemImage } from "../../../../../components/common";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { SortedByFilterCheckbox } from "../../../../../components/common/checkbox/SortedByFilterCheckbox";
import { useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "6px 0px 0px 6px",
  },
  gridMidHeading: {
    textAlign: "left",
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeaderInnerCenter: {
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
    height: "43px",
  },
  gridHeaderRow: {
    "& .MuiTableCell-root": {
      padding: "0px 16px",
      height: "37px",
      borderBottom: "unset",
    },
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridBodyInnerMid: {
    fontSize: "12px",
    color: "#2D3339",
    textAlign: "left",
  },
  gridStatusColumn: {
    textAlign: "center",
  },
  gridProductColumn: {
    // fontWeight: "bold",
  },
  gridVersionColumn: {
    fontWeight: "bold",
    color: "#3699FF",
  },
  gridStyledColumn: {
    fontSize: "12px",
    color: "#2D3339",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
      boxShadow: "none",
    },
  },
  launchedButtons: {
    color: "#00ABA3",
    backgroundColor: "rgba(0, 171, 163, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(0, 171, 163, 0.25)",
      boxShadow: "none",
    },
  },
  newProductLaunch: {
    color: "#8254E3",
    background: "rgba(130, 84, 227, 0.25)",
    boxShadow: "none",
    "&:hover": {
      background: "rgba(130, 84, 227, 0.25)",
      boxShadow: "none",
    },
  },
  editButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  buttonCell: {
    textAlign: "right",
  },
  addedBtn: {
    fontSize: "12px",
    width: "80px",
    height: "30px",
    fontWeight: "500",
    "& .MuiButton-root": {},
    backgroundColor: "#18BC6D",
    "&:hover": {
      backgroundColor: "#18BC6D",
    },
  },
  productGridButtonCell: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  gridBodyRow: {},
}));

export const ConversationBasicPopupGrid = ({
  filteredChannels,
  addDevice,
  order,
  setOrderBy,
  onCheckboxaddChannel,
  checkboxSelectedChannel,
  setSelectAllCheckBox,
  selectAllCheckBox,
  addMultiple,
  onselectAllDevices,
}) => {
  const { selectedConversationChannels } = useSelector(
    state => state.conversationReducer
  );
  const classes = useStyles();
  const history = useHistory();

  return (
    <div style={{ maxHeight: "260px", overflow: "auto" }}>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              Channel Name
              <TableSortLabel
                active={order?.orderByKey === "Name"}
                direction={order?.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  setOrderBy({
                    orderByKey: "Name",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SoftwareReleaseSortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridMidHeading}>
              Channel Type
              <TableSortLabel
                active={order?.orderByKey === "Channel"}
                direction={order?.orderVal === 0 ? "asc" : "desc"}
                onClick={() =>
                  setOrderBy({
                    orderByKey: "Channel",
                    orderVal: order?.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SoftwareReleaseSortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.buttonCell}>
              <div className={classes.productGridButtonCell}>
                <SortedByFilterCheckbox
                  handleCheckboxChange={() => {
                    setSelectAllCheckBox(!selectAllCheckBox);
                    onselectAllDevices();
                    // filteredChannels.forEach((x)=> onCheckboxaddChannel(x))
                  }}
                  checked={
                    checkboxSelectedChannel &&
                    filteredChannels.length === checkboxSelectedChannel.length
                  }
                />
                <div
                  style={{ cursor: "pointer", display: "flex" }}
                  onClick={() => addMultiple()}>
                  <AddIconWithBGColor />
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredChannels && filteredChannels?.length > 0 ? (
            filteredChannels?.map((item, index) => {
              return (
                <TableRow key={index} className={classes.gridBodyRow}>
                  <TableCell className={classes.gridStyledColumn}>
                    {item?.name ?? "--"}
                  </TableCell>
                  <TableCell className={[classes.gridBodyInnerMid]}>
                    {item?.channel ?? "--"}
                  </TableCell>
                  <TableCell className={classes.buttonCell}>
                    {selectedConversationChannels?.find(
                      x => Number(x.id) === Number(item.id)
                    ) ? (
                      <Button
                        className={classes.addedBtn}
                        color='primary'
                        variant='contained'>
                        <CheckIcon viewBox='0 0 30 24' /> Added
                      </Button>
                    ) : (
                      <div className={classes.productGridButtonCell}>
                        <SortedByFilterCheckbox
                          checked={checkboxSelectedChannel?.some(
                            selectedItem =>
                              selectedItem.id === item.id &&
                              selectedItem.name === item.name
                          )}
                          handleCheckboxChange={() =>
                            onCheckboxaddChannel(item)
                          }
                        />
                        <div
                          style={{ cursor: "pointer", display: "flex" }}
                          onClick={() => addDevice(item)}>
                          <AddIconWithBGColor />
                        </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! No Conversation Channels have been created.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
