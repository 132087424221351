import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ArrowBackIos, Add } from "@material-ui/icons";
import queryString from "query-string";
import Dashboard from "../Dashbard";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { useDispatch, useSelector } from "react-redux";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import CustomerProfile from "./CustomerProfile";
import CustomerInfo from "./CustomerInfo";
import CustomerActivity from "./CustomerActivity";
import CustomerNotes from "./CustomerNotes";
import { NewBusinessContact } from "./NewBusinessContact";
import BusinessCustomerConversation from "./BusinessCustomerConversation";
import ConsumerOrders from "./ConsumerOrders/ConsumerOrders";
import NewCompany from "./NewCompanyContacts/NewCompany";
import ProductsOwner from "../EndUser/ProductsOwner";
import { AddNewProduct } from "../EndUser/RegisterNewProduct/AddNewProduct";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import EndUserNotesGrid from "../EndUser/EndUserNotesGrid";
import EndUserActivityTab from "../EndUser/EndUserActivityTab";
import RecentConversations from "./RecentConversations";
import { Spinner } from "../../../../components/common";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "12px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

const ConsumerOverview = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [companyId, setCompanyId] = useState(null);
  const [activeTabId, setActiveTabId] = useState(null);
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { businessCompanyInfo } = useSelector(state => state.ContactsReducer);

  const redirectUrl = () => {
    // history.push("/all-business-contacts");
    history.goBack();
  };

  useEffect(() => {
    const selectedCompany = queryString.parse(location.search);
    if (selectedCompany?.id) {
      setCompanyId(selectedCompany.id);
      if (selectedCompany?.acitveTabId)
        setActiveTabId(selectedCompany.acitveTabId);
      else setActiveTabId("2");

      setLoading(true);
      dispatch(contactsActions.onGetCompanyById(selectedCompany?.id)).then(
        data => {
          setLoading(false);
        }
      );
    } else {
      setCompanyId(null);
      setActiveTabId("2");
    }
  }, [dispatch, location]);

  const registerNew = () => {
    setIsNewProduct(true);
    dispatch(endUserActions.onResetRegister("deviceOverviewInfo", ""));
  };

  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Spinner /> : null}
        <div className='endUserHeanding'>
          <div className='consumerHeading'>
            <h1>
              {businessCompanyInfo?.comapnayInfo
                ? businessCompanyInfo.comapnayInfo.name
                    ?.charAt(0)
                    ?.toUpperCase() +
                  businessCompanyInfo.comapnayInfo.name?.slice(1)
                : "Add Company"}
            </h1>
            <Button color='primary' onClick={redirectUrl} variant='contained'>
              <ArrowBackIos /> Back to Business Customers
            </Button>
          </div>
          {activeTabId === "3" && (
            <div>
              <Button
                color='primary'
                variant='contained'
                onClick={() => registerNew()}
                startIcon={<Add />}>
                Register Product
              </Button>
            </div>
          )}
        </div>
        <div className='enduserTabs'>
          {companyId && activeTabId ? (
            <TopTabNavigator
              getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
              value={activeTabId}
              defaultTabId={activeTabId}
              tabs={[
                {
                  id: "1",
                  title: "Overview",
                  component: <ConsumerProfile isActive={activeTabId === "1"} />,
                },
                {
                  id: "2",
                  title: "Company Information",
                  component: <NewCompany isActive={activeTabId === "2"} />,
                },
                {
                  id: "3",
                  title: "Products Owned",
                  component: isNewProduct ? (
                    <AddNewProduct
                      userId={companyId}
                      setIsNewProduct={setIsNewProduct}
                      isBusinessCustomers={true}
                    />
                  ) : (
                    <ProductsOwner
                      isActive={activeTabId === "3"}
                      isBusinessCustomers={true}
                    />
                  ),
                },
                // {
                //   id: "4",
                //   title: "Orders",
                //   component: <ConsumerOrders />,
                // },
                // {
                //   id: "5",
                //   title: "Conversations",
                //   component: (
                //     <BusinessCustomerConversation
                //       isActive={activeTabId === "5"}
                //     />
                //   ),
                // },
                // {
                //   id: "6",
                //   title: "Activity",
                //   component: (
                //     <EndUserActivityTab
                //       isActive={activeTabId === "6"}
                //       isBusUser={true}
                //     />
                //   ),
                // },
                // {
                //   id: "7",
                //   title: "Notes",
                //   component: (
                //     <EndUserNotesGrid
                //       isActive={activeTabId === "7"}
                //       isBusUser={true}
                //     />
                //   ),
                // },
              ]}
            />
          ) : (
            activeTabId && (
              <TopTabNavigator
                getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
                value={activeTabId}
                tabs={[
                  {
                    id: "2",
                    title: "Company Information",
                    component: (
                      <NewBusinessContact isActive={activeTabId === "2"} />
                    ),
                  },
                ]}
              />
            )
          )}
        </div>
      </div>
    </Dashboard>
  );
};

export default ConsumerOverview;

const ConsumerProfile = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} className={classes.gridWrapper}>
        <CustomerProfile />
        <CustomerInfo />
      </Grid>

      <Grid item xs={8} className={classes.gridWrapper}>
        <RecentConversations />
        <CustomerActivity />
        {/* <CustomerNotes /> */}
      </Grid>
    </Grid>
  );
};
