import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import { DropzoneArea } from "material-ui-dropzone";
import { Button } from "@material-ui/core";
import {
  txt,
  pngFile,
  doc,
  genericFile,
  folder,
  pdf,
} from "../../../../resources/images/index";
import RadioButtons from "../../../../components/common/RadioButton/RadioButton";
import { ArrowBack } from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  tabsSelected: {
    //   background: 'red',
    //   border: 'none'
  },
  libraryList: {
    padding: "0",
    listStyle: "none",
    overflowY: "auto",
    maxHeight: "313px",
  },
  libraryListItems: {
    marginRight: "10px",
    fontSize: "30px",
  },
  libraryListItemsImage: {
    fontSize: "42px",
    display: "flex",
    marginRight: "10px",
    alignItems: "center",
  },
  listContainer: {
    borderBottom: "1px solid #f1f1f1",
    paddingBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  tabsNav: {
    boxShadow: "none",
  },
  selectBtn: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "25px",
  },
  dragAndDropFileWrap: {
    padding: "0px 20px 20px",
  },
}));

export default function AddAssetSelectImg({
  addFeatureImg,
  featureData,
  selectedFeatureImg,
  selectedFolder,
  setSelectedFolder,
  onBackButtonPress,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [uploadFile, setUploadFile] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  const uploadFileHandle = file => {
    setUploadFile(file);
  };

  const checkFileType = fileType => {
    if (
      fileType.type === "png" ||
      fileType.type === "jpg" ||
      fileType.type === "jpeg"
    ) {
      return fileType.url;
    } else if (
      fileType.type === "rtf" ||
      fileType.type === "text" ||
      fileType.type === "txt"
    ) {
      return txt;
    } else if (fileType.type === "doc") {
      return doc;
    } else if (fileType.type === "folder") {
      return folder;
    } else if (fileType.type === "pdf") {
      return pdf;
    } else {
      return genericFile;
    }
  };

  const checkSelectedFile = value => {
    if (selectedFile.id === value.id) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={classes.root}>
      <>
        <AppBar position='static' color='default' className={classes.tabsNav}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            aria-label='full width tabs example'>
            <Tab
              label='Media Library'
              {...a11yProps(0)}
              className={classes.tabsSelected}
            />
            <Tab label='Upload' {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}>
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            className='imageLoaderWrapper'>
            <ul className={classes.libraryList}>
              {selectedFolder && (
                <li key={selectedFolder.id} className={classes.listContainer}>
                  <Button
                    color='primary'
                    variant='text'
                    startIcon={<ArrowBack />}
                    onClick={() => onBackButtonPress(selectedFolder)}>
                    Back
                  </Button>
                </li>
              )}
              {featureData?.length > 0 &&
                featureData.map((item, index) => {
                  const isFolder = item.type === "folder";
                  return (
                    <li key={index} className={classes.listContainer}>
                      <span className={classes.libraryListItems}>
                        <RadioButtons
                          selectedValue={checkSelectedFile(item)}
                          handleChange={() => setSelectedFile(item)}
                          disabled={isFolder}
                        />
                      </span>
                      <span className={classes.libraryListItemsImage}>
                        <img
                          style={{ maxHeight: "25px", maxWidth: "25px" }}
                          src={checkFileType(item)}
                          alt=''
                        />
                      </span>
                      <span
                        style={{
                          cursor: isFolder ? "pointer" : "default",
                        }}
                        onClick={() =>
                          isFolder &&
                          setSelectedFolder &&
                          setSelectedFolder(item)
                        }>
                        {item.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
            <span className={classes.selectBtn}>
              <Button
                variant='contained'
                color='primary'
                disabled={selectedFile === "" || featureData?.length <= 0}
                onClick={() => selectedFeatureImg(selectedFile)}>
                Select
              </Button>
            </span>
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <DragAndDropFile
              uploadedFile={uploadFile}
              uploadFileHandler={v => uploadFileHandle(v)}
              onSelectClick={() => addFeatureImg(uploadFile)}
            />
          </TabPanel>
        </SwipeableViews>
      </>
    </div>
  );
}

const DragAndDropFile = ({
  uploadedFile,
  uploadFileHandler,
  onSelectClick,
}) => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.libraryList}>
        <ul className={classes.libraryList}>
          <li className={classes.listContainer}>
            <span className={classes.libraryListItemsImage}>
              <FolderOpenOutlinedIcon />
            </span>
            <span>Upload from Device</span>
          </li>
          <li>
            <DropzoneArea
              onChange={uploadFileHandler}
              filesLimit={1}
              maxFileSize={110000000}
            />
          </li>
        </ul>
      </span>
      <span className={classes.selectBtn}>
        <Button
          variant='contained'
          color='primary'
          disabled={!uploadedFile || uploadedFile?.length <= 0}
          onClick={onSelectClick}>
          Select
        </Button>
      </span>
    </>
  );
};
