import React, { useState } from "react";
import { Forum } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import Loader from "../../../../../components/common/Loader";
import { facebook } from "../../../../../resources/images/index";

function Conversationchannel(props) {
  const [isLoading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const dispatch = useDispatch();

  const enableConversation = () => {
    setLoading(true);
    dispatch(
      conversationAction.onEnableConversation({
        productId: 2,
        isActive: isEnabled ? 1 : 0,
      })
    ).then(data => {
      toast.success(data?.responseMsg);
      dispatch(conversationAction.onChange("activeTabId", `${3}`));
      setLoading(false);
    });
  };
  return (
    <div className="conversationChannelWrapper">
      {isLoading ? <Loader /> : null}
      <div className="conversationChannelInnerWrapper">
        <h2>Conversation Channels</h2>
        <span>
          With Conversations you can receive messages from multiple channels
          within OFish. By Default we’ve enabled OFish Conversations, but you
          can turn it off at any time. You can also connect third party
          services.
        </span>
        <div className="channelWrapper">
          <div className="channelInnerContent">
            <span class="iconWrapper">
              <Forum style={{ fontSize: "30px" }} />
            </span>
            <div>
              <div className="titleWrapper">
                <h3>OFish Conversations</h3>
                {/* <div>
                  <span className="toggleWrapper">Active</span>
                  <Switch />
                </div> */}
                <div>
                  <span className="toggleWrapper">
                    {isEnabled === false ? "Disabled" : "Enabled"}
                  </span>
                  <Switch
                    onChange={() => {
                      setIsEnabled(!isEnabled);
                    }}
                    checked={isEnabled}
                  />
                </div>
              </div>
              <div>
                OFish Conversations enables messaging via in-app, chatbot, and
                e-mail conversations between your Brand and other customers
                within OFish. This also allows customer-to-customer messaging
                where allowed. Learn More
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="blurChannelWrapper">
          <div className="blurChannelInnerContent">
            <span class="iconWrapper">
              {/* <Forum style={{ fontSize: "30px" }} /> */}
              <img
                style={{ width: "40px", height: "40px" }}
                src={facebook}
                alt=""
              />
            </span>
            <div>
              <div className="fbTitleWrapper">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h3>Facebook</h3>
                  <h5 style={{ paddingLeft: "10px", color: "#3699FF" }}>
                    Coming Soon
                  </h5>
                </div>
              </div>
              <div>
                Sync and reply to your Brand’s Facebook messages within OFish
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <Button
        color="#F5F8FA"
        variant="contained"
        autoFocus
        type="submit"
        onClick={() => {
          dispatch(conversationAction.onChange("activeTabId", `${1}`));
        }}
        style={{
          bottom: 0,
          position: "absolute",
          right: "100px",
          margin: "20px",
        }}>
        <span style={{ color: "#7E8299", fontSize: "12px" }}>Go Back</span>
      </Button>
      <Button
        color="primary"
        variant="contained"
        autoFocus
        type="submit"
        onClick={() => {
          enableConversation();
        }}
        style={{ bottom: 0, position: "absolute", right: "0", margin: "20px" }}>
        Continue
      </Button>
    </div>
  );
}

export default Conversationchannel;
