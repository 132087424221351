import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ArrowForwardIos } from "@material-ui/icons";
import {
  LogHeader,
  MainHeading,
  InnerHeader,
} from "../../../components/common/Header/index";
import { mediaPic } from "../../../resources/images/index";
import { pubMediaLibraryActions } from "../../../store/pubMediaLibrary/pubMediaLibraryActions";
import { accountActions } from "../../../store/accounts/accountActions";
import {
  GridSelected,
  GridUnSelected,
  ListSelected,
  ListUnSelected,
} from "../../../ofishadmin/views/LeftNav/SvgIcons";
import MediaLibrarFilter from "./MediaLibraryFilter";
import SearchHighlight from "../../../components/common/SearchHighlight";
import Cards from "../../../components/common/Cards";
import Loader from "../../../components/common/Loader";
import Footer from "../../../components/common/Footer/Footer";
import routesObj from "../../../routes/RoutesObj";

function LogMediaLibrary() {
  document.title = "OFish - Media Library";

  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [showViewAll, setShowViewAll] = useState(true);
  const [isFilter, setIsFilter] = useState(true);
  const [releaseSrch, setReleaseSrch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [catName, setCatName] = useState("");
  const [childName, setChildName] = useState("");
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [isList, setIsList] = useState(false);
  const [isGrid, setIsGrid] = useState(false);

  const dispatch = useDispatch();
  const { slug } = useParams();

  const { pubMediaCategory, mediaAssetsWithCategory, selectedPMCategory } =
    useSelector(state => state.pubMediaLibraryReducer);

  useEffect(() => {
    if (slug && pubMediaCategory?.length > 0) {
      const catData = pubMediaCategory.filter(x => x.urlSlug === slug);
      if (catData?.length > 0) {
        const fiterObj = {
          id: catData[0].id,
          name: catData[0].name,
          urlSlug: catData[0].urlSlug,
        };
        dispatch(pubMediaLibraryActions.onAddValueInFilter(fiterObj));

        setLoading(true);
        const filterPayload = {
          releaseDate: 0,
          categoryIds: [catData[0].id],
          take: 100,
          skip: 0,
          title: "",
          dataType: ["CategoryOnly"],
        };
        dispatch(pubMediaLibraryActions.onGetPubAllAssets(filterPayload)).then(
          res => {
            setLoading(false);
          }
        );
      }
    }
  }, [slug, pubMediaCategory, dispatch]);

  const onSearch = () => {
    if (releaseSrch.trim() !== "") {
      setIsSearch(true);
      setLoading(true);
      setShowViewAll(false);
      const filterPayload = {
        collectionDate: 0,
        categoryIds: [],
        take: 100,
        skip: 0,
        title: releaseSrch,
        dataType: ["LatestOnly"],
      };
      dispatch(pubMediaLibraryActions.onGetPubAllAssets(filterPayload)).then(
        res => {
          setLoading(false);
        }
      );
    } else {
      loadData();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(accountActions.onAddUserSession(window.location.href));
    if (selectedPMCategory?.length > 0) {
      let catIds = [];
      selectedPMCategory.forEach(element => {
        catIds.push(element.id);
      });
      setLoading(true);
      const filterPayload = {
        releaseDate: 0,
        categoryIds: catIds,
        take: 100,
        skip: 0,
        title: "",
        dataType: ["CategoryOnly"],
      };
      dispatch(pubMediaLibraryActions.onGetPubAllAssets(filterPayload)).then(
        res => {
          setLoading(false);
        }
      );
    } else {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    setShowViewAll(true);
    setIsSearch(false);
    setReleaseSrch("");
    const assetData = {
      collectionDate: 0,
      categoryIds: [],
      take: 3,
      skip: 0,
      title: "",
      dataType: ["LatestOnly", "CategoryOnly"],
    };
    const catIds = [];
    setLoading(true);
    dispatch(pubMediaLibraryActions.onGetPubAllAssets(assetData)).then(data => {
      if (data && data.success) {
        dispatch(pubMediaLibraryActions.onGetPubMedCategories()).then(mCat => {
          if (mCat && mCat.success) {
            dispatch(pubMediaLibraryActions.onGetPubMedDateFilter(catIds)).then(
              medDate => {
                setLoading(false);
                if (medDate && !medDate.success) {
                  toast.error(medDate.message);
                }
              }
            );
          } else {
            setLoading(false);
            if (mCat) {
              toast.error(mCat.message);
            }
          }
        });
      } else {
        if (data) {
          toast.error(data.message);
        }
        setLoading(false);
      }
    });
  };

  const viewAllData = (item, isCategory) => {
    if (item.groupId !== 0 && isCategory) {
      const fiterObj = {
        id: item.groupId,
        name: item.groupBy,
        urlSlug: item.urlSlug,
      };
      dispatch(pubMediaLibraryActions.onAddValueInFilter(fiterObj));
    }
    setIsViewClicked(true);
    setLoading(true);
    let dataTypeval = "";
    if (item.groupId === 0) {
      dataTypeval = "LatestOnly";
    } else {
      dataTypeval = "CategoryOnly";
    }
    const filterPayload = {
      collectionDate: 0,
      categoryIds: item.groupId !== 0 ? [item.groupId] : [],
      take: 100,
      skip: 0,
      title: "",
      dataType: [dataTypeval],
    };
    dispatch(pubMediaLibraryActions.onGetPubAllAssets(filterPayload)).then(
      res => {
        setLoading(false);
        setShowViewAll(false);
      }
    );
  };

  const viewChildData = (rel, item) => {
    setChildName(rel.value);
    setCatName(item);
    setLoading(true);
    let dataTypeval = "CategoryOnly";
    const filterPayload = {
      releaseDate: 0,
      categoryIds: rel.id !== "0" ? [parseInt(rel.id)] : [],
      take: 100,
      skip: 0,
      title: "",
      dataType: [dataTypeval],
    };
    dispatch(pubMediaLibraryActions.onGetPubAllAssets(filterPayload)).then(
      res => {
        setLoading(false);
        setShowViewAll(false);
      }
    );
  };

  const ShowListView = () => {
    setIsList(true);
    setIsGrid(false);
  };

  const ShowGridView = () => {
    setIsList(false);
    setIsGrid(true);
  };

  const onRedirectCollection = item => {
    if (item.urlSlug) {
      history.push(routesObj.MediaDetail.path + "/" + item.urlSlug);
    } else {
      toast.error("No record found");
    }
  };

  return (
    <div className='releasesWrapper'>
      {isLoading ? <Loader /> : null}
      <LogHeader />
      <MainHeading headerSubHeading='Media Library'>
        <div className='publicBodyTwoColumn'>
          <div className='leftFiletBar'>
            {!showViewAll && isFilter && (
              <div className='viewAllBtn'>
                <span className='toggleGrids'>
                  {/* View As */}

                  <span onClick={ShowGridView}>
                    {" "}
                    {isGrid ? <GridSelected /> : <GridUnSelected />}{" "}
                  </span>
                  <span onClick={ShowListView}>
                    {" "}
                    {isList ? <ListSelected /> : <ListUnSelected />}{" "}
                  </span>
                </span>
              </div>
            )}
            <MediaLibrarFilter
              startLoading={() => setLoading(true)}
              stopLoading={() => setLoading(false)}
              showButton={() => setIsFilter(true)}
              hideButton={() => setIsFilter(false)}
              loadData={() => loadData()}
              searchPage={() => onSearch()}
              onChange={e => setReleaseSrch(e.target.value)}
              value={releaseSrch}
            />
          </div>
          <div className='rightContentArea'>
            <InnerHeader
              picture={mediaPic}
              text='Find media assets including overviews, images and more.'
              heading='Media Library'
              searchPage={() => onSearch()}
              onChange={e => setReleaseSrch(e.target.value)}
              value={releaseSrch}
              textPlace='Search media library'
            />
            {mediaAssetsWithCategory?.length > 0 &&
              mediaAssetsWithCategory.map((catWAsset, index) => (
                <div
                  key={index}
                  className='boxMainWrapper eventsWrapper releaseInnerWrapper'>
                  <div className='releaseMainGridWrapper'>
                    {!showViewAll && (
                      <>
                        <div
                          className={`viewAllBtn ${
                            isViewClicked ? "innerToggle" : ""
                          }`}>
                          {isViewClicked && (
                            <span className='toggleGrids'>
                              View As
                              <span onClick={ShowGridView}>
                                {" "}
                                {isGrid ? (
                                  <GridSelected />
                                ) : (
                                  <GridUnSelected />
                                )}{" "}
                              </span>
                              <span onClick={ShowListView}>
                                {" "}
                                {isList ? (
                                  <ListSelected />
                                ) : (
                                  <ListUnSelected />
                                )}{" "}
                              </span>
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {isSearch ? (
                      <h2>{`${
                        catWAsset.data?.length
                      }${" results found for `"}${releaseSrch}${"`"}`}</h2>
                    ) : (
                      <h2
                        className={`${
                          childName ? "innerCategoryHeading" : ""
                        }`}>
                        <span>
                          {!catWAsset.isParent && catWAsset.groupId !== 0 && (
                            <h2
                              onClick={() => {
                                viewAllData(catName, false);
                              }}>
                              {catName.groupBy}
                              {childName !== "" && (
                                <ArrowForwardIos
                                  style={{ color: "#49b8ef", margin: "20px" }}
                                />
                              )}
                            </h2>
                          )}
                          {catWAsset.groupBy}
                        </span>
                        {showViewAll &&
                          isFilter &&
                          selectedPMCategory?.length === 0 && (
                            <div className='viewAllBtn'>
                              {selectedPMCategory &&
                                selectedPMCategory.length === 0 && (
                                  <Button
                                    color='primary'
                                    size='small'
                                    onClick={() =>
                                      viewAllData(catWAsset, true)
                                    }>
                                    View All
                                  </Button>
                                )}
                            </div>
                          )}
                      </h2>
                    )}
                  </div>
                  <Grid container spacing={3} style={{ marginTop: "15px" }}>
                    {selectedPMCategory?.length > 0 &&
                      catWAsset.childCategories &&
                      catWAsset.childCategories?.length > 0 &&
                      catWAsset.childCategories.map((rel, i) => (
                        <Grid key={i} item md={4}>
                          <span
                            className='subCategoryHeading'
                            onClick={() => {
                              viewChildData(rel, catWAsset);
                            }}>
                            <ArrowForwardIos /> {rel.value}
                          </span>
                        </Grid>
                      ))}
                  </Grid>
                  <div
                    className={`cards smallCard ${
                      isList ? "gridListView" : ""
                    }`}>
                    <Grid container spacing={3} style={{ marginTop: "15px" }}>
                      {catWAsset &&
                        catWAsset.data &&
                        catWAsset.data.length > 0 &&
                        catWAsset.data.map((pMAsset, i) => (
                          <>
                            <Grid key={i} item md={4}>
                              <Cards
                                picture={
                                  pMAsset.imageUrl
                                    ? pMAsset.imageUrl
                                    : "/static/images/cards/contemplative-reptile.jpg"
                                }
                                subheading={
                                  <SearchHighlight
                                    text={pMAsset.title}
                                    highlight={releaseSrch}
                                  />
                                }
                                cardtext={`${pMAsset.assetsCount}${" assets"}`}
                                onClick={() => onRedirectCollection(pMAsset)}
                              />
                            </Grid>
                          </>
                        ))}
                    </Grid>
                  </div>
                </div>
              ))}
            {mediaAssetsWithCategory?.length === 0 && (
              <div className='releaseInnerWrapper noCategory'>
                <div className='releaseMainGridWrapper'>
                  <span>
                    {" "}
                    <h2
                      onClick={() => {
                        viewAllData(catName, false);
                      }}>
                      {catName.groupBy}
                    </h2>
                    {childName !== "" && (
                      <ArrowForwardIos style={{ color: "#49b8ef" }} />
                    )}
                    <h2>{childName}</h2>
                  </span>
                </div>
                <span>No Assets Found</span>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </MainHeading>
    </div>
  );
}

export default LogMediaLibrary;
