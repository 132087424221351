import React, { useState, useEffect, useCallback } from "react";

import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashbard from "../Dashbard";
import EventsCategoryGrid from "./EventsCategoryGrid";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/common/Loader";
import { useDispatch } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { Pagination } from "../../../../components/common";

function EventCategories(props) {
  const history = useHistory();
  const redirectUrl = () => {
    history.push("/new-category");
  };
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [eventsCategory, setEventsCategory] = useState([]);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDelete, setIsDelete] = useState(false);

  const loadAllEventsCategories = useCallback(() => {
    setLoading(true);
    const modal = {
      categoryName: null,
      totalEvents: null,
      createdDate: null,
      color: null,
      isPublic: null,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    dispatch(launchesActions.onGetEventCategories(modal)).then(data => {
      if (data?.success) {
        if (data.data?.data) {
          const eventList = data.data.data.map((x, index) => {
            return {
              ...x,
              id: x.id,
              categoryName: x.categoryName,
              totalEvents: x.totalEvents,
              createdDate: x.createdDate,
              color: x.color,
              isPublic: x.isPublic,
              checked: false,
            };
          });
          setPaginationResponse(data?.data?.pagination);
          setEventsData(eventList);
          var count = eventList.find(x => x.checked === true);
          setIsDelete(count ? true : false);
        }
      }
      setLoading(false);
    });
  }, [dispatch, page, rowsPerPage, order.orderByKey, order.orderVal]);

  useEffect(() => {
    loadAllEventsCategories();
    window.scrollTo(0, 0);
  }, [page, rowsPerPage, order.orderByKey, order.orderVal]);

  const checkEventGroupsSelected = item => {
    eventsData.map(x => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    var count = eventsData.find(x => x.checked === true);
    setIsDelete(count ? true : false);
    setEventsData([...eventsData], eventsData);
  };
  return (
    <Dashbard>
      <div className="body-content">
        {isLoading ? <Loader /> : null}
        <div className="contentHeading  launchBtn">
          <h1>Events Categories</h1>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={redirectUrl}>
            New Category
          </Button>
        </div>

        <div className="gridBody contentBody">
          <EventsCategoryGrid
            allEventsData={eventsData}
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadAllEventsCategories()}
            selectedRecord={item => {
              checkEventGroupsSelected(item);
            }}
            isDelete={isDelete}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </div>
    </Dashbard>
  );
}

export default EventCategories;
