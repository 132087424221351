import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableSortLabel,
} from "@material-ui/core";
import { FormattedDateTimePicker } from "../../../../components/common/DataTime/FormattedDateTimePicker";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  AccessAlarm,
  Add,
  Cancel,
  CheckCircle,
  Clear,
  PortableWifiOff,
} from "@material-ui/icons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Copy, EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { CancleIcon, TickIcon, ArrowsIcon } from "../../LeftNav/SvgIcons";
import { Dropdown } from "../../../../components/common";
import { CustomerGroupsPopup } from "./CustomerGroupsPopup";
import { useDispatch } from "react-redux";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import { toast } from "react-toastify";
import { eCustomerValueType } from "./StorePriceEnums";
import classnames from "classnames";

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
    whiteSpace: "nowrap",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
    '& .MuiTableCell-head':{
      color:"#A9B2BC !important",
    }
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "700",
    paddingTop: "8px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
      boxShadow: "none",
    },
  },
  chipWrapper: {
    backgroundColor: "#F3F6F9",
    borderRadius: "36px",
    color: "#70808F",
    fontSize: "16px",
    fontWeight: "400",
    padding: "7px 14px",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 7px 10px 0px",
    display: "inline-flex",
  },
  noPriceBtn: {
    color: "#3699FF",
    cursor: "pointer",
  },
  noPriceDes: {
    color: "#464E5F",
  },
  actionsIcons: {
    display: "flex",
    justifyContent: "center",
    gap: 5,
  },
}));

const AvailabilityStatus = ({ item, availabilityPeriods = [] }) => {
  const availableStatus = availabilityPeriods?.find(
    p => `${item?.papId}` === `${p.key}`
  );

  return availableStatus?.isUpComing ? (
    <>
      <AccessAlarm fontSize='small' style={{ color: "#9B51E0" }} />
    </>
  ) : (
    <>
      {availableStatus?.status === "Expired" ? (
        <Cancel fontSize='small' style={{ color: "#FF3535" }} />
      ) : availableStatus?.status === "" ? (
        <PortableWifiOff fontSize='small' style={{ color: "#FFAD4E" }} />
      ) : (
        <CheckCircle fontSize='small' style={{ color: "#18BC6D" }} />
      )}
    </>
  );
};

const getAvailabilityStatus = ({ value, availabilityPeriods = [] }) => {
  switch (value) {
    case "Not Available": {
      return 0; // NotAvailable
    }
    default: {
      const isUpcommingStatus = availabilityPeriods?.find(
        p => p.isUpComing && `${p.key}` === value
      );
      return isUpcommingStatus ? 4 : 1; // Upcoming/Availability
    }
  }
};

function findIndexOfFirstHyphen(inputString) {
  const indexOfHyphen = inputString.indexOf("-");
  return indexOfHyphen;
}

const GetValueByAvailabilityKey = ({ availabilityPeriods, item }) => {
  const matchingObject = availabilityPeriods.find(
    obj => obj.key === `${item.papId}`
  );
  if (matchingObject && matchingObject.value) {    
    const hypenIndex = findIndexOfFirstHyphen(matchingObject.value);
    if(hypenIndex === -1){
      return matchingObject.value;
    }else{
      return matchingObject.value.slice(0 , hypenIndex );
    }
  } else {
    return null;
  }
};

const EditableRow = ({
  index,
  handleCustomerTabChange,
  item,
  availabilityPeriodDD,
  setIsNewProductPricing,
  setPriceName,
  priceName,
  isNewProductPricing,
  allProductPrices,
  onItemSave,
  editRow,
  setEditableRow,
  isMassEditing,
  setMassEditing,
  allVisibilityGroups,
  visibilityGroups,
  listData,
  setMassEditableRows,
  massEditRows,
  excludedCustomers,
}) => {
  const classes = useStyles();
  const [isAddVisibilityGroup, setIsAddVisibilityGroup] = useState(false);
  const [isViewAll, setIsViewAll] = useState(false);
  const [productPricing, setProductPricing] = useState(item);
  const [priceSetting, setPriceSetting] = useState(
    item.priceSettingType === 3 ? "2" : "1"
  );
  const [customerList, setCustomerList] = useState(false);
  const [promotionsList, setPromotionsList] = useState(false);
  const [dateTimetoggle, setDateTimetoggle] = useState(true);

  const relatedGroupList = allProductPrices
  .map(a => ({ id: a.id,
      name: <><span>{a.customer} - <span style={{ color: 'rgb(54, 153, 255)'}}>{a.availabilityPeriod}</span></span></>,
      priceSettingType: a.priceSettingType,
      price: a.price }));
  const searchOptions = allProductPrices
  .map(a => ({ id: a.id,
      name: a.customer,
      priceSettingType: a.priceSettingType,
      price: a.price }))
    .filter(obj => obj.name !== null);

  const updatePricing = newValue => {
    const onPriceFilterData =
      allProductPrices?.find(element => element.id === newValue?.id) ?? "";

    const updatedObject = onPriceFilterData
      ? {
          id: productPricing.id,
          availabilityFullStatus:
            onPriceFilterData.availabilityFullStatus ?? "",
          availabilityPeriod: onPriceFilterData.availabilityPeriod ?? "",
          availabilityPeriodStatus:
            onPriceFilterData.availabilityPeriodStatus ?? "",
          availabilityStatus: onPriceFilterData.availabilityStatus ?? "",
          customer: onPriceFilterData.customer ?? newValue,
          groupId: onPriceFilterData.groupId ?? "",
          papId: onPriceFilterData.papId ?? "",
          price: onPriceFilterData.price ?? "",
          priceMethod: onPriceFilterData.priceMethod ?? "",
          priceMethodType: onPriceFilterData.priceMethodType ?? "",
          priceSetting: onPriceFilterData.priceSetting ?? "",
          priceSettingType: onPriceFilterData.priceSettingType ?? "",
          priceValue: onPriceFilterData.priceValue ?? "",
          priceValueType: onPriceFilterData.priceValueType ?? "",
          productId: onPriceFilterData.productId ?? "",
          relatedGroupId: onPriceFilterData.relatedGroupId ?? "",
        }
      : { ...productPricing, customer: newValue };
    setPriceName(updatedObject.customer);

    setProductPricing(updatedObject);
    if (isMassEditing) {
      handleMassUpdate(updatedObject);
    }
  };

  const setProp = ({ propName, value }) => {
    const updatedObject = { ...productPricing, [propName]: value };
    setProductPricing(updatedObject);
    if (isMassEditing) {
      handleMassUpdate(updatedObject);
    }
  };

  const handleMassUpdate = updatedObj => {
    let updatedRows = massEditRows.map(currentObj =>
      currentObj.id === updatedObj.id ? updatedObj : currentObj
    );
    setMassEditableRows(updatedRows);
  };

  const isPriceBased = () => {
    if (productPricing.priceSettingType === 1 && priceSetting === "2") {
      setProp({
        propName: "priceSettingType",
        value: 3,
      });
    }
  };

  const priceToggle = () => {
    if (priceSetting === "1") {
      setPriceSetting("2");
    } else {
      setPriceSetting("1");
    }
    setProp({
      propName: "priceSettingType",
      value: 1,
    });
    setCustomerList(false);
    setPromotionsList(false);
  };

  const isShowCustomerList = () => {
    setCustomerList(!customerList);
    setPriceSetting("1");
    setPromotionsList(false);
  };

  const isShowPromotions = () => {
    setPromotionsList(!promotionsList);
    setCustomerList(false);
    setPriceSetting("1");
  };

  const [startFormattedDateTime, setStartFormattedDateTime] = useState("");
  const [endFormattedDateTime, setEndFormattedDateTime] = useState("");
  const [duration, setDuration] = useState("");
  const [error, setError] = useState("");
   
  const calculateDuration = () => {
    const start = new Date(startFormattedDateTime);
    const end = new Date(endFormattedDateTime);

    if (start && end) {
      if (start.getDate() === end.getDate()) {
        if (end > start) {
          const durationInMilliseconds = end.getTime() - start.getTime();
          const durationInMinutes = durationInMilliseconds / (1000 * 60);
          const durationHours = Math.floor(durationInMinutes / 60);
          const durationMinutes = Math.floor(durationInMinutes % 60);

          setDuration(`${durationHours} hours and ${durationMinutes} minutes`);
          setError("");
        } else {
          setDuration("");
          setError("End time should be greater than start time");
        }
      } else if (end > start) {
        const durationInMilliseconds = end.getTime() - start.getTime();
        const durationInMinutes = durationInMilliseconds / (1000 * 60);
        const durationHours = Math.floor(durationInMinutes / 60);
        const durationMinutes = Math.floor(durationInMinutes % 60);

        setDuration(`${durationHours} hours and ${durationMinutes} minutes`);
        setError("");
      } else {
        setDuration("");
        setError("End date should be greater than start date");
      }
    } else {
      setDuration("");
      setError("");
    }
  };

  const onCancle = itemid => {
    if (isNewProductPricing) {
      setIsNewProductPricing(false);
      setPriceName("");
    } else {
      const NewEditarray = editRow?.filter(item => item !== itemid);
      if (isMassEditing) {
        const remainingMassUpdateRows = massEditRows?.filter(
          item => item.id !== itemid
        );
        setMassEditableRows([...remainingMassUpdateRows]);
        if (NewEditarray.length === 0) {
          setMassEditing(false);
        }
      }
      setEditableRow(NewEditarray);
    }
  };

  return (
    <>
      {isAddVisibilityGroup && (
        <CustomerGroupsPopup
          handleCustomerTabChange={handleCustomerTabChange}
          selectedValues={productPricing?.groupId?.map(g => {
            return {
              visibilityGroupId: g?.id || g,
              type: g?.type,
            };
          })}
          isAddVisibilityGroup={isAddVisibilityGroup}
          isViewAll={isViewAll}
          setIsAddVisibilityGroup={setIsAddVisibilityGroup}
          setIsViewAll={setIsViewAll}
          allVisibilityGroups={allVisibilityGroups}
          visibilityGroups={visibilityGroups}
          onGroupSelection={data => {
            const groups = [
              ...data?.map(g => {
                return {
                  id: g.visibilityGroupId,
                  type: g.type,
                };
              }),
            ];
            setProp({
              propName: "groupId",
              value: groups,
            });
          }}
        />
      )}
      <TableRow
        key={index}
        className='editableSingleProductPricing addPrice'>
        <TableCell
          style={{
            verticalAlign:
              productPricing?.priceSettingType === 3 && priceSetting === "2"
                ? "top"
                : "center",
          }}>
          {excludedCustomers?.includes(productPricing?.customer) ||
          (isNewProductPricing && priceName !== "" && excludedCustomers?.includes(priceName)) ? (
            <span
              style={{
                color: "#3699FF",
              }}>
              <strong>
                {isNewProductPricing ? priceName : productPricing?.customer}*
              </strong>
            </span>
          ) : (
            <Autocomplete
              popupIcon={""}
              value={productPricing.customer}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  updatePricing(newValue);
                } else if (newValue && newValue.inputValue) {
                  updatePricing(newValue.inputValue);
                } else {
                  updatePricing(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id='combo-box-demo'
              options={searchOptions}
              getOptionLabel={option => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name ?? "";
              }}
              renderOption={option => option.name}
              freeSolo
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder='Enter a Price Name'
                  variant='outlined'
                />
              )}
            />
          )}
        </TableCell>
        <TableCell
          className={classes.gridBodyInner}
          style={{
            verticalAlign:
              productPricing?.priceSettingType === 3 && priceSetting === "2"
                ? "top"
                : "center",
          }}>
          {excludedCustomers?.includes(productPricing?.customer) ||
          (isNewProductPricing && priceName !== "" && excludedCustomers?.includes(priceName)) ? (
            <>
              {priceName === "Everyone" && (
                <div
                  style={{
                    color: "#3699FF",
                    backgroundColor: "rgba(54, 153, 255, 0.25)",
                    padding: "10px 12px",
                    borderRadius: "6px",
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    fontWeight: 700,
                    display: "inline-block",
                  }}>
                  Public/Everyone Else
                </div>
              )}

              {["MAP Price", "MSRP Price"].includes(priceName ?? productPricing?.customer) && (
                <div
                  style={{
                    color: "#3699FF",
                    backgroundColor: "rgba(54, 153, 255, 0.25)",
                    padding: "10px 12px",
                    borderRadius: "6px",
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    fontWeight: 700,
                    display: "inline-block",
                  }}>
                  Any Contacts with Pricing
                </div>
              )}
              {!isNewProductPricing && (
                <div
                  style={{
                    color: "#3699FF",
                    backgroundColor: "rgba(54, 153, 255, 0.25)",
                    padding: "10px 12px",
                    borderRadius: "6px",
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    fontWeight: 700,
                    display: "inline-block",
                  }}>
                  {productPricing?.customer == "Everyone"
                    ? "Public/Everyone Else"
                    : productPricing?.customer == "MAP Price" || "MSRP Price"
                    ? "Any Contacts with Pricing"
                    : productPricing?.customer}
                </div>
              )}
            </>
          ) : (
            productPricing?.customer && (
              <Grid item>
                {productPricing.groupId?.length ? (
                  <Button
                    style={
                      customerList === true
                        ? {
                            color: "#FFFFFF",
                            backgroundColor: "#3699FF",
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                            padding: "8px 12px",
                            fontWeight: 700,
                            border: "none",
                          }
                        : {
                            color: "#3699FF",
                            backgroundColor: "rgba(54, 153, 255, 0.25)",
                            padding: "8px 12px",
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                            fontWeight: 700,
                            border: "none",
                          }
                    }
                    variant='outlined'
                    onClick={isShowCustomerList}>
                    {productPricing.groupId?.length} Customer Groups
                  </Button>
                ) : (
                  <Button
                    color='primary'
                    style={{
                      padding: "7px 32px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      fontWeight: 500,
                      whiteSpace: "nowrap",
                    }}
                    variant='outlined'
                    onClick={() => {
                      setIsAddVisibilityGroup(true);
                      setIsViewAll(false);
                    }}
                    startIcon={<Add />}>
                    Add Customers
                  </Button>
                )}
              </Grid>
            )
          )}
        </TableCell>
        <TableCell
          colSpan={2}
          className='priceSettingsCol'
          style={{ verticalAlign: "top" }}>
          {excludedCustomers?.includes(item?.customer) ||
          (isNewProductPricing && priceName !== "" && excludedCustomers?.includes(priceName)) ? (
            <>
              <Grid
                style={{
                  display: "flex",
                }}>
                <Grid
                  // xs={6}
                  style={{ textAlign: "right" }}
                  className='gridPriceCol price-container'>
                  <OutlinedInput
                    disabled={priceSetting === "2"}
                    value={productPricing?.price}
                    placeholder={priceSetting === "2" ? `Click "Based On"` : "Enter a Fixed Price"}

                    inputProps={{
                      maxLength: 9,
                      step: 0.5,
                    }}
                    startAdornment={
                      productPricing?.price && (
                        <InputAdornment position='start'>$</InputAdornment>
                      )
                    }
                    endAdornment={productPricing?.price && <Copy />}
                    onChange={({ target }) => {
                      if (
                        target.value &&
                        parseFloat(`${target.value}`) < 999999
                      ) {
                        setProp({
                          propName: "priceSettingType",
                          value: 1,
                        });
                        setProp({
                          propName: "price",
                          value: `${target.value}`,
                        });
                      } else if (target.value === "") {
                        setProp({
                          propName: "price",
                          value: `${target.value}`,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid xs={6} item={item}>
                  <Button
                    className='base-button'
                    onClick={isPriceBased}
                    style={
                      productPricing?.priceSettingType === 3 &&
                      priceSetting === "2"
                        ? { background: "#3699FF", color: "#FFF" }
                        : {}
                    }>
                    {priceSetting === "1" ? (
                      <>
                        <div>Fixed/Set Price </div>
                        <div onClick={priceToggle} className='arrowIcon'>
                          <ArrowsIcon style={{ color: "#3699FF" }} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div>Based On</div>
                        <div onClick={priceToggle} className='arrowIcon'>
                          <ArrowsIcon
                            style={
                              productPricing?.priceSettingType === 3 &&
                              priceSetting === "2"
                                ? { color: "#FFF" }
                                : { color: "#3699FF" }
                            }
                          />
                        </div>
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>

              {productPricing?.priceSettingType === 3 &&
              priceSetting === "2" ? (
                <Grid
                  container
                  style={{
                    marginTop: "20px",
                  }}>
                  <Grid
                    item={item}
                    xs={12}
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                    }}>
                    <Grid item style={{ whiteSpace: "nowrap" }}>
                      <strong>Based On</strong>
                    </Grid>

                    <Grid item={item} xs={12} className='onBasedDropdown'>
                      <Dropdown
                        placeholder='Please Select'
                        value={productPricing.relatedGroupId}
                        required={false}
                        customMenuItems={relatedGroupList}
                        handleChange={({ target }) => {
                          setProp({
                            propName: "relatedGroupId",
                            value: target.value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item={item} xs={12}>
                    <FormControl>
                      <RadioGroup
                        name='rg-category-type'
                        value={
                          productPricing?.priceMethodType
                            ? parseInt(productPricing?.priceMethodType)
                            : productPricing?.priceMethodType
                        }
                        onChange={({ target }) => {
                          setProp({
                            propName: "priceMethodType",
                            value: target.value,
                          });
                        }}>
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            <span>
                              <strong>Same Price</strong> - Keep Both Prices The
                              Same
                            </span>
                          }
                          value={1}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            <span>
                              <strong>Make a Change</strong> -
                              {` Based on ${
                                searchOptions?.find(
                                  g => g.id === productPricing.relatedGroupId
                                )?.name || "Selected Group"
                              }`}
                            </span>
                          }
                          value={2}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {productPricing?.priceMethodType &&
                    parseInt(productPricing?.priceMethodType) === 2 && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          gap: 10,
                          flexWrap: "nowrap",
                        }}>
                        <Grid item={item} xs={4}>
                          <Dropdown
                            placeholder='Select a Calculation'
                            value={productPricing?.priceMethod}
                            required={false}
                            customMenuItems={[
                              {
                                id: "+",
                                name: "Additional",
                              },
                              {
                                id: "-",
                                name: "Subtract",
                              },
                              {
                                id: "/",
                                name: "Divide",
                              },
                              {
                                id: "*",
                                name: "Multiply",
                              },
                            ]}
                            handleChange={({ target }) =>
                              setProp({
                                propName: "priceMethod",
                                value: target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid
                          item={item}
                          xs={4}
                          className='productPricingInput'>
                          <OutlinedInput
                            placeholder='Enter a Number'
                            fullWidth
                            value={productPricing?.priceValue}
                            type={"number"}
                            onChange={({ target }) =>{
                            const inputValue = parseInt(target.value);
                            const validInput = isNaN(inputValue) ? '' : Math.max(0, inputValue);
                            setProp({
                                  propName: "priceValue",
                                  value: validInput,
                                })
                            }}
                          />
                        </Grid>
                        <Grid item={item} xs={4} className='productDropdown'>
                          <Dropdown
                            placeholder='Select Value'
                            value={productPricing?.priceValueType}
                            required={false}
                            customMenuItems={[
                              {
                                id: "0",
                                name: "Percent",
                              },
                              {
                                id: "1",
                                name: "Dollar",
                              },
                            ]}
                            handleChange={({ target }) =>
                              setProp({
                                propName: "priceValueType",
                                value: target.value,
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              ) : productPricing?.priceSettingType === 2 ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginTop: "10px",
                  }}>
                  <Grid
                    item={item}
                    xs={2}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}>
                    <span>
                      <strong>Same as</strong>
                    </span>
                  </Grid>
                  <Grid item={item} xs={3}>
                    <Dropdown
                      value={productPricing.relatedGroupId}
                      required={false}
                      customMenuItems={relatedGroupList}
                      handleChange={({ target }) => {
                        setProp({
                          propName: "relatedGroupId",
                          value: target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : (
            <>
              {productPricing.groupId?.length ? (
                <Grid
                  style={{
                    display: "flex",
                  }}>
                  <Grid
                    // xs={6}
                    style={{ textAlign: "right" }}
                    className='gridPriceCol price-container'>
                    <OutlinedInput
                      disabled={priceSetting === "2"}
                      value={productPricing?.price}
                      placeholder={priceSetting === "2" ? `Click "Based On"` : "Enter a Fixed Price"}
                      inputProps={{
                        maxLength: 9,
                        step: 0.5,
                      }}
                      startAdornment={
                        productPricing?.price && (
                          <InputAdornment position='start'>$</InputAdornment>
                        )
                      }
                      endAdornment={productPricing?.price && <Copy />}
                      onChange={({ target }) => {
                        if (
                          target.value &&
                          parseFloat(`${target.value}`) < 999999
                        ) {
                          setProp({
                            propName: "priceSettingType",
                            value: 1,
                          });
                          setProp({
                            propName: "price",
                            value: `${target.value}`,
                          });
                        } else if (target.value === "") {
                          setProp({
                            propName: "price",
                            value: `${target.value}`,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid xs={6} item={item}>
                    <Button
                      className='base-button'
                      onClick={isPriceBased}
                      style={
                        productPricing?.priceSettingType === 3 &&
                        priceSetting === "2"
                          ? { background: "#3699FF", color: "#FFF" }
                          : {}
                      }>
                      {priceSetting === "1" ? (
                        <>
                          <div>Fixed/Set Price </div>
                          <div onClick={priceToggle} className='arrowIcon'>
                            <ArrowsIcon style={{ color: "#3699FF" }} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>Based On</div>
                          <div onClick={priceToggle} className='arrowIcon'>
                            <ArrowsIcon
                              style={
                                productPricing?.priceSettingType === 3 &&
                                priceSetting === "2"
                                  ? { color: "#FFF" }
                                  : { color: "#3699FF" }
                              }
                            />
                          </div>
                        </>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
              {productPricing?.priceSettingType === 3 &&
              priceSetting === "2" ? (
                <Grid
                  container
                  style={{
                    marginTop: "20px",
                  }}>
                  <Grid
                    item={item}
                    xs={12}
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                    }}>
                    <Grid item style={{ whiteSpace: "nowrap" }}>
                      <strong>Based On</strong>
                    </Grid>

                    <Grid item={item} xs={12} className='onBasedDropdown'>
                      <Dropdown
                        placeholder='Please Select'
                        value={productPricing.relatedGroupId}
                        required={false}
                        customMenuItems={relatedGroupList}
                        handleChange={({ target }) => {
                          setProp({
                            propName: "relatedGroupId",
                            value: target.value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item={item} xs={12}>
                    <FormControl>
                      <RadioGroup
                        name='rg-category-type'
                        value={
                          productPricing?.priceMethodType
                            ? parseInt(productPricing?.priceMethodType)
                            : productPricing?.priceMethodType
                        }
                        onChange={({ target }) => {
                          setProductPricing({
                            ...productPricing,
                            priceMethodType: target.value,
                          });
                        }}>
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            <span>
                              <strong>Same Price</strong> - Keep Both Prices The
                              Same
                            </span>
                          }
                          value={1}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            <span>
                              <strong>Make a Change</strong> -
                              {` Based on ${
                                searchOptions?.find(
                                  g => g.id === productPricing.relatedGroupId
                                )?.name || "Selected Group"
                              }`}
                            </span>
                          }
                          value={2}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {productPricing?.priceMethodType &&
                    parseInt(productPricing?.priceMethodType) === 2 && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          gap: 10,
                          flexWrap: "nowrap",
                        }}>
                        <Grid item={item} xs={4}>
                          <Dropdown
                            placeholder='Select a Calculation'
                            value={productPricing?.priceMethod}
                            required={false}
                            customMenuItems={[
                              {
                                id: "+",
                                name: "Additional",
                              },
                              {
                                id: "-",
                                name: "Subtract",
                              },
                              {
                                id: "/",
                                name: "Divide",
                              },
                              {
                                id: "*",
                                name: "Multiply",
                              },
                            ]}
                            handleChange={({ target }) =>
                              setProp({
                                propName: "priceMethod",
                                value: target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid
                          item={item}
                          xs={4}
                          className='productPricingInput'>
                          <OutlinedInput
                            placeholder='Enter a Number'
                            fullWidth
                            value={productPricing?.priceValue}
                            onChange={({ target }) =>
                              setProp({
                                propName: "priceValue",
                                value: target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item={item} xs={4} className='productDropdown'>
                          <Dropdown
                            placeholder='Select Value'
                            value={productPricing?.priceValueType}
                            required={false}
                            customMenuItems={[
                              {
                                id: "0",
                                name: "Percent",
                              },
                              {
                                id: "1",
                                name: "Dollar",
                              },
                            ]}
                            handleChange={({ target }) => {
                              setProp({
                                propName: "priceValueType",
                                value: target.value,
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              ) : productPricing?.priceSettingType === 2 ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginTop: "10px",
                  }}>
                  <Grid
                    item={item}
                    xs={2}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}>
                    <span>
                      <strong>Same as</strong>
                    </span>
                  </Grid>
                  <Grid item={item} xs={3}>
                    <Dropdown
                      value={productPricing.relatedGroupId}
                      required={false}
                      customMenuItems={relatedGroupList}
                      handleChange={({ target }) => {
                        setProp({
                          propName: "relatedGroupId",
                          value: target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </>
          )}
        </TableCell>

        <TableCell
          style={{
            verticalAlign: "top",
          }}>
          {productPricing?.price !== "" ||
          (priceSetting === "2" &&
            (productPricing?.priceMethodType == 1 ||
              productPricing?.priceValue)) ? (
            <Button
              className='noneBtn'
              style={
                promotionsList === true
                  ? {
                      color: "#FFFFFF",
                      backgroundColor: "#979797",
                      padding: "8px 12PX",
                    }
                  : {
                      color: "rgba(151, 151, 151, 1)",
                      backgroundColor: "rgba(151, 151, 151, 0.25)",
                      padding: "8px 12PX",
                    }
              }
              onClick={isShowPromotions}>
              None
            </Button>
          ) : null}
        </TableCell>
        <TableCell
          style={{
            textAlign: "center",
            width: "230px",
            verticalAlign: "top",
          }}>
          {productPricing?.price !== "" ||
          (priceSetting === "2" &&
            (productPricing?.priceMethodType == 1 ||
              productPricing?.priceValue)) ? (
            <Grid item={item} className='datePickerContainer'>
              {dateTimetoggle === true ? (
                <div className='dropDown'>
                  <Autocomplete
                    popupIcon={""}
                    id='AvaiabilityPeriods'
                    placeholder='Type an Availability Period'
                    value={
                    availabilityPeriodDD?.find(
                        obj => `${obj.key}` === `${productPricing?.papId}`
                      ) ?? "Expired"
                    }
                    options={availabilityPeriodDD}
                    renderOption={period => (
                      <React.Fragment>
                        <span>
                          {period.isUpComing ? (
                            <AccessAlarm
                              fontSize='small'
                              style={{ color: "#9B51E0" }}
                            />
                          ) : period.status?.includes("Expired") ? (
                            <Cancel
                              fontSize='small'
                              style={{ color: "#FF3535" }}
                            />
                          ) : period.status === "" ? (
                            <PortableWifiOff
                              fontSize='small'
                              style={{ color: "#FFAD4E" }}
                            />
                          ) : (
                            <CheckCircle
                              fontSize='small'
                              style={{ color: "#18BC6D" }}
                            />
                          )}
                        </span>
                        {                        
                        findIndexOfFirstHyphen(period.value) === -1 ? 
                        period.value : 
                        period.value.slice(0 , findIndexOfFirstHyphen(period.value))
                        }
                      </React.Fragment>
                    )}
                    onChange={(event, newValue) => {
                      setProp({
                        propName: "availabilityStatus",
                        value: getAvailabilityStatus({
                          value: `${newValue?.key}`,
                          availabilityPeriods: availabilityPeriodDD,
                        }),
                      });
                      setProp({
                        propName: "papId",
                        value: newValue?.key,
                      });
                    }}
                    getOptionLabel={option => option.value ?? ""}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant='outlined'
                        placeholder='Type an Availability Period'
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <React.Fragment>
                              {params.inputProps.value && (
                              <span>
                              {availabilityPeriodDD?.map(period => {
                               if (
                                  period.value === params.inputProps.value  && 
                                  `${period.key}` === `${productPricing.papId}`
                                ) {
                                  return period.isUpComing ? (
                                    <AccessAlarm
                                      key='access-alarm'
                                      fontSize='small'
                                      style={{ color: "#9B51E0" }}
                                    />
                                  ) : period.status?.includes("Expired") ? (
                                    <Cancel
                                      key='cancel'
                                      fontSize='small'
                                      style={{ color: "#FF3535" }}
                                    />
                                  ) : period.status === "" ? (
                                    <PortableWifiOff
                                      key='portable-wifi-off'
                                      fontSize='small'
                                      style={{ color: "#FFAD4E" }}
                                    />
                                  ) : (
                                    <CheckCircle
                                      key='check-circle'
                                      fontSize='small'
                                      style={{ color: "#18BC6D" }}
                                    />
                                  );
                                }
                                return null;
                              })}
                            </span> )}
                              {params.InputProps.startAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <span
                    style={{ display: "none" }}
                    onClick={() => setDateTimetoggle(!dateTimetoggle)}>
                    <ArrowsIcon style={{ color: "#3699FF" }} />
                  </span>
                </div>
              ) : (
                <Grid className='date-picker'>
                  <div>
                    <div style={{ marginBottom: 10 }} className='start-date'>
                      <FormattedDateTimePicker
                        formattedDateTime={startFormattedDateTime}
                        setFormattedDateTime={setStartFormattedDateTime}
                        calculateDuration={calculateDuration}
                      />
                      <span onClick={() => setDateTimetoggle(!dateTimetoggle)}>
                        <ArrowsIcon style={{ color: "#3699FF" }} />
                      </span>
                    </div>
                    <div className='end-date'>
                      <span className='to-span'>to</span>{" "}
                      <FormattedDateTimePicker
                        formattedDateTime={endFormattedDateTime}
                        setFormattedDateTime={setEndFormattedDateTime}
                        calculateDuration={calculateDuration}
                      />
                    </div>

                    {error && <div className='error'>{error}</div>}
                    {duration && <div>Duration: {duration}</div>}
                  </div>
                </Grid>
              )}
            </Grid>
          ) : null}
        </TableCell>
        <TableCell
          style={{
            verticalAlign:
              productPricing?.priceSettingType === 3 && priceSetting === "2"
                ? "top"
                : "center",
            width: "70px",
          }}>
          <div className={classes.actionsIcons}>
            <span style={{cursor: "pointer"}} onClick={() => onCancle(item.id)}>
              <CancleIcon />
            </span>
            <span>
              {productPricing?.papId ||
              item?.availabilityPeriod == "Indefinitely" ? (
                <span
                  style={{cursor: "pointer"}}
                  onClick={() => onItemSave(productPricing)}
                >
                  <TickIcon
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </span>
              ) : (
                <span
                  style={{
                    display: "block",
                    height: "25px",
                    width: "25px",
                  }}></span>
              )}
            </span>
          </div>
        </TableCell>
      </TableRow>
      <>
        {customerList && productPricing?.groupId?.length ? (
          <TableCell colSpan={6}>
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <Grid
                style={{ display: "flex", marginRight: "10px" }}
                className='customersAddContianiner'>
                <Typography className='customerTypo'>Customers</Typography>
                <span className='staric'>*</span>
              </Grid>
              <Button
                color='primary'
                style={{
                  padding: "9px",
                }}
                variant='outlined'
                onClick={() => {
                  setIsAddVisibilityGroup(true);
                  setIsViewAll(false);
                }}>
                <Add />
              </Button>
            </Grid>
            {!["Everyone", "MAP Price", "MSRP Price"].includes() && (
              <div>
                {productPricing?.groupId?.slice(0, 3)?.map(g => {
                  const svG = allVisibilityGroups?.find(
                    c => `${c.id}` === `${g?.id || g}` && c.generic === g?.type
                  );
                  return (
                    <span key={g?.id || g} className={classes.chipWrapper}>
                      <span>{svG?.value}</span>
                      <Clear
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setProp({
                            propName: "groupId",
                            value: [
                              ...productPricing?.groupId?.filter(
                                c =>
                                  !(
                                    `${c.id}` === `${g?.id || g}` &&
                                    c?.type === g?.type
                                  )
                              ),
                            ],
                          });
                        }}
                      />
                    </span>
                  );
                })}
                <Button
                  style={{ color: "#3699FF" }}
                  onClick={() => {
                    setIsAddVisibilityGroup(true);
                    setIsViewAll(true);
                  }}>
                  View All({productPricing?.groupId?.length})
                </Button>
              </div>
            )}
          </TableCell>
        ) : null}

        {promotionsList === true ? (
          <TableCell colSpan={6}>
            <h5>Promotions</h5>
            <Typography>No Promotions created yet for this product</Typography>
          </TableCell>
        ) : null}
      </>
    </>
  );
};

export const SingleProductPricingNew = ({
  allAvailableTimes,
  availabilityPeriodDD,
  listData,
  allProductPrices,
  allVisibilityGroups,
  isMassEditing,
  setMassEditing,
  massEditRows,
  setMassEditableRows,
  visibilityGroups,
  loadData,
  setLoading,
  order,
  onOrderBy,
  isNewProductPricing,
  setIsNewProductPricing,
  setPriceName,
  priceName,
  activeProductId,
  selectedtTab,
  editRow,
  handleCustomerTabChange,
  setEditableRow,
  addPriceModal,
  excludedCustomers,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addUpdateProductPricing = availabilityPeriodData => {
    setLoading(true);
    dispatch(
      productInventoryActions.onAddUpdateProductPricing(availabilityPeriodData)
    ).then(res => {
      if (!res?.success) {
        setLoading(false);
        toast.error(res?.message || "Something went wrong!");
      } else {
        const newEditAbles = editRow?.filter(id => id !== res?.data.id);
        if (isMassEditing) {
          const remainingMassUpdateRows = massEditRows.filter(
            row => row.id !== res?.data.id
          );
          setMassEditableRows(remainingMassUpdateRows);
          if (newEditAbles.length === 0) {
            setMassEditing(false);
          }
        }
        setEditableRow(newEditAbles);
        if (isNewProductPricing) {
          setIsNewProductPricing(false);
          setPriceName("");
        }
        loadData();
      }
    });
  };

  return (
    <Paper>
      <ValidatorForm onSubmit={() => {}}>
        <Table>
          <TableHead className={classes.gridHeader}>
            <TableRow className={classes.gridHeaderRow}>
              <TableCell
                className={classes.gridHeaderInner}
                // style={{ width: "26%" }}
              >
                Price Name
                <TableSortLabel
                  active={order.orderByKey === "Price Name"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Price Name",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
              <TableCell
                className={classes.gridHeaderInner}
                // style={{ width: "26%" }}
              >
                Customers
                <TableSortLabel
                  active={order.orderByKey === "Customer"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Customer",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Price
                <TableSortLabel
                  active={order.orderByKey === "Price"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Price",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'></TableSortLabel>
              </TableCell>
              <TableCell
                className={classes.gridHeaderInner}
                // style={{ width: "55%" }}
              >
                Price Settings
                <TableSortLabel
                  active={order.orderByKey === "Price Settings"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Price Settings",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'></TableSortLabel>
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Promotions
                <TableSortLabel
                  active={order.orderByKey === "Promotions"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Promotions",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
              <TableCell className={classes.gridHeaderInner}>
                Availability
                <TableSortLabel
                  active={order.orderByKey === "Availability"}
                  direction={order.orderVal === 1 ? "asc" : "desc"}
                  onClick={() =>
                    onOrderBy({
                      orderByKey: "Availability",
                      orderVal: order.orderVal === 0 ? 1 : 0,
                    })
                  }
                  IconComponent={SortIcon}
                  className='sortIcon'
                />
              </TableCell>
              <TableCell 
              className={classes.gridHeaderInner}
              style={{marginRight:"10px"}}
              >
                Quick Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isNewProductPricing && (
              <EditableRow
                index={listData?.length + 1}
                handleCustomerTabChange={handleCustomerTabChange}
                item={{
                  id: 0,
                  customer:
                    priceName == ""
                      ? ""
                      : priceName == "Everyone"
                      ? "Everyone"
                      : priceName == "MSRP Price"
                      ? "MSRP Price"
                      : priceName == "MAP Price"
                      ? "MAP Price"
                      : "",
                  groupId: [],
                  productId: activeProductId,
                  price: "",
                  priceSettingType: 1,
                  priceMethodType: 1,
                }}
                allProductPrices={allProductPrices}
                availabilityPeriodDD={availabilityPeriodDD}
                allAvailableTimes={allAvailableTimes}
                onItemSave={editedRow => {
                  addUpdateProductPricing(editedRow);
                }}
                isNewProductPricing={isNewProductPricing}
                setIsNewProductPricing={setIsNewProductPricing}
                setPriceName={setPriceName}
                priceName={priceName}
                editRow={editRow}
                setEditableRow={setEditableRow}
                allVisibilityGroups={allVisibilityGroups}
                visibilityGroups={visibilityGroups}
                listData={listData?.filter(obj => obj.name !== null)}
                excludedCustomers={excludedCustomers}
                setMassEditableRows={setMassEditableRows}
                massEditRows={massEditRows}
              />
            )}
            {listData?.map((item, index) => {
              return editRow?.includes(item.id) ? (
                <EditableRow
                  key={`edit-${index}`}
                  isMassEditing={isMassEditing}
                  setMassEditing={setMassEditing}
                  handleCustomerTabChange={handleCustomerTabChange}
                  index={index}
                  item={item}
                  allProductPrices={[
                    ...allProductPrices
                      ?.filter(i => i.id !== item.id)
                    ]}
                  availabilityPeriodDD={availabilityPeriodDD}
                  allAvailableTimes={allAvailableTimes}
                  onItemSave={editedRow => {
                    if (
                      editedRow?.id === 0 &&
                      (!editedRow?.groupId || editedRow?.group?.length === 0)
                    )
                      toast.warn("Please select atleast 1 customer group!");
                    else addUpdateProductPricing(editedRow);
                  }}
                  isNewProductPricing={false}
                  setIsNewProductPricing={setIsNewProductPricing}
                  setPriceName={setPriceName}
                  priceName={priceName}
                  editRow={editRow}
                  setEditableRow={setEditableRow}
                  allVisibilityGroups={allVisibilityGroups}
                  visibilityGroups={visibilityGroups}
                  listData={listData}
                  excludedCustomers={excludedCustomers}
                  setMassEditableRows={setMassEditableRows}
                  massEditRows={massEditRows}
                />
              ) : (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}>
                      {["Everyone", "MAP Price", "MSRP Price"].includes(
                        item?.customer
                      ) ? (
                        <span
                          style={{
                            color: "#3699FF",
                          }}>
                          <strong>{item?.customer}*</strong>
                        </span>
                      ) : (
                        <span style={{
                          color:"#464E5F",
                          fontSize:"14px",
                          fontWeight:"400"
                          }}>
                          {item?.customer}
                        </span>
                      )}
                      {item.id && item?.customer?.includes("MAP") && (
                        <span
                          className={classnames(
                            item?.availabilityStatus === 2
                              ? "greenBtn"
                              : item?.availabilityStatus === 3 && "redBtn"
                          )}>
                          {eCustomerValueType[item?.availabilityStatus]}
                        </span>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {excludedCustomers?.includes(item?.customer) ? (
                      ["Everyone"].includes(item?.customer) ? (
                        <div
                          style={{
                            color: "#3699FF",
                            backgroundColor: "rgba(54, 153, 255, 0.25)",
                            padding: "10px 12px",
                            borderRadius: "6px",
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            fontWeight: 700,
                            display: "inline-block",
                          }}>
                          Public/Everyone Else
                        </div>
                      ) : (
                        ["MAP Price", "MSRP Price"].includes(
                          item?.customer
                        ) && (
                          <div
                            style={{
                              color: "#3699FF",
                              backgroundColor: "rgba(54, 153, 255, 0.25)",
                              padding: "10px 12px",
                              borderRadius: "6px",
                              whiteSpace: "nowrap",
                              fontSize: "12px",
                              fontWeight: 700,
                              display: "inline-block",
                            }}>
                            Any Contacts with Pricing
                          </div>
                        )
                      )
                    ) : (
                      <div
                        style={{
                          color: "#3699FF",
                          backgroundColor: "rgba(54, 153, 255, 0.25)",
                          padding: "10px 12px",
                          borderRadius: "6px",
                          whiteSpace: "nowrap",
                          fontSize: "12px",
                          fontWeight: 700,
                          display: "inline-block",
                        }}>
                        {item.groupId.length === 1 ? (
                          <>{item.groupId[0].type}</>
                        ) : (
                          <>{item.groupId?.length} Customer Groups </>
                        )}
                      </div>
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.gridBodyInner}
                    style={{ width: "136px" }}>
                    {(item?.price && `$${item?.price}`) || "-"}
                  </TableCell>
                  <TableCell
                    className={classes.gridBodyInner}
                    style={{ width: "176px" }}>
                    {item?.priceMethodType ? item?.priceSetting : "Fixed Price"}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    <span
                      style={{
                        color: "rgba(151, 151, 151, 1)",
                        backgroundColor: "rgba(151, 151, 151, 0.25)",
                        padding: "8px 12PX",
                        width: "46px",
                        height: "33px",
                        borderRadius: "6px",
                      }}>
                      N/A
                    </span>
                  </TableCell>
                  <TableCell
                    className={classes.gridBodyInner}
                    style={{ width: "207.57px" }}>
                    <div
                      style={{
                        display: "flex",
                      }}>
                      
                      <AvailabilityStatus
                        item={item}
                        availabilityPeriods={availabilityPeriodDD}
                      />
                      <div
                        style={{
                          marginLeft: "5px",
                        }}>
                        <GetValueByAvailabilityKey
                          item={item}
                          availabilityPeriods={allAvailableTimes}
                        />
                        <div style={{ whiteSpace: "nowrap" }}>
                          {item?.availabilityPeriod}
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.gridBodyInner}
                    style={{
                      textAlign: "center",
                      lineHeight: "unset",
                      width: "62.17px",
                    }}>
                    <span
                      onClick={() => {
                        setEditableRow([...editRow, item.id]);
                        if (isMassEditing) {
                          setMassEditableRows([...massEditRows, item]);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                      }}>
                      <EditIcon />
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
            {(listData.length <= 0 && !isNewProductPricing) && (
              <TableRow key='0' className='gridBodyRow'>
                <TableCell
                  colSpan='7'
                  className={classes.gridBodyInner}
                  style={{ textAlign: "center" }}>
                  <span className='emptyGridText'>
                    {selectedtTab === "Expired" ? (
                      "No expired price(s) yet!"
                    ) : (
                      <p className={classes.noPriceDes}>
                      No Pricing has been added to this Product.{" "}
                      <span
                        className={classes.noPriceBtn}
                        onClick={addPriceModal}>
                        {" Add New Pricing "}
                      </span>
                      to set pricing for customers.
                    </p>
                    )}
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ValidatorForm>
    </Paper>
  );
};
