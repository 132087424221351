const hours = [
  {
    time: "06:00 AM",
  },
  {
    time: "07:00 AM",
  },
  {
    time: "08:00 AM",
  },
  {
    time: "09:00 AM",
  },
  {
    time: "10:00 AM",
  },
  {
    time: "11:00 AM",
  },
  {
    time: "12:00 PM",
  },
  {
    time: "01:00 PM",
  },
  {
    time: "02:00 PM",
  },
  {
    time: "03:00 PM",
  },
  {
    time: "04:00 PM",
  },
  {
    time: "05:00 PM",
  },
  {
    time: "06:00 PM",
  },
  {
    time: "07:00 PM",
  },
  {
    time: "08:00 PM",
  },
  {
    time: "09:00 PM",
  },
  {
    time: "10:00 PM",
  },
  {
    time: "11:00 PM",
  },
  {
    time: "12:00 AM",
  },
];
const closingHours = [
  {
    time: "06:00 AM",
  },
  {
    time: "07:00 AM",
  },
  {
    time: "08:00 AM",
  },
  {
    time: "09:00 AM",
  },
  {
    time: "10:00 AM",
  },
  {
    time: "11:00 AM",
  },
  {
    time: "12:00 PM",
  },
  {
    time: "01:00 PM",
  },
  {
    time: "02:00 PM",
  },
  {
    time: "03:00 PM",
  },
  {
    time: "04:00 PM",
  },
  {
    time: "05:00 PM",
  },
  {
    time: "06:00 PM",
  },
  {
    time: "07:00 PM",
  },
  {
    time: "08:00 PM",
  },
  {
    time: "09:00 PM",
  },
  {
    time: "10:00 PM",
  },
  {
    time: "11:00 PM",
  },
  {
    time: "11:59 PM",
  },
];

export default hours;
export { closingHours };
