import { toast } from "react-toastify";
import { productInventoryApi } from "../product/productInventoryApi";
import { softwareUpdatesApi } from "./softwareUpdatesApi";
import { softwareUpdatesTypes } from "./softwareUpdatesTypes";


const onChange = (field, value) => {
    return dispatch => {
        dispatch({
            type: softwareUpdatesTypes.SOFTWAREUPDATES_ONCHANGE,
            payload: { prop: field, value: value },
        });
    };
};

const updateSelectedReleaseContacts = (field, value) => {
    return {
        type: softwareUpdatesTypes.UPDATE_SELECTED_RELEASE_CONTACTS,
        payload: { prop: { key: field, value: value } },
    };
};

const removeSelectedReleaseContacts = (field, value) => {
    return {
        type: softwareUpdatesTypes.ON_REMOVE_SELECTED_PORTAL_CONTACT,
        payload: { prop: { key: field, value: value } },
    };
};

const onSearchUpdateProduct = (value) => {
    return dispatch => {
        dispatch({
            type: softwareUpdatesTypes.SEARCH_UPDATEPRODUCT,
            payload: { prop: value },
        });
    };
};

const onUpdateBuildTab = (value) => {
    return dispatch => {
        dispatch({
            type: softwareUpdatesTypes.BUILD_UPDATES_TAB,
            payload: { prop: value },
        });
    };
};

const onSelectedReleaseFieldChange = (value) => {
    return dispatch => {
        dispatch({
            type: softwareUpdatesTypes.ON_CHANGE_SELECTED_RELEASE_FIELD,
            payload: { prop: value }
        });
    };
};

const onPoratalAndContactFieldChange = (value) => {
    return dispatch => {
        dispatch({
            type: softwareUpdatesTypes.ON_CHANGE_PORTAL_CONTACT_FIELD,
            payload: { prop: value }
        });
    };
};
const onSaveReleaseInfo = (releaseData) => {
    let isNew = true;
    if (releaseData.id) {
        isNew = false;
    }
    return async dispatch => {
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareUpdatesApi.addUpdateRelease(releaseData);
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedRelease', value: response.data } } });
            toast.success(isNew ? `Release ${releaseData.nameInternal} Added Successfully` : `Release ${releaseData.nameInternal} Updated Successfully`);
        } else {
            toast.error(response?.message ?? 'Something went wrong!');
        }
    };
};

const onSaveAddUpdateSRPortalandContacts = (portalData) => {
    return async dispatch => {
        const response = await softwareUpdatesApi.addUpdateSRPortalandContacts(portalData);
        if (response?.success) {
            toast.success('Software Release Requirements updated!');
            const portalsData = await softwareUpdatesApi.getSoftwareReleasePortalById(portalData.softwareReleaseId);
            if (portalsData?.success) {
                const portalData = portalsData.data.map(x => { return { id: x.portal.id, portalId: x.id, name: x.portal.name, description: x.portal.description } });
                dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleasePortal', value: portalData } } });
            }
        } else {
            toast.error(response?.message ?? 'Something went wrong!');
        }
    };
};

const onAddUpdateReleaseProducts = (productsData) => {
    const isUpdated = productsData.find(product => product.id !== 0);
    const releaseId = productsData[0]?.softwareReleaseId;
    return async dispatch => {
        const response = await softwareUpdatesApi.addUpdateReleaseProducts(productsData);
        if (response?.success) {
            toast.success(isUpdated ? 'Products updated successfully' : 'Products added to Release');
            const products = await softwareUpdatesApi.getSoftwareReleaseProducts({
                softwareReleaseId: releaseId, pagination: {
                    orderByKey: "id",
                    orderByVal: 1
                }
            });
            if (products?.success) {
                dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleaseProducts', value: products.data } } });
            }
        } else {
            toast.error(response?.message ?? 'Something went wrong!')
        }
    };
};

const onGetReleaseCompatibleProducts = () => {
    return async dispatch => {
        const response = await softwareUpdatesApi.getReleaseCompatibleProducts();
        if (response?.success) {
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'compatibleProductsList', value: response?.data ?? [] } } })
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'productsList', value: response.data } } })
        }
    };
};

const onGetNotificationsType = () => {
    return async dispatch => {
        const response = await softwareUpdatesApi.getNotificationsType();
        if (response?.success) {
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'notificationTypes', value: response?.data ?? [] } } });
        }
    };
};

const onAddUpdateSoftwareReleaseNotification = (notificationData) => {
    return async dispatch => {
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareUpdatesApi.addUpdateSoftwareReleaseNotification(notificationData);
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            toast.success(notificationData.id === 0 ? `New Notification Added Successfully` : `Notification Updated Successfully`);
            const notificationsData = await softwareUpdatesApi.getSoftwareReleaseNotifications(notificationData?.softwareReleaseId);
            if (notificationsData?.success) {
                dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleaseNotifications', value: notificationsData.data } } });
            }
        } else {
            toast.error(response?.message ?? 'Something went wrong!');
        }
    };
};

const onGetSoftwareReleaseById = (releaseId) => {
    return async dispatch => {
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareUpdatesApi.getSoftwareReleaseById(releaseId);
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            dispatch({
                type: softwareUpdatesTypes.ON_SOFTWARE_RELEASE_DATA, payload: {
                prop: { value: response?.data } } });
        } else {
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'errorMessage', value: response?.message } } });
        }
    };
};

const onGetSoftwareReleaseContacts = (releaseId) => {
    return async dispatch => {
        const response = await softwareUpdatesApi.getSoftwareContactsReleaseById(releaseId);
        if (response?.success) {
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleaseContacts', value: response?.data } } })
        }
    }
};

const onGetSoftwareReleasePortal = (releaseId) => {
    return async dispatch => {
        const response = await softwareUpdatesApi.getSoftwareReleasePortalById(releaseId);
        if (response?.success) {
            const portalData = response.data.map(x => { return { id: x.portal.id, portalId: x.id, name: x.portal.name, description: x.portal.description } });
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleasePortal', value: portalData } } });
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'portalIds', value: portalData.map(x => x.id) } } });
        }
    }
};

const onGetSoftwareReleaseProducts = (queryData) => {
    return async dispatch => {
        const response = await softwareUpdatesApi.getSoftwareReleaseProducts(queryData);
        if (response?.success) {
            const productsData = response.data.map(product => ({ ...product, value: product.productName, }))
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleaseProducts', value: productsData } } });
        }
    }
};

const onGetReleaseNotifications = (releaseId) => {
    return async dispatch => {
        const response = await softwareUpdatesApi.getSoftwareReleaseNotifications(releaseId);
        if (response?.success) {
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleaseNotifications', value: response.data } } });
        }
    };
};

const onResetSoftwareReleaseData = () => {
    return async dispatch => {
        dispatch({ type: softwareUpdatesTypes.RESET_SOFTWARE_RELEASE_DATA })
    };
}

const onGetSoftwareReleaseDetails = (releaseId) => {
    return async (dispatch) => {
        const response = await softwareUpdatesApi.getSoftwareReleaseDetails(
            releaseId
        );
        if (response?.success) {
            dispatch({
                type: softwareUpdatesTypes.ON_CHANGE,
                payload: {
                    prop: { key: "softwareReleaseDetials", value: response.data },
                },
            });
        }
    };
};

const onGetContactsForRelease = (customerType) => {
    return async (dispatch) => {
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: "isLoading", value: true } } });
        const response = await productInventoryApi.getEndCustomerGroups({
            CustomerTypeName: customerType,
        });
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: "isLoading", value: false } } });

        if (response?.success) {
            dispatch({
                type: softwareUpdatesTypes.ON_CHANGE,
                payload: { prop: { key: "customerContactData", value: response.data } },
            });
        }
    };
};

const onAddSoftwareReleaseDetails = (selectedData) => {
    return async dispatch => {
        const response = await softwareUpdatesApi.addSoftwareReleaseDetails(selectedData);
        if (response?.success) {
            toast.success(selectedData.id === 0 ? `Release Plan Added Successfully` : `Release Plan Updated Successfully`);
        } else {
            toast.error(response?.message ?? 'Something went wrong!');
        }
    }
};

const onGetAllReleases = (queryData) => {
    return async dispatch => {
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareUpdatesApi.getAllSoftwareReleases(queryData);
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedVersions', value: response.data.data } } });
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'paginationResponse', value: response.data.pagination } } });
        } else {
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedVersions', value: [] } } });
            dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'paginationResponse', value: {} } } });
            toast.error(response.message ?? 'Something went wrong!');
        }
    };
};

const onMarkReleaseFavoriteUnFavorite = (queryData) => {
    return async dispatch => {
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareUpdatesApi.markUnMarkFavouriteSoftwareRelease(queryData);
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            toast.success('Software Release Updated Successfully!');
            dispatch({ type: softwareUpdatesTypes.ON_RELEASE_FAVOURITE, payload: { prop: { ...queryData } } });
        } else {
            toast.error(response.message ?? 'Something went wrong!');
        }
    };
};

const onDeleteSoftwareRelease = (releaseId) => {
    return async dispatch => {
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareUpdatesApi.deleteSoftwareRelease(releaseId);
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            dispatch({ type: softwareUpdatesTypes.ON_DELETE_RELEASE_DATA, payload: { prop: { releaseId } } })
            toast.success('Software Release Deleted Successfully!');
        } else {
            toast.error(response.message ?? 'Something went wrong!');
        }
    };
};

const onDeleteSoftwareReleaseNotification = (notificationId, releaseId) => {
    return async dispatch => {
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: true } } });
        const response = await softwareUpdatesApi.deleteSoftwareReleaseNotifiacation(notificationId);
        dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'isLoading', value: false } } });
        if (response?.success) {
            toast.success('Software Release Notification Deleted Successfully!');
            const notificationData = await softwareUpdatesApi.getSoftwareReleaseNotifications(releaseId);
            if (notificationData?.success) {
                dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleaseNotifications', value: notificationData?.data ?? [] } } });
            } else {
                dispatch({ type: softwareUpdatesTypes.ON_CHANGE, payload: { prop: { key: 'selectedReleaseNotifications', value: [] } } });
            }
        } else {
            toast.error(response?.message ?? 'Something went wrong while deleting notification!');
        }
    };
}

export const softwareUpdateActions = {
    onChange: onChange,
    onSearchUpdateProduct: onSearchUpdateProduct,
    onUpdateBuildTab: onUpdateBuildTab,
    onSelectedReleaseFieldChange: onSelectedReleaseFieldChange,
    onPoratalAndContactFieldChange: onPoratalAndContactFieldChange,
    onSaveReleaseInfo: onSaveReleaseInfo,
    onGetReleaseCompatibleProducts: onGetReleaseCompatibleProducts,
    onGetContactsForRelease: onGetContactsForRelease,
    onGetNotificationsType: onGetNotificationsType,
    onAddUpdateSoftwareReleaseNotification: onAddUpdateSoftwareReleaseNotification,
    onGetReleaseNotifications: onGetReleaseNotifications,
    updateSelectedReleaseContacts: updateSelectedReleaseContacts,
    onGetSoftwareReleaseById: onGetSoftwareReleaseById,
    onGetSoftwareReleaseContacts: onGetSoftwareReleaseContacts,
    onGetSoftwareReleasePortal: onGetSoftwareReleasePortal,
    onGetSoftwareReleaseProducts: onGetSoftwareReleaseProducts,
    onSaveAddUpdateSRPortalandContacts: onSaveAddUpdateSRPortalandContacts,
    onAddUpdateReleaseProducts: onAddUpdateReleaseProducts,
    onAddSoftwareReleaseDetails: onAddSoftwareReleaseDetails,
    onGetSoftwareReleaseDetails: onGetSoftwareReleaseDetails,
    onResetSoftwareReleaseData: onResetSoftwareReleaseData,
    onGetAllReleases: onGetAllReleases,
    onDeleteSoftwareRelease: onDeleteSoftwareRelease,
    onMarkReleaseFavoriteUnFavorite: onMarkReleaseFavoriteUnFavorite,
    onDeleteSoftwareReleaseNotification: onDeleteSoftwareReleaseNotification,
    removeSelectedReleaseContacts:removeSelectedReleaseContacts,
};
