import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import AllContactTypes from "./AllContactTypes";
import { searchIcon } from "../../../../../resources/images";

const NewCompanyDialog = ({ contactList }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const getData = (query, type) => {
    if (query === "") {
      return type === "all"
        ? contactList
        : contactList?.filter(x => x?.generic.includes(type));
    } else {
      return type === "all"
        ? contactList?.filter(x =>
            x?.value.toUpperCase().includes(query.toUpperCase())
          )
        : contactList?.filter(
            x =>
              x?.generic.includes(type) &&
              x?.value.toUpperCase().includes(query.toUpperCase())
          );
    }
  };

  return (
    <div className='newContactTabsWrapper newContactWrapperMain'>
      <span className='filterMainSearch endUserSearchFilterMain'>
        <img src={searchIcon} alt='' className='searchIcon' />
        <input
          placeholder='Search by role or contact type'
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
          }}
        />
      </span>
      <TopTabNavigator
        tabs={[
          {
            id: "1",
            title: "All Contact Types",
            component: (
              <AllContactTypes contactList={getData(searchQuery, "all")} />
            ),
          },
          {
            id: "2",
            title: "Dealer",
            component: (
              <AllContactTypes contactList={getData(searchQuery, "Dealer")} />
            ),
          },
          {
            id: "3",
            title: "Distributor",
            component: (
              <AllContactTypes
                contactList={getData(searchQuery, "distributor")}
              />
            ),
          },
          {
            id: "4",
            title: "Sales Representative",
            component: (
              <AllContactTypes
                contactList={getData(searchQuery, "Sales Representative")}
              />
            ),
          },
          {
            id: "5",
            title: "OEM/Brand",
            component: (
              <AllContactTypes contactList={getData(searchQuery, "oem")} />
            ),
          },
        ]}
      />
    </div>
  );
};

export { NewCompanyDialog };
