import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { launches, mediaReleases, calendar } from "../../../resources/images";
import { launchesActions } from "../../../store/launches/launchesActions";
import { RoutesObj } from "../../../routes";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headingWrapper: {
    backgroundColor: "#E5F2FF !important",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    display: "flex",
    alignItems: "center",
  },
  svgIcon: {
    marginRight: "11px",
  },
  expanded: {
    backgroundColor: "rgba(24, 103, 185, 0.08)",
  },
  links: {
    flexDirection: "column",
    paddingBottom: "0",
  },
  boxes: {
    boxShadow: "none",
  },
  hyperLink: {
    marginLeft: "15px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#3699FF",
    },
  },
  activeTest: {
    color: "#3699FF",
  },
}));

export default function LunchesNav() {
  const classes = useStyles();
  const { panel1, panel2, panel3, panel4, panel5 } = useSelector(
    state => state.LaunchesReducer
  );
  const [expanded1, setExpanded1] = useState(panel1);
  const [expanded2, setExpanded2] = useState(panel2);
  const [expanded3, setExpanded3] = useState(panel3);
  const [expanded4, setExpanded4] = useState(panel4);
  const [expanded5, setExpanded5] = useState(panel5);

  const history = useHistory();
  const dispatch = useDispatch();

  const currentRoot = history ? history.location.pathname : null;
  useEffect(() => {
    if (
      currentRoot === RoutesObj.Launches.path ||
      currentRoot === RoutesObj.NewLaunch.path ||
      currentRoot === RoutesObj.UpdateLaunch.path ||
      currentRoot === "/upcoming-launches" ||
      currentRoot === "/past-launches" ||
      currentRoot === "/launch-categories" ||
      currentRoot === "/add-launch-categories"
    ) {
      dispatch(launchesActions.onChange("panel1", true));
      setExpanded1(true);
    }
    if (
      currentRoot === "/all-releases" ||
      currentRoot === "/upcoming-releases" ||
      currentRoot === "/past-releases" ||
      currentRoot === "/release-category" ||
      currentRoot === "/new-release-category" ||
      currentRoot === "/new-media-release" ||
      currentRoot === "/update-media-release"
    ) {
      dispatch(launchesActions.onChange("panel2", true));
      setExpanded2(true);
    }
    if (
      currentRoot === "/all-collections" ||
      currentRoot === "/collection-category" ||
      currentRoot === "/new-collection-category" ||
      currentRoot === "/new-collections"
    ) {
      setExpanded3(true);
      dispatch(launchesActions.onChange("panel3", true));
    }
    if (
      currentRoot === "/event-categories" ||
      currentRoot === "/new-category" ||
      currentRoot === "/dashboard-events" ||
      currentRoot === "/past-events" ||
      currentRoot === "/upcomming-events" ||
      currentRoot === "/new-event"
    ) {
      setExpanded4(true);
      dispatch(launchesActions.onChange("panel4", true));
    }
    if (currentRoot === "/location" || currentRoot === "/new-location") {
      setExpanded5(true);
      dispatch(launchesActions.onChange("panel5", true));
    }
  }, []);
  const handleChange = panel => (event, newExpanded) => {
    setExpanded5(newExpanded ? panel : false);
    dispatch(launchesActions.onChange("panel", panel));
  };

  const redirectUrl = () => {
    history.push("/location");
  };
  return (
    <div className="subNavWrapper">
      <h1>PR Planning</h1>
      <div className={classes.root}>
        <Accordion
          className={classes.boxes}
          expanded={expanded1}
          onChange={() => {
            setExpanded1(expanded1 ? false : true);
            dispatch(
              launchesActions.onChange("panel1", expanded1 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={
              currentRoot === RoutesObj.Launches.path ||
              currentRoot === RoutesObj.NewLaunch.path ||
              currentRoot === RoutesObj.UpdateLaunch.path ||
              currentRoot === "/upcoming-launches" ||
              currentRoot === "/past-launches" ||
              currentRoot === "/launch-categories" ||
              currentRoot === "/add-launch-categories"
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              <img src={launches} alt="launches" className="launchIcon" />
              Launches
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <NavLink
              to="/launches"
              className={`${classes.hyperLink} ${
                [
                  RoutesObj.Launches.path,
                  RoutesObj.NewLaunch.path,
                  RoutesObj.UpdateLaunch.path,
                ].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              All Launches
            </NavLink>
            <NavLink to="/upcoming-launches" className={classes.hyperLink}>
              Upcoming Launches
            </NavLink>
            <NavLink to="/past-launches" className={classes.hyperLink}>
              Past Launches
            </NavLink>
            <NavLink to="/launch-categories" className={classes.hyperLink}>
              Launches Categories
            </NavLink>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.boxes}
          expanded={expanded2}
          onChange={() => {
            setExpanded2(expanded2 ? false : true);
            dispatch(
              launchesActions.onChange("panel2", expanded2 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={
              currentRoot === "/all-releases" ||
              currentRoot === "/upcoming-releases" ||
              currentRoot === "/past-releases" ||
              currentRoot === "/release-category" ||
              currentRoot === "/new-release-category" ||
              currentRoot === "/new-media-release" ||
              currentRoot === "/update-media-release"
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              <img src={mediaReleases} alt="launches" className="releaseIcon" />
              Media Releases
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to="/all-releases"
              className={`${classes.hyperLink} ${
                currentRoot === "/all-releases" ||
                currentRoot === "/new-media-release" ||
                currentRoot === "/update-media-release"
                  ? classes.activeTest
                  : null
              }`}>
              All Releases
            </Link>
            <NavLink to="/upcoming-releases" className={classes.hyperLink}>
              Upcoming Releases
            </NavLink>
            <NavLink to="/past-releases" className={classes.hyperLink}>
              Past Releases
            </NavLink>
            <Link
              to="/release-category"
              className={`${classes.hyperLink} ${
                currentRoot === "/new-release-category" ||
                currentRoot === "/release-category"
                  ? classes.activeTest
                  : null
              }`}>
              Releases Categories
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.boxes}
          expanded={expanded3}
          onChange={() => {
            setExpanded3(expanded3 ? false : true);
            dispatch(
              launchesActions.onChange("panel3", expanded3 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className={
              currentRoot === "/all-collections" ||
              currentRoot === "/collection-category" ||
              currentRoot === "/new-collection-category" ||
              currentRoot === "/new-collections"
                ? classes.headingWrapper
                : null
            }>
            <Typography className={classes.heading}>
              <img src={mediaReleases} alt="launches" className="releaseIcon" />
              Collections
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to="/all-collections"
              className={`${classes.hyperLink} ${
                currentRoot === "/all-collections" ||
                currentRoot === "/new-collections"
                  ? classes.activeTest
                  : null
              }`}>
              All Collections
            </Link>
            <Link
              to="/collection-category"
              className={`${classes.hyperLink} ${
                currentRoot === "/collection-category" ||
                currentRoot === "/new-collection-category"
                  ? classes.activeTest
                  : null
              }`}>
              Collections Categories
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.boxes}
          expanded={expanded4}
          onChange={() => {
            setExpanded4(expanded4 ? false : true);
            dispatch(
              launchesActions.onChange("panel4", expanded4 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header">
            <Typography className={classes.heading}>
              <CalendarTodayIcon className={classes.svgIcon} />
              Events
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to="/dashboard-events"
              className={`${classes.hyperLink} ${
                currentRoot === "/dashboard-events" ||
                currentRoot === "/new-event"
                  ? classes.activeTest
                  : null
              }`}>
              All Events
            </Link>
            <Link to="/dashboard-events" className={classes.hyperLink}>
              Events Calendar
            </Link>
            <Link to="/upcomming-events" className={`${classes.hyperLink} ${
                currentRoot === "/upcomming-events"
                  ? classes.activeTest
                  : null
              }`}>
              Upcoming Events
            </Link>
            <Link to="/past-events" className={`${classes.hyperLink} ${
                currentRoot === "/past-events"
                  ? classes.activeTest
                  : null
              }`}>
              Past Events
            </Link>
            <Link
              to="/event-categories"
              className={`${classes.hyperLink} ${
                currentRoot === "/event-categories" ||
                currentRoot === "/new-category"
                  ? classes.activeTest
                  : null
              }`}>
              Events Categories
            </Link>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded5}
          onChange={() => {
            setExpanded5(expanded5 ? false : true);
            dispatch(
              launchesActions.onChange("panel5", expanded5 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            className={
              currentRoot === "/location" || currentRoot === "/new-location"
                ? classes.headingWrapper
                : null
            }
            onClick={() => redirectUrl()}>
            <Typography className={classes.heading}>
              <LocationOnIcon className={classes.svgIcon} />
              Location
            </Typography>
          </AccordionSummary>
        </Accordion>
      </div>
    </div>
  );
}
