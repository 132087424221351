import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionSummary, Button, FormControl, IconButton, TextField, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { GridFilterBar } from "./GridFilterBar";
import { AllProductsGrid } from "./AllProductsGrid";
import Dashboard from "../Dashbard";
import Loader from "../../../../components/common/Loader";
import { Pagination, ProductTabButtons } from "../../../../components/common";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import ProductInventoryFilter, { FilterAutoComplete, SelectedChiptItems, SelectedDateChip } from "./ProductInventoryFilter";
import { ExpandMore, MoreHoriz } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { DateCreated } from "../../../../config/Enums";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { useDebounce } from "../../../../utils/use-debounce";
import { FilterCreationDate } from "../EndUser";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { useDropdownValues } from "../../../../utils/use-dropdown-values";
import { endpoints } from "../../../../config/apiConfig";

const ProductInventory = () => {
  document.title = "OFish - All Products";

  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    categoryId:[],
    product: "",
    brand:"",
    status: [],   
    createdOnFrom:null,
    createdOnTo:null,
    brandId:[],
    launchId:[],
    launchDateFrom:null,
    launchDateTo:null,
    storeAvailability:[],
    productType:[],
    isSerialized:[],
  });
  const { debouncedValue: debouncedSearchTxt } = useDebounce(filters.product, 500);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "ProductName",
    orderVal: 0,
  });
  let filtersIndex = 0;
  const filtersLimit = 4;
  const indexReference = useRef(filtersIndex);
  const [showFilters, setShowFilter] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [showFilterCounter , setShowFilterCounter] = useState(0);
  const [brands, setBrands] = useState([]);
  const [relatedLaunches, setRelatedLaunches] = useState([]);

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      productInventoryActions.onGetAllProducts({
        pageNumber: page,
        noOfRecoreds:rowsPerPage,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
        filters: {
          ...filters,
          productType: filters.productType
          ? prductTypesDD?.filter(x => filters.productType.includes(x.id)).map(x => x.key)
          : []
        },
      })
    ).then(data => {
      setProducts(data?.data?.data || []);
      if (data?.success) {
        setPaginationResponse(data?.data?.pagination);
      } else {
        if (data?.message?.includes("No Data Found")) {
        } else toast.error("Something went wrong, Please try later.");
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setLoading(false);
    });
  }, [dispatch, order.orderByKey, order.orderVal, page, rowsPerPage, debouncedSearchTxt]);

  useEffect(() => {
    if(filtersCount){
      applyFilters();
    }else{
    loadData();
  }
    setSelectedProducts([])
  }, [dispatch, loadData, page]);

  const showFilter = () => {
    window.scroll(0, 0);
    // document.body.classList.add("bodyScroll");
    setIsFilter(true);
  };

  const hideFilter = () => {
    setIsFilter(false);
  };
  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllProductSubCategories(0)).then(
      (data) => setCategories(data?.data)
    );
    dispatch(launchesActions.onGetDropDownLaunches()).then(data => {
      if (data?.success && data?.data) {
        setRelatedLaunches(data?.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllBrands()).then(data =>{
     const updatedBrand = data?.data.map(item => ({
        id:item.brandId,
        value: item.brandName
      }))
      setBrands(updatedBrand)
    });
  }, [dispatch]);
  const statusOption = [
    { id: 0, name: "In Active" },
    { id: 1, name: "Active" },
  ]
  const storeAvailabilityOptions =[
    { id: 0, value: "Not Available" },
    { id: 1, value: "Available" },
  ]
  const serializedOptions = [
    { id: 0, value: "No" },
    { id: 1, value: "Yes, created once received" },
  ]
  const { dropdownValues: prductTypesDD } = useDropdownValues({
    url: endpoints.generic.getProductTypeDropdown,
  });
const filtersCount =
(filters.categoryId?.length ? filters.categoryId.length : 0) +
(filters.status?.length ? filters.status.length : 0) +
(filters.brandId?.length ? filters.brandId.length : 0) +
(filters.launchId?.length ? filters.launchId.length : 0) +
(filters.storeAvailability?.length ? filters.storeAvailability.length : 0) +
(filters.productType?.length ? filters.productType.length : 0) +
(filters.isSerialized?.length ? filters.isSerialized.length : 0) +
(filters.createdOnFrom ? 1 : 0) +
(filters.createdOnTo ? 1 : 0) +
(filters.launchDateFrom ? 1 : 0) +
(filters.launchDateTo ? 1 : 0);

indexReference.current = filtersIndex;

const applyFilters = ()=>{
  setLoading(true);
  setShowFilterCounter(filtersCount)
  hideFilter();
  dispatch(
    productInventoryActions.onGetAllProducts({
      pageNumber: page,
      noOfRecoreds:rowsPerPage,
      orderByKey: order.orderByKey,
      orderByVal: order.orderVal,
      filters: {
        ...filters,
        productType: filters.productType
        ? prductTypesDD?.filter(x => filters.productType.includes(x.id)).map(x => x.key)
        : []
      },
    })
  ).then(data => {
    setProducts(data?.data?.data || []);
    if (data?.success) {
      setPaginationResponse(data?.data?.pagination);
    } else {
      if (data?.message?.includes("No Data Found")) {
      } else toast.error("Something went wrong, Please try later.");
      setPaginationResponse({
        currentCount: 0,
        totalRecords: 0,
      });
    }
    setLoading(false);
  });
}
const onClearButtonPress = ()=>{
  setLoading(true);
  setPage(1);
  setRowsPerPage(10);
  setShowFilterCounter(0)
  setFilters({
    product: "",
    status: [],
    createdOnFrom:null,
    createdOnTo:null,
    brandId:[],
    launchId:[],
    launchDateFrom:null,
    launchDateTo:null,
    storeAvailability:[],
    productType:[],
    isSerialized:[],
  })
  dispatch(
    productInventoryActions.onGetAllProducts({
      pageNumber: page,
      noOfRecoreds: rowsPerPage,
      orderByKey: order.orderByKey,
      orderByVal: order.orderVal,
      filters: {
        product: "",
        categoryId: [],
        status: [],
        createdOnFrom:null,
        createdOnTo:null,
        brandId:[],
        launchId:[],
        launchDateFrom:null,
        launchDateTo:null,
        storeAvailability:[],
        productType:[],
        isSerialized:[],
      },
    })
  ).then(data => {
    setProducts(data?.data?.data || []);
    if (data?.success) {
      setPaginationResponse(data?.data?.pagination);
    } else {
      if (data?.message?.includes("No Data Found")) {
      } else toast.error("Something went wrong, Please try later.");
      setPaginationResponse({
        currentCount: 0,
        totalRecords: 0,
      });
    }
    setLoading(false);
  });
}

  const setStartDate = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      createdOnFrom: date,
    }));
  };

  const setEndDate = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      createdOnTo: date,
    }));
  };
  const setLaunchStartDate = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      launchDateFrom: date,
    }));
  };

  const setLaunchEndDate = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      launchDateTo: date,
    }));
  };

  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>All Products</h1>
          <Button
            color='primary'
            onClick={() => history.push("/new-product")}
            variant='contained'>
            New Product
          </Button>
        </div>

        <ProductTabButtons />

        <div className='gridBody contentBody'>
          {/* <GridFilterBar
            filterBy={filters}
            onFilterButtonPress={customFilters => setFilters(customFilters)}
            onClearButtonPress={() =>
              setFilters({
                product: "",
                categoryId: null,
                dateCreated: null,
                status: null,
              })
            }
          /> */}
          <ProductInventoryFilter
            onFilterButtonPress={customFilters => setFilters(customFilters)}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            filters={filters}
            setFilters={setFilters}
            searchKey={"product"}
            title={"Product Filters"}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            showFilter={showFilter}
            hideFilter={hideFilter}
            applyFilters={applyFilters}
            showFilterCounter={showFilterCounter}
          >
            <Accordion
              defaultExpanded
              id="end-user-filter-expandable"
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  <span>Active Filter(s)</span>
                  <span>
                    <Button
                      className="filterClearBtn filterClearBtnStyle"
                      variant="outlined"
                      onClick={() => {
                        hideFilter();
                        onClearButtonPress();
                      }}
                    >
                      Clear
                    </Button>
                  </span>
                </Typography>
              </AccordionSummary>
              <div style={{marginBottom:"10px"}}>
              {filtersCount <= 0 && (
              <div className={`filtersChips`}>No Active Filter(s)</div>
              )}
              </div>

              <SelectedDateChip
              startDate={filters.createdOnFrom}
              endDate={filters.createdOnTo}
              setStartDate={(date) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  createdOnFrom: date,
                }));
              }}
              setEndDate={(date) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  createdOnTo: date,
                }));
              }}
              chipLabel={"Creation Date"}
              />

              <SelectedDateChip
                startDate={filters.launchDateFrom}
                endDate={filters.launchDateTo}
                setStartDate={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    launchDateFrom: date,
                  }));
                }}
                setEndDate={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    launchDateTo: date,
                  }));
                }}
                chipLabel={"Launch Date"}
              />

              <SelectedChiptItems
                  selectedItems={filters.categoryId}
                  items={categories}
                  setFilters={setFilters}
                  filtersIndex={filtersIndex}
                  filtersLimit={filtersLimit}
                  showFilters={showFilters}
                  field={"categoryId"}
                  chipLabel={"Product"}
              />
              <SelectedChiptItems
                selectedItems={filters.status}
                items={[...statusOption.map(item => ({
                  ...item,
                  value: item.name
                }))]}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"status"}
                chipLabel={"Product Status"}
              />
                            {/* <SelectedChiptItems
                selectedItems={filters.brandId}
                items={brands}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"brandId"}
                chipLabel={"Product Brand"}
              /> */}
              <SelectedChiptItems
                selectedItems={filters.brandId}
                items={brands}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"brandId"}
                chipLabel={"Product Brand"}
              />
              <SelectedChiptItems
                selectedItems={filters.launchId}
                items={relatedLaunches}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"launchId"}
                chipLabel={"Product Launch"}
              />
              <SelectedChiptItems
                selectedItems={filters.storeAvailability}
                items={storeAvailabilityOptions}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"storeAvailability"}
                chipLabel={"Store Availability"}
              />
              <SelectedChiptItems
                selectedItems={filters.productType}
                items={prductTypesDD}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"productType"}
                chipLabel={"Product Type"}
              />
              <SelectedChiptItems
                selectedItems={filters.isSerialized}
                items={serializedOptions}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"isSerialized"}
                chipLabel={"Serialized"}
              />

              <div
                style={{
                  textAlign: "right",
                }}>
                {filtersCount > filtersLimit && (
                  <IconButton
                    color={"primary"}
                    onClick={() => setShowFilter(!showFilters)}>
                    <MoreHoriz />
                  </IconButton>
                )}
              </div>
            </Accordion>

              <div className='userDetailsSearchField inputWrapper filterSelect'>
              <div>
              <h4>Product Creation Date</h4>
              <FilterCreationDate
                StartDate={filters.createdOnFrom}
                setStartDate={setStartDate}
                EndDate={filters.createdOnTo}
                setEndDate={setEndDate}
              />
              </div>
              <div style={{marginBottom:"10px"}}>
              <h4>Product Launches</h4>
              <FilterCreationDate
                StartDate={filters.launchDateFrom}
                setStartDate={setLaunchStartDate}
                EndDate={filters.launchDateTo}
                setEndDate={setLaunchEndDate}
              />
              </div>
              <FilterAutoComplete
                value={categories?.filter(eg => filters?.categoryId?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    categoryId: value.map(cat => cat.id)
                  }));
                }}
                id='product-category-dd'
                options={categories}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Product Category"}
                selectedFilter={filters.categoryId?.length}
              />
                <FilterAutoComplete
                  value={statusOption.filter(eg => filters.status?.includes(eg.id))}
                  onChange={(event, value) => {
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      status: value.map(cat => cat.id)
                    }));
                  }}
                  id='product-status-dd'
                  options={statusOption}
                  getOptionLabel={option => option?.name || ""}
                  placeholder={"Product Status"}
                  selectedFilter={filters.status?.length}
                />
              <FilterAutoComplete
                value={brands?.filter(eg => filters.brandId?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    brandId: value.map(cat => cat.id)
                  }));
                }}
                id='product-brandId-dd'
                options={brands}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Product Brand"}
                selectedFilter={filters.brandId?.length}
              />
              <FilterAutoComplete
                value={relatedLaunches?.filter(eg => filters.launchId?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    launchId: value.map(cat => cat.id)
                  }));
                }}
                id='product-launch-dd'
                options={relatedLaunches}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Product Launch"}
                selectedFilter={filters.launchId?.length}
              />
              <FilterAutoComplete
                value={storeAvailabilityOptions?.filter(eg => filters.storeAvailability?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    storeAvailability: value.map(cat => cat.id)
                  }));
                }}
                id='product-launch-dd'
                options={storeAvailabilityOptions}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Store Availability"}
                selectedFilter={filters.storeAvailability?.length}
              />
            <FilterAutoComplete
              value={prductTypesDD?.filter(eg => filters.productType?.includes(eg.id))}
              onChange={(event, value) => {
                setFilters(prevFilters => ({
                  ...prevFilters,
                  productType: value.map(cat => cat.id)
                }));
              }}
              id='product-launch-dd'
              options={prductTypesDD}
              getOptionLabel={option => option?.value || ""}
              placeholder={"Product Type"}
              selectedFilter={filters.productType?.length}
            />
            <FilterAutoComplete
              value={serializedOptions?.filter(eg => filters.isSerialized?.includes(eg.id))}
              onChange={(event, value) => {
                setFilters(prevFilters => ({
                  ...prevFilters,
                  isSerialized: value.map(cat => cat.id)
                }));
              }}
              id='product-type-dd'
              options={serializedOptions}
              getOptionLabel={option => option?.value || ""}
              placeholder={"Serialized Product"}
              selectedFilter={filters.isSerialized?.length}
            />
            </div>

          </ProductInventoryFilter>
          <AllProductsGrid
            listData={products}
            loadData={() => loadData()}
            order={order}
            onOrderBy={setOrderBy}
            selectedProducts={selectedProducts}
            setSelectedProducts = {setSelectedProducts}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          {/* {products?.length <= 0 ? (
            <EmptyGridText
              text={"product"}
              buttonText={"Product"}
              onClick={() => history.push("/new-product")}
            />
          ) : (
            <Pagination
              recordCount={paginationResponse?.totalRecords}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )} */}
        </div>
      </div>
    </Dashboard>
  );
};

export { ProductInventory };
