import React from "react";
import { useHistory, useLocation } from "react-router";
import { Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import queryString from "query-string";
import Dashbard from "../Dashbard";
import { NewMediaReleaseForm } from "./NewMediaReleaseForm";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";

const NewMediaRelease = () => {
  const history = useHistory();
  const location = useLocation();
  const selectedRelease = queryString.parse(location.search);

  return (
    <Dashbard>
      <div className="body-content">
        <div className="contentHeading">
          <h1>{`Release Name`}</h1>
          <div
            style={{
              position: "absolute",
              top: "40px",
            }}>
            <Button
              color="primary"
              variant="text"
              onClick={() => history.push("/all-releases")}>
              <ArrowBackIos style={{ fontSize: "14px" }} />
              {`Back to All Releases`}
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="mediaRelTabe">
          <TopTabNavigator
            tabs={[
              {
                id: 1,
                title: "Basics",
                component: (
                  <NewMediaReleaseForm activeReleaseId={selectedRelease?.id} />
                ),
              },
              {
                id: 2,
                title: "Marketing",
                // component:
              },
              {
                id: 3,
                title: "Activity",
                // component:
              },
              {
                id: 4,
                title: "Notes",
                // component:
              },
            ]}
          />
          {/* <NewMediaReleaseForm activeReleaseId={selectedRelease?.id} /> */}
        </div>
      </div>
    </Dashbard>
  );
};

export { NewMediaRelease };
