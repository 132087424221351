import { endUserTypes } from "./endUserTypes";
import _ from "lodash";

const INITIAL_STATE = {
  selectedFFDevices: [],
  errorMessage: "",
  userOverview: "",
  allUserEnv: null,
  envIcons: null,
  envWithDeviceCount: [],
  userDevices: [],
  editUserName: "",
  deviceOverviewInfo: "",
  panel1: false,
  panel2: true,
  panel3: false,
  panel4: false,
  panel5: false,
  panel6: false,
  spaDeviceSetting: "",
  delearName: "",
  prchPrice: "",
  spaName: "",
  spaService: "",
  prchDate: "",
  spaTopsidePrchDate: "",
  spaTopsideInstallDate: "",
  spaDeviceInstallationDate: "",
  spaPurchaseLatitude: "",
  spaPurchaseLocation: "",
  spaPurchaseLongitude: "",
  topSidePurchaseLatitude: "",
  topSidePurchaseLocation: "",
  topSidePurchaseLongitude: "",
  controllerPurchaseLatitude: "",
  controllerPurchaseLocation: "",
  controllerPurchaseLongitude: "",
  spaDevicewarrantyStatus: 0,
  spaRegistrationStatus: 0,
  registrationNote:"",
  address: "",
  city: "",
  province: "",
  country: "",
  zipCode: "",
  googleLocations: [],
  foucusAddress: false,
  spaLat: null,
  spaLon: null,
  deviceCatId: null,
  deviceDelearId: null,
  spaTemperatureSettings: "",
  spaGlobalSettings: "",
  spaWaterTreatment: "",
  spaDeviceDetails: "",
  spaSettings: "",
  spaController: {},
  spaTopside: {},
  filterLife: [],
  filterLifeStats: {},
  waterLifeStats: {},
  chemicalTestStats: {},
  spaTopsideWarrantyStatus: 0,
  spaTopsideRegistrationStatus:0,
  spaTopsideRegistrationNote:"",
  spaTopSide:[],
  spaControllerPrchDate: "",
  spaControllerinstalldate: "",
  spaControllerRegistration: 0,
  spaControllerNote:"",
  spaControllerWarrantyStatus: 0,
  isBCListView: true,
  isEUListView: true,
};

const endUserReducer = (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case endUserTypes.ENDUSER_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };

    case endUserTypes.REGISTER_DEVICE_CHANGE:
      return {
        ...state,
        deviceOverviewInfo: {
          ...state.deviceOverviewInfo,
          [action.payload.prop]: action.payload.value,
        },
      };

    case endUserTypes.RESET_DEVICE_OVERVIEWINFO:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };

    case endUserTypes.SELECT_FF_DEVICES: {
      const newState = { ...state };
      const ffDevice = action.payload;
      const relExist = newState.selectedFFDevices.find(devs => {
        return devs.deviceId == ffDevice.value;
      });
      if (relExist && newState.selectedFFDevices.length > 0) {
        _.remove(newState.selectedFFDevices, devs => {
          return devs.deviceId == ffDevice.value;
        });
      } else {
        newState.selectedFFDevices.push({ deviceId: ffDevice.value });
      }
      return newState;
    }
    case endUserTypes.SET_SPA_LOCATION: {
      const newState = { ...state };
      const spaData = action.payload;
      if (spaData?.spaGlobalSettings?.length > 0) {
        newState.spaGlobalSettings = spaData.spaGlobalSettings[0];
      }
      if (spaData?.spaWaterTreatment) {
        newState.spaWaterTreatment = spaData.spaWaterTreatment;
      }
      if (spaData?.spaTemperatureSettings?.length > 0) {
        newState.spaTemperatureSettings = spaData.spaTemperatureSettings[0];
      }
      if (spaData?.spaFilterSettings?.length > 0) {
        newState.spaFilterSettings = spaData.spaFilterSettings[0];
      }
      if (spaData?.spaSettings?.length > 0) {
        newState.spaSettings = spaData.spaSettings[0];
        newState.prchPrice = spaData.spaSettings[0].price;
        newState.spaName = spaData.spaSettings[0].spaName;
        newState.spaService = spaData.spaSettings[0].spaService;
      }
      if (spaData?.spaDeviceDetails) {
        newState.spaDeviceDetails = spaData.spaDeviceDetails;
        newState.delearName = spaData.spaDeviceDetails.dealer;
        newState.prchDate = spaData.spaDeviceDetails.purchaseDate;
        newState.spaLat = spaData.spaDeviceDetails.installationLatitude;
        newState.spaLon = spaData.spaDeviceDetails.installationLongitude;
        newState.deviceCatId = spaData.spaDeviceDetails.categoryId;
        newState.deviceDelearId = spaData.spaDeviceDetails.dealerId;
        newState.spaDeviceInstallationDate =
          spaData.spaDeviceDetails.installationDate;
        newState.spaPurchaseLatitude =
          spaData.spaDeviceDetails.purchaseLatitude;
        newState.spaPurchaseLocation =
          spaData?.spaDeviceDetails.purchaseLocation;
        newState.spaPurchaseLongitude =
          spaData.spaDeviceDetails.purchaseLongitude;
        newState.spaDevicewarrantyStatus =
          spaData.spaDeviceDetails.warrantyStatus;
        newState.spaRegistrationStatus =
          spaData.spaDeviceDetails.registrationStatus;
        const values =
          spaData.spaDeviceDetails.installationLocation?.split(", ");

        if (values?.length > 0) {
          newState.address = values[0];

          newState.city = values[1] ? values[1] : "";
          if (values.length === 3) {
            newState.country = values[2] ? values[2] : "";
          } else if (values.length === 4) {
            newState.province = values[2] ? values[2] : "";
            newState.country = values[3] ? values[3] : "";
          } else {
            newState.zipCode = values[2] ? values[2] : "";
            newState.province = values[3] ? values[3] : "";
            newState.country = values[4] ? values[4] : "";
          }
        }
      }
      if(spaData?.spaTopside?.length > 0){
        newState.spaTopsideWarrantyStatus = spaData?.spaTopside[0].warrantyStatus;
        newState.spaTopsideRegistrationStatus = spaData?.spaTopside[0].registrationStatus;
        // newState.spaTopSide = spaData?.spaTopSide[0];
      }
      return newState;
    }
    case endUserTypes.SET_SELECT_LOCATION_SPA: {
      const newState = { ...state };
      const spaData = action.payload;
      if (spaData) {
        const values = spaData.formatted_address.split(", ");
        newState.address = values[0];
        if (spaData.geometry?.location) {
          newState.spaLat = spaData.geometry.location.lat;
          newState.spaLon = spaData.geometry.location.lng;
        }
        const adrValues = spaData.address_components;
        adrValues.forEach(adrItem => {
          if (adrItem.types?.length > 0 && adrItem.types[0] === "locality") {
            newState.city = adrItem.long_name;
          }
          if (
            adrItem.types?.length > 0 &&
            adrItem.types[0] === "administrative_area_level_1"
          ) {
            newState.province = adrItem.long_name;
          }
          if (adrItem.types?.length > 0 && adrItem.types[0] === "country") {
            newState.country = adrItem.long_name;
          }
          if (adrItem.types?.length > 0 && adrItem.types[0] === "postal_code") {
            newState.zipCode = adrItem.long_name;
          }
        });
      }
      return newState;
    }
    case endUserTypes.SPA_RESET_FIELDS: {
      const newState = { ...state };
      newState.spaDeviceSetting = "";
      newState.delearName = "";
      newState.prchPrice = "";
      newState.spaName = "";
      newState.spaService = "";
      newState.prchDate = "";
      newState.address = "";
      newState.city = "";
      newState.province = "";
      newState.country = "";
      newState.zipCode = "";
      newState.googleLocations = [];
      newState.foucusAddress = false;
      newState.spaLat = null;
      newState.spaLon = null;
      newState.deviceCatId = null;
      newState.deviceDelearId = null;
      newState.filterLife = [];
      return newState;
    }
    default:
      return state;
  }
};

export default endUserReducer;
