import React, { useRef, useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Radio } from "@material-ui/core";
import InputField from "../../../../../components/common/InputField";
import Textarea from "../../../../../components/common/Textarea";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { Add, Clear, Close, Search } from "@material-ui/icons";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import { toast } from "react-toastify";
import Loader from "../../../../../components/common/Loader";
import ConversationGroup from "../ConversationSetup/ConversationGroup";
import { launchesActions } from "../../../../../store/launches/launchesActions";

const data = [];

function SettingsTab() {
  const [conversationObj, setConversationObj] = useState({
    nameInternal: "",
    namePublic: "",
    shortDescription: "",
    categoryColor: "",
    campaignId: 0,
    isPublic: true,
    isGroupChat: true,
    isDeplayedInAdminInbox: true,
    permissions: [],
  });
  const [isLoading, setLoading] = useState(false);
  const [endUserGroup, setEndUserGroup] = useState([]);
  const [openGroupPopup, setOpenGroupPopup] = useState("");

  const [newsRoomVisibilityId, selectCategoryNewsRoomVisibility] = useState("");

  const dispatch = useDispatch();
  const { editChannelCampId } = useSelector(state => state.conversationReducer);

  useEffect(() => {
    var modal = {
      productId: 2,
      campaignId: editChannelCampId,
    };
    setLoading(true);
    dispatch(conversationAction.onGetCampaignSettingsByCampaignId(modal)).then(
      data => {
        setLoading(false);
        if (data?.responseMsg === "Success" && data?.responseData !== null) {
          let rec = {
            nameInternal: data?.responseData?.nameInternal,
            namePublic: data?.responseData?.namePublic,
            shortDescription: data?.responseData?.shortDescription,
            categoryColor: data?.responseData?.color,
            isPublic: data?.responseData?.isPublic,
            isGroupChat: data?.responseData?.isGroupChat,
            isDeplayedInAdminInbox: data?.responseData?.isDeplayedInAdminInbox,
            permissions: data?.responseData?.permissions,
          };
          setConversationObj(rec);
        }
      }
    );
  }, []);

  useEffect(() => {
    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      if (data && data.success) {
        const list = data?.data.map(x => {
          return {
            ...x,
            checked: false,
          };
        });
        setEndUserGroup(list);
      }
    });
  }, []);
  useEffect(() => {
    dispatch(launchesActions.onGetReleaseNewsRoomVisibilities()).then(res => {
      if (res && res.success && res.data && res.data.length > 0) {
        selectCategoryNewsRoomVisibility(res.data[0].id);
      }
    });
  }, []);

  const onBasicSaveButtonPress = () => {
    if (
      conversationObj.nameInternal.trim() !== "" &&
      conversationObj.namePublic.trim() !== ""
    ) {
      var modal = {
        productId: 2,
        campaignId: editChannelCampId,
        nameInternal: conversationObj.nameInternal,
        namePublic: conversationObj.namePublic,
        color: conversationObj.categoryColor,
        shortDescription: conversationObj.shortDescription,
        channels: [conversationObj.channels],
        isPublic: conversationObj.isPublic,
        isGroupChat: conversationObj.isGroupChat,
        isDeplayedInAdminInbox: conversationObj.isDeplayedInAdminInbox,
        permissions: conversationObj.permissions,
      };
      setLoading(true);
      dispatch(conversationAction.onAddUpdateCampaignSettings(modal)).then(
        data => {
          toast.success(data?.responseMsg);
          setLoading(false);
        }
      );
    } else {
      toast.error(
        `Please add ${
          conversationObj.nameInternal.trim() === ""
            ? "Conversation Type Name (Internal)"
            : conversationObj.namePublic.trim() === ""
            ? "Conversation Type Name (Public)"
            : ""
        }`
      );
    }
  };

  const deletePermissionName = (selectedPermission, item) => {
    const per = conversationObj.permissions.map(x => {
      if (x.name === selectedPermission) {
        return {
          name: x.name,
          values: x.values.filter(s => s !== item),
        };
      } else {
        return x;
      }
    });

    setConversationObj({
      ...conversationObj,
      permissions: per,
    });
  };

  return (
    <div className='conversationTypeInnerWrapper'>
      <h2 className='createConversationHeading'>
        Create a New Conversation Type
      </h2>
      <p className='createConversationDetial'>
        To create a new conversation type, enter basic details, permissions, and
        create status types that mark the status of the conversation.
      </p>
      <AccordionContainer
        title='Conversation Basics'
        formButton={
          <Button
            color='primary'
            variant='outlined'
            className='dashboardButtons'
            onClick={() => onBasicSaveButtonPress()}>
            Next
          </Button>
        }>
        <div className='customisationWrapper'>
          {isLoading ? <Loader /> : null}

          <ValidatorForm ref={useRef("form")} onSubmit={() => {}}>
            <Grid container spacing={3}>
              <Grid container item xs={12} spacing={3}>
                <Grid item md={4}>
                  <InputField
                    inputlabel='Conversation Type Name (Internal)*'
                    placeholder='Enter Conversation Type Name (Internal)'
                    value={conversationObj.nameInternal}
                    onChange={v =>
                      setConversationObj({
                        ...conversationObj,
                        nameInternal: v.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item md={4}>
                  <InputField
                    inputlabel='Conversation Type Name (Public)*'
                    placeholder='Enter Conversation Type Name (Public)'
                    value={conversationObj.namePublic}
                    onChange={v =>
                      setConversationObj({
                        ...conversationObj,
                        namePublic: v.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item md={4}>
                  <Grid container className='slugWrapper'>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{ alignItems: "center" }}>
                      <Grid item xs={12}>
                        <label className='slugLabel'>Category color</label>
                      </Grid>
                      <Grid item xs={12}>
                        <ul>
                          <li>
                            <button
                              className={
                                conversationObj.categoryColor === "#015dfe"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#015dfe",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#00d0ff"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#00d0ff",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#f9b900"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#f9b900",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#ff0092"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#ff0092",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#49b8ef"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#49b8ef",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#eb5757"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#eb5757",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#66de64"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#66de64",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#0a42e9"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#0a42e9",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#ef64b3"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#ef64b3",
                                });
                              }}
                            />
                            <button
                              className={
                                conversationObj.categoryColor === "#236681"
                                  ? "colorActive"
                                  : ""
                              }
                              onClick={() => {
                                setConversationObj({
                                  ...conversationObj,
                                  categoryColor: "#236681",
                                });
                              }}
                            />
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Textarea
                  textLabel='Step Description'
                  direction='(5000 characters max)'
                  placeholder='Manage your Aquatic AV Notification settings via e-mail, web, or within the AAV App.'
                  value={conversationObj.shortDescription}
                  onChange={v =>
                    setConversationObj({
                      ...conversationObj,
                      shortDescription: v.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item md={12} className='addChannelGroups'>
                <h3>Conversation Channels</h3>{" "}
                <Button
                  color='primary'
                  variant='outlined'
                  startIcon={<Add />}
                  onClick={() => {}}>
                  Add Channels
                </Button>
              </Grid>

              <Grid item md={12} className='channelLabels'>
                {data.map((item, index) => {
                  return (
                    <div className='collectionLabelsWrapper' key={index}>
                      <div className='collectionLabels'>
                        <span>
                          {item.label} <i>{item.category}</i>
                        </span>
                        <span className='deleteLabels' onClick={() => {}}>
                          <Clear className='collectionLabelDeleteBtn' />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item md={12}>
              <h3>Conversation Settings</h3>
              <Grid
                container
                item
                xs={12}
                spacing={3}
                className='settingsWrapper'>
                <Grid item md={4}>
                  <span className='settingTitle'>
                    Can visitors participate in this conversation type?
                  </span>
                  <i className='settingDesc'>{}</i>
                </Grid>
                <Grid item md={8} className='radioBtnWrapper'>
                  <span>
                    <Radio
                      checked={conversationObj.isPublic === true}
                      onChange={() => {
                        setConversationObj({
                          ...conversationObj,
                          isPublic: true,
                        });
                      }}
                    />
                    Yes - Visitors are allowed to chat with this Conversation
                    Type
                  </span>
                  <span>
                    <Radio
                      checked={conversationObj.isPublic === false}
                      onChange={() => {
                        setConversationObj({
                          ...conversationObj,
                          isPublic: false,
                        });
                      }}
                    />
                    No - Users must have an account/Logged-In to access this
                    Conversation Type
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                spacing={3}
                className='settingsWrapper'>
                <Grid item md={4}>
                  <span className='settingTitle'>
                    Does this conversation allow for more than two participants?
                  </span>
                  <i className='settingDesc'>{}</i>
                </Grid>
                <Grid item md={8} className='radioBtnWrapper'>
                  <span>
                    <Radio
                      checked={conversationObj.isGroupChat === true}
                      onChange={() => {
                        setConversationObj({
                          ...conversationObj,
                          isGroupChat: true,
                        });
                      }}
                    />
                    Yes - Group Chats are allowed between 3+ users (Visitors are
                    not able to join group conversations)
                  </span>
                  <span>
                    <Radio
                      checked={conversationObj.isGroupChat === false}
                      onChange={() => {
                        setConversationObj({
                          ...conversationObj,
                          isGroupChat: false,
                        });
                      }}
                    />
                    No - 1:1 Chats Between Two Users Only Allowed"
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                spacing={3}
                className='settingsWrapper'>
                <Grid item md={4}>
                  <span className='settingTitle'>
                    Hide Conversations Your Brand is Not In?
                  </span>
                  <i className='settingDesc'>
                    If you allow customers to message each other, such as family
                    members or B2B customers, you can hide their conversations
                    from your main inbox.
                  </i>
                </Grid>
                <Grid item md={8} className='radioBtnWrapper'>
                  <span>
                    <Radio
                      checked={conversationObj.isDeplayedInAdminInbox === true}
                      onChange={() => {
                        setConversationObj({
                          ...conversationObj,
                          isDeplayedInAdminInbox: true,
                        });
                      }}
                    />
                    Yes - Hide Conversations Not Involving My Brand (You can
                    still find them in your archive or on individual contact
                    profiles)
                  </span>
                  <span>
                    <Radio
                      checked={conversationObj.isDeplayedInAdminInbox === false}
                      onChange={() => {
                        setConversationObj({
                          ...conversationObj,
                          isDeplayedInAdminInbox: false,
                        });
                      }}
                    />
                    No - Show in my Conversations Inboxes
                  </span>
                </Grid>
              </Grid>
              {/* {settingData.map((item, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={3}
                    key={index}
                    className="settingsWrapper">
                    <Grid item md={4}>
                      <span className="settingTitle">{item.title}</span>
                      <i className="settingDesc">{item.desc}</i>
                    </Grid>
                    <Grid item md={8} className="radioBtnWrapper">
                      <span>
                        <Radio />
                        {item.yes}
                      </span>
                      <span>
                        <Radio />
                        {item.no}
                      </span>
                    </Grid>
                  </Grid>
                );
              })} */}
            </Grid>
          </ValidatorForm>
        </div>
      </AccordionContainer>

      <AccordionContainer title='Conversation Permissions'>
        <Grid item md={12}>
          <div className='customisationWrapper '>
            <h3>Conversation Permissions</h3>
            <Grid item md={12} className='addChannelGroups'>
              <h3>Who at Aquatic AV can start this conversation?</h3>{" "}
              <Button
                color='primary'
                variant='outlined'
                startIcon={<Add />}
                onClick={() => {
                  setOpenGroupPopup("string");
                }}>
                Add Users or Groups
              </Button>
              {openGroupPopup !== "" && (
                <ConversationGroup
                  userGroup={endUserGroup}
                  selectedValues={
                    conversationObj?.permissions?.find(
                      x => x.name == openGroupPopup
                    )?.values || []
                  }
                  closeGroup={() => {
                    setOpenGroupPopup("");
                  }}
                  onAdd={e => {
                    let per = conversationObj.permissions.map(x => {
                      if (x.name === openGroupPopup) {
                        return { name: x.name, values: e };
                      } else {
                        return x;
                      }
                    });
                    if (per.length === 0) {
                      per = [{ name: openGroupPopup, values: e }];
                    } else if (!per?.find(x => x.name === openGroupPopup)) {
                      per = [...per, { name: openGroupPopup, values: e }];
                    }
                    setConversationObj({
                      ...conversationObj,
                      permissions: per,
                    });

                    setOpenGroupPopup("");
                  }}
                />
              )}
            </Grid>

            <Grid item md={12} className='channelLabels'>
              {conversationObj?.permissions
                ?.find(x => x.name == "string")
                ?.values?.map((item, index) => {
                  return (
                    <div className='collectionLabelsWrapper' key={index}>
                      <div className='collectionLabels'>
                        <span>
                          {endUserGroup.find(x => x.id == item)?.value}{" "}
                          <i>{endUserGroup.find(x => x.id == item)?.generic}</i>
                        </span>
                        <span
                          className='deleteLabels'
                          onClick={() => {
                            deletePermissionName("string", item);
                          }}>
                          <Clear className='collectionLabelDeleteBtn' />
                        </span>
                      </div>
                    </div>
                  );
                })}
            </Grid>
            <div className='gap' />

            <Grid item md={12} className='addChannelGroups'>
              <h3>Aquatic AV can start conversations with</h3>{" "}
              <Button
                color='primary'
                variant='outlined'
                startIcon={<Add />}
                onClick={() => {
                  setOpenGroupPopup("AquaticStart");
                }}>
                Add Users or Groups
              </Button>
              {/* {withCon && (
                <ConversationGroup
                  userGroup={tempWithCon}
                  closeGroup={e => {
                    setWithCon(e);
                  }}
                  onChange={e => {
                    setTempWithCon(e);
                  }}
                />
              )} */}
            </Grid>

            <Grid item md={12} className='channelLabels'>
              {conversationObj?.permissions
                ?.find(x => x.name == "AquaticStart")
                ?.values?.map((item2, index) => {
                  return (
                    <div className='collectionLabelsWrapper' key={index}>
                      <div className='collectionLabels'>
                        <span>
                          {endUserGroup.find(x => x.id == item2)?.value}{" "}
                          <i>
                            {endUserGroup.find(x => x.id == item2)?.generic}
                          </i>
                          {/* {item2}{" "}
                          <i>
                            {endUserGroup.find(x => x.value == item2)?.generic}
                          </i> */}
                        </span>
                        <span
                          className='deleteLabels'
                          onClick={() => {
                            deletePermissionName("AquaticStart", item2);
                          }}>
                          <Clear className='collectionLabelDeleteBtn' />
                        </span>
                      </div>
                    </div>
                  );
                })}
            </Grid>
            <div className='gap' />
            <Grid item md={12} className='addChannelGroups'>
              <h3>Aquatic AV can receive new conversations from </h3>{" "}
              <Button
                color='primary'
                variant='outlined'
                startIcon={<Add />}
                onClick={() => {
                  setOpenGroupPopup("AquaticRecive");
                }}>
                Add Users or Groups
              </Button>
              {/* {fromCon && (
                <ConversationGroup
                  userGroup={tempFromCon}
                  closeGroup={e => {
                    setFromCon(e);
                  }}
                  onChange={e => {
                    setTempFromCon(e);
                  }}
                />
              )} */}
            </Grid>

            <Grid item md={12} className='channelLabels'>
              {conversationObj?.permissions
                ?.find(x => x.name == "AquaticRecive")
                ?.values?.map((item3, index) => {
                  return (
                    <div className='collectionLabelsWrapper' key={index}>
                      <div className='collectionLabels'>
                        <span>
                          {endUserGroup.find(x => x.id == item3)?.value}{" "}
                          <i>
                            {endUserGroup.find(x => x.id == item3)?.generic}
                          </i>
                          {/* {item3}{" "}
                          <i>
                            {endUserGroup.find(x => x.value === item3)?.generic}
                          </i> */}
                        </span>
                        <span
                          className='deleteLabels'
                          onClick={() => {
                            deletePermissionName("AquaticRecive", item3);
                          }}>
                          <Clear className='collectionLabelDeleteBtn' />
                        </span>
                      </div>
                    </div>
                  );
                })}
            </Grid>
          </div>
        </Grid>
      </AccordionContainer>

      <AccordionContainer title='Understanding Conversation Statuses'>
        <Grid item md={12}>
          <div className='conversationStatusWrapper '>
            <div className='gap' />

            <Grid
              container
              item
              xs={12}
              spacing={3}
              className='convStatusWrapper'>
              <Grid item md={3}>
                <Button className='btnOpen'>Open</Button>
              </Grid>
              <Grid item md={9}>
                Leaves Conversation Open for Replies OFish Default
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              spacing={3}
              className='convStatusWrapper'>
              <Grid item md={3}>
                <Button className='btnDraft'>Draft</Button>
              </Grid>
              <Grid item md={9}>
                Conversations Not Yet Sent OFish Default
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              spacing={3}
              className='convStatusWrapper'>
              <Grid item md={3}>
                <Button className='btnClosed'>Closed</Button>
              </Grid>
              <Grid item md={9}>
                Closes the Conversation + Prevents Customer Replies unless
                Re-Opened OFish Default
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              spacing={3}
              className='convStatusWrapper'>
              <Grid item md={3}>
                <Button className='btnClosed'>Closed &amp; Archived</Button>
              </Grid>
              <Grid item md={9}>
                Once a Conversation has been closed for 30 Days OFish will
                Archive it from your Main Inbox. It will still be visible on
                Customer Profiles OFish Default
              </Grid>
            </Grid>
          </div>
        </Grid>
      </AccordionContainer>
    </div>
  );
}

export default SettingsTab;
