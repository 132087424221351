import React, { useRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import { launchesActions } from "../../../store/launches/launchesActions";
import CloseIcon from "@material-ui/icons/Close";

const AddCategoryPopup = ({
  closePopup,
  openPopup,
  startLoading,
  endLoading,
  loadData,
  isEdit,
  editData,
}) => {
  const dispatch = useDispatch();
  const [planName, setPlanName] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    if (isEdit) {
      dispatch(
        launchesActions.onUpdateReleaseCategory(planName, editData.id)
      ).then((data) => {
        if (data && data.success) {
          closePopup();
          loadData();
        } else {
          endLoading();
        }
      });
    } else {
      dispatch(launchesActions.onAddReleaseCategory(planName)).then((data) => {
        if (data && data.success) {
          closePopup();
          loadData();
        } else {
          endLoading();
        }
      });
    }
  };
  useEffect(() => {
    if (isEdit) {
      setPlanName(editData.name);
    }
  }, []);
  return (
    <div>
      <Dialog
        open={openPopup}
        onClose={closePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">
            {isEdit ? "Update Category" : "New Category"}
            <CloseIcon className="closePopup" onClick={closePopup} />
          </DialogTitle>
          <DialogContent className="addLaunchPopupWrapper">
            <DialogContentText id="alert-dialog-description">
              <TextValidator
                id="planName"
                label="Enter a name"
                variant="outlined"
                name="planName"
                fullWidth
                validators={["required"]}
                errorMessages={["Field is required"]}
                value={planName}
                onChange={(v) => setPlanName(v.target.value)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="popupFooter addCatFooter">
            <Button color="primary" variant="contained" autoFocus type="submit">
              {isEdit ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};

export default AddCategoryPopup;
