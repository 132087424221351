import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as Edit } from "../../../../resources/images/Write.svg";
import { contactsTypesActions } from "../../../../store/contactTypes/contactTypesActions";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/common/Loader";
import {
  searchIcon,
  saerchFilter,
  gridIcon,
  listIcon,
} from "../../../../resources/images";
import { FilterIcon } from "../../LeftNav/SvgIcons";

export default function ContactTypes() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [contactGrid, setContactGrid] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(contactsTypesActions.onChange("dataField", []));
    dispatch(contactsTypesActions.onChange("dataModule", []));
    dispatch(contactsTypesActions.onChange("dataConnection", []));
    gridDataLoad();
  }, []);

  const gridDataLoad = () => {
    setLoading(true);
    const modal = {
      name: search,
      pagination: {
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      },
    };
    dispatch(contactsTypesActions.onGetAllContactTypes(modal)).then(data => {
      if (data?.data?.success) {
        setContactGrid(data?.data?.data?.data);
      }
      setLoading(false);
    });
  };

  return (
    <>
      <span className='contacTypeSearchWrapper'>
        <span>
          <img src={searchIcon} alt='' className='searchIcon' />
          <input
            type='text'
            placeholder='Seach Onboarding Workflows'
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <i
            onClick={() => {
              gridDataLoad();
            }}>
            {/* <img src={saerchFilter} alt='filter' /> */}
            <FilterIcon />
          </i>
        </span>
        <span className='recentUploads'>
          <i>
            <img src={gridIcon} alt='gridIcon' />
            <img src={listIcon} alt='listIcon' />
          </i>
          Recently Updated <i className='chevrondown'>&#10095;</i>
        </span>
      </span>
      {isLoading ? <Loader /> : null}
      <Grid container spacing={1} sx={{ width: "100%" }}>
        {contactGrid.map(item => {
          return (
            <Grid item xs={12} sm={12} md={4}>
              <div className='contactTypesGrid'>
                <div className='editSection'>
                  <Edit
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("/new-contact-types?id=" + item.id);
                    }}
                  />
                </div>
                <p className='contactTypeTitle'>
                  {item.nameSingularInternal}
                  {item?.status === 1 ? (
                    <>
                      <span style={{ color: "red" }}>*</span>
                      <span
                        style={{
                          marginLeft: "10px",
                          height: "15px",
                          width: "15px",
                          background: "#50CD89",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}></span>
                    </>
                  ) : (
                    <span
                      style={{
                        marginLeft: "10px",
                        height: "15px",
                        width: "15px",
                        background: "#FFC84A",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}></span>
                  )}
                </p>
                <p className='contactTypeCustomer'>{item.contactAreaName}</p>
                <div className='contactAmount'>
                  <p className='amount'>{item.contactCounts}</p>
                  <p className='contact'>Contacts</p>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
