import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import {
  ChartDownloadIcon,
  ChartAddIcon,
  ChartEditIcon,
} from "../SpaProduct/SvgIcons";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { InputField, Dropdown } from "../../../../components/common";
import { CancleIcon, TickIcon } from "../../LeftNav/SvgIcons";
import CSVDownloadButton from "./CSVDownloadButton";
import { toast } from "react-toastify";
import moment from "moment";

export const ChemicalTestTable = ({
  deviceId,
  isUpdated,
  setIsUpdated,
  arrangeChemicalHistoryWithDates
}) => {
  const [addNewItem, setAddNewItem] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const [chemicaTestingData, setChemicalTestingData] = useState([]);
  const [testDate, setTestDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [phLevel, setPhLevel] = useState(0);
  const [chlorineLevel, setChlorineLevel] = useState(0);
  const [alkalityLevel, setAlkalityLevel] = useState(0);
  const [waterClarity, setWaterClarity] = useState(0);
  const [addedChemicals, setAddedChemicals] = useState(true);

  const loadChemicalTestingData = () => {
    const payload = {
      deviceId,
      pagination: {
        pageSize: 1000,
        pageNo: 1,
        orderByKey: "Id",
        orderByVal: 0,
      },
    };
    dispatch(endUserActions.onGetSpaChemicalTestingList(payload)).then(
      (response) => {
        const formattedData = response?.data?.data?.map((item) => ({
          ...item,
          testDate: moment(item.testDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
        }));
        if (formattedData?.length) setChemicalTestingData(formattedData);
      }
    );
  };

  useEffect(() => {
    loadChemicalTestingData();
  }, []);

  const handleEdit = () => {
    setEditItem(selectedRow);
    setAlkalityLevel(selectedRow.alkalinity);
    setChlorineLevel(selectedRow.chlroine);
    setPhLevel(selectedRow.ph);
    setTestDate(selectedRow.testDate);
    setWaterClarity(selectedRow.waterClarity);
    setAddedChemicals(selectedRow.isChemicalAdded);
  };

  const handleAddUpdateFilter = () => {
    const modal = {
      id: editItem ? editItem?.id : 0,
      ph: phLevel,
      chlroine: chlorineLevel,
      alkalinity: alkalityLevel,
      testDate: moment(testDate).format('MM/DD/YYYY'),
      deviceId: deviceId,
      waterClarity: waterClarity,
      isChemicalsAdded: addedChemicals,
    };
    dispatch(endUserActions.onAddUpdateSPAChemicalTesting(modal)).then((data) => {
      if (data && data.success) {
        let message = "Chemical Test added successfully!";
        if (!addNewItem) {
          message = "Chemical Test updated successfully!";
        }
        toast.success(message);
        setIsUpdated(!isUpdated);
        loadChemicalTestingData();
        const currentDate = new Date().getDate();
        const year = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const endDateRequest = `${year}-${currentMonth}-${currentDate}T00:00:00.000Z`;
        dispatch(endUserActions.onGetChemicalTestingStats(deviceId));
        dispatch(
          endUserActions.onGetSpaChemicalHistoryWithDates({
            deviceId: deviceId,
            startDate: `${year}-01-01T00:00:00.000Z`,
            endDate: `${endDateRequest}`,
          })
        ).then((res) => {
          arrangeChemicalHistoryWithDates(res?.data ?? []);
        });
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
    setAddNewItem(false);
    setEditItem(null);
    setSelectedRow(null);
    setAlkalityLevel(0);
    setChlorineLevel(0);
    setPhLevel(0);
    setTestDate(moment(Date.now()).format("YYYY-MM-DD"));
    setWaterClarity(0);
    setAddedChemicals(true);
  };

  const cancleAddUpdate = () => {
    setAddNewItem(false)
    setEditItem(null);
    setSelectedRow(null);
    setAlkalityLevel(0);
    setChlorineLevel(0);
    setPhLevel(0);
    setTestDate(moment(Date.now()).format("YYYY-MM-DD"));
    setWaterClarity(0);
    setAddedChemicals(true);
  }

  const addNewChemicalTest = () => {
    setAddNewItem(true);
  }

  return (
    <Box className="chemicalTestTab">
      <div className="buttonsGroupSection">
        <div>
          <span>Your Tests</span>
        </div>
        <div className="buttonsGroup">
          <Button
            variant="contained"
            color="primary"
            className="chartBtns"
            startIcon={<ChartDownloadIcon />}
          >
            <CSVDownloadButton stockData={chemicaTestingData.map(x => {
              return {
                date: x.testDate,
                isChemicalAdded: x.isChemicalAdded ? "YES" : "NO",
                pH: x.ph,
                alkalinity: x.alkalinity,
                chlroine: x.chlroine,
                cleaned: x.waterClarity,
              };
            })} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="chartBtns"
            onClick={() => {
              if (selectedRow === null && editItem === null) {
                addNewChemicalTest();
              } else {
                return null;
              }
            }}
            startIcon={<ChartAddIcon />}
          >
            Add
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="chartBtns"
            disabled={!selectedRow}
            onClick={() => handleEdit() }
            startIcon={<ChartEditIcon />}
          >
            Edit
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} className="chemicaltableContainer">
        <Table aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeader">DATE</TableCell>
              <TableCell className="tableHeader" align="center">
                PH LEVEL
              </TableCell>
              <TableCell className="tableHeader" align="center">
                CHLORINE LEVEL
              </TableCell>
              <TableCell className="tableHeader" align="center">
                ALKALINTY LEVEL
              </TableCell>
              <TableCell className="tableHeader" align="center">
                WATER CLEARITY
              </TableCell>
              <TableCell className="tableHeader" align="right">
                ADDED CHEMICALS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addNewItem && (
              <TableRow>
                <TableCell>
                  <InputField
                    value={testDate}
                    type={"date"}
                    onChange={(e) =>{ 
                      if(e.target.value > moment(Date.now()).format("YYYY-MM-DD")){
                        return
                      } else {
                        setTestDate(e.target.value)
                      }}}
                  />
                </TableCell>
                <TableCell>
                  <InputField
                    value={phLevel}
                    type={"number"}
                    onChange={(e) => setPhLevel(parseFloat(e.target.value))}
                  />
                </TableCell>
                <TableCell>
                  <InputField
                    value={chlorineLevel}
                    type={"number"}
                    onChange={(e) => setChlorineLevel(parseFloat(e.target.value))}
                  />
                </TableCell>
                <TableCell>
                  <InputField
                    value={alkalityLevel}
                    type={"number"}
                    onChange={(e) => setAlkalityLevel(parseFloat(e.target.value))}
                  />
                </TableCell>
                <TableCell>
                  <InputField
                    value={waterClarity}
                    type={"number"}
                    onChange={(e) => setWaterClarity(parseFloat(e.target.value))}
                  />
                </TableCell>
                <TableCell>
                <Dropdown
                        selectlabel=""
                        placeholder=""
                        customMenuItems={[
                          { id: true, name: "Yes", value: "true" },
                          { id: false, name: "No", value: "false" },
                        ]}
                        value={addedChemicals}
                        handleChange={({ target }) => {
                          setAddedChemicals(target.value)
                        }}
                      />
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", gap: 10 }}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={cancleAddUpdate}
                    >
                      <CancleIcon />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleAddUpdateFilter}
                    >
                      <TickIcon />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            )}

            {chemicaTestingData.map((elm) => (
              <>
                <TableRow className={( elm.id === selectedRow?.id && !editItem) ? 'selectedRow' : ''} key={elm.id} onClick={() => setSelectedRow((prevSelectedRow) => {
                  if(!addNewItem && prevSelectedRow?.id !== elm.id) {
                    return elm;
                  } else {
                    return null;
                  }
                })}>
                    <TableCell
                      align="left"
                      className="tablerows"
                      component="th"
                      scope="row"
                    >
                      <Typography>{editItem?.id === elm.id ? <InputField
                    value={testDate}
                    type={"date"}
                    onChange={(e) => {
                      if(e.target.value > moment(Date.now()).format("YYYY-MM-DD")){
                        return
                      } else {
                        setTestDate(e.target.value)
                      }}
                      }
                  /> :  moment(elm.testDate).format("MMMM D, YYYY")}</Typography>
                    </TableCell>
                    <TableCell
                      className="tablerows"
                      align="center"
                      component="th"
                      scope="row"
                    >
                      <Typography>{editItem?.id === elm.id ? <InputField
                    value={phLevel}
                    type={"number"}
                    onChange={(e) => setPhLevel(parseFloat(e.target.value))}
                  /> :elm.ph}</Typography>
                    </TableCell>
                    <TableCell
                      className="tablerows"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <Typography>{editItem?.id === elm.id ? <InputField
                    value={chlorineLevel}
                    type={"number"}
                    onChange={(e) => setChlorineLevel(parseFloat(e.target.value))}
                  /> :elm.chlroine}</Typography>
                    </TableCell>
                    <TableCell
                      className="tablerows"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <Typography>{editItem?.id === elm.id ? <InputField
                    value={alkalityLevel}
                    type={"number"}
                    onChange={(e) => setAlkalityLevel(parseFloat(e.target.value))}
                  /> :elm.alkalinity}</Typography>
                    </TableCell>
                    <TableCell
                      className="tablerows"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <Typography>
                        {editItem?.id === elm.id ? <InputField
                        value={waterClarity}
                        type={"number"}
                        onChange={(e) => setWaterClarity(parseFloat(e.target.value))}
                        /> :elm.waterClarity}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="tablerows"
                      component="th"
                      scope="row"
                      width="112px"
                      align="center"
                    >
                      <Typography>
                        {editItem?.id === elm.id ? <Dropdown
                          selectlabel=""
                          placeholder=""
                          customMenuItems={[
                            { id: true, name: "Yes", value: "true" },
                            { id: false, name: "No", value: "false" },
                          ]}
                          value={addedChemicals}
                          handleChange={({ target }) => {
                            setAddedChemicals(target.value)
                          }}
                        /> :elm.isChemicalAdded ? "YES" : "NO"}
                      </Typography>
                    </TableCell>
                    {addNewItem && <TableCell></TableCell>}
                    {editItem?.id === elm.id && (<TableCell>
                      <div style={{ display: "flex", gap: 10 }}>
                        <span className="editModel"
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            cancleAddUpdate();
                          }}
                        >
                          <CancleIcon />
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleAddUpdateFilter()
                          }}
                        >
                          <TickIcon />
                        </span>
                      </div>
                    </TableCell>)}
                  </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
