import React from "react";

function CSVDownloadButton({ stockData ,isfilterCleaned}) {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  }

  function convertArrayOfObjectsToCSV(data) {
    if (data == null || !data.length) {
      return null;
    }

    const columnMappings = {
      date: "Date",
      waterAdded: "WATER REFILLED",
      gallonsReplaced: "GALLONS ADDED",
      cleaned: isfilterCleaned ? "FITER CLEANED" : "WATER CLEANED",
      pH: "PH LEVEL",
      alkalinity: "ALKALINTY LEVEL",
      isChemicalAdded: "ADDED CHEMICALS",
      chlroine: "CHLORINE LEVEL",
      testDate: "Date",
      waterClarity: "WATER CLEARITY",
      replaced:"FITER REPLACED", 
    };

    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    const originalKeys = Object.keys(data[0]);
    const mappedKeys = originalKeys.map(key => columnMappings[key]);

    let result = "";
    result += mappedKeys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      let ctr = 0;
      mappedKeys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        const originalKey = originalKeys[mappedKeys.indexOf(key)];
        const value = item[originalKey];

        // Handle date formatting and null values
        if (originalKey === "date" || originalKey === "testDate") {
          result += value ? formatDate(value) : '';
        } else {
          result += value !== null ? value : '';
        }

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(filename) {
    let csv = convertArrayOfObjectsToCSV(stockData);
    if (csv == null) return;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    const data = encodeURI(csv);

    const link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename || "export.csv");
    link.click();
  }

  return (
    <span onClick={() => downloadCSV("stock-data.csv")}>Download</span>
  );
}

export default CSVDownloadButton;
