import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Chip,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
} from "@material-ui/core";
import { ExpandMore, MoreHoriz } from "@material-ui/icons";
import { MdClear } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { useDebounce } from "../../../../../utils/use-debounce";
import { crossImg } from "../../../../../resources/images";
import { Autocomplete } from "@material-ui/lab";
import { FormControl, TextField } from "@material-ui/core";
import CustomCheckbox from "../../../../../components/common/checkbox/CustomCheckbox";

export const ChipItem = ({ chipLabel, chipValue, onDelete }) => {
    const classes = useStyless();

    return (
        <Chip
            className={classes.MuiChip}
            deleteIcon={<MdClear style={{ width: "16px", height: "16px" }} />}
            label={
                <span className={classes.MuiChip}>
                    <b>{chipLabel}: </b>
                    {chipValue}
                </span>
            }
            onDelete={onDelete}
        />
    );
};

const useStyless = makeStyles(theme => ({
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    MuiChip: {
        backgroundColor: "#CDE5FF",
        color: "#70808F",
        maxWidth: "99%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left",
        margin: "0px 5px 5px 0px",
    },
    chip: {
        margin: 2,
    },
    dropdownBg: {
        background: "#f1f1f1",
    },
    formControl: {
        minWidth: "100%",
        background: "transparent",
    },
    selectEmpty: {
        border: "1px solid #F3F6F9",
        backgroundColor: "#F3F6F9",
        borderRadius: "6px",
        "&:before": {
            borderBottom: "1px solid #F3F6F9",
            "&:hover": {
                borderBottom: "0 !important",
            },
        },
        "&:after": {
            borderBottom: "1px solid #F3F6F9",
        },

        MuiSvgIcon: {
            root: {
                fontSize: 20,
            },
        },
    },
    MuiSelect: {
        icon: {
            fontSize: "26px",
        },
        iconOutlined: {
            fontSize: "26px",
        },
    },
    MuiFilledInput: {
        root: {
            "&:hover": {
                backgroundColor: "#F3F6F9",
            },
        },
    },
    dropdownWrapper: {
        width: "650px",
        padding: "15px 37px 40px 37px",
        position: "fixed",
        boxShadow: "0px 0px 13px 0px #ccc",
        right: "95px",
        marginTop: "-55px",
    },
}));


const handleSubmit = e => {
    e.preventDefault();
};

const GridFilter = ({
    isFilter,
    setIsFilter,
    conversationChannels,
    setConversationChannels,
    conversationSizes,
    setConversationSizes,
    conversationTags,
    setConversationTags,
    assignedBoxes,
    setAssignedBoxes,
    convSizes,
    convTags,
    allConversationChannels,
    inboxTypeList,
    filtersCount,
    filterConversationSetting,
    onClearFilter,
    setPage,
    setRowsPerPage,
    setOrderBy,
    setIsFilterApplied,
}) => {
    let filtersIndex = 0;
    const filtersLimit = 4;
    const [showFilters, setShowFilter] = useState(false);

    const hideFilter = () => {
        // document.body.classList.remove("bodyScroll");
        setIsFilter(false);
    };

    return (
        <div>
            <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
                {isFilter && (
                    <div className='endUserFilterWrapper'>
                        <div className='endUserFilterInnerWrapper'>
                            <img
                                className='filterCrossImage'
                                src={crossImg}
                                alt='cross icon'
                                onClick={hideFilter}
                            />
                            <div className='formContainer'>
                                <h3>Conversation Setting Filter</h3>
                                <Accordion
                                    defaultExpanded
                                    id='end-user-filter-expandable'
                                    TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography>
                                            <span>Active Filter(s)</span>
                                            <span>
                                                <Button
                                                    className='filterClearBtn'
                                                    variant='outlined'
                                                    onClick={() => {
                                                        onClearFilter();
                                                        hideFilter();
                                                    }}
                                                >
                                                    Clear
                                                </Button>
                                            </span>
                                        </Typography>
                                    </AccordionSummary>
                                    <div>
                                        {!filtersCount && (
                                            <div className={`filtersChips`}>No Active Filter(s)</div>
                                        )}
                                    </div>
                                    {conversationSizes.id > 0 && <ChipItem
                                        chipLabel='Conversation Size'
                                        chipValue={conversationSizes.value}
                                        onDelete={() => setConversationSizes({})}
                                    />}

                                    {conversationTags?.status && <ChipItem
                                        chipLabel='Conversation Tags'
                                        chipValue={conversationTags.status}
                                        onDelete={() => setConversationTags({})}
                                    />}

                                    {assignedBoxes?.value && <ChipItem
                                        chipLabel='Assigned Inbox'
                                        chipValue={assignedBoxes.value}
                                        onDelete={() => setAssignedBoxes({})}
                                    />}

                                    {conversationChannels?.length > 0 &&
                                        conversationChannels.map(channel => {
                                            // filtersCount = filtersIndex + 1;
                                            return (
                                                (filtersCount <= filtersLimit || showFilters)) && (
                                                    <ChipItem
                                                        chipLabel='Conversation Channel'
                                                        chipValue={channel.name}
                                                        onDelete={() => setConversationChannels([
                                                            ...conversationChannels.filter(
                                                                x => x.name !== channel.name
                                                            ),
                                                        ])}
                                                    />
                                                )
                                        })}
                                    <div
                                        style={{
                                            textAlign: "right",
                                        }}>
                                        {filtersCount > filtersLimit && (
                                            <IconButton
                                                color={"primary"}
                                                onClick={() => setShowFilter(!showFilters)}>
                                                <MoreHoriz />
                                            </IconButton>
                                        )}
                                    </div>
                                </Accordion>
                                <div className='userDetailsSearchField inputWrapper filterSelect'>
                                    
                                        {/* <Grid className="conversationGroupSize">
                                            <input
                                             type="checkbox"
                                             checked={conversationSizes}
                                             onChange={() => setConversationSizes(!conversationSizes)}
                                             />
                                            <span>
                                                Is Conversation Group? 
                                            </span>
                                        </Grid> */}
                                    <FormControl className='ac-dd'>
                                        <Autocomplete
                                            value={conversationSizes}
                                            onChange={(event, value) => {
                                                setConversationSizes(value);
                                            }}
                                            id='product-category-dd'
                                            options={convSizes}
                                            getOptionLabel={option => option?.value || ""}
                                            renderInput={params => (
                                                <TextField {...params} placeholder={"Conversation Size"} />
                                            )}
                                        />
                                        {/* {SelectedPC?.length > 0 && (
                                            <span className='countLabel'>{SelectedPC?.length}</span>
                                        )} */}
                                    </FormControl>
                                    <FormControl className='ac-dd'>
                                        <Autocomplete
                                            value={conversationTags}
                                            onChange={(event, value) => {
                                                setConversationTags(value);
                                            }}
                                            id='product-category-dd'
                                            options={convTags}
                                            getOptionLabel={option => option?.status || ""}
                                            renderInput={params => (
                                                <TextField {...params} placeholder={"Conversation Tag"} />
                                            )}
                                        />
                                        {/* {SelectedPC?.length > 0 && (
                                            <span className='countLabel'>{SelectedPC?.length}</span>
                                        )} */}
                                    </FormControl>
                                    <FormControl className='ac-dd'>
                                        <Autocomplete
                                            value={assignedBoxes}
                                            onChange={(event, value) => {
                                                setAssignedBoxes(value);
                                            }}
                                            id='product-category-dd'
                                            options={inboxTypeList}
                                            getOptionLabel={option => option?.value || ""}
                                            renderInput={params => (
                                                <TextField {...params} placeholder={"Assigned Inbox"} />
                                            )}
                                        />
                                        {assignedBoxes?.length > 0 && (
                                            <span className='countLabel'>{assignedBoxes?.length}</span>
                                        )}
                                    </FormControl>
                                    <FormControl className='ac-dd'>
                                        <Autocomplete
                                            value={conversationChannels}
                                            onChange={(event, value) => {
                                                setConversationChannels(value);
                                            }}
                                            multiple
                                            filterSelectedOptions
                                            id='product-category-dd'
                                            options={allConversationChannels}
                                            getOptionLabel={option => option?.name || ""}
                                            renderInput={params => (
                                                <TextField {...params} placeholder={"Conversation Channel"} />
                                            )}
                                        />
                                        {conversationChannels?.length > 0 && (
                                            <span className='countLabel'>{conversationChannels?.length}</span>
                                        )}
                                    </FormControl>
                                </div>
                            </div>

                            <div className='endUserfilrerBtn'>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        setIsFilterApplied(true);
                                        setPage(1)
                                        setRowsPerPage(10)
                                        setOrderBy({
                                            orderByKey: "Id",
                                            orderVal: 0,
                                          })
                                        filterConversationSetting()
                                        hideFilter()
                                    }}>
                                    Apply Filter
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </ValidatorForm>
        </div>
    );
};
export { GridFilter };
