import React from "react";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { OpenInNew } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import moment from "moment";
import { toast } from "react-toastify";
import { LightenDarkenColor } from "lighten-darken-color";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "0 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeaderInnerCenter: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#E06145",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  launchedButtons: {
    color: "#18BC6D",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  newProductLaunch: {
    color: "#8254E3",
    background: "rgba(130, 84, 227, 0.25)",
    boxShadow: "none",
    "&:hover": {
      background: "rgba(130, 84, 227, 0.25)",
      boxShadow: "none",
    },
  },
}));

const EventData = [
  {
    name: "Pro Sport Series Available",
    date: "OCT 2 AT 8PM PST",
    category: "Product Launch",
  },
  {
    name: "Spa Show",
    date: "OCT 2 AT 8PM PST",
    category: "Trade Show",
  },
];

function EventsGrid({
  allEventsData,
  startLoading,
  endLoading,
  loadData,
  selectedRecord,
  isDelete,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const redirectUrl = () => {
    history.push("/new-event");
  };
  const deleteAllContacts = () => {
    var userIds = [];
    allEventsData.map(x => {
      if (x.checked) {
        userIds.push(x.id);
      }
    });
    if (userIds.length > 0) {
      startLoading();
      dispatch(launchesActions.onDeleteEvents(userIds)).then(data => {
        if (data.success) {
          toast.success("Event(s) deleted successfully");
          // if (searchBusCom) {
          //  // onPressFilterBtn(searchBusCom);
          // } else {
          loadData();
          // }
        } else {
          toast.error("Something went wrong, Please try latter.");
        }
      });
    } else {
      toast.error("Please select the event.");
    }
  };
  const getTime = date => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const isEventChecked =
    Array.isArray(allEventsData) &&
    allEventsData.some((eventData) => eventData?.checked === false);
  const onSelectAllEvents = () => {
    const isCollectionChecked =
      Array.isArray(allEventsData) &&
      allEventsData.some((collectionData) => collectionData?.checked === true);
    if (!isCollectionChecked) {
      allEventsData?.forEach((event) => {
        selectedRecord(event);
      });
    } else {
      allEventsData.forEach((event) => {
        selectedRecord({
          ...event,
          checked: true,
        });
      });
    }
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        <>
          <Grid item md={9} className={classes.deleteItems}>
            {isDelete && (
              <span
                className='gridDeleteItems'
                onClick={() => deleteAllContacts()}>
                <DeleteIcon /> Delete
              </span>
            )}
          </Grid>
        </>
      </Paper>

      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont}
              onClick={onSelectAllEvents}
              checked={!isEventChecked && allEventsData.length > 0}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              EVENT NAME
              <TableSortLabel
                active={order.orderByKey === "EventName"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "EventName",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              EVENT DATE
              <TableSortLabel
                active={order.orderByKey === "StartDate"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "StartDate",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PUBLISH DATE
              <TableSortLabel
                active={order.orderByKey === "StartDate"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "StartDate",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              CATEGORY{" "}
              <TableSortLabel
                active={order.orderByKey === "EventCategory"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "EventCategory",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              STATUS{" "}
              <TableSortLabel
                active={order.orderByKey === "Status"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Status",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEventsData.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridHeaderInner}>
                  <Checkbox
                    className={classes.checkBoxFont}
                    onClick={() => selectedRecord(item)}
                    checked={item.checked}
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.eventName}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {moment(item.startDate).format("MMM DD")} AT{" "}
                  {getTime(new Date(item.startDate))}-{" "}
                  {moment(item.endDate).format("MMM DD")} AT{" "}
                  {getTime(new Date(item.endDate))}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {moment(item.startDate).format("MMMM DD, YYYY")}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <span
                    style={{
                      backgroundColor: LightenDarkenColor(
                        item.categoryColor ? item.categoryColor : "#54b0e3",
                        140
                      ),
                      color: item.categoryColor
                        ? item.categoryColor
                        : "#54b0e3",
                      padding: "8px 15px",
                      borderRadius: "6px",
                      fontSize: "12px",
                      fontWeight: 700,
                    }}>
                    {item.eventCategory}
                  </span>
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <span
                    className={
                      item.status === "Planning"
                        ? "eventPendingBtn"
                        : "eventLaunchedBtn"
                    }>
                    {item.status}
                  </span>

                  {/* <span className="eventLaunchedBtn">Launched</span> */}
                </TableCell>
                <TableCell className='editButton'>
                  <span
                    onClick={() => {
                      history.push("/new-event?id=" + item.id);
                    }}>
                    <EditIcon />
                  </span>
                  <a href={"/events/category/" + item.urlSlug} target='_blank'>
                    <OpenInNew style={{ color: "rgb(54, 153, 255)" }} />
                  </a>
                </TableCell>
              </TableRow>
            );
          })}
          {allEventsData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t created any events. Start planning and
                  create a<Button onClick={redirectUrl}>New Event</Button>
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default EventsGrid;
