import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Typography, Dialog } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import {
  AssetPopupClose,
  FolderIcon,
  UploadIcon,
} from "../../../ofishadmin/views/LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { FiChevronDown } from "react-icons/fi";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { assetsActions } from "../../../store/assetsManagement/assetsActions";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    width: "400px",
    borderRadius: "20px !important",
  },
  papers: {
    "&.MuiAutocomplete-paper": {
      overflow: "unset",
      margin: "unset",
    },
    "& .MuiAutocomplete-listbox": {
      border: "1px solid #E1E3EA",
      borderBottomLeftRadius: "20px",
      borderBottomRightRadius: "20px",
      maxHeight: "200px !important",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      padding: 0,
    },
    "& .MuiAutocomplete-option": {
      '&[data-focus="true"]': {
        backgroundColor: "#F3F6F9",
      },
      "&:nth-last-child(2)": {
        marginBottom: 30,
      },

      background: "#fff",
      "&:has(div.viewFile)": {
        color: "#3699FF",
        fontSize: 12,
        fontWeight: "500",
        position: "fixed",
        width: "328px",
        zIndex: 111,
        bottom: 1,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
      },
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #E2E5E9",
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 16,
    paddingBottom: 16,
    background: "#00A3FF",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  newPricingTypo: {
    fontSize: "18px",
    fontFamily: "Roboto",
    fontWeight: 800,
    color: "#fff",
  },
  inenerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  content: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Roboto",
    paddingLeft: 16,
    color: "#3699FF",
    paddingRight: 19,
    letterSpacing: "-0.01em",
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  fileInput: {
    display: "none",
  },
  submitBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "22px",
    paddingBottom: "16px",
  },
  uploadeAssetSubmitButton: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: "6px",
    background: " #3699FF",
    width: "78px",
    height: "36px",
  },
  typography: {
    padding: theme.spacing(2),
  },
  deleteIcon: {
    "&.MuiIconButton-root": {
      padding: 0,
    },
  },
  dialog: {
    "&.MuiDialog-paper": {
      borderRadius: "20px",
    },
  },
}));

const MediaUploadPopup = ({
  currentFolder,
  closePopup,
  openPopup,
  startLoading,
  endLoading,
  loadData,
  parent,
  onParentId,
  uploadeFolderData,
  setView,
  setOpenGridViewOnUploadeAsset,
  openGridViewOnUploadeAsset,
  setIsMoveClicked,
  selectedFolder,
  setSelectedFolder,
  setOpenAssetActionUndo,
  setFolderData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const { parentId, mediaAssetsFolder, uploadeAssetMedia } = useSelector(
    (state) => state.AssetsReducer
  );

  const moveFilesIntoSelectedFolder = (e) => {
    e.preventDefault();
    if (uploadeAssetMedia !== "" && uploadeAssetMedia.length > 0) {
      startLoading();

      const uploadFilesList = Object.keys(uploadeAssetMedia).map(
        (k) => uploadeAssetMedia[k]
      );

      const assetParentId = parent === undefined ? parentId : parent;

      dispatch(assetsActions.uploadImage(uploadFilesList, assetParentId)).then(
        (data) => {
          dispatch(assetsActions.onChange("uploadeAssetMedia", []));
          endLoading();
          if (data[0]?.data.success) {
            setOpenAssetActionUndo("upload");
            if (parentId === null || parentId === 0) {
              loadData();
            }
              history.push('assets-media-library')
            // loadData();
            setOpenGridViewOnUploadeAsset("");
            setSelectedFolder("");
            // if (inputValue !== "") {
            //   setFolderData(inputValue);
            // }
            setTimeout(() => {
              setOpenAssetActionUndo("");
            }, 3000);
          } else {
            toast.error(data?.data.message);
          }
          closePopup();
        }
      );
    } else {
      toast.error("Please choose any Folder first");
    }
  };

  const selectFolderDestination = () => {
    setOpenGridViewOnUploadeAsset("openGridViewOnUploadeAsset");
    setView("grid");
    setIsMoveClicked(true);
    closePopup();
  };

  const uploadFileHandle = (e) => {
    const selectedFile = e.target.files;
    const imageList = Object.keys(selectedFile).map((k) => selectedFile[k]);
    const mergedArray = [...uploadeAssetMedia, ...imageList].reduce(
      (uniqueArray, currentItem) => {
        const isDuplicate = uniqueArray.some(
          (item) =>
            item.type === currentItem.type && item.name === currentItem.name
        );
        if (!isDuplicate) {
          uniqueArray.push(currentItem);
        }
        return uniqueArray;
      },
      []
    );
    dispatch(assetsActions.onChange("uploadeAssetMedia", mergedArray));
  };
  const reciptSelectedFolder = [...mediaAssetsFolder];

  // Add selectedFolder to reciptSelectedFolder if it's not already present
  const isFolderInList = reciptSelectedFolder.some(
    (folder) => folder.id === selectedFolder.id
  );
  if (selectedFolder && !isFolderInList) {
    reciptSelectedFolder.push(selectedFolder);
  }
  reciptSelectedFolder.push({
    name: "View All",
  });
  const handleDeleteFile = (assetName) => {
    if (uploadeAssetMedia && uploadeAssetMedia.length > 0) {
      const updatedSelectedAssets = uploadeAssetMedia.filter(
        (f) => f.name !== assetName
      );
      dispatch(
        assetsActions.onChange("uploadeAssetMedia", updatedSelectedAssets)
      );
    } else {
      toast.error("No assets to delete.");
    }
  };
  function truncate(uploadeAssetName) {
    if (uploadeAssetName && uploadeAssetName.length > 15) {
      return uploadeAssetName.substring(0, 13) + "...";
    }
    return uploadeAssetName;
  }

  return (
    // <div className={classes.dialog}>
    <Dialog
      open={openPopup}
      classes={{ paper: classes.dialog }}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.paper}>
        <div className={classes.header}>
          <div className={classes.inenerContent}>
            <Typography className={classes.newPricingTypo}>
              Upload Assets
            </Typography>
          </div>
          <IconButton
            onClick={() => {
              closePopup();
              dispatch(assetsActions.onChange("uploadeAssetMedia", []));
              setOpenGridViewOnUploadeAsset("");
              setSelectedFolder("");
            }}
            className={classes.closIcon}
          >
            <AssetPopupClose width="24" height="24" />
          </IconButton>
        </div>

        <div
          className="uploadAssetsContanier"
          onDragOver={(e) => e.preventDefault()}
        >
          <div className="reciptPdf">
            <Autocomplete
              disableClearable={true}
              classes={{ paper: classes.papers }}
              popupIcon={<FiChevronDown />}
              value={selectedFolder ?? inputValue}
              onChange={(event, newValue) => {
                onParentId(newValue?.id);
                setInputValue(newValue);
                setSelectedFolder(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              selectOnFocus
              clearOnBlur={false}
              handleHomeEndKeys
              options={reciptSelectedFolder}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name ?? "";
              }}
              renderOption={(option) =>
                option.name === "View All" ? (
                  <div
                    style={{ width: "328px" }}
                    onClick={() => selectFolderDestination()}
                  >
                    <div className="viewFile">{option.name ?? "--"}</div>
                  </div>
                ) : (
                  <div>
                    <FolderIcon /> {option.name ?? "--"}
                  </div>
                )
              }
              // renderOption={(option) => option.name ?? "--"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    selectedFolder ? selectedFolder.name : "Upload Location"
                  }
                  variant="outlined"
                />
              )}
            />
          </div>
          <div className="uplodadfileRoot">
            <input
              accept="application/pdf, image/jpeg, image/png, applicatoin/msword, video/mp4,
              .3gp, .docx, .doc"
              className={classes.fileInput}
              id="contained-button-file"
              type="file"
              multiple
              onChange={uploadFileHandle}
            />
            <label htmlFor="contained-button-file">
              <Button className="uploadFileBtn" component="span">
                <UploadIcon width={"64"} height={"64"} />
              </Button>
            </label>

            <div className="dragContent">
              Drag files here or click to upload
            </div>
          </div>
          <div className="mainSelectedContainer">
            {uploadeAssetMedia?.map((f) => (
              <div className="selectedAssests">
                <span className="fileLink">{truncate(f?.name)}</span>
                <span>
                  <IconButton
                    className={classes.deleteIcon}
                    onClick={() => handleDeleteFile(f?.name)}
                  >
                    <Delete />
                  </IconButton>
                </span>
              </div>
            ))}
          </div>

          <div className={classes.submitBtnContainer}>
            <Button
              className={classes.uploadeAssetSubmitButton}
              variant="contained"
              color="primary"
              onClick={moveFilesIntoSelectedFolder}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
    // </div>
  );
};

export default MediaUploadPopup;
