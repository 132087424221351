import React , { useState, useEffect }from "react";
import Grid from "@material-ui/core/Grid";
import CustomSwitch from "../../../../components/common/Switch/CustomSwitch";
import { Dropdown, InputField } from "../../../../components/common";
import { Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {  useSelector } from "react-redux";

export const SpaSettings = ({spaSetting, setSpaSetting, activeTab}) => {
  const { spaTemperatureSettings, spaFilterSettings ,spaDeviceDetails, } = useSelector(
    (state) => state.endUserReducer
  );

  const updateSpaSetting = () => {
    setSpaSetting({
      tempratureControl: spaTemperatureSettings?.temperatureControl,
      heatMode: spaTemperatureSettings?.heatMode,
      temperatureLock: spaTemperatureSettings?.isTemperatureLock,
      maintenanceLock: spaTemperatureSettings?.isMaintenanceLock,
      filter1Start: spaFilterSettings?.filter1Start,
      filter1Duration: spaFilterSettings?.filter1Duration,
      filter2Start: spaFilterSettings?.filter2Start,
      filter2Duration: spaFilterSettings?.filter2Duration,
    });
  };

  useEffect(() => {
    updateSpaSetting()
  }, [activeTab == 4]);
   

  const [startTimes, setStartTime] = useState([]);
  const [durationTimes, setDurationTime] = useState([]);

  const onChange = (field, value) => {
    setSpaSetting({...spaSetting, [field] : value})
  }

  const handleTempratureControl = (event, newTempratureControl) => {
    onChange("tempratureControl", newTempratureControl);
  };

  const handleHeatMode = (event, newHeatMode) => {
    onChange("heatMode" , newHeatMode); 
  };

  const handleTemperatureLock = (event) => {
    onChange("temperatureLock" , event.target.checked);
  };

  const handleMaintenanceLock = (event) => {
    onChange("maintenanceLock" , event.target.checked);
  };

  const handleFilter1StartTime = (event) => {
    onChange("filter1Start" , event.target.value);
  };

  const handlefilter1Duration = (event) => {
    onChange("filter1Duration" , event.target.value);
  };

  const handleFilter2StartTime = (event) => {
    onChange("filter2Start" , event.target.value);
  };

  const handlefilter2Duration = (event) => {
    onChange("filter2Duration" , event.target.value);
  };

  const createTimeArray = () => {
    const timeArray = [];
    const timeObjectArray = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const period = hour >= 12 ? 'pm' : 'am';
        const timeString = {
          id: `${formattedHour.toString().padStart(1, '')}:${minute.toString().padStart(2, '0')} ${period}`,
          name: `${formattedHour.toString().padStart(1, '')}:${minute.toString().padStart(2, '0')} ${period}`
        };
        timeArray.push(timeString);
      }
      const timeObject = {
        id: hour + 1,
        name: `${hour >= 9 ? hour + 1 : '0' + (hour + 1)} HR`
      };
      timeObjectArray.push(timeObject);
    }

    setStartTime([...timeArray]);
    setDurationTime([...timeObjectArray]);
  };

  useEffect(() => {
    createTimeArray();
  }, []);

  return (
    <div className="spaCollectionBody spaDeviceContentBody deviceDetailMainContainer">
      <Grid
        container
        item
        className="spaCollectionWrapper deviceDetailOverviewInner imageWithDetails"
      >
        <Grid item md={2}>
          <div className="spaDevicePicture">
            <div className="spaPicture devicedDtaidivmage ">
            <img
              className="devicedDtailImage"
              src={spaDeviceDetails?.productImage}
              alt="SPA Device"
            />
            </div>
          </div>
        </Grid>
        <Grid item md={9} className="deviceOverViewDetail">
          <div className="spaSettingGlobleHeading">Globle</div>
          <div className="deviceDetailOverView">
            <div className="deviceDetailView">
              <div className="label">Time</div>
              <div className="spaSettingsFeild">
                <InputField />
              </div>
            </div>
            <div className="deviceDetailView">
              <div className="label">Daily Lights Timer</div>
              <div>
                <CustomSwitch />
              </div>
            </div>
            <div className="spaSettingDivContanier">
              <div className="innerContainerSpa">
                <Typography variant="body1">Scenes</Typography>
                <div className="spaSettingsFeild">
                  <InputField />
                </div>
              </div>
              <div>
                <div className="spaSettingHeadings">Filter & Temperature</div>
                <div className="spaLable">
                  <div>
                    <Typography variant="body1">Temperature Control</Typography>
                  </div>
                  <div className="toggleSelector">
                    <ToggleButtonGroup
                      value={spaSetting?.tempratureControl}
                      exclusive
                      onChange={handleTempratureControl}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={1} aria-label="fhrenheit aligned">
                        Fahrenheit (ºF)
                      </ToggleButton>
                      <ToggleButton value={2} aria-label="centered">
                        Celsius (ºC)
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="spaSettingDivContanier">
              <div className="spaLable">
                <div>
                  <Typography variant="body1">Heat Mode</Typography>
                </div>
                <div className="toggleSelector">
                  <ToggleButtonGroup
                    value={spaSetting?.heatMode}
                    exclusive
                    onChange={handleHeatMode}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="Heating" aria-label="standard aligned">
                      Standard
                    </ToggleButton>
                    <ToggleButton value="Economy" aria-label="centered">
                      Economy
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </div>
            <div className="deviceDetailView">
              <div className="label">Temperature Lock</div>
              <div>
                <CustomSwitch
                  checked={spaSetting?.temperatureLock}
                  onClick={handleTemperatureLock}
                />
              </div>
            </div>
            <div className="deviceDetailView">
              <div className="label">Maintenance Lock</div>
              <div>
                <CustomSwitch checked={spaSetting?.maintenanceLock} 
                 onClick={handleMaintenanceLock}
                />
              </div>
            </div>

            <div className="deviceDetailView">
              <div className="label">Filter 1 Start</div>
              <div className="spaSettingsFeild">
                <Dropdown
                  value={spaSetting?.filter1Start}
                  customMenuItems={startTimes}
                  handleChange={handleFilter1StartTime}
                />
              </div>
            </div>
            <div className="deviceDetailView">
              <div className="label">Filter 1 Duration</div>
              <div className="spaSettingsFeild">
                <Dropdown
                 value={spaSetting.filter1Duration}
                  customMenuItems={durationTimes}
                  handleChange={handlefilter1Duration}
                />
              </div>
            </div>

            <div className="deviceDetailView">
              <div className="label">Filter 2 Start</div>
              <div className="spaSettingsFeild">
                <Dropdown
                  value={spaSetting.filter2Start}
                  customMenuItems={startTimes}
                  handleChange={handleFilter2StartTime}
                />
              </div>
            </div>
            <div className="spaSettingDivContanier">
              <div className="innerContainerSpa">
                <div className="label">Filter 2 Duration</div>
                <div className="spaSettingsFeild">
                  <Dropdown
                   value={spaSetting.filter2Duration}
                    customMenuItems={durationTimes}
                    handleChange={handlefilter2Duration}
                  />
                </div>
              </div>
              <div>
                <div className="spaSettingHeadings">Spa Access</div>
                <div className="personList">
                  <div className="label">Family & Friends</div>
                  <div className="listItem">
                    <div>Person Name 1</div>
                    <div>Person Name 2</div>
                    <div>Person Name 3</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="spaSettingDivContanier">
              <div>
                <div className="personListItem">
                  <div className="label">Dealer & Service</div>
                  <div className="listItem">
                    <div>Person Name 1</div>
                    <div>Person Name 2</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="spaSettingDivContanier spaSettingDivContanierExtra">
              <div>
                <div className="personListItem">
                  <div className="label">Devices</div>
                  <div className="listItem">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      Device Name 1
                    </div>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      Device Name
                    </div>
                    <span
                      style={{
                        color: "#3699FF",
                        fontSize: 14,
                        fontWeight: 700,
                        cursor: "pointer",
                      }}
                    >
                      Edit in Contact Profile
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="spaSettingDivContanier">
              <div>
                <div className="spaSettingHeadings">Gateway</div>
                <div className="personList">
                  <div className="label">Last Connection</div>
                  <div className="listItem">--/--/-- --:-- --</div>
                </div>
              </div>
            </div>
            <div className="spaSettingDivContanier">
              <div className="personList">
                <div className="label">Mac Address</div>
                <div className="listItem">----</div>
              </div>
            </div>
            <div className="spaSettingDivContanier">
              <div className="personList">
                <div className="label">Spa Model</div>
                <div className="listItem">--</div>
              </div>
            </div>
            <div className="spaSettingDivContanier spaSettingDivContanierExtra">
              <div className="personList">
                <div className="label">Software/Firmware Version</div>
                <span
                  style={{
                    color: "#3699FF",
                    fontSize: 14,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                >
                  Check For Update{" "}
                  <span
                    style={{
                      color: "#181C33",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    --
                  </span>
                </span>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
