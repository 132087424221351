import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OutsideClickHandler from "react-outside-click-handler";

const eStatuses = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "Inactive",
  },
  {
    id: 4,
    name: "Draft",
  },
];

const CustomDropdown = ({ value, onChange }) => {
  const [isSelectType, setIsSelectType] = useState("");
  const [isSelectDropdown, setIsSelectDropdown] = useState(false);

  useEffect(() => {
    const slctedOption = eStatuses.find(es => es.id == value);
    if (slctedOption) {
      setIsSelectType(slctedOption?.name);
    }
  }, [value]);

  return (
    <div className='selectDropdownWrapper'>
      <OutsideClickHandler onOutsideClick={() => setIsSelectDropdown(false)}>
        <i
          className={`${
            isSelectType === "Active"
              ? "activeSelect"
              : "" || isSelectType === "Inactive"
              ? "inactiveSelect"
              : "" || isSelectType === "Draft"
              ? "draftSelect"
              : "emptySelect"
          } selectDropdownInnerWrapper activeSelect`}
          onClick={() => setIsSelectDropdown(!isSelectDropdown)}>
          {isSelectType === "" ? "Select" : isSelectType}{" "}
          <ExpandMoreIcon fontSize='medium' />
        </i>
        {isSelectDropdown && (
          <ul className='dropdown'>
            {eStatuses.map((item, index) => {
              return (
                <li
                  key={item.id}
                  onClick={() => {
                    setIsSelectType(item.name);
                    setIsSelectDropdown(false);
                    onChange(item);
                  }}>
                  {item.name}
                </li>
              );
            })}
          </ul>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export { CustomDropdown };
