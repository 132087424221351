import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import { ChartDownloadIcon,ChartAddIcon,ChartEditIcon } from "../SpaProduct/SvgIcons";
import { WaterLifeBanner } from "./WaterLifeBanner";
import { useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import CSVDownloadButton from "./CSVDownloadButton";
import { CancleIcon, TickIcon } from "../../LeftNav/SvgIcons";
import { InputField, Dropdown } from "../../../../components/common";
import { toast } from "react-toastify";
import moment from "moment";

export const WaterLife = ({ deviceId }) => {
  const [waterLifeData, setWaterLifeData] = useState([]);
  const [waterStats, setWaterStats] = useState(null);
  const [addNewItem, setAddNewItem] = useState(false);
  const [date, setDate] = useState();
  const [waterRefiled, setWaterRefiled] = useState();
  const [gallonsAdded, setGallonsAdded] = useState();
  const [waterCleaned, setWaterCleaned] = useState()
  const [selectedRow, setSelectedRow] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const dispatch  = useDispatch();
  const loadWaterLifeData = () => {
    dispatch(endUserActions.onGetAllWaterLife(deviceId))
    .then((response) => {
      const formattedData = response?.data?.map((item) => ({
        ...item,
        date: moment(item.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
      }));
      if(formattedData?.length) setWaterLifeData(formattedData);
    });
  };

  const loadWaterLifeStats = () => {
    dispatch(endUserActions.onGetWaterLifeStats(deviceId))
    .then(data => setWaterStats(data?.data));
  };

  useEffect(() => {
    loadWaterLifeData();
    loadWaterLifeStats();
  }, []);

  const handleEdit = () => {
    setEditItem(selectedRow);
    setDate(selectedRow?.date == null ? "" : selectedRow?.date );
    setWaterRefiled (selectedRow?.waterAdded);
    setGallonsAdded (selectedRow?.gallonsReplaced);
  };

    const handleAddUpdateWaterLife = () => {
    const modal = {
      id: editItem ? editItem?.id : 0,
      testDate: date,
      waterAdded: waterRefiled,
      gallonsReplaced: gallonsAdded,
      waterCleaned: waterCleaned,
      deviceId: deviceId
    }
    dispatch(
      endUserActions
        .onAddUpdateWaterLife(modal)
    ).then((data) => {
      if (data && data.success) {
        toast.success("Water Life added successfully");
        loadWaterLifeData();
        loadWaterLifeStats();
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    })
    setAddNewItem(false)
    setEditItem(null);
    setSelectedRow(null);
  };

  const addNewFilter = () =>  {
    setDate(moment(Date.now()).format("YYYY-MM-DD"))
     setWaterRefiled(0)
     setGallonsAdded(0)
   setWaterCleaned(true)
    setAddNewItem(true);
  }

  return (
    <>
      <div className="waterTreatment">
        <div className="spacingAdjust"></div>
        <WaterLifeBanner waterLifeStats={waterStats} />
        <Box className="chemicalTestTab">
          <div className="buttonsGroupSection">
            <div>
              <span>Your Filters</span>
            </div>
            <div className="buttonsGroup">
              <Button
                variant="contained"
                color="primary"
                className="chartBtns"
                startIcon={<ChartDownloadIcon />}
              >
                <CSVDownloadButton stockData={waterLifeData.map(x => {
                  return {
                    date: x.date,
                    waterAdded: x.waterAdded > 0 ? "Yes" : "No",
                    gallonsReplaced: x.gallonsReplaced,
                    cleaned: x.cleaned ? "YES" : "NO",
                  };
                })} />
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="chartBtns"
                onClick={() => {
                  if (selectedRow === null && editItem === null) {
                    addNewFilter()
                  } else {
                    return null;
                  }
                }}
                startIcon={<ChartAddIcon />}
              >
                Add
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="chartBtns"
                disabled={!selectedRow}
                onClick={() => handleEdit()}
                startIcon={<ChartEditIcon />}
              >
                Edit
              </Button>
            </div>
          </div>
          <TableContainer component={Paper} className="chemicaltableContainer">
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="tableHeader">
                    DATE
                  </TableCell>
                  <TableCell className="tableHeader" align="center">
                    WATER REFILLED
                  </TableCell>
                  <TableCell className="tableHeader" align="center">
                    GALLONS ADDED
                  </TableCell>
                  <TableCell className="tableHeader" align="right">
                    WATER CLEANED
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addNewItem && (
                  <TableRow>
                    <TableCell>
                      <InputField
                        value={date}
                        type={"date"}
                        onChange={(e) => {
                          if(e.target.value > moment(Date.now()).format("YYYY-MM-DD")){
                            return
                          } else {
                            setDate(e.target.value)
                          }}}
                         
                      />
                    </TableCell>
                    <TableCell>
                      <InputField
                        value={waterRefiled}
                        type={"number"}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value > -1) {
                            setWaterRefiled(e.target.value)
                          } else {
                            return;
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <InputField
                        value={gallonsAdded}
                        type={"number"}
                        onChange={(e) => {
                          if(e.target.value > -1) {
                            setGallonsAdded(e.target.value)
                          } else {
                            return;
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                        <InputField
                          min={0}
                          value={waterCleaned}
                          type={"number"}
                          onChange={(e) => {
                            if(e.target.value > -1) {
                              setWaterCleaned(e.target.value)
                            } else {
                              return;
                            }
                          }}
                        />
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", gap: 10 }}>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setAddNewItem(false)}
                        >
                          <CancleIcon />
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleAddUpdateWaterLife}
                        >
                          <TickIcon />
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {waterLifeData?.map((elm) => (
                  <TableRow
                    key={elm.id}
                    className={
                      elm.id === selectedRow?.id && !editItem
                        ? "selectedRow"
                        : ""
                    }
                    onClick={() =>
                      setSelectedRow((prevSelectedRow) => {
                        if (!addNewItem) {
                          if (prevSelectedRow?.id === elm.id) {
                            return null;
                          } else {
                            return elm;
                          }
                        } else {
                          return null;
                        }
                      })
                    }
                  >
                    <TableCell
                      className="tablerows"
                      component="th"
                      align="left"
                      scope="row"
                    >
                      <Typography>
                        {" "}
                        {editItem?.id === elm.id ? (
                          <InputField
                            value={date}
                            type={"date"}
                            onChange={(e) =>{
                              if(e.target.value > moment(Date.now()).format("YYYY-MM-DD")){
                                return
                              } else {
                                setDate(e.target.value)
                              }}}
                          />
                        ) : (
                          moment(elm.date).format("MMMM D, YYYY")
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="tablerows"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {" "}
                      {editItem?.id === elm.id ? (
                        <InputField
                        min={0}
                        value={waterRefiled}
                        type={"number"}
                        onChange={(e) => {
                          if(e.target.value > -1) {
                            setWaterRefiled(e.target.value)
                          } else {
                            return;
                          }
                          
                        }}
                      />
                      ) : (
                        <Typography>
                          {elm.waterAdded > 0 ? "YES" : "NO"}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      className="tablerows"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {editItem?.id === elm.id ? (
                        <Typography>
                          <InputField
                            value={gallonsAdded}
                            type={"number"}
                            onChange={(e) => {
                              if(e.target.value > -1) {
                                setGallonsAdded(e.target.value);
                              } else {
                                return;
                              }
                            }}
                          />
                        </Typography>
                      ) : (
                        <Typography>{elm.gallonsReplaced}</Typography>
                      )}
                    </TableCell>
                    <TableCell
                      className="tablerows"
                      component="th"
                      scope="row"
                      align="center"
                      width="99px"
                    >
                      {" "}
                      {editItem?.id === elm.id ? (
                        <InputField
                        min={0}
                        value={waterCleaned}
                        type={"number"}
                        onChange={(e) => {
                          if(e.target.value > -1) {
                            setWaterCleaned(e.target.value)
                          } else {
                            return;
                          }
                        }}
                      />
                      ) : (
                        <Typography>
                          {elm.waterCleaned > 0 ? "YES" : "NO"}
                        </Typography>
                      )}
                    </TableCell>
                    {editItem?.id === elm.id && (
                      <TableCell>
                        <div style={{ display: "flex", gap: 10 }}>
                          <span
                            className="editModel"
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setEditItem(null);
                              setSelectedRow(null);
                            }}
                          >
                            <CancleIcon />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleAddUpdateWaterLife();
                            }}
                          >
                            <TickIcon />
                          </span>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </>
  );
};
