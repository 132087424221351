import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputField from "../../../../components/common/InputField";
import { Button, Checkbox } from "@material-ui/core";
import { RedCross, TickMark } from "../../LeftNav/SvgIcons";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function FamilyNFriendForm({
  setIsEdit,
  devicesData,
  editItem,
  userId,
  loadFFGridData,
  startLoading,
  stopLoading,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedFFDevices } = useSelector(state => state.endUserReducer);
  const [userType, setUserType] = useState(1);
  const [cntctName, setCntctName] = useState("");

  useEffect(() => {
    if (editItem) {
      addExistDeviceIds();
    } else {
      dispatch(endUserActions.onChange("selectedFFDevices", []));
    }
  }, [editItem]);

  const addExistDeviceIds = () => {
    setCntctName(editItem?.name);
    setUserType(editItem?.contactType);
    const existingDevices = [];
    const existDeviceIds = editItem?.friendAndFamilyContactDevices;
    if (existDeviceIds.length > 0) {
      _.forEach(existDeviceIds, function (devId) {
        existingDevices.push({ deviceId: devId.deviceId });
      });
      dispatch(endUserActions.onChange("selectedFFDevices", existingDevices));
    }
  };

  const selectFFDevice = value => {
    dispatch(endUserActions.onSelectFFDevice(parseInt(value.id)));
  };

  const checkRelCategory = itemId => {
    const relExist = selectedFFDevices.find(devId => {
      return devId.deviceId == itemId;
    });
    if (relExist) {
      return true;
    } else {
      return false;
    }
  };

  const handleFFSubmit = e => {
    e.preventDefault();
    onAddUpdateFrndAndFam();
  };

  const onAddUpdateFrndAndFam = () => {
    let ffPayload = {
      name: cntctName,
      contactType: userType,
      userId: userId,
      friendAndFamilyContactDevices: selectedFFDevices,
    };
    if (editItem) {
      ffPayload.id = editItem.id;
    }
    startLoading();
    dispatch(endUserActions.onAddFrndAndFmly(ffPayload)).then(data => {
      stopLoading();
      if (data?.success) {
        setIsEdit();
        loadFFGridData();
      } else {
        toast.error(data?.message);
      }
    });
  };

  return (
    <div className='friendsFamilyWrapper'>
      <ValidatorForm ref={React.useRef("ffForm")} onSubmit={handleFFSubmit}>
        <>
          <Grid
            item
            xs={12}
            className={classes.gridWrapper}
            style={{ position: "relative" }}>
            <div className='formInnerWrapper'>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputField
                    placeholder='Enter Contact Name'
                    inputlabel='Contact Name'
                    className={classes.gridWrapper}
                    name='contactfirstname'
                    value={cntctName}
                    onChange={v => setCntctName(v.target.value)}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className='inputWrapper contactInputWrapper'>
                    <FormControl className={classes.formControl}>
                      <label>{"User Type"}</label>
                      <Select
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        variant={"outlined"}
                        value={userType}
                        onChange={e => {
                          setUserType(e.target.value);
                        }}>
                        <MenuItem value={1}>Family</MenuItem>
                        <MenuItem value={2}>Friend</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <div className='gap' />
              <Grid item xs={4} className='familynFriendLabel'>
                <h2 className='innerHeading'>Product Access</h2>
                <i>Choose what features this person will have access to:</i>
                <label />
              </Grid>
              <Grid item xs={3} className='friendsFamilyList'>
                <div className='addressCheckbox'>
                  {devicesData?.map((item, index) => {
                    return (
                      <span key={index} className='companyAddressCheckbox'>
                        <Checkbox
                          checked={checkRelCategory(parseInt(item.id))}
                          onChange={() => selectFFDevice(item)}
                        />
                        {item.name}
                      </span>
                    );
                  })}
                </div>
              </Grid>
            </div>
          </Grid>
          <span className='thumbIcon tickMarkCross friendsFamilyActions'>
            <span onClick={() => setIsEdit(false)}>
              <RedCross />
            </span>
            <Button type='submit'>
              <TickMark />
            </Button>
          </span>
        </>
      </ValidatorForm>
    </div>
  );
}

export default FamilyNFriendForm;
