import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import EmptyTextarea from "../../../../../components/common/Textarea";
import { Button } from "@material-ui/core";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { useDispatch } from "react-redux";
import { locatorWidgetActions } from "../../../../../store/locatorWidget/locatorWidgetActions";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  textArea: {
    minHeight: "212px",
    background: "#FFFFFF",
  },
  selectBtn: {
    width: "100px",
    float: "right",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function Embed({ startLoading, stopLoading }) {
  const dispatch = useDispatch();
  const [id, setId] = useState(1);
  const [embedCode, setEmbedCode] = useState("");
  const [isError, setIsError] = useState(false);

  const loadData = () => {
    startLoading();
    dispatch(locatorWidgetActions.onGetEmbedCode(id)).then((cnt) => {
      if (cnt && cnt.success && cnt.data) {
        setId(cnt.data.locatorWidgetId);
        setEmbedCode(cnt.data.embedCode);
      } else {
        toast.error(cnt?.message);
      }
      stopLoading();
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onUpdateSettings = () => {
    startLoading();
    dispatch(locatorWidgetActions.onSaveEmbedCode(id, embedCode)).then(
      (data) => {
        if (data && data.success) {
          toast.success("Embed code successfully updated");
        } else {
          toast.error(data?.message);
        }
        stopLoading();
      }
    );
  };

  const classes = useStyles();
  return (
    <div className="accordianOuterWrapper">
      <AccordionContainer title="Embed Code">
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridWrapper}>
              <EmptyTextarea
                maxRows={10}
                className={classes.textArea}
                value={embedCode}
                placeholder="Embed Code"
                onChange={(e) => setEmbedCode(e.target.value)}
                textLabel=""
                // textSubtitle=""
              />
            </Grid>

            <Grid item xs={12} className={classes.gridWrapper}>
              {/* <Button
                variant="outlined"
                color="primary"
                className={classes.selectBtn}
                onClick={(e) => copyEmbedCode(e.target.value)}
              >
                Copy
              </Button> */}
            </Grid>
          </Grid>
        </div>
        <span className="saveChangesBtn">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onUpdateSettings()}
          >
            Save Changes
          </Button>
        </span>
      </AccordionContainer>
    </div>
  );
}
