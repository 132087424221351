import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TextField,
} from "@material-ui/core";
import { GridFilterBarBusinessCustomers } from "../components";
import { Pagination } from "../../../../../components/common";
import {
  Delete,
  EditIcon,
  FilterIcon,
  GridListIcon,
  TableGrid,
} from "../../../LeftNav/SvgIcons";
import { searchIcon } from "../../../../../resources/images";
import { useHistory, useParams } from "react-router-dom";
import { RoutesObj } from "../../../../../routes";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { useDebounce } from "../../../../../utils/use-debounce";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../../../components/common/Loader";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  filterContainer: {
    padding: "29px 0px 10px",
  },
  girdHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const BlockedLocationGrid = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { debouncedValue } = useDebounce(search, 500);
  const [isLoading, setLoading] = useState(false);
  const [locList, setLocList] = useState([]);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [order, setOrderBy] = useState({
    orderByKey: "Id",
    orderVal: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.BusinessCustomer.path,
      companyId: value.companyId,
      search: `id=${value.companyId}`,
      state: { scrollTo: ".businessContacts" },
    });
  };
  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(contactsActions.onGetBlockedBusinessLocation(3)).then(data => {
      if (debouncedValue !== "") {
        const filteredBlockedCompany = data.data.filter(
          x => x.name?.toLowercase() === debouncedValue?.toLowercase()
        );
        setLocList(filteredBlockedCompany);
      } else {
        setLocList(data?.data);
      }
      setLoading(false);
    });
  }, [dispatch, debouncedValue, page, rowsPerPage, order.key, order.val]);

  const deleteLocation = id => {
    setLoading(true);
    dispatch(contactsActions.onDeleteCompanyLocations(id)).then(data => {
      setLoading(false);
      if (data?.success) {
        loadData();
        toast.success("Location deleted");
      } else {
        toast.error(data?.message);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, [dispatch, loadData, page, rowsPerPage]);

  return (
    <div className='gridBody contentBody' style={{ width: "100%" }}>
      {isLoading ? <Loader /> : null}
      {/* <Grid container spacing={2} className={classes.filterContainer}>
                <Grid item md={11} className='filterMainSearch endUserSearchFilterMain'>
                    <img src={searchIcon} alt='' className='searchIcon' />
                    <TextField
                        placeholder='Search'
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <span
                    className='endUserSearchFilter'
                onClick={() => setSearch(search)}
                >
                    <FilterIcon />
                </span>
                </Grid>

                <Grid item md={1} className='blockEndUserBtn gridFiltersButtons'>
                    <TableGrid />
                    <GridListIcon />
                </Grid>
            </Grid> */}

      <div className={classes.girdHeading}>
        <h2 className='gridInnerHeading'>Blocked Dealers</h2>
        {/* <span className='gridPagination'>
          <i>
            {page}-{rowsPerPage} of&nbsp;
            {paginationResponse?.totalRecords}
          </i>
        </span> */}
      </div>

      <div className='gap' />

      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              Location Nickname
              {/* <TableSortLabel
                            active={order.orderByKey === "CompanyName"}
                            direction={order.orderVal === 1 ? "asc" : "desc"}
                            onClick={() =>
                                onOrderBy({
                                orderByKey: "CompanyName",
                                orderVal: order.orderVal === 0 ? 1 : 0,
                                })
                            }
                            /> */}
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Company Name
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Address
              {/* <TableSortLabel
                                active={order.orderByKey === "TotalUsers"}
                                direction={order.orderVal === 1 ? "asc" : "desc"}
                                onClick={() =>
                                    onOrderBy({
                                    orderByKey: "TotalUsers",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                    })
                                }
                                /> */}
            </TableCell>
            <TableCell
              className={
                (classes.gridHeaderInner, "quickLinkWrapper quickLinkHeader")
              }>
              Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locList?.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow endUserRow'>
                <TableCell className={classes.gridBodyInner}>
                  {item.name}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.companyName ? item.companyName : "--"}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.fullAddress}
                </TableCell>

                <TableCell
                  className={(classes.gridBodyInner, "quickLinkWrapper")}>
                  <div className='gridActions'>
                    <span onClick={() => redirectUrl(item)}>
                      <EditIcon />
                    </span>

                    <span onClick={() => deleteLocation(item.id)}>
                      <Delete />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {locList?.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>No Delears Blocked!</span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* <Pagination
                recordCount={paginationResponse?.totalRecords}
                page={page - 1}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            /> */}
    </div>
  );
};

export default BlockedLocationGrid;
