import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { first, last, uniq } from "lodash";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Grid } from "@material-ui/core";
import queryString from "query-string";
import InputField from "../../../../components/common/InputField";
import Textarea from "../../../../components/common/Textarea";
import Loader from "../../../../components/common/Loader";
import countriesPhone from "../../../../resources/phoneCode";
import { star, starOutlineYellow } from "../../../../resources/images/index";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { Dropdown, ImageUploader } from "../../../../components/common";
import { getCustomFields, NewCompanyContacts } from "./NewCompanyContacts";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import OutsideClickHandler from "react-outside-click-handler";
import { useLoadDataGet } from "../../../../utils/use-load-data";
import { endpoints } from "../../../../config/apiConfig";
import { AddressAutoComplete } from "../../../../components/common";
import { StarFill, StarOutline } from "../../LeftNav/SvgIcons";

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    position: "relative",
    marginBottom: "0px",
  },
  gridCheckBox: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "149px",
    flexWrap: "wrap",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  formStyle: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  withStarButtonWrap: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginLeft: "10px",
    },
  },
}));

const NewBusinessContact = () => {
  let isDisable = [];
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [featureImgUpload, setFeatureImgUpload] = useState(null);

  const [businessGroups, setBusinessGroups] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [ofishAccessPolicies, setOfishAccessPolicies] = useState([]);
  const [companyData, setCompanyData] = useState({ address: "" });
  const [groupId, setBusinessGroupId] = useState(0);
  const [countryId, setCountryId] = useState("");
  const [cityId, setSelectCityId] = useState("");
  const [stateId, setSelectedStatesId] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedLotLong, setSelectedLocation] = useState({});
  const [isContactDropdown, setIsContactDropdown] = useState(false);
  const [selectedContactType, setSelectedContactType] = useState(null);
  const [customFields, setCustomFields] = useState({});
  const [isEditAble, setIsEditAble] = useState([]);

  const sepObjectProp = ({ propName, value }) => {
    setCompanyData({
      ...companyData,
      [propName]: value,
    });
  };

  const { records: contactTypes } = useLoadDataGet({
    url: `${endpoints.contactTypes.getContactTypesDdl}?ContactTypeAreaId=2`,
  });

  const {
    records: contactTypesInfo,
    setRecods,
    loadListAsync,
  } = useLoadDataGet({
    url: ``,
  });

  //#region Contact type
  useEffect(() => {
    if (contactTypes.length > 0) {
      if (companyData?.id && companyData?.contactTypeId > 0) {
        const slctedContactType = contactTypes.find(
          ct => `${ct.id}` === `${companyData.contactTypeId}`
        );
        if (slctedContactType) {
          setSelectedContactType(slctedContactType);
          return;
        }
      }
      setSelectedContactType(first(contactTypes));
    }
  }, [contactTypes, companyData?.id]);

  useEffect(() => {
    if (selectedContactType?.id) {
      const getContactTypeInfo = async () => {
        setLoading(true);
        const loaddedData = await loadListAsync(
          `${endpoints.endUser.getContactUserTypes}${selectedContactType?.id}`
        );
        setRecods(loaddedData?.data || []);
        setLoading(false);
        return loaddedData;
      };
      getContactTypeInfo();
      setIsContactDropdown(false);
    }
  }, [selectedContactType?.id]);

  useEffect(() => {
    if (contactTypesInfo?.length > 0) {
      const contactFieldsData = first(contactTypesInfo)?.["contactFieldsData"];
      setCustomFields(
        getCustomFields({
          contactFieldsData: contactFieldsData,
          fieldIds: contactFieldsData.map(f => f.fieldId),
        })
      );
    }
  }, [contactTypesInfo]);
  //#endregion

  //#region Load require droupdowns data
  useEffect(() => {
    dispatch(contactsActions.onGetPolicies()).then(data => {
      if (data?.success && data?.data?.length >= 0) {
        setOfishAccessPolicies(data.data);
      } else {
        toast.error("Unable to load policies");
      }
    });
  }, [dispatch]);

  const getBusinessGroups = useCallback(() => {
    dispatch(productInventoryActions.onGetBusinessCustomerGroups()).then(
      data => {
        if (data?.success && data?.data) {
          setBusinessGroups(data.data);
        } else {
          toast.error("Unable to load business groups");
        }
      }
    );
  }, [dispatch]);

  const getCountriesList = useCallback(() => {
    dispatch(contactsActions.onGetCountries()).then(data => {
      if (data && data.success) {
        if (data?.data) {
          setCountries(data.data);
        }
      }
    });
  }, [dispatch]);

  const getCities = useCallback(() => {
    dispatch(contactsActions.onGetCities()).then(data => {
      if (data && data.success) {
        if (data?.data) {
          setCities(data.data);
        }
      }
    });
  }, [dispatch]);

  const getStateProvince = useCallback(() => {
    dispatch(contactsActions.onGetProvinces()).then(data => {
      if (data && data.success) {
        if (data?.data) {
          setProvinces(data.data);
        }
      }
    });
  }, [dispatch]);

  const getProductSubCategories = useCallback(() => {
    dispatch(contactsActions.onGetProductSubCategories()).then(data => {
      if (data?.success && data?.data) {
        setProductCategories(data.data);
      } else {
        toast.error("Unable to load locator categories");
      }
    });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    getBusinessGroups();
    getCountriesList();
    getStateProvince();
    getCities();
    getProductSubCategories();
    setLoading(false);
    document.title = "OFish - Business Customer";
  }, [
    getBusinessGroups,
    getCities,
    getCountriesList,
    getProductSubCategories,
    getStateProvince,
  ]);

  useEffect(() => {
    if (!companyData?.id && businessGroups?.length > 0) {
      setBusinessGroupId(parseInt(first(businessGroups).id));
    }
  }, [businessGroups, companyData.id]);
  //#endregion

  useEffect(() => {
    getCompanyDetail();
  }, []);

  const getCompanyDetail = useCallback(() => {
    const selectedCompany = queryString.parse(location.search);
    if (selectedCompany?.id) {
      setLoading(true);
      dispatch(contactsActions.onGetCompanyDetail(selectedCompany.id)).then(
        data => {
          if (data && data.success) {
            setCompanyData(data?.data);
          } else {
            toast.error(`Company dosen't exist`);
          }
          setLoading(false);
        }
      );
    }
  }, [dispatch, location.search]);

  useLayoutEffect(() => {
    if (companyData.id) {
      setFeatureImgUpload(companyData.image);
      setBusinessGroupId(parseInt(companyData.groupId));
      setCountryId(companyData.countryName);
      setSelectCityId(companyData.cityName);
      setSelectedStatesId(companyData.stateId);
      if (companyData.phoneNumber) {
        setPhoneCode(first(companyData?.phoneNumber?.split("-")));
        setPhoneNumber(last(companyData?.phoneNumber?.split("-")));
      }
      if (companyData?.latitude && companyData?.longitude) {
        setSelectedLocation({
          lat: companyData?.latitude,
          lng: companyData?.longitude,
        });
      }
    }
  }, [companyData.id]);

  const onComapanyInfoSave = () => {
    setLoading(true);
    const imageURL = featureImgUpload ? featureImgUpload : "";
    const postComanyInfo = {
      ...companyData,
      image: imageURL,
      groupId: groupId || 0,
      countryName: countryId,
      cityName: cityId,
      stateName: stateId,
      cityId: cityId,
      stateId: stateId,
      phoneNumber: `${phoneCode}-${phoneNumber}`,
      users: [],
      productId:
        companyData?.productId?.length > 0 ? companyData?.productId : [],
      latitude: selectedLotLong?.lat || companyData?.longitude,
      longitude: selectedLotLong?.lng || companyData?.longitude,
      contactTypeId: selectedContactType?.id,
    };
    if (companyData?.id) {
      dispatch(contactsActions.onUpdateCompany(postComanyInfo)).then(data => {
        if (data && data.success) {
          setCompanyData(data.data);
          toast.success("Company information updated successfully");
        } else {
          toast.error("Something went wrong, Please try again later!");
        }
        setLoading(false);
      });
    } else
      dispatch(contactsActions.onAddContact(postComanyInfo)).then(data => {
        if (data && data.success) {
          setCompanyData(data.data);
          toast.success("Company information added successfully");
        } else {
          toast.error("Something went wrong, Please try again later!");
        }
        setLoading(false);
      });
  };

  // const uploadProfileImage = file => {
  //   setLoading(true);
  //   dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
  //     if (data && data.success && data.data) {
  //       // setProfileImg(data.data);
  //       setShowFeatureImage(false);
  //     }
  //     setLoading(false);
  //   });
  // };

  // const uploadAdminProfileImage = file => {
  //   setLoading(true);
  //   dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
  //     if (data && data.success && data.data) {
  //       // setAdminCompanyProfileImg(data.data);
  //       // setShowAdminFeatureImage(false);
  //     }
  //     setLoading(false);
  //   });
  // };
  return (
    <Grid container spacing={3} xs={12} style={{ flexDirection: "column" }}>
      {isLoading ? <Loader /> : null}
      <ValidatorForm
        className={classes.formStyle}
        // ref={React.useRef("form")}
        onSubmit={e => {
          e.preventDefault();
          onComapanyInfoSave();
        }}>
        <AccordionContainer
          title='Company Overview'
          formButton={
            <span className={classes.withStarButtonWrap}>
              <span className='contactTypeWrapperBtn'>
                Contact Type:
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => setIsContactDropdown(true)}>
                  {selectedContactType?.value}
                  <OutsideClickHandler
                    onOutsideClick={() => setIsContactDropdown(false)}>
                    {isContactDropdown && (
                      <ul className='dropdown'>
                        {contactTypes.length > 0 &&
                          contactTypes?.map((item, index) => {
                            return (
                              <li
                                className={
                                  selectedContactType?.id === item.id
                                    ? "activeItem"
                                    : ""
                                }
                                key={index}
                                onClick={() => {
                                  // getContactTypeById(parseInt(item.id));
                                  setSelectedContactType(item);
                                  setIsContactDropdown(false);
                                }}>
                                {item.value}
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </OutsideClickHandler>
                </Button>
              </span>
              <Button color='primary' variant='contained' type='submit'>
                Save
              </Button>
              {/* <img
                src={companyData?.isFavourite ? star : starOutlineYellow}
                alt='star'
                onClick={() => {
                  sepObjectProp({
                    propName: "isFavourite",
                    value: !companyData?.isFavourite,
                  });
                }}
              /> */}
              <span
                onClick={() => {
                  sepObjectProp({
                    propName: "isFavourite",
                    value: !companyData?.isFavourite,
                  });
                }}
                style={{ display: "block", marginLeft: "10px" }}>
                {companyData?.isFavourite ? <StarFill /> : <StarOutline />}
              </span>
            </span>
          }>
          <div className='fr-view'>
            <Grid container spacing={3} className='overviewWrapper'>
              {customFields[12] && (
                <Grid item xs={3} className={classes.gridWrapper}>
                  <div className='row'>
                    <div className='uploadWrapper'>
                      <div className='containers'>
                        <div className='imageWrapper'>
                          <img
                            alt='img'
                            className='image'
                            src={
                              featureImgUpload
                                ? featureImgUpload
                                : companyData?.image ||
                                  "http://via.placeholder.com/700x500"
                            }
                          />
                        </div>
                      </div>
                      <Button
                        variant='outlined'
                        color='primary'
                        className='file-upload'
                        style={{ height: 50, alignSelf: "center" }}
                        onClick={() => setShowFeatureImage(!showFeatureImage)}>
                        Upload avatar
                      </Button>
                      <ImageUploader
                        title={"Upload profile image"}
                        showFeatureImage={showFeatureImage}
                        setShowFeatureImage={() =>
                          setShowFeatureImage(!showFeatureImage)
                        }
                        onImageSelect={img => setFeatureImgUpload(img.url)}
                      />
                      {/* {showFeatureImage && (
                      <SelectCompanyImagePopUp
                        closePopup={() => setShowFeatureImage(false)}
                        openPopup={showFeatureImage}
                        
                        addProfileImage={value => {
                          uploadProfileImage(value);
                        }}
                      />
                    )} */}
                    </div>
                  </div>
                </Grid>
              )}

              <Grid item xs={8} className={classes.gridWrapper}>
                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.gridWrapper}>
                    <InputField
                      placeholder={customFields[14]?.fieldLabel || "Company"}
                      inputlabel={customFields[14]?.fieldLabel || "Company"}
                      className={classes.gridWrapper}
                      showRequired={true}
                      id='companyName'
                      name='companyName'
                      value={companyData?.name}
                      validators={[
                        "required",
                        "matchRegexp:^(?:[A-Z0-9a-z-'_ ]+([-_ ]?[A-Z0-9a-z]+)*)?$",
                        "matchRegexp:^.{0,50}$",
                      ]}
                      errorMessages={[
                        "This field is required",
                        "Special characters are not allowed",
                        "Maximum length is 50 characters",
                      ]}
                      onChange={({ target }) =>
                        sepObjectProp({
                          propName: "name",
                          value: target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.gridWrapper}>
                    <Dropdown
                      selectlabel='Business Group'
                      placeholder='Please Select'
                      value={parseInt(groupId)}
                      required={false}
                      showRequired
                      customMenuItems={businessGroups?.map(c => {
                        return {
                          id: parseInt(c.id),
                          name: c.value,
                        };
                      })}
                      handleChange={({ target }) => {
                        setBusinessGroupId(target.value);
                        sepObjectProp({
                          propName: "groupId",
                          value: target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                {customFields[23] && (
                  <Textarea
                    className='overviewTextarea'
                    placeholder='Write something about Bio'
                    textLabel={customFields[23]?.fieldLabel || "Company Bio"}
                    value={companyData?.bio}
                    onChange={({ target }) =>
                      sepObjectProp({
                        propName: "bio",
                        value: target.value,
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={11} className={classes.gridWrapper}>
                {customFields[18] && (
                  <>
                    <div className='gap' />

                    <Grid container spacing={3}>
                      <Grid item xs={7} className={classes.gridWrapper}>
                        {/* <LocationAutoComplete
                          label={customFields[18]?.fieldLabel || "Address"}
                          address={companyData?.address}
                          onLocationSelect={({
                            formatted_address,
                            latlong,
                          }) => {
                            sepObjectProp({
                              propName: "address",
                              value: formatted_address,
                            });
                            setSelectedLocation(latlong);
                          }}
                        /> */}
                        <AddressAutoComplete
                          address={companyData?.address}
                          autoComId={"popupEnvLocInput"}
                          onChange={e => {
                            sepObjectProp({
                              propName: "address",
                              value: e.target.value,
                            });
                          }}
                          setPlace={p => {
                            isDisable = [];
                            setSelectCityId("");
                            setSelectedStatesId("");
                            setCountryId("");
                            sepObjectProp({
                              propName: "zipCode",
                              value: "",
                            });
                            const val =
                              document.getElementById(
                                "popupEnvLocInput"
                              )?.value;
                            const arr = val?.split(",");
                            if (arr?.length > 0) {
                              const v = arr[0];

                              p?.address_components?.forEach(x => {
                                if (x?.types?.includes("route")) {
                                  setCompanyData({
                                    ...companyData,
                                    address: x?.long_name,
                                  });
                                }
                                if (x?.types?.includes("locality")) {
                                  setSelectCityId(x?.long_name);
                                  isDisable.push("city");
                                }
                                if (
                                  x?.types?.includes(
                                    "administrative_area_level_1"
                                  )
                                ) {
                                  setSelectedStatesId(x?.long_name);
                                  isDisable.push("state");
                                }
                                if (x?.types?.includes("country")) {
                                  setCountryId(x?.long_name);
                                  isDisable.push("country");
                                }
                                if (x?.types?.includes("postal_code")) {
                                  sepObjectProp({
                                    propName: "zipCode",
                                    value: x?.long_name,
                                  });
                                  isDisable.push("postalCode");
                                }
                              });
                              setIsEditAble(isDisable);
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={5} className={classes.gridWrapper}>
                        <InputField
                          placeholder='Enter country or region'
                          inputlabel='Country or region'
                          validators={["required"]}
                          errorMessages={["Please enter country or region"]}
                          value={countryId}
                          onChange={v => {
                            setCountryId(v.target.value);
                            sepObjectProp({
                              propName: "countryId",
                              value: v.target.value,
                            });
                          }}
                          // disabled={
                          //   isEditAble.includes("country") ? true : false
                          // }
                        />
                      </Grid>
                    </Grid>
                    <div className='gap' />

                    <Grid container spacing={3}>
                      <Grid item xs={4} className={classes.gridWrapper}>
                        <InputField
                          placeholder='Enter City'
                          inputlabel='City'
                          validators={["required"]}
                          errorMessages={["Please enter city"]}
                          value={cityId}
                          onChange={v => {
                            setSelectCityId(v.target.value);
                            sepObjectProp({
                              propName: "cityId",
                              value: v.target.value,
                            });
                          }}
                          // disabled={isEditAble.includes("city") ? true : false}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.gridWrapper}>
                        <InputField
                          placeholder='Enter State'
                          inputlabel='State'
                          validators={["required"]}
                          errorMessages={["Please enter state"]}
                          value={stateId}
                          onChange={v => {
                            setSelectedStatesId(v.target.value);
                            sepObjectProp({
                              propName: "stateId",
                              value: v.target.value,
                            });
                          }}
                          // disabled={isEditAble.includes("state") ? true : false}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.gridWrapper}>
                        <InputField
                          placeholder='Zip code or postal code'
                          inputlabel='Zip code or postal code'
                          className={classes.gridWrapper}
                          validators={[
                            // "matchRegexp:^[0-9]+$",
                            "matchRegexp:^(?:[A-Z0-9a-z ]+([-_ ]?[A-Z0-9a-z]+)*)?$",
                            "matchRegexp:^.{0,15}$",
                          ]}
                          errorMessages={[
                            "Special characters and multiple spaces are not allowed",
                            "Maximum length is 15 characters",
                          ]}
                          id='zip'
                          name='zip'
                          value={companyData?.zipCode}
                          onChange={({ target }) =>
                            sepObjectProp({
                              propName: "zipCode",
                              value: target.value,
                            })
                          }
                          // disabled={
                          //   isEditAble.includes("postalCode") ? true : false
                          // }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <div className='gap' />

                <Grid container spacing={3}>
                  {customFields[20] && (
                    <Grid item xs={5} className={classes.gridWrapper}>
                      <InputField
                        placeholder={customFields[20]?.fieldLabel || "Website"}
                        inputlabel={customFields[20]?.fieldLabel || "Website"}
                        className={classes.gridWrapper}
                        validators={["matchRegexp:^.{0,200}$"]}
                        errorMessages={["Maximum length is 200 characters"]}
                        id='website'
                        name='website'
                        value={companyData?.website}
                        onChange={({ target }) =>
                          sepObjectProp({
                            propName: "website",
                            value: target.value,
                          })
                        }
                      />
                    </Grid>
                  )}
                  {customFields[19] && (
                    <>
                      <Grid item xs={3} className={classes.gridWrapper}>
                        <Dropdown
                          selectlabel={"Country Code"}
                          placeholder='code'
                          value={phoneCode}
                          required={false}
                          customMenuItems={countriesPhone?.map(c => {
                            return {
                              id: c.code,
                              name: c.code,
                            };
                          })}
                          handleChange={({ target }) => {
                            setPhoneCode(target.value);
                            sepObjectProp({
                              propName: "phoneCode",
                              value: target.value,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.gridWrapper}>
                        <InputField
                          placeholder={
                            customFields[19]?.fieldLabel || "Phone Number"
                          }
                          inputlabel={
                            customFields[19]?.fieldLabel || "Phone Number"
                          }
                          className={classes.gridWrapper}
                          validators={[
                            "matchRegexp:^[0-9]+$",
                            "matchRegexp:^.{0,20}$",
                          ]}
                          errorMessages={[
                            "Special characters and space are not allowed",
                            "Maximum length is 20 characters",
                          ]}
                          id='phonenumber'
                          name='phonenumber'
                          value={phoneNumber}
                          onChange={({ target }) =>
                            setPhoneNumber(target.value)
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <div className='gap' />

                {customFields[21] && (
                  <Grid
                    item
                    xs={3}
                    className={classes.gridWrapper}
                    style={{ marginTop: "8px" }}>
                    <Dropdown
                      selectlabel={customFields[21]?.fieldLabel || "Locator"}
                      placeholder='Please Select'
                      value={companyData?.showInLocator === true ? true : false}
                      required={false}
                      customMenuItems={[
                        {
                          id: false,
                          name: "Exclude from Dealer Locator",
                        },
                        { id: true, name: "Show in Dealer Locator" },
                      ]}
                      handleChange={() => {
                        sepObjectProp({
                          propName: "showInLocator",
                          value: !companyData?.showInLocator,
                        });
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </AccordionContainer>

        {companyData?.showInLocator && customFields[20] && (
          <AccordionContainer title='Locator Settings'>
            <div className='fr-view'>
              <div className='row'>Filters</div>
              <h3>Products Carried/Environments</h3>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={3}
                  className={[classes.gridWrapper, classes.gridCheckBox]}>
                  {productCategories?.map(pc => {
                    return (
                      <span key={pc.id}>
                        <Checkbox
                          value={pc.id}
                          checked={
                            companyData?.productId?.includes(pc.id)
                              ? true
                              : false
                          }
                          onChange={({ target }) => {
                            if (companyData?.productId?.length > 0) {
                              if (target.checked) {
                                companyData?.productId.push(pc.id);
                                sepObjectProp({
                                  propName: "productId",
                                  value: uniq(companyData?.productId),
                                });
                              } else {
                                sepObjectProp({
                                  propName: "productId",
                                  value: companyData?.productId.filter(
                                    p => p !== pc.id
                                  ),
                                });
                              }
                            } else {
                              sepObjectProp({
                                propName: "productId",
                                value: [pc.id],
                              });
                            }
                          }}
                        />
                        {pc.value}
                      </span>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          </AccordionContainer>
        )}
      </ValidatorForm>
      {first(contactTypesInfo) && selectedContactType && (
        <NewCompanyContacts
          phonecodes={countriesPhone}
          countries={countries}
          cities={cities}
          provinces={provinces}
          accessPolicies={ofishAccessPolicies}
          companyData={companyData}
          comapnyContacts={companyData?.users || []}
          onNewUser={val => {
            if (val) {
              getCompanyDetail();
            }
          }}
          contactTypesInfo={contactTypesInfo}
          selectedContactType={selectedContactType}
        />
      )}
    </Grid>
  );
};

export { NewBusinessContact };

export const AccessPolicy = ({ accessPolicies, onPolicyChange }) => {
  const classes = useStyles();

  return (
    <div>
      <h3>OFish Access</h3>
      <Grid container spacing={3}>
        <Grid
          item
          xs={4}
          className={[classes.gridWrapper, classes.gridCheckBox]}>
          {accessPolicies?.length > 0 &&
            accessPolicies.map((ap, apindex) => {
              return (
                <span key={ap.id} className='checkboxLabel'>
                  <Checkbox
                    label={ap.value}
                    checked={ap.checked}
                    onChange={({ target }) => {
                      const newPoliciesList = [...accessPolicies];
                      newPoliciesList[apindex].checked = target.checked;

                      // accessPolicies = [...newPoliciesList];
                      onPolicyChange && onPolicyChange([...newPoliciesList]);
                    }}
                  />
                  {ap.value}
                </span>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};
