import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  time,
  phone,
  building,
  chatBubble,
  grommetIcons,
  email,
  contactInsta,
  contactFacebook,
  contactLinkedIn,
  contactTwitter,
} from "../../../resources/images/index";
import Loader from "../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { pubCompanyActions } from "../../../store/pubCompany/pubCompanyActions";
import { toast } from "react-toastify";

function ContactUs({ Active }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState("");

  useEffect(() => {
    if (Active) {
      setLoading(true);
      dispatch(pubCompanyActions.onGetPubCompContact()).then(data => {
        setLoading(false);
        if (data && data.success) {
          setContactInfo(data.data);
        } else {
          if (data) {
            toast.error(data.message);
          }
        }
      });
    }
  }, [Active]);

  const returnSocialImg = value => {
    if (value === "LinkedIn") {
      return contactLinkedIn;
    } else if (value === "Instagram") {
      return contactInsta;
    } else if (value === "Facebook") {
      return contactFacebook;
    } else {
      return contactTwitter;
    }
  };
  const calculatCloseHours = value => {
    if (value && value.length > 0) {
      const closeDay = GetDaysGrouped(value.filter(c => c.closed === true));
      let holiDays = "";
      if (closeDay.length > 0) {
        closeDay.forEach((element, index) => {
          let days = "";
          element.Days.forEach((day, i) => {
            days = `${days}${day}${element.Days.length > i + 1 ? ", " : ""}`;
          });
          holiDays = `${holiDays}${days}${
            closeDay.length > index + 1 ? ", " : ""
          }`;
        });
      }
      return (
        <>
          <p>{`${"Closed from "}${holiDays}`} </p>
        </>
      );
    }
  };
  const calculatOpenHours = value => {
    if (value && value.length > 0) {
      const openDay = GetDaysGrouped(value.filter(c => c.closed === false));
      let workingDays = [];
      if (openDay.length > 0) {
        openDay.forEach(element => {
          let days = "";
          element.Days.forEach((day, i) => {
            days = `${days}${day}${element.Days.length > i + 1 ? ", " : ""}`;
          });
          workingDays.push({
            finalDays: days,
            finalTime: element.Key,
          });
        });
      }
      return (
        <>
          {workingDays.length > 0 &&
            workingDays.map((hrs, index) => (
              <p key={index}>{`${hrs.finalDays}${" | "}${hrs.finalTime}`} </p>
            ))}
        </>
      );
    }
  };

  const GetDaysGrouped = Data => {
    const DaysNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const FinalDays = [];
    var helper = {};
    Data.reduce(function (r, o) {
      var key = o.openingTime + " - " + o.closingTime;

      if (!helper[key]) {
        helper[key] = [];
        helper[key].push(DaysNames.indexOf(o.day));
        r.push(helper[key]);
      } else {
        helper[key].push(DaysNames.indexOf(o.day));
      }

      return r;
    }, []);
    for (const item in helper) {
      const TempObj = { Key: item, Days: [] };
      let First = 0;
      let Last = 0;
      for (let index = 0; index < helper[item].length; index++) {
        const element = helper[item][index];
        if (index == 0) {
          First = element;
        }
        if (element + 1 != helper[item][index + 1]) {
          Last = element;
          if (Last == First) {
            TempObj.Days.push(DaysNames[First]);
          } else {
            TempObj.Days.push(DaysNames[First] + " - " + DaysNames[Last]);
          }

          First = helper[item][index + 1];
        }
      }
      FinalDays.push(TempObj);
    }
    return FinalDays;
  };

  return (
    <div className="contactUsWrapper">
      {isLoading ? <Loader /> : null}
      <div className="contactHeader">
        <span className="contactPic">
          <img
            src={
              contactInfo !== "" && contactInfo?.imageURL
                ? contactInfo.imageURL
                : null
            }
            alt="pic"
          />
        </span>
        <span>
          <p>Press Contact: </p>
          <p>{contactInfo !== "" && contactInfo?.pressContact} </p>
          <div className="innerColumsText">
            <a
              href={`${"mailto:"}${
                contactInfo !== "" &&
                contactInfo?.pressContact &&
                contactInfo?.pressContact?.email
              }`}>
              {contactInfo !== "" && contactInfo?.pressContactEmail}
            </a>
          </div>
        </span>
      </div>
      <Grid container spacing={3} className="assetsInner">
        <Grid item xs={4} className="contactGrid">
          <img src={building} alt="" />
          <div className="innerColumsText">
            <h3>Visit Us</h3>
            <p>{contactInfo !== "" ? contactInfo?.address : ""}</p>
          </div>
        </Grid>
        <Grid item xs={4} className="contactGrid">
          <img src={phone} alt="" />
          <div className="innerColumsText">
            <h3>Contact Us</h3>
            {contactInfo !== "" &&
              contactInfo?.contacts &&
              contactInfo.contacts.length > 0 &&
              contactInfo.contacts.map((cnt, index) => (
                <p key={index}>{`${cnt.countryCode}${"-"}${cnt.number}`}</p>
              ))}
            <p>{`${"FAX: "}${contactInfo?.faxCountryCode}${"-"}${
              contactInfo?.faxNumber
            }`}</p>
          </div>
        </Grid>
        <Grid item xs={4} className="contactGrid">
          <img src={time} alt="" />
          <div className="innerColumsText">
            <h3>Business Hours</h3>
            <span>{calculatOpenHours(contactInfo?.businessHours)}</span>
            <span>{calculatCloseHours(contactInfo?.businessHours)}</span>
            {/* <p>Monday - Friday | 9AM - 5PM</p>
            <p> Closed from Saturday - Sunday</p> */}
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="assetsInner">
        <Grid item xs={4} className="contactGrid">
          <img src={email} alt="" />
          <div className="innerColumsText">
            <h3>Email</h3>
            {contactInfo?.emails &&
              contactInfo.emails.length > 0 &&
              contactInfo.emails.map((eml, index) => (
                <a key={index} href={`${"mailto:"}${eml.email}`}>
                  {eml.email}
                </a>
              ))}
          </div>
        </Grid>
        <Grid item xs={4} className="contactGrid">
          <img src={grommetIcons} alt="" />
          <div className="innerColumsText">
            <h3>Social Media</h3>
            {contactInfo?.socialMedia &&
              contactInfo.socialMedia.length > 0 &&
              contactInfo.socialMedia.map((scl, index) => (
                <span key={index} className="socialMediaIcons">
                  <img src={returnSocialImg(scl.name)} alt="" />
                  <a href={scl.link} target="_blank">
                    {scl.name}
                  </a>
                </span>
              ))}
          </div>
        </Grid>
        <Grid item xs={4} className="contactGrid">
          <img src={chatBubble} alt="" />
          <div className="innerColumsText">
            <h3>Chat</h3>
            <Link>Chat with us</Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUs;
