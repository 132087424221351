import React, { useState, useEffect, useCallback } from "react";
import Dashboard from "../Dashbard";
import { Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import ContactTypeBasics from "./ContactTypeBasics";
import ContactTypeAccess from "./ContactTypeAccess";
import Select, { SelectChangeEvent } from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";

export default function NewContactTypes() {
  const [age, setAge] = React.useState("");
  const history = useHistory();

  const handleChange = e => {
    setAge(e.target.value);
  };
  return (
    <>
      <Dashboard>
        <div className="body-content">
          <div className="consumerHeading">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <h1>Contact Types</h1>
            </div>

            <Button
              color="primary"
              onClick={() => {
                history.push("/contact-types");
              }}
              variant="contained">
              <ArrowBackIos /> Back to Contact Types
            </Button>
          </div>
          {/* <p className="contactSubHeading">Back to Contact Types</p> */}

          <div className="tab-navigator ">
            <TopTabNavigator
              tabs={[
                {
                  id: "1",
                  title: "Basics",
                  component: <ContactTypeBasics />,
                },
                {
                  id: "2",
                  title: "Access",
                  component: <ContactTypeAccess />,
                },
                {
                  id: "3",
                  title: "Contacts",
                  component: <div>Access</div>,
                },
                {
                  id: "4",
                  title: "Activity",
                  component: <div>Activity</div>,
                },
                {
                  id: "5",
                  title: "Notes",
                  component: <div>Product Registration</div>,
                },
              ]}
            />
          </div>
          {/* <Grid container spacing={2}>
            <Grid item md={10} className="filterMainSearch">
              <img src={searchIcon} alt="" className="searchIcon" />
              <Field placeholder="Search" />
            </Grid>

            <Grid item md={2} className="searchByFilter">
              Search by Filter <FiChevronDown />
            </Grid>
          </Grid> */}
        </div>
      </Dashboard>
    </>
  );
}
