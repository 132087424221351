import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ArrowBackIos } from "@material-ui/icons";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../../components/common/InputField";
import EmptyTextarea from "../../../../components/common/Textarea";
import GeneralHeading from "../../../../components/common/GeneralHeading";
import Loader from "../../../../components/common/Loader";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { ReleaseCategoryVisibilityGroup } from "./ReleaseCategoryVisibilityGroup";
import { useDropdownValues } from "../../../../utils/use-dropdown-values";
import { endpoints } from "../../../../config/apiConfig";
import { CategoryType } from "../../../../components/common";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  eventCatTextarea: {},
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

const ReleaseCategoryOverview = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [categoryNameInternal, setCategoryNameInternal] = useState("");
  const [categoryNamePublic, setCategoryNamePublic] = useState("");
  const [categoryURLSlug, setCategoryURLSlug] = useState("");
  const [categoryShortDescription, setCategoryShortDescription] = useState("");
  const [parentCategoryId, setParentCategoryId] = useState(0);
  const [categoryColor, setCategoryColor] = useState("");
  const [eventHeading, setEventHeading] = useState("New Event");
  const [eventEditCatData, setEventCatEditData] = useState(null);
  const [newsRoomVisibilityId, selectCategoryNewsRoomVisibility] = useState("");
  const [releaseVisibilityGroups, setReleaseVisibilityGroups] = useState([]);
  const [preReleaseVisibilityGroups, setPreReleaseVisibilityGroups] = useState(
    []
  );
  const [visibilityGroups, setVisibilityGroups] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);

  const redirectUrl = () => {
    history.push("/release-category");
  };

  const dispatch = useDispatch();
  const eventId = parseInt(window.location.href.split("?id=")[1]);

  const classes = useStyles();
  const loadGroups = useCallback(() => {
    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      setVisibilityGroups(data?.data);
    });
  }, [dispatch]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  useEffect(() => {
    if (
      visibilityGroups &&
      visibilityGroups.length > 0 &&
      eventEditCatData?.categoryGroups?.length > 0
    ) {
      setReleaseVisibilityGroups(
        eventEditCatData.categoryGroups.map(x => {
          return {
            name: visibilityGroups.find(y => `${y.id}` === `${x}`)?.value,
            VisibilityGroupId: x,
          };
        })
      );
    }
  }, [visibilityGroups, eventEditCatData]);

  useEffect(() => {
    if (eventId) {
      setLoading(true);
      dispatch(launchesActions.onGetReleaseCatById(eventId)).then(res => {
        if (res && res.success && res.data) {
          setEventCatEditData(res.data);
          setCategoryNameInternal(res.data.name);
          setCategoryNamePublic(res.data.namePublic);
          setCategoryURLSlug(res.data.urlSlug);
          setCategoryShortDescription(res.data.description);
          setParentCategoryId(res.data?.parentId);
          setCategoryColor(res.data.color);
          setEventHeading(res.data.name);
          selectCategoryNewsRoomVisibility(res.data.newsRoomId);
        }
        setLoading(false);
      });
    }
    dispatch(launchesActions.onGetReleaseNewsRoomVisibilities()).then(res => {
      if (res && res.success && res.data && res.data.length > 0) {
        if (!eventId) {
          selectCategoryNewsRoomVisibility(res.data[0].id);
        }
      }
    });
  }, [dispatch, eventId]);

  const { dropdownValues: releaseCategoriesDD } = useDropdownValues({
    url: endpoints.releaseCategory.getReleaseCategoriesDD,
  });

  const onSaveCategory = () => {
    if (
      categoryNameInternal !== "" &&
      categoryURLSlug.length < 200 &&
      categoryNamePublic.length < 50 &&
      categoryNameInternal.length < 50 &&
      categoryShortDescription.length < 1000
    ) {
      var groupId = [];
      releaseVisibilityGroups.map(x => {
        groupId.push(x.VisibilityGroupId);
      });
      const modal = {
        id: 0,
        name: categoryNameInternal,
        namePublic: categoryNamePublic,
        urlSlug: categoryURLSlug,
        description: categoryShortDescription,
        color: categoryColor,
        groupIds: groupId,
        newsRoomId: newsRoomVisibilityId,
        parentId: parentCategoryId,
      };
      if (eventId) {
        modal.id = eventId;
      }
      setLoading(true);
      setErrorMsg(false);
      dispatch(launchesActions.onAddUpdateReleaseCategory(modal)).then(data => {
        if (data.success) {
          if (eventId) {
            toast.success("Release category updated");
          } else toast.success("Release category added");
          redirectUrl();
        } else if (data?.message) {
          toast.warning(data?.message);
        }
        setLoading(false);
      });
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <div className='relCategoryTabs'>
      {isLoading ? <Loader /> : null}
      <GeneralHeading
        headingName={eventId ? categoryNameInternal : "New Release Category"}
        buttonText={eventId ? "Update Category" : "Save Category"}
        onClick={() => {
          onSaveCategory();
        }}
      />

      <div
        className='consumerHeading backToCategory'
        style={{ marginTop: "0" }}>
        <Button color='primary' onClick={redirectUrl} variant='contained'>
          <ArrowBackIos /> Back to Release Category
        </Button>
      </div>
      <TopTabNavigator
        tabs={[
          {
            id: 1,
            title: "Basics",
            component: [
              <AccordionContainer title='Category Overview'>
                <Grid container spacing={3}>
                  <Grid item xs={11} className={classes.gridWrapper}>
                    <InputField
                      placeholder='Enter a Category Name (Internal)'
                      inputlabel='Category Name (Internal)'
                      value={categoryNameInternal}
                      onChange={v => setCategoryNameInternal(v.target.value)}
                    />
                    {errorMsg && categoryNameInternal === "" && (
                      <span style={{ color: "red" }}>
                        Please enter category name (Internal)
                      </span>
                    )}
                    {categoryNameInternal.length > 50 && (
                      <span style={{ color: "red" }}>
                        Maximum length is 50 characters
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={11} className={classes.gridWrapper}>
                    <InputField
                      placeholder='Enter a Category Name (Public)'
                      inputlabel='Category Name (Public)'
                      value={categoryNamePublic}
                      onChange={v => setCategoryNamePublic(v.target.value)}
                    />
                    {categoryNamePublic.length > 50 && (
                      <span style={{ color: "red" }}>
                        Maximum length is 50 characters
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={11} className={classes.gridWrapper}>
                    <label className='slugLabel'>Event URL</label>
                    <Grid container spacing={2} xs={12} className='slugWrapper'>
                      <span style={{ marginLeft: "10px" }}>
                        newsroom.aquaticav.com/releases/
                      </span>
                      <span
                        className='eventCatUrlSlug'
                        style={{ flex: "1", marginLeft: "10px" }}>
                        <InputField
                          inputlabel='Event URL'
                          placeholder='Enter a URL Slug'
                          value={categoryURLSlug}
                          onChange={v =>
                            setCategoryURLSlug(
                              v.target?.value?.replace(/\s+/g, "")
                            )
                          }
                        />
                        {categoryURLSlug.length > 200 && (
                          <span style={{ color: "red" }}>
                            Maximum length is 200 characters
                          </span>
                        )}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={11}
                    className={[classes.gridWrapper, "eventCatTextarea"]}>
                    <EmptyTextarea
                      textLabel='Short Description'
                      placeholder='Enter description here'
                      value={categoryShortDescription}
                      onChange={v =>
                        setCategoryShortDescription(v.target.value)
                      }
                    />
                    {categoryShortDescription.length > 1000 && (
                      <span style={{ color: "red" }}>
                        Maximum length is 1000 characters
                      </span>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={11}
                    className={[classes.gridWrapper, "eventCatTextarea"]}>
                    <Grid container className='slugWrapper'>
                      <Grid container item xs={6}>
                        <CategoryType
                          disabled={eventEditCatData?.childCount > 0}
                          parentCategories={releaseCategoriesDD?.filter(
                            d => d.id !== eventId
                          )}
                          selectedParentCategoryId={parentCategoryId}
                          setParentCategoryId={setParentCategoryId}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        style={{ alignItems: "center" }}>
                        <Grid item xs={3} className={classes.gridWrapper}>
                          <label className='slugLabel'>Category color</label>
                        </Grid>
                        <Grid item xs={9}>
                          <ul>
                            <li>
                              <button
                                className={
                                  categoryColor === "#015dfe"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#015dfe");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#00d0ff"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#00d0ff");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#f9b900"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#f9b900");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#ff0092"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#ff0092");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#49b8ef"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#49b8ef");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#eb5757"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#eb5757");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#66de64"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#66de64");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#0a42e9"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#0a42e9");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#ef64b3"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#ef64b3");
                                }}
                              />
                              <button
                                className={
                                  categoryColor === "#236681"
                                    ? "colorActive"
                                    : ""
                                }
                                onClick={() => {
                                  setCategoryColor("#236681");
                                }}
                              />
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionContainer>,
              <div className='contentBody accordianBody'>
                <ReleaseCategoryVisibilityGroup
                  newsRoomVisibilityId={newsRoomVisibilityId}
                  releaseVisibilityGroups={releaseVisibilityGroups}
                  onNewsRoomVisibilityIdSelect={NewsRoomVisibilityId =>
                    selectCategoryNewsRoomVisibility(NewsRoomVisibilityId)
                  }
                  onGroupSelection={groupList => {
                    setReleaseVisibilityGroups(groupList);
                  }}
                  preReleaseVisibilityGroups={preReleaseVisibilityGroups}
                  setPreReleaseVisibilityGroups={setPreReleaseVisibilityGroups}
                />
              </div>,
            ],
          },
        ]}
      />

      {/* 
      <div className='contentBody accordianBody'>
        <ReleaseCategoryVisibilityGroup
          newsRoomVisibilityId={newsRoomVisibilityId}
          releaseVisibilityGroups={releaseVisibilityGroups}
          onNewsRoomVisibilityIdSelect={NewsRoomVisibilityId =>
            selectCategoryNewsRoomVisibility(NewsRoomVisibilityId)
          }
          onGroupSelection={groupList => {
            setReleaseVisibilityGroups(groupList);
          }}
          preReleaseVisibilityGroups={preReleaseVisibilityGroups}
          setPreReleaseVisibilityGroups={setPreReleaseVisibilityGroups}
        />
      </div> */}
    </div>
  );
};

export { ReleaseCategoryOverview };
