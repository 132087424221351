import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { searchIcon } from "../../../../resources/images";
import { useDebounce } from "../../../../utils/use-debounce";
import { ProductsOwnedQtyList } from "../../../../config/Enums";

const StatusOptions = [
  { id: 1, name: "Active", value: 1 },
  { id: 2, name: "In-Active", value: 0 },
  { id: 3, name: "Archived", value: 3 },
  { id: 4, name: "Deleted", value: -1 },
  { id: 5, name: "Blocked", value: 2 },
];

function UserDetails({
  EndUserGroup,
  UserGroupIds,
  setUGIds,
  DeviceRegisteredQty,
  setDeviceRegisteredQty,
  UserStatus,
  setUserStatus,
  Email,
  setEmail,
  FirstName,
  setFirstName,
  LastName,
  setLastName,
  ContactId,
  setContactId,
  onEmailChange,
  EmailsList = [],
  onFirstNameChange,
  FirstNameList,
  onLastNameChange,
  LastNameList,
  onContactIdChange,
  ContactIdList,
}) {
  const [emailText, setEmailSearch] = useState(Email);
  const { debouncedValue: emailVal } = useDebounce(emailText, 500);

  const [firstNameTxt, setFirstNameTxt] = useState(FirstName);
  const { debouncedValue: firstNameVal } = useDebounce(firstNameTxt, 500);

  const [lastNameTxt, setLastNameTxt] = useState(LastName);
  const { debouncedValue: lastNameVal } = useDebounce(lastNameTxt, 500);

  const [contactIdTxt, setContactIdTxt] = useState(ContactId);
  const { debouncedValue: contactIdVal } = useDebounce(contactIdTxt, 500);

  useEffect(() => {
    if (emailVal) {
      onEmailChange(emailVal);
    }
  }, [emailVal]);

  useEffect(() => {
    if (firstNameVal) {
      onFirstNameChange(firstNameVal);
    }
  }, [firstNameVal]);

  useEffect(() => {
    if (lastNameVal) {
      onLastNameChange(lastNameVal);
    }
  }, [lastNameVal]);

  useEffect(() => {
    if (contactIdVal) {
      onContactIdChange(contactIdVal);
    }
  }, [contactIdVal]);

  return (
    <div className='userDetailsSearchField'>
      <div className='userDetailsSearchField inputWrapper filterSelect endUserSearchfilter'>
        {EndUserGroup && (
          <FormControl className='ac-dd'>
            <Autocomplete
              value={EndUserGroup.filter(eg => UserGroupIds.includes(eg.id))}
              onChange={(event, value) => setUGIds(value?.map(g => g.id))}
              multiple
              filterSelectedOptions
              id='end-user-group'
              options={EndUserGroup}
              getOptionLabel={option => option?.value || ""}
              renderInput={params => (
                <TextField 
                {...params} 
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder={"End User Group"} />
              )}
            />
            {UserGroupIds?.length > 0 && (
              <span className='countLabel'>{UserGroupIds?.length}</span>
            )}
          </FormControl>
        )}
        {!EndUserGroup && (
          <FormControl className='ac-dd'>
            <Autocomplete
              value={DeviceRegisteredQty}
              onChange={(event, value) => {
                setDeviceRegisteredQty(value);
              }}
              multiple
              filterSelectedOptions
              id='product-owned-dd'
              options={ProductsOwnedQtyList}
              getOptionLabel={option => option?.value || ""}
              renderInput={params => (
                <TextField 
                {...params} 
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder={"Products Owned Qty"} />
              )}
            />
            {DeviceRegisteredQty?.length > 0 && (
              <span className='countLabel'>{DeviceRegisteredQty?.length}</span>
            )}
          </FormControl>
        )}

        <FormControl className='ac-dd'>
          <Autocomplete
            value={StatusOptions.filter(eg => UserStatus.includes(eg.value))}
            onChange={(event, value) => setUserStatus(value?.map(s => s.value))}
            multiple
            filterSelectedOptions
            id='user-active-inactive'
            options={StatusOptions}
            getOptionLabel={option => option?.name || ""}
            renderInput={params => (
              <TextField 
              {...params} 
              inputProps={{ ...params.inputProps, autoComplete: 'none' }}
              placeholder={"User Status"} />
            )}
          />
          {UserStatus?.length > 0 && (
            <span className='countLabel'>{UserStatus?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={Email}
            onChange={(event, value) => {
              if (value.length > 0) {
                setEmail(value);
              } else if (!value) {
                setEmail([]);
                setEmailSearch("");
              }
            }}
            multiple
            filterSelectedOptions
            id='end-user-email'
            options={EmailsList}
            getOptionLabel={option => option?.email || ""}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={"Email Address"}
                onChange={({ target }) => setEmailSearch(target.value)}
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {Email?.length > 0 && (
            <span className='countLabel'>{Email?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={FirstName}
            onChange={(event, value) => {
              if (value.length > 0) setFirstName(value);
              else if (!value) {
                setFirstName([]);
                setFirstNameTxt("");
              }
            }}
            multiple
            filterSelectedOptions
            id='end-user-fn-ac'
            options={FirstNameList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder={"Contact Name"}
                onChange={({ target }) => setFirstNameTxt(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {FirstName?.length > 0 && (
            <span className='countLabel'>{FirstName?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={LastName}
            onChange={(event, value) => {
              if (value?.length > 0) setLastName(value);
              else if (!value) {
                setLastName([]);
                setLastNameTxt("");
              }
            }}
            multiple
            filterSelectedOptions
            id='end-user-ln-ac'
            options={LastNameList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder={"Contact Last Name"}
                onChange={({ target }) => setLastNameTxt(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {LastName?.length > 0 && (
            <span className='countLabel'>{LastName?.length}</span>
          )}
        </FormControl>

        <FormControl className='ac-dd filterAutocomplete'>
          <Autocomplete
            value={ContactId}
            onChange={(event, value) => {
              if (value?.length > 0) setContactId(value);
              else if (!value) {
                setContactId([]);
                setContactIdTxt("");
              }
            }}
            multiple
            filterSelectedOptions
            id='end-user-id-ac'
            options={ContactIdList}
            getOptionLabel={option => option?.value || ""}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'none' }}
                placeholder={"Contact ID"}
                onChange={({ target }) => setContactIdTxt(target.value)}
              />
            )}
          />
          {/* <img src={searchIcon} alt='' /> */}
          {ContactId?.length > 0 && (
            <span className='countLabel'>{ContactId?.length}</span>
          )}
        </FormControl>
      </div>
    </div>
  );
}

export { UserDetails };
