import React, { useState, useEffect, useCallback } from "react";
import Dashboard from "../../Dashbard";
import { Box, Button, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/Inbox";
import { Dialog, Checkbox, Input, Grid, Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as SearchIcon } from "../../../../../resources/images/Search.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import DataFields from "../DataFields";

export function EditFieldPopup(props) {
  const Popup = withStyles({
    root: {
      "&.MuiDialog-paper": {
        maxWidth: "80% !important",
        width: "80% !important",
      },
    },
  })(Dialog);
  const InputField = withStyles({
    root: {
      "&.MuiOutlinedInput-input": {
       //  height: "4px !important",
      },
      "&.MuiTextField-root": {
         width: "300px !important",
        background: "#FFFFFF",
        borderRadius: " 6px",
        border: "1px solid black"
      },
    },
  })(TextField);
  const list  = [
    {'Icon': '', 'Title': 'Media Contacts', 'Description': 'Manage Media Contacts & Organize your media Stategies'},
    {'Icon': '', 'Title': 'Media Contacts', 'Description': 'Manage Media Contacts & Organize your media Stategies'},
    {'Icon': '', 'Title': 'Media Contacts', 'Description': 'Manage Media Contacts & Organize your media Stategies'}
  ];
  const [openModal, setOpenModal] = React.useState(props.open);
  useEffect(() => {
    if(props.open != undefined){
      setOpenModal(true);
    }
  },[props.open]);
  return (
    <>
      <div>
        <Popup style={{maxWith:'70% !important'}} component="div"
          open={openModal} 
          
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="edit-popup">
            <Grid container >
            <Grid item sx={10} md={11} lg={11}>
              <Typography id="modal-modal-title" style={{ padding: "3%"}} variant="h6" component="h1">
                Select OFish Contact Area
              </Typography>
            </Grid>
            <Grid item sx={1}>
              <Box component="span"  onClick={() => setOpenModal(false)} style={{ margin: '20px', display: 'inline-block',fontFamily: 'cursive',cursor:'pointer'}}>X</Box>
            </Grid>
          </Grid>
          <Divider />
          <div className="contentDiv">
            <div style={{padding:"1% 2% 3% 2%"}}>
              <p>Contact Field Name<span className="text-danger">*</span></p>
              <InputField id="outlined-basic"
                variant="outlined"  placeholder="Select a Contact Area"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                  ),
                }}
              />
              <Box component="label" style={{lineHeight: '42px', height: '42px', display: 'inline-block', margin: '0 10px'}}> Required By OFish</Box>
            </div>
            <Button style={{float:"right"}} variant="contained" color="primary" type="button">
              Save
            </Button>
          </div>
        </Popup>
      </div>
    </>
  );
}
