import _ from "lodash";
import { contactsTypes } from "./contactsTypes";
import subDays from "date-fns/subDays";

function checkByDaysCollection(value, days) {
  let byDays = "";
  if (days === 30) {
    byDays = subDays(new Date(), 30);
    return value.filter(x => byDays < new Date(x.dateCreated));
  } else if (days === 90) {
    byDays = subDays(new Date(), 90);
    return value.filter(x => byDays < new Date(x.dateCreated));
  } else if (days === 180) {
    byDays = subDays(new Date(), 180);
    return value.filter(x => byDays < new Date(x.dateCreated));
  } else if (days === 365) {
    byDays = subDays(new Date(), 365);
    return value.filter(x => byDays < new Date(x.dateCreated));
  } else {
    return value;
  }
}

const INITIAL_STATE = {
  errorMessage: "",
  allContacts: [],
  copyAllContacts: [],
  selectedAllContacts: [],
  customerGroups: [],
  selectedCustomerGroups: [],
  noOfLeader: [1, 2],
  selectedFileType: [],
  leadersValue: [
    { name: "", position: "", imageId: null },
    { name: "", position: "", imageId: null },
  ],
  contactNameUser: [],
  endUserGroup: [],
  businessCompanyInfo: {},
  ofishAccessPolicy: [],
  searchBusCom: "",
  productEnviroment: [],
  contactTypes: {},
  companyLocation:[],
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case contactsTypes.CONTACTS_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    case contactsTypes.SELECTED_ALL_CONTACTS: {
      const newState = { ...state };
      const allContact = action.payload;
      const allContactExist = newState.selectedAllContacts.find(contacts => {
        return contacts.id === allContact.id;
      });

      if (allContactExist && newState.selectedAllContacts.length > 0) {
        _.remove(newState.selectedAllContacts, contacts => {
          return contacts.id === allContact.id;
        });
      } else {
        newState.selectedAllContacts.push(allContact);
      }
      return newState;
    }

    case contactsTypes.SELECTED_CUS_GROUPS: {
      const newState = { ...state };
      const cusGroups = action.payload;
      const cusGroupsExist = newState.selectedCustomerGroups.find(groups => {
        return groups.id === cusGroups.id;
      });

      if (cusGroupsExist && newState.selectedCustomerGroups.length > 0) {
        _.remove(newState.selectedCustomerGroups, groups => {
          return groups.id === cusGroups.id;
        });
      } else {
        newState.selectedCustomerGroups.push(cusGroups);
      }
      return newState;
    }
    case contactsTypes.PRESS_ADD_MORE: {
      const newState = { ...state };
      let leaderArray = [];
      leaderArray = newState.noOfLeader;
      leaderArray.push(newState.noOfLeader.length + 1);
      newState.noOfLeader = leaderArray;
      newState.leadersValue.push({ name: "", position: "", imageId: null });
      return newState;
    }
    case contactsTypes.CHANGE_LEADER_VALUE: {
      const newState = { ...state };
      let a = [...newState.leadersValue];
      const leaderData = action.payload;
      let item = a[leaderData.index];
      if (leaderData.fieldName === "name") {
        item = { ...item, name: leaderData.value };
      } else if (leaderData.fieldName === "position") {
        item = { ...item, position: leaderData.value };
      } else {
        item = { ...item, imageId: leaderData.value };
      }
      a[leaderData.index] = item;
      newState.leadersValue = a;
      return newState;
    }
    case contactsTypes.ON_SELECT_GROUP: {
      const newState = { ...state };
      const colCategory = action.payload;
      const colCategoryExist = newState.selectedFileType.find(colCat => {
        return colCat === colCategory;
      });

      if (colCategoryExist && newState.selectedFileType.length > 0) {
        _.remove(newState.selectedFileType, colCat => {
          return colCat === colCategory;
        });
      } else {
        newState.selectedFileType.push(colCategory);
      }
      return newState;
    }
    case contactsTypes.ON_PRESS_FILTER_CONTACT: {
      const newState = { ...state };
      let filterArray = [];
      const searchData = action.payload;
      if (searchData.searchText !== "") {
        let byName = [];
        byName = _.filter(newState.copyAllContacts, o => {
          if (o.firstName) {
            return o.firstName
              .toLowerCase()
              .includes(searchData.searchText.toLowerCase());
          }
        });
        if (searchData.dayFilter !== "") {
          byName = checkByDaysCollection(byName, searchData.dayFilter);
        }
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, x => {
            const aa = byName.filter(y => y.group && y.group.name === x);
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byName;
        }
      } else if (searchData.dayFilter !== "") {
        let byMonth = [];
        byMonth = checkByDaysCollection(
          newState.copyAllContacts,
          searchData.dayFilter
        );
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, x => {
            const aa = byMonth.filter(y => y.group && y.group.name === x);
            filterArray = filterArray.concat(aa);
          });
        } else {
          filterArray = byMonth;
        }
      } else {
        if (newState.selectedFileType.length > 0) {
          _.forEach(newState.selectedFileType, x => {
            const aa = newState.copyAllContacts.filter(
              y => y.group && y.group.name === x
            );
            filterArray = filterArray.concat(aa);
          });
        }
      }
      newState.allContacts = filterArray;
      return newState;
    }
    case contactsTypes.ON_ADD_CONTACT_NAME_USER_FORM: {
      const newState = { ...state };
      const contactName = action.payload.value;
      newState.contactNameUser.push(contactName);
      return newState;
    }
    default:
      return state;
  }
};
