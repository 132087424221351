import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import AddIcon from "@material-ui/icons/Add";
import CompanyLocationGrid from "./CompanyLocationGrid";
import { RoutesObj } from "../../../../../routes";
import { SVGIcons } from "../../../../../resources/images";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { contactsActions } from "../../../../../store/contacts/contactsActions";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: "25px",
  },
  headingIcon: {
    marginRight: "10px",
  },
  checkboxes: {
    fontSize: "30px",
  },
  selectLabel: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "7px",
    display: "block",
  },
  selectSubheding: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  socialMediaIcon: {
    marginTop: "6px",
  },
  saveButton: {
    textAlign: "right",
  },
  days: {
    fontSize: "14px",
  },
}));
const CompanyLocations = ({ listUser, listData, setListData }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isEditAdr, setIsEditAdr] = useState(false);
  const [search, setSearch] = useState("");
  const [listDataBackup, setListDataBackup] = useState([]);
  const selectedData = queryString.parse(location.search);
  const [isLoading, setLoading] = useState(false);
  const [isCollpased, setIsCollpased] = useState(false);
  const [listUserData, setListUserData] = useState([]);
  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    if (selectedData?.id) {
      loadLocation();
      getProductSubCategories();
      loadUser();
    }
  }, []);

  const getProductSubCategories = () => {
    dispatch(contactsActions.onGetProductSubCategories()).then(data => {
      if (data?.success && data?.data) {
        setProductCategories(data.data);
      } else {
        toast.error("Unable to load locator categories");
      }
    });
  };

  const loadUser = () => {
    dispatch(
      contactsActions.onGetCompanyUsers(parseInt(selectedData?.id))
    ).then(data => {
      setLoading(false);
      if (data?.success) {
        setListUserData(data?.data);
      }
    });
  };

  const loadLocation = () => {
    setLoading(true);
    dispatch(
      contactsActions.onGetCompanyLocations(parseInt(selectedData?.id))
    ).then(data => {
      setLoading(false);
      if (data?.success) {
        setListData(data?.data);
        setListDataBackup(data?.data);
      } else {
        setListData([]);
        setListDataBackup([]);
      }
    });
  };
  const searching = value => {
    setSearch(value);
    if (value === "") {
      setListData(listDataBackup);
    } else {
      let filterRec = listDataBackup.filter(x => x.nickName.includes(value));
      setListData(filterRec);
    }
  };

  const deleteLocation = id => {
    setLoading(true);
    dispatch(contactsActions.onDeleteCompanyLocations(id)).then(data => {
      setLoading(false);
      if (data?.success) {
        loadLocation();
        toast.success("Location deleted");
      } else {
        toast.error(data?.message);
      }
    });
  };

  return (
    <div className='newCompanySelectWrapper'>
      {listData?.length === 0 &&
        isCollpased &&
        location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) && (
          <span className='startBtnWrapper'>
            <Button
              disabled={listUser?.length === 0}
              color='primary'
              variant='outlined'
              onClick={() => {
                document.getElementById("summary-header-loc").click();
              }}>
              Start
            </Button>
          </span>
        )}

      <AccordionContainer
        title='Company Locations'
        accordionId='summary-header-loc'
        disabled={
          location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) &&
          listUser?.length === 0
        }
        isExpanded={
          !location?.pathname?.includes(RoutesObj.NewCompanyContacts.path)
            ? true
            : false
        }
        onCollapsed={collapsed => setIsCollpased(collapsed)}
        formButton={
          isCollpased ? (
            location?.pathname?.includes(RoutesObj.NewCompanyContacts.path) &&
            listData?.length > 0 ? (
              <span className='green-tick-svg'>
                <SVGIcons.GreenTick />
              </span>
            ) : (
              !location?.pathname?.includes(
                RoutesObj.NewCompanyContacts.path
              ) && (
                <span className='companyConnectionsButtons'>
                  <Button
                    disabled={!selectedData?.id}
                    color='primary'
                    startIcon={<AddIcon />}
                    onClick={() => {
                      document.getElementById("summary-header-loc").click();
                      setIsNewAddress(true);
                      setIsEditAdr(false);
                    }}>
                    Add Locations
                  </Button>
                </span>
              )
            )
          ) : (
            <span className='companyConnectionsButtons'>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setIsNewAddress(true);
                  setIsEditAdr(false);
                }}>
                Add Locations
              </Button>
            </span>
          )
        }>
        <CompanyLocationGrid
          setIsNewAddress={e => setIsNewAddress(e)}
          isNewAddress={isNewAddress}
          setIsEditAdr={e => setIsEditAdr(e)}
          isEditAdr={isEditAdr}
          loadLocation={loadLocation}
          listData={listData}
          search={search}
          setSearch={s => {
            searching(s);
          }}
          deleteLocation={id => deleteLocation(id)}
          isLoading={isLoading}
          setLoading={r => setLoading(r)}
          listUserData={listUser}
          productCategories={productCategories}
        />
        {(!listData || listData?.length === 0) && (
          <span className='emptyGridData'>
            You haven’t added any OFish Location. Start by adding a{" "}
            {
              <i
                onClick={() => {
                  setIsNewAddress(true);
                  setIsEditAdr(false);
                }}>
                Company Location
              </i>
            }
          </span>
        )}
        {/* {listData?.length > 0 && (
          <span className='saveNNestBtnWrapper'>
            {location?.pathname?.includes(
              RoutesObj.NewCompanyContacts.path
            ) && (
              <Button
                variant='outlined'
                className='skip-button'
                onClick={() => {
                  document.getElementById("summary-header-loc").click();
                  document.getElementById("summary-header-connection").click();
                }}>
                Skip
              </Button>
            )}

            <Button
              onClick={() => {
                document.getElementById("summary-header-loc").click();
                document.getElementById("summary-header-connection").click();
              }}>
              {location?.pathname?.includes(RoutesObj.NewCompanyContacts.path)
                ? "Next"
                : "Save"}
            </Button>
          </span>
        )} */}
      </AccordionContainer>
    </div>
  );
};

export { CompanyLocations };
