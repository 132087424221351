import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getLocationsList({
  nameInternal,
  namePublic,
  dateCreated,
  pageSize,
  pageNo,
  orderByKey,
  orderByVal,
}) {
  return axios
    .post(endpoints.locations.getLocationsList, {
      nameInternal: nameInternal,
      namePublic: namePublic,
      dateCreated: dateCreated,
      pagingModel: {
        pageSize: pageSize,
        pageNo: pageNo,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function deleteLocations(ids) {
  return axios
    .post(`${endpoints.locations.deleteLocations}`, {
      ids: ids,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function addUpdateLocations(
  locationId,
  isDeleted,
  locationNameInternal,
  locationNamePublic,
  locationShortDescription,
  locationStreetAddress,
  locationCountryId,
  locationCityId,
  locationStateId,
  locationPostalCode
) {
  return axios
    .post(endpoints.locations.addUpdateLocations, {
      locationId: locationId,
      isDeleted: isDeleted,
      nameInternal: locationNameInternal,
      namePublic: locationNamePublic,
      shortDescription: locationShortDescription,
      streetAddress: locationStreetAddress,
      countryId: locationCountryId,
      cityId: locationCityId,
      stateId: locationStateId,
      postalCode: locationPostalCode,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

async function getLocationDataById(id) {
  return axios
    .post(`${endpoints.locations.getLocationDataById}`, {
      id: id,
    })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
}

export const locationsApi = {
  getLocationsList: getLocationsList,
  deleteLocations: deleteLocations,
  addUpdateLocations: addUpdateLocations,
  getLocationDataById: getLocationDataById,
};
