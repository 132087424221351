import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import {
  facebook,
  instagram,
  twitter,
  linkedin,
  planefill,
} from "../../../resources/images/index";
import {
  LogHeader,
  MainHeading,
  InnerHeader,
} from "../../../components/common/Header/index";
import { useSelector } from "react-redux";
import Cards from "../../../components/common/Cards";

const speakers = [
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
  {
    picture: "/static/images/cards/contemplative-reptile.jpg",
    subheading: "Pro Sport 6.5” Speakers",
    cardtext: "14 assets",
  },
];

function LogAllCategories(props) {
  return (
    <div className="latestReleaseDetailWrapper allCategory">
      <LogHeader />
      <MainHeading headerSubHeading="All Category">
        <div className="popupBody">
          <div className="cards">
            <Grid container spacing={3}>
              {speakers.map((item, index) => (
                <Grid key={index} item md={4}>
                  <Cards
                    picture={item.picture}
                    subheading={item.subheading}
                    cardtext={item.cardtext}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </MainHeading>
    </div>
  );
}

export default LogAllCategories;
