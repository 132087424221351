import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { toast } from "react-toastify";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useAvailabilityPeriod } from "../../../../../../utils/use-availability-period";
import { FilterCreationDateNew } from "../../../EndUser";
import {
  InputField,
  Modal,
  Spinner,
  ChipItem,
} from "../../../../../../components/common";
import { Autocomplete } from "@material-ui/lab";
import { useLoadData } from "../../../../../../utils/use-load-data";
import { endpoints } from "../../../../../../config/apiConfig";

const AddAvailabilityPeriodForm = ({
  isAddAvailabilityPeriod,
  setAddAvailabilityPeriod,
  isDuplicateAvailabilityPeriod,
  availabilityPeriodDD,
  selectedAvailabilityPeriod,
  onSuccess,
}) => {
  const { records: customerGroupsByPAPId, loadList } = useLoadData();

  const {
    isProcessing,
    availabilityPeriod,
    setAvailabilityPeriod,
    addUpdateAvailabilityPeriod,
  } = useAvailabilityPeriod({ AvailabilityPeriod: selectedAvailabilityPeriod });

  const [plaPeriodId, setplaPeriodId] = useState(null);

  const [isDupAllCustomers, setIsDupAllCustomers] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [startDate, setStartDate] = useState(
    availabilityPeriod?.startPeriodDate
  );
  const [endDate, setEndDate] = useState(availabilityPeriod?.endPeriodDate);
  const [dateErrors, setDateError] = useState({
    startDate: "",
    endDate: "",
  });
 
  useEffect(() => {
    if (availabilityPeriod?.duplicateAvailabilitityPeriod >= 0)
      setIsDupAllCustomers(availabilityPeriod?.duplicateAvailabilitityPeriod);
  }, [availabilityPeriod?.duplicateAvailabilitityPeriod]);

  useEffect(() => {
    loadList({
      url: endpoints.priceListTool.getPricingGroupsByAvailabilityPeriod,
      payload: {
        id: availabilityPeriod?.previousPAPId,
      },
    });
    setSelectedGroups([]);
    if (availabilityPeriod?.previousPAPId)
      setplaPeriodId(availabilityPeriod?.previousPAPId);
  }, [availabilityPeriod?.previousPAPId, loadList]);

  useEffect(() => {
    const sgs = availabilityPeriod?.specificGroupId?.map(g => {
      return {
        id: g?.id,
        value: g?.name,
        generic: g?.type,
      };
    });
    if (sgs && sgs.length > 0) setSelectedGroups([...sgs]);
  }, [availabilityPeriod?.specificGroupId, customerGroupsByPAPId]);

  useEffect(() => {
    if (isDuplicateAvailabilityPeriod) {
      setIsDupAllCustomers("0");
    }
  }, [isDuplicateAvailabilityPeriod]);

  function findIndexOfFirstHyphen(inputString) {
    const indexOfHyphen = inputString.indexOf("-");
    return indexOfHyphen;
  }

  const newAvailbilityPeriodValue = () => {
      availabilityPeriodDD?.forEach(
        obj => {
          const indexOfHyphen = findIndexOfFirstHyphen(obj.value);
          obj.value = indexOfHyphen === -1 ? 
          obj.value : 
          obj.value?.slice(0 , (indexOfHyphen - 1))
        }
      );
    };
  
  useEffect(() => {
      newAvailbilityPeriodValue();
    }, []);

  return (
    <Modal
      open={isAddAvailabilityPeriod}
      setOpen={setAddAvailabilityPeriod}
      title={"Add New Blank Availability Period"}
      primaryHeading='Create A Blank Availability Period'
      secondaryHeading='Type Availability Period Names and the dates of the Availability Period to create your new Availability Period without customers or pricing.'>
      {isProcessing ? <Spinner /> : null}

      <ValidatorForm
        onSubmit={async () => {
          if (!startDate || !endDate) {
            setDateError({
              startDate: "required",
              endDate: "required",
            });
            return;
          }
          if (availabilityPeriod?.periodName) {
            const userId = localStorage.getItem("id");

            const success = await addUpdateAvailabilityPeriod({
              id: availabilityPeriod?.id || 0,
              ...availabilityPeriod,
              startPeriodDate: startDate
                ? moment(startDate).format("MM/DD/YYYY HH:mm:ss")
                : "",
              endPeriodDate: endDate
                ? moment(endDate).format("MM/DD/YYYY HH:mm:ss")
                : "",
              userId: userId,
              duplicateProdAvailabilitityPeriod: Number(isDupAllCustomers),
              specificGroupId:
                `${isDupAllCustomers}` === "1" && selectedGroups?.length > 0
                  ? selectedGroups?.map(g => {
                      return {
                        id: g?.id,
                        type: g?.generic,
                      };
                    })
                  : [],
            });
            if (success) {
              toast.success(
                `Availability Period ${
                  availabilityPeriod?.id ? "updated" : "created"
                } successfully`
              );
            }
            onSuccess(success);
          } else toast.error("Please fill period data to save record");
        }}>
        <Grid container spacing={3} className='pricelistDatepicker'>
          <Grid item xs={4} className='inputWrapper userDetailsSearchField'>
            <FormControl className='ac-dd'>
              <label>
                Previous Availability Period
                {isDuplicateAvailabilityPeriod && (
                  <i className={"showRequired"}></i>
                )}
              </label>
              <Autocomplete
               freeSolo
                value={
                  plaPeriodId &&
                  availabilityPeriodDD?.find(
                    em => `${em?.key}` === `${plaPeriodId}`
                  )
                }
                onChange={(event, option) => {
                  setAvailabilityPeriod({
                    ...availabilityPeriod,
                    previousPAPId: option?.key,
                    specificGroupId: null,
                  });
                }}
                filterSelectedOptions
                id='pl-availability-period'
                options={availabilityPeriodDD}
                getOptionLabel={option => option?.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    required={isDuplicateAvailabilityPeriod}
                    placeholder={"Enter a Past Availability Period"}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <InputField
              showRequired
              inputlabel='New Availability Period Name (Internal)'
              placeholder='Enter an Availability Period Internal Name'
              id='availPeriodinternalName'
              name='availPeriodinternalName'
              validators={["required", "matchRegexp:^.{0,50}$"]}
              errorMessages={[
                "Please enter price list internal name",
                "Maximum length is 50 characters",
              ]}
              value={availabilityPeriod?.periodName}
              onChange={({ target }) => {
                setAvailabilityPeriod({
                  ...availabilityPeriod,
                  periodName: target.value.replace(/\s+/g, " ")?.trimStart(),
                });
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <InputField
              autoFocus={true}
              inputlabel='New Availability Period Name (Visible)'
              placeholder='Enter an Availability Period Visible Name'
              id='periodNameVisible'
              name='periodNameVisible'
              validators={["matchRegexp:^.{0,50}$"]}
              errorMessages={["Maximum length is 50 characters"]}
              value={availabilityPeriod?.periodNameVisible}
              onChange={({ target }) => {
                setAvailabilityPeriod({
                  ...availabilityPeriod,
                  periodNameVisible: target.value
                    .replace(/\s+/g, " ")
                    ?.trimStart(),
                });
              }}
            />
          </Grid>

          <Grid container item xs={12} justifyContent='space-between'>
            <Grid
              item
              xs={3}
              className='inputWrapper'
              style={{
                paddingRight: "15px",
              }}>
              <label className={`showRequired`}>Availability Period</label>
              <FilterCreationDateNew
                StartDate={startDate}
                setStartDate={setStartDate}
                EndDate={endDate}
                setEndDate={setEndDate}
                error={dateErrors}
                setError={setDateError}
              />
            </Grid>
            {isDuplicateAvailabilityPeriod && (
              <Grid item xs={8} className={"dup-price-list-radio-form"}>
                <FormControl>
                  <label className='slugLabel showRequired'>
                    Duplicate Data Selection
                  </label>
                  <RadioGroup
                    name='dup-price-list-data'
                    value={`${isDupAllCustomers}`}
                    onChange={({ target }) => {
                      setIsDupAllCustomers(target.value);
                    }}>
                    <FormControlLabel
                      control={<Radio />}
                      label={
                        <>
                          <b>Duplicate ALL Customers + Pricing</b> - Duplicate
                          all customers + pricing including custom Price Names,
                          MAP, MSRP, and Public/Everyone Else pricing from a
                          past Availability Period & assigns future availability.
                        </>
                      }
                      value={"0"}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label={
                        <>
                          <b>Duplicate Specific Customers + Pricing</b> -
                          Duplicate only certain customers + their pricing from
                          a Past Availability Period & assigns future
                          availability.
                        </>
                      }
                      value={"1"}
                    />
                  </RadioGroup>
                </FormControl>
                <Grid item container xs={12}>
                  <Grid
                    className='inputWrapper userDetailsSearchField'
                    style={{
                      width: "30%",
                      marginLeft: "3.5%",
                    }}>
                    <FormControl className='ac-dd filterAutocomplete'>
                      <Autocomplete
                        disabled={`${isDupAllCustomers}` !== "1"}
                        value={customerGroupsByPAPId?.filter(eg =>
                          selectedGroups?.map(sg => sg?.id).includes(eg.id)
                        )}
                        onChange={(event, value) => {
                          if (value.length > 0) setSelectedGroups(value);
                          else if (!value) {
                            setSelectedGroups([]);
                          }
                        }}
                        multiple
                        filterSelectedOptions
                        id='dup-specific-customers-ac'
                        options={customerGroupsByPAPId}
                        getOptionLabel={option => option?.value || ""}
                        renderInput={params => (
                          <TextField {...params} placeholder='Please Select' />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    style={{
                      padding: "5px 0px",
                      marginLeft: "3.5%",
                    }}>
                    {`${isDupAllCustomers}` === "1" &&
                      selectedGroups?.length > 0 &&
                      selectedGroups.map(ga => (
                        <ChipItem
                          key={ga?.value}
                          chipTitle={ga?.value}
                          chipSubTitle={ga?.generic}
                          onDelete={() => {
                            const updatedList = selectedGroups?.filter(
                              f => ga?.id !== f?.id
                            );
                            setSelectedGroups(updatedList);
                          }}
                        />
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12} justifyContent='flex-end'>
            <Button
              color='primary'
              variant='contained'
              className='dashboardButtons'
              type='submit'>
              Save
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Modal>
  );
};

export { AddAvailabilityPeriodForm };
