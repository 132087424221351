import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Activities } from "../../../../components/common";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import Loader from "../../../../components/common/Loader";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";

const ProductActivities = ({ productId, isActive }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [activites, setActivites] = useState([]);

  useEffect(() => {
    if (isActive && productId > 0) {
      setLoading(true);
      dispatch(productInventoryActions.onGetProductActivities(productId)).then(
        data => {
          setLoading(false);
          if (data) {
            setActivites(data?.data);
          }
        }
      );
    }
  }, [dispatch, isActive, productId]);

  return (
    <AccordionContainer title='Product Activities'>
      {isLoading ? <Loader /> : null}
      <Activities listData={activites} />
    </AccordionContainer>
  );
};

export { ProductActivities };
