import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Divider } from "@material-ui/core";
import { Dialog, Checkbox, Input, Grid, Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { ReactComponent as Cross } from "../../../../../resources/images/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import { contactsTypesActions } from "../../../../../store/contactTypes/contactTypesActions";
import { first, flatten } from "lodash";
const closeButtonStyle = {
  margin: "20px",
  display: "inline-block",
  fontFamily: "cursive",
  cursor: "pointer",
};
const labelStyle = {
  lineHeight: "42px",
  height: "42px",
  display: "inline-block",
  margin: "0 10px",
};
const Popup = withStyles({
  root: {
    "&.MuiDialog-paper": {
      minWidth: "80% !important",
      width: "80% !important",
    },
  },
})(Dialog);
const InputField = withStyles({
  root: {
    "&.MuiOutlinedInput-input": {},
    "&.MuiTextField-root": {
      width: "300px !important",
      background: "#FFFFFF",
      borderRadius: " 6px",
      border: "1px solid black",
    },
  },
})(TextField);
export function EditFieldWithRadioPopupPassword({
  open,
  setOpenModulesModal,
  fieldSetting,
  selectedId,
  selectedName,
  selectedFieldSetting,
}) {
  const dispatch = useDispatch();

  const { dataField } = useSelector(state => state.pubContactType);
  const [selectedRadio, setSelectedRadio] = React.useState({});
  const [selectedRadio2, setSelectedRadio2] = React.useState({
    dataFieldSettingId: 12,
    additionalData: "",
  });
  const [selectedinput, setSelectedInput] = React.useState(null);

  useEffect(() => {
    if (
      selectedFieldSetting &&
      selectedFieldSetting?.contactDataFieldSettingInfo?.length > 0
    ) {
      var inputData = fieldSetting?.fieldSettings?.[0]?.settings?.find(
        x => x.type === "input"
      );
      if (inputData) {
        const val = selectedFieldSetting?.contactDataFieldSettingInfo.find(
          x => x?.dataFieldSettingId === inputData.id
        )?.additionalData;
        setSelectedInput({
          dataFieldSettingId: inputData.fieldId,
          additionalData: val,
        });
      }
      var radioData = fieldSetting?.fieldSettings?.[0]?.settings?.find(
        x =>
          x &&
          x.type !== "input" &&
          selectedFieldSetting?.contactDataFieldSettingInfo
            ?.map(a => a?.dataFieldSettingId)
            .includes(x.id)
      );
      if (radioData) {
        setSelectedRadio({
          dataFieldSettingId: radioData.id,
          additionalData:
            selectedFieldSetting?.contactDataFieldSettingInfo.find(
              x => x.dataFieldSettingId === radioData.id
            )?.additionalData,
        });
      }
      var radioData = fieldSetting?.fieldSettings?.[1]?.settings?.find(
        x =>
          x &&
          x.type !== "input" &&
          selectedFieldSetting?.contactDataFieldSettingInfo
            ?.map(a => a?.dataFieldSettingId)
            .includes(x.id)
      );
      if (radioData) {
        setSelectedRadio2({
          dataFieldSettingId: radioData.id,
          additionalData:
            selectedFieldSetting?.contactDataFieldSettingInfo.find(
              x => x.dataFieldSettingId === radioData.id
            )?.additionalData,
        });
      }
    } else {
      var radioData = fieldSetting?.fieldSettings?.[0]?.settings?.find(
        x => x?.type !== "input"
      );
      if (radioData) {
        setSelectedRadio({
          dataFieldSettingId: radioData.id,
          additionalData:
            selectedFieldSetting?.contactDataFieldSettingInfo.find(
              x => x.dataFieldSettingId === radioData.id
            )?.additionalData,
        });
      }
    }
  }, [selectedFieldSetting]);

  const onSaveClick = () => {
    if (
      selectedRadio?.dataFieldSettingId !== 10 &&
      selectedRadio?.dataFieldSettingId !== 11 &&
      selectedRadio?.dataFieldSettingId !== 12 &&
      selectedRadio?.dataFieldSettingId !== 13
    ) {
      if (selectedRadio || selectedinput) {
        const dataModules = [];
        if (dataField?.length > 0) {
          dataField?.forEach(x => {
            if (x.fieldId === selectedId) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactDataFieldSettingInfo: [selectedRadio, selectedinput],
              };
              dataModules.push(dm);
            } else {
              dataModules.push({
                ...x,
              });
            }
          });
        } else {
          const dm = {
            fieldId: selectedId,
            fieldValue: selectedName,
            contactDataFieldSettingInfo: [selectedRadio, selectedinput],
          };
          dataModules.push(dm);
        }
        dispatch(contactsTypesActions.onChange("dataField", dataModules));
      }
    } else {
      const dataModules = [];
      if (dataField?.length > 0) {
        dataField?.forEach(x => {
          if (x.fieldId === selectedId) {
            if (selectedRadio && selectedRadio2) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactDataFieldSettingInfo: [selectedRadio, selectedRadio2],
              };
              dataModules.push(dm);
            }
            if (selectedRadio && selectedRadio2 === null) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactDataFieldSettingInfo: [selectedRadio],
              };
              dataModules.push(dm);
            }
            if (selectedRadio2 && selectedRadio === null) {
              const dm = {
                fieldId: selectedId,
                fieldValue: selectedName,
                contactDataFieldSettingInfo: [selectedRadio],
              };
              dataModules.push(dm);
            }
          } else {
            dataModules.push({
              ...x,
            });
          }
        });
      } else {
        if (selectedRadio && selectedRadio2) {
          const dm = {
            fieldId: selectedId,
            fieldValue: selectedName,
            contactDataFieldSettingInfo: [selectedRadio, selectedRadio2],
          };
          dataModules.push(dm);
        }
        if (selectedRadio && selectedRadio2 === null) {
          const dm = {
            fieldId: selectedId,
            fieldValue: selectedName,
            contactDataFieldSettingInfo: [selectedRadio],
          };
          dataModules.push(dm);
        }
        if (selectedRadio2 && selectedRadio === null) {
          const dm = {
            fieldId: selectedId,
            fieldValue: selectedName,
            contactDataFieldSettingInfo: [selectedRadio],
          };
          dataModules.push(dm);
        }
      }
      dispatch(contactsTypesActions.onChange("dataField", dataModules));
    }
    setOpenModulesModal(false);
  };
  console.log("selectedRadio", selectedRadio);
  return (
    <>
      <div className="contactPopups">
        <Popup
          style={{ maxWith: "70% !important" }}
          component="div"
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="edit-popup">
          <>
            <Grid container>
              <Grid item sx={10} md={11} lg={11}>
                <Typography
                  id="modal-modal-title"
                  style={{
                    padding: "3%",
                    width: "fit-content",
                    display: "inline-block",
                  }}
                  variant="h6"
                  component="h1">
                  {fieldSetting?.heading}
                </Typography>
                <Box
                  component="span"
                  style={{
                    color: "#F23F44",
                    fontSize: "14px",
                    fontWeight: "800",
                  }}>
                  {fieldSetting?.subHeading}
                </Box>
              </Grid>
              <Grid item sx={1}>
                <Cross
                  style={{
                    width: "15px",
                    padding: "10px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // setFieldSetting([]);
                    setOpenModulesModal(false);
                  }}
                />
              </Grid>
            </Grid>
            <Divider />
            <div className="contentDiv">
              <FormControl style={{ marginLeft: "12px" }}>
                {fieldSetting?.fieldSettings?.map((item, index) => {
                  return (
                    <>
                      {item?.settings.filter(x => x.type === "radio")?.length >
                        0 && (
                        <>
                          {item?.section && (
                            <span>
                              <strong>{item?.section}</strong>
                            </span>
                          )}
                          <RadioGroup
                            name="rg-category-type"
                            value={
                              index === 0
                                ? selectedRadio?.dataFieldSettingId
                                : selectedRadio2?.dataFieldSettingId
                            }
                            onChange={({ target }) => {
                              const selValue = parseInt(target.value);

                              if (selValue === 10 || selValue === 11) {
                                const selValue = parseInt(target.value);
                                const sel = item?.settings.find(
                                  x => x.id == selValue
                                )?.subContent;
                                setSelectedRadio({
                                  dataFieldSettingId: selValue,
                                  additionalData: sel,
                                });
                              } else {
                                const selValue = parseInt(target.value);
                                const sel = item?.settings.find(
                                  x => x.id == selValue
                                )?.subContent;
                                setSelectedRadio2({
                                  dataFieldSettingId: selValue,
                                  additionalData: sel,
                                });
                              }
                            }}>
                            {item?.settings
                              .filter(x => x.type === "radio")
                              ?.map(d => {
                                return (
                                  <FormControlLabel
                                    control={<Radio />}
                                    key={d.id}
                                    label={
                                      <span>
                                        {`${d?.content} `}
                                        <span
                                          style={{
                                            color:
                                              d?.subContent === "Required"
                                                ? "#3699FF"
                                                : d?.subContent === "Optional"
                                                ? "#25A969"
                                                : d?.subContent ===
                                                  "Recommended"
                                                ? "#3699FF"
                                                : "#25A969",
                                          }}>
                                          {d?.subContent}
                                        </span>
                                      </span>
                                    }
                                    value={d.id}
                                  />
                                );
                              })}
                          </RadioGroup>
                        </>
                      )}
                    </>
                  );
                })}
              </FormControl>
              <span className="contactPopupBtn">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => {
                    onSaveClick();
                  }}>
                  Save
                </Button>
              </span>
            </div>
          </>
        </Popup>
      </div>
    </>
  );
}
