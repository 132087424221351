import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

const PopupDialog = ({
  visible,
  onClose,
  children,
  fullScreen,
  maxWidth,
  minWidth
}) => {
  return (
    visible && (
      <Dialog
        className={`popup-dialog${minWidth ? " min-width" : ""}`}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={visible}
        onClose={onClose}>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    )
  );
};

export { PopupDialog };
