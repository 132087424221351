import { pubCompanyTypes } from "./pubCompanyTypes";
import { pubCompanyApi } from "./pubCompanyApi";

const onGetPubCompanyInfo = () => async dispatch => {
  let response = await pubCompanyApi.getCompanyInfo();
  if (response && response.success) {
    dispatch({
      type: pubCompanyTypes.PUB_COMPANY_ONCHANGE,
      payload: { prop: "companyInfo", value: response.data }
    });
  }
  return response;
};

const onGetPubCompContact = () => async dispatch => {
  let response = await pubCompanyApi.getPubComContact();
  return response;
};

const onGetPubLeaders = () => async dispatch => {
  let response = await pubCompanyApi.getPubComLeaders();
  return response;
};

export const pubCompanyActions = {
  onGetPubCompanyInfo: onGetPubCompanyInfo,
  onGetPubCompContact: onGetPubCompContact,
  onGetPubLeaders: onGetPubLeaders
};
