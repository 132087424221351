import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Notes } from "../../../../components/common";
import Loader from "../../../../components/common/Loader";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";

const ProductNotes = ({ productId, isActive }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [notes, setNotes] = useState([]);

  const loadNotes = useCallback(() => {
    if (isActive && productId > 0) {
      setLoading(true);
      dispatch(productInventoryActions.onGetProductNotes(productId)).then(
        data => {
          setLoading(false);
          if (data) {
            setNotes(data?.data);
          }
        }
      );
    }
  }, [dispatch, isActive, productId]);

  useEffect(() => {
    loadNotes();
  }, [loadNotes]);

  const handleCommnetSubmit = comment => {
    const userId = localStorage.getItem("id");
    const addNote = {
      userId: userId,
      productId: productId,
      notes: comment
    };
    setLoading(true);
    dispatch(productInventoryActions.onAddProductNotes(addNote)).then(data => {
      if (data && data.success) {
        loadNotes();
        setNoteText("");
      } else if (!data?.success) {
        toast.error(data.message);
        setLoading(false);
      }
    });
  };

  const handleNoteUpdate = note => {
    setLoading(true);
    dispatch(
      productInventoryActions.onAddProductNotes({
        ...note
      })
    ).then(data => {
      if (data && data.success) {
        loadNotes();
      } else if (!data?.success) {
        toast.error(data.message);
        setLoading(false);
      }
    });
  };

  const deleteProductNote = noteId => {
    setLoading(true);
    dispatch(productInventoryActions.onDeleteProductNotes(noteId)).then(
      data => {
        if (data && data.success) {
          loadNotes();
        } else if (!data?.success) {
          toast.error(data.message);
          setLoading(false);
        }
      }
    );
  };

  return (
    <AccordionContainer title='Product Notes'>
      {isLoading && <Loader />}

      <Notes
        noteText={noteText}
        setNoteText={setNoteText}
        notes={notes}
        onSend={cmnt => handleCommnetSubmit(cmnt)}
        onDeleteNote={deleteProductNote}
        onUpdateNote={handleNoteUpdate}
        enableMoreMenuOption={true}
      />
    </AccordionContainer>
  );
};

export { ProductNotes };
