import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Calendar from "./Calendar";
import PropTypes from "prop-types";
import "chartjs-plugin-datalabels";
import BarChart from "./BarChart";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const ChemicalHistoryChart = ({onDatePick, transformedData, onTabChange, chartData, chartLabels }) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue, transformedData);
  };

  return (
    <>
      <div className="waterTreatmentStyling">
        <div className="waterTreatmentCalendar">
          <h1>Chemical Test History</h1>
          <span>
            <Calendar handlers={onDatePick}/>
          </span>
        </div>
        <div className="waterTreatmentTabs">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="pH" {...a11yProps(0)} />
            <Tab label="chlorine" {...a11yProps(1)} />
            <Tab label="Alkalinity" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div className="borderManager">
              <BarChart labels={chartLabels} barData={chartData} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <BarChart labels={chartLabels} barData={chartData} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <BarChart labels={chartLabels} barData={chartData} />
          </TabPanel>
        </div>
      </div>
    </>
  );
};
