import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import StarIcon from "../../../../resources/images/star.png";
import { starOutline } from "../../../../resources/images/index";
import { contactsTypesActions } from "../../../../store/contactTypes/contactTypesActions";
import { EditFieldWithRadioPopup } from "./Modals/EditFieldWithRadioPopup";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

export default function FavoritesData({ searchText, areaId }) {
  const dispatch = useDispatch();
  const {
    favoritesDataField,
    favoritesConnections,
    favoritesModules,
    refreshData,
  } = useSelector(state => state.pubContactType);

  const [isLoading, setIsLoading] = useState(false);
  const [dataFields, setDataFields] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fieldSetting, setFieldSetting] = useState();
  const [openEditFieldWithRadioPopup, setOpenEditFieldWithRadioPopup] =
    useState(false);

  useEffect(() => {
    dispatch(contactsTypesActions.onGetFavouriteUser()).then(data => {
      setDataFields(data?.data?.data);
    });
  }, []);

  useEffect(() => {
    var combinedData = favoritesDataField.concat(
      favoritesModules,
      favoritesConnections
    );
    if (combinedData?.length > 0) {
      var val = combinedData?.filter(value =>
        value.fieldName.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(val);
    } else if (!searchText || searchText !== "") {
      setFilteredData(combinedData);
    }
  }, [favoritesDataField, favoritesConnections, favoritesModules, searchText]);

  const onAddUpdateMarkFavouriteContactTypes = (checked, id, type) => {
    setIsLoading(true);
    const data = [];
    if (type === "data") {
      data.push({
        contactTypeId: id,
        isFavourite: checked,
      });
      dispatch(
        contactsTypesActions.onAddUpdateMarkFavouriteContactTypes(data)
      ).then(res => {
        setIsLoading(false);
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
        }
      });
    } else if (type === "modules") {
      data.push({
        contactTypeModuleId: id,
        isFavourite: checked,
      });
      dispatch(
        contactsTypesActions.onAddUpdateMarkFavouriteContactTypesModules(data)
      ).then(res => {
        setIsLoading(false);
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
        }
      });
    } else {
      data.push({
        contactTypeConnectionId: id,
        isFavourite: checked,
      });
      dispatch(
        contactsTypesActions.onAddUpdateMarkFavouriteOfishConnections(data)
      ).then(res => {
        setIsLoading(false);
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
        }
      });
    }

    dispatch(contactsTypesActions.onChange("refreshData", !refreshData));
  };

  return (
    <>
      <Grid container className='contactTypesMainWrapper'>
        {isLoading && <Loader />}
        {filteredData?.map((item, index) => {
          return (
            item.isFavourite === true && (
              <Grid item className='mainGrid' key={index}>
                <div className='dataFieldmainWrapper'>
                  <div className='dataFieldName'>
                    <h4>
                      {item.fieldName} <span>*</span>
                      <span className='defaultValue'>
                        {item.default === true ? "OFish Default" : ""}
                      </span>
                      <span
                        onClick={() => {
                          onAddUpdateMarkFavouriteContactTypes(
                            !item.isFavourite,
                            item.id,
                            item.type
                          );
                        }}>
                        {item.isFavourite === true ? (
                          <img src={StarIcon} alt={"StarIcom"} />
                        ) : (
                          <img src={starOutline} alt={"StarIcom"} />
                        )}
                      </span>
                    </h4>
                    <p className='dataFieldDescription'>{item.description}</p>
                  </div>
                  <div>
                    {/* <Button
                    color='primary'
                    variant='contained'
                    disabled={
                      dataField.find(x => x.fieldId === item.id) ? true : false
                    }
                    startIcon={
                      dataField.find(x => x.fieldId === item.id) ? null : (
                        <Add />
                      )
                    }
                    onClick={() => {
                      const tempDataConnection = dataField;
                      let isExist = tempDataConnection.find(
                        x => x.fieldId === item.id
                      );
                      if (isExist) {
                      } else {
                        tempDataConnection.push({
                          fieldId: item.id,
                          fieldValue: item.fieldName,
                          contactDataFieldSettingInfo: [],
                        });
                      }
                      dispatch(
                        contactsTypesActions.onChange(
                          "dataField",
                          tempDataConnection
                        )
                      );
                    }}>
                    {dataField.find(x => x.fieldId === item.id)
                      ? "Added"
                      : "Add"}
                  </Button> */}
                  </div>
                </div>
              </Grid>
            )
          );
        })}
      </Grid>
    </>
  );
}
