import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { PopupDialog } from "../../../../components/common/PopupDialog";
import { Close } from "../../LeftNav/SvgIcons";

export const convTypeColorScheme = [
  {
    backgroundColor: "#015dfe",
    foregroundColor: "#021433",
  },
  {
    backgroundColor: "#00d0ff",
    foregroundColor: "#037056",
  },
  {
    backgroundColor: "#f9b900",
    foregroundColor: "#9e7705",
  },
  {
    backgroundColor: "#ff0092",
    foregroundColor: "#940457",
  },
  {
    backgroundColor: "#49b8ef",
    foregroundColor: "#024c70",
  },
  {
    backgroundColor: "#eb5757",
    foregroundColor: "#730303",
  },
  {
    backgroundColor: "#66de64",
    foregroundColor: "#047302",
  },
  {
    backgroundColor: "#0a42e9",
    foregroundColor: "#011c6b",
  },
  {
    backgroundColor: "#ef64b3",
    foregroundColor: "#6e0340",
  },
  {
    backgroundColor: "#e8edef",
    foregroundColor: "#adadad",
  },
  {
    backgroundColor: "#e2e5e9",
    foregroundColor: "#5a6672",
  },
];

export const getTextColor = convTypeColor => {
  const newColor =
    convTypeColor === "red" ? "#a31a2d" : convTypeColor === "" ? "#5a6672" : "";
  const selectedcolor = convTypeColorScheme.find(
    colorScheme => colorScheme.backgroundColor === convTypeColor
  );
  return newColor.length > 0 ? newColor : selectedcolor?.foregroundColor ?? "";
};

const ConvTypeModal = ({
  convTypes,
  oncloseaction,
  isvisible,
  maxwidth,
  selectedConversationType,
  setSelectedConversationType,
}) => {
  const [conversationType, setConversationType] = useState(
    selectedConversationType
  );

  return (
    <PopupDialog
      visible={isvisible}
      onClose={oncloseaction}
      maxWidth={maxwidth}>
      <div className='newContactPopupWrapper deletePopupMainWrapper conTypePopupWrapper'>
        <div className='newContactPopupInnerWrapper'>
          <span className='innerHeading'>Change Conversation Type</span>
          <i onClick={oncloseaction}>
            {" "}
            <Close />
          </i>
        </div>
        <div className='deleteContactBody'>
          Current Conversation Type:
          <soan>
            {conversationType?.id !== "" && (
              <Button
                color='primary'
                variant='contained'
                style={{
                  marginTop: "8px",
                  backgroundColor: conversationType?.color?.length
                    ? conversationType.color
                    : "#e2e5e9",
                  color: getTextColor(conversationType.color),
                }}>
                {conversationType?.name}
              </Button>
            )}
          </soan>
          <span className='conTypeButtonWrapper'>
            <Grid container spacing={2}>
              {convTypes?.map(ct => (
                <Grid item xs={4}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{
                      backgroundColor: ct?.generic?.length
                        ? ct.generic
                        : "#e2e5e9",
                      color: getTextColor(ct?.generic),
                    }}
                    onClick={() => {
                      setConversationType({
                        id: ct?.id,
                        name: ct?.value,
                        color: ct?.generic,
                      });
                    }}>
                    {ct?.value || "--"}
                  </Button>
                </Grid>
              ))}

              {/* <Grid item xs={4}>
                <Button color='primary' variant='contained' className='general'>
                  General
                </Button>

                <Button color='primary' variant='contained' className='support'>
                  Support
                </Button>

                <Button
                  color='primary'
                  variant='contained'
                  className='marketing'>
                  Marketing
                </Button>

                <Button color='primary' variant='contained' className='brand'>
                  My Brand
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  color='primary'
                  variant='contained'
                  className='customerSupport'>
                  Customer Support
                </Button>

                <Button
                  color='primary'
                  variant='contained'
                  className='productSupport'>
                  Product Support
                </Button>

                <Button color='primary' variant='contained' className='press'>
                  Press
                </Button>

                <Button color='primary' variant='contained' className='general'>
                  General
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button color='primary' variant='contained' className='general'>
                  General
                </Button>

                <Button color='primary' variant='contained' className='support'>
                  Support
                </Button>

                <Button
                  color='primary'
                  variant='contained'
                  className='marketing'>
                  Marketing
                </Button>

                <Button color='primary' variant='contained' className='brand'>
                  My Brand
                </Button>
              </Grid> */}
            </Grid>
          </span>
        </div>
        <span className='deleteContactActions'>
          <Button onClick={oncloseaction}>Cancel</Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              setSelectedConversationType(conversationType);
              oncloseaction();
            }}>
            Change
          </Button>
        </span>
      </div>
    </PopupDialog>
  );
};

export { ConvTypeModal };
