import React, { useState, useEffect, useCallback } from "react";
import Dashboard from "../../Dashbard";
import { Button, Divider, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/Inbox";
import { Dialog, Checkbox, Input, Grid, Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as SearchIcon } from "../../../../../resources/images/Search.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import DataFields from "../DataFields";

export function EditListingWithCheckboxPopup(props) {
  const Popup = withStyles({
    root: {
      "&.MuiDialog-paper": {
        maxWidth: "80% !important",
        width: "80% !important",
      },
   
    },
  })(Dialog);
  const InputField = withStyles({
    root: {
      "&.MuiOutlinedInput-input": {
        // height: "4px !important",
      },
      "&.MuiTextField-root": {
        // width: "90% !important",
        background: "#FFFFFF",
        borderRadius: " 6px",
        width: '100%'
      },

    },
  })(TextField);
  const list  = [
    {'Icon': '', 'Title': 'Media Contacts', 'Description': 'Manage Media Contacts & Organize your media Stategies'},
    {'Icon': '', 'Title': 'Media Contacts', 'Description': 'Manage Media Contacts & Organize your media Stategies'},
    {'Icon': '', 'Title': 'Media Contacts', 'Description': 'Manage Media Contacts & Organize your media Stategies'}
  ];
  const [openModal, setOpenModal] = React.useState(props.open);
  useEffect(() => {
    if(props.open != undefined){
      setOpenModal(true);
    }
  },[props.open]);
  return (
    <>
      <div>
        <Popup style={{maxWith:'70% !important'}} component="div"
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="edit-popup">
          <Grid container >
            <Grid item sx={10} md={11} lg={11}>
              <Typography id="modal-modal-title" style={{ padding: "3%"}} variant="h6" component="h1">
                Portals
              </Typography>
            </Grid>
            <Grid item sx={1}>
              <Box component="span"   onClick={() => setOpenModal(false)} style={{ margin: '20px', display: 'inline-block',fontFamily: 'cursive',cursor: 'pointer'}}>X</Box>
            </Grid>
          </Grid>
          <Divider />
          <div className="contentDiv" style={{padding:'0 2% 2% 2%'}}>
            <div>
              <div className="tab-navigator " style={{ display: "flex", alignItems: "center" }}>        
              <Divider /> 
                <TopTabNavigator
                  tabs={[
                    {
                      id: "1",
                      title: "All",
                      component: <> 
                       <InputField id="outlined-basic"
                          variant="outlined"  placeholder="Search by portal name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      <List>
                      {list.map((item, index) => (
                        <>
                           <Divider />
                          
                            <ListItem ContainerComponent="li">
                              <input type='checkbox' style={{marginRight:'20px'}} /> 
                              <ListItemText>
                                <Typography component="h1" style={{display:'inline',fontSize:'14px',fontWeight:'bold'}}>{item.Title} - </Typography>
                                <Typography component="span">{item.Description}</Typography>
                              </ListItemText>
                              <ListItemSecondaryAction>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                            </>
                      ))}
                    </List>
                    </>
                    },
                    {
                      id: "2",
                      title: "Your Brand's Portal",
                      component: <>abc</>,
                    },
                    {
                      id: "3",
                      title: "Your Favourites",
                      component: <DataFields />,
                    },
                    {
                      id: "4",
                      title: "Other Portals",
                      component: <DataFields />,
                    },
                  ]}
                />
              </div>
              <Button style={{float:"right"}} variant="contained" color="primary" type="button">
              Add
            </Button>
             
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
}
