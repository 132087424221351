import React, { useState } from "react";
import { Button } from "@material-ui/core";
import classnames from "classnames";

const TabNavigator = ({ tabsTitle, tabs, onAdd, onActiveTabClick }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className='tabNavigator'>
      {tabs?.map((t, i) => (
        <Button
          key={i}
          className={classnames(
            "big-tab-button",
            activeTab !== i ? "inactive" : "active"
          )}
          onClick={() => {
            setActiveTab(i);
            onActiveTabClick(i);
          }}>
          <span>{`${tabsTitle || "Record"} ${i + 1}`}</span>
        </Button>
      ))}
      <Button
        className='big-tab-button'
        onClick={() =>
          onAdd({
            index: tabs.length
          })
        }>
        <span>{`+ Add ${tabsTitle || "Record"}`}</span>
      </Button>
    </div>
  );
};

export { TabNavigator };
