import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import AddLaunchesPopup from "../../../../components/common/popUps/AddLaunchesPopup";
import { launchesActions } from "../../../../store/launches/launchesActions";
import moment from "moment";
import { useHistory } from "react-router";
import { RoutesObj } from "../../../../routes";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "30px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeaderInnerCenter: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridBodyInnerCenter: {
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
}));

function LaunchesGrid({
  launchesData,
  startLoading,
  endLoading,
  loadData,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isModal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editLaunch, setEditLaunch] = useState("");
  const { selectedLaunches } = useSelector(state => state.LaunchesReducer);

  const selectLaunches = value => {
    dispatch(launchesActions.onSelectLaunches(value));
  };

  const checkLaunchSelected = itemId => {
    const launchExist = selectedLaunches.find(launch => {
      return launch.id === itemId;
    });
    if (launchExist) {
      return true;
    } else {
      return false;
    }
  };

  const deleteLaunches = () => {
    startLoading();
    dispatch(launchesActions.onDeleteLaunches(selectedLaunches)).then(data => {
      if (data?.success) {
        loadData();
        toast.success("Deleted successfully");
      } else {
        endLoading();
        toast.error(data?.message);
      }
    });
  };

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.UpdateLaunch.path,
      state: { launch: value },
      search: `id=${value.id}`,
    });
  };

  const onItemSelectAll = () => {
    if (!selectedLaunches?.length) {
      dispatch(launchesActions.onAllSelectedLaunches(launchesData));
    } else {
      dispatch(launchesActions.onAllSelectedLaunches([]));
    }
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        {selectedLaunches?.length > 0 ? (
          <>
            <Grid item md={9} className={classes.deleteItems}>
              <span
                className='gridDeleteItems'
                onClick={() => deleteLaunches()}>
                <DeleteIcon /> Delete
              </span>
              {/* <span className='gridDeleteItems'>
                <CancelIcon /> Cancel Launch
              </span> */}
            </Grid>
            <Grid item md={3} className={classes.selectedItems}>
              <span className='gridSelection'>
                <CloseIcon
                  onClick={() =>
                    dispatch(launchesActions.onChange("selectedLaunches", []))
                  }
                />{" "}
                {selectedLaunches?.length}
              </span>{" "}
              selected
            </Grid>
          </>
        ) : null}
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont}
              onClick={onItemSelectAll}
              checked={selectedLaunches?.length === launchesData?.length && selectedLaunches?.length > 0}
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              LAUNCH PLAN 
              <TableSortLabel
                active={order.key === "launch_plan"}
                direction={order.val === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    key: "launch_plan",
                    val: order.val === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              LAUNCH DATE
              <TableSortLabel
                active={order.key === "launch_date"}
                direction={order.val === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    key: "launch_date",
                    val: order.val === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              STARTED BY
              <TableSortLabel
                active={order.key === "started_by"}
                direction={order.val === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    key: "started_by",
                    val: order.val === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInnerCenter}>
              STATUS
              <TableSortLabel
                active={order.key === "status"}
                direction={order.val === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    key: "status",
                    val: order.val === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {launchesData?.length > 0 &&
            launchesData?.map((item, index) => {
              return (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell className={classes.gridHeaderInner}>
                    <Checkbox
                      onClick={() => selectLaunches(item)}
                      className={classes.checkBoxFont}
                      checked={checkLaunchSelected(item.id)}
                    />
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.launch_plan}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {moment(item.launch_date).format("MMMM DD, YYYY")}
                  </TableCell>
                  <TableCell className={(classes.gridBodyInner , classes.gridBodyInnerCenter)}>
                    {item.started_by ?? "--"}
                  </TableCell>
                  <TableCell
                    className={
                      (classes.gridBodyInner, classes.gridBodyInnerCenter)
                    }>
                    <span
                      className={
                        item.status === ""
                          ? "eventPendingBtn"
                          : "eventLaunchedBtn"
                      }>
                      {item.status === "" ? "Planning" : "Launched"}
                    </span>
                  </TableCell>

                  {/* <TableCell className={classes.gridBodyInnerCenter}>
                    <Button
                      variant='contained'
                      className={classes.pendingButtons}>
                      Inprogress
                    </Button>
                  </TableCell> */}
                  <TableCell className='editButton'>
                    <span
                      onClick={() => redirectUrl(item)}
                      style={{
                        cursor: "pointer",
                      }}
                      // onClick={() => {
                      //   setEditLaunch(item);
                      //   setIsModal(true);
                      //   setIsEdit(true);
                      // }}
                    >
                      <EditIcon />
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          {!launchesData?.length ? (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='6'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t created any release launches.
                </span>
              </TableCell>
           </TableRow>
          ) : null}
        </TableBody>
      </Table>
      {isModal && (
        <AddLaunchesPopup
          openPopup={isModal}
          closePopup={() => setIsModal(false)}
          startLoading={() => startLoading()}
          endLoading={() => endLoading()}
          loadData={() => loadData()}
          editData={editLaunch}
          isEdit={isEdit}
        />
      )}
    </Paper>
  );
}

export default LaunchesGrid;
