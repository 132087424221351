import { Button, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
  ActionContent,
  Modal,
  SearchAndFilter,
} from "../../../../../../components/common";
// import { Pagination } from "../../../../../../components/common/Pagination";

const PriceListAccessModal = ({
  isAccess,
  setIsAccess,
  isPreAccess,
  userGroups = [],
  groupAccessLevel = [],
  onAdd = () => {},
}) => {
  const [selectedGroups, setSelectedGroups] = useState(groupAccessLevel);
  const [filterByVal, setFilterByVal] = useState("");
  const [filterBySelectedTab, setFilterBySelectedTab] = useState("");

  useEffect(() => {
    return () => setFilterByVal("");
  }, []);

  useEffect(() => {
    onAdd(selectedGroups);
  }, [selectedGroups]);

  const filterList = useCallback(() => {
    let filteredList = userGroups?.filter(
      g =>
        g?.value?.toLowerCase().includes(filterByVal?.toLowerCase()) ||
        g?.generic?.toLowerCase().includes(filterByVal?.toLowerCase())
    );
    if (filterBySelectedTab) {
      filteredList = filteredList?.filter(g =>
        g?.generic?.toLowerCase().includes(filterBySelectedTab?.toLowerCase())
      );
    }
    return filteredList;
  }, [filterBySelectedTab, filterByVal, userGroups]);
  
  return (
    <Modal
      open={isAccess}
      setOpen={setIsAccess}
      title={"Provide Users Price List Access"}
      primaryHeading='Choose Customers to Provide Price List Access To:'
      secondaryHeading='Select the customers you want to be able to view and/or download price lists from the portals they have access to.'>
      <Grid
        container
        spacing={2}
        style={{
          height: "390px",
          overflow: "auto",
          display: "block",
        }}>
        <SearchAndFilter
          placeholderText={"Search for contacts, companies, groups & more"}
          onFilterButtonClick={val => setFilterByVal(val)}
          selectedTab={filterBySelectedTab}
          onTabButtonClick={selectedTab =>
            setFilterBySelectedTab(selectedTab?.generic)
          }
        />

        <Grid container item xs={12}>
          {filterList()?.map(ucg => {
            const sgRecord = selectedGroups?.find(
              sg =>
                sg?.groupId === ucg.id &&
                sg?.isPreReleaseAccessGroup === isPreAccess
            );
            return (
              <Grid item xs={6}>
                <ActionContent
                  IsCustomer={true}
                  IsAdded={sgRecord ? true : false}
                  IsFullAccess={sgRecord?.isAllAccess}
                  name={ucg?.value}
                  description={ucg?.generic}
                  onAccessChange={accessLvl => {
                    const updatedSGs = selectedGroups.map(slg => {
                      if (slg?.groupId === ucg.id) {
                        const isFullAccess = Boolean(accessLvl);
                        return {
                          ...slg,
                          isAllAccess: isFullAccess,
                          isViewOnlyAccess: !isFullAccess,
                          canDownloadCSVAccess: isFullAccess,
                          canDownloadPDFAccess: isFullAccess,
                          isMakeViewable: true,
                        };
                      } else
                        return {
                          ...slg,
                        };
                    });
                    setSelectedGroups(updatedSGs);
                  }}
                  onAction={() => {
                    const newUCG = [
                      ...selectedGroups,
                      {
                        id: 0,
                        groupId: ucg.id,
                        type: ucg?.generic,
                        isPreReleaseAccessGroup: isPreAccess,
                        isAllAccess: true,
                        isViewOnlyAccess: true,
                        canDownloadCSVAccess: true,
                        canDownloadPDFAccess: true,
                        isMakeViewable: true,
                      },
                    ];
                    setSelectedGroups([...newUCG]);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <div>&nbsp;</div>
        <div className='bottomPageWrapper'>
          {/* <Pagination /> */}
          <p className='addCount'>{`${
            selectedGroups.filter(
              s => s.isPreReleaseAccessGroup === isPreAccess
            ).length
          } added`}</p>
          <Button
            color='primary'
            variant='contained'
            className='dashboardButtons'
            onClick={() => {
              onAdd(selectedGroups);
              setIsAccess(!isAccess);
            }}>
            Add Access
          </Button>
        </div>
      </span>
    </Modal>
  );
};

export { PriceListAccessModal };
