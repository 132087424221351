import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton, Typography } from "@material-ui/core";
import { Close, SortIcon } from "../../../LeftNav/SvgIcons";
import { useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import SelectedProductGrid from "./SelectedProductGrid";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import { ItemImage, Pagination } from "../../../../../components/common";
import { productInventoryActions } from "../../../../../store/product/productInventoryAction";
import { toast } from "react-toastify";
import { endUserActions } from "../../../../../store/endUser/endUserActions";
import { debounce } from "lodash";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "6px",
    width: "1100px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 80,
    borderBottom: "2px solid #E2E5E9",
    paddingRight: 20,
    paddingLeft: 28,
    background: "#00A3FF",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  newPricingTypo: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#fff",
  },
  inenerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  content: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#434D56",
    padding: "16px 0px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 32,
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  productContent: {
    padding: "32px",
  },
  contentHeading: {
    color: "#181C32",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "700",
  },

  innerBody: {
    height: "calc(100vh - 410px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  modalConetent: {
    padding: "0px 32px 32px",
  },
  tableHeading: {
    fontWeight: 700,
    fontSize: 14,
    color: "#181C32",
    paddingBottom: 9,
  },

  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  popupSelectedButton:{
    backgroundColor:"#18bc6d",
  },
  popupSelectButton:{
    backgroundColor:"#3699FF",
   },
}));

const allTabs = [
  {
    id: "all",
    title: "All Products",
    component: "",
  },
  // {
  //   id: "Favorites",
  //   title: "Favorites",
  //   component: "",
  // },
  // {
  //   id: "Your Products",
  //   title: "Your Products",
  //   component: "",
  // },
];

const SelectProductModal = ({
  open,
  setOpenModal,
  onCloseProductModal,
  deviceOverviewInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeTabId, setActiveTabId] = useState("all");
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "ProductName",
    orderVal: 0,
  });
  const [products, setProducts] = useState([]);
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const filteredFavorites = favoriteProducts?.filter(x =>
    x.productName.toLowerCase().includes(search.toLowerCase())
  );
  const filteredUserUserProducts = userProducts?.filter(x =>
    x.productName.toLowerCase().includes(search.toLowerCase())
  );

  const loadData = () => {
    dispatch(
      productInventoryActions.onGetAllProducts({
        pageNumber: page,
        noOfRecoreds: rowsPerPage,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
        filters: {
          product: "",
          categoryId: null,
          dateCreated: null,
          status: null,
        },
      })
    ).then(data => {
      setProducts(data?.data?.data || []);
      if (data.success) {
        setPaginationResponse(data?.data?.pagination);
      } else {
        if (data.message.includes("No Data Found")) {
        } else toast.error("Something went wrong, Please try later.");
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
    });
  };
  const loadDataFavouriteProducts = () => {
    dispatch(productInventoryActions.onGetAllFavouriteProducts({})).then(
      data => {
        if (data?.success) {
          setFavoriteProducts(data?.data || []);
        } else {
          if (data?.message.includes("No Data Found")) {
          } else toast.error("Something went wrong, Please try later.");
        }
      }
    );
  };

  const loadDataUserProducts = () => {
    dispatch(productInventoryActions.onGetAllUserProducts({})).then(data => {
      if (data?.success) {
        setUserProducts(data?.data || []);
      } else {
        if (data?.message.includes("No Data Found")) {
        } else toast.error("Something went wrong, Please try later.");
      }
    });
  };

  useEffect(() => {
    loadDataFavouriteProducts();
    loadDataUserProducts();
  }, []);

  useEffect(() => {
    loadData();
  }, [order.orderByKey, order.orderVal, page, rowsPerPage]);

  const onSelectProduct = product => {
    dispatch(endUserActions.onChangeOfRegister("slectedDevice", product));
    setOpenModal(false);
    };

  const changeHandler = (event, activeTabId) => {
    setLoading(true);
    if (activeTabId === "all") {
      dispatch(
        productInventoryActions.onGetAllProducts({
          pageNumber: page,
          noOfRecoreds: rowsPerPage,
          orderByKey: order.orderByKey,
          orderByVal: order.orderVal,
          filters: {
            product: event.target.value,
            categoryId: null,
            dateCreated: null,
            status: null,
          },
        })
      ).then(data => {
        setProducts(data?.data?.data || []);
        if (data.success) {
          setPaginationResponse(data?.data?.pagination);
        } else {
          if (data.message.includes("No Data Found")) {
          } else toast.error("Something went wrong, Please try later.");
          setPaginationResponse({
            currentCount: 0,
            totalRecords: 0,
          });
        }
      });
    } else if (activeTabId === "Favorites") {
      setSearch(event.target.value);
    } else {
      setSearch(event.target.value);
    }
    setLoading(false);
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 1000), []);
  console.log({ loading });
  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={onCloseProductModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.header}>
              <div className={classes.inenerContent}>
                <Typography className={classes.newPricingTypo}>
                  Select Product
                </Typography>
              </div>
              <IconButton
                className={classes.closIcon}
                onClick={onCloseProductModal}>
                <Close />
              </IconButton>
            </div>

            <div className={classes.productContent}>
              <Typography className={classes.contentHeading}>
                Select Products
              </Typography>
              <Typography className={classes.content}>
                Select a product to register.
              </Typography>
              <div className='selectedProductContainer'>
                <SelectedProductGrid
                  search={search}
                  onSearch={(event, activeTabId) => {
                    debouncedChangeHandler(event, activeTabId);
                    setSearch(event.target.value);
                  }}
                  activeTabId={activeTabId}
                  loading={loading}
                />
                <Grid>
                  <TopTabNavigator
                    getActiveTabId={tabId => {
                      setActiveTabId(`${tabId}`);
                      setSearch("");
                      // handleTabChange(tabId);
                    }}
                    tabs={[...allTabs]}
                    defaultTabId={activeTabId}
                    className='tabsHeader'
                  />
                </Grid>
              </div>
            </div>
            {activeTabId === "all" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 32px",
                  }}>
                  <Typography className={classes.tableHeading}>
                    All Products
                  </Typography>
                  <span className=''>
                    {(page - 1) * rowsPerPage + 1} -&nbsp;
                    {rowsPerPage * page > paginationResponse?.totalRecords
                      ? paginationResponse?.totalRecords
                      : rowsPerPage * page}{" "}
                    of&nbsp;
                    {paginationResponse?.totalRecords}
                  </span>
                </div>
                <div className={classes.innerBody}>
                  <div className={classes.modalConetent}>
                    <Paper>
                      <Table>
                        <TableHead className={classes.gridHeader}>
                          <TableRow className={classes.gridHeaderRow}>
                            <TableCell className={classes.gridHeaderInner}>
                              Product Name
                              <TableSortLabel
                                active={order.orderByKey === "ProductName"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "ProductName",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                                IconComponent={SortIcon}
                                className='sortIcon'
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              SKU
                              <TableSortLabel
                                active={order.orderByKey === "SKU"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "SKU",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                                IconComponent={SortIcon}
                                className='sortIcon'
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              Brand Name
                              <TableSortLabel
                                active={order.orderByKey === " BrandName"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "BrandName",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                                IconComponent={SortIcon}
                                className='sortIcon'
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              Category
                              <TableSortLabel
                                active={order.orderByKey === "Category"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "Category",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                                IconComponent={SortIcon}
                                className='sortIcon'
                              />
                            </TableCell>
                            <TableCell
                              className={classes.gridHeaderInner}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {products?.map((item, index) => {
                            return (
                              <TableRow key={index} className='gridBodyRow'>
                                <TableCell className={classes.gridBodyInner}>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                    <ItemImage
                                      imgSrc={item?.productMainImage}
                                    />
                                    <span>{item.productName}</span>
                                  </span>
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  {item.sku}
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  {item.brand ?? "--"}
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  <span>{item?.category || "-"}</span>
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  <span>
                                    <Button
                                      color='primary'
                                      variant='contained'
                                      className={deviceOverviewInfo?.slectedDevice === item ? classes.popupSelectedButton : classes.popupSelectButton}
                                      onClick={() =>onSelectProduct(item)}>
                                      {deviceOverviewInfo?.slectedDevice ===
                                      item
                                        ? "selected"
                                        : "Select"}
                                    </Button>
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {products.length <= 0 && (
                            <TableRow key='0' className='gridBodyRow'>
                              <TableCell
                                colSpan='7'
                                className={classes.gridBodyInner}
                                style={{ textAlign: "center" }}>
                                <span className='emptyGridText'>
                                  Uh Oh! No products to display.
                                </span>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </div>
                </div>
                <div className={classes.footer}>
                  <Pagination
                    recordCount={paginationResponse?.totalRecords}
                    page={page - 1}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </div>
              </>
            ) : null}
            {activeTabId === "Favorites" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 32px",
                  }}>
                  <Typography className={classes.tableHeading}>
                    Favorite
                  </Typography>
                </div>

                <div className={classes.innerBody}>
                  <div className={classes.modalConetent}>
                    <Paper>
                      <Table>
                        <TableHead className={classes.gridHeader}>
                          <TableRow className={classes.gridHeaderRow}>
                            <TableCell className={classes.gridHeaderInner}>
                              Product Name
                              <TableSortLabel
                                active={order.orderByKey === "ProductName"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "ProductName",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              SKU
                              <TableSortLabel
                                active={order.orderByKey === "SKU"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "SKU",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              Brand Name
                              <TableSortLabel
                                active={order.orderByKey === " BrandName"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "BrandName",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              Category
                              <TableSortLabel
                                active={order.orderByKey === "Category"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "Category",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell
                              className={classes.gridHeaderInner}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredFavorites?.map((item, index) => {
                            return (
                              <TableRow key={index} className='gridBodyRow'>
                                <TableCell className={classes.gridBodyInner}>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                    <ItemImage
                                      imgSrc={item?.productMainImage}
                                    />
                                    <span>{item.productName}</span>
                                  </span>
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  {item.sku}
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  {item.brand ?? "--"}
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  <span>{item?.category || "-"}</span>
                                </TableCell>

                                <TableCell className={classes.gridBodyInner}>
                                  <span>
                                    <Button
                                      color='primary'
                                      variant='contained'
                                      className={deviceOverviewInfo?.slectedDevice === item ? classes.popupSelectedButton : classes.popupSelectButton}
                                      onClick={() => onSelectProduct(item)}>
                                      {deviceOverviewInfo?.slectedDevice ===
                                      item
                                        ? "selected"
                                        : "Select"}
                                    </Button>
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {filteredFavorites.length <= 0 && (
                            <TableRow key='0' className='gridBodyRow'>
                              <TableCell
                                colSpan='7'
                                className={classes.gridBodyInner}
                                style={{ textAlign: "center" }}>
                                <span className='emptyGridText'>
                                  Uh Oh! No products to display.
                                </span>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </div>
                </div>
              </>
            ) : null}
            {activeTabId === "Your Products" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 32px",
                  }}>
                  <Typography className={classes.tableHeading}>
                    Your Products
                  </Typography>
                </div>
                <div className={classes.innerBody}>
                  <div className={classes.modalConetent}>
                    <Paper>
                      <Table>
                        <TableHead className={classes.gridHeader}>
                          <TableRow className={classes.gridHeaderRow}>
                            <TableCell className={classes.gridHeaderInner}>
                              Product Name
                              <TableSortLabel
                                active={order.orderByKey === "ProductName"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "ProductName",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              SKU
                              <TableSortLabel
                                active={order.orderByKey === "SKU"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "SKU",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              Brand Name
                              <TableSortLabel
                                active={order.orderByKey === " BrandName"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "BrandName",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell className={classes.gridHeaderInner}>
                              Category
                              <TableSortLabel
                                active={order.orderByKey === "Category"}
                                direction={
                                  order.orderVal === 1 ? "asc" : "desc"
                                }
                                onClick={() =>
                                  setOrderBy({
                                    orderByKey: "Category",
                                    orderVal: order.orderVal === 0 ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell
                              className={classes.gridHeaderInner}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredUserUserProducts?.map((item, index) => {
                            return (
                              <TableRow key={index} className='gridBodyRow'>
                                <TableCell className={classes.gridBodyInner}>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                    <ItemImage
                                      imgSrc={item?.productMainImage}
                                    />
                                    <span>{item.productName}</span>
                                  </span>
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  {item.sku}
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  {item.brand ?? "--"}
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  <span>{item?.category || "-"}</span>
                                </TableCell>
                                <TableCell className={classes.gridBodyInner}>
                                  <span>
                                    <Button
                                      color='primary'
                                      variant='contained'
                                      className={deviceOverviewInfo?.slectedDevice === item ? classes.popupSelectedButton : classes.popupSelectButton}
                                      onClick={() => onSelectProduct(item)}>
                                      {deviceOverviewInfo?.slectedDevice ===
                                      item
                                        ? "selected"
                                        : "Select"}
                                    </Button>
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {filteredUserUserProducts.length <= 0 && (
                            <TableRow key='0' className='gridBodyRow'>
                              <TableCell
                                colSpan='7'
                                className={classes.gridBodyInner}
                                style={{ textAlign: "center" }}>
                                <span className='emptyGridText'>
                                  Uh Oh! No products to display.
                                </span>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default SelectProductModal;
