import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Radio } from "@material-ui/core";
import { conversationAction } from "../../../../../store/conversations/conversationAction";
import Loader from "../../../../../components/common/Loader";
import { toast } from "react-toastify";

function Notifications(props) {
  const [isLoading, setLoading] = useState(false);
  const [isGGlobal, setIsGGlobal] = useState(false);
  const [isPGlobal, setIsPGlobal] = useState(false);
  const [isSuGlobal, setIsSuGlobal] = useState(false);
  const [isSGlobal, setIsSGlobal] = useState(false);

  const [isYourGGlobal, setIsYourGGlobal] = useState(false);
  const [isYourPGlobal, setIsYourPGlobal] = useState(false);
  const [isYourSuGlobal, setIsYourSuGlobal] = useState(false);
  const [isYourSGlobal, setIsYourSGlobal] = useState(false);

  const [typesList, setTypesList] = useState([]);
  const dispatch = useDispatch();
  const { hubId } = useSelector(state => state.conversationReducer);

  useEffect(() => {
    dispatch(
      conversationAction.onGetAllCampaigns({
        productId: 2,
      })
    ).then(data => {
      if (data?.responseMsg === "Success") {
        setTypesList(data?.responseData?.length > 0 ? data?.responseData : []);
      }
    });
  }, []);

  const onButtonPress = () => {
    setLoading(true);
    var modal = {
      productId: 2,
      hubUserId: hubId,
      productNotificationSettings: [
        {
          campaignId: 1,
          isGlobal: isGGlobal,
        },
        {
          campaignId: 2,
          isGlobal: isPGlobal,
        },
        {
          campaignId: 4,
          isGlobal: isSuGlobal,
        },
        {
          campaignId: 5,
          isGlobal: isSGlobal,
        },
      ],
      userNotificationSettings: [
        {
          campaignId: 1,
          isGlobal: isYourGGlobal,
        },
        {
          campaignId: 2,
          isGlobal: isYourPGlobal,
        },
        {
          campaignId: 4,
          isGlobal: isYourSuGlobal,
        },
        {
          campaignId: 5,
          isGlobal: isYourSGlobal,
        },
      ],
    };
    dispatch(
      conversationAction.onAddUpdateProductNotificationSettings(modal)
    ).then(data => {
      setLoading(false);
      toast.success(data?.responseMsg);
      dispatch(conversationAction.onChange("isPopupOpen", false));
    });
  };
  return (
    <div className="conversationNotificationWrapper">
      {isLoading ? <Loader /> : null}
      <div className="conversationNotificationInnerWrapper">
        <h2>Notifications Settings</h2>
        <span>
          Set up how you want to receive notifcations both company-wide and you
          indivdually.
        </span>

        <Grid container item xs={12} spacing={3}>
          <Grid item md={6}>
            <div className="notificationContent">
              <div>
                <h4>Company Conversation Notification Settings</h4>
                <span>
                  These Will Override personal user settings Company-wide
                </span>
                {typesList.map(item => {
                  return (
                    <>
                      {item.name === "General Conversations" &&
                      item.isEnabled ? (
                        <div className="notificationItemWrapper">
                          <Button className="generalBtn">General</Button>
                          <div className="itemHeading">
                            <span>
                              {" "}
                              <Radio
                                checked={isGGlobal === false}
                                onChange={() => {
                                  setIsGGlobal(false);
                                }}
                              />
                              <b>Within Ofish</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                            <span>
                              {" "}
                              <Radio
                                checked={isGGlobal === true}
                                onChange={() => {
                                  setIsGGlobal(true);
                                }}
                              />
                              <b>Desktop browser</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.name === "Press Conversations" && item.isEnabled ? (
                        <div className="notificationItemWrapper">
                          <Button className="pressBtn">Press</Button>
                          <div className="itemHeading">
                            <span>
                              {" "}
                              <Radio
                                checked={isPGlobal === false}
                                onChange={() => {
                                  setIsPGlobal(false);
                                }}
                              />
                              <b>Within Ofish</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                            <span>
                              {" "}
                              <Radio
                                checked={isPGlobal === true}
                                onChange={() => {
                                  setIsPGlobal(true);
                                }}
                              />
                              <b>Desktop browser</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.name === "Support Conversations " &&
                      item.isEnabled ? (
                        <div className="notificationItemWrapper">
                          <Button className="supportBtn">Support</Button>
                          <div className="itemHeading">
                            <span>
                              {" "}
                              <Radio
                                checked={isSuGlobal === false}
                                onChange={() => {
                                  setIsSuGlobal(false);
                                }}
                              />
                              <b>Within Ofish</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                            <span>
                              {" "}
                              <Radio
                                checked={isSuGlobal === true}
                                onChange={() => {
                                  setIsSuGlobal(true);
                                }}
                              />
                              <b>Desktop browser</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.name === "Sales Conversations" && item.isEnabled ? (
                        <div className="notificationItemWrapper">
                          <Button className="salesBtn">Sales</Button>
                          <div className="itemHeading">
                            <span>
                              {" "}
                              <Radio
                                checked={isSGlobal === false}
                                onChange={() => {
                                  setIsSGlobal(false);
                                }}
                              />
                              <b>Within Ofish</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                            <span>
                              {" "}
                              <Radio
                                checked={isSGlobal === true}
                                onChange={() => {
                                  setIsSGlobal(true);
                                }}
                              />
                              <b>Desktop browser</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </Grid>

          <Grid item md={6}>
            <div className="notificationContent">
              <div>
                <h4>Your Individual Conversation Notification Settings</h4>
                <span>These Only Apply To You</span>
                {typesList.map(item => {
                  return (
                    <>
                      {item.name === "General Conversations" &&
                      item.isEnabled ? (
                        <div className="notificationItemWrapper">
                          <Button className="generalBtn">General</Button>
                          <div className="itemHeading">
                            <span>
                              {" "}
                              <Radio
                                checked={isYourGGlobal === false}
                                onChange={() => {
                                  setIsYourGGlobal(false);
                                }}
                              />
                              <b>Within Ofish</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                            <span>
                              {" "}
                              <Radio
                                checked={isYourGGlobal === true}
                                onChange={() => {
                                  setIsYourGGlobal(true);
                                }}
                              />
                              <b>Desktop browser</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.name === "Press Conversations" && item.isEnabled ? (
                        <div className="notificationItemWrapper">
                          <Button className="pressBtn">Press</Button>
                          <div className="itemHeading">
                            <span>
                              {" "}
                              <Radio
                                checked={isYourPGlobal === false}
                                onChange={() => {
                                  setIsYourPGlobal(false);
                                }}
                              />
                              <b>Within Ofish</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                            <span>
                              {" "}
                              <Radio
                                checked={isYourPGlobal === true}
                                onChange={() => {
                                  setIsYourPGlobal(true);
                                }}
                              />
                              <b>Desktop browser</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.name === "Support Conversations " &&
                      item.isEnabled ? (
                        <div className="notificationItemWrapper">
                          <Button className="supportBtn">Support</Button>
                          <div className="itemHeading">
                            <span>
                              {" "}
                              <Radio
                                checked={isYourSuGlobal === false}
                                onChange={() => {
                                  setIsYourSuGlobal(false);
                                }}
                              />
                              <b>Within Ofish</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                            <span>
                              {" "}
                              <Radio
                                checked={isYourSuGlobal === true}
                                onChange={() => {
                                  setIsYourSuGlobal(true);
                                }}
                              />
                              <b>Desktop browser</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.name === "Sales Conversations" && item.isEnabled ? (
                        <div className="notificationItemWrapper">
                          <Button className="salesBtn">Sales</Button>
                          <div className="itemHeading">
                            <span>
                              {" "}
                              <Radio
                                checked={isYourSGlobal === false}
                                onChange={() => {
                                  setIsYourSGlobal(false);
                                }}
                              />
                              <b>Within Ofish</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                            <span>
                              {" "}
                              <Radio
                                checked={isYourSGlobal === true}
                                onChange={() => {
                                  setIsYourSGlobal(true);
                                }}
                              />
                              <b>Desktop browser</b> - Notify me with in-app
                              notifications within OFish of new coversations and
                              replies{" "}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Button
        color="#F5F8FA"
        variant="contained"
        autoFocus
        type="submit"
        onClick={() => {
          dispatch(conversationAction.onChange("activeTabId", `${4}`));
        }}
        style={{
          bottom: 0,
          position: "absolute",
          right: "120px",
          margin: "20px",
        }}>
        <span style={{ color: "#7E8299", fontSize: "12px" }}>Go Back</span>
      </Button>
      <Button
        color="primary"
        variant="contained"
        autoFocus
        type="submit"
        onClick={() => {
          onButtonPress();
        }}
        style={{ bottom: 0, position: "absolute", right: "0", margin: "20px" }}>
        Finish Setup
      </Button>
    </div>
  );
}

export default Notifications;
