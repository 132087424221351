import React from "react";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const AddButtonWithLabel = ({
  isRequired = false,
  label = "Creating",
  onClick = () => {},
}) => {
  return (
    <span className='AddButtonWithLabel'>
      <label
        className={`bold-regular-label${isRequired ? " showRequired" : ""}`}>
        {label}
      </label>

      <Button color='primary' variant='outlined' onClick={onClick}>
        <Add />
      </Button>
    </span>
  );
};

export { AddButtonWithLabel };
