import React from "react";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { connector } from "../../resources/images/index";
import { useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import { newsroomActions } from "../../store/newsroom/newsroomActions";

const ProductCarousel = () => {
  const dispatch = useDispatch();
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    dispatch(newsroomActions.onGetAllNewsRoomContentByRoomId()).then(data => {
      if (data?.success) {
        setBanner(data?.data);
      }
    });
  }, []);
  return (
    <Carousel>
      {banner?.map(item => {
        return (
          <div className="slideWrapper">
            <div className="slideContent">
              <span className="title">
                <h3>{item.slideTitle}</h3>
                <p>{item.slideSubTitle}</p>
              </span>
            </div>
            <div className="slidePicture">
              <img src={item.bannerImageUrl} alt="" />
            </div>
          </div>
        );
      })}
      {/* <div className="slideWrapper">
        <div className="slideContent">
          <span className="title">
            <h3>
              Aquatic AV Announces Launch of RGB Controller &amp; Aquatic AV App
            </h3>
            <p>
              New RGB Controller has more power than the competition and works
              with the all-new, sleek and powerful Aquativ AV App
            </p>
          </span>
        </div>
        <div className="slidePicture">
          <img src={connector} alt="" />
        </div>
      </div>
      <div className="slideWrapper">
        <div className="slideContent">
          <span className="title">
            <span className="title">
              <h3>
                Aquatic AV Announces Launch of RGB Controller &amp; Aquatic AV
                App
              </h3>
              <p>
                New RGB Controller has more power than the competition and works
                with the all-new, sleek and powerful Aquativ AV App
              </p>
            </span>
          </span>
        </div>
        <div className="slidePicture">
          <img src={connector} alt="" />
        </div>
      </div>
      <div className="slideWrapper">
        <div className="slideContent">
          <span className="title">
            <span className="title">
              <h3>
                Aquatic AV Announces Launch of RGB Controller &amp; Aquatic AV
                App
              </h3>
              <p>
                New RGB Controller has more power than the competition and works
                with the all-new, sleek and powerful Aquativ AV App
              </p>
            </span>
          </span>
        </div>
        <div className="slidePicture">
          <img src={connector} alt="" />
        </div>
      </div> */}
    </Carousel>
  );
};

export default ProductCarousel;
