import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Grid, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@material-ui/lab";
import { useLocation } from "react-router-dom";
import { AccordionContainer, Spinner } from "../../../../components/common";
import { ConvTemplateModal } from "./ConvTemplateModal";
import { conversationAction } from "../../../../store/conversations/conversationAction";
import { ConversationConfig } from "../../../../config/conversationConfig";
import {
  EndUserStarFill,
  EndUserStarOutLine,
  FilterArrow,
} from "../../LeftNav/SvgIcons";
import searchIcon from "../../../../resources/images/searchIcon.png";
import { useDebounce } from "../../../../utils/use-debounce";
import { getTextColor } from "./ConvTypeModal";

const getDate = msgDate => {
  const today = new Date();
  const messageDate = new Date(msgDate);

  const timeDifference = today.getTime() - messageDate.getTime();
  const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));

  if (hoursDifference < 24) {
    const month = messageDate.getMonth() + 1;
    const year = messageDate.getFullYear();
    const date = messageDate.getDate();
    const timeZone = messageDate.toLocaleTimeString();
    return `today,${month}/${date}/${year},${timeZone}`;
  }

  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  if (daysDifference <= 14) {
    return `${daysDifference} d Ago`;
  }

  const month = messageDate.toLocaleString("default", { month: "short" });
  const year = messageDate.getFullYear();
  const date = messageDate.getDate();
  const timeZone = messageDate.toLocaleTimeString();
  return `${month} ${date}, ${year}, ${timeZone}`;
};

const ConversationCampaign = memo(
  ({
    conversationStatus,
    hubId,
    campaign,
    index,
    onChatClick,
    selectedRoom,
    isFavoriteRoom,
    filterByInbox,
    setIsStar,
  }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { loadCurrentListing, selectedAssignee } = useSelector(
      state => state.conversationReducer
    );
    // const intervalId = useRef(null);

    const [isLoading, setLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [rooms, setRooms] = useState([]);
    const [totalRooms, setTotalRooms] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    const [isMouseOver, setIsMouseOver] = useState(null);
    const [filterValue, setFilterValue] = useState("");
    const messagePerPage = 10;

    const { debouncedValue } = useDebounce(filterValue, 500);

    const loadConversationRoomsByUserId = useCallback(
      ({ campaignId, selectedRm }) => {
        // console.log({ campaignId, hubId })
        if (hubId !== 0) {
          setLoading(true);
          dispatch(
            conversationAction.onGetUserRoomsByUsersId({
              roomName: debouncedValue,
              productId: ConversationConfig.productId,
              isFavorite:
                campaignId === "Favorite" || campaignId === "Favourite"
                  ? true
                  : null,
              hubUserId: campaignId === "YourBrand" ? 0 : hubId,
              pageNo: selectedPage,
              take: messagePerPage,
              campaignId: ["All", "YourBrand", "Favorite"].includes(campaignId)
                ? 0
                : parseInt(campaignId),
              status: conversationStatus,
              assignee:
                location.pathname === "/assigned-conversations"
                  ? localStorage.getItem("id")
                  : location.pathname === "/unassigned-conversations" ? "-1" : "",
              inbox: filterByInbox || "",
              name: "",
            })
          ).then(response => {
            if (response?.responseMsg?.includes("Success")) {
              setRooms(response?.responseData?.data);
              setTotalRooms(response?.responseData?.count);
              dispatch(
                conversationAction.onChange(
                  "roomsData",
                  response?.responseData?.data
                )
              );
              if (selectedRm?.roomId) {
                const slctedOne = response?.responseData?.data.find(
                  d => d.roomId === selectedRm.roomId
                );
                if (slctedOne) {
                  onChatClick(slctedOne);
                }
              }
            } else console.log("else response : ", response);
            setLoading(false);
            dispatch(conversationAction.onChange("loadCurrentListing", false));
          });
        }
      },
      [
        dispatch,
        hubId,
        selectedAssignee,
        selectedPage,
        conversationStatus,
        debouncedValue,
        location.pathname,
      ]
    );

    useEffect(() => {
      if (!isCollapsed && campaign?.id) {
        loadConversationRoomsByUserId({
          campaignId: campaign?.id,
        });
      }
    }, [
      campaign?.id,
      isCollapsed,
      loadConversationRoomsByUserId,
      isFavoriteRoom,
    ]);

    useEffect(() => {
      if (loadCurrentListing && !isCollapsed && campaign?.id) {
        loadConversationRoomsByUserId({
          campaignId: campaign?.id,
          selectedRm: selectedRoom,
        });
      }
    }, [loadCurrentListing, selectedRoom]);

    // useEffect(() => {
    //   intervalId.current = setInterval(
    //     () => loadConversationRoomsByUserId({ campaignId: campaign?.id }),
    //     3000
    //   );
    //   return () => {
    //     clearInterval(intervalId.current);
    //   };
    // }, [campaign?.id]);

    const handleMouseOver = roomId => {
      setIsMouseOver(roomId);
    };

    const handleMouseLeave = () => {
      setIsMouseOver(null);
    };

    const onFiterRoomName = value => {
      setFilterValue(value);
    };

    function truncate(item) {
      if (item && item.length > 15) {
        return item.substring(0, 13) + "...";
      }
      return item;
    }

    return (
      <AccordionContainer
        key={index}
        title={campaign?.value}
        isExpanded={index === 0}
        onCollapsed={isCollapesd => setIsCollapsed(isCollapesd)}>
        {isLoading ? <Spinner /> : null}
        <Grid container xs={12}>
          <Grid
            item
            md={12}
            className='filterMainSearch endUserSearchFilterMain'>
            <img src={searchIcon} alt='' className='searchIcon' />
            <input
              type='text'
              value={filterValue}
              placeholder='Search'
              onChange={e => onFiterRoomName(e.target.value)}
            />

            {/* <span className='endUserSearchFilter'>
              <FilterArrow color={"#fff"} />
            </span> */}
          </Grid>
        </Grid>
        {rooms.map(item => {
          return (
            <div
              className='innerWrapper'
              key={item.roomId}
              onClick={() => {
                onChatClick(item);
                dispatch(
                  conversationAction.onChange("conversationPlateform", item)
                );
              }}>
              <div className='startCheckboxWrapper'>
                {/* <div className="activeEndUser">
                  <ActiveEndUserIcon />
                </div> */}

                <div className='userNamenPicWrapper'>
                  <span
                    className='endUserConversationProfile'
                    onClick={() => setIsStar(!item?.is_Favourite, item?.roomId)}
                    onMouseOver={() => handleMouseOver(item.roomId)}
                    onMouseLeave={() => handleMouseLeave(item.roomId)}>
                    {isMouseOver === item.roomId ? (
                      item?.is_Favourite ? (
                        <EndUserStarFill />
                      ) : (
                        <EndUserStarOutLine />
                      )
                    ) : (
                      <span className='userPicture'>
                        {item.pic ? (
                          <img src={item.pic} alt='' />
                        ) : (
                          <i className='userInitials'>{`${item?.chatInfo[0]?.name ?
                            item?.chatInfo[0]?.name
                            ?.charAt(0)
                            ?.toUpperCase() : ""}`}</i>
                        )}
                      </span>
                    )}
                  </span>
                  {item?.messageAlert === true ? (
                    <i className='socialIcons'></i>
                  ) : null}
                  <span
                    className={`${
                      item?.messageAlert === true ? "newmassage" : ""
                    } chatUserDetail`}>
                    {item?.chatInfo[0]?.name === "" ||
                    item?.chatInfo[0]?.name === null ||
                    item?.chatInfo[0]?.name === undefined
                      ? "--"
                      : item?.chatInfo[0]?.name}
                    <i
                    // dangerouslySetInnerHTML={{
                    //   __html: item.lastMessage?.content,
                    // }}
                    >
                      {/* {truncate(item.lastMessage?.content)} */}
                      {(item?.roomName === null ||
                      item?.roomName === "" ||
                      item?.roomName === undefined) && item?.platform === ""
                        ? "AQUATIC AV - " + item?.roomId
                        : item?.roomName + " - " + item?.roomId}
                    </i>
                  </span>
                </div>
              </div>

              <div className='dealerButtonWrapper'>
                <Tooltip
                  title={
                    item.campaignName.length ? item.campaignName : "Unassigned"
                  }
                  placement='top'>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => {}}
                    style={{
                      background: item.color?.length ? item.color : "#e2e5e9",
                      color: getTextColor(item.color),
                    }}>
                    <span style={{ color: getTextColor(item.color) }}>
                      {item.campaignName.length
                        ? truncate(item.campaignName)
                        : "Unassigned"}
                    </span>
                  </Button>
                </Tooltip>
                <span className='dateNTime'>
                  <span>{getDate(item.lastMessage?.date)}</span>
                  {/* <i className='infoSource'>{item.infoSource ?? "--"}</i> */}
                </span>
              </div>
            </div>
          );
        })}
        <span className='chatPagination'>
          <Pagination
            // showFirstButton
            // showLastButton
            count={Math.ceil(totalRooms / messagePerPage)}
            onChange={(_, page) => setSelectedPage(page - 1)}
          />
        </span>
      </AccordionContainer>
    );
  }
);

function ConversationListing({
  filterByInbox,
  conversationStatus,
  onChatClick,
  selectedRoom,
  key,
  setLoading,
  isTemplateModal,
  setIsTemplate,
  setAddTemplate,
  isFavoriteRoom,
  setRoomConversationCategories,
  setIsStar,
}) {
  const dispatch = useDispatch();

  const [conversationCategories, setConversationCategories] = useState([]);
  const [template, setTemplate] = useState([]);
  const [filterByVal, setFilterByVal] = useState("");
  const { debouncedValue } = useDebounce(filterByVal, 500);
  const { hubId } = useSelector(state => state.conversationReducer);

  useEffect(() => {
    dispatch(
      conversationAction.onGetCampaignsDdl({
        allowedOnCampaignsId: "",
        productId: 2,
        hubUserId: hubId,
      })
    ).then(res => {
      if (res?.responseMsg === "Success") {
        setConversationCategories(res?.responseData);
        setRoomConversationCategories(res?.responseData);
        // setCampaignsDdl(res?.responseData.filter(x => x.value.trim() !== ""));
      }
    });
  }, [dispatch, hubId]);

  useEffect(() => {
    dispatch(
      conversationAction.onGetTemplate({
        search: debouncedValue,
        templateType: 1,
        productId: ConversationConfig.productId,
        pagination: {
          pageSize: 10,
          pageNo: 1,
          orderByKey: "",
          orderByVal: 0,
        },
      })
    ).then(response => {
      if (response?.responseMsg?.includes("Success")) {
        setTemplate(response?.responseData);
      } else console.log("response : ", response);
    });
  }, [dispatch, hubId , debouncedValue]);

  const onAddTemplate = data => {
    setLoading(true);
    const userId = localStorage.getItem("id");
    dispatch(
      conversationAction.onAddUpdateTemplates({
        id: data?.id ? data?.id : 0,
        productId: ConversationConfig.productId,
        templateType: 1,
        title: data?.title,
        content: data?.content,
        userId: userId,
        hubUserId: `${hubId}`,
      })
    ).then(response => {
      setLoading(false);
      if (response?.responseMsg?.includes("Success")) {
        setAddTemplate(data);
        setIsTemplate(false);
      } else console.log("response : ", response);
    });
  };
  return (
    <div className='conversationListingWrapper' key={key}>
      {conversationCategories?.map((cc, index) => (
        <ConversationCampaign
          conversationStatus={conversationStatus}
          key={cc?.id}
          index={index}
          campaign={cc}
          hubId={hubId}
          onChatClick={onChatClick}
          selectedRoom={selectedRoom}
          isFavoriteRoom={isFavoriteRoom}
          filterByInbox={filterByInbox}
          setIsStar={setIsStar}
        />
      ))}

      {isTemplateModal && (
        <ConvTemplateModal
          isOpen={isTemplateModal}
          setIsOpen={x => setIsTemplate(x)}
          portals={template}
          setFilterByVal={setFilterByVal}
          priceListPortals={[]}
          onAdd={x => {
            onAddTemplate(x);
          }}
        />
      )}

      {/* <AccordionContainer title='All Unassigned'>
        <Grid container xs={12}>
          <Grid
            item
            md={12}
            className='filterMainSearch endUserSearchFilterMain'>
            <img src={searchIcon} alt='' className='searchIcon' />
            <input type='text' placeholder='Search' />

            <span className='endUserSearchFilter'>
              <FilterArrow color={"#fff"} />
            </span>
          </Grid>
        </Grid>
        {listingDate.map((item, index) => {
          return (
            <div className='innerWrapper' key={item.id}>
              <div className='startCheckboxWrapper'>
                <div className='userNamenPicWrapper'>
                  <span className='userPicture'>
                    {item.pic ? (
                      <img src={item.pic} alt='' />
                    ) : (
                      <i className='userInitials'>{`${item?.name
                        ?.charAt(0)
                        ?.toUpperCase()}`}</i>
                    )}
                  </span>
                  {item.messageAlert === true ? (
                    <i className='socialIcons'></i>
                  ) : null}
                  <span
                    className={`${
                      item.messageAlert === true ? "newmassage" : ""
                    } chatUserDetail`}>
                    {item.name} <i>{item.messagesubject}</i>
                  </span>
                </div>
              </div>

              <div className='dealerButtonWrapper'>
                <Button
                  variant='outlined'
                  color='primary'
                  // onClick={onclickHandler}
                  style={{
                    background: `${
                      item.campaignId === 0
                        ? `${item.color}`
                        : item.campaignId === 1
                        ? `${item.color}`
                        : item.campaignId === 2
                        ? `${item.color}`
                        : item.campaignId === 3
                        ? `${item.color}`
                        : item.campaignId === 4
                        ? `${item.color}`
                        : item.campaignId === 5
                        ? `${item.color}`
                        : item.campaignId === 6
                        ? `${item.color}`
                        : " "
                    }`,
                  }}
                  className={`${
                    item.campaignId === 0
                      ? "unassigned"
                      : item.campaignId === 1
                      ? "general"
                      : item.campaignId === 2
                      ? "press"
                      : item.campaignId === 3
                      ? "customerSupport"
                      : item.campaignId === 4
                      ? "support"
                      : item.campaignId === 5
                      ? "marketing"
                      : "general"
                  }`}>
                  {item.campaignId === 0
                    ? "Unassigned"
                    : item.campaignId === 1
                    ? "General"
                    : item.campaignId === 2
                    ? "Press"
                    : item.campaignId === 3
                    ? "Customer Support"
                    : item.campaignId === 4
                    ? "Support"
                    : item.campaignId === 5
                    ? "Marketing"
                    : "General"}
                </Button>

                <span className='dateNTime'>
                  <span>Today, {currentDate}</span>
                  <i className='infoSource'>{item.infoSource}</i>
                </span>
              </div>
            </div>
          );
        })}
        <span className='chatPagination'>
          <Pagination count={10} />
        </span>
      </AccordionContainer> */}
    </div>
  );
}

export default ConversationListing;
