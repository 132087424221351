import React, { useState, useEffect, useCallback } from "react";
// import { CircleSlider } from "react-circle-slider";
import { MyRoundSlider } from "../../../../components/common/RoundSlider/RoundSlider";
import Grid from "@material-ui/core/Grid";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { Button, Typography } from "@material-ui/core";
import { Addjust, Increment, Decrement } from "./SvgIcons";

export default function TempratureCard({ isActive }) {
  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);
  const [temprature, setTemprature] = useState(94);
  const [tempratureRange, setTempratureReange] = useState({
    min: 84,
    max: 104,
  });
  const [tempratureScale, setTempratureScale] = useState("F");
  const [isCurrentTemprature , setIsCurrentTemprature] = useState("");

  useEffect(() => {
    if (isActive) {
      setTempratureScale(spaTemperatureSettings?.temperatureControl === 1 ? "F" : "C")
      setTemprature(
        (!spaTemperatureSettings?.setTemperature || spaTemperatureSettings?.setTemperature <= 84)
          ? 84 : spaTemperatureSettings?.setTemperature
      );
      setIsCurrentTemprature((!spaTemperatureSettings?.currentTemperature || spaTemperatureSettings?.currentTemperature === 0)
        ? 100 : spaTemperatureSettings?.currentTemperature);
    }
  }, [isActive]);
  const decreaseTemp = (e) => {
    e && e.preventDefault();
    if (tempratureScale === "F") {
      if (temprature - 1 >= tempratureRange.min) {
        const aa = temprature - 1;
        setTemprature(temprature - 1);
        onIncDecDebounce(aa);
      }
    } else {
      const aa = temprature - 1;
      setTemprature(temprature - 1);
      onIncDecDebounce(aa);
    }
  };
  const increseTemp = (e) => {
    e && e.preventDefault();
    if (tempratureScale === "F") {
      if (temprature + 1 <= tempratureRange.max) {
        const newTemprature = temprature + 1;
        setTemprature(temprature + 1);
        onIncDecDebounce(newTemprature);
      } 
    } else {
      const newTemprature = temprature + 1;
        setTemprature(temprature + 1);
        onIncDecDebounce(newTemprature);
    }
  };
  const onUpdateTemVal = (value) => {
    const tempPayload = {
      deviceId: spaTemperatureSettings.deviceId,
      setTemperature: value,
    };
    dispatchTempCall(tempPayload);
  };
  const dispatchTempCall = (value) => {
    setIsloading(true);
    dispatch(endUserActions.onUpdataTempSetting(value)).then((res) => {
      if (res?.success) {
        dispatch(
          endUserActions.onGetSpaInfo(spaTemperatureSettings.deviceId)
        ).then((data) => {
          setIsloading(false);
          if (data?.success === false) {
            toast.error(data?.message);
          } else {
            toast.success("Value updated successfully.");
          }
        });
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };


  const setLockStatus = (value) => {
    const lockPayload = {
      deviceId: spaTemperatureSettings.deviceId,
      isTemperatureLock: value,
    };
    dispatchTempCall(lockPayload);
  };

  const onIncDecDebounce = useCallback(debounce(onUpdateTemVal, 1000), []);
  const { spaTemperatureSettings } = useSelector(
    (state) => state.endUserReducer
  );
  return (
    <Grid item xs={12}>
      {isLoading ? <Loader /> : null}
      <Grid className="gridSpaTempratureWrapper">
        <Grid className="gridSpaTempratureHeading">
          <span className="pumpsHeading">Current Temperature</span>
        </Grid>
        <Grid className="gridSpaTempratureWrapperBody">
          <Grid className="tempManger ">
            <Grid className="gridTempratureInfoSection">
              <div className="currentTemprature">
              {spaTemperatureSettings?.temperatureControl === 1 ? isCurrentTemprature +"F": Math.floor(((isCurrentTemprature - 32) * 5) / 9)+"C"}&deg;
              </div>
              <div>
                <p className="tempDescription">Current Water Temperature</p>
                <Typography className="spaTempAddjustBtn">
                  <Addjust />
                  ADJUSTING
                </Typography>
              </div>
              <div className="tempratureButtonControls">
                <div className="tempratureButtonOutline">
                  <Button
                    onClick={(e) => {
                      spaTemperatureSettings?.isTemperatureLock === false && spaTemperatureSettings?.isMaintenanceLock === false &&
                        decreaseTemp(e);
                    }}
                    disabled={temprature <= 84}
                  >
                    <Decrement style={{ color: temprature <= 84 ? "#b7b7b7" : "#3699FF"  }} />
                  </Button>
                  <b className="tempratureScale">
                  {spaTemperatureSettings?.temperatureControl === 1 ? temprature +"F": Math.round(((temprature - 32) * 5) / 9)+"C"}&deg;
                  </b>
                  <Button
                    onClick={(e) => {
                      spaTemperatureSettings?.isTemperatureLock === false && spaTemperatureSettings?.isMaintenanceLock === false &&
                        increseTemp(e);
                    }}
                    disabled={temprature >= 104}
                  >
                    <Increment style={{ color: temprature >= 104 ? "#b7b7b7" : "#3699FF"  }} />
                  </Button>
                </div>
              </div>
              <div className="tempratureLockIcons">
                {spaTemperatureSettings?.isTemperatureLock === true ? (
                  <LockOutlinedIcon
                    onClick={(e) => {
                      spaTemperatureSettings?.isMaintenanceLock === false && (
                        setLockStatus(false))
                    }}
                    style={{
                      color: "rgb(54 153 255)",
                      fontSize: "40",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <LockOpenOutlinedIcon
                    onClick={(e) => {
                      spaTemperatureSettings?.isMaintenanceLock === false && (
                        setLockStatus(true))
                    }}
                    style={{
                      color: "rgb(54 153 255)",
                      fontSize: "40",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
