import React, { useState, useEffect, useCallback } from "react";
import Dashboard from "../../Dashbard";
import { Box, Button, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/Inbox";
import { Dialog, Checkbox, Input, Grid, Typography } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ReactComponent as SearchIcon } from "../../../../../resources/images/Search.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import DataFields from "../DataFields";

const closeButtonStyle = {
  margin: "20px",
  display: "inline-block",
  fontFamily: "cursive",
  cursor: "pointer",
};
const labelStyle = {
  lineHeight: "42px",
  height: "42px",
  display: "inline-block",
  margin: "0 10px",
};
export function EditPasswordFieldPopup(props) {
  const Popup = withStyles({
    root: {
      "&.MuiDialog-paper": {
        maxWidth: "80% !important",
        width: "80% !important",
      },
    },
  })(Dialog);
  const InputField = withStyles({
    root: {
      "&.MuiOutlinedInput-input": {
        //  height: "4px !important",
      },
      "&.MuiTextField-root": {
        width: "300px !important",
        background: "#FFFFFF",
        borderRadius: " 6px",
        border: "1px solid black",
      },
    },
  })(TextField);
  const [openModal, setOpenModal] = React.useState(props.open);
  useEffect(() => {
    if (props.open != undefined) {
      setOpenModal(true);
    }
  }, [props.open]);
  return (
    <>
      <div>
        <Popup
          style={{ maxWith: "70% !important" }}
          component='div'
          open={openModal}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          className='edit-popup'>
          <Grid container>
            <Grid item sx={10} md={11} lg={11}>
              <Typography
                id='modal-modal-title'
                style={{
                  padding: "3%",
                  width: "fit-content",
                  display: "inline-block",
                }}
                variant='h6'
                component='h1'>
                Select OFish Contact Area
              </Typography>
            </Grid>
            <Grid item sx={1}>
              <Box
                component='span'
                onClick={() => setOpenModal(false)}
                style={closeButtonStyle}>
                X
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <div style={{ padding: "3%" }}>
            <div style={{ padding: "1% 0" }}>
              <FormControl>
                <RadioGroup name='required' column>
                  <FormControlLabel
                    key='require'
                    value='Require'
                    control={<Radio size='medium' />}
                    label={
                      <>
                        <span>Contact is Required to have a password</span>{" "}
                        <span style={{ marginLeft: "5px", color: "#3699FF" }}>
                          {" "}
                          Required
                        </span>
                      </>
                    }
                  />
                  <FormControlLabel
                    key='notrequire'
                    value='DontRequire'
                    control={<Radio size='medium' />}
                    label={
                      <>
                        <span>Contact is NOT Required to have a password</span>{" "}
                        <span style={{ marginLeft: "5px", color: "#25A969" }}>
                          {" "}
                          Optional
                        </span>
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Box
              component='label'
              style={{
                borderBottom: "1px solid black",
                display: "inline-block",
              }}>
              Password Security Rules
            </Box>
            <div style={{ padding: "1% 0" }}>
              <FormControl>
                <RadioGroup name='required' column>
                  <FormControlLabel
                    key='require'
                    value='Require'
                    control={<Radio size='medium' />}
                    label={
                      <>
                        <span>Require a Secure Password</span>{" "}
                        <span style={{ marginLeft: "5px", color: "#25A969" }}>
                          {" "}
                          Required
                        </span>
                      </>
                    }
                  />
                  <FormControlLabel
                    key='notrequire'
                    value='DontRequire'
                    control={<Radio size='medium' />}
                    label={
                      <>
                        <span>Don't Require a Secure Password</span>{" "}
                        <span style={{ marginLeft: "5px", color: "#FFA800" }}>
                          {" "}
                          Optional
                        </span>
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <Button
              style={{ position: "absolute", right: "20px", bottom: "20px" }}
              variant='contained'
              color='primary'
              type='button'>
              Save
            </Button>
          </div>
        </Popup>
      </div>
    </>
  );
}
