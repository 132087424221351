import React, { useRef, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MultiSelectCotactFilter from "./MultiSelectContactFilter";
import InputField from "../../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import { searchIcon } from "../../../../resources/images";
import { useDispatch, useSelector } from "react-redux";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { FiChevronDown } from "react-icons/fi";
import { locationsActions } from "../../../../store/locations/locationsActions";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    paddingTop: "40px",
    paddingBottom: "15px",
    borderBottom: "1px solid #E2E6E9",
    position: "relative",
  },
  dropdownBg: {
    background: "#f1f1f1",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    border: "1px solid #F3F6F9",
    backgroundColor: "#F3F6F9",
    borderRadius: "6px",
    "&:before": {
      borderBottom: "1px solid #F3F6F9",
      "&:hover": {
        borderBottom: "0 !important",
      },
    },
    "&:after": {
      borderBottom: "1px solid #F3F6F9",
    },

    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9",
      },
    },
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px"
  }
}));

function GridFilter({ loadData }) {
  const dispatch = useDispatch();
  const [nameInternal, setNameInternal] = useState("");
  const [namePublic, setNamePublic] = useState("");
  const [dateCreated, setDateCreated] = useState(0);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { selectedFileType, copyAllContacts } = useSelector(
    (state) => state.ContactsReducer
  );


  
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  const setSearch = (value) => {
    setNameInternal(value);
    setNamePublic(value);
  };
  const onPressFilterBtn = () => {
    dispatch(locationsActions.onChange("nameInternal", nameInternal));
    dispatch(locationsActions.onChange("namePublic", namePublic));
    dispatch(locationsActions.onChange("dateCreated", dateCreated));
    setAnchorEl(null);
    // loadData();
  };
  const onPressResetBtn = (internal, name, date) => {
    dispatch(locationsActions.onChange("nameInternal", internal));
    dispatch(locationsActions.onChange("namePublic", name));
    dispatch(locationsActions.onChange("dateCreated", date));
    setAnchorEl(null);
    // loadData();
  };
  return (
    <div className={classes.gridWrapper}>
      <ValidatorForm ref={useRef("form")}>
        <Grid container spacing={2}>
          <Grid item md={10} className="filterMainSearch">
            <img src={searchIcon} alt="" className="searchIcon" />
            <InputField
              placeholder="Search"
              value={nameInternal}
              onChange={(v) => setSearch(v.target.value)}
            />
          </Grid>

          <Grid item md={2} className="searchByFilter">
            <span 
              onClick={handleClick}
            >
              Search by Filter <FiChevronDown />
            </span>
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}>
            <Paper  className={classes.dropdownWrapper}>
            <Grid
                container
                spacing={2}
                style={{ display: "flex", alignItems: "center" }}>
                 <Grid item xs={7} className="filterBtnLabel">
                Filter by{" "}
              </Grid>
                <Grid item xs={5}>
                <span className="filterButtons" style={{float: 'right'}}>
                    <Button
                      variant="outlined"
                      onClick={() => onPressFilterBtn()}
                    >
                      Filter
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setNameInternal("");
                        setNamePublic("");
                        setDateCreated(0);
                        onPressResetBtn("", "", 0);
                      }}
                    >
                      Clear
                    </Button>
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className="inputWrapper">
                    <FormControl className={classes.formControl}>
                      <label>{""}</label>
                      <Select
                        value={dateCreated}
                        onChange={(e) => setDateCreated(e.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        variant={"filled"}
                        required
                      >
                        <MenuItem value="0">All Uploads</MenuItem>
                        <MenuItem value="1">Within Month</MenuItem>
                        <MenuItem value="2">Within Last Month</MenuItem>
                        <MenuItem value="3">Within Past 3 Months</MenuItem>
                        <MenuItem value="4">Within Past 6 Months</MenuItem>
                        <MenuItem value="5">Within Past Last Year</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                 
                </Grid>
           
              </Grid>
            </Paper>
            </Popover>
        {/* {isFilterShow && (
          <>
            <span className="filterDropdown">
              <Grid
                container
                spacing={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs={7} className="filterBtn">
                  Filter by{" "}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className="inputWrapper">
                    <FormControl className={classes.formControl}>
                      <label>{""}</label>
                      <Select
                        value={dateCreated}
                        onChange={(e) => setDateCreated(e.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        variant={"filled"}
                        required
                      >
                        <MenuItem value="0">All Uploads</MenuItem>
                        <MenuItem value="1">Within Month</MenuItem>
                        <MenuItem value="2">Within Last Month</MenuItem>
                        <MenuItem value="3">Within Past 3 Months</MenuItem>
                        <MenuItem value="4">Within Past 6 Months</MenuItem>
                        <MenuItem value="5">Within Past Last Year</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
               
                </Grid>
                <Grid item xs={4}>
                  <span className="filterButtons">
                    <Button
                      variant="outlined"
                      onClick={() => onPressFilterBtn()}
                    >
                      Filter
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setNameInternal("");
                        setNamePublic("");
                        setDateCreated(0);
                        onPressResetBtn("", "", 0);
                      }}
                    >
                      Clear
                    </Button>
                  </span>
                </Grid>
              </Grid>
            </span>
          </>
        )} */}
      </ValidatorForm>
    </div>
  );
}

export default GridFilter;
