import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
import SelectInput from "../../../../../components/common/SelectInput";
import { Button, Switch } from "@material-ui/core";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import SelectImage from "./selectImage";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import { launchesActions } from "../../../../../store/launches/launchesActions";
import { useDispatch, useSelector } from "react-redux";
import BusinessHours from "./BusinessHours";
import ContactEmail from "./ContactEmail";
import SocialMedia from "./SocialMedia";
import ContactNumber from "./ContactNumber";
import Loader from "../../../../../components/common/Loader";
import { toast } from "react-toastify";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { Close, Add, Search, Clear } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "6px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  hoursWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function GeneralSettings({ startLoading, stopLoading }) {
  const dispatch = useDispatch();
  const [newsRoomNameInternal, setNewsRoomNameInternal] = useState("");
  const [newsRoomNameExternal, setNewsRoomNameExternal] = useState("");
  const [newsRoomURL, setNewsRoomURL] = useState("");
  const [newsRoomStatus, setNewsRoomStatus] = useState(true);
  const [viewOnlyGroup, setViewOnlyGroup] = useState([]);
  const [viewAndDownloadGroup, setViewAndDownloadGroup] = useState([]);
  const [isDomainSetting, setDomainSetting] = useState(false);
  const [isAddVisibilityGroup, setIsAddVisibilityGroup] = useState(false);
  const [visibilityGroups, setVisibilityGroups] = useState([]);
  const [filteredvisibilityGroups, setfFlteredVisibilityGroups] =
    useState(visibilityGroups);
  const [selectedVisibilityGroups, setSelectedVisibilityGroups] = useState([]);
  const [tempVisibilityGroups, setTempVisibilityGroups] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();

  // const loadData = () => {
  //   setLoading(true);
  //   dispatch(newsroomActions.onGetSettings()).then((cnt) => {
  //     if (cnt && cnt.success && cnt.data) {
  //       setNewsRoomNameInternal(cnt.data.internalName);
  //       setNewsRoomNameExternal(cnt.data.externalName);
  //       setNewsRoomURL(cnt.data.newsRoomURL);
  //       setNewsRoomStatus(cnt.data.newsRoomStatus);
  //       setViewOnlyGroup(cnt.data.viewOnlyGroup);
  //       setViewAndDownloadGroup(cnt.data.viewAndDownloadGroup);
  //     } else {
  //       toast.error(cnt?.message);
  //     }
  //     setLoading(false);
  //   });
  // };

  // useEffect(() => {
  //   loadData();
  // }, []);

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(newsroomActions.onGetSettings()).then((cnt) => {
      if (cnt && cnt.success && cnt.data) {
        setNewsRoomNameInternal(cnt.data.internalName);
        setNewsRoomNameExternal(cnt.data.externalName);
        setNewsRoomURL(cnt.data.newsRoomURL);
        setNewsRoomStatus(cnt.data.newsRoomStatus);
        setViewOnlyGroup(cnt.data.viewOnlyGroup);
        setViewAndDownloadGroup(cnt.data.viewAndDownloadGroup);
      } else {
        toast.error(cnt?.message);
      }
      setLoading(false);
    });
  }, [dispatch]);

  const loadGroups = useCallback(() => {
    setLoading(true);
    dispatch(launchesActions.onGetAllUserGroup()).then((data) => {
      setVisibilityGroups(data?.data);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    loadData();
    if (isAddVisibilityGroup) {
      loadGroups();
    }
  }, [isAddVisibilityGroup, loadGroups, loadData]);

  const onUpdateSettings = () => {
    startLoading();
    dispatch(
      newsroomActions.onSaveSettings(
        newsRoomNameInternal,
        newsRoomNameExternal,
        newsRoomURL,
        newsRoomStatus,
        viewOnlyGroup,
        viewAndDownloadGroup
      )
    ).then((data) => {
      if (data && data.success) {
        toast.success("Settings successfully updated");
        setDomainSetting(false);
      } else {
        toast.error(data?.message);
      }
      stopLoading();
    });
  };

  return (
    <div className="accordianOuterWrapper">
      {isLoading ? <Loader /> : null}
      <AccordionContainer title="General Settings">
        <div className={classes.root}>
          <Grid
            item
            xs={12}
            className={classes.gridWrapper}
            style={{ textAlign: "right", marginTop: "-50px" }}
          >
            <label className="switchLabel">Newsroom Status</label>
            <Switch
              checked={newsRoomStatus}
              onChange={(e) => setNewsRoomStatus(e.target.checked)}
            />
          </Grid>
          <Grid item xs={9} className={classes.gridWrapper}>
            <InputField
              placeholder="AAV Main Newsroom"
              inputlabel="Newsroom Name (Internal)"
              value={newsRoomNameInternal}
              onChange={(e) => setNewsRoomNameInternal(e.target.value)}
              validators={["matchRegexp:^.{0,50}$"]}
              errorMessages={["Maximum length is 50 characters"]}
            />
          </Grid>
          <Grid item xs={9} className={classes.gridWrapper}>
            <InputField
              placeholder="Aquatic AV Newsroom"
              inputlabel="Newsroom Name (External)"
              value={newsRoomNameExternal}
              onChange={(e) => setNewsRoomNameExternal(e.target.value)}
              validators={["matchRegexp:^.{0,100}$"]}
              errorMessages={["Maximum length is 100 characters"]}
            />
          </Grid>
          {isDomainSetting ? (
            <Grid item xs={9} className={classes.gridWrapper}>
              <InputField
                placeholder="https://newsroom.aquaticav.com"
                inputlabel="Newsroom URL"
                value={newsRoomURL}
                onChange={(e) => setNewsRoomURL(e.target.value)}
                validators={["matchRegexp:^.{0,500}$"]}
                errorMessages={["Maximum length is 500 characters"]}
              />
            </Grid>
          ) : (
            <Grid item xs={9} className={classes.gridWrapper}>
              <h6
                style={{
                  fontSize: "15px",
                  color: "#2D3339",
                  fontWeight: 500,
                  marginBottom: "6px",
                  minHeight: "19px",
                  padding: "0px",
                }}
              >
                Newsroom URL
              </h6>
              <span
                style={{
                  fontSize: "14px",
                  color: "#2D3339",
                  fontWeight: 400,
                  marginBottom: "6px",
                  padding: "0px",
                }}
              >
                {newsRoomURL !== ""
                  ? newsRoomURL
                  : "https://newsroom.aquaticav.com"}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  color: "#3699FF",
                  fontWeight: 500,
                  marginLeft: "10px",
                  cursor: "pointer",
                  marginBottom: "6px",
                  padding: "0px",
                }}
                onClick={() => setDomainSetting(true)}
              >
                Domain Settings
              </span>
              {/* {address.length > 100 && (
              <span style={{ color: "red" }}>
                address characters length cannot be exceed from 100 characters
              </span>
            )} */}
            </Grid>
          )}
        </div>
        <span className="saveChangesBtn">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onUpdateSettings()}
          >
            Save Changes
          </Button>
        </span>
      </AccordionContainer>
      <AccordionContainer title="Newsroom Access">
        <div className={classes.root}>
          <Grid item xs={12} className={classes.gridWrapper}>
            <div className="addReleasesGroups">
              <Button
                color="primary"
                variant="outlined"
                // startIcon={<Add />}
                // onClick={() => setIsAddVisibilityGroup(true)}
              >
                Add Access
              </Button>
            </div>
            <Typography className="customCheckboxWrapper">
              Control which business groups, companies, or media contacts have
              access to view non-public content assigned to them and download
              content from the newsroom. Newsroom Access is different than
              Release and Pre-Release Visibility. All users with an Aquatic AV
              account will still show logged-in but will not have full access.
              Learn More
            </Typography>
          </Grid>
        </div>
        <span className="saveChangesBtn">
          <Button
            variant="contained"
            color="primary"
            // onClick={() => onUpdateComContact()}
          >
            Save Changes
          </Button>
        </span>
      </AccordionContainer>
    </div>
  );
}
