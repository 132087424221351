import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import queryString from "query-string";
import { Button } from "@material-ui/core";
import Dashbard from "../Dashbard";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import { ProductOverview } from "./ProductOverview";
import { SupportResources } from "./SupportResources";
import { SerialsTab } from "./SerialsTab";
import { ProductActivities } from "./ProductActivities";
import { ProductNotes } from "./ProductNotes";
import { ProductPricingStoreNew } from "./ProductPricingStoreNew";
import { StartIcon } from "../../LeftNav/SvgIcons";
import { FavoriteStartIcon } from "../../LeftNav/SvgIcons";
import { productInventoryActions } from "../../../../store/product/productInventoryAction";
import { useDispatch } from "react-redux";

const NewProduct = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const inventoryProduct = queryString.parse(location.search);

  const [activeTabId, setActiveTabId] = useState("1");
  const [product, setProduct] = useState(null);
  const [isStarActive, setIsStarActive] = useState(false);

  useEffect(() => {
    const isProductFavourite =
      product &&
      product.userFavouriteProducts?.length &&
      product.userFavouriteProducts.find(x => x.productId === product.id)
        ? true
        : false;
    setIsStarActive(isProductFavourite);
  }, [product]);

  const allPTabs = useMemo(
    () => [
      {
        id: "1",
        title: "Overview",
        component: (
          <ProductOverview
            activeProductId={inventoryProduct?.id}
            isActive={activeTabId === "1"}
            onProductLoad={p => setProduct(p)}
          />
        ),
      },
      {
        id: "2",
        title: "Purchasing",
        component: <div>Purchasing Tab here</div>,
      },
      {
        id: "3",
        title: "Store/Pricing",
        component: (
          <ProductPricingStoreNew
            isActive={activeTabId === "3"}
            activeProductId={inventoryProduct?.id}
          />
        ),
      },
      {
        id: "4",
        title: "Serials",
        component: (
          <SerialsTab
            activeTabId={activeTabId}
            isActive={`${activeTabId}` === "4"}
          />
        ),
      },
      {
        id: "6",
        title: "Support Resources",
        component: <SupportResources isActive={activeTabId === "6"} />,
      },
      {
        id: "9",
        title: "Activity",
        component: (
          <ProductActivities
            productId={product?.id}
            isActive={activeTabId === "9"}
          />
        ),
      },
      {
        id: "10",
        title: "Notes",
        component: (
          <ProductNotes
            productId={product?.id}
            isActive={activeTabId === "10"}
          />
        ),
      },
    ],
    [activeTabId, inventoryProduct?.id, product?.id]
  );
  const [pTabs, setPTabs] = useState(allPTabs);

  useEffect(() => {
    setPTabs(
      !Boolean(product) || !product?.isSerialized
        ? allPTabs.filter(t => t.id !== "4")
        : allPTabs
    );
  }, [allPTabs]);

  const markFavorite = () => {
    dispatch(
      productInventoryActions.onAddFavoriteProduct({
        productId: inventoryProduct?.id,
      })
    ).then(data => {
      if (data?.message === "Added Successfully") {
        setIsStarActive(true);
      } else {
        setIsStarActive(false);
      }
    });
  };

  return (
    <Dashbard>
      <div className='body-content'>
        <div className='productHeaderContainer'>
          <span className='productAvatar'>
            {product && (
              <img
                className='productAvatar'
                src={product?.featureImage}
                alt=''
              />
            )}
          </span>
          <div className='producInerContainer'>
            <div className='contentHeading'>
              <h1 className='prodNameHeading'>
                {product?.productName}
                <span
                  className='prodSKU'
                  style={{
                    fontSize: "22px",
                    marginTop: "2px",
                    marginLeft: "5px",
                  }}>
                  ({product?.productSKU || "SKU"})
                </span>
                <span
                  className='favoriteProduct'
                  onClick={() => markFavorite()}>
                  <span>
                    {isStarActive ? <FavoriteStartIcon /> : <StartIcon />}
                  </span>
                  <Button className='activeBtn'>Active</Button>
                </span>
              </h1>
              <span>
                <Button
                  color='primary'
                  variant='contained'
                  className='dashboardButtons'
                  style={{
                    height: "36px",
                    width: "96px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  onClick={() => history.push("/product-inventory")}>
                  Save
                </Button>
              </span>
            </div>
            <div>
              <Button
                color='primary'
                variant='text'
                className='backProductButton'
                onClick={() => history.push("/product-inventory")}>
                <ArrowBackIos style={{ fontSize: "14px" }} />
                {`Back to All Products`}
              </Button>
            </div>
          </div>
        </div>
        <div
          className='tab-navigator productInventoryTabs'
          style={{ marginTop: "20px" }}>
          <TopTabNavigator
            getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
            tabs={pTabs}
          />
        </div>
      </div>
    </Dashbard>
  );
};

export { NewProduct };
