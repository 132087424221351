import React from "react";

function ProductImageView(props) {
  return (
    <div className="productImageViewWrapper">
      <h2>Registered Image</h2>
    </div>
  );
}

export default ProductImageView;
