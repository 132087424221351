import React from "react";

export const FormattedDateInput = ({date, setDate, ...props}) => {

    const handleToDateChange = (event) => {
        setDate(event.target.value);
    };

    return (
        <div className="dateCalenderInput">
            <input type="date"  value={date} onChange={handleToDateChange} {...props}/>
        </div>
    );
};