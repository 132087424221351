import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EditIcon,
  FilterIcon,
  GridListIcon,
  TableGrid,
  Delete,
} from "../../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import CompanyLoctionForm from "./CompanyLoctionForm";
import { searchIcon } from "../../../../../resources/images";
import InputField from "../../../../../components/common/InputField";
import { FormControl, Grid, Select } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { RedCross, TickMark } from "../../../LeftNav/SvgIcons";
import queryString from "query-string";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const names = [
  { id: "canPurchaseProducts", name: "Can purchase products" },
  { id: "canProductAccessProvided", name: "Product access provided" },
  { id: "canResellProducts", name: "Can resell products" },
  { id: "canReturnProducts", name: "Can return products" },
  { id: "canSalesRepresentative", name: "Sales representative" },
];

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    border: "1px solid #ccc",
    borderRadius: "6px",
    width: "100%",
    paddingRight: "6px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function CompanyConnectionsGrid({
  editBulk,
  addNewCon,
  onPressCross,
  allBUConnections,
  listData,
  allContactTypeNames,
  bulkCopyData,
  addUpdateCompanyOFishConnections,
  search,
  setSearch,
  deleteConnection,
}) {
  const classes = useStyles();
  const location = useLocation();
  const { contactTypes } = useSelector(state => state.ContactsReducer);
  const selectedData = queryString.parse(location.search);
  const [editItem, setEditItem] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [contactName, setContacttName] = useState("");
  const [selectedRecordId, setSelectedRecordId] = useState(0);
  const [contactId, setContactId] = useState("");
  const [showContact, setShowContact] = useState(false);

  const handleProductChange = event => {
    setShowContact(false);
    setContacttName(event.value);
    setContactId(event.id);
  };

  const handleChange = event => {
    setPersonName(event.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={11} className='filterMainSearch endUserSearchFilterMain'>
          <img src={searchIcon} alt='' className='searchIcon' />
          <InputField
            placeholder='Search'
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <span
            className='endUserSearchFilter'
            onClick={() => alert("clicked")}>
            <FilterIcon />
          </span>
        </Grid>

        <Grid item md={1} className='blockEndUserBtn gridFiltersButtons'>
          <TableGrid />
          <GridListIcon />
        </Grid>
      </Grid>
      <div className='gap' />
      <div className='addressGrid'>
        <span className='gridHeaader'>
          <ul>
            <li>Contact</li>
            <li>Relationship</li>
            <li>Date Added</li>
            <li />
          </ul>
        </span>
        <span className='gridBody'>
          {listData?.map((item, index) => {
            return (
              <ul key={index}>
                <li className='highlishtedItem connectionGrid'>
                  <span className='connectionInitial'>aV</span>
                  {item.contact}
                </li>
                <li
                  className='highlishtedItem connectionMultiSelect'
                  style={{ display: "flex" }}>
                  {`${item.canProductAccessProvided ? "Access, " : ""}${
                    item.canPurchaseProducts ? "Purchase, " : ""
                  }${item.canResellProducts ? "Resell, " : ""}${
                    item.canSalesRepresentative ? "Sales, " : ""
                  }${item.canReturnProducts ? "Return" : ""}`}
                </li>
                <li>{`${moment(item.createdOn).format(
                  "DD/MM/yyyy"
                )} at ${moment(item.createdOn).format("hh:mm A")} PST`}</li>
                <li
                  className='gridActions'
                  style={{
                    textAlign: "right",
                    cursor: "pointer",
                    justifyContent: "flex-end",
                  }}>
                  <span
                    onClick={() => {
                      setSelectedRecordId(item?.id);
                      setEditItem(true);
                      setContacttName(item.contact);
                      setContactId(item.contactId);
                      let arr = [];
                      if (item?.canProductAccessProvided) {
                        arr.push("Product access provided");
                      }
                      if (item?.canPurchaseProducts) {
                        arr.push("Can purchase products");
                      }
                      if (item?.canResellProducts) {
                        arr.push("Can resell products");
                      }
                      if (item?.canReturnProducts) {
                        arr.push("Can return products");
                      }
                      if (item?.canSalesRepresentative) {
                        arr.push("Sales representative");
                      }
                      setPersonName(arr);
                    }}>
                    <EditIcon />
                  </span>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      deleteConnection(item?.id);
                    }}
                    >
                    <Delete />
                  </span>
                </li>
              </ul>
            );
          })}
        </span>

        {(addNewCon || editItem) && (
          <span className='gridBody'>
            <ul>
              <li className='highlishtedItem connectionGrid'>
                <span className='connectionInitial'>AV</span>
                <input
                  type='text'
                  placeholder='Company Name'
                  value={contactName}
                  onFocus={() => setShowContact(true)}
                />
                {/* <OutsideClickHandler
                    onOutsideClick={() => setShowContact(false)}> */}
                <span
                  className='companyDropdown'
                  style={{ display: showContact ? "block" : "none" }}>
                  {allContactTypeNames?.map((item, index) => {
                    return (
                      <button
                        key={index}
                        onClick={() => handleProductChange(item)}>
                        <span>{item.value}</span>
                      </button>
                    );
                  })}

                  {!allContactTypeNames && (
                    <button>
                      <span>No Record Found</span>
                    </button>
                  )}
                </span>
                {/* </OutsideClickHandler> */}
              </li>
              <li
                className='highlishtedItem connectionMultiSelect'
                style={{ display: "flex" }}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId='demo-mutiple-checkbox-label'
                    id='demo-mutiple-checkbox'
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={selected => selected.join(", ")}
                    // MenuProps={MenuProps}
                  >
                    {names.map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        <Checkbox
                          checked={personName.indexOf(item.name) > -1}
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li>
              <li>{`${moment(new Date()).format("DD/MM/yyyy")} at ${moment(
                new Date()
              ).format("hh:mm A")} PST`}</li>
              <li style={{ textAlign: "right", cursor: "pointer" }}>
                <span className='thumbIcon tickMarkCross businessAddress '>
                  <span
                    onClick={() => {
                      onPressCross();
                      setEditItem(false);
                    }}>
                    <RedCross />
                  </span>
                  <span
                    onClick={() => {
                      setEditItem(false);
                      addUpdateCompanyOFishConnections(
                        selectedRecordId,
                        contactId,
                        personName
                      );
                    }}>
                    <TickMark />
                  </span>
                </span>
              </li>
            </ul>
          </span>
        )}
      </div>
    </ValidatorForm>
  );
}

export default CompanyConnectionsGrid;
