import React from "react";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import hours from "../../../../../resources/businessHoursTime";
import { useDispatch, useSelector } from "react-redux";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import CustomCheckbox from "../../../../../components/common/checkbox/CustomCheckbox";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "6px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  hoursWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
}));

function BusinessHours(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const changeBusinessHour = (value, index, fieldName) => {
    dispatch(newsroomActions.onChangeBusinessHour(index, value, fieldName));
  };

  const { businessHours } = useSelector((state) => state.newsRoomReducer);
  return (
    <>
      <div className="gap" />
      <div className="gap" />
      <label className="innerLabel businessHoursInnerLabel">
        Business Hours <span>Closed</span>
      </label>
      {businessHours.length > 0 &&
        businessHours.map((hrs, index) => {
          return (
            <Grid
              key={index}
              container
              spacing={3}
              className={classes.hoursWrapper}
            >
              <Grid
                item
                xs={3}
                className={[classes.gridWrapper, "socialWrapper"]}
              >
                {hrs.day}
              </Grid>
              <Grid
                item
                className={[
                  classes.gridWrapper,
                  "socialWrapper hoursSelect contactWrapperOuter",
                ]}
              >
                <Select
                  value={hrs.openingTime}
                  onChange={(e) =>
                    changeBusinessHour(e.target.value, index, "openingTime")
                  }
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                  variant={"outlined"}
                  // required
                >
                  <MenuItem value="">
                    <span className="customPlaceholder">
                      {index !== 5 && index !== 6 ? "9:00 AM" : ""}
                    </span>
                  </MenuItem>
                  {hours &&
                    hours.length > 0 &&
                    hours.map((bhr, i) => {
                      return (
                        <MenuItem key={i} value={bhr.time}>
                          {bhr.time}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid
                item
                xs={0}
                className={[classes.gridWrapper, "socialWrapper"]}
              >
                to
              </Grid>
              <Grid
                item
                className={[
                  classes.gridWrapper,
                  "socialWrapper  hoursSelect contactWrapperOuter",
                ]}
              >
                <Select
                  value={hrs.closingTime}
                  onChange={(e) =>
                    changeBusinessHour(e.target.value, index, "closingTime")
                  }
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                  variant={"outlined"}
                  // required
                >
                  <MenuItem value="">
                    <span className="customPlaceholder">
                      {index !== 5 && index !== 6 ? "5:00 PM" : ""}
                    </span>
                  </MenuItem>
                  {hours &&
                    hours.length > 0 &&
                    hours.map((bhr, i) => {
                      return (
                        <MenuItem key={i} value={bhr.time}>
                          {bhr.time}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>

              <Grid
                item
                xs={2}
                className={[classes.gridWrapper, "addNumberBtn"]}
              >
                <CustomCheckbox
                  checkedId={hrs.day}
                  checkValue={hrs.closed}
                  pressCheck={() =>
                    changeBusinessHour(!hrs.closed, index, "closed")
                  }
                />
              </Grid>
            </Grid>
          );
        })}
    </>
  );
}

export default BusinessHours;
