import { makeStyles } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  activeTabTrash: {
    backgroundColor: "rgba(255, 53, 53, 0.40) !important",
    color:"#FF353580 !important"
  },
  activeTab:{
    background: "#D3E6FA !important",
    color:"#3699FF !important"
  }
}));

const TabButtons = ({ tabs = [] }) => {
  const classes = useStyles();
  return (
    <div className="tabButtons">
      {tabs.map((tab) => (
        <span
          className={"tab-button"}
          key={tab.title}
        >
          <NavLink
            to={tab.to}
           activeClassName={tab.title === "Trash" ? classes.activeTabTrash : classes.activeTab}
          >
            {tab.title}
          </NavLink>
        </span>
      ))}
    </div>
  );
};

export { TabButtons };
