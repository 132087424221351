import React, { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip, Dialog, DialogTitle } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AttachFile } from "@material-ui/icons";
import { TooltipPreview } from "../../../../components/common/Modal/AssetsModals";
import { Close, DocIconLarge, PdFIconLargeIcon, PdfIconLarge } from "../../LeftNav/SvgIcons";
import { Document, Page, pdfjs } from "react-pdf";

const AttachmentPreview = ({ roomMsgs, onClose }) => {

  const [pageNumber, setPageNumber] = useState([1]);
  const onPdfLoad = ({ numPages }) => {    
    const pagesArray = [];
    for (let i=1; i <= numPages; i++) {
      pagesArray.push(i);
    }
    setPageNumber([...pagesArray]);
  };

  const renderFile = (attachment) => {
    const fileType = attachment?.name.split('.').pop().toLowerCase();
    switch (fileType) {
      case "pdf":
        return (
          <div
            style={{
              height: "500px",
              width: "100%",
              overflow: "scroll",
            }}>
            <Document file={attachment?.path} onLoadSuccess={onPdfLoad}>
              {pageNumber.map(page => 
                <Page pageNumber={page} />
              )}
            </Document>
            <p>
          </p>
          </div>
        );
      case "doc":
      case "docx":
        return (
          <div>
          <a href={attachment.path} download={attachment.name}>{attachment.name}</a>
        </div>
        );
      case "png":
      case "jpeg":
      case "jpg":
        return <img src={attachment?.path} alt={attachment?.name} />;
      default:
        return <p>Unsupported file type</p>;
    }
  };
  return (
    <div className='Preview'>
      <Dialog
        onClose={onClose}
        aria-labelledby='customized-dialog-title'
        open={roomMsgs}
        maxWidth='sm'
        classes={{
          paper: {
            borderRadius: 12,
          },
        }}>
        <DialogTitle>
          <span style={{ marginRight: "10px" }}>{roomMsgs?.name}</span>
          <span
            disableRipple
            style={{ cursor: "pointer" }}
            aria-label='close'
            onClick={onClose}>
            <Close width={"29"} height={"29"} />
          </span>
        </DialogTitle>
        {/* <img src={roomMsgs?.path} alt={attachment?.name} />; */}
        {
          renderFile(roomMsgs)
        }
      </Dialog>
    </div>
  );
};

function ChatWindow({ roomMsgs }) {
  const chatContainerRef = useRef(null);
  const [previewAttachment, setPreviewAttachment] = useState(null);  
  const [fileType, setFileType] = useState("");
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { hubId } = useSelector(state => state.conversationReducer);
  const calculateTimeDifference = receivedDate => {
    const currentDate = new Date();
    const timeDifference = currentDate - receivedDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) {
      return seconds + " sec ago";
    } else if (minutes < 60) {
      return minutes + " min ago";
    } else if (hours < 24) {
      if (hours === 1) {
        return "1 hour ago";
      } else {
        return hours + " hours ago";
      }
    } else {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return receivedDate.toLocaleDateString(undefined, options);
    }
  };

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (chatContainerRef.current) {
      // Scroll to the bottom of the chat container
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [roomMsgs]);

  const checkFileType = (attachment) => {
    const fileType = attachment?.name.split('.').pop().toLowerCase();
    if (fileType?.toLowerCase() === "pdf") {
      return <div className="chatBody" >
        <PdfIconLarge />
        <span style={{ marginLeft: "10px" }}>
          {attachment.name}
        </span>
      </div>
        ;
    } else if (fileType?.toLowerCase() === "doc" || fileType?.toLowerCase() === "docx") {
      return  <div className="chatBody">
        <a href={attachment.path} download={attachment.name} style={{textDecoration:"none" , color:"#000000"}}>
      <DocIconLarge />
      <span style={{ marginLeft: "10px" }}>
        {attachment.name}
      </span>
    </a>
      </div>
    } else if (fileType?.toLowerCase() === "png" || fileType?.toLowerCase() === "jpg" || fileType?.toLowerCase() === "jpeg") {
     
      return (
        <img
          src={attachment?.path}
          alt='Preview-Img'
          style={{
            maxWidth: "200px",
            height: "auto",
            borderRadius: 16,
            marginTop: 6,
          }}
        />
      );
    } else {
      return (
        <div className="chatBody" >
          <span>
            <PdFIconLargeIcon style={{ width: "50px", height: "67px" }} />
          </span>
          <span style={{ marginLeft: "10px" }}>
            {attachment.name}
          </span>
        </div>

      )
    }
  };

  return (
    <div
      className='endUserChatWindowWrapper'
      ref={chatContainerRef}
      style={{ overflowY: "auto" }}>
      {roomMsgs?.map((x, i) =>
        hubId === x?.hubUserId ? (
          <div className='sender' key={i}>
            <span className='chatHeader'>
              <span className='pic'>
                <i className='userInitials'>{`${x?.name
                  ?.charAt(0)
                  ?.toUpperCase()}`}</i>
              </span>
              <span>
                {x?.name} <i>{calculateTimeDifference(new Date(x?.date))}</i>
              </span>
            </span>
            {x?.attachements?.length > 0 ? (
              <div 
              className='chatBody'
              style={{
                display:"flex",
                flexDirection:"column",
              }}>
                {x?.content ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: x?.content,
                    }}
                  />
                ) : (
                  <span
                    className='chat-img-thumnail'
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>{   
                      const fileType = x?.attachements[0]?.name.split('.').pop().toLowerCase();
                      if(fileType?.toLowerCase() !== "doc" && fileType?.toLowerCase() !== "docx") {
                        setPreviewAttachment({
                          ...x?.attachements[0],
                        })}
                      }
                    }>
                    {
                      checkFileType(x?.attachements[0])
                    }
                    {/* <img
                      src={x?.attachements[0]?.path}
                      alt='Preview-Img'
                      style={{
                        maxWidth: "200px",
                        height: "auto",
                        borderRadius: 16,
                        marginTop: 6,
                      }}
                    /> */}
                    {/* {renderFile(x?.attachements[0])} */}
                  </span>
                )}
                {x?.attachements[0]?.name && x?.content && (
                    <span
                    className='chat-img-thumnail'
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setPreviewAttachment({
                        ...x?.attachements[0],
                      })
                    }>
                    {/* <img
                      src={x?.attachements[0]?.path}
                      alt='Preview-Img'
                      style={{
                        maxWidth: "200px",
                        height: "auto",
                        borderRadius: 16,
                        marginTop: 6,
                      }}
                    /> */}
                    {
                      checkFileType(x?.attachements[0])
                    }
                  </span>
                )}
              </div>
            ) : (
              <span
                className='chatBody'
                dangerouslySetInnerHTML={{ __html: x?.content }}
              />
            )}

            <span className='chatStatus'>
              {x.isRead ? "Read" : "Delivered"}
            </span>
          </div>
        ) : (
          <div className='receiver' key={i}>
            <span className='chatHeader'>
              <span className='pic'>
                <i className='userInitials'>{`${x?.name
                  ?.charAt(0)
                  ?.toUpperCase()}`}</i>
              </span>
              <span>
                {x?.name} <i>{calculateTimeDifference(new Date(x?.date))}</i>
              </span>
            </span>
            <div className='chatBody'>
              <span
                dangerouslySetInnerHTML={{ __html: x?.content }}
              />
              {x?.attachements?.length > 0 && x?.attachements[0]?.name && (
                <span
                className='chat-img-thumnail'
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>{
                  const fileType = x?.attachements[0]?.name?.split('.').pop().toLowerCase();
                  if(fileType !== "doc" && fileType !== "docx") {
                    setPreviewAttachment({
                      ...x?.attachements[0],
                    })
                  }
                }}>
                {/* <img
                  src={x?.attachements[0]?.path}
                  alt='Preview-Img'
                  style={{
                    maxWidth: "200px",
                    height: "auto",
                    borderRadius: 16,
                    marginTop: 6,
                  }}
                /> */}
                {
                  checkFileType(x?.attachements[0])
                }
              </span>
              )}
                             
  
            </div>
          </div>
        )
      )}

      {previewAttachment && (
        <AttachmentPreview
          roomMsgs={previewAttachment}
          onClose={() => setPreviewAttachment(null)}
        />
      )}
    </div>
  );
}

export default ChatWindow;
