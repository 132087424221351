import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { contactsTypesActions } from "../../../../store/contactTypes/contactTypesActions";
import { ContactDataPopup } from "./Modals/ContactDataPopup";
import StarIcon from "../../../../resources/images/star.png";
import { starOutline } from "../../../../resources/images/index";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

export default function ContactModules({ searchText, areaId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [contactModules, setContactModules] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [contactModulesById] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId] = useState(0);
  const dispatch = useDispatch();
  const { dataModule, refreshData } = useSelector(
    state => state.pubContactType
  );

  useEffect(() => {
    dispatch(contactsTypesActions.onGetAllContactModules(areaId)).then(data => {
      setContactModules(data?.data?.data);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(contactsTypesActions.onGetAllContactModules(areaId)).then(data => {
      setContactModules(data?.data?.data);
      var filterData = [];
      data?.data?.data?.map(x => {
        if (x.isFavourite === true) {
          return filterData.push({
            ...x,
            fieldName: x.moduleName,
            type: "modules",
          });
        }
      });
      dispatch(contactsTypesActions.onChange("favoritesModules", filterData));
    });
  }, [refreshData]);

  useEffect(() => {
    if (contactModules?.length > 0) {
      setFilteredData(
        contactModules?.filter(value =>
          value.moduleName.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else if (!searchText || searchText !== "") {
      setFilteredData(contactModules);
    }
  }, [contactModules, searchText]);

  const addUpdateMarkFavouriteContactTypesModules = (checked, id) => {
    setIsLoading(true);
    var updatedDataFields = contactModules?.map(x => {
      if (x.id === id) {
        x.isFavourite = checked;
      }
      return x;
    });
    setContactModules(updatedDataFields);
    const data = [
      {
        contactTypeModuleId: id,
        isFavourite: checked,
      },
    ];
    dispatch(
      contactsTypesActions.onAddUpdateMarkFavouriteContactTypesModules(data)
    ).then(res => {
      setIsLoading(false);
      if (!res?.data?.success) {
        toast.error(res?.data?.message);
      }
      dispatch(contactsTypesActions.onChange("refreshData", !refreshData));
    });
  };

  return (
    <>
      <Grid container className='contactTypesMainWrapper'>
        {isLoading && <Loader />}
        {filteredData?.map((item, index) => {
          return (
            <Grid item className='mainGrid' key={index}>
              <div className='dataFieldmainWrapper'>
                <div className='dataFieldName'>
                  <h4>
                    {item.moduleName} <span>*</span>
                    <span className='defaultValue'>
                      {item.default === true ? "OFish Default" : ""}
                    </span>
                    <span
                      onClick={() => {
                        addUpdateMarkFavouriteContactTypesModules(
                          !item.isFavourite,
                          item.id
                        );
                      }}>
                      {item.isFavourite === true ? (
                        <img src={StarIcon} alt={"StarIcom"} />
                      ) : (
                        <img src={starOutline} alt={"StarIcom"} />
                      )}
                    </span>
                  </h4>
                  <p className='dataFieldDescription'>{item.description}</p>
                </div>
                <div>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={
                      dataModule.find(x => x.fieldId === item.id) ? true : false
                    }
                    startIcon={
                      dataModule.find(x => x.fieldId === item.id) ? null : (
                        <Add />
                      )
                    }
                    onClick={() => {
                      const tempDataConnection = dataModule;
                      let isExist = tempDataConnection.find(
                        x => x.fieldId === item.id
                      );
                      if (isExist) {
                      } else {
                        tempDataConnection.push({
                          fieldId: item.id,
                          fieldValue: item.moduleName,
                          contactTypeModuleFieldSettingInfo: [],
                        });
                      }
                      dispatch(
                        contactsTypesActions.onChange(
                          "dataModule",
                          tempDataConnection
                        )
                      );
                    }}>
                    {dataModule.find(x => x.fieldId === item.id)
                      ? "Added"
                      : "Add"}
                  </Button>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      {isOpen && (
        <ContactDataPopup
          isOpen={isOpen}
          records={contactModulesById?.fieldSettings}
          setIsOpen={e => {
            setIsOpen(e);
          }}
          selectedId={selectedId}
          selectedName={contactModulesById?.moduleName}
        />
      )}
    </>
  );
}
