import React from 'react'
import { WaterTreatmentReplaced } from "../SpaProduct/SvgIcons";
import moment from 'moment';
import { useSelector } from 'react-redux';

export const WaterLifeBanner = () => {
  const { waterLifeStats } = useSelector((state) => state.endUserReducer);
  return (
    <div className="watertratmentContainer">
        <h1>Water Life</h1>
    <div className="waterTreatmeantDetails replacementWaterLife">
       <WaterTreatmentReplaced />
      <span className="daysWaterTreatment">
        {waterLifeStats?.replacedDays ?? '0'} Days<br></br>
        <i>since water has been replaced</i>
      </span>

      <span className="daysWaterRecommended">
        Recommended next test date:<br></br>
        <i>
        {moment(waterLifeStats?.recommendedReplacingDate).diff(moment(new Date()), 'days')} days away <span> |</span> September 1, 2021
        </i>
      </span>
      <span className="daysWaterReplacementTable">
      Last Refill:<br></br>
        <i>{waterLifeStats?.lastRefil}</i>
      </span>
      <span className="daysWaterLife daysWaterReplacementTable">
      Total Gallons Used Since Ownership:<br></br>
        <i>
        {waterLifeStats?.totalGallons ?? '0'} gallons
        </i>
      </span>
    </div>
  </div>
  )
}
