import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Dashboard from "../Dashbard";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";

import ContactTypes from "./ContactTypes";

export default function ContactSettings() {
  document.title = "OFish - Contact Types";

  const history = useHistory();

  const [activeTabId, setActiveTabId] = useState("2");
  return (
    <>
      <Dashboard>
        <div className='body-content'>
          <div className='contentHeading'>
            <h1>Contact Types</h1>
            <Button
              color='primary'
              variant='contained'
              startIcon={<AddIcon />}
              onClick={() => {
                history.push("/new-contact-types");
              }}>
              New Contact Type
            </Button>
          </div>
          <p className='contactSubHeading'>Contact Settings</p>
          <p className='contactPara'>
            Contact Types allow your Brand to organize and manage various types
            of contacts your brand interacts with.
            <span className='spanHeading'>Learn More</span>
          </p>

          <div className='tab-navigator '>
            <TopTabNavigator
              getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
              value='1'
              className='contactType'
              tabs={[
                // {
                //   id: "1",
                //   title: "General",
                //   component: <div>General</div>,
                // },
                {
                  id: "2",
                  title: "Contact Types",
                  component: <ContactTypes />,
                },
                // {
                //   id: "3",
                //   title: "Spaces",
                //   component: <div>Spaces</div>,
                // },
                // {
                //   id: "4",
                //   title: "Onboarding",
                //   component: <div>Onboarding</div>,
                // },
                // {
                //   id: "5",
                //   title: "Product Registration",
                //   component: <div>Product Registration</div>,
                // },
              ]}
            />
          </div>
          {/* <Grid container spacing={2}>
            <Grid item md={10} className="filterMainSearch">
              <img src={searchIcon} alt="" className="searchIcon" />
              <Field placeholder="Search" />
            </Grid>

            <Grid item md={2} className="searchByFilter">
              Search by Filter <FiChevronDown />
            </Grid>
          </Grid> */}
        </div>
      </Dashboard>
    </>
  );
}
