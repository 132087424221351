import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { EditIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { contactsActions } from "../../../../store/contacts/contactsActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";
import { RoutesObj } from "../../../../routes";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function AllAdminGrid({ userData, isDelete }) {
  const history = useHistory();
  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.AdminOverview.path,
      userId: value.id,
      search: `id=${value.id}`,
    });
  };
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <Paper>
      {isLoading ? <Loader /> : null}
      <Paper className={classes.gridTopNav}>
        <Grid item md={9} className={classes.deleteItems}>
          {isDelete && (
            <span className='gridDeleteItems' onClick={() => {}}>
              <DeleteIcon /> Delete
            </span>
          )}
        </Grid>
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont} disabled={true} />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              FIRST NAME
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>LAST NAME</TableCell>

            <TableCell className={classes.gridHeaderInner}>
              LAST LOGIN
            </TableCell>
            <TableCell className={classes.gridHeaderInner}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.map((item, index) => {
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridHeaderInner}>
                  <Checkbox className={classes.checkBoxFont} disabled={true} />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.firstName}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.lastName}
                </TableCell>

                <TableCell className={classes.gridBodyInner}>
                  {" "}
                  {item.lastLoginDate !== null && item.lastLoginDate !== ""
                    ? moment(item.lastLoginDate).fromNow()
                    : ""}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <span onClick={() => redirectUrl(item)}>
                    <EditIcon />
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default AllAdminGrid;
