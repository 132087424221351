import apiCall from "axios";
import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/conversationConfig";
import { endpoints as end } from "../../config/apiConfig";

async function getUserRoomsByUsersId(data) {
  return axios
    .post(endpoints.conversation.getUserRoomsByUsersId, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getChatDetailsByRoomId(data) {
  return axios
    .post(endpoints.conversation.getChatDetailsByRoomId, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function makeRoomFavorite(data) {
  return axios
    .post(endpoints.conversation.makeRoomFavorite, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function changeRoomCoversationType(data) {
  return axios
    .post(endpoints.conversation.changeRoomCoversationType, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteRooms(data) {
  return axios
    .post(endpoints.conversation.deleteRooms, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function uploadFiles(data) {
  let formData = new FormData();
  formData.append("file", data[0]);
  const token = localStorage.getItem("access_token");

  return apiCall({
    url: endpoints.conversation.uploadFile,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => response?.data ?? null)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

// async function uploadFiles(data) {
//   const token = localStorage.getItem("access_token");
//   const convImagesPoints = data.map(m => {
//     const formData = new FormData();
//     formData.append("file", m);
//     return apiCall({
//       url: endpoints.conversation.uploadFile,
//       method: "post",
//       data: formData,
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${token}`,
//       },
//     });
//   });
//   return Promise.all(convImagesPoints)
//     .then(response => response)
//     .catch(error => {
//       console.error("error : ", error);
//       return null;
//     });
// }

async function getConfigurationStatus(data) {
  return axios
    .post(endpoints.conversationPopUp.getConfigurationStatus, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getAllCampaigns(data) {
  return axios
    .post(endpoints.conversationPopUp.getAllCampaigns, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function enableCampaigns(data) {
  return axios
    .post(endpoints.conversationPopUp.enableCampaigns, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function enableConversation(data) {
  return axios
    .post(endpoints.conversationPopUp.enableConversations, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addUpdateCampaignSettings(data) {
  return axios
    .post(endpoints.conversationPopUp.addUpdateCampaignSettings, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addUpdateProductNotificationSettings(data) {
  return axios
    .post(
      endpoints.conversationPopUp.addUpdateProductNotificationSettings,
      data
    )
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getUserAutoCompleteByEmail(email) {
  return axios
    .get(`${end.customers.getUserAutoCompleteByEmail}${email}`)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getCompanyAutoCompleteByName(email) {
  return axios
    .post(end.companies.getEndUserCompanyAutoComplete, { input: email })
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function sendNewMsg(data) {
  return axios
    .post(endpoints.conversation.sendMsgViaAPI, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getCampaignsDdl(data) {
  return axios
    .post(endpoints.conversation.getCampaignsDdl, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function updateConversationStatusInRoom(data) {
  return axios
    .post(endpoints.conversation.updateConversationStatusInRoom, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getConversationStatuses(data) {
  return axios
    .post(endpoints.conversationPopUp.getConversationStatuses, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getAllCampaignsGrid(data) {
  return axios
    .post(endpoints.conversationPopUp.getAllCampaignsGrid, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getCampaignSettingsByCampaignId(data) {
  return axios
    .post(endpoints.conversationPopUp.getCampaignSettingsByCampaignId, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function updateCampaignIdInRoom(data) {
  return axios
    .post(endpoints.conversation.updateCampaignIdInRoom, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addUpdateTemplates(data) {
  return axios
    .post(endpoints.conversation.addUpdateTemplates, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getTemplateById(id) {
  return axios
    .post(`${endpoints.conversation.getTemplateById}${id}`)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getTemplate(data) {
  return axios
    .post(endpoints.conversation.getTemplate, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function markConversationTagsToRooms(data) {
  return axios
    .post(endpoints.conversation.markConversationTagsToRooms, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getConversationTagsDdl(data) {
  return axios
    .post(endpoints.conversation.getConversationTagsDdl, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function markRoomAssignee(data) {
  return axios
    .post(endpoints.conversation.markRoomAssignee, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function markRoomInbox(data) {
  return axios
    .post(endpoints.conversation.markRoomInbox, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function makeConversationFavourite(data) {
  return axios
    .post(endpoints.conversationPopUp.makeConversationFavourite, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function archivedConversationType(data) {
  return axios
    .post(endpoints.conversationPopUp.archivedConversationType, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function deleteConversationType(data) {
  return axios
    .post(`${endpoints.conversationPopUp.deleteConversationType}/${data}`)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getAllConversationChannels(data) {
  return axios
    .post(endpoints.conversationPopUp.getAllConversationChannels, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getConversationPermission(id) {
  return axios
    .post(`${endpoints.conversationPopUp.getConversationPermission}/${id}`)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function addUpdateConversationPermission(data) {
  return axios
    .post(endpoints.conversationPopUp.addUpdateConversationPermission, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getConversationRoles(data){
  return axios
    .post(endpoints.conversationPopUp.getConversationRoles, data)
    .then(response => response)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getConversationCustomerData( CustomerTypeName ) {
  return axios
   .post( endpoints.conversationPopUp.getConversationCustomerData ,CustomerTypeName )
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getAllConversationStatus(data) {
  return axios
    .post(endpoints.conversationPopUp.getAllConversationStatus, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function getStatusAction() {
  return axios
    .get(endpoints.conversationPopUp.getStatusAction)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function addUpdateStatus(data) {
  return axios
    .post(endpoints.conversationPopUp.addUpdateStatus, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function deleteConversationStatus(data) {
  return axios
    .post(`${endpoints.conversationPopUp.deleteConversationStatus}/${data}`)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function sendMessages(data) {
  return axios
    .post(endpoints.conversation.sendMessages, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function sendInstaMessages(data) {
  return axios
    .post(endpoints.conversation.sendInstaMessages, data)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

export const conversationApi = {
  getUserRoomsByUsersId: getUserRoomsByUsersId,
  getChatDetailsByRoomId: getChatDetailsByRoomId,
  makeRoomFavorite: makeRoomFavorite,
  archivedConversationType: archivedConversationType,
  makeConversationFavourite: makeConversationFavourite,
  deleteConversationType: deleteConversationType,
  changeRoomCoversationType: changeRoomCoversationType,
  deleteRooms: deleteRooms,
  uploadFiles: uploadFiles,
  getConfigurationStatus: getConfigurationStatus,
  getAllCampaigns: getAllCampaigns,
  enableCampaigns: enableCampaigns,
  enableConversation: enableConversation,
  addUpdateCampaignSettings: addUpdateCampaignSettings,
  addUpdateProductNotificationSettings: addUpdateProductNotificationSettings,
  getUserAutoCompleteByEmail: getUserAutoCompleteByEmail,
  getCompanyAutoCompleteByName: getCompanyAutoCompleteByName,
  sendNewMsg: sendNewMsg,
  getCampaignsDdl: getCampaignsDdl,
  updateConversationStatusInRoom: updateConversationStatusInRoom,
  getConversationStatuses: getConversationStatuses,
  getAllCampaignsGrid: getAllCampaignsGrid,
  getCampaignSettingsByCampaignId: getCampaignSettingsByCampaignId,
  updateCampaignIdInRoom: updateCampaignIdInRoom,
  addUpdateTemplates: addUpdateTemplates,
  getTemplateById: getTemplateById,
  getTemplate: getTemplate,
  markConversationTagsToRooms: markConversationTagsToRooms,
  getConversationTagsDdl: getConversationTagsDdl,
  markRoomAssignee: markRoomAssignee,
  markRoomInbox: markRoomInbox,
  getAllConversationChannels: getAllConversationChannels,
  getConversationPermission: getConversationPermission,
  addUpdateConversationPermission: addUpdateConversationPermission,
  getAllConversationStatus: getAllConversationStatus,
  getStatusAction: getStatusAction,
  addUpdateStatus: addUpdateStatus,
  getConversationRoles:getConversationRoles,
  getConversationCustomerData:getConversationCustomerData,
  deleteConversationStatus:deleteConversationStatus,
  sendMessages:sendMessages,
  sendInstaMessages:sendInstaMessages,
};
