export const locationsTypes = {
  LOCATIONS_ONCHANGE: "LOCATIONS_ONCHANGE",
  SELECTED_LOCATIONS: "SELECTED_LOCATIONS",
  SELECTED_ALL_LOCATIONS: "SELECTED_ALL_LOCATIONS",
  ON_SELECT_FILETYPE: "ON_SELECT_FILETYPE",
  ON_PRESS_FILTER: "ON_PRESS_FILTER",
  ON_SELECT_CATTYPE: "ON_SELECT_CATTYPE",
  ON_PRESS_FILTER_CAT: "ON_PRESS_FILTER_CAT",
  ALL_SELECTED_LOCATIONS:"ALL_SELECTED_LOCATIONS"
};
