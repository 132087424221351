/* eslint-disable no-use-before-define */
import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

function AutoComp({
  data,
  onChangeClick,
  onSetSelectedEmailList,
  autoComplete,
}) {
  return (
    <div className='chatAutoComplete'>
      <span className='chatFromWrapper'>To:</span>
      <Autocomplete
        ref={autoComplete}
        multiple
        id='tags-outlined'
        options={data}
        getOptionLabel={option => option.name}
        //defaultValue={[top100Films[13]]}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            //label="filterSelectedOptions"
            //placeholder='Type a contact or company or explore via lists'
            onChange={e => onChangeClick(e.target.value)}
          />
        )}
        onChange={(_event, newTeam) => {
          onSetSelectedEmailList(newTeam);
        }}
      />
    </div>
  );
}

export default AutoComp;
