import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { PopupDialog } from "../../../../components/common/PopupDialog";
import { Chevron, Close, Cross, Magnifier } from "../../LeftNav/SvgIcons";
import { Add } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { conversationAction } from "../../../../store/conversations/conversationAction";
import { useDebounce } from "../../../../utils/use-debounce";

const ConvTagsModal = ({
  oncloseaction,
  isvisible,
  maxwidth,
  roomDetail,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [convTag, setConvTag] = useState([]);
  const [selectedConvTag, setSelectedConvTag] = useState([]);
  const [ isSelectedConvExist , setIsSelectedConvExist] = useState([]);
  const [totalRec, setTotalRec] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const recPerPage = 10;
  const { debouncedValue } = useDebounce(filterValue, 500);
  const tagsOnHover = 3;
  const [tagsToShow, setTagsToShow] = useState(3);

  const handleShowAllTags = () => {
    setTagsToShow(prevItems =>
      prevItems === tagsOnHover ? selectedConvTag?.length : tagsOnHover
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      conversationAction.onGetConversationTagsDdl({
        name: debouncedValue,
        productId: 2,
        pageNo: selectedPage,
        pageSize: recPerPage,
      })            
    ).then(statusesResponse => {
      setLoading(false);
      const ids = roomDetail?.tags?.map(item => item?.tagId);
      setSelectedConvTag(ids);
      setIsSelectedConvExist(ids);
      if (statusesResponse?.responseCode === 1) {
        setConvTag(statusesResponse?.responseData);
        setTotalRec(statusesResponse?.responseData?.count);
      }
    });
  }, [dispatch, selectedPage, debouncedValue]);

  const onSaveButton = () => {
    if (selectedConvTag?.length > 0 || isSelectedConvExist.length) {
      setLoading(true);
      dispatch(
        conversationAction.onMarkConversationTagsToRooms({
          tagIds: selectedConvTag,
          roomId: roomDetail?.roomId,
        })
      ).then(statusesResponse => {
        setLoading(false);
        if (statusesResponse?.responseCode === 1) {
          oncloseaction();
        }
      });
    }
  };

  const onFiterRoomName = value => {
    setFilterValue(value);
  };

  return (
    <PopupDialog
      visible={isvisible}
      onClose={oncloseaction}
      maxWidth={maxwidth}
      >
      <div className='newContactPopupWrapper deletePopupMainWrapper conTypePopupWrapper convTagsWrapper'>
        <div className='newContactPopupInnerWrapper'>
          <span className='innerHeading'>Conversation Tags</span>
          <i onClick={oncloseaction}>
            {" "}
            <Close />
          </i>
        </div>
        
        <div className='deleteContactBody'>
            {
              selectedConvTag.length > 0 ? <div className='ConvTagsHeader'>
                <IconButton className={tagsToShow > 3 ?  "tagButton" : "tagButtonRotate"} onClick={handleShowAllTags}>
                  <Chevron />
                </IconButton>
                <div className='selectedTagsWrapper'>
                  {selectedConvTag?.slice(0, tagsToShow).map(x => (
                    <span>
                      {convTag.filter(item => item?.id === x)[0]?.tag}
                      <i
                        onClick={() => {
                          const updatedList = selectedConvTag.filter(
                            item => item !== x
                          );
                          setSelectedConvTag(updatedList);
                        }}>
                        <Cross />
                      </i>
                    </span>
                  ))}
                  {/* <span>
              Bug Report{" "}
              <i>
                <Cross />
              </i>
            </span>
            <span>
              Tag Option{" "}
              <i>
                <Cross />
              </i>
            </span> */}
                </div>
              </div> :
              <span className="tagAssignedText">
                No Tags Assigned
              </span>
            }


          <span className='conTypeButtonWrapper tagsBtns'>
            <span className='tagsSearchWrpper'>
              <i>
                <Magnifier />
              </i>
              <input
                type='text'
                placeholder='Search'
                value={filterValue}
                onChange={e => onFiterRoomName(e.target.value)}
              />
            </span>
            <Grid
              container
              spacing={2}
              style={{ paddingLeft: "25px", paddingRight: "25px" }}>
              {convTag?.map(x => (
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Button
                      color='primary'
                      variant='contained'
                      disabled={selectedConvTag?.includes(x?.id)}
                      endIcon={<Add />}
                      style={{opacity: selectedConvTag?.includes(x?.id) ? 0.5 : 1}}
                      onClick={() => {
                        const updatedList = [...selectedConvTag, x?.id];
                        setSelectedConvTag(updatedList);
                      }}>
                      {x?.tag}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </span>
        </div>

        {totalRec > 10 && (
          <span className='tagsPagination'>
            <Pagination
              count={Math.ceil(totalRec / recPerPage)}
              onChange={(_, page) => setSelectedPage(page - 1)}
            />
          </span>
        )}
        <span className='deleteContactActions'>
          <Button onClick={oncloseaction}>Cancel</Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              onSaveButton();
            }}>
            Save
          </Button>
        </span>
      </div>
    </PopupDialog>
  );
};

export { ConvTagsModal };
