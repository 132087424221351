import React from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Grid } from "@material-ui/core";
import { Cross } from "../../LeftNav/SvgIcons";

const useStyles = makeStyles((theme) => ({
  tagHeading: {
    color: "#3F4254",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "12px",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#F3F6F9",
    color: "#70808F",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "145%",
  },
  noTagText: {
    color: "#70808F",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "italic",
    fontWeight: "500",
    lineHeight: "12px",
    marginTop: "8px",
  },
  tagBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "start",
  },
  tagsOverlayBox: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.15)",
    padding:"12px",
    borderRadius:"7px",
    minWidth:"200px",
  },
}));

const TagsOverlay = ({ tags }) => {
  const classes = useStyles();
  const handleDelete = () => {
    // add delete tag functionality
  };

  return (
    <>
      <div className={classes.tagsOverlayBox}>
        <span className={classes.tagHeading}>Tags</span>
        <div>
          {tags.length === 0 ? (
            <div className={classes.noTagText}>No tags assigned</div>
          ) : (
            <div className={classes.tagBox}>
              {tags.slice(0, 3).map((tag) => (
                <Chip
                  label={tag.tag}
                  onDelete={() => handleDelete()}
                  className={classes.chip}
                />
              ))}
              <span>
                {tags.length > 3 ? (
                  <Chip label={  `+ ${tags.length - 3}`} className={classes.chip} />
                ) : null}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TagsOverlay;
