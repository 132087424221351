import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { SortedByFilterCheckbox } from "../../../../components/common/checkbox/SortedByFilterCheckbox";
import { EmptyTextArea } from "../../../../components/common";

export const AnalyticsPreview = () => {
  return (
    <Grid className="analyticContainer">
      <Grid container className="analyticPreview">
        <span className="heading">Analytics</span>
        <Divider />
        <Grid className="checkBoxContainer">
          <Grid>
            <SortedByFilterCheckbox />
          </Grid>
          <Grid className="checkboxText">
            <Typography className="macIDText"> MAC ID: 1234567890 </Typography>
            <Typography className="lastConnectedText">
              Last Connected:<span> 11:00 AM PST, 12/03/23 </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="analyticDataContainer" container xs={12}>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            User ID
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            123456789
          </Grid>
        </Grid>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Gateway MAC ID
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            1234567890
          </Grid>
        </Grid>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Spa Pack Configuration
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            123459023456789
          </Grid>
        </Grid>
      </Grid>
      <Grid className="analyticDataContainer" container xs={12}>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            User Type
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            End User
          </Grid>
        </Grid>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Gateway Serial Number
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            1234567890
          </Grid>
        </Grid>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Topside OS
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            AquaOS v1.4
          </Grid>
        </Grid>
      </Grid>
      <Grid className="analyticDataContainer" container xs={12}>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Spa Serial Number
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            123456789
          </Grid>
        </Grid>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Spa Pack Serial Number
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            1234567890
          </Grid>
        </Grid>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Topside Graphics
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            123459023456789
          </Grid>
        </Grid>
      </Grid>
      <Grid className="analyticDataContainer" container xs={12}>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Device Health
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            123456789
          </Grid>
        </Grid>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Spa Pack OS
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            AquaOS v1.4
          </Grid>
        </Grid>
        <Grid item xs={4} className="analyticData">
          <Grid className="analyticDataKey" item xs={6}>
            Topside DSP
          </Grid>
          <Grid className="analyticDataValue" item xs={6}>
            123459023456789
          </Grid>
        </Grid>
      </Grid>
      <Grid className="analyticNote">
        <Typography className="noteHeading">Notes</Typography>
        <EmptyTextArea
          placeholder="Enter notes"
          className={"analyticTextArea"}
        />
      </Grid>
    </Grid>
  );
};
