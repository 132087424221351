import React from 'react';
import Dashbard from "../Dashbard";

function YourViews(props) {
    return (
        <div>
        <Dashbard>
   <div className="body-content">         
    
       <div className="contentHeading">
         <div className="newsPortalHeaderWrapper">
           <h1>Your Views</h1>             
         </div>         
       </div>       
       <div class="contentBody conversationBody">         
  
  cbcvbcvbcv

       </div> 
   </div>
 </Dashbard>
       
   </div>
    );
}

export default YourViews;