import React, { Children } from 'react';

function MainHeading({headerSubHeading, children, backLink, backNavHandler, icon }) {
    return (
        <div className="headerBottom">
        <div className="headerBottomInner">{headerSubHeading}
        <span onClick={backNavHandler} >{icon}{backLink}</span>
        </div>
        <span>{children}</span>
      </div>
    );
}

export default MainHeading;