import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dashboard from "../Dashbard";
import { useHistory } from "react-router-dom";
import AllAdminGrid from "./AllAdminGrid";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";

function AllAdminUsers() {
  document.title = "OFish - Admin Users";

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [adminUserList, setAdminUserList] = useState([]);

  useEffect(() => {
    loadAdminsList();
  }, []);

  const loadAdminsList = () => {
    setLoading(true);
    dispatch(endUserActions.onGetAllAdmins()).then(data => {
      setLoading(false);
      if (data?.success) {
        setAdminUserList(data.data);
      } else {
        toast.error(data?.message);
      }
    });
  };

  const history = useHistory();
  const redirectUrl = () => {
    history.push("/admin-overview");
  };

  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>Admin Users </h1>
          <Button
            color='primary'
            onClick={redirectUrl}
            variant='contained'
            startIcon={<AddIcon />}>
            New Admin User
          </Button>
        </div>

        <div className='gridBody  contentBody'>
          <AllAdminGrid userData={adminUserList} />
        </div>
      </div>
    </Dashboard>
  );
}

export default AllAdminUsers;
