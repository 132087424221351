import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import Popover from "@material-ui/core/Popover";

const ColorPickerTool = ({ color, anchorEl, handleClose, handleChange }) => {
  const styles = reactCSS({
    default: {
      cover: {
        position: "fixed",
        top: "-5px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div
        style={styles.swatch}
        onClick={handleClose}
        aria-describedby="color-popover"
      >
        <div style={styles.color} />
      </div>
      <Popover
        id="color-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div>
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      </Popover>
    </div>
  );
};

const ColorPicker = ({ onColorSelect, saveColor, edit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [color, setColor] = useState(
    saveColor && saveColor !== "" ? saveColor : "#c4c4c4"
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onColorSelect(color);
    setAnchorEl(null);
  };

  const handleChange = (color) => {
    setColor(color.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        height: "83px",
        top: "-5px",
        borderRadius: "4px",
        background: color,
      },
      swatch: {
        padding: "0",
        display: "inline-block",
        cursor: "pointer",
        width: "100%",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} aria-describedby="color-popover">
        <div style={styles.color} onClick={edit && handleClick} />
      </div>
      {edit && (
        <ColorPickerTool
          color={color}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

export { ColorPicker, ColorPickerTool };
