import React, { useState, useEffect } from "react";
import { ofishlogo } from "../../../resources/images/index";
import {
  Settings,
  Notification,
  Help,
} from "../../../ofishadmin/views/LeftNav/SvgIcons";
import { Dropdown } from "../../common";
import { Button } from "@material-ui/core";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

function DashboardHeader(props) {
  const [isLogout, setIsLogout] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const history = useHistory();
  useEffect(() => {
    const fName = localStorage.getItem("name");
    const lName = localStorage.getItem("lName");
    setFirstName(fName);
    setLastName(lName);
  }, []);

  const redirect = () => {
    history.push("/");
  };

  return (
    <div className='dashboardHeaderWrapper'>
      <div className='leftNavLogo'>
        <img src={ofishlogo} alt='' />
        <span>
          <Dropdown value='' placeholder='Aquatic AV' />
        </span>
      </div>
      <div className='headerRight'>
        <span>
          <input
            type='text'
            placeholder='Search product name, SKU, serials, orders and more'
          />
        </span>
        <span className='DashboardHeaderIcons'>
          <Settings />
          <Notification />
          <Help />
          <span>Hi, {lastName} </span>
          <OutsideClickHandler onOutsideClick={() => setIsLogout(false)}>
            <span className='circleName' onClick={() => setIsLogout(!isLogout)}>
              {`${firstName?.charAt(0).toUpperCase()}${lastName
                ?.charAt(0)
                .toUpperCase()}`}
              {isLogout && (
                <span className='logoutDropdown'>
                  <Button
                    onClick={() => {
                      history.push("/");
                    }}>
                    Newsroom
                  </Button>
                  <Button
                    onClick={() => {
                      localStorage.removeItem("access_token");
                      localStorage.removeItem("isAuthenticated");
                      localStorage.removeItem("userEmail");
                      localStorage.removeItem("isAdmin");
                      localStorage.removeItem("name");
                      localStorage.removeItem("lName");
                      localStorage.removeItem("id");
                      Cookies.remove("access_token");
                      redirect();
                    }}>
                    Logout
                  </Button>
                </span>
              )}
            </span>
          </OutsideClickHandler>
        </span>
      </div>
    </div>
  );
}

export default DashboardHeader;
