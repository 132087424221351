import {
  Grid,
  Button,
  Divider,
  Typography,
  List,
  ListItem,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { EligibleDevicesPopup } from "./EligibleDevicesPopup";
import SoftwareGridFilter from "./SoftwareGridFilter";
import { ChevronRight } from "../../LeftNav/SvgIcons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ProductsAccordian = ({
  buildId,
  setEligibleContentDone,
  accordionName,
  setAccordionName,
  setAccordionKey,
  productsList,
  compatibleProductsList,
  selectedReleaseProducts,
  selectedRelease,
  removeEligibleSelectedDevice,
  handleAddEligibleDevice,
}) => {
  const [order, setOrderBy] = useState({
    orderByKey: "ProductName",
    orderVal: 0,
  });
  const { id: paramId, buildId: selectedBuildId } = useParams();
  const defaultItemsToShow = 3;

  const [updataProductData, setUpdataProductData] = useState([]);
  const [eligibleDevice, setEligibleDevice] = useState(3);
  const [searchEligibleDevices, setSearchEligibleDevices] = useState("")

  const filetrdEligibleDevices = selectedReleaseProducts.filter((x) =>
    x?.productName?.toLowerCase().includes(searchEligibleDevices.toLocaleLowerCase())
  );
  const [isEligible, setIsEligible] = useState(false);

  const handleEligibleDevice = () => {
    setEligibleDevice((prevItems) =>
      prevItems === defaultItemsToShow
        ? selectedReleaseProducts.length
        : defaultItemsToShow
    );
  };

  const onSetAccorditionAction = () => {
    setAccordionName("");
    if ((!buildId && !paramId) || (buildId && !selectedBuildId)) {
      setAccordionKey(4);
      setEligibleContentDone(true);
    }
  }

  useEffect(() => {
    let sortedProduct = [];
    if (order.orderByKey === "ProductName" && order.orderVal === 1) {
      sortedProduct = productsList.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (order.orderByKey === "ProductName" && order.orderVal === 0) {
      sortedProduct = productsList
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .reverse();
    } else if (order.orderByKey === "SKU" && order.orderVal === 1) {
      sortedProduct = productsList.sort((a, b) => (a.sku > b.sku ? 1 : -1));
    } else if (order.orderByKey === "SKU" && order.orderVal === 0) {
      sortedProduct = productsList
        .sort((a, b) => (a.sku > b.sku ? 1 : -1))
        .reverse();
    } else if (order.orderByKey === "Brand Name" && order.orderVal === 1) {
      sortedProduct = productsList.sort((a, b) => (a.brand > b.brand ? 1 : -1));
    } else if (order.orderByKey === "Brand Name" && order.orderVal === 0) {
      sortedProduct = productsList
        .sort((a, b) => (a.brand > b.brand ? 1 : -1))
        .reverse();
    } else if (order.orderByKey === "Category" && order.orderVal === 1) {
      sortedProduct = productsList.sort((a, b) =>
        a.category > b.category ? 1 : -1
      );
    } else if (order.orderByKey === "Category" && order.orderVal === 0) {
      sortedProduct = productsList
        .sort((a, b) => (a.category > b.category ? 1 : -1))
        .reverse();
    }
    setUpdataProductData(sortedProduct);
  }, [
    productsList,
    order.orderVal,
    order.orderByKey,
  ]);

  return (
    <Grid container className="eligibleDevicesAccordian">
      <Divider />
      <EligibleDevicesPopup
        buildId={buildId}
        isEligible={isEligible}
        setIsEligible={setIsEligible}
        title={"Eligible Devices"}
        heading={"OTA Devices"}
        description={
          "Select the products that are eligible to receive this release."
        }
        totalRecords={compatibleProductsList.length}
        updataProductData={updataProductData}
        order={order}
        setOrderBy={setOrderBy}
        selectedRelease={selectedRelease}
        selectedReleaseProducts={selectedReleaseProducts}
      />
      <Grid className="eligibleConatiner">
        <Grid className="eligibleHeading">
          {accordionName === "eligibleDevices" && (
            <Button
              className="addEilibleButton"
              onClick={() => setIsEligible(true)}
            >
              <AddIcon />
            </Button>
          )}
          <span className="heading">Eligible Devices</span>
        </Grid>
        <Typography className="eligibleSummary">
          Select the products that are eligible to receive this release.
        </Typography>
      </Grid>
      {selectedReleaseProducts?.length > 0 ? (
        <>
          <Grid xs={12} className="eligibleSearch">
            <Typography className="eligibleDevideApplied">
              {selectedReleaseProducts.length} Eligible Devices Applied
            </Typography>
            <SoftwareGridFilter
              search={searchEligibleDevices}
              setSearch={setSearchEligibleDevices}
              placeholder={"Search"}
            />

          </Grid>
          <Grid className="allChipsContainer">
            <List>
              <ListItem>
                <Grid className="chevronIcon">
                  <IconButton onClick={handleEligibleDevice}>
                    <ChevronRight />
                  </IconButton>
                </Grid>
                <Grid className="chipsItemGrid">
                  {filetrdEligibleDevices
                    .slice(0, eligibleDevice)
                    .map((item) => (
                      <Grid className="chipsContainer" key={item}>
                        <Typography className="chipsTypo">
                          {item.productName}
                        </Typography>
                        <Grid
                          className="closeBtn"
                          onClick={() => removeEligibleSelectedDevice(item)}
                        >
                          <CloseIcon />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </>
      ) : (
        <Grid xs={12} className="addChip">
          <span className="content">
            You haven’t added any OTA devices. Start by adding an
          </span>
          &nbsp;
          <span className="portal" onClick={() => setIsEligible(true)}>
            OTA Device
          </span>
        </Grid>
      )}
      <Grid></Grid>
      <Grid className="btn_save_next">
        {accordionName === "eligibleDevices" && (
          <>
            <Button
              color="primary"
              variant="contained"
              className="skipButton"
              onClick={() => {
                onSetAccorditionAction();
              }}
            >
              Skip
            </Button>
            <Button
              color="primary"
              disabled={selectedReleaseProducts.length === 0}
              variant="contained"
              type="submit"
              className="saveBasicInfo"
              onClick={() => {
                handleAddEligibleDevice();
                onSetAccorditionAction();
              }}
            >
              Next
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductsAccordian;
