import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Reporting from "../SpaProduct/reporting";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function SpaReporting(props) {
  const classes = useStyles();
  return (
    <div className="spaDeviceContentBody">
      <div className={classes.root}>
        <Grid container spacing={3} className="gridMainSpaWrapper">
          <Grid item xs={12}>
            <Reporting />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SpaReporting;
