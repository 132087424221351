import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Trash } from "../../../LeftNav/SvgIcons";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

const data = [
  {
    type: "Text Options",
    name: "Environment",
    value: "Harley",
    productusing: "source unit",
  },
  {
    type: "Text Options",
    name: "Environment",
    value: "Harley",
    productusing: "source unit",
  },
  {
    type: "Text Options",
    name: "Environment",
    value: "Harley",
    productusing: "source unit",
  },
];

function AllAtributesGrid() {
  const classes = useStyles();
  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <>
      {" "}
      <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
        <div className='addressGrid allAttributesGridWrapper'>
          <span className='gridHeaader'>
            <ul>
              {/* <li /> */}
              <li>Type</li>
              <li>Name</li>
              <li>Value</li>
              <li>Products Using</li>
              <li>
                <Checkbox />
              </li>
            </ul>
          </span>
          <span className='gridBody enduserGrid'>
            {data.map((item, index) => {
              return (
                <ul key={index}>
                  <li>
                    <span className='prodPicture'></span>
                    {item.type}
                  </li>
                  <li>{item.name}</li>
                  <li className='highlishtedItem'>{item.value}</li>
                  <li>{item.productusing}</li>
                  <li>
                    <Trash />
                    <Checkbox />
                  </li>
                </ul>
              );
            })}
          </span>
          <div className='attributeFooter'>
            <span>{data.length} Added</span>
            <span>
              <Button color='primary' variant='contained'>
                Save
              </Button>
            </span>
          </div>
        </div>
      </ValidatorForm>
    </>
  );
}

export default AllAtributesGrid;
