import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, IconButton, Typography } from "@material-ui/core";
import { Close, Delete, UploadIcon } from "../../../LeftNav/SvgIcons";
import { endUserActions } from "../../../../../store/endUser/endUserActions";
import { useDispatch } from "react-redux";
import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "6px",
    width: "700px",
  },
  paper2: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "6px",
    width: "550px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 80,
    borderBottom: "2px solid #E2E5E9",
    paddingRight: 20,
    paddingLeft: 28,
    background: "#00A3FF",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  newPricingTypo: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#fff",
  },
  inenerContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  content: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Roboto",
    paddingLeft: 16,
    color: "#3699FF",
    paddingRight: 19,
    letterSpacing: "-0.01em",
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  fileInput: {
    display: "none",
  },
}));

const RecieptModal = ({
  open,
  recieptModal,
  isUploadRecieptModal,
  isDispatch,
  setRecipt,
  devId,
  deviceType,
  getSpaDeviceInfo
}) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No selected file");
  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState("");
  // Setting service worker URL - otherwise pdf will not render
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  
  const { deviceOverviewInfo,
    spaDeviceDetails,
    prchPrice,
    spaName,
    prchDate,
    deviceDelearId,
    spaTopsidePrchDate,
    spaDeviceInstallationDate,
    spaTopsideInstallDate,
    spaPurchaseLatitude,
    spaPurchaseLocation,
    spaPurchaseLongitude,
    topSidePurchaseLatitude,
    topSidePurchaseLocation,
    topSidePurchaseLongitude,
    controllerPurchaseLatitude,
    controllerPurchaseLocation,
    controllerPurchaseLongitude,
  } = useSelector(
    state => state.endUserReducer
  );

  const [receipt, setReceipt] = useState({
    url: "",
    type: "",
  });
  const [pageNumber, setPageNumber] = useState([1]);
 
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png' , 'application/pdf'];
    const selectedFile = e.target.files[0];
    if (acceptedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setFileData(reader.result);
        setFileType(selectedFile.type);
      };
      if (isDispatch) {
        dispatch(endUserActions.onChangeOfRegister("recipt", e.target.files[0]));
        setRecipt(selectedFile.name);
      }}else{
        toast.error("Images are supported only. (JPEG, JPG, PNG and PDF)");
      }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileChange({ target: { files } });
  };

  const handleDeleteFile = () => {
    setFileData(null);
    setFile(null);
    setFileName("No selected file");
    setFileType("");
  };

  const handlePdfFileSubmit = (e) => {
    e.preventDefault();
    if (fileData !== null) {
      recieptModal(file);
      dispatch(endUserActions.onUploadRecipt(file)).then((data) => {
        if (data && data.success && data.data) {
          if (isDispatch) {
            dispatch(endUserActions.onChangeOfRegister("receipt", data.data.url));
            dispatch(endUserActions.onChangeOfRegister("receiptType", data.data.type === 'pdf' ? data.data.type : 'img'));
            setRecipt(data.data);
          } else if (deviceType === 'spa') {
            updateSPADevice(data.data);
          } else {
            updateNonSpaDevice(data.data);
          }
        }
      });
    };
  };

  const onPdfLoad = ({ numPages }) => {    
    const pagesArray = [];
    for (let i=1; i <= numPages; i++) {
      pagesArray.push(i);
    }
    setPageNumber([...pagesArray]);
  };

  const updateSPADevice = (file) => {
    const devicePayload = {
      price: prchPrice,
      deviceName: spaName,
      deviceId: devId,
      dealerId: deviceDelearId,
      purchaseDate: prchDate,
      installationDate: spaDeviceInstallationDate,
      installationLocation: spaPurchaseLocation,
      installationLongitude: spaPurchaseLongitude,
      installationLatitude: spaPurchaseLatitude,
      receipt: file.url,
      receiptType: file.type === 'pdf' ? file.type : 'img',
      // spaTopside: {
      //   purchaseDate: spaTopsidePrchDate,
      //   installationDate: spaTopsideInstallDate,
      //   purchaseLocation: topSidePurchaseLocation,
      //   purchaseLongitude: topSidePurchaseLatitude,
      //   purchaseLatitude: topSidePurchaseLongitude
      // },
      // spaController: {
      //   purchaseLocation: controllerPurchaseLocation,
      //   "purchaseLongitude": controllerPurchaseLongitude,
      //   "purchaseLatitude": controllerPurchaseLatitude
      // }
    };
    dispatch(endUserActions.onAddUpdateSpaSetting(devicePayload)).then(
      data => {
        if (data?.success) {
          getSpaDeviceInfo(devId);
        }
      }
    );
  };

  const updateNonSpaDevice = (file) => {
    const updateObj = {
      receipt: file.url,
      receiptType: file.type === 'pdf' ? file.type : 'img',
      deviceId: devId,
      serialNo: "",
      purchaseDate: deviceOverviewInfo?.purchaseDate,
      dealerLocationId: "",
      receiptStatus: 0,
      envId: deviceOverviewInfo?.environmentId,
      name: deviceOverviewInfo?.name,
      warrantyStatus: deviceOverviewInfo?.warrantyStatus === "Active" ? 1 : deviceOverviewInfo?.warrantyStatus === "Expired" ? 2 : 3,
      warrantyExpiryDate: deviceOverviewInfo?.expiryDate === "Pending Approval" ? undefined : deviceOverviewInfo?.expiryDate,
    };
    dispatch(endUserActions.onUpdateUserDevice(updateObj)).then((data) => {
      if (data && data.success) {
        toast.success("The Information updated successfully");
        dispatch(endUserActions.onGetDeviceOverview(devId));
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
  };

  useEffect(() => {
    setReceipt({
      url: spaDeviceDetails?.receipt || deviceOverviewInfo?.receipt,
      type: spaDeviceDetails?.receiptType ||  deviceOverviewInfo?.receiptType 
    })
  }, [deviceOverviewInfo?.receipt, spaDeviceDetails?.receipt])

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={recieptModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={ isUploadRecieptModal ? classes.paper2 : classes.paper }>
            <div className={classes.header}>
              <div className={classes.inenerContent}>
                <Typography className={classes.newPricingTypo}>
                  {isUploadRecieptModal ? "Upload Receipt" : "View Receipt"}
                </Typography>
              </div>
              <IconButton className={classes.closIcon} onClick={recieptModal}>
                <Close />
              </IconButton>
            </div>
            {isUploadRecieptModal ? (
              <div
                className="fileUploadContanier"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className="uplodadfileRoot">
                  <input
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    className={classes.fileInput}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="contained-button-file">
                    <Button className="uploadFileBtn" component="span">
                      <UploadIcon />
                    </Button>
                  </label>

                  <div className="dragContent">
                    Drag files here or click to upload
                  </div>
                </div>
                {/* Display the name of the selected file */}
                {fileName && (
                  <div className="reciptPdf">
                    <span className="fileLink">{fileName}</span>
                    <span>
                      <IconButton
                        className={classes.deleteIcon}
                        onClick={handleDeleteFile}
                      >
                        <Delete />
                      </IconButton>
                    </span>
                  </div>
                )}
                <div className="userNoteInfo">
                  <span>
                    Images are supported only. (JPEG, JPG, PNG and PDF) <br></br>
                    Please ensure the following fields are properly filled on the receipt:
                    <div className="fieldsOption">
                     <span>1. Price</span>
                     <span>2. Model Number</span>
                     <span>3. Seller Name</span>
                     <span>4. Date of Purchase</span>
                    </div>
                  </span>
                </div>
                <div className="submitBtnContainer">
                  <Button
                    className="submitBtn"
                    variant="contained"
                    color="primary"
                    onClick={handlePdfFileSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : (
              <div className="viewRecieptImageContainer">
                {receipt?.url && (
                  <div className="viewRecieptImage">
                    <div className="viewRecieptImageSection">
                      {receipt.url.includes(".pdf") ? (
                        <div
                        className="deviceReciptPdfFile"
                        >
                          <Document file={receipt.url} onLoadSuccess={onPdfLoad}>
                            {pageNumber.map(page => 
                              <Page pageNumber={page} />
                            )}
                          </Document>
                        </div>
                      ) : (
                        <img
                          src={receipt.url}
                          alt="Device Receipt File"
                          className="deviceReciptFile"
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className="submitBtnContainer">
                  <Button
                    className="submitBtn"
                    variant="contained"
                    color="primary"
                    onClick={recieptModal}
                  >
                    Done
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default RecieptModal;
