import React, { useState, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../store/assetsManagement/assetsActions";
import { AssetLiabraryIcon } from "./SvgIcons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  headingWrapper: {
    backgroundColor: "#E5F2FF !important",
  },
  headingContainer:{
  display:"flex",
  flexDirection:"row",
  },
  heading: {
    fontSize: "16px !important",
    fontWeight: "500",
    color: "#2D3339 !important",
    display: "flex",
    alignItems: "center",
  },
  svgIcon: {
    marginRight: "11px",
  },
  expanded: {
    backgroundColor: "transparent",
  },
  links: {
    flexDirection: "column",
    paddingBottom: "0",
  },
  boxes: {
    boxShadow: "none",
  },
  hyperLink: {
    marginLeft: "15px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#8D99A5",
    },
  },
  activeTest: {
    color: "#3699FF",
  },
}));

export default function AssetManagementNav({ startLoading, endLoading }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    key: "Name",
    val: 0,
  });

  const history = useHistory();
  const currentRoot = history ? history.location.pathname : null;
  const mediaAccess = () => {
    history.push("assets-media-library");
  };
  const {
    parentId,
    gridData,
    favoritesAssets,
    breadCrumbs,
    type,
    search,
    time,
    isfavorite,
    pageSize,
    pageNo,
    orderByKey,
    orderByVal,
  } = useSelector((state) => state.AssetsReducer);

  const setFolderData = useCallback((item) => {
    startLoading();
    dispatch(
      assetsActions.onGetAssetManagementList({
        parentId: item.id,
        type: type,
        search: search,
        time: time,
        isfavorite: isfavorite,
        pageSize: pageSize,
        pageNo: pageNo,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      })
    ).then(
      (data) => {
        dispatch(assetsActions.onChange("parentId", item.id));
        dispatch(assetsActions.onChange("currentFolder", item.id));
        addNewBreadCrumbItem(item.id, item.name);
        endLoading();
      },
      [
        dispatch,
        parentId,
        type,
        search,
        time,
        isfavorite,
        orderByKey,
        orderByVal,
        pageNo,
        pageSize,
      ]
    );
  });





  const addNewBreadCrumbItem = (id, folderName) => {
    var breadCrumbsData = breadCrumbs;
    let data = {
      title: folderName,
      id: id,
    };
    breadCrumbsData.push(data);
    dispatch(assetsActions.onChange("breadCrumbs", breadCrumbsData));
  };
  return (
    <div className="subNavWrapper">
      {/* {isLoading ? <Loader /> : null} */}
      <h1>Asset Library</h1>
      <div className={classes.root}>
        <Accordion className={classes.boxes} expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={
              [
                "/assets-media-library",
                "/assets-media-library-favorite",
                "/assets-media-library-conversations",
                '/assets-media-library-sharedfiles',
                '/assets-media-library-internalFiles',
                '/assets-media-library-trash'
              ].includes(currentRoot)
                ? classes.headingWrapper
                : null
              // className={
              //   currentRoot === "/assets-media-library" && classes.headingWrapper
            }
          >
            <Typography  onClick={mediaAccess}>
              {/* <img src={launches} alt="launches" className="launchIcon" /> */}
              {/* <img src={mediaReleases} alt="launches" className="releaseIcon" /> */}
              <span className={classes.heading}>
              <AssetLiabraryIcon /> 
              Asset Library
              </span>
            </Typography>
          </AccordionSummary>
          {/* </Accordion>
        <Accordion className={classes.boxes} expanded={true}> */}
          {/* <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={
              currentRoot === "/assets-media-library/*"
                ? classes.headingWrapper
                : null
            }
          >
            <Typography className={classes.heading}>
              <img src={mediaReleases} alt="launches" className="releaseIcon" />
              Favourites
            </Typography>
          </AccordionSummary> */}
          <AccordionDetails className={classes.links}>
            <NavLink
              to='/assets-media-library'
              className={`${classes.hyperLink} ${["/assets-media-library"].includes(currentRoot) ? classes.activeTest : null
                }`}
            >
              Asset Library Home
            </NavLink>
            <NavLink
              to='/assets-media-library-favorite'
              className={`${classes.hyperLink} ${["/assets-media-library-favorite"].includes(currentRoot) ? classes.activeTest : null
                }`}
            >
              Favorites
            </NavLink>
            <NavLink
              to='/assets-media-library-conversations'
              className={`${classes.hyperLink} ${["/assets-media-library-conversations"].includes(currentRoot) ? classes.activeTest : null
                }`}
            >
              Conversations
            </NavLink>
            <NavLink
              to='/assets-media-library-sharedfiles'
              className={`${classes.hyperLink} ${["/assets-media-library-sharedfiles"].includes(currentRoot) ? classes.activeTest : null
                }`}
            >
              Shared Files
            </NavLink>
            {/* {gridData
              .filter((x) => x.type === "folder" && x.isfavorite)
              .map((item, index) => {
                return (
                  <NavLink
                    onClick={() => setFolderData(item)}
                    key={index}
                    to="/assets-media-library"
                    className="hyperLink"
                  >
                    <img
                      src={folder}
                      alt=""
                      style={{ height: 13.5, width: 15 }}
                    />{" "}
                    {item.name}{" "}
                    <img
                      src={star}
                      alt=""
                      style={{
                        height: 15,
                        width: 15,
                        float: "right",
                      }}
                    />
                  </NavLink>
                );
              })} */}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
