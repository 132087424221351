import React, { useRef } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { searchIcon } from "../../../../resources/images";
import { SearchFilter } from "../../LeftNav/SvgIcons";
import InputField from "../../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";

const CustomerGroupGridFilter = ({ 
  setFilterByName, 
  setFilterReleaseByName,
  id="",
  filterPlaceholder='Search for contacts, companies, groups & more'
 }) => {
  const [search, setSearch] = React.useState("");
  
  const setSearchValue = () => {
    setFilterByName(search);
  };

  return (
    <div className="customerGird">
      <ValidatorForm ref={useRef("form")}>
        <Grid className="filterMainSearch">
          <Grid
            className={
              id === "softwareReleases"
                ? "filterSearchGridSoftwareRelease"
                : "filterSearchGrid"
            }
          >
            <img
              src={searchIcon}
              alt="searchIcon"
              className="customerSearchIcon"
            />
            <InputField
              inputlabel="Search"
              placeholder={filterPlaceholder}
              onChange={(e) => {
                setSearch(e.target.value);
                setFilterReleaseByName(e.target.value);
              }}
            />
            {id !== "softwareReleases" ? (
              <Grid onClick={setSearchValue} className="filterIcon">
                <SearchFilter />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};

export default CustomerGroupGridFilter;
