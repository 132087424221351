import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { launches } from "../../../resources/images";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headingWrapper: {
    backgroundColor: "#E5F2FF !important",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    display: "flex",
    alignItems: "center",
  },
  svgIcon: {
    marginRight: "11px",
  },
  expanded: {
    backgroundColor: "transparent",
  },
  links: {
    flexDirection: "column",
    paddingBottom: "0",
  },
  boxes: {
    boxShadow: "none",
  },
  hyperLink: {
    marginLeft: "15px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#3699FF",
    },
  },
  activeTest: {
    color: "#3699FF",
  },
}));

export default function DashboardNav() {
  const classes = useStyles();

  const history = useHistory();
  const currentRoot = history ? history.location.pathname : null;
  const dashboardMain = () => {
    history.push("dashboard");
  };

  return (
    <div className='subNavWrapper'>
      <h1>Dashboards</h1>
      <div className={classes.root}>
        <Accordion className={classes.boxes} expanded={true}>
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className={currentRoot === "/dashboard" && classes.headingWrapper}>
            <Typography className={classes.heading} onClick={dashboardMain}>
              <img src={launches} alt='launches' className='launchIcon' />
              Main Dashboard
            </Typography>
          </AccordionSummary>
        </Accordion>
      </div>
    </div>
  );
}
