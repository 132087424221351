// import { productActionTypes } from "./productActionTypes";
import { productMediaApi } from "./productMediaApi";

const onGetProductMedia = id => async dispatch => {
  return await productMediaApi.getAllProductMedia(id);
};

// const getProductById = id => async dispatch => {
//   return await productMediaApi.getProductById(id);
// };

const onAddUpdateProductMedia = data => async dispatch => {
  return await productMediaApi.addUpdateProductMedia(data);
};

const onDeleteProductMedia = pmID => async dispatch => {
  return await productMediaApi.deleteProductMediaById(pmID);
};

const onMarkMainImage = (productId, productMediaId) => async dispatch => {
  return await productMediaApi.markMainImage(productId, productMediaId);
};

const onMarkUseOnStore = (productMediaId, useOnStore) => async dispatch => {
  return await productMediaApi.markUseOnStore(productMediaId, useOnStore);
};

export const productMediaAction = {
  onGetProductMedia: onGetProductMedia,
  // getProductById: getProductById,
  onAddUpdateProductMedia: onAddUpdateProductMedia,
  onDeleteProductMedia: onDeleteProductMedia,
  onMarkMainImage: onMarkMainImage,
  onMarkUseOnStore: onMarkUseOnStore
};
