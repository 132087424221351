import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import CategoryOveriew from "./CategoryOveriew";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Dashbard from "../Dashbard";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  eventCatTextarea: {},
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function AddLaunchCategory() {
  const [categoryNameInternal, setCategoryNameInternal] = useState("");
  const [categoryNamePublic, setCategoryNamePublic] = useState("");
  const [categoryColor, setCategoryColor] = useState("");
  const history = useHistory();

  const redirectUrl = () => {
    history.push("/launch-categories");
  };

  const classes = useStyles();
  return (
    <Dashbard>
      <div className='body-content generalTabs launchTabs'>
        <div className='consumerHeading backToCategory'>
          <Button color='primary' variant='contained' onClick={redirectUrl}>
            <ArrowBackIosIcon /> Back to Launch Category
          </Button>
        </div>
        <ValidatorForm ref={React.useRef("form")}>
          <TopTabNavigator
            tabs={[
              {
                id: 1,
                title: "Overview",
                component: [<CategoryOveriew />],
              },
            ]}
          />
        </ValidatorForm>
      </div>
    </Dashbard>
  );
}
