import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputField from "../../../../../components/common/InputField";
import Grid from "@material-ui/core/Grid";
import { AccordionContainer } from "../../../../../components/common/AccordionContainer";
import { useDispatch } from "react-redux";
import { GridFilterBarPlaces } from "../LocatorWidget/GridFilterBarPlaces";
import PlacesGrid from "../LocatorWidget/PlacesGrid";
import { toast } from "react-toastify";
import { SettingsOutlined } from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";
import { Pagination } from "../../../../../components/common";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  textArea: {
    minHeight: "470px",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function Places({
  startLoading,
  stopLoading,
  endUserList,
  selectedRecord,
  isDelete,
  order,
  onOrderBy,
  Categories,
  setFilterByName,
  setSelectedCategory,
  setSelectedDate,
  recordCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loadData,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [status, setStatus] = useState(0);
  const [isError, setIsError] = useState(false);
  useEffect(() => {}, []);

  const classes = useStyles();
  return (
    <div className="accordianOuterWrapper">
      <AccordionContainer title="Places Listed">
        <GridFilterBarPlaces
          Categories={Categories}
          setFilterByName={setFilterByName}
          setSelectedCategory={setSelectedCategory}
          setSelectedDate={setSelectedDate}
          loadData={loadData}
        />
        <PlacesGrid
          endUserList={endUserList}
          selectedRecord={selectedRecord}
          isDelete={isDelete}
          order={order}
          onOrderBy={onOrderBy}
          startLoading={() => startLoading()}
          stopLoading={() => stopLoading()}
          loadData={loadData}
        />
        <Pagination
          recordCount={recordCount}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          loadData={loadData}
        />
      </AccordionContainer>
    </div>
  );
}
