import React, { useState, useEffect, useMemo, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import "react-web-tabs/dist/react-web-tabs.css";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../Loader";
import ConversationList from "./ConversationList";
import SearchIcon from "@material-ui/icons/Search";
import { ValidatorForm } from "react-material-ui-form-validator";
import { star, starOutline } from "../../../resources/images/index";
import MessageSubject from "./MessageSubject";
import TextEditor from "./TextEditor";
import data from "./Pagination/data.json";
import { useDispatch, useSelector } from "react-redux";
import * as signalR from "@microsoft/signalr";
import { conversationAction } from "../../../store/conversations/conversationAction";
import { toast } from "react-toastify";
import { Pagination } from "../../../components/common";
import AutoComp from "../../../ofishadmin/views/Dashboard/Conversations/AutoComp";
import ConversationStatesBtn from "./ConversationStatesBtn";
import ConversationStatesDropDown from "../../../ofishadmin/views/Dashboard/Conversations/ConversationStatesDropDown";
import { endpoints } from "../../../config/conversationConfig";
import { FilterIcon } from "../../../ofishadmin/views/LeftNav/SvgIcons";
let PageSize = 10;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

const CustomerConversation = ({
  clientId,
  productId,
  userName,
  generic,
  options,
  campId = "All",
  //selectedtTab,
  onfavButtonPress,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(2);
  const [selectedCampId, setSelectedCampId] = useState(1);
  const [senderInfo, setSenderInfo] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const hubUserId = useRef(0);
  const roomId = useRef(0);
  const autoComplete = useRef(null);
  const [messages, setMessages] = useState([]);
  const [messageDetail, setMessageDetail] = useState([]);
  const [connection, setConnection] = useState(null);
  const [allRoomChecked, setAllRoomChecked] = useState(false);
  const [sendMsg, setSendMsg] = useState(false);
  const [fileName, setFileName] = useState("");
  const connectionUrl = endpoints.conversation.connectionUrl;

  const [getMsg, setGetMsg] = useState("false");
  const dispatch = useDispatch();

  const [isPage, setIsPage] = useState(10);
  const [ispageLength, setIsPageLength] = useState(15);
  const [isTotalRecords, setIsTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emailTo, setEmailTo] = useState("");
  const [emailFrom, setEmailFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [selectedEmailList, setSelectedEmailList] = useState([]);
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [compaginId, setCompaginId] = useState(1);
  const [roomName, setRoomName] = useState("");
  const [isFavorite, setIsFavorite] = useState(null);
  const [isChat, setIsChat] = useState(false);
  const [isSendButtonDisable, setIsSendButtonDisable] = useState(false);

  const gridData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  const classes = useStyles();
  const { isNewConversation, selectedTab } = useSelector(
    state => state.conversationReducer
  );

  const buildConnection = () => {
    setLoading(true);
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    let connect = new signalR.HubConnectionBuilder()
      .withUrl(
        connectionUrl +
          "?ClientId=" +
          clientId +
          "&ProductId=" +
          productId +
          "&ClientName=" +
          userName +
          " " +
          "&generic=" +
          generic,
        options
      )
      .withAutomaticReconnect()
      .withHubProtocol(protocol)
      .build();
    setConnection(connect);
    setLoading(false);
    connect.on("send", data => {});

    connect
      .start()
      .then(() => {})
      .catch(err => {
        console.log("error while establishing signalr connection: " + err);
      });
  };

  useEffect(() => {
    buildConnection();
  }, []);
  useEffect(() => {
    const ele = autoComplete?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
    if (isNewConversation) {
      setIsChat(true);
      setSelectedStatus(1);
      setSelectedCampId(1);
      setEmailTo("");
      setEmailFrom("");
      setSubject("");
      setCompanyList([]);
      setEmailList([]);
      setSelectedEmailList([]);
      setSelectedCompanyList([]);
      setMessageDetail([]);
    }
  }, [isNewConversation]);

  useEffect(() => {
    return () => {
      clearInterval();
    };
  }, []);
  useEffect(() => {
    if (connection) {
      connection.on("onUserConnected", data => {
        if (data) {
          hubUserId.current = data?.HubUserId;
          loadChat(hubUserId.current);
          dispatch(conversationAction.onChange("hubId", hubUserId.current));
        }
      });
      connection.on("MessageRecieved", data => {
        console.log("mesaage", data);
        if (data.roomId === roomId.current) {
          chatDetail(roomId.current);
          loadChat(hubUserId.current);
        } else {
          loadChat(hubUserId.current);
        }
      });
    }
  }, [connection]);

  useEffect(() => {
    if (hubUserId?.current > 0) {
      loadChat(hubUserId.current);
    }
  }, [hubUserId.current, page, rowsPerPage]);

  useEffect(scrollToBottom, [messageDetail.length]);

  useEffect(() => {
    if (hubUserId?.current > 0) {
      loadChat(hubUserId.current);
    }
  }, [selectedTab]);

  const loadChat = hubUserId => {
    if (hubUserId > 0) {
      setLoading(true);
      const campaignId =
        campId === "All"
          ? 0
          : campId === "Favorite" || campId === "Favourite"
          ? 0
          : campId === "YourBrand"
          ? 0
          : campId;
      dispatch(
        conversationAction.onGetUserRoomsByUsersId({
          roomName: roomName,
          productId: 2,
          isFavorite:
            campId === "Favorite" || campId === "Favourite" ? true : null,
          hubUserId: campId === "YourBrand" ? 0 : hubUserId,
          pageNo: page - 1,
          take: PageSize,
          campaignId: parseInt(campaignId),
        })
      ).then(res => {
        setLoading(false);
        var data =
          res?.responseData?.data?.length > 0 ? res.responseData.data : [];
        if (res?.responseData?.data?.length === 0) {
          setIsChat(false);
          setGetMsg(true);
        }
        const rec = data.map(x => {
          return { ...x, checked: false };
        });
        setIsTotalRecords(res?.responseData?.count);
        dispatch(
          conversationAction.onChange("allChat", res?.responseData?.count)
        );
        setMessages(rec);
      });
    }
  };

  const chatDetail = roomId => {
    setLoading(true);
    dispatch(
      conversationAction.onGetChatDetailsByRoomId({
        roomId: roomId,
        productId: 2,
        hubUserId: hubUserId.current,
        take: 10000,
        skip: page - 1,
      })
    ).then(res => {
      setLoading(false);
      if (res?.responseCode === 1) {
        dispatch(conversationAction.onChange("isNewConversation", false));
        setMessageDetail(res?.responseData?.length > 0 ? res.responseData : []);
      }
    });
  };

  const onGetUserAutoCompleteByEmail = email => {
    dispatch(conversationAction.onGetUserAutoCompleteByEmail(email)).then(
      res => {
        var data = res?.data?.data?.length > 0 ? res?.data?.data : [];
        setEmailList(data);
      }
    );
  };

  const onGetCompanyAutoCompleteByName = compnayName => {
    dispatch(
      conversationAction.onGetCompanyAutoCompleteByName(compnayName)
    ).then(res => {
      if (res?.data) {
        setCompanyList(res?.data?.length > 0 ? res?.data : []);
      }
    });
  };

  const setIsStar = (isStar, roomId) => {
    setLoading(true);
    dispatch(
      conversationAction.onMakeRoomFavorite({
        roomId: roomId,
        isFavorite: isStar,
        hubUserId: hubUserId.current,
      })
    ).then(res => {
      setLoading(false);
      if (res?.responseCode === 1) {
        messages.map(x => {
          if (x.roomId === roomId) {
            x.is_Favourite = isStar;
          }
        });
        setMessages([...messages], messages);
        loadChat(hubUserId.current);
      }
    });
  };
  const selectedRecord = roomId => {
    messages.map(x => {
      if (x.roomId === roomId) {
        x.checked = !x.checked;
      }
    });
    setMessages([...messages], messages);
  };

  const selectedAllRecord = () => {
    setAllRoomChecked(!allRoomChecked);
    messages.map(x => {
      x.checked = !allRoomChecked;
    });
    setMessages([...messages], messages);
  };

  const deleteRooms = () => {
    var roomIds = [];
    messages.map(x => {
      if (x.checked) {
        roomIds.push(x.roomId);
      }
    });
    if (roomIds?.length > 0) {
      dispatch(
        conversationAction.onDeleteRooms({
          hubUserId: hubUserId.current,
          roomIds: roomIds,
        })
      ).then(res => {
        var openChat = roomIds.includes(roomId);
        if (openChat) {
          setMessageDetail([]);
        }
        toast.success("Selected chat deleted");
        loadChat(hubUserId.current);
      });
    } else {
      toast.warn("Please select the chat");
    }
  };
  const onSendMessage = async (content, fileName, uploadFile) => {
    var messageContent = content?.getCurrentContent()?.getPlainText();
    if (!messageContent.trim() && !Boolean(fileName)) {
      setIsSendButtonDisable(false);
      return;
    }
    if (messageDetail?.length === 0) {
      let to = [];
      selectedCompanyList.map(x => {
        to.push({
          id: x.id.toString(),
          name: x.name,
          allowedOnCampaignsId: compaginId.toString(),
          generic: "Customer",
        });
      });
      const data = {
        content: messageContent,
        roomName: subject,
        messageFrom: {
          id: "d5083803-29d5-4bf0-bce7-fbaab34011a6",
          name: "Aquatic AV",
          allowedOnCampaignsId: compaginId.toString(),
          generic: "Aquatic AV",
        },
        messageTo: to,
        productId: 2,
      };
      dispatch(conversationAction.onSendNewMsg(data)).then(res => {
        if (res?.responseCode === 1) {
          setEmailTo("");
          setEmailFrom("");
          setSubject("");
          setCompanyList([]);
          setEmailList([]);
          setSelectedEmailList([]);
          setSelectedCompanyList([]);
          updateCampaignIdInRoom(res?.responseData?.roomId);
          chatDetail(res?.responseData?.roomId);
          toast.success(res?.responseMsg);
        }
        setIsSendButtonDisable(false);
      });
    } else {
      const data = {
        RoomId: roomId.current,
        HubUserId: hubUserId.current,
        Content: messageContent,
      };
      if (fileName && fileName !== "") {
        const formData = new FormData();
        formData.append("file", uploadFile[0]);
        dispatch(conversationAction.onUploadFile(formData)).then(res => {
          if (res?.responseCode == 1) {
            data.Attachments = [
              {
                Name: fileName,
                Path: res.responseData,
              },
            ];
            connection.send("SendMessage", data).then(x => {
              chatDetail(roomId.current);
              setSendMsg(!sendMsg);
              checkNewMsg();
              setFileName();
              console.log("Message Sent Successfully");
            });
          }
          setIsSendButtonDisable(false);
        });
      } else {
        connection.send("SendMessage", data).then(x => {
          chatDetail(roomId.current);
          setSendMsg(!sendMsg);
          checkNewMsg();
          console.log("Message Sent Successfully");
          //   MessageContent = "";
          //   FileToUpload = null;
        });
        setIsSendButtonDisable(false);
      }
    }
  };

  const downloadFile = (path, name) => {
    let sanitizedpath = path.split(`\\`).join("||");
    let completePath = endpoints.conversation.downloadFile + sanitizedpath;
    let ele = document.createElement("a");
    ele.href = completePath;
    ele.target = "_blank";
    ele.setAttribute("name", name);
    ele.setAttribute("download", true);
    ele.click();
  };

  const checkNewMsg = () => {
    if (hubUserId.current > 0) {
      dispatch(
        conversationAction.onGetUserRoomsByUsersId({
          hubUserId: hubUserId.current,
          pageNo: page - 1,
          take: PageSize,
        })
      ).then(res => {
        var data =
          res?.responseData?.data?.length > 0 ? res.responseData.data : [];
        const rec = data.map(x => {
          return { ...x, checked: false };
        });
        setMessages([...rec]);
      });
    }
  };

  const updateConversationStatusInRoom = (roomId, statusId) => {
    dispatch(
      conversationAction.onUpdateConversationStatusInRoom({
        roomId: roomId,
        statusId: statusId,
      })
    ).then(res => {
      var data = res?.data?.data?.length > 0 ? res?.data?.data : [];
      setEmailList(data);
    });
  };

  const updateCampaignIdInRoom = roomId => {
    dispatch(
      conversationAction.onUpdateCampaignIdInRoom({
        roomId: roomId,
        hubUserId: hubUserId.current,
        campaignId: compaginId,
      })
    ).then(res => {
      if (res?.responseCode === 1) {
      }
    });
  };

  let statusValue =
    selectedStatus === 1
      ? "Open"
      : selectedStatus === 2
      ? "Draft"
      : selectedStatus === 3
      ? "Closed"
      : "Closed & Archived";
  return (
    <Grid container spacing={3} style={{ position: "relative" }}>
      {isLoading ? <Loader /> : null}

      <Grid item xs={isChat ? 5 : 12} className={classes.gridWrapper}>
        <div className='conversationMainBox messageBoxLeft'>
          <div className='conversationHeader'>
            <span className='conversationSearch'>
              <SearchIcon />
              <input
                type='text'
                placeholder='Search all conversations'
                // onKeyPress={e => enterPressed(e)}
                onChange={e => {
                  setRoomName(e.target.value);
                }}
                value={roomName}
              />
            </span>
            <span className='saerchFilter'>
              {/* <img
                src={saerchFilter}
                alt='search filter'
                onClick={() => loadChat(hubUserId.current)}
              /> */}
              <i onClick={() => loadChat(hubUserId.current)}>
                <FilterIcon />
              </i>
            </span>
          </div>
          {messages?.length === 0 && getMsg === true ? (
            <span className='noChatMessage'>
              Uh Oh! No Conversations have been created.. You can always Start a
              New Conversation{" "}
            </span>
          ) : (
            <div>
              {messages?.map((item, index) => (
                <ConversationList
                  key={index}
                  star={star}
                  staroutline={starOutline}
                  name={
                    item?.chatInfo?.length > 0 ? item?.chatInfo[0].name : ""
                  }
                  messagesubject={item?.roomName}
                  onclickHandler={() => {
                    roomId.current = item?.roomId;
                    chatDetail(item?.roomId);
                    setSelectedStatus(item?.statusId);
                    setSelectedCampId(item?.campaignId);
                    setSenderInfo(item?.chatInfo);
                    setIsChat(true);
                  }}
                  date={item?.lastMessage}
                  isStar={item?.is_Favourite}
                  setIsStar={isStar => {
                    setIsStar(isStar, item.roomId);
                    onfavButtonPress({ isStar, roomId: item.roomId });
                  }}
                  selectedRecord={() => {
                    selectedRecord(item.roomId);
                  }}
                  isChecked={item?.checked}
                  lastMessage={item?.lastMessage}
                  campaignId={item?.campaignId}
                  statusId={item?.statusId}
                  color={item?.color}
                />
              ))}
              <Pagination
                recordCount={isTotalRecords}
                page={page - 1}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          )}
        </div>
      </Grid>

      {isChat && (
        <Grid item xs={7} className={classes.gridWrapper}>
          <div className='messageMainBox'>
            <div className='MessageBoxInner'>
              <div className='conversationHeader'>
                <span>
                  {" "}
                  {messageDetail?.length > 0
                    ? messageDetail[0].roomName
                    : "New Conversation"}{" "}
                </span>

                <button>Send As: OFish® Message</button>
              </div>
              <ValidatorForm onSubmit={() => {}}>
                <Grid container spacing={2}>
                  {messageDetail?.length > 0 ? (
                    <Grid>
                      <span className='staticFromWrapper borderRemove'>
                        {senderInfo.map(x => (
                          <span className='fromChips'>{x?.name}</span>
                        ))}
                      </span>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={12}
                      className='filterMainSearch chatTextField'>
                      <span className='staticFromWrapper'>
                        <i className='fromLabel'>From:</i>
                        <span className='fromChips'>
                          You @ Aquatic AV <i>(Default)</i>
                        </span>
                      </span>
                      <span className='chatToWrapper'>
                        <AutoComp
                          data={companyList}
                          onChangeClick={e => {
                            onGetCompanyAutoCompleteByName(e);
                          }}
                          onSetSelectedEmailList={e => {
                            setSelectedCompanyList(e);
                          }}
                          autoComplete={autoComplete}
                        />
                      </span>

                      <input
                        type='text'
                        placeholder='Enter a Conversation Title'
                        value={subject}
                        onChange={e => {
                          setSubject(e.target.value);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item md={12} className='chatTextButton'>
                    {messageDetail?.length > 0 ? (
                      <div>
                        <span className='convId'>
                          Conversation ID: <i>{roomId.current}</i>{" "}
                        </span>
                        <button
                          style={{
                            backgroundColor: `${
                              selectedCampId === 1
                                ? "#F2994A40"
                                : selectedCampId === 2
                                ? "#EB575740"
                                : selectedCampId === 3
                                ? "Facebook"
                                : selectedCampId === 4
                                ? "Support"
                                : "Sales"
                            }`,
                            color: `${
                              selectedCampId === 1
                                ? "#F2994A"
                                : selectedCampId === 2
                                ? "#EB5757"
                                : selectedCampId === 3
                                ? "Facebook"
                                : selectedCampId === 4
                                ? "Support"
                                : "Sales"
                            }`,
                          }}>
                          {selectedCampId === 1
                            ? "General"
                            : selectedCampId === 2
                            ? "Press"
                            : selectedCampId === 3
                            ? "Facebook"
                            : selectedCampId === 4
                            ? "Support"
                            : "Sales"}
                        </button>
                      </div>
                    ) : (
                      <ConversationStatesBtn
                        setCompaginId={e => setCompaginId(e)}
                      />
                    )}
                    {messageDetail?.length > 0 ? (
                      <ConversationStatesDropDown
                        value={selectedStatus}
                        setStatusId={e => {
                          setSelectedStatus(e);
                          updateConversationStatusInRoom(roomId.current, e);
                        }}
                      />
                    ) : (
                      <>
                        <button className='draftGrey'>{statusValue}</button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </ValidatorForm>
              <div
                style={{
                  height: "350px",
                  overflowY: "scroll",
                }}>
                {messageDetail.map((item, index) => (
                  <MessageSubject
                    key={index}
                    CurrentHubUserId={hubUserId.current}
                    hubUserId={item.hubUserId}
                    name={item.name}
                    subheading={item.subheading}
                    date={item.date}
                    time={item.time}
                    doc={item.doc}
                    message={item.content}
                    attachments={
                      item?.attachements?.length > 0 ? item.attachements[0] : []
                    }
                    onAttachmentClick={() => {
                      downloadFile(
                        item?.attachements[0]?.path,
                        item?.attachements[0]?.name
                      );
                    }}
                  />
                ))}
                <div ref={messagesEndRef} />
              </div>
              {selectedStatus === 1 || selectedStatus === 2 ? (
                <TextEditor
                  onSendBtnClick={(msg, fileName, uploadFile) => {
                    setIsSendButtonDisable(true);
                    onSendMessage(msg, fileName, uploadFile);
                  }}
                  sendMsg={sendMsg}
                  fileName={fileName}
                  setFileName={x => setFileName(x)}
                  isSendButtonDisable={isSendButtonDisable}
                />
              ) : (
                <></>
              )}
            </div>
            {/* )} */}
          </div>
        </Grid>
      )}

      {/* <span className="conversationChatIcon">
        <img
          src={conversationIcon}
          alt="Conversation Icon"
          onClick={() => setIsChat(!isChat)}
        />
      </span> */}
    </Grid>
  );
};

export default CustomerConversation;
