import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import { first, concat, orderBy, uniq, uniqBy, max } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { AccordionContainer } from "../../../../../components/common";
import Loader from "../../../../../components/common/Loader";
import SelectInput from "../../../../../components/common/SelectInput";
import EmptyTextarea from "../../../../../components/common/Textarea";
import InputField from "../../../../../components/common/InputField";
import {
  LocatorPath96Down,
  LocatorPath96Up,
  locatorVector,
  locatorVectorLeft,
} from "../../../../../resources/images";
import { endpoints } from "../../../../../config/apiConfig";
import { useDropdownValues } from "../../../../../utils/use-dropdown-values";
import { useLoadData } from "../../../../../utils/use-load-data";
import {
  CopyPriceListAccessPopup,
  GroupNameChip,
  PriceListAccessPopup,
} from "./PriceListAccessPopup";
import { useCRUD } from "../../../../../utils/use-crud";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "5",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  setLaunched: {
    backgroundColor: "#00ABA3",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    // border: "1px solid #70808F",
    borderRadius: "6px",
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
}));

const NewPriceOverview = ({ plData, onplDataChanged }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const [priceListTitle, setPriceListTitle] = useState("New Price List");
  const [priceList, setPriceList] = useState({
    papId: 1,
    isConfidential: false,
  });
  const [menuOptions, setMenuOptions] = useState([]);
  const [selectedAvailableMenu, setSelectedAvailableMenu] = useState(null);
  const [selectedMenuOptions, setSelectedMenuOptions] = useState([]);

  const [showAccessLvl, setShowAccessLvl] = useState(false);
  const [showCopyAccess, setShowCopyAccess] = useState(false);

  const { dropdownValues: availabilityPeriodDD } = useDropdownValues({
    url: endpoints.productInventory.getPricingAvailabilityPeriodDropdown,
  });

  const { dropdownValues: priceListDD } = useDropdownValues({
    url: endpoints.priceListTool.getPriceListForCopyAccess,
  });

  const { records: pricingGroups, loadList } = useLoadData();

  const { records: availableColumns, loadList: loadColumns } = useLoadData();

  const { postData } = useCRUD();

  useEffect(() => {
    setMenuOptions(uniq(availableColumns));
    // setSelectedMenuOptions([]);
  }, [availableColumns, priceList?.papId]);

  useEffect(() => {
    if (plData?.id > 0) {
      setPriceList(plData);
      if (plData.priceListColumns?.length > 0) {
        setSelectedMenuOptions([
          ...plData.priceListColumns.map((p, j) => {
            return {
              sort: j + 1,
              name: p,
            };
          }),
        ]);
      }
      setPriceListTitle(priceList?.priceListNameVisible || "New Price List");
    }
  }, [plData?.id]);

  useEffect(() => {
    onplDataChanged && onplDataChanged(priceList);
  }, [
    onplDataChanged,
    priceList?.shortDescription,
    priceList?.priceListNameVisible,
    priceList,
  ]);

  useEffect(() => {
    if (availableColumns.length > 0 && selectedMenuOptions.length > 0)
      setMenuOptions(
        uniq(
          availableColumns.filter(
            a => !selectedMenuOptions.map(s => s.name).includes(a)
          )
        )
      );
  }, [availableColumns, selectedMenuOptions]);

  useEffect(() => {
    loadList({
      url: endpoints.priceListTool.getPricingGroupsByAvailabilityPeriod,
      payload: {
        id: `${priceList?.papId}`,
      },
    });
  }, [loadList, priceList?.papId]);

  useEffect(() => {
    loadColumns({
      url: endpoints.priceListTool.getProductPricingColumns,
      payload: {
        papId: priceList?.papId,
        groupIds: [0],
      },
    });
  }, []);

  const copyAccessSettings = async payload => {
    setLoading(true);
    postData({
      url: endpoints.priceListTool.copyPriceListAccess,
      payload: payload,
    }).then(data => {
      if (data && data.success) {
        setPriceList({
          ...priceList,
          groupAccessLevel: data?.data?.map(r => {
            return {
              ...r,
              id: 0,
            };
          }),
        });
        setShowCopyAccess(false);
      } else if (!data.success) {
        toast.warning(data.message);
      }
      setLoading(false);
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    const newPriceList = {
      ...priceList,
      id: priceList?.id || 0,
      priceListColumns: selectedMenuOptions?.map(m => m?.name),
    };
    dispatch(
      postData({
        url: endpoints.priceListTool.addupdatePriceList,
        payload: newPriceList,
      }).then(data => {
        if (data && data.success) {
          history.goBack();
        } else if (!data.success) {
          toast.error(data.message);
        }
        setLoading(false);
      })
    );
  };

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
        <div className='contentHeading'>
          <div className='dashboardNav price-list-save'>
            <Button
              color='primary'
              variant='contained'
              className='dashboardButtons'
              type='submit'>
              Save
            </Button>
          </div>
        </div>
        <div className='newPriceListForm'>
          <AccordionContainer title='Price List Basics'>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={4} className={classes.gridWrapper}>
                    <InputField
                      autoFocus={true}
                      inputlabel='Price List Name (Visible)'
                      placeholder='Price List Name'
                      id='priceListName'
                      name='priceListName'
                      validators={["required", "matchRegexp:^.{0,50}$"]}
                      errorMessages={[
                        "Please enter price list name",
                        "Maximum length is 50 characters",
                      ]}
                      value={priceList?.priceListNameVisible}
                      onChange={({ target }) =>
                        setPriceList({
                          ...priceList,
                          priceListNameVisible: target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={4} className={classes.gridWrapper}>
                    <InputField
                      inputlabel='Price List Name (Internal)'
                      placeholder='Price List Name'
                      id='internalName'
                      name='internalName'
                      validators={["required", "matchRegexp:^.{0,50}$"]}
                      errorMessages={[
                        "Please enter price list internal name",
                        "Maximum length is 50 characters",
                      ]}
                      value={priceList?.priceListNameInternal}
                      onChange={({ target }) =>
                        setPriceList({
                          ...priceList,
                          priceListNameInternal: target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={4} className={classes.gridWrapper}>
                    <SelectInput
                      selectlabel='Availability Period'
                      placeholder='Please Select'
                      customMenuItems={availabilityPeriodDD?.map(b => {
                        return {
                          id: b.key,
                          name: b.value,
                        };
                      })}
                      required={true}
                      value={priceList?.papId || 0}
                      handleChange={({ target }) =>
                        setPriceList({
                          ...priceList,
                          papId: target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={priceList.isConfidential}
                          checked={priceList.isConfidential}
                          onChange={({ target }) => {
                            setPriceList({
                              ...priceList,
                              isConfidential: target.checked,
                            });
                          }}
                        />
                      }
                      label='Mark as Confidential'
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.gridWrapper}>
                    <EmptyTextarea
                      maxLength={500}
                      textLabel='Short Description Before Price List'
                      textSubtitle='(500 characters max)'
                      value={priceList?.shortDescription}
                      onChange={({ target }) =>
                        setPriceList({
                          ...priceList,
                          shortDescription: target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </AccordionContainer>
        </div>

        <div className='newPriceListForm'>
          <AccordionContainer
            title='Price List Access'
            formButton={
              <span className='dashboardNav'>
                <Button
                  color='primary'
                  variant='outlined'
                  className='dashboardButtons'
                  onClick={() => setShowCopyAccess(true)}>
                  Copy Access
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  className='dashboardButtons'
                  onClick={() => setShowAccessLvl(true)}>
                  Add Access
                </Button>
              </span>
            }>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} className={classes.gridWrapper}>
                    <p>
                      Control which business groups or companies have access to
                      view and download this price list. Price List Access is
                      different than Product Pricing & Availability. Learn More
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.gridWrapper}>
                  {priceList?.groupAccessLevel?.map(pg => {
                    const grp = pricingGroups?.find(
                      g => `${g.id}` === `${pg.groupId}`
                    );
                    if (grp) {
                      return (
                        <GroupNameChip
                          group={{
                            value: grp?.value,
                            ...pg,
                          }}
                          onRemove={so => {
                            setPriceList({
                              ...priceList,
                              groupAccessLevel:
                                priceList?.groupAccessLevel?.filter(
                                  plg => plg.groupId !== so.groupId
                                ),
                            });
                          }}
                        />
                      );
                    }
                    return null;
                  })}
                </Grid>
              </Grid>
            </div>
            <PriceListAccessPopup
              title='Add to Price List Access'
              isVisible={showAccessLvl}
              // labelPropName={"groupName"}
              listData={pricingGroups.filter(p => p.id)}
              selectedOptions={priceList?.groupAccessLevel || []}
              accessLvlSettings={
                priceList?.groupAccessLevel?.length > 0
                  ? first(priceList?.groupAccessLevel)
                  : {}
              }
              onSelect={sOpts => {
                setPriceList({
                  ...priceList,
                  groupAccessLevel: sOpts,
                });
                setShowAccessLvl(false);
              }}
              onClose={() => setShowAccessLvl(false)}
            />
            <CopyPriceListAccessPopup
              title='Copy Price List Access'
              isVisible={showCopyAccess}
              listData={uniqBy(priceListDD, "id")}
              labelPropName='priceListNameInternal'
              onClose={() => setShowCopyAccess(false)}
              onSelect={async plIds => {
                await copyAccessSettings({
                  papId: priceList?.papId,
                  priceListIds: plIds,
                });
              }}
            />
          </AccordionContainer>
        </div>

        <div className='newPriceListForm'>
          <AccordionContainer title='Price List Design'>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} className={classes.gridWrapper}>
                    <p>
                      By default, OFish will display the Product Code/SKU,
                      Product Name, Product Image/Description, and MAP/MSRP
                      Prices with products that are available to the business
                      groups or contacts you provided Price List Access. To
                      customize the Price List further, use the area below for
                      showing/hiding columns. Learn More
                    </p>
                  </Grid>

                  <Grid item xs={12} className={classes.gridWrapper}>
                    <div className={classes.root}>
                      <Grid container spacing={3}>
                        <Grid item xs={5} className={classes.gridWrapper}>
                          <label className='locatorFilterTitle'>
                            Available Columns
                          </label>
                          <Grid
                            item
                            xs={12}
                            className='locatorFilterContentGrid'>
                            {menuOptions.length > 0 &&
                              menuOptions.map(item => {
                                return (
                                  <div
                                    className={`locatornewsroomOption ${
                                      item === selectedAvailableMenu
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setSelectedAvailableMenu(item);
                                    }}>
                                    <div className='locatorFilterOptionText'>
                                      <h6>{item}</h6>
                                    </div>
                                  </div>
                                );
                              })}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.gridWrapper}
                          style={{ padding: "110px 0px" }}>
                          <Grid
                            item
                            xs={12}
                            className='newsroomFilterVectorGrid'>
                            <img
                              alt='locator vector left'
                              className='locatorVectorImg'
                              style={{ cursor: "pointer" }}
                              src={locatorVectorLeft}
                              onClick={() => {
                                const smOption = selectedMenuOptions.find(
                                  m => m?.name === selectedAvailableMenu
                                );
                                if (smOption) {
                                  setSelectedMenuOptions(
                                    selectedMenuOptions.filter(
                                      m => m?.name !== selectedAvailableMenu
                                    )
                                  );
                                  setMenuOptions(
                                    concat(menuOptions, smOption?.name)
                                  );
                                  setSelectedAvailableMenu(null);
                                }
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className='newsroomFilterVectorGrid'>
                            <img
                              alt='locator vector'
                              className='locatorVectorImg'
                              style={{
                                cursor: "pointer",
                              }}
                              src={locatorVector}
                              onClick={() => {
                                const smOption = menuOptions.find(
                                  m => m === selectedAvailableMenu
                                );
                                if (smOption) {
                                  setSelectedMenuOptions([
                                    ...orderBy(
                                      concat(selectedMenuOptions, {
                                        sort:
                                          selectedMenuOptions.length === 0
                                            ? 1
                                            : max(
                                                selectedMenuOptions.map(
                                                  x => x.sort
                                                )
                                              ) + 1,
                                        name: smOption,
                                      }),
                                      ["sort"],
                                      ["asc"]
                                    ),
                                  ]);
                                  setMenuOptions(
                                    menuOptions.filter(
                                      m => m !== selectedAvailableMenu
                                    )
                                  );
                                  setSelectedAvailableMenu(null);
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={5} className={classes.gridWrapper}>
                          <label className='locatorFilterTitle'>
                            Columns in Use
                          </label>
                          <Grid
                            item
                            xs={12}
                            className='locatorFilterContentGrid'>
                            {selectedMenuOptions.length > 0 &&
                              selectedMenuOptions.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={`locatornewsroomOption ${
                                      item?.name === selectedAvailableMenu
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setSelectedAvailableMenu(item?.name);
                                    }}>
                                    <div className='locatorFilterOptionText'>
                                      <h6>{item?.name}</h6>
                                    </div>
                                    <div
                                      style={{
                                        paddingRight: "20px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}>
                                      <span
                                        className='MuiButton-label'
                                        onClick={() => {
                                          if (i - 1 >= 0) {
                                            const sMOpt = {
                                              ...selectedMenuOptions[i],
                                            };
                                            selectedMenuOptions[i] = {
                                              ...selectedMenuOptions[i],
                                              sort: selectedMenuOptions[i - 1]
                                                ?.sort,
                                            };
                                            selectedMenuOptions[i - 1] = {
                                              ...selectedMenuOptions[i - 1],
                                              sort: sMOpt?.sort,
                                            };
                                          }
                                          const newList = orderBy(
                                            selectedMenuOptions,
                                            ["sort"],
                                            ["asc"]
                                          );
                                          setSelectedMenuOptions([...newList]);
                                        }}>
                                        <img
                                          alt='locator path'
                                          className='LocatorPathIcon'
                                          src={LocatorPath96Up}
                                        />
                                      </span>
                                      <span
                                        className='MuiButton-label'
                                        onClick={() => {
                                          if (
                                            i + 1 <=
                                            selectedMenuOptions.length
                                          ) {
                                            const sMOpt = {
                                              ...selectedMenuOptions[i],
                                            };
                                            selectedMenuOptions[i] = {
                                              ...selectedMenuOptions[i],
                                              sort: selectedMenuOptions[i + 1]
                                                ?.sort,
                                            };
                                            selectedMenuOptions[i + 1] = {
                                              ...selectedMenuOptions[i + 1],
                                              sort: sMOpt?.sort,
                                            };
                                          }
                                          const newList = orderBy(
                                            selectedMenuOptions,
                                            ["sort"],
                                            ["asc"]
                                          );
                                          setSelectedMenuOptions([...newList]);
                                        }}>
                                        <img
                                          alt='locator path'
                                          className='LocatorPathIcon'
                                          src={LocatorPath96Down}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </AccordionContainer>
        </div>
      </ValidatorForm>
    </div>
  );
};

export { NewPriceOverview };
