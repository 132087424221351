import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { debounce, first } from "lodash";
import { toast } from "react-toastify";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Add } from "@material-ui/icons";
import { Button, FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import EmptyTextarea from "../../../../components/common/Textarea";
import InputField from "../../../../components/common/InputField";
import Loader from "../../../../components/common/Loader";
import AutoComplete from "../../../../components/common/AutoComplete";
import PreviewPopup from "./PreviewPopup";
import ReleaseContent from "./ReleaseContent";
import { AssetCollection } from "./AssetCollection";
import { ImageUploader, LaunchState } from "../../../../components/common";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { ReleaseVisabilityGroup } from "./ReleaseVisabilityGroup";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import {
  MultiSelectReleaseCategories,
  ReleaseCategoryChip,
} from "./MultiSelectReleaseCategories";
import CustomCheckbox from "../../../../components/common/checkbox/CustomCheckbox";
import moment from "moment";
import { isValid } from "date-fns";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
    padding: "12px",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "5",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  setLaunched: {
    backgroundColor: "#00ABA3",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    border: "1px solid #70808F",
    borderRadius: "6px",
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
}));

const NewMediaReleaseForm = ({ activeReleaseId , existinglaunch }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [releaseData, setRelease] = useState(null);
  const [isDraft, setIsDraft] = useState(false);
  const [releaseStatus, setReleaseStatus] = useState(null);
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [showBannerImage, setShowBannerImage] = useState(false);
  const [releaseTitle, setReleaseTitle] = useState("");
  const [heading, setHeading] = useState("New Media Releases");
  const [releaseSubTitle, setReleaseSubTitle] = useState("");
  const [url, setReleaseURL] = useState("");
  const [shortDesc, setReleaseShortDesc] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [releaseLocation, selectReleaseLocation] = useState(null);
  const [relatedEvent, setRelatedEvent] = useState(0);
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [relatedLaunch, selectRelatedLaunch] = useState(null);
  const [newsRoomVisibilityId, selectReleaseNewsRoomVisibility] = useState("");
  const [releaseVisibilityGroups, setReleaseVisibilityGroups] = useState([]);
  const [preReleaseVisibilityGroups, setPreReleaseVisibilityGroups] = useState(
    []
  );
  const [isAddReleaseCategory, setIsAddReleaseCategory] = useState(false);
  const [slectedReleaseCategories, setSlectedReleaseCategories] = useState([]);
  const [mainReleaseCategory, setMainReleaseCategory] = useState(null);
  const [announceDate, handleAnnounceDate] = useState(new Date());
  const [sameDate, setSameAsLaunch] = useState(false);
  const [releaseDate, handleReleaseDate] = useState(new Date());
  // const [releaseVisibility, setReleaseVisibility] = React.useState([]);
  const [releaseContent, setReleaseContent] = useState("");
  const [editRelease, setEditRelease] = useState({});
  const [isEditRelease, setIsEditRelease] = useState(false);
  const [featureImgUpload, setFeatureImgUpload] = useState(null);
  const [bannerImgUpload, setBannerImgUpload] = useState(null);
  const [isPrereleaseValidDate, setIsPrereleaseValidDate] = useState(
    new Date()
  );
  const [avatarImg, setAvatarImG] = useState(null);
  // const [featureFiles, setFeatureFiles] = useState([]);
  const [releaseAssetCollection, setReleaseAssetCollection] = useState([]);
  const [eventLocation, setEventLocation] = useState([]);
  const [assetCollection, setAssetCollections] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [audianceList, setAudianceList] = useState([]);
  const [checkedAnnounceDated, setCheckedAnnounceDated] = useState(null);
  const loadData = useCallback(
    editRel => {
      setLoading(true);
      dispatch(launchesActions.onGetDropDownLaunches()).then(data => {
        if (data && data.success && data.data && data.data.length > 0) {
          if (!editRel) {
            selectRelatedLaunch(data.data[0].id);
          }
        } else {
          setLoading(false);
        }
        dispatch(launchesActions.onGetAllUserGroup()).then(data => {
          if (data && data.data) {
            setAudianceList(data?.data);
          } else {
            setLoading(false);
          }
          dispatch(launchesActions.onGetReleaseCategories()).then(res => {
            if (res && res.success && res.data && res.data.length > 0) {
              if (!editRel) {
                // selectReleaseCategory(res.data[0].id);
              }
            } else {
              setLoading(false);
            }
            dispatch(launchesActions.onGetReleaseNewsRoomVisibilities()).then(
              res => {
                if (res && res.success && res.data && res.data.length > 0) {
                  if (!editRel) {
                    selectReleaseNewsRoomVisibility(res.data[0].id);
                  }
                  setLoading(false);
                } else {
                  setLoading(false);
                }
              }
            );
          });
        });
      });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(launchesActions.onGetEventDropDown()).then(res => {
      if (res && res.success && res.data && res.data.length > 0) {
        setRelatedEvent(first(res.data)?.id);
        setRelatedEvents(res.data);
      }
    });
  }, [dispatch]);

  const loadCollectionsData = useCallback(() => {
    setLoading(true);
    dispatch(assetsActions.onGetCollectionsPopup()).then(data => {
      setAssetCollections(data?.data);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    loadCollectionsData();
  }, [loadCollectionsData]);

  useEffect(() => {
    dispatch(launchesActions.onChange("selectedCollectionAsset", []));
    dispatch(launchesActions.onGetReleaseVisibility());
  }, [dispatch]);

  const handleSubmit = e => {
    e.preventDefault();
    // setIsDropdown(!isDropdown);
    if (!isDraft) createReleases(1);
    else createReleases(2);
  };

  const {
    releaseCategories,
    // releaseLocations,
    // releaseRelatedEvents,
    launches,
    // selectedCollectionAsset
    // releaseVisibilities
  } = useSelector(state => state.LaunchesReducer);

  const createReleases = currentState => {
    if (shortDesc.length < 1000 && releaseLocation !== null) {
      if (!featureImgUpload) {
        toast.error("Thumbnil image is required");
        return;
      }
      if (slectedReleaseCategories?.length === 0 || !mainReleaseCategory) {
        toast.error("Main release category is required");
        return;
      }

      const categoryIds = slectedReleaseCategories?.map(sRCid => {
        return {
          categoryId: parseInt(sRCid),
          isMain: sRCid === mainReleaseCategory,
        };
      });
      setLoading(true);
      const releaseData = {
        title: releaseTitle,
        subTitle: releaseSubTitle,
        shortDescription: shortDesc,
        AnnouncementDate: moment(announceDate).format("YYYY-MM-DD HH:mm:ss"),
        useLaunchDate: sameDate,
        url: url,
        PreReleaseDate: moment(releaseDate).format("YYYY-MM-DD HH:mm:ss"),
        content: releaseContent,
        locationId: releaseLocation?.id,
        eventId: relatedEvent,
        image: featureImgUpload ? featureImgUpload?.url : "",
        bannerImage: bannerImgUpload ? bannerImgUpload?.url : "",
        // categoryId: releaseCategory,
        categoryIds: categoryIds,
        launchId: relatedLaunch,
        state: currentState,
        collections: [...releaseAssetCollection?.map(c => c?.collectionId)],
        // releaseVisibilities: visibilityArray,
        preReleaseDate: moment(isPrereleaseValidDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        releaseVisibility: {
          NewsRoomVisibilityId: newsRoomVisibilityId,
          isDeleted: false,
          groupList: releaseVisibilityGroups,
          preReleaseVisibilityList: preReleaseVisibilityGroups.map(x => {
            return {
              groupIds: x.groupList.map(x => x.VisibilityGroupId),
              preReleaseDate: moment(x.preReleaseDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            };
          }),
        },
      };
      releaseData.id = activeReleaseId ? parseInt(activeReleaseId) : 0;
      dispatch(launchesActions.onCreateReleases(releaseData)).then(data => {
        if (data && data.success) {
          if (window.location.pathname === "/launch") {
            if (releaseData.id === 0) {
              toast.success("Release Added");
              history.goBack();
            } else {
              toast.success("Release Updated");
              history.goBack();
            }
          } else {
            history.goBack();
          }
        } else if (data?.message) {
          toast.warning(data?.message);
        }
        setLoading(false);
      });
    } else {
      setErrorMsg(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    if (activeReleaseId && activeReleaseId > 0) {
      dispatch(launchesActions.onGetReleasById(activeReleaseId)).then(data => {
        setLoading(false);
        // const { data: release } = data;
        if (data?.data) {
          setRelease(data?.data);
          setIsDraft(data?.data?.state === 2 ? true : false);
          setReleaseStatus(data?.data?.state);
          loadData(true);
        }
      });
    } else {
      if (!activeReleaseId) {
        setReleaseTitle("");
        setRelease(null);
        setIsDraft(false);
        setReleaseStatus(null);
        setShowFeatureImage(false);
        setReleaseTitle("");
        setReleaseSubTitle("");
        setReleaseURL("");
        setReleaseShortDesc("");
        handleAnnounceDate(new Date());
        handleReleaseDate(new Date());
        setReleaseContent("");
        setSlectedReleaseCategories([]);
        selectReleaseLocation(null);
        setFeatureImgUpload(null);
        setBannerImgUpload(null);
        selectRelatedLaunch(null);
        selectReleaseNewsRoomVisibility("");
        setReleaseVisibilityGroups([]);
        setPreReleaseVisibilityGroups([]);
        setReleaseAssetCollection([]);
        setAssetCollections([]);
      }
      loadData(false);
    }
    return () => {
      setRelease(null);
    };
  }, [activeReleaseId, dispatch, loadData]);

  useEffect(() => {
    if (sameDate) {
      const relatedLlaunchDate = launches?.find(
        l => l.id === relatedLaunch
      )?.generic;
      if (relatedLlaunchDate) handleAnnounceDate(relatedLlaunchDate);
    } else {
      // handleAnnounceDate(addDays(new Date(), 15));
      // handleAnnounceDate(new Date());
    }
  }, [launches, relatedLaunch, sameDate]);

  useEffect(() => {
    if (!releaseData) return;
    setEditRelease(releaseData);
    setIsEditRelease(true);
    setReleaseContent(releaseData.content);
    handleAnnounceDate(releaseData.announcementDate);
    setSameAsLaunch(releaseData.useLaunchDate);
    handleReleaseDate(releaseData.preReleaseDate);
    setIsDraft(releaseData.state === 2 ? true : false);
    setReleaseStatus(releaseData.state);
    setReleaseTitle(releaseData.title);
    let heading = releaseData?.title
      ?.slice(0, 30)
      .concat(releaseData?.title.length > 30 ? "..." : "");
    setHeading(heading);
    setReleaseSubTitle(releaseData.subTitle);
    setReleaseShortDesc(releaseData.shortDescription);
    setSlectedReleaseCategories(
      releaseData?.categoryIds?.map(r => r?.categoryId)
    );
    setMainReleaseCategory(
      releaseData?.categoryIds?.find(r => r?.isMain === true)?.categoryId
    );
    selectReleaseLocation({
      id: releaseData?.locationId,
      value: releaseData?.locationData?.value,
    });
    setReleaseURL(releaseData.url || "");
    selectRelatedLaunch(releaseData.launchId ? releaseData.launchId : "");

    selectReleaseNewsRoomVisibility(
      releaseData.releaseVisibilityId ? releaseData.releaseVisibilityId : ""
    );
    setFeatureImgUpload(releaseData?.image || "");
    setBannerImgUpload(releaseData?.bannerImage || "");
    setAvatarImG(releaseData.asset ? releaseData.asset : "");
    // setCollection(
    //   releaseData.collections && releaseData.collections.length > 0
    //     ? releaseData.collections
    //     : null
    // );
    setRelatedEvent(releaseData.eventId || 0);
    if (releaseData?.collections?.length >= 0) {
      setReleaseAssetCollection([
        ...releaseData?.collections.map(c => {
          return {
            collectionId: c,
            name: assetCollection?.find(z => z.id === c)?.title,
          };
        }),
      ]);
    }
    if (audianceList.length) {
      if (releaseData?.releaseVisibility) {
        const { releaseVisibility } = releaseData;
        if (releaseVisibility) {
          selectReleaseNewsRoomVisibility(
            releaseVisibility?.newsRoomVisibilityId
          );
          setReleaseVisibilityGroups(releaseVisibility.groupList || []);
          if (releaseVisibility?.preReleaseVisibilityList) {
            setPreReleaseVisibilityGroups([
              ...releaseVisibility?.preReleaseVisibilityList.map(r => {
                return {
                  ...r,
                  groupList: [
                    ...r.groupIds?.map(g => {
                      const group = audianceList.find(
                        z => z.id.toString() === g.toString()
                      );
                      return {
                        VisibilityGroupId: g,
                        name: group?.value ?? "",
                      };
                    }),
                  ],
                };
              }),
            ]);
          }
        }
      } else {
        setLoading(true);
        dispatch(launchesActions.onGetReleaseVisibility()).then(visi => {
          setLoading(false);
        });
      }
    }
  }, [releaseData, dispatch, audianceList, assetCollection]);

  const changeHandler = event => {
    setLoading(true);
    dispatch(
      launchesActions.onGetLocationsAutoComplete({
        input: event.target.value,
      })
    ).then(res => {
      setEventLocation(res?.data || []);
      setLoading(false);
    });
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  useEffect(() => {
    if (sameDate) {
      setCheckedAnnounceDated(new Date(existinglaunch?.launchDate));
    } else {
      setCheckedAnnounceDated(new Date(announceDate));
    }
  }, [sameDate, announceDate]);

  const hasValidPreRelease = preReleaseVisibilityGroups?.some(group => {
    const preReleaseDate = group?.preReleaseDate;
    if (!preReleaseDate) {
      return false;
    }
    const preReleaseDateTime = new Date(preReleaseDate).getTime();
    const announceDateTime = new Date(announceDate).getTime();
    const launchDateTime = new Date(existinglaunch?.launchDate).getTime();
    return !sameDate
      ? preReleaseDateTime > announceDateTime
      : preReleaseDateTime > launchDateTime;
  });

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
        <AccordionContainer
          title='Release Overview'
          formButton={
            <div className='dashboardNav'>
              <Button
                color='primary'
                variant='outlined'
                className='dashboardButtons'
                onClick={() => setShowPopup(true)}>
                Preview
              </Button>
              <LaunchState
                value={releaseStatus === 1 ? 0 : releaseStatus === 2 ? 1 : 2}
                onChange={status => {
                  setReleaseStatus(status === 1 ? 2 : 1);
                  setIsDraft(Boolean(status));
                }}
              />
              <Button
                color='primary'
                variant='contained'
                className='dashboardButtons'
                disabled={hasValidPreRelease}
                type='submit'>
                Save Release
              </Button>
            </div>
          }>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.gridWrapper}>
                <InputField
                  showRequired={true}
                  placeholder='Enter A News Release Title'
                  inputlabel='New Release Title'
                  // subtitle="Enter the title of your news release. Make it catchy!"
                  id='title'
                  name='title'
                  validators={["required"]}
                  errorMessages={["Please enter release title"]}
                  value={releaseTitle}
                  onChange={v => setReleaseTitle(v.target.value)}
                />
              </Grid>

              <Grid item xs={12} className={classes.gridWrapper}>
                <InputField
                  showRequired={true}
                  placeholder='Enter A News Release Subtitle'
                  inputlabel='New Release Subtitle'
                  // subtitle="Enter the title of your news release. Make it catchy!"
                  id='subTitle'
                  name='subTitle'
                  validators={["required"]}
                  errorMessages={["Please enter release sub title"]}
                  value={releaseSubTitle}
                  onChange={v => setReleaseSubTitle(v.target.value)}
                />
              </Grid>

              <Grid item xs={12} className={classes.gridWrapper}>
                <label className='slugLabel'>
                  Release URL <i className={"showRequired"}></i>
                </label>
                <Grid container spacing={2} xs={12} className='slugWrapper'>
                  <span style={{ marginLeft: "10px" }}>
                    newsroom.aquaticav.com/releases/category/
                  </span>
                  <span
                    className='eventCatUrlSlug'
                    style={{ flex: "1", marginLeft: "10px" }}>
                    <InputField
                      inputlabel='Release URL'
                      placeholder='Enter a URL Slug'
                      validators={["required", "matchRegexp:^.{0,200}$"]}
                      errorMessages={[
                        "Please enter release url",
                        "Maximum length is 200 characters",
                      ]}
                      value={url}
                      onChange={v =>
                        setReleaseURL(v.target?.value?.replace(/\s+/g, ""))
                      }
                    />
                  </span>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.gridWrapper}>
                <EmptyTextarea
                  textLabel='Short Description'
                  // textSubtitle="Enter a short description of your news release. This will be used in various areas across the newsroom to preview your release. (500 char. max)"
                  required={true}
                  value={shortDesc}
                  onChange={v => setReleaseShortDesc(v.target.value)}
                />
                {shortDesc.length > 1000 && (
                  <span className='errorMessage'>
                    Maximum length is 1000 characters
                  </span>
                )}
              </Grid>

              <Grid item xs={4} className={classes.gridWrapper}>
                <AutoComplete
                  showRequired={true}
                  label='New Release Location'
                  value={releaseLocation?.value}
                  onChange={e => {
                    debouncedChangeHandler(e);
                    selectReleaseLocation(e.target.value);
                  }}
                  eventLocation={eventLocation}
                  setEvent={v => {
                    selectReleaseLocation(v);
                  }}
                />
                {errorMsg && releaseLocation === null && (
                  <span className='errorMessage'>
                    Please enter new release location
                  </span>
                )}
              </Grid>

              <Grid item xs={4} className={classes.gridWrapper}>
                <div className='inputWrapper'>
                  <FormControl className={classes.formControl}>
                    <label>{"Related Launch"}</label>
                    <Select
                      value={relatedLaunch}
                      onChange={e => selectRelatedLaunch(e.target.value)}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"outlined"}
                      // required
                    >
                      {launches?.length > 0 &&
                        launches.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>
                              {item.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={4} className={classes.gridWrapper}>
                <div className='inputWrapper'>
                  <FormControl className={classes.formControl}>
                    <label>{"Related Events"}</label>
                    <Select
                      value={relatedEvent}
                      onChange={({ target }) => {
                        setRelatedEvent(target.value);
                      }}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"outlined"}
                      required>
                      {relatedEvents?.length > 0 &&
                        relatedEvents.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>
                              {item.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {/* <FormControl className={classes.formControl}>
                      <label>{"New Release Category"}</label>
                      <Select
                        value={releaseCategory}
                        onChange={({ target }) => {
                          selectReleaseCategory(target.value);
                          const groupIds = releaseCategories.find(
                            r => r.id === target.value
                          )?.generic;

                          if (groupIds?.length > 0) {
                            const l = audianceList
                              .filter(a => groupIds?.includes(parseInt(a.id)))
                              .map(g => g.id);
                            setReleaseVisibilityGroups(l || []);
                          } else setReleaseVisibilityGroups([]);
                        }}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        variant={"outlined"}>
                        {releaseCategories?.length > 0 &&
                          releaseCategories.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.id}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl> */}
                </div>
              </Grid>

              <Grid item xs={6}>
                <span>
                  <label className='slugLabel' style={{ marginBottom: "7px" }}>
                    News Release Categories <i className={"showRequired"}></i>
                  </label>
                  <Button
                    color='primary'
                    variant='outlined'
                    startIcon={<Add />}
                    onClick={() => setIsAddReleaseCategory(true)}>
                    Add Category
                  </Button>
                </span>
                <span>
                  {slectedReleaseCategories?.map(s => (
                    <ReleaseCategoryChip
                      mainReleaseCategory={mainReleaseCategory}
                      setMainReleaseCategory={setMainReleaseCategory}
                      category={{
                        id: s,
                        value: releaseCategories.find(r => `${r.id}` === `${s}`)
                          ?.value,
                      }}
                      onRemove={rcId => {
                        setSlectedReleaseCategories([
                          ...slectedReleaseCategories.filter(
                            scId => scId !== rcId
                          ),
                        ]);
                        if (mainReleaseCategory === rcId) {
                          setMainReleaseCategory(null);
                        }
                      }}
                    />
                  ))}
                </span>
                <MultiSelectReleaseCategories
                  releaseCategories={releaseCategories}
                  isVisible={isAddReleaseCategory}
                  slectedReleaseCategories={slectedReleaseCategories}
                  onSelect={slectedCategories => {
                    setSlectedReleaseCategories(slectedCategories);
                    setIsAddReleaseCategory(false);
                  }}
                  onClose={() => setIsAddReleaseCategory(false)}
                />
              </Grid>

              <Grid
                container
                direction='row'
                xs={6}
                className={classes.gridWrapper}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}>
                <Grid item xs={6}>
                  <label className='slugLabel'>
                    News Release Announcement Date{" "}
                    <i className={"showRequired"}></i>
                  </label>
                  <div className='inputWrapper dateField'>
                    <FormControl className={classes.formControl}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          disableToolbar
                          variant='inline'
                          value={sameDate ? existinglaunch?.launchDate : announceDate}
                          disabled={sameDate}
                          onChange={handleAnnounceDate}
                          autoOk
                          format='yyyy/MM/dd hh:mm a'
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={4} className={classes.gridWrapper}>
                  <span
                    className='dateCheckbox'
                    style={{
                      marginTop: "10px",
                    }}>
                    <CustomCheckbox
                      className={classes.checkBoxFont}
                      checkedId={"checked"}
                      pressCheck={() => {
                        setSameAsLaunch(!sameDate);
                      }}
                      checkValue={sameDate}
                    />
                    Use same date as Launch Date
                  </span>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <Grid item xs={4} style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <label
                        className='slugLabel'
                        style={{ marginBottom: "10px" }}>
                        Thumbnail Image <i className={"showRequired"}></i>
                      </label>
                      <span>
                        <Button
                          variant='outlined'
                          color='primary'
                          className={classes.selectBtn}
                          onClick={() =>
                            setShowFeatureImage(!showFeatureImage)
                          }>
                          Select
                        </Button>
                      </span>
                    </div>

                    <span
                      className='releaseViewAvatarWrapper'
                      style={{ marginLeft: "10px" }}>
                      {(featureImgUpload?.url ||
                        featureImgUpload ||
                        avatarImg) && (
                        <img
                          src={
                            featureImgUpload?.url ||
                            featureImgUpload ||
                            avatarImg
                          }
                          alt=''
                        />
                      )}
                    </span>
                  </div>

                  <ImageUploader
                    title={"Featured Image"}
                    showFeatureImage={showFeatureImage}
                    setShowFeatureImage={() =>
                      setShowFeatureImage(!showFeatureImage)
                    }
                    onImageSelect={img => setFeatureImgUpload(img)}
                    infoText='Images are supported only. (JPEG, JPG and PNG)'
                    showInfoTxt={false}
                  />
                </Grid>
                <Grid item xs={4} style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <label
                        className='slugLabel'
                        style={{ marginBottom: "10px" }}>
                        Banner Image
                      </label>
                      <span>
                        <Button
                          variant='outlined'
                          color='primary'
                          className={classes.selectBtn}
                          onClick={() => setShowBannerImage(!showBannerImage)}>
                          Select
                        </Button>
                      </span>
                    </div>

                    <span
                      className='releaseViewAvatarWrapper'
                      style={{ marginLeft: "10px" }}>
                      {(bannerImgUpload?.url ||
                        bannerImgUpload ||
                        avatarImg) && (
                        <img
                          src={
                            bannerImgUpload?.url || bannerImgUpload || avatarImg
                          }
                          alt=''
                        />
                      )}
                    </span>
                  </div>

                  <ImageUploader
                    title={"Banner Image"}
                    showFeatureImage={showBannerImage}
                    setShowFeatureImage={() => {
                      setShowBannerImage(!showBannerImage);
                    }}
                    onImageSelect={img => {
                      setBannerImgUpload(img);
                    }}
                    infoText='Images are supported only. (JPEG, JPG and PNG)'
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </AccordionContainer>
        <div className='contentBody accordianBody'>
          <ReleaseVisabilityGroup
            audianceList={audianceList}
            newsRoomVisibilityId={newsRoomVisibilityId}
            releaseVisibilityGroups={releaseVisibilityGroups}
            onNewsRoomVisibilityIdSelect={NewsRoomVisibilityId =>
              selectReleaseNewsRoomVisibility(NewsRoomVisibilityId)
            }
            onGroupSelection={groupList => {
              setReleaseVisibilityGroups([
                ...groupList.map(g => parseInt(g.VisibilityGroupId)),
              ]);
            }}
            preReleaseVisibilityGroups={preReleaseVisibilityGroups}
            setPreReleaseVisibilityGroups={setPreReleaseVisibilityGroups}
            displayNewsRoomDD={true}
            mainTitle={"Release Visibility"}
            headingVisi={"View Release"}
            textVisi={
              "These users will have access to the release on the announcement date"
            }
            setIsPrereleaseValidDate={setIsPrereleaseValidDate}
            startDate={checkedAnnounceDated}
          />
        </div>
        <div className='contentBody accordianBody'>
          <ReleaseContent
            model={releaseContent}
            handelModelChange={model => setReleaseContent(model)}
          />
        </div>
        <div className='contentBody accordianBody'>
          <AssetCollection
            assetCollection={assetCollection}
            releaseAssetCollection={releaseAssetCollection}
            onReleaseAssetCollectionChange={setReleaseAssetCollection}
          />
        </div>
      </ValidatorForm>
      {showPopup && (
        <PreviewPopup
          showPopup={showPopup}
          title={releaseTitle}
          discription={shortDesc}
          releaseDate={announceDate}
          selectedImage={featureImgUpload}
          releaseContent={releaseContent}
          releaseAssets={
            releaseAssetCollection.length >= 1
              ? assetCollection.filter(a => {
                  return releaseAssetCollection
                    .map(r => parseInt(r.collectionId))
                    .includes(parseInt(a.id));
                })
              : []
          }
          closePopup={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export { NewMediaReleaseForm };
