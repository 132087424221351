import React, { useState, useEffect } from "react";
import { Routes } from "./routes";
import { v4 as uuidv4 } from "uuid";
import TimeOutPopup from "./components/common/popUps/TimeOutPopup";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RoutesObj } from "./routes";

function App() {
  // const { isTabDisActive, isTabActive, count } = useSelector(
  //   state => state.pubContactType
  // );

  useEffect(() => {
    const guid = uuidv4();
    localStorage.setItem("guid", guid);
  }, []);

  const [tabHasFocus, setTabHasFocus] = useState(true);
  const [isTabDisActive, setIsTabDisActive] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  useEffect(() => {
    let timeInt;
    const handleFocus = () => {
      setTabHasFocus(true);
      clearInterval(timeInt);
    };

    const handleBlur = () => {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login"
      ) {
        setTabHasFocus(false);
        let total = 0;
        timeInt = setInterval(function () {
          total = total + 60000;
          if (total >= 1800000 && !isTabDisActive) {
            setIsTabDisActive(true);
          }
          if (total === 2700000) {
            setIsTabDisActive(false);
            clearInterval(timeInt);
            localStorage.removeItem("access_token");
            localStorage.removeItem("isAuthenticated");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("isAdmin");
            localStorage.removeItem("name");
            localStorage.removeItem("lName");
            localStorage.removeItem("id");
            setIsLogout("true");
          }
        }, 60000);
      }
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  const handleClose = () => {
    setIsTabDisActive(false);
    clearInterval();
  };

  // const authAdminComponent = () => {
  //   return <Redirect to='/' />;
  // };

  return (
    <div className='App'>
      {isTabDisActive && (
        <TimeOutPopup
          openPopup={() => isTabDisActive}
          closePopup={handleClose}
        />
      )}
      {isLogout && (
        <Router>
          <Switch>
            <Route
              exact
              path={RoutesObj.Login.path}
              render={() => this.authAdminComponent()}
            />
          </Switch>
        </Router>
      )}
      <Routes />
    </div>
  );
}

export default App;
