import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { Dropdown } from "../../../../components/common";
import { FormattedDateInput } from "../../../../components/common/FormattedDateInput";
import RecieptModal from "./RequestProduct/RecieptModal";
import moment from "moment";
import Autocomplete from "react-google-autocomplete";
import AutoComplete from "../../../../components/common/AutoComplete";
import { cl } from "@fullcalendar/core/internal-common";
import EmptyTextarea from "../../../../components/common/Textarea";

export const SpaProductRegistration = ({
  isSpaEdit,
  setIsSpaEdit,
  deviceId,
  debouncedChangeHandler,
  allDelears,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setIsSpaEdit(false);
    dispatch(endUserActions.onResetSpaFields());
    // getSpaDeviceInfo(deviceId);
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [isTopsidStatus, setIsTopsidStatus] = useState(0);
  const [isTopsidWarrantyStatus, setIsTopsidWarrantyStatus] = useState(2);
  const [isControllerWarrantyStatus, setIsControllerWarrantyStatus] =
    useState(2);
  const [isControllerStatus, setIsControllerStatus] = useState(2);
  const [open, setOpen] = useState(false);
  const [isUploadRecieptModal, setIsUploadRecieptModal] = useState(false);

  const getSpaDeviceInfo = deviceId => {
    setLoading(true);
    dispatch(endUserActions.onGetSpaInfo(deviceId)).then(data => {
      setLoading(false);
      if (data?.success) {
        if (data?.data?.spaSettings.length > 0) {
          dispatch(
            endUserActions.onChange(
              "spaDeviceSetting",
              data.data.spaSettings[0]
            )
          );
        } else {
          dispatch(endUserActions.onChange("spaDeviceSetting", ""));
        }
      }
    });
  };

  const {
    spaDeviceSetting,
    spaDeviceDetails,
    spaSettings,
    prchDate,
    spaTopsidePrchDate,
    spaDeviceInstallationDate,
    spaTopsideInstallDate,
    spaPurchaseLocation,
    topSidePurchaseLatitude,
    topSidePurchaseLocation,
    topSidePurchaseLongitude,
    controllerPurchaseLatitude,
    controllerPurchaseLocation,
    controllerPurchaseLongitude,
    spaRegistrationStatus,
    registrationNote,
    spaDevicewarrantyStatus,
    spaTopside,
    spaTopsideWarrantyStatus,
    spaTopsideRegistrationStatus,
    spaTopsideRegistrationNote,
    spaControllerPrchDate,
    spaControllerinstalldate,
    spaControllerRegistration,
    spaControllerNote,
    spaController,
    spaControllerWarrantyStatus,
    spaTopSide,
  } = useSelector(state => state.endUserReducer);

  const recieptModal = item => {
    setOpen(!open);
    if (item === "uploadReciept") {
      setIsUploadRecieptModal(true);
    } else {
      setIsUploadRecieptModal(false);
    }
  };

  const maxPurchaseDate = useMemo(() => {
    const currentDate = new Date();
    const formattedCurrentDate = moment(currentDate).format("YYYY-MM-DD");

    const spaLimitInstallationDate = new Date(spaDeviceInstallationDate);
    const formattedInstallationDate = moment(spaLimitInstallationDate).format(
      "YYYY-MM-DD"
    );
    return spaDeviceInstallationDate
      ? formattedInstallationDate
      : formattedCurrentDate;
  }, [spaDeviceInstallationDate]);

  const insallationDateLimits = useMemo(() => {
    const maxDate = moment(new Date()).format("YYYY-MM-DD");

    const limitPurchaseDate = new Date(prchDate);
    const formattedPurchaseDate =
      moment(limitPurchaseDate).format("YYYY-MM-DD");

    return {
      max: maxDate,
      min: formattedPurchaseDate,
    };
  }, [prchDate]);

  const regisrtationStatusType = [
    {
      id: 0,
      name: "Pending",
      expectedResponse: "Pending",
      expectedResponse2: "Unregistered",
      expectedResponse3:"Registration Pending"
    },
    {
      id: 1,
      name: "Register",
      expectedResponse: "Approved",
      expectedResponse2: "Registered",
      expectedResponse3:"Registration Approved",
    },
    {
      id: 5,
      name: "Rejected",
      expectedResponse: "Expired",
      expectedResponse2: "Denied",
      expectedResponse3:"Registration Denied"
    },
    { 
      id: 3, 
      name: "Receipt Missing", 
      expectedResponse1: "Receipt Missing",
      expectedResponse2: "Receipt Missing" ,
      expectedResponse3: "Receipt Missing" 
    },
    { 
      id: 6, 
      name: "Incomplete", 
      expectedResponse1: "Incomplete",
      expectedResponse2: "Incomplete",
      expectedResponse3: "Mark as Incomplete",
    },
  ];
  
  const registrationStatusClasses = (status) => {
    switch (status) {
      case 0:
      case "Pending":
      case "Unregistered":
      case "Registration Pending":
        return "statusDropDownPending";
      case 1:
      case "Approved":
      case "Register":
      case "Registration Approved":
        return "statusDropDownRegistration";
      case 5:
      case "Expired":
      case "Denied":
      case "Rejected":
      case "Registration Denied":
        return "statusDropDownExpired";
      case 3:
      case "Receipt Missing":
        return "statusDropDownReceiptMissing";
      case 6:
      case "Incomplete":
        return "statusDropDownPending";
      default:
        return "statusDropDownPending";
    }
  };

  const spaRegStatusClass = (status) => {
    switch (status) {
      case 0:
      case "Pending":
      case "Unregistered":
      case "Registration Pending":
        return "statusPending";
      case 1:
      case "Approved":
      case "Register":
      case "Registered":
      case "Registration Approved":
        return "statusRegistration";
      case 5:
      case "Expired":
      case "Denied":
      case "Rejected":
      case "Registration Denied":
        return "statusExpired";
      case 3:
      case "Receipt Missing":
        return "statusReceiptMissing";
      case 6:
      case "Incomplete":
        return "statusPending";
      default:
        return "statusPending";
    }
  };

  const getRegStatusText = (status) => {
    switch (status) {
      case 0:
      case "Pending":
      case "Unregistered":
      case "Registration Pending":
        return "Pending";
      case 1:
      case "Approved":
      case "Register":
      case "Registered":
      case "Registration Approved":
        return "Registered";
      case 5:
      case "Expired":
      case "Denied":
      case "Rejected":
      case "Registration Denied":
        return "Registration Denied";
      case 3:
      case "Receipt Missing":
        return "Receipt Missing";
      case 6:
      case "Incomplete":
        return "Mark as Incomplete";
      default:
        return 'Pending';
    }
  };

  const getRegStatusValue = (status) => {
    if (status === undefined || status === null) {
      return 0;
    } else if (typeof status === "number") {
      return status;
    } else {
      const foundStatus = regisrtationStatusType.find(item =>
        [item.name,item.expectedResponse, item.expectedResponse2, item.expectedResponse3].includes(status)
      );
      return foundStatus ? foundStatus.id : 0;
    }
  };
  
  const regStatusOptions = [
    { id: 0, name: "Pending" },
    { id: 1, name: "Register" },
    { id: 5, name: "Rejected" },
    { id: 3, name: "Receipt Missing" },
    { id: 6, name: "Mark as Incomplete" },
  ]
  const warrantyStatusOption = [
    { id: 0, name: "Active" },
    { id: 1, name: "Expired" },
  ]
const getStatusClassName = (status) => {
  return (
    status === 0 || status === "Active" ? "statusDropDownRegistration" :
    status === 1 || status === "Expired" ? "statusDropDownExpired" :
    null
  );
};

  return (
    <div className='spaCollectionBody deviceDetailMainContainer '>
      <Grid
        container
        item
        // xs={12}
        className='spaCollectionWrapper deviceDetailOverviewInner imageWithDetails'>
        <Grid item md={2} className='spaRegistrationImage'>
          <div className='spaDevicePicture'>
            <img
              className='devicedDtailImage'
              src={spaDeviceDetails?.productImage}
              alt=''
            />
          </div>
        </Grid>
        <Grid item md={9} className='deviceOverViewDetail'>
          <div className='spaHedaingOverViewMain productsHeadings'>
            Spa Registration
          </div>
          <span className='deviceDetailOverView'>
            <ul className='registerListing'>
              <li className='deviceDetailView'>
                <span>Model</span>
                <span>{spaDeviceSetting?.spaModel ?? "--"}</span>
              </li>
              <li className='deviceDetailView'>
                <span>Product SKU</span>
                <span>{spaDeviceDetails.productSKU ?? "--"}</span>
              </li>
              <li className='deviceDetailView'>
                <span>Serial Number</span>
                <span> {spaDeviceDetails?.serialNo ?? "--"}</span>
              </li>
              <li className='deviceDetailView'>
                <span>Purchase Location</span>
                {isSpaEdit ? (
                  <div className='spaLastDeviceOverviewFeild locWrapper'>
                    <AutoComplete
                      value={spaPurchaseLocation}
                      onChange={e => {
                        debouncedChangeHandler(e, spaDeviceDetails?.categoryId);
                        dispatch(
                          endUserActions.onChange(
                            "spaPurchaseLocation",
                            e.target.value
                          )
                        );
                      }}
                      eventLocation={allDelears}
                      setEvent={v => {
                        dispatch(
                          endUserActions.onChange(
                            "spaPurchaseLocation",
                            v.value
                          )
                        );
                        dispatch(
                          endUserActions.onChange("spaPurchaseLocId", v.id)
                        );
                      }}
                    />
                  </div>
                ) : (
                  <span>{spaPurchaseLocation ?? "--"}</span>
                )}
              </li>
              <li className='deviceDetailView'>
                <span>Purchase Date</span>
                {isSpaEdit ? (
                  <div className='spaLastDeviceOverviewFeild'>
                    <FormattedDateInput
                      date={moment(prchDate).format("YYYY-MM-DD")}
                      max={maxPurchaseDate}
                      setDate={target => {
                        dispatch(endUserActions.onChange("prchDate", target));
                      }}
                    />
                  </div>
                ) : (
                  <span>
                    <span>{prchDate !== "" ? prchDate : "--"}</span>
                  </span>
                )}
              </li>
              <li className='deviceDetailView'>
                <span>Installation Date</span>
                {isSpaEdit ? (
                  <div className='spaLastDeviceOverviewFeild'>
                    <FormattedDateInput
                      date={moment(spaDeviceInstallationDate).format(
                        "YYYY-MM-DD"
                      )}
                      setDate={target => {
                        dispatch(
                          endUserActions.onChange(
                            "spaDeviceInstallationDate",
                            target
                          )
                        );
                      }}
                      max={insallationDateLimits.max}
                      min={insallationDateLimits.min}
                    />
                  </div>
                ) : (
                  <span>{spaDeviceInstallationDate ?? "--"}</span>
                )}
              </li>
              <li className='deviceDetailView'>
                <span>Registration Status</span>
                {isSpaEdit ? (
                  <div
                    className={registrationStatusClasses(spaRegistrationStatus)}>
                    <Dropdown
                      selectlabel=''
                      value={getRegStatusValue(spaRegistrationStatus)}
                      handleChange={({ target }) => {
                        dispatch(
                          endUserActions.onChange(
                            "spaRegistrationStatus",
                            target.value
                          )
                        );
                      }}
                      customMenuItems={regStatusOptions}
                    />
                  </div>
                ) : (
                    <div
                      className={spaRegStatusClass(spaRegistrationStatus)}>
                      {
                        getRegStatusText(spaRegistrationStatus)
                      }
                    </div>
                )}
              </li>
              <li className='deviceDetailView'>
                <span>Notes</span>
                {isSpaEdit ? (
                  <div className="deviceRegNoteArea">
                    <EmptyTextarea
                      className='overviewTextarea'
                      placeholder='Write Notes'
                      value={registrationNote}
                      onChange={v => dispatch(
                        endUserActions.onChange(
                          "registrationNote",
                          v.target.value
                        )
                      )}
                    />
                  </div>
                ) : (
                  <span className='deviceDetailProperties deviceDetailNotes'>
                    {registrationNote?.length ? registrationNote : "--"}
                  </span>
                )}
              </li>
              <li className='deviceDetailView'>
                <span>Warranty Status</span>
                {isSpaEdit ? (
                  <div
                    className={getStatusClassName(spaDevicewarrantyStatus)}>
                    <Dropdown
                      selectlabel=''
                      value={
                        spaDevicewarrantyStatus === "Expired"
                          ? 1
                          : spaDevicewarrantyStatus === "Active"
                          ? 0
                          : spaDevicewarrantyStatus
                      }
                      handleChange={({ target }) => {
                        dispatch(
                          endUserActions.onChange(
                            "spaDevicewarrantyStatus",
                            target.value
                          )
                        );
                      }}
                      customMenuItems={warrantyStatusOption}
                    />
                  </div>
                ) : (
                  <div
                    className={
                      spaDevicewarrantyStatus === 0 ||
                      spaDevicewarrantyStatus === "Active"
                        ? "statusRegistration"
                        : null ||
                          spaDevicewarrantyStatus === 1 ||
                          spaDevicewarrantyStatus === "Expired"
                        ? "statusExpired"
                        : "warrantyStatus"
                    }>
                    {spaDeviceDetails?.warrantyStatus ?? "--"}
                  </div>
                )}
              </li>
              <li className='deviceDetailView'>
                <span>Warranty Expiration</span>
                <span>{spaDeviceDetails?.warranyExpiryDate ?? "--"}</span>
              </li>
              <li className='deviceDetailView'>
                <span>Warranty Remaining </span>
                <span>{spaDeviceDetails?.warrantyRemaining ?? "--"}</span>
              </li>
            </ul>
          </span>
        </Grid>
        <div className='deviceDetailbuttons recieptBtnContainer'>
          {!isSpaEdit && spaDeviceDetails?.receipt && (
            <Button onClick={recieptModal} className='ViewRecieptBtn'>
              View Reciept
            </Button>
          )}
          {(getRegStatusValue(spaDeviceDetails?.registrationStatus) === 3||
            getRegStatusValue(spaDeviceDetails?.registrationStatus) === 0
          ) && isSpaEdit && (
            <Button
              className='uploadRecieptBtn'
              onClick={() => recieptModal("uploadReciept")}>
              {!spaDeviceDetails.receipt ? "Upload Reciept" : "Replace Reciept"}
            </Button>
          )}
        </div>
        <Grid
          container
          item
          // xs={12}
          className='spaCollectionWrapper deviceDetailOverviewInner imageWithDetails'>
          <Grid item md={2}>
            <div className='devicePicture'>
              <div className='spaPicture devicedDtailImage'></div>
            </div>
          </Grid>
          <Grid item md={9} className='deviceOverViewDetail'>
            <div className='spaHedaingOverViewMain productsHeadings'>
              Spa Topside Registration
            </div>
            <span className='deviceDetailOverView'>
              <ul className='listControler'>
                <li className='deviceDetailView'>
                  <span>Model</span>
                  <span>{spaSettings?.spaModel ?? "--"}</span>
                </li>
                <li className='deviceDetailView'>
                  <span>Product SKU</span>
                  <span>{spaTopside.productSku ?? "--"}</span>
                </li>
                <li className='deviceDetailView'>
                  <span>Serial Number</span>
                  <span>
                    <span> {spaTopside?.serialNo ?? "--"}</span>
                  </span>
                </li>
                <li className='deviceDetailView'>
                  <span>MAC ID</span>
                  <span>
                    <span>{spaTopside?.macID ?? "--"}</span>
                  </span>
                </li>
                <li className='deviceDetailView'>
                  <span>Purchase Location</span>
                  {isSpaEdit ? (
                    <div className='spaLastDeviceOverviewFeild locWrapper'>
                      <Autocomplete
                        id='popupEnvLocInput'
                        className='autocomplete'
                        apiKey={"AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"}
                        options={{
                          types: ["address"],
                        }}
                        onPlaceSelected={place => {
                          dispatch(
                            endUserActions.onChange(
                              "topSidePurchaseLocation",
                              place.formatted_address
                            )
                          );

                          dispatch(
                            endUserActions.onChange(
                              "topSidePurchaseLatitude",
                              place.geometry.location.lat()
                            )
                          );

                          dispatch(
                            endUserActions.onChange(
                              "topSidePurchaseLongitude",
                              place.geometry.location.lng()
                            )
                          );
                        }}
                        onChange={e =>
                          dispatch(
                            endUserActions.onChange(
                              "topSidePurchaseLocation",
                              e.target.value
                            )
                          )
                        }
                        value={topSidePurchaseLocation}
                      />
                    </div>
                  ) : (
                    <span>{topSidePurchaseLocation ?? "--"}</span>
                  )}
                </li>
                <li className='deviceDetailView'>
                  <span>Purchase Date</span>
                  {isSpaEdit ? (
                    <div className='spaLastDeviceOverviewFeild'>
                      <FormattedDateInput
                        date={moment(spaTopsidePrchDate, "MM/DD/YYYY").format(
                          "DD/MM/YYYY"
                        )}
                        setDate={target => {
                          dispatch(
                            endUserActions.onChange(
                              "spaTopsidePrchDate",
                              moment(target).format("MM/DD/YYYY")
                            )
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <span>
                      {spaTopsidePrchDate !== "" ? spaTopsidePrchDate : "--"}
                    </span>
                  )}
                </li>
                <li className='deviceDetailView'>
                  <span>Installation Date</span>
                  {isSpaEdit ? (
                    <div className='spaLastDeviceOverviewFeild'>
                      <FormattedDateInput
                        date={moment(
                          spaTopsideInstallDate,
                          "MM/DD/YYYY"
                        ).format("DD/MM/YYYY")}
                        setDate={target => {
                          dispatch(
                            endUserActions.onChange(
                              "spaTopsideInstallDate",
                              moment(target).format("MM/DD/YYYY")
                            )
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <span>{spaTopsideInstallDate ?? "--"}</span>
                  )}
                </li>
                <li className='deviceDetailView'>
                  <span>Registration Status</span>
                  {isSpaEdit ? (
                    <div
                      className={registrationStatusClasses(spaTopsideRegistrationStatus)}>
                      <Dropdown
                        selectlabel=''
                        value={getRegStatusValue(spaTopsideRegistrationStatus)}
                        handleChange={({ target }) => {
                          dispatch(
                            endUserActions.onChange(
                              "spaTopsideRegistrationStatus",
                              target.value
                            )
                          );
                        }}
                        customMenuItems={regStatusOptions}
                      />
                    </div>
                  ) : (
                    <div
                      className={spaRegStatusClass(spaTopsideRegistrationStatus)}>
                      {getRegStatusText(spaTopsideRegistrationStatus)}
                    </div>
                  )}
                </li>

                {/* <li className='deviceDetailView'>
                  <span>Notes</span>
                  {isSpaEdit ? (
                    <EmptyTextarea
                      className='overviewTextarea'
                      placeholder='Write Notes'
                      value={spaTopsideRegistrationNote}
                      onChange={v => dispatch(
                        endUserActions.onChange(
                          "spaTopsideRegistrationNote",
                          v.target.value
                        )
                      )}
                    />
                  ) : (
                    <span className='deviceDetailProperties deviceDetailNotes'>
                      {spaTopsideRegistrationNote?.length ? spaTopsideRegistrationNote : "--"}
                    </span>
                  )}
                </li> */}

                <li className='deviceDetailView'>
                  <span>Warranty Status</span>
                  {isSpaEdit ? (
                    <div
                      className={getStatusClassName(spaTopsideWarrantyStatus)}>
                      <Dropdown
                        selectlabel=''
                        value={ spaTopsideWarrantyStatus === "Expired"? 1
                        : spaTopsideWarrantyStatus === "Active" ? 0
                        : spaTopsideWarrantyStatus}
                        handleChange={({ target }) => {
                          dispatch(
                            endUserActions.onChange(
                              "spaTopsideWarrantyStatus",
                              target.value
                            )
                          );
                        }}
                        customMenuItems={warrantyStatusOption}
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        spaTopsideWarrantyStatus === 0 ||
                        spaTopsideWarrantyStatus === "Active"
                          ? "statusRegistration"
                          : spaTopsideWarrantyStatus === 1 ||
                          spaTopsideWarrantyStatus === "Expired"
                          ? "statusExpired"
                          : "warrantyStatus"
                      }>
                      {spaTopsideWarrantyStatus === 0
                        ? "Active"
                        : spaTopsideWarrantyStatus === 1
                        ? "Expired"
                        : "--"}
                    </div>
                  )}
                </li>

                <li className='deviceDetailView'>
                  <span>Warranty Expiration</span>
                  <span>{spaTopside?.warrantyExpiration ?? "--"}</span>
                </li>
                <li className='deviceDetailView'>
                  <span>Warranty Remaining </span>
                  <span>{spaTopside?.warrantyRemaining ?? "--"}</span>
                </li>
              </ul>
            </span>
          </Grid>
        </Grid>

        <div className='deviceDetailbuttons recieptBtnContainer'>
          {!isSpaEdit && spaTopside?.receipt && (
            <Button onClick={recieptModal} className='ViewRecieptBtn'>
              View Reciept
            </Button>
          )}
          {isSpaEdit && 
          (getRegStatusValue(spaTopside?.registrationStatus) === 3 ||
          getRegStatusValue(spaTopside?.registrationStatus) === 0
          ) && (
            <Button
              className='uploadRecieptBtn'
              onClick={() => recieptModal("uploadReciept")}>
              {!spaTopside.receipt ? "Upload Reciept" : "Replace Reciept"}
            </Button>
          )}
        </div>

        <Grid
          container
          item
          className='spaCollectionWrapper deviceDetailOverviewInner imageWithDetails'>
          <Grid item md={2}>
            <div className='devicePicture'>
              <div className='spaPicture devicedDtailImage'></div>
            </div>
          </Grid>
          <Grid item md={9} className='deviceOverViewDetail'>
            <div className='spaHedaingOverViewMain productsHeadings'>
              Spa Controller Registration
            </div>
            <span className='deviceDetailOverView'>
              <ul className='listControler'>
                <li className='deviceDetailView'>
                  <span>Model</span>
                  <span>{spaController?.spaModel ?? "--"}</span>
                </li>
                <li className='deviceDetailView'>
                  <span>Product SKU</span>
                  <span>{spaController.productSKU ?? "--"}</span>
                </li>
                <li className='deviceDetailView'>
                  <span>Serial Number</span>
                  <span>
                    <span>{spaController?.serialNo ?? "--"}</span>
                  </span>
                </li>
                <li className='deviceDetailView'>
                  <span>Purchase Location</span>
                  {isSpaEdit ? (
                    <div className='spaLastDeviceOverviewFeild locWrapper'>
                      <Autocomplete
                        id='popupEnvLocInput'
                        className='autocomplete'
                        apiKey={"AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"}
                        options={{
                          types: ["address"],
                        }}
                        onPlaceSelected={place => {
                          dispatch(
                            endUserActions.onChange(
                              "controllerPurchaseLocation",
                              place.formatted_address
                            )
                          );

                          dispatch(
                            endUserActions.onChange(
                              "controllerPurchaseLatitude",
                              place.geometry.location.lat()
                            )
                          );

                          dispatch(
                            endUserActions.onChange(
                              "controllerPurchaseLongitude",
                              place.geometry.location.lng()
                            )
                          );
                        }}
                        onChange={e =>
                          dispatch(
                            endUserActions.onChange(
                              "controllerPurchaseLocation",
                              e.target.value
                            )
                          )
                        }
                        value={controllerPurchaseLocation}
                      />
                    </div>
                  ) : (
                    <span>
                      {controllerPurchaseLocation !== ""
                        ? controllerPurchaseLocation
                        : "--"}
                    </span>
                  )}
                </li>
                <li className='deviceDetailView'>
                  <span>Purchase Date</span>
                  {isSpaEdit ? (
                    <div className='spaLastDeviceOverviewFeild'>
                      <FormattedDateInput
                        date={moment(
                          spaControllerPrchDate,
                          "MM/DD/YYYY"
                        ).format("DD/MM/YYYY")}
                        setDate={target => {
                          dispatch(
                            endUserActions.onChange(
                              "spaControllerPrchDate",
                              moment(target).format("MM/DD/YYYY")
                            )
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <span>
                      <span>{prchDate !== "" ? prchDate : "--"}</span>
                    </span>
                  )}
                </li>
                <li className='deviceDetailView'>
                  <span>Installation Date</span>
                  {isSpaEdit ? (
                    <div className='spaLastDeviceOverviewFeild'>
                      <FormattedDateInput
                        date={moment(
                          spaControllerinstalldate,
                          "MM/DD/YYYY"
                        ).format("DD/MM/YYYY")}
                        setDate={target => {
                          dispatch(
                            endUserActions.onChange(
                              "spaControllerinstalldate",
                              moment(target).format("MM/DD/YYYY")
                            )
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <span>
                      {spaController?.installationDate !== ""
                        ? spaController?.installationDate
                        : "--"}
                    </span>
                  )}
                </li>
                <li className='deviceDetailView'>
                  <span>Registration Status</span>
                  {isSpaEdit ? (
                    <div
                      className={registrationStatusClasses(spaControllerRegistration)}>
                      <Dropdown
                        selectlabel=''
                        value={getRegStatusValue(spaControllerRegistration)}
                        handleChange={({ target }) => {
                          dispatch(
                            endUserActions.onChange(
                              "spaControllerRegistration",
                              target.value
                            )
                          );
                        }}
                        customMenuItems={regStatusOptions}
                      />
                    </div>
                  ) : (
                    <div
                      className={spaRegStatusClass(spaControllerRegistration)}>
                      {getRegStatusText(spaControllerRegistration)}
                    </div>
                  )}
                </li>

                {/* <li className='deviceDetailView deviceDetailNotes'>
                  <span>Notes</span>
                  {isSpaEdit ? (
                    <EmptyTextarea
                      className='overviewTextarea'
                      placeholder='Write Notes'
                    value={spaControllerNote}
                    onChange={v =>  dispatch(
                      endUserActions.onChange(
                        "spaControllerNote",
                        v.target.value
                      ))}
                    />
                  ) : (
                    <span className='deviceDetailProperties'>
                      {spaControllerNote?.length  ? spaControllerNote : "--"}
                    </span>
                  )}
                </li> */}

                <li className='deviceDetailView'>
                  <span>Warranty Status</span>
                  {isSpaEdit ? (
                    <div
                      className={getStatusClassName(spaControllerWarrantyStatus)}>
                      <Dropdown
                        selectlabel=''
                        value={spaControllerWarrantyStatus}
                        handleChange={({ target }) => {
                          dispatch(
                            endUserActions.onChange(
                              "spaControllerWarrantyStatus",
                              target.value
                            )
                          );
                        }}
                        customMenuItems={warrantyStatusOption}
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        spaControllerWarrantyStatus === 0
                          ? "statusRegistration"
                          : spaControllerWarrantyStatus === 1
                          ? "statusExpired"
                          : "warrantyStatus"
                      }>
                      {spaControllerWarrantyStatus === 0 ||
                      spaControllerWarrantyStatus === "Active"
                        ? "Active"
                        : spaControllerWarrantyStatus === 1 ||
                        spaControllerWarrantyStatus === "Expired"
                        ? "Expired"
                        : spaControllerWarrantyStatus ?? "--"}
                    </div>
                  )}
                </li>

                <li className='deviceDetailView'>
                  <span>Warranty Expiration</span>
                  <span>{spaController?.warrantyExpiration ?? "--"}</span>
                </li>
                <li className='deviceDetailView'>
                  <span>Warranty Remaining </span>
                  <span>{spaController?.warrantyRemaining ?? "--"}</span>
                </li>
              </ul>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <div className='deviceDetailbuttons recieptBtnContainer'>
        {!isSpaEdit && spaController?.receipt && (
          <Button onClick={recieptModal} className='ViewRecieptBtn'>
            View Reciept
          </Button>
        )}
        {isSpaEdit && 
        (getRegStatusValue(spaController?.registrationStatus) === 3||
        getRegStatusValue(spaController?.registrationStatus) === 0
        ) && (
          <Button
            className='uploadRecieptBtn'
            onClick={() => recieptModal("uploadReciept")}>
            {!spaController.receipt ? "Upload Reciept" : "Replace Reciept"}
          </Button>
        )}
      </div>
      <RecieptModal
        recieptModal={recieptModal}
        open={open}
        isUploadRecieptModal={isUploadRecieptModal}
        devId={deviceId}
        getSpaDeviceInfo={getSpaDeviceInfo}
        deviceType='spa'
      />
    </div>
  );
};
