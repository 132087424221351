import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  LogHeader,
  MainHeading,
  InnerHeader,
} from "../../../components/common/Header/index";
import MediaLibrarFilter from "./MediaLibraryFilter";
import Cards from "../../../components/common/Cards";
import { mediaPic } from "../../../resources/images/index";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import routesObj from "../../../routes/RoutesObj";

function LogAllSourceUnits(props) {
  const history = useHistory();
  const location = useLocation();

  const [pmlCatData, setPMLCatData] = useState({});

  const mediaDetailLink = item => {
    if (item.noOfAssets > 0) {
      history.push({
        pathname: routesObj.MediaDetail.path,
        state: {
          colAssets: item,
          catName: item.category ? item.category.name : pmlCatData.name,
        },
      });
    } else {
      toast.warning("No asset is available");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location && location.state && location.state.collectionData) {
      setPMLCatData(location.state.collectionData);
    }
  }, [location]);

  return (
    <div className='latestReleaseDetailWrapper allCategory'>
      <LogHeader />
      <MainHeading headerSubHeading='All Source Units'>
        <div className='publicBodyTwoColumn'>
          <div className='leftFiletBar'>
            <MediaLibrarFilter />
          </div>
          <div className='rightContentArea'>
            <InnerHeader
              picture={mediaPic}
              text='Find media assets including overviews, images and more.'
              heading='Media Library'
            />
            <div className='boxMainWrapper eventsWrapper releaseInnerWrapper searchInner'>
              <h2>{`${
                pmlCatData.collections && pmlCatData.collections.length
              }${" results found for ‘"}${pmlCatData.name}${"‘"}`}</h2>
              <div className='cards'>
                <Grid container spacing={3}>
                  {pmlCatData.collections &&
                    pmlCatData.collections.length > 0 &&
                    pmlCatData.collections.map((pml, index) => (
                      <Grid key={index} item md={4}>
                        <Cards
                          picture={
                            "/static/images/cards/contemplative-reptile.jpg"
                          }
                          subheading={pml.title}
                          cardtext={`${pml.noOfAssets}${" assets"}`}
                          onClick={() => mediaDetailLink(pml)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </MainHeading>
    </div>
  );
}

export default LogAllSourceUnits;
