import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Accordion, AccordionSummary, Button, FormControl, IconButton, Typography } from "@material-ui/core";
import { AllProductSerials } from "./AllProductSerials";
import Dashboard from "../Dashbard";
import Loader from "../../../../components/common/Loader";
import {
  GridSearchFilterBar,
  InputField,
  Pagination,
  ProductTabButtons,
} from "../../../../components/common";
import { productSerialActions } from "../../../../store/product/productSerialsAction";
import ProductInventoryFilter, { FilterAutoComplete, SelectedChiptItems, SelectedDateChip } from "../ProductInventory/ProductInventoryFilter";
import { useDebounce } from "../../../../utils/use-debounce";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { toast } from "react-toastify";
import { ExpandMore, MoreHoriz } from "@material-ui/icons";
import { FilterCreationDate } from "../EndUser";
import { ChipItem } from "../Conversations/ConverstionSettings/GridFilter";
import AutoComplete from "../../../../components/common/AutoComplete";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { debounce } from "lodash";

const ProductSerials = props => {
  document.title = "OFish - Product Serials";

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [productSerials, setProductSerials] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [dealer, setDealer] = useState(0);
  const [paginationResponse, setPaginationResponse] = useState({
    currentCount: 0,
    totalRecords: 0,
  });
  const [filterCategories , setFilterCategories] = useState([])
  const [filters, setFilters] = useState({
    name: "",
    categoryId:[],
    // status: [],
    createdOnFrom:null,
    createdOnTo:null,
    serialStatus:[],
    email:"",
    purchaseLocation:"",
    purchaseDateFrom:null,
    purchaseDateTo:null,
    deviceStatus:[],
    serialNo:"",
    owner:"",
    purchasingPrice:""
  });

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    orderByKey: "SerialNo",
    orderVal: 0,
  });

  let filtersIndex = 0;
  const filtersLimit = 4;
  const indexReference = useRef(filtersIndex);
  const [showFilters, setShowFilter] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [showFilterCounter , setShowFilterCounter] = useState(0);
  const [brands, setBrands] = useState([]);
  const { debouncedValue: debouncedSearchTxt } = useDebounce(filters.name, 500);

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      productSerialActions.onGetAllProductSerials({
        pageNumber: page,
        noOfRecoreds: rowsPerPage,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
        filters: {
          ...filters,
          email: filters.email ? [filters.email] : [],
          purchaseLocation: filters.purchaseLocation ? [filters.purchaseLocation] : [],
        },
      })
    ).then(data => {
      setProductSerials(data?.data?.data || []);
      if (data?.success) {
        setPaginationResponse(data?.data?.pagination);
      } else {
        // toast.error("Something went wrong, Please try latter.");
        setPaginationResponse({
          currentCount: 0,
          totalRecords: 0,
        });
      }
      setLoading(false);
    });
  }, [dispatch, debouncedSearchTxt, order.orderByKey, order.orderVal, page, rowsPerPage]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const showFilter = () => {
    window.scroll(0, 0);
    // document.body.classList.add("bodyScroll");
    setIsFilter(true);
  };

  const hideFilter = () => {
    setIsFilter(false);
  };

  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllProductSubCategories(0)).then(
      (data) => setFilterCategories(data?.data)
    );
  }, [dispatch]);

  const deviceStatusOptions = [
    { id: 0, value: "Pending" },
    { id: 1, value: "Register" },
    { id: 5, value: "Rejected" },
    { id: 3, value: "Receipt Missing" },
    { id: 6, value: "Mark as Incomplete" },
  ]
  const statusOption = [
    { id: 2, name: "Blocked" },
    { id: 0, name: "Unblocked" },
  ]

const filtersCount =
(filters.categoryId?.length ? filters.categoryId.length : 0) +
(filters.deviceStatus?.length ? filters.deviceStatus.length : 0) +
(filters.serialStatus?.length ? filters.serialStatus.length : 0) +
(filters.email ? 1 : 0) +
(filters.serialNo ? 1 : 0) +
(filters.purchaseLocation ? 1 : 0) +
(filters.purchaseDateFrom ? 1 : 0) +
(filters.purchaseDateTo ? 1 : 0) +
(filters.createdOnFrom ? 1 : 0) +
(filters.createdOnTo ? 1 : 0);

indexReference.current = filtersIndex;

const applyFilters = ()=>{
  setLoading(true);
  setShowFilterCounter(filtersCount)
  hideFilter();
  dispatch(
    productSerialActions.onGetAllProductSerials({
      pageNumber: page,
      noOfRecoreds: rowsPerPage,
      orderByKey: order.orderByKey,
      orderByVal: order.orderVal,
      filters: {
        ...filters,
        email: filters.email ? [filters.email] : [],
        purchaseLocation: filters.purchaseLocation ? [filters.purchaseLocation] : [],
      }
    })
  ).then(data => {
    setProductSerials(data?.data?.data || []);
    if (data?.success) {
      setPaginationResponse(data?.data?.pagination);
    } else {
      setPaginationResponse({
        currentCount: 0,
        totalRecords: 0,
      });
    }
    setLoading(false);
  });
}
const onClearButtonPress = ()=>{
  setLoading(true);
  setPage(1);
  setRowsPerPage(10);
  setShowFilterCounter(0)
  setFilters({
    name: "",
    categoryId:[],
    // status: [],
    createdOnFrom:null,
    createdOnTo:null,
    serialStatus:[],
    email:"",
    purchaseLocation:"",
    purchaseDateFrom:null,
    purchaseDateTo:null,
    deviceStatus:[],
    serialNo:"",
    owner:"",
    purchasingPrice:""
  })
  dispatch(
    productSerialActions.onGetAllProductSerials({
      pageNumber: page,
      noOfRecoreds: rowsPerPage,
      orderByKey: order.orderByKey,
      orderByVal: order.orderVal,
      filters:{
        name: "",
        categoryId:[],
        // status: [],
        createdOnFrom:null,
        createdOnTo:null,
        serialStatus:[],
        email:[],
        purchaseLocation:[],
        purchaseDateFrom:null,
        purchaseDateTo:null,
        deviceStatus:[],
        serialNo:"",
        owner:"",
        purchasingPrice:""
      }})
  ).then(data => {
    setProductSerials(data?.data?.data || []);
    if (data?.success) {
      setPaginationResponse(data?.data?.pagination);
    } else {
      if (data?.message?.includes("No Data Found")) {
      } else toast.error("Something went wrong, Please try later.");
      setPaginationResponse({
        currentCount: 0,
        totalRecords: 0,
      });
    }
    setLoading(false);
  });
}

const changeHandler = (event, catId) => {
  dispatch(endUserActions.onGetAllDelears(event.target.value, catId)).then(
    res => {
      setDealers(res?.data || []);
    }
  );
};
const debouncedChangeHandler = useCallback(
  debounce(changeHandler, 500),
  []
);

useEffect(() => {
  dispatch(endUserActions.onGetAllDelears("", 0)).then(data => {
    if (data) {
      setDealers(data?.data);
    }
  });
}, [dispatch]);

  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>Product Serials</h1>
          <Button
            color='primary'
            // onClick={() => history.push("/new-product")}
            disabled
            variant='contained'>
            Import Serials
          </Button>
        </div>

        <ProductTabButtons />

        <div className='gridBody contentBody'>
          {/* <GridSearchFilterBar
            filterBy={filters}
            onFilterButtonPress={customFilters => setFilters(customFilters)}
            onClearButtonPress={() =>
              setFilters({
                name: "",
                categoryId: null,
                dateCreated: null,
              })
            }
          /> */}
          <ProductInventoryFilter
            onFilterButtonPress={customFilters => setFilters(customFilters)}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            filters={filters}
            setFilters={setFilters}
            searchKey={"name"}
            title={"Product Serials Filters"}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            showFilter={showFilter}
            hideFilter={hideFilter}
            applyFilters={applyFilters}
            showFilterCounter={showFilterCounter}
          >
            <Accordion
              defaultExpanded
              id="end-user-filter-expandable"
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  <span>Active Filter(s)</span>
                  <span>
                    <Button
                      className="filterClearBtn filterClearBtnStyle"
                      variant="outlined"
                      onClick={() => {
                        hideFilter();
                        onClearButtonPress();
                      }}
                    >
                      Clear
                    </Button>
                  </span>
                </Typography>
              </AccordionSummary>
              <div>
                {filtersCount <= 0 && (
                  <div className={`filtersChips`}>No Active Filter(s)</div>
                )}
              </div>

              <SelectedDateChip
                startDate={filters.createdOnFrom}
                endDate={filters.createdOnTo}
                setStartDate={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    createdOnFrom: date,
                  }));
                }}
                setEndDate={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    createdOnTo: date,
                  }));
                }}
                chipLabel={"Device Creation Date"}
              />

              <SelectedDateChip
                startDate={filters.purchaseDateFrom}
                endDate={filters.purchaseDateTo}
                setStartDate={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    purchaseDateFrom: date,
                  }));
                }}
                setEndDate={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    purchaseDateTo: date,
                  }));
                }}
                chipLabel={"Purchase Date"}
              />

              <SelectedChiptItems
                selectedItems={filters.categoryId}
                items={filterCategories}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"categoryId"}
                chipLabel={"Product"}
              />
              <SelectedChiptItems
                selectedItems={filters.serialStatus}
                items={[...statusOption.map(item => ({
                  ...item,
                  value: item.name
                }))]}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"serialStatus"}
                chipLabel={"Serial Status"}
              />
              <SelectedChiptItems
                selectedItems={filters.brand}
                items={brands}
                setFilters={setFilters}
                filtersIndex={filtersIndex}
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"brand"}
                chipLabel={"Product Brand"}
              />
              <SelectedChiptItems
                selectedItems={filters.deviceStatus}
                items={deviceStatusOptions}
                setFilters={setFilters}
                filtersIndex={filtersIndex}        
                filtersLimit={filtersLimit}
                showFilters={showFilters}
                field={"deviceStatus"}
                chipLabel={"Device Status"}
              />
              {filters.email?.length > 0 &&
              (filtersIndex <= filtersLimit ||
                (filtersIndex > filtersLimit && showFilters)) && (
                <ChipItem
                  chipLabel='Search By Mail'
                  chipValue={filters.email}
                   onDelete={() => {
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      email: ""
                    }));
                  }}
                />
              )}
              {filters.purchaseLocation?.length > 0 &&
                (filtersIndex <= filtersLimit ||
                  (filtersIndex > filtersLimit && showFilters)) && (
                  <ChipItem
                    chipLabel='Search By Mail'
                    chipValue={filters.purchaseLocation}
                    onDelete={() => {
                      setFilters(prevFilters => ({
                        ...prevFilters,
                        purchaseLocation: ""
                      }));
                    }}
                  />
                )}
              {filters.serialNo?.length > 0 &&
                (filtersIndex <= filtersLimit ||
                  (filtersIndex > filtersLimit && showFilters)) && (
                  <ChipItem
                    chipLabel='Search By Mail'
                    chipValue={filters.serialNo}
                    onDelete={() => {
                      setFilters(prevFilters => ({
                        ...prevFilters,
                        serialNo: ""
                      }));
                    }}
                  />
                )}

              <div
                style={{
                  textAlign: "right",
                }}>
                {filtersCount > filtersLimit && (
                  <IconButton
                    color={"primary"}
                    onClick={() => setShowFilter(!showFilters)}>
                    <MoreHoriz />
                  </IconButton>
                )}
              </div>
            </Accordion>

            <div className='userDetailsSearchField inputWrapper filterSelect'>
              <div style={{marginBottom:"10px"}}>
                <h4>Product Creation Date</h4>
                <FilterCreationDate
                  StartDate={filters.createdOnFrom}
                  setStartDate={(date) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      createdOnFrom: date,
                    }));
                  }}
                  EndDate={filters.createdOnTo}
                  setEndDate={(date) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      createdOnTo: date,
                    }));
                  }}
                />
              </div>
              <div style={{marginBottom:"10px"}}>
                <h4>Purchase Date</h4>
                <FilterCreationDate
                  StartDate={filters.purchaseDateFrom}
                  setStartDate={(date) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      purchaseDateFrom: date,
                    }));
                  }}
                  EndDate={filters.purchaseDateTo}
                  setEndDate={(date) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      purchaseDateTo: date,
                    }));
                  }}
                />
              </div>

              <FilterAutoComplete
                value={filterCategories.filter(eg => filters?.categoryId?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    categoryId: value.map(cat => cat.id)
                  }));
                }}
                id='product-category-dd'
                options={filterCategories}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Product Category"}
                selectedFilter={filters.categoryId?.length}
              />
              <FilterAutoComplete
                value={statusOption.filter(eg => filters.serialStatus?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    serialStatus: value.map(cat => cat.id)
                  }));
                }}
                id='product-serialStatus-dd'
                options={statusOption}
                getOptionLabel={option => option?.name || ""}
                placeholder={"Serial Status"}
                selectedFilter={filters.serialStatus?.length}
              />
              <FilterAutoComplete
                value={deviceStatusOptions.filter(eg => filters.deviceStatus?.includes(eg.id))}
                onChange={(event, value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    deviceStatus: value.map(cat => cat.id)
                  }));
                }}
                id='product-launch-dd'
                options={deviceStatusOptions}
                getOptionLabel={option => option?.value || ""}
                placeholder={"Device Status"}
                selectedFilter={filters.deviceStatus?.length}
              />
              <InputField
                id='email'
                name='email'
                placeholder={"Search By Mail"}
                value={filters.email}
                type={"email"}
                onChange={({ target }) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  email : target.value,
                }))}
              />
              <div className="purchaseLocFilter">
              <AutoComplete
                value={filters.purchaseLocation}
                onChange={e => {
                  debouncedChangeHandler(e, 0);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    purchaseLocation : e.target.value
                  }))
                }}
                eventLocation={dealers}
                setEvent={v => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    purchaseLocation : v.value,
                  }))
                }}
                label={"Purchase Location"}
                showLocationName={true}
                placeholder={"Dealer Location"}
              />
              </div>
              <InputField
                id='SerialNumber'
                name='SerialNumber'
                placeholder={"Serial Number"}
                value={filters.serialNo}
                onChange={({ target }) =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    serialNo: target.value,
                  }))}
              />
            </div>

          </ProductInventoryFilter>
          <AllProductSerials
            listData={productSerials}
            loadData={() => loadData()}
            order={order}
            onOrderBy={setOrderBy}
          />
          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          {/* {productSerials?.length <= 0 ? (
            <EmptyGridText
              text={"product serials"}
              buttonText={"Product Serial"}
              onClick={() => {}}
            />
          ) : (
            <Pagination
              recordCount={paginationResponse?.totalRecords}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )} */}
        </div>
      </div>
    </Dashboard>
  );
};

export { ProductSerials };
