import { pubReleaseTypes } from "./pubReleaseTypes";
import { pubReleaseApi } from "./pubReleaseApi";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: pubReleaseTypes.PUB_RELEASE_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onGetPubReleases = releasePayload => async dispatch => {
  let response = await pubReleaseApi.getPubReleases(releasePayload);
  if (response && response.success) {
    dispatch({
      type: pubReleaseTypes.PUB_RELEASE_ONCHANGE,
      payload: {
        prop: "releases",
        value: response.data[0] ? response.data[0].data : [],
      },
    });
  }
  return response;
};

const onGetPubRelWithCategory = releasePayload => async dispatch => {
  let response = await pubReleaseApi.getPubReleases(releasePayload);
  if (response && response.success) {
    dispatch({
      type: pubReleaseTypes.PUB_RELEASE_ONCHANGE,
      payload: { prop: "releasesWithCategory", value: response.data },
    });
  }
  return response;
};

const onGetPubCategories = () => async dispatch => {
  let response = await pubReleaseApi.getPubRelCategory();
  if (response && response.success) {
    dispatch({
      type: pubReleaseTypes.PUB_RELEASE_ONCHANGE,
      payload: { prop: "pubReleaseCategory", value: response.data },
    });
  }
  return response;
};

const onGetPubRelDateFilter = catIds => async dispatch => {
  let response = await pubReleaseApi.getPubRelDateFilter(catIds);
  if (response && response.success) {
    dispatch({
      type: pubReleaseTypes.PUB_RELEASE_ONCHANGE,
      payload: { prop: "relDateFilter", value: response.data },
    });
  }
  return response;
};

const onGetPubRelDetail = (relId, urlSlug) => async dispatch => {
  let response = await pubReleaseApi.getPubRelDetail(relId, urlSlug);
  return response;
};

const onSelectPubRelCategory = (value, selectedCat) => async dispatch => {
  let catIds = [];
  dispatch({
    type: pubReleaseTypes.SELECTED_PUB_REL_CATEGORY,
    payload: value,
  });
  if (selectedCat && selectedCat.length > 0) {
    selectedCat.forEach(element => {
      catIds.push(element.id);
    });
  }
  let response = await pubReleaseApi.getPubRelDateFilter(catIds);
  if (response && response.success) {
    dispatch({
      type: pubReleaseTypes.PUB_RELEASE_ONCHANGE,
      payload: { prop: "relDateFilter", value: response.data },
    });
  }
  return response;
};

const onGetPubFilterReleases = () => async dispatch => {
  let response = await pubReleaseApi.getPubAllFilterData();
  if (response && response.success) {
    // let finalRes = response.data.sort(function (a, b) {
    //   return new Date(b.dateCreated) - new Date(a.dateCreated);
    // });
    dispatch({
      type: pubReleaseTypes.PUB_RELEASES_FILTER_DATA,
      payload: response.data,
    });
  }
  return response;
};

const onSelectPubRelDateFilter = value => async dispatch => {
  dispatch({
    type: pubReleaseTypes.SELECTED_PUB_REL_FILTER,
    payload: value,
  });
};

const onPressPubFind = filterdData => async dispatch => {
  dispatch({
    type: pubReleaseTypes.DISPLAY_PUB_REL_FILTER,
    payload: filterdData,
  });
};

const onPressPubClear = () => async dispatch => {
  dispatch({
    type: pubReleaseTypes.CLEAR_PUB_REL_FILTER,
  });
};

const onAddValueInFilter = value => async dispatch => {
  dispatch({
    type: pubReleaseTypes.SELECTED_PUB_REL_CATEGORY,
    payload: value,
  });
};

export const pubReleaseActions = {
  onChange: onChange,
  onGetPubReleases: onGetPubReleases,
  onGetPubRelWithCategory: onGetPubRelWithCategory,
  onGetPubCategories: onGetPubCategories,
  onGetPubRelDateFilter: onGetPubRelDateFilter,
  onGetPubRelDetail: onGetPubRelDetail,
  onSelectPubRelCategory: onSelectPubRelCategory,
  onGetPubFilterReleases: onGetPubFilterReleases,
  onSelectPubRelDateFilter: onSelectPubRelDateFilter,
  onPressPubFind: onPressPubFind,
  onPressPubClear: onPressPubClear,
  onAddValueInFilter: onAddValueInFilter,
};
