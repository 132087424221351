import { pubReleaseTypes } from "./pubReleaseTypes";
import _ from "lodash";

const INITIAL_STATE = {
  errorMessage: "",
  releases: [],
  releasesWithCategory: [],
  pubReleaseCategory: [],
  relDateFilter: [],
  selectedPRCategory: [],
  allPubReleases: {},
  lastPubMonth: {},
  lastPub3Month: {},
  lastPub6Month: {},
  lastPubYear: {},
  selectedPubDateFilter: [],
  displayPubFilter: [],
  displayPRCategory: [],
  displayFilteredData: false,
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case pubReleaseTypes.PUB_RELEASE_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    case pubReleaseTypes.SELECTED_PUB_REL_CATEGORY: {
      const newState = { ...state };
      const relCategory = action.payload;
      const catExist = newState.selectedPRCategory.find(cat => {
        return cat.id == relCategory.id;
      });

      if (catExist && newState.selectedPRCategory.length > 0) {
        _.remove(newState.selectedPRCategory, cate => {
          return cate.id == relCategory.id;
        });
      } else {
        newState.selectedPRCategory.push(relCategory);
      }
      return newState;
    }
    case pubReleaseTypes.PUB_RELEASES_FILTER_DATA: {
      const newState = { ...state };
      const filterData = action.payload;
      newState.allPubReleases = { name: "All", releases: filterData.all };
      newState.lastPubMonth = {
        name: "Last Month",
        releases: filterData.lastMonth,
      };
      newState.lastPub3Month = {
        name: "Last 3 Months",
        releases: filterData.lastThreeMonths,
      };
      newState.lastPub6Month = {
        name: "Last 6 Months",
        releases: filterData.lastSixMonths,
      };
      newState.lastPubYear = {
        name: "Last Year",
        releases: filterData.lastYear,
      };
      return newState;
    }
    case pubReleaseTypes.SELECTED_PUB_REL_FILTER: {
      const newState = { ...state };
      const relCategory = action.payload;
      const relExist = newState.selectedPubDateFilter.find(cat => {
        return cat.id == relCategory.id;
      });
      if (relExist && newState.selectedPubDateFilter.length > 0) {
        _.remove(newState.selectedPubDateFilter, cate => {
          return cate.id == relCategory.id;
        });
      } else {
        newState.selectedPubDateFilter.push(relCategory);
      }
      return newState;
    }
    case pubReleaseTypes.DISPLAY_PUB_REL_FILTER: {
      const newState = { ...state };
      const result = action.payload;
      newState.displayPubFilter = result;
      newState.displayFilteredData = true;
      return newState;
    }
    case pubReleaseTypes.CLEAR_PUB_REL_FILTER: {
      const newState = { ...state };
      newState.displayPubFilter = [];
      newState.selectedPubDateFilter = [];
      // newState.displayPRCategory = [];
      newState.selectedPRCategory = [];
      newState.displayFilteredData = false;
      return newState;
    }

    default:
      return state;
  }
};
