import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../../components/common/InputField";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { newsroomActions } from "../../../../../store/newsroom/newsroomActions";
import { BiX } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridOuterWrapper: {
    position: "relative",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "6px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },

  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  hoursWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

function ContactEmail(props) {
  const dispatch = useDispatch();

  const [emailIndex, setEmailIndex] = useState(null);

  const changeEmail = (value, index, fieldName) => {
    dispatch(newsroomActions.onChangeEmails(index, value, fieldName));
  };
  const checkEmailValidation = (value, index) => {
    if (/^\S+@\S+\.\S+$/.test(value)) {
      dispatch(newsroomActions.onChangeEmails(index, true, "isValid"));
      setEmailIndex(null);
    } else {
      if (value === "") {
        dispatch(newsroomActions.onChangeEmails(index, true, "isValid"));
        setEmailIndex(null);
      } else {
        dispatch(newsroomActions.onChangeEmails(index, false, "isValid"));
        setEmailIndex(index);
      }
    }
  };
  const classes = useStyles();
  const { emails } = useSelector((state) => state.newsRoomReducer);
  return (
    <>
      <div className="contactWrapperOuter">
        <div className="gap" />
        <div className="gap" />
        <label className="innerLabel">Contact E-mail</label>
        {emails.length > 0 &&
          emails.map((eml, index) => {
            return (
              <Grid key={index} container spacing={3}>
                <Grid
                  item
                  xs={6}
                  className={[
                    classes.gridWrapper,
                    classes.gridOuterWrapper,
                    `${eml.isValid === false ? "formErrorWrapper" : ""}`,
                  ]}
                >
                  <InputField
                    placeholder="email"
                    inputlabel={`${"E-mail address "}${index + 1}`}
                    value={eml.email}
                    onChange={(e) =>
                      changeEmail(e.target.value, index, "email")
                    }
                    onBlur={(e) => checkEmailValidation(e.target.value, index)}
                  />
                  {eml.email.length > 50 && (
                    <span style={{ color: "red" }}>
                      email characters length cannot be exceed from 50
                      characters
                    </span>
                  )}
                  <span style={{ color: "red" }}>
                    {eml.isValid === false ? "InValid email format" : ""}
                  </span>
                  <span className="deleteRow">
                    <BiX
                      onClick={() =>
                        dispatch(newsroomActions.onRemoveEmail(index))
                      }
                    />
                  </span>
                </Grid>
              </Grid>
            );
          })}
        <Grid
          item
          xs={2}
          className={[classes.gridWrapper, "addNumberBtn addContact"]}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => dispatch(newsroomActions.onAddNewEmail())}
            className={classes.selectBtn}
          >
            Add
          </Button>
        </Grid>
      </div>
    </>
  );
}

export default ContactEmail;
