import { axios } from '../../config/axiosConfig';
import { endpoints } from '../../config/apiConfig';
import apiCall from 'axios';

async function addUpdateBuild(release) {
    return axios.post(endpoints.softwareRelease.addUpdateSoftwareBuild, release)
        .then(response => response.data)
        .catch(error => error);
};

async function addUpdateSoftwareReleaseBuildProducts(productsData) {
    return axios.post(endpoints.softwareRelease.addUpdateSoftwareReleaseBuildProducts, productsData)
        .then(response => response.data)
        .catch(error => error);
};

async function addUpdateSRBuildPortalandContacts(requirementsData) {
    return axios.post(endpoints.softwareRelease.addUpdateSRBuildPortalandContacts, requirementsData)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareBuildReleaseById(releaseId) {
    return axios.post(`${endpoints.softwareRelease.getSoftwareBuildReleaseById}/${releaseId}`)
        .then(response => response.data)
        .catch(error => error);
};

async function getReleaseCompatibleProducts() {
    return axios.post(endpoints.softwareRelease.getReleaseCompatibleProducts)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareContactsReleaseBuildById(releaseId) {
    return axios.post(`${endpoints.softwareRelease.getSoftwareContactsReleaseBuildById}/${releaseId}`)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleaseBuildPortalById(releaseId) {
    return axios.post(`${endpoints.softwareRelease.getSoftwareReleaseBuildPortalById}/${releaseId}`)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleaseBuildProducts(queryData) {
    return axios.post(endpoints.softwareRelease.getSoftwareReleaseBuildProducts, queryData)
        .then(response => response.data)
        .catch(error => error);
};

async function addUpdateReleaseFile(buildFile) {
    // This function can be changed or removed, so far we have no endpoints for the build file
    const token = localStorage.getItem('access_token');
    return apiCall({
        url: endpoints.softwareRelease.addUpdateSoftwareReleaseBuild,
        method: 'post',
        data: buildFile,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => response.data)
        .catch(error => error)
};

async function getNotificationsType() {
    return axios.post(endpoints.softwareRelease.getNotificationsType)
        .then(response => response.data)
        .catch(error => error);
};

async function addUpdateSoftwareReleaseBuildNotification(notificationData) {
    return axios.post(endpoints.softwareRelease.addUpdateSoftwareReleaseBuildNotification, notificationData)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleaseBuildNotification(releaseId) {
    return axios.post(`${endpoints.softwareRelease.getSoftwareReleaseBuildNotification}/${releaseId}`)
        .then(response => response.data)
        .catch(error => error);
};

async function addSoftwareReleaseBuildDetails(selectedData) {
    return axios.post(endpoints.softwareRelease.addSoftwareReleaseBuildDetails, selectedData)
        .then(response => response.data)
        .catch(error => error);
};

async function getSoftwareReleaseBuildDetails(releaseId) {
    return axios
        .post(
            `${endpoints.softwareRelease.getSoftwareReleaseBuildDetails}/${releaseId}`
        )
        .then((response) => response.data)
        .catch((error) => error);
};

async function getAllSoftwareReleases(queryData) {
    return axios
        .post(endpoints.softwareRelease.getAllSoftwareReleasesBuild, queryData)
        .then((response) => response.data)
        .catch((error) => error);
};

async function deleteSoftwareReleaseBuild(releaseId) {
    return axios
        .post(`${endpoints.softwareRelease.deleteSoftwareReleaseBuild}/${releaseId}`)
        .then((response) => response.data)
        .catch((error) => error);
};

async function markUnMarkSoftwareReleaseBuildFavourite(queryData) {
    return axios
        .post(endpoints.softwareRelease.markUnMarkSoftwareReleaseBuildFavourite, queryData)
        .then((response) => response.data)
        .catch((error) => error);
};

async function deleteSoftwareReleaseBuildNotifiacation(notificationId) {
    return axios
        .post(`${endpoints.softwareRelease.deleteSoftwareReleaseBuildNotifiacation}/${notificationId}`)
        .then((response) => response?.data)
        .catch((error) => error);
};

export const softwareBuildApi = {
    addUpdateBuild,
    addUpdateSoftwareReleaseBuildProducts,
    addUpdateSRBuildPortalandContacts,
    getSoftwareBuildReleaseById,
    getReleaseCompatibleProducts,
    getSoftwareContactsReleaseBuildById,
    getSoftwareReleaseBuildPortalById,
    getSoftwareReleaseBuildProducts,
    addUpdateReleaseFile,
    addUpdateSoftwareReleaseBuildNotification,
    getNotificationsType,
    getSoftwareReleaseBuildNotification,
    addSoftwareReleaseBuildDetails,
    getSoftwareReleaseBuildDetails,
    getAllSoftwareReleases,
    deleteSoftwareReleaseBuild,
    markUnMarkSoftwareReleaseBuildFavourite,
    deleteSoftwareReleaseBuildNotifiacation,
};