import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../components/common/InputField";
import EmptyTextarea from "../../../../components/common/Textarea";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import GeneralHeading from "../../../../components/common/GeneralHeading";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { EventsCategoryVisibilityGroup } from "./EventsCategoryVisibilityGroup";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/common/Loader";
import { useHistory } from "react-router-dom";
import { CategoryType } from "../../../../components/common";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
  },
  eventCatTextarea: {},
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

export default function CategoryOveriew() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [categoryNameInternal, setCategoryNameInternal] = useState("");
  const [categoryNamePublic, setCategoryNamePublic] = useState("");
  const [categoryURLSlug, setCategoryURLSlug] = useState("");
  const [categoryShortDescription, setCategoryShortDescription] = useState("");
  const [categoryColor, setCategoryColor] = useState("");
  const [eventHeading, setEventHeading] = useState("New Event Category");
  const [eventEditCatData, setEventCatEditData] = useState(null);
  const [newsRoomVisibilityId, selectCategoryNewsRoomVisibility] = useState("");
  const [releaseVisibilityGroups, setReleaseVisibilityGroups] = useState([]);
  const [preReleaseVisibilityGroups, setPreReleaseVisibilityGroups] = useState(
    []
  );
  const [visibilityGroups, setVisibilityGroups] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [eventCategoriesDD, setEventCategoriesDD] = useState([]);
  const [parentCategoryId, setParentCategoryId] = useState(0);

  const eventId = parseInt(window.location.href.split("?id=")[1]);

  const loadGroups = useCallback(() => {
    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      setVisibilityGroups(data?.data);
    });
  }, [dispatch]);
  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  useEffect(() => {
    if (
      visibilityGroups &&
      visibilityGroups.length > 0 &&
      eventEditCatData?.groupId?.length > 0
    ) {
      setReleaseVisibilityGroups(
        eventEditCatData.groupId.map(x => {
          return {
            name: visibilityGroups.find(y => y.id == x)?.value,
            VisibilityGroupId: x,
          };
        })
      );
    }
  }, [visibilityGroups, eventEditCatData]);

  useEffect(() => {
    if (eventId) {
      setLoading(true);
      dispatch(launchesActions.onGetEventCatById(eventId)).then(res => {
        if (res && res.success && res.data) {
          setEventCatEditData(res.data);
          setCategoryNameInternal(res.data.name);
          setCategoryNamePublic(res.data.publicName);
          setCategoryURLSlug(res.data.urlSlug);
          setParentCategoryId(res.data?.parentId);
          setCategoryShortDescription(res.data.shortDescription);
          setCategoryColor(res.data.color);
          setEventHeading(res.data.name);
          selectCategoryNewsRoomVisibility(res.data.newsroomId);
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(launchesActions.onGetReleaseNewsRoomVisibilities()).then(res => {
      if (res && res.success && res.data && res.data.length > 0) {
        if (eventId) {
        } else {
          selectCategoryNewsRoomVisibility(res.data[0].id);
        }
      }
    });
  }, [dispatch, eventId]);

  useEffect(() => {
    dispatch(launchesActions.onGetEventsCategory()).then(res => {
      if (res?.success && res.data?.length >= 0) {
        setEventCategoriesDD(res.data);
      }
    });
  }, [dispatch]);

  const onSaveCategory = () => {
    if (
      categoryNameInternal !== "" &&
      categoryURLSlug.length < 200 &&
      categoryNamePublic.length < 50 &&
      categoryNameInternal.length < 50 &&
      categoryShortDescription.length < 1000
    ) {
      var groupId = [];
      releaseVisibilityGroups.map(x => {
        groupId.push(x.VisibilityGroupId);
      });
      const modal = {
        id: 0,
        name: categoryNameInternal,
        publicName: categoryNamePublic,
        urlSlug: categoryURLSlug,
        shortDescription: categoryShortDescription,
        color: categoryColor,
        groupId: groupId,
        newsroomId: newsRoomVisibilityId,
        isDeleted: false,
        parentId: parentCategoryId,
      };
      if (eventId) {
        modal.id = eventId;
      }
      setLoading(true);
      setErrorMsg(false);
      dispatch(launchesActions.onAddUpdateEventCategory(modal)).then(data => {
        if (data.success) {
          if (eventId) {
            toast.success("Event category updated");
          } else toast.success("Event category added");
          history.push("/event-categories");
        } else if (data?.message) {
          toast.warning(data?.message);
        }
        setLoading(false);
      });
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <GeneralHeading
        headingName={eventHeading}
        buttonText={eventId ? "Update Category" : "Save Category"}
        onClick={() => {
          onSaveCategory();
        }}
      />
      <div className='eventOverviewWrapper'>
        <AccordionContainer title='Category Overview'>
          <Grid container spacing={3}>
            <Grid item xs={11} className={classes.gridWrapper}>
              <InputField
                placeholder='Enter a Category Name (Internal)'
                inputlabel='Category Name (Internal)'
                value={categoryNameInternal}
                onChange={v => setCategoryNameInternal(v.target.value)}
              />
              {errorMsg && categoryNameInternal === "" && (
                <span style={{ color: "red" }}>
                  Please enter category name (Internal)
                </span>
              )}
              {categoryNameInternal.length > 50 && (
                <span style={{ color: "red" }}>
                  Maximum length is 50 characters
                </span>
              )}
            </Grid>

            <Grid item xs={11} className={classes.gridWrapper}>
              <InputField
                placeholder='Enter a Category Name (Public)'
                inputlabel='Category Name (Public)'
                value={categoryNamePublic}
                onChange={v => setCategoryNamePublic(v.target.value)}
              />
              {errorMsg && categoryNamePublic === "" && (
                <span style={{ color: "red" }}>
                  Please enter category name (Public)
                </span>
              )}
              {categoryNamePublic.length > 50 && (
                <span style={{ color: "red" }}>
                  Maximum length is 50 characters
                </span>
              )}
            </Grid>
            {/* 
          <Grid item xs={11} className={classes.gridWrapper}>
            <label className="slugLabel">Category URL Slug</label>
            <Grid container spacing={4} className="slugWrapper">
            <span style={{ marginLeft: "10px" }}>
                newsroom.aquaticav.com/events/
              </span>
              <span
                       className="eventCatUrlSlug"
                       style={{ flex: "1", marginLeft: "10px" }}>
                <InputField
                inputlabel="Category URL Slug"
                  placeholder="source-units"
                  value={categoryURLSlug}
                  onChange={v => setCategoryURLSlug(v.target.value)}
                />
              </span>
            </Grid>
          </Grid> */}

            <Grid item xs={11} className={classes.gridWrapper}>
              <label className='slugLabel'>Category URL</label>
              <Grid container spacing={2} xs={12} className='slugWrapper'>
                <span style={{ marginLeft: "10px" }}>
                  newsroom.aquaticav.com/events/
                </span>
                <span
                  className='eventCatUrlSlug'
                  style={{ flex: "1", marginLeft: "10px" }}>
                  <InputField
                    inputlabel='Category URL'
                    placeholder='Enter a URL Slug'
                    // validators={["required"]}
                    // errorMessages={["Please enter event title"]}
                    value={categoryURLSlug}
                    onChange={v =>
                      setCategoryURLSlug(v.target?.value?.replace(/\s+/g, ""))
                    }
                  />
                  {categoryURLSlug.length > 200 && (
                    <span style={{ color: "red" }}>
                      Maximum length is 200 characters
                    </span>
                  )}
                </span>
              </Grid>
            </Grid>

            <Grid
              item
              xs={11}
              className={[classes.gridWrapper, "eventCatTextarea"]}>
              <EmptyTextarea
                textLabel='Short Description'
                placeholder='Enter description here'
                value={categoryShortDescription}
                onChange={v => setCategoryShortDescription(v.target.value)}
              />
              {categoryShortDescription.length > 1000 && (
                <span style={{ color: "red" }}>
                  Maximum length is 1000 characters
                </span>
              )}
            </Grid>

            <Grid
              item
              xs={11}
              className={[classes.gridWrapper, "eventCatTextarea"]}>
              <Grid container spacing={4} className='slugWrapper'>
                <Grid container item xs={6}>
                  <CategoryType
                    disabled={eventEditCatData?.childCount > 0}
                    parentCategories={eventCategoriesDD?.filter(
                      d => d.id !== eventId
                    )}
                    selectedParentCategoryId={parentCategoryId}
                    setParentCategoryId={setParentCategoryId}
                  />
                </Grid>
                <Grid container item xs={6} style={{ alignItems: "center" }}>
                  <Grid item xs={2} className={classes.gridWrapper}>
                    <label className='slugLabel' style={{ marginBottom: "0" }}>
                      Category color
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    className={[classes.gridWrapper, "slugWrapper"]}>
                    <ul>
                      <li>
                        <button
                          className={
                            categoryColor === "#015dfe" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#015dfe");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#00d0ff" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#00d0ff");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#f9b900" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#f9b900");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#ff0092" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#ff0092");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#49b8ef" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#49b8ef");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#eb5757" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#eb5757");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#66de64" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#66de64");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#0a42e9" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#0a42e9");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#ef64b3" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#ef64b3");
                          }}
                        />
                        <button
                          className={
                            categoryColor === "#236681" ? "colorActive" : ""
                          }
                          onClick={() => {
                            setCategoryColor("#236681");
                          }}
                        />
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionContainer>
      </div>

      <div className='contentBody accordianBody'>
        <EventsCategoryVisibilityGroup
          newsRoomVisibilityId={newsRoomVisibilityId}
          releaseVisibilityGroups={releaseVisibilityGroups}
          onNewsRoomVisibilityIdSelect={NewsRoomVisibilityId =>
            selectCategoryNewsRoomVisibility(NewsRoomVisibilityId)
          }
          onGroupSelection={groupList => {
            setReleaseVisibilityGroups(groupList);
          }}
          preReleaseVisibilityGroups={preReleaseVisibilityGroups}
          setPreReleaseVisibilityGroups={setPreReleaseVisibilityGroups}
        />
      </div>
    </>
  );
}
