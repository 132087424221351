import React, { useEffect } from "react";
import { Grid, IconButton, InputAdornment } from "@material-ui/core";
import { BsArrowRight } from "react-icons/bs";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { calalderIcon } from "../../../../resources/images";
import { ArrowsIcon } from "../../LeftNav/SvgIcons";
import { Calendar } from "../../../../ofishloggedin/views";
import { CalenderIcon, SoftwareCalendarIcon } from "../SpaProduct/SvgIcons";

const FilterCreationDate = ({
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  error = {
    startDate: "",
    endDate: "",
  },
  setError = () => {},
}) => {
  useEffect(() => {
    if (StartDate && error.startDate) {
      setError({
        ...error,
        startDate: "",
      });
    }
  }, [StartDate, error, error.startDate, setError]);

  useEffect(() => {
    if (EndDate && error.endDate) {
      setError({
        ...error,
        endDate: "",
      });
    }
  }, [EndDate, error, error.endDate, setError]);

  const handleEndDateChange = (date) => {
    if (StartDate && date < StartDate) {
      setError({ ...error, endDate: 'End Date cannot be before Start Date' });
      return;
    }

    setEndDate(date);
    setError({ ...error, endDate: '' });
  };

  return (
    <div className='userDetailsSearchField filterDatePicker'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <span>
              <DatePicker
                helperText={error.startDate}
                error={error.startDate ? true : false}
                autoOk
                clearable
                format='MM/dd/yyyy'
                value={StartDate}
                className='inputWrapper filterDetePickerInner'
                placeholder='Start Date'
                maxDate={ EndDate ? EndDate : new Date()}
                // minDate={}
                onChange={val => setStartDate(val)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton>
                        <SoftwareCalendarIcon fill="#828282"/>
                      </IconButton>
                    </InputAdornment>)
                }}
              />
            </span>
          </Grid>
          <span className='filterArrow'>
            <ul>
              <li>
                <BsArrowRight />
              </li>
              <li>
                <BsArrowRight />
              </li>
            </ul>
          </span>
          <Grid item xs={6}>
            <span>
              <DatePicker
                helperText={error.endDate}
                error={error.endDate ? true : false}
                autoOk
                clearable
                // minDate={ StartDate ? StartDate : null}
                format='MM/dd/yyyy'
                value={EndDate}
                className='inputWrapper filterDetePickerInner'
                placeholder='End Date'
                onChange={handleEndDateChange}
                maxDate={new Date()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton>
                        <SoftwareCalendarIcon fill="#828282"/>
                      </IconButton>
                    </InputAdornment>)
                }}
              />
              {/* <img src={calalderIcon} alt='' style={{ top: "6px" }} /> */}
            </span>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export { FilterCreationDate };

export const FilterCreationDateNew = ({
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  error = {
    startDate: "",
    endDate: "",
  },
  setError = () => {},
}) => {
  useEffect(() => {
    if (StartDate && error.startDate) {
      setError({
        ...error,
        startDate: "",
      });
    }
  }, [StartDate, error, error.startDate, setError]);

  useEffect(() => {
    if (EndDate && error.endDate) {
      setError({
        ...error,
        endDate: "",
      });
    }
  }, [EndDate, error, error.endDate, setError]);

  return (
    <div className='filterDatePickerIcon'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container style={{ flexDirection: "column", border:"1px solid rgba(0, 0, 0, 0.23)", borderRadius:"6px"}}>
          <Grid item style={{paddingLeft:"10px"}}>
            <span>
              <DatePicker
                helperText={error.startDate}
                error={error.startDate ? true : false}
                autoOk
                clearable
                format='MM/dd/yyyy'
                value={StartDate}
                className='filterDetePickerInner'
                placeholder='Select Start Date'
                onChange={val => setStartDate(val)}
              />
              <img src={calalderIcon} alt='' style={{ top: "14px" }} />
            </span>
            <ArrowsIcon style={{color: "#3699FF"}}/>
          </Grid>
          <Grid item style={{ display:"flex", paddingLeft:"15px"}}>
            <p style={{color:"#3699FF", fontSize:"14px", fontWeight:"700"}}>to</p>
            <span style={{paddingLeft:"5px"}}>
              <DatePicker
                helperText={error.endDate}
                error={error.endDate ? true : false}
                autoOk
                clearable
                minDate={StartDate || null}
                format='MM/dd/yyyy'
                value={EndDate}
                className='filterDetePickerInner'
                placeholder='Select End Date'
                onChange={val => setEndDate(val)}
              />
              <img src={calalderIcon} alt='' style={{ top: "14px" }} />
            </span>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};
