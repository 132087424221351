import React from "react";
import { Typography } from "@material-ui/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function WaterLifeReport({ title, data, dates }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top"
      },
      title: {
        display: false,
        text: "Water Report"
      }
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0) "
        },
        title: {
          display: true,
          text: "Date Used"
        }
      },
      y: {
        grid: {
          color: "rgba(45, 156, 219, 1)",
          borderDash: [3, 3]
        },
        title: {
          display: true,
          text: "Minutes Used"
        }
      }
    }
  };
  return (
    <div className="reportContent">
      <Typography className="reportTitle">{title}</Typography>
      <span className="reportDateFilters">
        {dates.from} - {dates.to}
      </span>
      <div className="chartContent">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}
