import React, { useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Button } from "@material-ui/core";
import { EmptyTextArea } from "../../../../components/common";

function ConvTemplateContent({
  addTemplate,
  setAddTemplate,
  clickback,
  onAdd,
}) {
  return (
    <div className='convTemplateContentWrapper'>
      <h3 className='convHeading '>
        <span onClick={clickback}>
          <ArrowBackIosIcon /> Back to Templates{" "}
        </span>
      </h3>
      <span className='templateTitle'>
        Title
        <input
          type='text'
          value={addTemplate?.title}
          onChange={x => {
            setAddTemplate({
              ...addTemplate,
              title: x.target.value,
            });
          }}
        />
      </span>

      <span className='templateBody'>
        <h2>Template Content</h2>
        <span>
          {/* <input
            type='text'
            value={addTemplate?.content}
            onChange={x => {
              setAddTemplate({
                ...addTemplate,
                content: x.target.value,
              });
            }}
          /> */}
          <textarea 
           className='templateList'
            value={addTemplate?.content}
            onChange={x => {
              setAddTemplate({
                ...addTemplate,
                content: x.target.value,
              });
            }}
          />
        </span>
      </span>

      <footer>
        <Button variant='outlined' onClick={clickback}>
          {" "}
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            onAdd(addTemplate);
          }}>
          Select
        </Button>
      </footer>
    </div>
  );
}

export default ConvTemplateContent;
