import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@material-ui/core";
import ReleaseContent from "./ReleaseContent";
import DateFnsUtils from "@date-io/date-fns";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../components/common/InputField";
import EmptyTextarea from "../../../../components/common/Textarea";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import debounce from "lodash.debounce";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  Dropdown,
  ImageUploader,
  LaunchState,
} from "../../../../components/common";
import AutoComplete from "../../../../components/common/AutoComplete";
import { CollectionVisibilityGroup } from "./CollectionVisibilityGroup";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import CustomCheckbox from "../../../../components/common/checkbox/CustomCheckbox";
import { ValidatorForm } from "react-material-ui-form-validator";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import {
  MultiSelectReleaseCategories,
  ReleaseCategoryChip,
} from "../ReleasesViews/MultiSelectReleaseCategories";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // border: '1px solid #f1f1f1',
    marginBottom: "12px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  setLaunched: {
    backgroundColor: "#00ABA3",
  },
  dateLabel: {
    fontSize: "15px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
    minHeight: "19px",
    display: "inline-block",
  },
  viewReleaseWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
  },
}));

const NewCollectionForm = props => {
  const { activeCollectionId , existinglaunch } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);

  const [isPlanning, setIsPlanning] = useState(null);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [uRLSlug, setURLSlug] = useState("");
  const [description, setDescription] = useState("");
  const [useLaunchDate, setUseLaunchDate] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState(new Date());
  const [collectionCategory, setCollectionCategory] = useState([]);
  // const [selectedCategoryId, setSelectedCategory] = useState("1");
  const [relatedEvent, setRelatedEvent] = useState([]);
  const [selectedRelatedEvent, setSelectedRelatedEvent] = useState("1");
  const [collectionLocation, setCollectionLocation] = useState([]);
  const [collectionLocationValue, setCollectionLocationValue] = useState("");
  const [newsRoomVisibilityId, selectReleaseNewsRoomVisibility] = useState("");
  const [releaseVisibilityGroups, setReleaseVisibilityGroups] = useState([]);
  const [preReleaseVisibilityGroups, setPreReleaseVisibilityGroups] = useState(
    []
  );

  const [isAddReleaseCategory, setIsAddReleaseCategory] = useState(false);
  const [slectedCategories, setSlectedCategories] = useState([]);
  const [mainCategory, setMainCategory] = useState(null);

  const [errorMsg, setErrorMsg] = useState(false);

  const [assetCollection, setAssetCollection] = useState([]);
  const [selectedLaunch, setSelectedLaunch] = useState("");
  const [collectionHeading, setCollectionHeading] = useState("New Collection");
  const [featureFiles, setFeatureFiles] = useState([]);
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [showBannerImage, setShowBannerImage] = useState(false);
  const [featureImgUpload, setFeatureImgUpload] = useState(null);
  const [bannerImgUpload, setBannerImgUpload] = useState(null);

  const [showAssetImage, setShowAssetImage] = useState(false);
  const [collectionEditData, setCollectionEditData] = useState([]);
  const [visibilityGroups, setVisibilityGroups] = useState([]);
  const { launches } = useSelector(state => state.LaunchesReducer);
  const { assetCollectionArray } = useSelector(state => state.AssetsReducer);
  const [checkedAnnounceDated, setCheckedAnnounceDated] = useState(null)

  useEffect(() => {
    setLoading(true);
    dispatch(assetsActions.onGetCollectionCategoryDropdown()).then(data => {
      if (data && data.success && data.data) {
        setCollectionCategory(data.data);
        // if (!activeCollectionId) {
        //   setSelectedCategory(data.data[0].id);
        // }
      }
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(launchesActions.onGetEventDropDown()).then(data => {
      if (data && data.success && data.data) {
        setRelatedEvent(data.data);
        if (!activeCollectionId) {
          setSelectedRelatedEvent(data.data[0].id);
        }
      }
      setLoading(false);
    });
  }, []);

  const changeHandler = event => {
    setLoading(true);
    dispatch(
      launchesActions.onGetLocationsAutoComplete({
        input: event.target.value,
      })
    ).then(res => {
      setCollectionLocation(res?.data || []);
      setLoading(false);
    });
  };

  const loadGroups = useCallback(() => {
    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      setVisibilityGroups(data?.data);
    });
  }, [dispatch]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  const uploadFeatureImg = value => {
    setLoading(true);
    let assetCollection = [];
    assetCollection = assetCollectionArray;
    dispatch(launchesActions.uploadImage(value)).then(data => {
      if (data && data.success && data.data) {
        assetCollection.push(data.data);
        dispatch(
          assetsActions.onChange("assetCollectionArray", assetCollection)
        );
        // setAssetCollectionArray(assetCollection);
        let finalArray = [];
        assetCollection.forEach(element => {
          const res = { assetId: element.id };
          finalArray.push(res);
          setAssetCollection(finalArray);
        });
        setShowFeatureImage(false);
      }
      setLoading(false);
    });
  };

  const checkedFeaturImage = value => {
    let assetCollection = [];
    assetCollection = assetCollectionArray;
    const assetExist = assetCollectionArray.find(asset => {
      return asset.id === value.id;
    });
    if (assetExist) {
      setShowFeatureImage(false);
    } else {
      assetCollection.push(value);
      dispatch(assetsActions.onChange("assetCollectionArray", assetCollection));
      // setAssetCollectionArray(assetCollection);
      let finalArray = [];
      assetCollection.forEach(element => {
        const res = { assetId: element.id };
        finalArray.push(res);
        setAssetCollection(finalArray);
      });
      setShowFeatureImage(false);
    }
  };

  const getFeatureFiles = () => {
    setLoading(true);
    dispatch(launchesActions.onGetFeatureFiles()).then(data => {
      if (data && data.success && data.data) {
        setFeatureFiles(data.data);
      }
      setShowAssetImage(!showAssetImage);
      setLoading(false);
    });
  };

  useEffect(() => {
    dispatch(launchesActions.onGetDropDownLaunches()).then(data => {
      if (data && data.success && data.data && data.data.length > 0) {
        setSelectedLaunch(data.data[0].id);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    dispatch(assetsActions.onChange("assetCollectionArray", []));
    dispatch(assetsActions.onGetCollectionVisibility()).then(data => {
      setLoading(false);
    });
    // loadData(false);
  }, []);

  const removeCollection = item => {
    let collectionArray = [];
    collectionArray = assetCollectionArray;
    let finalArray = [];
    finalArray = assetCollection;
    const colExist = collectionArray.find(ast => {
      return ast.id === item.id;
    });
    if (colExist && collectionArray.length > 0) {
      _.remove(collectionArray, col => {
        return col.id === item.id;
      });
    }
    const astExist = finalArray.find(ast => {
      return ast.assetId === item.id;
    });
    if (astExist && finalArray.length > 0) {
      _.remove(finalArray, final => {
        return final.assetId === item.id;
      });
    }
    setAssetCollection(finalArray);
    dispatch(assetsActions.onChange("assetCollectionArray", collectionArray));
  };

  useEffect(() => {
    if (
      visibilityGroups &&
      visibilityGroups.length > 0 &&
      collectionEditData?.groupIds?.length > 0
    ) {
      setReleaseVisibilityGroups(
        collectionEditData.groupIds.map(x => {
          return {
            name: visibilityGroups.find(y => y.id === x.toString())?.value,
            VisibilityGroupId: x,
          };
        })
      );
    }
    if (
      visibilityGroups &&
      visibilityGroups.length > 0 &&
      collectionEditData?.preReleaseCollections?.length > 0
    ) {
      setPreReleaseVisibilityGroups(
        collectionEditData.preReleaseCollections.map(x => {
          var groups = [];
          x.groupIds.map(y =>
            groups.push({
              name: visibilityGroups.find(z => z.id === y.toString())?.value,
              VisibilityGroupId: y,
            })
          );
          return {
            groupList: groups,
            preReleaseDate: new Date(x.preReleaseDate),
          };
        })
      );
    }
  }, [visibilityGroups, collectionEditData]);

  useEffect(() => {
    if (launches && launches.length > 0 && collectionEditData?.launchId) {
      setSelectedLaunch(collectionEditData.launchId);
    }
  }, [launches, collectionEditData]);

  useEffect(() => {
    if (activeCollectionId) {
      setLoading(true);
      dispatch(
        assetsActions.onGetAssertCollectionById(activeCollectionId)
      ).then(res => {
        if (res && res.success && res.data) {
          setCollectionEditData(res.data);
          setTitle(res.data.title);
          setSubTitle(res.data.subTitle);
          setURLSlug(res.data.urlSlug);
          setSlectedCategories(res.data?.categoryIds?.map(r => r?.categoryId));
          setMainCategory(
            res.data?.categoryIds?.find(r => r?.isMain === true)?.categoryId
          );
          setDescription(res.data.description);
          setEventDate(new Date(res.data.visibilityDate));
          setEventTime(new Date(res.data.visibilityDate));
          setFeatureImgUpload(res.data?.imageURL);
          setBannerImgUpload(res.data?.bannerImageURL);
          setIsPlanning(res.data.state);
          setUseLaunchDate(res.data.useLaunchDate);
          setCollectionHeading(res.data.title);
          setCollectionLocationValue({
            id: res.data.location?.id,
            value: res.data.location?.value,
          });

          dispatch(
            assetsActions.onChange("assetCollectionArray", res.data.assets)
          );
          var assertId = [];
          res.data.assets.map(x => assertId.push({ assetId: x.id }));
          setAssetCollection(assertId);
          props.onCollectionName && props.onCollectionName(res.data.title);
        }
        setLoading(false);
      });
    } else {
      setTitle("");
      setSubTitle("");
      setURLSlug("");
      setDescription("");
      setEventDate(new Date());
      setEventTime(new Date());
      setIsPlanning(null);
      setUseLaunchDate(false);
      setCollectionLocationValue("");
      setCollectionLocation([]);
      setAssetCollection([]);
      setReleaseVisibilityGroups([]);
      setPreReleaseVisibilityGroups([]);
      setSelectedRelatedEvent("1");
      // setSelectedCategory("1");
      dispatch(assetsActions.onChange("assetCollectionArray", []));
    }
  }, [activeCollectionId]);

  // useEffect(() => {
  //   if (collectionEditData?.categoryId) {
  //     setSelectedCategory(collectionEditData?.categoryId.toString());
  //   }
  // }, [collectionEditData]);

  useEffect(() => {
    if (
      relatedEvent &&
      relatedEvent.length > 0 &&
      collectionEditData?.eventId
    ) {
      setSelectedRelatedEvent(collectionEditData.eventId.toString());
    }
  }, [relatedEvent, collectionEditData]);

  const handleSubmit = e => {
    e.preventDefault();
    createCollection();
  };

  const createCollection = () => {
    if (
      title !== "" &&
      collectionLocationValue !== "" &&
      // selectedCategoryId !== -1 &&
      selectedRelatedEvent !== -1 &&
      description.length < 1000
    ) {
      if (!featureImgUpload) {
        toast.error("Feature image is required");
        return;
      }
      if (slectedCategories?.length === 0 || !mainCategory) {
        toast.error("Main collection category is required");
        return;
      }
      setLoading(true);
      const categoryIds = slectedCategories?.map(sCid => {
        return {
          categoryId: parseInt(sCid),
          isMain: sCid === mainCategory,
        };
      });

      var groupId = [];
      releaseVisibilityGroups.map(x => {
        groupId.push(parseInt(x.VisibilityGroupId));
      });
      var preReleaseData = [];
      preReleaseVisibilityGroups.map(x => {
        var groupId = [];
        x.groupList.map(y => groupId.push(parseInt(y.VisibilityGroupId)));
        preReleaseData.push({
          groupIds: groupId,
          preReleaseDate: x.preReleaseDate,
        });
      });
      let evtDate = new Date(eventDate);
      const startTargetTime = new Date(eventTime);
      evtDate.setHours(startTargetTime.getHours());
      evtDate.setMinutes(startTargetTime.getMinutes());
      var assertId = [];
      assetCollection.map(x => assertId.push(x.assetId));

      const collection = {
        id: 0,
        title: title,
        subtitle: subTitle,
        urlSlug: uRLSlug,
        description: description,
        // categoryId: parseInt(selectedCategoryId),
        categoryIds: categoryIds,
        launchId: parseInt(selectedLaunch),
        visibilityDate: evtDate,
        useLaunchDate: useLaunchDate,
        locationId: collectionLocationValue?.id
          ? parseInt(collectionLocationValue?.id)
          : 0,
        eventId: parseInt(selectedRelatedEvent),
        imageUrl: featureImgUpload?.url || featureImgUpload,
        bannerImageUrl: bannerImgUpload?.url || bannerImgUpload,
        groupIds: groupId,
        preReleaseData: preReleaseData,
        assetIds: assertId,
        state:  isPlanning,
      };
      if (activeCollectionId) {
        collection.id = activeCollectionId;
      }
      dispatch(assetsActions.onAddUpdateCollections(collection)).then(data => {
        if (data && data.success) {
          if (window.location.pathname === "/launch") {
            if (activeCollectionId) {
              toast.success("Collection Updated");
              history.goBack();
            } else {
              toast.success("Collection Added");
              history.goBack();
            }
          } else {
            if (activeCollectionId) {
              toast.success("Collection updated");
            } else toast.success("Collection added");
            history.goBack();
          }
        } else if (data?.message) {
          toast.warning(data?.message);
        }
        setLoading(false);
      });
    } else {
      setErrorMsg(true);
    }
  };

  useEffect(() => {
    if (useLaunchDate) {
      const relatedLlaunchDate = launches?.find(
        l => l.id === selectedLaunch
      )?.generic;
      if (relatedLlaunchDate) {
        setEventDate(relatedLlaunchDate);
        setEventTime(relatedLlaunchDate);
      }
    } else {
      // handleAnnounceDate(addDays(new Date(), 15));
      // handleAnnounceDate(new Date());
    }
  }, [launches, selectedLaunch, useLaunchDate]);

  useEffect(() => {
    if (useLaunchDate) {
      let entDate = new Date(existinglaunch?.launchDate);
      setCheckedAnnounceDated(entDate)
    } else {
      let entDate = new Date(eventDate);
      const eventTargetTime = new Date(eventTime);
      entDate.setHours(eventTargetTime.getHours());
      entDate.setMinutes(eventTargetTime.getMinutes());        
      setCheckedAnnounceDated(new Date(entDate))
    }
  }, [useLaunchDate, eventDate])

  return (
    <div>
      {isLoading ? <Loader /> : null}
      {/* <div className="contentBody accordianBody"> */}
      <ValidatorForm onSubmit={handleSubmit}>
        <AccordionContainer
          title='Collection Overview'
          // className='CollectionOverview'
          formButton={
            <div className='dashboardNav'>
              <LaunchState
                value={isPlanning === 2 ? 1 : isPlanning === 1 ? 0 : 2}
                onChange={status => {
                  setIsPlanning( status === 1 ? 2 : 1);
                }}
              />
              <Button
                color='primary'
                variant='contained'
                className='dashboardButtons'
                type='submit'>
                Save Collection
              </Button>
            </div>
          }>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridWrapper}>
              <InputField
                showRequired={true}
                placeholder='Enter A Collection Title'
                inputlabel='Collection Title'
                id='title'
                name='title'
                value={title}
                onChange={v => setTitle(v.target.value)}
                validators={["required"]}
                errorMessages={["Please enter collection title"]}
              />
              {errorMsg && title === "" && (
                <span className='errorMessage'>
                  Please enter collection title
                </span>
              )}
            </Grid>

            <Grid item xs={12} className={classes.gridWrapper}>
              <InputField
                showRequired={true}
                placeholder='Enter A Collection Subtitle'
                inputlabel='Collection Subtitle'
                id='subTitle'
                name='subTitle'
                value={subTitle}
                onChange={v => setSubTitle(v.target.value)}
                validators={["required"]}
                errorMessages={["Please enter collection subtitle"]}
              />
              {errorMsg && subTitle === "" && (
                <span className='errorMessage'>
                  Please enter collection title
                </span>
              )}
            </Grid>
            <Grid item xs={12} className={classes.gridWrapper}>
              <label className='slugLabel'>
                Collection URL Slug <i className={"showRequired"}></i>
              </label>
              <Grid container spacing={2} xs={12} className='slugWrapper'>
                <span style={{ marginLeft: "10px" }}>
                  newsroom.aquaticav.com/media-library/category/
                </span>
                <span
                  className='eventCatUrlSlug'
                  style={{ flex: "1", marginLeft: "10px" }}>
                  <InputField
                    inputlabel='Event URL'
                    placeholder='Enter a URL Slug'
                    validators={["required", "matchRegexp:^.{0,200}$"]}
                    errorMessages={[
                      "Please enter event url",
                      "Maximum length is 200 characters",
                    ]}
                    value={uRLSlug}
                    onChange={v =>
                      setURLSlug(v.target?.value?.replace(/\s+/g, ""))
                    }
                  />
                </span>
              </Grid>
            </Grid>

            {/* left column */}
            <Grid item xs={12} className={classes.gridWrapper}>
              <EmptyTextarea
                required
                textLabel='Collection Description'
                textSubtitle=''
                value={description}
                onChange={v => setDescription(v.target.value)}
              />
              {description && description.length > 1000 && (
                <span className='errorMessage'>
                  Maximum length is 1000 characters
                </span>
              )}
            </Grid>
            <Grid item xs={12} className={classes.gridWrapper}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <span>
                    <label
                      className='slugLabel'
                      style={{ marginBottom: "7px" }}>
                      Collection Categories <i className={"showRequired"}></i>
                    </label>
                    <Button
                      color='primary'
                      variant='outlined'
                      startIcon={<Add />}
                      onClick={() => setIsAddReleaseCategory(true)}>
                      Add Category
                    </Button>
                  </span>
                  <span>
                    {slectedCategories?.map(s => (
                      <ReleaseCategoryChip
                        mainReleaseCategory={mainCategory}
                        setMainReleaseCategory={setMainCategory}
                        category={{
                          id: s,
                          value: collectionCategory.find(
                            r => `${r.id}` === `${s}`
                          )?.value,
                        }}
                        onRemove={cId => {
                          setSlectedCategories([
                            ...slectedCategories.filter(scId => scId !== cId),
                          ]);
                          if (mainCategory === cId) {
                            setMainCategory(null);
                          }
                        }}
                      />
                    ))}
                  </span>
                  <MultiSelectReleaseCategories
                    releaseCategories={collectionCategory}
                    isVisible={isAddReleaseCategory}
                    slectedReleaseCategories={slectedCategories}
                    onSelect={slectedCategories => {
                      setSlectedCategories(slectedCategories);
                      setIsAddReleaseCategory(false);
                    }}
                    onClose={() => setIsAddReleaseCategory(false)}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}>
                  <Grid item xs={4} className={classes.gridWrapper}>
                    <label className={classes.dateLabel}>
                      Collection Visibility Date
                    </label>
                    <div className='inputWrapper dateField'>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl className={classes.formControl}>
                          <DatePicker
                            disableToolbar
                            variant='inline'
                            value={useLaunchDate ?  existinglaunch?.launchDate : eventDate}
                            onChange={time => {
                              setEventDate(time);
                            }}
                            disabled={useLaunchDate}
                            autoOk
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                  <Grid item xs={3} className={classes.gridWrapper}>
                    <label className={classes.dateLabel}>{""}</label>
                    <div className='inputWrapper dateField'>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl className={classes.formControl}>
                          <TimePicker
                            value={useLaunchDate ?  existinglaunch?.launchDate : eventTime}
                            onChange={time => {
                              setEventTime(time);
                            }}
                            disabled={useLaunchDate}
                            autoOk
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.gridWrapper}>
                    <label className={classes.dateLabel}>{""}</label>
                    <span className='dateCheckbox'>
                      <CustomCheckbox
                        className={classes.checkBoxFont}
                        checkedId={"checked"}
                        pressCheck={() => {
                          setUseLaunchDate(!useLaunchDate);
                        }}
                        checkValue={useLaunchDate}
                      />
                      Use same date as Launch Date
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              item
              style={{
                marginTop: "12px",
              }}>
              <Grid item xs={4} className={classes.gridWrapper}>
                <AutoComplete
                  showRequired
                  label='New Release Location'
                  value={collectionLocationValue?.value}
                  onChange={e => {
                    debouncedChangeHandler(e);
                    setCollectionLocationValue(e.target.value);
                  }}
                  eventLocation={collectionLocation}
                  setEvent={e => {
                    setCollectionLocationValue(e);
                  }}
                />
                {errorMsg && collectionLocationValue === "" && (
                  <span className='errorMessage'>
                    Please enter new release location
                  </span>
                )}
              </Grid>
              <Grid item xs={4} className={classes.gridWrapper}>
                <div className='inputWrapper'>
                  <FormControl className={classes.formControl}>
                    <label>Related Launch</label>
                    <Select
                      value={selectedLaunch}
                      onChange={e => setSelectedLaunch(e.target.value)}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"outlined"}
                      required>
                      {launches &&
                        launches.length > 0 &&
                        launches.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>
                              {item.value}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {errorMsg && selectedLaunch === "" && (
                    <span className='errorMessage'>
                      Please enter related launch categories
                    </span>
                  )}
                </div>
              </Grid>
              <Grid item xs={4} className={classes.gridWrapper}>
                <Dropdown
                  selectlabel='Related Events'
                  value={selectedRelatedEvent}
                  handleChange={({ target }) =>
                    setSelectedRelatedEvent(target.value)
                  }
                  placeholder='Please Select'
                  customMenuItems={relatedEvent?.map(c => {
                    return {
                      id: c.id,
                      name: c.value,
                    };
                  })}
                />
                {errorMsg && selectedRelatedEvent === -1 && (
                  <span className='errorMessage'>
                    Please enter releated event
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <Grid
                item
                xs={6}
                className={classes.gridWrapper}
                style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "center"
                    }}>
                    <label
                      className='slugLabel'
                      style={{ marginBottom: "10px" }}>
                      Feature Image <i className={"showRequired"}></i>
                    </label>
                    <span>
                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.selectBtn}
                        onClick={() => setShowFeatureImage(!showFeatureImage)}>
                        Select
                      </Button>
                    </span>
                  </div>

                  <span
                    className='releaseViewAvatarWrapper'
                    style={{ marginLeft: "10px" }}>
                    {(featureImgUpload?.url || featureImgUpload) && (
                      <img
                        src={featureImgUpload?.url || featureImgUpload}
                        alt=''
                      />
                    )}
                  </span>
                </div>

                <ImageUploader
                  title={"Featured Image"}
                  showFeatureImage={showFeatureImage}
                  setShowFeatureImage={() =>
                    setShowFeatureImage(!showFeatureImage)
                  }
                  onImageSelect={img => {
                    setFeatureImgUpload(img);
                  }}
                  infoText="Images are supported only. (JPEG, JPG and PNG)"
                />
              </Grid>
              <Grid item xs={6} style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <label
                      className='slugLabel'
                      style={{ marginBottom: "10px" }}>
                      Banner Image
                    </label>
                    <span>
                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.selectBtn}
                        onClick={() => setShowBannerImage(!showBannerImage)}>
                        Select
                      </Button>
                    </span>
                  </div>

                  <span
                    className='releaseViewAvatarWrapper'
                    style={{ marginLeft: "10px" }}>
                    {(bannerImgUpload?.url || bannerImgUpload) && (
                      <img
                        src={bannerImgUpload?.url || bannerImgUpload}
                        alt=''
                      />
                    )}
                  </span>
                </div>

                <ImageUploader
                  title={"Banner Image"}
                  showFeatureImage={showBannerImage}
                  setShowFeatureImage={() => {
                    setShowBannerImage(!showBannerImage);
                  }}
                  onImageSelect={img => {
                    setBannerImgUpload(img);
                  }}
                  infoText="Images are supported only. (JPEG, JPG and PNG)"
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={6} className={classes.gridWrapper}>
              <Grid
                item
                xs={8}
                className={(classes.gridWrapper, "collectionCat")}>
                <Dropdown
                  style={{ marginTop: "12px" }}
                  selectlabel='Collection Categories'
                  value={selectedCategoryId}
                  handleChange={({ target }) => {
                    setSelectedCategory(target.value);
                    const groupIds = collectionCategory.find(
                      r => r.id === target.value
                    )?.generic;
                    if (groupIds?.length > 0) {
                      setReleaseVisibilityGroups(
                        groupIds.map(x => {
                          return {
                            name: visibilityGroups.find(
                              y => y.id === x.toString()
                            )?.value,
                            VisibilityGroupId: x
                          };
                        })
                      );
                    } else {
                      setReleaseVisibilityGroups([]);
                    }
                  }}
                  placeholder='Please Select'
                  customMenuItems={collectionCategory?.map(c => {
                    return {
                      id: c.id,
                      name: c.value
                    };
                  })}
                />
                {errorMsg && selectedCategoryId === -1 && (
                  <span style={{ color: "red" }}>
                    Please enter collection categories
                  </span>
                )}
              </Grid>
            </Grid> */}
          </Grid>
        </AccordionContainer>
      </ValidatorForm>
      {/* </div> */}
      <div className='contentBody accordianBody'>
        <CollectionVisibilityGroup
          newsRoomVisibilityId={newsRoomVisibilityId}
          releaseVisibilityGroups={releaseVisibilityGroups}
          onNewsRoomVisibilityIdSelect={NewsRoomVisibilityId => {
            selectReleaseNewsRoomVisibility(NewsRoomVisibilityId);
          }}
          onGroupSelection={groupList => {
            setReleaseVisibilityGroups(groupList);
          }}
          preReleaseVisibilityGroups={preReleaseVisibilityGroups}
          setPreReleaseVisibilityGroups={setPreReleaseVisibilityGroups}
          eventDate={checkedAnnounceDated}
        />
      </div>
      <div className='contentBody accordianBody'>
        <ReleaseContent
          uploadFeatureImg={value => uploadFeatureImg(value)}
          checkedFeaturImage={value => checkedFeaturImage(value)}
          getFeatureFiles={() => getFeatureFiles()}
          featureFiles={featureFiles}
          assetCollectionArray={assetCollectionArray}
          setShowFeatureImage={() => setShowAssetImage()}
          showFeatureImage={showAssetImage}
          removeCollection={value => removeCollection(value)}
        />
      </div>
    </div>
  );
};

export { NewCollectionForm };
