import React, { useState } from "react";
import OverViewCard from "./overViewCard";
import TempratureCard from "./tempratureCard";
import SpaQuickToolsCard from "./spaQuickToolsCard";
import SpaPumpCard from "./spaPumpCard";
import SpaWaterTreatment from "./spaWaterTreatment";
import Grid from "@material-ui/core/Grid";

function SpaProduct({ isActive, onClickMange, activeTab }) {
  return (
    <div className="deviceDeailsMainContent deviceOverDeailsContent">
      <div className="spaDeviceContentBody spaMainOverview cardsManagment ">
        <Grid className="cardMainContainer">
          <Grid className="cardContainer cardContentTop">
            <OverViewCard />
          </Grid>
          <Grid className="cardContainerTemperature cardContentCenter">
            <Grid className="tempratureCardContainer">
              <TempratureCard isActive={true} />
            </Grid>
            <Grid>
              <SpaQuickToolsCard />
            </Grid>
          </Grid>
          <Grid className="cardContainerTemperature cardContentBottom">
            <Grid>
              <SpaPumpCard />
            </Grid>
            <Grid className="tretmentCardContainer">
              <SpaWaterTreatment
                isActive={true}
                onClickMange={onClickMange}
                activeTab={activeTab}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SpaProduct;
