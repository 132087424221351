import React from 'react';

function CompanyUsers(props) {
    return (
        <div className="settingsTabsBody">
          <h1>Company users</h1>  
        </div>
    );
}

export default CompanyUsers;