import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {
  LogHeader,
  MainHeading,
} from "../../../components/common/Header/index";
import Overview from "./Overview";
import EventHeading from "./EventHeading";
import Loader from "../../../components/common/Loader";
import { pubEventActions } from "../../../store/pubEvent/pubEventActions";
import { accountActions } from "../../../store/accounts/accountActions";

function LogEventDetail(props) {
  document.title = "OFish - Events";

  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [eventDetail, setEventDetail] = useState(null);
  const [idEvent, setIdEvent] = useState(null);
  const { slug } = useParams();

  var eventId = parseInt(window.location.href.split("?id=")[1]);
  useEffect(() => {
    dispatch(accountActions.onAddUserSession(window.location.href));
    if (eventId) {
      setIdEvent(eventId);
      loadData();
    }
    if (slug) {
      loadData();
    }
  }, []);
  const loadData = () => {
    setLoading(true);

    dispatch(
      pubEventActions.onGetEventDetail(eventId ? eventId : 0, slug ? slug : "")
    ).then(data => {
      if (data?.data !== null && data.success) {
        setEventDetail(data.data);
      } else {
        //if (data) {
        toast.error("No Record Found");
        history.push("/");
        //}
      }
      setLoading(false);
    });
  };
  const redirect = () => {
    history.push("/events");
  };

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <LogHeader />
      <MainHeading
        headerSubHeading={eventDetail ? eventDetail.category : ""}
        icon={<KeyboardArrowLeftIcon fontSize='medium' />}
        backLink='Back to Events Home'
        backNavHandler={() => redirect()}>
        <div className='boxMainWrapper companyInfo'>
          <EventHeading eventDetail={eventDetail} />
          <Overview
            eventDetail={eventDetail}
            loadData={() => loadData(idEvent)}
            startLoading={() => setLoading(true)}
            stopLoading={() => setLoading(false)}
          />
        </div>
      </MainHeading>
    </div>
  );
}

export default LogEventDetail;
