import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { folder } from "../../../../resources/images/index";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import Loader from "../../../../components/common/Loader";
import _ from "lodash";
import { toast } from "react-toastify";
var moveDirectory = null;
const MoveMediaFilesPopup = ({
  closePopup,
  openPopup,
  startLoading,
  endLoading,
  loadData,
}) => {
  const dispatch = useDispatch();
  const {
    mediaAssetsFolder,
    selectedMediaAssets,
    gridData,
    currentFolder,
    breadCrumbs,
    previousFolder,
    parentId,
    type,
    search,
    time,
    isfavorite,
    pageSize,
    pageNo,
    orderByKey,
    orderByVal,
  } = useSelector((state) => state.AssetsReducer);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isRootDirectory, setRootDirectory] = useState(false);

  const getFolders = () => {
    setRootDirectory(false);
    setLoading(true);

    dispatch(
      assetsActions.onGetAssetManagementListForPopup(previousFolder, "folder")
    ).then((data) => {
      moveDirectory = previousFolder;
      if (previousFolder === 0) {
        setRootDirectory(true);
      }
      setLoading(false);
    });
  };

  const moveFiles = () => {
    var moveAssetStatus = true;
    var fileDestinationFoler = moveDirectory != null ? moveDirectory : 0;
    if (selectedFolder !== "" && selectedFolder.id !== undefined) {
      fileDestinationFoler = selectedFolder.id;
    }
    startLoading();
    _.forEach(selectedMediaAssets, function (assetId) {
      if (assetId === fileDestinationFoler) {
        moveAssetStatus = false;
      }
    });
    if (moveAssetStatus) {
      dispatch(
        assetsActions.onMoveMediaFiles(
          selectedMediaAssets,
          fileDestinationFoler
        )
      ).then((data) => {
        closePopup();
        loadData();
      });
    } else {
      toast.error("Already in the same folder!");
      endLoading();
    }
  };

  const checkSelectedFolder = (value) => {
    if (selectedFolder.id === value.id) {
      return "moveSelectedFolder moveSelectedFolderHighlight";
    } else {
      return "moveSelectedFolder";
    }
  };

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <Dialog
        open={openPopup}
        onClose={closePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="moveHeadrWrapper">
          Move items to:
          <CloseIcon className="closePopup" onClick={closePopup} />
        </DialogTitle>
        <DialogContent className="addLaunchPopupWrapper zeroPadding">
          <DialogContentText id="alert-dialog-description">
            {isRootDirectory === false && (
              <div key={0} className="moveWrapper">
                <div class="moveSelectedFolder">
                  <span className="moveItemSpan" onClick={() => getFolders()}>
                    <img style={{ marginRight: 20 }} src={folder} alt="" />
                    <span>...</span>
                  </span>
                </div>
              </div>
            )}
            {mediaAssetsFolder &&
              mediaAssetsFolder.length > 0 &&
              mediaAssetsFolder.map((item, index) => {
                return (
                  <div key={index + 1} className="moveWrapper">
                    <div className={checkSelectedFolder(item)}>
                      <span
                        className="moveItemSpan"
                        onClick={() => setSelectedFolder(item)}
                      >
                        <img style={{ marginRight: 20 }} src={folder} alt="" />
                        <span>{item.name}</span>
                      </span>
                    </div>
                  </div>
                );
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="popupFooter addCatFooter">
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={() => moveFiles()}
          >
            Move
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MoveMediaFilesPopup;
