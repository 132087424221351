import React, { useEffect, useRef, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { assetsActions } from "../../../../store/assetsManagement/assetsActions";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import { GridView, SearchIcon } from "../../LeftNav/SvgIcons";
import { ListView } from "../../LeftNav/SvgIcons";
import { useDebounce } from "../../../../utils/use-debounce";

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    paddingTop: "40px",
    paddingBottom: "15px",
    borderBottom: "1px solid #E2E6E9",
  },
  dropdownBg: {
    background: "#f1f1f1",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    border: "1px solid #F3F6F9",
    backgroundColor: "#F3F6F9",
    borderRadius: "6px",
    "&:before": {
      borderBottom: "1px solid #F3F6F9",
      "&:hover": {
        borderBottom: "0 !important",
      },
    },
    "&:after": {
      borderBottom: "1px solid #F3F6F9",
    },

    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9",
      },
    },
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px",
  },
  gridIconWrapper:{
    display: "flex",
    alignContent: "center",
    justifyContent: "space-evenly",
  }
}));

const handleSubmit = (e) => {
  e.preventDefault();
};
function GridFilter({
  loadData,
  setPage,
  page,
  rowsPerPage,
  order,
  startLoading,
  endLoading,
  setView,
  view,
}) {
  const dispatch = useDispatch();
  const { isfavorite, parentId } = useSelector((state) => state.AssetsReducer);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [time, setTime] = useState("");
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { debouncedValue } = useDebounce(search, 2000);
  useEffect(() => {
    dispatch(assetsActions.onChange("search", debouncedValue));    
  }, [debouncedValue]);

  const onPressFilterBtn = () => { 
    dispatch(assetsActions.onChange("search", search));    
    dispatch(assetsActions.onChange("type", type));
    dispatch(assetsActions.onChange("time", time));
    setPage(1);
    setAnchorEl(null);
  };
  const onPressResetBtn = () => {
    startLoading();
    setPage(1);
    dispatch(assetsActions.onChange("selectedMediaAssets", []));
    dispatch(
      assetsActions.onGetAssetManagementList({
        parentId: parentId,
        type: "",
        search: "",
        time: "",
        isfavorite: isfavorite,
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.orderByKey,
        orderByVal: order.orderVal,
      })
    ).then((data) => {
      setSearch("");
      setType("");
      setTime("");
      dispatch(assetsActions.onChange("search", ""));
      dispatch(assetsActions.onChange("type", ""));
      dispatch(assetsActions.onChange("time", ""));
      endLoading();
    });
    setAnchorEl(null);
  };
  const fileTypes = [
    { name: "All File Types", value: "" },
    { name: "PDF", value: "pdf" },
    { name: "PNG", value: "png" },
    { name: "JPEG", value: "jpg" },
    { name: "CSV", value: "csv" },
    { name: "FOLDER", value: "folder" },
  ];
  return (
    <div className={classes.gridWrapper}>
      <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
        <Grid container alignItems="center" justifyContent="space-between" className="girdFilter">
          <Grid item  md={11} className="searchFiterBox">
          <Grid item md={10} className="inputGridBox">
            <SearchIcon />
            <input
              className="searchFilterInput"
              placeholder="Search"
              value={search}
              onChange={(v) => setSearch(v.target.value)}
            />
          </Grid>
          {/* <Grid item className="" onClick={handleClick}>
            <SearchFilterIcon />
          </Grid> */}
          </Grid>
          <Grid item md={1} className={classes.gridIconWrapper}>
            <Grid style={{ cursor: "pointer" }} onClick={() => view !== "grid" && setView("grid")}>
              <GridView color={view === "grid" ? "#3699FF" : "#979797"} />
            </Grid>
            <Grid style={{ cursor: "pointer" }} onClick={() => view !== "list" && setView("list")}>
              <ListView color={view === "list" ? "#3699FF" : "#979797"} />
            </Grid>
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Paper className={classes.dropdownWrapper}>
            <Grid container spacing={2} className="filterByWrapper">
              <Grid item xs={7} className="filterBtnLabel">
                Filter by{" "}
              </Grid>
              <Grid item xs={5}>
                <span className="filterButtons" style={{ float: "right" }}>
                  <Button variant="outlined" onClick={() => onPressFilterBtn()}>
                    Filter
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onPressResetBtn();
                    }}
                  >
                    Clear
                  </Button>
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="inputWrapper">
                  <FormControl className={classes.formControl}>
                    <Select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"filled"}
                      required
                    >
                      <MenuItem value=""></MenuItem>
                      {fileTypes.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="inputWrapper">
                  <FormControl className={classes.formControl}>
                    <Select
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                      fullWidth
                      variant={"filled"}
                      required
                    >
                      <MenuItem value="" disabled>
                        All Uploads
                      </MenuItem>
                      <MenuItem value="">All Uploads</MenuItem>
                      <MenuItem value="isinlastmonth">
                        Within Last Month
                      </MenuItem>
                      <MenuItem value="isinlast3months">
                        Within Past 3 Months
                      </MenuItem>
                      <MenuItem value="isinlast6months">
                        Within Past 6 Months
                      </MenuItem>
                      <MenuItem value="isinlastyear">
                        Within Past Last Year
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Popover>

        {/* 

        {isFilterShow && (
          <>
            <span className="filterDropdown">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div className="inputWrapper">
                    <FormControl className={classes.formControl}>
                      <label>{"File Type"}</label>
                      <Select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        variant={"filled"}
                        required
                      >
                        <MenuItem value=""></MenuItem>
                        {fileTypes.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="inputWrapper">
                    <FormControl className={classes.formControl}>
                      <label>{"Uploaded"}</label>
                      <Select
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        variant={"filled"}
                        required
                      >
                        <MenuItem value="" disabled>
                          All Uploads
                        </MenuItem>
                        <MenuItem value="">All Uploads</MenuItem>
                        <MenuItem value="isinlastmonth">
                          Within Last Month
                        </MenuItem>
                        <MenuItem value="isinlast3months">
                          Within Past 3 Months
                        </MenuItem>
                        <MenuItem value="isinlast6months">
                          Within Past 6 Months
                        </MenuItem>
                        <MenuItem value="isinlastyear">
                          Within Past Last Year
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                 
                </Grid>
                <Grid item xs={4}>
                  <span className="filterButtons">
                    <Button
                      variant="outlined"
                      onClick={() => onPressFilterBtn()}
                    >
                      Filter
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        onPressResetBtn();
                      }}
                    >
                      Clear
                    </Button>
                  </span>
                </Grid>
              </Grid>
            </span>
          </>
        )} */}
      </ValidatorForm>
    </div>
  );
}

export default GridFilter;
