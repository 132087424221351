import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableSortLabel,
} from "@material-ui/core";
import { OpenInNew, Remove, Add } from "@material-ui/icons";
import moment from "moment";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { launchesActions } from "../../../../store/launches/launchesActions";
import AddCategoryPopup from "../../../../components/common/popUps/AddCategoryPopup";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  emptyGridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "30px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function ReleaseCategoryGrid({
  releaseCategoryData,
  startLoading,
  endLoading,
  loadData,
  selectedRecord,
  childRecord,
  isDelete,
  order,
  onOrderBy,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isModal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState("");

  const [collapsibleItem, setCollapsibleItem] = useState(null);

  const redirectUrl = () => {
    history.push("/new-release-category");
  };

  const deleteRelCategory = () => {
    var userIds = [];
    releaseCategoryData.map(x => {
      if (x.checked) {
        userIds.push(x.id);
      }
    });
    if (userIds.length > 0) {
      startLoading();
      dispatch(launchesActions.onDeleteRelCategory(userIds)).then(data => {
        if (data.success) {
          toast.success("Release category deleted.");
          loadData();
        } else {
          toast.error("Something went wrong, Please try latter.");
        }
      });
    } else {
      toast.error("Please select the release category.");
    }
  };

  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        <Grid item md={9} className={classes.deleteItems}>
          {isDelete && (
            <span
              className='gridDeleteItems'
              onClick={() => deleteRelCategory()}>
              <Delete /> Delete
            </span>
          )}
        </Grid>
      </Paper>
      <ul className='releaseGridListHeader'>
        {/* <li></li> */}
        <li>
          CATEGORY NAME
          <TableSortLabel
            active={order.orderByKey === "Name"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "Name",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          NO. OF RELEASES
          <TableSortLabel
            active={order.orderByKey === "NoOfReleases"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "NoOfReleases",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          DATE CREATED
          <TableSortLabel
            active={order.orderByKey === "CreatedOn"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "CreatedOn",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          COLOR
          <TableSortLabel
            active={order.orderByKey === "Color"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "Color",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>
          PUBLIC
          <TableSortLabel
            active={order.orderByKey === "IsPublic"}
            direction={order.orderVal === 1 ? "asc" : "desc"}
            onClick={() =>
              onOrderBy({
                orderByKey: "IsPublic",
                orderVal: order.orderVal === 0 ? 1 : 0,
              })
            }
            IconComponent={SortIcon}
            className='sortIcon'
          />
        </li>
        <li>&nbsp;</li>
      </ul>

      {releaseCategoryData?.map((item, index) => {
        console.log("item : ", item);
        return (
          <>
            {item.id !== 1 ? (
              <ul
                className={`releaseGridListBody ${
                  collapsibleItem === item.id ? "selectedSubCategory" : ""
                }`}
                key={index}>
                <span style={{ display: "flex", width: "100%" }}>
                  {/* <li>
                      <Checkbox
                        className={classes.checkBoxFont}
                        onClick={() => selectedRecord(item)}
                        checked={item.checked}
                      />
                    </li> */}
                  <li>
                    {item.childrens?.length > 0 && (
                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => {
                          setCollapsibleItem(
                            collapsibleItem && collapsibleItem === item.id
                              ? null
                              : item?.id
                          );
                          // childRecord(item);
                        }}>
                        {collapsibleItem && collapsibleItem === item.id ? (
                          <Remove />
                        ) : (
                          <Add />
                        )}
                      </IconButton>
                    )}
                    {item.name}
                  </li>
                  <li> {item.noOfReleases}</li>
                  <li>{moment(item.createdOn).format("MMMM DD, YYYY")}</li>
                  <li>
                    <span
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "10px",
                        display: "flex",
                        backgroundColor: item.color ? item.color : "#EB5757",
                      }}
                    />
                  </li>
                  <li>
                    {item.isPublic ? (
                      <span className={classes.inUseYes}>Yes </span>
                    ) : (
                      <span className={classes.inUseNo}>No </span>
                    )}
                  </li>
                  <li>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push("/new-release-category?id=" + item.id);
                      }}>
                      <EditIcon />
                    </span>
                    <a href={"/releases/" + item.urlSlug} target='_blank'>
                      <OpenInNew style={{ color: "rgb(54, 153, 255)" }} />
                    </a>
                  </li>
                </span>
                {collapsibleItem && collapsibleItem === item.id && (
                  <>
                    {item.childrens?.map(historyRow => {
                      return (
                        <>
                          {item.id !== 1 ? (
                            <ul
                              className='releaseGridListBody releaseSubCatRow'
                              key={index}>
                              <li> {historyRow.name}</li>
                              <li> {historyRow.noOfReleases}</li>
                              <li>
                                {moment(historyRow.createdOn).format(
                                  "MMMM DD, YYYY"
                                )}
                              </li>
                              <li>
                                <span
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "10px",
                                    display: "flex",
                                    backgroundColor: historyRow.color
                                      ? historyRow.color
                                      : "#EB5757",
                                  }}
                                />
                              </li>
                              <li>
                                {historyRow.isPublic ? (
                                  <span className={classes.inUseYes}>Yes</span>
                                ) : (
                                  <span className={classes.inUseNo}>No </span>
                                )}
                              </li>
                              <li>
                                <span
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push(
                                      "/new-release-category?id=" +
                                        historyRow.id
                                    );
                                  }}>
                                  <EditIcon />
                                </span>
                                <a
                                  href={"/releases/" + item.urlSlug}
                                  target='_blank'>
                                  <OpenInNew
                                    style={{ color: "rgb(54, 153, 255)" }}
                                  />
                                </a>
                              </li>
                            </ul>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                )}
              </ul>
            ) : null}
          </>
        );
      })}
      <Table>
        {/* <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
           
            <TableCell className={classes.gridHeaderInner}>
              CATEGORY NAME
              <TableSortLabel
                active={order.orderByKey === "Name"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Name",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              NO. OF RELEASES
              <TableSortLabel
                active={order.orderByKey === "NoOfReleases"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "NoOfReleases",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              DATE CREATED
              <TableSortLabel
                active={order.orderByKey === "CreatedOn"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CreatedOn",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              COLOR
              <TableSortLabel
                active={order.orderByKey === "Color"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Color",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PUBLIC
              <TableSortLabel
                active={order.orderByKey === "IsPublic"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "IsPublic",
                    orderVal: order.orderVal === 0 ? 1 : 0
                  })
                }
              />
            </TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>  */}
      </Table>
      {isModal && (
        <AddCategoryPopup
          openPopup={isModal}
          closePopup={() => setIsModal(false)}
          startLoading={() => startLoading()}
          endLoading={() => endLoading()}
          loadData={() => loadData()}
          isEdit={isEdit}
          editData={editData}
        />
      )}
    </Paper>
  );
}

export default ReleaseCategoryGrid;
