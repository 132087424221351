import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  muiTab: {
   
      "& .MuiTab-wrapper": {
        color: "#fff",
      },
      background: "rgba(255, 53, 53, 0.80)",
      borderRadius:6

  },
  muiTabText:{
    "& .MuiTab-wrapper": {
      color: "#FF353580"

  }
  }
});

const AnimatedTabs = ({
  tabLabels,
  selectedTab,
  handleTabChange,
  setIsTrashedItem,
  setTabName,
}) => {
  if (selectedTab === 5) {
    setIsTrashedItem(true);
  }
  setTabName(tabLabels[selectedTab].label);
  const classes = useStyles();
  return (
    <div className="assestsTabs">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className="tabindicator "
        style={{ fontSize: "14px", marginRight: "8px" }}
        variant="scrollable"
      >
        {tabLabels.map((label, index) => (
          <Tab
            key={index}
            label={label.label}
            className={`${selectedTab === index ? "active-tab" : ""} ${
              label.label === "Trash" && selectedTab === index
                ? classes.muiTab
                : label.label === "Trash"
                ? classes.muiTabText
                : ""
            }`}

          />
        ))}
      </Tabs>
    </div>
  );
};

export default AnimatedTabs;
