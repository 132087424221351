import React from "react";
import { Button } from "@material-ui/core";
import { Add, Done } from "@material-ui/icons";
import { MarkFavButton } from "../Buttons/MarkFavButton";
import { LaunchState } from "../LaunchState";
import sampleImg from "../../../resources/images/sampleImg.png";

const ActionContent = ({
  mainHeading,
  secondaryHeading,
  name,
  description,
  onAction = () => {},
  IsCustomer = false,
  IsPortal = false,
  IsFav = false,
  IsAdded = false,
  IsFullAccess = false,
  onAccessChange = () => {},
}) => {
  return (
    <span className='mainGrid'>
      <div className='dataFieldmainWrapper innerContentMainWrapper'>
        {IsCustomer || IsPortal ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}>
            <div>
              <img src={sampleImg} width={36} height={36} alt='img' />
            </div>
            <div className='dataFieldName' style={{ marginLeft: 10 }}>
              <div style={{ display: "flex" }}>
                <h4>{name}</h4>

                <MarkFavButton isFav={IsFav} />
              </div>

              <p className='innerContentHeading'>{description}</p>
            </div>
          </div>
        ) : (
          <div className='dataFieldName'>
            <h4>{mainHeading}</h4>
            <p className='innerContentHeading'>{secondaryHeading}</p>
          </div>
        )}

        <div style={{ display: "flex" }}>
          {IsAdded && IsCustomer && (
            <>
              {/* <AccessDropdown /> */}
              <LaunchState
                States={{
                  value1: "View Only",
                  value2: "Full Access",
                }}
                value={IsFullAccess}
                onChange={onAccessChange}
              />
            </>
          )}
          <Button
            color='primary'
            variant='contained'
            style={{ backgroundColor: IsAdded ? "#18BC6D" : null }}
            startIcon={IsAdded ? <Done /> : <Add />}
            onClick={IsAdded ? () => {} : onAction}>
            {IsAdded ? "Added" : "Add"}
          </Button>
        </div>
      </div>
    </span>
  );
};

export { ActionContent };
