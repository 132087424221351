import React, { useState } from "react";
import "date-fns";
import { FormControl } from "@material-ui/core";
import { SoftwareCalendarIcon } from "../SpaProduct/SvgIcons";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { FiChevronDown } from "react-icons/fi";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
const hours = Array.from({ length: 12 }, (_, index) => index + 1);
const minutes = Array.from({ length: 60 }, (_, index) => index);
const periods = ["AM", "PM"];

export const ReleaseDate = ({disabled, onDateChange, onTimeChange, id, value  , timeValue=''}) => {
  const [time, period] = timeValue?.split(' ') ?? ['', ''];
  const [selectedHours, selectedMinutes] = time?.split(':') ?? [null, null];
  const [selectedDate, setSelectedDate] = useState(
    value?.length ? new Date(value) : new Date()
  );
  const [selectedHour, setSelectedHour] = useState(selectedHours ?? null);
  const [selectedMinute, setSelectedMinute] = useState(selectedMinutes ?? null);
  const [selectedPeriod, setSelectedPeriod] = useState(period ?? null);

  const handleDateChange = (date) => {
    const formattedDate = format(date, "MM/dd/yyy");
    setSelectedDate(formattedDate);
    onDateChange && onDateChange(formattedDate, id);
  };

  const handleHourChange = (_, value) => {
    setSelectedHour(value);
    onTimeChange && onTimeChange(id, selectedDate, value, selectedMinute, selectedPeriod); 
  };

  const handleMinuteChange = (_, value) => {
    setSelectedMinute(value);
    onTimeChange && onTimeChange(id, selectedDate, selectedHour, value, selectedPeriod); 
  };

  const handlePeriodChange = (_, value) => {
    setSelectedPeriod(value);
    onTimeChange && onTimeChange(id, selectedDate, selectedHour, selectedMinute, value); 
  };


  return (
    <div>
      <div className="releaseDate">
        <MuiPickersUtilsProvider utils={DateFnsUtils} disabled={disabled}>
          <div className="inputWrapper dateContainer">
            <FormControl>
              <DatePicker
                disableToolbar
                variant="inline"
                format="MMMM dd"
                value={selectedDate}
                onChange={handleDateChange}
                minDate={new Date()}
                autoOk
                id={`${id}-date`}
                disabled={disabled}
              />
            </FormControl>
            <i style={{cursor: 'pointer'}} onClick={() => {
              document.getElementById(`${id}-date`).click();
            }}>
              <SoftwareCalendarIcon style={{ marginRight: "8px" }} />
            </i>
          </div>
        </MuiPickersUtilsProvider>

        <span className="atTheRate">@</span>

        <Autocomplete
          className="timeline"
          options={hours}
          disableClearable
          popupIcon={<FiChevronDown />}
          getOptionLabel={(option) => option.toString()}
          value={selectedHour}
          defaultValue={hours[0]}
          onChange={handleHourChange}
          renderInput={(params) => <TextField {...params} variant="outlined"  disabled={disabled}  />}
          disabled={disabled}
        />
        <Autocomplete
          className="timeline"
          disableClearable
          popupIcon={<FiChevronDown />}
          options={minutes}
          getOptionLabel={(option) => option.toString().padStart(2, "0")}
          value={selectedMinute}
          defaultValue={minutes[0]}
          onChange={handleMinuteChange}
          renderInput={(params) => <TextField {...params} variant="outlined"  disabled={disabled} />}
          disabled={disabled}
        />
        <Autocomplete
          className="timeline"
          options={periods}
          popupIcon={<FiChevronDown />}
          getOptionLabel={(option) => option}
          value={selectedPeriod}
          onChange={handlePeriodChange}
          disableClearable
          defaultValue={periods[0]}
          renderInput={(params) => <TextField {...params} variant="outlined"  disabled={disabled} />}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
