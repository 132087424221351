import React, { useRef, useState } from "react";
import { Button } from "@material-ui/core";
import {
  logo,
  logoSign,
  loginLogo,
  passwordReset
} from "../../../resources/images/index";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { accountActions } from "../../../store/accounts/accountActions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import { RoutesObj } from "../../../routes";
import { Curve, CurveOverlay } from "../../../components/common/LoginSVG";
import curve from "../../../resources/images/curve.png";
import curveOverlay from "../../../resources/images/curveOverlay.png";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";

function PasswordReset(props) {
  const dispatch = useDispatch();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    if (password === confirmPassword) {
      setLoading(true);
      dispatch(accountActions.onResetPassword(password, confirmPassword)).then(
        data => {
          if (data && data.success) {
            toast.success(data.data);
            props.history.push("/login");
          } else {
            toast.error(data?.message);
          }
          setLoading(false);
        }
      );
    } else {
      toast.error("Your password and confirm password not matched");
    }
  };
  const loginForm = useRef("form");
  return (
    <div className="loggedinWrapper">
      {isLoading ? <Loader /> : null}
      <div className="leftCol">
        <span className="loginLeftshade" />
        <span className="loginRightShade">
          <img src={curve} alt="" className="curve" />
          <img src={curveOverlay} alt="" className="curveOverlay" />
        </span>
      </div>
      <div className="rightCol">
        <img src={loginLogo} alt="" />
        <img src={logoSign} alt="" style={{ marginLeft: "195px" }} />
        <span className="createAccount centerHeading">
          <img src={passwordReset} alt="" />
          Password Reset
        </span>
        {/* <form onSubmit={loginForm.handleSubmit}> */}
        <ValidatorForm ref={loginForm} onSubmit={handleSubmit}>
          <TextValidator
            id="password"
            label="New Password"
            variant="outlined"
            name="password"
            fullWidth
            validators={[
              "required",
              "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$"
            ]}
            errorMessages={[
              "Please enter your password",
              "Your password must contain at least 1 uppercase, lowercase, number and special character"
            ]}
            type={"password"}
            value={password}
            onChange={v => setPassword(v.target.value)}
          />
          <div className="gap" />
          <TextValidator
            id="confirmPassword"
            label="Confirm Password"
            variant="outlined"
            name="confirmPassword"
            fullWidth
            validators={[
              "required",
              "matchRegexp:^(?=.*\\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Zd@$!%*#?&])(?=.*).*$"
            ]}
            errorMessages={[
              "Please enter your password",
              "Your password must contain at least 1 uppercase, lowercase, number and special character"
            ]}
            type={"password"}
            value={confirmPassword}
            onChange={v => setConfirmPassword(v.target.value)}
          />

          <Button type="submit" color="primary" variant="contained">
            Continue
          </Button>
        </ValidatorForm>
        {/* </form> */}
      </div>
    </div>
  );
}

export default withRouter(PasswordReset);
