import { accountTypes } from "./accountTypes";
import { accountApi } from "./accountApi";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: accountTypes.ACCOUNT_LOGIN_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onForgotPassword = email => async dispatch => {
  let response = await accountApi.forgotPassword(email);
  return response;
};

const onResetPassword = (pass, confPass) => async dispatch => {
  let response = await accountApi.resetPassword(pass, confPass);
  return response;
};

const onSubmit = (email, password) => async dispatch => {
  let response = await accountApi.login(email, password);
  return response;
};

const onAddUserSession = pageUrl => async dispatch => {
  let response = await accountApi.addUserSession(pageUrl);
  return response;
};
const onGetUserAccountInfo = () => async dispatch => {
  let response = await accountApi.getUserAccountInfo();
  return response;
};

const onUpdateUserAccountInfo = userDate => async dispatch => {
  let response = await accountApi.updateUserAccountInfo(userDate);
  return response;
};

const onGetAllCounts = date => async dispatch => {
  let response = await accountApi.getAllCounts(date);
  return response;
};

const onLoginWithToken = token => async dispatch => {
  let response = await accountApi.tokenLogin(token);
  return response;
};

export const accountActions = {
  onChange: onChange,
  onSubmit: onSubmit,
  onAddUserSession: onAddUserSession,
  onGetUserAccountInfo: onGetUserAccountInfo,
  onUpdateUserAccountInfo: onUpdateUserAccountInfo,
  onGetAllCounts: onGetAllCounts,
  onForgotPassword: onForgotPassword,
  onResetPassword: onResetPassword,
  onLoginWithToken: onLoginWithToken,
};
