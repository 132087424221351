import React from 'react';
import {Link} from 'react-router-dom'

function NewsUpdate({heading, text, link, linkText}) {
    return (
        <div className="updateWrapper">
            <h2>{heading}</h2>
            <span className="updateText">{text} <Link to={link}>{linkText}</Link></span>
            
        </div>
    );
}

export default NewsUpdate;