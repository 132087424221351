import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "../common/checkbox/Checkbox";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { pubEventActions } from "../../store/pubEvent/pubEventActions";
import _ from "lodash";
import { toast } from "react-toastify";
import CustomCheckbox from "./checkbox/CustomCheckbox";
import { useHistory } from "react-router";
import EventIcon from "@material-ui/icons/Event";
import RecentReleases from "./RecentReleases";
import moment from "moment";

const data = [
  {
    heading: "Pro sport series",
    eventdate: "March 15, 2021",
    description: "6.5 Speaker and 10 Subwoofer",
  },
  {
    heading: "Pro sport series",
    eventdate: "March 15, 2021",
    description: "6.5 Speaker and 10 Subwoofer",
  },
];

function FilterBar({
  startLoading,
  stopLoading,
  showButton,
  hideButton,
  loadData,
  searchPage,
  onChange,
  value,
}) {
  const [isDotFill, setIsDotFill] = useState(false);
  const [allDotData, setAllDotData] = useState([]);
  const [displayDotData, setDisplayDotData] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const eventData = {
      eventDate: 0,
      categoryIds: [],
      take: 4,
      skip: 0,
      title: "",
      dataType: ["LatestOnly"],
      isPrevious: true,
    };
    dispatch(pubEventActions.onGetPubAllEvents(eventData)).then(res => {
      if (res && res.success && res.data?.length > 0) {
        if (res.data[0].data?.length > 0) {
          setAllDotData(res.data[0].data);
          setDisplayDotData(res.data[0].data.slice(0, 2));
        }
      }
    });
  }, []);

  const selectEventCategory = value => {
    startLoading();
    dispatch(
      pubEventActions.onSelectPubEventCategory(value, selectedEventCategory)
    ).then(data => {
      stopLoading();
    });
    opPresFindBtn();
  };
  const checkEventCategory = itemId => {
    const relExist = selectedEventCategory.find(eventCat => {
      return eventCat.id == itemId;
    });
    if (relExist) {
      return true;
    } else {
      return false;
    }
  };

  const selectEventDateFilter = value => {
    dispatch(pubEventActions.onSelectPubEventDateFilter(value));
    opPresFindBtn();
  };
  const checkEventDateFilter = itemName => {
    const relExist = selectedPubEventDateFilter.find(eventFilter => {
      return eventFilter.id == itemName;
    });
    if (relExist) {
      return true;
    } else {
      return false;
    }
  };

  const opPresFindBtn = () => {
    if (selectedEventCategory.length > 0) {
      startLoading();
      let selectedCat = [];
      let selectedDate = 0;
      _.forEach(selectedEventCategory, function (catId) {
        selectedCat.push(parseInt(catId.id));
      });
      const dateAll = selectedPubEventDateFilter.find(result => {
        return result.id === "0";
      });
      const dateYear = selectedPubEventDateFilter.find(result => {
        return result.id === "4";
      });
      const date6Month = selectedPubEventDateFilter.find(result => {
        return result.id === "3";
      });
      const date3Month = selectedPubEventDateFilter.find(result => {
        return result.id === "2";
      });
      const dateMonth = selectedPubEventDateFilter.find(result => {
        return result.id === "1";
      });
      if (dateAll) {
        selectedDate = dateAll.id;
      } else if (dateYear) {
        selectedDate = dateYear.id;
      } else if (date6Month) {
        selectedDate = date6Month.id;
      } else if (date3Month) {
        selectedDate = date3Month.id;
      } else if (dateMonth) {
        selectedDate = dateMonth.id;
      } else {
        selectedDate = 0;
      }
      const filterPayload = {
        eventDate: selectedDate,
        categoryIds: selectedCat,
        take: 100,
        skip: 0,
        title: "",
        dataType: ["CategoryOnly"],
      };
      dispatch(pubEventActions.onGetPubAllEvents(filterPayload)).then(res => {
        stopLoading();
        hideButton();
      });
    } else {
      // toast.error("Please select category first");
      loadData();
    }
  };

  const onClearFilter = () => {
    loadData();
    showButton();
    dispatch(pubEventActions.onChange("selectedEventCategory", []));
    dispatch(pubEventActions.onChange("selectedPubEventDateFilter", []));
  };

  const redirectURL = () => {
    history.push("/events-calendar");
  };

  const enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      searchPage();
    }
  };

  const onDotClick = value => {
    setIsDotFill(!isDotFill);
    if (value === 1) {
      setDisplayDotData(allDotData.slice(0, 2));
    } else if (value === 2) {
      setDisplayDotData(allDotData.slice(2, 4));
    }
  };

  const onViewEvent = item => {
    if (item.urlSlug) {
      history.push("/events/category/" + item.urlSlug);
    } else {
      toast.error("No record found");
    }
  };

  const {
    pubEventsCategory,
    eventDateFilter,
    selectedEventCategory,
    selectedPubEventDateFilter,
  } = useSelector(state => state.pubEventReducer);
  return (
    <>
      <div className='filterBar'>
        <span className='eventCalBtnLeftMenu' onClick={redirectURL}>
          <EventIcon /> View Full Calendar{" "}
        </span>
        <h2>Find an Event</h2>

        <span className='searchBar'>
          <SearchIcon onClick={searchPage} />
          <input
            type='text'
            placeholder='Search events'
            onKeyPress={e => enterPressed(e)}
            onChange={onChange}
            value={value}
          />
        </span>

        <h2>
          Event Categories{" "}
          {selectedEventCategory?.length > 0 && (
            <Button
              color='primary'
              variant='outlined'
              onClick={() => {
                history.push("/events");
                loadData();
                showButton();
                dispatch(pubEventActions.onChange("selectedEventCategory", []));
              }}>
              Clear
            </Button>
          )}
        </h2>

        <ul>
          {pubEventsCategory &&
            pubEventsCategory.length > 0 &&
            pubEventsCategory.map((item, index) => (
              <>
                {item.name === "No Category" && item.count === 0 ? null : (
                  <li key={index}>
                    <span>
                      {/* <Checkbox
                  onClick={() => selectEventCategory(item)}
                  checked={checkEventCategory(item.id)}
                /> */}
                      <CustomCheckbox
                        checkedId={`eventCategoryCheckId-${index}`}
                        pressCheck={() => selectEventCategory(item)}
                        checkValue={checkEventCategory(item.id)}
                      />
                      {item.name}
                    </span>
                    <span>{item.count}</span>
                  </li>
                )}
              </>
            ))}
        </ul>

        <span className='gap'>&nbsp;</span>
        <h2>
          Event Dates{" "}
          {selectedPubEventDateFilter?.length > 0 && (
            <Button
              color='primary'
              variant='outlined'
              onClick={() => {
                // loadData();
                // showButton();
                dispatch(
                  pubEventActions.onChange("selectedPubEventDateFilter", [])
                );
              }}>
              Clear
            </Button>
          )}
        </h2>
        <ul>
          {eventDateFilter &&
            eventDateFilter.length > 0 &&
            eventDateFilter.map((item, index) => (
              <li key={index}>
                <span>
                  {/* <Checkbox
                  onClick={() => selectEventDateFilter(item)}
                  checked={checkEventDateFilter(item.id)}
                /> */}
                  <CustomCheckbox
                    checkedId={`eventDateFilterId-${index}`}
                    pressCheck={() => selectEventDateFilter(item)}
                    checkValue={checkEventDateFilter(item.id)}
                  />
                  {item.value}
                </span>
                <span>{item.generic}</span>
              </li>
            ))}
        </ul>
        {/* <div className="filterFooter">
          <Button
            color="primary"
            variant="contained"
            onClick={() => opPresFindBtn()}>
            Find
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onClearFilter()}>
            Clear
          </Button>
        </div> */}
      </div>

      <div className='filterBar'>
        <h2 style={{ margin: "0" }}>Past Events</h2>
        <span className='ownerName'>
          By Aquatic AV
          <span className='dots'>
            <ul>
              <li
                className={`${!isDotFill ? "dotFill" : ""}`}
                onClick={() => onDotClick(1)}
              />
              {allDotData && allDotData.length > 2 && (
                <li
                  className={`${isDotFill ? "dotFill" : ""}`}
                  onClick={() => onDotClick(2)}
                />
              )}
            </ul>
          </span>
        </span>
        {displayDotData &&
          displayDotData.length > 0 &&
          displayDotData.map(item => {
            return (
              <RecentReleases
                picture={item.imageUrl}
                heading={item.title}
                eventdate={moment(item.startDate).format("MMMM DD, yyyy")}
                description={item.subTitle}
                onPressView={() => onViewEvent(item)}
              />
            );
          })}
      </div>
    </>
  );
}

export default FilterBar;
