import React from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { EditIcon } from "../../LeftNav/SvgIcons";
import { RoutesObj } from "../../../../routes";

const useStyles = makeStyles(theme => ({
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridCheckbox: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
    width: "26px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  quickActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const ProductsGrid = ({ listData }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.UpdateProduct.path,
      state: { product: value },
      search: `id=${value.productId}`,
    });
  };

  return (
    <Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>
              PRODUCT NAME
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>SKU</TableCell>
            <TableCell className={classes.gridHeaderInner}>
              DATE ADDED
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              QUICK ACTIONS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listData?.length > 0 &&
            listData.map((item, index) => {
              return (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell className={classes.gridBodyInner}>
                    {item.productName}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.sku}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {moment(item.dateAdded).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    <div className={classes.quickActions}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => redirectUrl(item)}>
                        <EditIcon />
                      </span>
                      {/* <span onClick={() => {}}>
                        <Trash />
                      </span> */}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          {listData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='5'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any product.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export { ProductsGrid };
