import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Activities } from "../../../../components/common";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { launchesActions } from "../../../../store/launches/launchesActions";
import Loader from "../../../../components/common/Loader";

const LaunchActivities = ({ launchId, isActive }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [activites, setActivites] = useState([]);

  useEffect(() => {
    if (isActive && launchId > 0) {
      setLoading(true);
      dispatch(launchesActions.onGetLaunchActivities(launchId)).then(data => {
        setLoading(false);
        if (data) {
          setActivites(data?.data);
        }
      });
    }
  }, [dispatch, isActive, launchId]);

  return (
    <AccordionContainer title='Launch Activities'>
      {isLoading ? <Loader /> : null}
      <Activities listData={activites} />
    </AccordionContainer>
  );
};

export { LaunchActivities };
