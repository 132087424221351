import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";
import { identity } from "lodash";

async function getAllContactTypes(data) {
  return axios
    .post(endpoints.contactTypes.getAllContactTypes, data)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllContactDataFields(id) {
  return axios
    .get(endpoints.contactTypes.getAllContactDataFields + id)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllContactModules(id) {
  return axios
    .get(endpoints.contactTypes.getAllContactModules + id)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllConnections(id) {
  return axios
    .get(endpoints.contactTypes.getAllConnections + id)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllContactAreas() {
  return axios
    .get(endpoints.contactTypes.getAllContactAreas)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllOFisRoles() {
  return axios
    .get(endpoints.contactTypes.getAllOfishRoles)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getAllContactDataFieldsById(id) {
  console.log("_id", id);
  return axios
    .get(
      `${endpoints.contactTypes.getAllContactDataFieldsById}` + `?fieldId=${id}`
    )
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}
// async function getContactTypeDetailByID(contactTypeID) {
//   return axios
//     .get(endpoints.companies.deleteCompanies + contactTypeID)
//     .then(response => {
//       let data = response.data;
//       return data;
//     })
//     .catch(error => {
//       return null;
//     });
// }

// async function updateContactTypeStatusByID(contactTypeID) {
//   return axios
//     .delete(endpoints.customerGroups.deleteCustomerGroups, {
//       data: JSON.stringify(ids),
//     })
//     .then(response => {
//       let data = response.data;
//       return data;
//     })
//     .catch(error => {
//       return null;
//     });
// }

// async function addContactType(contactType) {
//   return axios
//     .post(endpoints.customerGroups.addCustomerGroups, {
//       name: value,
//     })
//     .then(response => {
//       let data = response.data;
//       return data;
//     })
//     .catch(error => {
//       return null;
//     });
// }

// async function updateContactType(contactType) {
//   return axios
//     .post(`${endpoints.customers.updateContact}${contactType.ContactTypeID}`, JSON.stringify(contactType))
//     .then(response => {
//       let data = response.data;
//       return data;
//     })
//     .catch(error => {
//       return null;
//     });
// }
async function getContactTypesModulesFiledsSettingsById(id) {
  return axios
    .get(
      `${endpoints.contactTypes.getContactTypesModulesFiledsSettingsById}` +
        `?fieldId=${id}`
    )
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateContactType(data) {
  return axios
    .post(endpoints.contactTypes.addUpdateContactType, data)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getContactTypesById(id) {
  return axios
    .get(
      `${endpoints.contactTypes.getContactTypesById}` + `?ContactTypeId=${id}`
    )
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateMarkFavouriteContactTypes(data) {
  return axios
    .post(endpoints.contactTypes.addUpdateMarkFavouriteContactTypes, data)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateMarkFavouriteContactTypesModules(data) {
  return axios
    .post(
      endpoints.contactTypes.addUpdateMarkFavouriteContactTypesModules,
      data
    )
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addUpdateMarkFavouriteOfishConnections(data) {
  return axios
    .post(endpoints.contactTypes.addUpdateMarkFavouriteOfishConnections, data)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getFavouriteUsers() {
  return axios
    .post(`${endpoints.customers.getFavouriteUsers}`)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getStoreLocationsByCompanyId(id) {
  return axios
    .get(endpoints.contactTypes.getStoreLocationsByCompanyId(id))
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getLeadsPaginatedData(data) {
  return axios
    .post(endpoints.leadGenerations.getLeadsPaginatedData, data)
    .then(data => {
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function updateLeadGeneration(data) {
  return axios
    .post(endpoints.leadGenerations.updateLeadGeneration, data)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.error("errorMessage ", error);
      return null;
    });
}

async function getLeadGeneration(id) {
  try {
    const response = await axios.get(`${endpoints.leadGenerations.getLeadGeneration}?${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching lead generation data:', error);
    return null;
  }
}

export const contactsApi = {
  getAllContactTypes: getAllContactTypes,
  getAllContactDataFields: getAllContactDataFields,
  getAllContactModules: getAllContactModules,
  getAllConnections: getAllConnections,
  getAllContactAreas: getAllContactAreas,
  getAllOFisRoles: getAllOFisRoles,
  getAllContactDataFieldsById: getAllContactDataFieldsById,
  // getContactTypeDetailByID: getContactTypeDetailByID,
  // updateContactTypeStatusByID: updateContactTypeStatusByID,
  // addContactType: addContactType,
  // updateContactType: updateContactType,
  getContactTypesModulesFiledsSettingsById:
    getContactTypesModulesFiledsSettingsById,
  addUpdateContactType: addUpdateContactType,
  getContactTypesById: getContactTypesById,
  addUpdateMarkFavouriteContactTypes: addUpdateMarkFavouriteContactTypes,
  addUpdateMarkFavouriteContactTypesModules:
    addUpdateMarkFavouriteContactTypesModules,
  addUpdateMarkFavouriteOfishConnections:
    addUpdateMarkFavouriteOfishConnections,
  getFavouriteUsers: getFavouriteUsers,
  getStoreLocationsByCompanyId: getStoreLocationsByCompanyId,
  getLeadsPaginatedData:getLeadsPaginatedData,
  updateLeadGeneration:updateLeadGeneration,
  getLeadGeneration:getLeadGeneration,
};
