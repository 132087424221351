import React, { useEffect, useState } from "react";
import { Button, FormControl, Grid, TextField, IconButton } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  AccordionContainer,
  EmptyTextArea,
  InputField,
  TitleChip,
  Modal,
  ActionContent,
  StatusButton,
  SelectInput,
} from "../../../../../../components/common";
import { AddAvailabilityPeriodButton } from "./AddAvailabilityPeriodButton";
import { AddAvailabilityPeriodForm } from "./AddAvailabilityPeriodForm";
import { DefaultPriceListData } from "../../../../../../utils/use-price-list";
import { first } from "lodash";
import { toast } from "react-toastify";
import { Autocomplete } from "@material-ui/lab";
import {
  AccessAlarm,
  Cancel,
  CheckCircle,
  PortableWifiOff,
} from "@material-ui/icons";
import { SVGIcons } from "../../../../../../resources/images";
import { useDropdownValues } from "../../../../../../utils/use-dropdown-values";
import { endpoints } from "../../../../../../config/apiConfig";
import { RoutesObj } from "../../../../../../routes";
import { Add } from "@material-ui/icons";

const renderAvailabilityPeriodIcon = ({ period }) => (
  <span>
    {period.isUpComing ? (
      <AccessAlarm fontSize='small' style={{ color: "#9B51E0" }} />
    ) : period?.status?.includes("Expired") ? (
      <Cancel fontSize='small' style={{ color: "#FF3535" }} />
    ) : period.status === "" ? (
      <PortableWifiOff fontSize='small' style={{ color: "#FFAD4E" }} />
    ) : (
      <CheckCircle fontSize='small' style={{ color: "#18BC6D" }} />
    )}
  </span>
);

const eStatuses = [
  {
    id: "1",
    name: "Planning",
  },
  {
    id: "2",
    name: "Pre Release",
  },
  {
    id: "3",
    name: "Live",
  },
];

const renderEStatusClassName = ({ prodListData }) => {
  let eStatusClass = "prodStatusSelect";
  if (prodListData?.status) {
    const selectedStatus = eStatuses
      .find(s => s.id === `${prodListData?.status}`)
      ?.name?.replace(" ", "-");
    if (selectedStatus) eStatusClass = `prodStatusSelect-${selectedStatus}`;
  }
  return eStatusClass;
};

const BasicsForm = ({
  availabilityPeriodDD,
  plData,
  setPriceListData,
  onAvailabilityPeriodCreated = () => {},
  addUpdatePriceList,
  activeStep,
  setActiveStep,
}) => {
  const { pathname } = useLocation();
  const [openModal, setOpen] = useState(false);
  const [isCollpased, setIsCollpased] = useState(false);
  const [isAddAvailabilityPeriod, setAddAvailabilityPeriod] = useState(false);
  const [plaPeriodId, setplaPeriodId] = useState(null);
  const [previousPriceListId, setPrevPriceListId] = useState(null);
  const [nextPriceListId, setNextPriceListId] = useState(null);
  const [isDuplicateAvailabilityPeriod, setDuplicateAvailabilityPeriod] =
    useState(false);

  const { dropdownValues: priceListDD } = useDropdownValues({
    url: endpoints.priceListTool.getPriceListForCopyAccess,
  });

  useEffect(() => {
    setplaPeriodId(plData?.papId);
    setPrevPriceListId(plData?.previousPriceListId);
    setNextPriceListId(plData?.nextPriceListId);
  }, [plData]);


  const isPreviousAndComingShow = plaPeriodId && plaPeriodId != -1;
  function findIndexOfFirstHyphen(inputString) {
    const indexOfHyphen = inputString?.indexOf("-");
    return indexOfHyphen;
  }

  const newAvailbilityPeriodValue = ()=>{
    const availabilityPeriod = availabilityPeriodDD?.find(
      obj => `${obj?.key}` === `${plaPeriodId}`
    )
    const value = findIndexOfFirstHyphen(availabilityPeriod?.value) === -1 ? 
    availabilityPeriod?.value : 
    availabilityPeriod?.value?.slice(0 , findIndexOfFirstHyphen(availabilityPeriod?.value))
   
    const updatedAvailabilityPeriod = {...availabilityPeriod , value:value}
    return updatedAvailabilityPeriod;
   } 


  
  return (
    <div className='newPriceListForm gridBody contentBody '>
      <AccordionContainer
        title='Price List Basics'
        // subTitle='Complete the Basics Such as Price List Names + Availability'
        isExpanded={activeStep === 1}
        onCollapsed={collapsed => setIsCollpased(collapsed)}
        formButton={
          isCollpased ? (
            pathname?.includes(first(RoutesObj.CreatePriceList.path)) &&
            plData?.id > 0 && (
              <span className='green-tick-svg'>
                <SVGIcons.GreenTick />
              </span>
            )
          ) : (
            <>
              <TitleChip title='0 Groups' />
              <TitleChip title='0 Contacts' />
              <TitleChip title='0 Downloads' />
              <TitleChip title='0 Views' />
            </>
          )
        }>
        <ValidatorForm
          ref={React.useRef("form")}
          onSubmit={async () => {
            const response = await addUpdatePriceList({
              ...plData,
              papId: parseInt(plData.papId),
            });
            if (response.success) {
              toast.success(
                `Price List ${plData?.id ? "updated" : "created"} successfully`
              );
              if (plData.id) setPriceListData({ ...response.data });
              else
                setPriceListData({
                  ...response.data,
                  priceListColumns:
                    response.data?.priceListColumns ||
                    DefaultPriceListData.priceListColumns,
                  groupAccessLevel:
                    response.data?.groupAccessLevel ||
                    DefaultPriceListData.groupAccessLevel,
                });
              setActiveStep(activeStep + 1);
            }
            // onSuccess(response);
          }}>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <InputField
                  showRequired
                  inputlabel='Price List Name (Internal)'
                  placeholder='Price List Name'
                  id='internalName'
                  name='internalName'
                  validators={["required", "matchRegexp:^.{0,50}$"]}
                  errorMessages={[
                    "Please enter price list internal name",
                    "Maximum length is 50 characters",
                  ]}
                  value={plData?.priceListNameInternal}
                  onChange={({ target }) => {
                    setPriceListData({
                      ...plData,
                      priceListNameInternal: target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <InputField
                  autoFocus={true}
                  inputlabel='Price List Name (Visible)'
                  placeholder='Price List Name'
                  id='priceListName'
                  name='priceListName'
                  validators={["required", "matchRegexp:^.{0,50}$"]}
                  errorMessages={[
                    "Please enter price list name",
                    "Maximum length is 50 characters",
                  ]}
                  value={plData?.priceListNameVisible}
                  onChange={({ target }) => {
                    setPriceListData({
                      ...plData,
                      priceListNameVisible: target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={2} style={{ display: "flex" }}>
                {plData?.id ? (
                  <span
                    className={renderEStatusClassName({
                      prodListData: plData,
                    })}>
                    <SelectInput
                      placeholder='Please Select'
                      value={plData?.status}
                      customMenuItems={eStatuses}
                      handleChange={({ target }) => {
                        setPriceListData({
                          ...plData,
                          status: target.value,
                        });
                      }}
                    />
                  </span>
                ) : (
                  <StatusButton />
                )}
              </Grid>

              <Grid item xs={12}>
                <EmptyTextArea
                  maxLength={500}
                  textLabel='Short Description Before Price List'
                  textSubtitle='(500 characters max)'
                  value={plData?.shortDescription}
                  onChange={({ target }) => {
                    setPriceListData({
                      ...plData,
                      shortDescription: target.value,
                    });
                  }}
                />
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={10}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={4}
                      className='inputWrapper userDetailsSearchField'>
                      {!isCollpased && (
                        <AddAvailabilityPeriodButton
                          onClick={() => setOpen(!openModal)}
                        />
                      )}
                      <FormControl className='ac-dd'>
                        <label>
                          Availability Period <i className={"showRequired"}></i>
                        </label>
                        <Autocomplete
                         freeSolo
                          value={
                            plaPeriodId &&
                            newAvailbilityPeriodValue()
                          }
                          onChange={(event, option) => {
                            setPriceListData({
                              ...plData,
                              papId: option?.key,
                            });
                          }}
                          filterSelectedOptions
                          id='pl-availability-period'
                          options={availabilityPeriodDD}
                          renderOption={period => (
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}>
                              {renderAvailabilityPeriodIcon({ period: period })}
                              <span
                                style={{
                                  paddingLeft: "5px",
                                }}>
                              {                        
                                findIndexOfFirstHyphen(period.value) === -1 ? 
                                period.value : 
                                period.value.slice(0 , findIndexOfFirstHyphen(period.value))
                              }
                              </span>
                            </span>
                          )}
                          getOptionLabel={option => option?.value}
                          renderInput={params => (
                            <TextField
                              {...params}
                              required
                              placeholder={"Availability Period"}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {!isPreviousAndComingShow && (
                      <Grid
                      item
                      xs={4}
                      style={{display:"flex"}}>
                        <IconButton color='primary' size='small' style={{paddingTop:"18px"}} onClick={() => setOpen(!openModal)}>
                        <Add/> Add New Availability Period
                        </IconButton>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={4}
                      style={{
                        opacity: isPreviousAndComingShow ? 1 : 0,
                      }}
                      className='inputWrapper userDetailsSearchField'>
                      {isPreviousAndComingShow && (
                        <FormControl className='ac-dd'>
                          <label>Previous Price List</label>
                          <Autocomplete
                            value={
                              previousPriceListId &&
                              priceListDD?.find(
                                em => `${em?.id}` === `${previousPriceListId}`
                              )
                            }
                            onChange={(event, option) => {
                              setPriceListData({
                                ...plData,
                                previousPriceListId: option?.id,
                              });
                            }}
                            filterSelectedOptions
                            id='previousPriceListId'
                            options={priceListDD}
                            getOptionLabel={option =>
                              option?.priceListNameInternal
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder={"No Past Price List Connected Yet"}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <Cancel
                                      fontSize='small'
                                      style={{ color: "#FF3535" }}
                                    />
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        opacity: isPreviousAndComingShow ? 1 : 0,
                      }}
                      className='inputWrapper userDetailsSearchField'>
                      {isPreviousAndComingShow && (
                        <FormControl className='ac-dd'>
                          <label>Next Price List</label>
                          <Autocomplete
                            value={
                              nextPriceListId &&
                              priceListDD?.find(
                                em => `${em?.id}` === `${nextPriceListId}`
                              )
                            }
                            onChange={(event, option) => {
                              setPriceListData({
                                ...plData,
                                nextPriceListId: option?.id,
                              });
                            }}
                            filterSelectedOptions
                            id='nextPriceList'
                            options={priceListDD}
                            getOptionLabel={option =>
                              option?.priceListNameInternal
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder={"No Upcomming Price List Yet"}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <AccessAlarm
                                      fontSize='small'
                                      style={{ color: "#9B51E0" }}
                                    />
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}>
                  <div>
                    <Button
                      color='primary'
                      variant='contained'
                      className='dashboardButtons'
                      type='submit'>
                      Save & Next
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </AccordionContainer>

      {isAddAvailabilityPeriod ? (
        <AddAvailabilityPeriodForm
          isAddAvailabilityPeriod={isAddAvailabilityPeriod}
          setAddAvailabilityPeriod={setAddAvailabilityPeriod}
          isDuplicateAvailabilityPeriod={isDuplicateAvailabilityPeriod}
          availabilityPeriodDD={availabilityPeriodDD?.filter(
            ap => !["0", "-1"].includes(ap?.key)
          )}
          onSuccess={success => {
            if (success) setAddAvailabilityPeriod(false);

            onAvailabilityPeriodCreated(success);
          }}
        />
      ) : (
        <Modal
          open={openModal}
          setOpen={setOpen}
          title={"Add New Availability Period"}
          primaryHeading='Choose How You Want To Create A New Availability Period'
          secondaryHeading='Start from scratch with blank availability period and no pricing or customers or duplicate an existing availability period.'>
          <Grid container>
            <ActionContent
              mainHeading='Create a New Availability Period from Scratch'
              secondaryHeading='Start with a Blank Availability Period where you can add dates of Availability now + add pricing & customers later.'
              onAction={() => {
                setDuplicateAvailabilityPeriod(false);
                setAddAvailabilityPeriod(!isAddAvailabilityPeriod);
              }}
            />
            <ActionContent
              mainHeading='Duplicate/Copy Existing Availability Period'
              secondaryHeading='Create a New Availability by duplicating both pricing and customers from a past availability period to a future one.'
              onAction={() => {
                setDuplicateAvailabilityPeriod(true);
                setAddAvailabilityPeriod(!isAddAvailabilityPeriod);
              }}
            />
          </Grid>
        </Modal>
      )}
    </div>
  );
};

export { BasicsForm };
