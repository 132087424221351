import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { ReleaseDate } from "./ReleaseDate";
import { EmptyTextArea } from "../../../../components/common";
import AddIcon from "@material-ui/icons/Add";
import { CustomSoftwareReleasePopup } from "./CustomSoftwareReleasePopup";
import { Autocomplete } from "@material-ui/lab";
import { SortedByFilterCheckbox } from "../../../../components/common/checkbox/SortedByFilterCheckbox";
import { FiChevronDown } from "react-icons/fi";
import { ChevronRight, EditIconSmall } from "../../LeftNav/SvgIcons";
import CloseIcon from "@material-ui/icons/Close";
import SoftwareGridFilter from "./SoftwareGridFilter";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useDebounce } from "../../../../utils/use-debounce";

const allTabs = [
  {
    id: "all",
    title: "All",
    component: "",
  },
  {
    id: "MobileApps",
    title: "Mobile Apps",
    component: "",
  },
  {
    id: "WebPortals",
    title: "Web Portals",
    component: "",
  },
  {
    id: "Newsrooms",
    title: "Newsrooms",
    component: "",
  },
  {
    id: "Stores",
    title: "Stores",
    component: "",
  },
];

const useStyles = makeStyles({
  radioBtn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    marginTop: 16,
    marginBottom: 10,
  },
  deviceContainer: {
    display: "flex",
    alignItems: "center",
  },
  deviceBox: {
    width: "250px",
    padding: "13px 12px",
    color: "#2D3339",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "12px",
    marginLeft: 24,
  },
  textarea: {
    minHeight: "80px !important",
    border: "1px solid #E1E3EA !important",
    '&::placeholder': {
      color: '#7E8299 !important',
      fontSize: '12px !important',
      fontStyle: 'italic !important',
      fontWeight: 400,
      lineHeight: '12px !important',
    },
  },
  releaseContainer: {
    display: "flex",
    flexDirection: "column",
  },
  releasePlanHeading: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginTop: 28,
    marginBottom: 16,
  },
  releasePlanBtn: {
    minWidth: "42px",
    height: 30,
    padding: "10px 16px",
    borderRadius: "6px",
    background: "#3699FF",
    color: "white",
    "&.MuiButton-root:hover": {
      background: "#3699FF",
      color: "white",
    },
  },
  heading: {
    color: "#2D3339",
    fontSize: "22px",
    fontWeight: "700",
  },
  releaseDetails: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#434D56",
  },
  notificationConatiner: {
    width: "100%",
    marginTop: "28px",
    marginBottom: "28px",
    display: "flex",
    justifyContent: "center",
  },
  notifictionText: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#000",
  },
  notifictionTextColor: {
    color: "#3699FF",
    cursor: "pointer",
  },
  mainCustomerPricing: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "32px 0px",
  },
  customerGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: " space-between",
    padding: "19px 19px 19px 12px",
    background: "#F1FAFF",
    border: " 1px dashed #00A3FF",
    borderRadius: 9,
    marginBottom: 16,
    width: 460,
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customerPricingTypo: {
    fontSize: 18,
    fontWeight: 700,
    color: "#181C32",
    fontFamily: "Roboto",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "5px",
  },
  pricingTypoBody: {
    fontSize: 16,
    fontWeight: 700,
    color: "#3699FF",
    fontFamily: "Roboto",
  },
  changeButton: {
    height: 36,
    width: 82,
    padding: "10px 16px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    marginTop: "20px",
  },
  selectButton: {
    width: "73px",
    height: "36px",
    padding: "10px 16px",
    fontSize: 14,
    fontWeight: 500,
  },
  skipButton: {
    height: "36px",
    borderRadius: "6px",
    border: "1px solid #8D99A5",
    backgroundColor: "transparent",
    color: "#8D99A5",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    padding: "10px 16px",
    textTransform: "capitalize",
  },
  notificationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "28px",
  },
  notificationHeading: {
    color: "#2D3339",
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: 8,
  },
  notificationSubHeading: {
    color: "#000",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "12px",
  },
  afterReleaseTimeValueInput: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "0 !important",
      fontSize: "12px !important"
    },
    "& .MuiInputBase-input": {
      padding: "5px !important"
    },
    "& input[type='number']": {
      appearance: "none",
      "-moz-appearance": "textfield",
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        margin: 0,
        "-webkit-appearance": "none"
      }
    }
  },
  applyButton: {
    "&.MuiButton-root": {
      width: "69px",
      minWidth: "unset",
      height: "36px",
      fontSize: "14px",
      fontWeight: "500",
    }
  }
});
const options = [
  { label: "Day(s)", id: 1 },
  { label: "Month(s)", id: 2 },
  { label: "Year(s)", id: 3 },
];

const ReleaseNotesAccordian = ({
  setReleasePlanDone,
  accordionName,
  setAccordionName,
  setAccordionKey,
  formName,
  notificationTypes,
  selectedRelease,
  isLoading,
  selectedReleasePortal,
  selectedReleaseNotifications,
  softwareReleaseDetials,
  notificationData,
  setNotificationData,
  setReleaseNoteSelectedData,
  releaseNoteSelectedData,
  submitNotificationData,
  handleDeletedReleaseNotification,
  onSubmitRealsePlan,
}) => {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const [isSelectedDevice, setIsSelectedDevice] = useState(null);
  const { id: paramId, buildId: selectedBuildId } = useParams();
  const [releaseFilterNotificationType, setReleaseFilterNotificationType] =
    useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const defaultItemsToShow = 3;
  const [selectedOption, setSelectedOption] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [filterReleaseByName, setFilterReleaseByName] = useState("");
  const [notificationFilter, setNotificationFilter] = useState("")
  const { debouncedValue } = useDebounce(filterReleaseByName, 500);
  const [selectNotificationTab, setSelectNotificationTab] = useState("All");

  const portalNotificationTypes = notificationTypes.filter(type => {
    if (selectedReleasePortal.find(portal => portal.id === type.portalId)) { return type; } else return null;
  }).map(type => {
    return {
      ...type,
      portalId: selectedReleasePortal.find(portal => portal.id === type.portalId).portalId
    }
  });

  useEffect(() => {
    switch (selectNotificationTab) {
      case "MobileApps":
        const mobileFilteredPortals = selectedReleasePortal.filter(portal => portal.description.includes("Mobile App"));
        const mobileAppData = portalNotificationTypes.filter((device) => (mobileFilteredPortals.find(portal => portal.portalId === device.portalId)));
        setReleaseFilterNotificationType(mobileAppData);
        break;
      case "WebPortals":
        const webFilteredPortals = selectedReleasePortal.filter(portal => portal.description.includes("Web Portal"));
        const WebPortalData = portalNotificationTypes.filter((device) => (webFilteredPortals.find(portal => portal.portalId === device.portalId)));
        setReleaseFilterNotificationType(WebPortalData);
        break;
      case "Newsrooms":
        const newsFilteredPortals = selectedReleasePortal.filter(portal => portal.description.includes("Newsroom"));
        const newRoomsData = portalNotificationTypes.filter((device) => (newsFilteredPortals.find(portal => portal.portalId === device.portalId)));
        setReleaseFilterNotificationType(newRoomsData);
        break;
      case "Stores":
        const storeFilteredPortals = selectedReleasePortal.filter(portal => portal.description.includes("Store"));
        const newStoreData = portalNotificationTypes.filter((device) => (storeFilteredPortals.find(portal => portal.portalId === device.portalId)));
        setReleaseFilterNotificationType(newStoreData);
        break;
      default:
        setReleaseFilterNotificationType(portalNotificationTypes);
        break;
    }
  }, [selectedReleasePortal, selectNotificationTab]);

  useEffect(() => {
    if (filterReleaseByName !== "") {
      const filter = portalNotificationTypes.filter(
        (c) =>
          c.name?.toLowerCase().includes(filterReleaseByName.toLowerCase()) ||
          c.type?.toLowerCase().includes(filterReleaseByName.toLowerCase())
      );
      setReleaseFilterNotificationType(filter);
    } else {
      setReleaseFilterNotificationType(portalNotificationTypes);
    }
  }, [debouncedValue, notificationTypes]);

  const filteredSelectedNotifications = notifications.filter(
    (X) =>
      X?.name?.toLowerCase().includes(notificationFilter.toLocaleLowerCase()) ||
      X?.type?.toLowerCase().includes(notificationFilter.toLocaleLowerCase())
  );

  useEffect(() => {
    if (softwareReleaseDetials.length) {
      const releaseDetails = softwareReleaseDetials[0];
      if (releaseDetails?.releaseDateForAll) {
        setSelectedOption('1');
        setReleaseNoteSelectedData({
          ...releaseDetails,
          softwareReleasePortalDetails: [],
        });
      } else {
        setSelectedOption('2');
        setReleaseNoteSelectedData({
          ...releaseDetails,
          releaseDate: "",
          releaseTime: "",
        });
      }
    } else {
      setSelectedOption('1');
      setReleaseNoteSelectedData({
        id: 0,
        softwareReleaseBuildId: paramId ? selectedRelease?.id : null,
        releaseDate: "",
        softwareReleaseId: selectedBuildId ? selectedRelease?.softwareReleaseId : selectedRelease?.id,
        releaseDateForAll: true,
        releaseNotes: "",
        softwareReleasePortalDetails: [],
      });
    }
  }, [softwareReleaseDetials, selectedRelease.id]);

  useEffect(() => {
    if (selectedReleaseNotifications.length > 0 && portalNotificationTypes.length > 0) {
      setNotifications(() => {
        return selectedReleaseNotifications.map((notification) => {
          const notificationType = portalNotificationTypes.find(type => type.id === notification.notificationTypeId);
          return {
            ...notification,
            name: notificationType.name,
            type: notificationType.type,
          };
        })
      });
    }
  }, [selectedReleaseNotifications, notificationTypes]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value == "2") {
      const softwareReleasePortalDetails = selectedReleasePortal.map((portal) => ({
        releaseDate: format(new Date(), "MM/dd/yyyy"),
        releaseTime: null,
        softwareReleasePortalId: portal.id || "",
        softwareReleaseDetailId: 0,
      }));

      setReleaseNoteSelectedData({
        id: 0,
        softwareReleaseId: selectedRelease.id,
        releaseDateForAll: false,
        releaseDate: "",
        releaseTime: "",
        releaseNotes: releaseNoteSelectedData.releaseNotes,
        softwareReleasePortalDetails,
      });
    } else {
      const initialObject = {
        id: 0,
        softwareReleaseId: selectedRelease.id,
        releaseDateForAll: true,
        releaseDate: format(new Date(), "MM/dd/yyyy"),
        releaseTime: "",
        releaseNotes: releaseNoteSelectedData.releaseNotes,
        softwareReleasePortalDetails: [],
      };

      setReleaseNoteSelectedData(initialObject);
    }
  };

  const handleDateChange = (date, id) => {
    if (selectedOption === "1") {
      setReleaseNoteSelectedData({ ...releaseNoteSelectedData, releaseDate: date });
    } else {
      setReleaseNoteSelectedData((prevSelectedData) => {
        const updatedDetails = prevSelectedData.softwareReleasePortalDetails.map((detail) => {
          if (detail.softwareReleasePortalId === id) {
            return {
              ...detail,
              releaseDate: date,
            };
          }
          return detail;
        });

        return {
          ...prevSelectedData,
          softwareReleasePortalDetails: updatedDetails,
        };
      });
    }
  };

  const handleTimeChange = (id, date, hour, minute, period, portal) => {
    const formattedTime = `${hour}:${minute} ${period}`;

    if (selectedOption === "1") {
      setReleaseNoteSelectedData({ ...releaseNoteSelectedData, releaseTime: formattedTime });
    } else {
      setReleaseNoteSelectedData({ ...releaseNoteSelectedData, releaseTime: "" });
      setReleaseNoteSelectedData((prevSelectedData) => {
        const updatedDetails = prevSelectedData.softwareReleasePortalDetails.map((detail) => {
          if (detail.softwareReleasePortalId === id) {
            return {
              ...detail,
              releaseTime: formattedTime,
            };
          }
          return detail;
        });

        return {
          ...prevSelectedData,
          softwareReleasePortalDetails: updatedDetails,
        };
      });
    }
  };

  const handelSelectedDevice = (item) => {
    setIsSelectedDevice(item);
    setNotificationData({
      ...notificationData,
      portalId: item.portalId,
      notificationTypeId: item.id,
    });
  };

  const handleNotificationChangeData = (type, value) => {
    setNotificationData((prevData) => ({
      ...prevData,
      [type]: value,
    }));
  };

  const handleNotificationItem = () => {
    setSelectedItem((prevItems) =>
      prevItems === defaultItemsToShow
        ? selectedReleaseNotifications.length
        : defaultItemsToShow
    );
  };

  const onSetAccorditionAction = () => {
    setAccordionName("");
    if ((formName === "Release" && !paramId) || (formName === "Build" && !selectedBuildId)) {
      setReleasePlanDone(true);
      setAccordionKey(5);
    }
  }

  return (
    <Grid className="releaseNoteContainer" key={releaseNoteSelectedData.id}>
      <Divider />
      <Grid>
        <Typography className="headTittleRelease">{`${formName} `} Details</Typography>
        <span className="content">
          Select the {` ${formName.toLowerCase()} `} date and add any release notes related to the
          software {` ${formName.toLowerCase()}`}.
        </span>
      </Grid>
      <Grid>
        <Typography className="titleRelease">{`${formName} `} Date and Time</Typography>
      </Grid>

      {releaseNoteSelectedData && (
        <>
          <Grid>
            <Grid className={classes.radioBtn}>
              <input
                type="radio"
                id="releaseSoftwareDatePortal"
                name="releaseDate"
                value="1"
                style={{ width: "16px", height: "16px" }}
                checked={
                  selectedOption == "1" || releaseNoteSelectedData?.releaseDateForAll
                }
                onChange={handleOptionChange}
                disabled={accordionName !== "notificationRelease"}
              />
              <label for="releaseSoftwareDatePortal">
                <span className="radioBtnLable">
                  {`${formName} `} software on all portals at
                </span>
              </label>
            </Grid>
            <div className="releaseDateContainer">
              <ReleaseDate
                disabled={
                  selectedOption !== "1" ||
                  accordionName !== "notificationRelease" ||
                  releaseNoteSelectedData?.releaseDateForAll !== true
                }
                onDateChange={(date, id) => handleDateChange(date, id)}
                onTimeChange={(date, hour, minute, period, id) =>
                  handleTimeChange(id, date, hour, minute, period)
                }
                value={releaseNoteSelectedData?.releaseDate}
                timeValue={releaseNoteSelectedData?.releaseTime}
                id={0}
              />
            </div>
          </Grid>
          <Grid>
            {selectedReleasePortal.length ? <Grid className={classes.radioBtn}>
              <input
                type="radio"
                id="releaseSoftwareDate"
                name="releaseDate"
                style={{ width: "16px", height: "16px" }}
                value="2"
                checked={
                  selectedOption === "2" ||
                  releaseNoteSelectedData?.releaseDateForAll === false
                }
                onChange={handleOptionChange}
                disabled={accordionName !== "notificationRelease"}
              />
              <label for="releaseSoftwareDate">
                <span className="radioBtnLable">
                  {`${formName} `} software on specific portals at certain times
                </span>
              </label>
            </Grid> : null}
            {selectedReleasePortal.map((portal) => (
              <Grid className={classes.deviceContainer}>
                <span className={classes.deviceBox}>{`${portal.name} (${portal.description})`}</span>
                <ReleaseDate
                  disabled={
                    selectedOption !== "2" ||
                    accordionName !== "notificationRelease" ||
                    releaseNoteSelectedData?.releaseDateForAll === true
                  }
                  onDateChange={(date, id) => handleDateChange(date, id)}
                  onTimeChange={(date, hour, minute, period, id) =>
                    handleTimeChange(id, date, hour, minute, period)
                  }
                  value={
                    releaseNoteSelectedData?.softwareReleasePortalDetails?.find(
                      (x) => x.softwareReleasePortalId == portal.id
                    )?.releaseDate
                  }
                  timeValue={
                    releaseNoteSelectedData?.softwareReleasePortalDetails?.find(
                      (x) => x.softwareReleasePortalId == portal.id
                    )?.releaseTime
                  }
                  id={portal.id}
                />
              </Grid>
            ))}
          </Grid>
          <Grid>
            <Typography className="titleRelease">{`${formName} `}Notes</Typography>
            <Grid item md={12} className="textAriaContainer">
              <EmptyTextArea
                className={classes.textarea}
                direction="(5000 characters max)"
                placeholder={`Enter ${`${formName} `} notes`}
                disabled={accordionName !== "notificationRelease"}
                value={releaseNoteSelectedData.releaseNotes}
                onChange={(e) => setReleaseNoteSelectedData({ ...releaseNoteSelectedData, releaseNotes: e.target.value })}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid className={classes.releaseContainer}>
        <Grid className={classes.releasePlanHeading}>
          {accordionName === "notificationRelease" && (
            <Button
              className={classes.releasePlanBtn}
              onClick={() => {
                setOpenPopup(true);
              }}
            >
              <AddIcon />
            </Button>
          )}
          <span className={classes.heading}>
            Software {`${formName} `} Notifications
          </span>
        </Grid>
        <Grid className={classes.releaseDetails}>
          Select and customize notifications sent to eligible contacts regarding
          this software {formName}.
        </Grid>
      </Grid>
      {selectedReleaseNotifications.length > 0 ? (
        <>
          <Grid xs={12} className="userRequirementSearch">
            <Typography variant="body1">
              {selectedReleaseNotifications?.length ?? 0} OFish-Powered Portals
              Applied
            </Typography>
            <SoftwareGridFilter
              search={notificationFilter}
              setSearch={setNotificationFilter}
              placeholder={"Search"}
            />
          </Grid>
          <Grid className="allChipsContainer">
            <List>
              <ListItem>
                <Grid className="chevronIcon">
                  <IconButton onClick={handleNotificationItem}>
                    <ChevronRight />
                  </IconButton>
                </Grid>
                <Grid className="chipsItemGrid">
                  {filteredSelectedNotifications.slice(0, selectedItem).map((item) => (
                    <Grid className="chipsContainer">
                      <Typography className="chipsTypo">{item.name}</Typography>
                      &nbsp;
                      <Typography className="deviceName">
                        {item.type}
                      </Typography>
                      <div className="closeBtn">
                        {accordionName === "notificationRelease" ? (
                          <i onClick={() => {
                            setIsSelectedDevice(item);
                            setNotificationData(item);
                            setOpenPopup(true)
                          }}>
                            <EditIconSmall />
                          </i>
                        ) : null}
                      </div>
                      <div
                        className="closeBtn"
                        onClick={() => {
                          handleDeletedReleaseNotification(item.id);
                        }}
                      >
                        <CloseIcon />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </>
      ) : (
        <Grid className={classes.notificationConatiner}>
          <span className={classes.notifictionText}>
            You haven’t added any notifications. Start by adding a{" "}
            <span
              onClick={() => {
                setOpenPopup(true);
              }}
              className={classes.notifictionTextColor}>
              Notification
            </span>
          </span>
        </Grid>
      )}

      {!isLoading ? <Grid className="btn_save_next">
        {accordionName === "notificationRelease" && (
          <>
            <Button
              color="primary"
              variant="contained"
              className="skipButton"
              onClick={() => { onSetAccorditionAction() }}
            >
              Skip
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => {
                onSetAccorditionAction();
                onSubmitRealsePlan();
              }}
            >
              Next
            </Button>
          </>
        )}
      </Grid> :
        <Grid item className="btn_save_next">
          <CircularProgress />
        </Grid>}
      <CustomSoftwareReleasePopup
        title={"Software Release Notifications"}
        heading={"Notifications"}
        description={
          `Select and customize notifications sent to eligible contacts regarding this software ${formName.toLowerCase()}.`
        }
        filterPlaceholder={"Search"}
        allTabs={allTabs}
        tabChanged={(newTab) =>
          setSelectNotificationTab(newTab)
        }
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        isSelectedDevice={isSelectedDevice}
        setIsSelectedDevice={setIsSelectedDevice}
        setFilterReleaseByName={setFilterReleaseByName}
        onPopupClose={() => {
          setIsSelectedDevice(null);
        }}
      >
        {isSelectedDevice === null ? (
          <Grid>
            <Grid item xs={12} className={classes.mainCustomerPricing}>
              {releaseFilterNotificationType.map((item) => (
                <Grid key={item.id}>
                  <Grid className={classes.customerGrid}>
                    <Box className={classes.innerBox}>
                      <Box>
                        <Typography className={classes.customerPricingTypo}>
                          {item.type}
                        </Typography>
                        <Typography className={classes.pricingTypoBody}>
                          {item.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.selectButton}
                      onClick={() => {
                        handelSelectedDevice(item);
                      }}
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid className={classes.buttonContainer}>
              <Button onClick={() => {
                setIsSelectedDevice(null);
                setOpenPopup(false);
              }} className={classes.skipButton}>Cancel</Button>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Grid container className={classes.notificationContainer}>
              <Grid key={isSelectedDevice.id}>
                <Grid className={classes.customerGrid}>
                  <Box className={classes.innerBox}>
                    <Box>
                      <Typography className={classes.customerPricingTypo}>
                        {isSelectedDevice.type}
                      </Typography>
                      <Typography className={classes.pricingTypoBody}>
                        {isSelectedDevice.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.changeButton}
                    onClick={() => setIsSelectedDevice(null)}
                  >
                    Change
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <span className={classes.notificationHeading}>
                Send Notification:
              </span>
              <Grid className="checkBoxConatiner">
                <SortedByFilterCheckbox
                  handleCheckboxChange={() =>
                    handleNotificationChangeData(
                      "isImmediate",
                      !notificationData.isImmediate
                    )
                  }
                  checked={notificationData.isImmediate}
                />
                <span className={classes.notificationSubHeading}>
                  Immediately upon {formName.toLowerCase()}
                </span>
              </Grid>
              <Grid className="checkBoxConatiner">
                <SortedByFilterCheckbox
                  handleCheckboxChange={() => {
                    handleNotificationChangeData(
                      "isImmediate",
                      !notificationData.isImmediate
                    );
                  }}
                  checked={!notificationData.isImmediate}
                />
                <span className={classes.notificationSubHeading}>After</span>
                <TextField
                  id="outlined-basic"
                  type="number"
                  className={`textFild ${classes.afterReleaseTimeValueInput}`}
                  variant="outlined"
                  disabled={notificationData.isImmediate}
                  onChange={({ target }) => {
                    const inputValue = parseInt(target.value);
                    const validInput = isNaN(inputValue) ? '' : Math.max(0, inputValue);
                    handleNotificationChangeData(
                      "afterReleaseValue",
                      parseInt(validInput)
                    )
                  }
                  }
                  value={notificationData.afterReleaseValue}
                />
                <Autocomplete
                  className={`autocomplete ${classes.afterReleaseTimeValueInput}`}
                  options={options}
                  popupIcon={<FiChevronDown />}
                  getOptionLabel={(option) => option.label}
                  disableClearable={true}
                  value={options.find(option => option.id === notificationData.afterReleaseTimeValue) || null}
                  disabled={notificationData.isImmediate}
                  onChange={(event, value) => {
                    handleNotificationChangeData(
                      "afterReleaseTimeValue",
                      value.id
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      classes="inputRoot"
                      placeholder="Select"
                    />
                  )}
                />
                <span className={classes.notificationSubHeading}>
                  of {formName.toLowerCase()}
                </span>
              </Grid>
              <Grid container xs={12} className="subjectContainer">
                <span>Subject</span>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter subject"
                  variant="outlined"
                  className="subjectInput"
                  onChange={({ target }) =>
                    handleNotificationChangeData("subject", target.value)
                  }
                  value={notificationData.subject}
                />
              </Grid>
              <Grid container xs={12} className="subjectContainer">
                <span>Notification Content</span>
                <EmptyTextArea
                  className={classes.textarea}
                  direction="(5000 characters max)"
                  placeholder="Enter content"
                  onChange={(e) =>
                    handleNotificationChangeData("content", e.target.value)
                  }
                  value={notificationData.content}
                />
              </Grid>
              <Grid className="notificationPopupButton">
                <Button
                  color="primary"
                  variant="contained"
                  className="skipButton"
                  onClick={() => setIsSelectedDevice(null)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    submitNotificationData(notificationData);
                    setIsSelectedDevice(null);
                    setOpenPopup(false);
                  }}
                  className={classes.applyButton}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CustomSoftwareReleasePopup>
    </Grid>
  );
};

export default ReleaseNotesAccordian;
