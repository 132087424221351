import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";
import { toast } from "react-toastify";
import { LightenDarkenColor } from "lighten-darken-color";
import {
  EditIcon,
  SoftwareReleaseDeleteIcon,
  SoftwareReleaseEditIcon,
  SoftwareReleaseEyeIcon,
  SoftwareReleaseFavFilledIcon,
  SoftwareReleaseOulinedFavIcon,
  SortIcon,
} from "../../../LeftNav/SvgIcons";
import { DeleteFilesOverlay } from "../../../../../components/common/Modal/AssetsModals";
import { conversationAction } from "../../../../../store/conversations/conversationAction";

const useStyles = makeStyles(theme => ({
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeaderInnerCenter: {
    color: "#828282",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
    textAlign: "center",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
    "& .MuiTableCell-root": {
      borderBottom: "unset",
    },
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#2D3339",
    fontSize: "12px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  statusInActiveContainer: {
    width: "75px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e3a496",
    borderRadius: "6px",
    color: "#e06145",
    fontSize: "14px",
    fontWeight: "700",
  },
  statusActiveContainer: {
    width: "75px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#BFEAE8",
    borderRadius: "6px",
    color: "#00ABA3",
    fontSize: "14px",
    fontWeight: "700",
  },
  gridBodyInnerName: {
    color: "#3699FF",
    fontSize: "14px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  businessGroup: {
    background: "#E5F2FF",
    color: "#8254E3",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
  editButton: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  firstChildRadius: {
    borderRadius: "6px 0px 0px 6px",
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  lastChildRadius: {
    borderRadius: "0px 6px 6px 0px",
    textAlign: "center",
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
    textAlign: "end",
    paddingRight: "70px",
  },
}));

function AllContactsGrid({ 
  allCampGrid, 
  order, 
  onOrderBy,
  setIsConversationResponse,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isConversationSettingDelete, setIsConversationSettingDelete] =
    useState(false);
  const [deletedConversationType, setDeletedConversationType] = useState(null);
  const markConversationFav = item => {
    dispatch(conversationAction.onMakeConversationFavourite([
      {
        campaignId: item.id,
        isFavourite: !item?.isFavourite
      }
    ])).then(
      data => {
        if (data.responseMsg === "Data Updated Successfully") {
          setIsConversationResponse(data.responseMsg);
          toast.success(
            "Conversation Type is successfully mark as Favourite/UnFavourite"
          );
        } else {
          toast.error("Something went wrong!");
        }
      }
    );
  };
  const onDeleteConversation = () => {
    dispatch(
      conversationAction.onDeleteConversationType(deletedConversationType)
    )
      .then(data => {
        if (data.responseMsg === "Deleted Successfully") {
          setIsConversationResponse(data.responseMsg);
          toast.success(
            "Conversation Type is Deleted Successfully"
          );
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch(error => {
        console.error("Error occurred:", error);
        toast.error("An error occurred. Please try again later.");
      });
    setIsConversationSettingDelete(false);
    setDeletedConversationType(null);
  };
  const onEditButtonClick = ()=>{
    dispatch(
      conversationAction.onChange("basicConversationData", {
        productId: 0,
        campaignId: 0,
        nameInternal: "",
        namePublic: "",
        color: "",
        shortDescription: "",
        channels: [],
        isPublic: false,
        isGroupChat: false,
        isDeplayedInAdminInbox: false,
        type: "",
        status: 1,
      })
    );
    dispatch(
      conversationAction.onChange("conversationPermissionData", [])
    );
    dispatch(
      conversationAction.onChange("selectedStartConversationPermission", [])
    );
    dispatch(
      conversationAction.onChange("selectedReciveNewConversationPermission", [])
    );
    dispatch(
      conversationAction.onChange("selectedConversationChannels", [])
    );
    dispatch(
      conversationAction.onChange("selectedConversationPermission", [])
    );
  }
  return (
    <Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.firstChildRadius}>
              Conversation Type Name
              <TableSortLabel
                active={order.orderByKey === "Name"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Name",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Conversation Size
              <TableSortLabel
                active={order.orderByKey === "ConversationSize"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "ConversationSize",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              Conversation Tag
              <TableSortLabel
                active={order.orderByKey === "Status"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Status",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.lastChildRadius}>
              Quick Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allCampGrid?.map(item => {
            return (
              <TableRow key={item.userId} className='gridBodyRow'>
                <TableCell className={classes.gridBodyInnerName}>
                  {`${item.name}`}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.conversationSize}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <div
                    className={
                      item?.status === "InActive"
                        ? classes.statusInActiveContainer :
                        item?.status === "Active" ?
                        classes.statusActiveContainer : null
                    }>
                    { item?.status ?? "--" }
                  </div>
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {/* <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onEditButtonClick(item.id);
                    }}>
                    <EditIcon />
                  </span> */}
                  <TableCell className={classes.editButton}>
                    <IconButton
                      onClick={() => {
                        markConversationFav(item);
                      }}>
                      {item?.isFavourite ? (
                        <SoftwareReleaseFavFilledIcon />
                      ) : (
                        <SoftwareReleaseOulinedFavIcon />
                      )}
                    </IconButton>

                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        onEditButtonClick(item.id);
                        history.push(`/conversation-type/${item.id}/edit`);
                      }}>
                      <SoftwareReleaseEditIcon />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        onEditButtonClick(item.id);
                        history.push(`/conversation-type/${item.id}`);
                      }}>
                      <SoftwareReleaseEyeIcon />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        setIsConversationSettingDelete(
                          !isConversationSettingDelete
                        );
                        setDeletedConversationType(item.id);
                      }}>
                      <SoftwareReleaseDeleteIcon
                        style={{ cursor: "pointer" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableCell>
              </TableRow>
            );
          })}
          {allCampGrid.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any contact.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isConversationSettingDelete ? (
        <DeleteFilesOverlay
          heading={"Delete Conversation Setting"}
          openDeleteFilesOverlay={isConversationSettingDelete}
          setOpenDeleteFilesOverlay={value => {
            setIsConversationSettingDelete(value);
          }}
          deleteAssetFiles={() => {
            onDeleteConversation();
          }}
          overlayText1={"Are you sure you want to Delete Conversation Setting?"}
          overlayText2={""}
          buttonText={"Delete"}
        />
      ) : null}
    </Paper>
  );
}

export default AllContactsGrid;
