import React from "react";
import Dashbard from "../Dashbard";
import { ValidatorForm } from "react-material-ui-form-validator";
import EnduserGroupOverview from "./EnduserGroupOverview";

const NewEndUserGroup = props => {
  return (
    <Dashbard>
      <div className="body-content">
        <ValidatorForm ref={React.useRef("form")}>
          <>
            <EnduserGroupOverview />
          </>
        </ValidatorForm>
      </div>
    </Dashbard>
  );
};

export default NewEndUserGroup;
