import React from "react";
import Button from "@material-ui/core/Button";

function RelatedAssets({ picture, heading, subheading }) {
  return (
    <div className='RelatedAssetsWrapper'>
      <ul>
        <li>
          <span className='pressPicture'>
            <img src={picture} alt='assst-img' />
          </span>
        </li>
        <li>
          <h2>{heading}</h2>
          <span className='subheading' style={{ marginTop: "7px" }}>
            {subheading}
          </span>
        </li>
      </ul>

      <Button variant='contained' color='primary'>
        View Assets
      </Button>
    </div>
  );
}

export default RelatedAssets;
