import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import queryString from "query-string";
import Dashbard from "../Dashbard";
import EmptyTextarea from "../../../../components/common/Textarea";
import InputField from "../../../../components/common/InputField";
import Loader from "../../../../components/common/Loader";
import SelectInput from "../../../../components/common/SelectInput";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { ProductsListing } from "./ProductsListing";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { ImageUploader } from "../../../../components/common/ImageUploader";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    marginBottom: "0px",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "63px",
    left: "0",
    backgroundColor: "#ffffff",
    zIndex: "5",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
  setLaunched: {
    backgroundColor: "#00ABA3",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    // border: "1px solid #70808F",
    borderRadius: "6px",
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
}));

const NewProductCategory = props => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brand, setBrand] = useState(0);
  const [categoryName, setCategoryName] = useState("");
  const [parentCategory, setParentCategory] = useState(0);
  const [categorySlug, setCategorySlug] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [status, setStatus] = useState(1);
  const [showOnStore, setShowOnStore] = useState(0);
  const [showOnRegistration, setShowOnRegistration] = useState(0);

  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [featureImgUpload, setFeatureImgUpload] = useState(null);

  useEffect(() => {
    dispatch(
      productCategoriesActions.onGetAllProductCategories({
        pageNumber: 1,
        noOfRecoreds: 100,
        orderByKey: "",
        orderByVal: 0,
      })
    ).then(data => setCategories(data?.data?.data));
  }, [dispatch]);

  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllBrands()).then(data =>
      setBrands(data?.data)
    );
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    const productCategory = queryString.parse(location.search);
    dispatch(
      productCategoriesActions.getProductCategoryById(productCategory?.id)
    ).then(data => {
      setLoading(false);
      if (!data) return;
      const { data: category } = data;
      if (category) {
        setCategory(category);
        setBrand(category.brandId);
        setCategoryName(category.categoryName);
        setParentCategory(category.parentId);
        setCategorySlug(category.slug);
        setShortDesc(category.description);
        setStatus(category.status);
        setShowOnStore(category.showOnStore);
        setShowOnRegistration(category.showInRegistration);
      }
    });

    return () => {
      setCategory(null);
    };
  }, [dispatch, location]);

  const handleSubmit = e => {
    const fileName = featureImgUpload?.url;
    // fileName = fileName ? last(fileName.split("/")) : "";
    setLoading(true);
    const newProductCategory = {
      id: category ? category.id : 0,
      brandId: brand,
      categoryName: categoryName,
      parentId: parentCategory,
      slug: categorySlug,
      description: shortDesc,
      status: status,
      showOnStore: showOnStore,
      showInRegistration: showOnRegistration,
      featureImage: fileName || category?.featureImage || "",
    };
    dispatch(
      productCategoriesActions.onCreateProductCategory(newProductCategory)
    ).then(data => {
      if (data && data.success) {
        history.goBack();
      }
      setLoading(false);
    });
  };

  return (
    <Dashbard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <ValidatorForm ref={React.useRef("form")} onSubmit={handleSubmit}>
          <div className='contentHeading'>
            <h1>
              {category ? category?.categoryName : "New Product Category"}
            </h1>
            <div className='dashboardNav'>
              <Button
                color='primary'
                variant='contained'
                className='dashboardButtons'
                type='submit'>
                Save Category
              </Button>
            </div>
          </div>
          <div className='newProductsCategory'>
            <AccordionContainer title='Product Category Overview'>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={3} className={classes.gridWrapper}>
                      <SelectInput
                        selectlabel='Brand'
                        placeholder='Please Select'
                        customMenuItems={brands?.map(b => {
                          return {
                            id: b.brandId,
                            name: b.brandName,
                          };
                        })}
                        required={true}
                        value={brand}
                        handleChange={({ target }) => setBrand(target.value)}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.gridWrapper}>
                      <InputField
                        inputlabel='Category Name'
                        placeholder='Category Name'
                        id='CategoryName'
                        name='CategoryName'
                        validators={["required", "matchRegexp:^.{0,50}$"]}
                        errorMessages={[
                          "Please enter category name",
                          "Maximum length is 50 characters",
                        ]}
                        value={categoryName}
                        onChange={({ target }) => setCategoryName(target.value)}
                      />
                    </Grid>

                    <Grid item xs={3} className={classes.gridWrapper}>
                      <SelectInput
                        selectlabel='Parent Category?'
                        placeholder='Please Select'
                        value={parentCategory}
                        required={false}
                        customMenuItems={categories
                          ?.filter(c => c.productCategoryId !== category?.id)
                          .map(c => {
                            return {
                              id: c.productCategoryId,
                              name: c.categoryName,
                            };
                          })}
                        handleChange={({ target }) =>
                          setParentCategory(target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={3} className={classes.gridWrapper}>
                      <InputField
                        inputlabel='Category Slug'
                        placeholder='Category Slug'
                        id='CategorySlug'
                        name='CategorySlug'
                        validators={["required", "matchRegexp:^.{0,50}$"]}
                        errorMessages={[
                          "Please enter category slug",
                          "Maximum length is 50 characters",
                        ]}
                        value={categorySlug}
                        onChange={({ target }) => setCategorySlug(target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridWrapper}>
                      <EmptyTextarea
                        maxLength={500}
                        textLabel='Short Description'
                        textSubtitle='(500 characters max)'
                        value={shortDesc}
                        onChange={({ target }) => setShortDesc(target.value)}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <SelectInput
                        selectlabel='Status'
                        required={false}
                        value={status}
                        customMenuItems={[
                          { id: 0, name: "Inactive" },
                          { id: 1, name: "Active" },
                        ]}
                        handleChange={({ target }) => setStatus(target.value)}
                      />
                    </Grid>

                    <Grid item xs={4} className={classes.gridWrapper}>
                      <SelectInput
                        selectlabel='Show On Store'
                        required={false}
                        value={showOnStore}
                        customMenuItems={[
                          { id: 0, name: "No" },
                          { id: 1, name: "Yes" },
                        ]}
                        handleChange={({ target }) =>
                          setShowOnStore(target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.gridWrapper}>
                      <SelectInput
                        selectlabel='Show On Registration'
                        required={false}
                        value={showOnRegistration}
                        customMenuItems={[
                          { id: 0, name: "No" },
                          { id: 1, name: "Yes" },
                        ]}
                        handleChange={({ target }) =>
                          setShowOnRegistration(target.value)
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={(classes.gridWrapper, classes.featuredImage)}>
                      <label className={classes.featuredImageHeading}>
                        Featured Image
                      </label>
                      {(featureImgUpload?.url || category?.featureImage) && (
                        <img
                          alt='feature iamge'
                          className='featureRoundImg'
                          src={featureImgUpload?.url || category?.featureImage}
                        />
                      )}
                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.selectBtn}
                        onClick={() => setShowFeatureImage(!showFeatureImage)}>
                        Select
                      </Button>
                      <span className={classes.selectSubtitle}>
                        Select a featured image from your media library.
                      </span>

                      <ImageUploader
                        showFeatureImage={showFeatureImage}
                        setShowFeatureImage={() =>
                          setShowFeatureImage(!showFeatureImage)
                        }
                        onImageSelect={img => {
                          setFeatureImgUpload(img);
                          setShowFeatureImage(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </AccordionContainer>
          </div>
          <ProductsListing categoryId={category?.id} />
        </ValidatorForm>
      </div>
    </Dashbard>
  );
};

export { NewProductCategory };
