import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import MultiSelectAssetFilter from "./MultiSelectAssetFilter";
import InputField from "../../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import { searchIcon } from "../../../../resources/images";
import { FiChevronDown } from "react-icons/fi";
import { Dropdown } from "../../../../components/common";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { OpenInNew } from "@material-ui/icons";
import { DateCreated } from "../../../../config/Enums";
import { useDebounce } from "../../../../utils/use-debounce";

const useStyles = makeStyles(theme => ({
  filterModal: {
    minWidth: "700px",
    minHeight: "170px",
    boxShadow: `0px 0px 10px rgb(0 0 0 / 14%)`,
    display: "flex",
    flexDirection: "column",
    // position: "absolute",
    backgroundColor: "#fff",
    // right: "30px",
    padding: "29px  33px",
    boxSizing: "border-box",
  },
}));

const GridFilterBar = ({
  filterBy = {
    product: "",
    categoryId: null,
    dateCreated: null,
    status: null,
  },
  onFilterButtonPress,
  onClearButtonPress,
  removeGridFilter= false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(filterBy);
  // const [isFilterShow, setIsFilterShow] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllProductSubCategories(0)).then(
      data => setCategories(data?.data)
    );
  }, [dispatch]);

  const { product, categoryId, dateCreated, status } = filters;
  const { debouncedValue } = useDebounce(product, 500);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isFilterShow = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onclear = () => {
    setFilters({
      product: "",
      categoryId: null,
      dateCreated: null,
      status: null,
    });
  };

  useEffect(() => {
    if (debouncedValue) {
      onFilterButtonPress(filters);
    } else onClearButtonPress();
  }, [debouncedValue]);

  return (
    <div className='gridFilterBar'>
      <ValidatorForm ref={useRef("form")} onSubmit={() => {}}>
        <Grid container spacing={2} style={{flexWrap:"nowrap"}}>
          <Grid
            item
            md={removeGridFilter ? 12 : 10}
            className="filterMainSearch"
            style={{
              marginRight: removeGridFilter ? "8px" : null,
            }}
          >
            <img src={searchIcon} alt='' className='searchIcon' />
            <InputField
              placeholder='Search'
              value={product}
              onChange={({ target }) =>
                setFilters({
                  ...OpenInNew,
                  product: target.value,
                })
              }
            />
          </Grid>
          {!removeGridFilter && (
            <>
              <Grid item md={removeGridFilter ? null : 2} className='searchByFilter' onClick={handleClick}>
                Search by Filter <FiChevronDown />
              </Grid>

              <Menu
                className='gridFilterBarMui'
                anchorEl={anchorEl}
                open={isFilterShow}
                onClose={handleClose}
              >
                <Grid container className={classes.filterModal}>
                  <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}>
                    <Grid item sm={4}>
                      Filter by
                    </Grid>
                    <Grid
                      item
                      sm={8}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          handleClose();
                          onFilterButtonPress && onFilterButtonPress(filters);
                        }}>
                        Filter
                      </Button>
                      <Button
                        variant='text'
                        color='primary'
                        onClick={() => {
                          handleClose();
                          onClearButtonPress && onClearButtonPress();
                          onclear();
                        }}>
                        Clear
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction='row'
                    spacing={2}
                    style={{
                      marginTop: '7px',
                    }}>
                    <Grid item sm={3}>
                      <Dropdown
                        selectlabel=''
                        value={categoryId === null ? -1 : categoryId}
                        handleChange={({ target }) =>
                          setFilters({
                            ...filters,
                            categoryId: target.value,
                          })
                        }
                        placeholder='Product Category'
                        customMenuItems={categories?.map((c) => {
                          return {
                            id: c.id,
                            name: c.value,
                          };
                        })}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Dropdown
                        selectlabel=''
                        value={dateCreated === null ? -1 : dateCreated}
                        handleChange={({ target }) =>
                          setFilters({
                            ...filters,
                            dateCreated: target.value,
                          })
                        }
                        placeholder='Product Created'
                        customMenuItems={DateCreated}
                      />
                    </Grid>

                    <Grid item sm={3}>
                      <Dropdown
                        selectlabel=''
                        value={status === null ? -1 : status}
                        handleChange={({ target }) => {
                          setFilters({
                            ...filters,
                            status: target.value,
                          });
                        }}
                        placeholder='Product Status'
                        customMenuItems={[
                          { id: 0, name: "In Active" },
                          { id: 1, name: "Active" },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Menu>
            </>
          )}
        </Grid>
      </ValidatorForm>
    </div>
  );
};

export { GridFilterBar };
