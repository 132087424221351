import React from "react";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import GeneralHeading from "../../../../components/common/GeneralHeading";

function Activity(props) {
  return (
    <>
      <GeneralHeading
        headingName="New Event Category"
        buttonText="Save Category"
      />
       <div className="eventOverviewWrapper">
       <AccordionContainer title="Activity"></AccordionContainer>
       </div>
     
    </>
  );
}

export default Activity;
