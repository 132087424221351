import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, EditIcon, TickMark } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import InputField from "../../../../components/common/InputField";
import CustomCheckbox from "../../../../components/common/checkbox/CustomCheckbox";
import Autocomplete from "react-google-autocomplete";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { toast } from "react-toastify";
import { RedCross } from "../../LeftNav/SvgIcons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { DeleteFilesOverlay } from "../../../../components/common/Modal/AssetsModals";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

const NewEnvironmentGrid = ({
  userId,
  loadUserEnv,
  setLoading,
  userAddress,
  setIsNewEnv,
  isNewEnv,
  envList,
  tempEnvData,
  onClickAddNewEnv,
}) => {
  const dispatch = useDispatch();

  const { envWithDeviceCount } = useSelector(state => state.endUserReducer);
  const [editItem, setEditItem] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [environmentAddress, setEnvironmentAddress] = useState("");
  const [envName, setEnvName] = useState("");
  const [envIcon, setEnvIcon] = useState(null);
  const [envLat, setEnvLat] = useState("");
  const [envLong, setEnvLong] = useState("");
  const [sameAddress, setSameAddress] = useState(false);
  const [isDeletedItem , setIsDeletedItem] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (isNewEnv) {
      let aa = document.getElementById("popupAddEnvLocInput");
      if (aa) {
        aa.value = "";
        setEditItem(false);
        setEditItemId(null);
        setEnvironmentAddress("");
        setEnvName("");
        setEnvIcon(null);
        setEnvLat("");
        setEnvLong("");
        setSameAddress(false);
      }
    }
  }, [isNewEnv]);

  const onSaveEnv = () => {
    if (
      envName?.trim() !== "" &&
      environmentAddress?.trim() !== "" &&
      envIcon !== "" &&
      envIcon !== null
    ) {
      const envDataSet = {
        environmentId: 0,
        environmentName: envName,
        environmentIcon: envIcon,
        environmentType: "d",
        status: 1,
        userId: userId,
        location: environmentAddress,
        latitude: envLat,
        longitude: envLong,
        isSameAccountLocation: sameAddress,
      };
      setLoading(true);
      dispatch(endUserActions.onAddUpdateEnv(envDataSet)).then(data => {
        if (data && data.success) {
          loadUserEnv();
          toast.success("Environment is added successfully");
        } else {
          setLoading(false);
          if (data) {
            toast.error(data.message);
          }
        }
        setIsNewEnv();
      });
    } else {
      if (envName?.trim() === "") {
        toast.error("Environment Name cannot be empty");
      } else if (envIcon === "" || envIcon === null) {
        toast.error("Please select environment icon");
      } else {
        toast.error("Environment Address cannot be empty");
      }
    }
  };

  const updateEnvName = () => {
    if (envName?.trim() !== "" && environmentAddress?.trim() !== "") {
      const envDataSet = {
        environmentId: editItemId,
        environmentName: envName,
        environmentIcon: envIcon,
        environmentType: "d",
        status: 1,
        userId: userId,
        location: environmentAddress,
        latitude: envLat,
        longitude: envLong,
        isSameAccountLocation: sameAddress,
      };
      setLoading(true);
      dispatch(endUserActions.onAddUpdateEnv(envDataSet)).then(data => {
        if (data && data.success) {
          loadUserEnv();
          toast.success("Environment name is updated successfully");
        } else {
          setLoading(false);
          if (data) {
            toast.error(data.message);
          }
        }
        setEditItem(false);
        setEditItemId(null);
      });
    } else {
      if (envName?.trim() === "") {
        toast.error("Environment Name cannot be empty");
      } else {
        toast.error("Environment Address cannot be empty");
      }
    }
  };

  const onEditItemClick = item => {
    setEditItem(true);
    setEditItemId(item.id);
    setEnvIcon(item.icon);
    setEnvName(item.name);
    setEnvironmentAddress(item.location);
    setEnvLat(item.latitude);
    setEnvLong(item.longitude);
    setSameAddress(item.isSameAccountLocation);
  };

  const onSameAddressPress = test => {
    if (test.target.checked) {
      setSameAddress(true);
      setEnvironmentAddress(userAddress);
      setEnvLat("");
      setEnvLong("");
    } else {
      setSameAddress(false);
      setEnvironmentAddress("");
      setEnvLat("");
      setEnvLong("");
    }
  };
  const onPressDeleteEnv = (envId)=>{
    setIsDeletedItem(false)
    setLoading(true);
    dispatch(endUserActions.onDeleteEnv([envId])).then(data => {
      setLoading(false);
      if (data?.success) {
        loadUserEnv();
        toast.success("Environments deleted successfully");
      } else {
        toast.error(data?.message);
      }
    });
  }

  return (
    <>
      <div className='environmentList endUserEnvList enduserEnv'>
        <ValidatorForm ref={React.useRef("form")}>
          <ul>
            {tempEnvData &&
              tempEnvData.length > 0 &&
              tempEnvData.map((item, index) => {
                return (
                  <li key={index}>
                    <div className='environmentInnerListWrapper'>
                      <div>
                        <img src={item.icon} />
                        <span className='iconText'>{item.name}</span>
                      </div>

                      <span className='chavron tickMarkCross'>
                        {!isNewEnv && editItem && editItemId === item.id ? (
                          <>
                            <span
                              onClick={() => {
                                setEditItem(false);
                                setEditItemId(null);
                              }}>
                              <RedCross />
                            </span>
                            <span onClick={() => updateEnvName()}>
                              <TickMark />
                            </span>
                          </>
                        ) : (
                            <div className="environmentInnerActionButton">
                              <span
                                onClick={() => {
                                  onEditItemClick(item);
                                  setIsNewEnv();
                                }}>
                                <EditIcon />
                              </span>
                              <span
                              onClick={()=>{
                                setIsDeletedItem(item.id)
                              }}
                              >
                                <Delete />
                              </span>
                            </div>
                        )}
                      </span>
                    </div>
                    {!isNewEnv && editItem && editItemId === item.id && (
                      <span className='editEnvironmentWrapper'>
                        <Grid container spacing={5}>
                          <Grid item md={4}>
                            <InputField
                              placeholder='Enter an Environment Name'
                              inputlabel='Environment Name'
                              showRequired
                              onChange={e => setEnvName(e.target.value)}
                              value={envName}
                            />
                          </Grid>
                          <Grid item md={6} className='envAuutoComplete'>
                            {sameAddress ? (
                              <InputField
                                inputlabel='Location'
                                showRequired
                                value={environmentAddress}
                                disabled
                              />
                            ) : (
                              <>
                                <label className='envAutoLocLable'>
                                  Location
                                </label>
                                <Autocomplete
                                  id='popupEnvLocInput'
                                  className='autocomplete'
                                  apiKey={
                                    "AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"
                                  }
                                  options={{
                                    types: ["address"],
                                  }}
                                  onPlaceSelected={place => {
                                    setEnvironmentAddress(
                                      place.formatted_address
                                    );
                                    setEnvLat(place.geometry.location.lat());
                                    setEnvLong(place.geometry.location.lng());
                                  }}
                                  onChange={e =>
                                    setEnvironmentAddress(e.target.value)
                                  }
                                  defaultValue={environmentAddress}
                                />
                              </>
                            )}

                            <span
                              className='addressCheckbox'
                              style={{ flexDirection: "row" }}>
                              <CustomCheckbox
                                checkedId={"envSameAddress"}
                                pressCheck={e => onSameAddressPress(e)}
                                checkValue={sameAddress}
                              />
                              Same as Main Address
                            </span>
                          </Grid>
                        </Grid>
                        <div className='gap' />
                        <Grid
                          container
                          spacing={5}
                          style={{ paddingBottom: "25px" }}>
                          <Grid item md={12}>
                            <p className='selectIcon'>
                              Select a Icon<i>*</i>
                            </p>
                            <div className='editEnvIcons'>
                              <ul>
                                {envList?.map((myEnv, index) => {
                                  return (
                                    <li key={index}>
                                      <span
                                        className={`icon ${
                                          myEnv.icon === envIcon
                                            ? "selectedBox"
                                            : ""
                                        }`}
                                        onClick={() => setEnvIcon(myEnv.icon)}>
                                        <img src={myEnv.icon} />
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </span>
                    )}
                  </li>
                );
              })}
            {isNewEnv && (
              <li>
                <div>
                  <div></div>
                  <span className='chavron tickMarkCross'>
                    <span
                      onClick={() => {
                        setIsNewEnv();
                      }}>
                      <RedCross />
                    </span>
                    <span onClick={() => onSaveEnv()}>
                      <TickMark />
                    </span>
                  </span>
                </div>

                <span className='editEnvironmentWrapper'>
                  <Grid container spacing={5}>
                    <Grid item md={4}>
                      <InputField
                        placeholder='Enter an Environment Name'
                        inputlabel='Environment Name'
                        showRequired
                        onChange={e => setEnvName(e.target.value)}
                        value={envName}
                      />
                    </Grid>
                    <Grid item md={6} className='envAuutoComplete'>
                      {sameAddress ? (
                        <InputField
                          inputlabel='Location'
                          showRequired
                          value={environmentAddress}
                          disabled
                        />
                      ) : (
                        <>
                          <label className='envAutoLocLable'>Location</label>
                          <Autocomplete
                            id='popupAddEnvLocInput'
                            className='autocomplete'
                            apiKey={"AIzaSyDmGVRqxuXsUGlDBosd3I5ptRVySLSi6UQ"}
                            options={{
                              types: ["address"],
                            }}
                            onPlaceSelected={place => {
                              setEnvironmentAddress(place.formatted_address);
                              setEnvLat(place.geometry.location.lat());
                              setEnvLong(place.geometry.location.lng());
                            }}
                            onChange={e =>
                              setEnvironmentAddress(e.target.value)
                            }
                            defaultValue={environmentAddress}
                          />
                        </>
                      )}

                      <span
                        className='addressCheckbox'
                        style={{ flexDirection: "row" }}>
                        <CustomCheckbox
                          checkedId={"envSameAddress"}
                          pressCheck={e => onSameAddressPress(e)}
                          checkValue={sameAddress}
                        />
                        Same as Main Address
                      </span>
                    </Grid>
                  </Grid>
                  <div className='gap' />
                  <Grid container spacing={5} style={{ paddingBottom: "25px" }}>
                    <Grid item md={12}>
                      <p className='selectIcon'>
                        Select a Icon<i>*</i>
                      </p>
                      <div className='addEnvIcons'>
                        <ul>
                          {envList?.map((item, index) => {
                            return (
                              <li key={index}>
                                <span
                                  className={`icon ${
                                    item.icon === envIcon ? "selectedBox" : ""
                                  }`}
                                  onClick={() => setEnvIcon(item.icon)}>
                                  <img src={item.icon} />
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                </span>
              </li>
            )}
            { !tempEnvData?.length  && (
              <span className='emptyGridText emptyGridData'>
                You haven’t added any Environment. Start by adding a{" "}
                <i
                  onClick={() => {
                    onClickAddNewEnv (true);
                    setEditItem(null);
                  }}>
                  New Environment
                </i>
              </span>
            )}
          </ul>
        </ValidatorForm>
        {isDeletedItem ? (
        <DeleteFilesOverlay
          heading={"Delete Environment"}
          openDeleteFilesOverlay={isDeletedItem}
          setOpenDeleteFilesOverlay={value => {
            setIsDeletedItem(value);
          }}
          deleteAssetFiles={() => {
            onPressDeleteEnv(isDeletedItem);
          }}
          overlayText1={"Are you sure you want to Delete Environment?"}
          overlayText2={""}
          buttonText={"Delete"}
        />
      ) : null}
      </div>
    </>
  );
};

export default NewEnvironmentGrid;
