import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { productInventoryActions } from "../store/product/productInventoryAction";

const useAvailabilityPeriod = ({
  AvailabilityPeriod = {
    previousPAPId: "-1",
    periodName: "",
    startPeriodDate: null,
    endPeriodDate: null,
    followedBy: "",
  },
}) => {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);
  const [availabilityPeriod, setAvailabilityPeriod] =
    useState(AvailabilityPeriod);

  const addUpdateAvailabilityPeriod = useCallback(
    async availabilityPeriodData => {
      setIsProcessing(true);
      const result = await dispatch(
        productInventoryActions.onAddUpdateAvailabilityPeriod(
          availabilityPeriodData
        )
      );
      setIsProcessing(false);

      if (!result?.success) toast.error(result.message);
      return result.success;
    },
    [dispatch]
  );

  return {
    isProcessing,
    availabilityPeriod,
    setAvailabilityPeriod,
    addUpdateAvailabilityPeriod,
  };
};

export { useAvailabilityPeriod };
