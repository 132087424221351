import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import LaunchesGrid from "./LaunchesGrid";
import Dashbard from "../Dashbard";
import AddLaunchesPopup from "../../../../components/common/popUps/AddLaunchesPopup";
import Loader from "../../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { useHistory } from "react-router";
import { Pagination } from "../../../../components/common";
import TimeOutPopup from "../../../../components/common/popUps/TimeOutPopup";
import GridSearchFilter from "../../../../components/common/FilterLayout/GridFilter";
import { useDebounce } from "../../../../utils/use-debounce";

const Launches = () => {
  document.title = "OFish - Launches";
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [timeoutModal, setTimeoutModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { launches, paginationResponse } = useSelector(
    state => state.LaunchesReducer
  );
  const [filters, setFilters] = useState({
    name:""
  });
  const { debouncedValue } = useDebounce(filters.name , 500)
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrderBy] = useState({
    key: "launch_plan",
    val: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(launchesActions.onAllSelectedLaunches([]));
    dispatch(
      launchesActions.onGetLaunchesList({
        launchPlan: debouncedValue,
        type:"",
        pageSize: rowsPerPage,
        pageNo: page,
        orderByKey: order.key,
        orderByVal: order.val,
      })
    ).then(data => {
      setLoading(false);
    });
  }, [dispatch, debouncedValue, page, rowsPerPage, order.key, order.val]);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, [dispatch, loadData, page, rowsPerPage]);

  const handleOpen = () => {
    setTimeoutModal(true);
  };

  const handleClose = () => {
    setTimeoutModal(false);
  };

  return (
    <Dashbard>
      <div className='body-content'>
        {timeoutModal && (
          <TimeOutPopup
            openPopup={() => timeoutModal}
            closePopup={handleClose}
          />
        )}
        {isLoading ? <Loader /> : null}
        <div className='contentHeading launchBtn'>
          <h1>All Launches </h1>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            // onClick={() => setIsModal(true)}
            onClick={() => history.push("/new-launch")}>
            New Launch
          </Button>
          {/* <Button onClick={handleOpen}>Timeout</Button> */}
        </div>

        <div className='gridBody contentBody '>
          <GridSearchFilter
            isFilterAdded={false}
            setFilters={setFilters}
            filters={filters}
          />
          <LaunchesGrid
            launchesData={launches}
            startLoading={() => setLoading(true)}
            endLoading={() => setLoading(false)}
            loadData={() => loadData()}
            order={order}
            onOrderBy={setOrderBy}
          />

          <Pagination
            recordCount={paginationResponse?.totalRecords}
            page={page - 1}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          {isModal && (
            <AddLaunchesPopup
              openPopup={isModal}
              closePopup={() => setIsModal(false)}
              startLoading={() => setLoading(true)}
              endLoading={() => setLoading(false)}
              loadData={() => loadData()}
            />
          )}
        </div>
      </div>
    </Dashbard>
  );
};

export default Launches;
