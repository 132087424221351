import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PressContact from "./PressContact";
import RelatedAssets from "./RelatedAssets";
import { PopupDialog } from "../../../../components/common";
import { pubCompanyActions } from "../../../../store/pubCompany/pubCompanyActions";
import {
  facebook,
  instagram,
  twitter,
  linkedin,
  planefill,
  proSportFront,
} from "../../../../resources/images/index";

function PreviewPopup({
  showPopup,
  title,
  discription,
  releaseDate,
  selectedImage,
  releaseContent,
  releaseAssets,
  closePopup,
}) {
  const dispatch = useDispatch();

  const [pressContact, setPressContact] = useState(null);

  useEffect(() => {
    dispatch(pubCompanyActions.onGetPubCompContact()).then(data => {
      if (!data.success) toast.error("API changed, Please report");
      setPressContact(data?.data);
    });
  }, [dispatch]);
  console.log({ releaseContent });
  return (
    <PopupDialog
      maxWidth='lg'
      minWidth='999px'
      visible={showPopup}
      onClose={closePopup}>
      <div className='previewPopupWrapper'>
        <heder>
          <Button color='primary' variant='outlined' onClick={closePopup}>
            Close Preview
          </Button>
        </heder>

        <div className='popupBody'>
          <h2>{title}</h2>
          <span className='subHeading'>{discription}</span>
          <div className='dateNPlace'>
            <span>{moment(releaseDate).format("MMM, DD, YYYY")}</span>{" "}
            {/* <span>San Jose</span> */}
          </div>
          {selectedImage ? (
            <div className='popupTextarea'>
              <img
                src={selectedImage.url ? selectedImage.url : selectedImage}
                className='previeImgBody'
                alt=''
              />
            </div>
          ) : null}
          <FroalaEditorView model={releaseContent} />
          {/* <p>{releaseContent}</p> */}

          <div className='popupFooter'>
            <Button color='primary' variant='contained'>
              Download Release
            </Button>
            <span className='footerSocialMedia'>
              <img src={instagram} alt='' />
              <img src={facebook} alt='' />
              <img src={twitter} alt='' />
              <img src={linkedin} alt='' />
              <img src={planefill} alt='' />
            </span>
          </div>
        </div>

        <div className='pressAssets'>
          <Grid container spacing={3} className='assetsInner'>
            <Grid item xs={4}>
              <h2>Press Contact</h2>
              <div className='innerColums'>
                <PressContact
                  picture={pressContact?.imageURL}
                  heading='Press Contact'
                  subheading={
                    pressContact
                      ? `${pressContact?.pressContact}`
                      : "Ramona Bueza"
                  }
                  email={
                    pressContact
                      ? `${pressContact?.pressContactEmail}`
                      : "press@aquaticav.com"
                  }
                  inquiry='General Aquatic AV Inquires'
                  inqemail=' info@aquaticav.com'
                  emails={pressContact?.emails || []}
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <h2>Related Assets</h2>
              <div className='innerColums'>
                {releaseAssets.map((item, index) => {
                  return (
                    <RelatedAssets
                      key={index}
                      picture={
                        item.image && item.image === "string"
                          ? proSportFront
                          : item.image || proSportFront
                      }
                      heading={item.title}
                      subheading={`${item?.assetCount} Assets`}
                    />
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </PopupDialog>
  );
}

export default PreviewPopup;
