import React, { memo } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { first } from "lodash";
import SelectInput from "./SelectInput";

const CategoryType = memo(
  ({
    disabled,
    parentCategories = [],
    selectedParentCategoryId = "1",
    setParentCategoryId
  }) => {
    return (
      <Grid container spacing={2} className='categorytype'>
        <Grid item xs={4}>
          <FormControl>
            <label className='slugLabel'>Category Type</label>
            <RadioGroup
              name='rg-category-type'
              value={parseInt(selectedParentCategoryId) > 0 ? "1" : "0"}
              onChange={({ target }) => {
                setParentCategoryId(
                  target.value === "0" ? "0" : first(parentCategories)?.id
                );
              }}>
              <FormControlLabel
                control={<Radio />}
                label='Parent Category'
                value={"0"}
                disabled={disabled}
              />
              <FormControlLabel
                control={<Radio />}
                label='Sub Category'
                value={"1"}
                disabled={disabled}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={7}
          style={{
            alignItems: "flex-end",
            display: "flex"
          }}>
          {selectedParentCategoryId >= 1 && (
            <SelectInput
              selectlabel='Parent Category'
              placeholder='Please Select'
              value={selectedParentCategoryId}
              required={true}
              customMenuItems={parentCategories?.map(c => {
                return {
                  id: c.id,
                  name: c?.value || c?.name
                };
              })}
              handleChange={({ target }) => {
                // onCategoryTypeChange({
                //   isParent: !Boolean(parseInt(isSubCat)),
                //   parentCategoryId: target.value
                // })
                setParentCategoryId(target.value);
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }
);

export { CategoryType };
