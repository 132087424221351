import React, { useState, useEffect, useCallback } from "react";
import Dashboard from "../Dashbard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  equalizerFill,
  compiling,
  addUser,
  urgentMail,
  button,
  img1,
  img2,
  img3,
  img4,
} from "../../../../resources/images";
import { launchesActions } from "../../../../store/launches/launchesActions";
import { accountActions } from "../../../../store/accounts/accountActions";
import Loader from "../../../../components/common/Loader";
import moment from "moment";
import { RoutesObj } from "../../../../routes";
import { DownChevron } from "../../LeftNav/SvgIcons";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "1px solid",
  },
  gridWrapper: {
    // flex: 1,
    // border: '1px solid #f1f1f1',
    // marginBottom: "15px",
    marginBottom: "6px",
    float: "left",
    width: "100%",
  },
  featuredImage: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  featuredImageHeading: {
    fontSize: "16px",
    color: "#2D3339",
    fontWeight: "500",
    marginBottom: "6px",
  },
  featuredImageTabs: {
    position: "absolute",
    top: "80px",
    right: "0",
    backgroundColor: "#ffffff",
    zIndex: "2",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    maxHeight: "532px",
    overflowY: "auto",
  },
  featuredImageTabsHeading: {
    fontSize: "22px",
    color: "#434D56",
    padding: "0 15px",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },
  headingIcon: {
    marginRight: "10px",
  },
  textAreaWrapper: {
    width: "100%",
  },
  selectBtn: {
    width: "100px",
  },
  featuredImageBtn: {
    width: "84%",
    padding: "9px 15px",
  },
  selectSubtitle: {
    color: "#70808F",
    fontSize: "13px",
    marginTop: "6px",
  },
  closeTabs: {
    float: "right",
    cursor: "pointer",
  },
}));

function MainDashboard(props) {
  document.title = "OFish - Dashboard";
  const history = useHistory();
  const redirectReleaseUrl = value => {
    history.push({
      pathname: RoutesObj.UpdateMediaRelease.path,
      state: { release: value },
      search: `id=${value.id}`,
    });
  };
  const redirectUrl = value => {
    history.push({
      pathname: RoutesObj.UpdateLaunch.path,
      state: { release: value },
      search: `id=${value}`,
    });
  };

  const redirectProductUrl = value => {
    history.push({
      pathname: RoutesObj.ProductSerial.path,
      state: { device: value },
      search: `id=${value.id}`,
    });
  };
  const classes = useStyles();
  const [dayFilter, setDayFilter] = useState(0);
  const [pageViewCount, setPageViewCount] = useState(0);
  const [userSessionCount, setUserSessionCount] = useState(0);
  const [newCustomerCount, setNewCustomerCount] = useState(0);
  const [newRegistrationCount, setNewRegistrationCount] = useState(0);
  const [pendingApprovalProducts, setPendingApprovalProducts] = useState([]);
  const [newestApprovedProducts, setNewestApprovedProducts] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [newestApprovedPageSize, setNewestApprovedPageSize] = useState(20);
  const handleChange = date => {
    setLoading(true);
    setDayFilter(date);
    dispatch(accountActions.onGetAllCounts(date)).then(data => {
      if (data && data.data && data.data.success && data.data.data) {
        setPageViewCount(data.data.data.pageViewCount);
        setUserSessionCount(data.data.data.userSessionCount);
        setNewCustomerCount(data.data.data.newCustomerCount);
        setNewRegistrationCount(data.data.data.newRegistrationCount);
      }
      setLoading(false);
    });
  };
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { allReleases, launches } = useSelector(state => state.LaunchesReducer);
  const showMorePendingApprovalProducts = () => {
    var number = pageSize + 20;
    setPageSize(number);
    loadLaunches();
    // dispatch(
    //   launchesActions.onGetRegistrationPendingApprovalProducts(
    //     pageSize,
    //     1,
    //     "Date",
    //     1
    //   )
    // ).then((data) => {
    //   setPendingApprovalProducts(data.data.data);
    // });
  };
  const showMoreNewestApprovedProducts = () => {
    var number = newestApprovedPageSize + 20;
    setNewestApprovedPageSize(number);
    loadLaunches();
    // dispatch(
    //   launchesActions.onGetNewestApprovedRegistrationProducts(
    //     newestApprovedPageSize,
    //     1,
    //     "Date",
    //     1
    //   )
    // ).then((data) => {
    //   setNewestApprovedProducts(data.data.data);
    // });
  };
  const loadLaunches = useCallback(() => {
    setLoading(true);
    const modal = {
      title: null,
      announcementDate: null,
      category: null,
      status: 0,
      date: 0,
      categoryIds: [],
      pagination: {
        pageSize: 20,
        pageNo: 1,
        orderByKey: "Title",
        orderByVal: 1,
      },
    };
    dispatch(launchesActions.onGetAllReleases(modal)).then(data => {
      dispatch(launchesActions.onGetDropDownLaunches()).then(data => {
        dispatch(accountActions.onGetAllCounts(dayFilter)).then(data => {
          if (data && data.data && data.data.success && data.data.data) {
            setPageViewCount(data.data.data.pageViewCount);
            setUserSessionCount(data.data.data.userSessionCount);
            setNewCustomerCount(data.data.data.newCustomerCount);
            setNewRegistrationCount(data.data.data.newRegistrationCount);
          }
          setLoading(false);
        });
      });
      dispatch(
        launchesActions.onGetRegistrationPendingApprovalProducts(
          pageSize,
          1,
          "Date",
          0
        )
      ).then(data => {
        if (data && data.success && data.data) {
          setPendingApprovalProducts(data.data.data);
        }
      });
      dispatch(
        launchesActions.onGetNewestApprovedRegistrationProducts(
          newestApprovedPageSize,
          1,
          "Date",
          0
        )
      ).then(data => {
        if (data && data.success && data.data) {
          setNewestApprovedProducts(data.data.data);
        }
      });
    });
  }, [dispatch, dayFilter, pageSize, newestApprovedPageSize]);

  useEffect(() => {
    loadLaunches();
  }, [dispatch, loadLaunches]);

  const downChevron = DownChevron;
  return (
    <Dashboard>
      <div className='body-content'>
        {isLoading ? <Loader /> : null}
        <div className='contentHeading'>
          <h1>Dashboard </h1>
        </div>
        <div className='gridBody contentBody dashboardContentBody'>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item md={4} className='gridMainWrapper'>
                <Grid item md={12} className='gridStatsWrapper'>
                  <Grid item md={12} className='gridRow gridTopRow'>
                    <Grid item md={6} className='gridHeading'>
                      <h4>Quick Stats </h4>
                    </Grid>
                    <Grid item md={6} className='gridDropDown thisWeekDropdown'>
                      <Select
                        value={dayFilter}
                        onChange={e => handleChange(e.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        variant={"filled"}
                        IconComponent={downChevron}
                        required>
                        <MenuItem value='' disabled>
                          This Week
                        </MenuItem>
                        <MenuItem value={0}>This Week</MenuItem>
                        <MenuItem value={1}>Within Last Month</MenuItem>
                        <MenuItem value={2}>Within Past 3 Months</MenuItem>
                        <MenuItem value={3}>Within Past 6 Months</MenuItem>
                        <MenuItem value={4}>Within Past Last Year</MenuItem>
                      </Select>
                  
                    </Grid>
                  </Grid>
                  <Grid item md={12} className='gridRow'>
                    <Grid item md={6} className='gridParentWidget'>
                      <Grid item md={12} className='gridWidget'>
                        <Grid item md={12} className='gridDataView'>
                          <img
                            src={equalizerFill}
                            alt='equalizerFill'
                            className='equalizerFillIcon'
                          />
                          <span>{pageViewCount}</span>
                        </Grid>
                        <p>Page Views</p>
                      </Grid>
                    </Grid>
                    <Grid item md={6} className='gridParentWidget'>
                      <Grid item md={12} className='gridWidget'>
                        <Grid item md={12} className='gridDataView'>
                          <img
                            src={addUser}
                            alt='addUser'
                            className='addUser'
                          />
                          <span>{newCustomerCount}</span>
                        </Grid>
                        <p>New Customers</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} className='gridRow'>
                    <Grid item md={6} className='gridParentWidget'>
                      <Grid item md={12} className='gridWidget'>
                        <Grid item md={12} className='gridDataView'>
                          <img
                            src={compiling}
                            alt='compiling'
                            className='compiling'
                          />
                          <span>{newRegistrationCount}</span>
                        </Grid>
                        <p>New Registrations</p>
                      </Grid>
                    </Grid>
                    <Grid item md={6} className='gridParentWidget'>
                      <Grid item md={12} className='gridWidget'>
                        <Grid item md={12} className='gridDataView'>
                          <img
                            src={urgentMail}
                            alt='urgentMail'
                            className='urgentMail'
                          />
                          <span>{userSessionCount}</span>
                        </Grid>
                        <p>User Sessions</p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className='gridMainWrapper'>
                <Grid item md={12} className='gridReleaseWrapper'>
                  <Grid item md={12} className='gridReleaseWrapperHeader'>
                    <Grid item md={12} className='gridRow gridTopRow'>
                      <Grid item md={12} className='gridReleaseHeading'>
                        <h4>Registrations Pending Approval </h4>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} className='gridReleaseWrapperBody'>
                    {pendingApprovalProducts.length > 0 &&
                      pendingApprovalProducts.map((item, index) => {
                        return (
                          <Grid item md={12} className='gridRow'>
                            <Grid item md={12} className='gridParentWidget'>
                              <Grid item md={2} className='gridImageSection'>
                                <img
                                  src={item.image ? item.image : img1}
                                  alt='img1'
                                />
                              </Grid>
                              <Grid item md={8} className='gridInfoSection'>
                                <h6>{item.name}</h6>
                                <p>
                                  {item.date
                                    ? moment(item.date).format("MMMM DD, YYYY")
                                    : ""}
                                </p>
                              </Grid>
                              <Grid item md={2} className='gridButtonSection'>
                                <Button
                                  onClick={() => redirectProductUrl(item)}>
                                  <img
                                    src={button}
                                    alt='button'
                                    className='button'
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    {pendingApprovalProducts.length >= pageSize && (
                      <Grid item md={12} className='gridRow'>
                        <Grid item md={12} className='gridParentWidget'>
                          <Button
                            className='showMoreButton'
                            onClick={() => showMorePendingApprovalProducts()}>
                            Show More
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {pendingApprovalProducts.length <= 0 && (
                      <Grid item md={12} className='gridRow'>
                        <Grid item md={12} className='gridParentWidget'>
                          Uh Oh! Data not available.
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className='gridMainWrapper'>
                <Grid item md={12} className='gridReleaseWrapper'>
                  <Grid item md={12} className='gridReleaseWrapperHeader'>
                    <Grid item md={12} className='gridRow gridTopRow'>
                      <Grid item md={12} className='gridReleaseHeading'>
                        <h4>Newest Approved Registrations </h4>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} className='gridReleaseWrapperBody'>
                    {newestApprovedProducts.length > 0 &&
                      newestApprovedProducts.map((item, index) => {
                        return (
                          <Grid item md={12} className='gridRow'>
                            <Grid item md={12} className='gridParentWidget'>
                              <Grid item md={2} className='gridImageSection'>
                                <img
                                  src={item.image ? item.image : img1}
                                  alt='img1'
                                />
                              </Grid>
                              <Grid item md={8} className='gridInfoSection'>
                                <h6>{item.name}</h6>
                                <p>
                                  {item.date
                                    ? moment(item.date).format("MMMM DD, YYYY")
                                    : ""}
                                </p>
                                {/* <p>August 1, 2021 @ 12:00 AM PST</p> */}
                              </Grid>
                              <Grid item md={2} className='gridButtonSection'>
                                <Button
                                  onClick={() => redirectProductUrl(item)}>
                                  <img
                                    src={button}
                                    alt='button'
                                    className='button'
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    {newestApprovedProducts.length >= pageSize && (
                      <Grid item md={12} className='gridRow'>
                        <Grid item md={12} className='gridParentWidget'>
                          <Button
                            className='showMoreButton'
                            onClick={() => showMoreNewestApprovedProducts()}>
                            Show More
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {newestApprovedProducts.length <= 0 && (
                      <Grid item md={12} className='gridRow'>
                        <Grid item md={12} className='gridParentWidget'>
                          Uh Oh! Data not available.
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={4} className='gridMainWrapper'></Grid>
              <Grid item md={4} className='gridMainWrapper'>
                <Grid item md={12} className='gridReleaseWrapper'>
                  <Grid item md={12} className='gridReleaseWrapperHeader'>
                    <Grid item md={12} className='gridRow gridTopRow'>
                      <Grid item md={12} className='gridReleaseHeading'>
                        <h4>Latest Releases </h4>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} className='gridReleaseWrapperBody'>
                    {allReleases.length > 0 &&
                      allReleases.map((item, index) => {
                        return (
                          <Grid item md={12} className='gridRow'>
                            <Grid item md={12} className='gridParentWidget'>
                              <Grid item md={2} className='gridImageSection'>
                                <img
                                  src={
                                    item.releaseImage ? item.releaseImage : img1
                                  }
                                  alt='img1'
                                />
                              </Grid>
                              <Grid item md={8} className='gridInfoSection'>
                                <h6>{item.title}</h6>
                                <p>
                                  {item.announcementDate
                                    ? moment(item.announcementDate).format(
                                        "MMMM DD, YYYY"
                                      )
                                    : ""}
                                </p>
                                {/* <p>August 1, 2021 @ 12:00 AM PST</p> */}
                              </Grid>
                              <Grid item md={2} className='gridButtonSection'>
                                <Button
                                  onClick={() => redirectReleaseUrl(item)}>
                                  <img
                                    src={button}
                                    alt='button'
                                    className='button'
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    {allReleases.length > 20 && (
                      <Grid item md={12} className='gridRow'>
                        <Grid item md={12} className='gridParentWidget'>
                          <Button
                            className='showMoreButton'
                            onClick={() =>
                              history.push({
                                pathname: RoutesObj.Releases.path,
                              })
                            }>
                            Show More
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {allReleases.length <= 0 && (
                      <Grid item md={12} className='gridRow'>
                        <Grid item md={12} className='gridParentWidget'>
                          Uh Oh! Data not available.
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className='gridMainWrapper'>
                <Grid item md={12} className='gridReleaseWrapper'>
                  <Grid item md={12} className='gridReleaseWrapperHeader'>
                    <Grid item md={12} className='gridRow gridTopRow'>
                      <Grid item md={12} className='gridReleaseHeading'>
                        <h4>Upcoming Launches </h4>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} className='gridReleaseWrapperBody'>
                    {launches.length > 0 &&
                      launches.map((item, index) => {
                        return (
                          <Grid item md={12} className='gridRow'>
                            <Grid item md={12} className='gridParentWidget'>
                              <Grid item md={2} className='gridImageSection'>
                                <img src={img1} alt='img1' />
                              </Grid>
                              <Grid item md={8} className='gridInfoSection'>
                                <h6>{item.value}</h6>
                                <p>
                                  {item.generic
                                    ? moment(item.generic).format(
                                        "MMMM DD, YYYY"
                                      )
                                    : ""}
                                </p>
                                {/* <p>August 1, 2021 @ 12:00 AM PST</p> */}
                              </Grid>
                              <Grid item md={2} className='gridButtonSection'>
                                <Button onClick={() => redirectUrl(item.id)}>
                                  <img
                                    src={button}
                                    alt='button'
                                    className='button'
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    {launches.length <= 0 && (
                      <Grid item md={12} className='gridRow'>
                        <Grid item md={12} className='gridParentWidget'>
                          Uh Oh! Data not available.
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default MainDashboard;
