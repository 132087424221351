import React, { useState } from "react";
import { EditIcon, SortIcon } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import AddCategoryPopup from "../../../../components/common/popUps/AddCategoryPopup";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function LaunchCategoryGrid({
  launchCategoryData,
  startLoading,
  endLoading,
  loadData,
  selectedRecord,
  isDelete,
  order,
  onOrderBy,
}) {
  const [isModal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedRelCategory } = useSelector(state => state.LaunchesReducer);
  const history = useHistory();

  const selectRelCategory = value => {
    dispatch(launchesActions.onSelectRelCategory(value));
  };

  const checkRelCategorySelected = itemId => {
    const releaseExist = selectedRelCategory.find(release => {
      return release.id === itemId;
    });
    if (releaseExist) {
      return true;
    } else {
      return false;
    }
  };
  const redirectUrl = () => {
    history.push("/add-launch-categories");
  };
  const deleteLaunchCategory = () => {
    var userIds = [];
    launchCategoryData.map(x => {
      if (x.checked) {
        userIds.push(x.id);
      }
    });
    if (userIds.length > 0) {
      startLoading();
      dispatch(launchesActions.onDeleteLaunchCategory(userIds)).then(data => {
        endLoading();
        if (data.success) {
          toast.success("Launch category deleted.");
          loadData();
        } else {
          toast.error(data.message);
        }
      });
    } else {
      toast.error("Please select the release category.");
    }
  };
  return (
    <Paper>
      <Paper className={classes.gridTopNav}>
        <Grid item md={9} className={classes.deleteItems}>
          {isDelete && (
            <span
              className='gridDeleteItems'
              onClick={() => deleteLaunchCategory()}>
              <DeleteIcon /> Delete
            </span>
          )}
        </Grid>
      </Paper>
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            {/* <TableCell className={classes.gridHeaderInner}>
              <Checkbox className={classes.checkBoxFont} disabled={true} />
            </TableCell> */}
            <TableCell className={classes.gridHeaderInner}>
              CATEGORY NAME
              <TableSortLabel
                active={order.orderByKey === "Name"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Name",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              NO. OF LAUNCHES
              <TableSortLabel
                active={order.orderByKey === "NoOfLaunches"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "NoOfLaunches",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              DATE CREATED
              <TableSortLabel
                active={order.orderByKey === "CreatedOn"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "CreatedOn",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              COLOR
              <TableSortLabel
                active={order.orderByKey === "Color"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "Color",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              PUBLIC
              <TableSortLabel
                active={order.orderByKey === "IsPublic"}
                direction={order.orderVal === 1 ? "asc" : "desc"}
                onClick={() =>
                  onOrderBy({
                    orderByKey: "IsPublic",
                    orderVal: order.orderVal === 0 ? 1 : 0,
                  })
                }
                IconComponent={SortIcon}
                className='sortIcon'
              />
            </TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {launchCategoryData.length > 0 &&
            launchCategoryData.map((item, index) => {
              return (
                <>
                  {item.id !== 1 ? (
                    <TableRow key={index} className='gridBodyRow'>
                      {/* <TableCell className={classes.gridHeaderInner}>
                        <Checkbox
                          className={classes.checkBoxFont}
                          onClick={() => selectedRecord(item)}
                          checked={item.checked}
                        />
                      </TableCell> */}
                      <TableCell className={classes.gridBodyInner}>
                        {item.name}
                      </TableCell>
                      <TableCell className={classes.gridBodyInner}>
                        {item.noOfLaunches}
                      </TableCell>
                      <TableCell className={classes.gridBodyInner}>
                        {moment(item.createdOn).format("MMMM DD, YYYY")}
                      </TableCell>
                      <TableCell className={classes.gridBodyInner}>
                        <span
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "10px",
                            display: "flex",
                            backgroundColor: item.color
                              ? item.color
                              : "#EB5757",
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.gridBodyInner}>
                        {item.isPublic ? (
                          <span className={classes.inUseYes}>Yes </span>
                        ) : (
                          <span className={classes.inUseNo}>No </span>
                        )}
                      </TableCell>
                      <TableCell className='editButton'>
                        <span
                          onClick={() => {
                            history.push(
                              "/add-launch-categories?id=" + item.id
                            );
                          }}>
                          <EditIcon />
                        </span>
                        <span
                          onClick={() => {
                            history.push(
                              "/add-launch-categories?id=" + item.id
                            );
                          }}
                        >
                          <OpenInNewIcon
                            style={{ color: "rgb(54, 153, 255)" }}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          {launchCategoryData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='7'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t created any release categories. Start by
                  <Button onClick={redirectUrl}>
                    {" "}
                    creating a New release category
                  </Button>
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isModal && (
        <AddCategoryPopup
          openPopup={isModal}
          closePopup={() => setIsModal(false)}
          startLoading={() => startLoading()}
          endLoading={() => endLoading()}
          loadData={() => loadData()}
          isEdit={isEdit}
          editData={editData}
        />
      )}
    </Paper>
  );
}

export default LaunchCategoryGrid;
