import {
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { ChipCross, CloseIconCustomPopup } from "../../../LeftNav/SvgIcons";
import SoftwareGridFilter from "../../SoftwareUpdates/SoftwareGridFilter";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import { ConversationChip } from "./ConversationChip";
import Loader from "../../../../../components/common/Loader";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: "20px",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "20px",
    width: "1100px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #E2E5E9",
    padding: "23px 33px 23px 41px;",
    background: "#3699FF",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  customerTypo: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#fff",
  },
  innerBody: {
    overflowY: "auto",
  },
  chooseCustomerHeading: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#181C32",
    marginBottom: "16px",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#434D56",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    padding: "13px 18px 17px 20px",
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  mainCustomerPricing: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  mainBody: {
    padding: "32px",
  },
  customerGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: " space-between",
    padding: "19px 19px 19px 12px",
    background: "#F1FAFF",
    border: " 1px dashed #00A3FF",
    borderRadius: 9,
    marginRight: 30,
    marginBottom: 20,
  },
  coustomerBox: {
    width: 36,
    height: 36,
    background: "#D8D8D8",
    borderRadius: 3,
    marginRight: 12,
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customerPricingTypo: {
    fontSize: 16,
    fontWeight: 700,
    color: "#181C32",
    fontFamily: "Roboto",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "5px",
  },
  pricingTypoBody: {
    fontSize: 14,
    fontWeight: 700,
    color: "#3699FF",
    fontFamily: "Roboto",
  },
  addedSoftwareActions: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    flexDirection: "row",
  },
  typoGrid: {
    paddingLeft: 13,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 28,
    alignItems: "center",
    gap: "5px",
  },
  addedTypo: {
    marginRight: 24,
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#3699FF",
  },
  addedBtn: {
    backgroundColor: "#18BC6D",
    "&:hover": {
      backgroundColor: "#18BC6D",
    },
  },
  paginationContainer: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "flex-end !important",
  },
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      width: "150px",
      height: "36px",
    },
    '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
    {
      paddingRight: "0",
    },
    "& .MuiAutocomplete-inputRoot": {
      flexWrap: "nowrap",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      border: "none !important",
    },
  },
  cancleBtn: {
    height: "36px",
    width: "77px",
    border: "1px solid #8D99A5",
    borderRadius: "6px",
    padding: "10px 16px",
    color: "#8D99A5",
    textTransform: "capitalize",
  },
  applyBtn: {
    height: "36px",
    width: "69px",
    borderRadius: "6px",
    padding: "10px 16px",
    color: "#fff",
  },
  tablePaginationtToolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& .MuiPaginationItem-icon": {
      display: "none",
    },
  },
  chipContiner: {
    marginTop: "16px",
    height: "70px",
    overflowY: "auto",
    "& .MuiChip-deleteIconSmall": {
      margin: 0,
    },
  },
  selectedChips: {
    marginRight: "16px",
    marginTop: "16px",
  },
  chipsBox: {
    marginBottom: "16px",
  },
  customerSearchfilter2: {
    backgroundColor: "#F9F9F9",
    border: "1px solid #E1E3EA",
    borderRadius: "6px",
    '& .searchIcon': {
      top: "10px !important",
      left: "8px !important",
    },
    '& .filterWrapper': {
      paddingBottom: "unset !important",
      borderBottom: "unset !important",
    },
    '& .MuiOutlinedInput-input': {
      padding: "16px 14px",
    },
    '& .inputSubtitle': {
      display: 'none',
    }
  },
  expandChipButton: {
    color: "#3699FF",
    fontSize: "14px",
    fontWeight: "700",
    cursor: "pointer",
  },
  noChipText: {
    color: "#BDBDBD",
    fontSize: "12px",
    fontWeight: "500",
    fontStyle: "italic",
    textAlign: "center",
  },
  chipBox: {
    "& .MuiChip-sizeSmall": {
      height: "36px",
      padding: "8px 16px",
      backgroundColor: "#F3F6F9",
    },
    "& .MuiChip-label": {
      fontSize: "14px",
      fontWeight: "700",
      color: "#70808F"
    },
  },
  gridFilterTabContainer: {
    marginTop: "16px",
    gap: "10px",
    "& .rwt__tab[aria-selected=true]": {
      backgroundColor: "#D3E6FA"
    },
    "& .rwt__tablist button": {
      fontSize: '14px !important',
      fontWeight: "500 !important",

    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      fontStyle: "normal",

    },
    popupCheckBox: {
      display: "flex",
      border: "1px solid red"
    }
  },
}));

const allTabs = [
  {
    id: "all",
    title: "All Products",
    component: "",
  },
  {
    id: "popularProducts",
    title: "Popular Products",
    component: "",
  },
];

export const CustomConversationPopup = ({
  isOpen,
  setIsOpen,
  title,
  heading,
  description,
  allTabs,
  activeTabId,
  setActiveTabId,
  chipData,
  chipText,
  onRemoveChips,
  children,
  onSubmitUserPermission,
  searchChannels,
  setSearchChannels,
  onSubmit,
  isLoading,
}) => {
  const classes = useStyles();
  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Grid container className={classes.paper}>
          {isLoading ? <Loader /> : null}
            <Grid item xs={12} className={classes.header}>
              <Typography className={classes.customerTypo}>{title}</Typography>
              <IconButton className={classes.closIcon} onClick={onClose}>
                <CloseIconCustomPopup />
              </IconButton>
            </Grid>
            <Grid xs={12} className={classes.mainBody}>
              <Grid item>
                <Typography className={classes.chooseCustomerHeading}>
                  {heading}
                </Typography>
                <Typography className={classes.text}>{description}</Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                // justifyContent="center"
                className={classes.gridFilterTabContainer}
              >
                <Grid item xs={3} className={classes.customerSearchfilter2}>
                  <SoftwareGridFilter
                    search={searchChannels}
                    setSearch={setSearchChannels}
                    placeholder={"Search"}
                  />
                </Grid>
                <Grid item xs={8} className="eligibleModaltabls">
                  <TopTabNavigator
                    getActiveTabId={(tabId) => {
                      setActiveTabId(`${tabId}`);
                    }}
                    tabs={[...allTabs]}
                    defaultTabId={activeTabId}
                    className="tabsHeader"
                  />
                </Grid>
              </Grid>
              <ConversationChip
                text={chipText}
                onRemoveChips={onRemoveChips}
                chipData={chipData}
              />
              <Grid>
                {

                  children
                }
              </Grid>

              <Grid container xs={12} className={classes.paginationContainer}>
                <Grid item xs={4} className={classes.btnContainer}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.cancleBtn}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    // disabled={selectedReleaseProducts.length === 0}
                    variant="contained"
                    className={classes.applyBtn}
                    onClick={() => {
                      onSubmit()
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </div>
  );
};
