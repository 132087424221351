import React, { useEffect, useState, useMemo } from "react";
import { Button, Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { endUserActions } from "../../../../../store/endUser/endUserActions";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import FavoriteColors from "./FavoriteColor";
import DeviceDetail from "./DeviceDetail";
import ProductRegistration from "./ProductRegistration";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/common/Loader";
import { toast } from "react-toastify";
import { Trips } from "../Trips";
import moment from "moment";

function DeviceOverview({
  hideProductDetail,
  selectedDevice,
  deviceType,
  userId,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    onLoadDevOverview();
  }, []);
  const onLoadDevOverview = () => {
    setLoading(true);
    dispatch(endUserActions.onGetDeviceOverview(selectedDevice)).then(data => {
      if (data && !data.success) {
        toast.error(data.message);
      }
      setLoading(false);
    });
  };

  const { deviceOverviewInfo } = useSelector(state => state.endUserReducer);

  const formattedPurchaseDate = moment(
    deviceOverviewInfo?.purchaseDate,
    "MM-DD-YYYY"
  ).isValid()
    ? moment(deviceOverviewInfo?.purchaseDate).format("YYYY-MM-DD")
    : moment(new Date()).format("YYYY-MM-DD");
  const formattedinstallationDate = moment(
    deviceOverviewInfo?.installationDate,
    "MM-DD-YYYY"
  ).isValid()
    ? moment(deviceOverviewInfo?.installationDate).format("YYYY-MM-DD")
    : moment(new Date()).format("YYYY-MM-DD");
  const formattedwarrantyExpiration = moment(
    deviceOverviewInfo?.expiryDate,
    "MM-DD-YYYY"
  ).isValid()
    ? moment(deviceOverviewInfo?.expiryDate).format("YYYY-MM-DD")
    : moment(new Date()).format("YYYY-MM-DD");

  const [activeTabId, setActiveTabId] = useState("1");
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deviceName, setDeviceName] = useState(deviceOverviewInfo?.name ?? "");
  const [issOverviewStatus, setIsOverviewStatus] = useState(
    deviceOverviewInfo?.warrantyStatus ?? "Active"
  );
  const [warrantyExpiration, setWarrantyExpiration] = useState(
    formattedwarrantyExpiration
  );
  const [env, setEnv] = useState(deviceOverviewInfo?.environmentId ?? 0);
  const [purchaseLoc, setPurchaseLoc] = useState(
    deviceOverviewInfo?.dealer ?? ""
  );
  const [purchaseDate, setPurchaseDate] = useState(formattedPurchaseDate);
  const [installationDate, setInstallationDate] = useState(
    formattedinstallationDate
  );
  const [isStatusRegistration, setIsStatusRegistration] = useState(
    deviceOverviewInfo?.registrationStatus ?? "Pending"
  );
  const [registrationNotes, setResgistrationNotes] = useState("");
  // const [isWarrantyStatusRegistration, setIsWarrantyStatusRegistration] =
  //   useState(deviceOverviewInfo?.warrantyStatus ?? "Active");
  const [deviceDealerId, setDeviceDealerId] = useState(0);

  const isRgbDevice = deviceType === "Physical + IOT";
  const isCoolerDevice = deviceType === "Cooler";

  const editDevice = () => {
    setIsEdit(true);
  };

  useEffect(() => {
    if (deviceOverviewInfo) {
      setEnv(deviceOverviewInfo.environmentId);
      setPurchaseLoc(deviceOverviewInfo.dealer);
      setIsStatusRegistration(deviceOverviewInfo?.registrationStatus);
      setIsOverviewStatus(deviceOverviewInfo?.warrantyStatus);
      setResgistrationNotes(deviceOverviewInfo?.notes);
    }
  }, [deviceOverviewInfo]);

  const WarrantyStatus = useMemo(() => {
    if (issOverviewStatus == "Active") {
      return 1;
    } else return 2;
  }, [issOverviewStatus]);

  const regisrtationStatusType = [
    {
      id: 0,
      name: "Pending",
      expectedResponse: "Pending",
      expectedResponse2: "Unregistered",
    },
    {
      id: 1,
      name: "Register",
      expectedResponse: "Approved",
      expectedResponse2: "Registered",
    },
    {
      id: 5,
      name: "Rejected",
      expectedResponse: "Expired",
      expectedResponse2: "Denied",
    },
    { id: 0, name: "Receipt Missing", expectedResponse2: "Receipt Missing" },
  ];

  const changeDeviceInfo = () => {
    const updateObj = {
      serialNo: "",
      purchaseDate: moment(purchaseDate).format("MM/DD/YYYY"),
      dealerLocationId: deviceDealerId,
      receiptStatus: 0,
      deviceId: deviceOverviewInfo?.id,
      envId: env,
      installationDate: moment(installationDate).format("MM/DD/YYYY"),
      name: deviceName,
      warrantyStatus: typeof WarrantyStatus === "number" ? WarrantyStatus : deviceOverviewInfo?.warrantyStatusCode,
      warrantyExpiryDate: moment(warrantyExpiration).format("MM/DD/YYYY"),
      notes: registrationNotes,
      registrationStatus: typeof isStatusRegistration === "number"
      ?  isStatusRegistration
      : regisrtationStatusType.find(
          item =>
            item.expectedResponse ===
              isStatusRegistration ||
            item.expectedResponse2 ===
              isStatusRegistration
        )?.id,
    };
    if (isStatusRegistration === 6 || isStatusRegistration === 'Receipt Missing') {
      updateObj.receipt = 'Receipt Missing';
    }

    setLoading(true);
    dispatch(endUserActions.onUpdateUserDevice(updateObj)).then(data => {
      setLoading(false);
      if (data && data.success) {
        onLoadDevOverview();
        toast.success("The Information Updated Successfully");
        dispatch(endUserActions.onGetEnvWithDeviceCount(userId));
        dispatch(endUserActions.onGetUserDevices(userId));
      } else {
        if (data) {
          toast.error(data.message);
        }
      }
    });
    setIsEdit(false);
  };
  useEffect(()=>{
    setEnv(deviceOverviewInfo?.environmentId);
    setPurchaseLoc(deviceOverviewInfo?.dealer);
    setIsStatusRegistration(deviceOverviewInfo?.registrationStatus)
    setIsOverviewStatus(deviceOverviewInfo?.warrantyStatus )
    setResgistrationNotes(deviceOverviewInfo?.notes)
  },[deviceOverviewInfo?.environmentId]);

  return (
    <div className='Dashboardmain-container productDetailWrapper'>
      {isLoading ? <Loader /> : null}
      <Grid className='endcustomeroverview'>
        <div className='consumerHeader'>
          <div className='endCustomerHeading'>
            {`${deviceOverviewInfo ? deviceOverviewInfo?.name : ""}`}
          </div>
          <Button
            color='primary'
            onClick={() => hideProductDetail()}
            className='backbtn'>
            <ArrowBackIosIcon /> Back to Products Owned
          </Button>
        </div>
        <div className='deviceOverViewEdit'>
          {!isEdit ? (
            <Button
              className='editButtonOverview'
              variant='contained'
              onClick={editDevice}>
              Edit
            </Button>
          ) : (
            <Button
              className='saveButtonOverview'
              variant='contained'
              onClick={() => {
                changeDeviceInfo();
              }}>
              Save
            </Button>
          )}
        </div>
      </Grid>
      <div className='deviceOverviewMainContainer'>
        <div className='deviceOverviewContainer'>
          <TopTabNavigator
            getActiveTabId={tabId => {
              setActiveTabId(`${tabId}`);
              setIsEdit(false);
            }}
            tabs={[
              {
                id: 1,
                title: "Device Overview",
                component: (
                  <DeviceDetail
                    loadData={() => onLoadDevOverview()}
                    startLoading={() => setLoading(true)}
                    stopLoading={() => setLoading(false)}
                    userId={userId}
                    isEditOverView={isEdit}
                    setIsEditOverView={setIsEdit}
                    hideProductDetail={() => hideProductDetail()}
                    deviceName={deviceName}
                    setDeviceName={setDeviceName}
                    issOverviewStatus={issOverviewStatus}
                    setIsOverviewStatus={setIsOverviewStatus}
                    warrantyExpiration={warrantyExpiration}
                    setWarrantyExpiration={setWarrantyExpiration}
                    env={env}
                    setEnv={setEnv}
                    deviceType={deviceType}
                  />
                ),
              },
              {
                id: 2,
                title: "Product Registration",
                component: (
                  <ProductRegistration
                    loadData={() => onLoadDevOverview()}
                    startLoading={() => setLoading(true)}
                    stopLoading={() => setLoading(false)}
                    isEditOverView={isEdit}
                    setIsEditOverView={setIsEdit}
                    purchaseLoc={purchaseLoc}
                    setPurchaseLoc={setPurchaseLoc}
                    purchaseDate={purchaseDate}
                    setPurchaseDate={setPurchaseDate}
                    installationDate={installationDate}
                    setInstallationDate={setInstallationDate}
                    isStatusRegistration={isStatusRegistration}
                    setIsStatusRegistration={setIsStatusRegistration}
                    setResgistrationNotes={setResgistrationNotes}
                    registrationNotes={registrationNotes}
                    isWarrantyStatusRegistration={issOverviewStatus}
                    setIsWarrantyStatusRegistration={
                      setIsOverviewStatus
                    }
                    userId={userId}
                    deviceDealerId={deviceDealerId}
                    setDeviceDealerId={setDeviceDealerId}
                  />
                ),
              },
              isRgbDevice && {
                id: 3,
                title: "Favorite Colors",
                component: (
                  <FavoriteColors
                    loadData={() => onLoadDevOverview()}
                    startLoading={() => setLoading(true)}
                    stopLoading={() => setLoading(false)}
                    isActive={activeTabId === "3"}
                    isEditColor={isEdit}
                    setIsEditColor={setIsEdit}
                  />
                ),
              },
              isCoolerDevice && {
                id: 4,
                title: "TRIPS",
                component: <Trips />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default DeviceOverview;
