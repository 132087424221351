import { Button } from "@material-ui/core";
import React from "react";

const StatusButton = ({ label = "Creating" }) => {
  return (
    <div
      className='inputWrapper'
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0px 10px 7px",
      }}>
      <label className={`showRequired`}>Status</label>
      <Button
        color='primary'
        variant='contained'
        className='dashboardButtons'
        disabled
        style={{
          color: "#4F4F4F",
        }}>
        {label}
      </Button>
    </div>
  );
};

export { StatusButton };
