import React, { useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DropzoneArea } from "material-ui-dropzone";
// import { assetsActions } from "../../../store/assetsManagement/assetsActions";
import { useDispatch } from "react-redux";

const SelectImage = ({ closePopup, openPopup, title, addProfileImage }) => {
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState("");
  const uploadFileHandle = file => {
    setUploadFile(file[0]);
  };
  return (
    <div>
      <Dialog
        open={openPopup}
        onClose={closePopup}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          {title}
          <CloseIcon className='closePopup' onClick={closePopup} />
        </DialogTitle>
        <DialogContent className='addLaunchPopupWrapper'>
          <DialogContentText id='alert-dialog-description'>
            <DropzoneArea
              onChange={v => uploadFileHandle(v)}
              filesLimit={1}
              acceptedFiles={["image/*"]}
              maxFileSize={110000000}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className='popupFooter addCatFooter'>
          <Button
            color='primary'
            variant='contained'
            autoFocus
            onClick={() => addProfileImage(uploadFile)}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectImage;
