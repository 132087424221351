import React, { useRef } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  FilterArrow,
  FilterSettingIcon,
  SoftwareReleaseSearchIcon,
} from "../../LeftNav/SvgIcons";
import InputField from "../../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";

const useStyless = makeStyles((theme) => ({
  gridWrapper: {
    paddingTop: "40px",
    paddingBottom: "15px",
    borderBottom: "1px solid #E2E6E9",
    background: "red",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  dropdownBg: {
    background: "#f1f1f1",
  },
  formControl: {
    minWidth: "100%",
    background: "transparent",
  },
  selectEmpty: {
    border: "1px solid #F3F6F9",
    backgroundColor: "#F3F6F9",
    borderRadius: "6px",
    "&:before": {
      borderBottom: "1px solid #F3F6F9",
      "&:hover": {
        borderBottom: "0 !important",
      },
    },
    "&:after": {
      borderBottom: "1px solid #F3F6F9",
    },

    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9",
      },
    },
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px",
  },
  filterIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "38px",
    height: "24px",
    borderRadius: "6px",
    // backgroundColor: "#3699FF",
    position: "absolute",
    right: "6px",
    top: "10px",
  },
  gridFilterIcon:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    // width: "40px",
    padding:"0px 20px",
    height: "38px",
    borderRadius: "6px",
    backgroundColor: "#3699FF",
    position: "absolute",
    right: "0px",
    top: "0px !important",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: ".8px",
  }
}));
function SoftwareGridFilter({
  search,
  setSearch,
  placeholder,
  onPressFilterBtn,
  isFilterTrue,
  isGridFilter = false,
  appliedFilter,
}) {
  const classes = useStyless();
  return (
    <>
      <Grid className="filterWrapper software_release_filter">
        <ValidatorForm ref={useRef("form")}>
          <Grid container alignItems="center">
            <Grid item md={12} className="filterMainSearch search-padding">
              <Grid className="searchIcon software-filter-search"
                style={{ top: "14px" }}>
                <SoftwareReleaseSearchIcon />
              </Grid>
              <InputField
                inputlabel="Search"
                placeholder={placeholder}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
              {isFilterTrue && (
                isGridFilter ? 
                <Box className={classes.gridFilterIcon} onClick={onPressFilterBtn}>
                 <span>{appliedFilter ? `${appliedFilter} Filter(s) Applied` : ""}</span><FilterArrow color={"#fff"} />
                </Box> :
                <Box className={classes.filterIcon} onClick={onPressFilterBtn}>
                  <FilterSettingIcon />
                </Box>
              )}
            </Grid>
          </Grid>
        </ValidatorForm>
      </Grid>
    </>
  );
}

export default SoftwareGridFilter;
