import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getPubAllEvents(eventPayload) {
  return axios
    .post(endpoints.pubEvents.getAllPubEvents, eventPayload)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubEventCategory() {
  return axios
    .post(endpoints.pubEvents.getAllPubEventCategory)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getPubEventDateFilter(catIds) {
  return axios
    .post(endpoints.pubEvents.getAllPubDateEvent, {
      categoryIds: catIds,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}
async function getPubEventDetail(eventId, urlSlug) {
  return axios
    .post(endpoints.pubEvents.getPubEventDetail, {
      eventId: eventId,
      urlSlug: urlSlug,
    })
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function getCalenderData(calenderDate) {
  return axios
    .post(endpoints.pubEvents.getPubEventCalendar, calenderDate)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

async function addEventStatus(eventStatus) {
  return axios
    .post(endpoints.pubEvents.updatePubEventAttend, eventStatus)
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return null;
    });
}

export const pubEventApi = {
  getPubAllEvents: getPubAllEvents,
  getPubEventCategory: getPubEventCategory,
  getPubEventDateFilter: getPubEventDateFilter,
  getPubEventDetail: getPubEventDetail,
  getCalenderData: getCalenderData,
  addEventStatus: addEventStatus,
};
