import { softwareBuildTypes } from "./SoftwareBuildTypes";

const INITIAL_STATE = {
  compatibleBuildProductsList: [],
  buildData: [],
  compatibleProducts: [],
  selectedBuild: {
    id: 0,
    nameInternal: "",
    namePublic: "",
    releaseId: "",
    previousRelease: "",
    nextRelease: "",
    version: 1,
    releaseStatus: 1,
    isForceUpdate: false,
    numberToForceUpdate: 0,
    typeToForceUpdate: "",
    updateMethodBluetooth: false,
    updateMethodWifi: false,
    buildUrl: "",
    softwareReleaseId: 0,
    buildFileName: "",
  },
  selectedBuildNotifications: [],
  notificationBuildTypes: [],
  isLoading: false,
  selectedBuildProducts: [],
  selectedBuildPortal: [],
  groupBuildIds: [],
  companyBuildIds: [],
  contactBuildIds: [],
  portalBuildIds: [],
  poratlAndContactBuildData: {
    isForceUpdate: false,
    numberToForceUpdate: 0,
    typeToForceUpdate: "",
    updateMethodBluetooth: false,
    updateMethodWifi: false,
  },
  successMessage: "",
  errorMessage: "",
  selectedBuildVersions: [],
  paginationBuildResponse: {},
  productsBuildList: [],
  portalsList: [],
  contactsList: [],
  selectedbuildList: [],
  customerContactBuildData: [],
  selectedBuildContacts: [],
  softwareReleaseBuildData: {},
  softwarePortalsData: [],
  softwareConatactData: [],
  softwareBuildDetials: [],
}

const softwareBuildReducer = (state, action) => {
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case softwareBuildTypes.SOFTWAREUPDATES_ONCHANGE_BUILDS:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };

    case softwareBuildTypes.UPDATE_SELECTED_RELEASE_CONTACTS_BUILDS: {
      const { key, value } = action.payload.prop;

      let updatedContacts = [...state[key], value];
      return {
        ...state,
        errorMessage: "",
        [key]: updatedContacts,
      };
    }

    case softwareBuildTypes.ON_REMOVE_SELECTED_PORTAL_CONTACT_BUILDS: {
      const { key, value } = action.payload.prop;
      const newArray = [...state[key].filter((x) => x !== value)];
      return {
        ...state,
        errorMessage: "",
        [key]: newArray,
      };
    }

    case softwareBuildTypes.SEARCH_UPDATEPRODUCT_BUILDS: {
      const { search } = action.payload.prop;
      let data = state.compatibleBuildProductsList;

      let filteredSearchProductResults = data.filter((product) =>
        product.productName.toLowerCase().includes(search.toLowerCase())
      );
      return {
        ...state,
        errorMessage: "",
        productsBuildList: filteredSearchProductResults,
      };
    }

    case softwareBuildTypes.ON_CHANGE_BUILDS: {
      const { key, value } = action.payload.prop;
      return {
        ...state,
        [key]: value,
      };
    }

    case softwareBuildTypes.ON_CHANGE_SELECTED_RELEASE_FIELD_BUILDS: {
      const { propName, value } = action.payload.prop;
      const updatedRelease = { ...state.selectedBuild, [propName]: value };
      return {
        ...state,
        errorMessage: "",
        selectedBuild: updatedRelease,
      };
    }

    case softwareBuildTypes.ON_CHANGE_PORTAL_CONTACT_FIELD_BUILDS: {
      const { propName, value } = action.payload.prop;
      const updatedRelease = {
        ...state.poratlAndContactBuildData,
        [propName]: value,
      };
      return {
        ...state,
        errorMessage: "",
        poratlAndContactBuildData: updatedRelease,
      };
    }

    case softwareBuildTypes.ON_SOFTWARE_RELEASE_DATA_BUILDS: {
      const { value } = action.payload.prop;

      return {
        ...state,
        errorMessage: "",
        softwareReleaseBuildData: value,
        poratlAndContactBuildData: {
          isForceUpdate: value?.isForceUpdate,
          numberToForceUpdate: value?.numberToForceUpdate,
          typeToForceUpdate: value?.typeToForceUpdate,
          updateMethodBluetooth: value?.updateMethodBluetooth,
          updateMethodWifi: value?.updateMethodWifi,
        },
      };
    }

    case softwareBuildTypes.ON_RELEASE_FAVOURITE_BUILDS: {
      const { softwareReleaseBuildId, isFavourite } = action.payload.prop;
      const newVersions = state.selectedBuildVersions.map((version) => {
        if (version.id === softwareReleaseBuildId) {
          return {
            ...version,
            isFavourite: isFavourite,
          };
        } else {
          return version;
        }
      });
      return {
        ...state,
        selectedBuildVersions: newVersions,
      };
    }

    case softwareBuildTypes.ON_DELETE_RELEASE_DATA_BUILDS: {
      const { releaseId } = action.payload.prop;
      const newVersions = state.selectedBuildVersions.filter(
        (version) => version.id !== releaseId
      );
      return {
        ...state,
        selectedBuildVersions: newVersions,
      };
    }

    case softwareBuildTypes.RESET_SOFTWARE_RELEASE_DATA_BUILDS: {
      return {
        ...state,
        errorMessage: "",
        selectedBuild: {},
        selectedBuildPortal: [],
        selectedBuildContacts: [],
        selectedBuildNotifications: [],
        selectedBuildProducts: [],
        poratlAndContactBuildData: {
          isForceUpdate: false,
          numberToForceUpdate: 0,
          typeToForceUpdate: "",
          updateMethodBluetooth: false,
          updateMethodWifi: false,
        },
        portalBuildIds: [],
        contactBuildIds: [],
        companyBuildIds: [],
        groupBuildIds: [],
        softwareBuildDetials: [],
      };
    }

    default:
      return state;
  };
};

export default softwareBuildReducer;
