import React from "react";
import Dashbard from "../Dashbard";
import BusinessGroupOverview from "./BusinessGroupOverview";

const NewBusinessGroup = props => {
  return (
    <Dashbard>
      <div className='body-content'>
        <BusinessGroupOverview />
      </div>
    </Dashbard>
  );
};

export { NewBusinessGroup };
