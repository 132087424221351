import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import { EditIcon, Trash } from "../../LeftNav/SvgIcons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import {
  txt,
  pngFile,
  doc,
  genericFile,
  folder,
  pdf,
} from "../../../../resources/images/index";

import { useDispatch, useSelector } from "react-redux";
import { launchesActions } from "../../../../store/launches/launchesActions";

const useStyles = makeStyles(theme => ({
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridCheckbox: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
    width: "26px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
}));

function ReleaseAssetGrid({ assetData, removeCollection }) {
  const classes = useStyles();

  const checkFileType = fileType => {
    if (
      fileType.type === "png" ||
      fileType.type === "jpg" ||
      fileType.type === "jpeg"
    ) {
      return fileType.url;
    } else if (
      fileType.type === "rtf" ||
      fileType.type === "text" ||
      fileType.type === "txt"
    ) {
      return txt;
    } else if (fileType.type === "doc") {
      return doc;
    } else if (fileType.type === "folder") {
      return folder;
    } else if (fileType.type === "pdf") {
      return pdf;
    } else {
      return genericFile;
    }
  };
  const { launches } = useSelector(state => state.LaunchesReducer);
  return (
    <Paper>
      {/* <Paper className={classes.gridTopNav}>
        <Grid item md={9} className={classes.deleteItems}>
          <span className="gridDeleteItems">
            <DeleteIcon /> Delete
          </span>
        </Grid>    
      </Paper> */}
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridCheckbox}>
              <Checkbox className={classes.checkBoxFont} disabled />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}></TableCell>
            <TableCell className={classes.gridHeaderInner}>FILE NAME</TableCell>
            <TableCell className={classes.gridHeaderInner}>
              FEATURED
              <Checkbox className={classes.checkBoxFont} />
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>FILE TYPE</TableCell>
            <TableCell className={classes.gridHeaderInner}>FILE SIZE</TableCell>
            <TableCell className={classes.gridHeaderInner}>
              DATE UPLOADED
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              QUICK ACTIONS
            </TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assetData &&
            assetData.length > 0 &&
            assetData.map((item, index) => {
              return (
                <TableRow key={index} className='gridBodyRow'>
                  <TableCell className={classes.gridCheckbox}>
                    <Checkbox className={classes.checkBoxFont} />
                  </TableCell>
                  <TableCell className={classes.gridCheckbox}>
                    {/* {(item.type === "png" ||
                      item.type === "jpg" ||
                      (item.type === "jpeg" && item.url)) && (
                      <span className="releaseViewAvatarWrapper">
                        <img src={item.url} alt="" />
                      </span>
                    )} */}
                    <span className='releaseViewAvatarWrapper'>
                      <img
                        style={{ maxHeight: "25px", maxWidth: "25px" }}
                        src={checkFileType(item)}
                        alt=''
                      />
                    </span>
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.name}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    <Checkbox className={classes.checkBoxFont} />
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.type}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {item.size ? item.size : ""}
                  </TableCell>
                  <TableCell className={classes.gridBodyInner}>
                    {moment(item.uploadedAt).format("MM/DD/YYYY")}
                  </TableCell>
                  {/* <TableCell className={classes.inUseNo}>
                    No
                  </TableCell> */}
                  <TableCell className='editButton'>
                    <span>
                      <EditIcon />
                    </span>
                    <span onClick={() => removeCollection(item)}>
                      <Trash />
                    </span>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ReleaseAssetGrid;
