import React, { memo, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import queryString from "query-string";
import Dashbard from "../../Dashbard";
import { PreviewPriceList } from "./PreviewPriceList";
import { Spinner } from "../../../../../components/common";
import { TopTabNavigator } from "../../../../../components/common/Tabs/TopTabNavigator";
import { CreateBasicsPriceList } from "./CreateBasicsPriceList";
import { usePriceList } from "../../../../../utils/use-price-list";
import { toast } from "react-toastify";
import { RoutesObj } from "../../../../../routes";

const CreatePriceList = memo(() => {
  const history = useHistory();
  const location = useLocation();
  const pricingList = queryString.parse(location.search);

  const [activeTabId, setActiveTabId] = useState("1");
  const [previewData, setPreviewData] = useState(null);
  const [step, setStep] = useState(1);

  const {
    isProcessing,
    priceList,
    setPriceList,
    pricingGroupsByPapId,
    addUpdatePriceList,
    addUpdatePriceListVisibility,
    updatePriceListDesign,
    addUpdatePriceListColumns,
    deletePriceList,
  } = usePriceList({
    priceListId: pricingList?.id,
  });

  useEffect(() => {
    if (previewData) setPreviewData(previewData => previewData);
  }, [activeTabId, previewData]);

  return (
    <Dashbard>
      <div className='body-content create-price-list'>
        {isProcessing ? <Spinner /> : null}
        <div className='header-wrap'>
          <h1>{priceList?.priceListNameInternal || "New Price List"}</h1>
          <Button
            color='primary'
            variant='text'
            onClick={() => history.push("/price-list")}>
            <ArrowBackIos style={{ fontSize: "14px" }} />
            {`Back to All Price Lists`}
          </Button>
        </div>
        <TopTabNavigator
          getActiveTabId={tabId => setActiveTabId(`${tabId}`)}
          tabId={`${activeTabId}-tab`}
          value={activeTabId}
          tabs={[
            {
              id: "1",
              title: "Basics",
              component: (
                <CreateBasicsPriceList
                  priceList={priceList}
                  setPriceList={setPriceList}
                  onplDataChanged={pdDataChange => {
                    setPreviewData(pdDataChange);
                  }}
                  pricingGroupsByPapId={pricingGroupsByPapId}
                  addUpdatePriceList={addUpdatePriceList}
                  addUpdatePriceListVisibility={addUpdatePriceListVisibility}
                  updatePriceListDesign={updatePriceListDesign}
                  addUpdatePriceListColumns={addUpdatePriceListColumns}
                  onDeletePriceList={async plIds => {
                    const response = await deletePriceList(plIds);
                    if (response?.success) {
                      toast.success("Pricing list deleted successfully");
                      history.replace(RoutesObj.PriceList.path);
                    }
                  }}
                  onPreview={() => setActiveTabId(`${2}`)}
                  activeStep={step}
                  setActiveStep={setStep}
                />
              ),
            },
            {
              id: "2",
              title: "Preview",
              component: (
                <PreviewPriceList
                  isActive={activeTabId === "2"}
                  priceListId={pricingList?.id}
                  plData={priceList}
                />
              ),
            },
          ]}
        />
      </div>
    </Dashbard>
  );
});

export { CreatePriceList };
