import React, { useState } from "react";
import LeftNav from "../LeftNav/LeftNav";
import { withRouter } from "react-router-dom";
import DashboardHeader from "../../../components/common/Header/DashboardHeader";
import Loader from "../../../components/common/Loader";

function Dashbard(props) {
  const [open, setOpen] = useState(true);
  const [isLoading, setLoading] = useState(false);

  return (
    <div>
      <div>
        <LeftNav
          openNav={open}
          setOpen={() => setOpen(!open)}
          startLoading={() => setLoading(true)}
          endLoading={() => setLoading(false)}
        />
        {isLoading ? <Loader /> : null}
        <div className={`mainWrapper ${open ? "contentShrink" : ""}`}>
          <DashboardHeader />
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Dashbard);
