import React, { useEffect, useState } from "react";
import { Close, Search, Clear } from "@material-ui/icons";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  makeStyles,
  OutlinedInput,
} from "@material-ui/core";
import { concat } from "lodash";
import { PopupDialog } from "../../../../../components/common";
import { useDebounce } from "../../../../../utils/use-debounce";

const PriceListAccessPopup = ({
  title,
  isVisible,
  onClose,
  onSelect,
  listData,
  selectedOptions,
  labelPropName = "value",
  accessLvlSettings,
}) => {
  const [filteredList, setFilteredList] = useState([]);
  const [filterByName, setFilterByName] = useState("");
  const { debouncedValue } = useDebounce(filterByName, 500);

  const [slectedGroups, setSelectedOptions] = useState([]);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    if (debouncedValue) {
      setFilteredList([
        ...listData.filter(c =>
          c[labelPropName].toLowerCase().includes(debouncedValue.toLowerCase())
        ),
      ]);
    } else {
      setFilteredList(listData);
    }
  }, [debouncedValue, labelPropName, listData]);

  useEffect(() => {
    setFilteredList(listData);
  }, [listData]);

  useEffect(() => {
    if (selectedOptions?.length > 0) {
      const sGroups = listData
        .filter(i => selectedOptions.map(s => s.groupId).includes(i.id))
        .map(g => g?.value);
      setSelectedOptions([...sGroups]);
    }
  }, [listData, selectedOptions]);

  useEffect(() => {
    setSettings(accessLvlSettings);
  }, [accessLvlSettings]);

  return (
    <PopupDialog visible={isVisible} onClose={onClose}>
      <div className='audiancePopupWrapper'>
        <div className='audiancePopupInnerWrapper'>
          <span className='innerHeading'>{title || `Enter Title`}</span>
          <Close
            style={{
              cursor: "pointer",
              padding: "20px",
            }}
            onClick={onClose}
          />
        </div>
        <Grid item xs={12} style={{ paddingLeft: "13px" }}>
          <Input
            fullWidth
            placeholder='Search by name'
            disableUnderline
            style={{
              padding: "17px",
            }}
            startAdornment={
              <Search
                style={{
                  marginRight: "5px",
                }}
              />
            }
            onChange={({ target }) => setFilterByName(target.value)}
          />
        </Grid>
        {filteredList?.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "264px",
                overflowY: "auto",
                // maxHeight: "65%",
              }}>
              {filteredList.map((g, i) => {
                const gName = g[labelPropName];
                return (
                  <span className='innerList'>
                    <Checkbox
                      key={i}
                      label={gName}
                      checked={
                        slectedGroups.find(s => s === gName) ? true : false
                      }
                      onChange={({ target }) => {
                        if (target.checked) {
                          setSelectedOptions([...concat(slectedGroups, gName)]);
                        } else {
                          setSelectedOptions([
                            ...slectedGroups.filter(s => s !== gName),
                          ]);
                        }
                      }}
                    />
                    {labelPropName === "value" ? (
                      <>
                        {gName} <b>{g?.generic}</b>
                      </>
                    ) : (
                      gName
                    )}
                  </span>
                );
              })}
            </div>

            <Grid container spacing={2} className='price-access-settings'>
              <Grid item xs={12}>
                <lable className='slugLabel'>Price List Access Settings</lable>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.isAllAccess}
                      onChange={({ target }) => {
                        setSettings({
                          ...settings,
                          isAllAccess: target.checked,
                        });
                      }}
                    />
                  }
                  label='All Access'
                />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.canDownloadCSVAccess}
                      onChange={({ target }) => {
                        setSettings({
                          ...settings,
                          canDownloadCSVAccess: target.checked,
                        });
                      }}
                    />
                  }
                  label='Can Download CSV'
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.isViewOnlyAccess}
                      onChange={({ target }) => {
                        setSettings({
                          ...settings,
                          isViewOnlyAccess: target.checked,
                        });
                      }}
                    />
                  }
                  label='View Only Access'
                />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.canDownloadPDFAccess}
                      onChange={({ target }) => {
                        setSettings({
                          ...settings,
                          canDownloadPDFAccess: target.checked,
                        });
                      }}
                    />
                  }
                  label='Can Download PDF'
                />
              </Grid>
              <Grid item xs={12}>
                <lable className='slugLabel'>
                  Price List Visability Before Availability Start Date
                </lable>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.isMakeViewable}
                      onChange={({ target }) => {
                        setSettings({
                          ...settings,
                          isMakeViewable: target.checked,
                        });
                      }}
                    />
                  }
                  label='Make Viewable'
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  value={settings.makeViewableDays}
                  type='number'
                  min={0}
                  max={999}
                  onChange={({ target }) => {
                    if (target.value && parseInt(`${target.value}`, 10) < 999)
                      setSettings({
                        ...settings,
                        makeViewableDays: target.value
                          ? parseInt(`${target.value}`, 10)
                          : "",
                      });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <lable
                  className='MuiTypography-body1'
                  style={{ float: "left", marginTop: "10px" }}>
                  Days before Availability Start Date
                </lable>
              </Grid>
            </Grid>
          </>
        ) : (
          <span
            style={{
              margin: "20px",
              display: "flex",
            }}>
            No Group available
          </span>
        )}
        {filteredList?.length > 0 && (
          <div className='audianceBtnWrapper'>
            <Button
              variant='contained'
              color='primary'
              //   disabled={tempVisibilityGroups.length <= 0}
              onClick={() => {
                if (slectedGroups?.length > 0) {
                  const sGroups = listData
                    .filter(i => slectedGroups.map(n => n).includes(i.value))
                    .map(g => {
                      const accessSetting = selectedOptions.find(
                        s => s?.groupId === g?.id
                      );
                      return {
                        ...settings,
                        id: accessSetting?.id || 0,
                        groupId: g.id,
                        type: g.generic,
                      };
                    });
                  onSelect(sGroups);
                } else
                  onSelect({
                    ...settings,
                  });
              }}>
              Add
            </Button>
          </div>
        )}
      </div>
    </PopupDialog>
  );
};

const CopyPriceListAccessPopup = ({
  title,
  isVisible,
  onClose,
  onSelect,
  listData,
  labelPropName = "value",
}) => {
  const [filteredList, setFilteredList] = useState([]);
  const [filterByName, setFilterByName] = useState("");
  const { debouncedValue } = useDebounce(filterByName, 500);

  const [slectedPLIds, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (debouncedValue) {
      setFilteredList([
        ...listData.filter(c =>
          c[labelPropName].toLowerCase().includes(debouncedValue.toLowerCase())
        ),
      ]);
    } else {
      setFilteredList(listData);
    }
  }, [debouncedValue, labelPropName, listData]);

  useEffect(() => {
    setFilteredList(listData);
  }, [listData]);

  return (
    <PopupDialog visible={isVisible} onClose={onClose}>
      <div className='audiancePopupWrapper'>
        <div className='audiancePopupInnerWrapper'>
          <span className='innerHeading'>{title || `Enter Title`}</span>
          <Close
            style={{
              cursor: "pointer",
              padding: "20px",
            }}
            onClick={onClose}
          />
        </div>
        <Grid item xs={12} style={{ paddingLeft: "13px" }}>
          <Input
            fullWidth
            placeholder='Search by name'
            disableUnderline
            style={{
              padding: "17px",
            }}
            startAdornment={
              <Search
                style={{
                  marginRight: "5px",
                }}
              />
            }
            onChange={({ target }) => setFilterByName(target.value)}
          />
        </Grid>
        {filteredList?.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "264px",
                overflowY: "auto",
              }}>
              {filteredList.map((g, i) => {
                return (
                  <span className='innerList'>
                    <Checkbox
                      key={i}
                      label={g[labelPropName]}
                      checked={
                        slectedPLIds.find(s => s === g.id) ? true : false
                      }
                      onChange={({ target }) => {
                        if (target.checked) {
                          setSelectedOptions([...concat(slectedPLIds, g.id)]);
                        } else {
                          setSelectedOptions([
                            ...slectedPLIds.filter(s => s !== g.id),
                          ]);
                        }
                      }}
                    />
                    {g[labelPropName]}
                  </span>
                );
              })}
            </div>

            <Grid container spacing={2} className='price-access-settings'>
              <Grid item xs={9}>
                <p>
                  <b>Note:</b> This will copy all Contacts/Companies/ Groups
                  from the selected price list as well as any visability
                  settings prior to product availability.
                </p>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant='contained'
                  color='primary'
                  //   disabled={tempVisibilityGroups.length <= 0}
                  onClick={() => {
                    onSelect(slectedPLIds);
                  }}>
                  Copy Access
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <span
            style={{
              margin: "20px",
              display: "flex",
            }}>
            No price list available
          </span>
        )}
      </div>
    </PopupDialog>
  );
};

const GroupNameChip = ({ group, onRemove }) => {
  const classes = useStyles();

  return (
    <span className={classes.chipWrapper}>
      <span>{group?.value}</span>
      {group?.isAllAccess ? (
        <span className={classes.blueText}>All Access</span>
      ) : group?.isViewOnlyAccess ? (
        <span className={classes.blueText}>View Only</span>
      ) : (
        <span className={classes.blueText}></span>
      )}
      <span className={classes.deleteLabels} onClick={() => onRemove(group)}>
        <Clear />
      </span>
    </span>
  );
};

const useStyles = makeStyles(theme => ({
  chipWrapper: {
    backgroundColor: "#F3F6F9",
    borderRadius: "36px",
    color: "#70808F",
    fontWeight: "400",
    padding: "8px 16px 8px 16px",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 7px 10px 7px",
    display: "inline-flex",
  },
  blueText: {
    color: "#3699FF",
    margin: "0px 5px",
  },
  deleteLabels: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#A9B2BC",
  },
}));

export { PriceListAccessPopup, CopyPriceListAccessPopup, GroupNameChip };
