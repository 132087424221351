import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { InputField } from "../../../../../components/common";
import { Add, ChevronRight } from "@material-ui/icons";
import CustomCheckbox from "../../../../../components/common/checkbox/CustomCheckbox";
import RadioButtons from "../../../../../components/common/RadioButton/RadioButton";
import { Autocomplete } from "@material-ui/lab";
import { FiChevronDown } from "react-icons/fi";
import { CustomConversationPopup } from "./CustomConversationPopup";
import { ConversationBasicPopupGrid } from "./ConversationBasicPopupGrid";
import { CloseIconButton } from "../../../LeftNav/SvgIcons";
import OutsideClickHandler from "react-outside-click-handler";
import { ConversationChip } from "./ConversationChip";
import { ConversationAccordionChips } from "./ConversationAccordionChips";

export const ConversationBasics = ({ 
  paramId, 
  basicConversationData,
  selectedConversationChannels,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [colorOption, setColorOption] = useState(false);
  const [activeTabId, setActiveTabId] = useState("1");
  const allTabs = [
    {
      id: "1",
      title: "All Channels",
      component: "",
    },
  ];
  const channeldata = [
    {
      label: "AAV Web Portal",
      channel: "OFish Conversations",
    },
    {
      label: "AAV Chat Bot",
      channel: "OFish Conversations",
    },
    {
      label: "AAV App Portal",
      channel: "OFish Conversations",
    },
    {
      label: "support@aquaticav.com ",
      channel: "E-Mail",
    },
    {
      label: "Facebook ",
      channel: "Facebook",
    },
    {
      label: "Instagram ",
      channel: "Instagram",
    },
  ];


  const colors = [
    { color: "#2D6EC8" },
    { color: "#8254E3" },
    { color: "#00ABA3" },
    { color: "#E06145" },
    { color: "#FFAD4E" },
    { color: "#3699FF" },
    { color: "#18BC6D" },
    { color: "#36CFC9" },
    { color: "#54B0E3" },
    { color: "#80D7D3" },
    { color: "#FFEC3D" },
    { color: "#B867AB" },
    { color: "#439442" },
    { color: "#E23C3C" },
    { color: "#DDD587" },
    { color: "#E18DB0" },
    { color: "#FE157F" },
    { color: "#9FB6E5" },
    { color: "#F997EF" },
    { color: "#8FA3B5" },
  ];
  return (
    <div className="conversationBasicsSetting">
      <ValidatorForm ref={useRef("form")}>
        <Grid container spacing={2}>
          <Divider />
          <Grid item xs={4}>
            <InputField
              showRequired={true}
              placeholder="Conversation Type1"
              inputlabel={"Conversation Type Name (Internal)"}
              id="conversation-name"
              name="conversation-name"
              validators={["required"]}
              // onChange={({ target }) =>
              //     releaseBasicsProps("nameInternal", target.value)
              // }
              value={basicConversationData?.nameInternal}
              errorMessages={[`Please enter Conversation Type Name (Internal)`]}
              disabled={paramId}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              placeholder="Conversation Type1"
              inputlabel={"Conversation Type Name (Public)"}
              id="conversation-name"
              name="conversation-name"
              validators={["required"]}
              disabled={paramId}
              // onChange={({ target }) =>
              //     releaseBasicsProps("nameInternal", target.value)
              // }
              value={basicConversationData?.namePublic}
              errorMessages={[`Please enter Conversation Type Name (Public)`]}
            />
          </Grid>
          <Grid item xs={4} className="colorContainer">
            <InputField
              placeholder="Conversation Type1"
              inputlabel={"Conversation Type Color (Tag)"}
              id="conversation-name"
              name="conversation-name"
              validators={["required"]}
              disabled={paramId}
              // onChange={({ target }) =>
              //     // releaseBasicsProps("nameInternal", target.value)
              // }
              // onKeyPress={handleKeyPress}
              value={basicConversationData?.color}
              errorMessages={[`Please enter Conversation Type Color (Tag)`]}
            />
            <OutsideClickHandler onOutsideClick={() => setColorOption(false)}>
              <span
                className="colorBox"
                style={{ background: `${basicConversationData?.color}`  }}
                onClick={() => setColorOption(true)}
              />
              {colorOption && (
                <div className="colorOptions">
                  {colors.map((item, index) => {
                    return (
                      <span ket={item.id}>
                        <button
                          style={{ background: `${item.color}` }}
                          onClick={() => {
                            // basicConversationProps("color", item.color)
                            setColorOption(false)
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              )}
            </OutsideClickHandler>
          </Grid>

          <Grid xs={9} item>
            <InputField
              placeholder="This is Conversation Type 1"
              inputlabel={`Description`}
              id="conversation-name"
              name="conversation-name"
              validators={["required"]}
              disabled={paramId}
              errorMessages={[`Please Select Assigned Inbox`]}
              // onChange={({ target }) =>
              //     releaseBasicsProps("releaseId", target.value)
              // }
              value={basicConversationData?.shortDescription}
            />
          </Grid>
          <Grid xs={12} className="conversationChannel">
            {!paramId ? (
              <Button
                color="primary"
                className="addBtn"
                variant="contained"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <Add />
              </Button>
            ) : null}

            <Typography
              variant="body1"
              className="channelHeadings accordionTitle"
            >
              Conversation Channels
            </Typography>
            <CustomConversationPopup
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title="Conversation Channels"
              heading="OFish Channels"
              description="Choose the channels through which this type of conversation can be started and used."
              allTabs={allTabs}
              activeTabId={activeTabId}
              setActiveTabId={setActiveTabId}
            >
              <ConversationBasicPopupGrid />
            </CustomConversationPopup>
          </Grid>
          <Grid xs={12}>
            <p className="chooseChannel">
              Choose the channels through which this type of conversation can be
              started and used.<span> Learn More.</span>
            </p>

            {/* <Grid className="conversationChannelLabelsWrapper">
              {channeldata.map((item, index) => {
                return (
                  <span className="conversationChannelLabel" key={item.id}>
                    <span>
                      {item.label} <i>{item.channel}</i>
                    </span>
                    <span className="conversationChanneldeleteLabel">
                      <CloseIconButton />
                    </span>
                  </span>
                );
              })}
            </Grid> */}
            <ConversationAccordionChips
            text="Added conversation channel will appear here"
            chipData={selectedConversationChannels} />
          </Grid>
          <Grid xs={12} className="conversationSettingContainer">
            <h3>Conversation Settings</h3>
            <Grid item className="conversationChecked">
              <Typography variant="body2">
                Can visitors participate in this conversation type?
              </Typography>
              <Grid>
                <Grid item className="radioButtton" style={{ opacity:basicConversationData.isPublic !== true && paramId  ? 0.5 : 1}}>
                  <RadioButtons
                    label={"Yes"}
                    disabled={basicConversationData.isPublic !== true && paramId}
                    value={basicConversationData?.isPublic}
                    selectedValue={basicConversationData.isPublic === true}
                    // handleChange={() =>
                    //     basicConversationProps(
                    //         "isVisitorsAllowed",
                    //         true
                    //     )
                    // }
                    />
                  <List>
                    <ListItem className="checkList">
                      Yes{" "}
                      <Typography variant="caption">
                        - Visitors are allowed to chat with this Conversation
                        Type
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item className="radioButtton" style={{ opacity:basicConversationData.isPublic !== false && paramId  ? 0.5 : 1}}>
                  <RadioButtons
                    label={"No"}
                    value={!basicConversationData?.isPublic}
                    disabled={basicConversationData.isPublic !== false && paramId}
                    selectedValue={basicConversationData.isPublic === false}
                    // handleChange={() =>
                    //     portalAndContactBasicsProps({
                    //         propName: "isForceUpdate",
                    //         value: true,
                    //     })
                    // }
                  />
                  <List>
                    <ListItem className="checkList">
                      No{" "}
                      <Typography variant="caption">
                        - Users must have an account/Logged-In to access this
                        Conversation Type
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="conversationChecked">
              <Typography variant="body2">
                Does this conversation allow for more than two participants?
              </Typography>
              <Grid>
                <Grid item className="radioButtton"  style={{ opacity:basicConversationData.isGroupChat !== true && paramId  ? 0.5 : 1}}>
                <RadioButtons
                    label={"Yes"}
                    disabled={basicConversationData.isGroupChat !== true && paramId}
                    value={basicConversationData?.isGroupChat}
                    selectedValue={basicConversationData?.isGroupChat === true}
                    // handleChange={() =>
                    //     basicConversationProps(
                    //         "isVisitorsAllowed",
                    //         true
                    //     )
                    // }
                    />
                  <List>
                    <ListItem className="checkList">
                      Yes{" "}
                      <Typography variant="caption">
                        - Group Chats are allowed between 3+ users (Visitors are
                        not able to join group conversations)
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item className="radioButtton" style={{ opacity:basicConversationData.isGroupChat !== false && paramId  ? 0.5 : 1}}>
                <RadioButtons
                    label={"No"}
                    disabled={basicConversationData.isGroupChat !== false && paramId}
                    value={!basicConversationData?.isGroupChat}
                    selectedValue={basicConversationData?.isGroupChat === false}
                    // handleChange={() =>
                    //     basicConversationProps(
                    //         "isVisitorsAllowed",
                    //         true
                    //     )
                    // }
                    />
                  <List>
                    <ListItem className="checkList">
                      No{" "}
                      <Typography variant="caption">
                        - 1:1 Chats Between Two Users Only Allowed
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="conversationChecked">
              <Typography variant="body2">
                Hide conversations your brand is not in?
              </Typography>
              <span className="checkedText">
                If you allow customers to message each other, such as family
                members or B2B customers, you can hide their conversations from
                your main inbox.
              </span>
              <Grid>
                <Grid item className="radioButtton" style={{ opacity:basicConversationData.isDeplayedInAdminInbox !== true && paramId  ? 0.5 : 1}}>
                <RadioButtons
                    label={"Yes"}
                    disabled={basicConversationData.isDeplayedInAdminInbox !== true &&  paramId}
                    value={basicConversationData?.isDeplayedInAdminInbox}
                    selectedValue={basicConversationData?.isDeplayedInAdminInbox === true}
                    // handleChange={() =>
                    //     basicConversationProps(
                    //         "isVisitorsAllowed",
                    //         true
                    //     )
                    // }
                    />
                  <List>
                    <ListItem className="checkList">
                      Yes{" "}
                      <Typography variant="caption">
                        - Hide conversations not involving my brand (You can
                        still find these conversations in your archive or in
                        individual contact profiles)
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item className="radioButtton" style={{ opacity:basicConversationData.isDeplayedInAdminInbox !== false && paramId  ? 0.5 : 1}}>
                  <RadioButtons
                    label={"No"}
                    value={!basicConversationData?.isDeplayedInAdminInbox}
                    disabled={basicConversationData.isDeplayedInAdminInbox !== false && paramId}
                    selectedValue={basicConversationData?.isDeplayedInAdminInbox === false}
                    // handleChange={() =>
                    //     portalAndContactBasicsProps({
                    //         propName: "isForceUpdate",
                    //         value: true,
                    //     })
                    // }
                  />
                  <List>
                    <ListItem className="checkList">
                      No{" "}
                      <Typography variant="caption">
                        - Show all conversations in my conversations inboxes
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};
