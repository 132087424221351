import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidatorForm } from "react-material-ui-form-validator";
import queryString from "query-string";
import InputField from "../../../../components/common/InputField";
import { TickMark, RedCross } from "../../LeftNav/SvgIcons";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { AddressAutoComplete } from "../../../../components/common";
import PhoneNumberInput from "../../../../components/common/PhoneNumberInput/PhoneNumberInput";

const useStyles = makeStyles(theme => ({
  gridBoder: {
    borderBottom: "1px solid #e2e6e9",
  },
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  selectedItems: {
    textAlign: "right",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E6F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  pendingButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  launchedButtons: {
    color: "#FFAD4E",
    backgroundColor: "rgba(255, 173, 78, 0.25)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 173, 78, 0.25)",
    },
  },
  inUseYes: {
    color: "#18BC6D",
  },
  inUseNo: {
    color: "#E06145",
  },
  phoneNumberInputError: {
    border: "1px solid #F44336 !important",
    width: "120px !important",
    "& .MuiInput-underline:before": {
      borderBottom: "unset",
    },
    "& .formMainWrapper input": {
      border: "unset !important",
    },
    "& .MuiInputBase-input": {
      height: "34px",
      border: "none !important",
      flex: "0 0 88.5% !important",
      borderRadius: "0px 6px 6px 0px",
    },
    "& MuiInput-root.Mui-disabled": {
      background: "#f9f9f9 !important",
      color: "#4f4f4f !important",
    },
  },
  phoneNumberInput: {
    width: "120px !important",
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    "& .MuiInput-underline:before": {
      borderBottom: "unset",
    },
    "& .formMainWrapper input": {
      border: "unset !important",
    },
    "& .MuiInputBase-input": {
      height: "34px",
      border: "none !important",
      flex: "0 0 78% !important",
      borderRadius: "0px 6px 6px 0px",
    },
    "& MuiInput-root.Mui-disabled": {
      background: "#f9f9f9 !important",
      color: "#4f4f4f !important",
    },
  },
  phoneNumberLable: {
    fontSize: "14px",
    color: "#2d3339",
    fontWeight: "500",
    marginBottom: "6px",
    minHeight: "19px",
    zIndex: 1,
  },
  requiredStar: {
    color: "#ff4500",
  },
}));

const AddressTypeKeyValue = {
  "Billing Address": 1,
  "Shipping Address": 2,
  "Installation Address": 3,
};
const AddressKeyTypeValue = {
  1: "Billing Address",
  2: "Shipping Address",
  3: "Installation Address",
};

function AddressForm({
  countries,
  cities,
  provinces,
  setIsNewAddress,
  editData,
  loadAddress,
  setLoading,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  let isDisable = [];

  const [selectPhnType, setSelectPhnType] = useState("mobile");
  const [nickAddress, setNickAddress] = useState("");
  const [mainContact, setMainContact] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [addressType, setAddressType] = useState(0);
  const [selectedAddressTypes, setAddressTypes] = useState([]);
  const [isEditAble, setIsEditAble] = useState([]);
  const [countryCodeError, setCountryCodeError] = useState("");

  const selectedUser = queryString.parse(location.search);
  useEffect(() => {
    if (editData) {
      setNickAddress(editData.addressNickname);
      setMainContact(editData.mainContactName);
      setStreetAddress(editData.streetAddress);
      setPostalCode(editData.zipCode);
      setPhoneNum(editData.phone);
      setSelectPhnType(editData.phoneType);
      setSelectedCity(editData.city);
      setSelectedCountry(editData.country);
      setSelectedProvince(editData.state);
      setCountryCode(editData?.countryCode ? editData?.countryCode : "1");
      if (editData?.addressType?.length > 0)
        setAddressTypes([
          ...editData.addressType.map(a => AddressKeyTypeValue[a]),
        ]);
    }
  }, [editData]);

  const classes = useStyles();

  const handleAddressSubmit = e => {
    e.preventDefault();
    onAddUpdateAdrCus();
  };

  const onAddUpdateAdrCus = () => {
    if (addressType !== 0) {
      const cusAdrPayload = {
        id: editData ? editData.id : 0,
        userId: selectedUser.id,
        addressNickname: nickAddress,
        mainContactName: mainContact,
        streetAddress: streetAddress,
        city: selectedCity,
        state: selectedProvince,
        country: selectedCountry,
        zipCode: postalCode,
        phoneType: selectPhnType,
        phone: phoneNum,
        countryCode: countryCode,
        addressType: addressType,
      };
      setLoading(true);
      dispatch(endUserActions.onAddCusAdr(cusAdrPayload)).then(res => {
        if (res?.success) {
          loadAddress();
          setIsNewAddress(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      toast.error("Please select Address Type");
    }
  };

  useEffect(() => {
    if (selectedAddressTypes)
      setAddressType([
        ...selectedAddressTypes.map(a => AddressTypeKeyValue[a.trim()]),
      ]);
  }, [selectedAddressTypes]);

  const handlePhoneChange = (value, countryData) => {
    if (!countryData.dialCode) {
      setCountryCodeError("Invalid Country Code");
    } else {
      setCountryCodeError("");
    }
    setCountryCode(countryData.dialCode);
  };

  return (
    <div className='borderBottom addressTickButton'>
      <ValidatorForm
        ref={React.useRef("addressForm")}
        onSubmit={handleAddressSubmit}>
        <Grid item md={12} className={classes.gridWrapper}>
          <span className='thumbIcon tickMarkCross '>
            <span onClick={() => setIsNewAddress(false)}>
              <RedCross />
            </span>
            <Button type='submit'>
              <TickMark />
            </Button>
          </span>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <InputField
                id='addressNick'
                placeholder='Enter an Address Nickname'
                inputlabel='Address Nickname'
                showRequired={true}
                className={classes.gridWrapper}
                name='addressnickname'
                value={nickAddress}
                onChange={v => setNickAddress(v.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </Grid>

            <Grid item xs={4}>
              <InputField
                id='mainContact'
                placeholder='Enter a Main Contact'
                inputlabel='Main Contact Name'
                showRequired={true}
                className={classes.gridWrapper}
                name='maincontactname'
                value={mainContact}
                onChange={v => setMainContact(v.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} className={classes.gridWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/* <InputField
                id='streetAdd'
                placeholder='Enter an Address'
                inputlabel='Street Address*'
                className={classes.gridWrapper}
                name='streetAddress'
                value={streetAddress}
                onChange={v => setStreetAddress(v.target.value)}
                validators={["required"]}
                errorMessages={["This field is required"]}
              /> */}
              <AddressAutoComplete
                address={streetAddress}
                autoComId={"popupEnvLocInput"}
                onChange={e => {
                  setStreetAddress(e.target.value);
                }}
                setPlace={p => {
                  isDisable = [];
                  setSelectedCity("");
                  setSelectedProvince("");
                  setSelectedCountry("");
                  setPostalCode("");
                  const val =
                    document.getElementById("popupEnvLocInput")?.value;
                  p?.address_components?.forEach(x => {
                    if (x?.types?.includes("route")) {
                      setStreetAddress(x?.long_name);
                    }
                    if (x?.types?.includes("locality")) {
                      setSelectedCity(x?.long_name);
                      isDisable.push("city");
                    }
                    if (x?.types?.includes("administrative_area_level_1")) {
                      setSelectedProvince(x?.long_name);
                      isDisable.push("state");
                    }
                    if (x?.types?.includes("country")) {
                      setSelectedCountry(x?.long_name);
                      isDisable.push("country");
                    }
                    if (x?.types?.includes("postal_code")) {
                      setPostalCode(x?.long_name);
                      isDisable.push("postalCode");
                    }
                  });
                  setIsEditAble(isDisable);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <div className='inputWrapper contactInputWrapper'>
                <FormControl className={classes.formControl}>
                  <InputField
                    placeholder='Enter country or region'
                    inputlabel='Country or region'
                    showRequired={true}
                    validators={["required"]}
                    errorMessages={["Please enter country or region"]}
                    value={selectedCountry}
                    onChange={v => setSelectedCountry(v.target.value)}
                    // disabled={isEditAble.includes("country") ? true : false}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={10} className={classes.gridWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div className='inputWrapper contactInputWrapper'>
                <FormControl className={classes.formControl}>
                  <InputField
                    placeholder='Enter City'
                    showRequired={true}
                    inputlabel='City'
                    validators={["required"]}
                    errorMessages={["Please enter city"]}
                    value={selectedCity}
                    onChange={v => setSelectedCity(v.target.value)}
                    // disabled={isEditAble.includes("city") ? true : false}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className='inputWrapper contactInputWrapper'>
                <FormControl className={classes.formControl}>
                  <InputField
                    placeholder='Enter State'
                    inputlabel='State'
                    validators={["required"]}
                    showRequired={true}
                    errorMessages={["Please enter state"]}
                    value={selectedProvince}
                    onChange={v => setSelectedProvince(v.target.value)}
                    // disabled={isEditAble.includes("state") ? true : false}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={3}>
              <InputField
                placeholder='Zip code or postal code'
                inputlabel='Zip code or postal code'
                className={classes.gridWrapper}
                name='postalCode'
                value={postalCode}
                onChange={v => setPostalCode(v.target.value)}
                // disabled={isEditAble.includes("postalCode") ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={10} className={classes.gridWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <FormControl className='additionalPhoneNumber'>
                <span className={classes.phoneNumberLable}>
                  Country Code
                  <span className={classes.requiredStar}>*</span>
                </span>
                <PhoneNumberInput
                  className={
                    countryCodeError
                      ? classes.phoneNumberInputError
                      : classes.phoneNumberInput
                  }
                  id='phone-input'
                  name='phone-input'
                  placeholder='Please Enter Phone Number'
                  value={countryCode}
                  onChange={handlePhoneChange}
                  error={countryCodeError}
                  readOnly={true}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <InputField
                id='phoneNumber'
                placeholder='Phone Number'
                inputlabel='Phone Number'
                showRequired={true}
                className={classes.gridWrapper}
                name='phoneNumber'
                value={phoneNum}
                onChange={v => setPhoneNum(v.target.value.replace(/\D/g, ""))}
                validators={["required", "matchRegexp:^\\d{7,12}$"]}
                errorMessages={[
                  "This field is required",
                  "Not valid number",
                  "Maximum length is 20 characters",
                ]}
              />
            </Grid>
            {/* <Grid item xs={2}>
              <div className='inputWrapper contactInputWrapper'>
                <FormControl className={classes.formControl}>
                  <label>Select Type</label>
                  <Select
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                    fullWidth
                    variant={"outlined"}
                    value={selectPhnType}
                    onChange={e => {
                      setSelectPhnType(e.target.value);
                    }}>
                    <MenuItem value={"mobile"}>Mobile</MenuItem>
                    <MenuItem value={"home"}>Home</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid> */}
            <Grid item xs={5}>
              <div className='inputWrapper multi-select-cb'>
                <label>Address Type*</label>
                <Select
                  multiple
                  value={selectedAddressTypes}
                  onChange={({ target }) =>
                    setAddressTypes(
                      typeof target.value === "string"
                        ? target.value.split(",")
                        : target?.value
                    )
                  }
                  // input={<OutlinedInput label='Tag' />}
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <em>Address Type</em>;
                    }

                    return selected.join(", ");
                  }}
                  // MenuProps={MenuProps}
                >
                  <MenuItem disabled value=''>
                    <em>Address Type</em>
                  </MenuItem>
                  {[
                    "Billing Address",
                    "Shipping Address",
                    "Installation Address",
                  ].map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={selectedAddressTypes.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {/* <div className='inputWrapper'>
                <div className='addressCheckbox'>
                  <span className='dateCheckbox' style={{ marginTop: "12px" }}>
                    <CustomCheckbox
                      className={classes.checkBoxFont}
                      checkedId={"type1"}
                      pressCheck={() => setAddressType(1)}
                      checkValue={addressType === 1 ? true : false}
                    />
                    Billing Address
                  </span>

                  <span className='dateCheckbox' style={{ marginTop: "12px" }}>
                    <CustomCheckbox
                      className={classes.checkBoxFont}
                      checkedId={"type2"}
                      pressCheck={() => setAddressType(2)}
                      checkValue={addressType === 2 ? true : false}
                    />
                    Shipping Address
                  </span>

                  <span className='dateCheckbox' style={{ marginTop: "12px" }}>
                    <CustomCheckbox
                      className={classes.checkBoxFont}
                      checkedId={"type3"}
                      pressCheck={() => setAddressType(3)}
                      checkValue={addressType === 3 ? true : false}
                    />
                    Installation Address
                  </span>
                </div>
              </div> */}
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
}

export default AddressForm;
