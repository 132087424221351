import React, { useState, useMemo, useCallback } from "react";
import InputField from "../../../../../components/common/InputField";
import { useDispatch, useSelector } from "react-redux";
import { endUserActions } from "../../../../../store/endUser/endUserActions";
import { contactsActions } from "../../../../../store/contacts/contactsActions";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";
import { Dropdown } from "../../../../../components/common";
import RecieptModal from "./RecieptModal";
import { FormattedDateInput } from "../../../../../components/common/FormattedDateInput";
import moment from "moment";
import AutoComplete from "../../../../../components/common/AutoComplete";
import { debounce } from "lodash";
import EmptyTextarea from "../../../../../components/common/Textarea";

function ProductRegistration({
  loadData,
  startLoading,
  stopLoading,
  isEditOverView,
  purchaseLoc,
  setPurchaseLoc,
  purchaseDate,
  setPurchaseDate,
  installationDate,
  setInstallationDate,
  isStatusRegistration,
  setIsStatusRegistration,
  isWarrantyStatusRegistration,
  setIsWarrantyStatusRegistration,
  setDeviceDealerId,
  setResgistrationNotes,
  registrationNotes,
}) {
  const dispatch = useDispatch();

  const [, setSelectedImg] = useState("");
  const [showFeatureImage, setShowFeatureImage] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [allDelears, setAllDelears] = useState([]);
  const [isUploadRecieptModal, setIsUploadRecieptModal] = useState(false);

  const uploadProfileImage = file => {
    startLoading();
    dispatch(contactsActions.onCompanyUploadImage(file)).then(data => {
      if (data && data.success && data.data) {
        const recptData = {
          id: deviceOverviewInfo?.id,
          basePath: "",
          receipt: data.data.url,
        };
        dispatch(endUserActions.onUpdateReceipt(recptData)).then(rec => {
          stopLoading();
          if (rec && rec.success) {
            setSelectedImg(data.data.url);
            loadData();
          } else {
            if (rec) {
              toast.error(rec.message);
            }
          }
        });
        setShowFeatureImage(false);
      } else {
        stopLoading();
      }
    });
  };

  const { deviceOverviewInfo } = useSelector(state => state.endUserReducer);

  const recieptModal = item => {
    setOpen(!open);
    if (item === "uploadReciept") {
      setIsUploadRecieptModal(true);
    } else {
      setIsUploadRecieptModal(false);
    }
  };
  const changeHandler = (event, catId) => {
    setLoading(true);
    dispatch(endUserActions.onGetAllDelears(event.target.value, catId)).then(
      res => {
        setAllDelears(res?.data || []);
        setLoading(false);
      }
    );
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  const maxPurchaseDate = useMemo(() => {
    const currentDate = new Date();
    const formattedCurrentDate = moment(currentDate).format("YYYY-MM-DD");

    const LimitInstallationDate = new Date(installationDate);
    const formattedInstallationDate = moment(LimitInstallationDate).format(
      "YYYY-MM-DD"
    );
    return installationDate ? formattedInstallationDate : formattedCurrentDate;
  }, [installationDate]);

  const insallationDateLimits = useMemo(() => {
    const maxDate = moment(new Date()).format("YYYY-MM-DD");

    const limitPurchaseDate = new Date(purchaseDate);
    const formattedPurchaseDate =
      moment(limitPurchaseDate).format("YYYY-MM-DD");

    return {
      max: maxDate,
      min: formattedPurchaseDate,
    };
  }, [purchaseDate]);

  const regisrtationStatusType = [
    {
      id: 0,
      name: "Pending",
      expectedResponse: "Pending",
      expectedResponse2: "Unregistered",
      expectedResponse3:"Registration Pending"
    },
    {
      id: 1,
      name: "Register",
      expectedResponse: "Approved",
      expectedResponse2: "Registered",
      expectedResponse3:"Registration Approved",
    },
    {
      id: 5,
      name: "Rejected",
      expectedResponse: "Expired",
      expectedResponse2: "Denied",
      expectedResponse3:"Registration Denied"
    },
    { 
      id: 3, 
      name: "Receipt Missing", 
      expectedResponse1: "Receipt Missing",
      expectedResponse2: "Receipt Missing" ,
      expectedResponse3: "Receipt Missing" 
    },
    { 
      id: 6, 
      name: "Incomplete", 
      expectedResponse1: "Incomplete",
      expectedResponse2: "Incomplete",
      expectedResponse3: "Mark As Incomplete",
    },
  ];
  
  const registrationStatusClasses = (status) => {
    switch (status) {
      case 0:
      case "Pending":
      case "Unregistered":
      case "Registration Pending":
        return "statusDropDownPending";
      case 1:
      case "Approved":
      case "Register":
      case "Registration Approved":
        return "statusDropDownRegistration";
      case 5:
      case "Expired":
      case "Denied":
      case "Registration Denied":
        return "statusDropDownExpired";
      case 3:
      case "Receipt Missing":
        return "statusDropDownReceiptMissing";
      case 6:
      case "Incomplete":
      case "Mark As Incomplete":
        return "statusDropDownPending";
      default:
        return "statusDropDownPending";
    }
  };

  const spaRegStatusClass = (status) => {
    switch (status) {
      case 0:
      case "Pending":
      case "Unregistered":
      case "Registration Pending":
        return "statusPending";
      case 1:
      case "Approved":
      case "Register":
      case "Registered":
      case "Registration Approved":
        return "statusRegistration";
      case 5:
      case "Expired":
      case "Denied":
      case "Registration Denied":
        return "statusExpired";
      case 3:
      case "Receipt Missing":
        return "statusReceiptMissing";
      case 6:
      case "Incomplete":
        case "Mark As Incomplete":
        return "statusPending";
      default:
        return "statusPending";
    }
  };

  const getRegStatusText = (status) => {
    switch (status) {
      case 0:
      case "Pending":
      case "Unregistered":
      case "Registration Pending":
        return "Pending";
      case 1:
      case "Approved":
      case "Register":
      case "Registered":
      case "Registration Approved":
        return "Registered";
      case 5:
      case "Expired":
      case "Denied":
      case "Registration Denied":
        return "Registration Denied";
        case 3:
        case "Receipt Missing":
          return "Receipt Missing";
      case 6:
      case "Incomplete":
      case "Mark As Incomplete":
        return "Mark As Incomplete";
      default:
        return 'Pending';
    }
  };

  const getRegStatusValue = (status) => {
    if (status === undefined || status === null) {
      return 0;
    } else if (typeof status === "number") {
      return status;
    } else {
      const foundStatus = regisrtationStatusType.find(item =>
        [item.name,item.expectedResponse, item.expectedResponse2, item.expectedResponse3].includes(status)
      );
      return foundStatus ? foundStatus.id : 0;
    }
  };
  
  const regStatusOptions = [
    { id: 0, name: "Pending" },
    { id: 1, name: "Register" },
    { id: 5, name: "Rejected" },
    { id: 3, name: "Receipt Missing" },
    { id: 6, name: "Mark As Incomplete" },
  ]

  return (
    <>
      {deviceOverviewInfo ? (
        <div className='deviceDetailMainContainer'>
          <div className='deviceDetailOverviewInner'>
            <div className='devicePicture'>
              {deviceOverviewInfo?.image && (
                <img
                  className='devicedDtailImageOverView'
                  src={deviceOverviewInfo?.image}
                  alt=''
                />
              )}
            </div>

            <div className='deviceOverViewDetail'>
              <div className='headingDetail'>Product Registration</div>
              <span className='deviceDetailOverView'>
                <ul>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Serial Number
                    </span>
                    <span className='deviceDetailProperty '>
                      {deviceOverviewInfo?.serialNo}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Purchase Location
                    </span>
                    <span className='deviceDetailProperty productRegistrationField'>
                      {!isEditOverView && (
                        <span>{deviceOverviewInfo?.dealer}</span>
                      )}
                      {isEditOverView && (
                        <AutoComplete
                          value={purchaseLoc}
                          onChange={e => {
                            debouncedChangeHandler(
                              e,
                              deviceOverviewInfo?.categoryId
                            );
                            setPurchaseLoc(e.target.value);
                          }}
                          eventLocation={allDelears}
                          setEvent={v => {
                            setPurchaseLoc(v.value);
                            setDeviceDealerId(v.companyId);
                          }}
                          showLocationName={true}
                        />
                      )}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Purchase Date
                    </span>
                    <span className='deviceDetailProperty productRegistrationField'>
                      {!isEditOverView && deviceOverviewInfo?.purchaseDate}
                      {isEditOverView && (
                        <FormattedDateInput
                          date={purchaseDate}
                          setDate={setPurchaseDate}
                          max={maxPurchaseDate}
                        />
                      )}
                    </span>
                  </li>
                  <li
                    style={{ position: "relative" }}
                    className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Installation Date
                    </span>
                    <span className='deviceDetailProperty productRegistrationField'>
                      {!isEditOverView && deviceOverviewInfo?.installationDate}
                      {isEditOverView && (
                        <FormattedDateInput
                          date={installationDate}
                          setDate={setInstallationDate}
                          max={insallationDateLimits.max}
                          min={insallationDateLimits.min}
                        />
                      )}
                    </span>
                  </li>

                  <li className='deviceDetailView'>
                    <span>Registration Status</span>
                    {isEditOverView ? (
                      <div
                        className={registrationStatusClasses(isStatusRegistration)}>
                        <Dropdown
                          selectlabel=''
                          value={getRegStatusValue(isStatusRegistration)}
                          handleChange={({ target }) => {
                            setIsStatusRegistration(target.value);
                          }}
                          customMenuItems={regStatusOptions}
                        />
                      </div>
                    ) : (
                      <div
                        className={spaRegStatusClass(deviceOverviewInfo?.registrationStatus) }>
                        {
                          getRegStatusText(deviceOverviewInfo?.registrationStatus)
                        }
                      </div>
                    )}
                  </li>
                   
                  <li className='deviceDetailView'>
                      <span>Notes</span>
                      {isEditOverView ? (
                        <div className="deviceRegNoteArea">
                        <EmptyTextarea
                          className='overviewTextarea'
                          placeholder='Write Notes'
                          value={registrationNotes}
                          onChange={v => setResgistrationNotes(v.target.value)}
                        />
                        </div>
                      ) : (
                        <p className='deviceDetailProperties deviceDetailNotes'>
                          {deviceOverviewInfo?.notes ?? "--"}
                        </p>
                      )}
                    </li>

                  <li className='deviceDetailView'>
                    <span>Warranty Status</span>
                    {isEditOverView ? (
                      <div
                        className={
                          isWarrantyStatusRegistration === "Active"
                            ? "statusDropDownRegistration"
                            : null ||
                              isWarrantyStatusRegistration === "Register"
                            ? "statusDropDownRegistration"
                            : null || isWarrantyStatusRegistration === "Expired"
                            ? "statusDropDownExpired"
                            : null
                        }>
                        <Dropdown
                          selectlabel=''
                          value={isWarrantyStatusRegistration}
                          handleChange={({ target }) => {
                            setIsWarrantyStatusRegistration(target.value);
                          }}
                          // placeholder='Product Status'
                          customMenuItems={[
                            { id: "Active", name: "Active" },
                            { id: "Expired", name: "Expired" },
                          ]}
                        />
                      </div>
                    ) : (
                      <span
                        className={
                          isWarrantyStatusRegistration === "Active"
                            ? "statusRegistration"
                            : null ||
                              isWarrantyStatusRegistration ===
                                "Pending Approval"
                            ? "statusPending"
                            : null || isWarrantyStatusRegistration === "Pending"
                            ? "statusPending"
                            : null ||
                              isWarrantyStatusRegistration === "Register"
                            ? "statusRegistration"
                            : null || isWarrantyStatusRegistration === "Expired"
                            ? "statusExpired"
                            : null
                        }>
                        {isWarrantyStatusRegistration}
                      </span>
                    )}
                  </li>

                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Warranty Expiration
                    </span>
                    <span className='deviceDetailProperty'>
                      {deviceOverviewInfo?.expiryDate}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Warranty Remaining
                    </span>
                    <span className='deviceDetailProperty deviceDetailNotCap'>
                      {deviceOverviewInfo?.remainingDays}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Registration Date
                    </span>
                    <span className='deviceDetailProperty deviceDetailNotCap'>
                      {deviceOverviewInfo?.registrationDate}
                    </span>
                  </li>
                  <li className='deviceDetailView'>
                    <span className='deviceDetailProperties'>
                      Approval Date
                    </span>
                    <span className='deviceDetailProperty deviceDetailNotCap'>
                      {deviceOverviewInfo?.approvedDate ?? "Not Available"}
                    </span>
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div className='deviceDetailbuttons'>
            {!isEditOverView &&
              deviceOverviewInfo?.receipt && (
                <Button onClick={recieptModal} className='ViewRecieptBtn'>
                  View Receipt
                </Button>
              )}
            {isEditOverView &&
              (getRegStatusValue(deviceOverviewInfo?.registrationStatus) === 3 || getRegStatusValue(deviceOverviewInfo?.registrationStatus) === 0) && (
                <Button
                  className='uploadRecieptBtn'
                  onClick={() => recieptModal("uploadReciept")}>
                  {deviceOverviewInfo?.receipt
                    ? "Replace Reciept"
                    : "Upload Reciept"}
                </Button>
              )}
          </div>
          <RecieptModal
            recieptModal={recieptModal}
            open={open}
            isUploadRecieptModal={isUploadRecieptModal}
            devId={deviceOverviewInfo.id}
          />
        </div>
      ) : (
        <div>
          <span>No Data available</span>
        </div>
      )}
    </>
  );
}

export default ProductRegistration;
