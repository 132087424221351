import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../components/common/InputField";
import { useRef, useState } from "react";
import SelectInput from "../../../../components/common/SelectInput";
import SoftwareMediaUploadPopup from "./SoftwareBuildUploadPopup";
import { BiUpload } from "react-icons/bi";
import { CloseChipIcon } from "../../LeftNav/SvgIcons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: "none",
  },
  chip: {
    "&.MuiChip-root": {
      background: "#F3F6F9 !important",
      padding: "8px 16px",
    },

    "& .MuiChip-label": {
      padding: 0,
      paddingRight: 10,
    },
    "& .MuiChip-deleteIcon": {
      margin: "0px !important",
    },
  },
  chipFileName: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#70808F",
  },
  chipFileSpaName: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#3699FF",
  },
  chipContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8px",
  },
}));

const statusList = [
  { id: 1, name: "Creating", },
  { id: 2, name: "Live" },
  { id: 3, name: "Retired" },
  { id: 4, name: "In Testing" },
];



const ReleaseBasicsAccordian = ({
  saveBasicInfo,
  formName,
  setRelaseContentDone,
  setRelaseContent,
  releaseContent,
  setPortalRequirment,
  portalRequirment,
  softwareReleaseData,
  accordionName,
  setAccordionName,
  setAccordionKey,
  isLoading,
  selectedRelease,
  releaseBasicsProps,
}) => {
  const [openUploadPopup, setOpenUploadPopup] = useState(false);
  const classes = useStyles();
  const { id: paramId } = useParams();

  const onSubmitReleaseBasic = () => {
    setRelaseContentDone(true);
    setRelaseContent(!releaseContent);
    setPortalRequirment(!portalRequirment);   
  };

  return (
    <ValidatorForm
      ref={useRef("form")}
      onSubmit={() => {
        saveBasicInfo(selectedRelease);
        setAccordionName("");
        setAccordionKey(2);
        onSubmitReleaseBasic();
      }}
    >
      <Grid container spacing={2} className="releaseInptField">
        <Divider />
        <Grid item xs={6}>
          <InputField
            showRequired={true}
            placeholder="Type an answer"
            inputlabel={`${formName} Name (Internal)`}
            id="release-name"
            name="release-name"
            validators={["required"]}
            onChange={({ target }) =>
              releaseBasicsProps("nameInternal", target.value)
            }
            value={selectedRelease?.nameInternal}
            errorMessages={[`Please enter ${formName}’s Internal Name`]}
            disabled={accordionName !== "releaseBasics"}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            placeholder="Type an answer"
            inputlabel={`${formName} Name (Public)`}
            id="release-name"
            name="release-name"
            onChange={({ target }) =>
              releaseBasicsProps("namePublic", target.value)
            }
            value={selectedRelease?.namePublic}
            disabled={accordionName !== "releaseBasics"}
          />
        </Grid>
        <Grid xs={3} item>
          <InputField
            showRequired={true}
            placeholder="Type an answer (autofill)"
            inputlabel={`${formName} ID`}
            id="release-name"
            name="release-name"
            validators={["required"]}
            errorMessages={[`Please enter ${formName}’s ID`]}
            onChange={({ target }) =>
              releaseBasicsProps("releaseId", target.value)
            }
            value={selectedRelease?.releaseId}
            disabled={accordionName !== "releaseBasics"}
          />
        </Grid>
        <Grid xs={3} item>
          <InputField
            placeholder="Type an answer (autofill)"
            inputlabel={`Previous ${formName}`}
            id="release-name"
            name="release-name"
            onChange={({ target }) =>
              releaseBasicsProps("previousRelease", target.value)
            }
            value={selectedRelease?.previousRelease}
            disabled={accordionName !== "releaseBasics"}
          />
        </Grid>
        <Grid xs={3} item>
          <InputField
            placeholder="Type an answer (autofill)"
            inputlabel={`Next ${formName}`}
            id="release-name"
            name="release-name"
            onChange={({ target }) =>
              releaseBasicsProps("nextRelease", target.value)
            }
            value={selectedRelease?.nextRelease}
            disabled={accordionName !== "releaseBasics"}
          />
        </Grid>
        <Grid xs={3} item>
          <SelectInput
            selectlabel="Status"
            handleChange={({ target }) =>
              releaseBasicsProps("version", target.value ?? "Creating")
            }
            value={selectedRelease?.version ?? 1}
            customMenuItems={statusList}
            disabled={accordionName !== "releaseBasics"}
          />
        </Grid>
        {accordionName === "releaseBasics" && (
          <Grid item className="releaseBtnContainer">
            <Typography variant="body1" className="uploadTypo">
              Upload Software File
            </Typography>
            <Grid className="btnInerGrid">
              <Grid>
                <Button
                  startIcon={<BiUpload />}
                  color="primary"
                  variant="contained"
                  className="uploadBtn"
                  type="submit"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setOpenUploadPopup(true);
                  }}
                >
                  upload
                </Button>
              </Grid>
            </Grid>
            {selectedRelease.buildFileName && (
              <div className={classes.chipContainer}>
                <Chip
                  className={classes.chip}
                  label={
                    <div>
                      <span className={classes.chipFileName}>
                        {softwareReleaseData?.buildURL !== ""
                          ? selectedRelease.buildFileName
                          : null}{" "}
                      </span>
                    </div>
                  }
                  deleteIcon={
                    <i>
                      <CloseChipIcon />
                    </i>
                  }
                />
              </div>
            )}

            <SoftwareMediaUploadPopup
              openPopup={openUploadPopup}
              closePopup={() => setOpenUploadPopup(false)}
              releaseBasicsProps={releaseBasicsProps}
            />
          </Grid>
        )}
        {!isLoading ? (
          <Grid item className="btn_save_next">
            {accordionName === "releaseBasics" && (
              <>
                <Button
                  variant="outlined"
                  className="skipButton"
                  disabled={!paramId}
                  onClick={() =>{setAccordionName("")}}
                >
                  Skip
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="dashboardButtons saveBasicInfo"
                  type="submit"
                  disabled={
                    !selectedRelease?.nameInternal?.length ||
                    !selectedRelease?.releaseId?.length
                  }
                >
                  Next
                </Button>
              </>
            )}
          </Grid>
        ) : (
          <Grid item className="btn_save_next">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </ValidatorForm>
  );
};

export default ReleaseBasicsAccordian;
