import React from "react";
import { Button } from "@material-ui/core";
import {
  equalizer,
  dashboard,
  globe,
  userpic,
} from "../../../../resources/images/index";
import { EndUsers, Clock, Globe } from "../../../views/LeftNav/SvgIcons";
import moment from "moment";

function CustomerProfile({ profileImage, profileInfo, eStatus }) {
  return (
    <div className='customerProfileWrapper'>
      <div className='customerContactInner'>
        <span className='profileHeader'>
          <span className='userPicWrapper'>
            <span className='userPic'>
              {profileImage ? (
                <img src={profileImage ? profileImage : userpic} alt='user' />
              ) : (
                <i className='userInitials'>{`${profileInfo?.firstName
                  ?.charAt(0)
                  ?.toUpperCase()}${profileInfo?.lastName
                  ?.charAt(0)
                  ?.toUpperCase()}`}</i>
              )}
            </span>
          </span>
        </span>

        <span className='name'>
          {profileInfo
            ? profileInfo?.firstName?.charAt(0).toUpperCase() +
              profileInfo?.firstName?.slice(1)
            : ""}
        </span>
        <span className='companyId'>
          Email:{" "}
          <i style={{ color: "#3699ff", width: "auto" }}>
            {profileInfo?.email}
          </i>
        </span>
        <span className='companyName'>
          {profileInfo ? profileInfo.deviceRegistered : 0}
          {" Devices"}
        </span>

        {/* <span className='profileActiveBtnWrapper'>
          <Button color='primary' varient='contained'>
            Active
          </Button>
        </span> */}
        <div className='profileStatusBtn'>
          {eStatus === 1 ? (
            <span className='productRegistered'>Active</span>
          ) : eStatus === 0 ? (
            <span className='productPending'>Inactive</span>
          ) : eStatus === -1 ? (
            <span className='productDelete'>Deleted</span>
          ) : eStatus === 2 ? (
            <span className='productBlock'>Blocked</span>
          ) : eStatus === 4 ? (
            <span className='productDelete'>Draft</span>
          ) : eStatus === 3 ? (
            <span className='productDelete'>Archive</span>
          ) : (
            <span className='productDelete'>DeActivate</span>
          )}
        </div>

        {/* <span className='companyDesc'>Contact Bio / Short Description</span> */}

        <ul>
          <li>
            <span className='icon'>
              {/* <img src={EndUsers} alt='equalizer' /> */}
              <EndUsers />
            </span>
            <span className='profileHeading'>
              End User Group Name
              <i className='profileSubHeading'>
                {profileInfo ? profileInfo.groupName : "n/a"}
              </i>
            </span>
          </li>

          <li>
            <span className='icon'>
              <Globe />
            </span>
            <span className='profileHeading'>
              City, State Country
              <i className='profileSubHeading'>
                {profileInfo ? profileInfo.location : "n/a"}
              </i>
            </span>
          </li>

          <li>
            <span className='icon'>
              <Clock />
            </span>
            <span className='profileHeading'>
              Last Login
              <i className='profileSubHeading'>
                {profileInfo && profileInfo?.lastLogin !== null
                  ? moment(profileInfo?.lastLogin).fromNow()
                  : "n/a"}
              </i>
            </span>
          </li>
        </ul>

        <span>
          <Button color='primary' variant='contained'>
            Message Customer
          </Button>
        </span>
      </div>
    </div>
  );
}

export default CustomerProfile;
