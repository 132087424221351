import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";

import { conversationAction } from "../../../../../store/conversations/conversationAction";
import Loader from "../../../../../components/common/Loader";
import { launchesActions } from "../../../../../store/launches/launchesActions";
import CustomizationWrapper from "./CustomizationWrapper";

function Customizations(props) {
  const [typesList, setTypesList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [endUserGroup, setEndUserGroup] = useState([]);
  const { activeTabId } = useSelector(state => state.conversationReducer);
  const [conversationObj, setConversationObj] = useState({
    nameInternal: "",
    namePublic: "",
    shortDescription: "",
    categoryColor: "",
    campaignId: 2,
    isPublic: true,
    isGroupChat: true,
    isDeplayedInAdminInbox: true,
    isLoading: false,
    whoCon: false,
    tempWhoCon: [],
    withCon: false,
    tempWithCon: [],
    fromCon: false,
    tempFromCon: [],
  });
  const [generalConversation, setGeneralConversation] =
    useState(conversationObj);
  const [pressConversation, setPressConversation] = useState(conversationObj);
  const [facebookConversation, setFacebookConversation] =
    useState(conversationObj);
  const [supportConversation, setSupportConversation] =
    useState(conversationObj);
  const [saleConversation, setSaleConversation] = useState(conversationObj);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      conversationAction.onGetAllCampaigns({
        productId: 2,
      })
    ).then(data => {
      if (data?.responseMsg === "Success") {
        setTypesList(data?.responseData?.length > 0 ? data?.responseData : []);
      }
    });
  }, [activeTabId]);

  useEffect(() => {
    dispatch(launchesActions.onGetAllUserGroup()).then(data => {
      if (data && data.success) {
        const list = data?.data.map(x => {
          return {
            id: x.id,
            value: x.value,
            generic: x.generic,
            checked: false,
          };
        });
        setEndUserGroup(list);
      }
    });
  }, []);

  const onButtonPress = () => {
    dispatch(conversationAction.onChange("activeTabId", `${5}`));
  };

  const onSectionCompletedButtonPress = (record, campId) => {
    let perArray = [];
    if (record.tempWhoCon.length > 0) {
      let whoName = record.tempWhoCon.map(x => x.name);
      if (whoName.length > 0) {
        perArray.push({
          name: "string",
          values: whoName,
        });
      }
    }
    if (record.tempWithCon.length > 0) {
      let withName = record.tempWithCon.map(x => x.name);
      if (withName.length > 0) {
        perArray.push({
          name: "string",
          values: withName,
        });
      }
    }
    if (record.tempFromCon.length > 0) {
      let fromName = record.tempFromCon.map(x => x.name);
      if (fromName.length > 0) {
        perArray.push({
          name: "string",
          values: fromName,
        });
      }
    }
    var modal = {
      productId: 2,
      campaignId: campId,
      nameInternal: record.nameInternal,
      namePublic: record.namePublic,
      color: record.categoryColor,
      shortDescription: record.shortDescription,
      channels: ["string"],
      isPublic: record.isPublic,
      isGroupChat: record.isGroupChat,
      isDeplayedInAdminInbox: record.isDeplayedInAdminInbox,
      permissions: perArray,
    };
    setLoading(true);
    dispatch(conversationAction.onAddUpdateCampaignSettings(modal)).then(
      data => {
        toast.success(data?.responseMsg);
        setLoading(false);
      }
    );
  };

  return (
    <div className="conversationTypeWrapper">
      <div className="conversationTypeInnerWrapper">
        {isLoading ? <Loader /> : null}
        {typesList.map(item => {
          return (
            <>
              {item.isActive && item.isEnabled && item.id === 1 ? (
                <CustomizationWrapper
                  name={item.name}
                  description={item.discription}
                  pressConversation={generalConversation}
                  setPressConversation={setGeneralConversation}
                  onSectionCompletedButtonPress={d =>
                    onSectionCompletedButtonPress(d, item.id)
                  }
                  endUserGroup={endUserGroup}
                />
              ) : null}
              {item.isActive && item.isEnabled && item.id === 2 ? (
                <CustomizationWrapper
                  name={item.name}
                  description={item.discription}
                  pressConversation={pressConversation}
                  setPressConversation={setPressConversation}
                  onSectionCompletedButtonPress={d =>
                    onSectionCompletedButtonPress(d, item.id)
                  }
                  endUserGroup={endUserGroup}
                />
              ) : null}
              {item.isActive && item.isEnabled && item.id === 3 ? (
                <CustomizationWrapper
                  name={item.name}
                  description={item.discription}
                  pressConversation={facebookConversation}
                  setPressConversation={setFacebookConversation}
                  onSectionCompletedButtonPress={d =>
                    onSectionCompletedButtonPress(d, item.id)
                  }
                  endUserGroup={endUserGroup}
                />
              ) : null}
              {item.isActive && item.isEnabled && item.id === 4 ? (
                <CustomizationWrapper
                  name={item.name}
                  description={item.discription}
                  pressConversation={supportConversation}
                  setPressConversation={setSupportConversation}
                  onSectionCompletedButtonPress={d =>
                    onSectionCompletedButtonPress(d, item.id)
                  }
                  endUserGroup={endUserGroup}
                />
              ) : null}
              {item?.isActive && item?.isEnabled && item?.id === 5 ? (
                <CustomizationWrapper
                  name={item.name}
                  description={item.discription}
                  pressConversation={saleConversation}
                  setPressConversation={setSaleConversation}
                  onSectionCompletedButtonPress={d =>
                    onSectionCompletedButtonPress(d, item.id)
                  }
                  endUserGroup={endUserGroup}
                />
              ) : null}
            </>
          );
        })}
        <Button
          color="#F5F8FA"
          variant="contained"
          autoFocus
          type="submit"
          onClick={() => {
            dispatch(conversationAction.onChange("activeTabId", `${3}`));
          }}
          style={{
            bottom: 0,
            position: "absolute",
            right: "100px",
            margin: "20px",
          }}>
          <span style={{ color: "#7E8299", fontSize: "12px" }}>Go Back</span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          type="submit"
          onClick={() => {
            onButtonPress();
          }}
          style={{
            bottom: 0,
            position: "absolute",
            right: "0",
            margin: "20px",
          }}>
          Continue
        </Button>
      </div>
    </div>
  );
}

export default Customizations;
