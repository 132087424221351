import React from "react";
import { Forum } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { conversationAction } from "../../../../../store/conversations/conversationAction";

function Overview(props) {
  const dispatch = useDispatch();
  return (
    <div className="overviewWrapper">
      <div className="overviewInner">
        <h2 className="mainTitle">Welcome to OFish Conversations!</h2>
        <div className="overviewContent">
          <div className="overviewContentWrapper">
            <span className="contentSpace">
              With OFish Conversations, you can manage all your external company
              conversations in one place! From support requests, social media
              messages, to press and general inquires.
            </span>
            <span className="contentSpace">
              We’ll need you to answer some questions to finish setting up
              Conversations. If you need help, you can learn more about
              Conversations{" "}
              <span
                style={{
                  color: "#00A3FF",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}>
                here
              </span>
            </span>
          </div>
          <Forum
            style={{
              fontSize: "200px",
              marginLeft: "50px",
              marginRight: "50px",
              color: "#3699ff",
            }}
          />
        </div>
      </div>
      <Button
        color="primary"
        variant="contained"
        autoFocus
        type="submit"
        style={{ bottom: 0, position: "absolute", right: "0", margin: "20px" }}
        onClick={() => {
          dispatch(conversationAction.onChange("activeTabId", `${2}`));
        }}>
        Continue
      </Button>
    </div>
  );
}

export default Overview;
