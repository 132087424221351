import React, { memo, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Modal } from "../../../../../components/common";
import { endpoints } from "../../../../../config/apiConfig";
import { useDropdownValues } from "../../../../../utils/use-dropdown-values";
import {
  BasicsForm,
  PriceListVisibility,
  PriceListDesign,
  PortalsListModal,
  PriceListAccessModal,
} from "./components";
import { useLoadDataGet } from "../../../../../utils/use-load-data";

const CreateBasicsPriceList = memo(
  ({
    priceList,
    setPriceList,
    pricingGroupsByPapId,
    addUpdatePriceList,
    addUpdatePriceListVisibility,
    updatePriceListDesign,
    addUpdatePriceListColumns,
    onDeletePriceList,
    onPreview,
    activeStep,
    setActiveStep,
  }) => {
    const [isAccess, setIsAccess] = useState(false);
    const [isPreAccess, setIsPreAccess] = useState(false);
    const [isPortals, setIsPortals] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const { dropdownValues: availabilityPeriodDD, loadDropdownList } =
      useDropdownValues({
        url: endpoints.productInventory.getPricingAvailabilityPeriodDropdownV2,
      });

    const { records: portalsList } = useLoadDataGet({
      url: endpoints.portal.getAllPortals,
    });

    return (
      <div>
        <BasicsForm
          availabilityPeriodDD={availabilityPeriodDD}
          plData={priceList}
          setPriceListData={setPriceList}
          onAvailabilityPeriodCreated={success => {
            if (success)
              loadDropdownList(
                endpoints.productInventory.getPricingAvailabilityPeriodDropdown
              );
          }}
          addUpdatePriceList={addUpdatePriceList}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />

        <PriceListVisibility
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          plData={{
            ...priceList,
            priceListPortals: priceList?.priceListPortals
              ?.map(plp => {
                const portal = portalsList.find(
                  p => `${p.id}` === `${plp.portalId}`
                );
                if (portal) {
                  return {
                    id: plp?.id || 0,
                    portalId: plp.portalId,
                    name: portal?.name,
                    type: portal?.type,
                    description: portal?.description,
                  };
                }
                return null;
              })
              .filter(plp => plp),
            groupAccessLevel: priceList?.groupAccessLevel?.map(gal => {
              const group = pricingGroupsByPapId?.find(
                p => `${p.id}` === `${gal.groupId}`
              );
              if (group) {
                return {
                  ...gal,
                  id: gal?.id || 0,
                  groupId: gal.groupId,
                  name: group?.value,
                  type: group?.generic,
                  isPreReleaseAccessGroup: gal?.isPreReleaseAccessGroup,
                };
              }
              return null;
            }),
            preReleaseAccessDate: priceList?.preReleaseAccessDate || null,
          }}
          setPriceListData={setPriceList}
          onAddPortalVisibility={() => setIsPortals(!isPortals)}
          onAddPriceListAccess={() => {
            setIsAccess(!isAccess);
          }}
          onAddPriceListPreAccess={() => {
            if (isAccess) {
              setIsPreAccess(false);
              setIsAccess(false);
            } else {
              setIsPreAccess(true);
              setIsAccess(true);
            }
          }}
          addUpdatePriceListVisibility={addUpdatePriceListVisibility}
          availabilityPeriodDD={availabilityPeriodDD}
          priceList={priceList}
        />

        <PriceListDesign
          activeStep={activeStep}
          plData={priceList}
          setPriceListData={setPriceList}
          updatePriceListDesign={updatePriceListDesign}
          addUpdatePriceListColumns={addUpdatePriceListColumns}
          onPreview={onPreview}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Button
            color='error'
            variant='text'
            style={{
              color: "#EB5757",
              textTransform: "capitalize",
              fontSize: "18px",
              fontWeight: "700",
            }}
            onClick={() => {
              setDeleteModal(true);
            }}>
            {`Delete Price List`}
          </Button>
        </div>

        {isPortals && (
          <PortalsListModal
            isOpen={isPortals}
            setIsOpen={setIsPortals}
            portals={portalsList}
            priceListPortals={priceList?.priceListPortals}
            onAdd={selectedPRLs => {
              setPriceList({
                ...priceList,
                priceListPortals: [...selectedPRLs],
              });
            }}
          />
        )}

        {(isAccess || isPreAccess) && (
          <PriceListAccessModal
            isAccess={isAccess}
            setIsAccess={() => {
              if (isAccess) {
                setIsAccess(false);
                setIsPreAccess(false);
              }
            }}
            isPreAccess={isPreAccess}
            userGroups={pricingGroupsByPapId}
            groupAccessLevel={priceList?.groupAccessLevel}
            onAdd={selectedUCGs => {
              setPriceList({
                ...priceList,
                groupAccessLevel: [...selectedUCGs],
              });
            }}
          />
        )}

        {deleteModal && (
          <Modal
            open={deleteModal}
            setOpen={setDeleteModal}
            title={"Delete Price List"}
            primaryHeading='Are you sure you want to remove this price list? All data will be deleted.'
            secondaryHeading=''>
            <div className='actionBtnsWrapper'>
              <Button
                color='primary'
                variant='outlined'
                className='cancelBtnCls'
                onClick={() => setDeleteModal(!deleteModal)}>
                {`Cancel`}
              </Button>

              <Button
                variant='contained'
                className='deleteBtnCls'
                onClick={() => onDeletePriceList([priceList?.id])}>
                {`Delete`}
              </Button>
            </div>
          </Modal>
        )}
      </div>
    );
  }
);

export { CreateBasicsPriceList };
