import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import Dashbard from "../Dashbard";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import CategoryOveriew from "./CategoryOveriew";
import Attendance from "./Attendance";
import Activity from "./Activity";
import Data from "./Data";
import Notes from "./Notes";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

function NewCategory(props) {
  const history = useHistory();
  var eventId = parseInt(window.location.href.split("?id=")[1]);

  const redirectUrl = () => {
    history.push("/event-categories");
  };
  return (
    <Dashbard>
      <div className='body-content generalTabs launchTabs'>
        <div className='consumerHeading backToCategory'>
          <Button color='primary' onClick={redirectUrl} variant='contained'>
            <ArrowBackIosIcon /> Back to Event Category
          </Button>
        </div>
        <ValidatorForm ref={React.useRef("form")}>
          {eventId ? (
            <TopTabNavigator
              tabs={[
                {
                  id: 1,
                  title: "Overview",
                  component: [<CategoryOveriew />],
                },
                {
                  id: 2,
                  title: "Attendance",
                  component: <Attendance />,
                },
                {
                  id: 3,
                  title: "Activity",
                  component: <Activity />,
                },
                {
                  id: 4,
                  title: "Date",
                  component: <Data />,
                },
                {
                  id: 5,
                  title: "Notes",
                  component: <Notes />,
                },
              ]}
            />
          ) : (
            <TopTabNavigator
              tabs={[
                {
                  id: 1,
                  title: "Overview",
                  component: [<CategoryOveriew />],
                },
              ]}
            />
          )}
        </ValidatorForm>
      </div>
    </Dashbard>
  );
}

export default NewCategory;
