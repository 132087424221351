import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Notes } from "../../../../components/common";
import Loader from "../../../../components/common/Loader";
import { AccordionContainer } from "../../../../components/common/AccordionContainer";
import { launchesActions } from "../../../../store/launches/launchesActions";

const LaunchNotes = ({ launchId, isActive }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [notes, setNotes] = useState([]);

  const loadNotes = useCallback(() => {
    if (isActive && launchId > 0) {
      setLoading(true);
      dispatch(launchesActions.onGetLaunchNotes(launchId)).then(data => {
        setLoading(false);
        if (data) {
          setNotes(data?.data);
        }
      });
    }
  }, [dispatch, isActive, launchId]);

  useEffect(() => {
    loadNotes();
  }, [loadNotes]);

  const handleCommnetSubmit = comment => {
    // const userId = localStorage.getItem("id");
    const addNote = {
      id: 0,
      // userId: userId,
      launchId: launchId,
      note: comment
    };
    setLoading(true);
    dispatch(launchesActions.onAddUpdateNotes(addNote)).then(data => {
      if (data && data.success) {
        loadNotes();
        setNoteText("");
      } else if (!data?.success) {
        toast.error(data.message);
        setLoading(false);
      }
    });
  };

  const handleNoteUpdate = note => {
    setLoading(true);
    dispatch(
      launchesActions.onAddUpdateNotes({
        id: note?.id,
        launchId: launchId,
        note: note.notes
      })
    ).then(data => {
      if (data && data.success) {
        loadNotes();
      } else if (!data?.success) {
        toast.error(data.message);
        setLoading(false);
      }
    });
  };

  const deleteProductNote = noteId => {
    setLoading(true);
    dispatch(launchesActions.onDeleteLaunchNote(noteId)).then(data => {
      if (data && data.success) {
        loadNotes();
      } else if (!data?.success) {
        toast.error(data.message);
        setLoading(false);
      }
    });
  };

  return (
    <AccordionContainer title='Launch Notes'>
      {isLoading && <Loader />}

      <Notes
        noteText={noteText}
        setNoteText={setNoteText}
        notes={notes}
        onSend={cmnt => handleCommnetSubmit(cmnt)}
        onDeleteNote={deleteProductNote}
        onUpdateNote={handleNoteUpdate}
        enableMoreMenuOption={true}
      />
    </AccordionContainer>
  );
};

export { LaunchNotes };
