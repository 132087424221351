import { Button, Grid } from "@material-ui/core";
import React from "react";
const eSoftwareReleaseVersion = {
  Creating: 1,
  Live: 2,
  EndOfLife: 3,
  InTesting: 4,
};

export const ReleaseBasicPreview = ({ selectedRelease , buildId }) => {
  return (
    <Grid className="releaseConatiner">
      <h2>{buildId ? "Build" : "Release"} Basics</h2> 
      <Grid container spacing={2}  className="deviceBasicOverview">
        <Grid item xs={3} className="dataName">
        {buildId ? "Build" : "Release"} Name (Internal)
        </Grid>
        <Grid item xs={3} className="deviceData">
          {selectedRelease?.nameInternal ?? "--"}
        </Grid>
        <Grid item xs={3} className="dataName">
        Previous {buildId ? "Build" : "Release"}
        </Grid>
        <Grid item xs={3} className="deviceData">
        {selectedRelease?.previousRelease?.length ? selectedRelease?.previousRelease : "--"}
        </Grid>
        <Grid item xs={3} className="dataName">
        {buildId ? "Build" : "Release"} Name (Public)
        </Grid>
        <Grid item xs={3} className="deviceData">
        {selectedRelease?.namePublic?.length ? selectedRelease?.namePublic : "--"}
        </Grid>
        <Grid item xs={3} className="dataName">
        Next {buildId ? "Build" : "Release"}
        </Grid>
        <Grid item xs={3} className="deviceData">
        {selectedRelease?.nextRelease?.length ? selectedRelease?.nextRelease : "--"}
        </Grid>
        <Grid item xs={3} className="dataName">
        {buildId ? "Build" : "Release"} ID
        </Grid>
        <Grid item xs={3} className="deviceData">
        {selectedRelease?.releaseId ? selectedRelease?.releaseId : "--"}
        </Grid>
        <Grid item xs={3} className="dataName">
        Status
        </Grid>
        <Grid item xs={3} className="deviceData">
        <Button
              contained
              className="statusButton"
              style={{
                backgroundColor:
                  (selectedRelease?.version === eSoftwareReleaseVersion.Live &&
                    "rgba(33, 150, 83, 0.25)") ||
                  (selectedRelease?.version ===
                    eSoftwareReleaseVersion.EndOfLife &&
                    "rgba(255, 205, 205, 1)") ||
                  (selectedRelease?.version ===
                    eSoftwareReleaseVersion.InTesting &&
                    "rgba(242, 201, 76, 0.25)") ||
                  (selectedRelease?.version ===
                    eSoftwareReleaseVersion.Creating &&
                    "#ddd"),
                color:
                  (selectedRelease?.version === eSoftwareReleaseVersion.Live &&
                    "#219653") ||
                  (selectedRelease?.version ===
                    eSoftwareReleaseVersion.EndOfLife &&
                    "#FF3535") ||
                  (selectedRelease?.version ===
                    eSoftwareReleaseVersion.InTesting &&
                    "#F2994A") ||
                  (selectedRelease?.version ===
                    eSoftwareReleaseVersion.Creating &&
                    "rgb(141, 153, 165)"),
                padding: "8px 15px",
                borderRadius: "6px",
                fontSize: "12px",
                fontWeight: 700,
              }}
            >
              {selectedRelease?.version === 1 ? 'Creating' : selectedRelease?.version === 2 ? 'Live' : selectedRelease?.version === 3 ? 'Retired' : 'In Testing'}
            </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
