import { newsroomTypes } from "./newsroomTypes";

const INITIAL_STATE = {
  errorMessage: "",
  leadersValue: [
    { newsRoomId: 1, name: "", position: "", leadershipImageURL: "" },
    { newsRoomId: 1, name: "", position: "", leadershipImageURL: "" },
  ],
  menuOptionsValue: [],
  navigationValue: [],
  quickLinksValue: [
    { name: "", url: "", openInNewTab: true },
    { name: "", url: "", openInNewTab: true },
    { name: "", url: "", openInNewTab: true },
  ],
  featuredContentValue: [
    {
      id: 0,
      slideTitle: "",
      slideSubTitle: "",
      bannerImageUrl: "",
      linkTo: "",
      url: "",
      urlTarget: "",
      showImageOnly: true,
    },
    {
      newsRoomId: 0,
      slideTitle: "",
      slideSubTitle: "",
      bannerImageUrl: "",
      linkTo: "",
      url: "",
      urlTarget: "",
      showImageOnly: true,
    },
  ],
  contacts: [
    { countryCode: "", number: "" },
    { countryCode: "", number: "" },
  ],
  faxNumber: "",
  faxCountryCode: "",
  emails: [
    { email: "", isValid: true },
    { email: "", isValid: true },
    { email: "", isValid: true },
  ],
  socials: [
    {
      name: "",
      link: "",
    },
    {
      name: "",
      link: "",
    },
    {
      name: "",
      link: "",
    },
    {
      name: "",
      link: "",
    },
  ],
  businessHours: [
    { day: "Monday", openingTime: "", closingTime: "", closed: false },
    { day: "Tuesday", openingTime: "", closingTime: "", closed: false },
    { day: "Wednesday", openingTime: "", closingTime: "", closed: false },
    { day: "Thursday", openingTime: "", closingTime: "", closed: false },
    { day: "Friday", openingTime: "", closingTime: "", closed: false },
    { day: "Saturday", openingTime: "", closingTime: "", closed: true },
    { day: "Sunday", openingTime: "", closingTime: "", closed: true },
  ],
  quickLinks: [],
  menuHeader: [],
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case newsroomTypes.NEWSROOM_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    case newsroomTypes.NEWSROOM_ADD_FEATURED_CONTENT: {
      const newState = { ...state };
      newState.featuredContentValue.push({
        id: 0,
        slideTitle: "",
        slideSubTitle: "",
        bannerImageUrl: "",
        linkTo: "",
        url: "",
        urlTarget: "",
        showImageOnly: true,
      });
      return newState;
    }
    case newsroomTypes.NEWSROOM_REMOVE_FEATURED_CONTENT: {
      const newState = { ...state };
      const index = action.payload;
      newState.featuredContentValue.splice(index, 1);
      return newState;
    }
    case newsroomTypes.CHANGE_FEATURED_CONTENT_VALUE: {
      const newState = { ...state };
      let a = [...newState.featuredContentValue];
      const featuredContentData = action.payload;
      let item = a[featuredContentData.index];
      if (featuredContentData.fieldName === "slideTitle") {
        item = { ...item, slideTitle: featuredContentData.value };
      } else if (featuredContentData.fieldName === "slideSubTitle") {
        item = { ...item, slideSubTitle: featuredContentData.value };
      } else if (featuredContentData.fieldName === "bannerImageUrl") {
        item = { ...item, bannerImageUrl: featuredContentData.value };
      } else if (featuredContentData.fieldName === "linkTo") {
        item = { ...item, linkTo: featuredContentData.value };
      } else if (featuredContentData.fieldName === "url") {
        item = { ...item, url: featuredContentData.value };
      } else if (featuredContentData.fieldName === "urlTarget") {
        item = { ...item, urlTarget: featuredContentData.value };
      } else {
        item = { ...item, showImageOnly: featuredContentData.value };
      }
      a[featuredContentData.index] = item;
      newState.featuredContentValue = a;
      return newState;
    }
    case newsroomTypes.NEWSROOM_ADD_QUICK_LINKS: {
      const newState = { ...state };
      newState.quickLinksValue.push({
        name: "",
        url: "",
        openInNewTab: true,
      });
      return newState;
    }
    case newsroomTypes.NEWSROOM_REMOVE_QUICK_LINKS: {
      const newState = { ...state };
      const index = action.payload;
      newState.quickLinksValue.splice(index, 1);
      return newState;
    }
    case newsroomTypes.CHANGE_QUICK_LINKS_VALUE: {
      const newState = { ...state };
      let a = [...newState.quickLinksValue];
      const quickLinksData = action.payload;
      let item = a[quickLinksData.index];
      if (quickLinksData.fieldName === "name") {
        item = { ...item, name: quickLinksData.value };
      } else if (quickLinksData.fieldName === "url") {
        item = { ...item, url: quickLinksData.value };
      } else {
        item = { ...item, openInNewTab: quickLinksData.value };
      }
      a[quickLinksData.index] = item;
      newState.quickLinksValue = a;
      return newState;
    }
    case newsroomTypes.NEWSROOM_ADD_LEADER: {
      const newState = { ...state };
      newState.leadersValue.push({
        newsRoomId: 1,
        name: "",
        position: "",
        leadershipImageURL: "",
      });
      return newState;
    }
    case newsroomTypes.NEWSROOM_REMOVE_LEADER: {
      const newState = { ...state };
      const index = action.payload;
      newState.leadersValue.splice(index, 1);
      return newState;
    }
    case newsroomTypes.CHANGE_LEADER_VALUE: {
      const newState = { ...state };
      let a = [...newState.leadersValue];
      const leaderData = action.payload;
      let item = a[leaderData.index];
      if (leaderData.fieldName === "name") {
        item = { ...item, name: leaderData.value };
      } else if (leaderData.fieldName === "position") {
        item = { ...item, position: leaderData.value };
      } else {
        item = { ...item, leadershipImageURL: leaderData.value };
      }
      a[leaderData.index] = item;
      newState.leadersValue = a;
      return newState;
    }
    case newsroomTypes.NEWSROOM_ADD_CONTACT: {
      const newState = { ...state };
      newState.contacts.push("");
      return newState;
    }
    case newsroomTypes.NEWSROOM_REMOVE_CONTACT: {
      const newState = { ...state };
      const index = action.payload;
      newState.contacts.splice(index, 1);
      return newState;
    }
    case newsroomTypes.NEWSROOM_ADD_EMAIL: {
      const newState = { ...state };
      newState.emails.push({ email: "", isValid: true });
      return newState;
    }
    case newsroomTypes.NEWSROOM_REMOVE_EMAIL: {
      const newState = { ...state };
      const index = action.payload;
      newState.emails.splice(index, 1);
      return newState;
    }
    case newsroomTypes.NEWSROOM_ADD_SOCIALS: {
      const newState = { ...state };
      newState.socials.push({
        name: "",
        link: "",
      });
      return newState;
    }
    case newsroomTypes.NEWSROOM_REMOVE_SOCIALS: {
      const newState = { ...state };
      const index = action.payload;
      newState.socials.splice(index, 1);
      return newState;
    }
    case newsroomTypes.CHANGE_BUSINESS_HOURS_VALUE: {
      const newState = { ...state };
      let a = [...newState.businessHours];
      const hourData = action.payload;
      let item = a[hourData.index];
      if (hourData.fieldName === "openingTime") {
        item = { ...item, openingTime: hourData.value };
      } else if (hourData.fieldName === "closingTime") {
        item = { ...item, closingTime: hourData.value };
      } else if (hourData.fieldName === "closed") {
        item = { ...item, closed: hourData.value };
      }
      a[hourData.index] = item;
      newState.businessHours = a;
      return newState;
    }
    case newsroomTypes.CHANGE_CONTACT_VALUE: {
      const newState = { ...state };
      let a = [...newState.contacts];
      const contactData = action.payload;
      let item = a[contactData.index];
      if (contactData.fieldName === "countryCode") {
        item = { ...item, countryCode: contactData.value };
      } else if (contactData.fieldName === "number") {
        item = { ...item, number: contactData.value };
      }
      a[contactData.index] = item;
      newState.contacts = a;
      return newState;
    }
    case newsroomTypes.CHANGE_EMAIL_VALUE: {
      const newState = { ...state };
      let a = [...newState.emails];
      const emailData = action.payload;
      let item = a[emailData.index];
      if (emailData.fieldName === "email") {
        item = { ...item, email: emailData.value };
      } else if (emailData.fieldName === "isValid") {
        item = { ...item, isValid: emailData.value };
      }
      // item=emailData.value;
      a[emailData.index] = item;
      newState.emails = a;
      return newState;
    }
    case newsroomTypes.CHANGE_SOCIALS_VALUE: {
      const newState = { ...state };
      let a = [...newState.socials];
      const socialData = action.payload;
      let item = a[socialData.index];
      if (socialData.fieldName === "name") {
        item = { ...item, name: socialData.value };
      } else if (socialData.fieldName === "link") {
        item = { ...item, link: socialData.value };
      }
      a[socialData.index] = item;
      newState.socials = a;
      return newState;
    }
    default:
      return state;
  }
};
