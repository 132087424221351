import React, { useEffect, useState } from "react";
import { concat } from "lodash";
import { TabNavigator } from "../../../../components/common/Tabs/TabNavigator";
import { NewEventForm } from "../Events/NewEventForm";

const EventsTab = ({ isActive, existinglaunch }) => {
  const [activeRecordIndex, setActiveRecord] = useState(0);
  const [events, setEvents] = useState([
    {
      index: 0
    }
  ]);

  useEffect(() => {
    if (existinglaunch?.eventsIds?.length > 0) {
      setEvents(
        existinglaunch?.eventsIds?.map((e, i) => {
          return {
            index: i,
            eventId: e
          };
        })
      );
    }
  }, [existinglaunch?.eventsIds]);

  return (
    isActive && (
      <div style={{ flex: 1 }}>
        <TabNavigator
          tabsTitle='Events'
          tabs={events}
          onAdd={newE => setEvents(concat(events, newE))}
          onActiveTabClick={tabIndex => setActiveRecord(tabIndex)}
        />
        <div>
          <NewEventForm
            activeEventId={events[activeRecordIndex]?.eventId || null}
          />
        </div>
      </div>
    )
  );
};
export { EventsTab };
