import { Button } from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import GroupAvatar from "./GroupAvatar";

function AddAttributeTextBox({ picurl, title, onclick, option, avatar }) {
  return (
    <div className='addAttributeBoxWrapper'>
      <div className='addAttributeInner'>
        <span>
          {/* <i className='circle'>
            <img src={picurl} alt='' />
          </i> */}
          <i className='boxTitle'>{title}</i>
          <i className='boxOptions'>{option} Options</i>
        </span>
        <span>{avatar}</span>
        <span>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={onclick}>
            Add
          </Button>
        </span>
      </div>
    </div>
  );
}

export default AddAttributeTextBox;
