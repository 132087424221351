import { cU } from "@fullcalendar/core/internal-common";
import { productInventoryApi } from "../product/productInventoryApi";
import { conversationApi } from "./conversationApi";
import { conversationTypes } from "./conversationTypes";
import { softwareUpdatesApi } from "../softwareUpdates/softwareUpdatesApi";

const onChange = (field, value) => {
  return dispatch => {
    dispatch({
      type: conversationTypes.CONVERSATION_ONCHANGE,
      payload: { prop: field, value: value },
    });
  };
};

const onGetUserRoomsByUsersId = data => async dispatch => {
  let response = await conversationApi.getUserRoomsByUsersId(data);
  return response;
};
const onGetChatDetailsByRoomId = data => async dispatch => {
  let response = await conversationApi.getChatDetailsByRoomId(data);
  return response;
};
const onMakeRoomFavorite = data => async dispatch => {
  let response = await conversationApi.makeRoomFavorite(data);
  return response;
};
const onDeleteRooms = data => async dispatch => {
  let response = await conversationApi.deleteRooms(data);
  return response;
};
const onUploadFile = data => async dispatch => {
  let response = await conversationApi.uploadFiles(data);
  return response;
};
const onGetAllCampaigns = data => async dispatch => {
  let response = await conversationApi.getAllCampaigns(data);
  return response;
};

const onEnableCampaigns = data => async dispatch => {
  let response = await conversationApi.enableCampaigns(data);
  return response;
};

const onEnableConversation = data => async dispatch => {
  let response = await conversationApi.enableConversation(data);
  return response;
};

const onAddUpdateCampaignSettings = data => async dispatch => {
  let response = await conversationApi.addUpdateCampaignSettings(data);
  return response;
};

const onChangeBasicConversationFields = value => {
  return dispatch => {
    dispatch({
      type: conversationTypes.ON_CHANGE_Basic_Conversation_FIELD,
      payload: { prop: value },
    });
  };
};

const onAddUpdateProductNotificationSettings = data => async dispatch => {
  let response = await conversationApi.addUpdateProductNotificationSettings(
    data
  );
  return response;
};

const onGetConfigurationStatus = data => async dispatch => {
  let response = await conversationApi.getConfigurationStatus(data);
  return response;
};

const onGetUserAutoCompleteByEmail = data => async dispatch => {
  let response = await conversationApi.getUserAutoCompleteByEmail(data);
  return response;
};

const onGetCompanyAutoCompleteByName = data => async dispatch => {
  let response = await conversationApi.getCompanyAutoCompleteByName(data);
  return response;
};
const onSendNewMsg = data => async dispatch => {
  let response = await conversationApi.sendNewMsg(data);
  return response;
};
const onGetCampaignsDdl = data => async dispatch => {
  let response = await conversationApi.getCampaignsDdl(data);
  return response;
};
const onUpdateConversationStatusInRoom = data => async dispatch => {
  let response = await conversationApi.updateConversationStatusInRoom(data);
  return response;
};
const onGetConversationStatuses = data => async dispatch => {
  let response = await conversationApi.getConversationStatuses(data);
  return response;
};
const onGetAllCampaignsGrid = data => async dispatch => {
  let response = await conversationApi.getAllCampaignsGrid(data);
  return response;
};
const onGetCampaignSettingsByCampaignId = data => async dispatch => {
  let response = await conversationApi.getCampaignSettingsByCampaignId(data);
  return response;
};

const onUpdateCampaignIdInRoom = data => async dispatch => {
  let response = await conversationApi.updateCampaignIdInRoom(data);
  return response;
};

const onAddUpdateTemplates = data => async dispatch => {
  let response = await conversationApi.addUpdateTemplates(data);
  return response;
};

const onGetTemplateById = id => async dispatch => {
  let response = await conversationApi.getTemplateById(id);
  return response;
};

const onGetTemplate = data => async dispatch => {
  let response = await conversationApi.getTemplate(data);
  return response;
};

const onMarkConversationTagsToRooms = data => async dispatch => {
  let response = await conversationApi.markConversationTagsToRooms(data);
  return response;
};

const onGetConversationTagsDdl = data => async dispatch => {
  let response = await conversationApi.getConversationTagsDdl(data);
  return response;
};

const onMarkRoomAssignee = data => async dispatch => {
  let response = await conversationApi.markRoomAssignee(data);
  return response;
};

const onMarkInbox = data => async dispatch => {
  return await conversationApi.markRoomInbox(data);
};
const onMakeConversationFavourite = data => async dispatch => {
  let response = await conversationApi.makeConversationFavourite(data);
  return response;
};
const onArchivedConversationType = data => async dispatch => {
  let response = await conversationApi.archivedConversationType(data);
  return response;
};
const onDeleteConversationType = data => async dispatch => {
  let response = await conversationApi.deleteConversationType(data);
  return response;
};

const onGetConversationRoles = (data)=> {
  return async dispatch => {
    try {
      const response = await conversationApi.getConversationRoles(data);
        dispatch({
          type: conversationTypes.CONVERSATION_ONCHANGE,
          payload: {
            prop: "conversationPermissionGridData",
            value: response?.data !== '' ? response?.data : [],
          },
        });
      return response;
    } catch (error) {
      console.error("Error: ", error);
    }
  };
};
const onConversationGetEndCustomerGroups = customerType => {
  return async dispatch => {
    const response = await conversationApi.getConversationCustomerData(customerType);
    if (response?.success) {
      if (customerType === "All") {
        dispatch({
          type: conversationTypes.CONVERSATION_ONCHANGE,
          payload: { prop: "conversationPermissionGridData", value: response.data ?? [] },
        });
      } else if (customerType === "Groups") {
        dispatch({
          type: conversationTypes.CONVERSATION_ONCHANGE,
          payload: { prop: "conversationPermissionGridData", value: response.data ?? [] },
        });
      } else if(customerType === "Business Customer"){
        dispatch({
          type: conversationTypes.CONVERSATION_ONCHANGE,
          payload: { prop: "conversationPermissionGridData", value: response.data ?? [] },
        });
      }else{
        dispatch({
          type: conversationTypes.CONVERSATION_ONCHANGE,
          payload: { prop: "conversationPermissionGridData", value: response.data ?? [] },
        });
      }
    }
    return response;
  };
};


const onGetConversationCompatibleProducts = () => {
  return async dispatch => {
    const response = await softwareUpdatesApi.getReleaseCompatibleProducts();
    if (response?.success) {
      dispatch({
        type: conversationTypes.CONVERSATION_ONCHANGE,
        payload: {
          prop: "compatibleProductsList",
          value: response?.data ?? [],
        },
      });
    }
  };
};
const onGetAllConversationChannels = data => {
  return async dispatch => {
    try {
      const response = await conversationApi.getAllConversationChannels(data);
      if (response?.responseMsg === "Success") {
        dispatch({
          type: conversationTypes.CONVERSATION_ONCHANGE,
          payload: {
            prop: "allConversationChannels",
            value: response?.responseData.data ?? [],
          },
        });
      }
      return response;
    } catch (error) {
      console.error("Error: ", error);
    }
  };
};

const onGetConversationPermission = id => {
  return async dispatch => {
    try {
      const response = await conversationApi.getConversationPermission(id);
      if (response?.responseMsg === "Success") {
        dispatch({
          type: conversationTypes.CONVERSATION_ONCHANGE,
          payload: {
            prop: "conversationPermissionData",
            value: response?.responseData.permissions ?? [],
          },
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
};
const onAddUpdateConversationPermission = data => async dispatch => {
  let response = await conversationApi.addUpdateConversationPermission(
    data
  );
  return response;
};
const onGetAllConversationStatus = data => async dispatch => {
  let response = await conversationApi.getAllConversationStatus(data);
  return response;
};

const onGetStatusAction = () => async dispatch => {
  let response = await conversationApi.getStatusAction();
  return response;
};
const onAddUpdateStatus = data => async dispatch => {
  let response = await conversationApi.addUpdateStatus(data);
  return response;
};
const onResetConversationData = () => ({
  type: conversationTypes.RESET_CONVERSATION_DATA,
});
const onDeleteConversationStatus = data => async dispatch => {
  let response = await conversationApi.deleteConversationStatus(data);
  return response;
};

const onSendMessages = data => async dispatch => {
  let response = await conversationApi.sendMessages(data);
  return response;
};

const onSendInstaMessages = data => async dispatch => {
  let response = await conversationApi.sendInstaMessages(data);
  return response;
};

export const conversationAction = {
  onChange: onChange,
  onGetUserRoomsByUsersId: onGetUserRoomsByUsersId,
  onGetChatDetailsByRoomId: onGetChatDetailsByRoomId,
  onMakeRoomFavorite: onMakeRoomFavorite,
  onMakeConversationFavourite: onMakeConversationFavourite,
  onArchivedConversationType: onArchivedConversationType,
  onDeleteConversationType: onDeleteConversationType,
  onDeleteRooms: onDeleteRooms,
  onUploadFile: onUploadFile,
  onGetAllCampaigns: onGetAllCampaigns,
  onEnableCampaigns: onEnableCampaigns,
  onEnableConversation: onEnableConversation,
  onAddUpdateCampaignSettings: onAddUpdateCampaignSettings,
  onAddUpdateProductNotificationSettings:
    onAddUpdateProductNotificationSettings,
  onGetConfigurationStatus: onGetConfigurationStatus,
  onGetUserAutoCompleteByEmail: onGetUserAutoCompleteByEmail,
  onGetCompanyAutoCompleteByName: onGetCompanyAutoCompleteByName,
  onSendNewMsg: onSendNewMsg,
  onGetCampaignsDdl: onGetCampaignsDdl,
  onUpdateConversationStatusInRoom: onUpdateConversationStatusInRoom,
  onGetConversationStatuses: onGetConversationStatuses,
  onGetAllCampaignsGrid: onGetAllCampaignsGrid,
  onGetCampaignSettingsByCampaignId: onGetCampaignSettingsByCampaignId,
  onUpdateCampaignIdInRoom: onUpdateCampaignIdInRoom,
  onAddUpdateTemplates: onAddUpdateTemplates,
  onGetTemplateById: onGetTemplateById,
  onGetTemplate: onGetTemplate,
  onMarkConversationTagsToRooms: onMarkConversationTagsToRooms,
  onGetConversationTagsDdl: onGetConversationTagsDdl,
  onMarkRoomAssignee: onMarkRoomAssignee,
  onMarkInbox: onMarkInbox,
  onChangeBasicConversationFields: onChangeBasicConversationFields,
  onConversationGetEndCustomerGroups: onConversationGetEndCustomerGroups,
  onGetConversationCompatibleProducts: onGetConversationCompatibleProducts,
  onGetAllConversationChannels: onGetAllConversationChannels,
  onGetConversationPermission: onGetConversationPermission,
  onAddUpdateConversationPermission: onAddUpdateConversationPermission,
  onGetConversationRoles:onGetConversationRoles,
  onGetAllConversationStatus: onGetAllConversationStatus,
  onGetStatusAction: onGetStatusAction,
  onAddUpdateStatus: onAddUpdateStatus,
  onResetConversationData:onResetConversationData,
  onDeleteConversationStatus:onDeleteConversationStatus,
  onSendMessages:onSendMessages,
  onSendInstaMessages:onSendInstaMessages,
};
