import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { RoutesObj } from "../../../routes";
import { endUserActions } from "../../../store/endUser/endUserActions";
import { ReactComponent as SettingIcon } from "../../../resources/images/settingicon.svg";
import {
  BusinessCustomer,
  ContactSettings,
  EndUsers,
  LeadVititor,
  MediaContact,
  VenderContact,
} from "./SvgIcons";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headingWrapper: {
    backgroundColor: "#E5F2FF !important",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    display: "flex",
    alignItems: "center",
  },
  heading1: {
    fontSize: "18px",
    fontWeight: "700",
    marginRight: "3%",
  },
  head: {
    display: "flex",
    alignItems: "center",
  },
  svgIcon: {
    marginRight: "11px",
  },
  expanded: {
    backgroundColor: "rgba(24, 103, 185, 0.08)",
  },
  links: {
    flexDirection: "column",
    paddingBottom: "0",
  },
  boxes: {
    boxShadow: "none",
  },
  accordianIcons: {
    marginRight: "5px",
  },
  hyperLink: {
    marginLeft: "15px",
    textDecoration: "none",
    marginBottom: "12px",
    color: "#8D99A5",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: ".2px",
    "&:hover": {
      color: "#3699FF",
    },
  },
  activeTest: {
    color: "#3699FF",
  },
}));

const BusinessCustomerNav = () => {
  const dispatch = useDispatch();
  const { panel1, panel2, panel3, panel4, panel5, panel6, panel7 } =
    useSelector(state => state.endUserReducer);
  const [expanded1, setExpanded1] = useState(panel1);
  const [expanded2, setExpanded2] = useState(panel2);
  const [expanded3, setExpanded3] = useState(panel3);
  const [expanded4, setExpanded4] = useState(panel4);
  const [expanded5, setExpanded5] = useState(panel5);
  const [expanded6, setExpanded6] = useState(panel6);
  const [expanded7, setExpanded7] = useState(panel7);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const currentRoot = history ? history.location.pathname : null;

  useEffect(() => {
    if (
      currentRoot === "/business-group" ||
      currentRoot === "/new-contact" ||
      currentRoot === "/new-business-group"
    ) {
      dispatch(endUserActions.onChange("panel2", true));
      setExpanded2(true);
    }
    if (
      currentRoot === "/all-end-users" ||
      currentRoot === "/customer-group" ||
      currentRoot === "/customer-overview" ||
      currentRoot === "/new-contact" ||
      currentRoot === "/all-users-groups" ||
      currentRoot === "/new-enduser-group"
    ) {
      setExpanded4("panel4");
      // setExpanded2(false);
      // dispatch(endUserActions.onChange("panel2", false));
      dispatch(endUserActions.onChange("panel4", true));
    }
  }, []);
  const handleChange = panel => (event, newExpanded) => {
    setExpanded1(newExpanded ? panel : false);
    dispatch(endUserActions.onChange("panel", panel));
  };
  return (
    <div className='subNavWrapper'>
      <div className={classes.head}>
        <h1 className={classes.heading1}>Contacts</h1>
        <Link to='/contact-types' style={{ marginLeft: "0" }}>
          <SettingIcon />
        </Link>
      </div>
      <div className={classes.root}>
        <Accordion
          className={classes.boxes}
          expanded={expanded1}
          onChange={() => {
            setExpanded1(expanded1 ? false : true);
            dispatch(
              endUserActions.onChange("panel1", expanded1 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel1d-content'
            id='panel1d-header'
            className={classes.heading}>
            <Typography className={classes.heading}>
              <MediaContact className='releaseIcon' />
              Media Contacts
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link to='/all-business-contacts' className={classes.hyperLink}>
              All Media Contacts
            </Link>

            <Link to='/all-business-contacts' className={classes.hyperLink}>
              Media Directory
            </Link>
            <Link to='/all-business-contacts' className={classes.hyperLink}>
              Media List
            </Link>
            <Link to='/all-business-contacts' className={classes.hyperLink}>
              Media Group
            </Link>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.boxes}
          expanded={expanded2}
          onChange={() => {
            setExpanded2(expanded2 ? false : true);
            dispatch(
              endUserActions.onChange("panel2", expanded2 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
            className={
              [
                "/consumer-overview",
                "/all-business-contacts",
                "/all-companies",
                "/new-company-contacts",
                "/business-group",
                "/new-business-group",
                "/new-contact",
                "/new-customer",
                "/business-customer",
                "/business-contact-favorites",
                "/business-contact-recently-added",
                "/business-contact-archived",
                "/business-contact-blocked",
                "/business-contact-deleted",
                `/blocked-business-location/${id}/edit`,
                RoutesObj.BusinessContactFavorites.path,
                RoutesObj.BusinessContactRecentlyAdded.path,
                RoutesObj.BusinessContactArchived.path,
                RoutesObj.BusinessContactBlocked.path,
                RoutesObj.BusinessContactDeleted.path,
                RoutesObj.BlockedBusinessLocation.path,
              ].includes(currentRoot)
                ? "headingWrapper"
                : null
            }>
            <Typography className={classes.heading}>
              <BusinessCustomer className='releaseIcon' />
              Business Customers
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='/all-business-contacts'
              className={`${classes.hyperLink} ${
                [
                  "/consumer-overview",
                  "/all-business-contacts",
                  "/new-contact",
                  "/new-customer",
                  "/business-customer",
                  "/new-company-contacts",
                  "/business-contact-favorites",
                  "/business-contact-recently-added",
                  "/business-contact-archived",
                  "/business-contact-blocked",
                  "/business-contact-deleted",
                  "/blocked-business-location",
                ].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              Business Contacts
            </Link>

            <Link
              to='/all-companies'
              className={`${classes.hyperLink} ${
                ["/all-companies"].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              Companies
            </Link>
            <Link
              to='/business-group'
              className={`${classes.hyperLink} ${
                currentRoot === "/business-group" ||
                currentRoot === "/new-contact" ||
                currentRoot === "/new-business-group"
                  ? classes.activeTest
                  : null
              }`}>
              Business Groups
            </Link>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded3}
          onChange={() => {
            setExpanded3(expanded3 ? false : true);
            dispatch(
              endUserActions.onChange("panel3", expanded3 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel3a-content'
            id='panel3a-header'
            className={
              [
              "lead-generation",
              "/all-leads",
              "/lead-group"
              ].includes(currentRoot)
                ? "headingWrapper"
                : ""
            }>
            <Typography className={classes.heading}>
              <LeadVititor className='releaseIcon' />
              Leads/Visitors
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link to='/lead-generation' className={`${classes.hyperLink} ${
                ['/lead-generation'].includes(currentRoot)
                  ? classes.activeTest
                  : null
              }`}>
              All Leads
            </Link>

            <Link to='#' className={classes.hyperLink}>
              Lead Groups
            </Link>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded4}
          onChange={() => {
            setExpanded4(expanded4 ? false : true);
            dispatch(
              endUserActions.onChange("panel4", expanded4 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel4a-content'
            id='panel4a-header'
            className={
              currentRoot === "/all-end-users" ||
              currentRoot === "/customer-group" ||
              currentRoot === "/all-users-groups" ||
              currentRoot === "/customer-overview" ||
              currentRoot === "/new-contact"
                ? "headingWrapper"
                : null
            }>
            <Typography className={classes.heading}>
              <EndUsers className='releaseIcon' />
              End Users/Consumers
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='/all-end-users'
              className={`${classes.hyperLink} ${
                currentRoot === "/all-end-users" ||
                currentRoot === "/customer-overview" ||
                currentRoot === "/new-contact"
                  ? classes.activeTest
                  : null
              }`}>
              All End Users
            </Link>

            <Link
              to='/all-users-groups'
              className={`${classes.hyperLink} ${
                // currentRoot === "/all-end-users" ||
                currentRoot === "/all-users-groups" ||
                currentRoot === "/new-enduser-group"
                  ? classes.activeTest
                  : null
              }`}>
              All User Groups
            </Link>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded5}
          onChange={() => {
            setExpanded5(expanded5 ? false : true);
            dispatch(
              endUserActions.onChange("panel5", expanded5 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel5a-content'
            id='panel5a-header'
            className={
              currentRoot === "/all-contacts" ||
              currentRoot === "/customer-group" ||
              currentRoot === "/new-contact"
                ? classes.heading
                : null
            }>
            <Typography className={classes.heading}>
              <VenderContact className={classes.accordianIcons} />
              Vendor Contacts
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link to='/all-business-contacts' className={classes.hyperLink}>
              All Vendors
            </Link>

            <Link to='/all-business-contacts' className={classes.hyperLink}>
              Vendor Groups
            </Link>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded6}
          onChange={() => {
            setExpanded6(expanded6 ? false : true);
            dispatch(
              endUserActions.onChange("panel6", expanded6 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel6a-content'
            id='panel6a-header'
            className={
              currentRoot === "/all-contacts" ||
              currentRoot === "/customer-group" ||
              currentRoot === "/new-contact"
                ? classes.heading
                : null
            }>
            <Typography className={classes.heading}>
              <ContactSettings className='releaseIcon' />
              Contacts Settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link to='/all-business-contacts' className={classes.hyperLink}>
              General
            </Link>

            <Link to='/all-business-contacts' className={classes.hyperLink}>
              Contact Types
            </Link>
            <Link to='/all-business-contacts' className={classes.hyperLink}>
              Onboarding
            </Link>
            <Link to='/all-business-contacts' className={classes.hyperLink}>
              Product Registration
            </Link>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.boxes}
          expanded={expanded7}
          onChange={() => {
            setExpanded7(expanded7 ? false : true);
            dispatch(
              endUserActions.onChange("panel7", expanded7 ? false : true)
            );
          }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls='panel6a-content'
            id='panel6a-header'
            className={
              currentRoot === "/all-admin-users" ||
              currentRoot === "/admin-overview"
                ? "headingWrapper"
                : null
            }>
            <Typography className={classes.heading}>
              <ContactSettings className='releaseIcon' />
              Admin Users
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.links}>
            <Link
              to='/all-admin-users'
              className={`${classes.hyperLink} ${
                currentRoot === "/all-admin-users" ||
                currentRoot === "/admin-overview"
                  ? classes.activeTest
                  : null
              }`}>
              All Admin Users
            </Link>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export { BusinessCustomerNav };
