import React, { useState } from "react";
import CustomSwitch from "../../../../components/common/Switch/CustomSwitch";
import Grid from "@material-ui/core/Grid";
import { PumpOne, PumpTwo, Blower } from "./SvgIcons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

export default function SpaPumpCard() {
  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);
  const [checkedPumpTwo, setCheckedPumpTwo] = useState(false);
  const [checkedBlower, setCheckedBlower] = useState(false);



  const handlePumpOneChange = (event, newVal) => {
    let pumpPayload = null;
    if (newVal === "O") {
      pumpPayload = {
        pump1: "Off",
        deviceId: spaWaterTreatment.deviceId,
        id: spaGlobalSettings.id,
      };
    } else if (newVal === "L") {
      pumpPayload = {
        pump1: "Low",
        deviceId: spaWaterTreatment.deviceId,
        id: spaGlobalSettings.id,
      };
    } else {
      pumpPayload = {
        pump1: "High",
        deviceId: spaWaterTreatment.deviceId,
        id: spaGlobalSettings.id,
      };
    }
    updatingGlobalSetting(pumpPayload);
  };
  const onChangePump2 = (value) => {
    const pump2Payload = {
      pump2: value ? "True" : "False",
      deviceId: spaWaterTreatment.deviceId,
      id: spaGlobalSettings.id,
    };
    updatingGlobalSetting(pump2Payload);
  };
  const onChangeBlower = (value) => {
    const blowerPayload = {
      blower: value ? "True" : "False",
      deviceId: spaWaterTreatment.deviceId,
      id: spaGlobalSettings.id,
    };
    updatingGlobalSetting(blowerPayload);
  };
  const updatingGlobalSetting = value => {
    setIsloading(true);
    dispatch(endUserActions.onUpdataGlobalSetting(value)).then(res => {
      if (res?.success) {
        dispatch(endUserActions.onGetSpaInfo(spaWaterTreatment.deviceId)).then(
          data => {
            setIsloading(false);
            if (data?.success === false) {
              toast.error(data?.message);
            } else {
              toast.success("Value updated successfully.");
            }
          }
        );
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };

  const { spaGlobalSettings, spaWaterTreatment, spaTemperatureSettings } = useSelector(
    state => state.endUserReducer
  );

  return (
    <Grid item md={12} className="gridMainSpaWrapper spaPumpContainer cardSetting">
      {isLoading ? <Loader /> : null}
      <Grid item md={12} className="gridSpaPumpsWrapper">
        <Grid item md={12} className="gridSpaPumpsWrapperBody">
          <Grid item md={12} className="gridcolumn">
            <Grid className='pumpsContentOuter'>
            <Grid item md={12}>
            <Grid item md={12} className="gridcolumn">
              <Grid item md={12} className="gridSpaPumpsHeading">
                <span item md={6} className='pumpsHeading'>
                  Pumps
                </span>
              </Grid>
            </Grid>
          </Grid>
            <Grid item md={12} className='pumpsContent'>
              <span style={{marginRight: '55px'}}>
                <PumpOne/>
              </span>
              <h4 className='pumpsNo' >Pump 1</h4>

              <ToggleButtonGroup
                // value={checkedPumpOne}
                exclusive
                onChange={spaTemperatureSettings?.isMaintenanceLock === false && (
                  handlePumpOneChange)}
              >
                <ToggleButton
                  variant="outlined"
                  value="O"
                  selected={spaGlobalSettings?.pump1 === "Off" ? true : false}
                  className="tempratureButtonControlGroup"
                >
                  O
                </ToggleButton>
                <ToggleButton
                  variant="outlined"
                  value="L"
                  selected={spaGlobalSettings?.pump1 === "Low" ? true : false}
                  className="tempratureButtonControlGroup"
                >
                  L
                </ToggleButton>
                <ToggleButton
                  variant="outlined"
                  value="H"
                  selected={spaGlobalSettings?.pump1 === "High" ? true : false}
                  className="tempratureButtonControlGroup"
                >
                  H
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item md={12} className='pumpsContent'>
              <span>
                <PumpTwo />
              </span>
              <h4 className='pumpsNo' >Pump 2</h4>

              <CustomSwitch
                onClick={(e) => 
                  spaTemperatureSettings?.isMaintenanceLock === false && (
                  onChangePump2(e.target.checked))}
                checked={spaGlobalSettings?.pump2 === "True" ? true : false}
              />
            </Grid>
            <Grid item md={12} className='pumpsContent'>
              <span>
                <Blower />
              </span>
              <h4 className='pumpsNo'>Blower</h4>

              <CustomSwitch
                onClick={(e) => 
                  spaTemperatureSettings?.isMaintenanceLock === false &&(
                  onChangeBlower(e.target.checked))}
                checked={spaGlobalSettings?.blower === "True" ? true : false}
              />
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
