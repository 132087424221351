import { Grid, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { ChevronRight } from '../../LeftNav/SvgIcons';
import SoftwareGridFilter from './SoftwareGridFilter';
import CloseIcon from "@material-ui/icons/Close";

export const EligibleDevicesPreview = ({selectedReleaseProducts}) => {
    const [eligibleProductItem, setEligibleProductItem] = useState(3);
    const [searchSelectdEligibleProduct, setSearchEligibleProduct] =useState("")

    const eligibleProductFilteredData = selectedReleaseProducts.filter(
      (X) =>
        X?.value
          ?.toLowerCase()
          .includes(searchSelectdEligibleProduct.toLocaleLowerCase()) ||
        X?.brand
          .toLocaleLowerCase()
          .includes(searchSelectdEligibleProduct.toLocaleLowerCase())
    );

    const defaultItemsToShow = 3;
    const handleEligibleProducts = () => {
        // Toggle between showing more or less items
        setEligibleProductItem((prevItems) =>
          prevItems === defaultItemsToShow
            ? eligibleProductFilteredData.length
            : defaultItemsToShow
        );
      };
  return (
    <Grid className="releaseConatiner">
    <h2>Eligible Devices</h2>
    <h3>Eligible Devices</h3>
    <p>{eligibleProductFilteredData.length} Eligible Devices Applied</p>
    <Grid className="searchContainer">
      <SoftwareGridFilter
        search={searchSelectdEligibleProduct}
        setSearch={setSearchEligibleProduct}
        placeholder={"Search"}
      />
    </Grid>
    <Grid className="useRequirementsChips">
      <Grid className="chevronIcon">
        <IconButton onClick={handleEligibleProducts}>
          <ChevronRight />
        </IconButton>
      </Grid>
      <Grid className="portalItemGrid">
        {eligibleProductFilteredData.slice(0, eligibleProductItem).map((item) => (
          <Grid className="portalChips">
            <Typography className="chipsTypo">{item.value}</Typography>
            &nbsp;
            <Typography className="deviceName">{item.brand}</Typography>
            <CloseIcon />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
  )
}
