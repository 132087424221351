import { launchesTypes } from "./launchesTypes";
import _ from "lodash";

const INITIAL_STATE = {
  panel1: true,
  panel2: false,
  panel3: false,
  panel4: false,
  panel5: false,
  errorMessage: "",
  launches: [],
  selectedLaunches: [],
  releaseCategories: [],
  releaseLocations: [],
  releaseRelatedEvents: [],
  releaseNewsRoomVisibilities: [],
  allReleases: [],
  releases: [],
  pendingApprovalProducts: [],
  newestApprovedProducts: [],
  selectedRelease: [],
  selectedRelCategory: [],
  selectedCollectionAsset: [],
  releaseVisibilities: [],
  selectedReleasesGroups: [],
  launchPlan: "",
  preReleases: [
    {
      date: "",
      time: "",
      groups: [],
    },
  ],
  eventCategory: [],
  specificRelease: null,
  paginationResponse: {
    currentCount: 0,
    totalRecords: 0,
  },
};

export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case launchesTypes.LAUNCHES_ONCHANGE:
      return {
        ...state,
        errorMessage: "",
        [action.payload.prop]: action.payload.value,
      };
    case launchesTypes.SELECTED_LAUNCHES: {
      const newState = { ...state };
      const selectedLaunches = action.payload;
      const launchExist = newState.selectedLaunches.find((launch) => {
        return launch.id === selectedLaunches.id;
      });

      if (launchExist && newState.selectedLaunches.length > 0) {
        _.remove(newState.selectedLaunches, (launch) => {
          return launch.id === selectedLaunches.id;
        });
      } else {
        newState.selectedLaunches.push(selectedLaunches);
      }
      return newState;
    }
    case launchesTypes.ALL_SELECTED_LANUCHES: {
      return {
        ...state,
        selectedLaunches: action.payload,
      };
    }
    case launchesTypes.SELECTED_RELEASES: {
      const newState = { ...state };
      const releases = action.payload;
      const releaseExist = newState.selectedRelease.find((release) => {
        return release.id === releases.id;
      });

      if (releaseExist && newState.selectedRelease.length > 0) {
        _.remove(newState.selectedRelease, (release) => {
          return release.id === releases.id;
        });
      } else {
        newState.selectedRelease.push(releases);
      }
      return newState;
    }
    case launchesTypes.ALL_SELECTED_RELEASES:{
      return {
        ...state,
        selectedRelease: action.payload,
      };
    }
    case launchesTypes.SELECTED_REL_CATEGORY: {
      const newState = { ...state };
      const relCategory = action.payload;
      const releaseExist = newState.selectedRelCategory.find((release) => {
        return release.id === relCategory.id;
      });

      if (releaseExist && newState.selectedRelCategory.length > 0) {
        _.remove(newState.selectedRelCategory, (release) => {
          return release.id === relCategory.id;
        });
      } else {
        newState.selectedRelCategory.push(relCategory);
      }
      return newState;
    }
    case launchesTypes.SELECTED_COLLECTION_ASSET: {
      const newState = { ...state };
      const collection = action.payload;
      const collectionExist = newState.selectedCollectionAsset.find((col) => {
        return col.id === collection.id;
      });

      if (collectionExist && newState.selectedCollectionAsset.length > 0) {
        _.remove(newState.selectedCollectionAsset, (col) => {
          return col.id === collection.id;
        });
      } else {
        newState.selectedCollectionAsset.push(collection);
      }
      return newState;
    }
    case launchesTypes.SELECT_RELEASE_VISIBILITY: {
      const newState = { ...state };
      let a = [...newState.releaseVisibilities];
      const relVisibility = action.payload;
      let item = a[relVisibility.index];
      if (relVisibility.name === "viewRelease") {
        item = { ...item, viewRelease: !item.viewRelease };
      } else {
        item = { ...item, preReleaseAccess: !item.preReleaseAccess };
      }
      a[relVisibility.index] = item;
      newState.releaseVisibilities = a;
      return newState;
    }
    case launchesTypes.PRE_RELEASES: {
      const newState = { ...state };
      newState.preReleases.push({
        date: "",
        time: "",
        groups: [],
      });
      return newState;
    }
    default:
      return state;
  }
};
