import { Chip, Grid, IconButton, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { ChevronRight, ChipCross, CloseGrayIconButton, CloseIconButton } from '../../../LeftNav/SvgIcons';

const useStyles = makeStyles((theme) => ({
    popupCheckBox: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "10px",
    },
    popupCheckBoxText: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#2D3339',
    },
    recommendedText: {
        color: '#18BC6D',
        fontSize: '14px',
        fontWeight: '600',
    },
    chipContiner: {
        display:"flex !important",
        marginTop: "16px",
        // height: "70px",
        overflowY: "auto",
        "& .MuiChip-deleteIconSmall": {
            margin: 0,
        },
    },
    selectedChips: {
        marginRight: "16px",
        marginTop: "16px",
    },
    chipsBox: {
        marginBottom: "16px",
        display:"flex",
        justifyContent:"flex-start",
        // alignItems:"center",
    },
    chipBox: {
        "& .MuiChip-sizeSmall": {
            height: "36px",
            padding: "8px 16px",
            backgroundColor: "#F3F6F9",
        },
        "& .MuiChip-label": {
            fontSize: "14px",
            fontWeight: "700",
            color: "#70808F"
        },
    },
    expandChipButton: {
        color: "#3699FF",
        fontSize: "14px",
        fontWeight: "700",
        cursor: "pointer",
    },
    noChipText: {
        color: "#BDBDBD",
        fontSize: "12px",
        fontWeight: "500",
        fontStyle: "italic",
        textAlign: "center",
        marginTop: "16px",
        marginBottom: "16px",
    },
    conversationChannelLabel: {
        color: "#70808f",
        margin: "0 16px 16px 0",
        display: "flex",
        padding: "8px 16px",
        fontSize: "14px",
        alignItems: "center",
        fontWeight: "400",
        borderRadius: "36px !important",
        justifyContent: "space-between",
        background: "#f3f6f9 !important",
    },
    channelName: {
        color: "#3699FF",
        fontStyle: "normal",
        fontWeight: "700",
    },
    conversationChanneldeleteLabel: {
        cursor: "pointer",
        display: "flex",
        marginLeft: "15px",
        svg: {
            path: {
                fill: "#A9B2BC",
            }
        }
    }
}))
export const ConversationAccordionChips = ({ text, onRemoveChips, chipData }) => {
    const classes = useStyles();
    const [chipItems, setChipItems] = useState(3);
    const data = [];
    const defaultItemsToShow = 3;
    const handleChipItem = () => {
        setChipItems((prevItems) =>
            prevItems === defaultItemsToShow
                ? chipData?.length
                : defaultItemsToShow
        );
    };

    return (
        <Grid className={classes.chipsBox}>
            {
                chipData?.length ?
                 <Grid className="chevronIcon">
                <IconButton onClick={handleChipItem}>
                    <ChevronRight />
                </IconButton>
                </Grid> 
                : null
            }

            {chipData?.length ? <Grid container className={classes.chipContiner}>
                {chipData
                    ?.slice(0, chipItems)
                    .map((device) => (
                        <Grid
                            key={`${device?.id}-${device?.value}`}
                            className={classes.chipBox}
                        >
                            <span className={classes.conversationChannelLabel} key={device.id}>
                                <span>
                                    {device.value} <i className={classes.channelName}>{device?.generic}</i>
                                </span>
                                <span className={classes.conversationChanneldeleteLabel} onClick={() => onRemoveChips(device.id)}>
                                    <CloseGrayIconButton />
                                </span>
                            </span>
                        </Grid>
                    ))}

            </Grid> :
                <Grid item xs={12} className={classes.noChipText}>
                    {text}
                </Grid>
            }
        </Grid>
    )
};
