import React, { useState } from "react";
import CustomSwitch from "../../../../components/common/Switch/CustomSwitch";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import Loader from "../../../../components/common/Loader";
import { toast } from "react-toastify";

export default function SpaQuickToolsCard() {
  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);

  const onChangeCleanCycle = value => {
    const quickPayload = {
      cleanCycle: value,
      deviceId: spaWaterTreatment.deviceId,
      id: spaGlobalSettings.id,
    };
    updatingGlobalSetting(quickPayload);
  };
  const onChangeSummer = value => {
    const quickPayload = {
      summerTimer: value,
      deviceId: spaWaterTreatment.deviceId,
      id: spaGlobalSettings.id,
    };
    updatingGlobalSetting(quickPayload);
  };
  const onChangeLockStatus = value => {
    const quickPayload = {
      spaLockStatus: value,
      deviceId: spaWaterTreatment.deviceId,
      id: spaGlobalSettings.id,
    };
    updatingGlobalSetting(quickPayload);
  };
  const onChangeInsideLight = value => {
    const quickPayload = {
      insightLights: value,
      deviceId: spaWaterTreatment.deviceId,
      id: spaGlobalSettings.id,
    };
    updatingGlobalSetting(quickPayload);
  };

  const updatingGlobalSetting = value => {
    setIsloading(true);
    dispatch(endUserActions.onUpdataGlobalSetting(value)).then(res => {
      if (res?.success) {
        dispatch(endUserActions.onGetSpaInfo(spaWaterTreatment.deviceId)).then(
          data => {
            setIsloading(false);
            if (data?.success === false) {
              toast.error(data?.message);
            } else {
              toast.success("Value updated successfully.");
            }
          }
        );
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };

  const { spaGlobalSettings, spaWaterTreatment, spaTemperatureSettings } = useSelector(
    state => state.endUserReducer
  );

  return (
    <Grid item md={12} className="gridMainSpaWrapper">
      {isLoading ? <Loader /> : null}
      <Grid item md={12} className="gridSpaQuickToolsWrapper">
        <Grid item md={12} className="gridSpaQuickToolsWrapperBody">
          <Grid item md={12}>
          <Grid item md={12}>
          <Grid item md={12}>
            <Grid item md={12} className="gridSpaQuickToolsHeading">
              <span item md={6} className="pumpsHeading">
                Quick Tools
              </span>
              {/* <span item md={6} className="settingChange">
                SPA SETTING
              </span> */}
            </Grid>
          </Grid>
        </Grid>
            <Grid item md={12} className="gridSpaQuickToolsControlItem gridSpaQuickToolsControlItemManager">
              <span className="textInfo">Clean Cycle</span>

              <CustomSwitch
                onClick={e => 
                  spaTemperatureSettings?.isMaintenanceLock === false && (
                  onChangeCleanCycle(e.target.checked))}
                checked={spaGlobalSettings?.cleanCycle}
              />
            </Grid>
            <Grid item md={12} className="gridSpaQuickToolsControlItem gridSpaQuickToolsControlItemManager">
              <span className="textInfo">Summer Timer</span>

              <CustomSwitch
                onClick={e =>
                  spaTemperatureSettings?.isMaintenanceLock === false && (
                  onChangeSummer(e.target.checked))}
                checked={spaGlobalSettings?.summerTimer}
              />
            </Grid>
            <Grid item md={12} className="gridSpaQuickToolsControlItem gridSpaQuickToolsControlItemManager">
              <span className="textInfo">Spa Lock Status</span>

              <CustomSwitch
                onClick={e => 
                  spaTemperatureSettings?.isMaintenanceLock === false && (
                  onChangeLockStatus(e.target.checked))}
                checked={spaGlobalSettings?.spaLockStatus}
              />
            </Grid>
            <Grid item md={12} className="gridSpaQuickToolsControlItem gridSpaQuickToolsControlItemManager">
              <span className="textInfo">Inside Lights</span>

              <CustomSwitch
                onClick={e => 
                  spaTemperatureSettings?.isMaintenanceLock === false && (
                  onChangeInsideLight(e.target.checked))}
                checked={spaGlobalSettings?.insightLights}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
