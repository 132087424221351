import { axios } from "../../config/axiosConfig";
import { endpoints } from "../../config/apiConfig";

async function getAllProducts({
  pageNumber,
  noOfRecoreds,
  orderByKey,
  orderByVal,
  filters,
}) {
  return axios
    .post(endpoints.productInventory.getAllProducts, {
      ...filters,
      pagination: {
        pageSize: noOfRecoreds,
        pageNo: pageNumber,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getAllFavouriteProducts() {
  return axios
    .post(endpoints.productInventory.getAllFavouriteProducts)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
};

async function getAllUserProducts() {
  return axios
    .post(endpoints.productInventory.getAllUserProducts)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProductById(id) {
  return axios
    .post(endpoints.productInventory.getProductById, {
      productId: id,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addupdateProduct(product) {
  return axios
    .post(endpoints.productInventory.addupdateProduct, product)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteProducts(ids) {
  return axios
    .post(endpoints.productInventory.deleteProducts, ids)
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

//#region Serials/Devices
async function getSerialzedDevices({
  isSerialized,
  productId,
  pageNumber,
  noOfRecoreds,
  orderByKey,
  orderByVal,
  filters,
}) {
  return axios
    .post(endpoints.productSerials.getProductSerialList, {
      ...filters,
      isSerialized: isSerialized,
      productId: productId,
      pagination: {
        pageSize: noOfRecoreds,
        pageNo: pageNumber,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getBlockedSerials({
  pageNumber,
  noOfRecoreds,
  orderByKey,
  orderByVal,
  filters,
}) {
  return axios
    .post(endpoints.productSerials.getBlockedSerials, {
      ...filters,
      pagination: {
        pageSize: noOfRecoreds,
        pageNo: pageNumber,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
//#endregion

//#region Support Resources
async function getProductRegistrationByProductId(productId) {
  return axios
    .post(endpoints.supportResources.getProductRegistrationByProductId, {
      id: productId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addupdateProductRegistration(productRegistration) {
  return axios
    .post(
      endpoints.supportResources.addupdateProductRegistration,
      productRegistration
    )
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProductReturnByProductId(productId) {
  return axios
    .post(endpoints.supportResources.getProductReturnByProductId, {
      id: productId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addupdateProductReturn(productReturn) {
  return axios
    .post(endpoints.supportResources.addupdateProductReturn, productReturn)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getEndCustomerGroups({ CustomerTypeName }) {
  return axios
    .get(
      `${endpoints.supportResources.getGroupDropdown}?CustomerTypeName=${CustomerTypeName}`
    )
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProdSupportResources({ id }) {
  return axios
    .post(endpoints.supportResources.getProductSupportResources, {
      id: id,
    })
    .then(response => response?.data)
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addProductSupportResources(productSupportLinks) {
  return axios
    .post(
      endpoints.supportResources.addProductSupportResources,
      productSupportLinks
    )
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
//#endregion

async function getProductActivities({ productId }) {
  return axios
    .post(endpoints.productInventory.getProductActivities, {
      id: productId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProductNotes({ productId }) {
  return axios
    .post(endpoints.productInventory.getProductNotes, {
      id: productId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addProductNotes(note) {
  return axios
    .post(endpoints.productInventory.addProductNotes, note)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteProductNotes(noteId) {
  return axios
    .post(endpoints.productInventory.deleteProductNotes, {
      id: noteId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getPricingAvailabilityPeriods({
  pageNumber,
  noOfRecoreds,
  orderByKey,
  orderByVal,
  filters,
}) {
  return axios
    .post(endpoints.productInventory.getPricingAvailabilityPeriodList, {
      ...filters,
      pagination: {
        pageSize: noOfRecoreds,
        pageNo: pageNumber,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addUpdateAvailabilityPeriod(availabilityPeriod) {
  return axios
    .post(
      endpoints.productInventory.addupdatePricingAvailabilityPeriod,
      availabilityPeriod
    )
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getPricingByProductId({
  productId,
  period,
}) {
  return axios
    .post(endpoints.productInventory.getProductPricingById, {
      productId: productId,
      period: period,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function addUpdateBulkProductPricing(data) {
  return axios
    .post(endpoints.productInventory.addUpdateBulkProductPricing, data)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}
async function addFavoriteProduct({
  productId,
}) {
  const dynamicUrl = endpoints.productInventory.favoriteProduct;
  const idUrl = dynamicUrl.replace('{$id}', productId)
  return axios
    .post(idUrl, {})
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addUpdateProductPricing(productPricing) {
  return axios
    .post(endpoints.productInventory.addUpdateProductPricing, productPricing)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProductPricingGroup({ productId }) {
  return axios
    .post(endpoints.productInventory.getProductPricingGroup, {
      id: productId,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProductDescriptionByProduct(payload) {
  return axios
    .post(endpoints.productInventory.getProductDescriptionByProduct, payload)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function addUpdateProductDescription({ url, payload }) {
  return axios
    .post(url, payload)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getProductPriceDescriptionByProduct(payload) {
  return axios
    .post(
      endpoints.productInventory.getProductPriceDescriptionByProduct,
      payload
    )
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

//Price List Tools
async function getPricingList({
  pageNumber,
  noOfRecoreds,
  period,
  periodName,
  orderByKey, 
  orderByVal
}) {
  return axios
    .post(endpoints.priceListTool.getPriceList, {
      period,
      priceListNameInternal: periodName,
      pagination: {
        pageSize: noOfRecoreds,
        pageNo: pageNumber,
        orderByKey: orderByKey,
        orderByVal: orderByVal,
      },
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function loadData({ url, payload, config = null }) {
  return axios
    .post(url, payload, config)
    .then(response => {
      if (response?.status === 200) return response?.data;
      return {
        errorResponse: response,
        message: "Something went wrong!",
        success: false,
      };
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function loadDataGet({ url }) {
  return axios
    .get(url)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getPriceListById(id) {
  return axios
    .post(endpoints.priceListTool.getPriceListById, {
      id: id,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function deleteItemsByIds({ url, itemIds }) {
  return axios
    .post(url, {
      ids: itemIds,
    })
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      console.error("error : ", error);
      return null;
    });
}

async function getAllAvailableTimes() {
  return axios.post(endpoints.productInventory.getPricingAvailabilityPeriodDropdownV2)
  .then(response => {
    return response?.data;
  })
  .catch(error => {
    console.log("Error: ", error);
    return null;
  });
}

export const productInventoryApi = {
  getAllProducts: getAllProducts,
  getProductById: getProductById,
  addupdateProduct: addupdateProduct,
  deleteProducts: deleteProducts,
  getProductActivities: getProductActivities,
  getProductNotes: getProductNotes,
  addProductNotes: addProductNotes,
  deleteProductNotes: deleteProductNotes,
  getSerialzedDevices: getSerialzedDevices,
  getBlockedSerials: getBlockedSerials,
  getProductRegistrationByProductId: getProductRegistrationByProductId,
  addupdateProductRegistration: addupdateProductRegistration,
  getProductReturnByProductId: getProductReturnByProductId,
  addupdateProductReturn: addupdateProductReturn,
  getEndCustomerGroups: getEndCustomerGroups,
  getProdSupportResources: getProdSupportResources,
  addProductSupportResources: addProductSupportResources,
  getPricingAvailabilityPeriods: getPricingAvailabilityPeriods,
  addUpdateAvailabilityPeriod: addUpdateAvailabilityPeriod,
  getPricingByProductId: getPricingByProductId,
  addFavoriteProduct : addFavoriteProduct,
  addUpdateProductPricing: addUpdateProductPricing,
  getProductPricingGroup: getProductPricingGroup,
  getProductDescriptionByProduct: getProductDescriptionByProduct,
  addUpdateProductDescription: addUpdateProductDescription,
  getProductPriceDescriptionByProduct: getProductPriceDescriptionByProduct,
  loadData: loadData,
  loadDataGet: loadDataGet,
  getPricingList: getPricingList,
  getPriceListById: getPriceListById,
  deleteItemsByIds: deleteItemsByIds,
  getAllAvailableTimes: getAllAvailableTimes,
  getAllFavouriteProducts: getAllFavouriteProducts,
  getAllUserProducts: getAllUserProducts,
  addUpdateBulkProductPricing: addUpdateBulkProductPricing,
};
