import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Chip,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  InputLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { MdClear } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { MenuProps } from "../../../../components/common/utils";
import { ValidatorForm } from "react-material-ui-form-validator";
import { searchIcon, crossImg } from "../../../../resources/images";
import { useDebounce } from "../../../../utils/use-debounce";
import InputField from "../../../../components/common/InputField";
import { FilterArrow } from "../../LeftNav/SvgIcons";
import { Autocomplete } from "@material-ui/lab";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { Dropdown } from "../../../../components/common";
import { DateCreated } from "../../../../config/Enums";
import { filter } from "lodash";
import { fi } from "date-fns/locale";

const ChipItem = ({ chipLabel, chipValue, onDelete }) => {
  const classes = useStyless();

  return (
    <Chip
      className={classes.MuiChip}
      deleteIcon={<MdClear style={{ width: "16px", height: "16px" }} />}
      label={
        <span className={classes.MuiChip}>
          <b>{chipLabel}: </b>
          {chipValue}
        </span>
      }
      onDelete={onDelete}
    />
  );
};

const useStyless = makeStyles((theme) => ({
  gridWrapper: {
    paddingTop: "29px",
    paddingBottom: "20px",
    width: "100%",
    borderBottom: "1px solid #E2E6E9",
    position: "relative",
    "& .MuiGrid-container": {
      padding: "unset",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  MuiChip: {
    backgroundColor: "#CDE5FF",
    color: "#70808F",
    maxWidth: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    margin: "0px 5px 5px 0px",
  },
  chip: {
    margin: 2,
  },
  dropdownBg: {
    background: "#f1f1f1",
  },
  formControl: {
    minWidth: "100%",
    background: "#f3f6f9",
    '& .MuiInputLabel-shrink':{
        display:"none",
    },
    '& .MuiInputLabel-formControl': {
        top: "-12px",
        left: "8px",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
    },
    '& .MuiFilledInput-root':{
        backgroundColor:'unset',
        '&:hover':{
            backgroundColor:"unset",
        }
    },
    '& .MuiFilledInput-underline:hover:before':{
        borderBottom:"unset",
    },
    '& .MuiFilledInput-underline:before':{
        transition:"unset",
    },
    '& .MuiFormLabel-root':{
        color:"#010114 !important"
    }
  },
  selectEmpty: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    // backgroundColor: "#F3F6F9",
    color:"unset",
    width: "100%",
    borderRadius: "6px",
    "&:before": {
      borderBottom: "1px solid #F3F6F9",
      "&:hover": {
        borderBottom: "unset !important",
      },
    },
    "&:after": {
      borderBottom: "1px solid #F3F6F9",
    },

    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
  },

  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9",
      },
    },
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px",
  },
}));

const handleSubmit = (e) => {
  e.preventDefault();
};

export const ReleaseGridSideFilter = ({
  Categories,
  setFilterByName,
  setSelectedCategory,
  setSelectedDate,
  filterTitle = "Media Release",
}) => {
  const classes = useStyless();
  const dispatch = useDispatch();

  const [cat, setCat] = useState();
  const [selected, setSelected] = useState([]);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [selectDate, setSelectDate] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showFilterCounter, setShowFilterCounter] = useState(0);

  const showFilter = () => {
    window.scroll(0, 0);
    // document.body.classList.add("bodyScroll");
    setIsFilter(true);
  };

  useEffect(() => {
    setCat(Categories);
  }, [Categories]);

  const handleChange = (event) => {};
  const [search, setSearch] = useState("");

  const onPressClear = () => {
    cat.map((x) => {
      x.checked = false;
    });
    setCat([...cat], cat);
    setSearch("");
    setIsFilterShow(false);
    setSelectedCategory(null);
    setFilterByName(null);
    setSelectedDate(0);
    setSelectDate(null);
    setSelected([]);
    setShowFilterCounter(0);
    hideFilter();
  };

  const onPressFilterBtn = () => {
    setFilterByName(search);
    var selCatId = [];
    cat.map((x) => {
      if (x.checked) {
        selCatId.push(Number(x.id));
      }
    });
    setSelectedDate(selectDate === null ? 0 : selectDate);
    setSelectedCategory(selCatId.length === 0 ? null : selCatId);
    setShowFilterCounter(filterCount);
    hideFilter();
  };
  const checkCatSelected = (item) => {
    var catName = [];
    cat.map((x) => {
      if (x.id === item.id) {
        x.checked = !item.checked;
      }
    });
    cat.map((x) => {
      if (x.checked) {
        catName.push(x.value);
      }
    });
    setSelected(catName);
    setCat([...cat], cat);
  };

  const hideFilter = () => {
    setIsFilter(false);
  };

  function truncate(input) {
    if (input && input.length > 30) {
      return input.substring(0, 27) + "...";
    }
    return input;
  }

  return (
    <div className={classes.gridWrapper}>
      <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            className="filterMainSearch endUserSearchFilterMain"
          >
            <img src={searchIcon} alt="" className="searchIcon" />
            <InputField
              placeholder="Search"
              value={search}
              onChange={({ target }) => {
                setSearch(target.value);
                setFilterByName(target.value);
              }}
              onPaste={(value) => {
                const splitValue = value.split("\n");
                setSearch(splitValue.join(", "));
              }}
            />
            <span className="endUserSearchFilter" onClick={showFilter}>
              {showFilterCounter ? (
                <span>{`${showFilterCounter} Filter${
                  showFilterCounter > 1 ? "(s)" : ""
                } Applied `}</span>
              ) : null}
              <FilterArrow color={"#fff"} />
            </span>
          </Grid>
        </Grid>

        {isFilter && (
          <div className="endUserFilterWrapper">
            <div className="endUserFilterInnerWrapper">
              <img
                className="filterCrossImage"
                src={crossImg}
                alt="cross icon"
                onClick={hideFilter}
              />
              <div className="formContainer">
                <h3 className="endUserFilterHeading">{filterTitle} Filters</h3>
                <Accordion
                  defaultExpanded
                  id="end-user-filter-expandable"
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>
                      <span>Active Filter(s)</span>
                      <span>
                        <Button
                          className="filterClearBtn filterClearBtnStyle"
                          variant="outlined"
                          onClick={() => {
                            onPressClear();
                            setFilterCount(0);
                          }}
                        >
                          Clear
                        </Button>
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <div>
                    {selectDate !== null && (
                      <ChipItem
                        chipLabel="Time "
                        chipValue={
                          selectDate === 0
                            ? "All Time"
                            : selectDate === 1
                            ? "Within Month"
                            : selectDate === 2
                            ? "Within Past Month"
                            : selectDate === 3
                            ? "Within Past 3 Month"
                            : selectDate === 4
                            ? "Within Past 6 Month"
                            : selectDate === 5
                            ? "Within Past Year"
                            : null
                        }
                        onDelete={() => {
                          setSelectDate(null);
                          setFilterCount(filterCount - 1);
                        }}
                      />
                    )}

                    {selected &&
                      selected.map((y) => (
                        <ChipItem
                          chipLabel="Category "
                          chipValue={y}
                          onDelete={() => {
                            setSelected(selected.filter((x) => x !== y));
                            setCat(
                              cat.map((x) => {
                                if (x.value === y) {
                                  return { ...x, checked: false };
                                }
                                return x;
                              })
                            );
                            setFilterCount(filterCount - 1);
                          }}
                        />
                      ))}
                  </div>
                  {filterCount === 0 && (
                    <div className={`filtersChips`}>No Active Filter(s)</div>
                  )}
                </Accordion>

                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    marginTop: "7px",
                  }}
                >
                  <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                  <InputLabel id="mutiple-select-label">Select Categories</InputLabel>
                    <Select
                      id="demo-simple-select"
                      labelId="mutiple-select-label"
                      multiple
                      value={selected}
                      className={classes.selectEmpty}
                      onChange={(e) => handleChange(e)}
                      renderValue={(selected) => truncate(selected?.join(", "))}
                      label={"Select"}
                      MenuProps={MenuProps}
                      variant={"filled"}
                      displayEmpty
                    >
                      {cat &&
                        cat.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            <ListItemIcon>
                              <Checkbox
                                id={`checkbox-${index}`}
                                onClick={() => {
                                  checkCatSelected(option);
                                  if (option.checked === true) {
                                    setFilterCount(filterCount + 1);
                                  } else {
                                    setFilterCount(filterCount - 1);
                                  }
                                }}
                                checked={option.checked}
                              />
                            </ListItemIcon>
                            <label htmlFor={`checkbox-${index}`}>
                                <ListItemText primary={option.value} />
                            </label>
                          </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Select Upload Time Period</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectDate}
                        onChange={(v) => {
                          setSelectDate(v.target.value);
                          if (!selectDate) {
                            setFilterCount(filterCount + 1);
                          }
                        }}
                        className={classes.selectEmpty}
                        fullWidth
                        variant={"filled"}
                        required
                      >
                        <MenuItem value="" disabled>
                          All Uploads
                        </MenuItem>
                        <MenuItem value={0}>All Uploads</MenuItem>
                        <MenuItem value={1}>Within Last Month</MenuItem>
                        <MenuItem value={2}>Within Past 3 Months</MenuItem>
                        <MenuItem value={3}>Within Past 6 Months</MenuItem>
                        <MenuItem value={4}>Within Past Last Year</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div className="endUserfilrerBtn">
                <Button
                  variant="contained"
                  id="test-button-44"
                  onClick={() => {
                    onPressFilterBtn();
                  }}
                >
                  Apply Filter
                </Button>
              </div>
            </div>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
};
