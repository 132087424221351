import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { searchIcon } from "../../../../resources/images";
import { ProductOwnedFilterIcon } from "../../LeftNav/SvgIcons";
import InputField from "../../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import ClearIcon from '@material-ui/icons/Clear';
import { useDebounce } from "../../../../utils/use-debounce";

const ProductOwnedFilter = ({ setSearchDevice }) => {

  const [search, setSearch] = useState("");
  const [isClear, setIsClear] = useState(false);
  const { debouncedValue } = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedValue) {
      setSearchDevice(search)
      setIsClear(true)
  }else{
    setSearchDevice("")
    setIsClear(false)
  }
}, [debouncedValue]);

  // const SearchValue = () => {
  //   if (
  //     search === "" 
  //   ) return ;
  //   else 
  //   setSearchDevice(search)
  //   setIsClear(true)
  // };

  const CancleSearch = () => {
    setSearchDevice("");
    setSearch("")
    setIsClear(false)
  };
  return (
    <div className="productOwnedFilterGrid">
      <ValidatorForm ref={useRef("form")}>
        <Grid className="filterMainSearch">
          <Grid className="filterSearchGrid">
            <img src={searchIcon} alt="" className="customerSearchIcon" />
            <InputField
              inputlabel="Search"
              placeholder="Search"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
            {/* <span onClick={SearchValue} className="ProductFilterIcon">
                <ProductOwnedFilterIcon />
              </span> */}
             
            {  isClear &&
               <span onClick={CancleSearch} className="ProductCancleIcon">
               <ClearIcon />
             </span>
            }

          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};
export default ProductOwnedFilter;