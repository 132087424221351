import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import MultiSelectAssetFilter from "./MultiSelectAssetFilter";
import InputField from "../../../../components/common/InputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import { searchIcon } from "../../../../resources/images";
import { FiChevronDown } from "react-icons/fi";
import { Dropdown } from "../../../../components/common";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";

const useStyles = makeStyles(theme => ({
  gridWrapper: {
    paddingTop: "40px",
    paddingBottom: "15px",
    borderBottom: "1px solid #E2E6E9"
  },
  filterModal: {
    minWidth: "500px",
    boxShadow: `0px 0px 10px rgb(0 0 0 / 14%)`,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    backgroundColor: "#fff",
    right: "30px",
    padding: "29px  33px",
    boxSizing: "border-box"
  }
}));

const GridFilterBar = ({
  filterByName,
  setFilterByName,
  onFilterButtonPress,
  onClearButtonPress
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedCategoryId, setSelectedCategory] = useState(-1);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dispatch(productCategoriesActions.onGetAllProductSubCategories(0)).then(
      data => setCategories(data?.data)
    );
  }, [dispatch]);

  return (
    <div className={classes.gridWrapper}>
      <ValidatorForm ref={useRef("form")} onSubmit={() => {}}>
        <Grid container spacing={2}>
          <Grid item md={10} className='filterMainSearch'>
            <img src={searchIcon} alt='' className='searchIcon' />
            <InputField
              placeholder='Search'
              value={filterByName}
              onChange={({ target }) => setFilterByName(target.value)}
            />
          </Grid>

          <Grid
            item
            md={2}
            className='searchByFilter'
            onClick={() => setIsFilterShow(!isFilterShow)}>
            Search by Filter <FiChevronDown />
          </Grid>
        </Grid>
        {isFilterShow && (
          <div className={classes.filterModal}>
            <Grid container item xs={8} spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={8}>
                  <Dropdown
                    selectlabel='Category'
                    value={selectedCategoryId}
                    handleChange={({ target }) =>
                      setSelectedCategory(target.value)
                    }
                    placeholder='Please Select'
                    customMenuItems={categories?.map(c => {
                      return {
                        id: c.id,
                        name: c.value
                      };
                    })}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={7}
                style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => {
                    setIsFilterShow(false);
                    onFilterButtonPress &&
                      onFilterButtonPress({
                        selectedCategoryId: selectedCategoryId
                      });
                  }}>
                  Filter
                </Button>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() => {
                    setIsFilterShow(false);
                    setSelectedCategory(false);
                    onClearButtonPress && onClearButtonPress();
                  }}>
                  Clear
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
};

export { GridFilterBar };
