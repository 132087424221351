import {
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { ChipCross, CloseIconCustomPopup } from "../../LeftNav/SvgIcons";
import { TopTabNavigator } from "../../../../components/common/Tabs/TopTabNavigator";
import SoftwareProductGrid from "./SoftwareProductGrid";
import SoftwareGridFilter from "./SoftwareGridFilter";
import { useSelector, useDispatch } from "react-redux";
import { softwareUpdateActions } from "../../../../store/softwareUpdates/softwareUpdatesActions";
import { softwareBuildActions } from "../../../../store/softwareUpdates/SoftwareBuildActions";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: "20px",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 6px 0px #00000040",
    borderRadius: "20px",
    width: "1100px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #E2E5E9",
    padding: "23px 33px 23px 41px;",
    background: "#3699FF",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  customerTypo: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#fff",
  },
  innerBody: {
    overflowY: "auto",
  },
  chooseCustomerHeading: {
    fontSize: "22px",
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#181C32",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#434D56",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    padding: "13px 18px 17px 20px",
  },
  closIcon: {
    padding: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  mainCustomerPricing: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  mainBody: {
    padding: "32px",
  },
  customerGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: " space-between",
    padding: "19px 19px 19px 12px",
    background: "#F1FAFF",
    border: " 1px dashed #00A3FF",
    borderRadius: 9,
    marginRight: 30,
    marginBottom: 20,
  },
  coustomerBox: {
    width: 36,
    height: 36,
    background: "#D8D8D8",
    borderRadius: 3,
    marginRight: 12,
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customerPricingTypo: {
    fontSize: 16,
    fontWeight: 700,
    color: "#181C32",
    fontFamily: "Roboto",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "5px",
  },
  pricingTypoBody: {
    fontSize: 14,
    fontWeight: 700,
    color: "#3699FF",
    fontFamily: "Roboto",
  },
  addedSoftwareActions: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    flexDirection: "row",
  },
  typoGrid: {
    paddingLeft: 13,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 28,
    alignItems: "center",
    gap: "5px",
  },
  addedTypo: {
    marginRight: 24,
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#3699FF",
  },
  addedBtn: {
    backgroundColor: "#18BC6D",
    "&:hover": {
      backgroundColor: "#18BC6D",
    },
  },
  paginationContainer: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "flex-end !important",
  },
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      width: "150px",
      height: "36px",
    },
    '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: "0",
      },
    "& .MuiAutocomplete-inputRoot": {
      flexWrap: "nowrap",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      border: "none !important",
    },
  },
  cancleBtn: {
    height: "36px",
    width: "77px",
    border: "1px solid #8D99A5",
    borderRadius: "6px",
    padding: "10px 16px",
    color: "#8D99A5",
    textTransform: "capitalize",
  },
  applyBtn: {
    height: "36px",
    width:"69px",
    borderRadius: "6px",
    padding: "10px 16px",
    color: "#fff",
  },
  tablePaginationtToolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& .MuiPaginationItem-icon": {
      display: "none",
    },
  },
  chipContiner: {
    marginTop:"16px",
    height:"70px",
    overflowY:"auto",
    "& .MuiChip-deleteIconSmall": {
      margin: 0,
    },
  },
  selectedChips:{
    marginRight:"16px",
    marginTop:"16px",
  },
  chipsBox: {
    marginBottom: "16px",
  },
  expandChipButton:{
  color:"#3699FF",
  fontSize:"14px",
  fontWeight:"700",
  cursor:"pointer",
  },
  noChipText: {
    color: "#BDBDBD",
    fontSize: "12px",
    fontWeight: "500",
    fontStyle: "italic",
    textAlign: "center",
  },
  chipBox: {
    "& .MuiChip-sizeSmall": {
      height: "36px",
      padding: "8px 16px",
      backgroundColor: "#F3F6F9",
    },
    "& .MuiChip-label": {
      fontSize: "14px",
      fontWeight: "700",
      color:"#70808F"
    },
  },
  gridFilterTabContainer: {
    marginTop:"16px"
  },
}));

const allTabs = [
  {
    id: "all",
    title: "All Products",
    component: "",
  },
  {
    id: "popularProducts",
    title: "Popular Products",
    component: "",
  },
];

export const EligibleDevicesPopup = ({
  buildId,
  isEligible,
  setIsEligible,
  title,
  heading,
  description,
  updataProductData,
  order,
  selectedReleaseProducts,
  selectedRelease,
  setOrderBy,
}) => {
  
  const [searchEligibleDevices, setSearchEligibleDevices] =useState("")
  const [filteredvisibilityGroups, setFilteredVisibilityGroups] = useState();
  const [selectAllCheckBox , setSelectAllCheckBox] = useState(false);
  const classes = useStyles();
  const [activeTabId, setActiveTabId] = useState("all");
  const [checkboxSelectedDevices, setCheckboxSelectedDevices] = useState([]);
  const [eligibleDevice, setEligibleDevice] = useState(3);
  const defaultItemsToShow = 3;

  const dispatch = useDispatch();

  const onClose = () => {
    setIsEligible(false);
    const skipedDevices = selectedReleaseProducts.filter((x) => x.id !== 0);
    if (!buildId) {
      dispatch(
        softwareUpdateActions.onChange("selectedReleaseProducts", skipedDevices)
      );
    } else {
      dispatch(
        softwareBuildActions.onChange("selectedBuildProducts", skipedDevices)
      );
    }
  };

  const onApplyEligibleDevice = () => {
    setIsEligible(false);
  }

  useEffect(() => {
    if (searchEligibleDevices !== "") {
        setFilteredVisibilityGroups(
          updataProductData.filter((c) =>
            c.name?.toLowerCase().includes(searchEligibleDevices.toLowerCase()) ||
            c.brand?.toLocaleLowerCase().includes(searchEligibleDevices.toLocaleLowerCase()) ||
            c.category?.toLocaleLowerCase().includes(searchEligibleDevices.toLocaleLowerCase()) ||
            c.sku?.toLocaleLowerCase().includes(searchEligibleDevices.toLocaleLowerCase())
          )
        );
    } else {
      setFilteredVisibilityGroups(updataProductData);
    }
  }, [searchEligibleDevices, updataProductData]);

  const addDevice = (device) => {
    if (
      !selectedReleaseProducts.some(
        (selectedReleaseProducts) => Number(selectedReleaseProducts.productId) == Number(device.id)
      )
    ) {
      let newDevice = {
        softwareReleaseId: selectedRelease?.id,
        productName: device.name,
        productId: device.id,
        id: 0,
      };
      if (!buildId) {
        dispatch(softwareUpdateActions.onChange("selectedReleaseProducts", [...selectedReleaseProducts, newDevice]));
      } else {
        newDevice = {...newDevice, softwareReleaseId: selectedRelease?.softwareReleaseId, softwareReleaseBuild: selectedRelease?.id };
        dispatch(softwareBuildActions.onChange("selectedBuildProducts", [...selectedReleaseProducts, newDevice]));
      }
    }
  };

  const onCheckboxaddDevice = (item) => {
    if (checkboxSelectedDevices.includes(item)) {
      setCheckboxSelectedDevices((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem.id !== item.productId) 
      );
    } else {
      setCheckboxSelectedDevices((prevSelected) => [...prevSelected, item]);
    }
  };

  const onselectAllDevices = () => {
      setCheckboxSelectedDevices(filteredvisibilityGroups);
      if(selectAllCheckBox === true) {
        setCheckboxSelectedDevices([])
      }
  }

  const addMultiple = () => {
    // Check for duplicates and remove duplicates
    const uniqueCheckboxDevices = checkboxSelectedDevices.filter(
      (checkboxDevice) =>
        !selectedReleaseProducts.some(
          (selectedReleaseProducts) => Number(selectedReleaseProducts.productId) === Number(checkboxDevice.id)
        )
    );

    if (uniqueCheckboxDevices.length > 0) {
      // Map data about according to payload
      const uniqueDevicesToAdd = uniqueCheckboxDevices.map((checkboxDevice) => {
        if (!buildId) {
          return {
            softwareReleaseId: selectedRelease?.id,
            productName: checkboxDevice.name,
            productId: checkboxDevice.id,
            id: 0,
          };
        } else {
          return {
            softwareReleaseId: selectedRelease?.softwareReleaseld,
            softwareReleaseBuild: selectedRelease?.id,
            productName: checkboxDevice.name,
            productId: checkboxDevice.id,
            id: 0,
          };
        }
      });
      if (!buildId) {
        dispatch(softwareUpdateActions.onChange("selectedReleaseProducts", [...selectedReleaseProducts, ...uniqueDevicesToAdd,]))
      } else {
        dispatch(softwareBuildActions.onChange("selectedBuildProducts", [...selectedReleaseProducts, ...uniqueDevicesToAdd]));
      }
    }
    setCheckboxSelectedDevices([]);
    setSelectAllCheckBox(false);
  };

  const removeDevice = (device) => {
    const newDevices = selectedReleaseProducts.filter((x) => x.productId !== device.productId);
    if (!buildId) {
      dispatch(softwareUpdateActions.onChange("selectedReleaseProducts", [...newDevices]))
    } else {
      dispatch(softwareBuildActions.onChange("selectedBuildProducts", [...newDevices]));
    }
  };

  const handleEligibleDevice = () => {
    setEligibleDevice((prevItems) =>
      prevItems === defaultItemsToShow
        ? selectedReleaseProducts.length
        : defaultItemsToShow
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isEligible}
        onClose={onClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isEligible}>
          <Grid container className={classes.paper}>
            <Grid item xs={12} className={classes.header}>
              <Typography className={classes.customerTypo}>{title}</Typography>
              <IconButton className={classes.closIcon} onClick={()=>{onClose();
                 setEligibleDevice(3);}}>
                <CloseIconCustomPopup />
              </IconButton>
            </Grid>
            <Grid xs={12} className={classes.mainBody}>
              <Grid item>
                <Typography className={classes.chooseCustomerHeading}>
                  {heading}
                </Typography>
                <Typography className={classes.text}>{description}</Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                className={classes.gridFilterTabContainer}
              >
                <Grid item xs={3} className="customerSearchfilter">
                  <SoftwareGridFilter
                    search={searchEligibleDevices}
                    setSearch={setSearchEligibleDevices}
                    placeholder={"Search"}
                  />
                </Grid>
                <Grid item xs={9} className="eligibleModaltabls">
                  <TopTabNavigator
                    getActiveTabId={(tabId) => {
                      setActiveTabId(`${tabId}`);
                    }}
                    tabs={[...allTabs]}
                    defaultTabId={activeTabId}
                    className="tabsHeader"
                  />
                </Grid>
              </Grid>
              <Grid className={classes.chipsBox}>
                <Grid container className={classes.chipContiner}>
                  {selectedReleaseProducts
                    .slice(0, eligibleDevice)
                    .map((device) => (
                      <Grid
                        key={`${device.id}-${device.productName ?? device.name}`}
                        className={classes.chipBox}
                      >
                        <Chip
                          size="small"
                          label={device.productName ?? device.name}
                          className={classes.selectedChips}
                          onDelete={() => removeDevice(device)}
                          deleteIcon={
                            <i onClick={() => removeDevice(device)}>
                              <ChipCross />
                            </i>
                          }
                        />
                      </Grid>
                    ))}
                  {selectedReleaseProducts.length === 0 && (
                    <Grid item xs={12} className={classes.noChipText}>
                      Added devices will appear here
                    </Grid>
                  )}
                </Grid>                
                  <span className={classes.expandChipButton} onClick={handleEligibleDevice}>
                    {eligibleDevice > 3 ? "See Less" : "See All"}
                  </span>
              </Grid>

              <Grid item xs={12} className="grid ">
                <SoftwareProductGrid
                  listData={filteredvisibilityGroups}
                  order={order}
                  onOrderBy={setOrderBy}
                  addDevice={addDevice}
                  checkboxSelectedDevices={checkboxSelectedDevices}
                  onCheckboxaddDevice={onCheckboxaddDevice}
                  onselectAllDevices={onselectAllDevices}
                  addMultiple={addMultiple}
                  setSelectAllCheckBox={setSelectAllCheckBox}
                  selectAllCheckBox={selectAllCheckBox}
                  selectedReleaseProducts={selectedReleaseProducts}
                />
              </Grid>

              <Grid container xs={12} className={classes.paginationContainer}>
                <Grid item xs={4} className={classes.btnContainer}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.cancleBtn}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={selectedReleaseProducts.length === 0}
                    variant="contained"
                    className={classes.applyBtn}
                    onClick={() => onApplyEligibleDevice()}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </div>
  );
};
