const scrollToBottom = ({ elementId }) => {
  if (!elementId) return;
  const containerRef = document.getElementById(elementId);
  if (containerRef) {
    containerRef.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
};

export { scrollToBottom };
