import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Delete, Visibility } from "@material-ui/icons";
import { last } from "lodash";
import { productMediaAction } from "../../../../store/product/productMediaAction";
import { ItemImage } from "../../../../components/common";

const useStyles = makeStyles(theme => ({
  mediaGripContainer: {
    flexGrow: 1,
  },
  gridTopNav: {
    padding: "10px 0 10px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: "23px",
  },
  deleteItems: {
    display: "flex",
    alignItems: "center",
  },
  gridHeaderInner: {
    color: "#A9B2BC",
    fontSize: "12px",
    fontWeight: "700",
    paddingTop: "12px",
    paddingBottom: "9px",
  },
  gridHeader: {
    background: "#E5F2FF",
    borderRadius: "6px",
  },
  gridHeaderRow: {
    borderRadius: "6px",
  },
  checkBoxFont: {
    fontSize: "22px",
  },
  gridBodyInner: {
    color: "#464E5F",
    fontSize: "14px",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  quickActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const ProductMediaGrid = ({ productId, listData, loadData, setLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedProductMedia, setSelectedProducts] = useState([]);

  const onItemSelect = itemId => {
    const itemExists =
      selectedProductMedia.length > 0 &&
      selectedProductMedia.find(c => c === itemId);
    if (itemExists) {
      setSelectedProducts(selectedProductMedia.filter(c => c !== itemId));
    } else {
      setSelectedProducts([...selectedProductMedia, itemId]);
    }
  };

  const deleteProducts = () => {
    setLoading(true);
    dispatch(
      productMediaAction.onDeleteProductMedias({
        ids: selectedProductMedia,
      })
    ).then(() => {
      loadData();
      setSelectedProducts([]);
      setLoading(false);
    });
  };

  const deleteProductMedia = itemID => {
    setLoading(true);
    dispatch(productMediaAction.onDeleteProductMedia(itemID)).then(() => {
      loadData();
      setLoading(false);
    });
  };

  const markMainImage = (pId, pmId) => {
    setLoading(true);
    dispatch(productMediaAction.onMarkMainImage(pId, pmId)).then(() => {
      loadData();
      setLoading(false);
    });
  };

  const markUseOnStore = (pmId, useOnStore) => {
    setLoading(true);
    dispatch(productMediaAction.onMarkUseOnStore(pmId, useOnStore)).then(() => {
      loadData();
      setLoading(false);
    });
  };

  return (
    <div className={classes.mediaGripContainer}>
      {selectedProductMedia.length > 0 ? (
        <div className={classes.gridTopNav}>
          <Grid item md={9} className={classes.deleteItems}>
            <span className='gridDeleteItems' onClick={() => deleteProducts()}>
              <DeleteIcon /> Delete
            </span>
            {/* <span className='gridMoveItems' onClick={() => {}}>
              <img src={MoveIcon} alt='' /> Move to
            </span> */}
          </Grid>
        </div>
      ) : null}
      <Table>
        <TableHead className={classes.gridHeader}>
          <TableRow className={classes.gridHeaderRow}>
            <TableCell className={classes.gridHeaderInner}>FILE NAME</TableCell>
            <TableCell className={classes.gridHeaderInner}>
              MAIN IMAGE
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              USE ON STORE
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>FILE TYPE</TableCell>
            <TableCell className={classes.gridHeaderInner}>FILE SIZE</TableCell>
            <TableCell className={classes.gridHeaderInner}>
              DATE UPLOADED
            </TableCell>
            <TableCell className={classes.gridHeaderInner}>
              QUICK ACTIONS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listData?.map((item, index) => {
            const fileName = last(item.fileName.split("/"));
            return (
              <TableRow key={index} className='gridBodyRow'>
                <TableCell className={classes.gridBodyInner}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <ItemImage imgSrc={item?.fileName} />
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <span>{fileName}</span>
                      {item?.isMainImage === 1 && (
                        <span className='mainImageTag'>Main Image</span>
                      )}
                    </span>
                  </span>
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <Radio
                    checked={item.isMainImage}
                    onChange={() =>
                      markMainImage(productId, item.productMediaId)
                    }
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <Checkbox
                    checked={item.useOnStore}
                    onChange={() =>
                      markUseOnStore(item.productMediaId, !item.useOnStore)
                    }
                  />
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.fileType}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.fileSize}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  {item.dateUploaded}
                </TableCell>
                <TableCell className={classes.gridBodyInner}>
                  <div
                    className={classes.quickActions}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}>
                    <span onClick={() => {}}>
                      <Visibility color='primary' />
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => deleteProductMedia(item.productMediaId)}>
                      <Delete color='primary' />
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
          {listData.length <= 0 && (
            <TableRow key='0' className='gridBodyRow'>
              <TableCell
                colSpan='8'
                className={classes.gridBodyInner}
                style={{ textAlign: "center" }}>
                <span className='emptyGridText'>
                  Uh Oh! You haven’t create any product media.
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export { ProductMediaGrid };
