import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { proSportFront } from "../../resources/images/index";

import { Carousel } from "react-responsive-carousel";
import MediaDetailCard from "./MediaDetailCard";
import moment from "moment";

class ProductCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPanelOpen: "false"
    }
  }
  render() {
    const { assetData } = this.props;
    let mediaImgs;
    if (assetData.assets && assetData.assets.length > 0) {
      mediaImgs = assetData.assets.filter(
        obj => obj.type === "png" || obj.type === "jpg" || obj.type === "jpeg"
      );
    }
    return (
      <>
        <Carousel>
          {mediaImgs &&
            mediaImgs.length > 0 &&
            mediaImgs.map((pmlAsset, index) => (
              <div key={index} className="slideWrapper">
                <div className="librarySlidesLeft">
                  <span className="leftInner">
                    <img
                      src={pmlAsset ? pmlAsset.imageUrl : proSportFront}
                      alt=""
                    />
                  </span>
                </div>
                {/* <div className="librarySlidesRight">
                <span className="rightInner">
                  <MediaDetailCard
                    heading={assetData.title}
                    subheading={assetData.subtitle}
                    mediaDescription={assetData.description}
                    category={categoryName}
                    skuNumber="PR###"
                    color="Black or White"
                    available="March 2020"
                  />
                </span>
              </div> */}
              </div>
            ))}
          {/* <div className="slideWrapper">
          <div className="librarySlidesLeft">
            <span className="leftInner">
              <img src={proSportFront} alt="" />
            </span>
          </div>
          <div className="librarySlidesRight">
            <span className="rightInner">
              <MediaDetailCard
                heading="MP6"
                subheading="Shallow Mount Stereo"
                mediaDescription="Aquatic AV’s Pro Sport Series consists of a 6.5” Pro Sport Speaker with a sport style grill and suberb sound quality from Aquatic AV. The 10” Pro Sport Subwoofer matches perfect to provide the ultimate listening experience."
                category="Speakers/Subwoofer"
                skuNumber="PR###"
                color="Black or White"
                available="March 2020"
              />
            </span>
          </div>
        </div> */}
        </Carousel>
        <div className=" innerCarousalWrapper">
          <div className="librarySlidesRight">
            <span className="rightInner">
             
              {this.state.isPanelOpen && <>
                {assetData && (
                <MediaDetailCard
                  heading={assetData.title}
                  subheading={assetData.subTitle}
                  mediaDescription={assetData.description}
                  category={assetData.categoryName}
                  // skuNumber="PR###"
                  // color="Black or White"
                  available={moment(assetData.visibilityDate).format(
                    "MMMM YYYY"
                  )}
                />
              )}
               </>}
              
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default ProductCarousel;
