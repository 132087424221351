import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Chip,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  FormControl,
  TextField,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { MdClear } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { searchIcon, crossImg } from "../../../../resources/images";
import { useDebounce } from "../../../../utils/use-debounce";
import InputField from "../../../../components/common/InputField";
import { FilterArrow } from "../../LeftNav/SvgIcons";
import { Autocomplete } from "@material-ui/lab";
import { productCategoriesActions } from "../../../../store/product/productCategoriesAction";
import { Dropdown } from "../../../../components/common";
import { DateCreated } from "../../../../config/Enums";
import { filter } from "lodash";
import moment from "moment";

const ChipItem = ({ chipLabel, chipValue, onDelete }) => {
  const classes = useStyless();

  return (
    <Chip
      className={classes.MuiChip}
      deleteIcon={<MdClear style={{ width: "16px", height: "16px" }} />}
      label={
        <span className={classes.MuiChip}>
          <b>{chipLabel}: </b>
          {chipValue}
        </span>
      }
      onDelete={onDelete}
    />
  );
};

const useStyless = makeStyles((theme) => ({
  gridWrapper: {
    paddingTop: "29px",
    paddingBottom: "20px",
    borderBottom: "1px solid #E2E6E9",
    position: "relative",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  MuiChip: {
    backgroundColor: "#CDE5FF",
    color: "#70808F",
    maxWidth: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    margin: "0px 5px 5px 0px",
  },
  chip: {
    margin: 2,
  },
  dropdownBg: {
    background: "#f1f1f1",
  },
  // formControl: {
  //   minWidth: "100%",
  //   background: "transparent",
  //   "& .MuiInputBase-input": {
  //     border: "unset !important",
  //   },
  // },
  // selectEmpty: {
  //   border: "1px solid #F3F6F9",
  //   backgroundColor: "#F3F6F9",
  //   borderRadius: "6px",
  //   "& .MuiInputBase-input": {
  //     border: "unset !important",
  //   },
  //   "&:before": {
  //     borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
  //     "&:hover": {
  //       borderBottom: "0 !important",
  //     },
  //   },
  //   "&:after": {
  //     borderBottom: "1px solid #F3F6F9",
  //   },

  //   MuiSvgIcon: {
  //     root: {
  //       fontSize: 20,
  //     },
  //   },
  // },
  MuiSelect: {
    icon: {
      fontSize: "26px",
    },
    iconOutlined: {
      fontSize: "26px",
    },
  },
  MuiFilledInput: {
    root: {
      "&:hover": {
        backgroundColor: "#F3F6F9",
      },
    },
  },
  dropdownWrapper: {
    width: "650px",
    padding: "15px 37px 40px 37px",
    position: "fixed",
    boxShadow: "0px 0px 13px 0px #ccc",
    right: "95px",
    marginTop: "-55px",
  },
}));

const handleSubmit = (e) => {
  e.preventDefault();
};

function ProductInventoryFilter({
  filters,
  setPage,
  setRowsPerPage,
  setFilters,
  title,
  isFilter,
  setIsFilter,
  searchKey,
  showFilter,
  hideFilter,
  applyFilters,
  showFilterCounter,
  children,
}) {
  const classes = useStyless();

  return (
    <div className={classes.gridWrapper}>
      <ValidatorForm ref={useRef("form")} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            className="filterMainSearch endUserSearchFilterMain"
          >
            <img src={searchIcon} alt="" className="searchIcon" />
            <InputField
              placeholder="Search"
              value={searchKey === "name" ? filters.name : filters.product}
              onKeyPress={({ key }) => {
                if (["Enter", "enter"].includes(key)) applyFilters();
              }}
              onChange={({ target }) => {
                if(searchKey === "name"){
                  setFilters({ ...filters, name: target.value });
                }else{
                  setFilters({ ...filters, product: target.value });
                }
              }}
              onPaste={(value) => {
                const splitValue = value.split("\n");
                if(searchKey === "name"){
                  setFilters({ ...filters, name: splitValue.join(", ") });
                }else{
                  setFilters({ ...filters, product: splitValue.join(", ") });
                }
              }}
            />
            <span className="endUserSearchFilter" onClick={showFilter}>
              {showFilterCounter ? (
                <span>{`${showFilterCounter} Filter${showFilterCounter > 1 ? "(s)" : ""
                  } Applied `}</span>
              ) : null}
              <FilterArrow color={"#fff"} />
            </span>
          </Grid>
        </Grid>

        {isFilter && (
          <div className="endUserFilterWrapper">
            <div className="endUserFilterInnerWrapper">
              <img
                className="filterCrossImage"
                src={crossImg}
                alt="cross icon"
                onClick={hideFilter}
              />
              <div className="formContainer">
                <h3 className="endUserFilterHeading">
                  {title}
                </h3>
                {children}
              </div>
              <div className="endUserfilrerBtn">
                <Button
                  variant="contained"
                  onClick={() => {
                    setPage(1);
                    setRowsPerPage(10);
                    applyFilters();
                  }}
                >
                  Apply Filter
                </Button>
              </div>
            </div>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}

export default ProductInventoryFilter;


export const FilterAutoComplete = ({
  value,
  onChange,
  id,
  options,
  getOptionLabel,
  placeholder,
  selectedFilter,
}) => {
  return <FormControl className='ac-dd'>
    <Autocomplete
      value={value}
      onChange={onChange}
      multiple
      filterSelectedOptions
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <TextField
          {...params} placeholder={placeholder} />
      )}
    />
    { selectedFilter > 0 && (
      <span className='countLabel'>{selectedFilter}</span>
    )}
  </FormControl>
}

export const SelectedChiptItems = ({
  selectedItems,
  items,
  setFilters,
  filtersIndex,
  filtersLimit,
  showFilters,
  field,
  chipLabel,
}) => {
  return <>
    {selectedItems?.length > 0 &&
      selectedItems.map((id, index) => {
        filtersIndex = filtersIndex + 1;
        return (
          (filtersIndex <= filtersLimit || (filtersIndex > filtersLimit && showFilters)) && (
            <ChipItem
              key={id}
              chipLabel={chipLabel}
              chipValue={
                items?.find(x => x?.id === id)?.value
              }
              onDelete={() => {
                setFilters(prevFilters => ({
                  ...prevFilters,
                  [field]: prevFilters[field].filter(y => y !== id)
                }));
              }}
            />
          )
        );
      })
    }
  </>
}

export const SelectedDateChip = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  chipLabel,
}) => {
  return <>
    {(startDate || endDate) && (
      <ChipItem
        chipLabel={chipLabel}
        chipValue={`${startDate
            ? moment(startDate).format("MM/DD/yyyy")
            : "?"
          } - ${endDate ? moment(endDate).format("MM/DD/yyyy") : "?"
          }`}
        onDelete={() => {
          if (startDate) setStartDate(null);
          if (endDate) setEndDate(null);
        }}
      />
    )}
  </>
}
