import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { endUserActions } from "../../../../store/endUser/endUserActions";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import Loader from "../../../../components/common/Loader";

export const TempratureInputGradient = () => {
  const dispatch = useDispatch();
  const { spaWaterTreatment, spaSettings, spaTemperatureSettings } = useSelector(
    (state) => state.endUserReducer
  );
  const [isLoading, setIsloading] = useState(false);
  const [clGradient, setClGradient] = useState(spaWaterTreatment.chlorine ?? 0);
  const [hpGradient, setHpGradient] = useState(spaWaterTreatment.ph ?? 0);
  const [aklGradient, setAklGradient] = useState(spaWaterTreatment.alkalinity ?? 0);

  const onUpdateTreatmentValue = (value) => {
    setIsloading(true);
    dispatch(endUserActions.onChangeWaterTreatment(value)).then((res) => {
      if (res?.success) {
        dispatch(endUserActions.onGetSpaInfo(spaSettings.deviceId)).then(
          (data) => {
            setIsloading(false);
            if (data?.success === false) {
              toast.error(data?.message);
            } else {
              toast.success("Value updated successfully.");
            }
          }
        );
      } else {
        setIsloading(false);
        toast.error(res?.message);
      }
    });
  };

  const onChangePh = (value) => {

    const phPayload = {
      id: spaWaterTreatment.id ? spaWaterTreatment.id : 0,
      ph: value,
      deviceId: spaSettings.deviceId,
    };
    onUpdateTreatmentValue(phPayload);
  };

  const onChangeChlorine = (value) => {
    const chlPayload = {
      id: spaWaterTreatment.id ? spaWaterTreatment.id : 0,
      chlroine: value,
      deviceId: spaSettings.deviceId,
    };
    onUpdateTreatmentValue(chlPayload);
  };

  const onChangeAlkaline = (value) => {
    const alKPayload = {
      id: spaWaterTreatment.id ? spaWaterTreatment.id : 0,
      alkalinity: value,
      deviceId: spaSettings.deviceId,
    };
    onUpdateTreatmentValue(alKPayload);
  };
  const debouncedPhValue = useCallback(debounce(onChangePh, 500), []);
  const debouncedChlorineValue = useCallback(
    debounce(onChangeChlorine, 500),
    []
  );
  const debouncedAlkalineValue = useCallback(
    debounce(onChangeAlkaline, 500),
    []
  );

  return (
    <div className="inputscontentcontainer">
      {isLoading ? <Loader /> : null}
      <span>Last Test Results:</span>
      <div className="inputitems">
        <h4 className="inputitemsTitles">CL</h4>
        <input
          type="range"
          min={0}
          max={4.0}
          step={0.1}
          value={clGradient}
          onChange={(e) => {
            if (spaTemperatureSettings?.isMaintenanceLock === false) {
              setClGradient(e.target.value)
              debouncedChlorineValue(e.target.value)
            }
          }}
          className="inputRange"
          style={{
            background: "linear-gradient(to right, #FF0000, #FF9C28, #FF9C28)",
          }}
        />
        <span className="inputitemsSpan">{clGradient}</span>
      </div>

      <div className="inputitems">
        <h4 className="inputitemsTitles">PH</h4>
        <input
          type="range"
          min={0}
          max={14.8}
          step={0.1}
          onChange={(e) => {
            if (spaTemperatureSettings?.isMaintenanceLock === false) {
              setHpGradient(e.target.value);
              debouncedPhValue(e.target.value);
            } else return;
          }}
          value={hpGradient}
          className="inputRange"
          style={{
            background: "linear-gradient(to right, #F6D14E, #3A7D9E, #00A3FF)",
          }}
        />
        <span className="inputitemsSpan">{hpGradient}</span>
      </div>
      <div className="inputitems">
        <h4 className="inputitemsTitles">ALK</h4>
        <input
          type="range"
          min={0}
          max={150}
          step={0.1}
          value={aklGradient}
          onChange={(e) => {
            if (spaTemperatureSettings?.isMaintenanceLock === false) {
              setAklGradient(e.target.value);
             debouncedAlkalineValue(e.target.value);
            }
          }}
          className="inputRange"
          style={{
            background: "linear-gradient(to right, #FFF6C7, #EE9FBE, #3A7D9E)",
          }}
        />
        <span className="inputitemsSpan">{aklGradient}</span>
      </div>
    </div>
  );
};
