import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const conversationdata = [
  {
    picture: "",
    customername: "Customer name",
    conversationtitle: "Conversation Title",
    conversationtime: "10:30 AM",
  },
  // {
  //   picture: "",
  //   customername: "Awais Ahmed",
  //   conversationtitle: "Conversation Title",
  //   conversationtime: "10:30 AM",
  // },
  // {
  //   picture: "",
  //   customername: "Mubeen",
  //   conversationtitle: "Conversation Title",
  //   conversationtime: "10:30 AM",
  // },
];

function RecentConversations() {
  return (
    <div>
      <div className='customerContactInner recentConversations'>
        <div className='customerContactHeader'>
          Recent Conversations{" "}
          <span>
            <Button color='primary' variant='contained'>
              View All
            </Button>
          </span>
        </div>
        <ul>
          {conversationdata.map((item, index) => {
            return (
              <li key={item.id}>
                <div className='conversationList'>
                  <span className='userPicture'>
                    {item.picture ? (
                      <img src={item.picture} alt='' />
                    ) : (
                      `${item?.customername?.charAt(0)?.toUpperCase()}`
                    )}
                  </span>
                  <div className='userDetail'>
                    <span className='customerName'>{item.customername} </span>
                    <span className='conversationTitle'>
                      {item.conversationtitle}
                    </span>
                    <span className='conversationTime'>
                      {item.conversationtime}
                    </span>
                  </div>
                </div>
                <div className='supportBtn'>
                  <Button color='secondary' varient='contained'>
                    Support
                  </Button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default RecentConversations;
